import { Flex, Heading, Icon, Text } from '@chakra-ui/react';
import {
  type LearningPath,
  contentTotalTime,
  formatShortContentDuration
} from '@tigerhall/core';
import * as React from 'react';
import { IconClock } from '@tigerhall/icons';
import { ReportButton } from 'components/ui';
import { RichTextMarkdownWrapper } from 'components/ui/MarkdownWrapper/RichTextMarkdownWrapper';

interface LeftPanelProps {
  content: LearningPath;
  actions: React.ReactNode;
}

export function LeftPanel({ content, actions }: Readonly<LeftPanelProps>) {
  const experts = content.contentCards
    .map((c) => {
      if ('experts' in c) {
        return c.experts;
      }

      return [];
    })
    .flat()
    .filter((e, i, a) => {
      // filter out experts that occur more than once by comparing the index of the current expert with
      // the index of the first occurrence of the expert
      return a.findIndex((j) => j.id === e.id) === i;
    })
    .sort((a, b) =>
      `${a.firstName} ${a.lastName}`.localeCompare(
        `${b.firstName} ${b.lastName}`
      )
    );

  return (
    <>
      <Flex direction="column">
        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap="0.25rem">
            <Icon as={IconClock} color="white" w="0.75rem" h="0.75rem" />

            <Text fontSize="0.75rem" fontWeight="700" color="white" as="span">
              {formatShortContentDuration(contentTotalTime(content))}
              &nbsp;|&nbsp;
              {content.contentCards.length} Content Pieces
            </Text>
          </Flex>
          <ReportButton
            contentId={content.id}
            contentTypeName={content.__typename}
          />
        </Flex>

        <Text
          fontSize="0.875rem"
          fontWeight="700"
          color="tigerOrange.600"
          mt="1.25rem"
        >
          {content.categories.map((c) => c.name).join(', ')}
        </Text>
        <Heading fontSize="1.25rem" fontWeight="700" color="white" mt="0.5rem">
          {content.name}
        </Heading>

        <RichTextMarkdownWrapper>{content.preamble}</RichTextMarkdownWrapper>

        <Text
          fontSize="0.75rem"
          fontWeight="700"
          color="lightGrey.600"
          mt="1rem"
        >
          Thinkfluencers
        </Text>

        <Flex direction="column" gap="0.125rem" mt="0.5rem">
          {experts.map((e) => (
            <Flex key={e.id}>
              <Text fontSize="0.875rem" color="lightGrey.600">
                {e.firstName} {e.lastName}{' '}
                <Text fontSize="inherit" color="tigerOrange.600" as="span">
                  {e.company}
                </Text>
              </Text>
              &nbsp;
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex gap="1rem">{actions}</Flex>
    </>
  );
}
