import * as React from 'react';
import { captureException } from '@sentry/react';
import { Flex, LightMode, Switch, Text } from '@chakra-ui/react';
import { type UserNotificationPreference } from '@tigerhall/core/lib/types';

interface PreferenceItemProps {
  isChecked?: boolean;
  preference: Omit<UserNotificationPreference, '__typename'>;
  onToggleNotification: (
    pref: Omit<UserNotificationPreference, '__typename'>,
    isChecked: boolean
  ) => Promise<void>;
}

export function PreferenceItem({
  isChecked = false,
  preference,
  onToggleNotification
}: PreferenceItemProps) {
  return (
    <Flex
      h="4rem"
      p="1.5rem"
      backgroundColor="whiteAlpha.200"
      alignItems="center"
      justifyContent="space-between"
      border="1px solid"
      borderColor="darkGrey.300"
    >
      <Text as="h5" fontSize="1rem" color="white">
        {preference?.name}
      </Text>
      <LightMode>
        <Switch
          colorScheme="tigerOrange"
          size="lg"
          color="tigerOrange.600"
          defaultChecked={isChecked}
          onChange={() => {
            onToggleNotification(preference, isChecked).catch((error) => {
              captureException(error, {
                extra: { preference, isChecked }
              });
            });
          }}
        />
      </LightMode>
    </Flex>
  );
}
