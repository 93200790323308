import { useEffect } from 'react';
import { Grid, GridItem, HStack, VStack } from '@chakra-ui/react';
import { FormImageUpload, FormInput } from '@tigerhall/components';
import { THLink } from '@tigerhall/ui-components';
import { CategoriesSelect } from 'components/form';
import ContentWizard from 'components/ui/containers/ContentWizard';
import { useTHForm } from 'hooks/useTHForm';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import { EditorForm } from 'components/ui/lexical/form/EditorForm';

import useDraftLearningPaths from '../../hooks/useDraftLearningPaths';
import { useGalleryImageUpload } from '~/hooks';
import {
  trailContentRoute,
  trailSummaryRoute
} from '../../utils/getTrailsRoutes';
import { OrganisationGroupSelectAsync } from '~/components/form/Select/OrganisationGroupSelectAsync';

const DESCRIPTION =
  'Please provide the trail with a title, description, image and category to make it more accessible to your team';

type TrailActionProps = {
  idOrDraft: string;
  children: React.ReactNode;
};

function TrailSummaryAction({
  children,
  idOrDraft
}: Readonly<TrailActionProps>) {
  return (
    <THLink name="TRAIL_SUMMARY" to={trailSummaryRoute(idOrDraft)}>
      {children}
    </THLink>
  );
}

function TrailContentSelection({
  children,
  idOrDraft
}: Readonly<TrailActionProps>) {
  return (
    <THLink name="TRAIL_CONTENT_SELECTION" to={trailContentRoute(idOrDraft)}>
      {children}
    </THLink>
  );
}

export function TrailDetails() {
  const navigate = useNavigate();
  const upload = useGalleryImageUpload();

  const { actions, draftMeta, isValidDraftMeta, contentIds, idOrDraft } =
    useDraftLearningPaths();

  useEffect(() => {
    if (contentIds.length <= 0) {
      navigate(trailContentRoute('draft'), { replace: true });
    }
  }, [contentIds.length, navigate]);

  const form = useTHForm({
    formName: 'CREATE_TRAIL_DETAILS_ENTRY',
    defaultValues: draftMeta
  });
  const values = form.watch();

  useDeepCompareEffect(() => {
    actions.addMeta(values);
  }, [values]);

  return (
    <ContentWizard
      description={DESCRIPTION}
      currentStep={2}
      title="Trail Details"
      buttons={[
        {
          text: 'Back',
          container: TrailContentSelection,
          containerProps: { idOrDraft },
          variant: 'outline',
          backgroundColor: 'transparent',
          dataCy: 'back-button'
        },
        {
          text: 'Continue',
          container: TrailSummaryAction,
          containerProps: { idOrDraft },
          isDisabled: !isValidDraftMeta,
          dataCy: 'continue-button'
        }
      ]}
    >
      <FormProvider {...form}>
        <Grid templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={2} pr="4" borderRight="1px solid #474747">
            <VStack flex={1} spacing="4" alignItems="stretch">
              <FormInput
                type="text"
                name="name"
                label="Trail Name"
                placeholder="Enter a trail name"
                rules={{
                  required: true,
                  minLength: {
                    value: 3,
                    message: 'Trail name must be at least 3 characters'
                  },
                  maxLength: {
                    value: 85,
                    message: 'Trail name must be at most 85 characters'
                  }
                }}
              />

              <EditorForm
                name="description"
                label="Trail Description"
                placeholder="Add a 2-3 sentence description to help your team understand what they can look forward to in this Trail. "
                rules={{
                  required: true
                }}
                showHeadings={false}
              />

              <HStack>
                <CategoriesSelect name="category" required />
                <OrganisationGroupSelectAsync
                  name="organisationGroups"
                  isMulti
                  label={'Organization Groups'}
                />
              </HStack>
            </VStack>
          </GridItem>

          <GridItem pl="4">
            <FormImageUpload
              name="image"
              label="Trail Image"
              hint="(Minimum 800x600px resolution)"
              upload={upload}
              showResetButton={false}
              rules={{
                required: true
              }}
            />
          </GridItem>
        </Grid>
      </FormProvider>
    </ContentWizard>
  );
}
