import { type tabsAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleFunction,
  PartsStyleInterpolation,
  PartsStyleObject,
  SystemStyleFunction,
  SystemStyleObject
} from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

const baseStyleRoot: SystemStyleFunction = (props) => {
  return {};
};

const baseStyleTab: SystemStyleFunction = (props) => {
  return {};
};

const baseStyleTablist: SystemStyleFunction = (props) => {
  return {
    minH: '3rem'
  };
};

const baseStyleTabpanel: SystemStyleObject = {};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  root: baseStyleRoot(props),
  tab: baseStyleTab(props),
  tablist: baseStyleTablist(props),
  tabpanel: baseStyleTabpanel
});

const variantLine: PartsStyleFunction<typeof parts> = (props) => {
  const { colorScheme: c } = props;

  return {
    tab: {
      color: mode(`${c}.600`, `darkGrey.50`)(props),
      fontSize: '1.125rem',
      fontWeight: 700,

      _selected: {
        color: mode(`${c}.600`, `${c}.600`)(props),
        borderColor: 'currentColor'
      }
    }
  };
};

const variantUnstyled: PartsStyleObject<typeof parts> = {};

const variants: Record<string, PartsStyleInterpolation<typeof parts>> = {
  line: variantLine,
  unstyled: variantUnstyled
};

const defaultProps = {
  size: 'lg',
  variant: 'line',
  colorScheme: 'tigerOrange'
};

export const Tabs = {
  baseStyle,
  variants,
  defaultProps
};
