import * as React from 'react';
import { Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Text, useBreakpointValue } from '@chakra-ui/react';
import { ExtractTypename } from '@tigerhall/core';

import { FeaturedContentCard } from './components/FeaturedContentCard';

interface FeaturedContentCarouselProps {
  featuredContent: Omit<
    React.ComponentProps<typeof FeaturedContentCard>,
    'index'
  >[];
  heading?: string;
  isVisibleOnFirstPaint?: boolean;
}

export function FeaturedContentCarousel({
  featuredContent,
  heading,
  isVisibleOnFirstPaint
}: FeaturedContentCarouselProps) {
  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false
    },
    {
      fallback: 'md',
      ssr: false
    }
  );

  return (
    <Box width="100%">
      {heading ? (
        <Text
          as="h3"
          color="white"
          fontSize={{ base: '1.25rem', md: '1.5rem' }}
          fontWeight="bold"
          mb={{ base: '1rem', md: '1.5rem' }}
          ml={{ base: '1rem', md: '2rem' }}
        >
          {heading}
        </Text>
      ) : null}
      <Swiper
        modules={[Navigation, Mousewheel]}
        spaceBetween={24}
        slidesPerView="auto"
        mousewheel={{
          forceToAxis: true,
          sensitivity: 1.5
        }}
        navigation={!isMobile}
        className="assignments-carousel"
        style={{
          paddingLeft: isMobile ? '1rem' : '2rem',
          paddingRight: isMobile ? '1rem' : '2rem'
        }}
      >
        {featuredContent?.map((item, index) => {
          const { content } = item;

          return (
            <SwiperSlide
              key={item?.id}
              style={{
                width: 'fit-content'
              }}
            >
              <FeaturedContentCard
                {...item}
                content={
                  content as ExtractTypename<
                    typeof content,
                    'Ebook' | 'Stream' | 'Podcast' | 'Event'
                  >
                }
                index={index}
                isVisibleOnFirstPaint={isVisibleOnFirstPaint}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
}
