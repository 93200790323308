import { Flex } from '@chakra-ui/react';
import React from 'react';

import { Typography } from '../Typography';

export const Loader: React.FC = () => (
  <Flex flex={1} pb={700} pt={10} justifyContent="center" alignItems="center">
    <Typography>Loading...</Typography>
  </Flex>
);
