import { type BoxProps, type StyleProps, Box, Text } from '@chakra-ui/react';
import { TrackedLink } from '@tigerhall/components';

interface SingleChatMessageProps {
  userName: string;
  nameForTrackLink?: string;
  userId?: string;
  message?: string;
  containerStyles?: BoxProps;
}

const COMMON_CHAT_STYLES: StyleProps = {
  fontSize: 'sm',
  lineHeight: 1.5,
  color: 'white',
  fontWeight: 'medium'
};

export function SingleChatMessage({
  message = '',
  nameForTrackLink = 'CHAT_USER',
  userId = '',
  userName,
  containerStyles
}: Readonly<SingleChatMessageProps>) {
  return (
    <Box {...containerStyles}>
      <Text
        as={'span'}
        {...COMMON_CHAT_STYLES}
        fontWeight={'bold'}
        overflowWrap={'anywhere'}
      >
        <TrackedLink name={nameForTrackLink} href={`/profile/${userId}`}>
          {userName}
        </TrackedLink>
      </Text>
      <Text as={'span'} {...COMMON_CHAT_STYLES} color={'tigerOrange.600'}>
        &nbsp;|&nbsp;
      </Text>
      <Text as={'span'} {...COMMON_CHAT_STYLES} overflowWrap={'anywhere'}>
        {message}
      </Text>
    </Box>
  );
}
