import { Box, Button } from '@chakra-ui/react';
import TaskSuccessComponent from 'components/ui/TaskSuccessComponent';
import * as React from 'react';
import { Link } from 'react-router-dom';

export default function UploadCompleted() {
  return (
    <Box>
      <TaskSuccessComponent
        description="You've created a new video! Remember, you can always edit the
            description later."
      >
        <Link to="/admin/videos">
          <Button size="lg">Return to Overview</Button>
        </Link>
      </TaskSuccessComponent>
    </Box>
  );
}
