import { Collapse } from '@chakra-ui/react';

import { usePowerReadState } from '~/player/PowerRead/context/usePowerReadState';
import { TextSizeSlider } from './TextSizeSlider';

export function CollapsiblePanel() {
  const { activeCollapsiblePanel } = usePowerReadState((state) => {
    return {
      activeCollapsiblePanel: state.activeCollapsiblePanel
    };
  });

  return (
    <Collapse in={activeCollapsiblePanel === 'textSize'} animateOpacity>
      <TextSizeSlider />
    </Collapse>
  );
}
