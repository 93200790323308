import * as React from 'react';
import { Center, Flex, Grid, GridProps } from '@chakra-ui/react';
import times from 'lodash/times';
import { Typography } from 'components';
import theme from 'theme';
import { IconCircleCheckFilled } from '@tigerhall/icons';

function repeatItems<T>(repeat: number, functor: (index: number) => T[]) {
  return times(repeat, functor)
    .flat()
    .slice(0, repeat * 2 - 1);
}

interface StepIndicatorProps extends Omit<GridProps, 'gridTemplateColumns'> {
  currentStep?: number;
  stepCount: number;
  stepKnobSize?: number;
  showStepText?: boolean;
  customStepperTexts?: string[];
  allDone?: boolean;
}

export default function StepIndicator({
  currentStep = 1,
  stepCount,
  stepKnobSize = 12,
  showStepText = true,
  customStepperTexts,
  allDone = false,
  ...rest
}: StepIndicatorProps) {
  // safety check to now allow the length of customStepperTexts array exceed or undervalue the stepCount.
  if (!!customStepperTexts && customStepperTexts.length !== stepCount) {
    return (
      <Typography color="red">
        Make sure the customStepperTexts array&apos;s length is equal to
        stepCount.
      </Typography>
    );
  }

  const gridTemplateColumns = repeatItems(stepCount, () => [
    `${stepKnobSize}px`,
    `auto`
  ]).join(' ');

  const gridColumn = 2 * (currentStep - 1) + 1;

  return (
    <Grid
      alignItems="center"
      gridTemplateColumns={gridTemplateColumns}
      gap={4}
      {...rest}
    >
      {repeatItems(stepCount, (index) => {
        const activeColor = theme.colors.orange['600'];
        const defaultColor = '#979797';
        let color = '';
        if (allDone) {
          color = activeColor;
        } else if (index < currentStep - 1) {
          color = activeColor;
        } else if (currentStep - 1 === index) {
          color = 'white';
        } else {
          color = '#979797';
        }
        const lineColor =
          allDone || index < currentStep - 1 ? activeColor : defaultColor;

        const size = Math.floor(stepKnobSize / 3);
        return [
          <Center
            w={size}
            h={size}
            bg={color}
            rounded={999}
            key={`box-${index}`}
          >
            {(allDone || index < currentStep - 1) && <IconCircleCheckFilled />}
          </Center>,
          <Flex
            w="auto"
            h={0}
            bg={color}
            borderWidth={1}
            borderColor={lineColor}
            rounded={999}
            key={`flex-${index}`}
          />
        ];
      })}
      {showStepText && customStepperTexts ? (
        repeatItems(stepCount, (index) => [
          <Typography
            w="max-content"
            color={currentStep - 1 === index && !allDone ? 'white' : '#979797'}
            transform="translateX(-40%)"
            key={`stepper-text-${index}`}
          >
            {customStepperTexts?.[index]}
          </Typography>,
          <Flex w="auto" h={0} key={`flex-${index}`} />
        ])
      ) : (
        <Typography
          gridColumn={gridColumn}
          w="max-content"
          transform="translateX(-18%)"
        >
          {`Step ${currentStep}`}
        </Typography>
      )}
    </Grid>
  );
}
