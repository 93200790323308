import * as React from 'react';
import { HStack } from '@chakra-ui/react';

import { PostAuthoringSkeleton } from './components/PostAuthoringSkeleton';

export function PostHeaderSkeleton() {
  return (
    <HStack
      justifyContent="space-between"
      spacing="0.5rem"
      pt="1rem"
      pb="1.25rem"
      px={{
        base: '1rem',
        md: '2rem'
      }}
    >
      <PostAuthoringSkeleton />
    </HStack>
  );
}
