import * as React from 'react';
import { Avatar, Circle, Icon } from '@chakra-ui/react';
import { resizeImage } from '@tigerhall/core';
import { Notification } from '@tigerhall/core/lib/types';
import { IconClipboardText, IconLive } from '@tigerhall/icons';

interface NotificationImageProps {
  type: Notification['type'];
  imageUrl?: string;
}

interface NotificationIconProps {
  icon: React.ForwardRefExoticComponent<
    React.SVGAttributes<SVGElement> & React.RefAttributes<SVGSVGElement>
  >;
}

function NotificationIcon({ icon }: NotificationIconProps) {
  return (
    <Circle
      size="60px"
      borderWidth="1px"
      borderColor="white"
      background="teal.900"
    >
      <Icon as={icon} color="white" width="2rem" height="2rem" />
    </Circle>
  );
}

export function NotificationImage({ type, imageUrl }: NotificationImageProps) {
  if (type === 'LIVESTREAM_IS_LIVE' || type === 'LIVESTREAM_INVITED_AS_HOST') {
    return <NotificationIcon icon={IconLive} />;
  }

  if (type === 'ASSIGNMENT') {
    return <NotificationIcon icon={IconClipboardText} />;
  }

  // default and if (type === 'COMMENT' || type === 'LIKE' || type === 'FOLLOW' || type === 'REPLY_ON_COMMENT')
  return (
    <Avatar
      src={
        imageUrl
          ? resizeImage({ url: imageUrl, width: 60, height: 60 })
          : undefined
      }
      width="60px"
      height="60px"
      borderWidth="1px"
      borderColor="white"
    />
  );
}
