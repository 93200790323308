import { useToast } from '@chakra-ui/react';
import { captureException } from '@sentry/react';

import { useSetUserBlockedStateMutation } from '~/generated';
import { cache } from '~/api/apollo';

export function useBlockUnblockUser() {
  const toast = useToast();

  const [setUserBlockState, { loading }] = useSetUserBlockedStateMutation();
  async function blockUser(id: string) {
    try {
      await setUserBlockState({
        variables: {
          id,
          isBlocked: true
        }
      });
      toast({
        title: 'Blocked successfully',
        status: 'success'
      });
      // Why the cache clear?
      // If user is blocked then we don't want them to access their details
      cache.evict({
        id: cache.identify({
          __typename: 'User',
          id
        })
      });
      cache.gc();
    } catch (error) {
      toast({
        title: 'Something went wrong',
        status: 'error'
      });
      captureException(error, {
        extra: {
          blockingUserId: id,
          isBlocking: true
        }
      });
    }
  }

  async function unblockUser(id: string) {
    try {
      await setUserBlockState({
        variables: {
          id,
          isBlocked: false
        }
      });
      toast({
        title: 'Unblocked successfully',
        status: 'success'
      });
    } catch (error) {
      toast({
        title: 'Something went wrong',
        status: 'error'
      });
      captureException(error, {
        extra: {
          unblockingUserId: id,
          isBlocking: false
        }
      });
    }
  }

  return { blockUser, unblockUser, loading };
}
