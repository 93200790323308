import { Box, Text } from '@chakra-ui/react';
import { type Content } from '@tigerhall/core';
import { RichTextMarkdownWrapper } from 'components/ui/MarkdownWrapper/RichTextMarkdownWrapper';
import map from 'lodash/map';

interface SegmentsSectionProps
  extends Pick<NonNullable<Content>, 'contentSegments'> {}

export function SegmentsSection({
  contentSegments
}: Readonly<SegmentsSectionProps>) {
  return (
    <>
      {contentSegments?.length
        ? map(contentSegments, (segment) => {
            return (
              <Box px={'6'} key={`segment-${segment.header}`}>
                <Text
                  color={'lightGrey.200'}
                  fontSize={'lg'}
                  fontWeight={'bold'}
                >
                  {segment?.header}
                </Text>
                <Box fontSize="sm" color="lightGrey.600">
                  <RichTextMarkdownWrapper>
                    {segment?.paragraph}
                  </RichTextMarkdownWrapper>
                </Box>
              </Box>
            );
          })
        : null}
    </>
  );
}
