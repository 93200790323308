import { Box, Button, VStack } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { THButton, Typography } from '@tigerhall/ui-components';
import { redirectToStripePaymentGateway } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import { useOrganisationAddUsersMutation } from 'generated';
import { getAccessToken, getOrgIdFromToken } from 'app/state';
import { useTHForm } from 'hooks/useTHForm';
import { useAppSelector } from 'hooks';
import { HeaderDetails } from 'modules/authentication/modules/SignUp/components/HeaderDetails';
import {
  AddMemberForm,
  type MemberType
} from 'modules/authentication/modules/SignUp/screens/Team/children/AddTeamMembers/AddMemberForm';
import { AddNewMember } from 'modules/authentication/modules/SignUp/screens/Team/children/AddTeamMembers/AddNewMember';

const members = {
  firstName: '',
  lastName: '',
  email: ''
};

export type MemberValues = {
  member: MemberType[];
};

export function AddTeamMembers() {
  const token = useAppSelector(getAccessToken);
  const orgId = useAppSelector(getOrgIdFromToken);
  const [mutation, { loading }] = useOrganisationAddUsersMutation();
  const form = useTHForm<MemberValues>({
    formName: 'subscriptionPlanTeamsSignupAddMembers',
    defaultValues: {
      member: [{ ...members }]
    }
  });
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = form;
  const { fields, append, remove } = useFieldArray({
    name: 'member',
    control
  });

  const onSubmit = async (values: MemberValues) => {
    segment.formSubmitted({
      formName: 'subscriptionPlanTeamsSignupAddMembers',
      formLocation: window?.location?.pathname
    });

    try {
      const res = await mutation({
        variables: {
          organisationId: orgId || '',
          users: values.member
        }
      });
      if (res) {
        redirectToStripePaymentGateway({
          origin: import.meta.env.VITE_API_URL || '',
          token: token || '',
          subscriptionType: 'team',
          successRedirectUrl: `${
            import.meta.env.VITE_WEBAPP_URL
          }/signup/team/confirmation`,
          cancelRedirectUrl: `${
            import.meta.env.VITE_WEBAPP_URL
          }/signup/team/add-users`
        });
      }
    } catch (e) {
      return e;
    }
  };

  const onSkip = () => {
    segment.buttonClicked({
      buttonName: 'Skip',
      location: window?.location?.pathname
    });
    redirectToStripePaymentGateway({
      origin: import.meta.env.VITE_API_URL || '',
      token: token || '',
      subscriptionType: 'team',
      successRedirectUrl: `${
        import.meta.env.VITE_WEBAPP_URL
      }/signup/team/confirmation`,
      cancelRedirectUrl: `${
        import.meta.env.VITE_WEBAPP_URL
      }/signup/team/add-users`
    });
  };

  return (
    <VStack
      w="100%"
      pt={{
        base: '3rem',
        md: '5rem'
      }}
    >
      <Box w="100%">
        <HeaderDetails
          heading="Add team users"
          description="Don't worry if you missed out some of your teammates, you can still add more users at a later stage."
        />
      </Box>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <VStack spacing="1.5rem">
            {fields.map((member, index) => (
              <>
                <AddMemberForm
                  key={`member-form-${member.email}`}
                  memberNumber={index + 1}
                  onRemove={() => remove(index)}
                  index={index}
                  member={member}
                  hasError={!!errors?.member?.[index]}
                />
                {errors?.member?.[index] && (
                  <Box w="100%">
                    {' '}
                    <Typography variant="detail4" color="orange.400">
                      * Please enter First Name, Last Name and Email
                    </Typography>
                  </Box>
                )}
              </>
            ))}
            <AddNewMember onClick={() => append({ ...members })} />
            <Box pt="1rem" w="100%">
              <THButton
                name="Confirm"
                type="submit"
                size="lg"
                w="100%"
                isDisabled={!isEmpty(errors) || loading}
                isLoading={loading}
              >
                Confirm
              </THButton>
            </Box>
            <Button
              color="white"
              variant="unstyled"
              size="lg"
              w="100%"
              onClick={() => onSkip()}
            >
              Skip
            </Button>
          </VStack>
        </form>
      </FormProvider>
    </VStack>
  );
}
