import { mode } from '@chakra-ui/theme-tools';

type Dict = Record<string, any>;

const baseStyleOverlay = {
  bg: 'blackAlpha.600',
  zIndex: 'overlay'
};

const baseStyleDialogContainer = {
  display: 'flex',
  zIndex: 'modal',
  justifyContent: 'center'
};

function baseStyleDialog(props: Dict) {
  const { isFullHeight } = props;

  return {
    ...(isFullHeight && { height: '100vh' }),
    zIndex: 'modal',
    maxH: '100vh',
    bg: mode('white', 'darkGrey.200')(props),
    color: 'inherit',
    boxShadow: mode('lg', 'dark-lg')(props)
  };
}

function baseStyleHeader(props: Dict) {
  return {
    px: 6,
    pt: '4rem',
    bg: mode('lightGrey.100', 'darkGrey.700')(props),
    color: mode('darkGrey.400', 'white')(props),
    fontSize: '1.25rem',
    fontWeight: 700
  };
}

const baseStyleCloseButton = {
  position: 'absolute',
  top: '2rem',
  insetEnd: '2.5rem',
  color: 'darkGrey.50',
  w: '2rem',
  h: '2rem',
  borderRadius: 'full'
};

function baseStyleBody(props: Dict) {
  return {
    px: 6,
    py: 0,
    flex: 1,
    overflow: 'auto',
    bg: mode('lightGrey.100', 'darkGrey.700')(props)
  };
}

function baseStyleFooter(props: Dict) {
  return {
    px: 6,
    py: 4,
    bg: mode('lightGrey.100', 'darkGrey.700')(props)
  };
}

const baseStyle = (props: Record<string, any>) => ({
  overlay: baseStyleOverlay,
  dialogContainer: baseStyleDialogContainer,
  dialog: baseStyleDialog(props),
  header: baseStyleHeader(props),
  closeButton: baseStyleCloseButton,
  body: baseStyleBody(props),
  footer: baseStyleFooter(props)
});

export const Drawer = {
  baseStyle
};
