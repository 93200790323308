import { FeedActivityType, PostActivityType } from '@tigerhall/core';
import { Text, type TextProps } from '@chakra-ui/react';

import {
  useGetContentActivityCountQuery,
  useGetPostActivityCountQuery
} from './queries/generated/activityDescription.generated';

export interface ActivityDescriptionProps extends TextProps {
  activityType: FeedActivityType | PostActivityType;
  /** ID of the Post or Content that the activity is related to */
  postOrContentId: string;
}

export function ActivityDescription({
  activityType,
  postOrContentId,
  ...props
}: Readonly<ActivityDescriptionProps>) {
  const isCountablePostActivity =
    activityType === PostActivityType.Reacted ||
    activityType === PostActivityType.Commented;

  const isCountableContentActivity = activityType === FeedActivityType.Comment;

  const { data: postActivityCount } = useGetPostActivityCountQuery({
    skip: !isCountablePostActivity,
    variables: {
      id: postOrContentId
    }
  });

  const { data: contentActivityCount } = useGetContentActivityCountQuery({
    skip: !isCountableContentActivity,
    variables: {
      id: postOrContentId
    }
  });

  function getOtherText(activityCount: number) {
    const otherUsersActivityCount = activityCount - 1;

    return otherUsersActivityCount
      .toString()
      .concat(otherUsersActivityCount > 1 ? ' others' : ' other');
  }

  function getDescription() {
    switch (activityType) {
      // feed content activities
      case FeedActivityType.RatedContent:
        return 'gave this content a good rating';

      case FeedActivityType.NewelyPublishContent:
        return 'published a new content';

      case FeedActivityType.Comment: {
        const content = contentActivityCount?.contentCard;
        const commentersCount =
          content?.__typename === 'Podcast' ||
          content?.__typename === 'Stream' ||
          content?.__typename === 'Event' ||
          content?.__typename === 'Ebook'
            ? content.numberOfCommenters
            : 0;

        return commentersCount > 1
          ? `and ${getOtherText(commentersCount)} commented on this content`
          : 'commented on this content';
      }
      case FeedActivityType.ReplyOnComment:
        return 'replied to a comment on this content';

      // feed post activities
      case PostActivityType.Reacted: {
        const post = postActivityCount?.feedContentById;
        const reactionsCount =
          post?.__typename === 'Post' ? post.reactions.totalCount : 0;

        return reactionsCount > 1
          ? `and ${getOtherText(reactionsCount)} reacted to this post`
          : 'reacted to this post';
      }
      case PostActivityType.Commented: {
        const post = postActivityCount?.feedContentById;
        const commentersCount =
          post?.__typename === 'Post' ? post?.numberOfCommenters : 0;

        return commentersCount > 1
          ? `and ${getOtherText(commentersCount)} commented on this post`
          : 'commented on this post';
      }
      case PostActivityType.Replied:
        return 'replied to a comment on this post';

      case PostActivityType.Reposted:
        return 'reposted this post';

      // only available in user activity feed
      case FeedActivityType.FollowUser:
        return 'followed this user';

      case FeedActivityType.FinishedContent:
        return 'finished this content';

      default:
        return 'interacted with this content';
    }
  }

  return <Text {...props}>{getDescription()}</Text>;
}
