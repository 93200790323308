import { useCallback } from 'react';
import { List } from '@chakra-ui/react';
import { User } from '@tigerhall/core/lib/types';
import { IconCaretRightFilled } from '@tigerhall/icons';
import {
  ProfileBarSkeleton,
  TrackedLink,
  TrackedRTForm
} from '@tigerhall/components';
import NoFollowFollowers from 'modules/application/modules/Profile/components/FollowerFollowingPanel/components/NoFollowFollowers';
import FollowCount from 'modules/application/modules/Profile/components/FollowerFollowingPanel/components/FollowCount';

import { SearchBarInput } from '~/components/ui/SearchBarInput';
import { ProfileBarFollow } from '~/components/ui/User/ProfileBarFollow';
import { DummyCopies } from '~/components/ui/DummyCopies';

export type ActiveStatusType = {
  tabIndex: number;
  followersSearchValue: string;
  followingsSearchValue: string;
};

export type FollowersFollowingTabsType = {
  currentList: User[];
  loading: boolean;
  followCount: string;
  currentTab: 'follower' | 'following';
  isCurrentUser?: boolean;
  setActiveStatus?: React.Dispatch<React.SetStateAction<ActiveStatusType>>;
};

export function FollowerFollowingPanel({
  currentList,
  loading,
  followCount,
  currentTab,
  isCurrentUser,
  setActiveStatus = () => {}
}: Readonly<FollowersFollowingTabsType>) {
  const onUpdate = useCallback(
    (values) => {
      if (currentTab === 'following') {
        setActiveStatus((prev) => ({
          ...prev,
          followingsSearchValue: values.followUnfollow
        }));
      } else {
        setActiveStatus((prev) => ({
          ...prev,
          followersSearchValue: values.followUnfollow
        }));
      }
    },
    [currentTab, setActiveStatus]
  );

  return (
    <>
      <TrackedRTForm
        debounceMS={200}
        onSubmit={onUpdate}
        formHookProps={{
          defaultValues: {
            followUnfollow: ''
          }
        }}
        name="followFollowingSearch"
      >
        <SearchBarInput name="followUnfollow" />
      </TrackedRTForm>
      {currentTab === 'following' && isCurrentUser ? <TrackedLink
          name="FOLLOW_PEOPLE_FROM_ORGANIZATION"
          as="button"
          variant="outline"
          size="lg"
          w="100%"
          mt="2rem"
          justifyContent="space-between"
          href="/organization/people"
          rightIcon={
            <IconCaretRightFilled color="white" width="1em" height="1em" />
          }
        >
          Follow People From Your Organization
        </TrackedLink> : null}
      {loading ? <List spacing={4}>
          <FollowCount followCount={followCount} />
          <DummyCopies count={5}>
            <ProfileBarSkeleton />
          </DummyCopies>
        </List> : null}
      {currentList?.length ? (
        <>
          <FollowCount followCount={followCount} />
          <List spacing={4}>
            {currentList.map((user) => (
              <ProfileBarFollow
                userId={user.id}
                key={`following-panel-${user.id}`}
              />
            ))}
          </List>
        </>
      ) : (
        <NoFollowFollowers
          isCurrentUser={isCurrentUser}
          currentTab={currentTab}
        />
      )}
    </>
  );
}
