import * as React from 'react';
import {
  Box,
  BoxProps,
  Button,
  Flex,
  SimpleGrid,
  Text
} from '@chakra-ui/react';
import includes from 'lodash/includes';
import map from 'lodash/map';
import { AnimatePresence, motion } from 'framer-motion';
import { TigerhallSkeleton } from '@tigerhall/ui-components';
import { ContentCardWithOverlay } from '@tigerhall/components';
import { Content } from '@tigerhall/core/lib/types';
import { useGetContentByIdQuery } from 'generated';
import { DummyCopies, NoSearchResults } from 'components/ui';
import { ContentSummaryModal } from 'modules/admin/components';

interface ContentCardListProps extends BoxProps {
  cards: Content[];
  totalCount?: number;
  selectedContentIds: string[];
  setSelectedContentIds: (ids: string[]) => void;
  loading?: boolean;
}

export function ContentCardList({
  cards,
  totalCount,
  selectedContentIds,
  setSelectedContentIds,
  loading,

  ...rest
}: ContentCardListProps) {
  const [selectedContent, setSelectedContent] = React.useState<string>();

  const { data: selectedContentData } = useGetContentByIdQuery({
    skip: !selectedContent,
    variables: {
      id: selectedContent as string
    }
  });

  const onCardSelect = React.useCallback(
    (id: string) => {
      setSelectedContentIds([...selectedContentIds, id]);
    },
    [selectedContentIds, setSelectedContentIds]
  );

  if (loading) {
    return (
      <Flex justifyContent="center">
        <DummyCopies count={4}>
          <Box mt="2rem" pb="1rem">
            <Box mx="0.5rem">
              <TigerhallSkeleton variant="card" />
            </Box>
          </Box>
        </DummyCopies>
      </Flex>
    );
  }

  if (!loading && cards?.length === 0) {
    return <NoSearchResults />;
  }
  return (
    <motion.div>
      {totalCount ? (
        <Text fontSize={'sm'} color={'tigerOrange.600'}>
          {totalCount} search results
        </Text>
      ) : null}
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, xl: 4, '2xl': 5, '3xl': 6 }}
        spacing={6}
        {...rest}
      >
        <AnimatePresence>
          {map(cards, (data) => {
            if (includes(selectedContentIds, data?.id)) {
              return null;
            }

            return (
              <motion.div
                key={data?.id}
                layout
                layoutId={data?.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <ContentCardWithOverlay
                  {...data}
                  overlayActions={[
                    {
                      label: 'Learn More',
                      variant: 'outline',
                      onClick: () => {
                        setSelectedContent(data?.id);
                      }
                    },
                    {
                      label: 'Select Content',
                      variant: 'solid',
                      onClick: () => onCardSelect(data?.id)
                    }
                  ]}
                />
              </motion.div>
            );
          })}
        </AnimatePresence>
      </SimpleGrid>
      <ContentSummaryModal
        isOpen={!!selectedContentData?.contentCard}
        onClose={() => setSelectedContent(undefined)}
        content={selectedContentData?.contentCard as Content}
        actions={
          <>
            <Button
              flex={1}
              variant="outline"
              onClick={() => {
                onCardSelect(selectedContent || '');
                setSelectedContent(undefined);
              }}
            >
              Select Content
            </Button>
          </>
        }
      />
    </motion.div>
  );
}
