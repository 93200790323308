import { HStack } from '@chakra-ui/react';
import * as React from 'react';

interface PostMetricsProps {
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
}

export function PostMetrics({ leftSection, rightSection }: PostMetricsProps) {
  return (
    <HStack
      justifyContent="space-between"
      mb="1.25rem"
      mx={{
        base: '1rem',
        md: '2rem'
      }}
      spacing="0.75rem"
      fontSize="md"
      color="lightGrey.200"
    >
      <HStack spacing="0.75rem">{leftSection}</HStack>
      <HStack spacing="0.75rem">{rightSection}</HStack>
    </HStack>
  );
}
