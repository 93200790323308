// Common function to determine if the box is highlighted
export const getIsBoxHighlighted = (
  commentId: string,
  searchParams: URLSearchParams
) => {
  if (commentId) {
    return searchParams.get('highlight')?.includes(commentId);
  }
};

export const AVATAR_WIDTH = 2.75;
export const AVATAR_SPACING = 0.5;
export const COMMENT_PADDING_LEFT = AVATAR_WIDTH + AVATAR_SPACING;
