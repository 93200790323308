import { useCallback, useState } from 'react';
import { Flex, HStack, Icon, Text, Tooltip } from '@chakra-ui/react';
import { FormInput, TrackedRTForm } from '@tigerhall/components';
import { ContentPanel } from 'components/ui';
import { OrganisationGroupSelect } from 'components/form';
import {
  type SortableAndResizableColumn,
  Table
} from '@tigerhall/ui-components';
import { IconBook, IconHeadphones, IconMovie } from '@tigerhall/icons';
import { useAppSelector } from 'hooks';
import { hasOrgAdminAccess } from 'app/state';

import {
  type AssignmentStatisticsQuery,
  useAssignmentStatisticsQuery
} from '../../../queries';
import { ExportButton } from './ExportButton';

type Row = AssignmentStatisticsQuery['assignment']['statistics']['edges'][0];

const columns: SortableAndResizableColumn<Row>[] = [
  {
    id: 'name',
    Header: 'Full Name',
    disableSortBy: true,
    Cell: ({ value }) => (
      <Text
        fontSize="inherit"
        fontWeight="inherit"
        color="inherit"
        textTransform="capitalize"
      >
        {value}
      </Text>
    ),

    accessor: (originalRow) => {
      return `${originalRow.user.firstName} ${originalRow.user.lastName}`;
    }
  },
  {
    id: 'groups',
    Header: 'Groups',
    disableSortBy: true,
    width: 500,
    accessor: (originalRow) => {
      return originalRow.user.organisationGroups
        ?.map((g) => g.name)
        .sort()
        .join(', ');
    }
  },
  {
    id: 'ebooksCompleted',
    width: 50,
    Header: (
      <Tooltip hasArrow label="Power Reads" bg="lightGrey.200" color="black">
        <Icon as={IconBook} />
      </Tooltip>
    ),
    disableResizing: true,
    disableSortBy: true,
    align: 'center',
    accessor: 'ebooksCompleted'
  },
  {
    id: 'streamsCompleted',
    width: 50,
    Header: (
      <Tooltip hasArrow label="Videos" bg="lightGrey.200" color="black">
        <Icon as={IconMovie} />
      </Tooltip>
    ),
    disableResizing: true,
    disableSortBy: true,
    align: 'center',
    accessor: 'streamsCompleted'
  },
  {
    id: 'podcastsCompleted',
    width: 50,
    Header: (
      <Tooltip hasArrow label="Podcasts" bg="lightGrey.200" color="black">
        <Icon as={IconHeadphones} />
      </Tooltip>
    ),
    disableResizing: true,
    disableSortBy: true,
    align: 'center',
    accessor: 'podcastsCompleted'
  },
  {
    id: 'completionRate',
    width: 100,
    Header: '% Completion rate',
    disableSortBy: true,
    Cell: ({ value }) => (
      <Text fontSize="inherit" fontWeight="inherit" color="inherit">
        {value}%
      </Text>
    ),
    accessor: 'completionRate'
  }
];

interface SectionStatisticsProps {
  assignmentId: string;
}

export function SectionStatistics({
  assignmentId
}: Readonly<SectionStatisticsProps>) {
  const isOrgAdmin = useAppSelector(hasOrgAdminAccess);

  const [variables, setVariables] = useState({
    id: assignmentId,
    filter: {
      name: '',
      email: '',
      groups: []
    },
    pagination: {
      limit: 20,
      offset: 0
    }
  });

  const { data, loading, error } = useAssignmentStatisticsQuery({
    variables
  });

  const refetchData = useCallback(
    (limit: number, offset: number) => {
      setVariables((prevState) => ({
        ...prevState,
        pagination: {
          limit,
          offset
        }
      }));
    },
    [setVariables]
  );

  const onFilterChange = useCallback(
    (values) => {
      setVariables((prevState) => ({
        ...prevState,
        filter: {
          name: values.name,
          email: values.email,
          groups: values.groups.map((g) => g.id)
        },

        pagination: {
          limit: 20,
          offset: 0
        }
      }));
    },
    [setVariables]
  );

  return (
    <ContentPanel
      heading="Statistics"
      subheading="The statistics here are updated every four hours and can be filtered by any group regardless of the assigned group to allow for a more granular view of the data."
      headingRightSlot={
        isOrgAdmin ? (
          <ExportButton id={assignmentId} filter={variables.filter} />
        ) : null
      }
    >
      <Flex direction="column" gap="1.5rem">
        <Flex
          direction="column"
          border="1px solid"
          borderColor="darkGrey.400"
          padding="1rem"
          borderRadius="lg"
          gap="1rem"
        >
          <TrackedRTForm
            name="assignment_statistics_filter"
            onSubmit={onFilterChange}
          >
            <HStack direction="row" spacing={4} align="stretch">
              <FormInput
                name="name"
                type="text"
                label="Name"
                placeholder="Enter name"
              />
              <FormInput
                name="email"
                type="text"
                label="Email"
                placeholder="Enter email"
              />
              <OrganisationGroupSelect name="groups" isMulti />
            </HStack>
          </TrackedRTForm>
        </Flex>

        {error ? (
          <Text mx="auto" p="2rem">
            Something went wrong, please try again later.
          </Text>
        ) : (
          <Table
            name="assigmentStatistics"
            loading={loading}
            reFetchData={refetchData}
            data={data?.assignment.statistics.edges ?? []}
            columns={columns}
            pageSize={20}
            pageMeta={data?.assignment.statistics.meta}
          />
        )}
      </Flex>
    </ContentPanel>
  );
}
