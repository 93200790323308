import { Grid } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { User } from '@tigerhall/core/lib/types';
import { StatsCard } from 'modules/application/modules/Profile/components/common';
import { redirectToUserFollowingFollwerRoute } from 'modules/application/utils/routes';
import { THLink } from '@tigerhall/ui-components';

type StatisticsProps = {
  user: User;
  isCurrentUser: boolean;
};

const Statistics = ({ user, isCurrentUser }: StatisticsProps) => {
  const { followerCount, followingCount, contentCompletedCount, id } =
    user || {};

  const navigate = useNavigate();
  return (
    <Grid
      w="full"
      templateColumns={isCurrentUser ? 'repeat(3,1fr)' : 'repeat(2,1fr)'}
      gap="1rem"
    >
      <StatsCard
        label="FOLLOWERS"
        value={followerCount || 0}
        onClick={() =>
          navigate(redirectToUserFollowingFollwerRoute(id), {
            state: { isFollowers: true, isCurrentUser }
          })
        }
      />
      <StatsCard
        label="FOLLOWING"
        value={followingCount || 0}
        onClick={() =>
          navigate(redirectToUserFollowingFollwerRoute(id), {
            state: { isFollowers: false, isCurrentUser }
          })
        }
      />
      {isCurrentUser && (
        <THLink name="CONTENT_COMPLETED" to="content-completed">
          <StatsCard
            label="CONTENT COMPLETED"
            value={contentCompletedCount || 0}
          />
        </THLink>
      )}
    </Grid>
  );
};
export default Statistics;
