import * as React from 'react';
import { HeaderGroup } from 'react-table';
import { Flex, Box, Text, HStack } from '@chakra-ui/react';
import * as segment from '@tigerhall/analytics';
import { ToolTip } from 'components';

import { TableCell } from '../styles';

export const DefaultColumnHeader: React.FC<{
  header?: string;
  tooltip?: boolean;
  label?: string;
}> = ({ header, label, tooltip }) => (
  <Flex alignItems="center">
    <Box mr={1}>{header}</Box>
    {tooltip && label && <ToolTip label={label} />}
  </Flex>
);

export const TableHeader = <D extends object = {}>({
  headerGroups
}: {
  headerGroups: HeaderGroup<D>[];
}) => (
  <Flex flexDirection="column" width="100%" userSelect="none">
    {headerGroups.map((headerGroup) => (
      <Flex
        {...headerGroup.getHeaderGroupProps()}
        flex={1}
        flexDirection="row"
        background="black"
        px={2}
      >
        {headerGroup.headers.map((column) => {
          const headerProps = column.getHeaderProps();
          const sortOrder = column.isSortedDesc ? 'desc' : 'asc';

          const handleSortBy = () => {
            column.toggleSortBy();
            segment.tableSortClicked({
              columnId: column.id,
              header: typeof column.Header === 'string' ? column.Header : '',
              direction: column.isSorted ? sortOrder : null
            });
          };

          return (
            <HStack
              {...headerProps}
              justifyContent={column.align || 'space-between'}
            >
              <TableCell
                {...column.getSortByToggleProps()}
                onClick={column.canSort ? handleSortBy : undefined}
              >
                <Text as="span" fontSize="sm" fontWeight="bold">
                  {column.render('Header')}
                </Text>
                {column.canSort ? headerProps.sorting : null}
              </TableCell>
              {column.canResize ? headerProps.resizing : null}
            </HStack>
          );
        })}
      </Flex>
    ))}
  </Flex>
);
