import { Divider, HStack, Skeleton, VStack } from '@chakra-ui/react';
import { DummyCopies } from 'components/ui';
import * as React from 'react';

interface FeedItemActionsProps {
  numberOfButtons?: 1 | 2 | 3;
}

export function FeedItemActionsSkeleton({
  numberOfButtons = 3
}: FeedItemActionsProps) {
  return (
    <VStack
      mb="0.5rem"
      mx={{
        base: '1rem',
        md: '2rem'
      }}
      spacing="0.5rem"
    >
      <Divider borderColor="darkGrey.400" />
      <HStack spacing="0.5rem" width="100%">
        <DummyCopies count={numberOfButtons}>
          <Skeleton width="100%" height="2.5rem" rounded="md" />
        </DummyCopies>
      </HStack>
    </VStack>
  );
}
