import { theme } from '@tigerhall/theme';

export const mentionInputDefaultStyle = {
  // for some reason this avoids the text from being cut off from the top and bottom of the input
  // when the input is multiline. We found this issue while working in the create post modal.
  '&multiLine': {
    highlighter: {
      padding: '0.75rem 0'
    },
    input: {
      padding: '0.75rem 0'
    }
  },

  suggestions: {
    borderRadius: '0.5rem',
    backgroundColor: theme.colors.darkGrey[400],
    loadingIndicator: {
      boxShadow:
        'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,rgba(0, 0, 0, 0.2) 0px 5px 10px,rgba(0, 0, 0, 0.4) 0px 15px 40px',
      minWidth: '22.5rem',
      borderRadius: '0.5rem',
      backgroundColor: theme.colors.darkGrey[400],
      spinner: {
        backgroundColor: theme.colors.darkGrey[400],
        borderRadius: '0.5rem',
        element5: { display: 'none' }
      }
    }
  }
};

export const mentionDefaultStyle = {
  backgroundColor: theme.colors.whiteAlpha[200],
  borderRadius: '1rem'
};
