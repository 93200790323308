import * as React from 'react';
import axios from 'axios';
import { AppError } from 'utils';

import {
  ImageFragment,
  useCreateGalleryImageFromUrlMutation,
  useCreateUploadUrlMutation
} from '../generated';

export interface UseFileUploadReturn {
  upload: (file: File) => Promise<string>;
  progress: number;
}

export function useFileUpload(): UseFileUploadReturn {
  const [mutation] = useCreateUploadUrlMutation();
  const [progress, setProgress] = React.useState(0);

  const upload = React.useCallback(
    async (file: File) => {
      if (!file) {
        throw new AppError('No file to upload');
      }

      const { data } = await mutation({
        variables: {
          name: file.name,
          contentType: file.type
        }
      });

      if (!data) {
        throw new AppError(
          'No data returned from CreateUploadUrlMutation mutation',
          {
            extra: {
              file
            }
          }
        );
      }

      await axios.put(data.getPreSignedUploadUrl.url, file, {
        headers: data.getPreSignedUploadUrl.headers,
        onUploadProgress: ({ progress: axiosProgress }) => {
          setProgress(Math.round((axiosProgress ?? 0) * 100));
        }
      });

      return data.getPreSignedUploadUrl.url;
    },
    [mutation]
  );

  return {
    upload,
    progress
  };
}

type UseGalleryImageUploadReturn = (file: File) => Promise<ImageFragment>;

export function useGalleryImageUpload(): UseGalleryImageUploadReturn {
  const { upload } = useFileUpload();
  const [mutation] = useCreateGalleryImageFromUrlMutation();

  return async (file: File) => {
    const url = await upload(file);

    const result = await mutation({
      variables: {
        url
      }
    });

    if (!result.data) {
      throw new Error('No data returned from mutation');
    }

    return result.data.createGalleryImageFromPreSignedUrl;
  };
}
