import { Button, Flex, Heading } from '@chakra-ui/react';

interface EditProfileHeaderProps {
  isSubmitting?: boolean;
}

export function EditProfileHeader({
  isSubmitting
}: Readonly<EditProfileHeaderProps>) {
  return (
    <Flex
      w="100%"
      mt={8}
      h="4rem"
      justifyContent="space-between"
      alignItems="center"
      display={{
        base: 'none',
        md: 'flex'
      }}
    >
      <Heading fontSize="1.5rem" color="lightGrey.200">
        Edit Profile
      </Heading>
      <Button
        type="submit"
        data-cy="save-changes"
        variant="solid"
        size="lg"
        isLoading={isSubmitting}
      >
        Save Changes
      </Button>
    </Flex>
  );
}
