const styles = {
  baseStyle: {
    popper: {
      width: 'fit-content',
      maxWidth: 'fit-content'
    }
  }
};

export default styles;
