import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgMovie = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="th-icon"
    viewBox="0 0 24 24"
    focusable={false}
    color="white"
    width="1.25rem"
    height="1.25rem"
    ref={ref}
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M4 6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zM8 4v16M16 4v16M4 8h4M4 16h4M4 12h16M16 8h4M16 16h4" />
  </svg>
);
SvgMovie.displayName = "IconMovie";
const ForwardRef = forwardRef(SvgMovie);
export default ForwardRef;
