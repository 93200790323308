import { ChakraProps, VStack, type BoxProps } from '@chakra-ui/react';
import { ValueOf } from '@tigerhall/core';

import { AIBadge } from '~/badges';
import { InfoBox } from './InfoBox';

interface TranslatedByAIProps extends BoxProps {
  hideAIBadge?: boolean;
  infoColor?: ValueOf<Pick<ChakraProps, 'color'>>;
  infoBoxStyles?: Pick<ChakraProps, 'bg' | 'alignItems'>;
}

export function TranslatedByAI({
  hideAIBadge = false,
  infoColor = 'blue.600',
  infoBoxStyles = {},
  ...rest
}: Readonly<TranslatedByAIProps>) {
  return (
    <VStack p="1rem" alignItems={'flex-start'} {...rest}>
      {!hideAIBadge && <AIBadge />}
      <InfoBox
        iconStyles={{ color: infoColor }}
        textStyles={{ color: infoColor, fontSize: 'sm' }}
        p={0}
        {...infoBoxStyles}
      >
        Please be mindful that AI translations might contain interpretations or
        small inaccuracies, and that some content may not be available in your
        preferred language.
      </InfoBox>
    </VStack>
  );
}
