import { VStack } from '@chakra-ui/react';

import { useGetUpNextQueueItems } from '~/hooks';
import { QueueItem } from './components/QueueItem';
import { TrailContents } from './components/TrailContents';
import { UpNextContentSkeleton } from './components/UpNextContentSkeleton';

export function UpNextTab() {
  const { isLoading, queueItems } = useGetUpNextQueueItems();

  return (
    <VStack alignItems={'flex-start'} width={'full'} minH={'100%'}>
      {isLoading ? (
        <UpNextContentSkeleton />
      ) : (
        queueItems?.map((item, index) =>
          item.__typename === 'LearningPath' ? (
            <TrailContents
              content={item}
              contentIndex={`${index + 1}`}
              key={`upNext-${item.__typename}-${item.id}`}
            />
          ) : (
            <QueueItem
              content={item}
              contentIndex={`${index + 1}`}
              key={`upNext-${item.__typename}-${item.id}`}
            />
          )
        )
      )}
    </VStack>
  );
}
