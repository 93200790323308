import * as React from 'react';
import { Flex, type FlexProps, type SpaceProps } from '@chakra-ui/react';
import { type TableBodyPropGetter, type TableBodyProps } from 'react-table';

import { type Row } from '../types';
import { StyledTableRowContainer, TableCell, TableRow } from '../styles';

const isOdd = (value: number) => value % 2 === 0;

interface TableBodyCustomProps<D extends object = {}> {
  rows: Row<D>[];
  prepareRow: (row: Row<D>) => void;
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<D> | undefined
  ) => TableBodyProps;
  emptyRowsCount?: number;
  // currently limiting it to two props
  customStylingForRows?: Pick<FlexProps, 'alignItems'> & Pick<SpaceProps, 'py'>;
}

export function TableBody<D extends object = {}>({
  rows,
  prepareRow,
  getTableBodyProps,
  emptyRowsCount = 0,
  customStylingForRows
}: TableBodyCustomProps<D>) {
  return (
    <StyledTableRowContainer
      {...getTableBodyProps()}
      emptyRowsCount={emptyRowsCount}
    >
      {rows?.map((row, index) => {
        prepareRow(row);

        return (
          <TableRow
            {...row.getRowProps()}
            px={2}
            {...customStylingForRows}
            data-cy="data-row"
            backgroundColor={isOdd(index) ? 'darkGrey.400' : 'darkGrey.300'}
          >
            {row.cells.map((cell) => (
              <TableCell {...cell.getCellProps()}>
                <Flex
                  width="100%"
                  justifyContent={cell.column.align || 'start'}
                >
                  {cell.render('Cell')}
                </Flex>
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </StyledTableRowContainer>
  );
}
