import { useCallback, useMemo } from 'react';
import {
  type GroupBase,
  type SingleValue,
  type Props as ReactSelectProps,
  Select as ReactSelect
} from 'chakra-react-select';
import * as segment from '@tigerhall/analytics';
import { Box, useColorMode } from '@chakra-ui/react';

import { getSelectChakraStyles, scrollbarStylesForSelect } from './theme';

interface Option {
  label: string;
  value: string;
}

export interface UncontrolledSelectProps {
  name: string;
  value?: Option;
  size?: ReactSelectProps['size'];
  options: Option[];
  placeholder?: string;
  onChange: (val: Option | null) => void;
}

/**
 *
 * @param name
 * @param value
 * @param options
 * @param placeholder
 * @param onChange
 * @constructor
 */
export function UncontrolledSelect({
  name,
  value,
  size,
  options,
  placeholder,
  onChange
}: UncontrolledSelectProps) {
  const { colorMode } = useColorMode();

  const chakraStyles = useMemo(
    () => getSelectChakraStyles<Option>(colorMode),
    [colorMode]
  );

  const onChangeWithSegmentEvent = useCallback(
    (val: SingleValue<Option>) => {
      segment.dropdownSelectionClicked({
        dropdownName: name,
        selection: val?.value,
        location: window.location.pathname
      });

      onChange(val);
    },
    [name, onChange]
  );

  return (
    <Box
      as={ReactSelect<Option, false, GroupBase<Option>>}
      name={name}
      size={size}
      placeholder={placeholder}
      onChange={onChangeWithSegmentEvent}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      value={value}
      options={options}
      chakraStyles={chakraStyles}
      css={scrollbarStylesForSelect}
    />
  );
}
