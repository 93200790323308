import * as React from 'react';
import { ButtonProps } from '@chakra-ui/react';
import { IconEdit, IconTrash } from '@tigerhall/icons';
import { THButton } from 'components/buttons/THButton';

import AddIcon from './AddIcon';

// eslint-disable-next-line no-shadow
export enum IconType {
  ADD = 'add',
  DELETE = 'delete',
  EDIT = 'edit'
}

export type Variant = 'outline' | 'ghost' | 'unstyled' | 'solid' | 'solidDark';

interface FormButtonProps extends ButtonProps {
  iconName?: IconType;
  rightIconName?: IconType;
  text: string;
  rightIcon?: any;
  iconColor?: string;
  variantColor?: string;
  dataCy?: string;
  'data-cy'?: string;
}

interface IconProps {
  iconName?: IconType;
  iconColor?: string;
}

const Icon = ({ iconName, iconColor }: IconProps) => {
  switch (iconName) {
    case IconType.ADD:
      return <AddIcon color={iconColor} />;
    case IconType.DELETE:
      return <IconTrash width={12} height={12} color={iconColor} />;
    case IconType.EDIT:
      return <IconEdit color={iconColor} />;
    default:
      return null;
  }
};

export default function Button({
  onClick,
  iconName,
  iconColor,
  text,
  rightIcon,
  rightIconName,
  dataCy,

  variantColor,
  size = 'sm',

  ...rest
}: FormButtonProps) {
  const leftIcon = iconName ? (
    <Icon iconName={iconName} iconColor={iconColor} />
  ) : undefined;

  const customRightIcon = rightIconName ? (
    <Icon iconName={rightIconName} iconColor={iconColor} />
  ) : undefined;

  return (
    <THButton
      onClick={onClick}
      size={size}
      leftIcon={leftIcon}
      rightIcon={rightIcon ?? customRightIcon}
      data-cy={dataCy}
      name={text}
      {...rest}
    >
      {text}
    </THButton>
  );
}
