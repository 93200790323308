import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
export type ExpertItemFragment = { __typename?: 'Expert', id: string, firstName: string, lastName: string, email: string, title: string, biography: string, isMentor: boolean, isAuthenticatedUserFollowing: boolean, mentorPriceText?: string | null, mentorAvailableText?: string | null, company?: string | null, updatedAt: any, image: { __typename?: 'Image', id: string, uri: string }, categories: Array<{ __typename?: 'Category', id: string, name: string }>, organisationGroups: Array<{ __typename?: 'OrganisationGroup', id: string, name: string }>, organisation?: { __typename?: 'Organisation', id: string, name: string } | null };

export const ExpertItemFragmentDoc = gql`
    fragment expertItem on Expert {
  id
  firstName
  lastName
  email
  title
  biography
  image {
    id
    uri
  }
  categories {
    id
    name
  }
  isMentor
  isAuthenticatedUserFollowing
  mentorPriceText
  mentorAvailableText
  organisationGroups {
    id
    name
  }
  company
  updatedAt
  organisation {
    id
    name
  }
}
    `;