import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import { ContentCard, ContentType } from '@tigerhall/core/lib/types';
import { useGetContentCardsLazyQuery } from 'generated';
import { addContentCards, getAllContentCards } from 'app/state';
import { useAppDispatch, useAppSelector } from 'hooks';

const EMPTY_ARRAY = [];

export default function useContent() {
  const [fetchContentCardsQuery, { data, loading, error }] =
    useGetContentCardsLazyQuery();

  const dispatch = useAppDispatch();

  const fetchContentCards = React.useCallback(
    (variables?: {
      categories?: string[];
      keywords?: string;
      types?: ContentType[];
      experts?: string[];
      includeDrafts?: boolean;
    }) => {
      fetchContentCardsQuery({
        variables: {
          filter: {
            includeDrafts: variables?.includeDrafts,
            keywords: isEmpty(variables?.keywords) ? '' : variables?.keywords,
            categories: variables?.categories,
            types: variables?.types,
            experts: variables?.experts
          }
        }
      });
    },
    [fetchContentCardsQuery]
  );

  const contentCards =
    (data?.contentCards?.edges as ContentCard[]) ?? EMPTY_ARRAY;

  useEffect(() => {
    dispatch(addContentCards(keyBy(contentCards, 'id')));
  }, [contentCards, dispatch]);

  return {
    // totalCount: data?.contentCards?.meta?.total ?? 0,
    totalCount: 0,
    contentCards: Object.values(contentCards),
    isFetchingContentCards: loading,
    error,
    fetchContentCards,
    totalContentCards: useAppSelector(getAllContentCards)
  };
}
