import {
  IconBrandFacebookColor,
  IconBrandLinkedinColor,
  IconWorldWww,
  IconBrandWhatsapp,
  IconBrandX
} from '@tigerhall/icons';

export type ShareList = {
  facebook: string;
  x: string;
  linkedin: string;
  whatsapp: string;
  fbmsg: string;
};

type ShareUrlsProps = {
  url: string;
  text?: string;
  twitterUsername?: string;
  appVersion?: boolean;
};

export const shareUrls = ({
  twitterUsername,
  url,
  text = '',
  appVersion = true
}: ShareUrlsProps): ShareList => {
  const encodedUrl = encodeURIComponent(url);
  const encodedText = encodeURIComponent(text);

  const facebook = `https://www.facebook.com/sharer.php?u=${url}`;

  const twitterVia = twitterUsername ? `&via=${twitterUsername}` : '';
  const twitter = `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}${twitterVia}`;

  const linkedin = `https://linkedin.com/shareArticle?url=${encodedUrl}`;

  const whatsappEncodedText = encodeURIComponent(`${text} ${url}`);
  const whatsappHost = appVersion ? 'whatsapp://' : 'https://web.whatsapp.com/';
  const whatsapp = `${whatsappHost}send?text=${whatsappEncodedText}`;

  const fbmsg = `fb-messenger://share/?link=${url}`;

  return {
    facebook,
    x: twitter,
    linkedin,
    whatsapp,
    fbmsg
  };
};

export const socialMediaList: (keyof ShareList)[] = [
  'linkedin',
  'x',
  'facebook'
];

export function getSocialMediaIcon(name: keyof ShareList) {
  switch (name) {
    case 'facebook':
      return IconBrandFacebookColor;
    case 'x':
      return IconBrandX;
    case 'linkedin':
      return IconBrandLinkedinColor;
    case 'whatsapp':
      return IconBrandWhatsapp;
    case 'fbmsg':
    default:
      return IconWorldWww;
  }
}
