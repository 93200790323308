import { useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import {
  ContentType,
  type Organisation,
  useMicrosoftTeams
} from '@tigerhall/core';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUnleashContext } from '@unleash/proxy-client-react';
import {
  getAccessToken,
  getCustomerType,
  getOnboardingStatus,
  getOrgIdFromToken,
  getPlayingContent,
  getSelectedOrg,
  setSelectedOrganisation
} from 'app/state';
import { useGetOrganisationByIdLazyQuery } from 'generated';
import {
  useAppDispatch,
  useAppSelector,
  useOnboardingFlow,
  useSubscriptionStatus
} from 'hooks';

import {
  FreeAccountInfo,
  HEADER_HEIGHT,
  Header,
  Sidebar,
  Splash,
  SubscriptionExpiredOverlay
} from './components';
import { ContentModalContainer } from '../ContentModalContainer';
import { ContentPlayer } from '../ContentPlayer';

function useProtectedRoute() {
  const location = useLocation();

  const PROTECTED_ROUTES = [
    '/admin',
    '/home',
    '/content',
    '/explore',
    '/profile',
    '/search',
    '/organization',
    '/feed'
  ];

  const isProtectedRoute = PROTECTED_ROUTES.some((route) =>
    location.pathname.includes(route)
  );

  return { isProtectedRoute };
}

function isHeaderVisible(isInTeams: boolean, isInMobile: boolean): boolean {
  return !isInTeams || isInMobile;
}

export function Layout() {
  const dispatch = useAppDispatch();
  const { loading } = useOnboardingFlow();

  const { isExpired } = useSubscriptionStatus();

  const isLoggedIn = !!useAppSelector(getAccessToken);
  const selectedOrg = useAppSelector(getSelectedOrg);

  const tokenOrgId = useAppSelector(getOrgIdFromToken);
  const customerType = useAppSelector(getCustomerType);
  const playingContent = useAppSelector(getPlayingContent);
  const updateUnleashContext = useUnleashContext();
  const isOnboarding = useAppSelector(getOnboardingStatus);
  const { isProtectedRoute } = useProtectedRoute();
  const { isInTeams, isInMobile } = useMicrosoftTeams();

  const isMinimizedPlayerOn =
    playingContent?.type === ContentType.Podcast ||
    playingContent?.type === ContentType.Ebook;
  const isIndividual = customerType === 'INDIVIDUAL';
  const headerHeight = isInTeams ? '0px' : HEADER_HEIGHT;

  const shouldShowSubscriptionExpiredEnterpriseOverlay =
    isExpired && !isIndividual;

  useEffect(() => {
    // feature flags context is updated using `organizationId` as `userId`
    // this is to filter the feature flags based on the organization, and not the user
    updateUnleashContext({ userId: tokenOrgId ?? undefined });
  }, [tokenOrgId, updateUnleashContext]);

  const [getOrganisationById] = useGetOrganisationByIdLazyQuery();

  // If the organisation has not yet been loaded we request it
  // and dispatch an update to set the selected organisation
  useEffect(() => {
    // If an org is already selected we don't need to re-fetch
    if (tokenOrgId && isLoggedIn && !selectedOrg && !isIndividual) {
      getOrganisationById({
        variables: {
          id: tokenOrgId
        }
      }).then(({ data }) =>
        dispatch(setSelectedOrganisation(data?.organisation as Organisation))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  if (isProtectedRoute && !isLoggedIn) {
    return <Navigate replace to="/login" />;
  }

  if (loading) {
    return <Splash />;
  }

  if (isProtectedRoute && isLoggedIn) {
    if (isOnboarding) {
      return <Navigate to="/signup/individual/welcome" />;
    }

    if (shouldShowSubscriptionExpiredEnterpriseOverlay) {
      return <SubscriptionExpiredOverlay />;
    }

    return (
      <>
        <Flex flexDirection="column">
          {isHeaderVisible(isInTeams, isInMobile) && <Header />}

          <Flex
            backgroundColor={isInTeams ? 'white' : 'darkGrey.700'}
            height={`calc(100vh - ${headerHeight} - ${
              isMinimizedPlayerOn ? '6.25rem' : '0px'
            })`}
            direction="row"
            overflowY="hidden"
          >
            <Sidebar />

            <Flex direction="column" overflowY="auto" flex={1}>
              {!isInTeams && <FreeAccountInfo />}
              <ContentModalContainer>
                <Outlet />
              </ContentModalContainer>
            </Flex>
          </Flex>
        </Flex>
        <ContentPlayer />
      </>
    );
  }

  // If this is not a protected route and the user is not logged in
  // then DON'T render the Layout
  return <Outlet />;
}
