import {
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useBreakpointValue,
  useDisclosure,
  useOutsideClick
} from '@chakra-ui/react';
import { IconMoodPlus } from '@tigerhall/icons';
import * as segment from '@tigerhall/analytics';
import { type EmojiClickData, EmojiStyle, Theme } from 'emoji-picker-react';
import { Suspense, lazy, useRef } from 'react';

const EmojiPicker = lazy(() => import('emoji-picker-react'));

export interface EmojisPopoverProps {
  onEmojiClick: (data: EmojiClickData, event: MouseEvent) => void;
}

export function EmojisPopover({ onEmojiClick }: Readonly<EmojisPopoverProps>) {
  const ref = useRef<HTMLDivElement>(null);

  const isMobile = useBreakpointValue({ base: true, lg: false });

  const { onClose, onOpen, isOpen } = useDisclosure();

  /**
   * Close the popover when the user clicks outside of it.
   *
   * We use instead this of the `closeOnBlur` prop in the `Popover` component
   * because the `emoji-picker-react` internally plays with the focus
   * and the `closeOnBlur` prop doesn't work as expected, the popover
   * closes when the user hovers across different sections of the popover.
   */
  useOutsideClick({
    ref,
    enabled: isOpen,
    handler: () => {
      onClose();
    }
  });

  // Mobile users have access to the native emojis picker via the keyboard.
  if (isMobile) {
    return null;
  }

  /**
   * This `ref` in a `div` wrapping the `Popover` component is intentional.
   *
   * If we use the `ref` in the `PopoverContent` component, we can't close
   * the popover clicking the `IconButton` because the `useOutsideClick` hook
   * is triggered at the same time (because we click out of the PopoverContent),
   * causing the popover to close and open at the same time.
   */
  return (
    <div ref={ref}>
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        placement="top-start"
        strategy="fixed"
        gutter={40}
        closeOnBlur={false}
        returnFocusOnClose={false}
        isLazy
      >
        <PopoverTrigger>
          <IconButton
            aria-label="emojis"
            icon={<Icon as={IconMoodPlus} />}
            variant="ghost"
            size="sm"
            onClick={() => {
              if (isOpen) {
                onClose();
              } else {
                onOpen();
                segment.emojiPickerOpened({});
              }
            }}
          />
        </PopoverTrigger>
        <PopoverContent
          borderWidth={0}
          borderRadius="lg"
          backgroundColor="darkGrey.700"
          overflow="hidden"
          width={{
            base: '100%',
            md: '24rem'
          }}
        >
          <Suspense fallback={null}>
            <EmojiPicker
              /**
               * We use the `4.0` version because it's the version with apparently
               * better support for the `native` emojis in macOS.
               *
               * Today the most recent version is `5.0`, but not all emojis are supported.
               */
              emojiVersion="4.0"
              onEmojiClick={(data, event) => {
                onEmojiClick(data, event);

                segment.emojiPickerSelection({
                  emoji: data.emoji,
                  name: data.names[0],
                  isCustom: data.isCustom
                });
              }}
              width="100%"
              theme={Theme.DARK}
              emojiStyle={EmojiStyle.NATIVE}
              /**
               * This is disabled because adding support for skin tones here also implies that
               * we need to add support for skin tones in the input field, which is a separate
               * feature issue to deal with because we might need to use the `<Emoji />` component
               * provided by the `emoji-picker-react` library, and it might not be as easy as it sounds.
               */
              skinTonesDisabled
            />
          </Suspense>
        </PopoverContent>
      </Popover>
    </div>
  );
}
