import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  Text
} from '@chakra-ui/react';
import { IconEye, IconEyeOff } from '@tigerhall/icons';
import { useState } from 'react';
import {
  Controller,
  type UseControllerProps,
  type UseFormReturn,
  useFormContext
} from 'react-hook-form';

interface FormPasswordProps {
  placeholder: string;
  label: string;
  rules: UseControllerProps['rules'];
  name: string;
  autoComplete?: 'new-password' | 'current-password' | 'off';
  analyticsDisabled?: boolean;
  disabled?: boolean;
}

export function FormPassword({
  placeholder,
  label,
  rules,
  name,
  autoComplete,
  analyticsDisabled,
  disabled = false
}: FormPasswordProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const methods = useFormContext() as UseFormReturn & {
    formName: string;
  };

  if (methods === null) {
    return <Text color="state.error">Missing form context</Text>;
  }

  if (!methods.formName && !analyticsDisabled) {
    return <Text color="state.error">Missing form name</Text>;
  }

  const handlePasswordIcon = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      defaultValue={null}
      render={({ field, fieldState }) => (
        <FormControl
          isInvalid={!!fieldState.error}
          isRequired={!!rules?.required}
          isDisabled={disabled}
        >
          <Flex
            w="100%"
            position="relative"
            flexDir="column"
            alignItems="flex-start"
          >
            {label ? <FormLabel>{label}</FormLabel> : null}
            <InputGroup>
              <Input
                name={field.name}
                type={isPasswordVisible ? 'text' : 'password'}
                autoComplete={autoComplete}
                placeholder={placeholder}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                ref={field.ref}
                data-cy={`${methods.formName}-${name}`}
              />
            </InputGroup>
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            <IconButton
              aria-label={isPasswordVisible ? 'hide password' : 'show password'}
              icon={isPasswordVisible ? <IconEye /> : <IconEyeOff />}
              color="lightGrey.900"
              position="absolute"
              right="0.75rem"
              top="2.2rem"
              minH="1.5rem"
              minW="1.5rem"
              backgroundColor="transparent"
              _hover={{
                bg: 'none'
              }}
              variant="unstyled"
              onClick={handlePasswordIcon}
            />
          </Flex>
        </FormControl>
      )}
    />
  );
}
