import { devtools } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import { MediaState } from '../utils/const';

export interface MediaStateStore extends MediaState {
  setIsPlaying: (isPlaying: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setProgress: (progress: number) => void;
  setDuration: (duration: number) => void;
  setCustomDuration: (customDuration: number) => void;
  setDidJustSeek: (progress: number) => void;
  setPlaybackRate: (playbackRate: number) => void;
  setVolume: (volume: number) => void;

  getProgress: () => number;

  reset: () => void;
}

export const useMediaState = createWithEqualityFn<MediaStateStore>()(
  devtools(
    (set, get) => ({
      isPlaying: false,
      isLoading: true,
      progress: 0,
      // this `undefined` is important. Don't change it to `0` because
      // `useMediaAnalytics` will not track that the content finished if this is `0`.
      customDuration: undefined,
      duration: 0,
      didJustSeek: false,
      playbackRate: 1,
      volume: 1,

      getProgress: () => get().progress,

      setIsPlaying: (isPlaying: boolean) =>
        set(() => ({ isPlaying, didJustSeek: false })),
      setIsLoading: (isLoading: boolean) =>
        set(() => ({ isLoading, didJustSeek: false })),
      setProgress: (progress: number) =>
        set(() => ({ progress, didJustSeek: false })),
      setDuration: (duration: number) =>
        set(() => ({ duration, didJustSeek: false })),
      setCustomDuration: (customDuration: number) =>
        set(() => ({ customDuration, didJustSeek: false })),
      setDidJustSeek: (progress: number) =>
        set(() => ({ progress, didJustSeek: true })),
      setPlaybackRate: (playbackRate: number) =>
        set(() => ({ playbackRate, didJustSeek: false })),
      setVolume: (volume: number) =>
        set(() => ({ volume, didJustSeek: false })),

      reset: () =>
        // we want to keep the selected `playbackRate` and `volume` and not reset them
        set(() => ({
          isPlaying: false,
          isLoading: true,
          progress: 0,
          customDuration: undefined,
          duration: 0,
          didJustSeek: false
        }))
    }),

    {
      enabled: true,
      name: 'media-store'
    }
  ),
  shallow
);
