import { defineStyleConfig as t, defineStyle as a } from "./index33.mjs";
import { cssVar as r } from "./index34.mjs";
const e = r("tooltip-bg"), o = r("tooltip-fg"), i = r("popper-arrow-bg"), l = a({
  bg: e.reference,
  color: o.reference,
  [e.variable]: "colors.darkGrey.200",
  [o.variable]: "colors.whiteAlpha.900",
  _dark: {
    [e.variable]: "colors.lightGrey.400",
    [o.variable]: "colors.darkGrey.700"
  },
  [i.variable]: e.reference,
  px: "0.75rem",
  py: "0.5rem",
  borderRadius: "lg",
  fontWeight: "medium",
  fontSize: "xs",
  boxShadow: "md",
  maxW: "xs",
  zIndex: "tooltip"
}), n = t({
  baseStyle: l
});
export {
  n as Tooltip
};
