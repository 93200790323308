import * as React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { Splash } from 'components/ui/Layout/components';

const OrganisationContent = React.lazy(() =>
  import('./screens/OrganisationContent').then((module) => ({
    default: module.OrganisationContent
  }))
);

const PeopleToFollow = React.lazy(() =>
  import('./screens/PeopleToFollow').then((module) => ({
    default: module.PeopleToFollow
  }))
);

const MyAssignments = React.lazy(() =>
  import('./screens/MyAssignments').then((module) => ({
    default: module.MyAssignments
  }))
);

const routes: RouteObject[] = [
  {
    // redirect to new route
    path: '/from-your-org',
    element: <Navigate to="/organization/people" replace />
  },
  {
    path: '/organization',
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<Splash />}>
            <OrganisationContent />
          </React.Suspense>
        )
      },
      {
        path: 'people',
        element: (
          <React.Suspense fallback={<Splash />}>
            <PeopleToFollow />
          </React.Suspense>
        )
      },
      {
        path: 'my-assignments',
        element: <Navigate to={'/organization/assignments'} replace />
      },
      {
        path: 'assignments',
        element: (
          <React.Suspense fallback={<Splash />}>
            <MyAssignments />
          </React.Suspense>
        )
      }
    ]
  }
];

export default routes;
