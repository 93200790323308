import merge from 'lodash/merge';
import { ContentCard } from '@tigerhall/core/lib/types';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/reducer';

// ------------------------------------
// Reducer
// ------------------------------------

interface State {
  entities: { [pathId: string]: ContentCard };
}

const initialState: State = {
  entities: {}
};

export const contentSlice = createSlice({
  name: 'content',
  initialState: initialState,
  reducers: {
    addContentCards: (state, action) => {
      state.entities = merge({}, state.entities, action.payload);
    }
  }
});

export const { addContentCards } = contentSlice.actions;

// ------------------------------------
// Selectors
// ------------------------------------
export const getContentCard = (
  state: {
    content: State;
  },
  id: string
): ContentCard | null => state.content.entities[id] ?? null;

export const getContentCards = (
  state: RootState,
  ids: string[]
): (ContentCard | null)[] => ids.map((id) => getContentCard(state, id));

export const getAllContentCards = (state: { content: State }) =>
  Object.values(state?.content?.entities);
