import React from 'react';
import { Icon, Tooltip } from '@chakra-ui/react';

type ToolTipProps = {
  label: string;
  placement?: React.ComponentProps<typeof Tooltip>['placement'];
};

export default function ToolTip({ label, placement = 'auto' }: ToolTipProps) {
  return (
    <Tooltip
      label={label}
      aria-label={label}
      placement={placement}
      color="black"
      background="white"
      p={6}
      borderRadius={8}
      fontWeight={400}
      fontSize={14}
    >
      <Icon name="info-outline" color="orange" />
    </Tooltip>
  );
}
