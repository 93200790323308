import { Divider, Stack } from '@chakra-ui/react';
import { type User } from '@tigerhall/core/lib/types';

import { ProfileInfo } from '~/modules/application/modules/Profile/screens/Profile/components/ProfileInfo';
import { ProfileSocialLinks } from '~/modules/application/modules/Profile/screens/Profile/components/ProfileSocialLinks';
import { AboutSection } from '~/modules/application/modules/Profile/components/AboutSection';
import Statistics from '~/modules/application/modules/Profile/components/Statistics';
import { ProfileTabs } from '~/modules/application/modules/Profile/screens/Profile/components/ProfileTabs';

interface ProfileDetailsProps {
  isCurrentUser: boolean;
  user: User;
}
export function ProfileDetails({
  isCurrentUser,
  user
}: Readonly<ProfileDetailsProps>) {
  const hasSocialProfiles = Boolean(
    user?.facebookLink ||
      user?.twitterHandle ||
      user?.instagramHandle ||
      user?.linkedinLink ||
      user?.websiteLink
  );

  const userSocialLinks = {
    facebookLink: user?.facebookLink,
    twitterHandle: user?.twitterHandle,
    instagramHandle: user?.instagramHandle,
    linkedinLink: user?.linkedinLink,
    websiteLink: user?.websiteLink
  };

  return (
    <>
      <Stack spacing={6} px={{ base: '1rem', md: '2rem' }} w="full">
        <ProfileInfo user={user} isCurrentUser={isCurrentUser} />
        <Divider borderColor="darkGrey.300" />
        {hasSocialProfiles ? (
          <ProfileSocialLinks userSocialLinks={userSocialLinks} />
        ) : null}
        {user?.biography ? (
          <AboutSection
            aboutContent={user?.biography || ''}
            shouldApplyShowMore={false}
          />
        ) : null}
        <Statistics user={user} isCurrentUser={isCurrentUser} />
      </Stack>
      <ProfileTabs user={user} isCurrentUser={isCurrentUser} />
    </>
  );
}
