import {
  Avatar,
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resizeImage } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import {
  logout,
  setOnboardingToInnitialState,
  setSelectedOrganisation
} from 'app/state';
import { useGetCurrentUserQuery } from 'generated';
import { IconLogout, IconSettings, IconUser } from '@tigerhall/icons';
import { useAppDispatch } from 'hooks';
import { clearCache } from 'api/apollo';
import { captureException } from '@sentry/react';

export function ProfileMenu() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first'
  });

  const { pathname } = useLocation();

  async function handleLogout() {
    try {
      segment.buttonClicked({
        buttonName: 'logout',
        location: pathname
      });

      dispatch(setOnboardingToInnitialState());
      dispatch(setSelectedOrganisation(null));
      dispatch(logout());

      await clearCache();
    } catch (err) {
      captureException(new Error('Error occurred on logout button click'), {
        extra: {
          err
        }
      });
    }
  }

  return (
    <Box pr="0.5rem" ml="1.5rem" zIndex={10}>
      <Menu>
        <MenuButton aria-label="Profile menu">
          <Avatar
            width="2.5rem"
            height="2.5rem"
            name={data?.me?.firstName || ''}
            backgroundColor="tigerOrange.800"
            border="2px solid"
            id="profile-avatar"
            borderColor="lightGrey.200"
            src={
              data?.me?.avatarImage?.uri
                ? resizeImage({
                    url: data?.me?.avatarImage?.uri,
                    width: 40
                  })
                : ''
            }
          />
        </MenuButton>
        <MenuList gap="0.25rem" minW="11rem" borderColor="darkGrey.200">
          <MenuItem
            icon={
              <Icon
                as={IconUser}
                color="lightGrey.400"
                width="1rem"
                height="1rem"
              />
            }
            onClick={() => navigate('profile')}
          >
            My Profile
          </MenuItem>
          <MenuItem
            icon={
              <Icon
                as={IconSettings}
                color="lightGrey.400"
                width="1rem"
                height="1rem"
              />
            }
            onClick={() => navigate('/profile/settings')}
          >
            Account Settings
          </MenuItem>
          <MenuItem
            icon={
              <Icon
                as={IconLogout}
                color="lightGrey.400"
                width="1rem"
                height="1rem"
              />
            }
            onClick={handleLogout}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}
