import { Divider, HStack, Text } from '@chakra-ui/layout';

import { FreeTrialEmailForm } from './FreeTrialEmailForm';
import { SocialLogins } from './SocialLogins';

export function EmailAndSocialLoginForm() {
  return (
    <>
      <FreeTrialEmailForm />
      <HStack align="center" w="100%">
        <Divider borderColor="lightGrey.900" orientation="horizontal" />
        <Text fontSize="sm" color="lightGrey.900">
          OR
        </Text>
        <Divider borderColor="lightGrey.900" orientation="horizontal" />
      </HStack>
      <SocialLogins />
    </>
  );
}
