import { Circle, Flex, Text } from '@chakra-ui/react';
import { type ReactionCount, compactNumber } from '@tigerhall/core';

export interface ReactionsCountProps {
  commentId: string;
  reactions: Pick<ReactionCount, 'emoji'>[];
  count: number;
}

/**
 * @todo This will be a link that opens the reactions screen.
 * Will be done in Post Improvements.
 * Remember to add the segment event onClick.
 */
export function ReactionsCount({ reactions, count }: ReactionsCountProps) {
  if (count === 0) {
    return null;
  }

  const compactCount = compactNumber(count);

  const emojisList = reactions.map((reaction) => reaction.emoji);
  const emoijs = emojisList.slice(0, 3).join('');

  return (
    <>
      <Circle size="0.125rem" bg="lightGrey.900" />
      <Flex alignItems="center">
        <Text as="span" fontSize="sm" color="lightGrey.200">
          {compactCount}&nbsp;
        </Text>
        <Text as="span" fontSize="sm">
          {emoijs}
        </Text>
      </Flex>
    </>
  );
}
