import {
  Box,
  type BoxProps,
  type ButtonProps,
  Heading,
  Icon,
  type IconProps,
  LightMode,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToken
} from '@chakra-ui/react';
import { TrackedButton } from '@tigerhall/components';
import {
  IconAlertTriangle,
  IconCircleCheck,
  IconExclamationCircle,
  IconHelpCircle,
  IconInfoCircle
} from '@tigerhall/icons';
import isUndefined from 'lodash/isUndefined';
import { type ReactNode } from 'react';
import { fromHexToOpacity50 } from 'utils';

interface ModalButtonArgs extends ButtonProps {
  text: string;
  onClick: () => void;
}

interface FooterButtonsProps {
  actionButton: ModalButtonArgs;
  cancelButton?: ModalButtonArgs;
}

interface PromptModalProps extends FooterButtonsProps {
  type: 'success' | 'info' | 'confirmation' | 'warning' | 'error';
  isOpen: boolean;
  heading: string;
  children?: ReactNode;
  onClose(): void;
}

function ModalIconType({ type }: Readonly<Pick<PromptModalProps, 'type'>>) {
  const defaultBoxProps: BoxProps = {
    display: 'inline-flex',
    borderRadius: '2xl',
    p: 4,
    mb: 4
  };

  const defaultIconProps: IconProps = {
    boxSize: 10
  };

  const [green100, blue100, tigerOrange200, red100] = useToken('colors', [
    'green.100',
    'blue.100',
    'tigerOrange.200',
    'red.100'
  ]);

  switch (type) {
    case 'success':
      return (
        <Box {...defaultBoxProps} bg={fromHexToOpacity50(green100)}>
          <Icon {...defaultIconProps} as={IconCircleCheck} color="green.400" />
        </Box>
      );
    case 'info':
      return (
        <Box {...defaultBoxProps} bg={fromHexToOpacity50(blue100)}>
          <Icon {...defaultIconProps} as={IconInfoCircle} color="blue.400" />
        </Box>
      );

    case 'confirmation':
      return (
        <Box {...defaultBoxProps} bg={fromHexToOpacity50(tigerOrange200)}>
          <Icon
            {...defaultIconProps}
            as={IconHelpCircle}
            color="tigerOrange.600"
          />
        </Box>
      );
    case 'warning':
      return (
        <Box {...defaultBoxProps} bg={'kindaYellow.100'}>
          <Icon
            {...defaultIconProps}
            as={IconAlertTriangle}
            color="kindaYellow.600"
          />
        </Box>
      );
    case 'error':
      return (
        <Box {...defaultBoxProps} bg={fromHexToOpacity50(red100)}>
          <Icon
            {...defaultIconProps}
            as={IconExclamationCircle}
            color="red.400"
          />
        </Box>
      );
    default:
      return null;
  }
}

function FooterButtons({
  actionButton,
  cancelButton
}: Readonly<FooterButtonsProps>) {
  if (isUndefined(cancelButton)) {
    const { text, onClick, ...rest } = actionButton;
    return (
      <TrackedButton
        name={text}
        variant="solidDark"
        size="lg"
        w="full"
        onClick={onClick}
        {...rest}
      >
        {text}
      </TrackedButton>
    );
  }

  const {
    text: actionText,
    onClick: onActionClick,
    ...actionButtonProps
  } = actionButton;

  const {
    text: cancelText,
    onClick: onCancelClick,
    ...cancelButtonProps
  } = cancelButton;

  return (
    <>
      <TrackedButton
        name={cancelText}
        variant="ghost"
        size="lg"
        onClick={onCancelClick}
        {...cancelButtonProps}
      >
        {cancelText}
      </TrackedButton>
      <TrackedButton
        name={actionText}
        variant="solidDark"
        size="lg"
        onClick={onActionClick}
        {...actionButtonProps}
      >
        {actionButton.text}
      </TrackedButton>
    </>
  );
}

/**
 *
 * @example```tsx
 * <PromptModal
          isOpen
          actionButton={{ text: 'hello', onClick: () => {} }}
          type="warning"
          heading="hello"
          onClose={() => {}}
        >
          <Text fontSize="sm">
            Some Random Text
          </Text>
    </PromptModal>
 * ```
 */

export function PromptModal({
  type,
  heading,
  children,
  isOpen,
  actionButton,
  cancelButton,
  onClose
}: Readonly<PromptModalProps>) {
  return (
    <LightMode>
      <Modal isOpen={isOpen} onClose={onClose} isCentered variant="prompt">
        <ModalOverlay />
        <ModalContent borderRadius={'lg'} maxW={'25rem'}>
          <ModalHeader>
            <ModalIconType type={type} />
            <Heading color="darkGrey.400" fontWeight="bold" fontSize={'lg'}>
              {heading}
            </Heading>
          </ModalHeader>
          <ModalBody pt={0} pb={6}>
            {children}
          </ModalBody>
          <ModalFooter
            borderTopWidth={1}
            borderColor="lightGrey.400"
            justifyContent="space-between"
          >
            <FooterButtons
              actionButton={actionButton}
              cancelButton={cancelButton}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </LightMode>
  );
}
