import { FeedItemContainer } from '../FeedItemContainer';
import {
  PostBodySkeleton,
  type PostBodySkeletonProps
} from './components/PostBody';

export interface PostSkeletonProps extends PostBodySkeletonProps {}

export function PostSkeleton({ hasComments }: PostSkeletonProps) {
  return (
    <FeedItemContainer>
      <PostBodySkeleton hasComments={hasComments} />
    </FeedItemContainer>
  );
}
