let c = () => window.analytics;
function i(n) {
  c = n.analytics ? () => n.analytics || window.analytics : c;
}
function r(n = {}) {
  return {
    ...n,
    context: {
      ...n.context || {},
      typewriter: {
        language: "typescript",
        version: "9.1.0"
      }
    }
  };
}
function a(n, t, o) {
  const e = c();
  e && e.track("Page Viewed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function s(n, t, o) {
  const e = c();
  e && e.track("Segment Consent Preference Updated", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function l(n, t, o) {
  const e = c();
  e && e.track("access_level_created", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function d(n, t, o) {
  const e = c();
  e && e.track("access_level_deleted", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function p(n, t, o) {
  const e = c();
  e && e.track("access_level_updated", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function u(n, t, o) {
  const e = c();
  e && e.track("account_created", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function g(n, t, o) {
  const e = c();
  e && e.track("account_deleted", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function k(n, t, o) {
  const e = c();
  e && e.track("add_note_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function v(n, t, o) {
  const e = c();
  e && e.track("add_to_calendar", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function y(n, t, o) {
  const e = c();
  e && e.track("add_to_schedule", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function x(n, t, o) {
  const e = c();
  e && e.track("admin_export_statistics", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function _(n, t, o) {
  const e = c();
  e && e.track("assignment_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function f(n, t, o) {
  const e = c();
  e && e.track("assignment_created", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function w(n, t, o) {
  const e = c();
  e && e.track("assignment_deleted", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function C(n, t, o) {
  const e = c();
  e && e.track("assignment_finished", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function m(n, t, o) {
  const e = c();
  e && e.track("assignment_made_inactive", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function h(n, t, o) {
  const e = c();
  e && e.track("assignment_started", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function b(n, t, o) {
  const e = c();
  e && e.track("attempted_to_consume_content_over_freemium_limit", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function S(n, t, o) {
  const e = c();
  e && e.track("book_a_spot_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function P(n, t, o) {
  const e = c();
  e && e.track("bookmark_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function T(n, t, o) {
  const e = c();
  e && e.track("button_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function O(n, t, o) {
  const e = c();
  e && e.track("canceled_button_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function B(n, t, o) {
  const e = c();
  e && e.track("card_swiped", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function F(n, t, o) {
  const e = c();
  e && e.track("carousel_changed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function A(n, t, o) {
  const e = c();
  e && e.track("carousel_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function U(n, t, o) {
  const e = c();
  e && e.track("clear_cache_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function L(n, t, o) {
  const e = c();
  e && e.track("comment_button_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function R(n, t, o) {
  const e = c();
  e && e.track("comment_deleted_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function E(n, t, o) {
  const e = c();
  e && e.track("comment_options_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function D(n, t, o) {
  const e = c();
  e && e.track("comment_reply_button_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function V(n, t, o) {
  const e = c();
  e && e.track("comment_sent", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function I(n, t, o) {
  const e = c();
  e && e.track("confirmation_button_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function M(n, t, o) {
  const e = c();
  e && e.track("content_autoplayed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function j(n, t, o) {
  const e = c();
  e && e.track("content_card_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function J(n, t, o) {
  const e = c();
  e && e.track("content_consumed_at_interval", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Y(n, t, o) {
  const e = c();
  e && e.track("content_finished", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function N(n, t, o) {
  const e = c();
  e && e.track("content_playback_state_changed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function H(n, t, o) {
  const e = c();
  e && e.track("content_position_seek", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function W(n, t, o) {
  const e = c();
  e && e.track("content_preview_completed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function $(n, t, o) {
  const e = c();
  e && e.track("content_searched", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function z(n, t, o) {
  const e = c();
  e && e.track("content_started", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function q(n, t, o) {
  const e = c();
  e && e.track("delete_account_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function G(n, t, o) {
  const e = c();
  e && e.track("drag_drop_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function K(n, t, o) {
  const e = c();
  e && e.track("dropdown_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Q(n, t, o) {
  const e = c();
  e && e.track("dropdown_selection_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function X(n, t, o) {
  const e = c();
  e && e.track("edit_email_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Z(n, t, o) {
  const e = c();
  e && e.track("emoji_picker_opened", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function tt(n, t, o) {
  const e = c();
  e && e.track("emoji_picker_selection", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function et(n, t, o) {
  const e = c();
  e && e.track("enterprise_overlay", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function nt(n, t, o) {
  const e = c();
  e && e.track("event_attended", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ot(n, t, o) {
  const e = c();
  e && e.track("event_booked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ct(n, t, o) {
  const e = c();
  e && e.track("feed_post_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function it(n, t, o) {
  const e = c();
  e && e.track("feed_post_impression", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function rt(n, t, o) {
  const e = c();
  e && e.track("feed_post_started", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function at(n, t, o) {
  const e = c();
  e && e.track("fetch_more", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function st(n, t, o) {
  const e = c();
  e && e.track("file_upload_selected", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function lt(n, t, o) {
  const e = c();
  e && e.track("follow_button_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function dt(n, t, o) {
  const e = c();
  e && e.track("footer_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function pt(n, t, o) {
  const e = c();
  e && e.track("form_field_filled", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ut(n, t, o) {
  const e = c();
  e && e.track("form_submitted", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function gt(n, t, o) {
  const e = c();
  e && e.track("form_viewed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function kt(n, t, o) {
  const e = c();
  e && e.track("free_content_count", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function vt(n, t, o) {
  const e = c();
  e && e.track("free_content_limit_reached", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function yt(n, t, o) {
  const e = c();
  e && e.track("invited_to_join_organisation", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function xt(n, t, o) {
  const e = c();
  e && e.track("language_changed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function _t(n, t, o) {
  const e = c();
  e && e.track("like_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ft(n, t, o) {
  const e = c();
  e && e.track("live_event_ended_screen", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function wt(n, t, o) {
  const e = c();
  e && e.track("livestream_joined", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Ct(n, t, o) {
  const e = c();
  e && e.track("livestream_message_sent", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function mt(n, t, o) {
  const e = c();
  e && e.track("login", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ht(n, t, o) {
  const e = c();
  e && e.track("logout", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function bt(n, t, o) {
  const e = c();
  e && e.track("marketing_video_completed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function St(n, t, o) {
  const e = c();
  e && e.track("marketing_video_played", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Pt(n, t, o) {
  const e = c();
  e && e.track("mention_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Tt(n, t, o) {
  const e = c();
  e && e.track("mention_finished", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Ot(n, t, o) {
  const e = c();
  e && e.track("mention_removed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Bt(n, t, o) {
  const e = c();
  e && e.track("mention_started", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Ft(n, t, o) {
  const e = c();
  e && e.track("nav_bar_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function At(n, t, o) {
  const e = c();
  e && e.track("nav_button_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Ut(n, t, o) {
  const e = c();
  e && e.track("new_invitation_accepted", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Lt(n, t, o) {
  const e = c();
  e && e.track("new_notifications_count", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Rt(n, t, o) {
  const e = c();
  e && e.track("new_registered_b2c_user", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Et(n, t, o) {
  const e = c();
  e && e.track("newsletter_subscribed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Dt(n, t, o) {
  const e = c();
  e && e.track("note_cancel_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Vt(n, t, o) {
  const e = c();
  e && e.track("note_close_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function It(n, t, o) {
  const e = c();
  e && e.track("note_delete_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Mt(n, t, o) {
  const e = c();
  e && e.track("note_edit_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function jt(n, t, o) {
  const e = c();
  e && e.track("note_saved_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Jt(n, t, o) {
  const e = c();
  e && e.track("note_updated", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Yt(n, t, o) {
  const e = c();
  e && e.track("notes_button_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Nt(n, t, o) {
  const e = c();
  e && e.track("notification_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Ht(n, t, o) {
  const e = c();
  e && e.track("onboarding_completed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Wt(n, t, o) {
  const e = c();
  e && e.track("organisation_content_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function $t(n, t, o) {
  const e = c();
  e && e.track("poll_vote_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function zt(n, t, o) {
  const e = c();
  e && e.track("post_audience_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function qt(n, t, o) {
  const e = c();
  e && e.track("post_audience_selected", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Gt(n, t, o) {
  const e = c();
  e && e.track("post_created", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Kt(n, t, o) {
  const e = c();
  e && e.track("post_options_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Qt(n, t, o) {
  const e = c();
  e && e.track("product_tour_completed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Xt(n, t, o) {
  const e = c();
  e && e.track("product_tour_started", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Zt(n, t, o) {
  const e = c();
  e && e.track("profile_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function te(n, t, o) {
  const e = c();
  e && e.track("profile_updated", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ee(n, t, o) {
  const e = c();
  e && e.track("promoted_to_thinkfluencer", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ne(n, t, o) {
  const e = c();
  e && e.track("pulled_to_refresh", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function oe(n, t, o) {
  const e = c();
  e && e.track("reaction_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ce(n, t, o) {
  const e = c();
  e && e.track("reactions_opened", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ie(n, t, o) {
  const e = c();
  e && e.track("repost_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function re(n, t, o) {
  const e = c();
  e && e.track("repost_created", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ae(n, t, o) {
  const e = c();
  e && e.track("repost_with_your_thoughts_created", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function se(n, t, o) {
  const e = c();
  e && e.track("search", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function le(n, t, o) {
  const e = c();
  e && e.track("share_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function de(n, t, o) {
  const e = c();
  e && e.track("share_externally_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function pe(n, t, o) {
  const e = c();
  e && e.track("share_to_feed_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ue(n, t, o) {
  const e = c();
  e && e.track("shared_content", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ge(n, t, o) {
  const e = c();
  e && e.track("sign_out_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ke(n, t, o) {
  const e = c();
  e && e.track("slider_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ve(n, t, o) {
  const e = c();
  e && e.track("story_completed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function ye(n, t, o) {
  const e = c();
  e && e.track("story_started", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function xe(n, t, o) {
  const e = c();
  e && e.track("subscription_cancelled", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function _e(n, t, o) {
  const e = c();
  e && e.track("subscription_changed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function fe(n, t, o) {
  const e = c();
  e && e.track("subscription_started", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function we(n, t, o) {
  const e = c();
  e && e.track("support_email_link_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Ce(n, t, o) {
  const e = c();
  e && e.track("support_help_centre_link_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function me(n, t, o) {
  const e = c();
  e && e.track("tab_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function he(n, t, o) {
  const e = c();
  e && e.track("table_row_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function be(n, t, o) {
  const e = c();
  e && e.track("table_sort_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Se(n, t, o) {
  const e = c();
  e && e.track("thinkfluencer_first_content_published", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Pe(n, t, o) {
  const e = c();
  e && e.track("thinkfluencer_for_one_year", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Te(n, t, o) {
  const e = c();
  e && e.track("time_on_screen", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Oe(n, t, o) {
  const e = c();
  e && e.track("toggle_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Be(n, t, o) {
  const e = c();
  e && e.track("toggle_orientation_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Fe(n, t, o) {
  const e = c();
  e && e.track("topic_button_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Ae(n, t, o) {
  const e = c();
  e && e.track("topic_toggle_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Ue(n, t, o) {
  const e = c();
  e && e.track("trail_completion_info", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Le(n, t, o) {
  const e = c();
  e && e.track("trail_finished", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Re(n, t, o) {
  const e = c();
  e && e.track("user_access_level_changed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Ee(n, t, o) {
  const e = c();
  e && e.track("user_created", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function De(n, t, o) {
  const e = c();
  e && e.track("user_mentioned", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Ve(n, t, o) {
  const e = c();
  e && e.track("version_update_prompt_displayed", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Ie(n, t, o) {
  const e = c();
  e && e.track("version_updated", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Me(n, t, o) {
  const e = c();
  e && e.track("view_reactions_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function je(n, t, o) {
  const e = c();
  e && e.track("view_reposts_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Je(n, t, o) {
  const e = c();
  e && e.track("web_vitals", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
function Ye(n, t, o) {
  const e = c();
  e && e.track("website_like_clicked", n || {}, { ...t, context: {
    ...(t == null ? void 0 : t.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, o);
}
const Ne = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions: i,
  /**
   * Fires a 'PageViewed' track call.
   *
   * @param PageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pageViewed: a,
  /**
   * Fires a 'SegmentConsentPreferenceUpdated' track call.
   *
   * @param SegmentConsentPreferenceUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  segmentConsentPreferenceUpdated: s,
  /**
   * Fires a 'AccessLevelCreated' track call.
   *
   * @param AccessLevelCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accessLevelCreated: l,
  /**
   * Fires a 'AccessLevelDeleted' track call.
   *
   * @param AccessLevelDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accessLevelDeleted: d,
  /**
   * Fires a 'AccessLevelUpdated' track call.
   *
   * @param AccessLevelUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accessLevelUpdated: p,
  /**
   * Fires a 'AccountCreated' track call.
   *
   * @param AccountCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accountCreated: u,
  /**
   * Fires a 'AccountDeleted' track call.
   *
   * @param AccountDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accountDeleted: g,
  /**
   * Fires a 'AddNoteClicked' track call.
   *
   * @param AddNoteClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  addNoteClicked: k,
  /**
   * Fires a 'AddToCalendar' track call.
   *
   * @param AddToCalendar props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  addToCalendar: v,
  /**
   * Fires a 'AddToSchedule' track call.
   *
   * @param AddToSchedule props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  addToSchedule: y,
  /**
   * Fires a 'AdminExportStatistics' track call.
   *
   * @param AdminExportStatistics props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  adminExportStatistics: x,
  /**
   * Fires a 'AssignmentClicked' track call.
   *
   * @param AssignmentClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignmentClicked: _,
  /**
   * Fires a 'AssignmentCreated' track call.
   *
   * @param AssignmentCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignmentCreated: f,
  /**
   * Fires a 'AssignmentDeleted' track call.
   *
   * @param AssignmentDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignmentDeleted: w,
  /**
   * Fires a 'AssignmentFinished' track call.
   *
   * @param AssignmentFinished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignmentFinished: C,
  /**
   * Fires a 'AssignmentMadeInactive' track call.
   *
   * @param AssignmentMadeInactive props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignmentMadeInactive: m,
  /**
   * Fires a 'AssignmentStarted' track call.
   *
   * @param AssignmentStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignmentStarted: h,
  /**
   * Fires a 'AttemptedToConsumeContentOverFreemiumLimit' track call.
   *
   * @param AttemptedToConsumeContentOverFreemiumLimit props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  attemptedToConsumeContentOverFreemiumLimit: b,
  /**
   * Fires a 'BookASpotClicked' track call.
   *
   * @param BookASpotClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bookASpotClicked: S,
  /**
   * Fires a 'BookmarkClicked' track call.
   *
   * @param BookmarkClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bookmarkClicked: P,
  /**
   * Fires a 'ButtonClicked' track call.
   *
   * @param ButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  buttonClicked: T,
  /**
   * Fires a 'CanceledButtonClicked' track call.
   *
   * @param CanceledButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  canceledButtonClicked: O,
  /**
   * Fires a 'CardSwiped' track call.
   *
   * @param CardSwiped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  cardSwiped: B,
  /**
   * Fires a 'CarouselChanged' track call.
   *
   * @param CarouselChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  carouselChanged: F,
  /**
   * Fires a 'CarouselClicked' track call.
   *
   * @param CarouselClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  carouselClicked: A,
  /**
   * Fires a 'ClearCacheClicked' track call.
   *
   * @param ClearCacheClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  clearCacheClicked: U,
  /**
   * Fires a 'CommentButtonClicked' track call.
   *
   * @param CommentButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentButtonClicked: L,
  /**
   * Fires a 'CommentDeletedClicked' track call.
   *
   * @param CommentDeletedClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentDeletedClicked: R,
  /**
   * Fires a 'CommentOptionsClicked' track call.
   *
   * @param CommentOptionsClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentOptionsClicked: E,
  /**
   * Fires a 'CommentReplyButtonClicked' track call.
   *
   * @param CommentReplyButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentReplyButtonClicked: D,
  /**
   * Fires a 'CommentSent' track call.
   *
   * @param CommentSent props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentSent: V,
  /**
   * Fires a 'ConfirmationButtonClicked' track call.
   *
   * @param ConfirmationButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  confirmationButtonClicked: I,
  /**
   * Fires a 'ContentAutoplayed' track call.
   *
   * @param ContentAutoplayed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentAutoplayed: M,
  /**
   * Fires a 'ContentCardClicked' track call.
   *
   * @param ContentCardClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentCardClicked: j,
  /**
   * Fires a 'ContentConsumedAtInterval' track call.
   *
   * @param ContentConsumedAtInterval props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentConsumedAtInterval: J,
  /**
   * Fires a 'ContentFinished' track call.
   *
   * @param ContentFinished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentFinished: Y,
  /**
   * Fires a 'ContentPlaybackStateChanged' track call.
   *
   * @param ContentPlaybackStateChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentPlaybackStateChanged: N,
  /**
   * Fires a 'ContentPositionSeek' track call.
   *
   * @param ContentPositionSeek props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentPositionSeek: H,
  /**
   * Fires a 'ContentPreviewCompleted' track call.
   *
   * @param ContentPreviewCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentPreviewCompleted: W,
  /**
   * Fires a 'ContentSearched' track call.
   *
   * @param ContentSearched props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentSearched: $,
  /**
   * Fires a 'ContentStarted' track call.
   *
   * @param ContentStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentStarted: z,
  /**
   * Fires a 'DeleteAccountClicked' track call.
   *
   * @param DeleteAccountClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  deleteAccountClicked: q,
  /**
   * Fires a 'DragDropClicked' track call.
   *
   * @param DragDropClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  dragDropClicked: G,
  /**
   * Fires a 'DropdownClicked' track call.
   *
   * @param DropdownClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  dropdownClicked: K,
  /**
   * Fires a 'DropdownSelectionClicked' track call.
   *
   * @param DropdownSelectionClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  dropdownSelectionClicked: Q,
  /**
   * Fires a 'EditEmailClicked' track call.
   *
   * @param EditEmailClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  editEmailClicked: X,
  /**
   * Fires a 'EmojiPickerOpened' track call.
   *
   * @param EmojiPickerOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  emojiPickerOpened: Z,
  /**
   * Fires a 'EmojiPickerSelection' track call.
   *
   * @param EmojiPickerSelection props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  emojiPickerSelection: tt,
  /**
   * Fires a 'EnterpriseOverlay' track call.
   *
   * @param EnterpriseOverlay props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  enterpriseOverlay: et,
  /**
   * Fires a 'EventAttended' track call.
   *
   * @param EventAttended props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  eventAttended: nt,
  /**
   * Fires a 'EventBooked' track call.
   *
   * @param EventBooked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  eventBooked: ot,
  /**
   * Fires a 'FeedPostClicked' track call.
   *
   * @param FeedPostClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  feedPostClicked: ct,
  /**
   * Fires a 'FeedPostImpression' track call.
   *
   * @param FeedPostImpression props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  feedPostImpression: it,
  /**
   * Fires a 'FeedPostStarted' track call.
   *
   * @param FeedPostStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  feedPostStarted: rt,
  /**
   * Fires a 'FetchMore' track call.
   *
   * @param FetchMore props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  fetchMore: at,
  /**
   * Fires a 'FileUploadSelected' track call.
   *
   * @param FileUploadSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  fileUploadSelected: st,
  /**
   * Fires a 'FollowButtonClicked' track call.
   *
   * @param FollowButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  followButtonClicked: lt,
  /**
   * Fires a 'FooterClicked' track call.
   *
   * @param FooterClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  footerClicked: dt,
  /**
   * Fires a 'FormFieldFilled' track call.
   *
   * @param FormFieldFilled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  formFieldFilled: pt,
  /**
   * Fires a 'FormSubmitted' track call.
   *
   * @param FormSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  formSubmitted: ut,
  /**
   * Fires a 'FormViewed' track call.
   *
   * @param FormViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  formViewed: gt,
  /**
   * Fires a 'FreeContentCount' track call.
   *
   * @param FreeContentCount props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  freeContentCount: kt,
  /**
   * Fires a 'FreeContentLimitReached' track call.
   *
   * @param FreeContentLimitReached props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  freeContentLimitReached: vt,
  /**
   * Fires a 'InvitedToJoinOrganisation' track call.
   *
   * @param InvitedToJoinOrganisation props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  invitedToJoinOrganisation: yt,
  /**
   * Fires a 'LanguageChanged' track call.
   *
   * @param LanguageChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  languageChanged: xt,
  /**
   * Fires a 'LikeClicked' track call.
   *
   * @param LikeClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  likeClicked: _t,
  /**
   * Fires a 'LiveEventEndedScreen' track call.
   *
   * @param LiveEventEndedScreen props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  liveEventEndedScreen: ft,
  /**
   * Fires a 'LivestreamJoined' track call.
   *
   * @param LivestreamJoined props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  livestreamJoined: wt,
  /**
   * Fires a 'LivestreamMessageSent' track call.
   *
   * @param LivestreamMessageSent props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  livestreamMessageSent: Ct,
  /**
   * Fires a 'Login' track call.
   *
   * @param Login props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  login: mt,
  /**
   * Fires a 'Logout' track call.
   *
   * @param Logout props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  logout: ht,
  /**
   * Fires a 'MarketingVideoCompleted' track call.
   *
   * @param MarketingVideoCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  marketingVideoCompleted: bt,
  /**
   * Fires a 'MarketingVideoPlayed' track call.
   *
   * @param MarketingVideoPlayed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  marketingVideoPlayed: St,
  /**
   * Fires a 'MentionClicked' track call.
   *
   * @param MentionClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mentionClicked: Pt,
  /**
   * Fires a 'MentionFinished' track call.
   *
   * @param MentionFinished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mentionFinished: Tt,
  /**
   * Fires a 'MentionRemoved' track call.
   *
   * @param MentionRemoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mentionRemoved: Ot,
  /**
   * Fires a 'MentionStarted' track call.
   *
   * @param MentionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mentionStarted: Bt,
  /**
   * Fires a 'NavBarClicked' track call.
   *
   * @param NavBarClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  navBarClicked: Ft,
  /**
   * Fires a 'NavButtonClicked' track call.
   *
   * @param NavButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  navButtonClicked: At,
  /**
   * Fires a 'NewInvitationAccepted' track call.
   *
   * @param NewInvitationAccepted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  newInvitationAccepted: Ut,
  /**
   * Fires a 'NewNotificationsCount' track call.
   *
   * @param NewNotificationsCount props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  newNotificationsCount: Lt,
  /**
   * Fires a 'NewRegisteredB2CUser' track call.
   *
   * @param NewRegisteredB2CUser props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  newRegisteredB2CUser: Rt,
  /**
   * Fires a 'NewsletterSubscribed' track call.
   *
   * @param NewsletterSubscribed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  newsletterSubscribed: Et,
  /**
   * Fires a 'NoteCancelClicked' track call.
   *
   * @param NoteCancelClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteCancelClicked: Dt,
  /**
   * Fires a 'NoteCloseClicked' track call.
   *
   * @param NoteCloseClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteCloseClicked: Vt,
  /**
   * Fires a 'NoteDeleteClicked' track call.
   *
   * @param NoteDeleteClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteDeleteClicked: It,
  /**
   * Fires a 'NoteEditClicked' track call.
   *
   * @param NoteEditClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteEditClicked: Mt,
  /**
   * Fires a 'NoteSavedClicked' track call.
   *
   * @param NoteSavedClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteSavedClicked: jt,
  /**
   * Fires a 'NoteUpdated' track call.
   *
   * @param NoteUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteUpdated: Jt,
  /**
   * Fires a 'NotesButtonClicked' track call.
   *
   * @param NotesButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  notesButtonClicked: Yt,
  /**
   * Fires a 'NotificationClicked' track call.
   *
   * @param NotificationClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  notificationClicked: Nt,
  /**
   * Fires a 'OnboardingCompleted' track call.
   *
   * @param OnboardingCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  onboardingCompleted: Ht,
  /**
   * Fires a 'OrganisationContentClicked' track call.
   *
   * @param OrganisationContentClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  organisationContentClicked: Wt,
  /**
   * Fires a 'PollVoteClicked' track call.
   *
   * @param PollVoteClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pollVoteClicked: $t,
  /**
   * Fires a 'PostAudienceClicked' track call.
   *
   * @param PostAudienceClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postAudienceClicked: zt,
  /**
   * Fires a 'PostAudienceSelected' track call.
   *
   * @param PostAudienceSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postAudienceSelected: qt,
  /**
   * Fires a 'PostCreated' track call.
   *
   * @param PostCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postCreated: Gt,
  /**
   * Fires a 'PostOptionsClicked' track call.
   *
   * @param PostOptionsClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postOptionsClicked: Kt,
  /**
   * Fires a 'ProductTourCompleted' track call.
   *
   * @param ProductTourCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productTourCompleted: Qt,
  /**
   * Fires a 'ProductTourStarted' track call.
   *
   * @param ProductTourStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productTourStarted: Xt,
  /**
   * Fires a 'ProfileClicked' track call.
   *
   * @param ProfileClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  profileClicked: Zt,
  /**
   * Fires a 'ProfileUpdated' track call.
   *
   * @param ProfileUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  profileUpdated: te,
  /**
   * Fires a 'PromotedToThinkfluencer' track call.
   *
   * @param PromotedToThinkfluencer props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  promotedToThinkfluencer: ee,
  /**
   * Fires a 'PulledToRefresh' track call.
   *
   * @param PulledToRefresh props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pulledToRefresh: ne,
  /**
   * Fires a 'ReactionClicked' track call.
   *
   * @param ReactionClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  reactionClicked: oe,
  /**
   * Fires a 'ReactionsOpened' track call.
   *
   * @param ReactionsOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  reactionsOpened: ce,
  /**
   * Fires a 'RepostClicked' track call.
   *
   * @param RepostClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  repostClicked: ie,
  /**
   * Fires a 'RepostCreated' track call.
   *
   * @param RepostCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  repostCreated: re,
  /**
   * Fires a 'RepostWithYourThoughtsCreated' track call.
   *
   * @param RepostWithYourThoughtsCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  repostWithYourThoughtsCreated: ae,
  /**
   * Fires a 'Search' track call.
   *
   * @param Search props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  search: se,
  /**
   * Fires a 'ShareClicked' track call.
   *
   * @param ShareClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  shareClicked: le,
  /**
   * Fires a 'ShareExternallyClicked' track call.
   *
   * @param ShareExternallyClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  shareExternallyClicked: de,
  /**
   * Fires a 'ShareToFeedClicked' track call.
   *
   * @param ShareToFeedClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  shareToFeedClicked: pe,
  /**
   * Fires a 'SharedContent' track call.
   *
   * @param SharedContent props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sharedContent: ue,
  /**
   * Fires a 'SignOutClicked' track call.
   *
   * @param SignOutClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  signOutClicked: ge,
  /**
   * Fires a 'SliderClicked' track call.
   *
   * @param SliderClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sliderClicked: ke,
  /**
   * Fires a 'StoryCompleted' track call.
   *
   * @param StoryCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyCompleted: ve,
  /**
   * Fires a 'StoryStarted' track call.
   *
   * @param StoryStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyStarted: ye,
  /**
   * Fires a 'SubscriptionCancelled' track call.
   *
   * @param SubscriptionCancelled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionCancelled: xe,
  /**
   * Fires a 'SubscriptionChanged' track call.
   *
   * @param SubscriptionChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionChanged: _e,
  /**
   * Fires a 'SubscriptionStarted' track call.
   *
   * @param SubscriptionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionStarted: fe,
  /**
   * Fires a 'SupportEmailLinkClicked' track call.
   *
   * @param SupportEmailLinkClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  supportEmailLinkClicked: we,
  /**
   * Fires a 'SupportHelpCentreLinkClicked' track call.
   *
   * @param SupportHelpCentreLinkClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  supportHelpCentreLinkClicked: Ce,
  /**
   * Fires a 'TabClicked' track call.
   *
   * @param TabClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tabClicked: me,
  /**
   * Fires a 'TableRowClicked' track call.
   *
   * @param TableRowClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tableRowClicked: he,
  /**
   * Fires a 'TableSortClicked' track call.
   *
   * @param TableSortClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tableSortClicked: be,
  /**
   * Fires a 'ThinkfluencerFirstContentPublished' track call.
   *
   * @param ThinkfluencerFirstContentPublished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  thinkfluencerFirstContentPublished: Se,
  /**
   * Fires a 'ThinkfluencerForOneYear' track call.
   *
   * @param ThinkfluencerForOneYear props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  thinkfluencerForOneYear: Pe,
  /**
   * Fires a 'TimeOnScreen' track call.
   *
   * @param TimeOnScreen props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  timeOnScreen: Te,
  /**
   * Fires a 'ToggleClicked' track call.
   *
   * @param ToggleClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  toggleClicked: Oe,
  /**
   * Fires a 'ToggleOrientationClicked' track call.
   *
   * @param ToggleOrientationClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  toggleOrientationClicked: Be,
  /**
   * Fires a 'TopicButtonClicked' track call.
   *
   * @param TopicButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  topicButtonClicked: Fe,
  /**
   * Fires a 'TopicToggleClicked' track call.
   *
   * @param TopicToggleClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  topicToggleClicked: Ae,
  /**
   * Fires a 'TrailCompletionInfo' track call.
   *
   * @param TrailCompletionInfo props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  trailCompletionInfo: Ue,
  /**
   * Fires a 'TrailFinished' track call.
   *
   * @param TrailFinished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  trailFinished: Le,
  /**
   * Fires a 'UserAccessLevelChanged' track call.
   *
   * @param UserAccessLevelChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  userAccessLevelChanged: Re,
  /**
   * Fires a 'UserCreated' track call.
   *
   * @param UserCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  userCreated: Ee,
  /**
   * Fires a 'UserMentioned' track call.
   *
   * @param UserMentioned props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  userMentioned: De,
  /**
   * Fires a 'VersionUpdatePromptDisplayed' track call.
   *
   * @param VersionUpdatePromptDisplayed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  versionUpdatePromptDisplayed: Ve,
  /**
   * Fires a 'VersionUpdated' track call.
   *
   * @param VersionUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  versionUpdated: Ie,
  /**
   * Fires a 'ViewReactionsClicked' track call.
   *
   * @param ViewReactionsClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  viewReactionsClicked: Me,
  /**
   * Fires a 'ViewRepostsClicked' track call.
   *
   * @param ViewRepostsClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  viewRepostsClicked: je,
  /**
   * Fires a 'WebVitals' track call.
   *
   * @param WebVitals props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  webVitals: Je,
  /**
   * Fires a 'WebsiteLikeClicked' track call.
   *
   * @param WebsiteLikeClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  websiteLikeClicked: Ye
};
new Proxy(Ne, {
  get(n, t) {
    return typeof t == "string" && n.hasOwnProperty(t) ? n[t] : () => {
      console.warn(`⚠️  You made an analytics call (${String(t)}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`);
      const o = c();
      o && o.track(
        "Unknown Analytics Call Fired",
        {
          method: t
        },
        r()
      );
    };
  }
});
export {
  l as accessLevelCreated,
  d as accessLevelDeleted,
  p as accessLevelUpdated,
  u as accountCreated,
  g as accountDeleted,
  k as addNoteClicked,
  v as addToCalendar,
  y as addToSchedule,
  x as adminExportStatistics,
  _ as assignmentClicked,
  f as assignmentCreated,
  w as assignmentDeleted,
  C as assignmentFinished,
  m as assignmentMadeInactive,
  h as assignmentStarted,
  b as attemptedToConsumeContentOverFreemiumLimit,
  S as bookASpotClicked,
  P as bookmarkClicked,
  T as buttonClicked,
  O as canceledButtonClicked,
  B as cardSwiped,
  F as carouselChanged,
  A as carouselClicked,
  U as clearCacheClicked,
  L as commentButtonClicked,
  R as commentDeletedClicked,
  E as commentOptionsClicked,
  D as commentReplyButtonClicked,
  V as commentSent,
  I as confirmationButtonClicked,
  M as contentAutoplayed,
  j as contentCardClicked,
  J as contentConsumedAtInterval,
  Y as contentFinished,
  N as contentPlaybackStateChanged,
  H as contentPositionSeek,
  W as contentPreviewCompleted,
  $ as contentSearched,
  z as contentStarted,
  q as deleteAccountClicked,
  G as dragDropClicked,
  K as dropdownClicked,
  Q as dropdownSelectionClicked,
  X as editEmailClicked,
  Z as emojiPickerOpened,
  tt as emojiPickerSelection,
  et as enterpriseOverlay,
  nt as eventAttended,
  ot as eventBooked,
  ct as feedPostClicked,
  it as feedPostImpression,
  rt as feedPostStarted,
  at as fetchMore,
  st as fileUploadSelected,
  lt as followButtonClicked,
  dt as footerClicked,
  pt as formFieldFilled,
  ut as formSubmitted,
  gt as formViewed,
  kt as freeContentCount,
  vt as freeContentLimitReached,
  yt as invitedToJoinOrganisation,
  xt as languageChanged,
  _t as likeClicked,
  ft as liveEventEndedScreen,
  wt as livestreamJoined,
  Ct as livestreamMessageSent,
  mt as login,
  ht as logout,
  bt as marketingVideoCompleted,
  St as marketingVideoPlayed,
  Pt as mentionClicked,
  Tt as mentionFinished,
  Ot as mentionRemoved,
  Bt as mentionStarted,
  Ft as navBarClicked,
  At as navButtonClicked,
  Ut as newInvitationAccepted,
  Lt as newNotificationsCount,
  Rt as newRegisteredB2CUser,
  Et as newsletterSubscribed,
  Dt as noteCancelClicked,
  Vt as noteCloseClicked,
  It as noteDeleteClicked,
  Mt as noteEditClicked,
  jt as noteSavedClicked,
  Jt as noteUpdated,
  Yt as notesButtonClicked,
  Nt as notificationClicked,
  Ht as onboardingCompleted,
  Wt as organisationContentClicked,
  a as pageViewed,
  $t as pollVoteClicked,
  zt as postAudienceClicked,
  qt as postAudienceSelected,
  Gt as postCreated,
  Kt as postOptionsClicked,
  Qt as productTourCompleted,
  Xt as productTourStarted,
  Zt as profileClicked,
  te as profileUpdated,
  ee as promotedToThinkfluencer,
  ne as pulledToRefresh,
  oe as reactionClicked,
  ce as reactionsOpened,
  ie as repostClicked,
  re as repostCreated,
  ae as repostWithYourThoughtsCreated,
  se as search,
  s as segmentConsentPreferenceUpdated,
  i as setTypewriterOptions,
  le as shareClicked,
  de as shareExternallyClicked,
  pe as shareToFeedClicked,
  ue as sharedContent,
  ge as signOutClicked,
  ke as sliderClicked,
  ve as storyCompleted,
  ye as storyStarted,
  xe as subscriptionCancelled,
  _e as subscriptionChanged,
  fe as subscriptionStarted,
  we as supportEmailLinkClicked,
  Ce as supportHelpCentreLinkClicked,
  me as tabClicked,
  he as tableRowClicked,
  be as tableSortClicked,
  Se as thinkfluencerFirstContentPublished,
  Pe as thinkfluencerForOneYear,
  Te as timeOnScreen,
  Oe as toggleClicked,
  Be as toggleOrientationClicked,
  Fe as topicButtonClicked,
  Ae as topicToggleClicked,
  Ue as trailCompletionInfo,
  Le as trailFinished,
  Re as userAccessLevelChanged,
  Ee as userCreated,
  De as userMentioned,
  Ve as versionUpdatePromptDisplayed,
  Ie as versionUpdated,
  Me as viewReactionsClicked,
  je as viewRepostsClicked,
  Je as webVitals,
  Ye as websiteLikeClicked
};
