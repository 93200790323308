const r = {
  baseStyle: (e) => ({
    field: {
      height: "3rem",
      width: "10rem"
    },
    icon: {
      color: "darkGrey.50"
    }
  })
};
export {
  r as Select
};
