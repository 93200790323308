import * as React from 'react';
import { Box, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { IconCircleCheck } from '@tigerhall/icons';
import { motion } from 'framer-motion';

interface TaskSuccessComponentProps {
  children: React.ReactNode;
  description: string;
}

const TaskSuccessComponent = ({
  children,
  description
}: TaskSuccessComponentProps) => (
  <Flex
    backgroundColor="darkGrey.400"
    p="2rem"
    justifyContent="center"
    borderRadius="md"
  >
    <Flex direction="column" alignItems="center" gap="2rem" maxW="46.25rem">
      <Box
        as={motion.div}
        animate={{
          scale: [0.8, 1],
          opacity: [0, 1]
        }}
      >
        <Icon as={IconCircleCheck} color="tigerOrange.600" boxSize="5rem" />
      </Box>
      <Heading fontSize="1.75rem" fontWeight={700}>
        Success!
      </Heading>

      <Text
        fontSize="1rem"
        fontWeight="500"
        color="lightGrey.200"
        maxW="46.25rem"
        textAlign="center"
      >
        {description}
      </Text>

      <Flex flexDirection="column">{children}</Flex>
    </Flex>
  </Flex>
);

export default TaskSuccessComponent;
