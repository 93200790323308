import {
  ContentStatisticsReportColumns as Columns,
  ContentCardsSorting,
  ContentType,
  type ContentStatisticsReportFilter as Filter,
  formatContentType
} from '@tigerhall/core';
import {
  type GenericRow,
  type TableSelectDataResponse
} from '@tigerhall/components';

import {
  type AllStaticFilters,
  type DynamicFilter,
  type ReportColumn,
  type ReportTemplate,
  StaticFilterType
} from '~/modules/admin/modules/reportBuilder/types';
import { apolloClient } from '~/api/apollo';
import {
  ExportContentStatisticsReportDocument,
  type ExportContentStatisticsReportMutation,
  type ExportContentStatisticsReportMutationVariables,
  GetContentTitlesDocument,
  type GetContentTitlesQuery,
  type GetContentTitlesQueryVariables
} from '~/modules/admin/modules/reportBuilder/queries';
import { type ReportBuilderWizardStoreState } from '~/modules/admin/modules/reportBuilder/stores';
import { transformStateToDefaultFilters } from '~/modules/admin/modules/reportBuilder/utils';

export class ContentStatisticsReport implements ReportTemplate<Filter> {
  getName(): string {
    return 'Content Statistics';
  }

  getDescription(): string {
    return 'Summary of time spent and completion of the content piece.';
  }

  isEnabled(): boolean {
    return true;
  }

  async calculatePotentialRowCount(
    filter: Filter,
    audienceSize: number
  ): Promise<number> {
    if (filter.contents?.length && filter.contents.length > 0) {
      return await Promise.resolve(filter.contents.length * audienceSize);
    }

    const response = await apolloClient.query<
      GetContentTitlesQuery,
      GetContentTitlesQueryVariables
    >({
      query: GetContentTitlesDocument
    });

    return response.data.contentCards.meta.total * audienceSize;
  }

  getColumns(): ReportColumn[] {
    return [
      {
        id: Columns.ContentName,
        name: 'Content Name'
      },
      {
        id: Columns.ContentId,
        name: 'Content ID'
      },
      {
        id: Columns.EstimatedContentLength,
        name: 'Estimated Content Length'
      },
      {
        id: Columns.CumulativeTimeSpentOnContent,
        name: 'Cumulative Time Spent on Content'
      },
      {
        id: Columns.AverageTimeSpentByUserOnContent,
        name: 'Average Time Spent by User on Content'
      },
      {
        id: Columns.NumberOfCompletions,
        name: 'Number of Completions'
      },
      {
        id: Columns.NumberOfLikes,
        name: 'Number of Likes'
      },
      {
        id: Columns.NumberOfDislikes,
        name: 'Number of Dislikes'
      },
      {
        id: Columns.NumberOfShares,
        name: 'Number of Shares'
      },
      {
        id: Columns.NumberOfCommentsAndReplies,
        name: 'Number of Comments & Replies'
      }
    ];
  }

  getSampleData(): Record<string, string | number> {
    return {
      [Columns.ContentName]: 'Introduction to Programming',
      [Columns.ContentId]: 'C101',
      [Columns.EstimatedContentLength]: '5 hours 10 minutes 20 seconds',
      [Columns.CumulativeTimeSpentOnContent]: '2 days 3 hours 10 minutes',
      [Columns.AverageTimeSpentByUserOnContent]: '33 minutes',
      [Columns.NumberOfCompletions]: 100,
      [Columns.NumberOfLikes]: 48,
      [Columns.NumberOfDislikes]: 1,
      [Columns.NumberOfShares]: 60,
      [Columns.NumberOfCommentsAndReplies]: 10
    };
  }

  getStaticFilters(): AllStaticFilters<Filter>[] {
    return [
      {
        name: 'includeArchivedUsers',
        label: 'Include Archived Users',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        isClearDisabled: true,
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' }
        ]
      }
    ];
  }

  getDynamicFilter(): DynamicFilter<Filter> | null {
    return {
      id: 'contents',
      label: 'Content Pieces',
      description: 'Filter the report on a number of content pieces',

      columns: [
        {
          id: 'name',
          name: 'Name'
        },
        {
          id: 'format',
          name: 'Format',
          accessor: (row: GenericRow) => {
            return formatContentType(row['__typename'].toUpperCase(), false);
          }
        }
      ],

      rules: {
        required: false
      },

      dataSource: {
        query: async (
          context,
          keywords,
          limit,
          offset
        ): Promise<TableSelectDataResponse> => {
          const response = await apolloClient.query<
            GetContentTitlesQuery,
            GetContentTitlesQueryVariables
          >({
            query: GetContentTitlesDocument,
            variables: {
              filter: {
                types: [
                  ContentType.Podcast,
                  ContentType.Event,
                  ContentType.Stream,
                  ContentType.Ebook
                ],
                keywords,
                offset,
                limit
              },
              sorting: ContentCardsSorting.PublishedAt
            },

            /**
             * Todo figure out why cache is breaking my pagination
             */
            fetchPolicy: 'no-cache'
          });

          return {
            edges: response.data.contentCards.edges,
            meta: response.data.contentCards.meta
          };
        }
      }
    };
  }

  async export(state: ReportBuilderWizardStoreState): Promise<void> {
    const { reportFilters: filter } = state;

    const standardFilter = await transformStateToDefaultFilters<Columns>(state);

    await apolloClient.mutate<
      ExportContentStatisticsReportMutation,
      ExportContentStatisticsReportMutationVariables
    >({
      mutation: ExportContentStatisticsReportDocument,
      variables: {
        filter: {
          ...standardFilter,

          contents: filter.contents.map((r: GenericRow) => r.id)
        }
      }
    });
  }
}
