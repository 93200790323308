import { ActivationByUserReport } from './ActivationByUserReport';
import { UserAssignmentStatisticsReport } from './UserAssignmentStatisticsReport';
import { ActivationOverviewReport } from './ActivationOverviewReport';
import { UserContentStatisticsReport } from './UserContentStatisticsReport';
import { UserTrailStatisticsReport } from './UserTrailStatisticsReport';
import { CommentsReport } from './CommentsReport';
import { ContentStatisticsReport } from './ContentStatisticsReport';
import { EngagementReport } from './EngagementReport';

export const AllReportTemplates = {
  ActivationByUser: new ActivationByUserReport(),
  ActivationOverview: new ActivationOverviewReport(),
  UserContentStatistics: new UserContentStatisticsReport(),
  UserAssignmentStatistics: new UserAssignmentStatisticsReport(),
  Comments: new CommentsReport(),
  ContentStatistics: new ContentStatisticsReport(),
  UserTrailStatisticsReport: new UserTrailStatisticsReport(),
  Engagement: new EngagementReport()
};

type ValueOf<T> = T[keyof T];

export type OneOfTheReportTemplates = ValueOf<typeof AllReportTemplates>;

export type ReportTemplateId = keyof typeof AllReportTemplates;