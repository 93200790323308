import { FeedItemContainer } from '../FeedItemContainer';
import { PostBody } from './components/PostBody';
import { PostBodyProps } from './types';

export interface PostProps extends PostBodyProps {}

export function Post(props: PostProps) {
  const isRepostWithThoughts = !!props.post.parent;

  return (
    <FeedItemContainer>
      <PostBody
        {...props}
        type={isRepostWithThoughts ? 'REPOST_WITH_THOUGHTS' : 'POST'}
      />
    </FeedItemContainer>
  );
}
