import { useRef } from 'react';
import { toFixed } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';

/**
 * Report the progress to segment
 */
export function reportProgress(params: segment.ContentConsumedAtInterval) {
  segment.contentConsumedAtInterval({
    isLive: false,
    ...params,
    position: {
      current: toFixed(params.position.current),
      previous: toFixed(params.position.previous),
      total:
        typeof params.position.total === 'number'
          ? Math.round(params.position.total)
          : undefined
    },
    duration: toFixed(params.duration),
    durationByPositionDelta:
      typeof params.durationByPositionDelta === 'number'
        ? toFixed(params.durationByPositionDelta)
        : undefined
  });
}

export interface UseAnalyticsArgs {
  /**
   *  The id of the content that is being played
   */
  contentId: string;

  /**
   * The type of content that is being played
   */
  contentType: segment.ContentConsumedAtInterval['contentType'];

  /**
   * The ID of the playlist the user is consuming the content piece from.
   */
  playlistId?: string;

  /**
   * The type of the playlist. It can be "Trail" or "Assignment".
   */
  playlistType?: segment.ContentConsumedAtInterval['playlistType'];

  /**
   * The state of the player while consuming the content piece. It can be "Maximized",
   * "Minimized" or "Backgrounded".
   */
  playerState: segment.ContentConsumedAtInterval['state'];

  /**
   * How frequently to report the media progress in milliseconds
   */
  onTrackProgressIntervalMS?: number;

  /**
   * Callback to be called when the media progress is updated
   *
   * @param progress
   */
  onTrackProgress: (progress: number) => void;

  /**
   * Callback to be called when the media is marked as finished.
   * This callback is omitted if the `duration` or `customDuration` is 0 or less.
   */
  onTrackFinished: () => void;

  /**
   * boolean flag to stop time spent reporting
   */
  disableTimeSpentReporting?: boolean;

  /**
   * If this event has been originally triggered when the user didn't have internet access.
   */
  isOffline?: boolean;

  /**
   * this flag is to track if the media is a live event
   */
  isLive?: boolean;

  /**
   * this is for the selected language of the content
   */
  language?: string;

  /**
   * this is to know in which format user is consuming the content
   */
  mediaType?: 'audio' | 'text' | 'video';
}

export function useAnalytics() {
  // This is used to track the time when the user started watching the media
  const startedTrackingAt = useRef<number>(Date.now());

  // This is used to track the time when the user paused the media
  const prevReportingTime = useRef<number>(Date.now());

  // This stops us from reporting the media as finished multiple times
  const markedAsFinished = useRef<boolean>(false);

  return {
    startedTrackingAt,
    prevReportingTime,
    markedAsFinished
  };
}
