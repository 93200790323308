import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LiveVideoExpertsFragment = { __typename?: 'Expert', id: string, firstName: string, lastName: string, title: string, company?: string | null, isAuthenticatedUserFollowing: boolean, image: { __typename?: 'Image', id: string, uri: string } };

export type GetStreamForConnectedLiveVideoPlayerSubscriptionVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
}>;


export type GetStreamForConnectedLiveVideoPlayerSubscription = { __typename?: 'Subscription', stream?: { __typename: 'Stream', id: string, name: string, videoUrl: string, wentLiveAt?: any | null, source: SchemaTypes.StreamSource, status: SchemaTypes.StreamStatus, preamble: string, upvoteCount: number, contentRatingByUser: SchemaTypes.ContentVote, categories: Array<{ __typename?: 'Category', id: string, name: string }>, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, title: string, company?: string | null, isAuthenticatedUserFollowing: boolean, image: { __typename?: 'Image', id: string, uri: string } }>, image: { __typename?: 'Image', uri: string, id: string } } | null };

export const LiveVideoExpertsFragmentDoc = gql`
    fragment LiveVideoExperts on Expert {
  id
  firstName
  lastName
  image {
    id
    uri
  }
  title
  company
  isAuthenticatedUserFollowing
}
    `;
export const GetStreamForConnectedLiveVideoPlayerDocument = gql`
    subscription GetStreamForConnectedLiveVideoPlayer($id: ID!) {
  stream(id: $id) {
    __typename
    id
    name
    videoUrl
    wentLiveAt
    source
    status
    preamble
    upvoteCount
    contentRatingByUser
    categories {
      id
      name
    }
    experts {
      ...LiveVideoExperts
    }
    image {
      uri
      id
    }
  }
}
    ${LiveVideoExpertsFragmentDoc}`;

/**
 * __useGetStreamForConnectedLiveVideoPlayerSubscription__
 *
 * To run a query within a React component, call `useGetStreamForConnectedLiveVideoPlayerSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamForConnectedLiveVideoPlayerSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamForConnectedLiveVideoPlayerSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStreamForConnectedLiveVideoPlayerSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetStreamForConnectedLiveVideoPlayerSubscription, GetStreamForConnectedLiveVideoPlayerSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetStreamForConnectedLiveVideoPlayerSubscription, GetStreamForConnectedLiveVideoPlayerSubscriptionVariables>(GetStreamForConnectedLiveVideoPlayerDocument, options);
      }
export type GetStreamForConnectedLiveVideoPlayerSubscriptionHookResult = ReturnType<typeof useGetStreamForConnectedLiveVideoPlayerSubscription>;
export type GetStreamForConnectedLiveVideoPlayerSubscriptionResult = Apollo.SubscriptionResult<GetStreamForConnectedLiveVideoPlayerSubscription>;