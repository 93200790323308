import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import { type ComponentStyleConfig } from '@chakra-ui/react';
import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle
} from '@chakra-ui/styled-system';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const $bg = cssVar('menu-bg');
const $shadow = cssVar('menu-shadow');

const baseStyleList = defineStyle({
  [$bg.variable]: '#fff',
  [$shadow.variable]: 'shadows.sm',
  _dark: {
    [$bg.variable]: 'colors.darkGrey.400',
    [$shadow.variable]: 'shadows.dark-lg',
    color: 'white'
  },
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  p: '0.5rem',
  color: 'inherit',
  minW: '3xs',
  zIndex: 1,
  borderRadius: 'lg',
  border: 'none',
  bg: $bg.reference,
  boxShadow: $shadow.reference
});

const baseStyleItem = defineStyle({
  p: '0.5rem',
  transitionProperty: 'background',
  transitionDuration: 'ultra-fast',
  transitionTimingFunction: 'ease-in',
  borderRadius: 'base',
  fontSize: 'sm',
  fontWeight: 'medium',
  iconSpacing: '0.5rem',
  _hover: {
    [$bg.variable]: 'colors.lightGrey.200',
    _dark: {
      [$bg.variable]: 'colors.darkGrey.300'
    }
  },
  _focus: {
    [$bg.variable]: 'colors.lightGrey.200',
    _dark: {
      [$bg.variable]: 'colors.darkGrey.300'
    }
  },
  _active: {
    [$bg.variable]: 'colors.lightGrey.200',
    _dark: {
      [$bg.variable]: 'colors.darkGrey.400'
    }
  },
  _expanded: {
    [$bg.variable]: 'colors.lightGrey.200',
    _dark: {
      [$bg.variable]: 'colors.darkGrey.300'
    }
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed'
  },
  bg: $bg.reference
});

const baseStyleGroupTitle = defineStyle({
  mx: 4,
  my: 2,
  fontWeight: 'semibold',
  fontSize: 'sm'
});

const baseStyleCommand = defineStyle({
  opacity: 0.6
});

const baseStyleDivider = defineStyle({
  border: 0,
  borderBottom: '1px solid',
  borderColor: 'inherit',
  my: '2',
  opacity: 0.6
});

const baseStyleButton = defineStyle({
  transitionProperty: 'common',
  transitionDuration: 'normal'
});

const baseStyle = definePartsStyle({
  button: baseStyleButton,
  list: baseStyleList,
  item: baseStyleItem,
  groupTitle: baseStyleGroupTitle,
  command: baseStyleCommand,
  divider: baseStyleDivider
});

export const Menu: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle
});
