import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
export type ImageFragment = { __typename?: 'Image', id: string, uri: string, alt: string, filename: string };

export const ImageFragmentDoc = gql`
    fragment Image on Image {
  id
  uri
  alt
  filename
}
    `;