import {
  MarkdownProps,
  RichTextMarkdownWrapper
} from 'components/ui/MarkdownWrapper/RichTextMarkdownWrapper';
import { useFreeAccount } from 'hooks';

import { PreviewWall } from '../../PreviewWall';

export function SubOnlyProtectedMarkdownComponent(props: MarkdownProps) {
  const { isInPreviewMode } = useFreeAccount();
  return (
    <>
      <RichTextMarkdownWrapper {...props} />
      {isInPreviewMode && <PreviewWall right="0" rounded="2xl" zIndex="1" />}
    </>
  );
}
