import {
  UserTrailStatisticsReportColumns as Columns,
  CompletionStatus,
  ConsumptionFlag,
  ContentCardsSorting,
  ContentType,
  type UserTrailStatisticsReportFilter as Filter,
  UserTrailStatisticsReportColumns
} from '@tigerhall/core';
import { type GenericRow, type TableSelectDataResponse } from '@tigerhall/components';

import {
  type AllStaticFilters,
  type DynamicFilter,
  type ReportColumn,
  type ReportTemplate,
  StaticFilterType
} from '~/modules/admin/modules/reportBuilder/types';
import { apolloClient } from '~/api/apollo';
import {
  ExportUserTrailStatisticsReportDocument,
  type ExportUserTrailStatisticsReportMutation,
  type ExportUserTrailStatisticsReportMutationVariables,
  GetContentTitlesDocument,
  type GetContentTitlesQuery,
  type GetContentTitlesQueryVariables
} from '~/modules/admin/modules/reportBuilder/queries';
import { type ReportBuilderWizardStoreState } from '~/modules/admin/modules/reportBuilder/stores';
import {
  extractStaticDropDownValue,
  transformStateToDefaultFilters
} from '~/modules/admin/modules/reportBuilder/utils';

export class UserTrailStatisticsReport implements ReportTemplate<Filter> {
  getName(): string {
    return 'User Trail Statistics';
  }

  getDescription(): string {
    return 'Summary of time spent and completion by the user on any trail.';
  }

  isEnabled(): boolean {
    return true;
  }

  getColumns(): ReportColumn[] {
    return [
      {
        id: Columns.UserName,
        name: 'Username'
      },
      {
        id: Columns.Email,
        name: 'Email'
      },
      {
        id: Columns.TigerhallUserId,
        name: 'Tigerhall User ID'
      },
      {
        id: Columns.TrailName,
        name: 'Trail Name'
      },
      {
        id: Columns.TrailId,
        name: 'Trail ID'
      },
      {
        id: Columns.TrailCompletionDate,
        name: 'Trail Completion Date'
      },
      {
        id: Columns.TrailCompletionStatus,
        name: 'Trail Completion Status',
        description: 'Whether the user has completed the trail or not'
      },
      {
        id: Columns.NumberOfContentPiecesCompletedByTheUser,
        name: 'Number of Content Pieces Completed by the User',
        description:
          'The number of content pieces completed in the trail by the user'
      },
      {
        id: Columns.TotalNumberOfContentPiecesInTheTrail,
        name: 'Total Number of Content Pieces in the Trail',
        description: 'Total number of content pieces in the trail'
      },
      {
        id: Columns.TrailProgressPercentageBasedOnContentPiece,
        name: 'Trail Progress % (calculated at content piece)',
        description:
          'The number of content pieces the user has completed out of the total number of pieces in the trail'
      },
      {
        id: Columns.TrailProgressPercentageBasedOnContentLength,
        name: 'Trail Progress % (calculated at content length)',
        description: `Progress based on the consumption of the total trail length. Shows 100% if the user has fully consumed all the pieces in the trail. Any value less than 100% shows the user's total furthest progress of all pieces in the trail.`
      },
      {
        id: Columns.TrailLength,
        name: 'Trail Length',
        description: 'Estimated trail length'
      },
      {
        id: Columns.TrailTimeSpent,
        name: 'Trail Time Spent',
        description: 'Time spent by the user in trail'
      },
      {
        id: Columns.TrailConsumptionFlag,
        name: 'Trail Speed Runner',
        description:
          'Trail is flagged if any of the content pieces are flagged. Content is flagged if the content is marked as completed and the time spent is less than half the estimated content length'
      }
    ];
  }

  getSampleData(): Record<string, string | number | boolean> {
    return {
      [UserTrailStatisticsReportColumns.UserName]: 'jane smith',
      [UserTrailStatisticsReportColumns.Email]: 'janesmith@example.com',
      [UserTrailStatisticsReportColumns.TigerhallUserId]: 'TH123456',
      [UserTrailStatisticsReportColumns.TrailName]: 'Trail 1',
      [UserTrailStatisticsReportColumns.TrailId]: 'T123456',
      [UserTrailStatisticsReportColumns.TrailCompletionDate]: '4 Jun 2024',
      [UserTrailStatisticsReportColumns.TrailCompletionStatus]: 'Completed',
      [UserTrailStatisticsReportColumns.NumberOfContentPiecesCompletedByTheUser]: 15,
      [UserTrailStatisticsReportColumns.TotalNumberOfContentPiecesInTheTrail]: 15,
      [UserTrailStatisticsReportColumns.TrailProgressPercentageBasedOnContentPiece]: 100,
      [UserTrailStatisticsReportColumns.TrailProgressPercentageBasedOnContentLength]: 100,
      [UserTrailStatisticsReportColumns.TrailLength]:
        '5 hours 10 minutes 20 seconds',
      [UserTrailStatisticsReportColumns.TrailTimeSpent]:
        '5 hours 10 minutes 20 seconds',
      [UserTrailStatisticsReportColumns.TrailConsumptionFlag]: true
    };
  }

  getStaticFilters(): AllStaticFilters<Filter>[] {
    return [
      {
        name: 'completionStatus',
        label: 'Trail Completion Status',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        isMulti: true,
        options: [
          { label: 'Completed', value: CompletionStatus.Completed },
          { label: 'In Progress', value: CompletionStatus.InProgress },
          { label: 'Not Started', value: CompletionStatus.NotStarted }
        ]
      },
      {
        name: 'consumptionFlag',
        label: 'Trail Speed Runner',
        tooltip:
          'This will allow you to filter on users that have completed the trail in a unrealistic time and most likely skipped large parts of the content',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        options: [
          { label: 'Yes', value: ConsumptionFlag.Yes },
          { label: 'No', value: ConsumptionFlag.No }
        ]
      },
      {
        name: 'includeArchivedUsers',
        label: 'Include Archived Users',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        isClearDisabled: true,
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' }
        ]
      }
    ];
  }

  getDynamicFilter(): DynamicFilter<Filter> | null {
    return {
      id: 'trails',
      label: 'Trails',
      description: 'Filter the report on a number of content pieces',

      columns: [
        {
          id: 'name',
          name: 'Name'
        },
        {
          id: 'content pieces',
          name: 'Content Pieces',
          accessor: (row: GenericRow) => {
            return row['contentCards'].length;
          }
        }
      ],

      rules: {
        required: 'You must select at least one content piece for the report'
      },

      dataSource: {
        query: async (
          context,
          keywords,
          limit,
          offset
        ): Promise<TableSelectDataResponse> => {
          const response = await apolloClient.query<
            GetContentTitlesQuery,
            GetContentTitlesQueryVariables
          >({
            query: GetContentTitlesDocument,
            variables: {
              filter: {
                types: [ContentType.LearningPath],
                keywords,
                offset,
                limit
              },
              sorting: ContentCardsSorting.PublishedAt
            },

            /**
             * Todo figure out why cache is breaking my pagination
             */
            fetchPolicy: 'no-cache'
          });

          return {
            edges: response.data.contentCards.edges,
            meta: response.data.contentCards.meta
          };
        }
      }
    };
  }

  calculatePotentialRowCount(
    filter: Filter,
    audienceSize: number
  ): Promise<number> {
    const learningPaths = filter.trails as unknown as GenericRow[];

    const contentPiecesInLearningPaths = learningPaths.reduce(
      (acc, lp: GenericRow) => {
        return acc + lp['contentCards'].length;
      },
      0
    );

    return Promise.resolve(contentPiecesInLearningPaths * audienceSize);
  }

  async export(state: ReportBuilderWizardStoreState): Promise<void> {
    const { reportFilters: filter } = state;

    const standardFilter = await transformStateToDefaultFilters<Columns>(state);

    await apolloClient.mutate<
      ExportUserTrailStatisticsReportMutation,
      ExportUserTrailStatisticsReportMutationVariables
    >({
      mutation: ExportUserTrailStatisticsReportDocument,
      variables: {
        filter: {
          ...standardFilter,

          trails: filter.trails.map((r: GenericRow) => r.id),

          consumptionFlag: extractStaticDropDownValue(
            filter.consumptionFlag
          ) as ConsumptionFlag,

          completionStatus: extractStaticDropDownValue(
            filter.completionStatus
          ) as CompletionStatus[]
        }
      }
    });
  }
}
