import { Flex, GridItem, Skeleton } from '@chakra-ui/react';

export function ImageVariantSkeleton() {
  return (
    <GridItem
      height={16}
      borderRadius={8}
      bg={'blackAlpha.100'}
      borderWidth={1}
      width={'full'}
      h={{ base: '17rem', md: '25rem' }}
    >
      <Flex direction={'column'} gap={2}>
        <Skeleton aspectRatio={1.91 / 1} />

        <Flex
          direction={'column'}
          justifyContent={'flex-start'}
          gap={2}
          padding={3}
        >
          <Skeleton height={4} width={'40%'} />
          <Skeleton height={5} width={'80%'} />
          <Skeleton height={4} width={'full'} />
        </Flex>
      </Flex>
    </GridItem>
  );
}
