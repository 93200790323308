import * as React from 'react';
import { Button, Center, Icon, Text } from '@chakra-ui/react';
import { IconBrandMicrosoftColor } from '@tigerhall/icons';
import * as segment from '@tigerhall/analytics';

export function SignInWithMicrosoft() {
  return (
    <Button
      as="a"
      leftIcon={<Icon as={IconBrandMicrosoftColor} />}
      w="100%"
      href={`${
        import.meta.env.VITE_API_URL
      }/workos/authorize?provider=MicrosoftOAuth&returnUrl=${
        window.location.href
      }`}
      p="0.75rem"
      minH="2.7rem"
      onClick={() => {
        segment.buttonClicked({
          location: window.location.pathname,
          buttonName: 'MICROSOFT_SSO_LOGIN'
        });
      }}
    >
      <Center>
        <Text color="darkGrey.300" fontSize="1rem" my="0.75rem" as="span">
          Sign in with Microsoft
        </Text>
      </Center>
    </Button>
  );
}
