import { Paywall } from 'components/ui/Paywall';
import { Modal } from 'components/ui/Modal';

export enum MODALTYPES {
  Payment = 'Payment',
  SignUp = 'SignUp',
  MeetingsEmbed = 'MeetingsEmbed'
}
interface CommonModalProps {
  modalType: MODALTYPES;
}

export function CommonModal({ modalType }: CommonModalProps) {
  const getContent = () => {
    switch (modalType) {
      case MODALTYPES.Payment:
        return <Paywall />;
      case MODALTYPES.SignUp:
      default:
        break;
    }
  };
  return (
    <Modal title="CommonModal" fullPage size="full">
      {getContent()}
    </Modal>
  );
}
