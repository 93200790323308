export interface LinkMap {
  url: string;
  isRemoved: boolean;
}

export function filterLinksToShow(urls: LinkMap[], max: number) {
  const filteredUrls: string[] = [];

  for (const each of urls) {
    if (filteredUrls.length === max) {
      break;
    }

    if (!each.isRemoved && filteredUrls.length < max) {
      filteredUrls.push(each.url);
    }
  }

  return filteredUrls;
}
