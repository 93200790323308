/* eslint-disable no-console */
import { isDevelopment } from '../isProduction';

export const logger = {
  error(...args: any) {
    console.error(...args);
  },

  log(...args: any) {
    console.log(...args);
  },

  warn(...args: any) {
    if (!isDevelopment()) {
      return;
    }
    console.warn(...args);
  },

  debug(...args: any) {
    if (!isDevelopment()) {
      return;
    }
    console.log(...args);
  }
};
