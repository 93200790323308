import { Box } from '@chakra-ui/react';
import { FeedContentType } from '@tigerhall/core';

import { useGetPostByIdQuery } from '../../../../../../queries/generated/post.generated';
import { Post, PostSkeleton } from '../../../Post';

interface AttachedPostProps {
  postId: string;
}

export function AttachedPost({ postId }: AttachedPostProps) {
  const { data, loading } = useGetPostByIdQuery({
    skip: !postId,
    variables: {
      id: postId,
      type: FeedContentType.Post
    }
  });

  const post = data?.feedContentById;

  if (post?.__typename !== 'Post') {
    return null;
  }

  return (
    <Box position="relative" width="100%" px={2}>
      {loading ? (
        <PostSkeleton />
      ) : (
        <Post post={post} disableActions disableMetrics disableOptions />
      )}
    </Box>
  );
}
