import * as React from 'react';
import { Flex, Stack, Text } from '@chakra-ui/react';
import { Button, StepIndicator } from '@tigerhall/ui-components';
import map from 'lodash/map';

import { type PrimaryButton } from '../ContentBox';
import ContentBoxTitle from './ContentBoxTitle';

function renderButtons(primaryButtons) {
  return map(primaryButtons, (item) => {
    const ButtonContainer = item?.container;

    const button = (
      <Button
        {...item}
        key={item.text}
        iconName={item.iconName}
        text={item.text}
        onClick={item.onClick}
        size="lg"
        isLoading={item.loading}
        data-cy={item.dataCy}
      />
    );
    return ButtonContainer ? (
      <ButtonContainer {...item.containerProps}>{button}</ButtonContainer>
    ) : (
      button
    );
  });
}

type StepperHeaderProps = {
  stepNames: string[];
  title?: string;
  description?: string;
  currentStep?: number;
  primaryButtons?: PrimaryButton[];
};

export function StepperHeader({
  stepNames,
  currentStep,
  title,
  primaryButtons,
  description
}: StepperHeaderProps) {
  return (
    <Stack
      backgroundColor="darkGrey.400"
      mb="8"
      py="8"
      flex={1}
      borderRadius="xl"
      justifyContent="center"
      alignItems="center"
    >
      <Flex mb="4" width="100%" alignItems="center" justifyContent="center">
        <StepIndicator
          stepKnobSize={20}
          showStepText={true}
          customStepperTexts={stepNames}
          currentStep={currentStep}
          stepCount={3}
          width="55%"
        />
      </Flex>

      {title ? (
        <Flex flexDirection="row" justifyContent="center">
          <ContentBoxTitle>Step {currentStep}:</ContentBoxTitle>
          <ContentBoxTitle color="White" ml={2}>
            {title}
          </ContentBoxTitle>
        </Flex>
      ) : null}

      {description ? (
        <Text
          fontSize="md"
          color="lightGrey.200"
          maxW={'46.25rem'}
          textAlign="center"
        >
          {description}
        </Text>
      ) : null}

      {primaryButtons ? (
        <Flex
          pt="8"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
        >
          {renderButtons(primaryButtons)}
        </Flex>
      ) : null}
    </Stack>
  );
}
