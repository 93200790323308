import type { ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button } from '@chakra-ui/react';
import { motion } from 'framer-motion';

interface DndSortableItemProps {
  id: string;
  isDisabled?: boolean;
  children: ReactNode;
}

export function DndSortableItem({
  id,
  isDisabled = false,
  children
}: DndSortableItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isSorting
  } = useSortable({ id, disabled: isDisabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div
      ref={setNodeRef}
      style={{
        position: 'relative',
        ...style
      }}
    >
      {children}
      {isDisabled || isSorting ? null : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.3
          }}
        >
          <Button
            ref={setActivatorNodeRef}
            {...attributes}
            {...listeners}
            zIndex="zIndexOne"
            position="absolute"
            top="1.25rem"
            left="1.25rem"
            variant="ghost"
            size="md"
            background="blackAlpha.700"
            _hover={{
              background: 'blackAlpha.700'
            }}
            border="1px solid"
            borderColor="lightGrey.600"
            borderRadius="lg"
            opacity="0.9"
          >
            Click to Drag
          </Button>
        </motion.div>
      )}
    </div>
  );
}
