import { PrivacySettings, User, UserRole } from '@tigerhall/core';
import {
  IconBuildingSkyscraper,
  IconSpeakerphone,
  IconBrandTigerhall,
  IconWorld
} from '@tigerhall/icons';

export function getAudienceIcon(privacySettings: PrivacySettings) {
  switch (privacySettings) {
    case PrivacySettings.Organisation:
      return IconBuildingSkyscraper;

    case PrivacySettings.OrganisationAnnouncement:
      return IconSpeakerphone;

    case PrivacySettings.PlatformAnnouncement:
      return IconBrandTigerhall;

    case PrivacySettings.Public:
    default:
      return IconWorld;
  }
}

export const PRIVACY_SETTINGS = {
  [PrivacySettings.Public]: {
    heading: 'Public',
    description: 'Your followers and anyone visiting your profile',
    segmentAudience: 'public' as const
  },
  [PrivacySettings.Organisation]: {
    heading: 'Organization Only',
    description:
      'Only people from your organisation that follow you or visit your profile',
    segmentAudience: 'org_only' as const
  },
  [PrivacySettings.OrganisationAnnouncement]: {
    heading: 'Organization Announcement',
    description:
      'Everyone in your organization, including those who do not follow you',
    segmentAudience: 'org_announcement' as const
  },
  [PrivacySettings.PlatformAnnouncement]: {
    heading: 'Tigerhall-Wide Announcement',
    description: 'Everyone on Tigerhall, including those who do not follow you',
    segmentAudience: 'tigerhall_announcement' as const
  }
};

export const ORG_REPOST_PRIVACY_SETTINGS: Record<
  Exclude<PrivacySettings, PrivacySettings.PublicThinkfluencerPost>,
  Exclude<PrivacySettings, PrivacySettings.PublicThinkfluencerPost>[]
> = {
  [PrivacySettings.Public]: [
    PrivacySettings.Public,
    PrivacySettings.Organisation
  ],
  [PrivacySettings.Organisation]: [PrivacySettings.Organisation],
  [PrivacySettings.OrganisationAnnouncement]: [PrivacySettings.Organisation],
  [PrivacySettings.PlatformAnnouncement]: [
    PrivacySettings.Public,
    PrivacySettings.Organisation
  ]
};

export type PublicPrivacySettings = Exclude<
  PrivacySettings,
  | PrivacySettings.Organisation
  | PrivacySettings.OrganisationAnnouncement
  | PrivacySettings.PublicThinkfluencerPost
>;

export const PUBLIC_REPOST_PRIVACY_SETTINGS: Record<
  PublicPrivacySettings,
  PublicPrivacySettings[]
> = {
  [PrivacySettings.Public]: [PrivacySettings.Public],
  [PrivacySettings.PlatformAnnouncement]: [PrivacySettings.Public]
};

export const PRIVACY_SETTINGS_PER_ROLE: Record<string, PrivacySettings[]> = {
  admin: [
    PrivacySettings.Public,
    PrivacySettings.Organisation,
    PrivacySettings.OrganisationAnnouncement,
    PrivacySettings.PlatformAnnouncement
  ],
  orgAdmin: [
    PrivacySettings.Public,
    PrivacySettings.Organisation,
    PrivacySettings.OrganisationAnnouncement
  ],
  orgMember: [PrivacySettings.Public, PrivacySettings.Organisation],
  user: [PrivacySettings.Public]
};

interface GetPrivacySettingsListProps extends Pick<User, 'roles'> {
  isOrgMember?: boolean;
  parentPrivacySetting?: PrivacySettings;
}

/**
 * Get the list of privacy settings that can be used to post or repost,
 * based on the user's role, organization membership, and parent post privacy setting.
 */
export function getPrivacySettingsList({
  roles,
  isOrgMember,
  parentPrivacySetting
}: GetPrivacySettingsListProps): PrivacySettings[] {
  const isOrgAdmin = roles?.includes(UserRole.OrgAdmin);
  const isSuperAdmin = roles?.includes(UserRole.Admin);

  if (parentPrivacySetting) {
    return isOrgMember
      ? ORG_REPOST_PRIVACY_SETTINGS[parentPrivacySetting]
      : PUBLIC_REPOST_PRIVACY_SETTINGS[parentPrivacySetting];
  }

  if (isSuperAdmin) {
    return PRIVACY_SETTINGS_PER_ROLE.admin;
  }

  if (isOrgAdmin) {
    return PRIVACY_SETTINGS_PER_ROLE.orgAdmin;
  }

  if (isOrgMember) {
    return PRIVACY_SETTINGS_PER_ROLE.orgMember;
  }

  return PRIVACY_SETTINGS_PER_ROLE.user;
}
