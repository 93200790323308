import { checkboxAnatomy as r } from "./index32.mjs";
import { defineStyle as e, createMultiStyleConfigHelpers as o } from "./index33.mjs";
const { definePartsStyle: t, defineMultiStyleConfig: i } = o(r.keys), n = t({
  control: e({
    boder: "1px solid",
    borderColor: "darkGrey.300",
    _checked: {
      backgroundColor: "tigerOrange.600",
      borderColor: "tigerOrange.600",
      _hover: {
        backgroundColor: "tigerOrange.600",
        borderColor: "tigerOrange.600"
      }
    }
  }),
  icon: e({
    color: "white",
    _checked: {
      color: "white"
    }
  })
}), a = i({
  baseStyle: {},
  variants: {
    tiger: n
  },
  defaultProps: {
    variant: "tiger"
  }
});
export {
  a as Checkbox
};
