import { Button, Flex } from '@chakra-ui/react';
interface InterestSelectorItemProps {
  topicName: string;
  emoji: string;
  isSelected: boolean;
  handleInterestSelector: () => void;
}

export function InterestSelectorItem({
  topicName,
  emoji,
  isSelected = true,
  handleInterestSelector
}: InterestSelectorItemProps) {
  const bgColor = isSelected ? '#fff' : 'darkGrey.300';
  const textColor = isSelected ? '#000' : '#fff';
  const borderColor = isSelected ? 'tigerOrange.600' : 'transparent';

  return (
    <Button
      variant="unstyled"
      size="lg"
      bgColor={bgColor}
      color={textColor}
      fontSize="sm"
      border="2px solid"
      borderColor={borderColor}
      borderRadius="lg"
      alignItems="center"
      p={2}
      data-cy="topic-cloud-item"
      _hover={{
        bgColor: 'darkGrey.400',
        color: 'white'
      }}
      onClick={handleInterestSelector}
    >
      <Flex gap="0.25rem" alignContent="center">
        <span>{emoji || '🚀'}</span>
        {topicName}
      </Flex>
    </Button>
  );
}
