import * as React from 'react';
import { Button, Heading, Text } from '@chakra-ui/react';
import { ResetPasswordContainer } from 'modules/authentication/modules/ResetPassword/components';
import { Link } from 'react-router-dom';

export function PasswordResetSuccessfully() {
  return (
    <ResetPasswordContainer>
      <Heading color="white" fontSize="1.125rem" textAlign="center" my="1rem">
        Password Has Been Reset
      </Heading>
      <Text
        color="lightGrey.900"
        fontSize="1rem"
        fontWeight={500}
        lineHeight="1.5rem"
        textAlign="center"
        mb="3rem"
      >
        Try logging in with your new password.
      </Text>

      <Link to="/login/email">
        <Button w="100%" size="lg">
          Let&apos;s Login
        </Button>
      </Link>
    </ResetPasswordContainer>
  );
}
