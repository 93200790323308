const e = {
  // style object for base or default style
  baseStyle: {
    fontSize: "3xl",
    fontWeight: "extrabold",
    lineHeight: "1.375"
    // same as 'short'
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {},
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    size: "",
    variant: "",
    colorScheme: ""
  }
};
export {
  e as Heading
};
