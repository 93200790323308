import { useGetCurrentUserQuery } from 'generated';
import { useMemo } from 'react';

enum ProgressStatus {
  VeryWeak = 'Very Weak 😣',
  Weak = 'Weak 😣',
  Good = 'Good',
  Great = 'Great',
  Perfect = 'Perfect'
}

type UpdateProfileMilestones = {
  [key: string]: string;
};

enum Milestones {
  AvatarAndCover = 'avatar_and_cover',
  JobTitle = 'jobTitle',
  Company = 'company',
  OnTheWeb = 'onTheWeb'
}

const MILESTONE_PROGRESS_UPDATE = 20;
const DEFAULT_PROGRESS = 20;

const getProgressStatus = (progress: number) => {
  switch (true) {
    case progress <= 20:
      return ProgressStatus.VeryWeak;
    case progress < 50:
      return ProgressStatus.Weak;
    case progress < 68:
      return ProgressStatus.Good;
    case progress < 100:
      return ProgressStatus.Great;
    case progress === 100:
      return ProgressStatus.Perfect;

    default:
      return ProgressStatus.VeryWeak;
  }
};

const calculateProgress = (profileConditions) => {
  let progress = DEFAULT_PROGRESS;

  const updateProfileMilestones: UpdateProfileMilestones = {
    [Milestones.AvatarAndCover]: 'Upload a profile image and cover image',
    [Milestones.JobTitle]: 'Complete the “Job Title” section',
    [Milestones.Company]: 'Complete the “Company Name” section',
    [Milestones.OnTheWeb]: 'Complete the “On the Web” section'
  };

  profileConditions.forEach(({ isMileStoneCompleted, milestoneKey }) => {
    if (isMileStoneCompleted) {
      progress += MILESTONE_PROGRESS_UPDATE;
      delete updateProfileMilestones[milestoneKey];
    }
  });

  return {
    progress,
    progressSuggesion: Object.values(updateProfileMilestones)[0],
    progressStatus: getProgressStatus(progress)
  };
};

export const useProfileProgressAndSuggetion = () => {
  const { data } = useGetCurrentUserQuery();

  const profileConditions = useMemo(() => {
    if (!data?.me) {
      return [];
    }

    const {
      me: {
        avatarImage,
        jobTitle,
        facebookLink,
        coverImage,
        instagramHandle,
        twitterHandle,
        company
      }
    } = data;

    return [
      {
        isMileStoneCompleted:
          avatarImage?.id &&
          avatarImage?.filename !== 'default-avatar.jpg' &&
          coverImage?.uri,
        milestoneKey: Milestones.AvatarAndCover
      },
      {
        isMileStoneCompleted: company,
        milestoneKey: Milestones.Company
      },
      {
        isMileStoneCompleted: jobTitle,
        milestoneKey: Milestones.JobTitle
      },
      {
        isMileStoneCompleted: facebookLink || instagramHandle || twitterHandle,
        milestoneKey: Milestones.OnTheWeb
      }
    ];
  }, [data]);

  return useMemo(
    () => calculateProgress(profileConditions),
    [profileConditions]
  );
};
