import { Flex } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { HeaderBox } from 'modules/application/modules/Profile/components/common/HeaderBox';
import ContentInfiniteScroll from 'modules/application/modules/Profile/screens/ContentCompleted/components/ContentInfiniteScroll';

export function ContentCompleted() {
  return (
    <>
      <Helmet>
        <title>Content Completed</title>
      </Helmet>
      <Flex
        w="100%"
        maxW="48rem"
        mx="auto"
        backgroundColor="darkGrey.700"
        h="fit-content"
        direction="column"
      >
        <HeaderBox title="Content Completed" />
        <Flex px={7} py={8} direction="column">
          <ContentInfiniteScroll />
        </Flex>
      </Flex>
    </>
  );
}
