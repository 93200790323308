import { anatomy as t } from "./index42.mjs";
t("accordion").parts("root", "container", "button", "panel").extend("icon");
t("alert").parts("title", "description", "container").extend("icon", "spinner");
t("avatar").parts("label", "badge", "container").extend("excessLabel", "group");
t("breadcrumb").parts("link", "item", "container").extend("separator");
t("button").parts();
var r = t("checkbox").parts("control", "icon", "container").extend("label");
t("progress").parts("track", "filledTrack").extend("label");
t("drawer").parts("overlay", "dialogContainer", "dialog").extend("header", "closeButton", "body", "footer");
t("editable").parts(
  "preview",
  "input",
  "textarea"
);
t("form").parts(
  "container",
  "requiredIndicator",
  "helperText"
);
t("formError").parts("text", "icon");
t("input").parts("addon", "field", "element");
t("list").parts("container", "item", "icon");
var a = t("menu").parts("button", "list", "item").extend("groupTitle", "command", "divider"), o = t("modal").parts("overlay", "dialogContainer", "dialog").extend("header", "closeButton", "body", "footer");
t("numberinput").parts(
  "root",
  "field",
  "stepperGroup",
  "stepper"
);
t("pininput").parts("field");
t("popover").parts("content", "header", "body", "footer").extend("popper", "arrow", "closeButton");
t("progress").parts(
  "label",
  "filledTrack",
  "track"
);
t("radio").parts(
  "container",
  "control",
  "label"
);
t("select").parts("field", "icon");
t("slider").parts(
  "container",
  "track",
  "thumb",
  "filledTrack",
  "mark"
);
t("stat").parts(
  "container",
  "label",
  "helpText",
  "number",
  "icon"
);
t("switch").parts(
  "container",
  "track",
  "thumb"
);
t("table").parts(
  "table",
  "thead",
  "tbody",
  "tr",
  "th",
  "td",
  "tfoot",
  "caption"
);
t("tabs").parts(
  "root",
  "tab",
  "tablist",
  "tabpanel",
  "tabpanels",
  "indicator"
);
t("tag").parts(
  "container",
  "label",
  "closeButton"
);
t("card").parts(
  "container",
  "header",
  "body",
  "footer"
);
export {
  r as checkboxAnatomy,
  a as menuAnatomy,
  o as modalAnatomy
};
