import { useMicrosoftTeams } from '@tigerhall/core';
import { useBreakpointValue } from '@chakra-ui/react';

import { HEADER_HEIGHT } from '../components/ui/Layout';

export function useLayoutDimensions() {
  const { isInTeams } = useMicrosoftTeams();

  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false
    },
    {
      ssr: false,
      fallback: 'md'
    }
  );

  return {
    isMobile,
    headerHeight: isInTeams ? 0 : HEADER_HEIGHT,
    contentHeight: isInTeams ? '100vh' : `calc(100vh - ${HEADER_HEIGHT})`
  };
}
