import { HStack, Text, VStack } from '@chakra-ui/react';
import type { PlaylistType } from '@tigerhall/analytics';
import { useEffect, useState } from 'react';

import { CompletedBadge } from '~/badges';
import { CountDownTimer } from '~/player/components/CountDownTimer';
import { TrackedButton } from '~/tracked';

export interface OverlayBodyContainerProps {
  onAutoNextPlay: (value: boolean) => void;
  isLastContentInPlaylist: boolean;
  playlistType?: PlaylistType;
}

export function OverlayBodyContainer({
  onAutoNextPlay,
  isLastContentInPlaylist,
  playlistType
}: Readonly<OverlayBodyContainerProps>) {
  const [shouldShow, setShouldShow] = useState(true);
  const [nextPlayTimeoutId, setNextPlayTimeoutId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onAutoNextPlay(true);
    }, 5000);
    setNextPlayTimeoutId(timeoutId);

    return () => clearTimeout(timeoutId);
  }, [onAutoNextPlay]);

  const handleStayOnPage = () => {
    setShouldShow(false);
    onAutoNextPlay(false);
    clearTimeout(nextPlayTimeoutId);
  };

  return (
    <VStack
      justifyContent={'flex-start'}
      w={'100%'}
      gap={5}
      display={shouldShow ? 'flex' : 'none'}
    >
      <CompletedBadge />
      {isLastContentInPlaylist || !playlistType ? null : (
        <VStack bg={'blackAlpha.700'} borderRadius={8} gap={2} p={3}>
          <HStack gap={2}>
            <Text fontWeight={'bold'} color={'white'} fontSize={'sm'}>
              Next Piece starts in
            </Text>
            <CountDownTimer />
          </HStack>
          <TrackedButton
            name="STAY_ON_SAME_CONTENT"
            textDecoration={'underline'}
            cursor={'pointer'}
            variant={'link'}
            color={'lightGrey.900'}
            onClick={handleStayOnPage}
          >
            Stay on this page
          </TrackedButton>
        </VStack>
      )}
    </VStack>
  );
}
