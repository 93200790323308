import * as React from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { CommentItemSkeleton } from 'components/ui/Comments/CommentItem/CommentItemSkeleton';

export function ActivityCommentSkeleton() {
  return (
    <Center
      py="1.25rem"
      px={{
        base: '1rem',
        md: '2rem'
      }}
      borderTop="1px solid"
      borderTopColor="darkGrey.400"
    >
      <Flex gap="1rem" width="100%" flexDirection="column">
        <CommentItemSkeleton />
        <CommentItemSkeleton />
      </Flex>
    </Center>
  );
}
