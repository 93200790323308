import { Text, useColorModeValue } from '@chakra-ui/react';

export function TextTime({ time }: { time: number }) {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);

  const elementColor = useColorModeValue('black', 'white');

  return (
    <Text
      as={'span'}
      fontSize={'xs'}
      fontWeight={400}
      fontFamily={'mono'}
      color={elementColor}
      lineHeight={1.2}
    >
      {minutes.toString().padStart(2, '0')}:
      {seconds.toString().padStart(2, '0')}
    </Text>
  );
}
