import { Navigate, RouteObject, useLocation } from 'react-router-dom';
import {
  type SubscriptionType,
  redirectToStripePaymentGateway,
  useLastLocation,
  useMicrosoftTeams
} from '@tigerhall/core';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import {
  getAccessToken,
  getCustomerType,
  getOnboardingStatus
} from 'app/state';
import { useAppSelector } from 'hooks';

import { Login, LoginWithEmail } from './screens';

/**
 * Redirect from the old sso success url to the new one
 *
 * Stupidly enough backend appends `/sso-success` to the redirect url
 * So we need to keep this in place until the issue with backend can be fixed
 *
 * @todo: nuke the shit out of this
 */
function RedirectSSOSuccess() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  return <Navigate to={`/login?token=${token}`} replace />;
}

function RedirectOnAuthenticated({ children }) {
  const token = useAppSelector(getAccessToken);
  const isOnboarding = useAppSelector(getOnboardingStatus);
  const { lastLocation } = useLastLocation();

  // todo: please try to remove the `as` later
  const customerType = (
    useAppSelector(getCustomerType) || 'INDIVIDUAL'
  ).toLowerCase() as SubscriptionType;

  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const promoCode = params.get('promo-code');

  if (token) {
    if (promoCode) {
      redirectToStripePaymentGateway({
        origin: import.meta.env.VITE_API_URL || '',
        token,
        subscriptionType: customerType,
        successRedirectUrl: `${
          import.meta.env.VITE_WEBAPP_URL
        }/signup/${customerType}/confirmation`,
        cancelRedirectUrl: `${import.meta.env.VITE_WEBAPP_URL}/home`
      });
      return null;
    }

    return isOnboarding ? (
      <Navigate to="/signup/individual/welcome" replace />
    ) : (
      <>
        <Navigate to="/home" replace />
        {lastLocation ? <Navigate to={lastLocation} /> : null}
      </>
    );
  }

  return children;
}

function RedirectToTeamsIfInContext({ children }) {
  const { isInTeams } = useMicrosoftTeams();

  if (isInTeams) {
    return <Navigate to="/msteams/login" replace />;
  }

  return children;
}

export const routes: RouteObject[] = [
  {
    path: '/login',
    element: (
      <RedirectOnAuthenticated>
        <RedirectToTeamsIfInContext>
          <Login />
        </RedirectToTeamsIfInContext>
      </RedirectOnAuthenticated>
    )
  },
  {
    path: '/login/email',
    element: <LoginWithEmail />
  },
  {
    path: '/login/linkedin/callback',
    element: <LinkedInCallback />
  },
  {
    path: '/login/sso-success',
    element: <RedirectSSOSuccess />
  },
  {
    path: '/sso-success',
    element: <RedirectSSOSuccess />
  }
];
