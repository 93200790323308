import { Box, Flex, Text } from '@chakra-ui/react';
import { type Ebook, type User } from '@tigerhall/core/lib/types';
import { CategoryCloud } from '@tigerhall/ui-components';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { useGetContentCardsQuery } from 'generated';
import { ContentBarWithLinks } from 'components/ui/ContentBox/ContentBarWithLinks';

import NoDataTab from './NoDataTab';
import ExpertContentRatings from './ExpertContentRating';

type ContentExpertiseTabTypes = {
  user: User;
};

const ContentExpertiseTab = ({ user }: ContentExpertiseTabTypes) => {
  const navigate = useNavigate();

  const { data } = useGetContentCardsQuery({
    variables: {
      filter: {
        experts: [user?.id]
      }
    }
  });

  const upVoteCount = useMemo(() => {
    const upVotes = map(
      data?.contentCards?.edges,
      (edge: Ebook) => edge?.upvoteCount
    );

    return upVotes.reduce((partialSum, a) => partialSum + Number(a), 0);
  }, [data?.contentCards?.edges]);

  const events = filter(
    data?.contentCards?.edges,
    (content) => content?.__typename === 'Event'
  );

  const nonEvents = filter(
    data?.contentCards?.edges,
    (content) => content?.__typename !== 'Event'
  );

  if (isEmpty(data?.contentCards?.edges)) {
    return <NoDataTab title="content" />;
  }

  return (
    <>
      <Flex direction="column" px="2rem" pt="1.5rem">
        <ExpertContentRatings upvotes={upVoteCount} />
        {user?.recentlyConsumedCategories.length > 0 && (
          <Box mt="1.5rem">
            <Text fontSize="lg" color="lightGrey.200" fontWeight="bold">
              Expertise
            </Text>
            <CategoryCloud
              categories={user?.recentlyConsumedCategories}
              onClick={(id) => navigate(`/content/category/${id}`)}
            />
          </Box>
        )}

        {events?.length > 0 && (
          <Box mt={6} mb={2}>
            <Text fontSize="lg" color="lightGrey.200" fontWeight="bold" mb={4}>
              {`Experiences with ${user?.firstName} ${user?.lastName}`}
            </Text>

            {map(events, (event, index) => (
              <Box mb={4} key={event.id}>
                <ContentBarWithLinks
                  sectionName="Experiences by User"
                  sectionCardIndex={index}
                  {...event}
                />
              </Box>
            ))}
          </Box>
        )}

        {nonEvents?.length > 0 && (
          <Box mt={6} mb={2}>
            <Text
              variant="detail1"
              color="lightGrey.200"
              fontWeight={700}
              mb={4}
            >
              {`Content by ${user?.firstName} ${user?.lastName}`}
            </Text>

            {map(nonEvents, (nonEvent, index) => (
              <Box mb={4} key={nonEvent.id}>
                <ContentBarWithLinks
                  sectionName="Content by User"
                  sectionCardIndex={index}
                  {...nonEvent}
                />
              </Box>
            ))}
          </Box>
        )}
      </Flex>
    </>
  );
};

export default ContentExpertiseTab;
