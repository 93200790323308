import { Share } from '@tigerhall/components';
import { type User } from '@tigerhall/core';
import { Box } from '@chakra-ui/react';
import { OptionsMenuList } from 'components/ui/OptionsMenu';

import { ShareProfileOption } from './ActionOptions/ShareProfileOption';
import { BlockUnblockProfileOption } from './ActionOptions/BlockUnblockProfileOption';
import { ReportProfileOption } from './ActionOptions/ReportProfileOption';

export interface ActionsProps {
  isSelfProfile: boolean;
  user: Pick<
    User,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'biography'
    | 'isExpert'
    | 'isBlockingCurrentUser'
    | 'isBlockedByCurrentUser'
  >;
}

export function Actions({ isSelfProfile, user }: Readonly<ActionsProps>) {
  if (isSelfProfile) {
    return (
      <Share
        variant="outline"
        size="lg"
        padding="0.5rem"
        borderRadius="full"
        urlToShare={`${window.location.origin}/profile/${user.id}`}
        titleToShare={`${user.firstName} ${user.lastName}`}
        descriptionToShare={`${user.biography}`}
        aria-label="Share"
        color="white"
      />
    );
  }
  return (
    <Box
      display="flex"
      borderRadius="full"
      borderColor="white"
      borderWidth="1px"
    >
      <OptionsMenuList buttonSize="lg" onOpen={() => {}}>
        <ShareProfileOption user={user} />
        <ReportProfileOption userId={user.id} />
        <BlockUnblockProfileOption user={user} />
      </OptionsMenuList>
    </Box>
  );
}
