import { useMemo, useState } from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import { RichTextMarkdownWrapper } from 'components/ui/MarkdownWrapper/RichTextMarkdownWrapper';
import { CollapsibleMarkdownReader } from 'components/ui/MarkdownWrapper/CollapsibleMarkdownReader';

const CHARACTER_COUNT_LIMIT = 250;

type AboutSectionProps = {
  readonly aboutContent: string;
  readonly hasAbout?: boolean;
  // temporary applied this props because it  was breaking in profile page
  readonly shouldApplyShowMore?: boolean;
};

export function AboutSection({
  aboutContent,
  hasAbout = true,
  shouldApplyShowMore = true
}: AboutSectionProps) {
  const [isShowMore, setIsShowMore] = useState(false);

  const isAboutLess = useMemo(
    () => aboutContent?.length <= CHARACTER_COUNT_LIMIT,
    [aboutContent?.length]
  );

  return (
    <VStack w="full" spacing="1rem" alignItems="flex-start">
      {hasAbout ? (
        <Text color="lightGrey.200" fontWeight="bold" fontSize="2xl">
          About
        </Text>
      ) : null}
      {shouldApplyShowMore ? (
        <>
          <CollapsibleMarkdownReader isShowMore={isShowMore}>
            {aboutContent}
          </CollapsibleMarkdownReader>
          {!isAboutLess && (
            <Box textAlign="center" alignSelf="flex-end">
              <Text
                role="button"
                mb="1rem"
                color="tigerOrange.600"
                fontSize="sm"
                fontWeight="bold"
                cursor="pointer"
                onClick={() => setIsShowMore(!isShowMore)}
                whiteSpace="pre-wrap"
              >
                {`Show ${isShowMore ? 'less' : 'more'}`}
              </Text>
            </Box>
          )}
        </>
      ) : (
        <RichTextMarkdownWrapper>{aboutContent}</RichTextMarkdownWrapper>
      )}
    </VStack>
  );
}
