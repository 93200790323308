import { MentionItem } from 'react-mentions';
import * as segment from '@tigerhall/analytics';

import { HandleRemovedMentionsEventProps } from './types';

const getRemovedMentions = ({
  mentionsPreviousState,
  mentions
}: Pick<
  HandleRemovedMentionsEventProps,
  'mentionsPreviousState' | 'mentions'
>): MentionItem[] => {
  const prevMentions = mentionsPreviousState.current.mentions;
  const newMentionsIds = mentions.map((ele) => ele.id);

  return prevMentions.filter((ele) => !newMentionsIds.includes(ele.id));
};

export const handleMentionsRemovedEvent = ({
  mentionsPreviousState,
  mentions,
  ...rest
}: HandleRemovedMentionsEventProps): void => {
  const prevMentionsLength = mentionsPreviousState.current.mentions.length;
  const newMentionsLength = mentions.length;

  const isChanged = mentionsPreviousState.current.length !== newMentionsLength;
  const isRemoved = prevMentionsLength > newMentionsLength;

  if (isChanged) {
    if (isRemoved) {
      const removedMentions = getRemovedMentions({
        mentionsPreviousState,
        mentions
      });
      removedMentions.forEach((removedMention) => {
        segment.mentionRemoved({
          ...rest,
          mentionedUserId: removedMention.id,
          mentionedUserName: removedMention.display.replace(/@/, '')
        });
      });
    }
    mentionsPreviousState.current.mentions = mentions;
    mentionsPreviousState.current.length = newMentionsLength;
  }
};

export const handleMentionsStartEvent = ({
  e,
  referenceId
}: {
  e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>;
  referenceId?: string;
}) => {
  if (e.key === '@') {
    segment.mentionStarted({ referenceId, location: window.location.pathname });
  }
};

export const handleMentionFinised = (referenceId?: string) => {
  segment.mentionFinished({
    location: window.location.pathname,
    referenceId: referenceId ?? ''
  });
};
