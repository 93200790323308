import {
  Box,
  Button,
  InputGroup,
  InputRightElement,
  useBreakpointValue
} from '@chakra-ui/react';
import { useCallback, useState, useTransition } from 'react';
import isEmpty from 'lodash/isEmpty';
import { FormAutoResizableTextarea, TrackedForm } from '@tigerhall/components';
import { usePostMessageMutation } from 'generated';
import { EmojisPopover } from 'components/ui/EmojisPopover';

interface ChatInputProps {
  streamId: string;
  onInputFocusChange?: (focused: boolean) => void;
  showSendButton?: boolean;
}

export function ChatInput({
  streamId,
  onInputFocusChange,
  showSendButton = true
}: Readonly<ChatInputProps>) {
  const [postMessageMutation, { loading: isPostingMessage }] =
    usePostMessageMutation({});
  const [, startTransition] = useTransition();

  const [selectionStart, setSelectionStart] = useState(0);

  const addChatMessage = useCallback(
    (value: string) => {
      const comment = value;
      if (isEmpty(comment.replace(/ /g, ''))) {
        return;
      }
      postMessageMutation({
        variables: {
          content: comment,
          streamId
        }
      });
    },
    [postMessageMutation, streamId]
  );

  const isMobile = useBreakpointValue(
    { base: true, lg: false },
    {
      fallback: 'lg',
      ssr: false
    }
  );

  const inputStyles = isMobile
    ? {
        background: 'transparent',
        borderRadius: '3rem',
        minHeight: 'auto',
        height: '2.5rem'
      }
    : {};

  return (
    <Box
      paddingY={3}
      paddingX={4}
      borderTop={isMobile ? 'none' : 'solid 1px'}
      borderColor={'darkGrey.400'}
      position={'relative'}
      width={'full'}
      flex={'1'}
      bg={isMobile ? 'transparent' : 'darkGrey.700'}
    >
      <TrackedForm
        name="LIVESTREAM_COMMENTS"
        onSubmit={(formValues, form) => {
          addChatMessage(formValues.commentInput);
          form.setValue('commentInput', '');
        }}
      >
        {({ setValue, getValues }) => {
          return (
            <InputGroup>
              <FormAutoResizableTextarea
                placeholder="Add a comment..."
                name="commentInput"
                label={''}
                style={{
                  paddingRight: '6.5rem',
                  ...inputStyles
                }}
                onSelect={(event) => {
                  const target = event.target as HTMLTextAreaElement;
                  setSelectionStart(target.selectionStart);
                }}
                onBlur={() => {
                  /**
                   * Here we use `startTransition` to make sure the focus change
                   * occurs after the `submit` event is triggered.
                   *
                   * If we don't do this, the `submit` button will be unmounted as soon
                   * as it's clicked, because the input's `onBlur` event will be triggered before
                   * the `submit` event.
                   */
                  startTransition(() => {
                    onInputFocusChange?.(false);
                  });
                }}
                onFocus={() => onInputFocusChange?.(true)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();

                    const value = getValues('commentInput');
                    addChatMessage(value);
                    setValue('commentInput', '');
                  }
                }}
              />
              {showSendButton ? (
                <InputRightElement
                  width={'fit-content'}
                  pr={'0.5rem'}
                  height={isMobile ? '2.4rem' : '100%'}
                >
                  <EmojisPopover
                    onEmojiClick={(data) => {
                      const value = getValues('commentInput');

                      // Insert the emoji at the current cursor position
                      const newValue =
                        value.slice(0, selectionStart) +
                        data.emoji +
                        value.slice(selectionStart);

                      setValue('commentInput', newValue);

                      // Move the cursor to the right of the emoji that was just inserted
                      setSelectionStart(selectionStart + data.emoji.length);
                    }}
                  />
                  <Button
                    type="submit"
                    variant="ghost"
                    isLoading={isPostingMessage}
                    size="sm"
                    fontSize="sm"
                  >
                    Send
                  </Button>
                </InputRightElement>
              ) : null}
            </InputGroup>
          );
        }}
      </TrackedForm>
    </Box>
  );
}
