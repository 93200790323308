import { css } from '@emotion/react';

export const styles = css`
  // Ugly hack to get the navigation styling to work since we cannot access is-active
  .navigation-item-active > p {
    font-weight: bold;
    border-bottom: 2px solid #ff8615;
    color: #fff;
  }

  .recharts-text,
  .recharts-layer {
    font-size: 14px;
  }

  body *:focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }

  .animate-shimmer {
    position: relative;

    &:after {
      content: ' ';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      animation: shimmer 2s infinite;
      background: linear-gradient(
        to right,
        #ffffff87 0%,
        #ffffff00 25%,
        #ffffff87 100%
      );
      background-size: 1000px 100%;
      opacity: 0.65;
      mix-blend-mode: overlay;
    }
  }

  .tg-file-uploader-holder {
    width: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .editProfileUploader {
    z-index: 1;
  }
  p {
    margin: 0;
  }

  text {
    fill: white;
    font-weight: 700;
  }
`;
