import { FormSelect } from '@tigerhall/components';

export default function DraftSelect({
  options
}: {
  options: { value: string; label: string }[];
}) {
  return (
    <FormSelect
      name="draft"
      isClearable={false}
      label="Draft"
      options={options}
      getOptionValue={(option) => option.value}
      getOptionLabel={(option) => option.label}
      placeholder=""
    />
  );
}
