import { ContentType, StreamSource } from '@tigerhall/core';
import { PlayingContent } from 'app/state';

export enum AvailableTabs {
  UpNext = 'UP_NEXT',
  Suggested = 'SUGGESTED',
  Comments = 'COMMENTS',
  Chat = 'CHAT',
  MoreInfo = 'MORE_INFO',
  LiveMoreInfo = 'LIVE_MORE_INFO',
  ChatReplay = 'CHAT_REPLAY'
}

export const TABS_LABELS: Record<AvailableTabs, string> = {
  [AvailableTabs.UpNext]: 'Up Next',
  [AvailableTabs.Suggested]: 'Suggested',
  [AvailableTabs.Comments]: 'Comments',
  [AvailableTabs.Chat]: 'Chat',
  [AvailableTabs.MoreInfo]: 'More Info',
  [AvailableTabs.LiveMoreInfo]: 'More Info',
  [AvailableTabs.ChatReplay]: 'Chat Replay'
};

export interface GetPlayerTabsArgs extends Pick<PlayingContent, 'type'> {
  isPlaylist: boolean;
  isLive: boolean;
  isMobile?: boolean;
  source?: StreamSource;
}

export function getPlayerTabs({
  isPlaylist,
  isLive,
  type,
  source,
  isMobile
}: Readonly<GetPlayerTabsArgs>) {
  switch (type) {
    case StreamSource.BroadcastCloudflareLive:
    case StreamSource.BroadcastAwsIvs:
    case StreamSource.LivestreamAgora:
      return isLive
        ? isMobile
          ? [AvailableTabs.LiveMoreInfo]
          : [AvailableTabs.Chat, AvailableTabs.LiveMoreInfo]
        : [
            isPlaylist ? AvailableTabs.UpNext : AvailableTabs.Suggested,
            AvailableTabs.MoreInfo
          ];
    case ContentType.Stream: {
      const tabsToShow = [
        isPlaylist ? AvailableTabs.UpNext : AvailableTabs.Suggested,
        AvailableTabs.Comments,
        AvailableTabs.MoreInfo
      ];

      if (source === StreamSource.LivestreamAgora) {
        if (isMobile) {
          return tabsToShow;
        }
        // Add ChatReplay Tab when livestream on desktop
        tabsToShow.splice(1, 0, AvailableTabs.ChatReplay);
        return tabsToShow;
      }

      return tabsToShow;
    }

    case ContentType.Podcast:
    case ContentType.Ebook:
    default:
      return [
        isPlaylist ? AvailableTabs.UpNext : AvailableTabs.Suggested,
        AvailableTabs.Comments,
        AvailableTabs.MoreInfo
      ];
  }
}
