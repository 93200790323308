import { FormSelectAsync } from '@tigerhall/components';
import { User } from '@tigerhall/core/lib/types';
import { getSelectedOrgId } from 'app/state';
import { useGetOrganisationMembersLazyQuery } from 'generated';
import { useAppSelector } from 'hooks';
import property from 'lodash/property';
import * as React from 'react';
import { ValidationRule } from 'react-hook-form';

type Props = {
  name: string;
  label?: string;
  isMulti?: boolean;
  required?: ValidationRule<boolean> | string;
  placeholder?: string;
  /**
   * Hide the label
   * @default false
   */
  hideLabel?: boolean;
};

const LIMIT = 20;

export function OrganisationMemberSelect({
  name,
  label = undefined,
  required = false,
  isMulti = false,
  placeholder,
  hideLabel
}: Props) {
  const org = useAppSelector(getSelectedOrgId);

  const [getOrgMembers, { data, error, loading, fetchMore }] =
    useGetOrganisationMembersLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        id: org as string,
        filter: {
          limit: LIMIT
        }
      }
    });

  React.useEffect(() => {
    getOrgMembers();
  }, [getOrgMembers]);

  if (error) {
    return null;
  }

  const meta = data?.organisation?.members.meta || {
    total: 0,
    offset: 0,
    limit: 0
  };

  const onMenuScrollToBottom = () => {
    const hasFetchMore = !loading && meta?.total > meta?.offset + meta?.limit;

    if (hasFetchMore) {
      fetchMore({
        variables: {
          id: org as string,
          filter: {
            offset: meta.offset + meta.limit,
            limit: LIMIT
          }
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          return {
            organisation: {
              ...prev.organisation,
              members: {
                ...prev.organisation.members,
                edges: [
                  ...prev.organisation.members.edges,
                  ...fetchMoreResult.organisation.members.edges
                ],
                meta: fetchMoreResult.organisation.members.meta
              }
            }
          };
        }
      });
    }
  };

  return (
    <FormSelectAsync
      name={name}
      placeholder={placeholder ?? 'Select users'}
      label={label !== undefined ? label : isMulti ? 'Users' : 'User'}
      hideLabel={hideLabel}
      isMulti={isMulti}
      rules={{ required }}
      onMenuScrollToBottom={onMenuScrollToBottom}
      getOptionLabel={(u) => (u ? `${u.firstName} ${u.lastName}` : '')}
      getOptionValue={property('id')}
      defaultOptions={(data?.organisation?.members.edges || []) as User[]}
      isLoading={loading}
      hideSelectedOptions={false}
      loadOptions={(input: string, callback: (options: User[]) => void) => {
        getOrgMembers({
          variables: {
            id: org as string,
            filter: {
              name: input,
              limit: LIMIT
            }
          }
        }).then(({ data: newResults }) => {
          callback((newResults?.organisation?.members.edges || []) as User[]);
        });
      }}
    />
  );
}
