import * as types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const LiveGuestFragmentDoc = gql`
    fragment LiveGuest on Expert {
  id
  firstName
  lastName
  image {
    id
    uri
  }
  title
  company
  isAuthenticatedUserFollowing
}
    `;
export const ImageFragmentDoc = gql`
    fragment Image on Image {
  id
  uri
  alt
  filename
}
    `;
export const LimitedUserFragmentDoc = gql`
    fragment LimitedUser on User {
  __typename
  id
  avatarImage {
    ...Image
  }
  firstName
  lastName
  company
  jobTitle
  isFollowing
  isExpert
  expertOrg {
    id
  }
}
    ${ImageFragmentDoc}`;
export const LiveBroadcastAgendaFragmentDoc = gql`
    fragment LiveBroadcastAgenda on StreamAgendaBulletPoint {
  topic
  description
  isActive
  secondsIntoRecordingTopicStartsAt
  estimatedStartTime
  speakers {
    ...LimitedUser
  }
}
    ${LimitedUserFragmentDoc}`;
export const CategoryFragmentDoc = gql`
    fragment Category on Category {
  id
  name
  image {
    ...Image
  }
}
    ${ImageFragmentDoc}`;
export const ExpertFragmentDoc = gql`
    fragment Expert on Expert {
  id
  title
  firstName
  lastName
  biography
  createdAt
  updatedAt
  mentorAvailableText
  mentorPriceText
  isMentor
  isAuthenticatedUserFollowing
  company
  image {
    ...Image
  }
  categories {
    ...Category
  }
  userId
}
    ${ImageFragmentDoc}
${CategoryFragmentDoc}`;
export const UserContentTrackingFragmentDoc = gql`
    fragment UserContentTracking on UserContentTracking {
  __typename
  id
  current
  currentPlacement
  total
  isAttending
  isFinished
  bookmarkedAt
  lastOpenedAt
  finishedAt
}
    `;
export const PodcastFragmentDoc = gql`
    fragment Podcast on Podcast {
  __typename
  id
  name
  preamble
  entryLevel
  length
  isFree
  isAssigned
  createdAt
  updatedAt
  publishedAt
  hostedBy {
    ...LimitedUser
  }
  rating
  image {
    id
    uri
    alt
  }
  categories {
    id
    name
  }
  contentSegments {
    header
    paragraph
  }
  episodes {
    order
    name
    language
    audio {
      id
      uri
      length
      originalName
    }
  }
  experts {
    ...Expert
  }
  userContentTracking {
    ...UserContentTracking
  }
  audioAvailableLanguages {
    name
    code
  }
  transcriptAvailableLanguages {
    name
    code
  }
  transcripts {
    language
    transcripts {
      index
      page
    }
  }
  organisation {
    id
  }
  upvoteCount
  contentRatingByUser
}
    ${LimitedUserFragmentDoc}
${ExpertFragmentDoc}
${UserContentTrackingFragmentDoc}`;
export const EbookFragmentDoc = gql`
    fragment Ebook on Ebook {
  __typename
  id
  name
  isFree
  preamble
  entryLevel
  length
  readingTime
  isAssigned
  availableLanguages {
    code
    name
  }
  createdAt
  updatedAt
  publishedAt
  rating
  categories {
    id
    name
  }
  image {
    id
    uri
    alt
  }
  contentSegments {
    header
    paragraph
  }
  availableLanguages {
    code
    name
  }
  chapters {
    name
    index
    pages
  }
  experts {
    ...Expert
  }
  publishedAt
  userContentTracking {
    ...UserContentTracking
  }
  organisation {
    id
  }
  length
  organisationGroups {
    id
    name
  }
  upvoteCount
  contentRatingByUser
}
    ${ExpertFragmentDoc}
${UserContentTrackingFragmentDoc}`;
export const EventBaseFragmentDoc = gql`
    fragment EventBase on Event {
  id
  name
  eventType
  preamble
  startsAt
  endsAt
  isAssigned
  image {
    id
    uri
    alt
  }
  experts {
    ...Expert
  }
  categories {
    id
    name
  }
  locationDisplayName
  upvoteCount
  contentRatingByUser
}
    ${ExpertFragmentDoc}`;
export const EventFragmentDoc = gql`
    fragment Event on Event {
  __typename
  ...EventBase
  entryLevel
  maxAttendees
  attendeeCount
  publishedAt
  isFeatured
  isFree
  ticketPrice
  ticketCurrency
  questionnaire
  waitListEnabled
  isOnline
  conferenceUrl
  waitList(withHidden: true) {
    isAttending
    user {
      id
    }
  }
  location {
    name
    coordinates {
      lat
      lon
    }
    city
  }
  contentSegments {
    header
    paragraph
  }
  organisation {
    id
  }
  experts {
    ...Expert
  }
  userContentTracking {
    ...UserContentTracking
  }
}
    ${EventBaseFragmentDoc}
${ExpertFragmentDoc}
${UserContentTrackingFragmentDoc}`;
export const StreamFragmentDoc = gql`
    fragment Stream on Stream {
  __typename
  id
  status
  name
  source
  preamble
  entryLevel
  length
  isFree
  rating
  activityCount
  wentLiveAt
  createdAt
  updatedAt
  publishedAt
  isAssigned
  image {
    ...Image
  }
  categories {
    ...Category
  }
  channel
  videoUrl
  video {
    url
    language
    length
    publishedAt
  }
  contentSegments {
    header
    paragraph
  }
  experts {
    ...Expert
  }
  hosts {
    expert {
      ...Expert
    }
    userId
    uid
    accepted
    order
    isFeatured
  }
  audioAvailableLanguages {
    name
    code
  }
  subtitleAvailableLanguages {
    name
    code
  }
  hostedBy {
    ...LimitedUser
  }
  createdBy {
    ...Expert
  }
  userContentTracking {
    ...UserContentTracking
  }
  organisation {
    id
  }
  organisationGroups {
    id
    name
  }
  upvoteCount
  contentRatingByUser
}
    ${ImageFragmentDoc}
${CategoryFragmentDoc}
${ExpertFragmentDoc}
${LimitedUserFragmentDoc}
${UserContentTrackingFragmentDoc}`;
export const RecommendedContentFragmentDoc = gql`
    fragment RecommendedContent on ContentCard {
  ... on Podcast {
    ...Podcast
  }
  ... on Ebook {
    ...Ebook
  }
  ... on Event {
    ...Event
  }
  ... on Stream {
    ...Stream
  }
}
    ${PodcastFragmentDoc}
${EbookFragmentDoc}
${EventFragmentDoc}
${StreamFragmentDoc}`;
export const RecommendedContentConnectionFragmentDoc = gql`
    fragment RecommendedContentConnection on ContentConnection {
  edges {
    ...RecommendedContent
  }
  meta {
    limit
    offset
    recommendationId
    total
  }
}
    ${RecommendedContentFragmentDoc}`;
export const LiveVideoExpertsFragmentDoc = gql`
    fragment LiveVideoExperts on Expert {
  id
  firstName
  lastName
  image {
    id
    uri
  }
  title
  company
  isAuthenticatedUserFollowing
}
    `;
export const UserBaseProfileFragmentDoc = gql`
    fragment UserBaseProfile on User {
  __typename
  id
  firstName
  lastName
  company
  jobTitle
  isExpert
  isFollowing
  avatarImage {
    id
    uri
    alt
  }
  expertOrg {
    id
  }
  isBlockedByCurrentUser
  isBlockingCurrentUser
}
    `;
export const LiveStreamFragmentDoc = gql`
    fragment LiveStream on Stream {
  __typename
  id
  status
  name
  source
  preamble
  entryLevel
  length
  isFree
  rating
  activityCount
  wentLiveAt
  createdAt
  updatedAt
  publishedAt
  image {
    ...Image
  }
  categories {
    ...Category
  }
  channel
  videoUrl
  video {
    url
    language
    length
    publishedAt
  }
  contentSegments {
    header
    paragraph
  }
  experts {
    ...Expert
  }
  hosts {
    expert {
      ...Expert
    }
    userId
    uid
    accepted
    order
    isFeatured
  }
  audioAvailableLanguages {
    name
    code
  }
  subtitleAvailableLanguages {
    name
    code
  }
  hostedBy {
    ...LimitedUser
  }
  createdBy {
    ...Expert
  }
  userContentTracking {
    ...UserContentTracking
  }
  organisation {
    id
  }
  organisationGroups {
    id
    name
  }
  upvoteCount
  contentRatingByUser
}
    ${ImageFragmentDoc}
${CategoryFragmentDoc}
${ExpertFragmentDoc}
${LimitedUserFragmentDoc}
${UserContentTrackingFragmentDoc}`;
export const ReplyFragmentDoc = gql`
    fragment Reply on Comment {
  __typename
  id
  user {
    ...LimitedUser
  }
  parent {
    id
  }
  comment
  rawText
  mentions {
    index
    targetId
    mentionType
  }
  createdAt
  updatedAt
  usersReactToTheComment {
    totalCount
    reactions {
      emoji
    }
    userReacted {
      id
      reactionType
    }
  }
}
    ${LimitedUserFragmentDoc}`;
export const SharedCommentFragmentDoc = gql`
    fragment SharedComment on Comment {
  ...Reply
  numberOfReplies
  lastReply {
    ...Reply
  }
}
    ${ReplyFragmentDoc}`;
export const CommentFragmentDoc = gql`
    fragment Comment on Comment {
  ...SharedComment
  belongTo {
    ... on Ebook {
      organisation {
        id
      }
    }
    ... on Event {
      organisation {
        id
      }
    }
    ... on Stream {
      organisation {
        id
      }
    }
    ... on Podcast {
      organisation {
        id
      }
    }
  }
}
    ${SharedCommentFragmentDoc}`;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  limit
  offset
  total
  recommendationId
}
    `;
export const LimitedContentCardFragmentDoc = gql`
    fragment LimitedContentCard on ContentCard {
  ... on Podcast {
    id
    length
  }
  ... on Ebook {
    id
    readingTime
  }
  ... on Event {
    id
    startsAt
    endsAt
  }
  ... on Stream {
    id
    length
  }
}
    `;
export const LimitedLearningPathFragmentDoc = gql`
    fragment LimitedLearningPath on LearningPath {
  __typename
  id
  name
  isAssigned
  image {
    ...Image
  }
  categories {
    ...Category
  }
  contentCards {
    ...LimitedContentCard
  }
}
    ${ImageFragmentDoc}
${CategoryFragmentDoc}
${LimitedContentCardFragmentDoc}`;
export const ContentCardFragmentDoc = gql`
    fragment ContentCard on ContentCard {
  ... on Podcast {
    ...Podcast
  }
  ... on Ebook {
    ...Ebook
  }
  ... on Event {
    ...Event
  }
  ... on Stream {
    ...Stream
  }
  ... on Expert {
    ...Expert
  }
}
    ${PodcastFragmentDoc}
${EbookFragmentDoc}
${EventFragmentDoc}
${StreamFragmentDoc}
${ExpertFragmentDoc}`;
export const ContentConnectionFragmentDoc = gql`
    fragment ContentConnection on ContentConnection {
  edges {
    ...ContentCard
  }
  meta {
    limit
    offset
    recommendationId
    total
  }
}
    ${ContentCardFragmentDoc}`;
export const LifeGoalFragmentDoc = gql`
    fragment LifeGoal on Lifegoal {
  id
  name
  image {
    ...Image
  }
  categories {
    ...Category
  }
  contentCount
  icon {
    ...Image
  }
}
    ${ImageFragmentDoc}
${CategoryFragmentDoc}`;
export const LearningPathFragmentDoc = gql`
    fragment LearningPath on LearningPath {
  __typename
  id
  name
  preamble
  createdAt
  updatedAt
  isAssigned
  image {
    ...Image
  }
  categories {
    ...Category
  }
  featuredCategory {
    ...Category
  }
  featuredLifegoal {
    ...LifeGoal
  }
  sortOrder
  ebooks {
    ...Ebook
  }
  streams {
    ...Stream
  }
  podcasts {
    ...Podcast
  }
  events {
    ...Event
  }
  contentCards {
    ...ContentCard
  }
  userCollectionTracking {
    id
    bookmarkedAt
  }
}
    ${ImageFragmentDoc}
${CategoryFragmentDoc}
${LifeGoalFragmentDoc}
${EbookFragmentDoc}
${StreamFragmentDoc}
${PodcastFragmentDoc}
${EventFragmentDoc}
${ContentCardFragmentDoc}`;
export const AdminLevelPodcastFragmentDoc = gql`
    fragment AdminLevelPodcast on Podcast {
  ...Podcast
  organisationGroups {
    id
    name
  }
}
    ${PodcastFragmentDoc}`;
export const StreamMessageFragmentDoc = gql`
    fragment StreamMessage on StreamChatMessage {
  id
  content
  streamId
  timestamp
  user {
    id
    firstName
    lastName
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  firstName
  lastName
  email
  mobileNumber
  blocked
  status
  newsletter
  roles
  company
  seniority
  isMentor
  facebookLink
  twitterHandle
  instagramHandle
  linkedinLink
  websiteLink
  monthlyConsumedContentCount
  stats {
    __typename
    ebooksRead
    eventsAttended
    podcastsCompleted
    videosCompleted
    eventsBooked
  }
  statsContentConsumedTotal
  organisations {
    __typename
    id
    name
    type
  }
  organisationGroups {
    __typename
    id
    name
  }
  subscriptionStatus
  isExpert
  biography
  isFollowing
  contentCompletedCount
  followerCount
  followingCount
  jobTitle
  expertOrg {
    id
  }
  avatarImage {
    id
    uri
  }
  coverImage {
    id
    uri
  }
  lastOnlineAt
  deletedAt
  createdAt
  updatedAt
  archivedAt
}
    `;
export const UserNotificationPreferenceFragmentDoc = gql`
    fragment UserNotificationPreference on UserNotificationPreference {
  type
  name
  platform
  state
}
    `;
export const NotificationPreferencesFragmentDoc = gql`
    fragment NotificationPreferences on User {
  __typename
  id
  notificationPreferences {
    ...UserNotificationPreference
  }
}
    ${UserNotificationPreferenceFragmentDoc}`;
export const ContentBasicInfoFragmentDoc = gql`
    fragment ContentBasicInfo on ContentCard {
  ... on Event {
    id
    name
    __typename
    userContentTracking {
      id
      isFinished
    }
  }
  ... on Ebook {
    id
    name
    length
    __typename
    userContentTracking {
      id
      isFinished
    }
  }
  ... on Podcast {
    id
    name
    length
    __typename
    userContentTracking {
      id
      isFinished
    }
  }
  ... on Stream {
    id
    name
    length
    __typename
    userContentTracking {
      id
      isFinished
    }
  }
}
    `;
export const ExpertBasicInfoFragmentDoc = gql`
    fragment ExpertBasicInfo on Expert {
  id
  firstName
  lastName
  company
  title
  isAuthenticatedUserFollowing
}
    `;
export const ContentCardBasicInfoFragmentDoc = gql`
    fragment ContentCardBasicInfo on ContentCard {
  ... on Podcast {
    id
    name
    preamble
    length
    experts {
      ...ExpertBasicInfo
    }
  }
  ... on Ebook {
    id
    name
    preamble
    readingTime
    experts {
      ...ExpertBasicInfo
    }
  }
  ... on Event {
    id
    name
    preamble
    startsAt
    endsAt
    experts {
      ...ExpertBasicInfo
    }
  }
  ... on Stream {
    id
    name
    preamble
    length
    experts {
      ...ExpertBasicInfo
    }
  }
}
    ${ExpertBasicInfoFragmentDoc}`;
export const LearningPathInfoFragmentDoc = gql`
    fragment LearningPathInfo on LearningPath {
  id
  name
  preamble
  createdAt
  updatedAt
  categories {
    id
    name
  }
  contentCards {
    ...ContentCardBasicInfo
  }
}
    ${ContentCardBasicInfoFragmentDoc}`;
export const PostReactionCountFieldsFragmentDoc = gql`
    fragment PostReactionCountFields on ReactionCount {
  count
  type
}
    `;
export const PostReactionsSummaryFieldsFragmentDoc = gql`
    fragment PostReactionsSummaryFields on ReactionsSummary {
  reactions {
    ...PostReactionCountFields
  }
  totalCount
}
    ${PostReactionCountFieldsFragmentDoc}`;
export const UserStatsFragmentDoc = gql`
    fragment UserStats on UserStats {
  __typename
  ebooksRead
  podcastsCompleted
  videosCompleted
  eventsBooked
  eventsAttended
}
    `;
export const PublicUserInformationFragmentDoc = gql`
    fragment PublicUserInformation on User {
  __typename
  id
  avatarImage {
    ...Image
  }
  coverImage {
    ...Image
  }
  firstName
  lastName
  isExpert
  isMentor
  company
  persona
  jobTitle
  recentlyConsumedCategories {
    ...Category
  }
  expert {
    ...Expert
  }
  lifegoals {
    ...LifeGoal
  }
  stats {
    ...UserStats
  }
  isFollowing
  facebookLink
  twitterHandle
  instagramHandle
  linkedinLink
  websiteLink
  biography
  followerCount
  followingCount
  contentCreatedCount
  contentCompletedCount
  mentorPriceText
  mentorAvailableText
  createdAt
  updatedAt
  expertOrg {
    id
  }
  isBlockingCurrentUser
  isBlockedByCurrentUser
}
    ${ImageFragmentDoc}
${CategoryFragmentDoc}
${ExpertFragmentDoc}
${LifeGoalFragmentDoc}
${UserStatsFragmentDoc}`;
export const ReactedUserReactionFieldsFragmentDoc = gql`
    fragment ReactedUserReactionFields on UserReaction {
  id
  emoji
  reactionType
  user {
    ...PublicUserInformation
  }
}
    ${PublicUserInformationFragmentDoc}`;
export const ContentCardVotesFragmentDoc = gql`
    fragment ContentCardVotes on ContentCard {
  ... on Podcast {
    id
    upvoteCount
    contentRatingByUser
  }
  ... on Ebook {
    id
    upvoteCount
    contentRatingByUser
  }
  ... on Event {
    id
    upvoteCount
    contentRatingByUser
  }
  ... on Stream {
    id
    upvoteCount
    contentRatingByUser
  }
}
    `;
export const ImageIdUriFragmentDoc = gql`
    fragment imageIdUri on Image {
  id
  uri
}
    `;
export const UserContentTrackingFragmentFragmentDoc = gql`
    fragment userContentTrackingFragment on UserContentTracking {
  __typename
  id
  current
  currentPlacement
  total
  isAttending
  isFinished
  isBookmarked
  bookmarkedAt
  lastOpenedAt
  finishedAt
}
    `;
export const EventFragmentFragmentDoc = gql`
    fragment eventFragment on Event {
  id
  name
  preamble
  image {
    ...imageIdUri
  }
  organisationGroups {
    id
    name
  }
  attendees {
    id
    firstName
    lastName
    email
    organisationGroups {
      name
    }
  }
  entryLevel
  eventType
  maxAttendees
  isFeatured
  isFree
  isAssigned
  contentSegments {
    header
    paragraph
  }
  ticketPrice
  ticketCurrency
  experts {
    id
    firstName
    lastName
    title
    company
    image {
      id
      uri
    }
  }
  categories {
    id
    name
  }
  location {
    name
    city
  }
  isOnline
  conferenceUrl
  attendeeCount
  startsAt
  endsAt
  updatedAt
  publishedAt
  locationDisplayName
  userContentTracking {
    ...userContentTrackingFragment
  }
}
    ${ImageIdUriFragmentDoc}
${UserContentTrackingFragmentFragmentDoc}`;
export const FeedUserFragmentDoc = gql`
    fragment FeedUser on User {
  id
  firstName
  lastName
  isExpert
  isFollowing
  expertOrg {
    id
  }
  avatarImage {
    id
    uri
    alt
  }
}
    `;
export const FeedCommentBaseFragmentDoc = gql`
    fragment FeedCommentBase on Comment {
  id
  comment
  createdAt
  mentions {
    index
    targetId
    mentionType
  }
  numberOfReplies
  lastReply {
    ...Reply
  }
  rawText
  updatedAt
  user {
    ...FeedUser
  }
  usersReactToTheComment {
    totalCount
    reactions {
      emoji
    }
    userReacted {
      id
      reactionType
    }
  }
}
    ${ReplyFragmentDoc}
${FeedUserFragmentDoc}`;
export const FeedCommentFragmentDoc = gql`
    fragment FeedComment on Comment {
  ...FeedCommentBase
  parent {
    ...FeedCommentBase
  }
}
    ${FeedCommentBaseFragmentDoc}`;
export const EbookCardFragmentDoc = gql`
    fragment EbookCard on Ebook {
  __typename
  id
  name
  readingTime
  updatedAt
  isAssigned
  categories {
    id
    name
  }
  image {
    id
    uri
    alt
  }
  experts {
    ...ExpertBasicInfo
  }
  userContentTracking {
    ...UserContentTracking
  }
}
    ${ExpertBasicInfoFragmentDoc}
${UserContentTrackingFragmentDoc}`;
export const PodcastCardFragmentDoc = gql`
    fragment PodcastCard on Podcast {
  __typename
  id
  name
  length
  isAssigned
  episodes {
    audio {
      id
      length
    }
  }
  categories {
    id
    name
  }
  image {
    id
    uri
    alt
  }
  experts {
    ...ExpertBasicInfo
  }
  userContentTracking {
    ...UserContentTracking
  }
}
    ${ExpertBasicInfoFragmentDoc}
${UserContentTrackingFragmentDoc}`;
export const StreamCardFragmentDoc = gql`
    fragment StreamCard on Stream {
  __typename
  id
  name
  source
  length
  isAssigned
  categories {
    id
    name
  }
  image {
    id
    uri
    alt
  }
  experts {
    ...ExpertBasicInfo
  }
  userContentTracking {
    ...UserContentTracking
  }
}
    ${ExpertBasicInfoFragmentDoc}
${UserContentTrackingFragmentDoc}`;
export const EventCardFragmentDoc = gql`
    fragment EventCard on Event {
  __typename
  id
  name
  eventType
  startsAt
  endsAt
  isAssigned
  categories {
    id
    name
  }
  image {
    id
    uri
    alt
  }
  experts {
    ...ExpertBasicInfo
  }
  userContentTracking {
    ...UserContentTracking
  }
}
    ${ExpertBasicInfoFragmentDoc}
${UserContentTrackingFragmentDoc}`;
export const ContentActivityFragmentDoc = gql`
    fragment ContentActivity on ContentActivity {
  id
  contentActivityType
  createdAt
  comment {
    ...FeedComment
  }
  user {
    ...FeedUser
  }
  content {
    ... on Ebook {
      ...EbookCard
      numberOfComments
      numberOfCommenters
    }
    ... on Podcast {
      ...PodcastCard
      numberOfComments
      numberOfCommenters
    }
    ... on Stream {
      ...StreamCard
      numberOfComments
      numberOfCommenters
    }
    ... on Event {
      ...EventCard
      numberOfComments
      numberOfCommenters
    }
  }
}
    ${FeedCommentFragmentDoc}
${FeedUserFragmentDoc}
${EbookCardFragmentDoc}
${PodcastCardFragmentDoc}
${StreamCardFragmentDoc}
${EventCardFragmentDoc}`;
export const PostBaseFragmentDoc = gql`
    fragment PostBase on Post {
  id
  createdAt
  updatedAt
  privacySettings
  description
  numberOfComments
  numberOfCommenters
  numberOfReposts
  reactions {
    totalCount
    reactions {
      emoji
    }
    userReacted {
      id
      reactionType
    }
  }
  user {
    ...FeedUser
  }
  attachments {
    uri
    attachmentType
    __typename
  }
}
    ${FeedUserFragmentDoc}`;
export const PostFragmentDoc = gql`
    fragment Post on Post {
  ...PostBase
  parent {
    ...PostBase
  }
}
    ${PostBaseFragmentDoc}`;
export const PostActivityFragmentDoc = gql`
    fragment PostActivity on PostActivity {
  id
  comment {
    ...FeedComment
  }
  user {
    ...FeedUser
  }
  createdAt
  activityType
  post {
    ...Post
  }
}
    ${FeedCommentFragmentDoc}
${FeedUserFragmentDoc}
${PostFragmentDoc}`;
export const CursorInfoFieldsFragmentDoc = gql`
    fragment CursorInfoFields on CursorInfo {
  hasNext
  hasPrev
  nextCursor
  prevCursor
}
    `;
export const GraphDataPointFragmentDoc = gql`
    fragment graphDataPoint on GraphDataPoint {
  value
  label
}
    `;
export const BaseGroupFragmentDoc = gql`
    fragment BaseGroup on OrganisationGroup {
  __typename
  id
  name
  numberOfMembers
}
    `;
export const SelectionTableGroupFragmentDoc = gql`
    fragment SelectionTableGroup on OrganisationGroup {
  ...BaseGroup
  createdBy {
    id
    firstName
    lastName
  }
}
    ${BaseGroupFragmentDoc}`;
export const FullGroupFragmentDoc = gql`
    fragment FullGroup on OrganisationGroup {
  ...BaseGroup
  createdBy {
    id
    firstName
    lastName
  }
  createdAt
  updatedAt
  audience {
    id
  }
}
    ${BaseGroupFragmentDoc}`;
export const LearningPathItemFragmentDoc = gql`
    fragment learningPathItem on LearningPath {
  id
  name
  preamble
  createdAt
  updatedAt
  isAssigned
  image {
    ...imageIdUri
  }
  categories {
    id
    name
  }
  organisationGroups {
    id
    name
    __typename
  }
  sortOrder
  ebooks {
    ...Ebook
  }
  streams {
    ...Stream
  }
  podcasts {
    ...Podcast
  }
  events {
    ...Event
  }
}
    ${ImageIdUriFragmentDoc}
${EbookFragmentDoc}
${StreamFragmentDoc}
${PodcastFragmentDoc}
${EventFragmentDoc}`;
export const PieDataPointFragmentDoc = gql`
    fragment pieDataPoint on PieDataPoint {
  id
  value
  label
}
    `;
export const ExpertItemFragmentDoc = gql`
    fragment expertItem on Expert {
  id
  firstName
  lastName
  email
  title
  biography
  image {
    id
    uri
  }
  categories {
    id
    name
  }
  isMentor
  isAuthenticatedUserFollowing
  mentorPriceText
  mentorAvailableText
  organisationGroups {
    id
    name
  }
  company
  updatedAt
  organisation {
    id
    name
  }
}
    `;
export const StreamFragmentFragmentDoc = gql`
    fragment streamFragment on Stream {
  id
  name
  source
  status
  preamble
  isAssigned
  organisationGroups {
    id
    name
  }
  image {
    ...imageIdUri
  }
  experts {
    ...expertItem
  }
  categories {
    id
    name
  }
  contentSegments {
    header
    paragraph
  }
  videoUrl
  publishedAt
  updatedAt
  createdAt
  userContentTracking {
    ...userContentTrackingFragment
  }
  broadcastConfiguration {
    rtmpUrl
    streamKey
  }
  agenda {
    topic
    description
    isActive
    speakers {
      ...LimitedUser
    }
    estimatedStartTime
    secondsIntoRecordingTopicStartsAt
  }
}
    ${ImageIdUriFragmentDoc}
${ExpertItemFragmentDoc}
${UserContentTrackingFragmentFragmentDoc}
${LimitedUserFragmentDoc}`;
export const OrganisationFragmentDoc = gql`
    fragment organisation on Organisation {
  __typename
  id
  name
  type
  activeUsers
  createdAt
  updatedAt
}
    `;
export const GetOrgGroupsByOrgIdDocument = gql`
    query GetOrgGroupsByOrgId($organisationId: String!, $filter: OrganisationGroupFilter) {
  organisation(id: $organisationId) {
    id
    __typename
    orgGroups(filter: $filter) {
      edges {
        name
        id
        __typename
      }
      meta {
        limit
        offset
        total
      }
    }
  }
}
    `;

/**
 * __useGetOrgGroupsByOrgIdQuery__
 *
 * To run a query within a React component, call `useGetOrgGroupsByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgGroupsByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgGroupsByOrgIdQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOrgGroupsByOrgIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgGroupsByOrgIdQuery, types.GetOrgGroupsByOrgIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgGroupsByOrgIdQuery, types.GetOrgGroupsByOrgIdQueryVariables>(GetOrgGroupsByOrgIdDocument, options);
      }
export function useGetOrgGroupsByOrgIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgGroupsByOrgIdQuery, types.GetOrgGroupsByOrgIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgGroupsByOrgIdQuery, types.GetOrgGroupsByOrgIdQueryVariables>(GetOrgGroupsByOrgIdDocument, options);
        }
export type GetOrgGroupsByOrgIdQueryHookResult = ReturnType<typeof useGetOrgGroupsByOrgIdQuery>;
export type GetOrgGroupsByOrgIdLazyQueryHookResult = ReturnType<typeof useGetOrgGroupsByOrgIdLazyQuery>;
export type GetOrgGroupsByOrgIdQueryResult = Apollo.QueryResult<types.GetOrgGroupsByOrgIdQuery, types.GetOrgGroupsByOrgIdQueryVariables>;
export const GetOrgMembersByOrgIdDocument = gql`
    query GetOrgMembersByOrgId($organisationId: String!, $filter: OrganisationMemberFilter) {
  organisation(id: $organisationId) {
    id
    __typename
    members(filter: $filter) {
      edges {
        __typename
        id
        firstName
        lastName
      }
      meta {
        limit
        offset
        total
      }
    }
  }
}
    `;

/**
 * __useGetOrgMembersByOrgIdQuery__
 *
 * To run a query within a React component, call `useGetOrgMembersByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgMembersByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgMembersByOrgIdQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOrgMembersByOrgIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgMembersByOrgIdQuery, types.GetOrgMembersByOrgIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgMembersByOrgIdQuery, types.GetOrgMembersByOrgIdQueryVariables>(GetOrgMembersByOrgIdDocument, options);
      }
export function useGetOrgMembersByOrgIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgMembersByOrgIdQuery, types.GetOrgMembersByOrgIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgMembersByOrgIdQuery, types.GetOrgMembersByOrgIdQueryVariables>(GetOrgMembersByOrgIdDocument, options);
        }
export type GetOrgMembersByOrgIdQueryHookResult = ReturnType<typeof useGetOrgMembersByOrgIdQuery>;
export type GetOrgMembersByOrgIdLazyQueryHookResult = ReturnType<typeof useGetOrgMembersByOrgIdLazyQuery>;
export type GetOrgMembersByOrgIdQueryResult = Apollo.QueryResult<types.GetOrgMembersByOrgIdQuery, types.GetOrgMembersByOrgIdQueryVariables>;
export const ToggleCommentReactionDocument = gql`
    mutation ToggleCommentReaction($reactionType: ReactionType!, $referenceId: ID!) {
  toggleReact(
    reactionType: $reactionType
    reactionRefType: COMMENT
    referenceId: $referenceId
  ) {
    userReacted {
      id
      reactionType
    }
    totalCount
  }
}
    `;
export type ToggleCommentReactionMutationFn = Apollo.MutationFunction<types.ToggleCommentReactionMutation, types.ToggleCommentReactionMutationVariables>;

/**
 * __useToggleCommentReactionMutation__
 *
 * To run a mutation, you first call `useToggleCommentReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCommentReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCommentReactionMutation, { data, loading, error }] = useToggleCommentReactionMutation({
 *   variables: {
 *      reactionType: // value for 'reactionType'
 *      referenceId: // value for 'referenceId'
 *   },
 * });
 */
export function useToggleCommentReactionMutation(baseOptions?: Apollo.MutationHookOptions<types.ToggleCommentReactionMutation, types.ToggleCommentReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ToggleCommentReactionMutation, types.ToggleCommentReactionMutationVariables>(ToggleCommentReactionDocument, options);
      }
export type ToggleCommentReactionMutationHookResult = ReturnType<typeof useToggleCommentReactionMutation>;
export type ToggleCommentReactionMutationResult = Apollo.MutationResult<types.ToggleCommentReactionMutation>;
export type ToggleCommentReactionMutationOptions = Apollo.BaseMutationOptions<types.ToggleCommentReactionMutation, types.ToggleCommentReactionMutationVariables>;
export const GetRepliesForCommentDocument = gql`
    query GetRepliesForComment($commentId: ID!, $sorting: SortOrdering, $limit: Int, $afterCursor: String) {
  comment(id: $commentId) {
    id
    replies(sorting: $sorting, limit: $limit, afterCursor: $afterCursor) {
      edges {
        cursor
        comment {
          ...Comment
        }
      }
      meta {
        hasNext
        nextCursor
      }
    }
    lastReply {
      ...Comment
    }
    numberOfReplies
  }
}
    ${CommentFragmentDoc}`;

/**
 * __useGetRepliesForCommentQuery__
 *
 * To run a query within a React component, call `useGetRepliesForCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepliesForCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepliesForCommentQuery({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      sorting: // value for 'sorting'
 *      limit: // value for 'limit'
 *      afterCursor: // value for 'afterCursor'
 *   },
 * });
 */
export function useGetRepliesForCommentQuery(baseOptions: Apollo.QueryHookOptions<types.GetRepliesForCommentQuery, types.GetRepliesForCommentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetRepliesForCommentQuery, types.GetRepliesForCommentQueryVariables>(GetRepliesForCommentDocument, options);
      }
export function useGetRepliesForCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetRepliesForCommentQuery, types.GetRepliesForCommentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetRepliesForCommentQuery, types.GetRepliesForCommentQueryVariables>(GetRepliesForCommentDocument, options);
        }
export type GetRepliesForCommentQueryHookResult = ReturnType<typeof useGetRepliesForCommentQuery>;
export type GetRepliesForCommentLazyQueryHookResult = ReturnType<typeof useGetRepliesForCommentLazyQuery>;
export type GetRepliesForCommentQueryResult = Apollo.QueryResult<types.GetRepliesForCommentQuery, types.GetRepliesForCommentQueryVariables>;
export const GetCurrentLoggedInUserDocument = gql`
    query GetCurrentLoggedInUser {
  user: me {
    id
    isExpert
    lastName
    firstName
    avatarImage {
      alt
      blurHash
      id
      uri
    }
  }
}
    `;

/**
 * __useGetCurrentLoggedInUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentLoggedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentLoggedInUserQuery(baseOptions?: Apollo.QueryHookOptions<types.GetCurrentLoggedInUserQuery, types.GetCurrentLoggedInUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCurrentLoggedInUserQuery, types.GetCurrentLoggedInUserQueryVariables>(GetCurrentLoggedInUserDocument, options);
      }
export function useGetCurrentLoggedInUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCurrentLoggedInUserQuery, types.GetCurrentLoggedInUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCurrentLoggedInUserQuery, types.GetCurrentLoggedInUserQueryVariables>(GetCurrentLoggedInUserDocument, options);
        }
export type GetCurrentLoggedInUserQueryHookResult = ReturnType<typeof useGetCurrentLoggedInUserQuery>;
export type GetCurrentLoggedInUserLazyQueryHookResult = ReturnType<typeof useGetCurrentLoggedInUserLazyQuery>;
export type GetCurrentLoggedInUserQueryResult = Apollo.QueryResult<types.GetCurrentLoggedInUserQuery, types.GetCurrentLoggedInUserQueryVariables>;
export const GetAssignmentDetailsDocument = gql`
    query GetAssignmentDetails($id: ID!) {
  me {
    __typename
    id
    assignments(filter: {limit: 1, offset: 0, id: $id}) {
      edges {
        id
        assignmentTitle
        assignmentReason
        dueDate
        createdAt
        length
        completionRate
        backgroundImage {
          uri
          alt
        }
        contents {
          meta {
            total
          }
          edges {
            ... on Podcast {
              ...Podcast
            }
            ... on Ebook {
              ...Ebook
            }
            ... on Stream {
              ...Stream
            }
            ... on LearningPath {
              ...LearningPath
            }
          }
        }
        userAssignmentStatistics {
          assignedTrails
          assignedPodcasts
          assignedEbooks
          assignedStreams
        }
      }
    }
  }
}
    ${PodcastFragmentDoc}
${EbookFragmentDoc}
${StreamFragmentDoc}
${LearningPathFragmentDoc}`;

/**
 * __useGetAssignmentDetailsQuery__
 *
 * To run a query within a React component, call `useGetAssignmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssignmentDetailsQuery(baseOptions: Apollo.QueryHookOptions<types.GetAssignmentDetailsQuery, types.GetAssignmentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetAssignmentDetailsQuery, types.GetAssignmentDetailsQueryVariables>(GetAssignmentDetailsDocument, options);
      }
export function useGetAssignmentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetAssignmentDetailsQuery, types.GetAssignmentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetAssignmentDetailsQuery, types.GetAssignmentDetailsQueryVariables>(GetAssignmentDetailsDocument, options);
        }
export type GetAssignmentDetailsQueryHookResult = ReturnType<typeof useGetAssignmentDetailsQuery>;
export type GetAssignmentDetailsLazyQueryHookResult = ReturnType<typeof useGetAssignmentDetailsLazyQuery>;
export type GetAssignmentDetailsQueryResult = Apollo.QueryResult<types.GetAssignmentDetailsQuery, types.GetAssignmentDetailsQueryVariables>;
export const GetLearningPathByIdDocument = gql`
    query GetLearningPathById($id: String!) {
  learningPath(id: $id) {
    ...LearningPath
  }
}
    ${LearningPathFragmentDoc}`;

/**
 * __useGetLearningPathByIdQuery__
 *
 * To run a query within a React component, call `useGetLearningPathByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningPathByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningPathByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLearningPathByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetLearningPathByIdQuery, types.GetLearningPathByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetLearningPathByIdQuery, types.GetLearningPathByIdQueryVariables>(GetLearningPathByIdDocument, options);
      }
export function useGetLearningPathByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetLearningPathByIdQuery, types.GetLearningPathByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetLearningPathByIdQuery, types.GetLearningPathByIdQueryVariables>(GetLearningPathByIdDocument, options);
        }
export type GetLearningPathByIdQueryHookResult = ReturnType<typeof useGetLearningPathByIdQuery>;
export type GetLearningPathByIdLazyQueryHookResult = ReturnType<typeof useGetLearningPathByIdLazyQuery>;
export type GetLearningPathByIdQueryResult = Apollo.QueryResult<types.GetLearningPathByIdQuery, types.GetLearningPathByIdQueryVariables>;
export const GetLiveStreamMoreInfoDocument = gql`
    query GetLiveStreamMoreInfo($id: String!) {
  stream(id: $id) {
    __typename
    id
    source
    contentSegments {
      paragraph
      header
    }
    experts {
      ...LiveGuest
    }
    hosts {
      expert {
        ...LiveGuest
      }
    }
  }
}
    ${LiveGuestFragmentDoc}`;

/**
 * __useGetLiveStreamMoreInfoQuery__
 *
 * To run a query within a React component, call `useGetLiveStreamMoreInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveStreamMoreInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveStreamMoreInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLiveStreamMoreInfoQuery(baseOptions: Apollo.QueryHookOptions<types.GetLiveStreamMoreInfoQuery, types.GetLiveStreamMoreInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetLiveStreamMoreInfoQuery, types.GetLiveStreamMoreInfoQueryVariables>(GetLiveStreamMoreInfoDocument, options);
      }
export function useGetLiveStreamMoreInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetLiveStreamMoreInfoQuery, types.GetLiveStreamMoreInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetLiveStreamMoreInfoQuery, types.GetLiveStreamMoreInfoQueryVariables>(GetLiveStreamMoreInfoDocument, options);
        }
export type GetLiveStreamMoreInfoQueryHookResult = ReturnType<typeof useGetLiveStreamMoreInfoQuery>;
export type GetLiveStreamMoreInfoLazyQueryHookResult = ReturnType<typeof useGetLiveStreamMoreInfoLazyQuery>;
export type GetLiveStreamMoreInfoQueryResult = Apollo.QueryResult<types.GetLiveStreamMoreInfoQuery, types.GetLiveStreamMoreInfoQueryVariables>;
export const GetBroadcastAgendaDocument = gql`
    subscription GetBroadcastAgenda($id: ID!) {
  stream(id: $id) {
    __typename
    id
    agenda {
      ...LiveBroadcastAgenda
    }
  }
}
    ${LiveBroadcastAgendaFragmentDoc}`;

/**
 * __useGetBroadcastAgendaSubscription__
 *
 * To run a query within a React component, call `useGetBroadcastAgendaSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetBroadcastAgendaSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBroadcastAgendaSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBroadcastAgendaSubscription(baseOptions: Apollo.SubscriptionHookOptions<types.GetBroadcastAgendaSubscription, types.GetBroadcastAgendaSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<types.GetBroadcastAgendaSubscription, types.GetBroadcastAgendaSubscriptionVariables>(GetBroadcastAgendaDocument, options);
      }
export type GetBroadcastAgendaSubscriptionHookResult = ReturnType<typeof useGetBroadcastAgendaSubscription>;
export type GetBroadcastAgendaSubscriptionResult = Apollo.SubscriptionResult<types.GetBroadcastAgendaSubscription>;
export const GetRecommendedContentCardsDocument = gql`
    query GetRecommendedContentCards($categoryFilter: ContentCardsFilter, $expertFilter: ContentCardsFilter, $sorting: ContentCardsSorting) {
  sameCategoryCards: contentCards(filter: $categoryFilter, sorting: $sorting) {
    ...RecommendedContentConnection
  }
  sameExpertCards: contentCards(filter: $expertFilter, sorting: $sorting) {
    ...RecommendedContentConnection
  }
}
    ${RecommendedContentConnectionFragmentDoc}`;

/**
 * __useGetRecommendedContentCardsQuery__
 *
 * To run a query within a React component, call `useGetRecommendedContentCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedContentCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedContentCardsQuery({
 *   variables: {
 *      categoryFilter: // value for 'categoryFilter'
 *      expertFilter: // value for 'expertFilter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetRecommendedContentCardsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetRecommendedContentCardsQuery, types.GetRecommendedContentCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetRecommendedContentCardsQuery, types.GetRecommendedContentCardsQueryVariables>(GetRecommendedContentCardsDocument, options);
      }
export function useGetRecommendedContentCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetRecommendedContentCardsQuery, types.GetRecommendedContentCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetRecommendedContentCardsQuery, types.GetRecommendedContentCardsQueryVariables>(GetRecommendedContentCardsDocument, options);
        }
export type GetRecommendedContentCardsQueryHookResult = ReturnType<typeof useGetRecommendedContentCardsQuery>;
export type GetRecommendedContentCardsLazyQueryHookResult = ReturnType<typeof useGetRecommendedContentCardsLazyQuery>;
export type GetRecommendedContentCardsQueryResult = Apollo.QueryResult<types.GetRecommendedContentCardsQuery, types.GetRecommendedContentCardsQueryVariables>;
export const GetContentExpertAndCategoryIDsDocument = gql`
    query GetContentExpertAndCategoryIDs($id: ID!) {
  expertsAndCategories: contentCard(id: $id) {
    ... on Ebook {
      id
      __typename
      experts {
        id
      }
      categories {
        id
      }
    }
    ... on Event {
      id
      __typename
      experts {
        id
      }
      categories {
        id
      }
    }
    ... on Stream {
      id
      __typename
      experts {
        id
      }
      categories {
        id
      }
    }
    ... on Podcast {
      id
      __typename
      experts {
        id
      }
      categories {
        id
      }
    }
  }
}
    `;

/**
 * __useGetContentExpertAndCategoryIDsQuery__
 *
 * To run a query within a React component, call `useGetContentExpertAndCategoryIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentExpertAndCategoryIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentExpertAndCategoryIDsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentExpertAndCategoryIDsQuery(baseOptions: Apollo.QueryHookOptions<types.GetContentExpertAndCategoryIDsQuery, types.GetContentExpertAndCategoryIDsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetContentExpertAndCategoryIDsQuery, types.GetContentExpertAndCategoryIDsQueryVariables>(GetContentExpertAndCategoryIDsDocument, options);
      }
export function useGetContentExpertAndCategoryIDsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetContentExpertAndCategoryIDsQuery, types.GetContentExpertAndCategoryIDsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetContentExpertAndCategoryIDsQuery, types.GetContentExpertAndCategoryIDsQueryVariables>(GetContentExpertAndCategoryIDsDocument, options);
        }
export type GetContentExpertAndCategoryIDsQueryHookResult = ReturnType<typeof useGetContentExpertAndCategoryIDsQuery>;
export type GetContentExpertAndCategoryIDsLazyQueryHookResult = ReturnType<typeof useGetContentExpertAndCategoryIDsLazyQuery>;
export type GetContentExpertAndCategoryIDsQueryResult = Apollo.QueryResult<types.GetContentExpertAndCategoryIDsQuery, types.GetContentExpertAndCategoryIDsQueryVariables>;
export const GetStreamForConnectedLiveVideoPlayerDocument = gql`
    subscription GetStreamForConnectedLiveVideoPlayer($id: ID!) {
  stream(id: $id) {
    __typename
    id
    name
    videoUrl
    wentLiveAt
    source
    status
    preamble
    upvoteCount
    contentRatingByUser
    categories {
      id
      name
    }
    experts {
      ...LiveVideoExperts
    }
    image {
      uri
      id
    }
  }
}
    ${LiveVideoExpertsFragmentDoc}`;

/**
 * __useGetStreamForConnectedLiveVideoPlayerSubscription__
 *
 * To run a query within a React component, call `useGetStreamForConnectedLiveVideoPlayerSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamForConnectedLiveVideoPlayerSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamForConnectedLiveVideoPlayerSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStreamForConnectedLiveVideoPlayerSubscription(baseOptions: Apollo.SubscriptionHookOptions<types.GetStreamForConnectedLiveVideoPlayerSubscription, types.GetStreamForConnectedLiveVideoPlayerSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<types.GetStreamForConnectedLiveVideoPlayerSubscription, types.GetStreamForConnectedLiveVideoPlayerSubscriptionVariables>(GetStreamForConnectedLiveVideoPlayerDocument, options);
      }
export type GetStreamForConnectedLiveVideoPlayerSubscriptionHookResult = ReturnType<typeof useGetStreamForConnectedLiveVideoPlayerSubscription>;
export type GetStreamForConnectedLiveVideoPlayerSubscriptionResult = Apollo.SubscriptionResult<types.GetStreamForConnectedLiveVideoPlayerSubscription>;
export const GetPodcastForConnectedPodcastPlayerDocument = gql`
    query GetPodcastForConnectedPodcastPlayer($audioLanguage: String, $id: String!) {
  podcast(id: $id) {
    __typename
    id
    name
    preamble
    upvoteCount
    contentRatingByUser
    userContentTracking {
      ...UserContentTracking
    }
    audioAvailableLanguages {
      code
      name
    }
    transcriptAvailableLanguages {
      code
      name
    }
    episodes(language: $audioLanguage) {
      audio {
        uri
        id
        length
      }
      language
      name
    }
    categories {
      id
      name
    }
    experts {
      id
      firstName
      lastName
    }
    image {
      uri
      id
    }
  }
}
    ${UserContentTrackingFragmentDoc}`;

/**
 * __useGetPodcastForConnectedPodcastPlayerQuery__
 *
 * To run a query within a React component, call `useGetPodcastForConnectedPodcastPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPodcastForConnectedPodcastPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPodcastForConnectedPodcastPlayerQuery({
 *   variables: {
 *      audioLanguage: // value for 'audioLanguage'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPodcastForConnectedPodcastPlayerQuery(baseOptions: Apollo.QueryHookOptions<types.GetPodcastForConnectedPodcastPlayerQuery, types.GetPodcastForConnectedPodcastPlayerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPodcastForConnectedPodcastPlayerQuery, types.GetPodcastForConnectedPodcastPlayerQueryVariables>(GetPodcastForConnectedPodcastPlayerDocument, options);
      }
export function useGetPodcastForConnectedPodcastPlayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPodcastForConnectedPodcastPlayerQuery, types.GetPodcastForConnectedPodcastPlayerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPodcastForConnectedPodcastPlayerQuery, types.GetPodcastForConnectedPodcastPlayerQueryVariables>(GetPodcastForConnectedPodcastPlayerDocument, options);
        }
export type GetPodcastForConnectedPodcastPlayerQueryHookResult = ReturnType<typeof useGetPodcastForConnectedPodcastPlayerQuery>;
export type GetPodcastForConnectedPodcastPlayerLazyQueryHookResult = ReturnType<typeof useGetPodcastForConnectedPodcastPlayerLazyQuery>;
export type GetPodcastForConnectedPodcastPlayerQueryResult = Apollo.QueryResult<types.GetPodcastForConnectedPodcastPlayerQuery, types.GetPodcastForConnectedPodcastPlayerQueryVariables>;
export const GetPodcastTranscriptDocument = gql`
    query GetPodcastTranscript($transcriptLanguage: String, $id: String!) {
  podcast(id: $id) {
    __typename
    id
    userContentTracking {
      ...UserContentTracking
    }
    transcriptAvailableLanguages {
      code
      name
    }
    transcripts(language: $transcriptLanguage) {
      language
      transcripts {
        page
      }
    }
  }
}
    ${UserContentTrackingFragmentDoc}`;

/**
 * __useGetPodcastTranscriptQuery__
 *
 * To run a query within a React component, call `useGetPodcastTranscriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPodcastTranscriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPodcastTranscriptQuery({
 *   variables: {
 *      transcriptLanguage: // value for 'transcriptLanguage'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPodcastTranscriptQuery(baseOptions: Apollo.QueryHookOptions<types.GetPodcastTranscriptQuery, types.GetPodcastTranscriptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPodcastTranscriptQuery, types.GetPodcastTranscriptQueryVariables>(GetPodcastTranscriptDocument, options);
      }
export function useGetPodcastTranscriptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPodcastTranscriptQuery, types.GetPodcastTranscriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPodcastTranscriptQuery, types.GetPodcastTranscriptQueryVariables>(GetPodcastTranscriptDocument, options);
        }
export type GetPodcastTranscriptQueryHookResult = ReturnType<typeof useGetPodcastTranscriptQuery>;
export type GetPodcastTranscriptLazyQueryHookResult = ReturnType<typeof useGetPodcastTranscriptLazyQuery>;
export type GetPodcastTranscriptQueryResult = Apollo.QueryResult<types.GetPodcastTranscriptQuery, types.GetPodcastTranscriptQueryVariables>;
export const GetEbookContentByLanguageDocument = gql`
    query GetEbookContentByLanguage($id: String!, $language: String) {
  ebook(id: $id) {
    id
    chapters(language: $language) {
      name
      index
      pages
      language
    }
  }
}
    `;

/**
 * __useGetEbookContentByLanguageQuery__
 *
 * To run a query within a React component, call `useGetEbookContentByLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEbookContentByLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEbookContentByLanguageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetEbookContentByLanguageQuery(baseOptions: Apollo.QueryHookOptions<types.GetEbookContentByLanguageQuery, types.GetEbookContentByLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetEbookContentByLanguageQuery, types.GetEbookContentByLanguageQueryVariables>(GetEbookContentByLanguageDocument, options);
      }
export function useGetEbookContentByLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetEbookContentByLanguageQuery, types.GetEbookContentByLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetEbookContentByLanguageQuery, types.GetEbookContentByLanguageQueryVariables>(GetEbookContentByLanguageDocument, options);
        }
export type GetEbookContentByLanguageQueryHookResult = ReturnType<typeof useGetEbookContentByLanguageQuery>;
export type GetEbookContentByLanguageLazyQueryHookResult = ReturnType<typeof useGetEbookContentByLanguageLazyQuery>;
export type GetEbookContentByLanguageQueryResult = Apollo.QueryResult<types.GetEbookContentByLanguageQuery, types.GetEbookContentByLanguageQueryVariables>;
export const GetEbookAudioByLanguageDocument = gql`
    query GetEbookAudioByLanguage($id: String!, $language: String) {
  ebook(id: $id) {
    id
    episodes(language: $language) {
      language
      episodes {
        name
        audio {
          id
          length
          uri
        }
      }
    }
  }
}
    `;

/**
 * __useGetEbookAudioByLanguageQuery__
 *
 * To run a query within a React component, call `useGetEbookAudioByLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEbookAudioByLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEbookAudioByLanguageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetEbookAudioByLanguageQuery(baseOptions: Apollo.QueryHookOptions<types.GetEbookAudioByLanguageQuery, types.GetEbookAudioByLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetEbookAudioByLanguageQuery, types.GetEbookAudioByLanguageQueryVariables>(GetEbookAudioByLanguageDocument, options);
      }
export function useGetEbookAudioByLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetEbookAudioByLanguageQuery, types.GetEbookAudioByLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetEbookAudioByLanguageQuery, types.GetEbookAudioByLanguageQueryVariables>(GetEbookAudioByLanguageDocument, options);
        }
export type GetEbookAudioByLanguageQueryHookResult = ReturnType<typeof useGetEbookAudioByLanguageQuery>;
export type GetEbookAudioByLanguageLazyQueryHookResult = ReturnType<typeof useGetEbookAudioByLanguageLazyQuery>;
export type GetEbookAudioByLanguageQueryResult = Apollo.QueryResult<types.GetEbookAudioByLanguageQuery, types.GetEbookAudioByLanguageQueryVariables>;
export const GetEbookForConnectedPowerReadPlayerDocument = gql`
    query GetEbookForConnectedPowerReadPlayer($id: String!) {
  ebook(id: $id) {
    __typename
    id
    name
    preamble
    pagesCount
    upvoteCount
    downvoteCount
    contentRatingByUser
    userContentTracking {
      ...UserContentTracking
    }
    audioAvailableLanguages {
      code
      name
    }
    availableLanguages {
      code
      name
    }
    categories {
      id
      name
    }
    experts {
      id
      firstName
      lastName
    }
    image {
      uri
      id
    }
  }
}
    ${UserContentTrackingFragmentDoc}`;

/**
 * __useGetEbookForConnectedPowerReadPlayerQuery__
 *
 * To run a query within a React component, call `useGetEbookForConnectedPowerReadPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEbookForConnectedPowerReadPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEbookForConnectedPowerReadPlayerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEbookForConnectedPowerReadPlayerQuery(baseOptions: Apollo.QueryHookOptions<types.GetEbookForConnectedPowerReadPlayerQuery, types.GetEbookForConnectedPowerReadPlayerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetEbookForConnectedPowerReadPlayerQuery, types.GetEbookForConnectedPowerReadPlayerQueryVariables>(GetEbookForConnectedPowerReadPlayerDocument, options);
      }
export function useGetEbookForConnectedPowerReadPlayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetEbookForConnectedPowerReadPlayerQuery, types.GetEbookForConnectedPowerReadPlayerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetEbookForConnectedPowerReadPlayerQuery, types.GetEbookForConnectedPowerReadPlayerQueryVariables>(GetEbookForConnectedPowerReadPlayerDocument, options);
        }
export type GetEbookForConnectedPowerReadPlayerQueryHookResult = ReturnType<typeof useGetEbookForConnectedPowerReadPlayerQuery>;
export type GetEbookForConnectedPowerReadPlayerLazyQueryHookResult = ReturnType<typeof useGetEbookForConnectedPowerReadPlayerLazyQuery>;
export type GetEbookForConnectedPowerReadPlayerQueryResult = Apollo.QueryResult<types.GetEbookForConnectedPowerReadPlayerQuery, types.GetEbookForConnectedPowerReadPlayerQueryVariables>;
export const GetStreamSubtitlesDocument = gql`
    query GetStreamSubtitles($id: String!, $subtitleLanguage: String) {
  stream(id: $id) {
    id
    subtitle(language: $subtitleLanguage) {
      vttUrl
    }
  }
}
    `;

/**
 * __useGetStreamSubtitlesQuery__
 *
 * To run a query within a React component, call `useGetStreamSubtitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamSubtitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamSubtitlesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      subtitleLanguage: // value for 'subtitleLanguage'
 *   },
 * });
 */
export function useGetStreamSubtitlesQuery(baseOptions: Apollo.QueryHookOptions<types.GetStreamSubtitlesQuery, types.GetStreamSubtitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetStreamSubtitlesQuery, types.GetStreamSubtitlesQueryVariables>(GetStreamSubtitlesDocument, options);
      }
export function useGetStreamSubtitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetStreamSubtitlesQuery, types.GetStreamSubtitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetStreamSubtitlesQuery, types.GetStreamSubtitlesQueryVariables>(GetStreamSubtitlesDocument, options);
        }
export type GetStreamSubtitlesQueryHookResult = ReturnType<typeof useGetStreamSubtitlesQuery>;
export type GetStreamSubtitlesLazyQueryHookResult = ReturnType<typeof useGetStreamSubtitlesLazyQuery>;
export type GetStreamSubtitlesQueryResult = Apollo.QueryResult<types.GetStreamSubtitlesQuery, types.GetStreamSubtitlesQueryVariables>;
export const GetStreamForConnectedVideoPlayerDocument = gql`
    query GetStreamForConnectedVideoPlayer($audioLanguage: String, $id: String!) {
  stream(id: $id) {
    __typename
    id
    name
    wentLiveAt
    source
    preamble
    upvoteCount
    length
    contentRatingByUser
    userContentTracking {
      ...UserContentTracking
    }
    contentSegments {
      paragraph
      header
    }
    audioAvailableLanguages {
      code
      name
    }
    subtitleAvailableLanguages {
      code
      name
    }
    video(language: $audioLanguage) {
      url
      language
      orientation
    }
    categories {
      id
      name
    }
    experts {
      id
      firstName
      lastName
      image {
        id
        uri
      }
      title
      company
      isAuthenticatedUserFollowing
    }
    image {
      uri
      id
    }
    agenda {
      topic
      description
      isActive
      secondsIntoRecordingTopicStartsAt
      speakers {
        ...LimitedUser
      }
    }
  }
}
    ${UserContentTrackingFragmentDoc}
${LimitedUserFragmentDoc}`;

/**
 * __useGetStreamForConnectedVideoPlayerQuery__
 *
 * To run a query within a React component, call `useGetStreamForConnectedVideoPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamForConnectedVideoPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamForConnectedVideoPlayerQuery({
 *   variables: {
 *      audioLanguage: // value for 'audioLanguage'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStreamForConnectedVideoPlayerQuery(baseOptions: Apollo.QueryHookOptions<types.GetStreamForConnectedVideoPlayerQuery, types.GetStreamForConnectedVideoPlayerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetStreamForConnectedVideoPlayerQuery, types.GetStreamForConnectedVideoPlayerQueryVariables>(GetStreamForConnectedVideoPlayerDocument, options);
      }
export function useGetStreamForConnectedVideoPlayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetStreamForConnectedVideoPlayerQuery, types.GetStreamForConnectedVideoPlayerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetStreamForConnectedVideoPlayerQuery, types.GetStreamForConnectedVideoPlayerQueryVariables>(GetStreamForConnectedVideoPlayerDocument, options);
        }
export type GetStreamForConnectedVideoPlayerQueryHookResult = ReturnType<typeof useGetStreamForConnectedVideoPlayerQuery>;
export type GetStreamForConnectedVideoPlayerLazyQueryHookResult = ReturnType<typeof useGetStreamForConnectedVideoPlayerLazyQuery>;
export type GetStreamForConnectedVideoPlayerQueryResult = Apollo.QueryResult<types.GetStreamForConnectedVideoPlayerQuery, types.GetStreamForConnectedVideoPlayerQueryVariables>;
export const GetLiveStreamInfoByIdDocument = gql`
    query GetLiveStreamInfoById($id: String!) {
  stream(id: $id) {
    __typename
    id
    name
    source
    preamble
    experts {
      ...LiveGuest
    }
    hosts {
      expert {
        ...LiveGuest
      }
    }
  }
}
    ${LiveGuestFragmentDoc}`;

/**
 * __useGetLiveStreamInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetLiveStreamInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveStreamInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveStreamInfoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLiveStreamInfoByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetLiveStreamInfoByIdQuery, types.GetLiveStreamInfoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetLiveStreamInfoByIdQuery, types.GetLiveStreamInfoByIdQueryVariables>(GetLiveStreamInfoByIdDocument, options);
      }
export function useGetLiveStreamInfoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetLiveStreamInfoByIdQuery, types.GetLiveStreamInfoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetLiveStreamInfoByIdQuery, types.GetLiveStreamInfoByIdQueryVariables>(GetLiveStreamInfoByIdDocument, options);
        }
export type GetLiveStreamInfoByIdQueryHookResult = ReturnType<typeof useGetLiveStreamInfoByIdQuery>;
export type GetLiveStreamInfoByIdLazyQueryHookResult = ReturnType<typeof useGetLiveStreamInfoByIdLazyQuery>;
export type GetLiveStreamInfoByIdQueryResult = Apollo.QueryResult<types.GetLiveStreamInfoByIdQuery, types.GetLiveStreamInfoByIdQueryVariables>;
export const LiveStreamDocument = gql`
    subscription LiveStream($id: ID!) {
  stream(id: $id) {
    __typename
    id
    name
    source
    preamble
    channel
    status
    experts {
      ...LiveGuest
    }
    hosts {
      expert {
        ...LiveGuest
      }
      userId
      uid
      accepted
      order
      isFeatured
    }
  }
}
    ${LiveGuestFragmentDoc}`;

/**
 * __useLiveStreamSubscription__
 *
 * To run a query within a React component, call `useLiveStreamSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLiveStreamSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveStreamSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLiveStreamSubscription(baseOptions: Apollo.SubscriptionHookOptions<types.LiveStreamSubscription, types.LiveStreamSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<types.LiveStreamSubscription, types.LiveStreamSubscriptionVariables>(LiveStreamDocument, options);
      }
export type LiveStreamSubscriptionHookResult = ReturnType<typeof useLiveStreamSubscription>;
export type LiveStreamSubscriptionResult = Apollo.SubscriptionResult<types.LiveStreamSubscription>;
export const GetAssignmentTitleByIdDocument = gql`
    query GetAssignmentTitleById($id: ID!) {
  assignment(id: $id) {
    id
    assignmentTitle
    __typename
  }
}
    `;

/**
 * __useGetAssignmentTitleByIdQuery__
 *
 * To run a query within a React component, call `useGetAssignmentTitleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentTitleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentTitleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssignmentTitleByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetAssignmentTitleByIdQuery, types.GetAssignmentTitleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetAssignmentTitleByIdQuery, types.GetAssignmentTitleByIdQueryVariables>(GetAssignmentTitleByIdDocument, options);
      }
export function useGetAssignmentTitleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetAssignmentTitleByIdQuery, types.GetAssignmentTitleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetAssignmentTitleByIdQuery, types.GetAssignmentTitleByIdQueryVariables>(GetAssignmentTitleByIdDocument, options);
        }
export type GetAssignmentTitleByIdQueryHookResult = ReturnType<typeof useGetAssignmentTitleByIdQuery>;
export type GetAssignmentTitleByIdLazyQueryHookResult = ReturnType<typeof useGetAssignmentTitleByIdLazyQuery>;
export type GetAssignmentTitleByIdQueryResult = Apollo.QueryResult<types.GetAssignmentTitleByIdQuery, types.GetAssignmentTitleByIdQueryVariables>;
export const GetTrailTitleByIdDocument = gql`
    query GetTrailTitleById($id: String!) {
  learningPath(id: $id) {
    id
    name
    __typename
  }
}
    `;

/**
 * __useGetTrailTitleByIdQuery__
 *
 * To run a query within a React component, call `useGetTrailTitleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrailTitleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrailTitleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTrailTitleByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetTrailTitleByIdQuery, types.GetTrailTitleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetTrailTitleByIdQuery, types.GetTrailTitleByIdQueryVariables>(GetTrailTitleByIdDocument, options);
      }
export function useGetTrailTitleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetTrailTitleByIdQuery, types.GetTrailTitleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetTrailTitleByIdQuery, types.GetTrailTitleByIdQueryVariables>(GetTrailTitleByIdDocument, options);
        }
export type GetTrailTitleByIdQueryHookResult = ReturnType<typeof useGetTrailTitleByIdQuery>;
export type GetTrailTitleByIdLazyQueryHookResult = ReturnType<typeof useGetTrailTitleByIdLazyQuery>;
export type GetTrailTitleByIdQueryResult = Apollo.QueryResult<types.GetTrailTitleByIdQuery, types.GetTrailTitleByIdQueryVariables>;
export const GetStreamSourceDocument = gql`
    query GetStreamSource($id: String!) {
  stream(id: $id) {
    __typename
    id
    source
  }
}
    `;

/**
 * __useGetStreamSourceQuery__
 *
 * To run a query within a React component, call `useGetStreamSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamSourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStreamSourceQuery(baseOptions: Apollo.QueryHookOptions<types.GetStreamSourceQuery, types.GetStreamSourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetStreamSourceQuery, types.GetStreamSourceQueryVariables>(GetStreamSourceDocument, options);
      }
export function useGetStreamSourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetStreamSourceQuery, types.GetStreamSourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetStreamSourceQuery, types.GetStreamSourceQueryVariables>(GetStreamSourceDocument, options);
        }
export type GetStreamSourceQueryHookResult = ReturnType<typeof useGetStreamSourceQuery>;
export type GetStreamSourceLazyQueryHookResult = ReturnType<typeof useGetStreamSourceLazyQuery>;
export type GetStreamSourceQueryResult = Apollo.QueryResult<types.GetStreamSourceQuery, types.GetStreamSourceQueryVariables>;
export const GetLinkPreviewDocument = gql`
    query GetLinkPreview($link: String!) {
  getLinkPreview(link: $link) {
    description
    imageUrl
    title
    uri
  }
}
    `;

/**
 * __useGetLinkPreviewQuery__
 *
 * To run a query within a React component, call `useGetLinkPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinkPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinkPreviewQuery({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useGetLinkPreviewQuery(baseOptions: Apollo.QueryHookOptions<types.GetLinkPreviewQuery, types.GetLinkPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetLinkPreviewQuery, types.GetLinkPreviewQueryVariables>(GetLinkPreviewDocument, options);
      }
export function useGetLinkPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetLinkPreviewQuery, types.GetLinkPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetLinkPreviewQuery, types.GetLinkPreviewQueryVariables>(GetLinkPreviewDocument, options);
        }
export type GetLinkPreviewQueryHookResult = ReturnType<typeof useGetLinkPreviewQuery>;
export type GetLinkPreviewLazyQueryHookResult = ReturnType<typeof useGetLinkPreviewLazyQuery>;
export type GetLinkPreviewQueryResult = Apollo.QueryResult<types.GetLinkPreviewQuery, types.GetLinkPreviewQueryVariables>;
export const GetUserBaseProfileDocument = gql`
    query GetUserBaseProfile($id: String!) {
  user(id: $id) {
    ...UserBaseProfile
  }
}
    ${UserBaseProfileFragmentDoc}`;

/**
 * __useGetUserBaseProfileQuery__
 *
 * To run a query within a React component, call `useGetUserBaseProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBaseProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBaseProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserBaseProfileQuery(baseOptions: Apollo.QueryHookOptions<types.GetUserBaseProfileQuery, types.GetUserBaseProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserBaseProfileQuery, types.GetUserBaseProfileQueryVariables>(GetUserBaseProfileDocument, options);
      }
export function useGetUserBaseProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserBaseProfileQuery, types.GetUserBaseProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserBaseProfileQuery, types.GetUserBaseProfileQueryVariables>(GetUserBaseProfileDocument, options);
        }
export type GetUserBaseProfileQueryHookResult = ReturnType<typeof useGetUserBaseProfileQuery>;
export type GetUserBaseProfileLazyQueryHookResult = ReturnType<typeof useGetUserBaseProfileLazyQuery>;
export type GetUserBaseProfileQueryResult = Apollo.QueryResult<types.GetUserBaseProfileQuery, types.GetUserBaseProfileQueryVariables>;
export const GetAssignmentContentsByIdDocument = gql`
    query GetAssignmentContentsById($id: ID!) {
  assignment(id: $id) {
    id
    assignmentTitle
    __typename
    contents {
      edges {
        ...ContentBasicInfo
        ... on LearningPath {
          id
          name
          __typename
          contentCards {
            ...ContentBasicInfo
          }
        }
      }
    }
  }
}
    ${ContentBasicInfoFragmentDoc}`;

/**
 * __useGetAssignmentContentsByIdQuery__
 *
 * To run a query within a React component, call `useGetAssignmentContentsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentContentsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentContentsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssignmentContentsByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetAssignmentContentsByIdQuery, types.GetAssignmentContentsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetAssignmentContentsByIdQuery, types.GetAssignmentContentsByIdQueryVariables>(GetAssignmentContentsByIdDocument, options);
      }
export function useGetAssignmentContentsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetAssignmentContentsByIdQuery, types.GetAssignmentContentsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetAssignmentContentsByIdQuery, types.GetAssignmentContentsByIdQueryVariables>(GetAssignmentContentsByIdDocument, options);
        }
export type GetAssignmentContentsByIdQueryHookResult = ReturnType<typeof useGetAssignmentContentsByIdQuery>;
export type GetAssignmentContentsByIdLazyQueryHookResult = ReturnType<typeof useGetAssignmentContentsByIdLazyQuery>;
export type GetAssignmentContentsByIdQueryResult = Apollo.QueryResult<types.GetAssignmentContentsByIdQuery, types.GetAssignmentContentsByIdQueryVariables>;
export const GetTrailContentsByIdDocument = gql`
    query GetTrailContentsById($id: String!) {
  learningPath(id: $id) {
    id
    name
    __typename
    contentCards {
      ...ContentBasicInfo
    }
  }
}
    ${ContentBasicInfoFragmentDoc}`;

/**
 * __useGetTrailContentsByIdQuery__
 *
 * To run a query within a React component, call `useGetTrailContentsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrailContentsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrailContentsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTrailContentsByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetTrailContentsByIdQuery, types.GetTrailContentsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetTrailContentsByIdQuery, types.GetTrailContentsByIdQueryVariables>(GetTrailContentsByIdDocument, options);
      }
export function useGetTrailContentsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetTrailContentsByIdQuery, types.GetTrailContentsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetTrailContentsByIdQuery, types.GetTrailContentsByIdQueryVariables>(GetTrailContentsByIdDocument, options);
        }
export type GetTrailContentsByIdQueryHookResult = ReturnType<typeof useGetTrailContentsByIdQuery>;
export type GetTrailContentsByIdLazyQueryHookResult = ReturnType<typeof useGetTrailContentsByIdLazyQuery>;
export type GetTrailContentsByIdQueryResult = Apollo.QueryResult<types.GetTrailContentsByIdQuery, types.GetTrailContentsByIdQueryVariables>;
export const AssignmentStatisticsDocument = gql`
    query AssignmentStatistics($id: ID!, $filter: AssignmentStatisticsFilterInput!, $pagination: OffsetPagination!) {
  assignment(id: $id) {
    __typename
    id
    statistics(filter: $filter, pagination: $pagination) {
      edges {
        completedPieces
        completionRate
        ebooks
        ebooksCompleted
        podcasts
        podcastsCompleted
        streams
        streamsCompleted
        user {
          id
          firstName
          lastName
          organisationGroups {
            id
            name
          }
        }
      }
      meta {
        limit
        offset
        total
      }
    }
  }
}
    `;

/**
 * __useAssignmentStatisticsQuery__
 *
 * To run a query within a React component, call `useAssignmentStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentStatisticsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAssignmentStatisticsQuery(baseOptions: Apollo.QueryHookOptions<types.AssignmentStatisticsQuery, types.AssignmentStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.AssignmentStatisticsQuery, types.AssignmentStatisticsQueryVariables>(AssignmentStatisticsDocument, options);
      }
export function useAssignmentStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.AssignmentStatisticsQuery, types.AssignmentStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.AssignmentStatisticsQuery, types.AssignmentStatisticsQueryVariables>(AssignmentStatisticsDocument, options);
        }
export type AssignmentStatisticsQueryHookResult = ReturnType<typeof useAssignmentStatisticsQuery>;
export type AssignmentStatisticsLazyQueryHookResult = ReturnType<typeof useAssignmentStatisticsLazyQuery>;
export type AssignmentStatisticsQueryResult = Apollo.QueryResult<types.AssignmentStatisticsQuery, types.AssignmentStatisticsQueryVariables>;
export const ExportAssignmentStatisticsDocument = gql`
    mutation ExportAssignmentStatistics($id: ID!, $filter: AssignmentStatisticsFilterInput!) {
  exportAssignmentStatistics(id: $id, filter: $filter)
}
    `;
export type ExportAssignmentStatisticsMutationFn = Apollo.MutationFunction<types.ExportAssignmentStatisticsMutation, types.ExportAssignmentStatisticsMutationVariables>;

/**
 * __useExportAssignmentStatisticsMutation__
 *
 * To run a mutation, you first call `useExportAssignmentStatisticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAssignmentStatisticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAssignmentStatisticsMutation, { data, loading, error }] = useExportAssignmentStatisticsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportAssignmentStatisticsMutation(baseOptions?: Apollo.MutationHookOptions<types.ExportAssignmentStatisticsMutation, types.ExportAssignmentStatisticsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ExportAssignmentStatisticsMutation, types.ExportAssignmentStatisticsMutationVariables>(ExportAssignmentStatisticsDocument, options);
      }
export type ExportAssignmentStatisticsMutationHookResult = ReturnType<typeof useExportAssignmentStatisticsMutation>;
export type ExportAssignmentStatisticsMutationResult = Apollo.MutationResult<types.ExportAssignmentStatisticsMutation>;
export type ExportAssignmentStatisticsMutationOptions = Apollo.BaseMutationOptions<types.ExportAssignmentStatisticsMutation, types.ExportAssignmentStatisticsMutationVariables>;
export const GetOrgAssignmentsDocument = gql`
    query GetOrgAssignments($id: String!, $filter: AssignmentsFilter!, $sorting: SortOrdering) {
  organisation(id: $id) {
    __typename
    id
    assignments(filter: $filter, sorting: $sorting) {
      meta {
        total
        offset
        limit
      }
      edges {
        id
        assignmentTitle
        createdBy {
          firstName
          lastName
        }
        users {
          meta {
            total
          }
          edges {
            id
            firstName
            lastName
            email
            roles
            subscriptionStatus
            lastOnlineAt
            statsContentConsumedTotal
            organisationGroups {
              id
              name
            }
          }
        }
        orgGroups {
          id
          name
        }
        createdAt
        dueDate
        updatedBy {
          firstName
          lastName
        }
        updatedAt
        status
      }
    }
  }
}
    `;

/**
 * __useGetOrgAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetOrgAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgAssignmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetOrgAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgAssignmentsQuery, types.GetOrgAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgAssignmentsQuery, types.GetOrgAssignmentsQueryVariables>(GetOrgAssignmentsDocument, options);
      }
export function useGetOrgAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgAssignmentsQuery, types.GetOrgAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgAssignmentsQuery, types.GetOrgAssignmentsQueryVariables>(GetOrgAssignmentsDocument, options);
        }
export type GetOrgAssignmentsQueryHookResult = ReturnType<typeof useGetOrgAssignmentsQuery>;
export type GetOrgAssignmentsLazyQueryHookResult = ReturnType<typeof useGetOrgAssignmentsLazyQuery>;
export type GetOrgAssignmentsQueryResult = Apollo.QueryResult<types.GetOrgAssignmentsQuery, types.GetOrgAssignmentsQueryVariables>;
export const GetUserInfoByIdDocument = gql`
    query GetUserInfoById($userId: String!) {
  user(id: $userId) {
    id
    firstName
    lastName
    __typename
  }
}
    `;

/**
 * __useGetUserInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetUserInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserInfoByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetUserInfoByIdQuery, types.GetUserInfoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserInfoByIdQuery, types.GetUserInfoByIdQueryVariables>(GetUserInfoByIdDocument, options);
      }
export function useGetUserInfoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserInfoByIdQuery, types.GetUserInfoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserInfoByIdQuery, types.GetUserInfoByIdQueryVariables>(GetUserInfoByIdDocument, options);
        }
export type GetUserInfoByIdQueryHookResult = ReturnType<typeof useGetUserInfoByIdQuery>;
export type GetUserInfoByIdLazyQueryHookResult = ReturnType<typeof useGetUserInfoByIdLazyQuery>;
export type GetUserInfoByIdQueryResult = Apollo.QueryResult<types.GetUserInfoByIdQuery, types.GetUserInfoByIdQueryVariables>;
export const GetOrgGroupInfoByIdDocument = gql`
    query GetOrgGroupInfoById($organisationGroupId: String!) {
  organisationGroup(id: $organisationGroupId) {
    id
    __typename
    name
  }
}
    `;

/**
 * __useGetOrgGroupInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetOrgGroupInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgGroupInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgGroupInfoByIdQuery({
 *   variables: {
 *      organisationGroupId: // value for 'organisationGroupId'
 *   },
 * });
 */
export function useGetOrgGroupInfoByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgGroupInfoByIdQuery, types.GetOrgGroupInfoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgGroupInfoByIdQuery, types.GetOrgGroupInfoByIdQueryVariables>(GetOrgGroupInfoByIdDocument, options);
      }
export function useGetOrgGroupInfoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgGroupInfoByIdQuery, types.GetOrgGroupInfoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgGroupInfoByIdQuery, types.GetOrgGroupInfoByIdQueryVariables>(GetOrgGroupInfoByIdDocument, options);
        }
export type GetOrgGroupInfoByIdQueryHookResult = ReturnType<typeof useGetOrgGroupInfoByIdQuery>;
export type GetOrgGroupInfoByIdLazyQueryHookResult = ReturnType<typeof useGetOrgGroupInfoByIdLazyQuery>;
export type GetOrgGroupInfoByIdQueryResult = Apollo.QueryResult<types.GetOrgGroupInfoByIdQuery, types.GetOrgGroupInfoByIdQueryVariables>;
export const GetAudienceFieldsDocument = gql`
    query GetAudienceFields {
  audienceFields {
    id
    __typename
    name
    description
    type
    createdAt
  }
}
    `;

/**
 * __useGetAudienceFieldsQuery__
 *
 * To run a query within a React component, call `useGetAudienceFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudienceFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudienceFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAudienceFieldsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetAudienceFieldsQuery, types.GetAudienceFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetAudienceFieldsQuery, types.GetAudienceFieldsQueryVariables>(GetAudienceFieldsDocument, options);
      }
export function useGetAudienceFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetAudienceFieldsQuery, types.GetAudienceFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetAudienceFieldsQuery, types.GetAudienceFieldsQueryVariables>(GetAudienceFieldsDocument, options);
        }
export type GetAudienceFieldsQueryHookResult = ReturnType<typeof useGetAudienceFieldsQuery>;
export type GetAudienceFieldsLazyQueryHookResult = ReturnType<typeof useGetAudienceFieldsLazyQuery>;
export type GetAudienceFieldsQueryResult = Apollo.QueryResult<types.GetAudienceFieldsQuery, types.GetAudienceFieldsQueryVariables>;
export const GetAudienceFieldAutocompleteDocument = gql`
    query GetAudienceFieldAutocomplete($query: String!, $audienceFieldId: ID!) {
  audienceField(id: $audienceFieldId) {
    autocomplete(query: $query)
  }
}
    `;

/**
 * __useGetAudienceFieldAutocompleteQuery__
 *
 * To run a query within a React component, call `useGetAudienceFieldAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudienceFieldAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudienceFieldAutocompleteQuery({
 *   variables: {
 *      query: // value for 'query'
 *      audienceFieldId: // value for 'audienceFieldId'
 *   },
 * });
 */
export function useGetAudienceFieldAutocompleteQuery(baseOptions: Apollo.QueryHookOptions<types.GetAudienceFieldAutocompleteQuery, types.GetAudienceFieldAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetAudienceFieldAutocompleteQuery, types.GetAudienceFieldAutocompleteQueryVariables>(GetAudienceFieldAutocompleteDocument, options);
      }
export function useGetAudienceFieldAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetAudienceFieldAutocompleteQuery, types.GetAudienceFieldAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetAudienceFieldAutocompleteQuery, types.GetAudienceFieldAutocompleteQueryVariables>(GetAudienceFieldAutocompleteDocument, options);
        }
export type GetAudienceFieldAutocompleteQueryHookResult = ReturnType<typeof useGetAudienceFieldAutocompleteQuery>;
export type GetAudienceFieldAutocompleteLazyQueryHookResult = ReturnType<typeof useGetAudienceFieldAutocompleteLazyQuery>;
export type GetAudienceFieldAutocompleteQueryResult = Apollo.QueryResult<types.GetAudienceFieldAutocompleteQuery, types.GetAudienceFieldAutocompleteQueryVariables>;
export const GetAudienceFilterFunctionsDocument = gql`
    query GetAudienceFilterFunctions {
  audienceFilterFuncs {
    __typename
    type
    name
    description
  }
}
    `;

/**
 * __useGetAudienceFilterFunctionsQuery__
 *
 * To run a query within a React component, call `useGetAudienceFilterFunctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudienceFilterFunctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudienceFilterFunctionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAudienceFilterFunctionsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetAudienceFilterFunctionsQuery, types.GetAudienceFilterFunctionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetAudienceFilterFunctionsQuery, types.GetAudienceFilterFunctionsQueryVariables>(GetAudienceFilterFunctionsDocument, options);
      }
export function useGetAudienceFilterFunctionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetAudienceFilterFunctionsQuery, types.GetAudienceFilterFunctionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetAudienceFilterFunctionsQuery, types.GetAudienceFilterFunctionsQueryVariables>(GetAudienceFilterFunctionsDocument, options);
        }
export type GetAudienceFilterFunctionsQueryHookResult = ReturnType<typeof useGetAudienceFilterFunctionsQuery>;
export type GetAudienceFilterFunctionsLazyQueryHookResult = ReturnType<typeof useGetAudienceFilterFunctionsLazyQuery>;
export type GetAudienceFilterFunctionsQueryResult = Apollo.QueryResult<types.GetAudienceFilterFunctionsQuery, types.GetAudienceFilterFunctionsQueryVariables>;
export const ChangeBroadcastStatusDocument = gql`
    mutation ChangeBroadcastStatus($id: ID!, $status: StreamStatus!) {
  changeStreamStatus(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type ChangeBroadcastStatusMutationFn = Apollo.MutationFunction<types.ChangeBroadcastStatusMutation, types.ChangeBroadcastStatusMutationVariables>;

/**
 * __useChangeBroadcastStatusMutation__
 *
 * To run a mutation, you first call `useChangeBroadcastStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBroadcastStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBroadcastStatusMutation, { data, loading, error }] = useChangeBroadcastStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChangeBroadcastStatusMutation(baseOptions?: Apollo.MutationHookOptions<types.ChangeBroadcastStatusMutation, types.ChangeBroadcastStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ChangeBroadcastStatusMutation, types.ChangeBroadcastStatusMutationVariables>(ChangeBroadcastStatusDocument, options);
      }
export type ChangeBroadcastStatusMutationHookResult = ReturnType<typeof useChangeBroadcastStatusMutation>;
export type ChangeBroadcastStatusMutationResult = Apollo.MutationResult<types.ChangeBroadcastStatusMutation>;
export type ChangeBroadcastStatusMutationOptions = Apollo.BaseMutationOptions<types.ChangeBroadcastStatusMutation, types.ChangeBroadcastStatusMutationVariables>;
export const CreateEventDocument = gql`
    mutation createEvent($input: EventInput!) {
  result: createEvent(input: $input) {
    ...eventFragment
  }
}
    ${EventFragmentFragmentDoc}`;
export type CreateEventMutationFn = Apollo.MutationFunction<types.CreateEventMutation, types.CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateEventMutation, types.CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateEventMutation, types.CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<types.CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<types.CreateEventMutation, types.CreateEventMutationVariables>;
export const UpdateEventDocument = gql`
    mutation updateEvent($id: String!, $input: EventInput!) {
  result: updateEvent(input: $input, id: $id) {
    ...eventFragment
  }
}
    ${EventFragmentFragmentDoc}`;
export type UpdateEventMutationFn = Apollo.MutationFunction<types.UpdateEventMutation, types.UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateEventMutation, types.UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateEventMutation, types.UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<types.UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<types.UpdateEventMutation, types.UpdateEventMutationVariables>;
export const DeleteEventDocument = gql`
    mutation deleteEvent($id: String!) {
  deleteEvent(id: $id)
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<types.DeleteEventMutation, types.DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteEventMutation, types.DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteEventMutation, types.DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<types.DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<types.DeleteEventMutation, types.DeleteEventMutationVariables>;
export const CreateAudienceDocument = gql`
    mutation CreateAudience($input: CreateAudienceInput!) {
  createAudience(input: $input) {
    id
    name
    description
    createdAt
    criteria
    updatedAt
    users {
      meta {
        total
      }
    }
  }
}
    `;
export type CreateAudienceMutationFn = Apollo.MutationFunction<types.CreateAudienceMutation, types.CreateAudienceMutationVariables>;

/**
 * __useCreateAudienceMutation__
 *
 * To run a mutation, you first call `useCreateAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAudienceMutation, { data, loading, error }] = useCreateAudienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAudienceMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateAudienceMutation, types.CreateAudienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateAudienceMutation, types.CreateAudienceMutationVariables>(CreateAudienceDocument, options);
      }
export type CreateAudienceMutationHookResult = ReturnType<typeof useCreateAudienceMutation>;
export type CreateAudienceMutationResult = Apollo.MutationResult<types.CreateAudienceMutation>;
export type CreateAudienceMutationOptions = Apollo.BaseMutationOptions<types.CreateAudienceMutation, types.CreateAudienceMutationVariables>;
export const RefreshAudienceUsersDocument = gql`
    mutation RefreshAudienceUsers($refreshAudienceUsersId: ID!) {
  refreshAudienceUsers(id: $refreshAudienceUsersId) {
    id
  }
}
    `;
export type RefreshAudienceUsersMutationFn = Apollo.MutationFunction<types.RefreshAudienceUsersMutation, types.RefreshAudienceUsersMutationVariables>;

/**
 * __useRefreshAudienceUsersMutation__
 *
 * To run a mutation, you first call `useRefreshAudienceUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAudienceUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAudienceUsersMutation, { data, loading, error }] = useRefreshAudienceUsersMutation({
 *   variables: {
 *      refreshAudienceUsersId: // value for 'refreshAudienceUsersId'
 *   },
 * });
 */
export function useRefreshAudienceUsersMutation(baseOptions?: Apollo.MutationHookOptions<types.RefreshAudienceUsersMutation, types.RefreshAudienceUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.RefreshAudienceUsersMutation, types.RefreshAudienceUsersMutationVariables>(RefreshAudienceUsersDocument, options);
      }
export type RefreshAudienceUsersMutationHookResult = ReturnType<typeof useRefreshAudienceUsersMutation>;
export type RefreshAudienceUsersMutationResult = Apollo.MutationResult<types.RefreshAudienceUsersMutation>;
export type RefreshAudienceUsersMutationOptions = Apollo.BaseMutationOptions<types.RefreshAudienceUsersMutation, types.RefreshAudienceUsersMutationVariables>;
export const UpdateAudienceRefreshIntervalDocument = gql`
    mutation UpdateAudienceRefreshInterval($updateAudienceRefreshIntervalId: ID!, $triggerFrequency: TriggerFrequency!, $weeklyTriggerDays: [WeeklyTriggerDay!]) {
  updateAudienceRefreshInterval(
    id: $updateAudienceRefreshIntervalId
    triggerFrequency: $triggerFrequency
    weeklyTriggerDays: $weeklyTriggerDays
  ) {
    id
  }
}
    `;
export type UpdateAudienceRefreshIntervalMutationFn = Apollo.MutationFunction<types.UpdateAudienceRefreshIntervalMutation, types.UpdateAudienceRefreshIntervalMutationVariables>;

/**
 * __useUpdateAudienceRefreshIntervalMutation__
 *
 * To run a mutation, you first call `useUpdateAudienceRefreshIntervalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAudienceRefreshIntervalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAudienceRefreshIntervalMutation, { data, loading, error }] = useUpdateAudienceRefreshIntervalMutation({
 *   variables: {
 *      updateAudienceRefreshIntervalId: // value for 'updateAudienceRefreshIntervalId'
 *      triggerFrequency: // value for 'triggerFrequency'
 *      weeklyTriggerDays: // value for 'weeklyTriggerDays'
 *   },
 * });
 */
export function useUpdateAudienceRefreshIntervalMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateAudienceRefreshIntervalMutation, types.UpdateAudienceRefreshIntervalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateAudienceRefreshIntervalMutation, types.UpdateAudienceRefreshIntervalMutationVariables>(UpdateAudienceRefreshIntervalDocument, options);
      }
export type UpdateAudienceRefreshIntervalMutationHookResult = ReturnType<typeof useUpdateAudienceRefreshIntervalMutation>;
export type UpdateAudienceRefreshIntervalMutationResult = Apollo.MutationResult<types.UpdateAudienceRefreshIntervalMutation>;
export type UpdateAudienceRefreshIntervalMutationOptions = Apollo.BaseMutationOptions<types.UpdateAudienceRefreshIntervalMutation, types.UpdateAudienceRefreshIntervalMutationVariables>;
export const AudienceUserPreviewDocument = gql`
    query AudienceUserPreview($input: AudienceUserPreviewInput!) {
  audienceUserPreview(input: $input) {
    edges {
      id
      firstName
      lastName
      email
      lastOnlineAt
      contentCompletedCount
    }
    meta {
      total
      limit
      offset
    }
  }
}
    `;

/**
 * __useAudienceUserPreviewQuery__
 *
 * To run a query within a React component, call `useAudienceUserPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudienceUserPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudienceUserPreviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAudienceUserPreviewQuery(baseOptions: Apollo.QueryHookOptions<types.AudienceUserPreviewQuery, types.AudienceUserPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.AudienceUserPreviewQuery, types.AudienceUserPreviewQueryVariables>(AudienceUserPreviewDocument, options);
      }
export function useAudienceUserPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.AudienceUserPreviewQuery, types.AudienceUserPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.AudienceUserPreviewQuery, types.AudienceUserPreviewQueryVariables>(AudienceUserPreviewDocument, options);
        }
export type AudienceUserPreviewQueryHookResult = ReturnType<typeof useAudienceUserPreviewQuery>;
export type AudienceUserPreviewLazyQueryHookResult = ReturnType<typeof useAudienceUserPreviewLazyQuery>;
export type AudienceUserPreviewQueryResult = Apollo.QueryResult<types.AudienceUserPreviewQuery, types.AudienceUserPreviewQueryVariables>;
export const UpdateAudienceDocument = gql`
    mutation UpdateAudience($updateAudienceId: ID!, $input: UpdateAudienceInput!) {
  updateAudience(id: $updateAudienceId, input: $input) {
    __typename
    id
    name
    description
    criteria
    removeUsersInNextRefreshThatNoLongerMeetCriteria
  }
}
    `;
export type UpdateAudienceMutationFn = Apollo.MutationFunction<types.UpdateAudienceMutation, types.UpdateAudienceMutationVariables>;

/**
 * __useUpdateAudienceMutation__
 *
 * To run a mutation, you first call `useUpdateAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAudienceMutation, { data, loading, error }] = useUpdateAudienceMutation({
 *   variables: {
 *      updateAudienceId: // value for 'updateAudienceId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAudienceMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateAudienceMutation, types.UpdateAudienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateAudienceMutation, types.UpdateAudienceMutationVariables>(UpdateAudienceDocument, options);
      }
export type UpdateAudienceMutationHookResult = ReturnType<typeof useUpdateAudienceMutation>;
export type UpdateAudienceMutationResult = Apollo.MutationResult<types.UpdateAudienceMutation>;
export type UpdateAudienceMutationOptions = Apollo.BaseMutationOptions<types.UpdateAudienceMutation, types.UpdateAudienceMutationVariables>;
export const GetOrgGroupDocument = gql`
    query GetOrgGroup($id: String!) {
  organisationGroup(id: $id) {
    id
    name
    updatedAt
    createdAt
    assignments {
      id
      assignmentTitle
    }
    audience {
      id
      name
      description
      criteria
      removeUsersInNextRefreshThatNoLongerMeetCriteria
      nextRefreshAt
      refreshInterval {
        frequency
        days
      }
      lastRefreshedAt
      isUsersListInSyncWithCriteria
    }
    isRefreshingAudience
  }
}
    `;

/**
 * __useGetOrgGroupQuery__
 *
 * To run a query within a React component, call `useGetOrgGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgGroupQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgGroupQuery, types.GetOrgGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgGroupQuery, types.GetOrgGroupQueryVariables>(GetOrgGroupDocument, options);
      }
export function useGetOrgGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgGroupQuery, types.GetOrgGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgGroupQuery, types.GetOrgGroupQueryVariables>(GetOrgGroupDocument, options);
        }
export type GetOrgGroupQueryHookResult = ReturnType<typeof useGetOrgGroupQuery>;
export type GetOrgGroupLazyQueryHookResult = ReturnType<typeof useGetOrgGroupLazyQuery>;
export type GetOrgGroupQueryResult = Apollo.QueryResult<types.GetOrgGroupQuery, types.GetOrgGroupQueryVariables>;
export const GetDataLakeLastUpdatedDocument = gql`
    query GetDataLakeLastUpdated {
  dataLakeLastUpdatedAt
}
    `;

/**
 * __useGetDataLakeLastUpdatedQuery__
 *
 * To run a query within a React component, call `useGetDataLakeLastUpdatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataLakeLastUpdatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataLakeLastUpdatedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDataLakeLastUpdatedQuery(baseOptions?: Apollo.QueryHookOptions<types.GetDataLakeLastUpdatedQuery, types.GetDataLakeLastUpdatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetDataLakeLastUpdatedQuery, types.GetDataLakeLastUpdatedQueryVariables>(GetDataLakeLastUpdatedDocument, options);
      }
export function useGetDataLakeLastUpdatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetDataLakeLastUpdatedQuery, types.GetDataLakeLastUpdatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetDataLakeLastUpdatedQuery, types.GetDataLakeLastUpdatedQueryVariables>(GetDataLakeLastUpdatedDocument, options);
        }
export type GetDataLakeLastUpdatedQueryHookResult = ReturnType<typeof useGetDataLakeLastUpdatedQuery>;
export type GetDataLakeLastUpdatedLazyQueryHookResult = ReturnType<typeof useGetDataLakeLastUpdatedLazyQuery>;
export type GetDataLakeLastUpdatedQueryResult = Apollo.QueryResult<types.GetDataLakeLastUpdatedQuery, types.GetDataLakeLastUpdatedQueryVariables>;
export const GetContentTitlesDocument = gql`
    query GetContentTitles($filter: ContentCardsFilter, $sorting: ContentCardsSorting) {
  contentCards(filter: $filter, sorting: $sorting) {
    edges {
      ... on Ebook {
        id
        name
      }
      ... on Event {
        id
        name
      }
      ... on Podcast {
        id
        name
      }
      ... on Stream {
        id
        name
      }
      ... on Expert {
        id
        name: firstName
      }
      ... on LearningPath {
        id
        name
        contentCards {
          __typename
          ... on Ebook {
            id
          }
          ... on Event {
            id
          }
          ... on Podcast {
            id
          }
          ... on Stream {
            id
          }
        }
      }
    }
    meta {
      recommendationId
      total
      offset
      limit
    }
  }
}
    `;

/**
 * __useGetContentTitlesQuery__
 *
 * To run a query within a React component, call `useGetContentTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentTitlesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetContentTitlesQuery(baseOptions?: Apollo.QueryHookOptions<types.GetContentTitlesQuery, types.GetContentTitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetContentTitlesQuery, types.GetContentTitlesQueryVariables>(GetContentTitlesDocument, options);
      }
export function useGetContentTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetContentTitlesQuery, types.GetContentTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetContentTitlesQuery, types.GetContentTitlesQueryVariables>(GetContentTitlesDocument, options);
        }
export type GetContentTitlesQueryHookResult = ReturnType<typeof useGetContentTitlesQuery>;
export type GetContentTitlesLazyQueryHookResult = ReturnType<typeof useGetContentTitlesLazyQuery>;
export type GetContentTitlesQueryResult = Apollo.QueryResult<types.GetContentTitlesQuery, types.GetContentTitlesQueryVariables>;
export const GetOrgAssignmentsTitlesDocument = gql`
    query GetOrgAssignmentsTitles($id: String!, $filter: AssignmentsFilter!, $sorting: SortOrdering) {
  organisation(id: $id) {
    __typename
    id
    assignments(filter: $filter, sorting: $sorting) {
      meta {
        total
        offset
        limit
      }
      edges {
        id
        name: assignmentTitle
        status
        users {
          meta {
            total
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrgAssignmentsTitlesQuery__
 *
 * To run a query within a React component, call `useGetOrgAssignmentsTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgAssignmentsTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgAssignmentsTitlesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetOrgAssignmentsTitlesQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgAssignmentsTitlesQuery, types.GetOrgAssignmentsTitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgAssignmentsTitlesQuery, types.GetOrgAssignmentsTitlesQueryVariables>(GetOrgAssignmentsTitlesDocument, options);
      }
export function useGetOrgAssignmentsTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgAssignmentsTitlesQuery, types.GetOrgAssignmentsTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgAssignmentsTitlesQuery, types.GetOrgAssignmentsTitlesQueryVariables>(GetOrgAssignmentsTitlesDocument, options);
        }
export type GetOrgAssignmentsTitlesQueryHookResult = ReturnType<typeof useGetOrgAssignmentsTitlesQuery>;
export type GetOrgAssignmentsTitlesLazyQueryHookResult = ReturnType<typeof useGetOrgAssignmentsTitlesLazyQuery>;
export type GetOrgAssignmentsTitlesQueryResult = Apollo.QueryResult<types.GetOrgAssignmentsTitlesQuery, types.GetOrgAssignmentsTitlesQueryVariables>;
export const ExportActivationByUserReportDocument = gql`
    mutation ExportActivationByUserReport($filter: ActivationByUserReportFilter!) {
  exportActivationByUserReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportActivationByUserReportMutationFn = Apollo.MutationFunction<types.ExportActivationByUserReportMutation, types.ExportActivationByUserReportMutationVariables>;

/**
 * __useExportActivationByUserReportMutation__
 *
 * To run a mutation, you first call `useExportActivationByUserReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportActivationByUserReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportActivationByUserReportMutation, { data, loading, error }] = useExportActivationByUserReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportActivationByUserReportMutation(baseOptions?: Apollo.MutationHookOptions<types.ExportActivationByUserReportMutation, types.ExportActivationByUserReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ExportActivationByUserReportMutation, types.ExportActivationByUserReportMutationVariables>(ExportActivationByUserReportDocument, options);
      }
export type ExportActivationByUserReportMutationHookResult = ReturnType<typeof useExportActivationByUserReportMutation>;
export type ExportActivationByUserReportMutationResult = Apollo.MutationResult<types.ExportActivationByUserReportMutation>;
export type ExportActivationByUserReportMutationOptions = Apollo.BaseMutationOptions<types.ExportActivationByUserReportMutation, types.ExportActivationByUserReportMutationVariables>;
export const ExportActivationOverviewReportDocument = gql`
    mutation ExportActivationOverviewReport($filter: ActivationOverviewReportFilter!) {
  exportActivationOverviewReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportActivationOverviewReportMutationFn = Apollo.MutationFunction<types.ExportActivationOverviewReportMutation, types.ExportActivationOverviewReportMutationVariables>;

/**
 * __useExportActivationOverviewReportMutation__
 *
 * To run a mutation, you first call `useExportActivationOverviewReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportActivationOverviewReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportActivationOverviewReportMutation, { data, loading, error }] = useExportActivationOverviewReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportActivationOverviewReportMutation(baseOptions?: Apollo.MutationHookOptions<types.ExportActivationOverviewReportMutation, types.ExportActivationOverviewReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ExportActivationOverviewReportMutation, types.ExportActivationOverviewReportMutationVariables>(ExportActivationOverviewReportDocument, options);
      }
export type ExportActivationOverviewReportMutationHookResult = ReturnType<typeof useExportActivationOverviewReportMutation>;
export type ExportActivationOverviewReportMutationResult = Apollo.MutationResult<types.ExportActivationOverviewReportMutation>;
export type ExportActivationOverviewReportMutationOptions = Apollo.BaseMutationOptions<types.ExportActivationOverviewReportMutation, types.ExportActivationOverviewReportMutationVariables>;
export const ExportUserContentStatisticsReportDocument = gql`
    mutation ExportUserContentStatisticsReport($filter: UserContentStatisticsReportFilter!) {
  exportUserContentStatisticsReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportUserContentStatisticsReportMutationFn = Apollo.MutationFunction<types.ExportUserContentStatisticsReportMutation, types.ExportUserContentStatisticsReportMutationVariables>;

/**
 * __useExportUserContentStatisticsReportMutation__
 *
 * To run a mutation, you first call `useExportUserContentStatisticsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUserContentStatisticsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUserContentStatisticsReportMutation, { data, loading, error }] = useExportUserContentStatisticsReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportUserContentStatisticsReportMutation(baseOptions?: Apollo.MutationHookOptions<types.ExportUserContentStatisticsReportMutation, types.ExportUserContentStatisticsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ExportUserContentStatisticsReportMutation, types.ExportUserContentStatisticsReportMutationVariables>(ExportUserContentStatisticsReportDocument, options);
      }
export type ExportUserContentStatisticsReportMutationHookResult = ReturnType<typeof useExportUserContentStatisticsReportMutation>;
export type ExportUserContentStatisticsReportMutationResult = Apollo.MutationResult<types.ExportUserContentStatisticsReportMutation>;
export type ExportUserContentStatisticsReportMutationOptions = Apollo.BaseMutationOptions<types.ExportUserContentStatisticsReportMutation, types.ExportUserContentStatisticsReportMutationVariables>;
export const ExportUserAssignmentStatisticsReportDocument = gql`
    mutation ExportUserAssignmentStatisticsReport($filter: UserAssignmentStatisticsReportFilter!) {
  exportUserAssignmentStatisticsReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportUserAssignmentStatisticsReportMutationFn = Apollo.MutationFunction<types.ExportUserAssignmentStatisticsReportMutation, types.ExportUserAssignmentStatisticsReportMutationVariables>;

/**
 * __useExportUserAssignmentStatisticsReportMutation__
 *
 * To run a mutation, you first call `useExportUserAssignmentStatisticsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUserAssignmentStatisticsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUserAssignmentStatisticsReportMutation, { data, loading, error }] = useExportUserAssignmentStatisticsReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportUserAssignmentStatisticsReportMutation(baseOptions?: Apollo.MutationHookOptions<types.ExportUserAssignmentStatisticsReportMutation, types.ExportUserAssignmentStatisticsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ExportUserAssignmentStatisticsReportMutation, types.ExportUserAssignmentStatisticsReportMutationVariables>(ExportUserAssignmentStatisticsReportDocument, options);
      }
export type ExportUserAssignmentStatisticsReportMutationHookResult = ReturnType<typeof useExportUserAssignmentStatisticsReportMutation>;
export type ExportUserAssignmentStatisticsReportMutationResult = Apollo.MutationResult<types.ExportUserAssignmentStatisticsReportMutation>;
export type ExportUserAssignmentStatisticsReportMutationOptions = Apollo.BaseMutationOptions<types.ExportUserAssignmentStatisticsReportMutation, types.ExportUserAssignmentStatisticsReportMutationVariables>;
export const ExportUserTrailStatisticsReportDocument = gql`
    mutation ExportUserTrailStatisticsReport($filter: UserTrailStatisticsReportFilter!) {
  exportUserTrailStatisticsReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportUserTrailStatisticsReportMutationFn = Apollo.MutationFunction<types.ExportUserTrailStatisticsReportMutation, types.ExportUserTrailStatisticsReportMutationVariables>;

/**
 * __useExportUserTrailStatisticsReportMutation__
 *
 * To run a mutation, you first call `useExportUserTrailStatisticsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUserTrailStatisticsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUserTrailStatisticsReportMutation, { data, loading, error }] = useExportUserTrailStatisticsReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportUserTrailStatisticsReportMutation(baseOptions?: Apollo.MutationHookOptions<types.ExportUserTrailStatisticsReportMutation, types.ExportUserTrailStatisticsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ExportUserTrailStatisticsReportMutation, types.ExportUserTrailStatisticsReportMutationVariables>(ExportUserTrailStatisticsReportDocument, options);
      }
export type ExportUserTrailStatisticsReportMutationHookResult = ReturnType<typeof useExportUserTrailStatisticsReportMutation>;
export type ExportUserTrailStatisticsReportMutationResult = Apollo.MutationResult<types.ExportUserTrailStatisticsReportMutation>;
export type ExportUserTrailStatisticsReportMutationOptions = Apollo.BaseMutationOptions<types.ExportUserTrailStatisticsReportMutation, types.ExportUserTrailStatisticsReportMutationVariables>;
export const ExportEngagementReportDocument = gql`
    mutation ExportEngagementReport($filter: EngagementReportFilter!) {
  exportEngagementReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportEngagementReportMutationFn = Apollo.MutationFunction<types.ExportEngagementReportMutation, types.ExportEngagementReportMutationVariables>;

/**
 * __useExportEngagementReportMutation__
 *
 * To run a mutation, you first call `useExportEngagementReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportEngagementReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportEngagementReportMutation, { data, loading, error }] = useExportEngagementReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportEngagementReportMutation(baseOptions?: Apollo.MutationHookOptions<types.ExportEngagementReportMutation, types.ExportEngagementReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ExportEngagementReportMutation, types.ExportEngagementReportMutationVariables>(ExportEngagementReportDocument, options);
      }
export type ExportEngagementReportMutationHookResult = ReturnType<typeof useExportEngagementReportMutation>;
export type ExportEngagementReportMutationResult = Apollo.MutationResult<types.ExportEngagementReportMutation>;
export type ExportEngagementReportMutationOptions = Apollo.BaseMutationOptions<types.ExportEngagementReportMutation, types.ExportEngagementReportMutationVariables>;
export const ExportUserCommentsReportDocument = gql`
    mutation ExportUserCommentsReport($filter: UserCommentsReportFilter!) {
  exportUserCommentsReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportUserCommentsReportMutationFn = Apollo.MutationFunction<types.ExportUserCommentsReportMutation, types.ExportUserCommentsReportMutationVariables>;

/**
 * __useExportUserCommentsReportMutation__
 *
 * To run a mutation, you first call `useExportUserCommentsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUserCommentsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUserCommentsReportMutation, { data, loading, error }] = useExportUserCommentsReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportUserCommentsReportMutation(baseOptions?: Apollo.MutationHookOptions<types.ExportUserCommentsReportMutation, types.ExportUserCommentsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ExportUserCommentsReportMutation, types.ExportUserCommentsReportMutationVariables>(ExportUserCommentsReportDocument, options);
      }
export type ExportUserCommentsReportMutationHookResult = ReturnType<typeof useExportUserCommentsReportMutation>;
export type ExportUserCommentsReportMutationResult = Apollo.MutationResult<types.ExportUserCommentsReportMutation>;
export type ExportUserCommentsReportMutationOptions = Apollo.BaseMutationOptions<types.ExportUserCommentsReportMutation, types.ExportUserCommentsReportMutationVariables>;
export const ExportContentStatisticsReportDocument = gql`
    mutation ExportContentStatisticsReport($filter: ContentStatisticsReportFilter!) {
  exportContentStatisticsReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportContentStatisticsReportMutationFn = Apollo.MutationFunction<types.ExportContentStatisticsReportMutation, types.ExportContentStatisticsReportMutationVariables>;

/**
 * __useExportContentStatisticsReportMutation__
 *
 * To run a mutation, you first call `useExportContentStatisticsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportContentStatisticsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportContentStatisticsReportMutation, { data, loading, error }] = useExportContentStatisticsReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportContentStatisticsReportMutation(baseOptions?: Apollo.MutationHookOptions<types.ExportContentStatisticsReportMutation, types.ExportContentStatisticsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ExportContentStatisticsReportMutation, types.ExportContentStatisticsReportMutationVariables>(ExportContentStatisticsReportDocument, options);
      }
export type ExportContentStatisticsReportMutationHookResult = ReturnType<typeof useExportContentStatisticsReportMutation>;
export type ExportContentStatisticsReportMutationResult = Apollo.MutationResult<types.ExportContentStatisticsReportMutation>;
export type ExportContentStatisticsReportMutationOptions = Apollo.BaseMutationOptions<types.ExportContentStatisticsReportMutation, types.ExportContentStatisticsReportMutationVariables>;
export const GetOrgUsersListDocument = gql`
    query GetOrgUsersList($id: String!, $filter: OrganisationMemberFilter, $isCurrentUserAdmin: Boolean!) {
  organisation(id: $id) {
    __typename
    id
    members(filter: $filter) {
      edges {
        id
        firstName
        lastName
        email @include(if: $isCurrentUserAdmin)
        avatarImage {
          id
          uri
          blurHash
        }
      }
      meta {
        total
        offset
        limit
      }
    }
  }
}
    `;

/**
 * __useGetOrgUsersListQuery__
 *
 * To run a query within a React component, call `useGetOrgUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgUsersListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      isCurrentUserAdmin: // value for 'isCurrentUserAdmin'
 *   },
 * });
 */
export function useGetOrgUsersListQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgUsersListQuery, types.GetOrgUsersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgUsersListQuery, types.GetOrgUsersListQueryVariables>(GetOrgUsersListDocument, options);
      }
export function useGetOrgUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgUsersListQuery, types.GetOrgUsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgUsersListQuery, types.GetOrgUsersListQueryVariables>(GetOrgUsersListDocument, options);
        }
export type GetOrgUsersListQueryHookResult = ReturnType<typeof useGetOrgUsersListQuery>;
export type GetOrgUsersListLazyQueryHookResult = ReturnType<typeof useGetOrgUsersListLazyQuery>;
export type GetOrgUsersListQueryResult = Apollo.QueryResult<types.GetOrgUsersListQuery, types.GetOrgUsersListQueryVariables>;
export const GetOrgCreatedAtDocument = gql`
    query GetOrgCreatedAt($organisationId: String!) {
  organisation(id: $organisationId) {
    createdAt
    id
    members {
      meta {
        total
      }
    }
  }
}
    `;

/**
 * __useGetOrgCreatedAtQuery__
 *
 * To run a query within a React component, call `useGetOrgCreatedAtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgCreatedAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgCreatedAtQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetOrgCreatedAtQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgCreatedAtQuery, types.GetOrgCreatedAtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgCreatedAtQuery, types.GetOrgCreatedAtQueryVariables>(GetOrgCreatedAtDocument, options);
      }
export function useGetOrgCreatedAtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgCreatedAtQuery, types.GetOrgCreatedAtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgCreatedAtQuery, types.GetOrgCreatedAtQueryVariables>(GetOrgCreatedAtDocument, options);
        }
export type GetOrgCreatedAtQueryHookResult = ReturnType<typeof useGetOrgCreatedAtQuery>;
export type GetOrgCreatedAtLazyQueryHookResult = ReturnType<typeof useGetOrgCreatedAtLazyQuery>;
export type GetOrgCreatedAtQueryResult = Apollo.QueryResult<types.GetOrgCreatedAtQuery, types.GetOrgCreatedAtQueryVariables>;
export const GetLearningPathInfoByIdDocument = gql`
    query GetLearningPathInfoById($id: String!) {
  learningPath(id: $id) {
    ...LearningPathInfo
  }
}
    ${LearningPathInfoFragmentDoc}`;

/**
 * __useGetLearningPathInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetLearningPathInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningPathInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningPathInfoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLearningPathInfoByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetLearningPathInfoByIdQuery, types.GetLearningPathInfoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetLearningPathInfoByIdQuery, types.GetLearningPathInfoByIdQueryVariables>(GetLearningPathInfoByIdDocument, options);
      }
export function useGetLearningPathInfoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetLearningPathInfoByIdQuery, types.GetLearningPathInfoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetLearningPathInfoByIdQuery, types.GetLearningPathInfoByIdQueryVariables>(GetLearningPathInfoByIdDocument, options);
        }
export type GetLearningPathInfoByIdQueryHookResult = ReturnType<typeof useGetLearningPathInfoByIdQuery>;
export type GetLearningPathInfoByIdLazyQueryHookResult = ReturnType<typeof useGetLearningPathInfoByIdLazyQuery>;
export type GetLearningPathInfoByIdQueryResult = Apollo.QueryResult<types.GetLearningPathInfoByIdQuery, types.GetLearningPathInfoByIdQueryVariables>;
export const GetOrgInviteLinkDocument = gql`
    query GetOrgInviteLink($id: String!) {
  organisation(id: $id) {
    id
    invitationToken
  }
}
    `;

/**
 * __useGetOrgInviteLinkQuery__
 *
 * To run a query within a React component, call `useGetOrgInviteLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgInviteLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgInviteLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgInviteLinkQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgInviteLinkQuery, types.GetOrgInviteLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgInviteLinkQuery, types.GetOrgInviteLinkQueryVariables>(GetOrgInviteLinkDocument, options);
      }
export function useGetOrgInviteLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgInviteLinkQuery, types.GetOrgInviteLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgInviteLinkQuery, types.GetOrgInviteLinkQueryVariables>(GetOrgInviteLinkDocument, options);
        }
export type GetOrgInviteLinkQueryHookResult = ReturnType<typeof useGetOrgInviteLinkQuery>;
export type GetOrgInviteLinkLazyQueryHookResult = ReturnType<typeof useGetOrgInviteLinkLazyQuery>;
export type GetOrgInviteLinkQueryResult = Apollo.QueryResult<types.GetOrgInviteLinkQuery, types.GetOrgInviteLinkQueryVariables>;
export const GetOrgMembersDocument = gql`
    query GetOrgMembers($id: String!, $filter: OrganisationMemberFilter!, $sorting: SortOrdering!) {
  organisation(id: $id) {
    id
    name
    members(filter: $filter, sorting: $sorting) {
      edges {
        id
        firstName
        lastName
        email
        lastOnlineAt
        contentCompletedCount
      }
      meta {
        limit
        offset
        total
      }
    }
  }
}
    `;

/**
 * __useGetOrgMembersQuery__
 *
 * To run a query within a React component, call `useGetOrgMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetOrgMembersQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgMembersQuery, types.GetOrgMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgMembersQuery, types.GetOrgMembersQueryVariables>(GetOrgMembersDocument, options);
      }
export function useGetOrgMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgMembersQuery, types.GetOrgMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgMembersQuery, types.GetOrgMembersQueryVariables>(GetOrgMembersDocument, options);
        }
export type GetOrgMembersQueryHookResult = ReturnType<typeof useGetOrgMembersQuery>;
export type GetOrgMembersLazyQueryHookResult = ReturnType<typeof useGetOrgMembersLazyQuery>;
export type GetOrgMembersQueryResult = Apollo.QueryResult<types.GetOrgMembersQuery, types.GetOrgMembersQueryVariables>;
export const NudgeOrgUserDocument = gql`
    mutation NudgeOrgUser($userId: ID!, $orgId: ID!) {
  organisationAdminNudgeUser(userId: $userId, organisationId: $orgId) {
    success
  }
}
    `;
export type NudgeOrgUserMutationFn = Apollo.MutationFunction<types.NudgeOrgUserMutation, types.NudgeOrgUserMutationVariables>;

/**
 * __useNudgeOrgUserMutation__
 *
 * To run a mutation, you first call `useNudgeOrgUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNudgeOrgUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nudgeOrgUserMutation, { data, loading, error }] = useNudgeOrgUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useNudgeOrgUserMutation(baseOptions?: Apollo.MutationHookOptions<types.NudgeOrgUserMutation, types.NudgeOrgUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.NudgeOrgUserMutation, types.NudgeOrgUserMutationVariables>(NudgeOrgUserDocument, options);
      }
export type NudgeOrgUserMutationHookResult = ReturnType<typeof useNudgeOrgUserMutation>;
export type NudgeOrgUserMutationResult = Apollo.MutationResult<types.NudgeOrgUserMutation>;
export type NudgeOrgUserMutationOptions = Apollo.BaseMutationOptions<types.NudgeOrgUserMutation, types.NudgeOrgUserMutationVariables>;
export const NudgeAllOrgInactiveUsersDocument = gql`
    mutation NudgeAllOrgInactiveUsers($orgId: ID!) {
  organisationAdminNudgeAllUsers(organisationId: $orgId) {
    success
  }
}
    `;
export type NudgeAllOrgInactiveUsersMutationFn = Apollo.MutationFunction<types.NudgeAllOrgInactiveUsersMutation, types.NudgeAllOrgInactiveUsersMutationVariables>;

/**
 * __useNudgeAllOrgInactiveUsersMutation__
 *
 * To run a mutation, you first call `useNudgeAllOrgInactiveUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNudgeAllOrgInactiveUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nudgeAllOrgInactiveUsersMutation, { data, loading, error }] = useNudgeAllOrgInactiveUsersMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useNudgeAllOrgInactiveUsersMutation(baseOptions?: Apollo.MutationHookOptions<types.NudgeAllOrgInactiveUsersMutation, types.NudgeAllOrgInactiveUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.NudgeAllOrgInactiveUsersMutation, types.NudgeAllOrgInactiveUsersMutationVariables>(NudgeAllOrgInactiveUsersDocument, options);
      }
export type NudgeAllOrgInactiveUsersMutationHookResult = ReturnType<typeof useNudgeAllOrgInactiveUsersMutation>;
export type NudgeAllOrgInactiveUsersMutationResult = Apollo.MutationResult<types.NudgeAllOrgInactiveUsersMutation>;
export type NudgeAllOrgInactiveUsersMutationOptions = Apollo.BaseMutationOptions<types.NudgeAllOrgInactiveUsersMutation, types.NudgeAllOrgInactiveUsersMutationVariables>;
export const SetUserArchivedStateDocument = gql`
    mutation SetUserArchivedState($userID: ID!, $isArchived: Boolean!) {
  setUserArchivedState(id: $userID, isArchived: $isArchived) {
    id
    archivedAt
  }
}
    `;
export type SetUserArchivedStateMutationFn = Apollo.MutationFunction<types.SetUserArchivedStateMutation, types.SetUserArchivedStateMutationVariables>;

/**
 * __useSetUserArchivedStateMutation__
 *
 * To run a mutation, you first call `useSetUserArchivedStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserArchivedStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserArchivedStateMutation, { data, loading, error }] = useSetUserArchivedStateMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useSetUserArchivedStateMutation(baseOptions?: Apollo.MutationHookOptions<types.SetUserArchivedStateMutation, types.SetUserArchivedStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.SetUserArchivedStateMutation, types.SetUserArchivedStateMutationVariables>(SetUserArchivedStateDocument, options);
      }
export type SetUserArchivedStateMutationHookResult = ReturnType<typeof useSetUserArchivedStateMutation>;
export type SetUserArchivedStateMutationResult = Apollo.MutationResult<types.SetUserArchivedStateMutation>;
export type SetUserArchivedStateMutationOptions = Apollo.BaseMutationOptions<types.SetUserArchivedStateMutation, types.SetUserArchivedStateMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(id: $id, reasons: []) {
    success
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<types.DeleteUserMutation, types.DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteUserMutation, types.DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteUserMutation, types.DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<types.DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<types.DeleteUserMutation, types.DeleteUserMutationVariables>;
export const GetUserAssignmentStatisticsByIdDocument = gql`
    query GetUserAssignmentStatisticsById($id: String!, $filter: AssignmentsFilter!, $sorting: SortOrdering) {
  user(id: $id) {
    __typename
    id
    assignments(filter: $filter, sorting: $sorting) {
      meta {
        total
        limit
        offset
      }
      edges {
        id
        assignmentTitle
        completionRate
        userAssignmentStatistics {
          assignedTrails
          completedTrails
          assignedPodcasts
          completedPodcasts
          assignedEbooks
          completedEbooks
          assignedStreams
          completedStreams
        }
        timeConsumed
      }
    }
  }
}
    `;

/**
 * __useGetUserAssignmentStatisticsByIdQuery__
 *
 * To run a query within a React component, call `useGetUserAssignmentStatisticsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAssignmentStatisticsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAssignmentStatisticsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetUserAssignmentStatisticsByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetUserAssignmentStatisticsByIdQuery, types.GetUserAssignmentStatisticsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserAssignmentStatisticsByIdQuery, types.GetUserAssignmentStatisticsByIdQueryVariables>(GetUserAssignmentStatisticsByIdDocument, options);
      }
export function useGetUserAssignmentStatisticsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserAssignmentStatisticsByIdQuery, types.GetUserAssignmentStatisticsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserAssignmentStatisticsByIdQuery, types.GetUserAssignmentStatisticsByIdQueryVariables>(GetUserAssignmentStatisticsByIdDocument, options);
        }
export type GetUserAssignmentStatisticsByIdQueryHookResult = ReturnType<typeof useGetUserAssignmentStatisticsByIdQuery>;
export type GetUserAssignmentStatisticsByIdLazyQueryHookResult = ReturnType<typeof useGetUserAssignmentStatisticsByIdLazyQuery>;
export type GetUserAssignmentStatisticsByIdQueryResult = Apollo.QueryResult<types.GetUserAssignmentStatisticsByIdQuery, types.GetUserAssignmentStatisticsByIdQueryVariables>;
export const CreateStreamDocument = gql`
    mutation CreateStream($input: StreamInput!) {
  createStream(input: $input) {
    ... on ValidationError {
      fieldErrors {
        path
        message
      }
    }
    ... on Stream {
      ...streamFragment
    }
  }
}
    ${StreamFragmentFragmentDoc}`;
export type CreateStreamMutationFn = Apollo.MutationFunction<types.CreateStreamMutation, types.CreateStreamMutationVariables>;

/**
 * __useCreateStreamMutation__
 *
 * To run a mutation, you first call `useCreateStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStreamMutation, { data, loading, error }] = useCreateStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStreamMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateStreamMutation, types.CreateStreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateStreamMutation, types.CreateStreamMutationVariables>(CreateStreamDocument, options);
      }
export type CreateStreamMutationHookResult = ReturnType<typeof useCreateStreamMutation>;
export type CreateStreamMutationResult = Apollo.MutationResult<types.CreateStreamMutation>;
export type CreateStreamMutationOptions = Apollo.BaseMutationOptions<types.CreateStreamMutation, types.CreateStreamMutationVariables>;
export const UpdateStreamDocument = gql`
    mutation UpdateStream($updateStreamId: String!, $input: StreamInput!) {
  updateStream(id: $updateStreamId, input: $input) {
    ... on ValidationError {
      fieldErrors {
        path
        message
      }
    }
    ... on NotFoundError {
      message
    }
    ... on Stream {
      ...streamFragment
    }
  }
}
    ${StreamFragmentFragmentDoc}`;
export type UpdateStreamMutationFn = Apollo.MutationFunction<types.UpdateStreamMutation, types.UpdateStreamMutationVariables>;

/**
 * __useUpdateStreamMutation__
 *
 * To run a mutation, you first call `useUpdateStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStreamMutation, { data, loading, error }] = useUpdateStreamMutation({
 *   variables: {
 *      updateStreamId: // value for 'updateStreamId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStreamMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateStreamMutation, types.UpdateStreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateStreamMutation, types.UpdateStreamMutationVariables>(UpdateStreamDocument, options);
      }
export type UpdateStreamMutationHookResult = ReturnType<typeof useUpdateStreamMutation>;
export type UpdateStreamMutationResult = Apollo.MutationResult<types.UpdateStreamMutation>;
export type UpdateStreamMutationOptions = Apollo.BaseMutationOptions<types.UpdateStreamMutation, types.UpdateStreamMutationVariables>;
export const UpsertStreamMediaDocument = gql`
    mutation UpsertStreamMedia($input: UpsertStreamMediaInput!) {
  upsertStreamMedia(input: $input) {
    ... on ValidationError {
      fieldErrors {
        path
        message
      }
    }
    ... on NotFoundError {
      message
    }
    ... on Stream {
      id
    }
  }
}
    `;
export type UpsertStreamMediaMutationFn = Apollo.MutationFunction<types.UpsertStreamMediaMutation, types.UpsertStreamMediaMutationVariables>;

/**
 * __useUpsertStreamMediaMutation__
 *
 * To run a mutation, you first call `useUpsertStreamMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertStreamMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertStreamMediaMutation, { data, loading, error }] = useUpsertStreamMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertStreamMediaMutation(baseOptions?: Apollo.MutationHookOptions<types.UpsertStreamMediaMutation, types.UpsertStreamMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpsertStreamMediaMutation, types.UpsertStreamMediaMutationVariables>(UpsertStreamMediaDocument, options);
      }
export type UpsertStreamMediaMutationHookResult = ReturnType<typeof useUpsertStreamMediaMutation>;
export type UpsertStreamMediaMutationResult = Apollo.MutationResult<types.UpsertStreamMediaMutation>;
export type UpsertStreamMediaMutationOptions = Apollo.BaseMutationOptions<types.UpsertStreamMediaMutation, types.UpsertStreamMediaMutationVariables>;
export const DeleteStreamDocument = gql`
    mutation deleteStream($id: String!) {
  deleteStream(id: $id)
}
    `;
export type DeleteStreamMutationFn = Apollo.MutationFunction<types.DeleteStreamMutation, types.DeleteStreamMutationVariables>;

/**
 * __useDeleteStreamMutation__
 *
 * To run a mutation, you first call `useDeleteStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStreamMutation, { data, loading, error }] = useDeleteStreamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStreamMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteStreamMutation, types.DeleteStreamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteStreamMutation, types.DeleteStreamMutationVariables>(DeleteStreamDocument, options);
      }
export type DeleteStreamMutationHookResult = ReturnType<typeof useDeleteStreamMutation>;
export type DeleteStreamMutationResult = Apollo.MutationResult<types.DeleteStreamMutation>;
export type DeleteStreamMutationOptions = Apollo.BaseMutationOptions<types.DeleteStreamMutation, types.DeleteStreamMutationVariables>;
export const FetchStreamDocument = gql`
    query fetchStream($id: String!) {
  stream(id: $id) {
    ...streamFragment
  }
}
    ${StreamFragmentFragmentDoc}`;

/**
 * __useFetchStreamQuery__
 *
 * To run a query within a React component, call `useFetchStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchStreamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchStreamQuery(baseOptions: Apollo.QueryHookOptions<types.FetchStreamQuery, types.FetchStreamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.FetchStreamQuery, types.FetchStreamQueryVariables>(FetchStreamDocument, options);
      }
export function useFetchStreamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.FetchStreamQuery, types.FetchStreamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.FetchStreamQuery, types.FetchStreamQueryVariables>(FetchStreamDocument, options);
        }
export type FetchStreamQueryHookResult = ReturnType<typeof useFetchStreamQuery>;
export type FetchStreamLazyQueryHookResult = ReturnType<typeof useFetchStreamLazyQuery>;
export type FetchStreamQueryResult = Apollo.QueryResult<types.FetchStreamQuery, types.FetchStreamQueryVariables>;
export const FetchStreamsDocument = gql`
    query fetchStreams($filter: StreamFilter!, $sorting: SortOrdering) {
  streams(filter: $filter, sorting: $sorting) {
    edges {
      ...streamFragment
    }
    meta {
      total
      offset
      limit
    }
  }
}
    ${StreamFragmentFragmentDoc}`;

/**
 * __useFetchStreamsQuery__
 *
 * To run a query within a React component, call `useFetchStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchStreamsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useFetchStreamsQuery(baseOptions: Apollo.QueryHookOptions<types.FetchStreamsQuery, types.FetchStreamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.FetchStreamsQuery, types.FetchStreamsQueryVariables>(FetchStreamsDocument, options);
      }
export function useFetchStreamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.FetchStreamsQuery, types.FetchStreamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.FetchStreamsQuery, types.FetchStreamsQueryVariables>(FetchStreamsDocument, options);
        }
export type FetchStreamsQueryHookResult = ReturnType<typeof useFetchStreamsQuery>;
export type FetchStreamsLazyQueryHookResult = ReturnType<typeof useFetchStreamsLazyQuery>;
export type FetchStreamsQueryResult = Apollo.QueryResult<types.FetchStreamsQuery, types.FetchStreamsQueryVariables>;
export const GetAccessLevelsDocument = gql`
    query GetAccessLevels($organisationId: String!, $pagination: OffsetPagination) {
  organisation(id: $organisationId) {
    id
    iamRoles(pagination: $pagination) {
      meta {
        limit
        offset
        total
      }
      edges {
        id
        name
        canEdit
        permissions {
          isAllowed
          permission {
            id
            name
            description
            identifier
          }
        }
        users {
          meta {
            total
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAccessLevelsQuery__
 *
 * To run a query within a React component, call `useGetAccessLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessLevelsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetAccessLevelsQuery(baseOptions: Apollo.QueryHookOptions<types.GetAccessLevelsQuery, types.GetAccessLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetAccessLevelsQuery, types.GetAccessLevelsQueryVariables>(GetAccessLevelsDocument, options);
      }
export function useGetAccessLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetAccessLevelsQuery, types.GetAccessLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetAccessLevelsQuery, types.GetAccessLevelsQueryVariables>(GetAccessLevelsDocument, options);
        }
export type GetAccessLevelsQueryHookResult = ReturnType<typeof useGetAccessLevelsQuery>;
export type GetAccessLevelsLazyQueryHookResult = ReturnType<typeof useGetAccessLevelsLazyQuery>;
export type GetAccessLevelsQueryResult = Apollo.QueryResult<types.GetAccessLevelsQuery, types.GetAccessLevelsQueryVariables>;
export const GetPermissionsDocument = gql`
    query GetPermissions {
  permissions {
    createdAt
    deletedAt
    description
    id
    name
    identifier
    updatedAt
  }
}
    `;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetPermissionsQuery, types.GetPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPermissionsQuery, types.GetPermissionsQueryVariables>(GetPermissionsDocument, options);
      }
export function useGetPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPermissionsQuery, types.GetPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPermissionsQuery, types.GetPermissionsQueryVariables>(GetPermissionsDocument, options);
        }
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsQueryResult = Apollo.QueryResult<types.GetPermissionsQuery, types.GetPermissionsQueryVariables>;
export const DeleteAccessLevelDocument = gql`
    mutation DeleteAccessLevel($deleteAccessLevelId: ID!) {
  deleteIAMRole(id: $deleteAccessLevelId)
}
    `;
export type DeleteAccessLevelMutationFn = Apollo.MutationFunction<types.DeleteAccessLevelMutation, types.DeleteAccessLevelMutationVariables>;

/**
 * __useDeleteAccessLevelMutation__
 *
 * To run a mutation, you first call `useDeleteAccessLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccessLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccessLevelMutation, { data, loading, error }] = useDeleteAccessLevelMutation({
 *   variables: {
 *      deleteAccessLevelId: // value for 'deleteAccessLevelId'
 *   },
 * });
 */
export function useDeleteAccessLevelMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteAccessLevelMutation, types.DeleteAccessLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteAccessLevelMutation, types.DeleteAccessLevelMutationVariables>(DeleteAccessLevelDocument, options);
      }
export type DeleteAccessLevelMutationHookResult = ReturnType<typeof useDeleteAccessLevelMutation>;
export type DeleteAccessLevelMutationResult = Apollo.MutationResult<types.DeleteAccessLevelMutation>;
export type DeleteAccessLevelMutationOptions = Apollo.BaseMutationOptions<types.DeleteAccessLevelMutation, types.DeleteAccessLevelMutationVariables>;
export const CreateAccessLevelDocument = gql`
    mutation CreateAccessLevel($input: IAMRoleInput) {
  createIAMRole(input: $input) {
    ... on IAMRole {
      id
      name
      canEdit
      roleType
      updatedAt
      deletedAt
      createdAt
      permissions {
        isAllowed
        permission {
          id
          name
          description
          deletedAt
          identifier
        }
      }
    }
    ... on ValidationError {
      fieldErrors {
        message
        path
      }
    }
  }
}
    `;
export type CreateAccessLevelMutationFn = Apollo.MutationFunction<types.CreateAccessLevelMutation, types.CreateAccessLevelMutationVariables>;

/**
 * __useCreateAccessLevelMutation__
 *
 * To run a mutation, you first call `useCreateAccessLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccessLevelMutation, { data, loading, error }] = useCreateAccessLevelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccessLevelMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateAccessLevelMutation, types.CreateAccessLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateAccessLevelMutation, types.CreateAccessLevelMutationVariables>(CreateAccessLevelDocument, options);
      }
export type CreateAccessLevelMutationHookResult = ReturnType<typeof useCreateAccessLevelMutation>;
export type CreateAccessLevelMutationResult = Apollo.MutationResult<types.CreateAccessLevelMutation>;
export type CreateAccessLevelMutationOptions = Apollo.BaseMutationOptions<types.CreateAccessLevelMutation, types.CreateAccessLevelMutationVariables>;
export const UpdateAccessLevelDocument = gql`
    mutation UpdateAccessLevel($updateIamRoleId: ID!, $input: IAMRoleInput) {
  updateIAMRole(id: $updateIamRoleId, input: $input) {
    ... on ValidationError {
      fieldErrors {
        message
        path
      }
    }
    ... on NotFoundError {
      message
    }
    ... on IAMRole {
      id
      name
      canEdit
      roleType
      updatedAt
      deletedAt
      createdAt
      permissions {
        isAllowed
        permission {
          id
          name
          description
          deletedAt
          identifier
        }
      }
      users {
        meta {
          total
          limit
          offset
        }
        edges {
          ...LimitedUser
          email
        }
      }
    }
  }
}
    ${LimitedUserFragmentDoc}`;
export type UpdateAccessLevelMutationFn = Apollo.MutationFunction<types.UpdateAccessLevelMutation, types.UpdateAccessLevelMutationVariables>;

/**
 * __useUpdateAccessLevelMutation__
 *
 * To run a mutation, you first call `useUpdateAccessLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessLevelMutation, { data, loading, error }] = useUpdateAccessLevelMutation({
 *   variables: {
 *      updateIamRoleId: // value for 'updateIamRoleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccessLevelMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateAccessLevelMutation, types.UpdateAccessLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateAccessLevelMutation, types.UpdateAccessLevelMutationVariables>(UpdateAccessLevelDocument, options);
      }
export type UpdateAccessLevelMutationHookResult = ReturnType<typeof useUpdateAccessLevelMutation>;
export type UpdateAccessLevelMutationResult = Apollo.MutationResult<types.UpdateAccessLevelMutation>;
export type UpdateAccessLevelMutationOptions = Apollo.BaseMutationOptions<types.UpdateAccessLevelMutation, types.UpdateAccessLevelMutationVariables>;
export const GetRoleByIdDocument = gql`
    query GetRoleById($iamRoleId: ID!, $pagination: OffsetPagination) {
  iamRole(id: $iamRoleId) {
    permissions {
      isAllowed
      permission {
        description
        id
        name
        identifier
      }
    }
    roleType
    canEdit
    id
    name
    users(pagination: $pagination) {
      meta {
        total
        limit
        offset
      }
      edges {
        ...LimitedUser
        email
      }
    }
  }
}
    ${LimitedUserFragmentDoc}`;

/**
 * __useGetRoleByIdQuery__
 *
 * To run a query within a React component, call `useGetRoleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleByIdQuery({
 *   variables: {
 *      iamRoleId: // value for 'iamRoleId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetRoleByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetRoleByIdQuery, types.GetRoleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetRoleByIdQuery, types.GetRoleByIdQueryVariables>(GetRoleByIdDocument, options);
      }
export function useGetRoleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetRoleByIdQuery, types.GetRoleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetRoleByIdQuery, types.GetRoleByIdQueryVariables>(GetRoleByIdDocument, options);
        }
export type GetRoleByIdQueryHookResult = ReturnType<typeof useGetRoleByIdQuery>;
export type GetRoleByIdLazyQueryHookResult = ReturnType<typeof useGetRoleByIdLazyQuery>;
export type GetRoleByIdQueryResult = Apollo.QueryResult<types.GetRoleByIdQuery, types.GetRoleByIdQueryVariables>;
export const AddIamRoleUsersDocument = gql`
    mutation AddIAMRoleUsers($roleId: ID!, $input: AddIAMRoleUsersInput!) {
  addIAMRoleUsers(id: $roleId, input: $input) {
    ... on IAMRole {
      id
      name
      organisationId
      users {
        edges {
          ...LimitedUser
          email
        }
        meta {
          limit
          offset
          total
        }
      }
      roleType
      permissions {
        isAllowed
        permission {
          description
          id
          name
          identifier
        }
      }
      canEdit
      createdAt
      updatedAt
      deletedAt
    }
    ... on ValidationError {
      fieldErrors {
        message
        path
      }
    }
    ... on NotFoundError {
      message
    }
  }
}
    ${LimitedUserFragmentDoc}`;
export type AddIamRoleUsersMutationFn = Apollo.MutationFunction<types.AddIamRoleUsersMutation, types.AddIamRoleUsersMutationVariables>;

/**
 * __useAddIamRoleUsersMutation__
 *
 * To run a mutation, you first call `useAddIamRoleUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIamRoleUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIamRoleUsersMutation, { data, loading, error }] = useAddIamRoleUsersMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddIamRoleUsersMutation(baseOptions?: Apollo.MutationHookOptions<types.AddIamRoleUsersMutation, types.AddIamRoleUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.AddIamRoleUsersMutation, types.AddIamRoleUsersMutationVariables>(AddIamRoleUsersDocument, options);
      }
export type AddIamRoleUsersMutationHookResult = ReturnType<typeof useAddIamRoleUsersMutation>;
export type AddIamRoleUsersMutationResult = Apollo.MutationResult<types.AddIamRoleUsersMutation>;
export type AddIamRoleUsersMutationOptions = Apollo.BaseMutationOptions<types.AddIamRoleUsersMutation, types.AddIamRoleUsersMutationVariables>;
export const RemoveIamRoleUsersDocument = gql`
    mutation RemoveIAMRoleUsers($roleId: ID!, $userIds: [ID!]!) {
  removeIAMRoleUsers(id: $roleId, userIds: $userIds) {
    ... on IAMRole {
      id
      name
      organisationId
      users {
        edges {
          id
        }
        meta {
          offset
          limit
          total
        }
      }
      roleType
      permissions {
        isAllowed
        permission {
          description
          id
          name
          identifier
        }
      }
      canEdit
      createdAt
      updatedAt
      deletedAt
    }
    ... on ValidationError {
      fieldErrors {
        message
        path
      }
    }
    ... on NotFoundError {
      message
    }
  }
}
    `;
export type RemoveIamRoleUsersMutationFn = Apollo.MutationFunction<types.RemoveIamRoleUsersMutation, types.RemoveIamRoleUsersMutationVariables>;

/**
 * __useRemoveIamRoleUsersMutation__
 *
 * To run a mutation, you first call `useRemoveIamRoleUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveIamRoleUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeIamRoleUsersMutation, { data, loading, error }] = useRemoveIamRoleUsersMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useRemoveIamRoleUsersMutation(baseOptions?: Apollo.MutationHookOptions<types.RemoveIamRoleUsersMutation, types.RemoveIamRoleUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.RemoveIamRoleUsersMutation, types.RemoveIamRoleUsersMutationVariables>(RemoveIamRoleUsersDocument, options);
      }
export type RemoveIamRoleUsersMutationHookResult = ReturnType<typeof useRemoveIamRoleUsersMutation>;
export type RemoveIamRoleUsersMutationResult = Apollo.MutationResult<types.RemoveIamRoleUsersMutation>;
export type RemoveIamRoleUsersMutationOptions = Apollo.BaseMutationOptions<types.RemoveIamRoleUsersMutation, types.RemoveIamRoleUsersMutationVariables>;
export const GetOrganisationDetailsForAdminSettingsDocument = gql`
    query GetOrganisationDetailsForAdminSettings {
  me {
    id
    organisation {
      id
      name
      type
      activeUsers
      subscription {
        currentPeriodEnd
        status
      }
    }
  }
}
    `;

/**
 * __useGetOrganisationDetailsForAdminSettingsQuery__
 *
 * To run a query within a React component, call `useGetOrganisationDetailsForAdminSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationDetailsForAdminSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationDetailsForAdminSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganisationDetailsForAdminSettingsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetOrganisationDetailsForAdminSettingsQuery, types.GetOrganisationDetailsForAdminSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganisationDetailsForAdminSettingsQuery, types.GetOrganisationDetailsForAdminSettingsQueryVariables>(GetOrganisationDetailsForAdminSettingsDocument, options);
      }
export function useGetOrganisationDetailsForAdminSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganisationDetailsForAdminSettingsQuery, types.GetOrganisationDetailsForAdminSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganisationDetailsForAdminSettingsQuery, types.GetOrganisationDetailsForAdminSettingsQueryVariables>(GetOrganisationDetailsForAdminSettingsDocument, options);
        }
export type GetOrganisationDetailsForAdminSettingsQueryHookResult = ReturnType<typeof useGetOrganisationDetailsForAdminSettingsQuery>;
export type GetOrganisationDetailsForAdminSettingsLazyQueryHookResult = ReturnType<typeof useGetOrganisationDetailsForAdminSettingsLazyQuery>;
export type GetOrganisationDetailsForAdminSettingsQueryResult = Apollo.QueryResult<types.GetOrganisationDetailsForAdminSettingsQuery, types.GetOrganisationDetailsForAdminSettingsQueryVariables>;
export const GetUserByIdDocument = gql`
    query getUserById($id: String!) {
  user(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetUserByIdQuery, types.GetUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserByIdQuery, types.GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserByIdQuery, types.GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserByIdQuery, types.GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<types.GetUserByIdQuery, types.GetUserByIdQueryVariables>;
export const GetAssignmentsStatisticsByAssignmentDocument = gql`
    query GetAssignmentsStatisticsByAssignment($orgId: String!, $filter: StatisticsByAssignmentFilter!) {
  organisation(id: $orgId) {
    __typename
    id
    assignmentStatisticsByAssignment(filter: $filter) {
      edges {
        __typename
        isTotal
        assignmentId
        assignmentTitle
        usersAssigned
        usersStarted
        usersCompleted
        percentUsersStarted
        percentUsersCompleted
        completionRate
        durationConsumed
        percentUsersLiked
      }
      meta {
        total
        limit
        offset
      }
    }
  }
}
    `;

/**
 * __useGetAssignmentsStatisticsByAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAssignmentsStatisticsByAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentsStatisticsByAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentsStatisticsByAssignmentQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAssignmentsStatisticsByAssignmentQuery(baseOptions: Apollo.QueryHookOptions<types.GetAssignmentsStatisticsByAssignmentQuery, types.GetAssignmentsStatisticsByAssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetAssignmentsStatisticsByAssignmentQuery, types.GetAssignmentsStatisticsByAssignmentQueryVariables>(GetAssignmentsStatisticsByAssignmentDocument, options);
      }
export function useGetAssignmentsStatisticsByAssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetAssignmentsStatisticsByAssignmentQuery, types.GetAssignmentsStatisticsByAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetAssignmentsStatisticsByAssignmentQuery, types.GetAssignmentsStatisticsByAssignmentQueryVariables>(GetAssignmentsStatisticsByAssignmentDocument, options);
        }
export type GetAssignmentsStatisticsByAssignmentQueryHookResult = ReturnType<typeof useGetAssignmentsStatisticsByAssignmentQuery>;
export type GetAssignmentsStatisticsByAssignmentLazyQueryHookResult = ReturnType<typeof useGetAssignmentsStatisticsByAssignmentLazyQuery>;
export type GetAssignmentsStatisticsByAssignmentQueryResult = Apollo.QueryResult<types.GetAssignmentsStatisticsByAssignmentQuery, types.GetAssignmentsStatisticsByAssignmentQueryVariables>;
export const GetAssignmentsStatisticsByGroupDocument = gql`
    query GetAssignmentsStatisticsByGroup($orgId: String!, $filter: StatisticsByGroupFilter!) {
  organisation(id: $orgId) {
    __typename
    id
    assignmentStatisticsByGroup(filter: $filter) {
      edges {
        __typename
        isTotal
        groupId
        groupName
        assignmentId
        assignmentTitle
        usersAssigned
        usersStarted
        usersCompleted
        percentUsersLiked
        completionRate
        durationConsumed
        totalDurationConsumed
      }
      meta {
        total
        limit
        offset
      }
    }
  }
}
    `;

/**
 * __useGetAssignmentsStatisticsByGroupQuery__
 *
 * To run a query within a React component, call `useGetAssignmentsStatisticsByGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentsStatisticsByGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentsStatisticsByGroupQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAssignmentsStatisticsByGroupQuery(baseOptions: Apollo.QueryHookOptions<types.GetAssignmentsStatisticsByGroupQuery, types.GetAssignmentsStatisticsByGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetAssignmentsStatisticsByGroupQuery, types.GetAssignmentsStatisticsByGroupQueryVariables>(GetAssignmentsStatisticsByGroupDocument, options);
      }
export function useGetAssignmentsStatisticsByGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetAssignmentsStatisticsByGroupQuery, types.GetAssignmentsStatisticsByGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetAssignmentsStatisticsByGroupQuery, types.GetAssignmentsStatisticsByGroupQueryVariables>(GetAssignmentsStatisticsByGroupDocument, options);
        }
export type GetAssignmentsStatisticsByGroupQueryHookResult = ReturnType<typeof useGetAssignmentsStatisticsByGroupQuery>;
export type GetAssignmentsStatisticsByGroupLazyQueryHookResult = ReturnType<typeof useGetAssignmentsStatisticsByGroupLazyQuery>;
export type GetAssignmentsStatisticsByGroupQueryResult = Apollo.QueryResult<types.GetAssignmentsStatisticsByGroupQuery, types.GetAssignmentsStatisticsByGroupQueryVariables>;
export const GetAssignmentsStatisticsByUserDocument = gql`
    query GetAssignmentsStatisticsByUser($orgId: String!, $filter: StatisticsByUserFilter!) {
  organisation(id: $orgId) {
    __typename
    id
    assignmentStatisticsByUser(filter: $filter) {
      edges {
        __typename
        isTotal
        email
        userId
        userName
        assignmentId
        assignmentTitle
        assignmentAssigned
        assignmentStarted
        assignmentCompleted
        completionRate
        durationConsumed
        dateCompletion
      }
      meta {
        total
        limit
        offset
      }
    }
  }
}
    `;

/**
 * __useGetAssignmentsStatisticsByUserQuery__
 *
 * To run a query within a React component, call `useGetAssignmentsStatisticsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentsStatisticsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentsStatisticsByUserQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAssignmentsStatisticsByUserQuery(baseOptions: Apollo.QueryHookOptions<types.GetAssignmentsStatisticsByUserQuery, types.GetAssignmentsStatisticsByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetAssignmentsStatisticsByUserQuery, types.GetAssignmentsStatisticsByUserQueryVariables>(GetAssignmentsStatisticsByUserDocument, options);
      }
export function useGetAssignmentsStatisticsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetAssignmentsStatisticsByUserQuery, types.GetAssignmentsStatisticsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetAssignmentsStatisticsByUserQuery, types.GetAssignmentsStatisticsByUserQueryVariables>(GetAssignmentsStatisticsByUserDocument, options);
        }
export type GetAssignmentsStatisticsByUserQueryHookResult = ReturnType<typeof useGetAssignmentsStatisticsByUserQuery>;
export type GetAssignmentsStatisticsByUserLazyQueryHookResult = ReturnType<typeof useGetAssignmentsStatisticsByUserLazyQuery>;
export type GetAssignmentsStatisticsByUserQueryResult = Apollo.QueryResult<types.GetAssignmentsStatisticsByUserQuery, types.GetAssignmentsStatisticsByUserQueryVariables>;
export const ExportAssignmentStatisticsByAssignmentDocument = gql`
    mutation ExportAssignmentStatisticsByAssignment($orgId: ID!, $filter: StatisticsByAssignmentFilter!) {
  organisationExportAssignmentStatisticsByAssignment(
    orgId: $orgId
    filter: $filter
  ) {
    success
  }
}
    `;
export type ExportAssignmentStatisticsByAssignmentMutationFn = Apollo.MutationFunction<types.ExportAssignmentStatisticsByAssignmentMutation, types.ExportAssignmentStatisticsByAssignmentMutationVariables>;

/**
 * __useExportAssignmentStatisticsByAssignmentMutation__
 *
 * To run a mutation, you first call `useExportAssignmentStatisticsByAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAssignmentStatisticsByAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAssignmentStatisticsByAssignmentMutation, { data, loading, error }] = useExportAssignmentStatisticsByAssignmentMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportAssignmentStatisticsByAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<types.ExportAssignmentStatisticsByAssignmentMutation, types.ExportAssignmentStatisticsByAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ExportAssignmentStatisticsByAssignmentMutation, types.ExportAssignmentStatisticsByAssignmentMutationVariables>(ExportAssignmentStatisticsByAssignmentDocument, options);
      }
export type ExportAssignmentStatisticsByAssignmentMutationHookResult = ReturnType<typeof useExportAssignmentStatisticsByAssignmentMutation>;
export type ExportAssignmentStatisticsByAssignmentMutationResult = Apollo.MutationResult<types.ExportAssignmentStatisticsByAssignmentMutation>;
export type ExportAssignmentStatisticsByAssignmentMutationOptions = Apollo.BaseMutationOptions<types.ExportAssignmentStatisticsByAssignmentMutation, types.ExportAssignmentStatisticsByAssignmentMutationVariables>;
export const ExportAssignmentStatisticsByGroupDocument = gql`
    mutation ExportAssignmentStatisticsByGroup($orgId: ID!, $filter: StatisticsByGroupFilter!) {
  organisationExportAssignmentStatisticsByGroup(orgId: $orgId, filter: $filter) {
    success
  }
}
    `;
export type ExportAssignmentStatisticsByGroupMutationFn = Apollo.MutationFunction<types.ExportAssignmentStatisticsByGroupMutation, types.ExportAssignmentStatisticsByGroupMutationVariables>;

/**
 * __useExportAssignmentStatisticsByGroupMutation__
 *
 * To run a mutation, you first call `useExportAssignmentStatisticsByGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAssignmentStatisticsByGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAssignmentStatisticsByGroupMutation, { data, loading, error }] = useExportAssignmentStatisticsByGroupMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportAssignmentStatisticsByGroupMutation(baseOptions?: Apollo.MutationHookOptions<types.ExportAssignmentStatisticsByGroupMutation, types.ExportAssignmentStatisticsByGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ExportAssignmentStatisticsByGroupMutation, types.ExportAssignmentStatisticsByGroupMutationVariables>(ExportAssignmentStatisticsByGroupDocument, options);
      }
export type ExportAssignmentStatisticsByGroupMutationHookResult = ReturnType<typeof useExportAssignmentStatisticsByGroupMutation>;
export type ExportAssignmentStatisticsByGroupMutationResult = Apollo.MutationResult<types.ExportAssignmentStatisticsByGroupMutation>;
export type ExportAssignmentStatisticsByGroupMutationOptions = Apollo.BaseMutationOptions<types.ExportAssignmentStatisticsByGroupMutation, types.ExportAssignmentStatisticsByGroupMutationVariables>;
export const ExportAssignmentStatisticsByUserDocument = gql`
    mutation ExportAssignmentStatisticsByUser($orgId: ID!, $filter: StatisticsByUserFilter!) {
  organisationExportAssignmentStatisticsByUser(orgId: $orgId, filter: $filter) {
    success
  }
}
    `;
export type ExportAssignmentStatisticsByUserMutationFn = Apollo.MutationFunction<types.ExportAssignmentStatisticsByUserMutation, types.ExportAssignmentStatisticsByUserMutationVariables>;

/**
 * __useExportAssignmentStatisticsByUserMutation__
 *
 * To run a mutation, you first call `useExportAssignmentStatisticsByUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAssignmentStatisticsByUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAssignmentStatisticsByUserMutation, { data, loading, error }] = useExportAssignmentStatisticsByUserMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportAssignmentStatisticsByUserMutation(baseOptions?: Apollo.MutationHookOptions<types.ExportAssignmentStatisticsByUserMutation, types.ExportAssignmentStatisticsByUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ExportAssignmentStatisticsByUserMutation, types.ExportAssignmentStatisticsByUserMutationVariables>(ExportAssignmentStatisticsByUserDocument, options);
      }
export type ExportAssignmentStatisticsByUserMutationHookResult = ReturnType<typeof useExportAssignmentStatisticsByUserMutation>;
export type ExportAssignmentStatisticsByUserMutationResult = Apollo.MutationResult<types.ExportAssignmentStatisticsByUserMutation>;
export type ExportAssignmentStatisticsByUserMutationOptions = Apollo.BaseMutationOptions<types.ExportAssignmentStatisticsByUserMutation, types.ExportAssignmentStatisticsByUserMutationVariables>;
export const GetFeedContentDocument = gql`
    query GetFeedContent($pagination: CursorPaginationInput) {
  feedV2(pagination: $pagination) {
    meta {
      hasNext
      hasPrev
      nextCursor
      prevCursor
    }
    edges {
      cursor
      feed {
        ... on ContentActivity {
          ...ContentActivity
        }
        ... on Post {
          ...Post
        }
        ... on PostActivity {
          ...PostActivity
        }
      }
    }
  }
}
    ${ContentActivityFragmentDoc}
${PostFragmentDoc}
${PostActivityFragmentDoc}`;

/**
 * __useGetFeedContentQuery__
 *
 * To run a query within a React component, call `useGetFeedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedContentQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetFeedContentQuery(baseOptions?: Apollo.QueryHookOptions<types.GetFeedContentQuery, types.GetFeedContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetFeedContentQuery, types.GetFeedContentQueryVariables>(GetFeedContentDocument, options);
      }
export function useGetFeedContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetFeedContentQuery, types.GetFeedContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetFeedContentQuery, types.GetFeedContentQueryVariables>(GetFeedContentDocument, options);
        }
export type GetFeedContentQueryHookResult = ReturnType<typeof useGetFeedContentQuery>;
export type GetFeedContentLazyQueryHookResult = ReturnType<typeof useGetFeedContentLazyQuery>;
export type GetFeedContentQueryResult = Apollo.QueryResult<types.GetFeedContentQuery, types.GetFeedContentQueryVariables>;
export const CreatePostDocument = gql`
    mutation CreatePost($input: CreatePostInput!) {
  createPost(input: $input)
}
    `;
export type CreatePostMutationFn = Apollo.MutationFunction<types.CreatePostMutation, types.CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePostMutation(baseOptions?: Apollo.MutationHookOptions<types.CreatePostMutation, types.CreatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreatePostMutation, types.CreatePostMutationVariables>(CreatePostDocument, options);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<types.CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<types.CreatePostMutation, types.CreatePostMutationVariables>;
export const GetPostByIdDocument = gql`
    query GetPostById($id: ID!, $type: FeedContentType!) {
  feedContentById(id: $id, type: $type) {
    ... on Post {
      ...Post
    }
  }
}
    ${PostFragmentDoc}`;

/**
 * __useGetPostByIdQuery__
 *
 * To run a query within a React component, call `useGetPostByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetPostByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetPostByIdQuery, types.GetPostByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPostByIdQuery, types.GetPostByIdQueryVariables>(GetPostByIdDocument, options);
      }
export function useGetPostByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPostByIdQuery, types.GetPostByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPostByIdQuery, types.GetPostByIdQueryVariables>(GetPostByIdDocument, options);
        }
export type GetPostByIdQueryHookResult = ReturnType<typeof useGetPostByIdQuery>;
export type GetPostByIdLazyQueryHookResult = ReturnType<typeof useGetPostByIdLazyQuery>;
export type GetPostByIdQueryResult = Apollo.QueryResult<types.GetPostByIdQuery, types.GetPostByIdQueryVariables>;
export const GetPostCommentsByIdDocument = gql`
    query GetPostCommentsById($id: ID!, $type: FeedContentType!, $pagination: CursorPaginationInput) {
  feedContentById(id: $id, type: $type) {
    ... on Post {
      id
      numberOfComments
      comments(pagination: $pagination) {
        edges {
          cursor
          comment {
            ...SharedComment
          }
        }
        meta {
          nextCursor
          hasNext
          prevCursor
          hasPrev
        }
      }
    }
  }
}
    ${SharedCommentFragmentDoc}`;

/**
 * __useGetPostCommentsByIdQuery__
 *
 * To run a query within a React component, call `useGetPostCommentsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostCommentsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostCommentsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetPostCommentsByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetPostCommentsByIdQuery, types.GetPostCommentsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPostCommentsByIdQuery, types.GetPostCommentsByIdQueryVariables>(GetPostCommentsByIdDocument, options);
      }
export function useGetPostCommentsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPostCommentsByIdQuery, types.GetPostCommentsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPostCommentsByIdQuery, types.GetPostCommentsByIdQueryVariables>(GetPostCommentsByIdDocument, options);
        }
export type GetPostCommentsByIdQueryHookResult = ReturnType<typeof useGetPostCommentsByIdQuery>;
export type GetPostCommentsByIdLazyQueryHookResult = ReturnType<typeof useGetPostCommentsByIdLazyQuery>;
export type GetPostCommentsByIdQueryResult = Apollo.QueryResult<types.GetPostCommentsByIdQuery, types.GetPostCommentsByIdQueryVariables>;
export const GetPostRepostsByIdDocument = gql`
    query GetPostRepostsById($postId: ID!, $type: FeedContentType!, $pagination: CursorPaginationInput) {
  feedContentById(id: $postId, type: $type) {
    ... on Post {
      id
      numberOfReposts
      reposts(pagination: $pagination) {
        meta {
          ...CursorInfoFields
        }
        edges {
          cursor
          feed {
            ... on Post {
              ...Post
            }
            ... on PostActivity {
              ...PostActivity
            }
          }
        }
      }
    }
  }
}
    ${CursorInfoFieldsFragmentDoc}
${PostFragmentDoc}
${PostActivityFragmentDoc}`;

/**
 * __useGetPostRepostsByIdQuery__
 *
 * To run a query within a React component, call `useGetPostRepostsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostRepostsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostRepostsByIdQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *      type: // value for 'type'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetPostRepostsByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetPostRepostsByIdQuery, types.GetPostRepostsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPostRepostsByIdQuery, types.GetPostRepostsByIdQueryVariables>(GetPostRepostsByIdDocument, options);
      }
export function useGetPostRepostsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPostRepostsByIdQuery, types.GetPostRepostsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPostRepostsByIdQuery, types.GetPostRepostsByIdQueryVariables>(GetPostRepostsByIdDocument, options);
        }
export type GetPostRepostsByIdQueryHookResult = ReturnType<typeof useGetPostRepostsByIdQuery>;
export type GetPostRepostsByIdLazyQueryHookResult = ReturnType<typeof useGetPostRepostsByIdLazyQuery>;
export type GetPostRepostsByIdQueryResult = Apollo.QueryResult<types.GetPostRepostsByIdQuery, types.GetPostRepostsByIdQueryVariables>;
export const GetPostReactionsByIdDocument = gql`
    query GetPostReactionsById($postId: ID!, $type: FeedContentType!) {
  feedContentById(id: $postId, type: $type) {
    ... on Post {
      reactions {
        ...PostReactionsSummaryFields
      }
    }
  }
}
    ${PostReactionsSummaryFieldsFragmentDoc}`;

/**
 * __useGetPostReactionsByIdQuery__
 *
 * To run a query within a React component, call `useGetPostReactionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostReactionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostReactionsByIdQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetPostReactionsByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetPostReactionsByIdQuery, types.GetPostReactionsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPostReactionsByIdQuery, types.GetPostReactionsByIdQueryVariables>(GetPostReactionsByIdDocument, options);
      }
export function useGetPostReactionsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPostReactionsByIdQuery, types.GetPostReactionsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPostReactionsByIdQuery, types.GetPostReactionsByIdQueryVariables>(GetPostReactionsByIdDocument, options);
        }
export type GetPostReactionsByIdQueryHookResult = ReturnType<typeof useGetPostReactionsByIdQuery>;
export type GetPostReactionsByIdLazyQueryHookResult = ReturnType<typeof useGetPostReactionsByIdLazyQuery>;
export type GetPostReactionsByIdQueryResult = Apollo.QueryResult<types.GetPostReactionsByIdQuery, types.GetPostReactionsByIdQueryVariables>;
export const GetUsersReactedByReactionTypeDocument = gql`
    query GetUsersReactedByReactionType($postId: ID!, $type: FeedContentType!, $pagination: CursorPaginationInput, $reactionType: ReactionType) {
  feedContentById(id: $postId, type: $type) {
    ... on Post {
      reactions {
        totalCount
        users(pagination: $pagination, type: $reactionType) {
          meta {
            ...CursorInfoFields
          }
          edges {
            cursor
            userReaction {
              ...ReactedUserReactionFields
            }
          }
        }
      }
    }
  }
}
    ${CursorInfoFieldsFragmentDoc}
${ReactedUserReactionFieldsFragmentDoc}`;

/**
 * __useGetUsersReactedByReactionTypeQuery__
 *
 * To run a query within a React component, call `useGetUsersReactedByReactionTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersReactedByReactionTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersReactedByReactionTypeQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *      type: // value for 'type'
 *      pagination: // value for 'pagination'
 *      reactionType: // value for 'reactionType'
 *   },
 * });
 */
export function useGetUsersReactedByReactionTypeQuery(baseOptions: Apollo.QueryHookOptions<types.GetUsersReactedByReactionTypeQuery, types.GetUsersReactedByReactionTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUsersReactedByReactionTypeQuery, types.GetUsersReactedByReactionTypeQueryVariables>(GetUsersReactedByReactionTypeDocument, options);
      }
export function useGetUsersReactedByReactionTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUsersReactedByReactionTypeQuery, types.GetUsersReactedByReactionTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUsersReactedByReactionTypeQuery, types.GetUsersReactedByReactionTypeQueryVariables>(GetUsersReactedByReactionTypeDocument, options);
        }
export type GetUsersReactedByReactionTypeQueryHookResult = ReturnType<typeof useGetUsersReactedByReactionTypeQuery>;
export type GetUsersReactedByReactionTypeLazyQueryHookResult = ReturnType<typeof useGetUsersReactedByReactionTypeLazyQuery>;
export type GetUsersReactedByReactionTypeQueryResult = Apollo.QueryResult<types.GetUsersReactedByReactionTypeQuery, types.GetUsersReactedByReactionTypeQueryVariables>;
export const GetContentActivityCountDocument = gql`
    query GetContentActivityCount($id: ID) {
  contentCard(id: $id) {
    ... on Podcast {
      id
      numberOfCommenters
    }
    ... on Ebook {
      id
      numberOfCommenters
    }
    ... on Event {
      id
      numberOfCommenters
    }
    ... on Stream {
      id
      numberOfCommenters
    }
  }
}
    `;

/**
 * __useGetContentActivityCountQuery__
 *
 * To run a query within a React component, call `useGetContentActivityCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentActivityCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentActivityCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentActivityCountQuery(baseOptions?: Apollo.QueryHookOptions<types.GetContentActivityCountQuery, types.GetContentActivityCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetContentActivityCountQuery, types.GetContentActivityCountQueryVariables>(GetContentActivityCountDocument, options);
      }
export function useGetContentActivityCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetContentActivityCountQuery, types.GetContentActivityCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetContentActivityCountQuery, types.GetContentActivityCountQueryVariables>(GetContentActivityCountDocument, options);
        }
export type GetContentActivityCountQueryHookResult = ReturnType<typeof useGetContentActivityCountQuery>;
export type GetContentActivityCountLazyQueryHookResult = ReturnType<typeof useGetContentActivityCountLazyQuery>;
export type GetContentActivityCountQueryResult = Apollo.QueryResult<types.GetContentActivityCountQuery, types.GetContentActivityCountQueryVariables>;
export const GetPostActivityCountDocument = gql`
    query GetPostActivityCount($id: ID!) {
  feedContentById(id: $id, type: POST) {
    id
    ... on Post {
      id
      numberOfCommenters
      reactions {
        totalCount
      }
    }
  }
}
    `;

/**
 * __useGetPostActivityCountQuery__
 *
 * To run a query within a React component, call `useGetPostActivityCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostActivityCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostActivityCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPostActivityCountQuery(baseOptions: Apollo.QueryHookOptions<types.GetPostActivityCountQuery, types.GetPostActivityCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPostActivityCountQuery, types.GetPostActivityCountQueryVariables>(GetPostActivityCountDocument, options);
      }
export function useGetPostActivityCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPostActivityCountQuery, types.GetPostActivityCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPostActivityCountQuery, types.GetPostActivityCountQueryVariables>(GetPostActivityCountDocument, options);
        }
export type GetPostActivityCountQueryHookResult = ReturnType<typeof useGetPostActivityCountQuery>;
export type GetPostActivityCountLazyQueryHookResult = ReturnType<typeof useGetPostActivityCountLazyQuery>;
export type GetPostActivityCountQueryResult = Apollo.QueryResult<types.GetPostActivityCountQuery, types.GetPostActivityCountQueryVariables>;
export const TogglePostReactionDocument = gql`
    mutation TogglePostReaction($reactionType: ReactionType!, $referenceId: ID!) {
  toggleReact(
    reactionType: $reactionType
    reactionRefType: POST
    referenceId: $referenceId
  ) {
    userReacted {
      id
      reactionType
    }
    totalCount
  }
}
    `;
export type TogglePostReactionMutationFn = Apollo.MutationFunction<types.TogglePostReactionMutation, types.TogglePostReactionMutationVariables>;

/**
 * __useTogglePostReactionMutation__
 *
 * To run a mutation, you first call `useTogglePostReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePostReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePostReactionMutation, { data, loading, error }] = useTogglePostReactionMutation({
 *   variables: {
 *      reactionType: // value for 'reactionType'
 *      referenceId: // value for 'referenceId'
 *   },
 * });
 */
export function useTogglePostReactionMutation(baseOptions?: Apollo.MutationHookOptions<types.TogglePostReactionMutation, types.TogglePostReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.TogglePostReactionMutation, types.TogglePostReactionMutationVariables>(TogglePostReactionDocument, options);
      }
export type TogglePostReactionMutationHookResult = ReturnType<typeof useTogglePostReactionMutation>;
export type TogglePostReactionMutationResult = Apollo.MutationResult<types.TogglePostReactionMutation>;
export type TogglePostReactionMutationOptions = Apollo.BaseMutationOptions<types.TogglePostReactionMutation, types.TogglePostReactionMutationVariables>;
export const CreateInstantRepostDocument = gql`
    mutation CreateInstantRepost($postId: ID!) {
  createInstantRepost(postId: $postId) {
    ...Post
  }
}
    ${PostFragmentDoc}`;
export type CreateInstantRepostMutationFn = Apollo.MutationFunction<types.CreateInstantRepostMutation, types.CreateInstantRepostMutationVariables>;

/**
 * __useCreateInstantRepostMutation__
 *
 * To run a mutation, you first call `useCreateInstantRepostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstantRepostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstantRepostMutation, { data, loading, error }] = useCreateInstantRepostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useCreateInstantRepostMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateInstantRepostMutation, types.CreateInstantRepostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateInstantRepostMutation, types.CreateInstantRepostMutationVariables>(CreateInstantRepostDocument, options);
      }
export type CreateInstantRepostMutationHookResult = ReturnType<typeof useCreateInstantRepostMutation>;
export type CreateInstantRepostMutationResult = Apollo.MutationResult<types.CreateInstantRepostMutation>;
export type CreateInstantRepostMutationOptions = Apollo.BaseMutationOptions<types.CreateInstantRepostMutation, types.CreateInstantRepostMutationVariables>;
export const DeletePostDocument = gql`
    mutation DeletePost($postId: ID!, $reason: String) {
  deletePost(post: $postId, reason: $reason)
}
    `;
export type DeletePostMutationFn = Apollo.MutationFunction<types.DeletePostMutation, types.DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: Apollo.MutationHookOptions<types.DeletePostMutation, types.DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeletePostMutation, types.DeletePostMutationVariables>(DeletePostDocument, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<types.DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<types.DeletePostMutation, types.DeletePostMutationVariables>;
export const FeaturedContentEntriesDocument = gql`
    query featuredContentEntries($filter: FeaturedContentFilter, $sorting: SortOrdering) {
  featuredContentEntries(filter: $filter, sorting: $sorting) {
    edges {
      id
      isFree
      content {
        ... on Ebook {
          ...Ebook
        }
        ... on Event {
          ...Event
        }
        ... on Podcast {
          ...Podcast
        }
        ... on Stream {
          ...Stream
        }
      }
      header
      paragraph
      image {
        id
        uri
        alt
      }
      screen
      screenParams
      linkToURL
      organisation {
        id
        name
        type
      }
    }
  }
}
    ${EbookFragmentDoc}
${EventFragmentDoc}
${PodcastFragmentDoc}
${StreamFragmentDoc}`;

/**
 * __useFeaturedContentEntriesQuery__
 *
 * To run a query within a React component, call `useFeaturedContentEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedContentEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedContentEntriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useFeaturedContentEntriesQuery(baseOptions?: Apollo.QueryHookOptions<types.FeaturedContentEntriesQuery, types.FeaturedContentEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.FeaturedContentEntriesQuery, types.FeaturedContentEntriesQueryVariables>(FeaturedContentEntriesDocument, options);
      }
export function useFeaturedContentEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.FeaturedContentEntriesQuery, types.FeaturedContentEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.FeaturedContentEntriesQuery, types.FeaturedContentEntriesQueryVariables>(FeaturedContentEntriesDocument, options);
        }
export type FeaturedContentEntriesQueryHookResult = ReturnType<typeof useFeaturedContentEntriesQuery>;
export type FeaturedContentEntriesLazyQueryHookResult = ReturnType<typeof useFeaturedContentEntriesLazyQuery>;
export type FeaturedContentEntriesQueryResult = Apollo.QueryResult<types.FeaturedContentEntriesQuery, types.FeaturedContentEntriesQueryVariables>;
export const GetOngoingLiveStreamsDocument = gql`
    subscription getOngoingLiveStreams {
  onGoingStreams {
    ...LiveStream
  }
}
    ${LiveStreamFragmentDoc}`;

/**
 * __useGetOngoingLiveStreamsSubscription__
 *
 * To run a query within a React component, call `useGetOngoingLiveStreamsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetOngoingLiveStreamsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOngoingLiveStreamsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetOngoingLiveStreamsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<types.GetOngoingLiveStreamsSubscription, types.GetOngoingLiveStreamsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<types.GetOngoingLiveStreamsSubscription, types.GetOngoingLiveStreamsSubscriptionVariables>(GetOngoingLiveStreamsDocument, options);
      }
export type GetOngoingLiveStreamsSubscriptionHookResult = ReturnType<typeof useGetOngoingLiveStreamsSubscription>;
export type GetOngoingLiveStreamsSubscriptionResult = Apollo.SubscriptionResult<types.GetOngoingLiveStreamsSubscription>;
export const GetStreamsDocument = gql`
    query getStreams($filter: StreamFilter, $sorting: SortOrdering) {
  streams(filter: $filter, sorting: $sorting) {
    edges {
      ...Stream
    }
  }
}
    ${StreamFragmentDoc}`;

/**
 * __useGetStreamsQuery__
 *
 * To run a query within a React component, call `useGetStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetStreamsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetStreamsQuery, types.GetStreamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetStreamsQuery, types.GetStreamsQueryVariables>(GetStreamsDocument, options);
      }
export function useGetStreamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetStreamsQuery, types.GetStreamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetStreamsQuery, types.GetStreamsQueryVariables>(GetStreamsDocument, options);
        }
export type GetStreamsQueryHookResult = ReturnType<typeof useGetStreamsQuery>;
export type GetStreamsLazyQueryHookResult = ReturnType<typeof useGetStreamsLazyQuery>;
export type GetStreamsQueryResult = Apollo.QueryResult<types.GetStreamsQuery, types.GetStreamsQueryVariables>;
export const GetContentCardsDocument = gql`
    query getContentCards($filter: ContentCardsFilter, $sorting: ContentCardsSorting) {
  contentCards(filter: $filter, sorting: $sorting) {
    edges {
      ... on Podcast {
        ...Podcast
      }
      ... on Ebook {
        ...Ebook
      }
      ... on Event {
        ...Event
      }
      ... on Stream {
        ...Stream
      }
      ... on Expert {
        ...Expert
      }
      ... on LearningPath {
        ...LearningPath
      }
    }
    meta {
      recommendationId
      total
      offset
      limit
    }
  }
}
    ${PodcastFragmentDoc}
${EbookFragmentDoc}
${EventFragmentDoc}
${StreamFragmentDoc}
${ExpertFragmentDoc}
${LearningPathFragmentDoc}`;

/**
 * __useGetContentCardsQuery__
 *
 * To run a query within a React component, call `useGetContentCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentCardsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetContentCardsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetContentCardsQuery, types.GetContentCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetContentCardsQuery, types.GetContentCardsQueryVariables>(GetContentCardsDocument, options);
      }
export function useGetContentCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetContentCardsQuery, types.GetContentCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetContentCardsQuery, types.GetContentCardsQueryVariables>(GetContentCardsDocument, options);
        }
export type GetContentCardsQueryHookResult = ReturnType<typeof useGetContentCardsQuery>;
export type GetContentCardsLazyQueryHookResult = ReturnType<typeof useGetContentCardsLazyQuery>;
export type GetContentCardsQueryResult = Apollo.QueryResult<types.GetContentCardsQuery, types.GetContentCardsQueryVariables>;
export const GetContentCardDocument = gql`
    query getContentCard($id: ID) {
  contentCard(id: $id) {
    ... on Podcast {
      ...Podcast
    }
    ... on Ebook {
      ...Ebook
    }
    ... on Event {
      ...Event
    }
    ... on Stream {
      ...Stream
    }
    ... on Expert {
      ...Expert
    }
  }
}
    ${PodcastFragmentDoc}
${EbookFragmentDoc}
${EventFragmentDoc}
${StreamFragmentDoc}
${ExpertFragmentDoc}`;

/**
 * __useGetContentCardQuery__
 *
 * To run a query within a React component, call `useGetContentCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentCardQuery(baseOptions?: Apollo.QueryHookOptions<types.GetContentCardQuery, types.GetContentCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetContentCardQuery, types.GetContentCardQueryVariables>(GetContentCardDocument, options);
      }
export function useGetContentCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetContentCardQuery, types.GetContentCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetContentCardQuery, types.GetContentCardQueryVariables>(GetContentCardDocument, options);
        }
export type GetContentCardQueryHookResult = ReturnType<typeof useGetContentCardQuery>;
export type GetContentCardLazyQueryHookResult = ReturnType<typeof useGetContentCardLazyQuery>;
export type GetContentCardQueryResult = Apollo.QueryResult<types.GetContentCardQuery, types.GetContentCardQueryVariables>;
export const GetConsumableContentDocument = gql`
    query getConsumableContent($filter: ConsumableContentFilter!) {
  user: me {
    id
    ongoingContent(filter: $filter) {
      edges {
        ... on Ebook {
          ...Ebook
        }
        ... on Podcast {
          ...Podcast
        }
        ... on Stream {
          ...Stream
        }
        ... on LearningPath {
          ...LearningPath
        }
      }
    }
  }
}
    ${EbookFragmentDoc}
${PodcastFragmentDoc}
${StreamFragmentDoc}
${LearningPathFragmentDoc}`;

/**
 * __useGetConsumableContentQuery__
 *
 * To run a query within a React component, call `useGetConsumableContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsumableContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsumableContentQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetConsumableContentQuery(baseOptions: Apollo.QueryHookOptions<types.GetConsumableContentQuery, types.GetConsumableContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetConsumableContentQuery, types.GetConsumableContentQueryVariables>(GetConsumableContentDocument, options);
      }
export function useGetConsumableContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetConsumableContentQuery, types.GetConsumableContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetConsumableContentQuery, types.GetConsumableContentQueryVariables>(GetConsumableContentDocument, options);
        }
export type GetConsumableContentQueryHookResult = ReturnType<typeof useGetConsumableContentQuery>;
export type GetConsumableContentLazyQueryHookResult = ReturnType<typeof useGetConsumableContentLazyQuery>;
export type GetConsumableContentQueryResult = Apollo.QueryResult<types.GetConsumableContentQuery, types.GetConsumableContentQueryVariables>;
export const GetRecommendContentDocument = gql`
    query getRecommendContent {
  user: me {
    id
    contentRecommendations {
      edges {
        ... on Podcast {
          ...Podcast
        }
        ... on Ebook {
          ...Ebook
        }
        ... on Event {
          ...Event
        }
        ... on Stream {
          ...Stream
        }
        ... on Expert {
          ...Expert
        }
        ... on LearningPath {
          ...LearningPath
        }
      }
    }
  }
}
    ${PodcastFragmentDoc}
${EbookFragmentDoc}
${EventFragmentDoc}
${StreamFragmentDoc}
${ExpertFragmentDoc}
${LearningPathFragmentDoc}`;

/**
 * __useGetRecommendContentQuery__
 *
 * To run a query within a React component, call `useGetRecommendContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecommendContentQuery(baseOptions?: Apollo.QueryHookOptions<types.GetRecommendContentQuery, types.GetRecommendContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetRecommendContentQuery, types.GetRecommendContentQueryVariables>(GetRecommendContentDocument, options);
      }
export function useGetRecommendContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetRecommendContentQuery, types.GetRecommendContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetRecommendContentQuery, types.GetRecommendContentQueryVariables>(GetRecommendContentDocument, options);
        }
export type GetRecommendContentQueryHookResult = ReturnType<typeof useGetRecommendContentQuery>;
export type GetRecommendContentLazyQueryHookResult = ReturnType<typeof useGetRecommendContentLazyQuery>;
export type GetRecommendContentQueryResult = Apollo.QueryResult<types.GetRecommendContentQuery, types.GetRecommendContentQueryVariables>;
export const GetCurrentUserRecentCategoriesDocument = gql`
    query GetCurrentUserRecentCategories {
  me {
    id
    recentlyConsumedCategories {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCurrentUserRecentCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserRecentCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserRecentCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserRecentCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserRecentCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<types.GetCurrentUserRecentCategoriesQuery, types.GetCurrentUserRecentCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCurrentUserRecentCategoriesQuery, types.GetCurrentUserRecentCategoriesQueryVariables>(GetCurrentUserRecentCategoriesDocument, options);
      }
export function useGetCurrentUserRecentCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCurrentUserRecentCategoriesQuery, types.GetCurrentUserRecentCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCurrentUserRecentCategoriesQuery, types.GetCurrentUserRecentCategoriesQueryVariables>(GetCurrentUserRecentCategoriesDocument, options);
        }
export type GetCurrentUserRecentCategoriesQueryHookResult = ReturnType<typeof useGetCurrentUserRecentCategoriesQuery>;
export type GetCurrentUserRecentCategoriesLazyQueryHookResult = ReturnType<typeof useGetCurrentUserRecentCategoriesLazyQuery>;
export type GetCurrentUserRecentCategoriesQueryResult = Apollo.QueryResult<types.GetCurrentUserRecentCategoriesQuery, types.GetCurrentUserRecentCategoriesQueryVariables>;
export const GetTrailOfTheMonthDocument = gql`
    query GetTrailOfTheMonth {
  promotedLearningPathOfTheMonth {
    learninPath {
      ...LearningPath
    }
    description
  }
}
    ${LearningPathFragmentDoc}`;

/**
 * __useGetTrailOfTheMonthQuery__
 *
 * To run a query within a React component, call `useGetTrailOfTheMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrailOfTheMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrailOfTheMonthQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTrailOfTheMonthQuery(baseOptions?: Apollo.QueryHookOptions<types.GetTrailOfTheMonthQuery, types.GetTrailOfTheMonthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetTrailOfTheMonthQuery, types.GetTrailOfTheMonthQueryVariables>(GetTrailOfTheMonthDocument, options);
      }
export function useGetTrailOfTheMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetTrailOfTheMonthQuery, types.GetTrailOfTheMonthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetTrailOfTheMonthQuery, types.GetTrailOfTheMonthQueryVariables>(GetTrailOfTheMonthDocument, options);
        }
export type GetTrailOfTheMonthQueryHookResult = ReturnType<typeof useGetTrailOfTheMonthQuery>;
export type GetTrailOfTheMonthLazyQueryHookResult = ReturnType<typeof useGetTrailOfTheMonthLazyQuery>;
export type GetTrailOfTheMonthQueryResult = Apollo.QueryResult<types.GetTrailOfTheMonthQuery, types.GetTrailOfTheMonthQueryVariables>;
export const GetUserAssignmentStatisticsDocument = gql`
    query GetUserAssignmentStatistics {
  me {
    __typename
    id
    assignmentStatistics {
      assignedTrails
      completedTrails
      assignedPodcasts
      completedPodcasts
      assignedEbooks
      completedEbooks
      assignedStreams
      completedStreams
    }
  }
}
    `;

/**
 * __useGetUserAssignmentStatisticsQuery__
 *
 * To run a query within a React component, call `useGetUserAssignmentStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAssignmentStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAssignmentStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAssignmentStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetUserAssignmentStatisticsQuery, types.GetUserAssignmentStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserAssignmentStatisticsQuery, types.GetUserAssignmentStatisticsQueryVariables>(GetUserAssignmentStatisticsDocument, options);
      }
export function useGetUserAssignmentStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserAssignmentStatisticsQuery, types.GetUserAssignmentStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserAssignmentStatisticsQuery, types.GetUserAssignmentStatisticsQueryVariables>(GetUserAssignmentStatisticsDocument, options);
        }
export type GetUserAssignmentStatisticsQueryHookResult = ReturnType<typeof useGetUserAssignmentStatisticsQuery>;
export type GetUserAssignmentStatisticsLazyQueryHookResult = ReturnType<typeof useGetUserAssignmentStatisticsLazyQuery>;
export type GetUserAssignmentStatisticsQueryResult = Apollo.QueryResult<types.GetUserAssignmentStatisticsQuery, types.GetUserAssignmentStatisticsQueryVariables>;
export const GetUserAssignedContentStatisticsDocument = gql`
    query GetUserAssignedContentStatistics {
  me {
    __typename
    id
    assignmentContentStatistics {
      contentId
      contentName
      contentLength
      completionRate
    }
  }
}
    `;

/**
 * __useGetUserAssignedContentStatisticsQuery__
 *
 * To run a query within a React component, call `useGetUserAssignedContentStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAssignedContentStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAssignedContentStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAssignedContentStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetUserAssignedContentStatisticsQuery, types.GetUserAssignedContentStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserAssignedContentStatisticsQuery, types.GetUserAssignedContentStatisticsQueryVariables>(GetUserAssignedContentStatisticsDocument, options);
      }
export function useGetUserAssignedContentStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserAssignedContentStatisticsQuery, types.GetUserAssignedContentStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserAssignedContentStatisticsQuery, types.GetUserAssignedContentStatisticsQueryVariables>(GetUserAssignedContentStatisticsDocument, options);
        }
export type GetUserAssignedContentStatisticsQueryHookResult = ReturnType<typeof useGetUserAssignedContentStatisticsQuery>;
export type GetUserAssignedContentStatisticsLazyQueryHookResult = ReturnType<typeof useGetUserAssignedContentStatisticsLazyQuery>;
export type GetUserAssignedContentStatisticsQueryResult = Apollo.QueryResult<types.GetUserAssignedContentStatisticsQuery, types.GetUserAssignedContentStatisticsQueryVariables>;
export const GetUserAssignmentsDocument = gql`
    query GetUserAssignments($limit: Int = 20, $offset: Int = 0, $status: [AssignmentStatus!]) {
  me {
    __typename
    id
    assignments(filter: {limit: $limit, offset: $offset, status: $status}) {
      meta {
        total
        limit
        offset
      }
      edges {
        id
        assignmentTitle
        assignmentReason
        dueDate
        length
        completionRate
        createdAt
        updatedAt
        status
        backgroundImage {
          uri
          alt
        }
        userAssignmentStatistics {
          assignedTrails
          assignedPodcasts
          assignedEbooks
          assignedStreams
        }
        state
      }
    }
    assignmentStatistics {
      assignedTrails
      assignedPodcasts
      assignedEbooks
      assignedStreams
    }
  }
}
    `;

/**
 * __useGetUserAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetUserAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAssignmentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetUserAssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetUserAssignmentsQuery, types.GetUserAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserAssignmentsQuery, types.GetUserAssignmentsQueryVariables>(GetUserAssignmentsDocument, options);
      }
export function useGetUserAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserAssignmentsQuery, types.GetUserAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserAssignmentsQuery, types.GetUserAssignmentsQueryVariables>(GetUserAssignmentsDocument, options);
        }
export type GetUserAssignmentsQueryHookResult = ReturnType<typeof useGetUserAssignmentsQuery>;
export type GetUserAssignmentsLazyQueryHookResult = ReturnType<typeof useGetUserAssignmentsLazyQuery>;
export type GetUserAssignmentsQueryResult = Apollo.QueryResult<types.GetUserAssignmentsQuery, types.GetUserAssignmentsQueryVariables>;
export const MarkAssignmentsAsViewedDocument = gql`
    mutation MarkAssignmentsAsViewed($assignmentIds: [ID!]!) {
  markAssignmentsAsViewed(assignmentIds: $assignmentIds) {
    success
  }
}
    `;
export type MarkAssignmentsAsViewedMutationFn = Apollo.MutationFunction<types.MarkAssignmentsAsViewedMutation, types.MarkAssignmentsAsViewedMutationVariables>;

/**
 * __useMarkAssignmentsAsViewedMutation__
 *
 * To run a mutation, you first call `useMarkAssignmentsAsViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAssignmentsAsViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAssignmentsAsViewedMutation, { data, loading, error }] = useMarkAssignmentsAsViewedMutation({
 *   variables: {
 *      assignmentIds: // value for 'assignmentIds'
 *   },
 * });
 */
export function useMarkAssignmentsAsViewedMutation(baseOptions?: Apollo.MutationHookOptions<types.MarkAssignmentsAsViewedMutation, types.MarkAssignmentsAsViewedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.MarkAssignmentsAsViewedMutation, types.MarkAssignmentsAsViewedMutationVariables>(MarkAssignmentsAsViewedDocument, options);
      }
export type MarkAssignmentsAsViewedMutationHookResult = ReturnType<typeof useMarkAssignmentsAsViewedMutation>;
export type MarkAssignmentsAsViewedMutationResult = Apollo.MutationResult<types.MarkAssignmentsAsViewedMutation>;
export type MarkAssignmentsAsViewedMutationOptions = Apollo.BaseMutationOptions<types.MarkAssignmentsAsViewedMutation, types.MarkAssignmentsAsViewedMutationVariables>;
export const GetOrganisationContentDocument = gql`
    query GetOrganisationContent($filter: CategoryFilter) {
  categories(filter: $filter) {
    meta {
      total
    }
    edges {
      id
      name
    }
  }
  trendingContent: contentCards(
    filter: {orgSpecific: true, limit: 1}
    sorting: WEEKLY_TRENDING
  ) {
    meta {
      total
    }
  }
  mostPopularContent: contentCards(
    filter: {orgSpecific: true, limit: 1}
    sorting: TRENDING
  ) {
    meta {
      total
    }
  }
  orgContent: contentCards(
    filter: {orgSpecific: true, excludeOrgCategories: true, limit: 1}
    sorting: PUBLISHED_AT
  ) {
    meta {
      total
    }
  }
}
    `;

/**
 * __useGetOrganisationContentQuery__
 *
 * To run a query within a React component, call `useGetOrganisationContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationContentQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOrganisationContentQuery(baseOptions?: Apollo.QueryHookOptions<types.GetOrganisationContentQuery, types.GetOrganisationContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganisationContentQuery, types.GetOrganisationContentQueryVariables>(GetOrganisationContentDocument, options);
      }
export function useGetOrganisationContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganisationContentQuery, types.GetOrganisationContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganisationContentQuery, types.GetOrganisationContentQueryVariables>(GetOrganisationContentDocument, options);
        }
export type GetOrganisationContentQueryHookResult = ReturnType<typeof useGetOrganisationContentQuery>;
export type GetOrganisationContentLazyQueryHookResult = ReturnType<typeof useGetOrganisationContentLazyQuery>;
export type GetOrganisationContentQueryResult = Apollo.QueryResult<types.GetOrganisationContentQuery, types.GetOrganisationContentQueryVariables>;
export const FollowAllUsersInOrgDocument = gql`
    mutation FollowAllUsersInOrg {
  followAllUsersInOrg
}
    `;
export type FollowAllUsersInOrgMutationFn = Apollo.MutationFunction<types.FollowAllUsersInOrgMutation, types.FollowAllUsersInOrgMutationVariables>;

/**
 * __useFollowAllUsersInOrgMutation__
 *
 * To run a mutation, you first call `useFollowAllUsersInOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowAllUsersInOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followAllUsersInOrgMutation, { data, loading, error }] = useFollowAllUsersInOrgMutation({
 *   variables: {
 *   },
 * });
 */
export function useFollowAllUsersInOrgMutation(baseOptions?: Apollo.MutationHookOptions<types.FollowAllUsersInOrgMutation, types.FollowAllUsersInOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.FollowAllUsersInOrgMutation, types.FollowAllUsersInOrgMutationVariables>(FollowAllUsersInOrgDocument, options);
      }
export type FollowAllUsersInOrgMutationHookResult = ReturnType<typeof useFollowAllUsersInOrgMutation>;
export type FollowAllUsersInOrgMutationResult = Apollo.MutationResult<types.FollowAllUsersInOrgMutation>;
export type FollowAllUsersInOrgMutationOptions = Apollo.BaseMutationOptions<types.FollowAllUsersInOrgMutation, types.FollowAllUsersInOrgMutationVariables>;
export const ContentCompletedDocument = gql`
    query contentCompleted($offset: Int!, $limit: Int!) {
  me {
    id
    finishedContent(filter: {limit: $limit, offset: $offset}) {
      edges {
        ... on Ebook {
          ...Ebook
        }
        ... on Podcast {
          ...Podcast
        }
        ... on Stream {
          ...Stream
        }
        ... on LearningPath {
          ...LearningPath
        }
      }
      meta {
        offset
        limit
        total
      }
    }
  }
}
    ${EbookFragmentDoc}
${PodcastFragmentDoc}
${StreamFragmentDoc}
${LearningPathFragmentDoc}`;

/**
 * __useContentCompletedQuery__
 *
 * To run a query within a React component, call `useContentCompletedQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentCompletedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentCompletedQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useContentCompletedQuery(baseOptions: Apollo.QueryHookOptions<types.ContentCompletedQuery, types.ContentCompletedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.ContentCompletedQuery, types.ContentCompletedQueryVariables>(ContentCompletedDocument, options);
      }
export function useContentCompletedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.ContentCompletedQuery, types.ContentCompletedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.ContentCompletedQuery, types.ContentCompletedQueryVariables>(ContentCompletedDocument, options);
        }
export type ContentCompletedQueryHookResult = ReturnType<typeof useContentCompletedQuery>;
export type ContentCompletedLazyQueryHookResult = ReturnType<typeof useContentCompletedLazyQuery>;
export type ContentCompletedQueryResult = Apollo.QueryResult<types.ContentCompletedQuery, types.ContentCompletedQueryVariables>;
export const SetUserBlockedStateDocument = gql`
    mutation SetUserBlockedState($id: ID!, $isBlocked: Boolean!) {
  setUserBlockedState(id: $id, isBlocked: $isBlocked) {
    __typename
    id
    isBlockingCurrentUser
    isBlockedByCurrentUser
  }
}
    `;
export type SetUserBlockedStateMutationFn = Apollo.MutationFunction<types.SetUserBlockedStateMutation, types.SetUserBlockedStateMutationVariables>;

/**
 * __useSetUserBlockedStateMutation__
 *
 * To run a mutation, you first call `useSetUserBlockedStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserBlockedStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserBlockedStateMutation, { data, loading, error }] = useSetUserBlockedStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isBlocked: // value for 'isBlocked'
 *   },
 * });
 */
export function useSetUserBlockedStateMutation(baseOptions?: Apollo.MutationHookOptions<types.SetUserBlockedStateMutation, types.SetUserBlockedStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.SetUserBlockedStateMutation, types.SetUserBlockedStateMutationVariables>(SetUserBlockedStateDocument, options);
      }
export type SetUserBlockedStateMutationHookResult = ReturnType<typeof useSetUserBlockedStateMutation>;
export type SetUserBlockedStateMutationResult = Apollo.MutationResult<types.SetUserBlockedStateMutation>;
export type SetUserBlockedStateMutationOptions = Apollo.BaseMutationOptions<types.SetUserBlockedStateMutation, types.SetUserBlockedStateMutationVariables>;
export const GetUserActivitiesFeedDocument = gql`
    query GetUserActivitiesFeed($userId: String!, $pagination: CursorPaginationInput) {
  user(id: $userId) {
    id
    userActivities(pagination: $pagination) {
      meta {
        hasNext
        hasPrev
        nextCursor
        prevCursor
      }
      edges {
        cursor
        feed {
          ... on ContentActivity {
            ...ContentActivity
          }
          ... on Post {
            ...Post
          }
          ... on PostActivity {
            ...PostActivity
          }
        }
      }
    }
  }
}
    ${ContentActivityFragmentDoc}
${PostFragmentDoc}
${PostActivityFragmentDoc}`;

/**
 * __useGetUserActivitiesFeedQuery__
 *
 * To run a query within a React component, call `useGetUserActivitiesFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserActivitiesFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserActivitiesFeedQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetUserActivitiesFeedQuery(baseOptions: Apollo.QueryHookOptions<types.GetUserActivitiesFeedQuery, types.GetUserActivitiesFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserActivitiesFeedQuery, types.GetUserActivitiesFeedQueryVariables>(GetUserActivitiesFeedDocument, options);
      }
export function useGetUserActivitiesFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserActivitiesFeedQuery, types.GetUserActivitiesFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserActivitiesFeedQuery, types.GetUserActivitiesFeedQueryVariables>(GetUserActivitiesFeedDocument, options);
        }
export type GetUserActivitiesFeedQueryHookResult = ReturnType<typeof useGetUserActivitiesFeedQuery>;
export type GetUserActivitiesFeedLazyQueryHookResult = ReturnType<typeof useGetUserActivitiesFeedLazyQuery>;
export type GetUserActivitiesFeedQueryResult = Apollo.QueryResult<types.GetUserActivitiesFeedQuery, types.GetUserActivitiesFeedQueryVariables>;
export const GetLanguagesDocument = gql`
    query GetLanguages {
  languages {
    name
    code
  }
}
    `;

/**
 * __useGetLanguagesQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<types.GetLanguagesQuery, types.GetLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetLanguagesQuery, types.GetLanguagesQueryVariables>(GetLanguagesDocument, options);
      }
export function useGetLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetLanguagesQuery, types.GetLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetLanguagesQuery, types.GetLanguagesQueryVariables>(GetLanguagesDocument, options);
        }
export type GetLanguagesQueryHookResult = ReturnType<typeof useGetLanguagesQuery>;
export type GetLanguagesLazyQueryHookResult = ReturnType<typeof useGetLanguagesLazyQuery>;
export type GetLanguagesQueryResult = Apollo.QueryResult<types.GetLanguagesQuery, types.GetLanguagesQueryVariables>;
export const UpdateUserPreferredLanguageDocument = gql`
    mutation UpdateUserPreferredLanguage($language: String!) {
  updateUserPreferredLanguage(language: $language) {
    preferredLanguage
    id
  }
}
    `;
export type UpdateUserPreferredLanguageMutationFn = Apollo.MutationFunction<types.UpdateUserPreferredLanguageMutation, types.UpdateUserPreferredLanguageMutationVariables>;

/**
 * __useUpdateUserPreferredLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateUserPreferredLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPreferredLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPreferredLanguageMutation, { data, loading, error }] = useUpdateUserPreferredLanguageMutation({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateUserPreferredLanguageMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateUserPreferredLanguageMutation, types.UpdateUserPreferredLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateUserPreferredLanguageMutation, types.UpdateUserPreferredLanguageMutationVariables>(UpdateUserPreferredLanguageDocument, options);
      }
export type UpdateUserPreferredLanguageMutationHookResult = ReturnType<typeof useUpdateUserPreferredLanguageMutation>;
export type UpdateUserPreferredLanguageMutationResult = Apollo.MutationResult<types.UpdateUserPreferredLanguageMutation>;
export type UpdateUserPreferredLanguageMutationOptions = Apollo.BaseMutationOptions<types.UpdateUserPreferredLanguageMutation, types.UpdateUserPreferredLanguageMutationVariables>;
export const GetUserPreferredLanguageDocument = gql`
    query GetUserPreferredLanguage {
  user: me {
    id
    preferredLanguage
  }
}
    `;

/**
 * __useGetUserPreferredLanguageQuery__
 *
 * To run a query within a React component, call `useGetUserPreferredLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPreferredLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPreferredLanguageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPreferredLanguageQuery(baseOptions?: Apollo.QueryHookOptions<types.GetUserPreferredLanguageQuery, types.GetUserPreferredLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserPreferredLanguageQuery, types.GetUserPreferredLanguageQueryVariables>(GetUserPreferredLanguageDocument, options);
      }
export function useGetUserPreferredLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserPreferredLanguageQuery, types.GetUserPreferredLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserPreferredLanguageQuery, types.GetUserPreferredLanguageQueryVariables>(GetUserPreferredLanguageDocument, options);
        }
export type GetUserPreferredLanguageQueryHookResult = ReturnType<typeof useGetUserPreferredLanguageQuery>;
export type GetUserPreferredLanguageLazyQueryHookResult = ReturnType<typeof useGetUserPreferredLanguageLazyQuery>;
export type GetUserPreferredLanguageQueryResult = Apollo.QueryResult<types.GetUserPreferredLanguageQuery, types.GetUserPreferredLanguageQueryVariables>;
export const GetEventLivestreamStatusDocument = gql`
    query GetEventLivestreamStatus($id: String!) {
  event(id: $id) {
    ... on Event {
      id
      eventType
      startsAt
      stream {
        id
        status
        source
      }
      userContentTracking {
        id
        isAttending
      }
    }
  }
}
    `;

/**
 * __useGetEventLivestreamStatusQuery__
 *
 * To run a query within a React component, call `useGetEventLivestreamStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventLivestreamStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventLivestreamStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventLivestreamStatusQuery(baseOptions: Apollo.QueryHookOptions<types.GetEventLivestreamStatusQuery, types.GetEventLivestreamStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetEventLivestreamStatusQuery, types.GetEventLivestreamStatusQueryVariables>(GetEventLivestreamStatusDocument, options);
      }
export function useGetEventLivestreamStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetEventLivestreamStatusQuery, types.GetEventLivestreamStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetEventLivestreamStatusQuery, types.GetEventLivestreamStatusQueryVariables>(GetEventLivestreamStatusDocument, options);
        }
export type GetEventLivestreamStatusQueryHookResult = ReturnType<typeof useGetEventLivestreamStatusQuery>;
export type GetEventLivestreamStatusLazyQueryHookResult = ReturnType<typeof useGetEventLivestreamStatusLazyQuery>;
export type GetEventLivestreamStatusQueryResult = Apollo.QueryResult<types.GetEventLivestreamStatusQuery, types.GetEventLivestreamStatusQueryVariables>;
export const GetFollowingFollowerListDocument = gql`
    query getFollowingFollowerList($filter: FollowersFollowingFilter) {
  user: me {
    id
    followingCount
    followerCount
    followers(filter: $filter) {
      edges {
        id
        firstName
        lastName
        avatarImage {
          uri
        }
        jobTitle
        isExpert
        __typename
        company
        isFollowing
      }
    }
    followings(filter: $filter) {
      meta {
        limit
      }
      edges {
        id
        firstName
        lastName
        avatarImage {
          uri
        }
        jobTitle
        isExpert
        __typename
        company
        isFollowing
      }
    }
  }
}
    `;

/**
 * __useGetFollowingFollowerListQuery__
 *
 * To run a query within a React component, call `useGetFollowingFollowerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowingFollowerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowingFollowerListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFollowingFollowerListQuery(baseOptions?: Apollo.QueryHookOptions<types.GetFollowingFollowerListQuery, types.GetFollowingFollowerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetFollowingFollowerListQuery, types.GetFollowingFollowerListQueryVariables>(GetFollowingFollowerListDocument, options);
      }
export function useGetFollowingFollowerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetFollowingFollowerListQuery, types.GetFollowingFollowerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetFollowingFollowerListQuery, types.GetFollowingFollowerListQueryVariables>(GetFollowingFollowerListDocument, options);
        }
export type GetFollowingFollowerListQueryHookResult = ReturnType<typeof useGetFollowingFollowerListQuery>;
export type GetFollowingFollowerListLazyQueryHookResult = ReturnType<typeof useGetFollowingFollowerListLazyQuery>;
export type GetFollowingFollowerListQueryResult = Apollo.QueryResult<types.GetFollowingFollowerListQuery, types.GetFollowingFollowerListQueryVariables>;
export const ToggleContentBookmarkDocument = gql`
    mutation ToggleContentBookmark($id: String!) {
  userTrackContentToggleBookmarked(id: $id) {
    ... on Ebook {
      id
      userContentTracking {
        ...UserContentTracking
      }
    }
    ... on Event {
      id
      userContentTracking {
        ...UserContentTracking
      }
    }
    ... on Podcast {
      id
      userContentTracking {
        ...UserContentTracking
      }
    }
    ... on Stream {
      id
      userContentTracking {
        ...UserContentTracking
      }
    }
  }
}
    ${UserContentTrackingFragmentDoc}`;
export type ToggleContentBookmarkMutationFn = Apollo.MutationFunction<types.ToggleContentBookmarkMutation, types.ToggleContentBookmarkMutationVariables>;

/**
 * __useToggleContentBookmarkMutation__
 *
 * To run a mutation, you first call `useToggleContentBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleContentBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleContentBookmarkMutation, { data, loading, error }] = useToggleContentBookmarkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleContentBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<types.ToggleContentBookmarkMutation, types.ToggleContentBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ToggleContentBookmarkMutation, types.ToggleContentBookmarkMutationVariables>(ToggleContentBookmarkDocument, options);
      }
export type ToggleContentBookmarkMutationHookResult = ReturnType<typeof useToggleContentBookmarkMutation>;
export type ToggleContentBookmarkMutationResult = Apollo.MutationResult<types.ToggleContentBookmarkMutation>;
export type ToggleContentBookmarkMutationOptions = Apollo.BaseMutationOptions<types.ToggleContentBookmarkMutation, types.ToggleContentBookmarkMutationVariables>;
export const ToggleLearningPathBookmarkDocument = gql`
    mutation ToggleLearningPathBookmark($id: ID!) {
  userLearningPathToggleBookmarkedV2(id: $id) {
    id
    userCollectionTracking {
      bookmarkedAt
      id
    }
  }
}
    `;
export type ToggleLearningPathBookmarkMutationFn = Apollo.MutationFunction<types.ToggleLearningPathBookmarkMutation, types.ToggleLearningPathBookmarkMutationVariables>;

/**
 * __useToggleLearningPathBookmarkMutation__
 *
 * To run a mutation, you first call `useToggleLearningPathBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleLearningPathBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleLearningPathBookmarkMutation, { data, loading, error }] = useToggleLearningPathBookmarkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleLearningPathBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<types.ToggleLearningPathBookmarkMutation, types.ToggleLearningPathBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ToggleLearningPathBookmarkMutation, types.ToggleLearningPathBookmarkMutationVariables>(ToggleLearningPathBookmarkDocument, options);
      }
export type ToggleLearningPathBookmarkMutationHookResult = ReturnType<typeof useToggleLearningPathBookmarkMutation>;
export type ToggleLearningPathBookmarkMutationResult = Apollo.MutationResult<types.ToggleLearningPathBookmarkMutation>;
export type ToggleLearningPathBookmarkMutationOptions = Apollo.BaseMutationOptions<types.ToggleLearningPathBookmarkMutation, types.ToggleLearningPathBookmarkMutationVariables>;
export const GetCommentByIdDocument = gql`
    query GetCommentById($id: ID!) {
  comment(id: $id) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;

/**
 * __useGetCommentByIdQuery__
 *
 * To run a query within a React component, call `useGetCommentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommentByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetCommentByIdQuery, types.GetCommentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCommentByIdQuery, types.GetCommentByIdQueryVariables>(GetCommentByIdDocument, options);
      }
export function useGetCommentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCommentByIdQuery, types.GetCommentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCommentByIdQuery, types.GetCommentByIdQueryVariables>(GetCommentByIdDocument, options);
        }
export type GetCommentByIdQueryHookResult = ReturnType<typeof useGetCommentByIdQuery>;
export type GetCommentByIdLazyQueryHookResult = ReturnType<typeof useGetCommentByIdLazyQuery>;
export type GetCommentByIdQueryResult = Apollo.QueryResult<types.GetCommentByIdQuery, types.GetCommentByIdQueryVariables>;
export const GetAllCommentsForContentDocument = gql`
    query GetAllCommentsForContent($id: ID!, $sorting: SortOrdering, $limit: Int, $afterCursor: String) {
  contentCard(id: $id) {
    ... on Podcast {
      id
      __typename
      comments(sorting: $sorting, limit: $limit, afterCursor: $afterCursor) {
        edges {
          cursor
          comment {
            ...Comment
          }
        }
        meta {
          hasNext
          nextCursor
        }
      }
    }
    ... on Ebook {
      id
      __typename
      comments(sorting: $sorting, limit: $limit, afterCursor: $afterCursor) {
        edges {
          cursor
          comment {
            ...Comment
          }
        }
        meta {
          hasNext
          nextCursor
        }
      }
    }
    ... on Event {
      id
      __typename
      comments(sorting: $sorting, limit: $limit, afterCursor: $afterCursor) {
        edges {
          cursor
          comment {
            ...Comment
          }
        }
        meta {
          hasNext
          nextCursor
        }
      }
    }
    ... on Stream {
      id
      __typename
      comments(sorting: $sorting, limit: $limit, afterCursor: $afterCursor) {
        edges {
          cursor
          comment {
            ...Comment
          }
        }
        meta {
          hasNext
          nextCursor
        }
      }
    }
  }
}
    ${CommentFragmentDoc}`;

/**
 * __useGetAllCommentsForContentQuery__
 *
 * To run a query within a React component, call `useGetAllCommentsForContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCommentsForContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCommentsForContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sorting: // value for 'sorting'
 *      limit: // value for 'limit'
 *      afterCursor: // value for 'afterCursor'
 *   },
 * });
 */
export function useGetAllCommentsForContentQuery(baseOptions: Apollo.QueryHookOptions<types.GetAllCommentsForContentQuery, types.GetAllCommentsForContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetAllCommentsForContentQuery, types.GetAllCommentsForContentQueryVariables>(GetAllCommentsForContentDocument, options);
      }
export function useGetAllCommentsForContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetAllCommentsForContentQuery, types.GetAllCommentsForContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetAllCommentsForContentQuery, types.GetAllCommentsForContentQueryVariables>(GetAllCommentsForContentDocument, options);
        }
export type GetAllCommentsForContentQueryHookResult = ReturnType<typeof useGetAllCommentsForContentQuery>;
export type GetAllCommentsForContentLazyQueryHookResult = ReturnType<typeof useGetAllCommentsForContentLazyQuery>;
export type GetAllCommentsForContentQueryResult = Apollo.QueryResult<types.GetAllCommentsForContentQuery, types.GetAllCommentsForContentQueryVariables>;
export const GetUsersWhoHaveLikedCommentDocument = gql`
    query GetUsersWhoHaveLikedComment($id: ID!) {
  comment(id: $id) {
    usersLikedTheComment(limit: 40, offset: 0) {
      edges {
        ...LimitedUser
      }
    }
  }
}
    ${LimitedUserFragmentDoc}`;

/**
 * __useGetUsersWhoHaveLikedCommentQuery__
 *
 * To run a query within a React component, call `useGetUsersWhoHaveLikedCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersWhoHaveLikedCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersWhoHaveLikedCommentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUsersWhoHaveLikedCommentQuery(baseOptions: Apollo.QueryHookOptions<types.GetUsersWhoHaveLikedCommentQuery, types.GetUsersWhoHaveLikedCommentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUsersWhoHaveLikedCommentQuery, types.GetUsersWhoHaveLikedCommentQueryVariables>(GetUsersWhoHaveLikedCommentDocument, options);
      }
export function useGetUsersWhoHaveLikedCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUsersWhoHaveLikedCommentQuery, types.GetUsersWhoHaveLikedCommentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUsersWhoHaveLikedCommentQuery, types.GetUsersWhoHaveLikedCommentQueryVariables>(GetUsersWhoHaveLikedCommentDocument, options);
        }
export type GetUsersWhoHaveLikedCommentQueryHookResult = ReturnType<typeof useGetUsersWhoHaveLikedCommentQuery>;
export type GetUsersWhoHaveLikedCommentLazyQueryHookResult = ReturnType<typeof useGetUsersWhoHaveLikedCommentLazyQuery>;
export type GetUsersWhoHaveLikedCommentQueryResult = Apollo.QueryResult<types.GetUsersWhoHaveLikedCommentQuery, types.GetUsersWhoHaveLikedCommentQueryVariables>;
export const ToggleLikeDocument = gql`
    mutation ToggleLike($referenceId: ID!, $type: LikeType!) {
  toggleLike(referenceId: $referenceId, type: $type) {
    ... on Comment {
      ...Comment
    }
  }
}
    ${CommentFragmentDoc}`;
export type ToggleLikeMutationFn = Apollo.MutationFunction<types.ToggleLikeMutation, types.ToggleLikeMutationVariables>;

/**
 * __useToggleLikeMutation__
 *
 * To run a mutation, you first call `useToggleLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleLikeMutation, { data, loading, error }] = useToggleLikeMutation({
 *   variables: {
 *      referenceId: // value for 'referenceId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useToggleLikeMutation(baseOptions?: Apollo.MutationHookOptions<types.ToggleLikeMutation, types.ToggleLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ToggleLikeMutation, types.ToggleLikeMutationVariables>(ToggleLikeDocument, options);
      }
export type ToggleLikeMutationHookResult = ReturnType<typeof useToggleLikeMutation>;
export type ToggleLikeMutationResult = Apollo.MutationResult<types.ToggleLikeMutation>;
export type ToggleLikeMutationOptions = Apollo.BaseMutationOptions<types.ToggleLikeMutation, types.ToggleLikeMutationVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($comment: PostComment!) {
  createComment(comment: $comment) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;
export type CreateCommentMutationFn = Apollo.MutationFunction<types.CreateCommentMutation, types.CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateCommentMutation, types.CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateCommentMutation, types.CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<types.CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<types.CreateCommentMutation, types.CreateCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($commentId: ID!, $reason: String) {
  deleteComment(comment: $commentId, reason: $reason)
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<types.DeleteCommentMutation, types.DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteCommentMutation, types.DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteCommentMutation, types.DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<types.DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<types.DeleteCommentMutation, types.DeleteCommentMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($update: UpdateComment!) {
  updateComment(update: $update) {
    id
    comment
    rawText
    mentions {
      index
      targetId
      mentionType
    }
  }
}
    `;
export type UpdateCommentMutationFn = Apollo.MutationFunction<types.UpdateCommentMutation, types.UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateCommentMutation, types.UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateCommentMutation, types.UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<types.UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<types.UpdateCommentMutation, types.UpdateCommentMutationVariables>;
export const GetLifeGoalDocument = gql`
    query getLifeGoal($id: String!) {
  lifegoal(id: $id) {
    id
    name
    emoji
    categories {
      id
      name
    }
  }
}
    `;

/**
 * __useGetLifeGoalQuery__
 *
 * To run a query within a React component, call `useGetLifeGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLifeGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLifeGoalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLifeGoalQuery(baseOptions: Apollo.QueryHookOptions<types.GetLifeGoalQuery, types.GetLifeGoalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetLifeGoalQuery, types.GetLifeGoalQueryVariables>(GetLifeGoalDocument, options);
      }
export function useGetLifeGoalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetLifeGoalQuery, types.GetLifeGoalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetLifeGoalQuery, types.GetLifeGoalQueryVariables>(GetLifeGoalDocument, options);
        }
export type GetLifeGoalQueryHookResult = ReturnType<typeof useGetLifeGoalQuery>;
export type GetLifeGoalLazyQueryHookResult = ReturnType<typeof useGetLifeGoalLazyQuery>;
export type GetLifeGoalQueryResult = Apollo.QueryResult<types.GetLifeGoalQuery, types.GetLifeGoalQueryVariables>;
export const GetLifeGoalsDocument = gql`
    query getLifeGoals($filter: LifegoalFilter) {
  lifegoals(filter: $filter) {
    edges {
      emoji
      name
      id
      categories {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetLifeGoalsQuery__
 *
 * To run a query within a React component, call `useGetLifeGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLifeGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLifeGoalsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetLifeGoalsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetLifeGoalsQuery, types.GetLifeGoalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetLifeGoalsQuery, types.GetLifeGoalsQueryVariables>(GetLifeGoalsDocument, options);
      }
export function useGetLifeGoalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetLifeGoalsQuery, types.GetLifeGoalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetLifeGoalsQuery, types.GetLifeGoalsQueryVariables>(GetLifeGoalsDocument, options);
        }
export type GetLifeGoalsQueryHookResult = ReturnType<typeof useGetLifeGoalsQuery>;
export type GetLifeGoalsLazyQueryHookResult = ReturnType<typeof useGetLifeGoalsLazyQuery>;
export type GetLifeGoalsQueryResult = Apollo.QueryResult<types.GetLifeGoalsQuery, types.GetLifeGoalsQueryVariables>;
export const UpdateUserLifegoalsDocument = gql`
    mutation updateUserLifegoals($lifeGoals: [ID!]!) {
  updateUserLifegoals(lifeGoals: $lifeGoals) {
    success
  }
}
    `;
export type UpdateUserLifegoalsMutationFn = Apollo.MutationFunction<types.UpdateUserLifegoalsMutation, types.UpdateUserLifegoalsMutationVariables>;

/**
 * __useUpdateUserLifegoalsMutation__
 *
 * To run a mutation, you first call `useUpdateUserLifegoalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLifegoalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLifegoalsMutation, { data, loading, error }] = useUpdateUserLifegoalsMutation({
 *   variables: {
 *      lifeGoals: // value for 'lifeGoals'
 *   },
 * });
 */
export function useUpdateUserLifegoalsMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateUserLifegoalsMutation, types.UpdateUserLifegoalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateUserLifegoalsMutation, types.UpdateUserLifegoalsMutationVariables>(UpdateUserLifegoalsDocument, options);
      }
export type UpdateUserLifegoalsMutationHookResult = ReturnType<typeof useUpdateUserLifegoalsMutation>;
export type UpdateUserLifegoalsMutationResult = Apollo.MutationResult<types.UpdateUserLifegoalsMutation>;
export type UpdateUserLifegoalsMutationOptions = Apollo.BaseMutationOptions<types.UpdateUserLifegoalsMutation, types.UpdateUserLifegoalsMutationVariables>;
export const GetUserLifeGoalsDocument = gql`
    query getUserLifeGoals {
  me {
    id
    firstName
    lastName
    lifegoals {
      id
      name
      emoji
    }
  }
}
    `;

/**
 * __useGetUserLifeGoalsQuery__
 *
 * To run a query within a React component, call `useGetUserLifeGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLifeGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLifeGoalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserLifeGoalsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetUserLifeGoalsQuery, types.GetUserLifeGoalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserLifeGoalsQuery, types.GetUserLifeGoalsQueryVariables>(GetUserLifeGoalsDocument, options);
      }
export function useGetUserLifeGoalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserLifeGoalsQuery, types.GetUserLifeGoalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserLifeGoalsQuery, types.GetUserLifeGoalsQueryVariables>(GetUserLifeGoalsDocument, options);
        }
export type GetUserLifeGoalsQueryHookResult = ReturnType<typeof useGetUserLifeGoalsQuery>;
export type GetUserLifeGoalsLazyQueryHookResult = ReturnType<typeof useGetUserLifeGoalsLazyQuery>;
export type GetUserLifeGoalsQueryResult = Apollo.QueryResult<types.GetUserLifeGoalsQuery, types.GetUserLifeGoalsQueryVariables>;
export const GetOrganisationMediaDocument = gql`
    query GetOrganisationMedia($id: String!) {
  organisation(id: $id) {
    __typename
    id
    logo {
      id
      uri
      alt
      width
      height
    }
    coverImage {
      id
      uri
      alt
    }
  }
}
    `;

/**
 * __useGetOrganisationMediaQuery__
 *
 * To run a query within a React component, call `useGetOrganisationMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationMediaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganisationMediaQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganisationMediaQuery, types.GetOrganisationMediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganisationMediaQuery, types.GetOrganisationMediaQueryVariables>(GetOrganisationMediaDocument, options);
      }
export function useGetOrganisationMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganisationMediaQuery, types.GetOrganisationMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganisationMediaQuery, types.GetOrganisationMediaQueryVariables>(GetOrganisationMediaDocument, options);
        }
export type GetOrganisationMediaQueryHookResult = ReturnType<typeof useGetOrganisationMediaQuery>;
export type GetOrganisationMediaLazyQueryHookResult = ReturnType<typeof useGetOrganisationMediaLazyQuery>;
export type GetOrganisationMediaQueryResult = Apollo.QueryResult<types.GetOrganisationMediaQuery, types.GetOrganisationMediaQueryVariables>;
export const GetPeopleAndContentCardsDocument = gql`
    query GetPeopleAndContentCards($keywords: String!, $offset: Int!, $limit: Int!, $sorting: ContentCardsSorting, $includeDrafts: Boolean, $isAMemberOfSameOrganisation: Boolean, $isExpert: Boolean, $categories: [String!], $duration: IntRangeFilter, $includeUsersAlreadyFollowed: Boolean, $lifegoals: [ID!]) {
  me {
    id
    suggestedUsersToFollow(
      filter: {query: $keywords, limit: 4, offset: 0, isAMemberOfSameOrganisation: $isAMemberOfSameOrganisation, isExpert: $isExpert, includeUsersAlreadyFollowed: $includeUsersAlreadyFollowed}
    ) {
      edges {
        ...PublicUserInformation
      }
      meta {
        total
        limit
        offset
      }
    }
  }
  contentCards(
    filter: {keywords: $keywords, limit: $limit, offset: $offset, includeDrafts: $includeDrafts, lifegoals: $lifegoals, categories: $categories, duration: $duration}
    sorting: $sorting
  ) {
    edges {
      ... on Podcast {
        ...Podcast
      }
      ... on Ebook {
        ...Ebook
      }
      ... on Event {
        ...Event
      }
      ... on Stream {
        ...Stream
      }
      ... on Expert {
        ...Expert
      }
      ... on LearningPath {
        ...LearningPath
      }
    }
    meta {
      recommendationId
      total
      offset
      limit
    }
  }
}
    ${PublicUserInformationFragmentDoc}
${PodcastFragmentDoc}
${EbookFragmentDoc}
${EventFragmentDoc}
${StreamFragmentDoc}
${ExpertFragmentDoc}
${LearningPathFragmentDoc}`;

/**
 * __useGetPeopleAndContentCardsQuery__
 *
 * To run a query within a React component, call `useGetPeopleAndContentCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleAndContentCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleAndContentCardsQuery({
 *   variables: {
 *      keywords: // value for 'keywords'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sorting: // value for 'sorting'
 *      includeDrafts: // value for 'includeDrafts'
 *      isAMemberOfSameOrganisation: // value for 'isAMemberOfSameOrganisation'
 *      isExpert: // value for 'isExpert'
 *      categories: // value for 'categories'
 *      duration: // value for 'duration'
 *      includeUsersAlreadyFollowed: // value for 'includeUsersAlreadyFollowed'
 *      lifegoals: // value for 'lifegoals'
 *   },
 * });
 */
export function useGetPeopleAndContentCardsQuery(baseOptions: Apollo.QueryHookOptions<types.GetPeopleAndContentCardsQuery, types.GetPeopleAndContentCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPeopleAndContentCardsQuery, types.GetPeopleAndContentCardsQueryVariables>(GetPeopleAndContentCardsDocument, options);
      }
export function useGetPeopleAndContentCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPeopleAndContentCardsQuery, types.GetPeopleAndContentCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPeopleAndContentCardsQuery, types.GetPeopleAndContentCardsQueryVariables>(GetPeopleAndContentCardsDocument, options);
        }
export type GetPeopleAndContentCardsQueryHookResult = ReturnType<typeof useGetPeopleAndContentCardsQuery>;
export type GetPeopleAndContentCardsLazyQueryHookResult = ReturnType<typeof useGetPeopleAndContentCardsLazyQuery>;
export type GetPeopleAndContentCardsQueryResult = Apollo.QueryResult<types.GetPeopleAndContentCardsQuery, types.GetPeopleAndContentCardsQueryVariables>;
export const GetPodcastByIdDocument = gql`
    query getPodcastById($id: String) {
  podcast(id: $id) {
    ...Podcast
  }
}
    ${PodcastFragmentDoc}`;

/**
 * __useGetPodcastByIdQuery__
 *
 * To run a query within a React component, call `useGetPodcastByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPodcastByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPodcastByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPodcastByIdQuery(baseOptions?: Apollo.QueryHookOptions<types.GetPodcastByIdQuery, types.GetPodcastByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPodcastByIdQuery, types.GetPodcastByIdQueryVariables>(GetPodcastByIdDocument, options);
      }
export function useGetPodcastByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPodcastByIdQuery, types.GetPodcastByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPodcastByIdQuery, types.GetPodcastByIdQueryVariables>(GetPodcastByIdDocument, options);
        }
export type GetPodcastByIdQueryHookResult = ReturnType<typeof useGetPodcastByIdQuery>;
export type GetPodcastByIdLazyQueryHookResult = ReturnType<typeof useGetPodcastByIdLazyQuery>;
export type GetPodcastByIdQueryResult = Apollo.QueryResult<types.GetPodcastByIdQuery, types.GetPodcastByIdQueryVariables>;
export const GetAdminLevelPodcastsByIdDocument = gql`
    query GetAdminLevelPodcastsById($id: String) {
  podcast(id: $id) {
    ...AdminLevelPodcast
  }
}
    ${AdminLevelPodcastFragmentDoc}`;

/**
 * __useGetAdminLevelPodcastsByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminLevelPodcastsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminLevelPodcastsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminLevelPodcastsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminLevelPodcastsByIdQuery(baseOptions?: Apollo.QueryHookOptions<types.GetAdminLevelPodcastsByIdQuery, types.GetAdminLevelPodcastsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetAdminLevelPodcastsByIdQuery, types.GetAdminLevelPodcastsByIdQueryVariables>(GetAdminLevelPodcastsByIdDocument, options);
      }
export function useGetAdminLevelPodcastsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetAdminLevelPodcastsByIdQuery, types.GetAdminLevelPodcastsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetAdminLevelPodcastsByIdQuery, types.GetAdminLevelPodcastsByIdQueryVariables>(GetAdminLevelPodcastsByIdDocument, options);
        }
export type GetAdminLevelPodcastsByIdQueryHookResult = ReturnType<typeof useGetAdminLevelPodcastsByIdQuery>;
export type GetAdminLevelPodcastsByIdLazyQueryHookResult = ReturnType<typeof useGetAdminLevelPodcastsByIdLazyQuery>;
export type GetAdminLevelPodcastsByIdQueryResult = Apollo.QueryResult<types.GetAdminLevelPodcastsByIdQuery, types.GetAdminLevelPodcastsByIdQueryVariables>;
export const GetPodcastsDocument = gql`
    query GetPodcasts($filter: PodcastFilter!, $sorting: SortOrdering) {
  podcasts(filter: $filter, sorting: $sorting) {
    edges {
      ...Podcast
    }
    meta {
      total
      offset
      limit
    }
  }
}
    ${PodcastFragmentDoc}`;

/**
 * __useGetPodcastsQuery__
 *
 * To run a query within a React component, call `useGetPodcastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPodcastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPodcastsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetPodcastsQuery(baseOptions: Apollo.QueryHookOptions<types.GetPodcastsQuery, types.GetPodcastsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPodcastsQuery, types.GetPodcastsQueryVariables>(GetPodcastsDocument, options);
      }
export function useGetPodcastsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPodcastsQuery, types.GetPodcastsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPodcastsQuery, types.GetPodcastsQueryVariables>(GetPodcastsDocument, options);
        }
export type GetPodcastsQueryHookResult = ReturnType<typeof useGetPodcastsQuery>;
export type GetPodcastsLazyQueryHookResult = ReturnType<typeof useGetPodcastsLazyQuery>;
export type GetPodcastsQueryResult = Apollo.QueryResult<types.GetPodcastsQuery, types.GetPodcastsQueryVariables>;
export const DeletePodcastDocument = gql`
    mutation DeletePodcast($id: String!) {
  deletePodcast(id: $id)
}
    `;
export type DeletePodcastMutationFn = Apollo.MutationFunction<types.DeletePodcastMutation, types.DeletePodcastMutationVariables>;

/**
 * __useDeletePodcastMutation__
 *
 * To run a mutation, you first call `useDeletePodcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePodcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePodcastMutation, { data, loading, error }] = useDeletePodcastMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePodcastMutation(baseOptions?: Apollo.MutationHookOptions<types.DeletePodcastMutation, types.DeletePodcastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeletePodcastMutation, types.DeletePodcastMutationVariables>(DeletePodcastDocument, options);
      }
export type DeletePodcastMutationHookResult = ReturnType<typeof useDeletePodcastMutation>;
export type DeletePodcastMutationResult = Apollo.MutationResult<types.DeletePodcastMutation>;
export type DeletePodcastMutationOptions = Apollo.BaseMutationOptions<types.DeletePodcastMutation, types.DeletePodcastMutationVariables>;
export const CreatePodcastDocument = gql`
    mutation CreatePodcast($input: PodcastInput!) {
  createPodcast(input: $input) {
    ... on ValidationError {
      fieldErrors {
        message
        path
      }
    }
    ... on Podcast {
      ...Podcast
    }
  }
}
    ${PodcastFragmentDoc}`;
export type CreatePodcastMutationFn = Apollo.MutationFunction<types.CreatePodcastMutation, types.CreatePodcastMutationVariables>;

/**
 * __useCreatePodcastMutation__
 *
 * To run a mutation, you first call `useCreatePodcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePodcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPodcastMutation, { data, loading, error }] = useCreatePodcastMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePodcastMutation(baseOptions?: Apollo.MutationHookOptions<types.CreatePodcastMutation, types.CreatePodcastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreatePodcastMutation, types.CreatePodcastMutationVariables>(CreatePodcastDocument, options);
      }
export type CreatePodcastMutationHookResult = ReturnType<typeof useCreatePodcastMutation>;
export type CreatePodcastMutationResult = Apollo.MutationResult<types.CreatePodcastMutation>;
export type CreatePodcastMutationOptions = Apollo.BaseMutationOptions<types.CreatePodcastMutation, types.CreatePodcastMutationVariables>;
export const UpdatePodcastDocument = gql`
    mutation UpdatePodcast($updatePodcastId: String!, $input: PodcastInput!) {
  updatePodcast(id: $updatePodcastId, input: $input) {
    ... on Podcast {
      ...Podcast
      organisationGroups {
        id
        name
      }
    }
    ... on ValidationError {
      fieldErrors {
        message
        path
      }
    }
    ... on NotFoundError {
      message
    }
  }
}
    ${PodcastFragmentDoc}`;
export type UpdatePodcastMutationFn = Apollo.MutationFunction<types.UpdatePodcastMutation, types.UpdatePodcastMutationVariables>;

/**
 * __useUpdatePodcastMutation__
 *
 * To run a mutation, you first call `useUpdatePodcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePodcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePodcastMutation, { data, loading, error }] = useUpdatePodcastMutation({
 *   variables: {
 *      updatePodcastId: // value for 'updatePodcastId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePodcastMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdatePodcastMutation, types.UpdatePodcastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdatePodcastMutation, types.UpdatePodcastMutationVariables>(UpdatePodcastDocument, options);
      }
export type UpdatePodcastMutationHookResult = ReturnType<typeof useUpdatePodcastMutation>;
export type UpdatePodcastMutationResult = Apollo.MutationResult<types.UpdatePodcastMutation>;
export type UpdatePodcastMutationOptions = Apollo.BaseMutationOptions<types.UpdatePodcastMutation, types.UpdatePodcastMutationVariables>;
export const UpsertPodcastAudioAndTranscriptDocument = gql`
    mutation UpsertPodcastAudioAndTranscript($podcastId: ID!, $language: String!, $audio: PodcastAudioInput) {
  upsertPodcastAudioAndTranscript(
    podcastId: $podcastId
    language: $language
    audio: $audio
  ) {
    ... on Podcast {
      ...Podcast
    }
    ... on ValidationError {
      fieldErrors {
        message
        path
      }
    }
    ... on NotFoundError {
      message
    }
  }
}
    ${PodcastFragmentDoc}`;
export type UpsertPodcastAudioAndTranscriptMutationFn = Apollo.MutationFunction<types.UpsertPodcastAudioAndTranscriptMutation, types.UpsertPodcastAudioAndTranscriptMutationVariables>;

/**
 * __useUpsertPodcastAudioAndTranscriptMutation__
 *
 * To run a mutation, you first call `useUpsertPodcastAudioAndTranscriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPodcastAudioAndTranscriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPodcastAudioAndTranscriptMutation, { data, loading, error }] = useUpsertPodcastAudioAndTranscriptMutation({
 *   variables: {
 *      podcastId: // value for 'podcastId'
 *      language: // value for 'language'
 *      audio: // value for 'audio'
 *   },
 * });
 */
export function useUpsertPodcastAudioAndTranscriptMutation(baseOptions?: Apollo.MutationHookOptions<types.UpsertPodcastAudioAndTranscriptMutation, types.UpsertPodcastAudioAndTranscriptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpsertPodcastAudioAndTranscriptMutation, types.UpsertPodcastAudioAndTranscriptMutationVariables>(UpsertPodcastAudioAndTranscriptDocument, options);
      }
export type UpsertPodcastAudioAndTranscriptMutationHookResult = ReturnType<typeof useUpsertPodcastAudioAndTranscriptMutation>;
export type UpsertPodcastAudioAndTranscriptMutationResult = Apollo.MutationResult<types.UpsertPodcastAudioAndTranscriptMutation>;
export type UpsertPodcastAudioAndTranscriptMutationOptions = Apollo.BaseMutationOptions<types.UpsertPodcastAudioAndTranscriptMutation, types.UpsertPodcastAudioAndTranscriptMutationVariables>;
export const GetPollQuestionsDocument = gql`
    query getPollQuestions($filter: PollQuestionFilter) {
  pollQuestions(filter: $filter) {
    edges {
      id
      question
      image {
        id
        uri
      }
      answerOptions {
        id
        answer
        image {
          id
          uri
        }
      }
    }
  }
}
    `;

/**
 * __useGetPollQuestionsQuery__
 *
 * To run a query within a React component, call `useGetPollQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPollQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPollQuestionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPollQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetPollQuestionsQuery, types.GetPollQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPollQuestionsQuery, types.GetPollQuestionsQueryVariables>(GetPollQuestionsDocument, options);
      }
export function useGetPollQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPollQuestionsQuery, types.GetPollQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPollQuestionsQuery, types.GetPollQuestionsQueryVariables>(GetPollQuestionsDocument, options);
        }
export type GetPollQuestionsQueryHookResult = ReturnType<typeof useGetPollQuestionsQuery>;
export type GetPollQuestionsLazyQueryHookResult = ReturnType<typeof useGetPollQuestionsLazyQuery>;
export type GetPollQuestionsQueryResult = Apollo.QueryResult<types.GetPollQuestionsQuery, types.GetPollQuestionsQueryVariables>;
export const AddAnswersToPollQuestionsDocument = gql`
    mutation addAnswersToPollQuestions($input: PollQuestionAnswer!) {
  addAnswerToPollQuestion(input: $input)
}
    `;
export type AddAnswersToPollQuestionsMutationFn = Apollo.MutationFunction<types.AddAnswersToPollQuestionsMutation, types.AddAnswersToPollQuestionsMutationVariables>;

/**
 * __useAddAnswersToPollQuestionsMutation__
 *
 * To run a mutation, you first call `useAddAnswersToPollQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAnswersToPollQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnswersToPollQuestionsMutation, { data, loading, error }] = useAddAnswersToPollQuestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAnswersToPollQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<types.AddAnswersToPollQuestionsMutation, types.AddAnswersToPollQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.AddAnswersToPollQuestionsMutation, types.AddAnswersToPollQuestionsMutationVariables>(AddAnswersToPollQuestionsDocument, options);
      }
export type AddAnswersToPollQuestionsMutationHookResult = ReturnType<typeof useAddAnswersToPollQuestionsMutation>;
export type AddAnswersToPollQuestionsMutationResult = Apollo.MutationResult<types.AddAnswersToPollQuestionsMutation>;
export type AddAnswersToPollQuestionsMutationOptions = Apollo.BaseMutationOptions<types.AddAnswersToPollQuestionsMutation, types.AddAnswersToPollQuestionsMutationVariables>;
export const GetOnboardingPollAnswersDocument = gql`
    query getOnboardingPollAnswers($limit: Int!, $offset: Int!) {
  me {
    id
    repliedPollQuestions(
      filter: {purpose: ONBOARDING}
      pagination: {limit: $limit, offset: $offset}
    ) {
      edges {
        id
        question
        selectedAnswer {
          id
          answer
        }
        answerOptions {
          id
          answer
        }
      }
    }
  }
}
    `;

/**
 * __useGetOnboardingPollAnswersQuery__
 *
 * To run a query within a React component, call `useGetOnboardingPollAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingPollAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingPollAnswersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOnboardingPollAnswersQuery(baseOptions: Apollo.QueryHookOptions<types.GetOnboardingPollAnswersQuery, types.GetOnboardingPollAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOnboardingPollAnswersQuery, types.GetOnboardingPollAnswersQueryVariables>(GetOnboardingPollAnswersDocument, options);
      }
export function useGetOnboardingPollAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOnboardingPollAnswersQuery, types.GetOnboardingPollAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOnboardingPollAnswersQuery, types.GetOnboardingPollAnswersQueryVariables>(GetOnboardingPollAnswersDocument, options);
        }
export type GetOnboardingPollAnswersQueryHookResult = ReturnType<typeof useGetOnboardingPollAnswersQuery>;
export type GetOnboardingPollAnswersLazyQueryHookResult = ReturnType<typeof useGetOnboardingPollAnswersLazyQuery>;
export type GetOnboardingPollAnswersQueryResult = Apollo.QueryResult<types.GetOnboardingPollAnswersQuery, types.GetOnboardingPollAnswersQueryVariables>;
export const RateContentDocument = gql`
    mutation rateContent($contentId: ID!, $vote: ContentVote!) {
  rateContent(contentId: $contentId, vote: $vote) {
    id
    content {
      ...ContentCardVotes
    }
  }
}
    ${ContentCardVotesFragmentDoc}`;
export type RateContentMutationFn = Apollo.MutationFunction<types.RateContentMutation, types.RateContentMutationVariables>;

/**
 * __useRateContentMutation__
 *
 * To run a mutation, you first call `useRateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateContentMutation, { data, loading, error }] = useRateContentMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      vote: // value for 'vote'
 *   },
 * });
 */
export function useRateContentMutation(baseOptions?: Apollo.MutationHookOptions<types.RateContentMutation, types.RateContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.RateContentMutation, types.RateContentMutationVariables>(RateContentDocument, options);
      }
export type RateContentMutationHookResult = ReturnType<typeof useRateContentMutation>;
export type RateContentMutationResult = Apollo.MutationResult<types.RateContentMutation>;
export type RateContentMutationOptions = Apollo.BaseMutationOptions<types.RateContentMutation, types.RateContentMutationVariables>;
export const GetScheduleDocument = gql`
    query getSchedule {
  upcoming: events(filter: {limit: 20, onlyFutureEvents: true}) {
    edges {
      ...Event
    }
    meta {
      total
      limit
      offset
    }
  }
  booked: me {
    id
    events(sorting: {sorting: [{field: "starts_at", direction: "desc"}]}) {
      edges {
        id
      }
    }
  }
}
    ${EventFragmentDoc}`;

/**
 * __useGetScheduleQuery__
 *
 * To run a query within a React component, call `useGetScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetScheduleQuery(baseOptions?: Apollo.QueryHookOptions<types.GetScheduleQuery, types.GetScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetScheduleQuery, types.GetScheduleQueryVariables>(GetScheduleDocument, options);
      }
export function useGetScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetScheduleQuery, types.GetScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetScheduleQuery, types.GetScheduleQueryVariables>(GetScheduleDocument, options);
        }
export type GetScheduleQueryHookResult = ReturnType<typeof useGetScheduleQuery>;
export type GetScheduleLazyQueryHookResult = ReturnType<typeof useGetScheduleLazyQuery>;
export type GetScheduleQueryResult = Apollo.QueryResult<types.GetScheduleQuery, types.GetScheduleQueryVariables>;
export const GetStreamDocument = gql`
    query GetStream($id: String, $audioLanguage: String, $subtitleLanguage: String) {
  stream(id: $id) {
    __typename
    id
    status
    name
    source
    preamble
    entryLevel
    length
    isFree
    rating
    activityCount
    wentLiveAt
    createdAt
    updatedAt
    publishedAt
    image {
      ...Image
    }
    categories {
      ...Category
    }
    channel
    videoUrl
    contentSegments {
      header
      paragraph
    }
    experts {
      ...Expert
    }
    video(language: $audioLanguage) {
      url
      language
      length
      publishedAt
    }
    audioAvailableLanguages {
      name
      code
    }
    subtitleAvailableLanguages {
      name
      code
    }
    subtitle(language: $subtitleLanguage) {
      vttUrl
      language
      length
      publishedAt
    }
    hosts {
      expert {
        ...Expert
      }
      userId
      uid
      accepted
      order
      isFeatured
    }
    createdBy {
      ...Expert
    }
    userContentTracking {
      ...UserContentTracking
    }
    organisationGroups {
      id
      name
    }
    upvoteCount
    contentRatingByUser
  }
}
    ${ImageFragmentDoc}
${CategoryFragmentDoc}
${ExpertFragmentDoc}
${UserContentTrackingFragmentDoc}`;

/**
 * __useGetStreamQuery__
 *
 * To run a query within a React component, call `useGetStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamQuery({
 *   variables: {
 *      id: // value for 'id'
 *      audioLanguage: // value for 'audioLanguage'
 *      subtitleLanguage: // value for 'subtitleLanguage'
 *   },
 * });
 */
export function useGetStreamQuery(baseOptions?: Apollo.QueryHookOptions<types.GetStreamQuery, types.GetStreamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetStreamQuery, types.GetStreamQueryVariables>(GetStreamDocument, options);
      }
export function useGetStreamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetStreamQuery, types.GetStreamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetStreamQuery, types.GetStreamQueryVariables>(GetStreamDocument, options);
        }
export type GetStreamQueryHookResult = ReturnType<typeof useGetStreamQuery>;
export type GetStreamLazyQueryHookResult = ReturnType<typeof useGetStreamLazyQuery>;
export type GetStreamQueryResult = Apollo.QueryResult<types.GetStreamQuery, types.GetStreamQueryVariables>;
export const GetStreamChatMessagesDocument = gql`
    query getStreamChatMessages($id: String!) {
  streamChatMessages(id: $id) {
    id
    streamId
    timestamp
    content
    user {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetStreamChatMessagesQuery__
 *
 * To run a query within a React component, call `useGetStreamChatMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamChatMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamChatMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStreamChatMessagesQuery(baseOptions: Apollo.QueryHookOptions<types.GetStreamChatMessagesQuery, types.GetStreamChatMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetStreamChatMessagesQuery, types.GetStreamChatMessagesQueryVariables>(GetStreamChatMessagesDocument, options);
      }
export function useGetStreamChatMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetStreamChatMessagesQuery, types.GetStreamChatMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetStreamChatMessagesQuery, types.GetStreamChatMessagesQueryVariables>(GetStreamChatMessagesDocument, options);
        }
export type GetStreamChatMessagesQueryHookResult = ReturnType<typeof useGetStreamChatMessagesQuery>;
export type GetStreamChatMessagesLazyQueryHookResult = ReturnType<typeof useGetStreamChatMessagesLazyQuery>;
export type GetStreamChatMessagesQueryResult = Apollo.QueryResult<types.GetStreamChatMessagesQuery, types.GetStreamChatMessagesQueryVariables>;
export const StreamChatMessagesDocument = gql`
    subscription streamChatMessages($streamId: ID!) {
  chatMessages(streamId: $streamId) {
    ...StreamMessage
  }
}
    ${StreamMessageFragmentDoc}`;

/**
 * __useStreamChatMessagesSubscription__
 *
 * To run a query within a React component, call `useStreamChatMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStreamChatMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamChatMessagesSubscription({
 *   variables: {
 *      streamId: // value for 'streamId'
 *   },
 * });
 */
export function useStreamChatMessagesSubscription(baseOptions: Apollo.SubscriptionHookOptions<types.StreamChatMessagesSubscription, types.StreamChatMessagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<types.StreamChatMessagesSubscription, types.StreamChatMessagesSubscriptionVariables>(StreamChatMessagesDocument, options);
      }
export type StreamChatMessagesSubscriptionHookResult = ReturnType<typeof useStreamChatMessagesSubscription>;
export type StreamChatMessagesSubscriptionResult = Apollo.SubscriptionResult<types.StreamChatMessagesSubscription>;
export const StreamDocument = gql`
    subscription stream($id: ID!) {
  stream(id: $id) {
    ...Stream
  }
}
    ${StreamFragmentDoc}`;

/**
 * __useStreamSubscription__
 *
 * To run a query within a React component, call `useStreamSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStreamSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStreamSubscription(baseOptions: Apollo.SubscriptionHookOptions<types.StreamSubscription, types.StreamSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<types.StreamSubscription, types.StreamSubscriptionVariables>(StreamDocument, options);
      }
export type StreamSubscriptionHookResult = ReturnType<typeof useStreamSubscription>;
export type StreamSubscriptionResult = Apollo.SubscriptionResult<types.StreamSubscription>;
export const PostMessageDocument = gql`
    mutation postMessage($streamId: ID!, $content: String!) {
  streamPostMessage(input: {streamId: $streamId, content: $content})
}
    `;
export type PostMessageMutationFn = Apollo.MutationFunction<types.PostMessageMutation, types.PostMessageMutationVariables>;

/**
 * __usePostMessageMutation__
 *
 * To run a mutation, you first call `usePostMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postMessageMutation, { data, loading, error }] = usePostMessageMutation({
 *   variables: {
 *      streamId: // value for 'streamId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function usePostMessageMutation(baseOptions?: Apollo.MutationHookOptions<types.PostMessageMutation, types.PostMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.PostMessageMutation, types.PostMessageMutationVariables>(PostMessageDocument, options);
      }
export type PostMessageMutationHookResult = ReturnType<typeof usePostMessageMutation>;
export type PostMessageMutationResult = Apollo.MutationResult<types.PostMessageMutation>;
export type PostMessageMutationOptions = Apollo.BaseMutationOptions<types.PostMessageMutation, types.PostMessageMutationVariables>;
export const GetStreamSubtitleDocument = gql`
    query GetStreamSubtitle($id: String, $subtitleLanguage: String) {
  stream(id: $id) {
    subtitle(language: $subtitleLanguage) {
      url
      language
      length
      publishedAt
    }
  }
}
    `;

/**
 * __useGetStreamSubtitleQuery__
 *
 * To run a query within a React component, call `useGetStreamSubtitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamSubtitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamSubtitleQuery({
 *   variables: {
 *      id: // value for 'id'
 *      subtitleLanguage: // value for 'subtitleLanguage'
 *   },
 * });
 */
export function useGetStreamSubtitleQuery(baseOptions?: Apollo.QueryHookOptions<types.GetStreamSubtitleQuery, types.GetStreamSubtitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetStreamSubtitleQuery, types.GetStreamSubtitleQueryVariables>(GetStreamSubtitleDocument, options);
      }
export function useGetStreamSubtitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetStreamSubtitleQuery, types.GetStreamSubtitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetStreamSubtitleQuery, types.GetStreamSubtitleQueryVariables>(GetStreamSubtitleDocument, options);
        }
export type GetStreamSubtitleQueryHookResult = ReturnType<typeof useGetStreamSubtitleQuery>;
export type GetStreamSubtitleLazyQueryHookResult = ReturnType<typeof useGetStreamSubtitleLazyQuery>;
export type GetStreamSubtitleQueryResult = Apollo.QueryResult<types.GetStreamSubtitleQuery, types.GetStreamSubtitleQueryVariables>;
export const GetSuggestedUsersToFollowDocument = gql`
    query getSuggestedUsersToFollow($filter: SuggestedUsersToFollowFilter!) {
  me {
    id
    suggestedUsersToFollow(filter: $filter) {
      edges {
        ...PublicUserInformation
      }
      meta {
        total
        limit
        offset
      }
    }
  }
}
    ${PublicUserInformationFragmentDoc}`;

/**
 * __useGetSuggestedUsersToFollowQuery__
 *
 * To run a query within a React component, call `useGetSuggestedUsersToFollowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggestedUsersToFollowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggestedUsersToFollowQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSuggestedUsersToFollowQuery(baseOptions: Apollo.QueryHookOptions<types.GetSuggestedUsersToFollowQuery, types.GetSuggestedUsersToFollowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetSuggestedUsersToFollowQuery, types.GetSuggestedUsersToFollowQueryVariables>(GetSuggestedUsersToFollowDocument, options);
      }
export function useGetSuggestedUsersToFollowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetSuggestedUsersToFollowQuery, types.GetSuggestedUsersToFollowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetSuggestedUsersToFollowQuery, types.GetSuggestedUsersToFollowQueryVariables>(GetSuggestedUsersToFollowDocument, options);
        }
export type GetSuggestedUsersToFollowQueryHookResult = ReturnType<typeof useGetSuggestedUsersToFollowQuery>;
export type GetSuggestedUsersToFollowLazyQueryHookResult = ReturnType<typeof useGetSuggestedUsersToFollowLazyQuery>;
export type GetSuggestedUsersToFollowQueryResult = Apollo.QueryResult<types.GetSuggestedUsersToFollowQuery, types.GetSuggestedUsersToFollowQueryVariables>;
export const GetSuggestedUsersToFollowWithFollowedUsersDocument = gql`
    query getSuggestedUsersToFollowWithFollowedUsers($filter: SuggestedUsersToFollowFilter!) {
  me {
    id
    suggestedUsersToFollow(filter: $filter) {
      edges {
        ...PublicUserInformation
      }
      meta {
        total
        limit
        offset
      }
    }
  }
}
    ${PublicUserInformationFragmentDoc}`;

/**
 * __useGetSuggestedUsersToFollowWithFollowedUsersQuery__
 *
 * To run a query within a React component, call `useGetSuggestedUsersToFollowWithFollowedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggestedUsersToFollowWithFollowedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggestedUsersToFollowWithFollowedUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSuggestedUsersToFollowWithFollowedUsersQuery(baseOptions: Apollo.QueryHookOptions<types.GetSuggestedUsersToFollowWithFollowedUsersQuery, types.GetSuggestedUsersToFollowWithFollowedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetSuggestedUsersToFollowWithFollowedUsersQuery, types.GetSuggestedUsersToFollowWithFollowedUsersQueryVariables>(GetSuggestedUsersToFollowWithFollowedUsersDocument, options);
      }
export function useGetSuggestedUsersToFollowWithFollowedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetSuggestedUsersToFollowWithFollowedUsersQuery, types.GetSuggestedUsersToFollowWithFollowedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetSuggestedUsersToFollowWithFollowedUsersQuery, types.GetSuggestedUsersToFollowWithFollowedUsersQueryVariables>(GetSuggestedUsersToFollowWithFollowedUsersDocument, options);
        }
export type GetSuggestedUsersToFollowWithFollowedUsersQueryHookResult = ReturnType<typeof useGetSuggestedUsersToFollowWithFollowedUsersQuery>;
export type GetSuggestedUsersToFollowWithFollowedUsersLazyQueryHookResult = ReturnType<typeof useGetSuggestedUsersToFollowWithFollowedUsersLazyQuery>;
export type GetSuggestedUsersToFollowWithFollowedUsersQueryResult = Apollo.QueryResult<types.GetSuggestedUsersToFollowWithFollowedUsersQuery, types.GetSuggestedUsersToFollowWithFollowedUsersQueryVariables>;
export const UserToggleFollowDocument = gql`
    mutation userToggleFollow($id: ID!) {
  userToggleFollow(personToFollow: $id) {
    id
    followerCount
    isFollowing
  }
}
    `;
export type UserToggleFollowMutationFn = Apollo.MutationFunction<types.UserToggleFollowMutation, types.UserToggleFollowMutationVariables>;

/**
 * __useUserToggleFollowMutation__
 *
 * To run a mutation, you first call `useUserToggleFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserToggleFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userToggleFollowMutation, { data, loading, error }] = useUserToggleFollowMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserToggleFollowMutation(baseOptions?: Apollo.MutationHookOptions<types.UserToggleFollowMutation, types.UserToggleFollowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UserToggleFollowMutation, types.UserToggleFollowMutationVariables>(UserToggleFollowDocument, options);
      }
export type UserToggleFollowMutationHookResult = ReturnType<typeof useUserToggleFollowMutation>;
export type UserToggleFollowMutationResult = Apollo.MutationResult<types.UserToggleFollowMutation>;
export type UserToggleFollowMutationOptions = Apollo.BaseMutationOptions<types.UserToggleFollowMutation, types.UserToggleFollowMutationVariables>;
export const UserToggleFollowExpertDocument = gql`
    mutation userToggleFollowExpert($id: String!, $expertId: String!) {
  userToggleFollowExpert(id: $id, expertId: $expertId) {
    id
    firstName
    lastName
    isAuthenticatedUserFollowing
  }
}
    `;
export type UserToggleFollowExpertMutationFn = Apollo.MutationFunction<types.UserToggleFollowExpertMutation, types.UserToggleFollowExpertMutationVariables>;

/**
 * __useUserToggleFollowExpertMutation__
 *
 * To run a mutation, you first call `useUserToggleFollowExpertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserToggleFollowExpertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userToggleFollowExpertMutation, { data, loading, error }] = useUserToggleFollowExpertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useUserToggleFollowExpertMutation(baseOptions?: Apollo.MutationHookOptions<types.UserToggleFollowExpertMutation, types.UserToggleFollowExpertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UserToggleFollowExpertMutation, types.UserToggleFollowExpertMutationVariables>(UserToggleFollowExpertDocument, options);
      }
export type UserToggleFollowExpertMutationHookResult = ReturnType<typeof useUserToggleFollowExpertMutation>;
export type UserToggleFollowExpertMutationResult = Apollo.MutationResult<types.UserToggleFollowExpertMutation>;
export type UserToggleFollowExpertMutationOptions = Apollo.BaseMutationOptions<types.UserToggleFollowExpertMutation, types.UserToggleFollowExpertMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: String!, $input: UpdateUserInput) {
  updateUser(id: $id, input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<types.UpdateUserMutation, types.UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateUserMutation, types.UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateUserMutation, types.UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<types.UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<types.UpdateUserMutation, types.UpdateUserMutationVariables>;
export const UserChangePasswordDocument = gql`
    mutation userChangePassword($id: String!, $oldPassword: String!, $newPassword: String!) {
  userChangePassword(
    id: $id
    input: {oldPassword: $oldPassword, newPassword: $newPassword}
  ) {
    success
  }
}
    `;
export type UserChangePasswordMutationFn = Apollo.MutationFunction<types.UserChangePasswordMutation, types.UserChangePasswordMutationVariables>;

/**
 * __useUserChangePasswordMutation__
 *
 * To run a mutation, you first call `useUserChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userChangePasswordMutation, { data, loading, error }] = useUserChangePasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUserChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<types.UserChangePasswordMutation, types.UserChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UserChangePasswordMutation, types.UserChangePasswordMutationVariables>(UserChangePasswordDocument, options);
      }
export type UserChangePasswordMutationHookResult = ReturnType<typeof useUserChangePasswordMutation>;
export type UserChangePasswordMutationResult = Apollo.MutationResult<types.UserChangePasswordMutation>;
export type UserChangePasswordMutationOptions = Apollo.BaseMutationOptions<types.UserChangePasswordMutation, types.UserChangePasswordMutationVariables>;
export const CreateCampaignUserDocument = gql`
    mutation CreateCampaignUser($input: CreateUserInput!) {
  createUserV2(input: $input) {
    __typename
    ... on ValidationError {
      fieldErrors {
        path
        message
      }
    }
    ... on User {
      id
    }
  }
}
    `;
export type CreateCampaignUserMutationFn = Apollo.MutationFunction<types.CreateCampaignUserMutation, types.CreateCampaignUserMutationVariables>;

/**
 * __useCreateCampaignUserMutation__
 *
 * To run a mutation, you first call `useCreateCampaignUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignUserMutation, { data, loading, error }] = useCreateCampaignUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCampaignUserMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateCampaignUserMutation, types.CreateCampaignUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateCampaignUserMutation, types.CreateCampaignUserMutationVariables>(CreateCampaignUserDocument, options);
      }
export type CreateCampaignUserMutationHookResult = ReturnType<typeof useCreateCampaignUserMutation>;
export type CreateCampaignUserMutationResult = Apollo.MutationResult<types.CreateCampaignUserMutation>;
export type CreateCampaignUserMutationOptions = Apollo.BaseMutationOptions<types.CreateCampaignUserMutation, types.CreateCampaignUserMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  loginEmail(email: $email, password: $password) {
    jwt
    success
    message
    finishedRegistration
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<types.LoginMutation, types.LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<types.LoginMutation, types.LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.LoginMutation, types.LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<types.LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<types.LoginMutation, types.LoginMutationVariables>;
export const LoginEmailV2Document = gql`
    mutation loginEmailV2($email: String!, $password: String!) {
  loginEmailV2(email: $email, password: $password) {
    ... on AuthenticationError {
      __typename
      message
    }
    ... on ValidationError {
      __typename
      fieldErrors {
        message
        path
      }
    }
    ... on AuthenticationResponse {
      jwt
      success
      message
      finishedRegistration
    }
  }
}
    `;
export type LoginEmailV2MutationFn = Apollo.MutationFunction<types.LoginEmailV2Mutation, types.LoginEmailV2MutationVariables>;

/**
 * __useLoginEmailV2Mutation__
 *
 * To run a mutation, you first call `useLoginEmailV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginEmailV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginEmailV2Mutation, { data, loading, error }] = useLoginEmailV2Mutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginEmailV2Mutation(baseOptions?: Apollo.MutationHookOptions<types.LoginEmailV2Mutation, types.LoginEmailV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.LoginEmailV2Mutation, types.LoginEmailV2MutationVariables>(LoginEmailV2Document, options);
      }
export type LoginEmailV2MutationHookResult = ReturnType<typeof useLoginEmailV2Mutation>;
export type LoginEmailV2MutationResult = Apollo.MutationResult<types.LoginEmailV2Mutation>;
export type LoginEmailV2MutationOptions = Apollo.BaseMutationOptions<types.LoginEmailV2Mutation, types.LoginEmailV2MutationVariables>;
export const IsSsoEnabledDocument = gql`
    mutation isSSOEnabled($email: String!) {
  isSSOEnabled(email: $email)
}
    `;
export type IsSsoEnabledMutationFn = Apollo.MutationFunction<types.IsSsoEnabledMutation, types.IsSsoEnabledMutationVariables>;

/**
 * __useIsSsoEnabledMutation__
 *
 * To run a mutation, you first call `useIsSsoEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIsSsoEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [isSsoEnabledMutation, { data, loading, error }] = useIsSsoEnabledMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsSsoEnabledMutation(baseOptions?: Apollo.MutationHookOptions<types.IsSsoEnabledMutation, types.IsSsoEnabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.IsSsoEnabledMutation, types.IsSsoEnabledMutationVariables>(IsSsoEnabledDocument, options);
      }
export type IsSsoEnabledMutationHookResult = ReturnType<typeof useIsSsoEnabledMutation>;
export type IsSsoEnabledMutationResult = Apollo.MutationResult<types.IsSsoEnabledMutation>;
export type IsSsoEnabledMutationOptions = Apollo.BaseMutationOptions<types.IsSsoEnabledMutation, types.IsSsoEnabledMutationVariables>;
export const LoginLinkedInDocument = gql`
    mutation loginLinkedIn($token: String!, $redirectUri: String!) {
  loginLinkedIn(token: $token, redirectUri: $redirectUri) {
    success
    message
    newAccount
    jwt
    userId
    finishedRegistration
  }
}
    `;
export type LoginLinkedInMutationFn = Apollo.MutationFunction<types.LoginLinkedInMutation, types.LoginLinkedInMutationVariables>;

/**
 * __useLoginLinkedInMutation__
 *
 * To run a mutation, you first call `useLoginLinkedInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginLinkedInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginLinkedInMutation, { data, loading, error }] = useLoginLinkedInMutation({
 *   variables: {
 *      token: // value for 'token'
 *      redirectUri: // value for 'redirectUri'
 *   },
 * });
 */
export function useLoginLinkedInMutation(baseOptions?: Apollo.MutationHookOptions<types.LoginLinkedInMutation, types.LoginLinkedInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.LoginLinkedInMutation, types.LoginLinkedInMutationVariables>(LoginLinkedInDocument, options);
      }
export type LoginLinkedInMutationHookResult = ReturnType<typeof useLoginLinkedInMutation>;
export type LoginLinkedInMutationResult = Apollo.MutationResult<types.LoginLinkedInMutation>;
export type LoginLinkedInMutationOptions = Apollo.BaseMutationOptions<types.LoginLinkedInMutation, types.LoginLinkedInMutationVariables>;
export const LoginAppleDocument = gql`
    mutation loginApple($authorizationCode: String!, $firstName: String!, $lastName: String!) {
  loginApple(
    authorizationCode: $authorizationCode
    firstName: $firstName
    lastName: $lastName
  ) {
    success
    message
    newAccount
    jwt
    userId
    finishedRegistration
  }
}
    `;
export type LoginAppleMutationFn = Apollo.MutationFunction<types.LoginAppleMutation, types.LoginAppleMutationVariables>;

/**
 * __useLoginAppleMutation__
 *
 * To run a mutation, you first call `useLoginAppleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAppleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAppleMutation, { data, loading, error }] = useLoginAppleMutation({
 *   variables: {
 *      authorizationCode: // value for 'authorizationCode'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useLoginAppleMutation(baseOptions?: Apollo.MutationHookOptions<types.LoginAppleMutation, types.LoginAppleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.LoginAppleMutation, types.LoginAppleMutationVariables>(LoginAppleDocument, options);
      }
export type LoginAppleMutationHookResult = ReturnType<typeof useLoginAppleMutation>;
export type LoginAppleMutationResult = Apollo.MutationResult<types.LoginAppleMutation>;
export type LoginAppleMutationOptions = Apollo.BaseMutationOptions<types.LoginAppleMutation, types.LoginAppleMutationVariables>;
export const ResetPasswordInitiateDocument = gql`
    mutation resetPasswordInitiate($email: String!) {
  resetPasswordInitiate(email: $email) {
    success
  }
}
    `;
export type ResetPasswordInitiateMutationFn = Apollo.MutationFunction<types.ResetPasswordInitiateMutation, types.ResetPasswordInitiateMutationVariables>;

/**
 * __useResetPasswordInitiateMutation__
 *
 * To run a mutation, you first call `useResetPasswordInitiateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordInitiateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordInitiateMutation, { data, loading, error }] = useResetPasswordInitiateMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordInitiateMutation(baseOptions?: Apollo.MutationHookOptions<types.ResetPasswordInitiateMutation, types.ResetPasswordInitiateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ResetPasswordInitiateMutation, types.ResetPasswordInitiateMutationVariables>(ResetPasswordInitiateDocument, options);
      }
export type ResetPasswordInitiateMutationHookResult = ReturnType<typeof useResetPasswordInitiateMutation>;
export type ResetPasswordInitiateMutationResult = Apollo.MutationResult<types.ResetPasswordInitiateMutation>;
export type ResetPasswordInitiateMutationOptions = Apollo.BaseMutationOptions<types.ResetPasswordInitiateMutation, types.ResetPasswordInitiateMutationVariables>;
export const ResetPasswordFinalizeDocument = gql`
    mutation resetPasswordFinalize($input: ResetPasswordFinalizeInput!) {
  resetPasswordFinalize(input: $input) {
    success
  }
}
    `;
export type ResetPasswordFinalizeMutationFn = Apollo.MutationFunction<types.ResetPasswordFinalizeMutation, types.ResetPasswordFinalizeMutationVariables>;

/**
 * __useResetPasswordFinalizeMutation__
 *
 * To run a mutation, you first call `useResetPasswordFinalizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordFinalizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordFinalizeMutation, { data, loading, error }] = useResetPasswordFinalizeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordFinalizeMutation(baseOptions?: Apollo.MutationHookOptions<types.ResetPasswordFinalizeMutation, types.ResetPasswordFinalizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ResetPasswordFinalizeMutation, types.ResetPasswordFinalizeMutationVariables>(ResetPasswordFinalizeDocument, options);
      }
export type ResetPasswordFinalizeMutationHookResult = ReturnType<typeof useResetPasswordFinalizeMutation>;
export type ResetPasswordFinalizeMutationResult = Apollo.MutationResult<types.ResetPasswordFinalizeMutation>;
export type ResetPasswordFinalizeMutationOptions = Apollo.BaseMutationOptions<types.ResetPasswordFinalizeMutation, types.ResetPasswordFinalizeMutationVariables>;
export const CreateChannelTabFollowTopicDocument = gql`
    mutation createChannelTabFollowTopic($channelId: String!, $teamId: String!, $topics: [ID!]!) {
  registerFollowTopicTab(channelId: $channelId, teamId: $teamId, topics: $topics) {
    id
  }
}
    `;
export type CreateChannelTabFollowTopicMutationFn = Apollo.MutationFunction<types.CreateChannelTabFollowTopicMutation, types.CreateChannelTabFollowTopicMutationVariables>;

/**
 * __useCreateChannelTabFollowTopicMutation__
 *
 * To run a mutation, you first call `useCreateChannelTabFollowTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelTabFollowTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelTabFollowTopicMutation, { data, loading, error }] = useCreateChannelTabFollowTopicMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      teamId: // value for 'teamId'
 *      topics: // value for 'topics'
 *   },
 * });
 */
export function useCreateChannelTabFollowTopicMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateChannelTabFollowTopicMutation, types.CreateChannelTabFollowTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateChannelTabFollowTopicMutation, types.CreateChannelTabFollowTopicMutationVariables>(CreateChannelTabFollowTopicDocument, options);
      }
export type CreateChannelTabFollowTopicMutationHookResult = ReturnType<typeof useCreateChannelTabFollowTopicMutation>;
export type CreateChannelTabFollowTopicMutationResult = Apollo.MutationResult<types.CreateChannelTabFollowTopicMutation>;
export type CreateChannelTabFollowTopicMutationOptions = Apollo.BaseMutationOptions<types.CreateChannelTabFollowTopicMutation, types.CreateChannelTabFollowTopicMutationVariables>;
export const UpdateChannelTabFollowTopicDocument = gql`
    mutation updateChannelTabFollowTopic($id: ID!, $topics: [ID!]!) {
  updateFollowTopicTab(id: $id, topics: $topics) {
    id
    topics {
      id
      name
      categories {
        id
        name
      }
    }
  }
}
    `;
export type UpdateChannelTabFollowTopicMutationFn = Apollo.MutationFunction<types.UpdateChannelTabFollowTopicMutation, types.UpdateChannelTabFollowTopicMutationVariables>;

/**
 * __useUpdateChannelTabFollowTopicMutation__
 *
 * To run a mutation, you first call `useUpdateChannelTabFollowTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelTabFollowTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelTabFollowTopicMutation, { data, loading, error }] = useUpdateChannelTabFollowTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      topics: // value for 'topics'
 *   },
 * });
 */
export function useUpdateChannelTabFollowTopicMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateChannelTabFollowTopicMutation, types.UpdateChannelTabFollowTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateChannelTabFollowTopicMutation, types.UpdateChannelTabFollowTopicMutationVariables>(UpdateChannelTabFollowTopicDocument, options);
      }
export type UpdateChannelTabFollowTopicMutationHookResult = ReturnType<typeof useUpdateChannelTabFollowTopicMutation>;
export type UpdateChannelTabFollowTopicMutationResult = Apollo.MutationResult<types.UpdateChannelTabFollowTopicMutation>;
export type UpdateChannelTabFollowTopicMutationOptions = Apollo.BaseMutationOptions<types.UpdateChannelTabFollowTopicMutation, types.UpdateChannelTabFollowTopicMutationVariables>;
export const DeleteChannelTabFollowTopicDocument = gql`
    mutation deleteChannelTabFollowTopic($id: ID!) {
  unregisterFollowTopicTab(id: $id)
}
    `;
export type DeleteChannelTabFollowTopicMutationFn = Apollo.MutationFunction<types.DeleteChannelTabFollowTopicMutation, types.DeleteChannelTabFollowTopicMutationVariables>;

/**
 * __useDeleteChannelTabFollowTopicMutation__
 *
 * To run a mutation, you first call `useDeleteChannelTabFollowTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChannelTabFollowTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChannelTabFollowTopicMutation, { data, loading, error }] = useDeleteChannelTabFollowTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChannelTabFollowTopicMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteChannelTabFollowTopicMutation, types.DeleteChannelTabFollowTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteChannelTabFollowTopicMutation, types.DeleteChannelTabFollowTopicMutationVariables>(DeleteChannelTabFollowTopicDocument, options);
      }
export type DeleteChannelTabFollowTopicMutationHookResult = ReturnType<typeof useDeleteChannelTabFollowTopicMutation>;
export type DeleteChannelTabFollowTopicMutationResult = Apollo.MutationResult<types.DeleteChannelTabFollowTopicMutation>;
export type DeleteChannelTabFollowTopicMutationOptions = Apollo.BaseMutationOptions<types.DeleteChannelTabFollowTopicMutation, types.DeleteChannelTabFollowTopicMutationVariables>;
export const GetChannelTabFollowTopicsDocument = gql`
    query getChannelTabFollowTopics($id: ID!) {
  msTeamsFollowTopicTab(id: $id) {
    id
    topics {
      id
      name
      categories {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetChannelTabFollowTopicsQuery__
 *
 * To run a query within a React component, call `useGetChannelTabFollowTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelTabFollowTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelTabFollowTopicsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChannelTabFollowTopicsQuery(baseOptions: Apollo.QueryHookOptions<types.GetChannelTabFollowTopicsQuery, types.GetChannelTabFollowTopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetChannelTabFollowTopicsQuery, types.GetChannelTabFollowTopicsQueryVariables>(GetChannelTabFollowTopicsDocument, options);
      }
export function useGetChannelTabFollowTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetChannelTabFollowTopicsQuery, types.GetChannelTabFollowTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetChannelTabFollowTopicsQuery, types.GetChannelTabFollowTopicsQueryVariables>(GetChannelTabFollowTopicsDocument, options);
        }
export type GetChannelTabFollowTopicsQueryHookResult = ReturnType<typeof useGetChannelTabFollowTopicsQuery>;
export type GetChannelTabFollowTopicsLazyQueryHookResult = ReturnType<typeof useGetChannelTabFollowTopicsLazyQuery>;
export type GetChannelTabFollowTopicsQueryResult = Apollo.QueryResult<types.GetChannelTabFollowTopicsQuery, types.GetChannelTabFollowTopicsQueryVariables>;
export const LoginMsTeamsDocument = gql`
    mutation loginMSTeams($token: String!) {
  loginMSTeams(accessToken: $token) {
    finishedRegistration
    jwt
    message
    newAccount
    userId
    success
  }
}
    `;
export type LoginMsTeamsMutationFn = Apollo.MutationFunction<types.LoginMsTeamsMutation, types.LoginMsTeamsMutationVariables>;

/**
 * __useLoginMsTeamsMutation__
 *
 * To run a mutation, you first call `useLoginMsTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMsTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMsTeamsMutation, { data, loading, error }] = useLoginMsTeamsMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginMsTeamsMutation(baseOptions?: Apollo.MutationHookOptions<types.LoginMsTeamsMutation, types.LoginMsTeamsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.LoginMsTeamsMutation, types.LoginMsTeamsMutationVariables>(LoginMsTeamsDocument, options);
      }
export type LoginMsTeamsMutationHookResult = ReturnType<typeof useLoginMsTeamsMutation>;
export type LoginMsTeamsMutationResult = Apollo.MutationResult<types.LoginMsTeamsMutation>;
export type LoginMsTeamsMutationOptions = Apollo.BaseMutationOptions<types.LoginMsTeamsMutation, types.LoginMsTeamsMutationVariables>;
export const CreateAssignmentDocument = gql`
    mutation createAssignment($orgId: ID!, $input: AssignmentInput) {
  createAssignment(organisationID: $orgId, input: $input) {
    id
    assignmentTitle
    assignmentReason
    dueDate
  }
}
    `;
export type CreateAssignmentMutationFn = Apollo.MutationFunction<types.CreateAssignmentMutation, types.CreateAssignmentMutationVariables>;

/**
 * __useCreateAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentMutation, { data, loading, error }] = useCreateAssignmentMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateAssignmentMutation, types.CreateAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateAssignmentMutation, types.CreateAssignmentMutationVariables>(CreateAssignmentDocument, options);
      }
export type CreateAssignmentMutationHookResult = ReturnType<typeof useCreateAssignmentMutation>;
export type CreateAssignmentMutationResult = Apollo.MutationResult<types.CreateAssignmentMutation>;
export type CreateAssignmentMutationOptions = Apollo.BaseMutationOptions<types.CreateAssignmentMutation, types.CreateAssignmentMutationVariables>;
export const UpdateAssignmentDocument = gql`
    mutation UpdateAssignment($id: ID!, $input: AssignmentInput) {
  updateAssignment(id: $id, input: $input) {
    id
    assignmentTitle
    assignmentReason
    status
  }
}
    `;
export type UpdateAssignmentMutationFn = Apollo.MutationFunction<types.UpdateAssignmentMutation, types.UpdateAssignmentMutationVariables>;

/**
 * __useUpdateAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentMutation, { data, loading, error }] = useUpdateAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateAssignmentMutation, types.UpdateAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateAssignmentMutation, types.UpdateAssignmentMutationVariables>(UpdateAssignmentDocument, options);
      }
export type UpdateAssignmentMutationHookResult = ReturnType<typeof useUpdateAssignmentMutation>;
export type UpdateAssignmentMutationResult = Apollo.MutationResult<types.UpdateAssignmentMutation>;
export type UpdateAssignmentMutationOptions = Apollo.BaseMutationOptions<types.UpdateAssignmentMutation, types.UpdateAssignmentMutationVariables>;
export const UpdateAssignmentStatusDocument = gql`
    mutation UpdateAssignmentStatus($id: ID!, $status: AssignmentStatus!) {
  updateAssignmentStatus(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type UpdateAssignmentStatusMutationFn = Apollo.MutationFunction<types.UpdateAssignmentStatusMutation, types.UpdateAssignmentStatusMutationVariables>;

/**
 * __useUpdateAssignmentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentStatusMutation, { data, loading, error }] = useUpdateAssignmentStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateAssignmentStatusMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateAssignmentStatusMutation, types.UpdateAssignmentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateAssignmentStatusMutation, types.UpdateAssignmentStatusMutationVariables>(UpdateAssignmentStatusDocument, options);
      }
export type UpdateAssignmentStatusMutationHookResult = ReturnType<typeof useUpdateAssignmentStatusMutation>;
export type UpdateAssignmentStatusMutationResult = Apollo.MutationResult<types.UpdateAssignmentStatusMutation>;
export type UpdateAssignmentStatusMutationOptions = Apollo.BaseMutationOptions<types.UpdateAssignmentStatusMutation, types.UpdateAssignmentStatusMutationVariables>;
export const DeleteAssignmentDocument = gql`
    mutation deleteAssignment($id: ID!) {
  deleteAssignment(id: $id)
}
    `;
export type DeleteAssignmentMutationFn = Apollo.MutationFunction<types.DeleteAssignmentMutation, types.DeleteAssignmentMutationVariables>;

/**
 * __useDeleteAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssignmentMutation, { data, loading, error }] = useDeleteAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteAssignmentMutation, types.DeleteAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteAssignmentMutation, types.DeleteAssignmentMutationVariables>(DeleteAssignmentDocument, options);
      }
export type DeleteAssignmentMutationHookResult = ReturnType<typeof useDeleteAssignmentMutation>;
export type DeleteAssignmentMutationResult = Apollo.MutationResult<types.DeleteAssignmentMutation>;
export type DeleteAssignmentMutationOptions = Apollo.BaseMutationOptions<types.DeleteAssignmentMutation, types.DeleteAssignmentMutationVariables>;
export const GetOrgAssignmentDocument = gql`
    query getOrgAssignment($orgId: String!, $assignmentId: ID!) {
  organisation(id: $orgId) {
    __typename
    id
    assignments(filter: {limit: 10, offset: 0, id: $assignmentId}) {
      meta {
        total
        offset
        limit
      }
      edges {
        id
        assignmentTitle
        assignmentReason
        dueDate
        status
        users {
          meta {
            total
          }
          edges {
            id
            firstName
            lastName
            email
            organisationGroups {
              id
              name
            }
          }
        }
        contents {
          meta {
            total
          }
          edges {
            ... on Podcast {
              ...Podcast
            }
            ... on Ebook {
              ...Ebook
            }
            ... on Stream {
              ...Stream
            }
            ... on LearningPath {
              ...LearningPath
            }
          }
        }
        orgGroups {
          ...SelectionTableGroup
        }
      }
    }
  }
}
    ${PodcastFragmentDoc}
${EbookFragmentDoc}
${StreamFragmentDoc}
${LearningPathFragmentDoc}
${SelectionTableGroupFragmentDoc}`;

/**
 * __useGetOrgAssignmentQuery__
 *
 * To run a query within a React component, call `useGetOrgAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgAssignmentQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useGetOrgAssignmentQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgAssignmentQuery, types.GetOrgAssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgAssignmentQuery, types.GetOrgAssignmentQueryVariables>(GetOrgAssignmentDocument, options);
      }
export function useGetOrgAssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgAssignmentQuery, types.GetOrgAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgAssignmentQuery, types.GetOrgAssignmentQueryVariables>(GetOrgAssignmentDocument, options);
        }
export type GetOrgAssignmentQueryHookResult = ReturnType<typeof useGetOrgAssignmentQuery>;
export type GetOrgAssignmentLazyQueryHookResult = ReturnType<typeof useGetOrgAssignmentLazyQuery>;
export type GetOrgAssignmentQueryResult = Apollo.QueryResult<types.GetOrgAssignmentQuery, types.GetOrgAssignmentQueryVariables>;
export const FetchLearningPathDocument = gql`
    query fetchLearningPath($filter: LearningPathFilter, $sorting: SortOrdering) {
  learningPaths(filter: $filter, sorting: $sorting) {
    meta {
      total
      offset
      limit
    }
    edges {
      ...learningPathItem
    }
  }
}
    ${LearningPathItemFragmentDoc}`;

/**
 * __useFetchLearningPathQuery__
 *
 * To run a query within a React component, call `useFetchLearningPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchLearningPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchLearningPathQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useFetchLearningPathQuery(baseOptions?: Apollo.QueryHookOptions<types.FetchLearningPathQuery, types.FetchLearningPathQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.FetchLearningPathQuery, types.FetchLearningPathQueryVariables>(FetchLearningPathDocument, options);
      }
export function useFetchLearningPathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.FetchLearningPathQuery, types.FetchLearningPathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.FetchLearningPathQuery, types.FetchLearningPathQueryVariables>(FetchLearningPathDocument, options);
        }
export type FetchLearningPathQueryHookResult = ReturnType<typeof useFetchLearningPathQuery>;
export type FetchLearningPathLazyQueryHookResult = ReturnType<typeof useFetchLearningPathLazyQuery>;
export type FetchLearningPathQueryResult = Apollo.QueryResult<types.FetchLearningPathQuery, types.FetchLearningPathQueryVariables>;
export const GetCategoryDocument = gql`
    query getCategory($id: String!) {
  category(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetCategoryQuery__
 *
 * To run a query within a React component, call `useGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCategoryQuery(baseOptions: Apollo.QueryHookOptions<types.GetCategoryQuery, types.GetCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCategoryQuery, types.GetCategoryQueryVariables>(GetCategoryDocument, options);
      }
export function useGetCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCategoryQuery, types.GetCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCategoryQuery, types.GetCategoryQueryVariables>(GetCategoryDocument, options);
        }
export type GetCategoryQueryHookResult = ReturnType<typeof useGetCategoryQuery>;
export type GetCategoryLazyQueryHookResult = ReturnType<typeof useGetCategoryLazyQuery>;
export type GetCategoryQueryResult = Apollo.QueryResult<types.GetCategoryQuery, types.GetCategoryQueryVariables>;
export const FetchCategoriesDocument = gql`
    query fetchCategories($filter: CategoryFilter) {
  categories(filter: $filter) {
    meta {
      total
    }
    edges {
      id
      name
      image {
        id
        uri
      }
    }
  }
}
    `;

/**
 * __useFetchCategoriesQuery__
 *
 * To run a query within a React component, call `useFetchCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFetchCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<types.FetchCategoriesQuery, types.FetchCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.FetchCategoriesQuery, types.FetchCategoriesQueryVariables>(FetchCategoriesDocument, options);
      }
export function useFetchCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.FetchCategoriesQuery, types.FetchCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.FetchCategoriesQuery, types.FetchCategoriesQueryVariables>(FetchCategoriesDocument, options);
        }
export type FetchCategoriesQueryHookResult = ReturnType<typeof useFetchCategoriesQuery>;
export type FetchCategoriesLazyQueryHookResult = ReturnType<typeof useFetchCategoriesLazyQuery>;
export type FetchCategoriesQueryResult = Apollo.QueryResult<types.FetchCategoriesQuery, types.FetchCategoriesQueryVariables>;
export const GetContentByIdDocument = gql`
    query getContentById($id: ID!) {
  contentCard(id: $id) {
    ...ContentCard
  }
}
    ${ContentCardFragmentDoc}`;

/**
 * __useGetContentByIdQuery__
 *
 * To run a query within a React component, call `useGetContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetContentByIdQuery, types.GetContentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetContentByIdQuery, types.GetContentByIdQueryVariables>(GetContentByIdDocument, options);
      }
export function useGetContentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetContentByIdQuery, types.GetContentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetContentByIdQuery, types.GetContentByIdQueryVariables>(GetContentByIdDocument, options);
        }
export type GetContentByIdQueryHookResult = ReturnType<typeof useGetContentByIdQuery>;
export type GetContentByIdLazyQueryHookResult = ReturnType<typeof useGetContentByIdLazyQuery>;
export type GetContentByIdQueryResult = Apollo.QueryResult<types.GetContentByIdQuery, types.GetContentByIdQueryVariables>;
export const GetRelatedTrailsForContentDocument = gql`
    query getRelatedTrailsForContent($id: ID!) {
  contentCard(id: $id) {
    ... on Podcast {
      id
      learningPaths(filter: {limit: 5, offset: 0}) {
        edges {
          ...LimitedLearningPath
        }
      }
    }
    ... on Event {
      id
      learningPaths(filter: {limit: 5, offset: 0}) {
        edges {
          ...LimitedLearningPath
        }
      }
    }
    ... on Stream {
      id
      learningPaths(filter: {limit: 5, offset: 0}) {
        edges {
          ...LimitedLearningPath
        }
      }
    }
    ... on Ebook {
      id
      learningPaths(filter: {limit: 5, offset: 0}) {
        edges {
          ...LimitedLearningPath
        }
      }
    }
  }
}
    ${LimitedLearningPathFragmentDoc}`;

/**
 * __useGetRelatedTrailsForContentQuery__
 *
 * To run a query within a React component, call `useGetRelatedTrailsForContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelatedTrailsForContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelatedTrailsForContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRelatedTrailsForContentQuery(baseOptions: Apollo.QueryHookOptions<types.GetRelatedTrailsForContentQuery, types.GetRelatedTrailsForContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetRelatedTrailsForContentQuery, types.GetRelatedTrailsForContentQueryVariables>(GetRelatedTrailsForContentDocument, options);
      }
export function useGetRelatedTrailsForContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetRelatedTrailsForContentQuery, types.GetRelatedTrailsForContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetRelatedTrailsForContentQuery, types.GetRelatedTrailsForContentQueryVariables>(GetRelatedTrailsForContentDocument, options);
        }
export type GetRelatedTrailsForContentQueryHookResult = ReturnType<typeof useGetRelatedTrailsForContentQuery>;
export type GetRelatedTrailsForContentLazyQueryHookResult = ReturnType<typeof useGetRelatedTrailsForContentLazyQuery>;
export type GetRelatedTrailsForContentQueryResult = Apollo.QueryResult<types.GetRelatedTrailsForContentQuery, types.GetRelatedTrailsForContentQueryVariables>;
export const UserTrackContentProgressDocument = gql`
    mutation userTrackContentProgress($id: String!, $data: TrackingData!, $meta: ContentMetadata) {
  userTrackContentProgress(id: $id, data: $data, contentMetadata: $meta) {
    ... on Ebook {
      ...Ebook
    }
    ... on Event {
      ...Event
    }
    ... on Podcast {
      ...Podcast
    }
    ... on Stream {
      ...Stream
    }
  }
}
    ${EbookFragmentDoc}
${EventFragmentDoc}
${PodcastFragmentDoc}
${StreamFragmentDoc}`;
export type UserTrackContentProgressMutationFn = Apollo.MutationFunction<types.UserTrackContentProgressMutation, types.UserTrackContentProgressMutationVariables>;

/**
 * __useUserTrackContentProgressMutation__
 *
 * To run a mutation, you first call `useUserTrackContentProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserTrackContentProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userTrackContentProgressMutation, { data, loading, error }] = useUserTrackContentProgressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *      meta: // value for 'meta'
 *   },
 * });
 */
export function useUserTrackContentProgressMutation(baseOptions?: Apollo.MutationHookOptions<types.UserTrackContentProgressMutation, types.UserTrackContentProgressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UserTrackContentProgressMutation, types.UserTrackContentProgressMutationVariables>(UserTrackContentProgressDocument, options);
      }
export type UserTrackContentProgressMutationHookResult = ReturnType<typeof useUserTrackContentProgressMutation>;
export type UserTrackContentProgressMutationResult = Apollo.MutationResult<types.UserTrackContentProgressMutation>;
export type UserTrackContentProgressMutationOptions = Apollo.BaseMutationOptions<types.UserTrackContentProgressMutation, types.UserTrackContentProgressMutationVariables>;
export const UserTrackContentToggleAttendingDocument = gql`
    mutation userTrackContentToggleAttending($id: String!) {
  userTrackContentToggleAttending(id: $id) {
    ... on Event {
      id
      userContentTracking {
        isAttending
      }
    }
  }
}
    `;
export type UserTrackContentToggleAttendingMutationFn = Apollo.MutationFunction<types.UserTrackContentToggleAttendingMutation, types.UserTrackContentToggleAttendingMutationVariables>;

/**
 * __useUserTrackContentToggleAttendingMutation__
 *
 * To run a mutation, you first call `useUserTrackContentToggleAttendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserTrackContentToggleAttendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userTrackContentToggleAttendingMutation, { data, loading, error }] = useUserTrackContentToggleAttendingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserTrackContentToggleAttendingMutation(baseOptions?: Apollo.MutationHookOptions<types.UserTrackContentToggleAttendingMutation, types.UserTrackContentToggleAttendingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UserTrackContentToggleAttendingMutation, types.UserTrackContentToggleAttendingMutationVariables>(UserTrackContentToggleAttendingDocument, options);
      }
export type UserTrackContentToggleAttendingMutationHookResult = ReturnType<typeof useUserTrackContentToggleAttendingMutation>;
export type UserTrackContentToggleAttendingMutationResult = Apollo.MutationResult<types.UserTrackContentToggleAttendingMutation>;
export type UserTrackContentToggleAttendingMutationOptions = Apollo.BaseMutationOptions<types.UserTrackContentToggleAttendingMutation, types.UserTrackContentToggleAttendingMutationVariables>;
export const ReportContentDocument = gql`
    mutation ReportContent($contentID: ID!, $contentType: ReportableContentType!, $reason: String!, $comment: String) {
  reportContent(
    contentID: $contentID
    contentType: $contentType
    reason: $reason
    comment: $comment
  )
}
    `;
export type ReportContentMutationFn = Apollo.MutationFunction<types.ReportContentMutation, types.ReportContentMutationVariables>;

/**
 * __useReportContentMutation__
 *
 * To run a mutation, you first call `useReportContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportContentMutation, { data, loading, error }] = useReportContentMutation({
 *   variables: {
 *      contentID: // value for 'contentID'
 *      contentType: // value for 'contentType'
 *      reason: // value for 'reason'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useReportContentMutation(baseOptions?: Apollo.MutationHookOptions<types.ReportContentMutation, types.ReportContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ReportContentMutation, types.ReportContentMutationVariables>(ReportContentDocument, options);
      }
export type ReportContentMutationHookResult = ReturnType<typeof useReportContentMutation>;
export type ReportContentMutationResult = Apollo.MutationResult<types.ReportContentMutation>;
export type ReportContentMutationOptions = Apollo.BaseMutationOptions<types.ReportContentMutation, types.ReportContentMutationVariables>;
export const GetEventsDocument = gql`
    query getEvents($filter: EventFilter!, $sorting: SortOrdering) {
  events(filter: $filter, sorting: $sorting) {
    edges {
      __typename
      ...eventFragment
    }
    meta {
      ...PageInfo
    }
  }
}
    ${EventFragmentFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions: Apollo.QueryHookOptions<types.GetEventsQuery, types.GetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetEventsQuery, types.GetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetEventsQuery, types.GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetEventsQuery, types.GetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<types.GetEventsQuery, types.GetEventsQueryVariables>;
export const FetchEventDocument = gql`
    query fetchEvent($id: String!) {
  event(id: $id) {
    ...eventFragment
  }
}
    ${EventFragmentFragmentDoc}`;

/**
 * __useFetchEventQuery__
 *
 * To run a query within a React component, call `useFetchEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchEventQuery(baseOptions: Apollo.QueryHookOptions<types.FetchEventQuery, types.FetchEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.FetchEventQuery, types.FetchEventQueryVariables>(FetchEventDocument, options);
      }
export function useFetchEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.FetchEventQuery, types.FetchEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.FetchEventQuery, types.FetchEventQueryVariables>(FetchEventDocument, options);
        }
export type FetchEventQueryHookResult = ReturnType<typeof useFetchEventQuery>;
export type FetchEventLazyQueryHookResult = ReturnType<typeof useFetchEventLazyQuery>;
export type FetchEventQueryResult = Apollo.QueryResult<types.FetchEventQuery, types.FetchEventQueryVariables>;
export const CreateTrailDocument = gql`
    mutation createTrail($input: LearningPathInput!) {
  result: createLearningPath(input: $input) {
    id
    ...learningPathItem
  }
}
    ${LearningPathItemFragmentDoc}`;
export type CreateTrailMutationFn = Apollo.MutationFunction<types.CreateTrailMutation, types.CreateTrailMutationVariables>;

/**
 * __useCreateTrailMutation__
 *
 * To run a mutation, you first call `useCreateTrailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrailMutation, { data, loading, error }] = useCreateTrailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTrailMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateTrailMutation, types.CreateTrailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateTrailMutation, types.CreateTrailMutationVariables>(CreateTrailDocument, options);
      }
export type CreateTrailMutationHookResult = ReturnType<typeof useCreateTrailMutation>;
export type CreateTrailMutationResult = Apollo.MutationResult<types.CreateTrailMutation>;
export type CreateTrailMutationOptions = Apollo.BaseMutationOptions<types.CreateTrailMutation, types.CreateTrailMutationVariables>;
export const EditTrailDocument = gql`
    mutation editTrail($id: String!, $input: LearningPathInput!) {
  result: updateLearningPath(input: $input, id: $id) {
    id
    ...learningPathItem
  }
}
    ${LearningPathItemFragmentDoc}`;
export type EditTrailMutationFn = Apollo.MutationFunction<types.EditTrailMutation, types.EditTrailMutationVariables>;

/**
 * __useEditTrailMutation__
 *
 * To run a mutation, you first call `useEditTrailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTrailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTrailMutation, { data, loading, error }] = useEditTrailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTrailMutation(baseOptions?: Apollo.MutationHookOptions<types.EditTrailMutation, types.EditTrailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.EditTrailMutation, types.EditTrailMutationVariables>(EditTrailDocument, options);
      }
export type EditTrailMutationHookResult = ReturnType<typeof useEditTrailMutation>;
export type EditTrailMutationResult = Apollo.MutationResult<types.EditTrailMutation>;
export type EditTrailMutationOptions = Apollo.BaseMutationOptions<types.EditTrailMutation, types.EditTrailMutationVariables>;
export const DeleteLearningPathDocument = gql`
    mutation deleteLearningPath($id: String!) {
  deleteLearningPath(id: $id) {
    success
  }
}
    `;
export type DeleteLearningPathMutationFn = Apollo.MutationFunction<types.DeleteLearningPathMutation, types.DeleteLearningPathMutationVariables>;

/**
 * __useDeleteLearningPathMutation__
 *
 * To run a mutation, you first call `useDeleteLearningPathMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLearningPathMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLearningPathMutation, { data, loading, error }] = useDeleteLearningPathMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLearningPathMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteLearningPathMutation, types.DeleteLearningPathMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteLearningPathMutation, types.DeleteLearningPathMutationVariables>(DeleteLearningPathDocument, options);
      }
export type DeleteLearningPathMutationHookResult = ReturnType<typeof useDeleteLearningPathMutation>;
export type DeleteLearningPathMutationResult = Apollo.MutationResult<types.DeleteLearningPathMutation>;
export type DeleteLearningPathMutationOptions = Apollo.BaseMutationOptions<types.DeleteLearningPathMutation, types.DeleteLearningPathMutationVariables>;
export const GetPodcastByIdWithLanguageDocument = gql`
    query GetPodcastByIdWithLanguage($language: String, $id: String!) {
  podcast(id: $id) {
    __typename
    id
    name
    preamble
    entryLevel
    length
    createdAt
    updatedAt
    publishedAt
    rating
    image {
      id
      uri
      blurHash
    }
    categories {
      id
      name
    }
    contentSegments {
      header
      paragraph
    }
    episodes(language: $language) {
      name
      order
      audio {
        id
        uri
        length
        sizeInBytes
        contentType
        filename
        originalName
        createdAt
      }
      audioId
      language
    }
    audioAvailableLanguages {
      name
      code
    }
    transcriptAvailableLanguages {
      name
      code
    }
    transcripts {
      language
      publishedAt
      length
      transcripts {
        index
        page
      }
    }
    experts {
      ...Expert
    }
    userContentTracking {
      ...UserContentTracking
    }
    upvoteCount
    contentRatingByUser
    learningPaths(filter: {limit: 3}) {
      edges {
        __typename
        id
        name
      }
    }
  }
}
    ${ExpertFragmentDoc}
${UserContentTrackingFragmentDoc}`;

/**
 * __useGetPodcastByIdWithLanguageQuery__
 *
 * To run a query within a React component, call `useGetPodcastByIdWithLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPodcastByIdWithLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPodcastByIdWithLanguageQuery({
 *   variables: {
 *      language: // value for 'language'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPodcastByIdWithLanguageQuery(baseOptions: Apollo.QueryHookOptions<types.GetPodcastByIdWithLanguageQuery, types.GetPodcastByIdWithLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPodcastByIdWithLanguageQuery, types.GetPodcastByIdWithLanguageQueryVariables>(GetPodcastByIdWithLanguageDocument, options);
      }
export function useGetPodcastByIdWithLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPodcastByIdWithLanguageQuery, types.GetPodcastByIdWithLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPodcastByIdWithLanguageQuery, types.GetPodcastByIdWithLanguageQueryVariables>(GetPodcastByIdWithLanguageDocument, options);
        }
export type GetPodcastByIdWithLanguageQueryHookResult = ReturnType<typeof useGetPodcastByIdWithLanguageQuery>;
export type GetPodcastByIdWithLanguageLazyQueryHookResult = ReturnType<typeof useGetPodcastByIdWithLanguageLazyQuery>;
export type GetPodcastByIdWithLanguageQueryResult = Apollo.QueryResult<types.GetPodcastByIdWithLanguageQuery, types.GetPodcastByIdWithLanguageQueryVariables>;
export const GetPodcastTranscriptByLanguageDocument = gql`
    query GetPodcastTranscriptByLanguage($id: String!, $transcriptLanguage: String) {
  podcast(id: $id) {
    id
    transcriptAvailableLanguages {
      code
      name
    }
    transcripts(language: $transcriptLanguage) {
      language
      length
      publishedAt
      transcripts {
        index
        page
      }
    }
  }
}
    `;

/**
 * __useGetPodcastTranscriptByLanguageQuery__
 *
 * To run a query within a React component, call `useGetPodcastTranscriptByLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPodcastTranscriptByLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPodcastTranscriptByLanguageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      transcriptLanguage: // value for 'transcriptLanguage'
 *   },
 * });
 */
export function useGetPodcastTranscriptByLanguageQuery(baseOptions: Apollo.QueryHookOptions<types.GetPodcastTranscriptByLanguageQuery, types.GetPodcastTranscriptByLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPodcastTranscriptByLanguageQuery, types.GetPodcastTranscriptByLanguageQueryVariables>(GetPodcastTranscriptByLanguageDocument, options);
      }
export function useGetPodcastTranscriptByLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPodcastTranscriptByLanguageQuery, types.GetPodcastTranscriptByLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPodcastTranscriptByLanguageQuery, types.GetPodcastTranscriptByLanguageQueryVariables>(GetPodcastTranscriptByLanguageDocument, options);
        }
export type GetPodcastTranscriptByLanguageQueryHookResult = ReturnType<typeof useGetPodcastTranscriptByLanguageQuery>;
export type GetPodcastTranscriptByLanguageLazyQueryHookResult = ReturnType<typeof useGetPodcastTranscriptByLanguageLazyQuery>;
export type GetPodcastTranscriptByLanguageQueryResult = Apollo.QueryResult<types.GetPodcastTranscriptByLanguageQuery, types.GetPodcastTranscriptByLanguageQueryVariables>;
export const GetEbookByIdDocument = gql`
    query getEbookById($id: String!) {
  ebook(id: $id) {
    ... on Ebook {
      ...Ebook
    }
  }
}
    ${EbookFragmentDoc}`;

/**
 * __useGetEbookByIdQuery__
 *
 * To run a query within a React component, call `useGetEbookByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEbookByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEbookByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEbookByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetEbookByIdQuery, types.GetEbookByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetEbookByIdQuery, types.GetEbookByIdQueryVariables>(GetEbookByIdDocument, options);
      }
export function useGetEbookByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetEbookByIdQuery, types.GetEbookByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetEbookByIdQuery, types.GetEbookByIdQueryVariables>(GetEbookByIdDocument, options);
        }
export type GetEbookByIdQueryHookResult = ReturnType<typeof useGetEbookByIdQuery>;
export type GetEbookByIdLazyQueryHookResult = ReturnType<typeof useGetEbookByIdLazyQuery>;
export type GetEbookByIdQueryResult = Apollo.QueryResult<types.GetEbookByIdQuery, types.GetEbookByIdQueryVariables>;
export const GetEbookForIdAndLanguageDocument = gql`
    query GetEbookForIdAndLanguage($id: String!, $language: String) {
  ebook(id: $id) {
    id
    chapters(language: $language) {
      name
      index
      pages
      language
    }
    episodes(language: $language) {
      language
      episodes {
        name
        audio {
          id
          length
          originalName
          uri
        }
      }
    }
  }
}
    `;

/**
 * __useGetEbookForIdAndLanguageQuery__
 *
 * To run a query within a React component, call `useGetEbookForIdAndLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEbookForIdAndLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEbookForIdAndLanguageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetEbookForIdAndLanguageQuery(baseOptions: Apollo.QueryHookOptions<types.GetEbookForIdAndLanguageQuery, types.GetEbookForIdAndLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetEbookForIdAndLanguageQuery, types.GetEbookForIdAndLanguageQueryVariables>(GetEbookForIdAndLanguageDocument, options);
      }
export function useGetEbookForIdAndLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetEbookForIdAndLanguageQuery, types.GetEbookForIdAndLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetEbookForIdAndLanguageQuery, types.GetEbookForIdAndLanguageQueryVariables>(GetEbookForIdAndLanguageDocument, options);
        }
export type GetEbookForIdAndLanguageQueryHookResult = ReturnType<typeof useGetEbookForIdAndLanguageQuery>;
export type GetEbookForIdAndLanguageLazyQueryHookResult = ReturnType<typeof useGetEbookForIdAndLanguageLazyQuery>;
export type GetEbookForIdAndLanguageQueryResult = Apollo.QueryResult<types.GetEbookForIdAndLanguageQuery, types.GetEbookForIdAndLanguageQueryVariables>;
export const UserTrackContentToggleFinishedDocument = gql`
    mutation userTrackContentToggleFinished($id: String!) {
  userTrackContentToggleFinished(id: $id) {
    ... on Ebook {
      ...Ebook
    }
    ... on Podcast {
      ...Podcast
    }
    ... on Event {
      ...Event
    }
    ... on Stream {
      ...Stream
    }
  }
}
    ${EbookFragmentDoc}
${PodcastFragmentDoc}
${EventFragmentDoc}
${StreamFragmentDoc}`;
export type UserTrackContentToggleFinishedMutationFn = Apollo.MutationFunction<types.UserTrackContentToggleFinishedMutation, types.UserTrackContentToggleFinishedMutationVariables>;

/**
 * __useUserTrackContentToggleFinishedMutation__
 *
 * To run a mutation, you first call `useUserTrackContentToggleFinishedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserTrackContentToggleFinishedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userTrackContentToggleFinishedMutation, { data, loading, error }] = useUserTrackContentToggleFinishedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserTrackContentToggleFinishedMutation(baseOptions?: Apollo.MutationHookOptions<types.UserTrackContentToggleFinishedMutation, types.UserTrackContentToggleFinishedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UserTrackContentToggleFinishedMutation, types.UserTrackContentToggleFinishedMutationVariables>(UserTrackContentToggleFinishedDocument, options);
      }
export type UserTrackContentToggleFinishedMutationHookResult = ReturnType<typeof useUserTrackContentToggleFinishedMutation>;
export type UserTrackContentToggleFinishedMutationResult = Apollo.MutationResult<types.UserTrackContentToggleFinishedMutation>;
export type UserTrackContentToggleFinishedMutationOptions = Apollo.BaseMutationOptions<types.UserTrackContentToggleFinishedMutation, types.UserTrackContentToggleFinishedMutationVariables>;
export const GetEbooksDocument = gql`
    query GetEbooks($filter: EbookFilter, $sorting: SortOrdering) {
  ebooks(filter: $filter, sorting: $sorting) {
    edges {
      ...EbookCard
    }
    meta {
      total
      offset
      limit
    }
  }
}
    ${EbookCardFragmentDoc}`;

/**
 * __useGetEbooksQuery__
 *
 * To run a query within a React component, call `useGetEbooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEbooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEbooksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetEbooksQuery(baseOptions?: Apollo.QueryHookOptions<types.GetEbooksQuery, types.GetEbooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetEbooksQuery, types.GetEbooksQueryVariables>(GetEbooksDocument, options);
      }
export function useGetEbooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetEbooksQuery, types.GetEbooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetEbooksQuery, types.GetEbooksQueryVariables>(GetEbooksDocument, options);
        }
export type GetEbooksQueryHookResult = ReturnType<typeof useGetEbooksQuery>;
export type GetEbooksLazyQueryHookResult = ReturnType<typeof useGetEbooksLazyQuery>;
export type GetEbooksQueryResult = Apollo.QueryResult<types.GetEbooksQuery, types.GetEbooksQueryVariables>;
export const CreateEbookDocument = gql`
    mutation CreateEbook($input: EbookInput!) {
  createEbook(input: $input) {
    ... on ValidationError {
      fieldErrors {
        message
        path
      }
    }
    ... on Ebook {
      ...Ebook
    }
  }
}
    ${EbookFragmentDoc}`;
export type CreateEbookMutationFn = Apollo.MutationFunction<types.CreateEbookMutation, types.CreateEbookMutationVariables>;

/**
 * __useCreateEbookMutation__
 *
 * To run a mutation, you first call `useCreateEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEbookMutation, { data, loading, error }] = useCreateEbookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEbookMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateEbookMutation, types.CreateEbookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateEbookMutation, types.CreateEbookMutationVariables>(CreateEbookDocument, options);
      }
export type CreateEbookMutationHookResult = ReturnType<typeof useCreateEbookMutation>;
export type CreateEbookMutationResult = Apollo.MutationResult<types.CreateEbookMutation>;
export type CreateEbookMutationOptions = Apollo.BaseMutationOptions<types.CreateEbookMutation, types.CreateEbookMutationVariables>;
export const UpsertEbookAndAudioDocument = gql`
    mutation UpsertEbookAndAudio($ebookId: ID!, $language: String!, $chaptersInput: UpsertEbookChaptersInput, $audioInput: EbookAudioInput) {
  upsertEbookChaptersAndAudio(
    ebookId: $ebookId
    language: $language
    chaptersInput: $chaptersInput
    audioInput: $audioInput
  ) {
    ... on Ebook {
      ...Ebook
    }
    ... on ValidationError {
      fieldErrors {
        path
        message
      }
    }
    ... on NotFoundError {
      message
    }
  }
}
    ${EbookFragmentDoc}`;
export type UpsertEbookAndAudioMutationFn = Apollo.MutationFunction<types.UpsertEbookAndAudioMutation, types.UpsertEbookAndAudioMutationVariables>;

/**
 * __useUpsertEbookAndAudioMutation__
 *
 * To run a mutation, you first call `useUpsertEbookAndAudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertEbookAndAudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertEbookAndAudioMutation, { data, loading, error }] = useUpsertEbookAndAudioMutation({
 *   variables: {
 *      ebookId: // value for 'ebookId'
 *      language: // value for 'language'
 *      chaptersInput: // value for 'chaptersInput'
 *      audioInput: // value for 'audioInput'
 *   },
 * });
 */
export function useUpsertEbookAndAudioMutation(baseOptions?: Apollo.MutationHookOptions<types.UpsertEbookAndAudioMutation, types.UpsertEbookAndAudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpsertEbookAndAudioMutation, types.UpsertEbookAndAudioMutationVariables>(UpsertEbookAndAudioDocument, options);
      }
export type UpsertEbookAndAudioMutationHookResult = ReturnType<typeof useUpsertEbookAndAudioMutation>;
export type UpsertEbookAndAudioMutationResult = Apollo.MutationResult<types.UpsertEbookAndAudioMutation>;
export type UpsertEbookAndAudioMutationOptions = Apollo.BaseMutationOptions<types.UpsertEbookAndAudioMutation, types.UpsertEbookAndAudioMutationVariables>;
export const UpdateEbookDocument = gql`
    mutation UpdateEbook($id: String!, $input: EbookInput!) {
  updateEbook(id: $id, input: $input) {
    ... on Ebook {
      ...Ebook
    }
    ... on NotFoundError {
      message
    }
    ... on ValidationError {
      fieldErrors {
        message
        path
      }
    }
  }
}
    ${EbookFragmentDoc}`;
export type UpdateEbookMutationFn = Apollo.MutationFunction<types.UpdateEbookMutation, types.UpdateEbookMutationVariables>;

/**
 * __useUpdateEbookMutation__
 *
 * To run a mutation, you first call `useUpdateEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEbookMutation, { data, loading, error }] = useUpdateEbookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEbookMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateEbookMutation, types.UpdateEbookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateEbookMutation, types.UpdateEbookMutationVariables>(UpdateEbookDocument, options);
      }
export type UpdateEbookMutationHookResult = ReturnType<typeof useUpdateEbookMutation>;
export type UpdateEbookMutationResult = Apollo.MutationResult<types.UpdateEbookMutation>;
export type UpdateEbookMutationOptions = Apollo.BaseMutationOptions<types.UpdateEbookMutation, types.UpdateEbookMutationVariables>;
export const DeleteEbookDocument = gql`
    mutation DeleteEbook($id: String!) {
  deleteEbook(id: $id)
}
    `;
export type DeleteEbookMutationFn = Apollo.MutationFunction<types.DeleteEbookMutation, types.DeleteEbookMutationVariables>;

/**
 * __useDeleteEbookMutation__
 *
 * To run a mutation, you first call `useDeleteEbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEbookMutation, { data, loading, error }] = useDeleteEbookMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEbookMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteEbookMutation, types.DeleteEbookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteEbookMutation, types.DeleteEbookMutationVariables>(DeleteEbookDocument, options);
      }
export type DeleteEbookMutationHookResult = ReturnType<typeof useDeleteEbookMutation>;
export type DeleteEbookMutationResult = Apollo.MutationResult<types.DeleteEbookMutation>;
export type DeleteEbookMutationOptions = Apollo.BaseMutationOptions<types.DeleteEbookMutation, types.DeleteEbookMutationVariables>;
export const FetchExpertsDocument = gql`
    query fetchExperts($filter: ExpertFilter, $sorting: SortOrdering) {
  experts(filter: $filter, sorting: $sorting) {
    meta {
      total
    }
    edges {
      ...expertItem
    }
  }
}
    ${ExpertItemFragmentDoc}`;

/**
 * __useFetchExpertsQuery__
 *
 * To run a query within a React component, call `useFetchExpertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchExpertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchExpertsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useFetchExpertsQuery(baseOptions?: Apollo.QueryHookOptions<types.FetchExpertsQuery, types.FetchExpertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.FetchExpertsQuery, types.FetchExpertsQueryVariables>(FetchExpertsDocument, options);
      }
export function useFetchExpertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.FetchExpertsQuery, types.FetchExpertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.FetchExpertsQuery, types.FetchExpertsQueryVariables>(FetchExpertsDocument, options);
        }
export type FetchExpertsQueryHookResult = ReturnType<typeof useFetchExpertsQuery>;
export type FetchExpertsLazyQueryHookResult = ReturnType<typeof useFetchExpertsLazyQuery>;
export type FetchExpertsQueryResult = Apollo.QueryResult<types.FetchExpertsQuery, types.FetchExpertsQueryVariables>;
export const FetchExpertDocument = gql`
    query fetchExpert($id: String!) {
  expert(id: $id) {
    ...expertItem
  }
}
    ${ExpertItemFragmentDoc}`;

/**
 * __useFetchExpertQuery__
 *
 * To run a query within a React component, call `useFetchExpertQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchExpertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchExpertQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchExpertQuery(baseOptions: Apollo.QueryHookOptions<types.FetchExpertQuery, types.FetchExpertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.FetchExpertQuery, types.FetchExpertQueryVariables>(FetchExpertDocument, options);
      }
export function useFetchExpertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.FetchExpertQuery, types.FetchExpertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.FetchExpertQuery, types.FetchExpertQueryVariables>(FetchExpertDocument, options);
        }
export type FetchExpertQueryHookResult = ReturnType<typeof useFetchExpertQuery>;
export type FetchExpertLazyQueryHookResult = ReturnType<typeof useFetchExpertLazyQuery>;
export type FetchExpertQueryResult = Apollo.QueryResult<types.FetchExpertQuery, types.FetchExpertQueryVariables>;
export const RequestMentorShipDocument = gql`
    mutation requestMentorShip($expertId: String!) {
  requestMentorship(expertId: $expertId) {
    success
  }
}
    `;
export type RequestMentorShipMutationFn = Apollo.MutationFunction<types.RequestMentorShipMutation, types.RequestMentorShipMutationVariables>;

/**
 * __useRequestMentorShipMutation__
 *
 * To run a mutation, you first call `useRequestMentorShipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMentorShipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMentorShipMutation, { data, loading, error }] = useRequestMentorShipMutation({
 *   variables: {
 *      expertId: // value for 'expertId'
 *   },
 * });
 */
export function useRequestMentorShipMutation(baseOptions?: Apollo.MutationHookOptions<types.RequestMentorShipMutation, types.RequestMentorShipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.RequestMentorShipMutation, types.RequestMentorShipMutationVariables>(RequestMentorShipDocument, options);
      }
export type RequestMentorShipMutationHookResult = ReturnType<typeof useRequestMentorShipMutation>;
export type RequestMentorShipMutationResult = Apollo.MutationResult<types.RequestMentorShipMutation>;
export type RequestMentorShipMutationOptions = Apollo.BaseMutationOptions<types.RequestMentorShipMutation, types.RequestMentorShipMutationVariables>;
export const ExportMainStatisticsDocument = gql`
    mutation ExportMainStatistics($orgId: ID!, $filter: OrgStatisticsFilter!) {
  organisationExportMainStatistics(orgId: $orgId, filter: $filter) {
    success
  }
}
    `;
export type ExportMainStatisticsMutationFn = Apollo.MutationFunction<types.ExportMainStatisticsMutation, types.ExportMainStatisticsMutationVariables>;

/**
 * __useExportMainStatisticsMutation__
 *
 * To run a mutation, you first call `useExportMainStatisticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportMainStatisticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportMainStatisticsMutation, { data, loading, error }] = useExportMainStatisticsMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportMainStatisticsMutation(baseOptions?: Apollo.MutationHookOptions<types.ExportMainStatisticsMutation, types.ExportMainStatisticsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ExportMainStatisticsMutation, types.ExportMainStatisticsMutationVariables>(ExportMainStatisticsDocument, options);
      }
export type ExportMainStatisticsMutationHookResult = ReturnType<typeof useExportMainStatisticsMutation>;
export type ExportMainStatisticsMutationResult = Apollo.MutationResult<types.ExportMainStatisticsMutation>;
export type ExportMainStatisticsMutationOptions = Apollo.BaseMutationOptions<types.ExportMainStatisticsMutation, types.ExportMainStatisticsMutationVariables>;
export const ExportUserAssignmentStatisticsDocument = gql`
    mutation ExportUserAssignmentStatistics($orgId: ID!, $userId: ID!) {
  organisationExportUserAssignmentStatistics(orgId: $orgId, userId: $userId) {
    success
  }
}
    `;
export type ExportUserAssignmentStatisticsMutationFn = Apollo.MutationFunction<types.ExportUserAssignmentStatisticsMutation, types.ExportUserAssignmentStatisticsMutationVariables>;

/**
 * __useExportUserAssignmentStatisticsMutation__
 *
 * To run a mutation, you first call `useExportUserAssignmentStatisticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUserAssignmentStatisticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUserAssignmentStatisticsMutation, { data, loading, error }] = useExportUserAssignmentStatisticsMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useExportUserAssignmentStatisticsMutation(baseOptions?: Apollo.MutationHookOptions<types.ExportUserAssignmentStatisticsMutation, types.ExportUserAssignmentStatisticsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ExportUserAssignmentStatisticsMutation, types.ExportUserAssignmentStatisticsMutationVariables>(ExportUserAssignmentStatisticsDocument, options);
      }
export type ExportUserAssignmentStatisticsMutationHookResult = ReturnType<typeof useExportUserAssignmentStatisticsMutation>;
export type ExportUserAssignmentStatisticsMutationResult = Apollo.MutationResult<types.ExportUserAssignmentStatisticsMutation>;
export type ExportUserAssignmentStatisticsMutationOptions = Apollo.BaseMutationOptions<types.ExportUserAssignmentStatisticsMutation, types.ExportUserAssignmentStatisticsMutationVariables>;
export const GetOrganisationByIdDocument = gql`
    query getOrganisationById($id: String!) {
  organisation(id: $id) {
    ...organisation
    subscription {
      id
      billingPeriod
      currentPeriodEnd
      cancelAtPeriodEnd
      paidAt
      customerId
      status
      trialUntil
      createdAt
      updatedAt
    }
    groups {
      id
      name
    }
    members {
      meta {
        total
      }
    }
  }
}
    ${OrganisationFragmentDoc}`;

/**
 * __useGetOrganisationByIdQuery__
 *
 * To run a query within a React component, call `useGetOrganisationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganisationByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganisationByIdQuery, types.GetOrganisationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganisationByIdQuery, types.GetOrganisationByIdQueryVariables>(GetOrganisationByIdDocument, options);
      }
export function useGetOrganisationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganisationByIdQuery, types.GetOrganisationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganisationByIdQuery, types.GetOrganisationByIdQueryVariables>(GetOrganisationByIdDocument, options);
        }
export type GetOrganisationByIdQueryHookResult = ReturnType<typeof useGetOrganisationByIdQuery>;
export type GetOrganisationByIdLazyQueryHookResult = ReturnType<typeof useGetOrganisationByIdLazyQuery>;
export type GetOrganisationByIdQueryResult = Apollo.QueryResult<types.GetOrganisationByIdQuery, types.GetOrganisationByIdQueryVariables>;
export const GetOrganisationGroupsDocument = gql`
    query getOrganisationGroups($orgId: String!) {
  organisation(id: $orgId) {
    id
    groups {
      id
      name
      numberOfMembers
      assignments {
        id
        assignmentTitle
      }
    }
  }
}
    `;

/**
 * __useGetOrganisationGroupsQuery__
 *
 * To run a query within a React component, call `useGetOrganisationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationGroupsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetOrganisationGroupsQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganisationGroupsQuery, types.GetOrganisationGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganisationGroupsQuery, types.GetOrganisationGroupsQueryVariables>(GetOrganisationGroupsDocument, options);
      }
export function useGetOrganisationGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganisationGroupsQuery, types.GetOrganisationGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganisationGroupsQuery, types.GetOrganisationGroupsQueryVariables>(GetOrganisationGroupsDocument, options);
        }
export type GetOrganisationGroupsQueryHookResult = ReturnType<typeof useGetOrganisationGroupsQuery>;
export type GetOrganisationGroupsLazyQueryHookResult = ReturnType<typeof useGetOrganisationGroupsLazyQuery>;
export type GetOrganisationGroupsQueryResult = Apollo.QueryResult<types.GetOrganisationGroupsQuery, types.GetOrganisationGroupsQueryVariables>;
export const GetOrganisationMembersDocument = gql`
    query getOrganisationMembers($id: String!, $filter: OrganisationMemberFilter, $sorting: SortOrdering) {
  organisation(id: $id) {
    __typename
    id
    members(filter: $filter, sorting: $sorting) {
      edges {
        id
        firstName
        lastName
        email
        roles
        status
        lastOnlineAt
        archivedAt
        statsContentConsumedTotal
        subscriptionStatus
        organisationGroups {
          id
          name
        }
      }
      meta {
        total
        offset
        limit
      }
    }
  }
}
    `;

/**
 * __useGetOrganisationMembersQuery__
 *
 * To run a query within a React component, call `useGetOrganisationMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetOrganisationMembersQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganisationMembersQuery, types.GetOrganisationMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganisationMembersQuery, types.GetOrganisationMembersQueryVariables>(GetOrganisationMembersDocument, options);
      }
export function useGetOrganisationMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganisationMembersQuery, types.GetOrganisationMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganisationMembersQuery, types.GetOrganisationMembersQueryVariables>(GetOrganisationMembersDocument, options);
        }
export type GetOrganisationMembersQueryHookResult = ReturnType<typeof useGetOrganisationMembersQuery>;
export type GetOrganisationMembersLazyQueryHookResult = ReturnType<typeof useGetOrganisationMembersLazyQuery>;
export type GetOrganisationMembersQueryResult = Apollo.QueryResult<types.GetOrganisationMembersQuery, types.GetOrganisationMembersQueryVariables>;
export const GetOrganisationGroupsWithFilterDocument = gql`
    query GetOrganisationGroupsWithFilter($id: String!, $filter: OrganisationGroupFilter!, $sorting: SortOrdering) {
  organisation(id: $id) {
    __typename
    id
    orgGroups(filter: $filter, sorting: $sorting) {
      edges {
        ...FullGroup
      }
      meta {
        total
        offset
        limit
      }
    }
  }
}
    ${FullGroupFragmentDoc}`;

/**
 * __useGetOrganisationGroupsWithFilterQuery__
 *
 * To run a query within a React component, call `useGetOrganisationGroupsWithFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationGroupsWithFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationGroupsWithFilterQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetOrganisationGroupsWithFilterQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganisationGroupsWithFilterQuery, types.GetOrganisationGroupsWithFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganisationGroupsWithFilterQuery, types.GetOrganisationGroupsWithFilterQueryVariables>(GetOrganisationGroupsWithFilterDocument, options);
      }
export function useGetOrganisationGroupsWithFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganisationGroupsWithFilterQuery, types.GetOrganisationGroupsWithFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganisationGroupsWithFilterQuery, types.GetOrganisationGroupsWithFilterQueryVariables>(GetOrganisationGroupsWithFilterDocument, options);
        }
export type GetOrganisationGroupsWithFilterQueryHookResult = ReturnType<typeof useGetOrganisationGroupsWithFilterQuery>;
export type GetOrganisationGroupsWithFilterLazyQueryHookResult = ReturnType<typeof useGetOrganisationGroupsWithFilterLazyQuery>;
export type GetOrganisationGroupsWithFilterQueryResult = Apollo.QueryResult<types.GetOrganisationGroupsWithFilterQuery, types.GetOrganisationGroupsWithFilterQueryVariables>;
export const GetAllOrganisationsDocument = gql`
    query getAllOrganisations($filter: OrganisationFilter, $sorting: SortOrdering) {
  organisations(filter: $filter, sorting: $sorting) {
    meta {
      total
    }
    edges {
      id
      name
      type
    }
  }
}
    `;

/**
 * __useGetAllOrganisationsQuery__
 *
 * To run a query within a React component, call `useGetAllOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrganisationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetAllOrganisationsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetAllOrganisationsQuery, types.GetAllOrganisationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetAllOrganisationsQuery, types.GetAllOrganisationsQueryVariables>(GetAllOrganisationsDocument, options);
      }
export function useGetAllOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetAllOrganisationsQuery, types.GetAllOrganisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetAllOrganisationsQuery, types.GetAllOrganisationsQueryVariables>(GetAllOrganisationsDocument, options);
        }
export type GetAllOrganisationsQueryHookResult = ReturnType<typeof useGetAllOrganisationsQuery>;
export type GetAllOrganisationsLazyQueryHookResult = ReturnType<typeof useGetAllOrganisationsLazyQuery>;
export type GetAllOrganisationsQueryResult = Apollo.QueryResult<types.GetAllOrganisationsQuery, types.GetAllOrganisationsQueryVariables>;
export const OrganisationRemoveUserDocument = gql`
    mutation organisationRemoveUser($userId: String!, $organisationId: String!) {
  organisationRemoveUser(userId: $userId, organisationId: $organisationId) {
    success
  }
}
    `;
export type OrganisationRemoveUserMutationFn = Apollo.MutationFunction<types.OrganisationRemoveUserMutation, types.OrganisationRemoveUserMutationVariables>;

/**
 * __useOrganisationRemoveUserMutation__
 *
 * To run a mutation, you first call `useOrganisationRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationRemoveUserMutation, { data, loading, error }] = useOrganisationRemoveUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useOrganisationRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<types.OrganisationRemoveUserMutation, types.OrganisationRemoveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.OrganisationRemoveUserMutation, types.OrganisationRemoveUserMutationVariables>(OrganisationRemoveUserDocument, options);
      }
export type OrganisationRemoveUserMutationHookResult = ReturnType<typeof useOrganisationRemoveUserMutation>;
export type OrganisationRemoveUserMutationResult = Apollo.MutationResult<types.OrganisationRemoveUserMutation>;
export type OrganisationRemoveUserMutationOptions = Apollo.BaseMutationOptions<types.OrganisationRemoveUserMutation, types.OrganisationRemoveUserMutationVariables>;
export const OrganisationAddUserDocument = gql`
    mutation organisationAddUser($userId: String!, $organisationId: String!, $role: OrganisationRole!) {
  organisationAddUser(
    userId: $userId
    organisationId: $organisationId
    role: $role
  ) {
    success
  }
}
    `;
export type OrganisationAddUserMutationFn = Apollo.MutationFunction<types.OrganisationAddUserMutation, types.OrganisationAddUserMutationVariables>;

/**
 * __useOrganisationAddUserMutation__
 *
 * To run a mutation, you first call `useOrganisationAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationAddUserMutation, { data, loading, error }] = useOrganisationAddUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organisationId: // value for 'organisationId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useOrganisationAddUserMutation(baseOptions?: Apollo.MutationHookOptions<types.OrganisationAddUserMutation, types.OrganisationAddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.OrganisationAddUserMutation, types.OrganisationAddUserMutationVariables>(OrganisationAddUserDocument, options);
      }
export type OrganisationAddUserMutationHookResult = ReturnType<typeof useOrganisationAddUserMutation>;
export type OrganisationAddUserMutationResult = Apollo.MutationResult<types.OrganisationAddUserMutation>;
export type OrganisationAddUserMutationOptions = Apollo.BaseMutationOptions<types.OrganisationAddUserMutation, types.OrganisationAddUserMutationVariables>;
export const OrganisationAddUsersDocument = gql`
    mutation organisationAddUsers($organisationId: String!, $groupId: String, $users: [OrganisationUserImport!]!) {
  organisationImportUsers(
    orgId: $organisationId
    groupId: $groupId
    users: $users
  ) {
    firstName
    lastName
    email
    error
  }
}
    `;
export type OrganisationAddUsersMutationFn = Apollo.MutationFunction<types.OrganisationAddUsersMutation, types.OrganisationAddUsersMutationVariables>;

/**
 * __useOrganisationAddUsersMutation__
 *
 * To run a mutation, you first call `useOrganisationAddUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationAddUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationAddUsersMutation, { data, loading, error }] = useOrganisationAddUsersMutation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      groupId: // value for 'groupId'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useOrganisationAddUsersMutation(baseOptions?: Apollo.MutationHookOptions<types.OrganisationAddUsersMutation, types.OrganisationAddUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.OrganisationAddUsersMutation, types.OrganisationAddUsersMutationVariables>(OrganisationAddUsersDocument, options);
      }
export type OrganisationAddUsersMutationHookResult = ReturnType<typeof useOrganisationAddUsersMutation>;
export type OrganisationAddUsersMutationResult = Apollo.MutationResult<types.OrganisationAddUsersMutation>;
export type OrganisationAddUsersMutationOptions = Apollo.BaseMutationOptions<types.OrganisationAddUsersMutation, types.OrganisationAddUsersMutationVariables>;
export const OrganisationJoinWithInviteLinkDocument = gql`
    mutation organisationJoinWithInviteLink($token: ID!, $user: CreateUserInput) {
  organisationJoinViaInviteLink(token: $token, user: $user)
}
    `;
export type OrganisationJoinWithInviteLinkMutationFn = Apollo.MutationFunction<types.OrganisationJoinWithInviteLinkMutation, types.OrganisationJoinWithInviteLinkMutationVariables>;

/**
 * __useOrganisationJoinWithInviteLinkMutation__
 *
 * To run a mutation, you first call `useOrganisationJoinWithInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationJoinWithInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationJoinWithInviteLinkMutation, { data, loading, error }] = useOrganisationJoinWithInviteLinkMutation({
 *   variables: {
 *      token: // value for 'token'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useOrganisationJoinWithInviteLinkMutation(baseOptions?: Apollo.MutationHookOptions<types.OrganisationJoinWithInviteLinkMutation, types.OrganisationJoinWithInviteLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.OrganisationJoinWithInviteLinkMutation, types.OrganisationJoinWithInviteLinkMutationVariables>(OrganisationJoinWithInviteLinkDocument, options);
      }
export type OrganisationJoinWithInviteLinkMutationHookResult = ReturnType<typeof useOrganisationJoinWithInviteLinkMutation>;
export type OrganisationJoinWithInviteLinkMutationResult = Apollo.MutationResult<types.OrganisationJoinWithInviteLinkMutation>;
export type OrganisationJoinWithInviteLinkMutationOptions = Apollo.BaseMutationOptions<types.OrganisationJoinWithInviteLinkMutation, types.OrganisationJoinWithInviteLinkMutationVariables>;
export const ReauthorizeWithOrgDocument = gql`
    mutation reauthorizeWithOrg($orgId: ID!) {
  reauthorizeWithOrg(id: $orgId) {
    jwt
  }
}
    `;
export type ReauthorizeWithOrgMutationFn = Apollo.MutationFunction<types.ReauthorizeWithOrgMutation, types.ReauthorizeWithOrgMutationVariables>;

/**
 * __useReauthorizeWithOrgMutation__
 *
 * To run a mutation, you first call `useReauthorizeWithOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReauthorizeWithOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reauthorizeWithOrgMutation, { data, loading, error }] = useReauthorizeWithOrgMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useReauthorizeWithOrgMutation(baseOptions?: Apollo.MutationHookOptions<types.ReauthorizeWithOrgMutation, types.ReauthorizeWithOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ReauthorizeWithOrgMutation, types.ReauthorizeWithOrgMutationVariables>(ReauthorizeWithOrgDocument, options);
      }
export type ReauthorizeWithOrgMutationHookResult = ReturnType<typeof useReauthorizeWithOrgMutation>;
export type ReauthorizeWithOrgMutationResult = Apollo.MutationResult<types.ReauthorizeWithOrgMutation>;
export type ReauthorizeWithOrgMutationOptions = Apollo.BaseMutationOptions<types.ReauthorizeWithOrgMutation, types.ReauthorizeWithOrgMutationVariables>;
export const GetOrganisationGroupByIdDocument = gql`
    query getOrganisationGroupById($id: String!) {
  organisationGroup(id: $id) {
    id
    name
    members {
      __typename
      id
      firstName
      lastName
      email
      subscriptionStatus
    }
  }
}
    `;

/**
 * __useGetOrganisationGroupByIdQuery__
 *
 * To run a query within a React component, call `useGetOrganisationGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationGroupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganisationGroupByIdQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrganisationGroupByIdQuery, types.GetOrganisationGroupByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrganisationGroupByIdQuery, types.GetOrganisationGroupByIdQueryVariables>(GetOrganisationGroupByIdDocument, options);
      }
export function useGetOrganisationGroupByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrganisationGroupByIdQuery, types.GetOrganisationGroupByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrganisationGroupByIdQuery, types.GetOrganisationGroupByIdQueryVariables>(GetOrganisationGroupByIdDocument, options);
        }
export type GetOrganisationGroupByIdQueryHookResult = ReturnType<typeof useGetOrganisationGroupByIdQuery>;
export type GetOrganisationGroupByIdLazyQueryHookResult = ReturnType<typeof useGetOrganisationGroupByIdLazyQuery>;
export type GetOrganisationGroupByIdQueryResult = Apollo.QueryResult<types.GetOrganisationGroupByIdQuery, types.GetOrganisationGroupByIdQueryVariables>;
export const CreateOrganisationGroupDocument = gql`
    mutation createOrganisationGroup($orgId: String!, $input: CreateOrganisationGroupInput!) {
  createOrganisationGroup(orgId: $orgId, input: $input) {
    id
    name
  }
}
    `;
export type CreateOrganisationGroupMutationFn = Apollo.MutationFunction<types.CreateOrganisationGroupMutation, types.CreateOrganisationGroupMutationVariables>;

/**
 * __useCreateOrganisationGroupMutation__
 *
 * To run a mutation, you first call `useCreateOrganisationGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganisationGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganisationGroupMutation, { data, loading, error }] = useCreateOrganisationGroupMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganisationGroupMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateOrganisationGroupMutation, types.CreateOrganisationGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateOrganisationGroupMutation, types.CreateOrganisationGroupMutationVariables>(CreateOrganisationGroupDocument, options);
      }
export type CreateOrganisationGroupMutationHookResult = ReturnType<typeof useCreateOrganisationGroupMutation>;
export type CreateOrganisationGroupMutationResult = Apollo.MutationResult<types.CreateOrganisationGroupMutation>;
export type CreateOrganisationGroupMutationOptions = Apollo.BaseMutationOptions<types.CreateOrganisationGroupMutation, types.CreateOrganisationGroupMutationVariables>;
export const OrganisationGroupAddMultipleUserDocument = gql`
    mutation organisationGroupAddMultipleUser($userIds: [String!]!, $groupId: String!) {
  organisationGroupAddMultipleUser(userIds: $userIds, groupId: $groupId) {
    success
  }
}
    `;
export type OrganisationGroupAddMultipleUserMutationFn = Apollo.MutationFunction<types.OrganisationGroupAddMultipleUserMutation, types.OrganisationGroupAddMultipleUserMutationVariables>;

/**
 * __useOrganisationGroupAddMultipleUserMutation__
 *
 * To run a mutation, you first call `useOrganisationGroupAddMultipleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationGroupAddMultipleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationGroupAddMultipleUserMutation, { data, loading, error }] = useOrganisationGroupAddMultipleUserMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useOrganisationGroupAddMultipleUserMutation(baseOptions?: Apollo.MutationHookOptions<types.OrganisationGroupAddMultipleUserMutation, types.OrganisationGroupAddMultipleUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.OrganisationGroupAddMultipleUserMutation, types.OrganisationGroupAddMultipleUserMutationVariables>(OrganisationGroupAddMultipleUserDocument, options);
      }
export type OrganisationGroupAddMultipleUserMutationHookResult = ReturnType<typeof useOrganisationGroupAddMultipleUserMutation>;
export type OrganisationGroupAddMultipleUserMutationResult = Apollo.MutationResult<types.OrganisationGroupAddMultipleUserMutation>;
export type OrganisationGroupAddMultipleUserMutationOptions = Apollo.BaseMutationOptions<types.OrganisationGroupAddMultipleUserMutation, types.OrganisationGroupAddMultipleUserMutationVariables>;
export const OrganisationRemoveGroupDocument = gql`
    mutation organisationRemoveGroup($id: String!) {
  deleteOrganisationGroup(id: $id) {
    __typename
    ... on ConflictError {
      associationErrors {
        id
        message
        type
      }
    }
    ... on NotFoundError {
      message
    }
    ... on NoContent {
      success
    }
  }
}
    `;
export type OrganisationRemoveGroupMutationFn = Apollo.MutationFunction<types.OrganisationRemoveGroupMutation, types.OrganisationRemoveGroupMutationVariables>;

/**
 * __useOrganisationRemoveGroupMutation__
 *
 * To run a mutation, you first call `useOrganisationRemoveGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationRemoveGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationRemoveGroupMutation, { data, loading, error }] = useOrganisationRemoveGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganisationRemoveGroupMutation(baseOptions?: Apollo.MutationHookOptions<types.OrganisationRemoveGroupMutation, types.OrganisationRemoveGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.OrganisationRemoveGroupMutation, types.OrganisationRemoveGroupMutationVariables>(OrganisationRemoveGroupDocument, options);
      }
export type OrganisationRemoveGroupMutationHookResult = ReturnType<typeof useOrganisationRemoveGroupMutation>;
export type OrganisationRemoveGroupMutationResult = Apollo.MutationResult<types.OrganisationRemoveGroupMutation>;
export type OrganisationRemoveGroupMutationOptions = Apollo.BaseMutationOptions<types.OrganisationRemoveGroupMutation, types.OrganisationRemoveGroupMutationVariables>;
export const OrganisationGroupAddMultiUserDocument = gql`
    mutation organisationGroupAddMultiUser($users: [AddUserRequest!]!, $groupId: ID!) {
  organisationGroupAddMultiUser(users: $users, groupId: $groupId) {
    id
    email
    error
  }
}
    `;
export type OrganisationGroupAddMultiUserMutationFn = Apollo.MutationFunction<types.OrganisationGroupAddMultiUserMutation, types.OrganisationGroupAddMultiUserMutationVariables>;

/**
 * __useOrganisationGroupAddMultiUserMutation__
 *
 * To run a mutation, you first call `useOrganisationGroupAddMultiUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationGroupAddMultiUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationGroupAddMultiUserMutation, { data, loading, error }] = useOrganisationGroupAddMultiUserMutation({
 *   variables: {
 *      users: // value for 'users'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useOrganisationGroupAddMultiUserMutation(baseOptions?: Apollo.MutationHookOptions<types.OrganisationGroupAddMultiUserMutation, types.OrganisationGroupAddMultiUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.OrganisationGroupAddMultiUserMutation, types.OrganisationGroupAddMultiUserMutationVariables>(OrganisationGroupAddMultiUserDocument, options);
      }
export type OrganisationGroupAddMultiUserMutationHookResult = ReturnType<typeof useOrganisationGroupAddMultiUserMutation>;
export type OrganisationGroupAddMultiUserMutationResult = Apollo.MutationResult<types.OrganisationGroupAddMultiUserMutation>;
export type OrganisationGroupAddMultiUserMutationOptions = Apollo.BaseMutationOptions<types.OrganisationGroupAddMultiUserMutation, types.OrganisationGroupAddMultiUserMutationVariables>;
export const OrganisationGroupRemoveMultiUserDocument = gql`
    mutation organisationGroupRemoveMultiUser($users: [AddUserRequest!]!, $groupId: ID!) {
  organisationGroupRemoveMultiUser(users: $users, groupId: $groupId) {
    id
    email
    error
  }
}
    `;
export type OrganisationGroupRemoveMultiUserMutationFn = Apollo.MutationFunction<types.OrganisationGroupRemoveMultiUserMutation, types.OrganisationGroupRemoveMultiUserMutationVariables>;

/**
 * __useOrganisationGroupRemoveMultiUserMutation__
 *
 * To run a mutation, you first call `useOrganisationGroupRemoveMultiUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationGroupRemoveMultiUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationGroupRemoveMultiUserMutation, { data, loading, error }] = useOrganisationGroupRemoveMultiUserMutation({
 *   variables: {
 *      users: // value for 'users'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useOrganisationGroupRemoveMultiUserMutation(baseOptions?: Apollo.MutationHookOptions<types.OrganisationGroupRemoveMultiUserMutation, types.OrganisationGroupRemoveMultiUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.OrganisationGroupRemoveMultiUserMutation, types.OrganisationGroupRemoveMultiUserMutationVariables>(OrganisationGroupRemoveMultiUserDocument, options);
      }
export type OrganisationGroupRemoveMultiUserMutationHookResult = ReturnType<typeof useOrganisationGroupRemoveMultiUserMutation>;
export type OrganisationGroupRemoveMultiUserMutationResult = Apollo.MutationResult<types.OrganisationGroupRemoveMultiUserMutation>;
export type OrganisationGroupRemoveMultiUserMutationOptions = Apollo.BaseMutationOptions<types.OrganisationGroupRemoveMultiUserMutation, types.OrganisationGroupRemoveMultiUserMutationVariables>;
export const GetOrgActiveUserStatsDocument = gql`
    query getOrgActiveUserStats($orgId: String!, $filter: OrgStatisticsFilter!) {
  organisation(id: $orgId) {
    id
    stats: statisticsUserActivity(filter: $filter) {
      ...graphDataPoint
    }
  }
}
    ${GraphDataPointFragmentDoc}`;

/**
 * __useGetOrgActiveUserStatsQuery__
 *
 * To run a query within a React component, call `useGetOrgActiveUserStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgActiveUserStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgActiveUserStatsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOrgActiveUserStatsQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgActiveUserStatsQuery, types.GetOrgActiveUserStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgActiveUserStatsQuery, types.GetOrgActiveUserStatsQueryVariables>(GetOrgActiveUserStatsDocument, options);
      }
export function useGetOrgActiveUserStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgActiveUserStatsQuery, types.GetOrgActiveUserStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgActiveUserStatsQuery, types.GetOrgActiveUserStatsQueryVariables>(GetOrgActiveUserStatsDocument, options);
        }
export type GetOrgActiveUserStatsQueryHookResult = ReturnType<typeof useGetOrgActiveUserStatsQuery>;
export type GetOrgActiveUserStatsLazyQueryHookResult = ReturnType<typeof useGetOrgActiveUserStatsLazyQuery>;
export type GetOrgActiveUserStatsQueryResult = Apollo.QueryResult<types.GetOrgActiveUserStatsQuery, types.GetOrgActiveUserStatsQueryVariables>;
export const GetOrgAvgTimeSpentStatsDocument = gql`
    query getOrgAvgTimeSpentStats($orgId: String!, $filter: OrgStatisticsFilter!) {
  organisation(id: $orgId) {
    id
    stats: statisticsAverageTimeSpent(filter: $filter) {
      ...graphDataPoint
    }
  }
}
    ${GraphDataPointFragmentDoc}`;

/**
 * __useGetOrgAvgTimeSpentStatsQuery__
 *
 * To run a query within a React component, call `useGetOrgAvgTimeSpentStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgAvgTimeSpentStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgAvgTimeSpentStatsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOrgAvgTimeSpentStatsQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgAvgTimeSpentStatsQuery, types.GetOrgAvgTimeSpentStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgAvgTimeSpentStatsQuery, types.GetOrgAvgTimeSpentStatsQueryVariables>(GetOrgAvgTimeSpentStatsDocument, options);
      }
export function useGetOrgAvgTimeSpentStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgAvgTimeSpentStatsQuery, types.GetOrgAvgTimeSpentStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgAvgTimeSpentStatsQuery, types.GetOrgAvgTimeSpentStatsQueryVariables>(GetOrgAvgTimeSpentStatsDocument, options);
        }
export type GetOrgAvgTimeSpentStatsQueryHookResult = ReturnType<typeof useGetOrgAvgTimeSpentStatsQuery>;
export type GetOrgAvgTimeSpentStatsLazyQueryHookResult = ReturnType<typeof useGetOrgAvgTimeSpentStatsLazyQuery>;
export type GetOrgAvgTimeSpentStatsQueryResult = Apollo.QueryResult<types.GetOrgAvgTimeSpentStatsQuery, types.GetOrgAvgTimeSpentStatsQueryVariables>;
export const GetOrgAverageContentPiecesStartDocument = gql`
    query getOrgAverageContentPiecesStart($orgId: String!, $filter: OrgStatisticsFilter!) {
  organisation(id: $orgId) {
    id
    stats: statisticsAverageContentPiecesStart(filter: $filter) {
      ...graphDataPoint
    }
  }
}
    ${GraphDataPointFragmentDoc}`;

/**
 * __useGetOrgAverageContentPiecesStartQuery__
 *
 * To run a query within a React component, call `useGetOrgAverageContentPiecesStartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgAverageContentPiecesStartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgAverageContentPiecesStartQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOrgAverageContentPiecesStartQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgAverageContentPiecesStartQuery, types.GetOrgAverageContentPiecesStartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgAverageContentPiecesStartQuery, types.GetOrgAverageContentPiecesStartQueryVariables>(GetOrgAverageContentPiecesStartDocument, options);
      }
export function useGetOrgAverageContentPiecesStartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgAverageContentPiecesStartQuery, types.GetOrgAverageContentPiecesStartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgAverageContentPiecesStartQuery, types.GetOrgAverageContentPiecesStartQueryVariables>(GetOrgAverageContentPiecesStartDocument, options);
        }
export type GetOrgAverageContentPiecesStartQueryHookResult = ReturnType<typeof useGetOrgAverageContentPiecesStartQuery>;
export type GetOrgAverageContentPiecesStartLazyQueryHookResult = ReturnType<typeof useGetOrgAverageContentPiecesStartLazyQuery>;
export type GetOrgAverageContentPiecesStartQueryResult = Apollo.QueryResult<types.GetOrgAverageContentPiecesStartQuery, types.GetOrgAverageContentPiecesStartQueryVariables>;
export const GetOrgMostActiveUsersStatsDocument = gql`
    query getOrgMostActiveUsersStats($orgId: String!, $filter: OrgStatisticsFilter!) {
  organisation(id: $orgId) {
    __typename
    id
    stats: statisticsMostActiveUsers(filter: $filter) {
      startedContentPieces
      averageCompletionRate
      userId
      username
      timeSpentInSeconds
    }
  }
}
    `;

/**
 * __useGetOrgMostActiveUsersStatsQuery__
 *
 * To run a query within a React component, call `useGetOrgMostActiveUsersStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgMostActiveUsersStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgMostActiveUsersStatsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOrgMostActiveUsersStatsQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgMostActiveUsersStatsQuery, types.GetOrgMostActiveUsersStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgMostActiveUsersStatsQuery, types.GetOrgMostActiveUsersStatsQueryVariables>(GetOrgMostActiveUsersStatsDocument, options);
      }
export function useGetOrgMostActiveUsersStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgMostActiveUsersStatsQuery, types.GetOrgMostActiveUsersStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgMostActiveUsersStatsQuery, types.GetOrgMostActiveUsersStatsQueryVariables>(GetOrgMostActiveUsersStatsDocument, options);
        }
export type GetOrgMostActiveUsersStatsQueryHookResult = ReturnType<typeof useGetOrgMostActiveUsersStatsQuery>;
export type GetOrgMostActiveUsersStatsLazyQueryHookResult = ReturnType<typeof useGetOrgMostActiveUsersStatsLazyQuery>;
export type GetOrgMostActiveUsersStatsQueryResult = Apollo.QueryResult<types.GetOrgMostActiveUsersStatsQuery, types.GetOrgMostActiveUsersStatsQueryVariables>;
export const GetOrgMostPopularExpertsDocument = gql`
    query getOrgMostPopularExperts($orgId: String!, $filter: OrgStatisticsFilter!) {
  organisation(id: $orgId) {
    id
    stats: statisticsMostPopularExperts(filter: $filter) {
      ...pieDataPoint
    }
  }
}
    ${PieDataPointFragmentDoc}`;

/**
 * __useGetOrgMostPopularExpertsQuery__
 *
 * To run a query within a React component, call `useGetOrgMostPopularExpertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgMostPopularExpertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgMostPopularExpertsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOrgMostPopularExpertsQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgMostPopularExpertsQuery, types.GetOrgMostPopularExpertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgMostPopularExpertsQuery, types.GetOrgMostPopularExpertsQueryVariables>(GetOrgMostPopularExpertsDocument, options);
      }
export function useGetOrgMostPopularExpertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgMostPopularExpertsQuery, types.GetOrgMostPopularExpertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgMostPopularExpertsQuery, types.GetOrgMostPopularExpertsQueryVariables>(GetOrgMostPopularExpertsDocument, options);
        }
export type GetOrgMostPopularExpertsQueryHookResult = ReturnType<typeof useGetOrgMostPopularExpertsQuery>;
export type GetOrgMostPopularExpertsLazyQueryHookResult = ReturnType<typeof useGetOrgMostPopularExpertsLazyQuery>;
export type GetOrgMostPopularExpertsQueryResult = Apollo.QueryResult<types.GetOrgMostPopularExpertsQuery, types.GetOrgMostPopularExpertsQueryVariables>;
export const GetOrgMostPopularCategoriesDocument = gql`
    query getOrgMostPopularCategories($orgId: String!, $filter: OrgStatisticsFilter!) {
  organisation(id: $orgId) {
    id
    stats: statisticsMostPopularCategories(filter: $filter) {
      ...pieDataPoint
    }
  }
}
    ${PieDataPointFragmentDoc}`;

/**
 * __useGetOrgMostPopularCategoriesQuery__
 *
 * To run a query within a React component, call `useGetOrgMostPopularCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgMostPopularCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgMostPopularCategoriesQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOrgMostPopularCategoriesQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgMostPopularCategoriesQuery, types.GetOrgMostPopularCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgMostPopularCategoriesQuery, types.GetOrgMostPopularCategoriesQueryVariables>(GetOrgMostPopularCategoriesDocument, options);
      }
export function useGetOrgMostPopularCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgMostPopularCategoriesQuery, types.GetOrgMostPopularCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgMostPopularCategoriesQuery, types.GetOrgMostPopularCategoriesQueryVariables>(GetOrgMostPopularCategoriesDocument, options);
        }
export type GetOrgMostPopularCategoriesQueryHookResult = ReturnType<typeof useGetOrgMostPopularCategoriesQuery>;
export type GetOrgMostPopularCategoriesLazyQueryHookResult = ReturnType<typeof useGetOrgMostPopularCategoriesLazyQuery>;
export type GetOrgMostPopularCategoriesQueryResult = Apollo.QueryResult<types.GetOrgMostPopularCategoriesQuery, types.GetOrgMostPopularCategoriesQueryVariables>;
export const GetOrgMostPopularContentDocument = gql`
    query getOrgMostPopularContent($orgId: String!, $filter: OrgStatisticsFilter!) {
  organisation(id: $orgId) {
    id
    stats: statisticsMostPopularContent(filter: $filter) {
      contentId
      contentName
      timeSpentInSeconds
      averageCompletionRate
      numberOfUsers
    }
  }
}
    `;

/**
 * __useGetOrgMostPopularContentQuery__
 *
 * To run a query within a React component, call `useGetOrgMostPopularContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgMostPopularContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgMostPopularContentQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOrgMostPopularContentQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgMostPopularContentQuery, types.GetOrgMostPopularContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgMostPopularContentQuery, types.GetOrgMostPopularContentQueryVariables>(GetOrgMostPopularContentDocument, options);
      }
export function useGetOrgMostPopularContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgMostPopularContentQuery, types.GetOrgMostPopularContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgMostPopularContentQuery, types.GetOrgMostPopularContentQueryVariables>(GetOrgMostPopularContentDocument, options);
        }
export type GetOrgMostPopularContentQueryHookResult = ReturnType<typeof useGetOrgMostPopularContentQuery>;
export type GetOrgMostPopularContentLazyQueryHookResult = ReturnType<typeof useGetOrgMostPopularContentLazyQuery>;
export type GetOrgMostPopularContentQueryResult = Apollo.QueryResult<types.GetOrgMostPopularContentQuery, types.GetOrgMostPopularContentQueryVariables>;
export const GetOrgUserStatsDocument = gql`
    query getOrgUserStats($orgId: String!) {
  organisation(id: $orgId) {
    id
    memberAdoptionRate
    membersLoggedIn
    members {
      meta {
        total
      }
    }
  }
}
    `;

/**
 * __useGetOrgUserStatsQuery__
 *
 * To run a query within a React component, call `useGetOrgUserStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgUserStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgUserStatsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetOrgUserStatsQuery(baseOptions: Apollo.QueryHookOptions<types.GetOrgUserStatsQuery, types.GetOrgUserStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetOrgUserStatsQuery, types.GetOrgUserStatsQueryVariables>(GetOrgUserStatsDocument, options);
      }
export function useGetOrgUserStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetOrgUserStatsQuery, types.GetOrgUserStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetOrgUserStatsQuery, types.GetOrgUserStatsQueryVariables>(GetOrgUserStatsDocument, options);
        }
export type GetOrgUserStatsQueryHookResult = ReturnType<typeof useGetOrgUserStatsQuery>;
export type GetOrgUserStatsLazyQueryHookResult = ReturnType<typeof useGetOrgUserStatsLazyQuery>;
export type GetOrgUserStatsQueryResult = Apollo.QueryResult<types.GetOrgUserStatsQuery, types.GetOrgUserStatsQueryVariables>;
export const GetPendingInvitationsDocument = gql`
    query GetPendingInvitations($organisationId: String!, $field: InvitationFilter!, $sorting: SortOrdering) {
  organisation(id: $organisationId) {
    id
    invitations(field: $field, sorting: $sorting) {
      meta {
        total
        limit
        offset
      }
      edges {
        id
        user {
          id
          firstName
          lastName
          email
        }
        createdAt
        acceptedAt
        expiresAt
        expiredByAdmin
      }
    }
  }
}
    `;

/**
 * __useGetPendingInvitationsQuery__
 *
 * To run a query within a React component, call `useGetPendingInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingInvitationsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      field: // value for 'field'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetPendingInvitationsQuery(baseOptions: Apollo.QueryHookOptions<types.GetPendingInvitationsQuery, types.GetPendingInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPendingInvitationsQuery, types.GetPendingInvitationsQueryVariables>(GetPendingInvitationsDocument, options);
      }
export function useGetPendingInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPendingInvitationsQuery, types.GetPendingInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPendingInvitationsQuery, types.GetPendingInvitationsQueryVariables>(GetPendingInvitationsDocument, options);
        }
export type GetPendingInvitationsQueryHookResult = ReturnType<typeof useGetPendingInvitationsQuery>;
export type GetPendingInvitationsLazyQueryHookResult = ReturnType<typeof useGetPendingInvitationsLazyQuery>;
export type GetPendingInvitationsQueryResult = Apollo.QueryResult<types.GetPendingInvitationsQuery, types.GetPendingInvitationsQueryVariables>;
export const RemoveUserOrgInvitationDocument = gql`
    mutation RemoveUserOrgInvitation($ids: [ID!]!) {
  removeUserOrgInvitation(ids: $ids) {
    success
  }
}
    `;
export type RemoveUserOrgInvitationMutationFn = Apollo.MutationFunction<types.RemoveUserOrgInvitationMutation, types.RemoveUserOrgInvitationMutationVariables>;

/**
 * __useRemoveUserOrgInvitationMutation__
 *
 * To run a mutation, you first call `useRemoveUserOrgInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserOrgInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserOrgInvitationMutation, { data, loading, error }] = useRemoveUserOrgInvitationMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveUserOrgInvitationMutation(baseOptions?: Apollo.MutationHookOptions<types.RemoveUserOrgInvitationMutation, types.RemoveUserOrgInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.RemoveUserOrgInvitationMutation, types.RemoveUserOrgInvitationMutationVariables>(RemoveUserOrgInvitationDocument, options);
      }
export type RemoveUserOrgInvitationMutationHookResult = ReturnType<typeof useRemoveUserOrgInvitationMutation>;
export type RemoveUserOrgInvitationMutationResult = Apollo.MutationResult<types.RemoveUserOrgInvitationMutation>;
export type RemoveUserOrgInvitationMutationOptions = Apollo.BaseMutationOptions<types.RemoveUserOrgInvitationMutation, types.RemoveUserOrgInvitationMutationVariables>;
export const CreateUploadUrlDocument = gql`
    mutation createUploadUrl($name: String!, $contentType: String!) {
  getPreSignedUploadUrl(name: $name, contentType: $contentType) {
    url
    expiresIn
    headers
  }
}
    `;
export type CreateUploadUrlMutationFn = Apollo.MutationFunction<types.CreateUploadUrlMutation, types.CreateUploadUrlMutationVariables>;

/**
 * __useCreateUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadUrlMutation, { data, loading, error }] = useCreateUploadUrlMutation({
 *   variables: {
 *      name: // value for 'name'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useCreateUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateUploadUrlMutation, types.CreateUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateUploadUrlMutation, types.CreateUploadUrlMutationVariables>(CreateUploadUrlDocument, options);
      }
export type CreateUploadUrlMutationHookResult = ReturnType<typeof useCreateUploadUrlMutation>;
export type CreateUploadUrlMutationResult = Apollo.MutationResult<types.CreateUploadUrlMutation>;
export type CreateUploadUrlMutationOptions = Apollo.BaseMutationOptions<types.CreateUploadUrlMutation, types.CreateUploadUrlMutationVariables>;
export const CreateGalleryImageFromUrlDocument = gql`
    mutation createGalleryImageFromUrl($url: String!) {
  createGalleryImageFromPreSignedUrl(url: $url) {
    ...Image
  }
}
    ${ImageFragmentDoc}`;
export type CreateGalleryImageFromUrlMutationFn = Apollo.MutationFunction<types.CreateGalleryImageFromUrlMutation, types.CreateGalleryImageFromUrlMutationVariables>;

/**
 * __useCreateGalleryImageFromUrlMutation__
 *
 * To run a mutation, you first call `useCreateGalleryImageFromUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGalleryImageFromUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGalleryImageFromUrlMutation, { data, loading, error }] = useCreateGalleryImageFromUrlMutation({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useCreateGalleryImageFromUrlMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateGalleryImageFromUrlMutation, types.CreateGalleryImageFromUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateGalleryImageFromUrlMutation, types.CreateGalleryImageFromUrlMutationVariables>(CreateGalleryImageFromUrlDocument, options);
      }
export type CreateGalleryImageFromUrlMutationHookResult = ReturnType<typeof useCreateGalleryImageFromUrlMutation>;
export type CreateGalleryImageFromUrlMutationResult = Apollo.MutationResult<types.CreateGalleryImageFromUrlMutation>;
export type CreateGalleryImageFromUrlMutationOptions = Apollo.BaseMutationOptions<types.CreateGalleryImageFromUrlMutation, types.CreateGalleryImageFromUrlMutationVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  me {
    id
    firstName
    lastName
    avatarImage {
      ...Image
    }
    coverImage {
      ...Image
    }
    biography
    contentCompletedCount
    followerCount
    followingCount
    jobTitle
    isExpert
    isFollowing
    isMentor
    facebookLink
    twitterHandle
    instagramHandle
    linkedinLink
    websiteLink
    firstName
    lastName
    createdAt
    email
    mobileNumber
    blocked
    newsletter
    roles
    company
    expertOrg {
      id
    }
    organisation {
      id
      type
      name
    }
    couponRemainingCharges
    couponCode
    lifegoals {
      __typename
      id
      name
      categories {
        __typename
        id
        name
      }
      organisation {
        id
        name
      }
    }
    stats {
      __typename
      ebooksRead
      eventsAttended
      podcastsCompleted
      videosCompleted
      eventsBooked
    }
    organisations {
      __typename
      id
      name
      subscription {
        status
      }
    }
    organisationGroups {
      __typename
      id
      name
    }
    stripeCustomers
    creditCard {
      expMonth
      expYear
      funding
      last4
      brand
      tokenizationMethod
    }
    consumedTrialContent {
      __typename
      id
      contentId
      consumedAt
    }
    subscription {
      __typename
      id
      customerId
      subscriptionId
      status
      trialUntil
      currentPeriodEnd
      cancelAtPeriodEnd
      paidAt
      createdAt
      updatedAt
    }
    productTourTracking {
      state
      showAgainAfter
      startedAt
      stream {
        ...Stream
      }
    }
    subscriptionStatus
    createdAt
    updatedAt
  }
}
    ${ImageFragmentDoc}
${StreamFragmentDoc}`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<types.GetCurrentUserQuery, types.GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCurrentUserQuery, types.GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCurrentUserQuery, types.GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCurrentUserQuery, types.GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<types.GetCurrentUserQuery, types.GetCurrentUserQueryVariables>;
export const GetFreeTrialUserInfoDocument = gql`
    query GetFreeTrialUserInfo {
  me {
    __typename
    id
    monthlyConsumedContentCount
    freeTrialEndsAt
    organisations {
      id
      name
      subscription {
        status
      }
    }
    organisation {
      id
      type
      subscription {
        status
        trialUntil
        endsAt
      }
    }
    subscriptionStatus
    organisationGroups {
      id
      name
    }
  }
}
    `;

/**
 * __useGetFreeTrialUserInfoQuery__
 *
 * To run a query within a React component, call `useGetFreeTrialUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFreeTrialUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreeTrialUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFreeTrialUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<types.GetFreeTrialUserInfoQuery, types.GetFreeTrialUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetFreeTrialUserInfoQuery, types.GetFreeTrialUserInfoQueryVariables>(GetFreeTrialUserInfoDocument, options);
      }
export function useGetFreeTrialUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetFreeTrialUserInfoQuery, types.GetFreeTrialUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetFreeTrialUserInfoQuery, types.GetFreeTrialUserInfoQueryVariables>(GetFreeTrialUserInfoDocument, options);
        }
export type GetFreeTrialUserInfoQueryHookResult = ReturnType<typeof useGetFreeTrialUserInfoQuery>;
export type GetFreeTrialUserInfoLazyQueryHookResult = ReturnType<typeof useGetFreeTrialUserInfoLazyQuery>;
export type GetFreeTrialUserInfoQueryResult = Apollo.QueryResult<types.GetFreeTrialUserInfoQuery, types.GetFreeTrialUserInfoQueryVariables>;
export const GetCurrentUserOnboardingStatusDocument = gql`
    query GetCurrentUserOnboardingStatus {
  me {
    __typename
    id
    isOnboardingCompleted
  }
}
    `;

/**
 * __useGetCurrentUserOnboardingStatusQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserOnboardingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserOnboardingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserOnboardingStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserOnboardingStatusQuery(baseOptions?: Apollo.QueryHookOptions<types.GetCurrentUserOnboardingStatusQuery, types.GetCurrentUserOnboardingStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCurrentUserOnboardingStatusQuery, types.GetCurrentUserOnboardingStatusQueryVariables>(GetCurrentUserOnboardingStatusDocument, options);
      }
export function useGetCurrentUserOnboardingStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCurrentUserOnboardingStatusQuery, types.GetCurrentUserOnboardingStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCurrentUserOnboardingStatusQuery, types.GetCurrentUserOnboardingStatusQueryVariables>(GetCurrentUserOnboardingStatusDocument, options);
        }
export type GetCurrentUserOnboardingStatusQueryHookResult = ReturnType<typeof useGetCurrentUserOnboardingStatusQuery>;
export type GetCurrentUserOnboardingStatusLazyQueryHookResult = ReturnType<typeof useGetCurrentUserOnboardingStatusLazyQuery>;
export type GetCurrentUserOnboardingStatusQueryResult = Apollo.QueryResult<types.GetCurrentUserOnboardingStatusQuery, types.GetCurrentUserOnboardingStatusQueryVariables>;
export const GetCurrentUserSubscriptionStatusDocument = gql`
    query GetCurrentUserSubscriptionStatus {
  me {
    __typename
    id
    subscriptionStatus
  }
}
    `;

/**
 * __useGetCurrentUserSubscriptionStatusQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserSubscriptionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserSubscriptionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserSubscriptionStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserSubscriptionStatusQuery(baseOptions?: Apollo.QueryHookOptions<types.GetCurrentUserSubscriptionStatusQuery, types.GetCurrentUserSubscriptionStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCurrentUserSubscriptionStatusQuery, types.GetCurrentUserSubscriptionStatusQueryVariables>(GetCurrentUserSubscriptionStatusDocument, options);
      }
export function useGetCurrentUserSubscriptionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCurrentUserSubscriptionStatusQuery, types.GetCurrentUserSubscriptionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCurrentUserSubscriptionStatusQuery, types.GetCurrentUserSubscriptionStatusQueryVariables>(GetCurrentUserSubscriptionStatusDocument, options);
        }
export type GetCurrentUserSubscriptionStatusQueryHookResult = ReturnType<typeof useGetCurrentUserSubscriptionStatusQuery>;
export type GetCurrentUserSubscriptionStatusLazyQueryHookResult = ReturnType<typeof useGetCurrentUserSubscriptionStatusLazyQuery>;
export type GetCurrentUserSubscriptionStatusQueryResult = Apollo.QueryResult<types.GetCurrentUserSubscriptionStatusQuery, types.GetCurrentUserSubscriptionStatusQueryVariables>;
export const GetNewAssignmentsDocument = gql`
    query GetNewAssignments {
  me {
    __typename
    id
    assignments(filter: {limit: 1000, offset: 0, state: NEW}) {
      meta {
        total
      }
      edges {
        id
        assignmentTitle
      }
    }
  }
}
    `;

/**
 * __useGetNewAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetNewAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNewAssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetNewAssignmentsQuery, types.GetNewAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetNewAssignmentsQuery, types.GetNewAssignmentsQueryVariables>(GetNewAssignmentsDocument, options);
      }
export function useGetNewAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetNewAssignmentsQuery, types.GetNewAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetNewAssignmentsQuery, types.GetNewAssignmentsQueryVariables>(GetNewAssignmentsDocument, options);
        }
export type GetNewAssignmentsQueryHookResult = ReturnType<typeof useGetNewAssignmentsQuery>;
export type GetNewAssignmentsLazyQueryHookResult = ReturnType<typeof useGetNewAssignmentsLazyQuery>;
export type GetNewAssignmentsQueryResult = Apollo.QueryResult<types.GetNewAssignmentsQuery, types.GetNewAssignmentsQueryVariables>;
export const GetSuggestedUsersToInteractDocument = gql`
    query GetSuggestedUsersToInteract($name: String, $limit: Int!, $isMemberOfSameOrganisation: Boolean) {
  me {
    id
    firstName
    email
    suggestedUsersToInteractWith(
      filter: {limit: $limit, offset: 0, isMemberOfSameOrganisation: $isMemberOfSameOrganisation, name: $name}
    ) {
      edges {
        ...PublicUserInformation
      }
    }
  }
}
    ${PublicUserInformationFragmentDoc}`;

/**
 * __useGetSuggestedUsersToInteractQuery__
 *
 * To run a query within a React component, call `useGetSuggestedUsersToInteractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggestedUsersToInteractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggestedUsersToInteractQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *      isMemberOfSameOrganisation: // value for 'isMemberOfSameOrganisation'
 *   },
 * });
 */
export function useGetSuggestedUsersToInteractQuery(baseOptions: Apollo.QueryHookOptions<types.GetSuggestedUsersToInteractQuery, types.GetSuggestedUsersToInteractQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetSuggestedUsersToInteractQuery, types.GetSuggestedUsersToInteractQueryVariables>(GetSuggestedUsersToInteractDocument, options);
      }
export function useGetSuggestedUsersToInteractLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetSuggestedUsersToInteractQuery, types.GetSuggestedUsersToInteractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetSuggestedUsersToInteractQuery, types.GetSuggestedUsersToInteractQueryVariables>(GetSuggestedUsersToInteractDocument, options);
        }
export type GetSuggestedUsersToInteractQueryHookResult = ReturnType<typeof useGetSuggestedUsersToInteractQuery>;
export type GetSuggestedUsersToInteractLazyQueryHookResult = ReturnType<typeof useGetSuggestedUsersToInteractLazyQuery>;
export type GetSuggestedUsersToInteractQueryResult = Apollo.QueryResult<types.GetSuggestedUsersToInteractQuery, types.GetSuggestedUsersToInteractQueryVariables>;
export const GetCurrentUserAccessLevelDocument = gql`
    query GetCurrentUserAccessLevel {
  me {
    id
    permissions {
      isAllowed
      permission {
        identifier
        name
        id
        description
      }
    }
  }
}
    `;

/**
 * __useGetCurrentUserAccessLevelQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserAccessLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserAccessLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserAccessLevelQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserAccessLevelQuery(baseOptions?: Apollo.QueryHookOptions<types.GetCurrentUserAccessLevelQuery, types.GetCurrentUserAccessLevelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetCurrentUserAccessLevelQuery, types.GetCurrentUserAccessLevelQueryVariables>(GetCurrentUserAccessLevelDocument, options);
      }
export function useGetCurrentUserAccessLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetCurrentUserAccessLevelQuery, types.GetCurrentUserAccessLevelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetCurrentUserAccessLevelQuery, types.GetCurrentUserAccessLevelQueryVariables>(GetCurrentUserAccessLevelDocument, options);
        }
export type GetCurrentUserAccessLevelQueryHookResult = ReturnType<typeof useGetCurrentUserAccessLevelQuery>;
export type GetCurrentUserAccessLevelLazyQueryHookResult = ReturnType<typeof useGetCurrentUserAccessLevelLazyQuery>;
export type GetCurrentUserAccessLevelQueryResult = Apollo.QueryResult<types.GetCurrentUserAccessLevelQuery, types.GetCurrentUserAccessLevelQueryVariables>;
export const UserAcceptCommunityGuidelineDocument = gql`
    mutation UserAcceptCommunityGuideline {
  userAcceptCommunityGuideline
}
    `;
export type UserAcceptCommunityGuidelineMutationFn = Apollo.MutationFunction<types.UserAcceptCommunityGuidelineMutation, types.UserAcceptCommunityGuidelineMutationVariables>;

/**
 * __useUserAcceptCommunityGuidelineMutation__
 *
 * To run a mutation, you first call `useUserAcceptCommunityGuidelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserAcceptCommunityGuidelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userAcceptCommunityGuidelineMutation, { data, loading, error }] = useUserAcceptCommunityGuidelineMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserAcceptCommunityGuidelineMutation(baseOptions?: Apollo.MutationHookOptions<types.UserAcceptCommunityGuidelineMutation, types.UserAcceptCommunityGuidelineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UserAcceptCommunityGuidelineMutation, types.UserAcceptCommunityGuidelineMutationVariables>(UserAcceptCommunityGuidelineDocument, options);
      }
export type UserAcceptCommunityGuidelineMutationHookResult = ReturnType<typeof useUserAcceptCommunityGuidelineMutation>;
export type UserAcceptCommunityGuidelineMutationResult = Apollo.MutationResult<types.UserAcceptCommunityGuidelineMutation>;
export type UserAcceptCommunityGuidelineMutationOptions = Apollo.BaseMutationOptions<types.UserAcceptCommunityGuidelineMutation, types.UserAcceptCommunityGuidelineMutationVariables>;
export const GetAcceptedCommunityGuidelineDocument = gql`
    query GetAcceptedCommunityGuideline {
  me {
    id
    acceptedCommunityGuidelineAt
  }
}
    `;

/**
 * __useGetAcceptedCommunityGuidelineQuery__
 *
 * To run a query within a React component, call `useGetAcceptedCommunityGuidelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcceptedCommunityGuidelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcceptedCommunityGuidelineQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAcceptedCommunityGuidelineQuery(baseOptions?: Apollo.QueryHookOptions<types.GetAcceptedCommunityGuidelineQuery, types.GetAcceptedCommunityGuidelineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetAcceptedCommunityGuidelineQuery, types.GetAcceptedCommunityGuidelineQueryVariables>(GetAcceptedCommunityGuidelineDocument, options);
      }
export function useGetAcceptedCommunityGuidelineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetAcceptedCommunityGuidelineQuery, types.GetAcceptedCommunityGuidelineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetAcceptedCommunityGuidelineQuery, types.GetAcceptedCommunityGuidelineQueryVariables>(GetAcceptedCommunityGuidelineDocument, options);
        }
export type GetAcceptedCommunityGuidelineQueryHookResult = ReturnType<typeof useGetAcceptedCommunityGuidelineQuery>;
export type GetAcceptedCommunityGuidelineLazyQueryHookResult = ReturnType<typeof useGetAcceptedCommunityGuidelineLazyQuery>;
export type GetAcceptedCommunityGuidelineQueryResult = Apollo.QueryResult<types.GetAcceptedCommunityGuidelineQuery, types.GetAcceptedCommunityGuidelineQueryVariables>;
export const GetUserNewNotificationsCountDocument = gql`
    query getUserNewNotificationsCount($filter: NotificationFilter) {
  me {
    __typename
    id
    newNotifications(filter: $filter)
  }
}
    `;

/**
 * __useGetUserNewNotificationsCountQuery__
 *
 * To run a query within a React component, call `useGetUserNewNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNewNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNewNotificationsCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUserNewNotificationsCountQuery(baseOptions?: Apollo.QueryHookOptions<types.GetUserNewNotificationsCountQuery, types.GetUserNewNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserNewNotificationsCountQuery, types.GetUserNewNotificationsCountQueryVariables>(GetUserNewNotificationsCountDocument, options);
      }
export function useGetUserNewNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserNewNotificationsCountQuery, types.GetUserNewNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserNewNotificationsCountQuery, types.GetUserNewNotificationsCountQueryVariables>(GetUserNewNotificationsCountDocument, options);
        }
export type GetUserNewNotificationsCountQueryHookResult = ReturnType<typeof useGetUserNewNotificationsCountQuery>;
export type GetUserNewNotificationsCountLazyQueryHookResult = ReturnType<typeof useGetUserNewNotificationsCountLazyQuery>;
export type GetUserNewNotificationsCountQueryResult = Apollo.QueryResult<types.GetUserNewNotificationsCountQuery, types.GetUserNewNotificationsCountQueryVariables>;
export const GetUserNotificationsDocument = gql`
    query getUserNotifications($limit: Int = 10, $afterCursor: String, $filter: NotificationFilter) {
  me {
    __typename
    id
    notifications(
      pagination: {limit: $limit, afterCursor: $afterCursor}
      filter: $filter
    ) {
      meta {
        nextCursor
        hasNext
        prevCursor
        hasPrev
      }
      edges {
        cursor
        notification {
          id
          title
          body
          deepLink
          image {
            uri
            alt
            id
          }
          type
          state
          readAt
          createdAt
          updatedAt
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserNotificationsQuery__
 *
 * To run a query within a React component, call `useGetUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      afterCursor: // value for 'afterCursor'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUserNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetUserNotificationsQuery, types.GetUserNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserNotificationsQuery, types.GetUserNotificationsQueryVariables>(GetUserNotificationsDocument, options);
      }
export function useGetUserNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserNotificationsQuery, types.GetUserNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserNotificationsQuery, types.GetUserNotificationsQueryVariables>(GetUserNotificationsDocument, options);
        }
export type GetUserNotificationsQueryHookResult = ReturnType<typeof useGetUserNotificationsQuery>;
export type GetUserNotificationsLazyQueryHookResult = ReturnType<typeof useGetUserNotificationsLazyQuery>;
export type GetUserNotificationsQueryResult = Apollo.QueryResult<types.GetUserNotificationsQuery, types.GetUserNotificationsQueryVariables>;
export const UpdateNotificationAsReadDocument = gql`
    mutation updateNotificationAsRead($id: ID!) {
  markNotificationRead(id: $id) {
    id
    readAt
    state
  }
}
    `;
export type UpdateNotificationAsReadMutationFn = Apollo.MutationFunction<types.UpdateNotificationAsReadMutation, types.UpdateNotificationAsReadMutationVariables>;

/**
 * __useUpdateNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationAsReadMutation, { data, loading, error }] = useUpdateNotificationAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateNotificationAsReadMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateNotificationAsReadMutation, types.UpdateNotificationAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateNotificationAsReadMutation, types.UpdateNotificationAsReadMutationVariables>(UpdateNotificationAsReadDocument, options);
      }
export type UpdateNotificationAsReadMutationHookResult = ReturnType<typeof useUpdateNotificationAsReadMutation>;
export type UpdateNotificationAsReadMutationResult = Apollo.MutationResult<types.UpdateNotificationAsReadMutation>;
export type UpdateNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<types.UpdateNotificationAsReadMutation, types.UpdateNotificationAsReadMutationVariables>;
export const UpdateAllNotificationsAsSeenDocument = gql`
    mutation updateAllNotificationsAsSeen {
  markNotificationsOld {
    newNotifications
  }
}
    `;
export type UpdateAllNotificationsAsSeenMutationFn = Apollo.MutationFunction<types.UpdateAllNotificationsAsSeenMutation, types.UpdateAllNotificationsAsSeenMutationVariables>;

/**
 * __useUpdateAllNotificationsAsSeenMutation__
 *
 * To run a mutation, you first call `useUpdateAllNotificationsAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllNotificationsAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllNotificationsAsSeenMutation, { data, loading, error }] = useUpdateAllNotificationsAsSeenMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateAllNotificationsAsSeenMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateAllNotificationsAsSeenMutation, types.UpdateAllNotificationsAsSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateAllNotificationsAsSeenMutation, types.UpdateAllNotificationsAsSeenMutationVariables>(UpdateAllNotificationsAsSeenDocument, options);
      }
export type UpdateAllNotificationsAsSeenMutationHookResult = ReturnType<typeof useUpdateAllNotificationsAsSeenMutation>;
export type UpdateAllNotificationsAsSeenMutationResult = Apollo.MutationResult<types.UpdateAllNotificationsAsSeenMutation>;
export type UpdateAllNotificationsAsSeenMutationOptions = Apollo.BaseMutationOptions<types.UpdateAllNotificationsAsSeenMutation, types.UpdateAllNotificationsAsSeenMutationVariables>;
export const UpdateAllNotificationsAsReadDocument = gql`
    mutation updateAllNotificationsAsRead($filter: NotificationFilter) {
  markAllNotificationRead(filter: $filter) {
    edges {
      notification {
        id
        title
        state
      }
    }
  }
}
    `;
export type UpdateAllNotificationsAsReadMutationFn = Apollo.MutationFunction<types.UpdateAllNotificationsAsReadMutation, types.UpdateAllNotificationsAsReadMutationVariables>;

/**
 * __useUpdateAllNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useUpdateAllNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllNotificationsAsReadMutation, { data, loading, error }] = useUpdateAllNotificationsAsReadMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdateAllNotificationsAsReadMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateAllNotificationsAsReadMutation, types.UpdateAllNotificationsAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateAllNotificationsAsReadMutation, types.UpdateAllNotificationsAsReadMutationVariables>(UpdateAllNotificationsAsReadDocument, options);
      }
export type UpdateAllNotificationsAsReadMutationHookResult = ReturnType<typeof useUpdateAllNotificationsAsReadMutation>;
export type UpdateAllNotificationsAsReadMutationResult = Apollo.MutationResult<types.UpdateAllNotificationsAsReadMutation>;
export type UpdateAllNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<types.UpdateAllNotificationsAsReadMutation, types.UpdateAllNotificationsAsReadMutationVariables>;
export const DeleteNotificationDocument = gql`
    mutation deleteNotification($id: String!) {
  deleteNotification(id: $id) {
    success
  }
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<types.DeleteNotificationMutation, types.DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteNotificationMutation, types.DeleteNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteNotificationMutation, types.DeleteNotificationMutationVariables>(DeleteNotificationDocument, options);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<types.DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<types.DeleteNotificationMutation, types.DeleteNotificationMutationVariables>;
export const GetUsersOrganisationsDocument = gql`
    query getUsersOrganisations {
  user: me {
    __typename
    id
    email
    roles
    organisations {
      id
      name
      type
      invitationToken
      subscription {
        id
        seats
        billingPeriod
        trialUntil
      }
      groups {
        id
        name
      }
      members {
        meta {
          total
        }
      }
    }
  }
}
    `;

/**
 * __useGetUsersOrganisationsQuery__
 *
 * To run a query within a React component, call `useGetUsersOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersOrganisationsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetUsersOrganisationsQuery, types.GetUsersOrganisationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUsersOrganisationsQuery, types.GetUsersOrganisationsQueryVariables>(GetUsersOrganisationsDocument, options);
      }
export function useGetUsersOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUsersOrganisationsQuery, types.GetUsersOrganisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUsersOrganisationsQuery, types.GetUsersOrganisationsQueryVariables>(GetUsersOrganisationsDocument, options);
        }
export type GetUsersOrganisationsQueryHookResult = ReturnType<typeof useGetUsersOrganisationsQuery>;
export type GetUsersOrganisationsLazyQueryHookResult = ReturnType<typeof useGetUsersOrganisationsLazyQuery>;
export type GetUsersOrganisationsQueryResult = Apollo.QueryResult<types.GetUsersOrganisationsQuery, types.GetUsersOrganisationsQueryVariables>;
export const UpdateUserAsOrgAdminDocument = gql`
    mutation updateUserAsOrgAdmin($id: ID!, $input: UpdateUserAsOrgAdmin!) {
  updateUserAsOrgAdmin(id: $id, input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserAsOrgAdminMutationFn = Apollo.MutationFunction<types.UpdateUserAsOrgAdminMutation, types.UpdateUserAsOrgAdminMutationVariables>;

/**
 * __useUpdateUserAsOrgAdminMutation__
 *
 * To run a mutation, you first call `useUpdateUserAsOrgAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAsOrgAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAsOrgAdminMutation, { data, loading, error }] = useUpdateUserAsOrgAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAsOrgAdminMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateUserAsOrgAdminMutation, types.UpdateUserAsOrgAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateUserAsOrgAdminMutation, types.UpdateUserAsOrgAdminMutationVariables>(UpdateUserAsOrgAdminDocument, options);
      }
export type UpdateUserAsOrgAdminMutationHookResult = ReturnType<typeof useUpdateUserAsOrgAdminMutation>;
export type UpdateUserAsOrgAdminMutationResult = Apollo.MutationResult<types.UpdateUserAsOrgAdminMutation>;
export type UpdateUserAsOrgAdminMutationOptions = Apollo.BaseMutationOptions<types.UpdateUserAsOrgAdminMutation, types.UpdateUserAsOrgAdminMutationVariables>;
export const GetPublicUserInformationDocument = gql`
    query getPublicUserInformation($id: String!) {
  user(id: $id) {
    ...PublicUserInformation
  }
}
    ${PublicUserInformationFragmentDoc}`;

/**
 * __useGetPublicUserInformationQuery__
 *
 * To run a query within a React component, call `useGetPublicUserInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicUserInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicUserInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPublicUserInformationQuery(baseOptions: Apollo.QueryHookOptions<types.GetPublicUserInformationQuery, types.GetPublicUserInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetPublicUserInformationQuery, types.GetPublicUserInformationQueryVariables>(GetPublicUserInformationDocument, options);
      }
export function useGetPublicUserInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetPublicUserInformationQuery, types.GetPublicUserInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetPublicUserInformationQuery, types.GetPublicUserInformationQueryVariables>(GetPublicUserInformationDocument, options);
        }
export type GetPublicUserInformationQueryHookResult = ReturnType<typeof useGetPublicUserInformationQuery>;
export type GetPublicUserInformationLazyQueryHookResult = ReturnType<typeof useGetPublicUserInformationLazyQuery>;
export type GetPublicUserInformationQueryResult = Apollo.QueryResult<types.GetPublicUserInformationQuery, types.GetPublicUserInformationQueryVariables>;
export const GetUserBasicInformationDocument = gql`
    query getUserBasicInformation($id: String!) {
  user(id: $id) {
    ... on User {
      __typename
      id
      firstName
      lastName
      company
      jobTitle
      isBlockedByCurrentUser
      isBlockingCurrentUser
      avatarImage {
        ...Image
      }
      coverImage {
        ...Image
      }
    }
  }
}
    ${ImageFragmentDoc}`;

/**
 * __useGetUserBasicInformationQuery__
 *
 * To run a query within a React component, call `useGetUserBasicInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBasicInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBasicInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserBasicInformationQuery(baseOptions: Apollo.QueryHookOptions<types.GetUserBasicInformationQuery, types.GetUserBasicInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetUserBasicInformationQuery, types.GetUserBasicInformationQueryVariables>(GetUserBasicInformationDocument, options);
      }
export function useGetUserBasicInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetUserBasicInformationQuery, types.GetUserBasicInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetUserBasicInformationQuery, types.GetUserBasicInformationQueryVariables>(GetUserBasicInformationDocument, options);
        }
export type GetUserBasicInformationQueryHookResult = ReturnType<typeof useGetUserBasicInformationQuery>;
export type GetUserBasicInformationLazyQueryHookResult = ReturnType<typeof useGetUserBasicInformationLazyQuery>;
export type GetUserBasicInformationQueryResult = Apollo.QueryResult<types.GetUserBasicInformationQuery, types.GetUserBasicInformationQueryVariables>;
export const GetFollowersDocument = gql`
    query getFollowers($id: String!, $filter: FollowersFollowingFilter) {
  user(id: $id) {
    __typename
    id
    firstName
    lastName
    followers(filter: $filter) {
      edges {
        ...LimitedUser
      }
      meta {
        total
      }
    }
  }
}
    ${LimitedUserFragmentDoc}`;

/**
 * __useGetFollowersQuery__
 *
 * To run a query within a React component, call `useGetFollowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFollowersQuery(baseOptions: Apollo.QueryHookOptions<types.GetFollowersQuery, types.GetFollowersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetFollowersQuery, types.GetFollowersQueryVariables>(GetFollowersDocument, options);
      }
export function useGetFollowersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetFollowersQuery, types.GetFollowersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetFollowersQuery, types.GetFollowersQueryVariables>(GetFollowersDocument, options);
        }
export type GetFollowersQueryHookResult = ReturnType<typeof useGetFollowersQuery>;
export type GetFollowersLazyQueryHookResult = ReturnType<typeof useGetFollowersLazyQuery>;
export type GetFollowersQueryResult = Apollo.QueryResult<types.GetFollowersQuery, types.GetFollowersQueryVariables>;
export const GetFollowingsDocument = gql`
    query getFollowings($id: String!, $filter: FollowersFollowingFilter) {
  user(id: $id) {
    __typename
    id
    firstName
    lastName
    followings(filter: $filter) {
      edges {
        ...LimitedUser
      }
      meta {
        total
      }
    }
  }
}
    ${LimitedUserFragmentDoc}`;

/**
 * __useGetFollowingsQuery__
 *
 * To run a query within a React component, call `useGetFollowingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetFollowingsQuery(baseOptions: Apollo.QueryHookOptions<types.GetFollowingsQuery, types.GetFollowingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetFollowingsQuery, types.GetFollowingsQueryVariables>(GetFollowingsDocument, options);
      }
export function useGetFollowingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetFollowingsQuery, types.GetFollowingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetFollowingsQuery, types.GetFollowingsQueryVariables>(GetFollowingsDocument, options);
        }
export type GetFollowingsQueryHookResult = ReturnType<typeof useGetFollowingsQuery>;
export type GetFollowingsLazyQueryHookResult = ReturnType<typeof useGetFollowingsLazyQuery>;
export type GetFollowingsQueryResult = Apollo.QueryResult<types.GetFollowingsQuery, types.GetFollowingsQueryVariables>;
export const GetMyFollowingsDocument = gql`
    query GetMyFollowings($filter: FollowersFollowingFilter) {
  me {
    __typename
    id
    firstName
    lastName
    followings(filter: $filter) {
      edges {
        ...LimitedUser
      }
      meta {
        total
      }
    }
  }
}
    ${LimitedUserFragmentDoc}`;

/**
 * __useGetMyFollowingsQuery__
 *
 * To run a query within a React component, call `useGetMyFollowingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyFollowingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyFollowingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetMyFollowingsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetMyFollowingsQuery, types.GetMyFollowingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetMyFollowingsQuery, types.GetMyFollowingsQueryVariables>(GetMyFollowingsDocument, options);
      }
export function useGetMyFollowingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetMyFollowingsQuery, types.GetMyFollowingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetMyFollowingsQuery, types.GetMyFollowingsQueryVariables>(GetMyFollowingsDocument, options);
        }
export type GetMyFollowingsQueryHookResult = ReturnType<typeof useGetMyFollowingsQuery>;
export type GetMyFollowingsLazyQueryHookResult = ReturnType<typeof useGetMyFollowingsLazyQuery>;
export type GetMyFollowingsQueryResult = Apollo.QueryResult<types.GetMyFollowingsQuery, types.GetMyFollowingsQueryVariables>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($id: String!, $input: UpdateUserInput) {
  updateUser(id: $id, input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<types.UpdateUserProfileMutation, types.UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateUserProfileMutation, types.UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateUserProfileMutation, types.UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<types.UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<types.UpdateUserProfileMutation, types.UpdateUserProfileMutationVariables>;
export const DeleteUserAccountDocument = gql`
    mutation deleteUserAccount($reasons: [String!]) {
  deleteUser(reasons: $reasons) {
    success
  }
}
    `;
export type DeleteUserAccountMutationFn = Apollo.MutationFunction<types.DeleteUserAccountMutation, types.DeleteUserAccountMutationVariables>;

/**
 * __useDeleteUserAccountMutation__
 *
 * To run a mutation, you first call `useDeleteUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAccountMutation, { data, loading, error }] = useDeleteUserAccountMutation({
 *   variables: {
 *      reasons: // value for 'reasons'
 *   },
 * });
 */
export function useDeleteUserAccountMutation(baseOptions?: Apollo.MutationHookOptions<types.DeleteUserAccountMutation, types.DeleteUserAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.DeleteUserAccountMutation, types.DeleteUserAccountMutationVariables>(DeleteUserAccountDocument, options);
      }
export type DeleteUserAccountMutationHookResult = ReturnType<typeof useDeleteUserAccountMutation>;
export type DeleteUserAccountMutationResult = Apollo.MutationResult<types.DeleteUserAccountMutation>;
export type DeleteUserAccountMutationOptions = Apollo.BaseMutationOptions<types.DeleteUserAccountMutation, types.DeleteUserAccountMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput) {
  createUser(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<types.CreateUserMutation, types.CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateUserMutation, types.CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateUserMutation, types.CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<types.CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<types.CreateUserMutation, types.CreateUserMutationVariables>;
export const CreateUserV2Document = gql`
    mutation CreateUserV2($input: CreateUserInput) {
  createUserV2(input: $input) {
    ... on ValidationError {
      fieldErrors {
        message
        path
      }
    }
    ... on User {
      id
      firstName
      lastName
    }
  }
}
    `;
export type CreateUserV2MutationFn = Apollo.MutationFunction<types.CreateUserV2Mutation, types.CreateUserV2MutationVariables>;

/**
 * __useCreateUserV2Mutation__
 *
 * To run a mutation, you first call `useCreateUserV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserV2Mutation, { data, loading, error }] = useCreateUserV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserV2Mutation(baseOptions?: Apollo.MutationHookOptions<types.CreateUserV2Mutation, types.CreateUserV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateUserV2Mutation, types.CreateUserV2MutationVariables>(CreateUserV2Document, options);
      }
export type CreateUserV2MutationHookResult = ReturnType<typeof useCreateUserV2Mutation>;
export type CreateUserV2MutationResult = Apollo.MutationResult<types.CreateUserV2Mutation>;
export type CreateUserV2MutationOptions = Apollo.BaseMutationOptions<types.CreateUserV2Mutation, types.CreateUserV2MutationVariables>;
export const CreateTeamsAccountDocument = gql`
    mutation createTeamsAccount($input: CreateTeamsInput) {
  createTeamsAccount(input: $input) {
    auth {
      jwt
    }
  }
}
    `;
export type CreateTeamsAccountMutationFn = Apollo.MutationFunction<types.CreateTeamsAccountMutation, types.CreateTeamsAccountMutationVariables>;

/**
 * __useCreateTeamsAccountMutation__
 *
 * To run a mutation, you first call `useCreateTeamsAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamsAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamsAccountMutation, { data, loading, error }] = useCreateTeamsAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamsAccountMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateTeamsAccountMutation, types.CreateTeamsAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateTeamsAccountMutation, types.CreateTeamsAccountMutationVariables>(CreateTeamsAccountDocument, options);
      }
export type CreateTeamsAccountMutationHookResult = ReturnType<typeof useCreateTeamsAccountMutation>;
export type CreateTeamsAccountMutationResult = Apollo.MutationResult<types.CreateTeamsAccountMutation>;
export type CreateTeamsAccountMutationOptions = Apollo.BaseMutationOptions<types.CreateTeamsAccountMutation, types.CreateTeamsAccountMutationVariables>;
export const SwitchUserToTeamsDocument = gql`
    mutation switchUserToTeams {
  switchUserToTeams {
    __typename
    auth {
      success
      jwt
    }
    user {
      firstName
      lastName
    }
  }
}
    `;
export type SwitchUserToTeamsMutationFn = Apollo.MutationFunction<types.SwitchUserToTeamsMutation, types.SwitchUserToTeamsMutationVariables>;

/**
 * __useSwitchUserToTeamsMutation__
 *
 * To run a mutation, you first call `useSwitchUserToTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchUserToTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchUserToTeamsMutation, { data, loading, error }] = useSwitchUserToTeamsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSwitchUserToTeamsMutation(baseOptions?: Apollo.MutationHookOptions<types.SwitchUserToTeamsMutation, types.SwitchUserToTeamsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.SwitchUserToTeamsMutation, types.SwitchUserToTeamsMutationVariables>(SwitchUserToTeamsDocument, options);
      }
export type SwitchUserToTeamsMutationHookResult = ReturnType<typeof useSwitchUserToTeamsMutation>;
export type SwitchUserToTeamsMutationResult = Apollo.MutationResult<types.SwitchUserToTeamsMutation>;
export type SwitchUserToTeamsMutationOptions = Apollo.BaseMutationOptions<types.SwitchUserToTeamsMutation, types.SwitchUserToTeamsMutationVariables>;
export const SetUserOnboardingCompletedDocument = gql`
    mutation SetUserOnboardingCompleted {
  userSetOnboardingCompleted {
    id
  }
}
    `;
export type SetUserOnboardingCompletedMutationFn = Apollo.MutationFunction<types.SetUserOnboardingCompletedMutation, types.SetUserOnboardingCompletedMutationVariables>;

/**
 * __useSetUserOnboardingCompletedMutation__
 *
 * To run a mutation, you first call `useSetUserOnboardingCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserOnboardingCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserOnboardingCompletedMutation, { data, loading, error }] = useSetUserOnboardingCompletedMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetUserOnboardingCompletedMutation(baseOptions?: Apollo.MutationHookOptions<types.SetUserOnboardingCompletedMutation, types.SetUserOnboardingCompletedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.SetUserOnboardingCompletedMutation, types.SetUserOnboardingCompletedMutationVariables>(SetUserOnboardingCompletedDocument, options);
      }
export type SetUserOnboardingCompletedMutationHookResult = ReturnType<typeof useSetUserOnboardingCompletedMutation>;
export type SetUserOnboardingCompletedMutationResult = Apollo.MutationResult<types.SetUserOnboardingCompletedMutation>;
export type SetUserOnboardingCompletedMutationOptions = Apollo.BaseMutationOptions<types.SetUserOnboardingCompletedMutation, types.SetUserOnboardingCompletedMutationVariables>;
export const UpdateUserProductTourTrackingDocument = gql`
    mutation UpdateUserProductTourTracking($input: UpdateUserProductTourTrackingInput!) {
  updateUserProductTourTracking(input: $input) {
    state
    showAgainAfter
  }
}
    `;
export type UpdateUserProductTourTrackingMutationFn = Apollo.MutationFunction<types.UpdateUserProductTourTrackingMutation, types.UpdateUserProductTourTrackingMutationVariables>;

/**
 * __useUpdateUserProductTourTrackingMutation__
 *
 * To run a mutation, you first call `useUpdateUserProductTourTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProductTourTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProductTourTrackingMutation, { data, loading, error }] = useUpdateUserProductTourTrackingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProductTourTrackingMutation(baseOptions?: Apollo.MutationHookOptions<types.UpdateUserProductTourTrackingMutation, types.UpdateUserProductTourTrackingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UpdateUserProductTourTrackingMutation, types.UpdateUserProductTourTrackingMutationVariables>(UpdateUserProductTourTrackingDocument, options);
      }
export type UpdateUserProductTourTrackingMutationHookResult = ReturnType<typeof useUpdateUserProductTourTrackingMutation>;
export type UpdateUserProductTourTrackingMutationResult = Apollo.MutationResult<types.UpdateUserProductTourTrackingMutation>;
export type UpdateUserProductTourTrackingMutationOptions = Apollo.BaseMutationOptions<types.UpdateUserProductTourTrackingMutation, types.UpdateUserProductTourTrackingMutationVariables>;
export const UserSetPasswordDocument = gql`
    mutation userSetPassword($id: ID!, $password: String!) {
  userSetPassword(id: $id, password: $password)
}
    `;
export type UserSetPasswordMutationFn = Apollo.MutationFunction<types.UserSetPasswordMutation, types.UserSetPasswordMutationVariables>;

/**
 * __useUserSetPasswordMutation__
 *
 * To run a mutation, you first call `useUserSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSetPasswordMutation, { data, loading, error }] = useUserSetPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<types.UserSetPasswordMutation, types.UserSetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.UserSetPasswordMutation, types.UserSetPasswordMutationVariables>(UserSetPasswordDocument, options);
      }
export type UserSetPasswordMutationHookResult = ReturnType<typeof useUserSetPasswordMutation>;
export type UserSetPasswordMutationResult = Apollo.MutationResult<types.UserSetPasswordMutation>;
export type UserSetPasswordMutationOptions = Apollo.BaseMutationOptions<types.UserSetPasswordMutation, types.UserSetPasswordMutationVariables>;
export const CreateRegularUserDocument = gql`
    mutation createRegularUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    firstName
    lastName
    email
  }
}
    `;
export type CreateRegularUserMutationFn = Apollo.MutationFunction<types.CreateRegularUserMutation, types.CreateRegularUserMutationVariables>;

/**
 * __useCreateRegularUserMutation__
 *
 * To run a mutation, you first call `useCreateRegularUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegularUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegularUserMutation, { data, loading, error }] = useCreateRegularUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRegularUserMutation(baseOptions?: Apollo.MutationHookOptions<types.CreateRegularUserMutation, types.CreateRegularUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.CreateRegularUserMutation, types.CreateRegularUserMutationVariables>(CreateRegularUserDocument, options);
      }
export type CreateRegularUserMutationHookResult = ReturnType<typeof useCreateRegularUserMutation>;
export type CreateRegularUserMutationResult = Apollo.MutationResult<types.CreateRegularUserMutation>;
export type CreateRegularUserMutationOptions = Apollo.BaseMutationOptions<types.CreateRegularUserMutation, types.CreateRegularUserMutationVariables>;
export const VerifyEmailExistDocument = gql`
    mutation VerifyEmailExist($email: String!) {
  emailExists(email: $email)
}
    `;
export type VerifyEmailExistMutationFn = Apollo.MutationFunction<types.VerifyEmailExistMutation, types.VerifyEmailExistMutationVariables>;

/**
 * __useVerifyEmailExistMutation__
 *
 * To run a mutation, you first call `useVerifyEmailExistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailExistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailExistMutation, { data, loading, error }] = useVerifyEmailExistMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useVerifyEmailExistMutation(baseOptions?: Apollo.MutationHookOptions<types.VerifyEmailExistMutation, types.VerifyEmailExistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.VerifyEmailExistMutation, types.VerifyEmailExistMutationVariables>(VerifyEmailExistDocument, options);
      }
export type VerifyEmailExistMutationHookResult = ReturnType<typeof useVerifyEmailExistMutation>;
export type VerifyEmailExistMutationResult = Apollo.MutationResult<types.VerifyEmailExistMutation>;
export type VerifyEmailExistMutationOptions = Apollo.BaseMutationOptions<types.VerifyEmailExistMutation, types.VerifyEmailExistMutationVariables>;
export const GetRecommendedExpertsDocument = gql`
    query getRecommendedExperts {
  me {
    id
    expertRecommendations {
      edges {
        ...Expert
      }
    }
  }
}
    ${ExpertFragmentDoc}`;

/**
 * __useGetRecommendedExpertsQuery__
 *
 * To run a query within a React component, call `useGetRecommendedExpertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedExpertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedExpertsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecommendedExpertsQuery(baseOptions?: Apollo.QueryHookOptions<types.GetRecommendedExpertsQuery, types.GetRecommendedExpertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetRecommendedExpertsQuery, types.GetRecommendedExpertsQueryVariables>(GetRecommendedExpertsDocument, options);
      }
export function useGetRecommendedExpertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetRecommendedExpertsQuery, types.GetRecommendedExpertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetRecommendedExpertsQuery, types.GetRecommendedExpertsQueryVariables>(GetRecommendedExpertsDocument, options);
        }
export type GetRecommendedExpertsQueryHookResult = ReturnType<typeof useGetRecommendedExpertsQuery>;
export type GetRecommendedExpertsLazyQueryHookResult = ReturnType<typeof useGetRecommendedExpertsLazyQuery>;
export type GetRecommendedExpertsQueryResult = Apollo.QueryResult<types.GetRecommendedExpertsQuery, types.GetRecommendedExpertsQueryVariables>;
export const UserTopListenedExpertsDocument = gql`
    query userTopListenedExperts($id: String!) {
  user(id: $id) {
    userTopListenedExperts {
      ...LimitedUser
    }
  }
}
    ${LimitedUserFragmentDoc}`;

/**
 * __useUserTopListenedExpertsQuery__
 *
 * To run a query within a React component, call `useUserTopListenedExpertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTopListenedExpertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTopListenedExpertsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserTopListenedExpertsQuery(baseOptions: Apollo.QueryHookOptions<types.UserTopListenedExpertsQuery, types.UserTopListenedExpertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.UserTopListenedExpertsQuery, types.UserTopListenedExpertsQueryVariables>(UserTopListenedExpertsDocument, options);
      }
export function useUserTopListenedExpertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.UserTopListenedExpertsQuery, types.UserTopListenedExpertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.UserTopListenedExpertsQuery, types.UserTopListenedExpertsQueryVariables>(UserTopListenedExpertsDocument, options);
        }
export type UserTopListenedExpertsQueryHookResult = ReturnType<typeof useUserTopListenedExpertsQuery>;
export type UserTopListenedExpertsLazyQueryHookResult = ReturnType<typeof useUserTopListenedExpertsLazyQuery>;
export type UserTopListenedExpertsQueryResult = Apollo.QueryResult<types.UserTopListenedExpertsQuery, types.UserTopListenedExpertsQueryVariables>;
export const UserTopCategoriesDocument = gql`
    query userTopCategories($id: String!) {
  user(id: $id) {
    userTopCategories {
      category {
        ...Category
      }
      duration
    }
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useUserTopCategoriesQuery__
 *
 * To run a query within a React component, call `useUserTopCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTopCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTopCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserTopCategoriesQuery(baseOptions: Apollo.QueryHookOptions<types.UserTopCategoriesQuery, types.UserTopCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.UserTopCategoriesQuery, types.UserTopCategoriesQueryVariables>(UserTopCategoriesDocument, options);
      }
export function useUserTopCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.UserTopCategoriesQuery, types.UserTopCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.UserTopCategoriesQuery, types.UserTopCategoriesQueryVariables>(UserTopCategoriesDocument, options);
        }
export type UserTopCategoriesQueryHookResult = ReturnType<typeof useUserTopCategoriesQuery>;
export type UserTopCategoriesLazyQueryHookResult = ReturnType<typeof useUserTopCategoriesLazyQuery>;
export type UserTopCategoriesQueryResult = Apollo.QueryResult<types.UserTopCategoriesQuery, types.UserTopCategoriesQueryVariables>;
export const GetNotificationPreferencesDocument = gql`
    query getNotificationPreferences {
  me {
    ...NotificationPreferences
  }
}
    ${NotificationPreferencesFragmentDoc}`;

/**
 * __useGetNotificationPreferencesQuery__
 *
 * To run a query within a React component, call `useGetNotificationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationPreferencesQuery(baseOptions?: Apollo.QueryHookOptions<types.GetNotificationPreferencesQuery, types.GetNotificationPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetNotificationPreferencesQuery, types.GetNotificationPreferencesQueryVariables>(GetNotificationPreferencesDocument, options);
      }
export function useGetNotificationPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetNotificationPreferencesQuery, types.GetNotificationPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetNotificationPreferencesQuery, types.GetNotificationPreferencesQueryVariables>(GetNotificationPreferencesDocument, options);
        }
export type GetNotificationPreferencesQueryHookResult = ReturnType<typeof useGetNotificationPreferencesQuery>;
export type GetNotificationPreferencesLazyQueryHookResult = ReturnType<typeof useGetNotificationPreferencesLazyQuery>;
export type GetNotificationPreferencesQueryResult = Apollo.QueryResult<types.GetNotificationPreferencesQuery, types.GetNotificationPreferencesQueryVariables>;
export const ToggleNotificationPreferenceDocument = gql`
    mutation toggleNotificationPreference($type: NotificationType!, $userID: ID) {
  toggleNotificationPreference(type: $type, userID: $userID) {
    ...UserNotificationPreference
  }
}
    ${UserNotificationPreferenceFragmentDoc}`;
export type ToggleNotificationPreferenceMutationFn = Apollo.MutationFunction<types.ToggleNotificationPreferenceMutation, types.ToggleNotificationPreferenceMutationVariables>;

/**
 * __useToggleNotificationPreferenceMutation__
 *
 * To run a mutation, you first call `useToggleNotificationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleNotificationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleNotificationPreferenceMutation, { data, loading, error }] = useToggleNotificationPreferenceMutation({
 *   variables: {
 *      type: // value for 'type'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useToggleNotificationPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<types.ToggleNotificationPreferenceMutation, types.ToggleNotificationPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.ToggleNotificationPreferenceMutation, types.ToggleNotificationPreferenceMutationVariables>(ToggleNotificationPreferenceDocument, options);
      }
export type ToggleNotificationPreferenceMutationHookResult = ReturnType<typeof useToggleNotificationPreferenceMutation>;
export type ToggleNotificationPreferenceMutationResult = Apollo.MutationResult<types.ToggleNotificationPreferenceMutation>;
export type ToggleNotificationPreferenceMutationOptions = Apollo.BaseMutationOptions<types.ToggleNotificationPreferenceMutation, types.ToggleNotificationPreferenceMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken {
  refreshToken {
    jwt
    success
    finishedRegistration
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<types.RefreshTokenMutation, types.RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<types.RefreshTokenMutation, types.RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<types.RefreshTokenMutation, types.RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<types.RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<types.RefreshTokenMutation, types.RefreshTokenMutationVariables>;
export const GetListOfBlockedUsersDocument = gql`
    query GetListOfBlockedUsers($filter: BlockedUsersFilter!) {
  me {
    blockedUsers(filter: $filter) {
      meta {
        limit
        offset
        total
      }
      edges {
        id
        __typename
      }
    }
  }
}
    `;

/**
 * __useGetListOfBlockedUsersQuery__
 *
 * To run a query within a React component, call `useGetListOfBlockedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfBlockedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfBlockedUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetListOfBlockedUsersQuery(baseOptions: Apollo.QueryHookOptions<types.GetListOfBlockedUsersQuery, types.GetListOfBlockedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<types.GetListOfBlockedUsersQuery, types.GetListOfBlockedUsersQueryVariables>(GetListOfBlockedUsersDocument, options);
      }
export function useGetListOfBlockedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<types.GetListOfBlockedUsersQuery, types.GetListOfBlockedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<types.GetListOfBlockedUsersQuery, types.GetListOfBlockedUsersQueryVariables>(GetListOfBlockedUsersDocument, options);
        }
export type GetListOfBlockedUsersQueryHookResult = ReturnType<typeof useGetListOfBlockedUsersQuery>;
export type GetListOfBlockedUsersLazyQueryHookResult = ReturnType<typeof useGetListOfBlockedUsersLazyQuery>;
export type GetListOfBlockedUsersQueryResult = Apollo.QueryResult<types.GetListOfBlockedUsersQuery, types.GetListOfBlockedUsersQueryVariables>;