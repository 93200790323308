import { useState, useTransition } from 'react';
import * as segment from '@tigerhall/analytics';
import { IconTrash } from '@tigerhall/icons';
import { FormInput, TrackedButton, TrackedForm } from '@tigerhall/components';
import { Flex } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { cache } from 'api/apollo';
import { OptionsMenuItem } from 'components/ui/OptionsMenu';
import { ActionModal } from 'components/ui/modals';

import { useDeletePostMutation } from '../queries/generated/post.generated';

export interface DeletePostOptionProps {
  postId: string;
  isPostOwner?: boolean;
  onDelete?: () => Promise<unknown> | void;
}

type FormFields = {
  reason?: string;
};

const OWNER_DELETE_POST_HEADER = {
  title: 'Delete Post',
  description: 'Are you sure you want to delete this post?'
};

const ADMIN_DELETE_POST_HEADER = {
  title: "Tell us your reason for deleting this user's post",
  description: 'Please enter your reason'
};

const DELETE_WARNING =
  'Once a post is deleted, it will be permanently removed and cannot be restored';

export function DeletePostOption({
  postId,
  isPostOwner = false,
  onDelete
}: DeletePostOptionProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [shouldOpenDeleteModal, setShouldOpenDeleteModal] = useState(false);
  const [, startTransition] = useTransition();

  const [deletePostMutation] = useDeletePostMutation();

  const toast = useToast({
    duration: 5000
  });

  const headerDetails = isPostOwner
    ? OWNER_DELETE_POST_HEADER
    : ADMIN_DELETE_POST_HEADER;

  async function onSubmit(values: FormFields) {
    try {
      toast({
        title: 'Submitting',
        description: 'Please wait while we delete this post',
        status: 'loading',
        duration: 2000
      });
      setIsLoading(true);
      await deletePostMutation({
        variables: {
          postId,
          reason: values.reason
        }
      });

      await onDelete?.();
      setShouldOpenDeleteModal(false);

      startTransition(() => {
        cache.evict({
          id: cache.identify({
            __typename: 'Post',
            id: postId
          })
        });
        cache.gc();
        toast.closeAll();
        toast({
          title: 'Success',
          description: 'Post deleted successfully',
          status: 'success'
        });
      });
    } catch (error) {
      startTransition(() => {
        toast.closeAll();
        toast({
          title: 'Error',
          description: 'Post could not be deleted. Please try again later.',
          status: 'error'
        });
      });
      captureException(error, {
        tags: {
          postId: postId
        },
        extra: {
          reason: values.reason
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  function onCancelDelete() {
    setShouldOpenDeleteModal(false);
  }

  return (
    <>
      <OptionsMenuItem
        icon={IconTrash}
        title="Delete Post"
        description="Post will be deleted forever"
        onClick={() => {
          segment.postOptionsClicked({
            referenceId: postId,
            buttonName: 'delete_post'
          });
          setShouldOpenDeleteModal(true);
        }}
      />
      <ActionModal
        title={headerDetails.title}
        description={headerDetails.description}
        info={DELETE_WARNING}
        isOpen={shouldOpenDeleteModal}
        onClose={onCancelDelete}
      >
        <TrackedForm<FormFields>
          name="deletePost"
          focus="reason"
          onSubmit={onSubmit}
        >
          {isPostOwner ? null : (
            <FormInput
              name="reason"
              placeholder="Enter reason"
              type="text"
              label="Reason"
              rules={{
                required: 'Please enter a reason for deleting this post'
              }}
            />
          )}
          <Flex gap="1rem" mt="1.5rem">
            <TrackedButton
              name="cancel"
              variant="outlineDark"
              onClick={onCancelDelete}
              size="lg"
              flex={1}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Cancel
            </TrackedButton>

            <TrackedButton
              name="submit"
              type="submit"
              variant="solidDark"
              size="lg"
              flex={1}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Delete
            </TrackedButton>
          </Flex>
        </TrackedForm>
      </ActionModal>
    </>
  );
}
