import * as React from 'react';
import { HStack, StackProps } from '@chakra-ui/react';

import { EventDate } from './components/EventDate';
import { EventTime } from './components/EventTime';
import { ContentDuration } from './components/ContentDuration';
import type {
  EventTimeBadgeProps,
  NoEventTimeBadgeProps,
  ComponentsProps
} from './types';

type ContentTimeBadgeProps = StackProps &
  (EventTimeBadgeProps | NoEventTimeBadgeProps) &
  ComponentsProps;

export function ContentTimeBadge({
  typename,
  duration,
  date,
  hasTime,
  iconProps,
  textProps,
  ...rest
}: ContentTimeBadgeProps) {
  if (!duration && !date) {
    return null;
  }

  const defaultProps: StackProps = {
    height: {
      base: '1.25rem',
      lg: '1.2rem'
    },
    background: 'blackAlpha.700',
    color: 'white',
    px: '0.375rem',
    rounded: 'full',
    spacing: '0.25rem '
  };

  if (typename === 'Event' && !hasTime) {
    return (
      <HStack {...defaultProps} {...rest}>
        <EventDate date={date} iconProps={iconProps} textProps={textProps} />
      </HStack>
    );
  }

  if (typename === 'Event' && hasTime) {
    return (
      <HStack
        spacing={{
          base: '0.5rem',
          sm: '0.75rem',
          lg: '1rem'
        }}
      >
        <HStack {...defaultProps} {...rest}>
          <EventDate date={date} iconProps={iconProps} textProps={textProps} />
        </HStack>
        <HStack {...defaultProps} {...rest}>
          <EventTime date={date} iconProps={iconProps} textProps={textProps} />
        </HStack>
      </HStack>
    );
  }

  return (
    <HStack {...defaultProps} {...rest}>
      <ContentDuration
        duration={duration as number}
        iconProps={iconProps}
        textProps={textProps}
      />
    </HStack>
  );
}
