import { Helmet } from 'react-helmet';
import { Box, Center, Flex, Heading, VStack } from '@chakra-ui/react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { captureException } from '@sentry/react';
import { IconBan } from '@tigerhall/icons';
import { TableBodyFallback } from '@tigerhall/ui-components';

import { BackButton } from '~/components/ui/Buttons';
import { DarkPanel } from '~/components/ui';
import { useGetListOfBlockedUsersQuery } from '~/queries/user/generated/user.generated';
import { ProfileBarBlock } from '~/components/ui/User/ProfileBarBlock';
import { BlockedUsersLoadingSkelton } from './components/BlockedUsersLoadingSkelton';

export function BlockedUsers() {
  const { data, loading, error, fetchMore } = useGetListOfBlockedUsersQuery({
    variables: {
      filter: {
        limit: 20,
        offset: 0
      }
    }
  });

  const normalized = {
    edges: data?.me?.blockedUsers.edges ?? [],
    meta: data?.me?.blockedUsers?.meta ?? {
      total: 0,
      limit: 0,
      offset: 0
    }
  };

  const hasNextPage =
    !loading && normalized.meta.total > normalized.edges.length;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => {
      const offset = normalized.meta.offset + normalized.meta.limit;
      const fetchMoreFilter = {
        offset,
        limit: 20
      };

      fetchMore({
        variables: {
          filter: fetchMoreFilter
        }
      }).catch((err) => {
        captureException(err, {
          extra: {
            filter: fetchMoreFilter
          }
        });
      });
    },
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px'
  });

  const blockedUsersData = data?.me?.blockedUsers.edges;
  const hasBlockedUsers = blockedUsersData && blockedUsersData.length > 0;

  return (
    <>
      <Helmet>
        <title>Blocked Users</title>
      </Helmet>

      <VStack w="100%" py="2.5rem" spacing="2rem">
        <Box ml="4.5rem" w="fit-content" alignSelf="flex-start">
          <BackButton />
        </Box>

        <Flex
          alignSelf="center"
          w="100%"
          maxW="50rem"
          direction="column"
          gap="1.5rem"
          px="1rem"
        >
          <Heading
            variant="as"
            fontSize="3xl"
            fontWeight="700"
            color="tigerOrange.600"
          >
            Blocked Users
          </Heading>

          <DarkPanel gap="1rem">
            {!loading && !hasBlockedUsers ? (
              <Center width="100%" height="15rem" px="2rem" textAlign="center">
                <TableBodyFallback
                  icon={IconBan}
                  heading="No blocked users"
                  subheading="Manage blocked users from this page."
                />
              </Center>
            ) : null}
            {hasBlockedUsers
              ? blockedUsersData.map((user) => (
                  <Box w="100%" key={user?.id}>
                    <ProfileBarBlock userId={user.id} />
                  </Box>
                ))
              : null}
            {loading ? <BlockedUsersLoadingSkelton /> : <Box ref={sentryRef} />}
          </DarkPanel>
        </Flex>
      </VStack>
    </>
  );
}
