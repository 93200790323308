import type * as React from 'react';
import { Box, type BoxProps, Flex, type FlexProps } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { type SpaceProps, color, flex, space } from 'styled-system';
import theme from 'theme';

const rowHeight = '2.25rem';

export const StyledTable: React.ComponentType<BoxProps> = styled(Box)<
  SpaceProps & { emptyRowsCount?: number }
>`
  ${space};
  flex: 1;
  width: 100%;
  display: grid;
  max-width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid ${theme.colors.darkGrey['300']};
  border-radius: 0.25rem;
  overflow: auto;
`;

export const StyledTableRowContainer = styled.div<
  SpaceProps & { emptyRowsCount?: number }
>`
  min-height: ${({ emptyRowsCount = 0 }) => emptyRowsCount * 35}px;
  position: before;
  pointer-event: none;
  height: 100%;
  width: 100%;
`;

export const TableCell = styled.div<SpaceProps & color>`
  ${space};
  ${color};
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 30px;

  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${theme.fontSizes.sm};
`;

export const TableRow: React.ComponentType<FlexProps & { clickable: boolean }> =
  styled(Flex)<{ clickable: boolean }>`
    ${flex};
    flex-direction: row;
    height: ${rowHeight};
    display: flex;
    flex: 1;

    &:hover {
      background-color: ${theme.colors.darkGrey['700']};
      ${(props) => (props.clickable ? 'cursor: pointer;' : '')}
    }
  `;

export const tableRootStyle = css`
  .table-container {
    width: 0.75rem;
    height: 0.75rem;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid ${theme.colors.darkGrey['300']};
  }

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .table-background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    pointer-events: none;
    background-color: transparent;
    border: 1px solid #ffffff;
  }

  input[type='checkbox']:checked + .table-background {
    background-color: #ff8615;
    border-width: 0px;
  }
`;
