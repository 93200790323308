const l = {
  global: {
    body: {
      bg: "teal.900",
      color: "black",
      height: "100%"
    },
    "::selection": {
      bg: "teal.600",
      color: "white"
    }
  }
};
export {
  l as styles
};
