export const CURRENT_PROGRESS = {
  CONTENT_SELECTION: 'CONTENT_SELECTION',
  USERS_SELECTION: 'USERS_SELECTION',
  CONFIRMATION: 'CONFIRMATION',
  DONE: 'DONE'
};

export const ACTION_TYPES = {
  LearnMore: 'LearnMore',
  Remove: 'Remove',
  SelectContent: 'SelectContent'
};

export type Actions = keyof typeof ACTION_TYPES;
