import { HStack } from '@chakra-ui/layout';
import { Dispatch, SetStateAction, useCallback } from 'react';
import remove from 'lodash/remove';
import { useLocation } from 'react-router-dom';
import * as segment from '@tigerhall/analytics';

import { InterestSelectorItem } from './InterestSelectorItem';

type SelectedInterestsType = string[];

type InterestSelectorListProps = {
  lifeGoadData: {
    id: string;
    emoji: string;
    name: string;
  }[];
  setSelectedIDs: Dispatch<SetStateAction<SelectedInterestsType>>;
  selectedIDs: SelectedInterestsType;
};

export function InterestSelectorList({
  lifeGoadData,
  selectedIDs,
  setSelectedIDs
}: InterestSelectorListProps) {
  const { pathname } = useLocation();

  const handleInterestSelector = useCallback(
    (id: string, name: string) => {
      if (selectedIDs.includes(id)) {
        segment.topicToggleClicked({
          category: name,
          categoryId: id,
          isSelected: false,
          location: pathname
        });
        setSelectedIDs(remove(selectedIDs, (i) => i !== id));
      } else {
        segment.topicToggleClicked({
          category: name,
          categoryId: id,
          isSelected: false,
          location: pathname
        });
        setSelectedIDs((previous) => [...previous, id]);
      }
    },
    [pathname, selectedIDs, setSelectedIDs]
  );

  return (
    <HStack gap="0.5rem" spacing={0} flexWrap="wrap" justifyContent="center">
      {lifeGoadData?.map((lifeGoal) => {
        const { id, name, emoji } = lifeGoal || {};
        return (
          <InterestSelectorItem
            isSelected={selectedIDs?.includes(id)}
            key={id}
            topicName={name}
            handleInterestSelector={() => handleInterestSelector(id, name)}
            emoji={emoji}
          />
        );
      })}
    </HStack>
  );
}
