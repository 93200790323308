import { createSlice } from '@reduxjs/toolkit';
import { Organisation } from '@tigerhall/core/lib/types';
import type { RootState } from 'app/reducer';

// ------------------------------------
// Reducer
// ------------------------------------
interface State {
  selected: Pick<
    Organisation,
    'id' | 'name' | 'type' | 'activeUsers' | 'subscription'
  > | null;
}

const initialState: State = {
  selected: null
};

export const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    setSelectedOrganisation: (state, action) => {
      state.selected = action.payload;
    }
  }
});

export const { setSelectedOrganisation } = organisationSlice.actions;

// ------------------------------------
// Selectors
// -----------------------------------

/**
 * @deprecated use `getSelectedOrgId` instead
 */
export const getSelectedOrg = (state: RootState) =>
  state.organisations.selected;
