import { Center } from '@chakra-ui/react';
import { useRedirectCTA } from '@tigerhall/core/lib/hooks';
import { SubscriptionStatus } from '@tigerhall/core/lib/types';
import { useGetCurrentUserQuery } from 'generated';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Success } from 'modules/authentication/modules/SignUp/screens/Confirmation/Success';
import { Verification } from 'modules/authentication/modules/SignUp/screens/Confirmation/Verification';
import { getAccessToken, setAccessToken } from 'app/state';
import * as segment from '@tigerhall/analytics';
import { useEffect, useState } from 'react';

export function Confirmation() {
  const [didTimeout, setDidTimeout] = useState(false);
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAccessToken);

  const { redirectLink } = useRedirectCTA({
    websiteRedirectUrl: import.meta.env.VITE_WEBAPP_URL
  });

  const { data: currentUserResponse, stopPolling } = useGetCurrentUserQuery({
    context: {
      headers: {
        authorization: `jwt ${token}`
      }
    },
    fetchPolicy: 'network-only',
    pollInterval: 1000
  });

  const userProfile = currentUserResponse?.me;
  const didSubscribeSuccessfully =
    userProfile?.subscriptionStatus === SubscriptionStatus.Active ||
    userProfile?.subscriptionStatus === SubscriptionStatus.Trial;

  if (didSubscribeSuccessfully) {
    stopPolling();
    dispatch(setAccessToken({ token }));

    /**
     * Conditional tracking if the user is part of a Team or an Individual
     */
    const userOraganisation = userProfile.organisations || [];
    const hasOrganizationSubscription = userOraganisation.some(
      (organization) => {
        const organizationSubscriptionStatus =
          organization?.subscription?.status;
        return (
          organizationSubscriptionStatus === 'active' ||
          organizationSubscriptionStatus === 'trialing'
        );
      }
    );
    if (hasOrganizationSubscription) {
      segment.formViewed({
        formName: 'subscriptionPlanTeamsSignupSuccess',
        formLocation: window?.location?.pathname
      });
    } else {
      segment.formViewed({
        formName: 'subscriptionPlanIndividualSignupSuccess',
        formLocation: window?.location?.pathname
      });
    }

    setTimeout(() => {
      window.location.href = redirectLink;
    }, 5000);
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!didSubscribeSuccessfully) {
        setDidTimeout(true);
        stopPolling();
      }
    }, 30000);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Center
      backgroundColor="darkGrey.700"
      h="calc(100vh - 180px)"
      flexDirection="column"
      width="100%"
    >
      {didSubscribeSuccessfully ? (
        <Success />
      ) : (
        <Verification failed={didTimeout} />
      )}
    </Center>
  );
}
