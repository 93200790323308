import {
  Content,
  ContentCard,
  ContentType,
  EbookFilter,
  EventFilter,
  LearningPathFilter,
  PodcastFilter,
  SortOrdering,
  StreamFilter
} from '@tigerhall/core/lib/types';
import * as segment from '@tigerhall/analytics';
import { DocumentNode, NetworkStatus, useQuery } from '@apollo/client';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {
  CONTENT_CARD_DIMENSIONS,
  ContentCardOverlayAction,
  ContentCardWithOverlay
} from '@tigerhall/components';
import { Loader } from '@tigerhall/ui-components';
import { Box, Flex, SimpleGrid, Skeleton } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import map from 'lodash/map';
import {
  FetchLearningPathDocument,
  FetchStreamsDocument,
  GetEbooksDocument,
  GetEventsDocument,
  GetPodcastsDocument
} from 'generated';
import { DummyCopies } from 'components/ui/DummyCopies';
import { NoSearchResults } from 'components/ui/NoSearchResults';
import { ContentCardWithLinks } from 'components/ui/ContentBox';

type InfiniteSingleTypeCardGridProps = {
  /**
   * What type of content we should be listing
   */
  type: ContentType;

  filter:
    | StreamFilter
    | EventFilter
    | PodcastFilter
    | EbookFilter
    | LearningPathFilter;

  sorting?: SortOrdering;

  sectionName?: string;

  overlayActions?: (
    card: ContentCard,
    refetch: () => Promise<unknown>
  ) => ContentCardOverlayAction[];
};

/**
 * If no limit has been provided in the initial filter value then we default to this number of items
 */
const defaultPageSize = 20;

/**
 * Get the graphql document node and the edge key to be able to iterate over the edges
 *
 * @param type
 */
const getDocumentAndEdgeKey = (type: ContentType): [DocumentNode, string] => {
  switch (type) {
    case ContentType.Event:
      return [GetEventsDocument, 'events'];

    case ContentType.Stream:
      return [FetchStreamsDocument, 'streams'];

    case ContentType.LearningPath:
      return [FetchLearningPathDocument, 'learningPaths'];
    case ContentType.Podcast:
      return [GetPodcastsDocument, 'podcasts'];
    case ContentType.Ebook:
      return [GetEbooksDocument, 'ebooks'];

    default:
      throw new Error(`unsupported type ${type} provided`);
  }
};

/**
 * Infinite card list is an infinite scrolling list of content cards that are retrieved used a dedicated type query
 *
 * The reason for this is contentCards does not support filtering on specific organisations, so this component
 * should only be used for listing content in webapp for a specific organisation
 */
export function InfiniteSingleTypeCardGrid({
  type,
  filter,
  sorting = { sorting: [{ field: 'updatedAt', direction: 'DESC' }] },
  sectionName,
  overlayActions
}: InfiniteSingleTypeCardGridProps) {
  const [doc, edgeKey] = getDocumentAndEdgeKey(type);

  const { data, loading, fetchMore, error, networkStatus, refetch } = useQuery(
    doc,
    {
      variables: { filter, sorting }
    }
  );

  const normalized = data
    ? data[edgeKey]
    : {
        edges: [],
        meta: {
          total: 0,
          offset: 0,
          limit: 0
        }
      };

  const hasNextPage =
    !loading && normalized.edges.length < normalized.meta.total;

  const [scrollRef] = useInfiniteScroll({
    loading,
    hasNextPage,

    onLoadMore: () => {
      const offset = normalized.edges.length;

      fetchMore({
        variables: {
          filter: {
            ...filter,
            limit: filter.limit || defaultPageSize,
            offset
          }
        }
      });

      segment.fetchMore({
        componentName: 'InfiniteSingleTypeCardGrid',
        fetchedItemCount: offset
      });
    },

    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error || networkStatus === NetworkStatus.refetch,

    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px'
  });

  if (
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables
  ) {
    return (
      <Flex gap="1.2rem">
        <DummyCopies count={4}>
          <Box pb="1rem">
            <Box>
              <Skeleton {...CONTENT_CARD_DIMENSIONS} rounded="lg" />
            </Box>
          </Box>
        </DummyCopies>
      </Flex>
    );
  }

  if (normalized.edges.length === 0) {
    return <NoSearchResults />;
  }
  return (
    <SimpleGrid
      spacing={6}
      templateColumns="repeat(auto-fill, minmax(240px, 1fr))"
    >
      <AnimatePresence>
        {map(normalized.edges, (card: Content, index) => (
          <motion.div
            key={card.id}
            ref={scrollRef}
            layout
            layoutId={card.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {overlayActions ? (
              <ContentCardWithOverlay
                {...card}
                dataTestId={`card-${card.id}`}
                overlayActions={overlayActions(card, refetch)}
              />
            ) : (
              <ContentCardWithLinks
                {...card}
                dataTestId={`card-${card.id}`}
                sectionName={sectionName}
                sectionCardIndex={index}
              />
            )}
          </motion.div>
        ))}
      </AnimatePresence>
      {networkStatus === NetworkStatus.fetchMore && <Loader />}
    </SimpleGrid>
  );
}
