import * as React from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Text
} from '@chakra-ui/react';
import { FREE_ACCOUNT_HEIGHT, HEADER_HEIGHT } from 'components/ui/Layout';
import { useFreeAccount } from 'hooks';

type Props = {
  children?: React.ReactNode;

  breadcrumbs?: {
    href?: string;
    title: string;
  }[];
};

/**
 * This container wraps the entire page and provides the header
 *
 * @param children
 * @param breadcrumbs
 * @constructor
 */
export default function PageContainer({ children, breadcrumbs }: Props) {
  const { hasFreeSubscription, hasTrialSubscription } = useFreeAccount();

  const shouldShowBanner = hasFreeSubscription || hasTrialSubscription;

  return (
    <Box
      color="white"
      bg="darkGrey.700"
      display="flex"
      flex={1}
      height={`calc(100vh - ${HEADER_HEIGHT} - ${
        shouldShowBanner ? FREE_ACCOUNT_HEIGHT : '0px'
      })`}
      overflowY="auto"
      css={{
        '&::-webkit-scrollbar': {
          width: '4px'
        },
        '&::-webkit-scrollbar-track': {
          width: '6px'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#C4C4C4',
          borderRadius: '24px'
        }
      }}
    >
      <Flex flexDirection="column" marginX="auto" width="100%" px={8} pt={8}>
        {breadcrumbs ? (
          <Breadcrumb mb="4" separator=">">
            {breadcrumbs?.map((b) => (
              <BreadcrumbItem key={b.title}>
                {b.href ? (
                  <BreadcrumbLink href={b.href}>{b.title}</BreadcrumbLink>
                ) : (
                  <Text>{b.title}</Text>
                )}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        ) : null}

        <Box pb={8}>{children}</Box>
      </Flex>
    </Box>
  );
}
