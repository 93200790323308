import { useEffect } from 'react';
import { Box, Flex, Icon, Skeleton, Text, TextProps } from '@chakra-ui/react';
import { Content } from '@tigerhall/core/lib/types';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { TrackedLink } from '@tigerhall/components';
import { IconChevronDown } from '@tigerhall/icons';
import { WatchQueryFetchPolicy } from '@apollo/client';
import {
  GetContentCardsQueryVariables,
  useGetContentCardsLazyQuery
} from 'generated';

import { ContentCardsCarousel } from './ContentCardsCarousel';

interface ContentCardSectionProps {
  sectionTitle: string;
  variables?: GetContentCardsQueryVariables;
  contentCardData?: Content[];
  dataLoading?: boolean;
  enableFetchMore?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
  sectionId?: string;
  seeAllLink?: string;
  titleProps?: TextProps;
}

export default function ContentCardSection({
  sectionTitle,
  variables,
  contentCardData,
  dataLoading,
  enableFetchMore,
  fetchPolicy,
  sectionId,
  seeAllLink,
  titleProps
}: ContentCardSectionProps) {
  const [getContentCards, { data, loading, fetchMore }] =
    useGetContentCardsLazyQuery({
      fetchPolicy
    });

  const mergedVariables = merge(
    {},
    {
      filter: {
        includeDrafts: false,
        limit: 10
      }
    },
    variables
  );

  function handleFetchMore() {
    fetchMore({
      variables: {
        filter: {
          ...mergedVariables?.filter,
          offset: data?.contentCards?.edges?.length
        }
      }
    });
  }

  useEffect(() => {
    if (isEmpty(contentCardData)) {
      getContentCards({
        variables: mergedVariables
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentCardData]);

  const isLoading = dataLoading || loading;
  const didReachLimit =
    data?.contentCards?.meta?.total === data?.contentCards?.edges?.length;

  if (
    ((!data?.contentCards?.edges?.length && !contentCardData) ||
      contentCardData?.length === 0) &&
    !isLoading
  ) {
    return null;
  }

  return (
    <Box w="100%" id={sectionId}>
      {isLoading ? (
        <Box w="100%" mb="1.5rem" pl={{ base: '1rem', md: '2rem' }}>
          <Skeleton w="15rem" h="2rem" />
        </Box>
      ) : (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={{
            base: '1rem',
            md: '1.5rem'
          }}
          px={{
            base: '1rem',
            md: '2rem'
          }}
        >
          <Text
            as="h3"
            color="white"
            fontSize={{
              base: '1.25rem',
              md: '1.5rem'
            }}
            fontWeight="bold"
            {...titleProps}
          >
            {sectionTitle}
          </Text>
          {seeAllLink ? (
            <TrackedLink
              name={`See All ${sectionTitle}`}
              href={seeAllLink}
              as="button"
              variant="inline"
              fontWeight="bold"
              iconSpacing="0.25rem"
              rightIcon={
                <Icon
                  as={IconChevronDown}
                  boxSize="0.75rem"
                  transform="rotate(-90deg)"
                  aria-hidden
                />
              }
            >
              See All
            </TrackedLink>
          ) : null}
        </Flex>
      )}
      <ContentCardsCarousel
        sectionTitle={sectionTitle}
        contentList={contentCardData ?? (data?.contentCards.edges as Content[])}
        fetchMore={
          enableFetchMore && !didReachLimit && data?.contentCards?.meta?.total
            ? handleFetchMore
            : undefined
        }
        isLoading={isLoading}
      />
    </Box>
  );
}
