import { IconTextSize } from '@tigerhall/icons';

import { OptionButton } from '~/player/components/OptionButton';
import { usePowerReadState } from '~/player/PowerRead/context/usePowerReadState';

export function ChangeTextSizeButton() {
  const { activeCollapsiblePanel, setActiveCollapsiblePanel } =
    usePowerReadState((state) => {
      return {
        activeCollapsiblePanel: state.activeCollapsiblePanel,
        setActiveCollapsiblePanel: state.setActiveCollapsiblePanel
      };
    });

  return (
    <OptionButton
      label={'Text Size'}
      icon={IconTextSize}
      onClick={() =>
        activeCollapsiblePanel === 'textSize'
          ? setActiveCollapsiblePanel(undefined)
          : setActiveCollapsiblePanel('textSize')
      }
    />
  );
}
