import { noop } from '@chakra-ui/utils';
import { useEffect, useRef, useState } from 'react';

import { type MinimizedPlayerProps } from '~/player/components/MinimizedPlayer';
import { type TrackSelectorProps } from '~/player/components/TrackSelector';
import { MediaPlayerContextProvider } from '~/player/context';
import {
  type UseMediaPlayerArgs,
  useMediaAnalytics,
  useMediaPlayer
} from '~/player/hooks';
import { useVideoHls } from '~/player/hooks/useVideoHls';
import {
  ActivePlayer,
  type ActivePlayerProps
} from './components/ActivePlayer';

export interface VideoProps
  extends Omit<
      ActivePlayerProps,
      | 'children'
      | 'name'
      | 'casting'
      | 'isHidden'
      | 'onSubtitlesToggle'
      | 'handleClose'
      | 'isSubtitleActive'
      | 'videoRef'
    >,
    Pick<UseMediaPlayerArgs, 'customDuration' | 'previousProgress'>,
    Pick<MinimizedPlayerProps, 'onClose' | 'onMaximize'>,
    Pick<TrackSelectorProps, 'tracks' | 'trackIndex' | 'setTrackIndex'> {
  autoPlay?: boolean;
  muted?: boolean;
  meta: {
    contentId: string;
    contentName: string;
    casting: string;
  };
  onTrackProgress: (progress: number) => void;
  onTrackFinished: () => void;
  onFinished?: () => void;
  /**
   * If the user completed the podcast.
   * This will be used to determine if the user should be able to mark the podcast as done.
   */
  isFinished?: boolean;
  /**
   * If the player should be hidden without being unmounted.
   * This is useful if you want to keep the player state but not have it visible,
   * e.g. if you want to open a video and then resume the podcast after the video is closed.
   */
  isHidden?: boolean;
  onMaximize: () => void;
  playlistId?: string;
}

export function Video({
  autoPlay = true,
  muted = false,
  meta: { contentId, contentName, casting },

  onTrackProgress,
  onTrackFinished,
  onFinished = noop,
  isHidden = false,
  playlistId,

  // from `ActivePlayerProps`
  mediaLanguages,
  selectedMediaLanguage,
  onChangeMediaLanguage,
  urlToShare,
  contentDescription,

  transcriptLanguages,
  selectedTranscriptLanguage,
  onChangeTranscriptLanguage,

  vote,
  onLike,
  onDislike,

  isMinimized,

  // For Header Info
  playlistTitle,
  playlistType,
  contentUrl,

  // For Auto Play content
  onAutoNextPlay,
  isLastContentInPlaylist,

  orientation,

  // from `UseMediaPlayerArgs`
  customDuration,
  previousProgress,

  // from `MinimizedPlayerProps`
  onClose,
  onMaximize,

  // from `TrackSelectorProps`
  tracks,
  trackIndex = 0,
  setTrackIndex
}: Readonly<VideoProps>) {
  const src = tracks[trackIndex].url;
  const videoRef = useRef<HTMLVideoElement>(null);

  const [isSubtitleActive, setIsSubtitleActive] = useState(
    !!selectedTranscriptLanguage
  );

  useEffect(() => {
    setIsSubtitleActive(!!selectedTranscriptLanguage);
  }, [selectedTranscriptLanguage]);

  useMediaAnalytics({
    contentId,
    contentType: 'Stream',
    playlistId,
    playlistType,
    onTrackProgress,
    onTrackFinished,
    playerState: isMinimized ? 'Minimized' : 'Maximized',
    language: selectedMediaLanguage,
    mediaType: 'video'
  });

  const player = useMediaPlayer(videoRef, {
    onFinished,
    customDuration,
    previousProgress,
    defaultAutoPlay: autoPlay
  });

  const { hls } = useVideoHls(src, videoRef);

  function onNextTrack() {
    if (trackIndex === tracks.length - 1) {
      setTrackIndex(0);
    } else {
      setTrackIndex(trackIndex + 1);
    }
  }

  function onPrevTrack() {
    if (trackIndex === 0) {
      setTrackIndex(tracks.length - 1);
    } else {
      setTrackIndex(trackIndex - 1);
    }
  }

  function toggleSubtitles() {
    if (!videoRef.current?.textTracks[0]) {
      return;
    }
    if (videoRef.current.textTracks[0].mode === 'showing') {
      videoRef.current.textTracks[0].mode = 'hidden';
      setIsSubtitleActive(false);
    } else {
      videoRef.current.textTracks[0].mode = 'showing';
      setIsSubtitleActive(true);
    }
  }

  function handleClose() {
    // to make sure the video doesn't keep playing in the background
    // @see https://tigerhall.atlassian.net/browse/ENG-5559
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.src = '';
      hls.detachMedia();
      hls.destroy();
    }
    onClose();
  }

  if (isHidden) {
    player.pause();
  }

  return (
    <MediaPlayerContextProvider
      {...player}
      onNextTrack={onNextTrack}
      onPrevTrack={onPrevTrack}
    >
      <ActivePlayer
        tracks={tracks}
        mediaLanguages={mediaLanguages}
        selectedMediaLanguage={selectedMediaLanguage}
        onChangeMediaLanguage={onChangeMediaLanguage}
        transcriptLanguages={transcriptLanguages}
        selectedTranscriptLanguage={selectedTranscriptLanguage}
        onChangeTranscriptLanguage={onChangeTranscriptLanguage}
        vote={vote}
        onLike={onLike}
        onDislike={onDislike}
        name={contentName}
        casting={casting}
        isMinimized={isMinimized}
        contentUrl={contentUrl}
        onSubtitlesToggle={toggleSubtitles}
        onMaximize={onMaximize}
        handleClose={handleClose}
        isSubtitleActive={isSubtitleActive}
        videoRef={videoRef}
        urlToShare={urlToShare}
        contentDescription={contentDescription}
        playlistTitle={playlistTitle}
        playlistType={playlistType}
        onAutoNextPlay={onAutoNextPlay}
        orientation={orientation}
        isLastContentInPlaylist={isLastContentInPlaylist}
      >
        <video
          ref={videoRef}
          controls={false}
          src={src}
          muted={muted}
          autoPlay={autoPlay}
          crossOrigin="anonymous"
          controlsList="nodownload"
          disablePictureInPicture
          playsInline
          style={{
            width: '100%',
            height: '100%',
            maxHeight: '100vh',
            objectFit: 'contain'
          }}
        >
          {selectedTranscriptLanguage && tracks[trackIndex].subtitlesUrl ? (
            <track
              kind="subtitles"
              src={tracks[trackIndex].subtitlesUrl}
              default
            />
          ) : null}
        </video>
      </ActivePlayer>
    </MediaPlayerContextProvider>
  );
}
