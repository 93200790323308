import { Text, type TextProps } from '@chakra-ui/react';
import { type ForwardedRef, forwardRef } from 'react';

import { GetComponentByType } from './GetComponentByType';
import { getParsedText } from './utils/getParsedText';

export interface ParsedTextProps {
  rawText: string;
  textProps?: Omit<TextProps, 'as' | 'ref'>;
}

/**
 * ParsedText component.
 *
 * This component takes a raw text and returns different components wrapped withing itself like a comment can consist of link, mentions, plain text. This component returns different components based on the category of texts.
 * @returns
 */

function ParsedTextComponent(
  { rawText, textProps }: Readonly<ParsedTextProps>,
  textRef: ForwardedRef<HTMLParagraphElement>
) {
  const textBlocks = getParsedText(rawText);

  return (
    <Text
      as="p"
      ref={textRef}
      fontWeight="normal"
      fontSize="sm"
      color="lightGrey.400"
      whiteSpace="pre-line"
      wordBreak="break-word"
      {...textProps}
    >
      {textBlocks.map((textBlock, index) => (
        <GetComponentByType key={textBlock.text + index} {...textBlock} />
      ))}
    </Text>
  );
}

export const ParsedText = forwardRef<
  HTMLParagraphElement,
  Readonly<ParsedTextProps>
>(ParsedTextComponent);
