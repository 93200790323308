import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { FormInput, TrackedForm } from '@tigerhall/components';
import { useResetPasswordInitiateMutation } from 'generated';
import { Link, useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';

import { ResetPasswordContainer } from '../components';

type FormValues = {
  email: string;
};

export function SendConfirmationCode() {
  const navigate = useNavigate();

  const [
    resetPasswordInitiateMutation,
    { loading: isSendingResetPasswordCode }
  ] = useResetPasswordInitiateMutation();

  const sendConfirmationCode = (data: FormValues) => {
    resetPasswordInitiateMutation({
      variables: {
        email: data.email
      }
    })
      .then((res) => {
        if (res.data?.resetPasswordInitiate.success) {
          navigate('/reset-password/new-password?e=' + data.email);
        }
      })
      .catch((error) => {
        captureException(error, {
          extra: {
            email: data.email
          }
        });
      });
  };

  return (
    <ResetPasswordContainer>
      <TrackedForm<FormValues>
        name="initiateResetPassword"
        onSubmit={sendConfirmationCode}
      >
        <Flex gap="1rem" direction="column" width="100%">
          <Heading color="white" fontSize="1.125rem" textAlign="center">
            Forgot Password
          </Heading>
          <Text
            color="lightGrey.900"
            fontSize="1rem"
            fontWeight={500}
            lineHeight="1.5rem"
            textAlign="center"
          >
            Enter the email associated with your account and we’ll send an email
            with a confirmation code to reset your password.
          </Text>
          <FormInput
            name="email"
            type="email"
            label="email"
            placeholder="Email"
            data-cy="email"
            autoComplete="email"
          />
          <Button
            my="2.5rem"
            data-cy="submit"
            type="submit"
            width="100%"
            size="lg"
            isLoading={isSendingResetPasswordCode}
            isDisabled={isSendingResetPasswordCode}
          >
            Send Confirmation Code
          </Button>
          <Link to="/login">
            <Text
              color="lightGrey.900"
              fontSize="0.875rem"
              textAlign="center"
              py="1.5rem"
            >
              Back to login
            </Text>
          </Link>
        </Flex>
      </TrackedForm>
    </ResetPasswordContainer>
  );
}
