import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
export type UserContentTrackingFragmentFragment = { __typename: 'UserContentTracking', id: string, current: number, currentPlacement: number, total: number, isAttending: boolean, isFinished: boolean, isBookmarked: boolean, bookmarkedAt?: any | null, lastOpenedAt?: any | null, finishedAt?: any | null };

export const UserContentTrackingFragmentFragmentDoc = gql`
    fragment userContentTrackingFragment on UserContentTracking {
  __typename
  id
  current
  currentPlacement
  total
  isAttending
  isFinished
  isBookmarked
  bookmarkedAt
  lastOpenedAt
  finishedAt
}
    `;