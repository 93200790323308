import { MouseEvent as ReactMouseEvent } from 'react';
import {
  Category,
  DisplayableContent,
  Expert,
  useMicrosoftTeams
} from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import { captureException } from '@sentry/react';
import { useBreakpointValue } from '@chakra-ui/react';

export type ShareableContent = Pick<
  DisplayableContent,
  '__typename' | 'id' | 'name'
> & {
  experts?: Pick<Expert, 'id' | 'firstName' | 'lastName'>[];
  categories?: Pick<Category, 'id' | 'name'>[];
};

export interface UseShareModalReturn {
  /**
   * Dictates if the sharing functionality is available or if it should be hidden
   *
   * Sharing is not supported in mobile for Microsoft Teams
   * Read More: https://learn.microsoft.com/en-us/microsoftteams/platform/concepts/build-and-test/share-to-teams-from-personal-app-or-tab#limitations
   */
  isAvailable: boolean;

  /**
   * Open share modal
   *
   * This is a wrapper around opening share functionality on different devices and platforms.
   *
   * The supported platforms are:
   * - Microsoft Teams
   * - Mobile Native share
   * - Desktop browser modal, see the ShareModal from this package.
   *
   * @param event
   */
  openShareModal: (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
}

export interface UseShareModalArgs {
  title: string;
  description: string;
  urlToShare: string;
  content?: ShareableContent;
  onOpen: () => void;
}

export function useShareModal({
  title,
  description,
  urlToShare,
  content,
  onOpen
}: UseShareModalArgs): UseShareModalReturn {
  const { isInTeams, isInMobile, sdk } = useMicrosoftTeams();

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { fallback: 'md' }
  );

  return {
    isAvailable: !isInTeams || (isInTeams && !isInMobile),

    openShareModal: async (
      event: ReactMouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.preventDefault();

      if (content) {
        const { id, name, categories, experts } = content;

        segment.sharedContent({
          contentId: id ?? '',
          contentName: name ?? '',
          contentType: content.__typename ?? '',
          categories: categories?.map((c) => c.name) ?? [],
          categoriesIds: categories?.map((c) => c.id) ?? [],
          experts: experts?.map((e) => `${e.firstName} ${e.lastName}`) ?? [],
          expertsIds: experts?.map((e) => e?.id) ?? [],
          location: window?.location?.pathname ?? ''
        });
      }

      if (isInTeams) {
        return await sdk.sharing
          .shareWebContent({
            content: [
              {
                type: 'URL',
                url: urlToShare,
                message: description,
                preview: true
              }
            ]
          })
          .catch((error) => {
            captureException(error);
            console.error(error);
          });
      }

      // For more info about the browser Share API
      // @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
      if ('share' in navigator && isMobile) {
        try {
          await navigator.share({
            title: title,
            text: `${title} - ${description}`,
            url: urlToShare
          });
        } catch (err) {
          captureException(err);

          console.error(
            'An error did occur while using navigator.share: ',
            err
          );

          onOpen();
        }
      } else {
        onOpen();
      }
    }
  };
}
