import * as React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { DarkPanel } from 'components/ui';

export function Section({ title, children }) {
  return (
    <Flex alignSelf="center" w="680px" direction="column" mt={8}>
      <DarkPanel gap="1.5rem">
        <Text fontSize="1.25rem" fontWeight="700" color="white">
          {title}
        </Text>
        {children}
      </DarkPanel>
    </Flex>
  );
}
