import { IconSquareOff } from '@tigerhall/icons';
import { Flex, Icon, SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';

const Card = ({ info }: { info?: boolean }) => (
  <Flex
    w="100%"
    h="290px"
    borderRadius="10px"
    bg="linear-gradient(180deg, #333738 0%, rgba(21, 22, 24, 0) 100%);"
    justifyContent="center"
    alignItems="center"
  >
    {info ? (
      <Flex direction="column" justifyContent="center" alignItems="center">
        <Icon as={IconSquareOff} w={16} h={16} color="lightGrey.900" my={4} />
        <Text fontSize="1rem" fontWeight="600" color="lightGrey.900">
          No search results found
        </Text>
      </Flex>
    ) : null}
  </Flex>
);

export default function NoSearchResults() {
  return (
    <SimpleGrid spacing={8} columns={3} minChildWidth="200px">
      <Card />
      <Card info />
      <Card />
    </SimpleGrid>
  );
}
