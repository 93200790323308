import { NetworkStatus } from '@apollo/client';
import {
  Box,
  Flex,
  HStack,
  Heading,
  type SystemStyleObject,
  Text,
  VStack
} from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { useEdgesOverlay } from '@tigerhall/core';
import { CommentType, type Content } from '@tigerhall/core/lib/types';
import { getUserId } from 'app/state';
import { CommentInputWrapper } from 'components/ui/Comments/CommentInputWrapper';
import { CommentItemSkeleton } from 'components/ui/Comments/CommentItem';
import { CommentWithReplies } from 'components/ui/Comments/CommentWithReplies';
import { AnimatePresence, motion } from 'framer-motion';
import { useGetAllCommentsForContentLazyQuery } from 'generated';
import { useAppSelector } from 'hooks';
import map from 'lodash/map';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

interface CommentsTabProps {
  contentId: string;
  isOrganizationContent?: boolean;
}

/**
 * Here we use `SystemStyleObject` because the `content` property is not a valid
 * CSS property, so it's not available in the `StyleProps` type.
 */
const OVERLAY_STYLES: SystemStyleObject = {
  content: '"."',
  color: 'transparent',
  display: 'block',
  position: 'sticky',
  width: '100%',
  height: '3rem',
  lineHeight: '3rem',
  left: 0,
  right: 0
};

const LIMIT = 10;

export function CommentsTab({
  contentId,
  isOrganizationContent
}: Readonly<CommentsTabProps>) {
  const userId = useAppSelector(getUserId);
  const limitRef = useRef(LIMIT); // using ref for the limit in-order to keep the updated variable in-case of cache-evict

  const variables = {
    id: contentId,
    sorting: {
      sorting: [
        {
          direction: 'DESC',
          field: 'createdAt'
        }
      ]
    },
    limit: limitRef.current <= LIMIT ? LIMIT : limitRef.current,
    afterCursor: null
  };

  const scrollableRootRef = useRef<HTMLDivElement | null>(null);
  const lastScrollDistanceToBottomRef = useRef<number>();

  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);
  const { handleScroll, hasBottomOverlay } = useEdgesOverlay();

  function scrollToBottom() {
    // the `setTimeout` is to make sure the scroll is done after the new comment is added
    setTimeout(() => {
      if (!scrollableRootRef.current) {
        return;
      }

      scrollableRootRef.current.scrollTo({
        top: scrollableRootRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }, 200);
  }

  const [
    fetchComments,
    { data, loading, error, refetch, fetchMore, networkStatus }
  ] = useGetAllCommentsForContentLazyQuery({
    variables: variables,
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      const contentCard = res.contentCard as Content;
      const commentsShown = contentCard.comments.edges.length ?? 0;
      limitRef.current = commentsShown;

      if (shouldScrollToBottom) {
        const isLastCommentSentByCurrentUser =
          contentCard.comments.edges[0].comment.user.id === userId;

        // if the last comment is sent by the current user, scroll to the bottom
        if (isLastCommentSentByCurrentUser) {
          scrollToBottom();
          setShouldScrollToBottom(false);
        }
      }
    }
  });

  useEffect(() => {
    void fetchComments();
  }, [fetchComments]);

  const contentCard = data?.contentCard as Content;
  const comments = useMemo(
    () => contentCard?.comments.edges ?? [],
    [contentCard?.comments.edges]
  );

  const reversedComments = useMemo(() => [...comments].reverse(), [comments]);

  const hasNextPage = useMemo(
    () => Boolean(contentCard?.comments?.meta?.hasNext),
    [contentCard?.comments?.meta?.hasNext]
  );

  const [targetRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,

    onLoadMore: () => {
      const nextVariables = {
        ...variables,
        limit: LIMIT,
        afterCursor: contentCard.comments.meta.nextCursor
      };

      fetchMore({
        variables: nextVariables
      }).catch((e) => captureException(e));
    },

    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,

    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '400px 0px 0px 0px'
  });

  function handleRefetch() {
    const refetchVariables = {
      ...variables,
      limit: limitRef.current <= LIMIT ? LIMIT : limitRef.current
    };

    refetch(refetchVariables).catch((e) => captureException(e));
    setShouldScrollToBottom(true);
  }

  useLayoutEffect(() => {
    const scrollableRoot = scrollableRootRef.current;
    const lastScrollDistanceToBottom =
      lastScrollDistanceToBottomRef.current ?? 0;
    if (scrollableRoot) {
      scrollableRoot.scrollTop =
        scrollableRoot.scrollHeight - lastScrollDistanceToBottom;
    }
  }, [comments, rootRef]);

  const rootRefSetter = useCallback(
    (node: HTMLDivElement) => {
      rootRef(node);
      scrollableRootRef.current = node;
    },
    [rootRef]
  );

  const handleRootScroll = useCallback(() => {
    const rootNode = scrollableRootRef.current;
    if (rootNode) {
      const scrollDistanceToBottom = rootNode.scrollHeight - rootNode.scrollTop;
      lastScrollDistanceToBottomRef.current = scrollDistanceToBottom;
    }
  }, []);

  if (loading && NetworkStatus.ready === networkStatus) {
    return (
      <Box width="100%" mt="1.25rem" px={'1rem'}>
        <CommentItemSkeleton />
      </Box>
    );
  }

  return (
    <VStack
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      width={'full'}
      height={'100%'}
      spacing={0}
    >
      <Box
        px={'1rem'}
        height={'full'}
        overflow={'auto'}
        w={'full'}
        ref={rootRefSetter}
        onScroll={(e) => {
          handleRootScroll();
          handleScroll(e);
        }}
      >
        <Box
          w={'full'}
          _after={{
            ...OVERLAY_STYLES,
            visibility: hasBottomOverlay ? 'visible' : 'hidden',
            bottom: 0,
            bgGradient: 'linear(to-t, darkGrey.700, transparent)'
          }}
        >
          {hasNextPage ? (
            <Box width="100%" mt="1.25rem" px={'1rem'} ref={targetRef}>
              <CommentItemSkeleton />
            </Box>
          ) : null}
          {reversedComments.length === 0 ? (
            <Box
              alignItems={'center'}
              display={'flex'}
              flexDirection={'column'}
              h={'100%'}
            >
              <Heading
                as="h3"
                fontSize={{ base: '1.25rem', md: '2xl' }}
                textAlign="center"
                color="lightGrey.200"
                fontWeight="bold"
                mb={6}
                mt={4}
              >
                No comments yet.
              </Heading>
              <Text fontSize={'md'}>Be the first to comment!</Text>
            </Box>
          ) : (
            <AnimatePresence>
              {map(reversedComments, (c) => (
                <motion.div
                  key={`content-comment-tabs-${c?.comment?.id}`}
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Flex direction="column" mt={6} key={c?.comment?.id}>
                    <CommentWithReplies
                      referenceId={contentId}
                      referenceType={CommentType.Content}
                      comment={c?.comment}
                      parentView="comments-tab"
                    />
                  </Flex>
                </motion.div>
              ))}
            </AnimatePresence>
          )}
        </Box>
      </Box>
      <HStack
        alignItems="flex-start"
        p={'1rem'}
        borderTop={'1px solid'}
        borderTopColor={'darkGrey.400'}
        w={'inherit'}
        backgroundColor={'darkGrey.700'}
      >
        <CommentInputWrapper
          refetch={handleRefetch}
          referenceId={contentId}
          referenceType={CommentType.Content}
          isOrganizationContent={isOrganizationContent}
        />
      </HStack>
    </VStack>
  );
}
