import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Flex,
  HStack,
  Heading,
  Icon,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react';
import {
  type AssignmentContent,
  AssignmentStatus,
  type OrganisationGroup,
  type User
} from '@tigerhall/core/lib/types';
import {
  FormDateTimePicker,
  FormInput,
  StatusBadge,
  TrackedButton,
  TrackedLink,
  TrackedRTForm
} from '@tigerhall/components';
import { IconEdit } from '@tigerhall/icons';
import { captureException } from '@sentry/react';
import {
  useGetOrgAssignmentQuery,
  useUpdateAssignmentMutation,
  useUpdateAssignmentStatusMutation
} from 'generated';
import { getSelectedOrg } from 'app/state';
import { useAppSelector } from 'hooks';
import { ContentPanel } from 'components/ui';
import { BackButton } from 'components/ui/Buttons';
import { ActionModal, type ActionModalProps } from 'components/ui/modals';

import { SelectedContentCards, UsersAndGroups } from '../../components';
import { SectionStatistics } from './components';
import { typenameToContentType } from '../../../../../../utils/content';

type FormValues = {
  name?: string;
  purpose?: string;
  dueDate?: Date;
};

export function AssignmentDetails() {
  const { id: assignmentId } = useParams<{ id: string }>();
  const { id: orgId } = useAppSelector(getSelectedOrg) || {};

  const toast = useToast();

  const { data, loading } = useGetOrgAssignmentQuery({
    skip: !orgId || !assignmentId,
    variables: {
      orgId: orgId as string,
      assignmentId: assignmentId as string
    },
    fetchPolicy: 'cache-and-network'
  });

  const assignmentDetails = data?.organisation.assignments.edges[0] ?? null;

  const dueDate = React.useMemo(
    () =>
      assignmentDetails?.dueDate
        ? new Date(assignmentDetails.dueDate)
        : undefined,
    [assignmentDetails?.dueDate]
  );

  const isInactive = assignmentDetails?.status === AssignmentStatus.Inactive;
  const isExpired = assignmentDetails?.status === AssignmentStatus.Expired;
  const isNotEditable = isInactive || isExpired;

  const selectedContent =
    (assignmentDetails?.contents?.edges?.filter(
      (content) => content.__typename !== 'Event'
    ) as AssignmentContent[]) || [];
  const users = assignmentDetails?.users?.edges ?? [];
  const orgGroups = (assignmentDetails?.orgGroups ?? []) as OrganisationGroup[];

  const [isEdited, setIsEdited] = React.useState(false);
  const [details, setDetails] = React.useState({
    assignmentTitle: assignmentDetails?.assignmentTitle || '',
    assignmentReason: assignmentDetails?.assignmentReason || '',
    dueDate
  });
  const [actionModal, setActionModal] = React.useState<ActionModalProps>({
    isOpen: false,
    title: '',
    onClose: () => {},
    description: ''
  });

  const navigate = useNavigate();

  const onDetailsUpdate = React.useCallback(
    (values: FormValues) => {
      setDetails({
        assignmentTitle: values.name ?? '',
        assignmentReason: values.purpose ?? '',
        dueDate: values.dueDate
      });

      // we need to transform the date to ISO string to compare
      // because `Date` is an object, it will always be different
      if (
        (values.name !== assignmentDetails?.assignmentTitle ||
          values.purpose !== assignmentDetails?.assignmentReason ||
          values.dueDate?.toISOString?.() !== dueDate?.toISOString()) &&
        !isInactive
      ) {
        setIsEdited(true);
      } else {
        setIsEdited(false);
      }
    },
    [setDetails, assignmentDetails, dueDate, isInactive]
  );

  const [updateAssignment, { loading: isUpdatingAssignment }] =
    useUpdateAssignmentMutation();

  const [mutationMarkAssignmentAsInactive, { loading: isMarkingInactive }] =
    useUpdateAssignmentStatusMutation();

  if (loading || !orgId || !assignmentId) {
    return null;
  }

  function resetModal() {
    setActionModal({
      isOpen: false,
      title: '',
      onClose: () => {},
      description: '',
      primaryButtonText: '',
      primaryButtonOnClick: () => {},
      secondaryButtonText: '',
      secondaryButtonOnClick: () => {}
    });
  }

  async function saveChanges() {
    resetModal();
    try {
      toast({
        title: 'Saving changes',
        status: 'loading'
      });

      if (!assignmentId) {
        throw new Error('No assignment id');
      }

      const updatedStatus =
        details.dueDate && new Date(details.dueDate) < new Date()
          ? AssignmentStatus.Expired
          : AssignmentStatus.Active;

      await updateAssignment({
        variables: {
          id: assignmentId,
          input: {
            assignmentTitle: details.assignmentTitle,
            assignmentReason: details.assignmentReason,
            // if due date is not set, set it to `null`. it should not be `undefined`
            dueDate: details.dueDate || null,
            status: updatedStatus,
            contents: selectedContent.map((content, index) => ({
              id: content.id,
              contentType: typenameToContentType(content.__typename),
              index
            })),
            users: users.map((user) => user.id),
            orgGroups: orgGroups.map((group) => group.id)
          }
        }
      });
      setIsEdited(false);
      toast.closeAll();
      toast({
        title: 'Assignment updated successfully',
        status: 'success'
      });
    } catch (error) {
      toast.closeAll();
      toast({
        title:
          'Error saving changes. Please try again later or contact support.',
        status: 'error'
      });
      captureException(new Error('Error saving changes in assignment'), {
        level: 'error',
        tags: {
          assignmentId: assignmentId
        },
        extra: {
          error,
          details
        }
      });
    }
  }

  function openSaveChangesModal() {
    setActionModal({
      isOpen: true,
      title: 'Saves Changes',
      onClose: resetModal,
      description: 'Are you sure you want to save your changes?',
      primaryButtonText: 'Save Changes',
      primaryButtonOnClick: saveChanges,
      secondaryButtonText: 'Cancel',
      secondaryButtonOnClick: resetModal
    });
  }

  async function markAssignmentAsInactive() {
    resetModal();
    try {
      if (!assignmentId) {
        throw new Error('No assignment id');
      }
      await mutationMarkAssignmentAsInactive({
        variables: {
          id: assignmentId,
          status: AssignmentStatus.Inactive
        }
      });
      toast({
        title: 'Assignment marked as inactive',
        status: 'success'
      });
    } catch (error) {
      toast({
        title:
          'Error marking assignment as inactive. Please try again later or contact support.',
        status: 'error'
      });
    }
  }

  function onMarkAssignmentAsInactiveClicked() {
    setActionModal({
      isOpen: true,
      title: 'Mark Assignment as Inactive',
      type: 'warning',
      onClose: resetModal,
      description:
        'Once you mark this assignment as inactive you won’t be able to make it active again.',
      info: 'Marking an assignment as inactive removes it from the My Assignments section for users in the mobile and web app.',
      primaryButtonText: 'Mark as Inactive',
      primaryButtonOnClick: markAssignmentAsInactive,
      secondaryButtonText: 'Cancel',
      secondaryButtonOnClick: resetModal
    });
  }

  function onBackButtonClick() {
    if (isEdited) {
      setActionModal({
        isOpen: true,
        title: 'You have unsaved changes',
        type: 'warning',
        onClose: resetModal,
        description:
          'You did not save the modifications you made to the assignment. Do you want to save it before leaving?',
        primaryButtonText: 'Save Changes',
        primaryButtonOnClick: saveChanges,
        secondaryButtonText: 'Discard & Exit',
        secondaryButtonOnClick: () => navigate(-1)
      });
    } else {
      navigate(-1);
    }
  }

  const detailsInlineButtons = [
    {
      id: 'markAsInactive',
      text: 'Mark as Inactive',
      hide: isInactive,
      isDisabled: isUpdatingAssignment || isMarkingInactive,
      leftIcon: <Icon as={IconEdit} w="1rem" h="1rem" />,
      onClick: onMarkAssignmentAsInactiveClicked
    }
  ];

  const usersAndGroupsInlineButtons = [
    {
      id: 'edit',
      text: 'Edit',
      hide: isNotEditable,
      isDisabled: isUpdatingAssignment || isMarkingInactive,
      leftIcon: <Icon as={IconEdit} w="1rem" h="1rem" />,
      onClick: () => navigate(`/admin/assignments/${assignmentId}/edit`)
    }
  ];

  function getStatusBadgeStatus() {
    switch (assignmentDetails?.status) {
      case AssignmentStatus.Active:
        return 'success';
      case AssignmentStatus.Inactive:
        return 'disabled';
      case AssignmentStatus.Expired:
        return 'error';
      default:
        return 'disabled';
    }
  }

  return (
    <Flex direction="column" gap="2rem">
      <BackButton to="" onClick={onBackButtonClick}>
        Back
      </BackButton>
      <Stack
        direction="row"
        backgroundColor="darkGrey.400"
        py="2rem"
        px="2rem"
        borderRadius="10px"
        boxShadow="0rem 0.19rem 0.37rem rgba(0, 0, 0, 0.160784);"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading fontSize="2rem" color="tigerOrange.600">
          {assignmentDetails?.assignmentTitle}
        </Heading>
        <TrackedButton
          name="Save Changes"
          onClick={openSaveChangesModal}
          size="lg"
          isDisabled={!isEdited}
        >
          Save Changes
        </TrackedButton>
      </Stack>
      <ContentPanel
        heading="Assignments Details"
        inlineButtons={detailsInlineButtons}
        headingRightSlot={
          <StatusBadge
            status={getStatusBadgeStatus()}
            variant="solid"
            size="md"
            textTransform="capitalize"
          >
            {assignmentDetails?.status?.toLowerCase()}
          </StatusBadge>
        }
      >
        <TrackedRTForm<FormValues>
          name="assignmentDetails"
          onSubmit={onDetailsUpdate}
          formHookProps={{
            defaultValues: {
              name: assignmentDetails?.assignmentTitle,
              purpose: assignmentDetails?.assignmentReason,
              dueDate: dueDate
            }
          }}
        >
          <HStack direction="row" spacing={4} align="stretch">
            <Box flex={1}>
              <FormInput
                name="name"
                label="Assignment Title"
                placeholder="Assignment Title"
                disabled={isNotEditable}
              />
            </Box>
            <Box flex={1}>
              <FormInput
                name="purpose"
                label="Purpose/Goal"
                placeholder="Purpose/Goal"
                disabled={isNotEditable}
              />
            </Box>
            <Box flex={1}>
              <FormDateTimePicker
                name="dueDate"
                label="Due Date"
                placeholder="-"
                minDate={isExpired || !dueDate ? new Date() : dueDate}
                disabled={isInactive}
              />
            </Box>
          </HStack>
        </TrackedRTForm>
      </ContentPanel>

      <ContentPanel
        heading={`Selected Content (${selectedContent.length || 0})`}
      >
        <Text fontSize="md" pb="2rem">
          You cannot edit the selected content for this assignment.{' '}
          <TrackedLink
            name="CreateNewAssignment"
            href="/admin/assignments/draft"
            color="tigerOrange.200"
          >
            Create New Assignment
          </TrackedLink>{' '}
          if you want to add or remove content.
        </Text>
        <SelectedContentCards
          contentCards={selectedContent}
          actions={['LearnMore']}
          shouldHideFrame
        />
      </ContentPanel>

      <UsersAndGroups
        loading={loading}
        members={users as User[]}
        orgGroups={orgGroups}
        inlineButtons={usersAndGroupsInlineButtons}
      />

      <SectionStatistics assignmentId={assignmentId} />

      <ActionModal {...actionModal} />
    </Flex>
  );
}
