import { type ReactNode, useEffect } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { type Category, type Expert } from '@tigerhall/core';
import { ContentType } from '@tigerhall/core/lib/types';
import { FormInput, useTrackedForm } from '@tigerhall/components';
import { THLink, Typography } from '@tigerhall/ui-components';
import { CategoriesSelect, ExpertsSelect } from 'components/form';
import { ContentCardList } from 'components/ui/Cards/CardList/ContentCardList';
import ContentWizard from 'components/ui/containers/ContentWizard';
import { useGetCurrentUserQuery } from 'generated';
import useContent from 'modules/admin/modules/trails/hooks/useContent';
import useDraftLearningPaths from 'modules/admin/modules/trails/hooks/useDraftLearningPaths';
import { FormProvider } from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';
import { hasOrgAdminAccess } from 'app/state';
import { useAppSelector } from 'hooks';

import DraftSelect from './DraftSelect';
import SelectedContentCardList from './SelectedContentCardList';

const options = [
  {
    label: 'Include drafts',
    value: 'include_drafts'
  },
  {
    label: 'Exclude drafts',
    value: 'exclude_drafts'
  }
];

function TrailDetailAction({
  children,
  idOrDraft
}: Readonly<{
  idOrDraft: string;
  children: ReactNode;
}>) {
  return (
    <THLink name="TRAIL_DETAIL" to={`../${idOrDraft}/details`}>
      {children}
    </THLink>
  );
}

type FormValues = {
  category: Category;
  expert: Expert;
  draft: (typeof options)[number];
  keywords: string;
};

const DESCRIPTION = `Start building your custom Trail by selecting the pieces you'd like to include. There's a lot to choose from, so filter by Thinkfluencer, category, or content type. You can also search for specific key words. `;

export function TrailContent() {
  const {
    contentIds: selectedContentIds,
    actions,
    idOrDraft
  } = useDraftLearningPaths();
  const { data } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first'
  });
  const isOrgAdmin = useAppSelector(hasOrgAdminAccess);

  const isAdmin = isOrgAdmin || data?.me?.roles?.includes('admin');

  const {
    isFetchingContentCards,
    contentCards,
    totalCount,
    fetchContentCards,
    totalContentCards
  } = useContent();

  useEffect(() => {
    fetchContentCards({
      types: [
        ContentType.Event,
        ContentType.Ebook,
        ContentType.Podcast,
        ContentType.Stream
      ]
    });
  }, [fetchContentCards]);

  function isContinueButtonDisabled() {
    return selectedContentIds?.length <= 0;
  }

  const form = useTrackedForm<FormValues>('CUSTOM_TRAIL_CREATION', {
    defaultValues: {
      draft: options[1]
    }
  });
  const values = form.watch();

  useDeepCompareEffect(() => {
    fetchContentCards({
      ...values,
      includeDrafts: values?.draft?.value === options[0].value,
      types: [
        ContentType.Event,
        ContentType.Ebook,
        ContentType.Podcast,
        ContentType.Stream
      ],
      experts: values.expert ? [values.expert.id] : [],
      categories: values.category ? [values.category.id] : []
    });
  }, [values, fetchContentCards]);

  return (
    <ContentWizard
      title="Select Content"
      currentStep={1}
      buttons={[
        {
          text: 'Next',
          container: TrailDetailAction,
          isDisabled: isContinueButtonDisabled(),
          containerProps: { idOrDraft },
          dataCy: 'next-button'
        }
      ]}
      description={DESCRIPTION}
    >
      <Box mb={2}>
        <SelectedContentCardList
          title="My Selected Content in Order"
          allContentCards={totalContentCards}
          selectedContentIds={selectedContentIds}
          setSelectedContentIds={actions.setContentIds}
          dataCy="selected_content"
        />
        <Typography
          variant="heading3"
          fontWeight="bold"
          fontSize={22}
          mt={5}
          mb={8}
        >
          All Content
        </Typography>
        <Box
          padding="16px"
          borderRadius="5px"
          borderWidth="1px"
          borderColor="darkGrey.400"
        >
          <FormProvider {...form}>
            <Typography variant="heading4" mb={4}>
              Search
            </Typography>
            <SimpleGrid spacing="1rem" columns={4}>
              <FormInput
                type="text"
                name="keywords"
                label="Search"
                placeholder="Keywords"
              />

              <CategoriesSelect name="category" isClearable />
              <ExpertsSelect name="expert" isClearable />
              {isAdmin ? <DraftSelect options={options} /> : null}
            </SimpleGrid>
          </FormProvider>
        </Box>
        <ContentCardList
          // @ts-expect-error - Type ContentCard[] is not assignable to type Content[]
          cards={contentCards}
          totalCount={totalCount}
          loading={isFetchingContentCards}
          selectedContentIds={selectedContentIds}
          setSelectedContentIds={actions.setContentIds}
          mt={8}
        />
      </Box>
    </ContentWizard>
  );
}
