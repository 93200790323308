import { type BoxProps, Text, VStack } from '@chakra-ui/react';
import React from 'react';

type PageBannerProps = {
  description: string;
  title: string;
  action: React.ReactNode;
} & BoxProps;

export const PageBanner: React.FC<PageBannerProps> = ({
  description,
  title,
  action
}) => (
  <VStack
    backgroundColor="darkGrey.400"
    alignItems="center"
    justifyContent="center"
    py="2rem"
    spacing="1.5rem"
    borderRadius="md"
  >
    <Text
      fontSize="2rem"
      fontWeight={700}
      lineHeight="2.375rem"
      color="tigerOrange.600"
    >
      {title}
    </Text>
    <Text
      fontSize="1rem"
      lineHeight="1.5rem"
      fontWeight={500}
      color="lightGrey.200"
      maxW="46.25rem"
      textAlign="center"
    >
      {description}
    </Text>
    {action}
  </VStack>
);
