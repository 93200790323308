import { useEffect } from 'react';
import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { FormProvider } from 'react-hook-form';
import { ResetPasswordContainer } from 'modules/authentication/modules/ResetPassword/components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormInput, useTrackedForm } from '@tigerhall/components';
import {
  useResetPasswordFinalizeMutation,
  useResetPasswordInitiateMutation
} from 'generated';

export function ResetPassword() {
  const [params] = useSearchParams();

  const form = useTrackedForm('resetPassword');
  const navigate = useNavigate();

  const { handleSubmit, setError, getValues } = form;

  const email = params.get('e') || '';

  useEffect(() => {
    if (!email) {
      navigate('/reset-password');
    }
  }, [navigate, email]);

  const [
    resetPasswordInitiateMutation,
    { loading: isSendingResetPasswordCode }
  ] = useResetPasswordInitiateMutation();

  const [resetPasswordFinalizeMutation, { loading }] =
    useResetPasswordFinalizeMutation({
      onError: (apolloError) => {
        apolloError.graphQLErrors?.forEach((graphQLError) => {
          if (graphQLError.path?.[0] === 'resetPasswordFinalize') {
            setError('confirmationCode', {
              type: 'manual',
              message: 'Invalid Confirmation Code'
            });
          }
        });
      }
    });

  const handleNewPasswordSubmit = (values: any) => {
    if (values.newPassword !== values.confirmPassword) {
      return setError('confirmPassword', {
        type: 'manual',
        message: 'Passwords do not match'
      });
    }

    resetPasswordFinalizeMutation({
      variables: {
        input: {
          nonce: values?.confirmationCode,
          email,
          password: values?.newPassword
        }
      }
    })
      .then(() => {
        navigate('/reset-password/success');
      })
      .catch((error) => {
        captureException(error, {
          extra: {
            email
          }
        });
      });
  };

  const receiveNewCode = () => {
    resetPasswordInitiateMutation({
      variables: {
        email
      }
    }).catch((error) => {
      captureException(error, {
        extra: {
          email
        }
      });
    });
  };

  return (
    <ResetPasswordContainer>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(handleNewPasswordSubmit)}
          style={{ width: '100%' }}
        >
          <Heading
            color="white"
            fontSize="1.125rem"
            textAlign="center"
            my="1rem"
          >
            Set a new password
          </Heading>
          <Text
            color="lightGrey.900"
            fontSize="1rem"
            fontWeight={500}
            lineHeight="1.5rem"
            textAlign="center"
          >
            A confirmation code has been sent to your email, please enter the
            confirmation code and your new password.
          </Text>
          <Flex direction="column" gap="0.75rem" my="2.5rem">
            <FormInput
              name="confirmationCode"
              type="number"
              placeholder="Confirmation code"
              label="Confirmation Code"
              rules={{ required: true }}
            />
            <FormInput
              name="newPassword"
              type="password"
              placeholder="New password"
              label="New Password"
              autoComplete="new-password"
              rules={{
                required: true,
                minLength: {
                  value: 8,
                  message: 'Your password must be at least 8 characters long'
                }
              }}
            />
            <FormInput
              rules={{
                required: true,
                validate: (value) => {
                  const { newPassword } = getValues();

                  return (
                    newPassword === value || 'Your Password does not match'
                  );
                }
              }}
              name="confirmPassword"
              type="password"
              placeholder="Confirm password"
              label="Confirm Password"
              autoComplete="new-password"
            />
          </Flex>
          <Flex alignItems="center" flexDirection="column" pt="1rem">
            <Button
              data-cy="submit"
              type="submit"
              width="100%"
              size="lg"
              isDisabled={loading}
              isLoading={loading}
            >
              Change Password
            </Button>
            <Flex
              direction="row"
              my="1.5rem"
              gap="0.5rem"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontSize=".75rem" as="span">
                Did not receive a confirmation code?
              </Text>
              <Button
                variant="outline"
                size="sm"
                onClick={receiveNewCode}
                borderRadius="md"
                isLoading={isSendingResetPasswordCode}
              >
                Send New Code
              </Button>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    </ResetPasswordContainer>
  );
}
