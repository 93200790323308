import type { ThinkfluencerScope } from '@tigerhall/components';

export interface GetThinkfluencerScopeArgs {
  isExpert: boolean | undefined;
  expertOrg:
    | {
        id: string;
      }
    | null
    | undefined;
  signedInUserOrgId: string;
}

export function getThinkfluencerScope({
  isExpert,
  expertOrg,
  signedInUserOrgId
}: GetThinkfluencerScopeArgs): ThinkfluencerScope | undefined {
  switch (true) {
    case isExpert && !expertOrg:
      return 'public';
    case isExpert && expertOrg?.id === signedInUserOrgId:
      return 'organization';
    default:
      return undefined;
  }
}
