import * as segment from '@tigerhall/analytics';
import { PrivacySettings } from '@tigerhall/core';
import {
  OptionsMenuItem,
  type OptionsMenuItemProps
} from 'components/ui/OptionsMenu';
import { useAppDispatch } from 'hooks';
import { setPrivacySetting } from 'app/state';

import {
  getAudienceIcon,
  PRIVACY_SETTINGS
} from '../../../../../../../utils/privacySettings';

interface PrivacyOptionItemProps extends Partial<OptionsMenuItemProps> {
  selectedOption: PrivacySettings;
}

export function PrivacyOptionItem({
  selectedOption,
  ...rest
}: PrivacyOptionItemProps) {
  const dispatch = useAppDispatch();
  const setting = PRIVACY_SETTINGS[selectedOption];

  return (
    <OptionsMenuItem
      icon={getAudienceIcon(selectedOption)}
      title={setting.heading}
      description={setting.description}
      onClick={() => {
        segment.postAudienceSelected({
          postAudience: setting.segmentAudience
        });
        dispatch(setPrivacySetting(selectedOption));
      }}
      {...rest}
    />
  );
}
