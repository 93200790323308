import { HStack, Text } from '@chakra-ui/react';
import { AIBadge, TrackedLink } from '@tigerhall/components';
import { DarkPanel } from 'components/ui';

export function LanguageSection() {
  return (
    <DarkPanel w="100%" gap="1.5rem">
      <HStack gap={3}>
        <Text fontSize="xl" fontWeight="bold" color="white">
          Language
        </Text>
        <AIBadge />
      </HStack>
      <Text fontSize="md" fontWeight="500" color="darkGrey.50">
        Change your preferred language
      </Text>
      <TrackedLink
        name="PREFERRED_LANGUAGE"
        href="/profile/settings/language"
        as="button"
        size="lg"
        maxW="20rem"
      >
        Change Language
      </TrackedLink>
    </DarkPanel>
  );
}
