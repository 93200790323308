/**
 * ### Tigerhall Brand Colors
 *
 * **Primary**:
 * - Tiger Orange 600
 *
 * **Secondary**:
 * - So Sunny 600
 * - Kinda Yellow 600
 * - Teal 600
 * - lightGrey.200
 * - Black
 * - White
 *
 * ### State Colors
 *
 * **Success**:
 * - Green 500
 *
 * **Warning**:
 * - Kinda Yellow 500
 *
 * **Error**:
 * - Red 500
 *
 * [See our design systemm](https://www.figma.com/file/WLhIGuIkWTy7rf4QbRfxvA/New-Design-System?node-id=2%3A2)
 */

const customColors = {
  tigerOrange: {
    50: '#FFF9F6',
    100: '#FFF3ED',
    200: '#FFDECC',
    300: '#FFC3A3',
    400: '#FFA97A',
    500: '#FF7429',
    600: '#FF5900',
    700: '#DD3722',
    800: '#AD3D00',
    900: '#842E00'
  },
  soSunny: {
    50: '#FFF8F1',
    100: '#FEECD9',
    200: '#FCE5CC',
    300: '#FCCC9C',
    400: '#FAB269',
    500: '#F99936',
    600: '#F78104',
    700: '#DD7608',
    800: '#BA6103',
    900: '#7D4003'
  },
  kindaYellow: {
    50: '#FFF7EC',
    100: '#FFEDD6',
    200: '#FFE7C1',
    300: '#FCDEB0',
    400: '#FCCC87',
    500: '#FABD5E',
    600: '#FAAB36',
    700: '#DD8D17',
    800: '#BA8029',
    900: '#7D571C'
  },
  lightGrey: {
    50: '#FBFCFC',
    100: '#F8F8F9',
    200: '#F4F5F5',
    300: '#F1F2F2',
    400: '#EAEBEC',
    500: '#E3E5E5',
    600: '#DCDEDF',
    700: '#CDCFD0',
    800: '#BEC0C2',
    900: '#AFB2B3'
  },
  darkGrey: {
    50: '#A0A3A5',
    100: '#828587',
    200: '#64686A',
    300: '#464A4D',
    400: '#383B3E',
    500: '#2A2C2E',
    600: '#1C1E1F',
    700: '#151617',
    800: '#0E0F0F',
    900: '#070708'
  },
  teal: {
    50: '#EAF6F6',
    100: '#D4EDED',
    200: '#A6D9D9',
    300: '#7DC4C7',
    400: '#249EA0',
    500: '#008083',
    600: '#005F60',
    700: '#004747',
    800: '#003030',
    900: '#001717'
  },
  green: {
    50: '#F0FFF4',
    100: '#C6F7C4',
    200: '#9BE899',
    300: '#5AD057',
    400: '#2FC12C',
    500: '#04B100',
    600: '#038E00',
    700: '#026A00',
    800: '#024700',
    900: '#1C4532'
  },
  red: {
    50: '#FFF5F5',
    100: '#FBCDCD',
    200: '#F46A6A',
    300: '#F13838',
    400: '#ED0606',
    500: '#BE0505',
    600: '#8E0404',
    700: '#5F0202',
    800: '#470202',
    900: '#3C080A'
  }
};

const stateColors = {
  success: customColors.green[500],
  warning: customColors.kindaYellow[500],
  error: customColors.red[500]
};

export const colors = {
  state: stateColors,
  ...customColors
};
