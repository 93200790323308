import * as React from 'react';
import { RouteObject } from 'react-router-dom';
import { Splash } from 'components/ui/Layout/components';

const NotFound = React.lazy(() =>
  import('./screens/NotFound').then((module) => ({
    default: module.NotFound
  }))
);

const BrazeNotificationRedirect = React.lazy(() =>
  import('./screens/BrazeNotificationRedirect').then((module) => ({
    default: module.BrazeNotificationRedirect
  }))
);

const ContentNotFound = React.lazy(() =>
  import('./screens/ContentNotFound').then((module) => ({
    default: module.ContentNotFound
  }))
);

const Editor = React.lazy(() =>
  import('./screens/Editor').then((module) => ({
    default: module.Editor
  }))
);

const ExpiredInvite = React.lazy(() =>
  import('./screens/invite').then((module) => ({
    default: module.Expired
  }))
);

const AcceptedInvite = React.lazy(() =>
  import('./screens/invite').then((module) => ({
    default: module.Accepted
  }))
);

const routes: RouteObject[] = [
  {
    path: '/*',
    element: (
      <React.Suspense fallback={<Splash />}>
        <NotFound />
      </React.Suspense>
    )
  },
  {
    path: '/braze',
    element: (
      <React.Suspense fallback={<Splash />}>
        <BrazeNotificationRedirect />
      </React.Suspense>
    )
  },
  {
    path: '/invite/expired',
    element: (
      <React.Suspense fallback={<Splash />}>
        <ExpiredInvite />
      </React.Suspense>
    )
  },
  {
    path: '/invite/accepted',
    element: (
      <React.Suspense fallback={<Splash />}>
        <AcceptedInvite />
      </React.Suspense>
    )
  },
  {
    path: '/content-not-found',
    element: (
      <React.Suspense fallback={<Splash />}>
        <ContentNotFound />
      </React.Suspense>
    )
  },
  {
    path: '/editor',
    element: (
      <React.Suspense fallback={<Splash />}>
        <Editor />
      </React.Suspense>
    )
  }
];

export default routes;
