import * as React from 'react';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';
import {
  Hooks,
  ColumnInstance,
  Row,
  TableOptions,
  IdType,
  TableState,
  TableRowProps,
  UseTableRowProps
} from 'react-table';
import { logger } from '@tigerhall/core';

import TableCheckbox from '../components/TableCheckbox';
import { RowSelectionHandlerType } from '../types';

const TABLE_SELECTION_COLUMN_WIDTH = '30px';

/**
 * create onChange and check props for TableCheckbox
 */
function createCellProps<D extends object = {}>({
  state,
  onRowSelection,
  rowId
}: {
  state: TableState<D>;
  onRowSelection?: RowSelectionHandlerType<D>;
  rowId: string;
}) {
  const selectedRowIdsMap = pickBy(state?.selectedRowIds);
  const selectedRowIds = Object.keys(state?.selectedRowIds);

  function onRowSelectionChange(id: string, checked: boolean) {
    if (checked) {
      onRowSelection?.(uniq([...(selectedRowIds ?? []), id]));
    } else {
      onRowSelection?.(selectedRowIds?.filter((itemId) => itemId !== id));
    }
  }

  return {
    checked: selectedRowIdsMap?.[rowId],
    onClick: (event: React.PointerEvent<HTMLIFrameElement>) => {
      event.stopPropagation();
    },
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      onRowSelectionChange(rowId, checked);
    }
  };
}

/**
 * handle onChange and checked props for Select all button
 */
function createSelectAllProps<D extends object = {}>({
  state,
  onRowSelection,
  rowIds
}: {
  state: TableState<D>;
  onRowSelection?: RowSelectionHandlerType<D>;
  rowIds: string[];
}) {
  const selectedRowIdsMap = pickBy(state?.selectedRowIds);
  const selectedRowIds = Object.keys(selectedRowIdsMap);

  function onRowSelectionChange(checked: boolean) {
    const rowIdsSet = new Set(rowIds);
    if (checked) {
      // on adding Select all tigger add current page's row ids
      onRowSelection?.(uniq(rowIds ?? []));
    } else {
      // on remove Select all trigger remove only current page's row ids
      onRowSelection?.(
        selectedRowIds?.filter((itemId) => !rowIdsSet.has(itemId))
      );
    }
  }

  const selectedRowLength = Object.keys(
    pick(state?.selectedRowIds, rowIds)
  ).length;
  const isSelectedAll =
    selectedRowLength === rowIds.length && selectedRowLength > 0;

  return {
    checked: isSelectedAll,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      event.nativeEvent.stopPropagation();
      onRowSelectionChange(checked);
    }
  };
}

const createOptions =
  <D extends object = {}>({
    selectedRowIds
  }: {
    selectedRowIds?: IdType<D>[];
  }) =>
  (options: TableOptions<D>) => {
    const rowIds = selectedRowIds;

    // adapting id[] to {id:boolean} state
    const selectedRowIdState = React.useMemo(
      () =>
        (rowIds ?? []).reduce(
          (accum: Record<IdType<D>, boolean>, id: IdType<D>) => ({
            ...accum,
            [id]: true
          }),
          {} as Record<IdType<D>, boolean>
        ),
      [rowIds]
    );

    return {
      ...options,
      initialState: {
        ...(options?.initialState ?? {}),
        selectedRowIds: selectedRowIdState
      },
      useControlledState: (state: TableState<D>) => ({
        ...state,
        selectedRowIds: selectedRowIdState
      })
    };
  };

const createClickableHook =
  <D extends object = {}>({ onRowSelection, onRowClick }) =>
  (hooks: Hooks<D>) => {
    hooks.getRowProps.push((props: any) => [
      props,
      {
        clickable: !!(onRowSelection ?? onRowClick)
      }
    ]);
  };

/**
 * @param onRowSelection
 */
export function createUseTableSelection<D extends object = {}>({
  onRowClick,
  onRowSelection,
  selectedRowIds
}: {
  onRowClick?: (row: UseTableRowProps<D>) => void;
  onRowSelection?: RowSelectionHandlerType<D>;
  selectedRowIds?: IdType<D>[];
}): any {
  const clickableHook = [
    createClickableHook({ onRowSelection, onRowClick }),
    (hooks: Hooks<D>) =>
      hooks.getRowProps.push(
        (props: Partial<TableRowProps>, { row }: { row: Row<D> }) => ({
          ...props,
          onClick: () => onRowClick?.(row)
        })
      )
  ];

  if (!onRowSelection) {
    return clickableHook;
  }

  return [
    ...clickableHook,
    (hooks: Hooks<D>) => {
      hooks.useOptions.push(createOptions({ selectedRowIds }));
      hooks.visibleColumns.push((c: ColumnInstance<D>[]) => [
        // Let's make a column for selection
        {
          id: 'selection',
          width: TABLE_SELECTION_COLUMN_WIDTH,
          canResize: false,
          flexGrow: 0,
          flexShrink: 0,

          /**
           * render header cell checkbox
           */
          Header: ({ state, data, getRowId }) => {
            if (!getRowId) {
              // TODO: remove once legacy code fix
              logger.error(
                'For useTableSelection hook getRowId props is required'
              );
              return null;
            }

            const selectAllProps = createSelectAllProps({
              state,
              onRowSelection,
              rowIds: data.map((item, index) => getRowId(item, index))
            });

            return (
              <TableCheckbox
                data-cy="all-row-selection-checkbox"
                {...selectAllProps}
              />
            );
          },

          /**
           * render cell checkbox
           */
          Cell: ({ row, getRowId, state }: any) => {
            if (!getRowId) {
              // TODO: remove once legacy code fix
              logger.error(
                'For useTableSelection hook getRowId props is required'
              );
              return null;
            }
            const rowId = getRowId(row.original);

            const cellProps = createCellProps({
              rowId,
              state,
              onRowSelection
            });
            return (
              <TableCheckbox data-cy="row-selection-checkbox" {...cellProps} />
            );
          }
        },
        ...c
      ]);
    }
  ];
}
