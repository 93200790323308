const e = {
  baseStyle: {
    track: {
      _dark: {
        bg: "darkGrey.50",
        _checked: {
          bg: "tigerOrange.600"
        }
      }
    }
  }
};
export {
  e as Switch
};
