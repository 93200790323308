import { handleMentionsRemovedEvent } from './event';
import { mapUsersToSuggestions, transformMentions } from './format';
import { GetUsersProps, HandleChangeProps } from './types';

/**
 * this function returns suggested users
 * fetched from the query based on input change
 */
export async function getUsers({
  fetchSuggestions,
  setIsLoading,
  callback,
  query,
  isLoading
}: GetUsersProps) {
  try {
    if (!isLoading) {
      setIsLoading(true);
    }
    callback(
      mapUsersToSuggestions({
        users: await fetchSuggestions(query).then((data) => {
          setIsLoading(false);
          return data;
        })
      })
    );
  } catch (error) {
    console.error('Error fetching users:', error);
  }
}

export function handleChange({
  rawText,
  onChange,
  mentions,
  newPlainText,
  mentionsPreviousState,
  referenceId
}: HandleChangeProps) {
  onChange?.({
    rawText,
    mentions: transformMentions({ mentions }),
    comment: newPlainText
  });
  handleMentionsRemovedEvent({
    mentionsPreviousState,
    mentions,
    location: window.location.pathname,
    referenceId
  });
}
