import { Box } from '@chakra-ui/react';

import type {
  SharedTranscriptReaderProps,
  TranscriptReaderConfig
} from '../types';

export interface ContentProps
  extends Pick<SharedTranscriptReaderProps, 'config'> {
  markdownComponent: React.FC<
    Pick<TranscriptReaderConfig, 'isDarkMode' | 'fontSize'> & {
      children: string;
    }
  >;
}

export function Content({
  config,
  markdownComponent: Markdown
}: Readonly<ContentProps>) {
  const { isDarkMode, transcript, fontSize } = config;

  if (!transcript) {
    return null;
  }

  return (
    <Box flex="1">
      <Markdown isDarkMode={isDarkMode} fontSize={fontSize}>
        {transcript}
      </Markdown>
    </Box>
  );
}
