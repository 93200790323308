import {
  type Content,
  contentTotalTime,
  formatShortContentDuration
} from '@tigerhall/core';
import { Circle, Flex, Text } from '@chakra-ui/react';
import {
  IconBook,
  IconHeadphones,
  IconMovie,
  IconTicket
} from '@tigerhall/icons';
import { useMemo } from 'react';
import { DateTime } from 'luxon';

export interface MoreInfoTabProps {
  content: Content;
}

export function MoreInfoHead({ content }: Readonly<MoreInfoTabProps>) {
  const { name, __typename: type, publishedAt } = content;

  const formattedPublishedAt = DateTime.fromISO(publishedAt);

  const listenTime = contentTotalTime(content);

  const formattedTime = formatShortContentDuration(listenTime);

  const head = useMemo(() => {
    switch (type) {
      case 'Ebook':
        return { title: 'Power Read', icon: <IconBook width={'14'} /> };
      case 'Podcast':
        return { title: 'Podcast', icon: <IconHeadphones width={'14'} /> };
      case 'Stream':
        return { title: 'Video', icon: <IconMovie width={'14'} /> };
      case 'Event':
        return { title: 'Event', icon: <IconTicket width={'14'} /> };
      default:
        return null;
    }
  }, [type]);

  return (
    <>
      <Flex alignItems={'center'} gap={2} px={6}>
        <Circle background={'tigerOrange.600'} size={5}>
          {head?.icon}
        </Circle>
        <Text
          color={'tigerOrange.600'}
          fontSize={'xs'}
          textTransform={'uppercase'}
        >
          {head?.title}
        </Text>
      </Flex>
      <Flex gap={2} flexDirection="column">
        <Text color={'white'} fontSize={'xl'} fontWeight={'bold'} px={6}>
          {name}
        </Text>
        <Text color={'lightGrey.600'} fontSize={'xs'} px={6}>
          {publishedAt ? `${formattedPublishedAt.toFormat('DD')} | ` : null}
          {formattedTime}
        </Text>
      </Flex>
    </>
  );
}
