import { FormSelectAsync } from '@tigerhall/components';
import { getSelectedOrgId } from 'app/state';
import { useAppSelector } from 'hooks';
import property from 'lodash/property';
import { ValidationRule } from 'react-hook-form';
import { useEffect } from 'react';
import { captureException } from '@sentry/react';

import {
  GetOrgGroupsByOrgIdQuery,
  useGetOrgGroupsByOrgIdLazyQuery
} from './queries';

type OrganisationGroupSelectAsyncProps = {
  name: string;
  label?: string;
  isMulti?: boolean;
  required?: ValidationRule<boolean> | string;
  placeholder?: string;
  /**
   * Hide the label
   * @default false
   */
  hideLabel?: boolean;
};

const LIMIT = 20;

type OrgGroups = GetOrgGroupsByOrgIdQuery['organisation']['orgGroups']['edges'];

export function OrganisationGroupSelectAsync({
  name,
  label = undefined,
  required = false,
  isMulti = false,
  placeholder,
  hideLabel
}: Readonly<OrganisationGroupSelectAsyncProps>) {
  const org = useAppSelector(getSelectedOrgId);

  const [fetchOrgGroups, { data, error, loading, fetchMore }] =
    useGetOrgGroupsByOrgIdLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        organisationId: org as string,
        filter: {
          limit: LIMIT
        }
      }
    });

  useEffect(() => {
    fetchOrgGroups().catch((e) => {
      captureException(e);
    });
  }, [fetchOrgGroups]);

  if (error) {
    return null;
  }

  const meta = data?.organisation?.orgGroups.meta || {
    total: 0,
    offset: 0,
    limit: 0
  };

  function onMenuScrollToBottom() {
    const hasFetchMore = !loading && meta?.total > meta?.offset + meta?.limit;

    if (hasFetchMore) {
      fetchMore({
        variables: {
          id: org as string,
          filter: {
            offset: meta.offset + meta.limit,
            limit: LIMIT
          }
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          return {
            organisation: {
              ...prev.organisation,
              orgGroups: {
                ...prev.organisation.orgGroups,
                edges: [
                  ...prev.organisation.orgGroups.edges,
                  ...fetchMoreResult.organisation.orgGroups.edges
                ],
                meta: fetchMoreResult.organisation.orgGroups.meta
              }
            }
          };
        }
      }).catch((e) => {
        captureException(e);
      });
    }
  }

  return (
    <FormSelectAsync
      name={name}
      placeholder={placeholder ?? 'Select group'}
      label={label ?? (isMulti ? 'Groups' : 'Group')}
      hideLabel={hideLabel}
      isMulti={isMulti}
      rules={{ required }}
      onMenuScrollToBottom={onMenuScrollToBottom}
      getOptionLabel={property('name')}
      getOptionValue={property('id')}
      defaultOptions={data?.organisation?.orgGroups.edges || []}
      isLoading={loading}
      hideSelectedOptions={false}
      loadOptions={(input: string, callback: (options: OrgGroups) => void) => {
        fetchOrgGroups({
          variables: {
            organisationId: org as string,
            filter: {
              name: input,
              limit: LIMIT
            }
          }
        })
          .then(({ data: newResults }) => {
            callback(newResults?.organisation?.orgGroups.edges || []);
          })
          .catch((e) => {
            captureException(e);
          });
      }}
    />
  );
}
