import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Icon,
  Text
} from '@chakra-ui/react';
import { contentTotalTime, formatShortContentDuration } from '@tigerhall/core';
import { IconRoute } from '@tigerhall/icons';
import { RichTextMarkdownWrapper } from 'components/ui/MarkdownWrapper/RichTextMarkdownWrapper';

export function RightPanel({ content }) {
  return (
    <>
      <Flex gap="0.5rem" mb="1rem">
        <Icon as={IconRoute} color="white" />
        <Text fontSize="0.75rem" fontWeight="700" color="white">
          In this Trail
        </Text>
      </Flex>

      <Accordion>
        {content.contentCards.map((c: any) => {
          const firstExpert = c.experts[0];

          return (
            <AccordionItem border="0" key={c.id}>
              <Flex direction="row">
                {/* todo: this is not the right icon */}
                <AccordionIcon color="tigerOrange.600" mt="0.5rem" />
                <AccordionButton flexDirection="column" alignItems="flex-start">
                  <Text
                    fontSize="0.875rem"
                    fontWeight="700"
                    color="white"
                    as="h5"
                    textAlign="left"
                  >
                    {c.name}&nbsp;
                    <Text as="span" fontSize="inherit" fontWeight="400">
                      ({formatShortContentDuration(contentTotalTime(c))})
                    </Text>
                  </Text>
                  <Text
                    fontSize="0.75rem"
                    fontWeight="500"
                    color="white"
                    textAlign="left"
                    as="span"
                  >
                    {firstExpert.firstName} {firstExpert.lastName} |{' '}
                    {firstExpert.title} | {firstExpert.company}
                  </Text>
                </AccordionButton>
              </Flex>
              <AccordionPanel pl="2.125rem">
                <RichTextMarkdownWrapper fontSize={14}>
                  {c.preamble}
                </RichTextMarkdownWrapper>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </>
  );
}
