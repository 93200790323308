import { Box, Text, type BoxProps, type TextProps } from '@chakra-ui/react';
import { useCallback, useRef, useState } from 'react';

interface MarqueeProps
  extends Pick<
      TextProps,
      | 'children'
      | 'as'
      | 'fontStyle'
      | 'fontWeight'
      | 'fontSize'
      | 'fontFamily'
      | 'lineHeight'
      | 'textAlign'
      | 'variant'
      | 'color'
    >,
    Pick<BoxProps, 'width' | 'height'> {
  /** The delay in milliseconds before the animation starts */
  delay?: number;
}

/**
 * Marquee text component.
 * If the text fits in its container, it will not animate, but if it overflows, then it will animate.
 *
 * The animation consists of the text moving from right to left until the end of the text is reached.
 * then it will pause for 2 seconds before moving back to the beginning of the text, and repeat.
 * The animation will pause on hover and resume on mouse leave.
 */
export function Marquee({
  children,
  width,
  height,
  delay = 2000,
  ...props
}: MarqueeProps) {
  const [textContent, setTextContent] = useState<string | undefined>();

  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<Animation>();

  const calculateOverflowSize = useCallback(() => {
    const containerNode = containerRef.current;
    const textNode = textRef.current;
    if (containerNode) {
      const { width: containerWidth } = containerNode.getBoundingClientRect();
      const { scrollWidth: textWidth } = containerNode.children[0];
      const overflowSize = textWidth - containerWidth;

      if (textNode && overflowSize > 0) {
        const marqueeKeyframes = [
          { transform: 'translateX(0px)' },
          // we add extra `24px` to ensure all the text is visible during the animation
          { transform: `translateX(-${overflowSize + 24}px)` }
        ];

        const marqueeTimingOptions = {
          // `0.03` is the sweet spot to ensure the animation speed is constant no matter the text length
          duration: overflowSize / 0.03,
          iterations: 2,
          direction: 'alternate',
          easing: 'linear',
          delay,
          endDelay: 2000
        } as const;

        /**
         * native `animate()` is used instead of framer-motion because it is more performant
         * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/animate
         */
        animationRef.current = textNode.animate(
          marqueeKeyframes,
          marqueeTimingOptions
        );
      }
    }
  }, [delay]);

  if (textContent !== textRef.current?.innerText) {
    /**
     * This condition verifies whether the text has been replaced.
     * If a replacement has occurred, it cancels the ongoing animation by setting animationRef to undefined.
     * Subsequently, it recalculates the overflowSize
     * and re-establishes the animation based on the updated overflow size.
     * */

    animationRef.current?.cancel();
    animationRef.current = undefined;
    setTextContent(textRef?.current?.innerText);
    calculateOverflowSize();
  }

  return (
    <Box
      ref={containerRef}
      overflow={'hidden'}
      whiteSpace={'nowrap'}
      width={width}
      height={height}
      /**
       * Line height is set to `1.135` to address the issue in the UpNext Tab.
       * When clicking on any queueItem, the content moves up and down due to
       * the div having its own space. Attempted using the value used for text, which is `short` (equals `1.375`),
       * but it did not resolve the issue.
       *
       * Tested on both mobile and laptop screens; it appears to be working fine.
       *
       * This value won't be reused, which is why it hasn't been added to the theme.
       */

      lineHeight={1.135}
    >
      <Text
        ref={textRef}
        display={'inline-block'}
        onMouseEnter={() => {
          if (animationRef.current) {
            animationRef.current.pause();
          }
        }}
        onMouseLeave={() => {
          if (animationRef.current) {
            animationRef.current.play();
          }
        }}
        {...props}
      >
        {children}
      </Text>
    </Box>
  );
}
