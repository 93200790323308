import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from './image.generated';
import { CategoryFragmentDoc } from './category.generated';
export type ExpertFragment = { __typename?: 'Expert', id: string, title: string, firstName: string, lastName: string, biography: string, createdAt: any, updatedAt: any, mentorAvailableText?: string | null, mentorPriceText?: string | null, isMentor: boolean, isAuthenticatedUserFollowing: boolean, company?: string | null, userId?: string | null, image: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string }, categories: Array<{ __typename?: 'Category', id: string, name: string, image?: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string } | null }> };

export const ExpertFragmentDoc = gql`
    fragment Expert on Expert {
  id
  title
  firstName
  lastName
  biography
  createdAt
  updatedAt
  mentorAvailableText
  mentorPriceText
  isMentor
  isAuthenticatedUserFollowing
  company
  image {
    ...Image
  }
  categories {
    ...Category
  }
  userId
}
    ${ImageFragmentDoc}
${CategoryFragmentDoc}`;