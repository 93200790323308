import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  EntryLevelValue,
  StreamInput,
  StreamSource,
  User
} from '@tigerhall/core/lib/types';
import { useAppSelector } from 'hooks';
import { getSelectedOrgId } from 'app/state';
import { useToast } from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { useFetchStreamQuery } from 'generated';

import { Form, FormValues } from './components/Form';
import { useManageBroadcast } from './hooks';

export function BroadcastForm() {
  const { idOrDraft } = useParams();

  const orgId = useAppSelector(getSelectedOrgId);

  const [hasError, setHasError] = React.useState(false);

  const toast = useToast();

  const isDraft = idOrDraft === 'draft';

  const { create, update } = useManageBroadcast({
    updateStreamId: !isDraft ? idOrDraft : undefined
  });

  const { data, loading, error } = useFetchStreamQuery({
    variables: {
      id: idOrDraft as string
    },

    skip: isDraft
  });

  const onSubmit = React.useCallback(
    async (values: FormValues, form: UseFormReturn<FormValues>) => {
      // reset error state before submitting
      if (hasError) {
        setHasError(false);
      }

      if (!form) {
        toast({
          description: 'form is not found'
        });
        return;
      }

      const { setError } = form;

      const input: StreamInput = {
        name: values.name,
        preamble: values.preamble,

        contentSegments: values?.contentSegments.map((s) => ({
          header: s?.header,
          paragraph: s?.paragraph
        })),

        image: values.image?.id ?? '',
        experts: values.experts.map((e) => e?.id),
        categories: values.categories.map((c) => c?.id),

        organisationID: orgId,
        organisationGroups: values.organisationGroups?.map((g) => g?.id) || [],

        isSensitive: false,
        entryLevel: EntryLevelValue.Beginner,

        // todo: do we want to support AWS IVS?
        source: StreamSource.BroadcastCloudflareLive,
        agenda: values?.agenda?.map((a) => ({
          topic: a.topic,
          description: a.description,
          speakers: a.speakers.map((u: Pick<User, 'id'>) => u.id),
          estimatedStartTime: a.estimatedStartTime,
          isActive: a.isActive ?? false
        }))
      };

      if (isDraft) {
        await create(input, setError);
      } else {
        await update(input, setError);
      }
    },
    [hasError, orgId, isDraft, create, update, toast]
  );

  if (loading || error || !orgId) {
    return null;
  }

  const defaultValues = isDraft
    ? { contentSegments: [{ header: '', paragraph: '' }] }
    : {
        ...data?.stream,
        status: { value: data?.stream?.status, label: data?.stream?.status },
        agenda: data?.stream?.agenda?.map((a) => ({
          ...a,
          estimatedStartTime: new Date(a.estimatedStartTime)
        }))
      };

  return (
    <Form
      isDraft={isDraft}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      includePublicExperts={false}
    />
  );
}
