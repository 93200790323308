import {
  Avatar,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  VStack,
  useToast
} from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import * as segment from '@tigerhall/analytics';
import { TrackedButton, TrackedRTForm } from '@tigerhall/components';
import { Flags, resizeImage } from '@tigerhall/core';
import { cache } from 'api/apollo';
import {
  getPost,
  resetPost,
  setDescription,
  setMentions
} from 'app/state/post';
import { useCreatePostMutation, useGetCurrentUserQuery } from 'generated';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useCallback, useEffect, useState, useTransition } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getPreviousRoute } from 'utils';
import debounce from 'lodash/debounce';
import { useFlag } from '@unleash/proxy-client-react';

import { PostTextContent } from './components/PostTextContent';
import { PrivacyOptions } from './components/PrivacyOptions';
import { AttachedPost } from './components/AttachedPost';
import { NameWithBadge } from '~/components/ui/User/NameWithBadge';
import { getParsedText } from '~/components/ui/ParsedText/utils/getParsedText';
import { PostLinkPreview } from '../PostLinkPreview';
import { extractMentions } from '~/utils/extractMentions';

const MAX_POST_LENGTH = 2000;

type FormFields = {
  description: string;
};

export interface CreatePostModalProps
  extends Omit<ModalProps, 'children' | 'isOpen' | 'onClose' | 'onOpen'> {}

export function CreatePostModal(props: Readonly<CreatePostModalProps>) {
  const navigate = useNavigate();
  const postDraft = useAppSelector(getPost);
  const dispatch = useAppDispatch();

  const isUrlPreviewEnabled = useFlag(Flags.UrlPreview);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, startTransition] = useTransition();

  const [urls, setUrls] = useState<string[]>([]);

  const [createPostMutation] = useCreatePostMutation();

  const { data } = useGetCurrentUserQuery();
  const user = data?.me;

  const toast = useToast({
    duration: 2000
  });

  const userImageUrl = user?.avatarImage?.uri ?? '';
  const userFullName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
  const userRoles = user?.roles ?? [];

  const onClose = useCallback(() => {
    const { prevRoute, replace } = getPreviousRoute({
      fallback: '/feed'
    });

    // clean up post draft if it is a repost with thoughts
    if (postDraft.parentPost) {
      dispatch(resetPost());
    }

    if (typeof prevRoute === 'string') {
      navigate(prevRoute, { replace });
    } else {
      navigate(prevRoute);
    }
  }, [dispatch, navigate, postDraft.parentPost]);

  const setUrlsOnDescriptionChange = useCallback(
    debounce<(args: string) => void>((args) => {
      const parsedText = getParsedText(args);
      const parsedUrls = parsedText.filter((each) => each.type === 'LINK');
      const urlsForPreview = parsedUrls.map((each) => each.url).filter(Boolean);
      setUrls(urlsForPreview);
    }, 500),
    []
  );

  const handleDescriptionChange = useCallback(
    (values: FormFields, form: UseFormReturn<FormFields>) => {
      const rawText = values.description;
      const { mentions } = extractMentions(rawText);
      setUrlsOnDescriptionChange(rawText);
      if (rawText.length > MAX_POST_LENGTH) {
        form.setError('description', {
          type: 'maxLength',
          message: `You have reached the limit of ${MAX_POST_LENGTH} characters`
        });
        setIsDisabled(true);
        return;
      }

      startTransition(() => {
        form.clearErrors('description');
        setIsDisabled(false);
        dispatch(setMentions(mentions));
        dispatch(setDescription(rawText));
      });
    },
    [dispatch, setUrlsOnDescriptionChange]
  );

  const handleCreatePost = useCallback(async () => {
    if (!postDraft.description?.trim() || isDisabled || isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      toast({
        title: 'Submitting',
        description: 'Please wait while we create your post',
        status: 'loading'
      });

      await createPostMutation({
        variables: {
          input: {
            ...postDraft,
            description: postDraft.description.trim()
          }
        }
      });

      cache.evict({ fieldName: 'feedV2' });
      cache.gc();

      onClose();
      startTransition(() => {
        toast.closeAll();
        toast({
          title: 'Success',
          description: 'Your post has been created',
          status: 'success'
        });
        dispatch(resetPost());
      });
    } catch (error) {
      captureException(new Error('Error creating a post'), {
        extra: {
          values: postDraft,
          error
        }
      });

      startTransition(() => {
        toast.closeAll();
        toast({
          title: 'Error',
          description:
            'An error occurred trying to create your post. Please try again later.',
          status: 'error'
        });
      });
    } finally {
      startTransition(() => {
        setIsLoading(false);
      });
    }
  }, [
    createPostMutation,
    dispatch,
    isDisabled,
    isLoading,
    onClose,
    postDraft,
    toast
  ]);

  useEffect(() => {
    segment.feedPostStarted({});
  }, []);

  return (
    <Modal size="xl" isOpen={true} onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent border="1px solid" borderColor="darkGrey.300">
        <ModalHeader color="lightGrey.200">Create Post</ModalHeader>
        <Divider mx="1rem" width="calc(100% - 2rem)" />
        <ModalCloseButton />
        <HStack px="1.5rem" pt="1rem">
          <Avatar
            src={resizeImage({
              url: userImageUrl,
              width: 88,
              height: 88
            })}
            name={userFullName}
            showBorder={true}
            borderWidth="2px"
            borderColor="darkGrey.300"
            size="md"
          />
          <VStack alignItems="flex-start" spacing="0.25rem">
            <NameWithBadge
              userId={user?.id || ''}
              disableLink
              spacing={1}
              fontSize="md"
              fontWeight="bold"
              color="lightGrey.200"
              noOfLines={1}
              badgeProps={{
                size: 'lg'
              }}
            />
            <HStack spacing="0.375rem">
              <PrivacyOptions roles={userRoles} />
            </HStack>
          </VStack>
        </HStack>

        <ModalBody pt={0} px="0.875rem" minHeight="20rem">
          <VStack spacing="1rem" pt="0.5rem" alignItems={'flex-start'}>
            <TrackedRTForm<FormFields>
              name="CREATE_POST_FORM"
              focus="description"
              formHookProps={{
                defaultValues: {
                  description: postDraft.description ?? ''
                }
              }}
              onSubmit={handleDescriptionChange}
            >
              <PostTextContent />
            </TrackedRTForm>
            {isUrlPreviewEnabled ? <PostLinkPreview urls={urls} /> : null}

            {postDraft.parentPost ? (
              <AttachedPost postId={postDraft.parentPost} />
            ) : null}
          </VStack>
        </ModalBody>
        <Divider mx="1rem" width="calc(100% - 2rem)" />
        <ModalFooter>
          <HStack width="100%">
            <TrackedButton
              name="CREATE_POST_SUBMIT_BUTTON"
              size="lg"
              width="100%"
              isLoading={isLoading}
              isDisabled={isDisabled}
              onClick={handleCreatePost}
            >
              Post
            </TrackedButton>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
