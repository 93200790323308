import { lazy, Suspense, useEffect } from 'react';
import { Navigate, RouteObject, useNavigate } from 'react-router-dom';
import { useBeforeUnload } from '@tigerhall/core';

import { ErrorBoundary } from '~/components/tools/ErrorBoundary';
import { Splash } from '~/components/ui/Layout/components';
import { useReportBuilderWizardStore } from './stores';
import { ReportBuilderRoutes, ReportBuilderSteps } from './types';

const ReportTemplate = lazy(() =>
  import('./screens').then((m) => ({ default: m.ReportTemplate }))
);

const SelectYourCriteria = lazy(() =>
  import('./screens').then((m) => ({ default: m.SelectYourCriteria }))
);

const TableColumnsAndFilter = lazy(() =>
  import('./screens').then((m) => ({ default: m.TableColumnsAndFilter }))
);

const ReviewAndExport = lazy(() =>
  import('./screens').then((m) => ({ default: m.ReviewAndExport }))
);

function RouteGuard({ children }) {
  useBeforeUnload(true);

  const navigate = useNavigate();

  const selectedTemplate = useReportBuilderWizardStore(
    (state) => state.templateId
  );

  useEffect(() => {
    if (!selectedTemplate) {
      navigate('/admin/report-builder/template', { replace: true });
    }
  }, [selectedTemplate, navigate]);

  if (!selectedTemplate) {
    return null;
  }

  return children;
}

const routes: RouteObject[] = [
  {
    path: 'report-builder',
    // we don't have screen for report-builder page(probably in v2)
    // and we also want sidebar selected option highlighted
    element: <Navigate to={ReportBuilderSteps.Template} replace />
  },
  {
    path: ReportBuilderRoutes.TemplateStep,
    element: (
      <ErrorBoundary>
        <Suspense fallback={<Splash />}>
          <ReportTemplate />
        </Suspense>
      </ErrorBoundary>
    )
  },
  {
    path: ReportBuilderRoutes.SelectYourCriteria,
    element: (
      <ErrorBoundary>
        <Suspense fallback={<Splash />}>
          <RouteGuard>
            <SelectYourCriteria />
          </RouteGuard>
        </Suspense>
      </ErrorBoundary>
    )
  },
  {
    path: ReportBuilderRoutes.ColumnsAndFilterStep,
    element: (
      <ErrorBoundary>
        <Suspense fallback={<Splash />}>
          <RouteGuard>
            <TableColumnsAndFilter />
          </RouteGuard>
        </Suspense>
      </ErrorBoundary>
    )
  },
  {
    path: ReportBuilderRoutes.ReviewAndExportStep,
    element: (
      <ErrorBoundary>
        <Suspense fallback={<Splash />}>
          <RouteGuard>
            <ReviewAndExport />
          </RouteGuard>
        </Suspense>
      </ErrorBoundary>
    )
  }
];

export default routes;
