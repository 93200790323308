import { CommentType } from '@tigerhall/core/lib/types';
import { MODALTYPES } from 'components/ui/modals';

export const redirectToUserFollowingFollwerRoute = (userId: string) =>
  `/profile/${userId}/follower-following`;
export const redirectToContentCardRoute = (): string => '/profile/content-card';
export const redirectToEditProfile = (): string => '/profile/edit';
export const redirectToProfile = (): string => '/profile';
export const redirectToUserProfile = (userId: string): string =>
  `/profile/${userId}`;

export const contentRoute = (id: string) => `?content=${id}`;
export const learningPathRoute = (id: string) => `?trail=${id}`;
export const livestreamPlayerRoute = (id: string) => `?livestream=${id}`;
export const powerReadModal = (id: string) => `?power-read=${id}`;
export const commonModal = (modalType: MODALTYPES) => `?modal=${modalType}`;
export const assignmentRoute = (id: string) => `?assignment=${id}`;

interface CommentRepliesRouteProps {
  commentId: string;
  referenceId: string;
  referenceType?: Exclude<CommentType, CommentType.Feed>;
}

export function getCommentRepliesRoute({
  commentId,
  referenceId,
  referenceType
}: CommentRepliesRouteProps) {
  return referenceType === CommentType.Post
    ? `/feed/${referenceId}/comment/${commentId}`
    : `?content=${referenceId}&comment=${commentId}`;
}
