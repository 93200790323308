import { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Flex,
  LightMode,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  Text,
  useToast
} from '@chakra-ui/react';
import { FormInput, FormSelect, TrackedForm } from '@tigerhall/components';
import {
  type Comment,
  type Content,
  type LearningPath,
  type Post,
  REPORT_CONTENT_REASONS,
  REPORT_POST_AND_COMMENT_REASONS,
  ReportableContentType,
  type User,
  getReportableContentType
} from '@tigerhall/core';
import property from 'lodash/property';
import { captureException } from '@sentry/react';
import { useReportContentMutation } from 'generated';

export interface ReportModalProps {
  isOpen: ModalProps['isOpen'];
  onClose: ModalProps['onClose'];
  contentId: Content['id'];
  contentTypeName:
    | Content['__typename']
    | LearningPath['__typename']
    | Comment['__typename']
    | Post['__typename']
    | User['__typename'];
  heading?: string;
  subheading?: string;
}

export function ReportModal({
  isOpen,
  onClose,
  contentId,
  contentTypeName,
  heading,
  subheading
}: ReportModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [reportContentMutation] = useReportContentMutation();
  const toast = useToast({
    duration: 5000
  });

  const reportReasons = useMemo(
    () =>
      contentTypeName === 'Comment' || contentTypeName === 'Post'
        ? REPORT_POST_AND_COMMENT_REASONS
        : REPORT_CONTENT_REASONS,
    [contentTypeName]
  );

  const shouldAskForReason = contentTypeName !== 'User';
  const formInputLabel =
    contentTypeName === 'User'
      ? {
          placeholder: 'Enter reason',
          label: 'Reason'
        }
      : {
          placeholder: 'Enter comment',
          label: 'Comment/Please elaborate on selected reason'
        };

  const onSubmit = useCallback(
    async (values) => {
      try {
        toast({
          title: 'Submitting',
          description: 'Please wait while we submit your report',
          status: 'loading',
          duration: 2000
        });
        setIsLoading(true);
        const reportContentTypeName = getReportableContentType(contentTypeName);
        await reportContentMutation({
          variables: {
            contentID: contentId,
            contentType: reportContentTypeName,
            reason:
              reportContentTypeName === ReportableContentType.User
                ? ''
                : values.reason.label,
            comment: values.comment
          }
        });
        toast.closeAll();
        toast({
          title: 'Success',
          description: `${contentTypeName} reported successfully`,
          status: 'success'
        });
      } catch (error) {
        toast.closeAll();
        toast({
          title: 'Error',
          description: `${contentTypeName} could not be reported. Please try again later.`,
          status: 'error'
        });
        captureException(new Error(`Error reporting content`), {
          level: 'error',
          tags: {
            contentId: contentId,
            contentType: contentTypeName
          },
          extra: { values, error }
        });
      } finally {
        setIsLoading(false);
        onClose();
      }
    },
    [reportContentMutation, contentId, contentTypeName, toast, onClose]
  );

  return (
    <LightMode>
      <Modal
        isOpen={isOpen}
        size={{ base: 'sm', md: 'md' }}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent border="1px solid" borderColor="lightGrey.200" p={0}>
          <ModalCloseButton />
          <ModalHeader
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap="2rem"
            justifyContent="center"
            pt="3rem"
          >
            {heading ?? 'Report an Issue'}
          </ModalHeader>
          <ModalBody>
            <Text fontSize="md" color="black" textAlign="center" mb="1rem">
              {subheading ??
                'Please choose the relevant reason for reporting content'}
            </Text>

            <TrackedForm name="reportContent" onSubmit={onSubmit}>
              <Flex direction="column" gap="1.5rem">
                {shouldAskForReason ? (
                  <FormSelect
                    name="reason"
                    options={reportReasons}
                    label="Reason"
                    getOptionLabel={property('label')}
                    getOptionValue={property('label')}
                    placeholder="Select"
                    rules={{
                      required: true
                    }}
                  />
                ) : null}
                <FormInput
                  name="comment"
                  placeholder={formInputLabel.placeholder}
                  type="text"
                  label={formInputLabel.label}
                />
              </Flex>
              <Flex gap="1rem" mt="1.5rem">
                <Button
                  name="cancel"
                  variant="outlineDark"
                  onClick={() => {
                    onClose();
                  }}
                  size="lg"
                  flex={1}
                  isLoading={isLoading}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  variant="solidDark"
                  size="lg"
                  flex={1}
                  isLoading={isLoading}
                >
                  Submit
                </Button>
              </Flex>
            </TrackedForm>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </LightMode>
  );
}
