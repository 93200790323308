import { ReactNode } from 'react';
import { IconShare3 } from '@tigerhall/icons';
import {
  Button,
  Icon,
  type IconProps,
  ButtonProps,
  useDisclosure
} from '@chakra-ui/react';

import { ShareModal } from './ShareModal';
import { useShareModal } from './useShareModal';
import type { ShareableContent } from './useShareModal';

export interface ShareProps extends Omit<ButtonProps, 'onClick'> {
  /**
   * URL that will be shared. It should also contain the `host.
   * @example `https://tigerhall.com/content/podcasts/my-favorite-podcast`
   */
  urlToShare: string;

  /**
   * Title that will be shared
   */
  titleToShare: string;

  /**
   * Description that will be shared
   */
  descriptionToShare: string;

  /**
   * Twitter user name for twitter share
   */
  twitterUsername?: string;

  canCopyToClipboard?: boolean;

  /**
   * Icon or text that will be wrapped in the share button.
   * By default it uses the `IconShare3`.
   */
  children?: ReactNode;

  content?: ShareableContent;

  iconProps?: IconProps;
}

/**
 * This component uses the Share API when is possible. If the
 * Share API is not available, a modal will appear with the default
 * options to share.
 *
 * Learn more about the features used here:
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
 * @see https://chakra-ui.com/docs/components/modal/usage
 */
export function Share({
  urlToShare,
  titleToShare,
  descriptionToShare,
  twitterUsername,
  children,
  content,
  iconProps,
  ...rest
}: ShareProps) {
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: false
  });

  const { isAvailable, openShareModal } = useShareModal({
    urlToShare,
    title: titleToShare || window?.document?.title || '',
    description: descriptionToShare,
    content,
    onOpen
  });

  if (!isAvailable) {
    return null;
  }

  return (
    <>
      <Button
        variant="unstyled"
        display="flex"
        size="sm"
        fontSize="16"
        borderRadius="md"
        aria-label="Share"
        onClick={openShareModal}
        {...rest}
      >
        {children || <Icon as={IconShare3} {...iconProps} />}
      </Button>
      <ShareModal
        isOpen={isOpen}
        onClose={onClose}
        titleToShare={titleToShare}
        urlToShare={urlToShare}
        twitterUsername={twitterUsername}
      />
    </>
  );
}
