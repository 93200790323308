import { useTransition } from 'react';
import * as segment from '@tigerhall/analytics';
import { IconRepeat } from '@tigerhall/icons';
import { useToast } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { OptionsMenuItem } from 'components/ui/OptionsMenu';

import { useCreateInstantRepostMutation } from '../queries/generated/repost.generated';

export interface InstantRepostProps {
  postId: string;
}

export function InstantRepost({ postId }: InstantRepostProps) {
  const [, startTransition] = useTransition();
  const toast = useToast({
    duration: 2000
  });

  const [createInstantRepostMutation] = useCreateInstantRepostMutation();

  async function handleInstantRepost() {
    try {
      segment.repostClicked({
        buttonName: 'INSTANT_REPOST'
      });

      toast({
        title: 'Reposting',
        description: 'Please wait while we create your repost',
        status: 'loading'
      });

      await createInstantRepostMutation({
        variables: {
          postId
        }
      });

      startTransition(() => {
        toast.closeAll();
        toast({
          title: 'Success',
          description:
            'Your repost has been created. Refresh the feed to see it.',
          status: 'success'
        });
      });
    } catch (error) {
      captureException(new Error('Error creating a repost'), {
        tags: {
          postId: postId
        },
        extra: { error }
      });

      startTransition(() => {
        toast.closeAll();
        toast({
          title: 'Error',
          description:
            'An error occurred trying to create your repost. Please try again later.',
          status: 'error'
        });
      });
    }
  }

  return (
    <OptionsMenuItem
      icon={IconRepeat}
      title="Repost"
      description="Instantly bring this post to your followers’ feeds"
      onClick={handleInstantRepost}
    />
  );
}
