import React from 'react';

type Size = { height: number; width: number };

const useImageCanvas = (
  canvasRef: React.RefObject<
    HTMLCanvasElement & { canvasImage?: HTMLImageElement }
  >,
  image: string,
  {
    onLoad
  }: {
    onLoad: ({
      imageSize,
      boundingSize
    }: {
      imageSize: Size;
      boundingSize: Size;
    }) => void;
  }
) => {
  /** Loading image on canvas when image url or crop size change **/
  React.useEffect(() => {
    const img = new Image();
    if (!canvasRef?.current) {
      return;
    }
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvasEL = canvasRef.current;
      if (!canvasEL || canvasEL?.canvasImage) {
        return;
      }
      const imageSize = { width: img.width, height: img.height };
      const boundingSize = canvasEL?.parentElement?.getBoundingClientRect();
      if (!boundingSize) {
        return;
      }
      const context = canvasEL?.getContext('2d');
      canvasEL.canvasImage = img;

      onLoad({ imageSize, boundingSize });

      context?.drawImage(canvasEL.canvasImage, 0, 0);
    };
    img.src = image;
  }, [canvasRef, image, onLoad]);
};

export default useImageCanvas;
