import { useToast } from '@chakra-ui/react';
import { Event } from '@tigerhall/core/lib/types';
import { useUserTrackContentToggleAttendingMutation } from 'generated';

export function useEventAction() {
  const toast = useToast();
  const [userTrackContentToggleAttendingMutation, { loading }] =
    useUserTrackContentToggleAttendingMutation();

  const bookEvent = async (content: Pick<Event, 'id'>) => {
    try {
      await userTrackContentToggleAttendingMutation({
        variables: {
          id: content?.id
        }
      });
    } catch (err: any) {
      toast({
        status: 'error',
        title: err?.message
      });
    }
  };
  return {
    bookEvent,
    loading
  };
}
