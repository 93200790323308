import { RouteObject } from 'react-router-dom';

import TrailHistory from './screens/TrailHistory';
import { TrailDetails } from './screens/TrailDetails';
import { TrailContent } from './screens/TrailContent';
import { TrailSummary } from './screens/TrailSummary';

const routes: RouteObject[] = [
  {
    path: 'trails/',
    children: [
      {
        index: true,
        element: <TrailHistory />
      },
      {
        path: ':idOrDraft/contents',
        element: <TrailContent />
      },
      {
        path: ':idOrDraft/details',
        element: <TrailDetails />
      },
      {
        path: ':idOrDraft/summary',
        element: <TrailSummary />
      }
    ]
  }
];

export default routes;
