import { Button, Icon, Stack, Text, VStack, useToast } from '@chakra-ui/react';
import {
  FormCheckbox,
  FormInput,
  FormPassword,
  TrackedButton,
  TrackedForm,
  TrackedLink
} from '@tigerhall/components';
import { IconMail } from '@tigerhall/icons';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateUserV2Mutation, useLoginMutation } from 'generated';
import {
  getFreeTrialUserEmail,
  setAccessToken,
  setFreeTrialEmail
} from 'app/state';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useStructuredErrors } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import { captureException } from '@sentry/react';

type FormFields = {
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  password: string;
  isMoreInfoSubscribed: boolean;
  isNewsLetterSubscribed: boolean;
};

export function FreeTrialSignUpForm() {
  const navigate = useNavigate();
  const [createUser] = useCreateUserV2Mutation();
  const [loginUser] = useLoginMutation();
  const freeTrialUserEmail = useAppSelector(getFreeTrialUserEmail);
  const dispatch = useAppDispatch();
  const toast = useToast({ status: 'error' });

  const { handleMutationError, captureMutationError } = useStructuredErrors();

  const handleSignUpFormNext = useCallback(
    async (values: FormFields, { setError }) => {
      const {
        firstName,
        lastName,
        email,
        password,
        company,
        isMoreInfoSubscribed,
        isNewsLetterSubscribed
      } = values;
      try {
        const { data } = await createUser({
          variables: {
            input: {
              email,
              password,
              company,
              firstName,
              lastName
            }
          }
        });

        if (!data?.createUserV2) {
          throw new Error(
            'User creation failed: No user data received from createUser mutation'
          );
        }

        captureMutationError(data?.createUserV2, setError, (description) =>
          toast({ description })
        );

        const { data: loginData } = await loginUser({
          variables: {
            email,
            password
          }
        });

        if (loginData?.loginEmail?.success) {
          const {
            loginEmail: { jwt }
          } = loginData;
          dispatch(setAccessToken({ token: jwt }));
        }
        dispatch(setFreeTrialEmail(''));

        segment.userCreated({
          firstName,
          lastName,
          email,
          company,
          sendMoreInfo: isMoreInfoSubscribed,
          optInForNewsletters: isNewsLetterSubscribed
        });

        navigate('/signup/individual/welcome');
      } catch (error) {
        if (handleMutationError(error)) {
          return;
        }

        // handle unhandled error
        captureException(error, {
          extra: {
            email
          }
        });

        toast({
          description: 'An unexpected error has occurred'
        });
      }
    },
    [
      createUser,
      dispatch,
      loginUser,
      navigate,
      captureMutationError,
      handleMutationError,
      toast
    ]
  );

  return (
    <TrackedForm<FormFields>
      name="freeTrialSignupForm"
      onSubmit={handleSignUpFormNext}
      focus="firstName"
      formHookProps={{
        defaultValues: {
          email: freeTrialUserEmail,
          firstName: '',
          lastName: '',
          password: '',
          company: '',
          isMoreInfoSubscribed: false,
          isNewsLetterSubscribed: false
        }
      }}
    >
      {({ formState: { isSubmitting } }) => {
        return (
          <VStack spacing="2.5rem">
            <FormInput
              rules={{
                required: true
              }}
              label=""
              name="email"
              placeholder="Email"
              disabled
              leftAddon={
                <Icon
                  as={IconMail}
                  color="lightGrey.900"
                  width="1.25rem"
                  height="1.25rem"
                />
              }
              rightAddon={
                <Button
                  name="editEmail"
                  color="lightGrey.900"
                  fontSize="1rem"
                  fontWeight="medium"
                  variant="unstyled"
                  onClick={() => {
                    segment.editEmailClicked({});
                    navigate(-1);
                  }}
                >
                  Edit
                </Button>
              }
            />
            <VStack spacing="2rem">
              <FormInput
                rules={{
                  required: true
                }}
                label="First Name"
                name="firstName"
                placeholder="Enter Your First Name"
              />
              <FormInput
                rules={{
                  required: true
                }}
                label="Last Name"
                name="lastName"
                placeholder="Enter Your Last Name"
              />
              <FormInput
                rules={{
                  required: true
                }}
                label="Company"
                name="company"
                placeholder="Enter Company Name"
              />
              <FormPassword
                label="Create a Password"
                rules={{
                  required: true
                }}
                name="password"
                placeholder="Enter Password"
              />
              <Stack spacing="1rem">
                <FormCheckbox
                  name="isMoreInfoSubscribed"
                  text="I'm interested in finding out more about Tigerhall for my team or organization!"
                />
                <FormCheckbox
                  name="isNewsLetterSubscribed"
                  text="Include me in any newsletters or marketing emails"
                />
              </Stack>
            </VStack>
            <VStack w="100%" spacing="1rem">
              <TrackedButton
                variant="solid"
                type="submit"
                size="lg"
                name="signUpSubmit"
                isLoading={isSubmitting}
                borderRadius="full"
                w="100%"
              >
                Next
              </TrackedButton>
              <Text fontSize="xs" textAlign="center">
                By signing up, you agree to our
                <TrackedLink
                  name="termAndConditions"
                  ml={1}
                  color="tigerOrange.600"
                  href="https://tigerhall.com/terms-of-service"
                  isExternal
                >
                  Terms & Conditions.
                </TrackedLink>
              </Text>
            </VStack>
          </VStack>
        );
      }}
    </TrackedForm>
  );
}
