import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text
} from '@chakra-ui/react';
import { IconX } from '@tigerhall/icons';
import { useCallback, useEffect, useState } from 'react';
import Sheet from 'react-modal-sheet';

import { TranslatedByAI } from '~/info-box/TranslatedByAI';
import { SelectOption } from './components';
import { type LanguagesProps } from './const';

export function LanguagesBottomSheet({
  isOpen,
  onClose,

  mediaHeading,
  transcriptHeading,

  mediaLanguages,
  transcriptLanguages,

  selectedMediaLanguage,
  selectedTranscriptLanguage,

  onChangeMediaLanguage,
  onChangeTranscriptLanguage
}: Readonly<LanguagesProps>) {
  const [newMediaLanguage, setNewMediaLanguage] = useState<string>(
    selectedMediaLanguage
  );

  const [newTranscriptLanguage, setNewTranscriptLanguage] = useState<
    string | null
  >(selectedTranscriptLanguage);

  useEffect(() => {
    // Setting `selectedTranscriptLanguage ?? null` in the `useState` doesn't work
    // when this re-renders, so we need to update the state here
    setNewTranscriptLanguage(selectedTranscriptLanguage);
  }, [selectedTranscriptLanguage]);

  const handleApply = useCallback(() => {
    onChangeMediaLanguage(newMediaLanguage);
    onChangeTranscriptLanguage(newTranscriptLanguage);
    onClose();
  }, [
    newMediaLanguage,
    newTranscriptLanguage,
    onChangeMediaLanguage,
    onChangeTranscriptLanguage,
    onClose
  ]);

  return (
    <Sheet isOpen={isOpen} onClose={onClose} detent="content-height">
      <Sheet.Backdrop />
      <Sheet.Container>
        <Sheet.Header>
          <Flex justifyContent="space-between" p="1rem">
            <Text color="darkGrey.400" fontSize="1.125rem" fontWeight="700">
              Change Language
            </Text>
            <IconButton
              aria-label="Close"
              variant="unstyled"
              minW="1.5rem"
              h="1.5rem"
              p="0.25rem"
              icon={
                <Icon as={IconX} w="1.25rem" h="1.25rem" color="darkGrey.300" />
              }
              onClick={onClose}
            />
          </Flex>
        </Sheet.Header>
        <Sheet.Content>
          <Sheet.Scroller style={{ colorScheme: 'light' }}>
            <TranslatedByAI />
            <Accordion w="100%" defaultIndex={0}>
              <AccordionItem>
                <AccordionButton>
                  <Text
                    as="h2"
                    textAlign="left"
                    fontSize="1rem"
                    fontWeight="700"
                    color="darkGrey.300"
                    py="0.75rem"
                    px="0.675rem"
                    flex="1"
                  >
                    {mediaHeading}
                  </Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {mediaLanguages.map((lang) => (
                    <SelectOption
                      key={lang.code}
                      optionName={lang.name}
                      onClick={() => setNewMediaLanguage(lang.code)}
                      isSelected={newMediaLanguage === lang.code}
                    />
                  ))}
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton>
                  <Text
                    as="h2"
                    flex="1"
                    textAlign="left"
                    fontSize="1rem"
                    fontWeight="700"
                    color="darkGrey.300"
                    py="0.75rem"
                    px="0.675rem"
                  >
                    {transcriptHeading}
                  </Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <SelectOption
                    onClick={() => setNewTranscriptLanguage(null)}
                    optionName="Off"
                    isSelected={!newTranscriptLanguage}
                  />
                  {transcriptLanguages.map((lang) => (
                    <SelectOption
                      key={lang.code}
                      optionName={lang.name}
                      onClick={() => setNewTranscriptLanguage(lang.code)}
                      isSelected={newTranscriptLanguage === lang.code}
                    />
                  ))}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Sheet.Scroller>

          <Box p="1rem">
            <Button
              variant="solidDark"
              onClick={handleApply}
              w="full"
              size="lg"
            >
              Apply
            </Button>
          </Box>
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
}
