import * as React from 'react';
import { Circle, HStack, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { transformDeepLinkToPath } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import { type Image, type Notification } from '@tigerhall/core/lib/types';
import { useUpdateNotificationAsReadMutation } from 'generated';

import { NotificationMenu } from './NotificationMenu';
import { NotificationImage } from './NotificationImage';

interface NotificationItemProps
  extends Omit<Notification, 'image' | '__typename'> {
  image?: Pick<Image, 'uri' | 'alt' | 'id'> | null;
}

export function NotificationItem(props: NotificationItemProps) {
  const {
    id,
    state,
    image,
    title,
    body,
    type = null,
    createdAt,
    deepLink
  } = props || {};

  const [shouldBeHidden, setShouldBeHidden] = React.useState(false);

  const navigate = useNavigate();
  const redirectPath = transformDeepLinkToPath(deepLink);

  const isNew = state === 'NEW';
  const isUnread = state === 'UNREAD';
  const imageUrl = image?.uri;

  const [updateNotificationAsRead] = useUpdateNotificationAsReadMutation({
    onCompleted: (data) => {
      if (data?.markNotificationRead?.state && redirectPath) {
        navigate(redirectPath);
      }
    }
  });

  if (shouldBeHidden) {
    return null;
  }

  return (
    <HStack
      as="button"
      position="relative"
      gap="1rem"
      spacing="0"
      p="1rem"
      width="100%"
      borderBottomWidth="1px"
      borderBottomColor="darkGrey.200"
      background={isUnread || isNew ? 'teal.800' : 'teal.900'}
      onClick={() => {
        segment.notificationClicked({
          title,
          body,
          type,
          location: window.location.pathname
        });
        updateNotificationAsRead({
          variables: {
            id
          }
        });
      }}
    >
      {isNew ? (
        <Circle
          position="absolute"
          top=".5rem"
          left=".5rem"
          size=".5rem"
          background="tigerOrange.600"
        />
      ) : null}
      <NotificationImage type={type} imageUrl={imageUrl} />
      <VStack
        spacing={0}
        gap={1}
        alignItems="flex-start"
        textAlign="start"
        flex="1"
      >
        <Text fontSize="xs" color="soSunny.500">
          {title || ''}
        </Text>
        <Text fontSize="sm" noOfLines={2}>
          {body || ''}
        </Text>
        <Text as="span" fontSize="xs" pt={1}>
          {createdAt ? DateTime.fromISO(createdAt).toRelativeCalendar() : null}
        </Text>
      </VStack>
      <NotificationMenu
        id={id}
        state={state}
        hideNotification={setShouldBeHidden}
      />
    </HStack>
  );
}
