import { PublicUserInformationFragment } from 'fragments/generated/user.generated';
import { TargetType } from '@tigerhall/core/lib/types';

import {
  FormatToSuggestionDataProps,
  SuggestionReturnType,
  TransformMentionsProps
} from './types';

export const formatToSuggestion = ({
  user
}: FormatToSuggestionDataProps): SuggestionReturnType => {
  const {
    firstName,
    lastName,
    id,
    avatarImage,
    jobTitle,
    company,
    isExpert,
    expertOrg
  } = user;

  return {
    display: `${firstName} ${lastName}`,
    firstName,
    lastName,
    id,
    image: avatarImage.uri,
    jobTitle,
    company,
    isExpert,
    expertOrg
  };
};

export const mapUsersToSuggestions = ({
  users
}: {
  users: PublicUserInformationFragment[];
}) => {
  return users.map((user) => formatToSuggestion({ user }));
};

export const transformMentions = ({ mentions }: TransformMentionsProps) => {
  return mentions.map((mention) => ({
    targetId: mention.id,
    index: mention.index,
    targetType: TargetType.User
  }));
};
