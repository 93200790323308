import * as React from 'react';

const loadApiErr =
  'Google Maps Places API library must be loaded. See: https://github.com/wellyshen/use-places-autocomplete#load-the-library';

export function useGooglePlacesSearch() {
  const svcRef = React.useRef<google.maps.places.AutocompleteService>();

  React.useEffect(() => {
    if (svcRef.current) {
      return;
    }

    const placesLib = google?.maps?.places;

    if (!placesLib) {
      console.error(loadApiErr);
      return;
    }

    svcRef.current = new placesLib.AutocompleteService();
  }, []);

  return React.useCallback(async (val: string) => {
    if (!val) {
      return [];
    }

    return await new Promise<google.maps.places.AutocompletePrediction[]>(
      (resolve, reject) => {
        svcRef.current?.getPlacePredictions(
          {
            input: val
          },
          (
            data: google.maps.places.AutocompletePrediction[] | null,
            status: google.maps.places.PlacesServiceStatus
          ) => {
            resolve(data || []);
          }
        );
      }
    );
  }, []);
}
