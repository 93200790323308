import { logger } from '../logger';

type ResizeImageProps = {
  url: string;
  width: number;
  height?: number;
  quality?: number;
  smartCrop?: boolean;
};

export const CDN_PATH = 'https://tigerhall.com/cdn-cgi/image';

export function resizeImage({
  url,
  width = 0,
  height = 0,
  quality = 80,
  smartCrop = false
}: ResizeImageProps) {
  if (!url) {
    return '';
  }

  if (!width && !height) {
    logger.error(
      `Please, provide a valid width and/or height. width:${width}, height:${height}`
    );
    return url;
  }

  // resize for strapi images
  if (url.includes('strapi-v4-uploads')) {
    const options = [
      width ? `width=${width}` : '',
      height ? `height=${height}` : '',
      'format=auto'
    ];

    return `${CDN_PATH}/${options.filter((n) => n).join(',')}/${url}`;
  }

  // resize for other images
  const operation = `${width}x${height},q${quality}${smartCrop ? ',sc' : ''}`;

  return url.replace(
    /^(https:\/\/[-\w.]+\.tigerhall\.\w+)\/([-./\w]+)$/g,
    `$1/resize/${operation}/$2`
  );
}
