import { Flex, Icon, Text } from '@chakra-ui/react';
import { ADMIN_SUPPORT_URL, BetaFlags, SUPPORT_URL } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import { NavLink, useLocation, useMatch } from 'react-router-dom';
import { useColorModeValue } from '@chakra-ui/system';
import { useAppSelector } from 'hooks';
import { hasOrgAdminAccess, isSidebarExpanded } from 'app/state';
import { BetaBadge } from 'components/ui/BetaBadge';

import { type MenuItemType } from '../utils';

type MenuListItemType = {
  item: MenuItemType;
};

const ROUTES_WITH_BETA_BADGE: Record<`/admin/${string}`, BetaFlags> = {
  ['/admin/report-builder']: BetaFlags.ReportBuilderBeta
};

const Content = ({ item }: MenuListItemType) => {
  const isActive = !!useMatch({
    path: item.route,
    end: Boolean(item.isActiveRouteMatchExact)
  });

  const isExpanded = useAppSelector(isSidebarExpanded);

  const textInactiveColor = useColorModeValue('darkGrey.200', 'darkGrey.50');
  const textActiveColor = useColorModeValue('darkGrey.300', 'white');
  const bgActiveColor = useColorModeValue('lightGrey.100', 'darkGrey.400');
  const bgHoverColor = useColorModeValue('lightGrey.100', 'darkGrey.400');

  const betaFlag = ROUTES_WITH_BETA_BADGE[item.route];

  return (
    <Flex
      alignItems="center"
      px="1rem"
      py="0.75rem"
      gap="0.625rem"
      _before={{
        content: '""',
        position: 'absolute',
        left: 0,
        width: isActive && isExpanded ? '0.25rem' : '',
        height: isActive && isExpanded ? '1.125rem' : '',
        backgroundColor: '#FF5900'
      }}
      _hover={{
        backgroundColor: bgHoverColor,
        color: 'white'
      }}
      bg={isActive ? bgActiveColor : 'unset'}
    >
      <Icon as={item.icon} w="1.25rem" h="1.25rem" color={textActiveColor} />
      <Text
        fontSize="0.875rem"
        fontWeight={isActive ? '700' : '500'}
        color={isActive ? textActiveColor : textInactiveColor}
        lineHeight="normal"
      >
        {item.label}
      </Text>
      <BetaBadge betaFlag={betaFlag} />
    </Flex>
  );
};

export function SidebarMenuListItem({ item }: MenuListItemType) {
  const isOrgAdmin = useAppSelector(hasOrgAdminAccess);
  const { pathname } = useLocation();

  if (item.route === '/support') {
    return (
      <a
        href={isOrgAdmin ? ADMIN_SUPPORT_URL : SUPPORT_URL}
        target="_blank"
        rel="noreferrer"
      >
        <Content item={item} />
      </a>
    );
  }

  return (
    <NavLink
      onClick={() => {
        segment.navBarClicked({
          location: pathname,
          section: item?.label
        });
      }}
      to={item.route}
    >
      <Content item={item} />
    </NavLink>
  );
}
