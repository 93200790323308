import { Collapse } from '@chakra-ui/react';

import {
  type MarkdownProps,
  RichTextMarkdownWrapper
} from '~/components/ui/MarkdownWrapper/RichTextMarkdownWrapper';

interface CollapsibleMarkdownReaderProps extends MarkdownProps {
  isShowMore?: boolean;
}
export function CollapsibleMarkdownReader({
  isShowMore,
  children,
  ...props
}: CollapsibleMarkdownReaderProps) {
  return (
    <Collapse startingHeight={'6rem'} in={isShowMore} animateOpacity>
      <RichTextMarkdownWrapper {...props}>{children}</RichTextMarkdownWrapper>
    </Collapse>
  );
}
