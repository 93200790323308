import * as React from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable';

type DndItem = {
  id: string | number;
};

interface DndSortableListProps<T extends DndItem> {
  /** each item should have a matching `id` with each `DndSortableItem` passed as `children */
  items: T[];
  setItems?: (items: T[]) => void;
  /** should be an array of `DndSortableItem` components */
  children: JSX.Element | JSX.Element[];
}

export function DndSortableList<T extends DndItem>({
  items,
  setItems,
  children
}: DndSortableListProps<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id !== over.id && setItems) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      setItems(arrayMove(items, oldIndex, newIndex));
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items}>{children}</SortableContext>
    </DndContext>
  );
}
