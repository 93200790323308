import * as React from 'react';
import { Center, Link, keyframes } from '@chakra-ui/react';
import { IconX } from '@tigerhall/icons';
import { Typography } from '@tigerhall/ui-components';
import { motion } from 'framer-motion';

interface VerificationProps {
  failed?: boolean;
}

export function Verification({ failed = false }: VerificationProps) {
  const pulseKeyframes = keyframes`
  0% { transform: scale(0.8);}
  50% { transform: scale(1);}
  100% { transform: scale(0.8);}
`;

  const pulseAnimation = `${pulseKeyframes} 1s ease-in-out infinite`;

  return failed ? (
    <>
      <Center
        width="80px"
        h="80px"
        borderRadius="40px"
        borderColor="red.600"
        borderWidth={3}
        as={motion.div}
        animate={{
          scale: [0.8, 1],
          opacity: [0, 1]
        }}
        backgroundColor="darkGrey.700"
      >
        <IconX color="white" width="1.75rem" height="1.75rem" />
      </Center>
      <Center w="100%" mt="2.5rem" flexDirection="column">
        <Typography
          variant="heading2"
          color="white"
          fontWeight="700"
          textAlign="center"
          lineHeight={1.2}
        >
          Verification Failed
        </Typography>
        <Typography
          variant="heading5"
          color="lightGrey.900"
          fontWeight="400"
          mt="0.5rem"
          textAlign="center"
          lineHeight={1.5}
        >
          Please reload the page or{' '}
          <Link href="https://tigerhall.com/contact" textDecoration="none">
            contact support
          </Link>
        </Typography>
      </Center>
    </>
  ) : (
    <>
      <Center
        width="80px"
        h="80px"
        borderRadius="40px"
        borderColor="orange.600"
        borderWidth={3}
        as={motion.div}
        animation={pulseAnimation}
        transformOrigin="center"
      />
      <Center w="100%" mt="2.5rem" flexDirection="column">
        <Typography
          variant="heading2"
          color="white"
          fontWeight="700"
          textAlign="center"
          lineHeight={1.2}
        >
          You’re almost there!
        </Typography>
        <Typography
          variant="heading5"
          color="lightGrey.900"
          fontWeight="400"
          mt="0.5rem"
          textAlign="center"
          lineHeight={1.5}
        >
          We are verifying your subscription. This may take a few seconds.
        </Typography>
      </Center>
    </>
  );
}
