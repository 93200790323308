import { useBreakpointValue } from '@chakra-ui/react';

import { PlayerControls } from '~/player/components/PlayerControls';
import { LiveContentOverlayWrapper } from './LiveContentOverlayWrapper';

export function LiveContentPlayPauseToggle() {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
    lg: false
  });
  return (
    <LiveContentOverlayWrapper>
      <PlayerControls
        size={isMobile ? 'md' : 'xl'}
        hideTrackControls
        hideSeekControls
      />
    </LiveContentOverlayWrapper>
  );
}
