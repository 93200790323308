import { Box, Stack, Text, VStack, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@tigerhall/ui-components';
import { getAccessToken, setAccessToken } from 'app/state';
import {
  GetCurrentUserDocument,
  type GetCurrentUserQuery,
  useSwitchUserToTeamsMutation,
  useUpdateUserMutation
} from 'generated';
import { useAppDispatch, useAppSelector } from 'hooks';
import { apolloClient } from 'api/apollo';
import { FormInput, TrackedForm } from '@tigerhall/components';
import { ApolloError } from '@apollo/client';
import { useCallback } from 'react';

type FormValues = {
  company: string;
};

export function SwitchToTeam() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(getAccessToken);
  const toast = useToast();

  const [switchUserToTeamsMutation] = useSwitchUserToTeamsMutation();
  const [updateUser] = useUpdateUserMutation();

  const onSubmit = useCallback(
    async (values: FormValues, { setError }) => {
      try {
        const { data: currentUserResponse } =
          await apolloClient.query<GetCurrentUserQuery>({
            query: GetCurrentUserDocument,
            context: {
              headers: {
                authorization: `jwt ${accessToken}`
              }
            }
          });

        const userProfile = currentUserResponse?.me;

        // onComplete execute `switchUserToTeamsMutation()`
        await updateUser({
          variables: {
            id: userProfile?.id || '',
            input: {
              email: userProfile?.email || '',
              firstName: userProfile?.firstName || '',
              lastName: userProfile?.lastName || '',
              company: values.company
            }
          }
        });

        const result = await switchUserToTeamsMutation();

        toast({
          position: 'top-right',
          title: 'Successfully upgraded to Teams account',
          status: 'success',
          duration: 2000,
          isClosable: true
        });

        const newAccessToken = result?.data?.switchUserToTeams?.auth?.jwt;

        dispatch(
          setAccessToken({
            token: newAccessToken as string
          })
        );

        navigate('/signup/team/add-users');
      } catch (e) {
        if (e instanceof ApolloError) {
          setError('company', {
            type: 'manual',
            message: e.message
          });

          return;
        }

        setError('company', {
          type: 'manual',
          message: 'An unexpected error occurred, please try again later'
        });
      }
    },
    [
      accessToken,
      dispatch,
      navigate,
      switchUserToTeamsMutation,
      toast,
      updateUser
    ]
  );

  return (
    <VStack
      spacing="2.5rem"
      w="100%"
      maxW="30rem"
      pt={{
        base: '3rem',
        md: '5rem'
      }}
    >
      <VStack spacing="1rem">
        <Text color="white" fontSize="3xl" textAlign="center" fontWeight="bold">
          Upgrade to a Teams Account
        </Text>
        <Text
          color="lightGrey.900"
          fontSize="md"
          textAlign="center"
          fontWeight="normal"
        >
          Let’s give your team a cool name.
        </Text>
      </VStack>
      <TrackedForm<FormValues>
        name="switchToTeamsFromIndividualAccount"
        onSubmit={onSubmit}
      >
        {({ formState: { isSubmitting, isValid } }) => (
          <Stack spacing="1.5rem">
            <FormInput
              rules={{
                required: '* Please enter your Team name',
                minLength: {
                  value: 2,
                  message: '* Team name should be at least 2 characters'
                }
              }}
              placeholder="Team name"
              type="text"
              autoComplete="organization"
              name="company"
              label="Enter your team name"
            />
            <Box pt="1rem">
              <Button
                text="Upgrade Account"
                data-cy="submit"
                type="submit"
                width="100%"
                size="lg"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              />
            </Box>
          </Stack>
        )}
      </TrackedForm>
    </VStack>
  );
}
