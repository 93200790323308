import type { User, Pretty } from '@tigerhall/core';
import * as React from 'react';
import * as segment from '@tigerhall/analytics';
import { Link, LinkProps, useLocation } from 'react-router-dom';

export type THLinkProps = {
  name: string;
  isExternal?: boolean;
  user?: Pretty<Partial<Pick<User, 'firstName' | 'lastName' | 'isExpert'>>>;
  to: string;
};

/**
 * @deprecated Use the dedicated TrackedLink from @tigerhall/components instead
 */
export const THLink = React.forwardRef<
  HTMLAnchorElement,
  LinkProps & THLinkProps
>((props, ref) => {
  const location = useLocation();

  const path = `${props?.to}`;
  const expert = props?.user;

  return (
    <Link
      onClick={(args) => {
        segment.buttonClicked({
          location: location.pathname,
          buttonName: props?.name,
          queryParams: location?.search
        });
        /*
        TODO: If anyone can come up with adding additional
         logic to check for other routes that they actually
          write a wrapper around <THLink>
        **/
        if (path.includes('/profile')) {
          segment.profileClicked({
            profileName: `${expert?.firstName} ${expert?.lastName}`,
            href: path,
            userId: path.split('profile/')?.[1],
            location: window?.location?.pathname,
            isThinkfluencer: (expert as User)?.isExpert ?? true
          });
        }
        props?.onClick?.(args);

        if (props?.isExternal) {
          args?.preventDefault();
          window.location.href = props?.to.toString();
        }
      }}
      ref={ref}
      {...props}
    />
  );
});
