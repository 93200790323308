import { Box, Image as CKImage } from '@chakra-ui/react';
import { resizeImage } from '@tigerhall/core';

interface UserBubbleProps {
  image: string;
  isHighlighted: boolean;
  isProductDemoStream?: boolean;
}

export function UserBubble({
  image,
  isHighlighted,
  isProductDemoStream
}: Readonly<UserBubbleProps>) {
  const ctrBorderColor = isHighlighted ? 'tigerOrange.600' : 'darkGrey.400';

  return (
    <Box
      borderRadius="full"
      borderColor={!isProductDemoStream ? ctrBorderColor : 'white'}
      borderWidth={{
        base: '2px',
        md: '4px'
      }}
      alignItems="center"
      justifyContent="center"
      bg="black"
      p={{
        base: '0.125rem',
        md: '0.25rem'
      }}
      h="100%"
      w="100%"
      data-testid="user-bubble"
    >
      <CKImage
        src={resizeImage({
          url: image,

          // This is equal to the largest size of the image according to UserStory/VideoStory
          width: 148,
          height: 148,
          smartCrop: true
        })}
        width="100%"
        height="100%"
        objectFit="cover"
        borderRadius="full"
      />
    </Box>
  );
}
