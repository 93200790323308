import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
export type UserStatsFragment = { __typename: 'UserStats', ebooksRead: number, podcastsCompleted: number, videosCompleted: number, eventsBooked: number, eventsAttended: number };

export const UserStatsFragmentDoc = gql`
    fragment UserStats on UserStats {
  __typename
  ebooksRead
  podcastsCompleted
  videosCompleted
  eventsBooked
  eventsAttended
}
    `;