import { useState } from 'react';

import { VideoPlayer, type VideoPlayerProps } from './components';
import {
  useGetStreamForConnectedVideoPlayerQuery,
  useGetStreamSubtitlesQuery
} from './queries';
import { PlayerLoader } from '../PlayerLoader';

interface VideoPlayerConnectedProps
  extends Pick<
    VideoPlayerProps,
    'onFinished' | 'onClose' | 'isMinimized' | 'onMaximize' | 'setIsMinimized'
  > {
  id: string;
  userPreferredLanguage: string;
}

export function VideoPlayerConnected({
  id,
  onFinished,
  onClose,
  onMaximize,
  isMinimized,
  setIsMinimized,
  userPreferredLanguage
}: Readonly<VideoPlayerConnectedProps>) {
  const [audioLanguage, setAudioLanguage] = useState<string>('EN'); // at the moment we serve streams in "English" language only.
  const [subtitleLanguage, setSubtitleLanguage] = useState<string | null>(
    userPreferredLanguage
  );

  const { data: { stream } = {} } = useGetStreamForConnectedVideoPlayerQuery({
    variables: {
      id,
      audioLanguage
    },
    fetchPolicy: 'cache-first'
  });

  const { data: { stream: streamSubtitles } = {} } = useGetStreamSubtitlesQuery(
    {
      variables: {
        id,
        subtitleLanguage
      },
      fetchPolicy: 'cache-first',
      skip: !subtitleLanguage,
      onCompleted: (data) => {
        /**
         * We are setting English as the subtitle language,
         * if subtitle is not present for the preferred language
         * set by user from languages in settings.
         */
        if (!data.stream?.subtitle.vttUrl) {
          setSubtitleLanguage('EN');
        }
      }
    }
  );

  if (!stream) {
    return <PlayerLoader />;
  }

  return (
    <VideoPlayer
      stream={stream}
      subtitlesUrl={streamSubtitles?.subtitle?.vttUrl}
      onFinished={onFinished}
      setAudioLanguage={setAudioLanguage}
      audioLanguage={audioLanguage}
      setSubtitleLanguage={setSubtitleLanguage}
      subtitleLanguage={subtitleLanguage}
      onClose={onClose}
      onMaximize={onMaximize}
      isMinimized={isMinimized}
      setIsMinimized={setIsMinimized}
    />
  );
}
