import { Box, ButtonProps, Text, VStack } from '@chakra-ui/react';

import { TrackedButton } from '../tracked/components/TrackedButton';
import { ContentCard, ContentCardProps } from './ContentCard';

export interface ContentCardOverlayAction
  extends Omit<ButtonProps, 'children' | 'as'> {
  /** Text rendered inside the action button */
  label: string;
  /** We reduce the available button variants intentionally here per design */
  variant?: Extract<ButtonProps['variant'], 'outline' | 'solid'>;
}

export interface ContentCardOverlayProps {
  /** Text to be rendered above the action buttons */
  overlayDescription?: string;
  /**
   * This translates into a list of action buttons that will be rendered on top of the
   * content card overlay. The buttons will be rendered in the order they are provided.
   */
  overlayActions: ContentCardOverlayAction[];
  /**
   *  add data test id for test cases that should be rendered
   */
  dataTestId?: string;
}

function ContentCardOverlay({
  overlayDescription,
  overlayActions,
  dataTestId
}: Readonly<ContentCardOverlayProps>) {
  return (
    <VStack
      position="absolute"
      inset={0}
      rounded="lg"
      zIndex="zIndexOne"
      justifyContent="center"
      padding="1.5rem"
      spacing="1rem"
      border="1px solid"
      borderColor="transparent"
      background="blackAlpha.900"
      opacity="0"
      transition="opacity 0.3s"
      _hover={{
        opacity: '1',
        borderColor: 'white'
      }}
      data-testid={dataTestId}
    >
      {overlayDescription ? (
        <Text fontSize="sm" fontWeight="500" color="white">
          {overlayDescription}
        </Text>
      ) : null}
      {overlayActions.map((action) => {
        const { label, ...customProps } = action;
        return (
          <TrackedButton
            name={label}
            key={label}
            // default props
            variant="solid"
            size="lg"
            width="100%"
            type="button"
            {...customProps}
          >
            {label}
          </TrackedButton>
        );
      })}
    </VStack>
  );
}

export interface ContentCardWithOverlayProps
  extends ContentCardProps,
    ContentCardOverlayProps {}

export function ContentCardWithOverlay({
  overlayDescription,
  overlayActions,
  ...contentCardProps
}: ContentCardWithOverlayProps) {
  return (
    <Box position="relative" width="fit-content">
      <ContentCard
        disableUserProgress
        disableShareButton
        {...contentCardProps}
      />
      <ContentCardOverlay
        overlayDescription={overlayDescription}
        overlayActions={overlayActions}
      />
    </Box>
  );
}
