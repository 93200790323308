import { HStack, Skeleton, SkeletonCircle, VStack } from '@chakra-ui/react';

import { sizeMap, type ProfileBarProps } from './ProfileBar';

export interface ProfileBarSkeletonProps
  extends Pick<ProfileBarProps, 'size'> {}

export function ProfileBarSkeleton({
  size = 'md'
}: Readonly<ProfileBarSkeletonProps>) {
  const avatarBorderSize = '4px';
  const avatarSize = `calc(${sizeMap[size].imageSize} + ${avatarBorderSize})`;

  return (
    <HStack flex={1} spacing={3}>
      <SkeletonCircle size={avatarSize} />
      <VStack alignItems="flex-start" spacing="0.25rem">
        <Skeleton width="10rem" height="1rem" />
        <Skeleton width="5rem" height="0.75rem" />
      </VStack>
    </HStack>
  );
}
