import { Box, Flex, Icon, Text, VStack } from '@chakra-ui/react';
import {
  IconChevronDown,
  IconMaximize,
  IconMinimize,
  IconShare3
} from '@tigerhall/icons';

import { Overlay } from '~/player/components/Overlay';
import { OverlayFooterContainer } from '~/player/components/OverlayFooterContainer';
import { OptionsGroup } from '~/player/components/OptionsGroup';
import { OptionButton } from '~/player/components/OptionButton';
import { type ExpertBasicInfo, ProfileBar } from '~/user';
import { Share } from '~/share';
import { LiveBadge } from '~/badges';

export interface LiveContentOverlayProps {
  featuredUser: ExpertBasicInfo;
  contentId: string;
  contentTitle: string;
  contentDescription: string;
  isFullscreen: boolean;
  onFullscreenToggle: () => void;
  onMinimize: () => void;
  /** URL that opens the LiveContent player */
  urlToShare: string;
  disableShare?: boolean;
}

export function LiveContentOverlay({
  featuredUser,
  contentId,
  contentTitle,
  contentDescription,
  isFullscreen,
  onMinimize,
  onFullscreenToggle,
  urlToShare,
  disableShare
}: Readonly<LiveContentOverlayProps>) {
  function handleMinimize() {
    if (isFullscreen) {
      onFullscreenToggle();
    }
    onMinimize();
  }
  return (
    <Box display={{ base: 'block', lg: 'none' }}>
      <Overlay
        header={
          <VStack
            alignItems={'flex-start'}
            px={{ base: '1.5rem', lg: '3rem' }}
            width={'100%'}
            flex={1}
            pt={{ lg: '2.5rem' }}
          >
            <Flex
              flex={1}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'100%'}
              gap={4}
            >
              <ProfileBar profile={featuredUser} size={'xs'} />
              <LiveBadge badgeProps={{ ml: 'auto' }} />
              <OptionButton
                label={'Minimize'}
                icon={IconChevronDown}
                iconSize="1.5rem"
                onClick={handleMinimize}
                iconButtonProps={{
                  background: 'blackAlpha.500',
                  border: '1px solid',
                  borderColor: 'whiteAlpha.400',
                  borderRadius: 'full',
                  w: 10,
                  h: 10
                }}
              />
            </Flex>
            <Text
              fontWeight={'bold'}
              fontSize={'md'}
              letterSpacing={'wide'}
              lineHeight={'1.5'}
              color={'white'}
            >
              {contentTitle}
            </Text>

            <OptionButton
              label={isFullscreen ? 'Exit full screen ' : 'Enter full screen'}
              icon={isFullscreen ? IconMinimize : IconMaximize}
              iconSize="1.5rem"
              iconColor="darkGrey.50"
              iconButtonProps={{ alignSelf: 'flex-end' }}
              onClick={() => {
                onFullscreenToggle();
              }}
            />
          </VStack>
        }
        footer={
          !disableShare ? (
            <OverlayFooterContainer>
              <OptionsGroup>
                <Share
                  urlToShare={urlToShare}
                  titleToShare={contentTitle}
                  descriptionToShare={contentDescription}
                  content={{
                    __typename: 'Stream',
                    id: contentId,
                    name: contentTitle
                  }}
                  aria-label="Share"
                  color="white"
                >
                  <VStack>
                    <Icon fontSize={'1.5rem'} as={IconShare3} />
                    <Text
                      fontSize={'xx-small'}
                      color={'white'}
                      style={{ marginTop: 0 }}
                    >
                      Share
                    </Text>
                  </VStack>
                </Share>
              </OptionsGroup>
            </OverlayFooterContainer>
          ) : null
        }
      />
    </Box>
  );
}
