import * as React from 'react';
import * as segment from '@tigerhall/analytics';

import { LinkProps, useLink } from '../../link';

export type TrackedLinkProps = {
  name: string;
} & LinkProps;

export const TrackedLink = React.forwardRef<
  HTMLAnchorElement,
  TrackedLinkProps
>(({ name, children, ...props }) => {
  const { Link } = useLink();
  return (
    <Link
      {...props}
      onClick={(
        e: React.MouseEvent<HTMLButtonElement, MouseEvent> &
          React.MouseEvent<HTMLAnchorElement, MouseEvent>
      ) => {
        e.stopPropagation();
        segment.buttonClicked({
          location: location.pathname,
          buttonName: name,
          queryParams: location?.search
        });

        props.onClick?.(e);
      }}
    >
      {children}
    </Link>
  );
});
