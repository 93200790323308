import * as React from 'react';
import { Flex, Heading, Icon, SimpleGrid, Text } from '@chakra-ui/react';
import {
  type Category,
  type ContentCardsFilter,
  type Expert
} from '@tigerhall/core/lib/types';
import {
  FormInput,
  FormRangeDatePicker,
  TrackedRTForm
} from '@tigerhall/components';
import {
  CategoriesSelect,
  ContentTypeSelect,
  type ContentTypeSelectOption,
  DEFAULT_CONTENT_TYPE_SELECT_OPTIONS,
  ExpertsSelect
} from 'components/form';
import {
  ContentPanel,
  ContentStepper,
  InfiniteSelectableContentCardGrid,
  type PrimaryButton
} from 'components/ui';
import { IconCaretRightFilled } from '@tigerhall/icons';

import { SelectedContentCards } from '../../../components';
import { useAssignmentDraftContext } from '../../../hooks/useAssignmentDraftContext';

const DESCRIPTION = `Add or remove selected content belonging to this assignment based on your team's individual development areas. Browse various content by typing in relevant keywords, or chose from a list of categories.`;

type FormValues = {
  keywords: string;
  category: Pick<Category, 'id'>;
  expert: Pick<Expert, 'id'>;
  contentType: ContentTypeSelectOption;
  createdBetween: Date | Date[];
};

interface AssignmentContentSelectionProps {
  stepNames: string[];
  forward: () => any;
  isEditing: boolean;
  saveChanges: () => any;
}

export function AssignmentContentSelection({
  stepNames,
  forward,
  isEditing,
  saveChanges
}: Readonly<AssignmentContentSelectionProps>) {
  const { contents, setContents } = useAssignmentDraftContext();

  const [queryFilters, setQueryFilters] = React.useState<ContentCardsFilter>({
    limit: 20,
    offset: 0,
    types: DEFAULT_CONTENT_TYPE_SELECT_OPTIONS[0].value,
    keywords: '',
    categories: [],
    experts: []
  });

  const onFilterChange = React.useCallback(
    (values: FormValues) => {
      setQueryFilters({
        ...queryFilters,
        keywords: values.keywords || '',
        categories: values.category ? [values.category.id] : [],
        experts: values.expert ? [values.expert.id] : [],
        types: values.contentType
          ? values.contentType.value
          : DEFAULT_CONTENT_TYPE_SELECT_OPTIONS[0].value,
        createdAt: values.createdBetween
          ? {
              from: values.createdBetween?.[0] || values.createdBetween,
              to: values.createdBetween?.[1] || new Date()
            }
          : undefined
      });
    },
    [setQueryFilters, queryFilters]
  );

  const isContinueButtonDisabled = React.useCallback(
    () => contents?.length <= 0,
    [contents]
  );

  const buttons: PrimaryButton[] = React.useMemo(
    () => [
      {
        text: 'Next',
        onClick: forward,
        isDisabled: isContinueButtonDisabled(),
        rightIcon: <Icon as={IconCaretRightFilled} color="tigerOrange.600" />
      }
    ],
    [forward, isContinueButtonDisabled]
  );

  const buttonsOnEdit: PrimaryButton[] = React.useMemo(
    () => [
      {
        text: 'Go to Summary',
        onClick: saveChanges,
        rightIcon: <Icon as={IconCaretRightFilled} color="tigerOrange.600" />
      }
    ],
    [saveChanges]
  );

  return (
    <ContentStepper
      stepNames={stepNames}
      title="Select Content"
      currentStep={1}
      buttons={isEditing ? buttonsOnEdit : buttons}
      description={DESCRIPTION}
    >
      <Flex mb={2} gap="1rem" direction="column">
        {contents.length > 0 && (
          <ContentPanel heading={`Selected Content (${contents.length || 0})`}>
            <SelectedContentCards
              contentCards={contents}
              setContentCards={setContents}
              actions={['LearnMore', 'Remove']}
            />
          </ContentPanel>
        )}
        <Heading
          fontSize="1.5rem"
          fontWeight={700}
          lineHeight="1.875rem"
          color="white"
          mt={contents.length > 0 ? '1rem' : undefined}
        >
          All Content
        </Heading>
        <Flex
          direction="column"
          border="1px solid"
          borderColor="darkGrey.400"
          p="1rem"
          borderRadius="5px"
          gap="1rem"
        >
          <Text
            color="white"
            fontSize="1.125rem"
            fontWeight={700}
            lineHeight="1.375rem"
          >
            Filter by
          </Text>
          <TrackedRTForm<FormValues>
            name="ASSIGN_TRAILS_CONTENT_SELECTION"
            onSubmit={onFilterChange}
          >
            <SimpleGrid spacing="1rem" columns={5}>
              <FormInput
                type="text"
                name="keywords"
                label="Search"
                placeholder="Keywords"
              />
              <CategoriesSelect name="category" isClearable />
              <ExpertsSelect name="expert" isClearable />
              <ContentTypeSelect name="contentType" isClearable />
              <FormRangeDatePicker
                name="createdBetween"
                label="Time Period"
                placeholder="Till date"
                numberOfCalendars={2}
                maxDate={new Date()}
              />
            </SimpleGrid>
          </TrackedRTForm>
        </Flex>
        <InfiniteSelectableContentCardGrid
          filter={queryFilters}
          contentCards={contents}
          setContentCards={setContents}
        />
      </Flex>
    </ContentStepper>
  );
}
