import * as React from 'react';
import { FormSelect } from '@tigerhall/components';
import { AssignmentStatus } from '@tigerhall/core';

type AssignmentStatusSelectProps = {
  name: string;
  statusToShow: AssignmentStatus[];
};

export type AssignmentStatusSelectOption = {
  label: string;
  value: AssignmentStatus[];
};

export function getAllStatusOptions(statusOptions: AssignmentStatus[]) {
  const defaultOptions: AssignmentStatusSelectOption[] = [
    {
      label: 'All',
      value: [...statusOptions]
    }
  ];

  statusOptions.forEach((status) => {
    switch (status) {
      case AssignmentStatus.Active:
        defaultOptions.push({
          label: 'Active',
          value: [AssignmentStatus.Active]
        });
        break;

      case AssignmentStatus.Inactive:
        defaultOptions.push({
          label: 'Inactive',
          value: [AssignmentStatus.Inactive]
        });
        break;
      case AssignmentStatus.Expired:
        defaultOptions.push({
          label: 'Expired',
          value: [AssignmentStatus.Expired]
        });
        break;
    }
  });

  return defaultOptions;
}

export function AssignmentStatusSelect({
  name,
  statusToShow
}: AssignmentStatusSelectProps) {
  return (
    <FormSelect
      name={name}
      label="Status"
      isMulti={false}
      isClearable={false}
      options={getAllStatusOptions(statusToShow)}
      placeholder="Status"
    />
  );
}
