import { Button, type ButtonProps, forwardRef } from '@chakra-ui/react';

interface ActionButtonProps extends ButtonProps {}

/**
 * Need to use Chakra's `forwardRef` since sometimes the `ActionButton`
 * is used as `MenuButton`, and the `ref` is needed to render the `MenuList`
 * in the right place
 * @see https://chakra-ui.com/docs/components/menu#customizing-the-button
 */
export const ActionButton = forwardRef<ActionButtonProps, 'button'>(
  ({ children, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        variant="unstyled"
        display="flex"
        borderRadius="md"
        height="2.375rem"
        width="100%"
        padding="0.25rem 1rem"
        background="transparent"
        color="lightGrey.200"
        fontWeight="medium"
        fontSize={{
          base: 'sm',
          md: 'lg'
        }}
        _hover={{
          background: 'darkGrey.400'
        }}
        {...props}
      >
        {children}
      </Button>
    );
  }
);
