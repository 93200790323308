import { NetworkStatus } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { TrackedButton } from '@tigerhall/components';
import { Content, Pretty } from '@tigerhall/core/lib/types';
import { AnimatePresence } from 'framer-motion';
import useScrollToView from 'hooks/useScrollToView';
import map from 'lodash/map';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DummyCopies } from '../DummyCopies';
import {
  CommentItem,
  CommentItemSkeleton,
  type CommentItemProps
} from './CommentItem';
import { SingleReply } from './SingleReply';
import { useGetRepliesForCommentLazyQuery } from './queries';
import { getIsBoxHighlighted } from './utils/constant';

type CommentBase = CommentItemProps['comment'] & {
  numberOfReplies: number;
  lastReply?: CommentItemProps['comment'] | null;
};

export interface CommentWithRepliesProps
  extends Pick<CommentItemProps, 'referenceId' | 'referenceType' | 'onDelete'> {
  comment: Pretty<CommentBase>;
  disableReplyOnComment?: boolean;
  parentView: string;
  commentIdToHighlight?: string;
}

const LIMIT = 10;

export function CommentWithReplies({
  referenceId,
  referenceType,
  comment,
  disableReplyOnComment = false,
  onDelete,
  parentView,
  commentIdToHighlight
}: Readonly<CommentWithRepliesProps>) {
  const limitRef = useRef(LIMIT); // using ref for the limit in-order to keep the updated variable in-case of cache-evict

  const variables = useMemo(
    () => ({
      commentId: comment.id,
      sorting: {
        sorting: [
          {
            direction: 'DESC',
            field: 'createdAt'
          }
        ]
      },
      limit: limitRef.current,
      afterCursor: null
    }),
    [comment.id]
  );

  const [
    fetchReplies,
    {
      data: commentReplies,
      loading: isLoadingReplies,
      refetch,
      fetchMore,
      networkStatus
    }
  ] = useGetRepliesForCommentLazyQuery({
    variables: variables,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const repliesShown = data.comment.replies.edges.length ?? 0;
      limitRef.current = repliesShown;
    }
  });

  const replies = useMemo(
    () => commentReplies?.comment?.replies?.edges ?? [],
    [commentReplies]
  );

  const handleRefetch = useCallback(async () => {
    try {
      if (replies.length >= 1) {
        const refetchVariables = {
          ...variables,
          limit: limitRef.current
        };
        await refetch(refetchVariables);
      } else {
        const refetchVariables = {
          ...variables,
          limit: replies.length + 1
        };
        await fetchReplies({ variables: refetchVariables });
      }
    } catch (error) {
      captureException(error);
    }
  }, [fetchReplies, refetch, replies.length, variables]);

  const repliesCount =
    commentReplies?.comment.numberOfReplies ?? comment?.numberOfReplies ?? 0;

  const lastReply =
    commentReplies?.comment?.lastReply ?? comment?.lastReply ?? undefined;

  const isOrganizationContent = Boolean(
    (comment?.belongTo as Content)?.organisation?.id
  );

  const [
    shouldSkipScrollToHighlightedComment,
    setShouldSkipScrollToHighlightedComment
  ] = useState(false);

  const [searchParams] = useSearchParams();

  const highlightedCommentRef = useRef<HTMLDivElement>(null);

  const highlightedRepliesRefs = useRef<{
    [key: string]: HTMLDivElement | null;
  }>({});

  const setReplyRef = useCallback((node: HTMLDivElement | null, id: string) => {
    if (node) {
      highlightedRepliesRefs.current[id] = node;
    }
  }, []);

  const scrollToView = useScrollToView();

  const highlightId = searchParams.get('highlight');

  const [shouldFetchMore, setShouldFetchMore] = useState(false);
  const [hasAttemptedFetch, setHasAttemptedFetch] = useState(false);

  const handleFetchNextReplies = useCallback(() => {
    async function fetchNextReplies() {
      const nextVariables = {
        ...variables,
        limit: LIMIT,
        afterCursor: commentReplies?.comment.replies.meta.nextCursor
      };

      try {
        if (replies.length >= 1) {
          await fetchMore({
            variables: nextVariables
          });
        } else {
          await fetchReplies();
        }
      } catch (error) {
        captureException(error);
      } finally {
        setHasAttemptedFetch(true);
      }
    }

    void fetchNextReplies();
  }, [
    commentReplies?.comment.replies.meta.nextCursor,
    fetchMore,
    fetchReplies,
    replies.length,
    variables
  ]);

  useEffect(() => {
    if (shouldFetchMore && !hasAttemptedFetch) {
      handleFetchNextReplies();
    }
  }, [shouldFetchMore, hasAttemptedFetch, handleFetchNextReplies]);

  useEffect(() => {
    if (
      !highlightId ||
      comment?.id !== commentIdToHighlight ||
      shouldSkipScrollToHighlightedComment
    ) {
      return;
    }

    function scrollToHighlightedElement() {
      if (comment?.id === highlightId) {
        setTimeout(() => {
          scrollToView(highlightedCommentRef.current);
          setShouldSkipScrollToHighlightedComment(true);
        }, 200);
        return;
      }

      const highlightedReply = replies.find(
        (reply) => reply?.comment?.id === highlightId
      );
      if (highlightedReply) {
        const replyRef =
          highlightedRepliesRefs.current[highlightedReply.comment.id];
        if (replyRef) {
          setTimeout(() => {
            scrollToView(replyRef);
            setShouldSkipScrollToHighlightedComment(true);
          }, 200);
        }
      } else {
        setShouldFetchMore(true);
      }
    }

    scrollToHighlightedElement();
  }, [
    highlightId,
    comment?.id,
    commentIdToHighlight,
    replies,
    scrollToView,
    shouldSkipScrollToHighlightedComment
  ]);

  useEffect(() => {
    if (hasAttemptedFetch) {
      const highlightedReply = replies.find(
        (reply) => reply?.comment?.id === highlightId
      );
      if (highlightedReply) {
        const replyRef =
          highlightedRepliesRefs.current[highlightedReply.comment.id];
        if (replyRef) {
          // adding timeout to render the ui first and then scroll
          setTimeout(() => {
            scrollToView(replyRef);
            setShouldSkipScrollToHighlightedComment(true);
          }, 200);
        }
      }
      setShouldFetchMore(false);
      setHasAttemptedFetch(false);
    }
  }, [hasAttemptedFetch, replies, highlightId, scrollToView]);

  const repliesShownCount =
    repliesCount > 0 && replies.length === 0 ? 1 : replies.length;
  const repliesLeftToFetch = repliesCount - repliesShownCount;

  const showMoreCount = repliesLeftToFetch > LIMIT ? LIMIT : repliesLeftToFetch;

  function renderReplies() {
    return (
      <AnimatePresence>
        {map(replies, (reply) => {
          const replyShown = reply?.comment;

          return (
            <SingleReply
              key={reply.comment.id}
              parentView={parentView}
              replyShown={replyShown}
              searchParams={searchParams}
              isOrganizationContent={isOrganizationContent}
              refetch={handleRefetch}
              referenceId={referenceId}
              referenceType={referenceType}
              onDelete={onDelete}
              ref={(node) => setReplyRef(node, reply.comment.id)}
            />
          );
        })}
      </AnimatePresence>
    );
  }

  return (
    <Flex gap="1rem" width="100%" flexDirection="column">
      <CommentItem
        comment={comment}
        referenceId={referenceId}
        referenceType={referenceType}
        ref={highlightedCommentRef}
        disableReply={disableReplyOnComment}
        isHighlighted={getIsBoxHighlighted(comment?.id, searchParams)}
        onDelete={onDelete}
        refetch={handleRefetch}
        isOrganizationContent={isOrganizationContent}
        replies={
          lastReply ? (
            <>
              {replies.length >= 1 || isLoadingReplies ? (
                renderReplies()
              ) : (
                <AnimatePresence>
                  <SingleReply
                    parentView={parentView}
                    replyShown={lastReply}
                    searchParams={searchParams}
                    isOrganizationContent={isOrganizationContent}
                    onDelete={onDelete}
                    refetch={handleRefetch}
                    referenceId={referenceId}
                    referenceType={referenceType}
                    ref={(node) => setReplyRef(node, lastReply.id)}
                  />
                </AnimatePresence>
              )}

              {isLoadingReplies && NetworkStatus.fetchMore === networkStatus ? (
                <DummyCopies count={showMoreCount}>
                  <Box width="100%" mt="1.25rem">
                    <CommentItemSkeleton />
                  </Box>
                </DummyCopies>
              ) : null}

              {showMoreCount > 0 ? (
                <TrackedButton
                  name="VIEW_MORE_REPLIES"
                  variant={'link'}
                  onClick={handleFetchNextReplies}
                  size="lg"
                  alignSelf={'flex-start'}
                  shadow={'none'}
                  isDisabled={isLoadingReplies}
                >
                  {`View ${showMoreCount} more replies...`}
                </TrackedButton>
              ) : null}
            </>
          ) : null
        }
      />
    </Flex>
  );
}
