import { Flex, Icon, VStack } from '@chakra-ui/react';
import { StepIndicator } from '@tigerhall/ui-components';
import { useAppSelector, useCrossDomainSession } from 'hooks';
import { Confirmation } from 'modules/authentication/modules/SignUp/screens/Confirmation';
import { AddTeamMembers } from 'modules/authentication/modules/SignUp/screens/Team/children/AddTeamMembers';
import { SignUpForm } from 'modules/authentication/modules/SignUp/screens/Team/children/SignUpForm';
import { SwitchToTeam } from 'modules/authentication/modules/SignUp/screens/Team/children/SwitchToTeam';
import { Helmet } from 'react-helmet';
import { getCustomerType } from 'app/state';
import { IconBrandTigerhallHorizontal } from '@tigerhall/icons';

type TeamSignUpProps = {
  step?: 1 | 2 | 3;
};

export function TeamSignUp({ step }: TeamSignUpProps) {
  useCrossDomainSession();
  const customerType = useAppSelector(getCustomerType);

  const isIndividual = customerType === 'INDIVIDUAL';

  const firstStepText = isIndividual ? 'Upgrade' : 'Sign up';

  const renderContent = () => {
    switch (step) {
      case 1:
        return isIndividual ? <SwitchToTeam /> : <SignUpForm />;
      case 2:
        return <AddTeamMembers />;
      case 3:
        return <Confirmation />;
      default:
        return null;
    }
  };
  return (
    <>
      <Helmet>
        <title>Create Team Account</title>
      </Helmet>
      <VStack
        backgroundColor="darkGrey.700"
        width="100%"
        minHeight="100vh"
        pb={10}
        px="2rem"
      >
        <Flex
          w="100%"
          mb={10}
          pt={6}
          px={10}
          justifyContent={{ base: 'center', md: 'start' }}
        >
          <a href="https://tigerhall.com">
            <Icon
              as={IconBrandTigerhallHorizontal}
              width="6.25rem"
              height={7}
            />
          </a>
        </Flex>
        <StepIndicator
          width="100%"
          maxWidth="40rem"
          stepCount={3}
          currentStep={step}
          /**
           * `Payment Details` is too long in mobile. To make it work
           * will require some refactoring of the StepIndicator component.
           * For now, we will just use `Payment`
           */
          customStepperTexts={[firstStepText, 'Add Users', 'Payment']}
          allDone={step === 3}
        />
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap="2.5rem"
          width="100%"
          maxWidth="40rem"
        >
          {renderContent()}
        </Flex>
      </VStack>
    </>
  );
}
