import { HStack } from '@chakra-ui/react';
import { ProfileBarSkeleton } from '@tigerhall/components';

import { FollowButtonSkeleton } from '~/components/ui/Buttons';

interface ProfileBarFollowSkeletonProps
  extends React.ComponentProps<typeof ProfileBarSkeleton> {}

export function ProfileBarFollowSkeleton({
  size
}: ProfileBarFollowSkeletonProps) {
  return (
    <HStack justifyContent="space-between">
      <ProfileBarSkeleton size={size} />;
      <FollowButtonSkeleton />
    </HStack>
  );
}
