import { useMemo } from 'react';
import * as segment from '@tigerhall/analytics';
import { hasOrgAdminAccess, getUserId } from 'app/state';
import { useAppSelector } from 'hooks';
import { OptionsMenuList } from 'components/ui/OptionsMenu';

import { SharePostOption } from './components/SharePostOption';
import {
  ReportPostOption,
  type ReportPostOptionProps
} from './components/ReportPostOption';
import {
  DeletePostOption,
  type DeletePostOptionProps
} from './components/DeletePostOption';

export interface PostOptionsProps
  extends Pick<DeletePostOptionProps, 'onDelete'> {
  authorId: string;
  postId: DeletePostOptionProps['postId'];
}

export function PostOptions({ authorId, postId, onDelete }: PostOptionsProps) {
  const isAdmin = useAppSelector(hasOrgAdminAccess);
  const currentUserId = useAppSelector(getUserId);

  const isPostOwner = useMemo(() => {
    return authorId === currentUserId;
  }, [authorId, currentUserId]);

  const canEdit = useMemo(() => {
    return isPostOwner;
  }, [isPostOwner]);

  const canReport = useMemo(() => {
    return !isPostOwner;
  }, [isPostOwner]);

  const canDelete = useMemo(() => {
    return isPostOwner || isAdmin;
  }, [isPostOwner, isAdmin]);

  if (!canEdit && !canReport && !canDelete) {
    return null;
  }

  return (
    <>
      <OptionsMenuList
        onOpen={() => {
          segment.postOptionsClicked({
            referenceId: postId,
            buttonName: 'options'
          });
        }}
      >
        <SharePostOption postId={postId} />
        {/* todo: disabled until the functionality is impemented
          {canEdit && <EditPostOption postId={postId}/>} 
          */}
        {canReport && <ReportPostOption postId={postId} />}
        {canDelete && (
          <DeletePostOption
            postId={postId}
            onDelete={onDelete}
            isPostOwner={isPostOwner}
          />
        )}
      </OptionsMenuList>
    </>
  );
}
