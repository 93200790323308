import { mode as o } from "./index31.mjs";
import { modalAnatomy as i } from "./index32.mjs";
import { createMultiStyleConfigHelpers as l, defineStyle as t } from "./index33.mjs";
const { definePartsStyle: r, defineMultiStyleConfig: a } = l(i.keys), d = t(
  ({ isCentered: e, scrollBehavior: n }) => ({
    display: "flex",
    zIndex: "modal",
    justifyContent: "center",
    alignItems: e ? "center" : "flex-start",
    overflow: n === "inside" ? "hidden" : "auto"
  })
), s = t((e) => ({
  bg: o("white", "darkGrey.700")(e)
})), y = t((e) => ({
  fontSize: "1.25rem",
  fontWeight: "700",
  lineHeight: "1.2",
  color: o("black", "white")(e),
  px: "1.5rem",
  py: "1rem",
  borderBottomWidth: "1px"
})), c = t((e) => ({
  color: o("darkGrey.50", "white")(e),
  position: "absolute",
  top: 2,
  insetEnd: 3,
  borderRadius: "full"
})), f = t(({ scrollBehavior: e }) => ({
  px: "1.5rem",
  py: "1rem",
  flex: 1,
  overflow: e === "inside" ? "auto" : void 0
})), m = t((e) => ({
  borderTopWidth: "1px"
})), u = r((e) => ({
  dialogContainer: d(e),
  dialog: s(e),
  header: y(e),
  closeButton: c(e),
  body: f(e),
  footer: m(e)
})), b = r((e) => ({
  header: {
    textAlign: "center"
  }
})), h = a({
  baseStyle: u,
  variants: { prompt: b }
});
export {
  h as Modal
};
