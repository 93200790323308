import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  ResponsiveValue,
  SimpleGrid,
  Text
} from '@chakra-ui/react';
import * as React from 'react';
import {
  Controller,
  UseControllerProps,
  useFormContext,
  UseFormReturn
} from 'react-hook-form';

type Option = {
  label: string;
  value: string;
};

type FormRadioProps = {
  /**
   * The name of the input
   */
  name: string;

  /**
   * The label of the tooltip
   */
  label: string;

  /**
   * Options available for the radio
   */
  options: Option[];

  /**
   * Internally the component uses a SimpleGrid so columns does what you expect
   */
  columns?: ResponsiveValue<number>;

  /**
   * React-hook-form controller props
   */
  rules?: UseControllerProps['rules'];

  /**
   *  This allows you to transform the value before it is passed to and from the form
   *  which can be useful if you want to use boolean values as radios and checkbox traditionally only support strings
   *
   *  ```tsx
   * <FormRadio
   *   label="Is this user a Thinkfluencer?"
   *   name="isExpert"
   *   options={[
   *     { label: 'Yes', value: 'true' },
   *     { label: 'No', value: 'false' }
   *   ]}
   *   transform={{
   *     onChange: (value) => value === 'true',
   *     value: (value) => (value ? 'true' : 'false')
   *   }}
   * />
   *  ```
   */
  transform?: {
    onChange(value: string): any;
    value(value: any): string;
  };

  analyticsDisabled?: boolean;
};

const NOOP = (v) => v;

export function FormRadio({
  name,
  label,
  options,
  columns = 1,
  rules = {},
  transform = {
    onChange: NOOP,
    value: NOOP
  },
  analyticsDisabled = false
}: FormRadioProps) {
  const methods = useFormContext() as UseFormReturn & {
    formName: string;
  };

  if (methods === null) {
    return <Text color="state.error">Missing form context</Text>;
  }

  if (!methods.formName && !analyticsDisabled) {
    return <Text color="state.error">Missing form name</Text>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      render={({ field, fieldState }) => (
        <FormControl
          isInvalid={!!fieldState.error}
          isRequired={!!rules?.required}
        >
          <FormLabel>{label}</FormLabel>
          <RadioGroup
            value={transform.value(field.value)}
            onChange={(val) => {
              field.onChange(transform.onChange(val));
            }}
            onBlur={field.onBlur}
          >
            <SimpleGrid columns={columns} spacing="1rem">
              {options.map((option) => (
                <Radio value={option.value} key={option.value}>
                  {option.label}
                </Radio>
              ))}
            </SimpleGrid>
          </RadioGroup>
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}
