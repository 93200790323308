import * as React from 'react';
import { Box, BoxProps, Center, Text, Icon } from '@chakra-ui/react';
import { IconCircleCheck } from '@tigerhall/icons';

interface ContentCardFrameProps {
  /**
   * Intended to be a `ContentCardWithOverlay` component.
   */
  children: React.ReactNode;
  /**
   * Indicates the index of the card in a list or grid.
   * If no index is provided we render a `Check` icon instead.
   */
  index?: number;
  /**
   * Color of the frame.
   * @default "tigerOrange.600"
   */
  color?: BoxProps['borderColor'];
}

export function ContentCardFrame({
  children,
  index,
  color = 'tigerOrange.600'
}: ContentCardFrameProps) {
  return (
    <Box
      position="relative"
      width="fit-content"
      border="2px solid"
      borderColor={color}
      rounded="lg"
    >
      {children}
      <Center
        position="absolute"
        top="-.5rem"
        left="-.5rem"
        width="1.5rem"
        height="1.5rem"
        rounded="full"
        background="black"
        zIndex="docked"
      >
        {index ? (
          <Center
            width="inherit"
            height="inherit"
            border="2px solid"
            borderColor={color}
            rounded="full"
          >
            <Text as="span" fontSize="xs" fontWeight="700" color="white">
              {index}
            </Text>
          </Center>
        ) : (
          <Icon
            as={IconCircleCheck}
            width="inherit"
            height="inherit"
            color={color}
          />
        )}
      </Center>
    </Box>
  );
}
