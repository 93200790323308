import { Button, Flex, Text } from '@chakra-ui/react';
import * as React from 'react';

import PanTool, { Specs } from './children/PanTool';
import ZoomTool, { calculateDefaultZoom } from './children/ZoomTool';
import useImageCanvas from './hooks/useImageCanvas';

type Size = { height: number; width: number };

const isDebugEnabled = false;

const TOOL_SIZE = {
  width: 736,
  height: 538
};

const calculateCropArea = (
  imageSize: Size,
  specs: Specs,
  zoom: number,
  cropSize: Size
) => ({
  x: (imageSize.width - cropSize.width / zoom) / 2 - specs.x,
  y: (imageSize.height - cropSize.height / zoom) / 2 - specs.y,
  width: cropSize.width / zoom,
  height: cropSize.height / zoom
});

const debugCanvasDrawCropArea = (
  context: CanvasRenderingContext2D,
  specs: Specs,
  zoom: number,
  imageSize: Size,
  size: Size
) => {
  context.beginPath();
  context.strokeStyle = 'green';
  context.lineWidth = 10;
  const { x, y, width, height } = calculateCropArea(
    imageSize,
    specs,
    zoom,
    size
  );
  context.rect(x, y, width, height);
  context.stroke();
};

const DEFAULT_SPECS = {
  x: 0,
  y: 0,
  width: 0,
  height: 0
};

export const ImageCropTool: React.FC<{
  image: string;
  cropSize?: Size;
  onApply: (url: string, blob: Blob | null) => void;
}> = ({ image, cropSize = { width: 296, height: 296 }, onApply }) => {
  const [imageSize, setImageSize] = React.useState<Size>({
    width: 0,
    height: 0
  });

  const [zoomBounds, setZoomBound] = React.useState({ min: 0, max: 1 });
  const [zoom, setZoom] = React.useState(1);
  const [specs, setSpecs] = React.useState(DEFAULT_SPECS);
  const canvasRef = React.useRef<
    HTMLCanvasElement & { canvasImage?: HTMLImageElement }
  >(null);

  const onUpdatePan = React.useCallback(
    (value: Specs) => {
      /** image can be only move within the extra space **/
      const xBound = (imageSize.width - cropSize.width / zoom) / 2;
      const yBound = (imageSize.height - cropSize.height / zoom) / 2;
      const boundedValue = {
        ...value,
        x: Math.max(Math.min(value.x, xBound), -xBound),
        y: Math.max(Math.min(value.y, yBound), -yBound)
      };
      setSpecs(boundedValue);

      // enable this to visualize real croping area
      if (isDebugEnabled) {
        const context = canvasRef.current?.getContext('2d');

        if (canvasRef.current?.canvasImage) {
          context?.drawImage(canvasRef.current.canvasImage, 0, 0);
        }

        debugCanvasDrawCropArea(context!, value, zoom, imageSize, cropSize);
      }
    },
    [canvasRef, zoom, imageSize, cropSize, setSpecs]
  );

  useImageCanvas(canvasRef, image, {
    onLoad({ imageSize: currentSize }) {
      const minZoom = calculateDefaultZoom({
        imageSize: currentSize,
        minSize: cropSize
      });
      setImageSize({ ...currentSize });
      setZoom(minZoom);
      setZoomBound({ min: minZoom, max: 1 });
    }
  });

  const onApplyAction = () => {
    const context = canvasRef?.current?.getContext('2d');
    if (!context) {
      return;
    }

    const cropArea = calculateCropArea(imageSize, specs, zoom, cropSize);
    const imageData = context.getImageData(
      cropArea.x,
      cropArea.y,
      cropArea.width,
      cropArea.height
    );

    const output = document.createElement('canvas');
    output.height = cropArea.height;
    output.width = cropArea.width;
    const ctx = output.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.putImageData(imageData, 0, 0);
    output.toBlob((blob) => {
      const blobImage = new Image();
      const url = URL.createObjectURL(blob as Blob);
      blobImage.onload = () => {
        onApply(url, blob);
      };
      blobImage.src = url;
    }, 'image/jpeg');
  };

  const renderHeader = () => (
    <Flex justifyContent="space-between" mb={8}>
      <Text color="tigerOrange.600">CROP IMAGE</Text>
      <Flex>
        <Button variant="solid" minWidth="auto" onClick={onApplyAction}>
          Apply
        </Button>
      </Flex>
    </Flex>
  );

  return (
    <Flex
      direction="column"
      border="1px solid white"
      borderRadius={5}
      py={6}
      px={10}
      {...TOOL_SIZE}
    >
      {renderHeader()}
      <PanTool specs={specs} onUpdatePan={onUpdatePan} zoom={zoom}>
        {(panProps) => (
          <>
            <canvas
              ref={canvasRef}
              width={imageSize.width}
              height={imageSize.height}
              {...panProps}
              style={{
                cursor: 'grab',
                width: imageSize.width,
                height: imageSize.height,
                transform: `scale(${zoom}) translate(${specs.x}px, ${specs.y}px)`,
                transformOrigin: 'center'
              }}
            />
            <Flex
              position="absolute"
              pointerEvents="none"
              width={cropSize.width}
              height={cropSize.height}
              border="1px solid white"
            />
          </>
        )}
      </PanTool>
      <ZoomTool zoom={zoom} setZoom={setZoom} zoomBounds={zoomBounds} />
    </Flex>
  );
};
