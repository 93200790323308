import { IconSearch } from '@tigerhall/icons';
import {
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack
} from '@chakra-ui/react';
import React from 'react';

export const calculateDefaultZoom = ({ imageSize, minSize }) => {
  const aspectFitHeight = minSize.height / imageSize.height;
  const aspectFitWidth = minSize.width / imageSize.width;

  return imageSize.height < imageSize.width ? aspectFitHeight : aspectFitWidth;
};

const ZoomTool: React.FC<{
  setZoom: (number) => void;
  zoom: number;
  zoomBounds: { min: number; max: number };
}> = ({ setZoom, zoom, zoomBounds }) => (
  <Flex justifyContent="center" alignItems="center" py={8}>
    <IconSearch color="white" />
    <Slider
      maxWidth={343}
      defaultValue={zoom * 100}
      min={zoomBounds.min * 100}
      max={zoomBounds.max * 100}
      value={zoom * 100}
      onChange={(value) => setZoom(value / 100)}
      marginX={5}
    >
      <SliderTrack>
        <SliderFilledTrack bg="orange" />
      </SliderTrack>
      <SliderThumb bg="orange" borderWidth={3} borderColor="white" />
    </Slider>
    <IconSearch color="white" />
  </Flex>
);

export default ZoomTool;
