import { Text } from '@chakra-ui/react';

import { PromptModal } from '~/components/ui/modals';

interface BlockThinkfluencerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function BlockThinkfluencerModal({
  isOpen,
  onClose
}: Readonly<BlockThinkfluencerModalProps>) {
  return (
    <PromptModal
      isOpen={isOpen}
      onClose={onClose}
      type="info"
      heading="Thinkfluencers Can’t Be Blocked"
      actionButton={{
        text: 'Okay ',
        isLoading: false,
        onClick: onClose,
        'aria-label': 'Okay'
      }}
    >
      <Text color="darkGrey.400" fontWeight="medium" fontSize="sm">
        Thinkfluencers are content creators on the platform. Blocking them will
        restrict the app experience.
      </Text>
    </PromptModal>
  );
}
