import { useBreakpointValue } from '@chakra-ui/react';
import {
  LiveContent,
  type ExpertBasicInfo,
  type LiveContentProps,
  type PlayerModalProps
} from '@tigerhall/components';
import { getIsTemporarilyHiddenAndPaused } from 'app/state';
import { useAppSelector, useNavigationListener } from 'hooks';
import { useContentActions } from 'modules/application/hooks/useContentActions';
import { useCallback, useMemo } from 'react';

import { GetStreamForConnectedLiveVideoPlayerSubscription } from '../queries';
import { getUrlToShareForLiveContent } from '../utils';

export interface LiveContentPlayerProps {
  /**
   * Stream object
   */
  stream: NonNullable<
    GetStreamForConnectedLiveVideoPlayerSubscription['stream']
  >;
  onFinished?: LiveContentProps['onFinished'];
  onClose: PlayerModalProps['onMinimize'];
  onMaximize: LiveContentProps['onMaximize'];
  isMinimized: LiveContentProps['isMinimized'];
  setIsMinimized: (value: boolean) => void;
}

export function LiveContentPlayer({
  stream,
  onFinished,
  onMaximize,
  isMinimized,
  setIsMinimized,
  onClose
}: Readonly<LiveContentPlayerProps>) {
  const isHidden = useAppSelector(getIsTemporarilyHiddenAndPaused);
  const { trackProgress, trackFinished } = useContentActions();

  const isMobile = useBreakpointValue(
    { base: true, lg: false },
    {
      fallback: 'lg',
      ssr: false
    }
  );

  useNavigationListener(() => {
    if (!isMinimized) {
      setIsMinimized(true);
    }
  });

  const meta = useMemo(
    () => ({
      contentId: stream.id,
      contentName: stream.name,
      preamble: stream.preamble
    }),
    [stream]
  );

  const featuredUser: ExpertBasicInfo = {
    ...stream.experts[0],
    __typename: 'Expert'
  };

  const onTrackProgress = useCallback(
    (progress: number) => trackProgress({ content: stream, progress }),
    [stream, trackProgress]
  );

  const onTrackFinished = useCallback(
    () => trackFinished(stream),
    [stream, trackFinished]
  );

  const urlToShare = getUrlToShareForLiveContent({
    id: stream.id,
    source: stream.source
  });

  return (
    <LiveContent
      src={stream.videoUrl}
      customDuration={0}
      meta={meta}
      featuredUser={featuredUser}
      urlToShare={urlToShare}
      disableShare={isMobile}
      onTrackProgress={onTrackProgress}
      onTrackFinished={onTrackFinished}
      onFinished={onFinished}
      isHidden={isHidden}
      isMinimized={isMinimized}
      streamStatus={stream.status}
      onClose={() => {
        onClose();
        setIsMinimized(false);
      }}
      onMaximize={onMaximize}
      onMinimize={() => {
        setIsMinimized(true);
      }}
    />
  );
}
