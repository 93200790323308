import { type Content, ContentVote, compactNumber } from '@tigerhall/core';
import { Flex, Text } from '@chakra-ui/react';
import {
  IconThumbDown,
  IconThumbDownFilled,
  IconThumbUp,
  IconThumbUpFilled
} from '@tigerhall/icons';
import { Icon } from '@chakra-ui/icon';
import { useContentActions } from 'modules/application/hooks/useContentActions';
import { useCallback } from 'react';

interface LikeUnlikeButtonProps {
  content: Content;
}

export function LikeUnlikeButton({ content }: Readonly<LikeUnlikeButtonProps>) {
  const { rate } = useContentActions();

  const onLike = useCallback(
    () => rate(content, ContentVote.Upvote),
    [rate, content]
  );

  const onDislike = useCallback(
    () => rate(content, ContentVote.Downvote),
    [rate, content]
  );

  const upVoteCount = content?.upvoteCount || 0;

  const upVoteColor =
    content.contentRatingByUser === ContentVote.Upvote
      ? 'tigerOrange.600'
      : 'darkGrey.50';

  const downVoteColor =
    content.contentRatingByUser === ContentVote.Downvote
      ? 'tigerOrange.600'
      : 'darkGrey.50';

  const ThumbUp = () => {
    if (content.contentRatingByUser === ContentVote.Upvote) {
      return <Icon as={IconThumbUpFilled} color={upVoteColor} />;
    }

    return <Icon as={IconThumbUp} color={'white'} />;
  };

  const ThumbDown = () => {
    if (content.contentRatingByUser === ContentVote.Downvote) {
      return <IconThumbDownFilled color={downVoteColor} />;
    }

    return <Icon as={IconThumbDown} color={'white'} />;
  };

  return (
    <Flex gap={'4'} px={'6'}>
      <Flex gap={'1.5'} as={'button'} onClick={onLike}>
        <ThumbUp />
        <Text color={upVoteColor} fontSize={'xs'}>
          {upVoteCount ? compactNumber(upVoteCount) : null}
        </Text>
      </Flex>
      <Flex gap={'1.5'} onClick={onDislike} as={'button'}>
        <ThumbDown />
      </Flex>
    </Flex>
  );
}
