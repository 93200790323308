import { type ReactNode } from 'react';
import {
  type ButtonProps,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  type MenuListProps,
  Portal
} from '@chakra-ui/react';
import { IconDots } from '@tigerhall/icons';
import * as segment from '@tigerhall/analytics';

export interface OptionsMenuListProps extends Partial<MenuListProps> {
  buttonSize?: ButtonProps['size'];
  onOpen?: () => void;
  menuButton?: ReactNode;
  children: ReactNode;
}

export function OptionsMenuList({
  buttonSize = 'xs',
  onOpen,
  menuButton: customMenuButton,
  children,
  ...rest
}: OptionsMenuListProps) {
  return (
    <Menu
      isLazy
      lazyBehavior="keepMounted"
      onOpen={() => {
        segment.buttonClicked({
          buttonName: 'More Options',
          location: window.location.pathname
        });
        onOpen?.();
      }}
    >
      {customMenuButton || (
        <MenuButton
          // to make sure the button is over any `LinkBox` or `LinkOverlay` components
          zIndex={1}
          aria-label="More Options"
          as={IconButton}
          variant="ghost"
          size={buttonSize}
          _active={{
            background: 'whiteAlpha.200'
          }}
          _hover={{
            background: 'whiteAlpha.200'
          }}
          icon={
            <Icon
              as={IconDots}
              color="lightGrey.400"
              height="1rem"
              width="1rem"
            />
          }
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      )}
      <Portal>
        <MenuList zIndex="popover" {...rest}>
          {children}
        </MenuList>
      </Portal>
    </Menu>
  );
}
