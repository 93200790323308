import { Box, Flex, Heading, Image, Link, Text } from '@chakra-ui/react';
import { useMicrosoftTeams } from '@tigerhall/core';
import { useAuthentication } from 'hooks';
import { Navigate, useLocation } from 'react-router-dom';
import * as React from 'react';
import { TrackedButton } from '@tigerhall/components';

import image from '../../authentication/assets/logo.png';
import { useMicrosoftTeamsLogin } from '../hooks';

export function RouteGuard({ children }) {
  const { isInTeams, loading } = useMicrosoftTeams();
  const { isAuthenticated } = useAuthentication();
  const { login, isLoading } = useMicrosoftTeamsLogin('standard');

  const location = useLocation();

  if (loading) {
    return null;
  }

  if (!isInTeams) {
    return <Navigate to="/home" replace />;
  }

  if (!isAuthenticated && location.pathname !== '/msteams/login') {
    return (
      <Box h="100vh" w="100vw" py="2rem">
        <Flex direction="column" m="auto" gap="2.5rem" alignItems="center">
          <Image src={image} w="6rem" h="6rem" />

          <Flex direction="column" gap="0.5rem" textAlign="center">
            <Heading as="h1" fontSize="1.125rem" fontWeight="700" color="black">
              Welcome to Tigerhall! We’re glad you’re here.
            </Heading>
            <Text fontSize="0.875rem" fontWeight="400" color="black">
              Tigerhall is your tool for social learning.
            </Text>
          </Flex>

          <TrackedButton
            name="Microsoft Teams Sign In"
            size="lg"
            onClick={login}
            isLoading={isLoading}
            variant="solidDark"
            px="4rem"
          >
            Sign in to Tigerhall
          </TrackedButton>

          <Text fontSize="0.9375rem" fontWeight="400" color="#242424">
            Don&apos;t have a Tigerhall account?{' '}
            <Link
              href="https://tigerhall.com"
              target="_blank"
              color="tigerOrange.600"
              fontWeight={'700'}
            >
              Visit our website
            </Link>{' '}
            to learn more.
          </Text>
        </Flex>
      </Box>
    );
  }

  return children;
}
