import {
  ActivationOverviewReportColumns as Columns,
  type ActivationOverviewReportFilter as Filter
} from '@tigerhall/core';

import {
  type AllStaticFilters,
  type DynamicFilter,
  type ReportColumn,
  type ReportTemplate,
  StaticFilterType
} from '../types';
import { type ReportBuilderWizardStoreState } from '~/modules/admin/modules/reportBuilder/stores';
import { apolloClient } from '~/api/apollo';
import {
  ExportActivationOverviewReportDocument,
  type ExportActivationOverviewReportMutation,
  type ExportActivationOverviewReportMutationVariables
} from '~/modules/admin/modules/reportBuilder/queries';
import {
  transformStateToDefaultFilters,
  transformStaticDateToTimeRange
} from '~/modules/admin/modules/reportBuilder/utils';

export enum SubColumn {
  ActiveUsersInThisPeriod = 'Active_Users_in_this_Period',
  ActiveUsersTotal = 'Active_Users_Total',
  TotalContractedUsers = 'Total_Contracted_Users',
  TotalAccountsEnabledForAccess = 'Total_Accounts_Enabled_For_Access',
  NumberOfLicensesAdoptedInThisPeriod = 'Number_of_Licenses_Adopted_in_this_Period',
  LicenseAdoptionPercentThisPeriod = 'License_Adoption_Percent_this_Period',
  TotalLicenseAdoptionPercent = 'Total_License_Adoption_Percent',
  TimeSpentOnContentByAllUsersInThisPeriod = 'Time_Spent_on_Content_by_All_Users_in_This_Period',
  TotalTimeSpentOnContentByAllUsers = 'Total_Time_Spent_on_Content_by_All_Users',
  NumberOfUsersConsumingContentInThisPeriod = 'Number_of_Users_Consuming_Content_in_this_Period',
  TotalUsersConsumingContent = 'Total_Users_Consuming_Content'
}

export class ActivationOverviewReport implements ReportTemplate<Filter> {
  getName(): string {
    return 'Activation Overview';
  }

  getDescription(): string {
    return 'Overview of number of licenses adopted along with active users count in the platform.';
  }

  isEnabled(): boolean {
    return true;
  }

  getColumns(): ReportColumn[] {
    return [
      {
        id: Columns.ActiveUsers,
        name: 'Active Users',
        subColumns: [
          {
            name: 'Active Users in This Period',
            id: SubColumn.ActiveUsersInThisPeriod,
            description:
              'Distinct count of users who have performed any active event in the chosen time period'
          },
          {
            name: 'Active Users Total',
            id: SubColumn.ActiveUsersTotal,
            description:
              'Distinct count of users who have performed any active event since the start of the program'
          }
        ]
      },
      {
        id: Columns.ContractedUsers,
        name: 'Contracted Users',
        subColumns: [
          {
            name: 'Total Contracted Users',
            id: SubColumn.TotalContractedUsers,
            description:
              'Contracted users calculated from the start of the program'
          }
        ]
      },
      {
        id: Columns.EnabledAccounts,
        name: 'Enabled Accounts',
        subColumns: [
          {
            name: 'Total Accounts Enabled For Access',
            id: SubColumn.TotalAccountsEnabledForAccess,
            description:
              'All users who are registered on the platform excluding deleted and archived users. This value is calculated from the start of the program'
          }
        ]
      },
      {
        id: Columns.LicenceAdoption,
        name: 'License Adoption',
        subColumns: [
          {
            name: 'Number of Licenses Adopted in This Period',
            id: SubColumn.NumberOfLicensesAdoptedInThisPeriod,
            description:
              'Count of users who have logged in for the “first time” in this period'
          },
          {
            name: 'License Adoption % This Period',
            id: SubColumn.LicenseAdoptionPercentThisPeriod,
            description: 'Adoption % based on contracted users'
          },
          {
            name: 'Total License Adoption %',
            id: SubColumn.TotalLicenseAdoptionPercent,
            description:
              'Distinct count of users who have logged in at least once out of total contracted users since the start of the program'
          }
        ]
      },
      {
        id: Columns.TimeSpentOnContentByUsers,
        name: 'Time Spent on Content by Users',
        subColumns: [
          {
            name: 'Time Spent on Content by All Users in This Period',
            id: SubColumn.TimeSpentOnContentByAllUsersInThisPeriod,
            description:
              'Total time spent on content by users in the chosen period'
          },
          {
            name: 'Total Time Spent on Content by All Users',
            id: SubColumn.TotalTimeSpentOnContentByAllUsers,
            description:
              'Total time spent on content by users since the start of the program'
          }
        ]
      },
      {
        id: Columns.NumberOfUsersConsumingContent,
        name: 'Number of Users Consuming Content',
        subColumns: [
          {
            name: 'Number of Users Consuming Content in This Period',
            id: SubColumn.NumberOfUsersConsumingContentInThisPeriod,
            description:
              'Distinct count of users who have consumed any content in the chosen time period'
          },
          {
            name: 'Total Users Consuming Content',
            id: SubColumn.TotalUsersConsumingContent,
            description:
              'Total distinct count of users who have consumed any content since the start of the program'
          }
        ]
      }
    ];
  }

  getSampleData(): Record<string, string | number> {
    return {
      [SubColumn.ActiveUsersInThisPeriod]: 150,
      [SubColumn.ActiveUsersTotal]: 500,
      [SubColumn.LicenseAdoptionPercentThisPeriod]: '75%',
      [SubColumn.NumberOfLicensesAdoptedInThisPeriod]: 100,
      [SubColumn.NumberOfUsersConsumingContentInThisPeriod]: 120,
      [SubColumn.TimeSpentOnContentByAllUsersInThisPeriod]:
        '5 hours 10 minutes 20 seconds',
      [SubColumn.TotalAccountsEnabledForAccess]: 450,
      [SubColumn.TotalContractedUsers]: 600,
      [SubColumn.TotalLicenseAdoptionPercent]: '80%',
      [SubColumn.TotalTimeSpentOnContentByAllUsers]:
        '5 hours 10 minutes 20 seconds',
      [SubColumn.TotalUsersConsumingContent]: 550
    };
  }

  getStaticFilters(): AllStaticFilters<Filter>[] {
    return [
      {
        name: 'timeFilter',
        label: 'Time Period',
        placeholder: 'Select a time period',
        tooltip: '',
        type: StaticFilterType.DateTime
      },
      {
        name: 'includeArchivedUsers',
        label: 'Include Archived Users',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        isClearDisabled: true,
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' }
        ]
      }
    ];
  }

  getDynamicFilter(): DynamicFilter<Filter> | null {
    return null;
  }

  calculatePotentialRowCount(
    filter: Filter,
    audienceSize: number
  ): Promise<number> {
    return Promise.resolve(audienceSize);
  }

  async export(state: ReportBuilderWizardStoreState): Promise<void> {
    const { reportFilters: filter } = state;

    const standardFilter = await transformStateToDefaultFilters<Columns>(state);

    await apolloClient.mutate<
      ExportActivationOverviewReportMutation,
      ExportActivationOverviewReportMutationVariables
    >({
      mutation: ExportActivationOverviewReportDocument,
      variables: {
        filter: {
          ...standardFilter,

          timeFilter: transformStaticDateToTimeRange(filter.timeFilter)
        }
      }
    });
  }
}
