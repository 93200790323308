import { Text } from '@chakra-ui/react';
import { DarkPanel } from 'components/ui';
import { TrackedLink } from '@tigerhall/components';

export default function UserTopics() {
  return (
    <DarkPanel gap="1.5rem">
      <Text fontSize="1.25rem" fontWeight="700" color="white">
        My Topics of Interest
      </Text>
      <Text fontSize="1rem" fontWeight="500" color="darkGrey.50">
        View and edit the topics that you’re interested in to get better in-app
        recommendations
      </Text>
      <TrackedLink
        name="TOPICS_OF_INTEREST"
        href="/profile/settings/topics"
        as="button"
        size="lg"
        maxW="20rem"
      >
        View My Topics of Interest
      </TrackedLink>
    </DarkPanel>
  );
}
