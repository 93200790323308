import { captureException } from '@sentry/react';
import { useParams } from 'react-router-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Box, Center, List, VStack } from '@chakra-ui/react';
import { FeedContentType, ReactionType } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import { TableBodyFallback } from '@tigerhall/ui-components';
import { DummyCopies } from 'components/ui';
import { useGetUsersReactedByReactionTypeQuery } from 'generated';

import {
  ProfileBarFollow,
  ProfileBarFollowSkeleton
} from '~/components/ui/User/ProfileBarFollow';

interface ReactedUserListProps {
  reactionType: ReactionType | 'All';
}

const DEFAULT_LIMIT = 10;

export function ReactedUserList({
  reactionType
}: Readonly<ReactedUserListProps>) {
  const { postId = '' } = useParams();

  const { data, loading, error, fetchMore } =
    useGetUsersReactedByReactionTypeQuery({
      variables: {
        postId: postId,
        type: FeedContentType.Post,
        ...(reactionType !== 'All' && {
          reactionType
        }),
        pagination: {
          limit: DEFAULT_LIMIT,
          afterCursor: undefined
        }
      }
    });

  const { edges: reactedUsers = [], meta = undefined } =
    data?.feedContentById.__typename === 'Post'
      ? data.feedContentById.reactions.users
      : {};

  const hasNextPage = !loading && !!meta?.hasNext;

  const [targetRef] = useInfiniteScroll({
    onLoadMore: () => {
      segment.fetchMore({
        componentName: 'REACTIONS',
        fetchedItemCount: reactedUsers.length
      });

      fetchMore({
        variables: {
          pagination: {
            limit: DEFAULT_LIMIT,
            afterCursor: meta?.nextCursor
          }
        }
      }).catch((err) => {
        captureException(err);
      });
    },
    hasNextPage,
    loading,
    disabled: !!error
  });

  return (
    <>
      {error ? <Center p="2rem" pb="4rem">
          <TableBodyFallback
            heading="Reactions are not available"
            subheading="This post may have been deleted or you may not have access to it."
          />
        </Center> : null}
      <VStack
        width="100%"
        maxWidth="48rem"
        height="auto"
        mx="auto"
        py="1rem"
        spacing="1rem"
      >
        {reactedUsers.map((reactedUser) => {
          const {
            userReaction: { user }
          } = reactedUser;
          return (
            <Box w="100%" key={'user-reaction-' + user?.id}>
              <ProfileBarFollow userId={user?.id} />
            </Box>
          );
        })}
        {loading || hasNextPage ? (
          <List ref={targetRef} width="100%" spacing={4}>
            <DummyCopies count={5}>
              <ProfileBarFollowSkeleton />
            </DummyCopies>
          </List>
        ) : null}
      </VStack>
    </>
  );
}
