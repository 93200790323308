import * as React from 'react';

const TableCheckbox: React.FC = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref ?? defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className="table-container">
        <input type="checkbox" ref={resolvedRef} {...rest} />
        <span className="table-background" />
      </div>
    );
  }
);

export default TableCheckbox;
