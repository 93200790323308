import { Text, VStack } from '@chakra-ui/react';
import { compactNumber } from '@tigerhall/core';

interface StatsCardProps {
  label: string;
  value: number;
  onClick?: () => void;
}

export function StatsCard({ label, value, onClick }: Readonly<StatsCardProps>) {
  return (
    <VStack
      direction="column"
      bgColor="darkGrey.400"
      boxShadow="0px 1px 4px rgba(0, 0, 0, 0.2)"
      py="0.8rem"
      px={{ base: '2rem', md: '3rem' }}
      textAlign="center"
      justifyContent="center"
      onClick={onClick}
      cursor="pointer"
      borderRadius={12}
    >
      <Text fontSize="md" fontWeight="bold" color="white">
        {compactNumber(value)}
      </Text>
      <Text fontSize="xs" color="white">
        {label}
      </Text>
    </VStack>
  );
}
