const BELOW_TWENTY = [
  '',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen'
];

const TENS = [
  '',
  '',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety'
];

const THOUSANDS = ['', 'thousand', 'million', 'billion'];

/**
 * Formats a number to a compact format.
 * @param number The number to format.
 * @returns The formatted number.
 * @example
 * ```ts
 * compactNumber(1000); // 1K
 * compactNumber(1000000); // 1M
 * ```
 */
export function compactNumber(number: number) {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(number);
}

/**
 * This function minimizes
 * the length of number after decimal
 * @param value Number which we want to convert
 * @param length The length of Number we want after decimal
 * @returns Converted number back in typeof number
 */
export function toFixed(value: number, length: number = 2) {
  return Number(value?.toFixed(length));
}

/**
 * @description this methods converts numbers into english words
 * - it handles decimal numbers by converting them to whole numbers
 * @example
 * ```ts
 * numberToWords(1000); // one thousand
 * numberToWords(123); // one hundered twenty three
 * ```
 */
export function numberToWords(num: number): string {
  if (Math.floor(num) === 0) {
    return 'zero';
  }

  function helper(n: number): string {
    if (n < 20) {
      return BELOW_TWENTY[n];
    }
    if (n < 100) {
      return (
        TENS[Math.floor(n / 10)] + (n % 10 ? ' ' + BELOW_TWENTY[n % 10] : '')
      );
    }
    return (
      BELOW_TWENTY[Math.floor(n / 100)] +
      ' hundred' +
      (n % 100 ? ' ' + helper(n % 100) : '')
    );
  }

  let result: string = '';
  let currentNum = Math.floor(num);
  let i = 0;

  while (currentNum > 0) {
    if (currentNum % 1000 !== 0) {
      result =
        helper(currentNum % 1000) +
        ' ' +
        THOUSANDS[i] +
        (result ? ' ' + result : '');
    }
    currentNum = Math.floor(currentNum / 1000);
    i++;
  }

  return result.trim();
}
