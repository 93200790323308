import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgBrandApple = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    focusable={false}
    className="th-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.552 5.538c1.03 0 2.32-.732 3.087-1.708.696-.885 1.203-2.121 1.203-3.357 0-.168-.014-.336-.043-.473-1.145.046-2.522.809-3.348 1.83-.653.779-1.247 2-1.247 3.25 0 .184.03.367.044.428.072.015.188.03.304.03ZM5.928 24c1.406 0 2.03-.992 3.783-.992 1.783 0 2.175.961 3.74.961 1.536 0 2.566-1.495 3.537-2.96 1.087-1.678 1.536-3.326 1.565-3.402-.101-.03-3.044-1.297-3.044-4.852 0-3.082 2.32-4.47 2.45-4.577-1.537-2.32-3.87-2.38-4.508-2.38-1.725 0-3.13 1.098-4.015 1.098-.957 0-2.218-1.037-3.71-1.037C2.883 5.859 0 8.33 0 12.999c0 2.9 1.073 5.966 2.392 7.95C3.522 22.627 4.508 24 5.928 24Z"
    />
  </svg>
);
SvgBrandApple.displayName = "IconBrandApple";
const ForwardRef = forwardRef(SvgBrandApple);
export default ForwardRef;
