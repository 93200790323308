import { captureException } from '@sentry/react';
import { Box } from '@chakra-ui/react';
import type { ExpertBasicInfo } from '@tigerhall/components';
import { ContentType, StreamSource } from '@tigerhall/core';
import { getPlayingContent, getPlaylistType } from 'app/state';
import { useGetLiveStreamInfoByIdLazyQuery } from 'components/ui/ContentPlayer/queries';
import { useAppSelector } from 'hooks';
import { useEffect } from 'react';

import { DrawerContent } from '../DrawerContent';
import { getExperts } from '../LiveContentConnected/utils';

export interface PlayerConnectedDrawerContentProps {
  tabIndex: number;
}

export function PlayerConnectedDrawerContent({
  tabIndex
}: Readonly<PlayerConnectedDrawerContentProps>) {
  const playingContent = useAppSelector(getPlayingContent);
  const playlistType = useAppSelector(getPlaylistType);
  const isPlaylist = !!playlistType;

  const [getLiveStreamInfo, { data: liveContentMetaData }] =
    useGetLiveStreamInfoByIdLazyQuery({
      variables: {
        id: playingContent?.id ?? ''
      },
      fetchPolicy: 'cache-first'
    });

  useEffect(() => {
    if (
      playingContent?.type === StreamSource.BroadcastAwsIvs ||
      playingContent?.type === StreamSource.BroadcastCloudflareLive ||
      playingContent?.type === StreamSource.LivestreamAgora
    ) {
      getLiveStreamInfo().catch((err) => {
        captureException(err, {
          extra: {
            playingContentType: playingContent?.type
          }
        });
      });
    }
  }, [getLiveStreamInfo, playingContent?.type]);

  switch (playingContent?.type) {
    case ContentType.Podcast:
      return (
        <DrawerContent
          content={{ id: playingContent?.id ?? '', __typename: 'Podcast' }}
          type={playingContent.type}
          isPlaylist={isPlaylist}
          defaultSelectedTab={tabIndex}
        />
      );
    case ContentType.Stream:
      return (
        <DrawerContent
          content={{ id: playingContent?.id ?? '', __typename: 'Stream' }}
          type={playingContent.type}
          isPlaylist={isPlaylist}
          defaultSelectedTab={tabIndex}
        />
      );
    case ContentType.Ebook:
      return (
        <DrawerContent
          content={{ id: playingContent?.id ?? '', __typename: 'Ebook' }}
          type={playingContent.type}
          isPlaylist={isPlaylist}
          defaultSelectedTab={tabIndex}
        />
      );
    case StreamSource.BroadcastCloudflareLive:
    case StreamSource.BroadcastAwsIvs:
    case StreamSource.LivestreamAgora: {
      const expert = getExperts({
        source: liveContentMetaData?.stream?.source,
        hosts: liveContentMetaData?.stream?.hosts,
        experts: liveContentMetaData?.stream?.experts
      })?.[0];

      const featuredUser: ExpertBasicInfo = {
        __typename: 'Expert',
        id: expert?.id ?? '',
        firstName: expert?.firstName ?? '',
        lastName: expert?.lastName ?? '',
        title: expert?.title ?? '',
        image: expert?.image
      };

      const stream = {
        name: liveContentMetaData?.stream?.name ?? '',
        id: liveContentMetaData?.stream?.id ?? '',
        preamble: liveContentMetaData?.stream?.preamble ?? '',
        source: playingContent?.type
      };

      const liveHeaderProps = {
        stream: stream,
        expert: featuredUser,
        isLive: true
      };

      return (
        <DrawerContent
          content={{ id: playingContent?.id ?? '', __typename: 'Stream' }}
          type={playingContent.type}
          isPlaylist={isPlaylist}
          defaultSelectedTab={tabIndex}
          liveHeaderProps={liveHeaderProps}
        />
      );
    }

    default:
      return <Box />;
  }
}
