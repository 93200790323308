import { HStack, Icon, Text } from '@chakra-ui/react';
import { formatShortContentDuration } from '@tigerhall/core';
import {
  IconBook,
  IconHeadphones,
  IconLive,
  IconRoute
} from '@tigerhall/icons';

import type {
  EventContent,
  PlaylistContent,
  PlaylistTrailContent
} from '~/hooks/useAutoplayContent/types';

interface QueueItemDetailsProps {
  item: PlaylistContent;
}

export function QueueItemDetails({ item }: Readonly<QueueItemDetailsProps>) {
  const content = {
    icon: IconHeadphones,
    name: 'Podcast',
    duration: 0
  };

  function getIsPlaylistWithoutEvent(
    data: PlaylistTrailContent
  ): data is Exclude<PlaylistTrailContent, EventContent> {
    return data.__typename !== 'Event';
  }

  switch (item?.__typename) {
    case 'Event':
      content.icon = IconLive;
      content.name = 'Livestream';
      content.duration = 0;
      break;
    case 'Ebook':
      content.icon = IconBook;
      content.name = 'Power Read';
      content.duration = item?.length;
      break;
    case 'Stream':
      content.icon = IconLive;
      content.name = 'Video';
      content.duration = item?.length;
      break;
    case 'LearningPath':
      content.icon = IconRoute;
      content.name = 'Trail';

      content.duration = item?.contentCards.reduce(
        (accumulator, currentValue) => {
          return (
            accumulator +
            (getIsPlaylistWithoutEvent(currentValue) ? currentValue.length : 0)
          );
        },
        0
      );
      break;
    case 'Podcast':
    default:
      content.icon = IconHeadphones;
      content.name = 'Podcast';
      content.duration = item?.length;
  }

  const formattedDuration = content.duration
    ? formatShortContentDuration(content.duration)
    : undefined;

  const duration = formattedDuration ? `| ${formattedDuration}` : '';

  return (
    <HStack w={'full'}>
      <Icon as={content.icon} color="lightGrey.900" />
      <Text color="lightGrey.900" fontSize="xs" fontWeight={'semibold'}>
        {`${content.name} ${duration}`}
      </Text>
    </HStack>
  );
}
