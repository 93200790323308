import { BoxProps, Flex, Icon, Text } from '@chakra-ui/react';
import { IconLive } from '@tigerhall/icons';

interface LiveBadgeProps {
  badgeProps?: BoxProps;
  size?: 'xs' | 'sm' | 'md';
}

const sizeMap = {
  xs: {
    px: 1,
    py: 0.5,
    fontSize: '2xs',
    iconSize: '0.75rem'
  },
  sm: {
    px: 1,
    py: 0.5,
    fontSize: 'xs',
    iconSize: '0.75rem'
  },
  md: {
    px: 2,
    py: 1,
    fontSize: 'sm',
    iconSize: '1rem'
  }
};

export function LiveBadge({ badgeProps, size = 'md' }: LiveBadgeProps) {
  return (
    <Flex
      px={sizeMap[size].px}
      py={sizeMap[size].py}
      bg={'tigerOrange.600'}
      borderRadius={'base'}
      alignItems={'center'}
      gap={'2px'}
      {...badgeProps}
    >
      <Icon
        as={IconLive}
        color={'white'}
        display="inline-block"
        aria-hidden
        boxSize={sizeMap[size].iconSize}
      />
      <Text
        fontSize={sizeMap[size].fontSize}
        fontWeight={'bold'}
        color={'white'}
      >
        LIVE
      </Text>
    </Flex>
  );
}
