import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgFilledCircleStar = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    focusable={false}
    className="th-icon"
    ref={ref}
    {...props}
  >
    <circle cx={9} cy={9} r={8.5} fill="#FF5900" stroke="#000" />
    <path
      fill="#fff"
      d="M8.641 5.26a.377.377 0 0 1 .718 0l.685 2.111a.377.377 0 0 0 .36.26h2.218c.365 0 .517.468.222.683l-1.795 1.304a.377.377 0 0 0-.137.422l.685 2.11a.377.377 0 0 1-.58.422l-1.795-1.304a.377.377 0 0 0-.444 0l-1.795 1.304a.377.377 0 0 1-.58-.422l.685-2.11a.377.377 0 0 0-.137-.422L5.156 8.314a.377.377 0 0 1 .222-.682h2.219a.377.377 0 0 0 .359-.26L8.64 5.26Z"
    />
  </svg>
);
SvgFilledCircleStar.displayName = "IconFilledCircleStar";
const ForwardRef = forwardRef(SvgFilledCircleStar);
export default ForwardRef;
