import {
  Box,
  Fade,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react';
import { IconChevronUp } from '@tigerhall/icons';
import { type PlayingContent } from 'app/state';
import { ContentType, StreamSource } from '@tigerhall/core';

import { ChatReplayTab } from './ChatReplayTab';

interface ChatReplayBottomSheetProps {
  playingContent: PlayingContent;
  isMinimized?: boolean;
  source?: StreamSource;
}

export function ChatReplayBottomSheet({
  playingContent,
  source,
  isMinimized = false
}: Readonly<ChatReplayBottomSheetProps>) {
  const { isOpen, getButtonProps, getDisclosureProps } = useDisclosure();

  const isMobile = useBreakpointValue(
    { base: true, lg: false },
    {
      fallback: 'lg',
      ssr: false
    }
  );

  const [isLandscape] = useMediaQuery('(orientation: landscape)', {
    ssr: false,
    fallback: false
  });

  const isStream = playingContent.type === ContentType.Stream;

  /**
   * Render the chat replay if:
   * - In mobile view
   * - It is a stream
   * - Not in landscape view
   * - The player is not minimized
   * - Source is Agora LiveStream
   */

  if (
    !(isMobile && isStream) ||
    isMinimized ||
    isLandscape ||
    source !== StreamSource.LivestreamAgora
  ) {
    return null;
  }

  return (
    <Box
      bg={'darkGrey.700'}
      h={isOpen ? '18rem' : '3.875rem'}
      transition={'height 300ms'}
      overflow={'hidden'}
    >
      <Flex
        w={'full'}
        justifyContent={'center'}
        py={2}
        borderBottomWidth={1}
        borderColor={isOpen ? 'darkGrey.400' : 'darkGrey.700'}
      >
        <Flex
          as="button"
          borderRadius={'md'}
          px={3}
          py={2}
          gap={1}
          alignItems={'center'}
          justifyContent={'center'}
          w={'full'}
          maxW={'21.5rem'}
          _active={{
            bg: 'whiteAlpha.200'
          }}
          {...getButtonProps()}
        >
          <Icon
            as={IconChevronUp}
            color={'darkGrey.50'}
            boxSize={4}
            transform={`rotate(${isOpen ? -180 : 0}deg)`}
            transition={'transform 300ms'}
          />
          <Text
            as={'span'}
            fontSize={'sm'}
            color={'darkGrey.50'}
            fontWeight={'bold'}
          >
            Chat Replay
          </Text>
        </Flex>
      </Flex>
      <Flex h={'15rem'} pb={4} {...getDisclosureProps()}>
        <Fade
          in={isOpen}
          style={{ height: '100%', width: '100%', overflowY: 'auto' }}
          transition={{
            exit: { duration: 0.1 },
            enter: { delay: 0.2, duration: 0.3 }
          }}
        >
          <ChatReplayTab id={playingContent.id} />
        </Fade>
      </Flex>
    </Box>
  );
}
