import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleObject
} from '@chakra-ui/theme-tools';
import { type ComponentStyleConfig } from '@chakra-ui/react';

const baseStyle: PartsStyleObject<typeof parts> = {
  field: {
    _placeholder: {
      color: 'darkGrey.50',
      fontSize: '0.875rem',
      fontWeight: 'normal',
      lineHeight: '1.0625rem'
    },

    px: 3.5,
    py: 2
  }
};

const size: Record<string, SystemStyleObject> = {
  lg: {
    fontSize: 'lg',
    px: 4,
    h: 12,
    borderRadius: 'lg'
  },

  md: {
    fontSize: 'md',
    px: 4,
    h: 10,
    borderRadius: 'md'
  },

  sm: {
    fontSize: 'sm',
    px: 3,
    h: 8,
    borderRadius: 'md'
  },

  xs: {
    fontSize: 'xs',
    px: 2,
    h: 6,
    borderRadius: 'sm'
  }
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  lg: {
    field: size.lg,
    addon: size.lg
  },
  md: {
    field: size.md,
    addon: size.md
  },
  sm: {
    field: size.sm,
    addon: size.sm
  },
  xs: {
    field: size.xs,
    addon: size.xs
  }
};

const variantOutline: PartsStyleFunction<typeof parts> = (props) => {
  return {
    field: {
      outline: 0,

      _placeholder:
        props.colorScheme === 'white'
          ? {
              color: 'darkGrey.50'
            }
          : {},

      _focus: {
        borderColor: 'orange.600'
      },

      borderColor: props.colorScheme === 'white' ? 'orange.800' : undefined,
      borderRadius: 'lg'
    },
    addon: {}
  };
};

const variantFilled: PartsStyleFunction<typeof parts> = (props) => {
  return {
    field: {
      color: props.colorScheme === 'white' ? 'black' : undefined,
      bg: props.colorScheme === 'white' ? 'white' : 'teal.800',
      borderColor: props.colorScheme === 'white' ? 'lightGrey.400' : undefined,
      borderWidth: 1,

      _focus: {
        borderColor:
          props.colorScheme === 'white' ? 'lightGrey.900' : 'orange.600',
        bg: props.colorScheme === 'white' ? 'white' : 'teal.800',
        borderWidth: 1
      },

      _hover: {
        bg: props.colorScheme === 'white' ? 'white' : 'darkGrey.700'
      }
    }
  };
};

const variantFlushed: PartsStyleFunction<typeof parts> = (props) => {
  return {
    field: {},
    addon: {}
  };
};

const variantUnstyled: PartsStyleObject<typeof parts> = {
  field: {},
  addon: {}
};

const variants = {
  outline: variantOutline,
  filled: variantFilled,
  flushed: variantFlushed,
  unstyled: variantUnstyled
};

const defaultProps = {
  size: 'sm',
  variant: 'filled',
  colorScheme: 'teal'
};

export default {
  parts: parts.keys,
  baseStyle,
  sizes,
  variants,
  defaultProps
} as ComponentStyleConfig;
