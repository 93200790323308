import * as React from 'react';
import property from 'lodash/property';
import { DateTime } from 'luxon';
import { Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';
import { NetworkStatus } from '@apollo/client';
import {
  SortableAndResizableColumn,
  Table as TableUI,
  TableBodyFallback
} from '@tigerhall/ui-components';
import { IconSquareOff } from '@tigerhall/icons';
import {
  Assignment,
  AssignmentStatus,
  SortOrderingInput
} from '@tigerhall/core';
import { useAppSelector } from 'hooks';
import { getSelectedOrg } from 'app/state';
import { ContentPanel } from 'components/ui';
import { BackButton } from 'components/ui/Buttons';
import {
  GetOrgAssignmentsQueryVariables,
  useGetOrgAssignmentsQuery
} from 'generated';

function formatRowDate(date: string) {
  return date ? DateTime.fromISO(date).toFormat('DDD') : '-';
}

const columns: SortableAndResizableColumn<Assignment>[] = [
  {
    id: 'assignmentTitle',
    Header: 'Assignment Title',
    width: 230,
    accessor: 'assignmentTitle'
  },
  {
    id: 'createdBy',
    Header: 'Created By',
    width: 180,
    accessor: (assignment) =>
      `${assignment?.createdBy?.firstName} ${assignment?.createdBy?.lastName}`,
    disableSortBy: true
  },
  {
    id: 'users',
    Header: 'Users',
    width: 100,
    accessor: (assignment) => assignment?.users?.meta?.total,
    disableSortBy: true
  },
  {
    id: 'orgGroups',
    Header: 'Groups',
    width: 200,
    accessor: (assignment) =>
      assignment?.orgGroups
        ? assignment?.orgGroups.map((group) => group.name).join(', ')
        : '-',
    disableSortBy: true
  },
  {
    id: 'createdAt',

    Header: 'Date Created',
    accessor: (assignment) => formatRowDate(assignment?.createdAt)
  },
  {
    id: 'dueDate',
    Header: 'Due Date',
    accessor: (assignment) => formatRowDate(assignment?.dueDate)
  },
  {
    id: 'updatedBy',
    Header: 'Last Updated By',
    width: 180,
    accessor: (assignment) =>
      `${assignment?.updatedBy?.firstName} ${assignment?.updatedBy?.lastName}`,
    disableSortBy: true
  },
  {
    id: 'updatedAt',
    Header: 'Marked as Inactive On',
    accessor: (assignment) => formatRowDate(assignment?.updatedAt)
  }
];

const getRowId = property<Assignment, string>('id');
const INITIAL_LIMIT = 10;

export function InactiveAssignments() {
  const navigate = useNavigate();
  const { id: selectedOrgId = '' } = useAppSelector(getSelectedOrg) || {};

  const [queryVariables, setQueryVariables] =
    React.useState<GetOrgAssignmentsQueryVariables>({
      id: selectedOrgId,
      filter: {
        limit: INITIAL_LIMIT,
        offset: 0,
        title: '',
        status: [AssignmentStatus.Inactive]
      }
    });

  const { data, loading, networkStatus } = useGetOrgAssignmentsQuery({
    variables: queryVariables
  });

  const refetchData = React.useCallback(
    (limit: number, offset: number, val: SortingRule<string>[]) => {
      const sorting: SortOrderingInput[] = [];

      val.forEach((current) => {
        sorting.push({
          field: current.id,
          direction: current.desc ? 'desc' : 'asc'
        });
      });

      setQueryVariables({
        id: queryVariables.id,
        filter: {
          limit: limit,
          offset: offset,
          title: '',
          status: [AssignmentStatus.Inactive]
        },
        sorting: { sorting }
      });
    },
    [setQueryVariables, queryVariables.id]
  );

  return (
    <Flex flexDirection="column" gap="1.5rem">
      <BackButton />
      <ContentPanel
        heading="Inactive Assignments"
        subheading="View all assignments that have been marked as inactive."
      >
        <Flex mt="1.5rem">
          <TableUI
            name="inactiveAssignments"
            data={
              (data?.organisation?.assignments?.edges as Assignment[]) || []
            }
            columns={columns}
            loading={loading || networkStatus === NetworkStatus.refetch}
            onRowClick={(row) => {
              navigate(`/admin/assignments/${row.original.id}`);
            }}
            getRowId={getRowId}
            pageMeta={data?.organisation?.assignments?.meta}
            reFetchData={refetchData}
            emptyMessage={
              <TableBodyFallback
                icon={IconSquareOff}
                heading="You have not marked any assignment as inactive"
                subheading="This is where you can view all the assignments you marked as inactive"
              />
            }
          />
        </Flex>
      </ContentPanel>
    </Flex>
  );
}
