/**
 * This function was created to handle the deep link that comes from our
 * notifications so we can transform the link into a valid route.
 * If the deepLink is not supported this function will return `undefined`
 */
export function transformDeepLinkToPath(deepLink: string): string | undefined {
  const cleanDeepLink = deepLink.replace('tigerhall://', '');

  // StreamPlayer:<livestreamId>
  const livestreamRegex = /^StreamPlayer:(?<livestreamId>[-\w]{36})$/;

  // UserProfile:<userId>
  const userProfileRegex = /^UserProfile:(?<userId>[-\w]{36})$/;

  // <stream|podcast|ebook|event>:<contentId?>:<commentId?>:<replyId?>
  const contentRegex =
    /^(stream|podcast|ebook|event):(?<contentId>[-\w]{36})(:(?<commentId>[-\w]{36}))?(:(?<replyId>[-\w]{36}))?$/i;

  // Trail:<trailId?>:<commentId?>:<replyId?>
  const trailRegex =
    /^Trail:(?<trailId>[-\w]{36})(:(?<commentId>[-\w]{36}))?(:(?<replyId>[-\w]{36}))?$/i;

  // AssignmentDetails:<assignmentId>
  const assignmentRegex = /^AssignmentDetails:(?<assignmentId>[-\w]{36})$/;

  // Post:<postId>:<commentId>:<replyId>
  const postRegex =
    /^Post:(?<postId>[-\w]{36})(:(?<commentId>[-\w]{36}))?(:(?<replyId>[-\w]{36}))?$/i;

  // Post:<postId>:User:<userId>
  const postUserRegex =
    /^Post:(?<postId>[-\w]{36}):User:(?<userId>[-\w]{36})$/i;

  // Organization:People
  const organizationPeopleRegex = /^Organization:People$/i;

  if (livestreamRegex.test(cleanDeepLink)) {
    const { livestreamId } = livestreamRegex?.exec(cleanDeepLink)?.groups ?? {};
    return `/home?livestream=${livestreamId}`;
  }

  if (userProfileRegex.test(cleanDeepLink)) {
    const { userId } = userProfileRegex?.exec(cleanDeepLink)?.groups ?? {};
    return `/profile/${userId}`;
  }

  if (contentRegex.test(cleanDeepLink)) {
    const { contentId, commentId, replyId } =
      contentRegex?.exec(cleanDeepLink)?.groups ?? {};
    if (contentId && commentId && replyId) {
      return `/home?content=${contentId}&comment=${commentId}&highlight=${replyId}`;
    }
    if (contentId && commentId) {
      return `/home?content=${contentId}&highlight=${commentId}`;
    }
    return `/home?content=${contentId}`;
  }

  if (trailRegex.test(cleanDeepLink)) {
    const { trailId, commentId, replyId } =
      trailRegex?.exec(cleanDeepLink)?.groups ?? {};

    if (trailId && commentId && replyId) {
      return `/home?trail=${trailId}&comment=${commentId}&highlight=${replyId}`;
    }
    if (trailId && commentId) {
      return `/home?trail=${trailId}&highlight=${commentId}`;
    }
    return `/home?trail=${trailId}`;
  }

  if (assignmentRegex.test(cleanDeepLink)) {
    const { assignmentId } = assignmentRegex?.exec(cleanDeepLink)?.groups ?? {};
    return `/home?assignment=${assignmentId}`;
  }

  if (postRegex.test(cleanDeepLink)) {
    const { postId, commentId, replyId } =
      postRegex.exec(cleanDeepLink)?.groups ?? {};

    if (postId && commentId && replyId) {
      return `/feed/${postId}/comment/${commentId}?highlight=${replyId}`;
    }
    if (postId && commentId) {
      return `/feed/${postId}?highlight=${commentId}`;
    }
    return `/feed/${postId}`;
  }

  if (postUserRegex.test(cleanDeepLink)) {
    const { postId, userId } = postUserRegex.exec(cleanDeepLink)?.groups ?? {};
    return `/feed/${postId}?user=${userId}`;
  }

  if (organizationPeopleRegex.test(cleanDeepLink)) {
    return '/organization/people';
  }

  return undefined;
}
