import * as React from 'react';
import { ExtractTypename, useFeaturedContentEntriesQuery } from 'generated';
import {
  FeaturedContentCarousel,
  FeaturedContentCarouselSkeleton
} from 'components/ui/FeaturedContentCarousel';

interface FeaturedContentSectionProps
  extends Omit<
    React.ComponentProps<typeof FeaturedContentCarousel>,
    'featuredContent'
  > {}

export function FeaturedContentSection({
  heading,
  isVisibleOnFirstPaint
}: FeaturedContentSectionProps) {
  const { data, loading } = useFeaturedContentEntriesQuery({
    variables: {
      filter: {
        onlyVisible: true
      }
    }
  });

  const featuredContent = data?.featuredContentEntries?.edges;

  if (loading) {
    return <FeaturedContentCarouselSkeleton hasHeading={!!heading} />;
  }

  if (!featuredContent || featuredContent.length === 0) {
    return null;
  }

  return (
    <FeaturedContentCarousel
      featuredContent={featuredContent.map((item) => {
        return {
          ...item,
          // we make sure that content can't be
          content: item.content as ExtractTypename<
            typeof item.content,
            'Ebook' | 'Event' | 'Podcast' | 'Stream'
          >
        };
      })}
      heading={heading}
      isVisibleOnFirstPaint={isVisibleOnFirstPaint}
    />
  );
}
