import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgBrandMicrosoftColor = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    focusable={false}
    className="th-icon"
    ref={ref}
    {...props}
  >
    <path fill="#F25022" d="M11.368 0H0v11.368h11.368V0Z" />
    <path fill="#00A4EF" d="M11.368 12.632H0V24h11.368V12.632Z" />
    <path fill="#7FBA00" d="M24 0H12.632v11.368H24V0Z" />
    <path fill="#FFB900" d="M24 12.632H12.632V24H24V12.632Z" />
  </svg>
);
SvgBrandMicrosoftColor.displayName = "IconBrandMicrosoftColor";
const ForwardRef = forwardRef(SvgBrandMicrosoftColor);
export default ForwardRef;
