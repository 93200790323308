import { mode as o } from "./index31.mjs";
const r = (t) => ({}), n = (t) => ({}), s = (t) => ({
  minH: "3rem"
}), a = {}, l = (t) => ({
  root: r(),
  tab: n(),
  tablist: s(),
  tabpanel: a
}), c = (t) => {
  const { colorScheme: e } = t;
  return {
    tab: {
      color: o(`${e}.600`, "darkGrey.50")(t),
      fontSize: "1.125rem",
      fontWeight: 700,
      _selected: {
        color: o(`${e}.600`, `${e}.600`)(t),
        borderColor: "currentColor"
      }
    }
  };
}, i = {}, b = {
  line: c,
  unstyled: i
}, p = {
  size: "lg",
  variant: "line",
  colorScheme: "tigerOrange"
}, y = {
  baseStyle: l,
  variants: b,
  defaultProps: p
};
export {
  y as Tabs
};
