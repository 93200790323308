import { Flex, SkeletonText } from '@chakra-ui/react';

export function PostTextSkeleton() {
  return (
    <Flex
      position="relative"
      mb="1.25rem"
      mx={{
        base: '1rem',
        md: '2rem'
      }}
    >
      <SkeletonText
        noOfLines={3}
        skeletonHeight="1rem"
        spacing="0.5rem"
        py="0.25rem"
        width="100%"
      />
    </Flex>
  );
}
