import * as React from 'react';
import property from 'lodash/property';
import { SortingRule } from 'react-table';
import { Flex, Heading, SimpleGrid, Text, useToast } from '@chakra-ui/react';
import {
  FormInput,
  FormRangeDatePicker,
  TrackedRTForm
} from '@tigerhall/components';
import { IconUser } from '@tigerhall/icons';
import { OrganisationGroup, SortOrderingInput } from '@tigerhall/core';
import { Table, TableBodyFallback } from '@tigerhall/ui-components';
import { OrganisationGroupSelect } from 'components/form';
import { useAppSelector } from 'hooks';
import { getSelectedOrg } from 'app/state';
import {
  GetOrganisationMembersQueryVariables,
  useGetOrganisationMembersQuery
} from 'queries/generated/organisation.generated';

import { getContentUsersColumns } from '../table';

const USER_COLUMNS = getContentUsersColumns();

interface FormValues extends Record<string, unknown> {
  name: string;
  groupId?: OrganisationGroup;
  createdBetween?: Date | Date[];
}

interface UsersSelectionTableProps {
  selectedRowIds: string[];
  onRowSelection: (row: any) => void;
}
const DEFAULT_FILTER_LIMIT = 10;
const DEFAULT_FILTER_OFFSET = 0;

export function UsersSelectionTable({
  selectedRowIds,
  onRowSelection
}: Readonly<UsersSelectionTableProps>) {
  const { id: organisationId = '' } = useAppSelector(getSelectedOrg) || {};
  const [queryVariables, setQueryVariables] =
    React.useState<GetOrganisationMembersQueryVariables>({
      id: organisationId,
      filter: {
        limit: DEFAULT_FILTER_LIMIT,
        offset: DEFAULT_FILTER_OFFSET
      }
    });
  const toast = useToast({ position: 'top-right', isClosable: true });

  const { data, loading } = useGetOrganisationMembersQuery({
    skip: !organisationId,
    variables: queryVariables,
    onError: () => {
      toast({
        title: 'An error occurred. Please try again later or contact support.',
        status: 'error'
      });
    }
  });

  const { edges: users, meta } = data?.organisation?.members || {};

  const refetchData = React.useCallback(
    (limit: number, offset: number, val: SortingRule<string>[]) => {
      const sorting: SortOrderingInput[] = [];

      val.forEach((current) => {
        if (current.id === 'name') {
          sorting.push({
            field: 'firstName',
            direction: current.desc ? 'desc' : 'asc'
          });

          sorting.push({
            field: 'lastName',
            direction: current.desc ? 'desc' : 'asc'
          });
        } else {
          sorting.push({
            field: current.id,
            direction: current.desc ? 'desc' : 'asc'
          });
        }
      });

      setQueryVariables({
        id: queryVariables.id,
        filter: {
          ...queryVariables.filter,
          limit: limit,
          offset: offset
        },
        sorting: { sorting }
      });
    },
    [setQueryVariables, queryVariables.id, queryVariables.filter]
  );

  const onFilterChange = React.useCallback(
    (values: FormValues) => {
      setQueryVariables({
        ...queryVariables,
        filter: {
          ...queryVariables.filter,
          name: values.name,
          groupId: values.groupId ? values.groupId.id : null,
          createdBetween: values.createdBetween
            ? {
                from: values.createdBetween?.[0] || values.createdBetween,
                to: values.createdBetween?.[1]
              }
            : null
        }
      });
    },
    [setQueryVariables, queryVariables]
  );

  const selectedRowsTotal = selectedRowIds?.length ?? 0;

  return (
    <>
      <Flex direction="column" py="1rem" gap="2rem" flex={1}>
        <TrackedRTForm<FormValues>
          name="assignmentUsersSearch"
          onSubmit={onFilterChange}
        >
          <Text color="white" fontSize="lg" fontWeight="bold" pb="0.25rem">
            Filter by
          </Text>
          <SimpleGrid columns={3} gap="1rem">
            <FormInput name="name" label="Search" placeholder="Search user" />
            <OrganisationGroupSelect name="groupId" />
            <FormRangeDatePicker
              name="createdBetween"
              placeholder="Select a date range"
              label="Time Period"
              numberOfCalendars={2}
              maxDate={new Date()}
            />
          </SimpleGrid>
        </TrackedRTForm>
        <Flex direction="column" gap="1rem">
          <Heading as="span" fontSize="md" fontWeight="bold" color="white">
            {selectedRowsTotal} {selectedRowsTotal === 1 ? 'User' : 'Users'}{' '}
            Selected
          </Heading>
          <Table
            data={users || []}
            loading={loading}
            columns={USER_COLUMNS}
            pageMeta={meta}
            getRowId={property('id')}
            selectedRowIds={selectedRowIds}
            onRowSelection={onRowSelection}
            reFetchData={refetchData}
            emptyMessage={
              <TableBodyFallback
                icon={IconUser}
                heading="No User Selected"
                subheading="You have not assigned any user to this assignment"
              />
            }
          />
        </Flex>
      </Flex>
    </>
  );
}
