import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgBrandTigerhallVertical = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 180 126"
    focusable={false}
    className="th-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentcolor"
      d="M94.928 76.256h.65c6.612 0 13.222.006 19.833-.004 2.114-.003 3.702.878 4.615 2.79 1.467 3.074-.778 6.68-4.181 6.783h-.051c-17.364.001-34.728.006-52.092-.005-2.017 0-3.766-1.307-4.43-3.207-1.053-3.012 1.072-6.19 4.261-6.334 1.181-.052 2.367-.022 3.55-.022 5.563-.002 11.125-.001 16.688-.001h.672V53.548h-5.05v18.554H69.18v-.537l.001-45.65c0-2.732 1.636-4.855 4.076-5.319 3.202-.608 6.063 1.706 6.116 4.979.032 1.977.007 3.956.007 5.934V43.643h5.089V6.28c0-2.35 1.202-4.232 3.217-5.064 3.182-1.312 6.74.764 7.14 4.17.044.37.06.743.06 1.114.003 12.156.003 24.313.003 36.47v.672h4.5v-.621c0-5.749-.005-11.497.003-17.246.003-2.624 1.694-4.739 4.112-5.183 3.19-.586 5.95 1.667 6.085 4.97.007.168.004.338.004.507v46.028H99.414V53.541h-4.486v22.715Z"
    />
    <path
      fill="currentcolor"
      fillRule="evenodd"
      d="m89.737 118.442 2.763 5.563c.389.783.114 1.243-.758 1.251-.895.008-1.791-.003-2.687.002-.55.004-.912-.231-1.147-.742-.706-1.533-1.446-3.05-2.154-4.581-.134-.29-.303-.394-.616-.385-.741.02-1.483.007-2.303.007v.581c0 1.402.002 2.805-.001 4.208-.002.696-.211.906-.906.91-.879.005-1.757.007-2.636 0-.651-.005-.865-.205-.865-.841-.004-5.443-.004-10.885 0-16.328 0-.595.203-.804.789-.805 2.67-.001 5.342-.047 8.01.03 2.724.078 4.872 2.126 5.215 4.841.243 1.926-.079 3.712-1.435 5.191-.36.393-.81.706-1.269 1.098Zm-6.862-6.704v3.6c1.307 0 2.575.039 3.839-.026.326-.018.744-.292.931-.574.477-.72.468-1.535.139-2.328-.17-.406-.502-.635-.95-.651-.522-.018-1.046-.018-1.57-.021-.79-.003-1.58 0-2.39 0Z"
      clipRule="evenodd"
    />
    <path
      fill="currentcolor"
      d="M44.67 118.725c-.877 0-1.685.004-2.493-.002-.683-.005-.873-.197-.877-.876-.003-.659-.002-1.319 0-1.978.003-.703.198-.908.9-.91 1.911-.006 3.822-.003 5.732-.002.186 0 .373-.01.558.004.515.039.735.258.736.771.005 2.908.004 5.816 0 8.724 0 .536-.236.769-.764.801l-.05.001c-.922.068-1.809.07-2.019-1.114-.111.018-.167.012-.206.035-3.256 1.878-6.513 1.685-9.568-.322-3.025-1.986-4.303-4.984-3.95-8.563.401-4.074 3.1-7.125 7.088-7.994 3.172-.692 6.068.031 8.533 2.223.447.397.447.858.01 1.258-.524.479-1.058.947-1.592 1.415-.519.455-.823.471-1.394.057-1.361-.986-2.905-1.195-4.494-.91-1.987.355-3.443 2.069-3.748 4.224-.357 2.524 1.192 5.117 3.519 5.783 1.207.345 2.437.295 3.641-.109.31-.104.467-.252.447-.614-.034-.605-.01-1.214-.01-1.902ZM104.703 114.4h5.559v-.569c0-1.86-.002-3.719.002-5.579.002-.817.162-.971.988-.973.862-.003 1.724-.007 2.586.002.639.007.843.217.843.861.002 5.409.002 10.818 0 16.227 0 .662-.218.882-.878.887-.879.007-1.758.006-2.637 0-.699-.004-.898-.2-.901-.914-.007-1.691-.002-3.381-.003-5.071v-.637h-5.534c-.008.184-.024.364-.024.544-.002 1.707.001 3.414-.002 5.121-.002.75-.214.955-.975.958-.862.003-1.724.006-2.586-.001-.66-.005-.874-.222-.874-.892-.003-5.392-.003-10.785-.001-16.177.001-.712.191-.903.901-.907.879-.006 1.758-.007 2.637 0 .689.006.896.217.897.923.004 1.876.002 3.753.002 5.629v.568ZM62.037 111.555v2.709h.776c1.673 0 3.347-.004 5.02.002.702.003.895.205.9.913.003.727 0 1.453 0 2.18 0 .945-.144 1.093-1.074 1.094H62.046v2.619h.572l6.694.001c.937 0 1.076.143 1.077 1.099 0 .727.005 1.454-.002 2.18-.006.679-.207.901-.866.902a3736.8 3736.8 0 0 1-11.105 0c-.596-.001-.844-.243-.844-.829-.005-5.443-.005-10.885 0-16.327 0-.575.232-.815.797-.816 3.735-.006 7.47-.006 11.206 0 .576.001.8.226.81.799.013.844.007 1.69.002 2.535-.004.735-.198.935-.934.936-2.265.006-4.53.003-6.794.003h-.622Z"
    />
    <path
      fill="currentcolor"
      fillRule="evenodd"
      d="M123.941 125.257c-.508 0-1.015.012-1.522-.003-.681-.02-.944-.443-.653-1.045 2.621-5.428 5.246-10.853 7.868-16.28.217-.45.551-.73 1.062-.726.508.005.833.287 1.051.74 2.615 5.412 5.235 10.82 7.852 16.231.309.638.052 1.069-.659 1.08-.98.014-1.962-.014-2.941.011-.552.013-.895-.232-1.115-.707-.34-.735-.696-1.464-1.017-2.207-.12-.278-.281-.365-.574-.362-1.742.011-3.483.013-5.225-.002-.305-.002-.45.112-.566.379-.31.712-.655 1.408-.971 2.117-.239.536-.604.826-1.221.781-.455-.033-.913-.006-1.369-.007Zm8.055-6.977-1.309-2.709-1.367 2.709h2.676Z"
      clipRule="evenodd"
    />
    <path
      fill="currentcolor"
      d="M8.826 111.556V124.195c0 .886-.179 1.063-1.071 1.064-.812 0-1.623.003-2.434-.001-.727-.004-.935-.208-.935-.938-.002-4.039-.001-8.079-.001-12.118v-.646H1.098c-.949-.001-1.096-.145-1.096-1.071 0-.795-.005-1.589.001-2.383.005-.587.21-.817.781-.818 3.888-.006 7.775-.006 11.662.001.542.001.757.239.762.788.007.845.003 1.69.002 2.535-.002.757-.19.944-.969.947-1.113.004-2.227.001-3.415.001ZM171.603 121.112h7.339c.892 0 1.055.164 1.056 1.065 0 .744.006 1.488-.003 2.231-.006.606-.222.844-.823.846-3.735.008-7.47.007-11.205 0-.549-.001-.75-.221-.787-.776-.007-.118-.003-.236-.003-.355v-15.718c0-1.009.12-1.125 1.144-1.126.795 0 1.589-.004 2.383.002.708.005.898.198.898.909l.001 12.27v.652ZM151.11 121.112h7.445c.75.001.948.204.95.967.002.778.007 1.555-.001 2.333-.007.602-.225.841-.826.842-3.736.008-7.471.007-11.206 0-.563-.001-.772-.229-.777-.83-.011-1.352-.004-2.704-.004-4.056v-11.965c0-1.025.101-1.123 1.143-1.124.794 0 1.589-.005 2.383.002.708.005.891.195.892.913l.001 12.27v.648ZM24.878 116.252l-.001 8.066c0 .726-.207.935-.932.939-.862.004-1.725.005-2.587-.001-.699-.004-.907-.204-.908-.914-.004-5.395-.005-10.79 0-16.184.001-.682.205-.873.884-.878.88-.007 1.76-.006 2.638 0 .71.004.904.2.905.905.002 2.689 0 5.378 0 8.067Z"
    />
  </svg>
);
SvgBrandTigerhallVertical.displayName = "IconBrandTigerhallVertical";
const ForwardRef = forwardRef(SvgBrandTigerhallVertical);
export default ForwardRef;
