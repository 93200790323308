import { Text, Heading, VStack } from '@chakra-ui/react';

import { TrackedButton } from '~/tracked';
import { LiveContentOverlayWrapper } from './LiveContentOverlayWrapper';

interface LiveContentEndedOverlayProps {
  handleClose: () => void;
}

export function LiveContentEndedOverlay({
  handleClose
}: LiveContentEndedOverlayProps) {
  return (
    <LiveContentOverlayWrapper>
      <VStack gap={'0.5rem'}>
        <Heading color={'white'}>Thank you for watching!</Heading>
        <Text color={'white'}>Live video has ended.</Text>
      </VStack>
      <TrackedButton
        name="Broadcast-ended"
        as="button"
        variant="solid"
        size="lg"
        borderRadius="full"
        mb="1.5rem"
        px={'1.5rem'}
        py={'.375rem'}
        minW={'330px'}
        onClick={handleClose}
      >
        Done
      </TrackedButton>
    </LiveContentOverlayWrapper>
  );
}
