import { deleteCookie, DOMAIN, getCookie, setCookie } from '~/utils';

const COOKIE_NAME = 'lastLocation';

interface SetLastLocationProps {
  /** The location to set as the last location. */
  location: string;
  /**
   * The duration in hours that the cookie should last.
   * @default 0.1667 (10 minutes)
   */
  durationInHours?: number;
  /**
   * Whether the cookie can be use across domain and subdomains or not.
   * WARNING: will not work locally because localhost is not a domain.
   * @default false
   */
  crossDomain?: boolean;
}

/**
 * Sets the last location in a cookie so we can redirect back to it after login.
 * If `customLocation` is provided, it will be used instead of the current location.
 */
export function setLastLocation({
  location,
  durationInHours = 0.1667,
  crossDomain = false
}: SetLastLocationProps) {
  // the cookie lasts 10 minutes by default so users that leave and come back 10
  // minutes later will be redirected to the home page
  setCookie(
    COOKIE_NAME,
    location,
    durationInHours,
    crossDomain ? `.${DOMAIN}` : undefined
  );
}

export function deleteLastLocation() {
  deleteCookie(COOKIE_NAME);
}

/**
 * This hook gets and returns the last location and then
 * sets the current location as the last location in a cookie.
 * The cookie exists for the duration of the session.
 *
 * @param initialLocation Set this as the `lastLocation` when
 * this hook is first called. This is useful when you want to
 * set the `lastLocation` to a location other than the current
 * location.
 *
 * @example```ts
 * const { lastLocation } = useLastLocation();
 * return <Navigate to={lastLocation} />
 * ```
 *
 * @example```ts
 * const { setLastLocation } = useLastLocation();
 * setLastLocation('/some/custom/location');
 * ```
 */
export function useLastLocation(initialLocation?: string) {
  // get and return the last location
  const lastLocation = getCookie(COOKIE_NAME);

  if (initialLocation) {
    setLastLocation({ location: initialLocation });
  }

  return { lastLocation, setLastLocation, deleteLastLocation };
}
