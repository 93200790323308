import { HStack, Icon, Text } from '@chakra-ui/react';
import { IconCircleCheckFilled } from '@tigerhall/icons';

export function CompletedBadge() {
  return (
    <HStack bg={'green.900'} borderRadius={100} px={4} py={2}>
      <Icon
        as={IconCircleCheckFilled}
        color={'green.500'}
        w={'1.125rem'}
        h={'1.125rem'}
      />
      <Text fontSize={'sm'} color={'white'}>
        Completed
      </Text>
    </HStack>
  );
}
