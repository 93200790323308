import { type User } from '@tigerhall/core';
import { IconBan } from '@tigerhall/icons';
import { useDisclosure } from '@chakra-ui/react';

import { OptionsMenuItem } from '~/components/ui/OptionsMenu';
import { BlockUnblockUserModal } from '~/components/ui/modals';

export interface BlockUnblockProfileOptionProps {
  readonly user: Pick<
    User,
    'id' | 'isExpert' | 'isBlockingCurrentUser' | 'isBlockedByCurrentUser'
  >;
}
export function BlockUnblockProfileOption({
  user
}: BlockUnblockProfileOptionProps) {
  const { id, isExpert, isBlockedByCurrentUser } = user;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const details = isBlockedByCurrentUser
    ? UNBLOCK_USER_DETAILS
    : BLOCK_USER_DETAILS;

  return (
    <>
      <OptionsMenuItem
        icon={IconBan}
        title={details.title}
        description={details.description}
        onClick={onOpen}
      />
      <BlockUnblockUserModal
        userId={id}
        isThinkfluencer={isExpert}
        isBlockedByCurrentUser={isBlockedByCurrentUser}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

const BLOCK_USER_DETAILS = {
  title: 'Block User',
  description: 'They won’t be able to view your activity and search for you.'
};

const UNBLOCK_USER_DETAILS = {
  title: 'Unblock User',
  description: 'They will be able to view your activity and search for you.'
};
