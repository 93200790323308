import { ContentCard, ContentCardProps } from '@tigerhall/components';
import { linkToCategory, linkToContent, linkToExpert } from 'utils/routes';
import { useContentActions } from 'modules/application/hooks/useContentActions';

export interface ContentCardWithLinksProps extends ContentCardProps {}

export function ContentCardWithLinks(props: ContentCardProps) {
  const { toggleBookmark } = useContentActions();

  function onBookmarkClick() {
    toggleBookmark(props);
  }
  return (
    <ContentCard
      getPathToContent={linkToContent}
      getPathToCategory={linkToCategory}
      getPathToExpert={linkToExpert}
      // If no click event is received then togglebookmark is send in props
      // need to refactor this while replacing old content cards with new one
      // as BookmarkButton has a condition to hide button if no click event is passed as props
      onBookmarkClick={props.onBookmarkClick || onBookmarkClick}
      {...props}
    />
  );
}
