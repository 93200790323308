import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import { ExpertFragmentDoc } from './expert.generated';
import { UserContentTrackingFragmentDoc } from './userContentTracking.generated';
import { ExpertBasicInfoFragmentDoc } from '../../modules/admin/modules/trails/queries/generated/GetLearningPathInfoById.generated';
export type EbookFragment = { __typename: 'Ebook', id: string, name: string, isFree: boolean, preamble: string, entryLevel: SchemaTypes.EntryLevelValue, length: number, readingTime?: number | null, isAssigned: boolean, createdAt: any, updatedAt: any, publishedAt?: any | null, rating: number, upvoteCount: number, contentRatingByUser: SchemaTypes.ContentVote, availableLanguages: Array<{ __typename?: 'Language', code: string, name: string }>, categories: Array<{ __typename?: 'Category', id: string, name: string }>, image: { __typename?: 'Image', id: string, uri: string, alt: string }, contentSegments: Array<{ __typename?: 'ContentSegment', header: string, paragraph: string }>, chapters: Array<{ __typename?: 'Chapter', name: string, index: number, pages: Array<string> }>, experts: Array<{ __typename?: 'Expert', id: string, title: string, firstName: string, lastName: string, biography: string, createdAt: any, updatedAt: any, mentorAvailableText?: string | null, mentorPriceText?: string | null, isMentor: boolean, isAuthenticatedUserFollowing: boolean, company?: string | null, userId?: string | null, image: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string }, categories: Array<{ __typename?: 'Category', id: string, name: string, image?: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string } | null }> }>, userContentTracking: { __typename: 'UserContentTracking', id: string, current: number, currentPlacement: number, total: number, isAttending: boolean, isFinished: boolean, bookmarkedAt?: any | null, lastOpenedAt?: any | null, finishedAt?: any | null }, organisation?: { __typename?: 'Organisation', id: string } | null, organisationGroups: Array<{ __typename?: 'OrganisationGroup', id: string, name: string }> };

export type EbookCardFragment = { __typename: 'Ebook', id: string, name: string, readingTime?: number | null, updatedAt: any, isAssigned: boolean, categories: Array<{ __typename?: 'Category', id: string, name: string }>, image: { __typename?: 'Image', id: string, uri: string, alt: string }, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, company?: string | null, title: string, isAuthenticatedUserFollowing: boolean }>, userContentTracking: { __typename: 'UserContentTracking', id: string, current: number, currentPlacement: number, total: number, isAttending: boolean, isFinished: boolean, bookmarkedAt?: any | null, lastOpenedAt?: any | null, finishedAt?: any | null } };

export const EbookFragmentDoc = gql`
    fragment Ebook on Ebook {
  __typename
  id
  name
  isFree
  preamble
  entryLevel
  length
  readingTime
  isAssigned
  availableLanguages {
    code
    name
  }
  createdAt
  updatedAt
  publishedAt
  rating
  categories {
    id
    name
  }
  image {
    id
    uri
    alt
  }
  contentSegments {
    header
    paragraph
  }
  availableLanguages {
    code
    name
  }
  chapters {
    name
    index
    pages
  }
  experts {
    ...Expert
  }
  publishedAt
  userContentTracking {
    ...UserContentTracking
  }
  organisation {
    id
  }
  length
  organisationGroups {
    id
    name
  }
  upvoteCount
  contentRatingByUser
}
    ${ExpertFragmentDoc}
${UserContentTrackingFragmentDoc}`;
export const EbookCardFragmentDoc = gql`
    fragment EbookCard on Ebook {
  __typename
  id
  name
  readingTime
  updatedAt
  isAssigned
  categories {
    id
    name
  }
  image {
    id
    uri
    alt
  }
  experts {
    ...ExpertBasicInfo
  }
  userContentTracking {
    ...UserContentTracking
  }
}
    ${ExpertBasicInfoFragmentDoc}
${UserContentTrackingFragmentDoc}`;