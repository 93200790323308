import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import { PostFragmentDoc, PostActivityFragmentDoc } from '../../../../../../../../../../../../queries/fragments/generated/feed.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateInstantRepostMutationVariables = SchemaTypes.Exact<{
  postId: SchemaTypes.Scalars['ID'];
}>;


export type CreateInstantRepostMutation = { __typename?: 'Mutation', createInstantRepost: { __typename?: 'Post', id: string, createdAt: any, updatedAt?: any | null, privacySettings: SchemaTypes.PrivacySettings, description?: string | null, numberOfComments: number, numberOfCommenters: number, numberOfReposts: number, parent?: { __typename?: 'Post', id: string, createdAt: any, updatedAt?: any | null, privacySettings: SchemaTypes.PrivacySettings, description?: string | null, numberOfComments: number, numberOfCommenters: number, numberOfReposts: number, reactions: { __typename?: 'ReactionsSummary', totalCount: number, reactions: Array<{ __typename?: 'ReactionCount', emoji: string }>, userReacted?: { __typename?: 'UserReaction', id: string, reactionType: SchemaTypes.ReactionType } | null }, user: { __typename?: 'User', id: string, firstName: string, lastName: string, isExpert: boolean, isFollowing: boolean, expertOrg?: { __typename?: 'Organisation', id: string } | null, avatarImage: { __typename?: 'Image', id: string, uri: string, alt: string } }, attachments: Array<{ __typename: 'FeedPostAttachments', uri?: string | null, attachmentType: string } | null> } | null, reactions: { __typename?: 'ReactionsSummary', totalCount: number, reactions: Array<{ __typename?: 'ReactionCount', emoji: string }>, userReacted?: { __typename?: 'UserReaction', id: string, reactionType: SchemaTypes.ReactionType } | null }, user: { __typename?: 'User', id: string, firstName: string, lastName: string, isExpert: boolean, isFollowing: boolean, expertOrg?: { __typename?: 'Organisation', id: string } | null, avatarImage: { __typename?: 'Image', id: string, uri: string, alt: string } }, attachments: Array<{ __typename: 'FeedPostAttachments', uri?: string | null, attachmentType: string } | null> } };


export const CreateInstantRepostDocument = gql`
    mutation CreateInstantRepost($postId: ID!) {
  createInstantRepost(postId: $postId) {
    ...Post
  }
}
    ${PostFragmentDoc}`;
export type CreateInstantRepostMutationFn = Apollo.MutationFunction<CreateInstantRepostMutation, CreateInstantRepostMutationVariables>;

/**
 * __useCreateInstantRepostMutation__
 *
 * To run a mutation, you first call `useCreateInstantRepostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstantRepostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstantRepostMutation, { data, loading, error }] = useCreateInstantRepostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useCreateInstantRepostMutation(baseOptions?: Apollo.MutationHookOptions<CreateInstantRepostMutation, CreateInstantRepostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInstantRepostMutation, CreateInstantRepostMutationVariables>(CreateInstantRepostDocument, options);
      }
export type CreateInstantRepostMutationHookResult = ReturnType<typeof useCreateInstantRepostMutation>;
export type CreateInstantRepostMutationResult = Apollo.MutationResult<CreateInstantRepostMutation>;
export type CreateInstantRepostMutationOptions = Apollo.BaseMutationOptions<CreateInstantRepostMutation, CreateInstantRepostMutationVariables>;