import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  EntryLevelValue,
  EventInput,
  EventType
} from '@tigerhall/core/lib/types';
import { useAppSelector } from 'hooks';
import { getSelectedOrg } from 'app/state';
import { useFetchEventQuery } from 'generated';

import { Form } from './components';
import { useCreateEventMutation, useUpdateEventMutation } from '../../queries';

export default function EventForm() {
  const nav = useNavigate();
  const org = useAppSelector(getSelectedOrg);

  const { idOrDraft } = useParams();

  const [create] = useCreateEventMutation();
  const [update] = useUpdateEventMutation();

  const isDraft = idOrDraft === 'draft';

  const { data, loading, error } = useFetchEventQuery({
    variables: {
      id: idOrDraft as string
    },

    skip: isDraft
  });

  const onSubmit = React.useCallback(
    async (values) => {
      const input: EventInput = {
        name: values.name,
        preamble: values.preamble,
        maxAttendees: Number.parseInt(values.maxAttendees),

        // We are doing this to remove __typename
        contentSegments: values.contentSegments.map(
          ({ header, paragraph }) => ({
            header,
            paragraph
          })
        ),

        isSensitive: false,

        image: values.image.id,
        experts: values.experts.map((e) => e.id),
        categories: values.categories.map((c) => c.id),

        organisationID: org?.id as string,
        organisationGroups: values.organisationGroups?.map((g) => g.id) || [],

        isOnline: values.isOnline,
        conferenceUrl: values.conferenceUrl,
        locationDisplayName: values.locationDisplayName,

        startsAt: values.startsAt,
        endsAt: values.endsAt,
        publishedAt: values.publishedAt,

        waitListEnabled: false,
        questionnaire: '',
        entryLevel: EntryLevelValue.Beginner,
        regionRestrictions: [],

        // todo: this should probably be a dropdown?
        eventType: EventType.Livestream
      };

      if (isDraft) {
        await create({
          variables: {
            input
          }
        });

        nav('/admin/events/draft/success');
      } else {
        await update({
          variables: {
            id: idOrDraft as string,
            input
          }
        });

        nav(`/admin/events/${idOrDraft}/success`);
      }
    },
    [create, update, isDraft, idOrDraft, org?.id, nav]
  );

  // todo: add a loading indcator
  if (loading) {
    return null;
  }

  // todo: add a error splash screen
  if (error) {
    return null;
  }

  const defaultValues = isDraft
    ? {
        isOnline: true,
        contentSegments: [
          {
            header: '',
            paragraph: ''
          }
        ]
      }
    : {
        ...data?.event,
        startsAt: data?.event?.startsAt && new Date(data.event.startsAt),
        endsAt: data?.event?.endsAt && new Date(data.event.endsAt),
        publishedAt:
          data?.event?.publishedAt && new Date(data.event.publishedAt)
      };

  return (
    <Form isDraft={isDraft} onSubmit={onSubmit} defaultValues={defaultValues} />
  );
}
