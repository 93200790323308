import { Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import {
  type Ebook,
  type Event,
  type LearningPath,
  type Podcast,
  type Stream
} from '@tigerhall/core/lib/types';
import { IconChevronLeft } from '@tigerhall/icons';
import { CONTENT_CARD_DIMENSIONS } from '@tigerhall/components';
import { TigerhallSkeleton } from '@tigerhall/ui-components';

import {
  ContentBarWithLinks,
  ContentCardWithLinks
} from '../../../../components/ui/ContentBox';
import { DummyCopies } from '../../../../components/ui';

type Content = LearningPath | Stream | Podcast | Ebook | Event;

interface ContentCardsCarouselProps {
  sectionTitle: string;
  contentList: (Content & { footer?: string })[];
  isLoading?: boolean;
  fetchMore?: () => void;
}

const SLIDE_STYLE = {
  width: '15rem'
};

export function ContentCardsCarousel({
  contentList,
  isLoading,
  fetchMore,
  sectionTitle
}: ContentCardsCarouselProps) {
  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { fallback: 'md', ssr: false }
  );

  const shouldRenderContentBar = contentList && contentList.length <= 1;
  const paddingX = isMobile ? '1rem' : '2rem';

  if (isLoading) {
    return (
      <HStack spacing="1.5rem" overflow="hidden" pl="2rem">
        <DummyCopies count={10}>
          <Box
            {...CONTENT_CARD_DIMENSIONS}
            borderRadius="lg"
            overflow="hidden"
            flexShrink={0}
          >
            <TigerhallSkeleton variant="content_white_card" />
          </Box>
        </DummyCopies>
      </HStack>
    );
  }

  return shouldRenderContentBar ? (
    <Box px={paddingX}>
      <ContentBarWithLinks {...contentList[0]} sectionName={sectionTitle} />
    </Box>
  ) : (
    <Swiper
      modules={[Mousewheel, Navigation]}
      className="content-cards-carousel"
      spaceBetween={24}
      slidesPerView="auto"
      mousewheel={{
        forceToAxis: true,
        sensitivity: 1.5
      }}
      navigation={!isMobile}
      style={{
        paddingLeft: paddingX,
        paddingRight: paddingX
      }}
    >
      <>
        {contentList?.map((item, index) => (
          <SwiperSlide key={item.id} style={SLIDE_STYLE}>
            <Flex textAlign="start" direction="column" gap=".5rem">
              <ContentCardWithLinks
                {...item}
                sectionName={sectionTitle}
                sectionCardIndex={index}
              />
              {item?.footer ? (
                <Text fontSize="xs" color="lightGrey.200" lineHeight="1.5">
                  {item.footer}
                </Text>
              ) : null}
            </Flex>
          </SwiperSlide>
        ))}
        {fetchMore ? (
          <SwiperSlide style={SLIDE_STYLE}>
            <Button
              variant="outline"
              onClick={() => fetchMore()}
              {...CONTENT_CARD_DIMENSIONS}
              borderRadius="md"
              rightIcon={
                <Icon as={IconChevronLeft} transform="auto" rotate="180deg" />
              }
            >
              Load More
            </Button>
          </SwiperSlide>
        ) : null}
      </>
    </Swiper>
  );
}
