export function createBlobURL(image: File) {
  const blob = new Blob([image]);
  return URL.createObjectURL(blob);
}

/**
 * Transform the given `width` and `height` proportionally
 * based on the given `objectiveWidth` or `objectiveHeight`.
 *
 * @example ```jsx
 * const { width, height } = transformDimensions({
 *  width: 1000,
 *  height: 2000,
 *  objectiveHeight: 100
 * })
 *
 * // width === 50
 * // height === 100
 * ```
 */
export function transformDimensions({
  width,
  height,
  objectiveWidth,
  objectiveHeight
}: {
  width: number;
  height: number;
  objectiveWidth?: number;
  objectiveHeight?: number;
}): { width: number; height: number } {
  if (objectiveWidth && objectiveHeight) {
    return { width: objectiveWidth, height: objectiveHeight };
  }

  let a = 0;
  let b = 0;
  let c = 0;
  let finalWidth = 0;
  let finalHeight = 0;

  if (objectiveWidth) {
    a = objectiveWidth;
    b = height;
    c = width;

    finalWidth = objectiveWidth;
    finalHeight = (a * b) / c;
  } else if (objectiveHeight) {
    a = objectiveHeight;
    b = width;
    c = height;

    finalWidth = (a * b) / c;
    finalHeight = objectiveHeight;
  }

  return { width: Math.ceil(finalWidth), height: Math.ceil(finalHeight) };
}
