import { HStack, Icon, type IconProps, Text } from '@chakra-ui/react';
import {
  getTimeLeftForEventToStart,
  isDateAfter,
  isDateBetween
} from '@tigerhall/core';
import { type Event } from '@tigerhall/core/lib/types';
import { IconCircleMinus, IconClock } from '@tigerhall/icons';
import { useEffect, useState } from 'react';

import { IconPieChartProgress } from '~/icon';

/**
 * Renders the time left until an event starts, or if it has
 * already started or ended, in the top left corner of the
 * content box.
 *
 * This is not used for other content types (e.g. podcasts) because there
 * we don't want to show the time left but rather the
 * completion indicator.
 */

type Props = Pick<Event, 'startsAt' | 'endsAt'> & {
  iconProps?: IconProps;
};

export function ContentBoxEventTimer({ startsAt, endsAt, iconProps }: Props) {
  const [timeLeft, setTimeLeft] = useState<string | null>(null);

  const didEnd = isDateAfter({ date: new Date(), endDate: endsAt });
  const isOngoing = isDateBetween({
    date: new Date(),
    startDate: startsAt,
    endDate: endsAt
  });
  const hasNotStartedYet = !(isOngoing || didEnd);

  useEffect(() => {
    const timer = setInterval(
      () => setTimeLeft(getTimeLeftForEventToStart({ startsAt: startsAt })),
      1000
    );

    return () => clearInterval(timer);
  }, [startsAt]);

  return (
    <>
      {hasNotStartedYet && timeLeft ? (
        <HStack
          position="absolute"
          top="0"
          left="0"
          bg="darkGrey.400"
          py="0.5rem"
          px="0.5rem"
          borderRadius="0 0 0.3rem"
          spacing="0.3rem"
          justifyContent="center"
        >
          <Icon as={IconClock} {...iconProps} color="white" />

          <Text
            as="span"
            fontSize={{
              base: '2xs',
              lg: 'xs'
            }}
            fontWeight="bold"
            whiteSpace="nowrap"
            color="lightGrey.200"
          >
            {timeLeft}
          </Text>
        </HStack>
      ) : null}
      {isOngoing ? (
        <HStack
          position="absolute"
          top="0"
          left="0"
          bg="tigerOrange.50"
          py="0.5rem"
          px="0.5rem"
          borderRadius="0 0 0.3rem"
          spacing="0.3rem"
          justifyContent="center"
        >
          <IconPieChartProgress {...iconProps} color="tigerOrange.400" />
          <Text
            as="span"
            fontSize={{
              base: '2xs',
              lg: 'xs'
            }}
            fontWeight="bold"
            whiteSpace="nowrap"
            color="darkGrey.400"
          >
            Started
          </Text>
        </HStack>
      ) : null}
      {didEnd ? (
        <HStack
          position="absolute"
          top="0"
          left="0"
          bg="darkGrey.300"
          py="0.5rem"
          px="0.5rem"
          borderRadius="0 0 0.3rem"
          spacing="0.3rem"
          justifyContent="center"
        >
          <Icon as={IconCircleMinus} {...iconProps} color="white" />
          <Text
            as="span"
            fontSize={{
              base: '2xs',
              lg: 'xs'
            }}
            fontWeight="bold"
            whiteSpace="nowrap"
            color="lightGrey.200"
          >
            Experience Ended
          </Text>
        </HStack>
      ) : null}
    </>
  );
}
