import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist';

import { rootReducer } from './reducer';
import type { RootState } from './reducer';
import { listenerMiddleware } from './listeners';

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  const persistConfig = {
    key: 'root',
    version: 2,
    storage,
    whitelist: ['user', 'sidebar', 'assignments', 'popup', 'freeTrial']
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }).prepend(listenerMiddleware.middleware)
  });
}

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

// export a default store that the application uses at run time, the setupStore is used for testing allowing us to have a preloaded state
export const store = setupStore();
