import { captureException } from '@sentry/react';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Content,
  ContentVote,
  Flags,
  Stream,
  StreamSource,
  StreamStatus,
  UserContentTracking,
  LearningPath as OriginalLearningPath,
  isLearningPath
} from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import { useContentMeter, useFreeAccount } from 'hooks';
import { useFlag } from '@unleash/proxy-client-react';
import { MODALTYPES } from 'components/ui/modals';

import {
  useRateContentMutation,
  useToggleLearningPathBookmarkMutation,
  useUserTrackContentProgressMutation,
  useToggleContentBookmarkMutation,
  useUserTrackContentToggleFinishedMutation
} from '~/generated';
import { useEbookAction } from './useEbookAction';
import { useEventAction } from './useEventAction';
import { useLiveVideoActions } from './useLiveVideoActions';
import { usePodcastAction } from './usePodcastAction';
import { useVideoAction } from './useVideoAction';
import useCommonModal from './useCommonModal';
import { PlayingContent } from '~/app/state';

type ExpectedContent = Pick<
  Content,
  '__typename' | 'id' | 'name' | 'upvoteCount' | 'contentRatingByUser'
> & {
  userContentTracking: Pick<
    UserContentTracking,
    | 'id'
    | 'bookmarkedAt'
    | 'current'
    | 'currentPlacement'
    | 'total'
    | 'isAttending'
    | 'isFinished'
  >;
};

type LearningPath = Pick<OriginalLearningPath, '__typename' | 'id' | 'name'> & {
  userCollectionTracking?: Pick<
    NonNullable<OriginalLearningPath['userCollectionTracking']>,
    'id' | 'bookmarkedAt'
  > | null;
};

interface TrackProgressArgs {
  content: Pick<ExpectedContent, 'id' | '__typename'>;
  progress: number;
  playlist?: Pick<PlayingContent, 'playlistType' | 'playlistId'>;
}

/**
 * Hook to handle all the actions that can be performed on a content.
 *
 * These actions include:
 * - Playing the content
 * - Rating the content
 * - Bookmarking the content
 * - Tracking the progress of the content
 * - Tracking the finished state of the content
 *
 * @returns An object containing all the actions
 */
export function useContentActions() {
  const { playVideo } = useVideoAction();
  const { playLiveVideo } = useLiveVideoActions();
  const { playPodcast } = usePodcastAction();
  const { startEbook } = useEbookAction();
  const { bookEvent } = useEventAction();
  const { isInPreviewMode } = useFreeAccount();
  const { isMeterExpired } = useContentMeter();
  const location = useLocation();
  const { openModal } = useCommonModal();
  const isFreeTrialEnabled = useFlag(Flags.FreeTrial);
  const [toggleContentBookmarkMutation] = useToggleContentBookmarkMutation();
  const [toggleTrailBookmarkMutation] = useToggleLearningPathBookmarkMutation();
  const [trackProgressMutation] = useUserTrackContentProgressMutation({});
  const [trackFinishedMutation] = useUserTrackContentToggleFinishedMutation();
  const [rateContentMutation] = useRateContentMutation({});

  function isStreamRecording(content: Pick<Stream, 'status' | 'source'>) {
    // Regardless of the type of video if it's a recording then we show the video player
    if (content.status === StreamStatus.Recording) {
      return true;
    }

    return content.source === StreamSource.Upload;
  }

  function playMedia(content: Pick<ExpectedContent, 'id' | '__typename'>) {
    if (isInPreviewMode && isMeterExpired && !isFreeTrialEnabled) {
      return openModal(MODALTYPES.Payment);
    }

    switch (content?.__typename) {
      case 'Ebook':
        startEbook({ id: content.id });
        break;

      case 'Podcast':
        playPodcast({ id: content.id });
        break;

      case 'Stream': {
        // if anyone know how to extend the ExpectedContent with these properties I would love to do that instead
        const stream = content as unknown as Pick<
          Stream,
          'id' | 'status' | 'source'
        >;

        if (isStreamRecording(stream)) {
          playVideo({ id: content.id });
        } else {
          playLiveVideo({
            id: stream.id,
            type: stream.source as
              | StreamSource.BroadcastCloudflareLive
              | StreamSource.BroadcastAwsIvs
              | StreamSource.LivestreamAgora
          });
        }
        break;
      }

      case 'Event':
        bookEvent({ id: content.id }).catch((e) => {
          captureException(e, {
            tags: {
              contentId: content.id,
              contentType: content.__typename
            }
          });
        });
        break;

      default:
        throw new Error('Invalid content type');
    }
  }

  const toggleBookmark = useCallback(
    async (content: ExpectedContent | LearningPath, section?: string) => {
      const isBookmarked = isLearningPath(content)
        ? content.userCollectionTracking?.bookmarkedAt
        : content.userContentTracking.bookmarkedAt;

      segment.bookmarkClicked({
        contentId: content.id,
        contentName: content.name,
        contentType: content.__typename,
        location: location.pathname,
        section,
        state: isBookmarked ? 'unselected' : 'selected'
      });

      try {
        if (isLearningPath(content)) {
          await toggleTrailBookmarkMutation({
            variables: { id: content.id },
            optimisticResponse: {
              userLearningPathToggleBookmarkedV2: {
                __typename: 'LearningPath',
                id: content.id,
                userCollectionTracking: {
                  __typename: 'UserCollectionTracking',
                  id: content.userCollectionTracking?.id || '',
                  bookmarkedAt: isBookmarked ? null : new Date().toISOString()
                }
              }
            }
          });
        } else {
          await toggleContentBookmarkMutation({
            variables: { id: content.id },
            optimisticResponse: {
              userTrackContentToggleBookmarked: {
                __typename: content.__typename,
                id: content.id,
                userContentTracking: {
                  ...content.userContentTracking,
                  __typename: 'UserContentTracking',
                  bookmarkedAt: isBookmarked ? null : new Date().toISOString()
                }
              }
            }
          });
        }
      } catch (e) {
        captureException(e, {
          tags: {
            contentId: content.id,
            contentType: content.__typename
          }
        });
      }
    },
    [
      location.pathname,
      toggleTrailBookmarkMutation,
      toggleContentBookmarkMutation
    ]
  );

  const rate = useCallback(
    async (content: ExpectedContent, reaction: ContentVote) => {
      let state: segment.LikeClicked['state'];
      let upvoteCount = content.upvoteCount;

      // start by resetting the vote if they already voted
      if (content.contentRatingByUser === ContentVote.Upvote) {
        upvoteCount -= 1;
      }

      //  we only want to increment one of the counters if they selected a different option
      if (reaction === content.contentRatingByUser) {
        state = 'undetermined';
      } else {
        if (reaction === ContentVote.Upvote) {
          upvoteCount += 1;
          state = 'liked';
        }

        if (reaction === ContentVote.Downvote) {
          state = 'unliked';
        }
      }

      segment.likeClicked({
        contentId: content.id,
        contentName: content?.name,
        contentType: content.__typename,
        state,

        // todo: should this really be tracked on event level?
        isThinkfluencer: false
      });

      try {
        await rateContentMutation({
          variables: {
            contentId: content.id,

            // The API expects to know what the user wants to do, not what they did
            // so if the reset presses on something they have already voted we want to reset it
            // for this reason we need to change the reaction to undetermined
            vote:
              reaction === content.contentRatingByUser
                ? ContentVote.Undetermined
                : reaction
          },
          optimisticResponse: {
            rateContent: {
              __typename: 'ContentRating',

              // this values does not matter since we are updating the cache of the content already
              id: 'random',

              content: {
                id: content?.id,
                __typename: content.__typename,

                upvoteCount,
                contentRatingByUser: reaction
              }
            }
          }
        });
      } catch (e) {
        captureException(e, {
          tags: {
            contentId: content.id,
            contentType: content.__typename
          },
          extra: { reaction }
        });
      }
    },
    [rateContentMutation]
  );

  const trackProgress = useCallback(
    async ({ content, progress, playlist }: TrackProgressArgs) => {
      const assignmentId =
        playlist?.playlistType === 'Assignment' ? playlist.playlistId : null;

      const learningPathId =
        playlist?.playlistType === 'Trail' ? playlist.playlistId : null;

      try {
        const data =
          content.__typename === 'Ebook'
            ? { current: progress, currentPlacement: 0 }
            : {
                // needs to be 0 because it's the default episode index for podcasts,
                // and it will be ignored for videos
                current: 0,
                currentPlacement: Number.parseInt(progress.toString(10))
              };

        await trackProgressMutation({
          variables: {
            id: content.id,
            data,
            meta: {
              assignmentId,
              learningPathId
            }
          }
        });
      } catch (e) {
        captureException(e, {
          tags: {
            contentId: content.id,
            contentType: content.__typename
          },
          extra: { progress, assignmentId, learningPathId }
        });
      }
    },
    [trackProgressMutation]
  );

  const trackFinished = useCallback(
    async (content: Pick<ExpectedContent, 'id' | '__typename'>) => {
      // don't want to hit finish mutation when user is in preview mode
      if (isInPreviewMode) {
        return;
      }

      try {
        await trackFinishedMutation({
          variables: {
            id: content.id
          }
        });
      } catch (e) {
        captureException(e, {
          tags: {
            contentId: content.id,
            contentType: content.__typename
          }
        });
      }
    },
    [trackFinishedMutation, isInPreviewMode]
  );

  return {
    playMedia,
    rate,
    toggleBookmark,

    trackProgress,
    trackFinished
  };
}
