import { RouteObject } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import { Splash } from '~/components/ui/Layout/components';

const Overview = lazy(() =>
  import('./screens/overview/Overview').then((module) => ({
    default: module.Overview
  }))
);

const Group = lazy(() =>
  import('./screens/detail/Detail').then((module) => ({
    default: module.Detail
  }))
);

const routes: RouteObject[] = [
  {
    path: 'groups',
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Splash />}>
            <Overview />
          </Suspense>
        )
      },
      {
        path: ':groupId',
        element: (
          <Suspense fallback={<Splash />}>
            <Group />
          </Suspense>
        )
      }
    ]
  }
];

export default routes;
