export interface MediaState {
  /**
   * Whether the media is currently playing.
   */
  isPlaying: boolean;

  /**
   * Weather the media is currently loading.
   *
   * todo: this one is not yet supported officially
   */
  isLoading: boolean;

  /**
   * The current progress of the media in seconds.
   */
  progress: number;

  /**
   * The duration of the media in seconds.
   */
  duration: number;

  /**
   * A custom duration that can be set to override the media duration. This is useful for live streams.
   * Value is in seconds.
   */
  customDuration?: number;

  /**
   * The playback rate of the media.
   */
  playbackRate: number;

  /**
   * The volume level on a scale of 0 to 1.
   */
  volume: number;

  /**
   * If the user just jumped to a new position in the media.
   */
  didJustSeek: boolean;
}

export const THRESHOLD_FOR_MAKING_MEDIA_AS_COMPLETED = 0.9;
