import { ButtonGroup } from '@chakra-ui/react';
import { Children, type ReactNode } from 'react';

interface OptionsGroupProps {
  children: ReactNode;
  isMiniVideoPlayer?: boolean;
}

/**
 * A group of options that are displayed at the bottom of the player controls.
 * If there is only one option, it will be right-aligned for UX reasons.
 */
export function OptionsGroup({
  children,
  isMiniVideoPlayer
}: OptionsGroupProps) {
  const childrenArray = Children.toArray(children);
  const isAlone = childrenArray.length === 1;

  return (
    <ButtonGroup
      color={'darkGrey.50'}
      display={'flex'}
      justifyContent={{
        base: isAlone ? 'flex-end' : 'space-between',
        sm: isMiniVideoPlayer ? 'space-between' : 'center'
      }}
      gap={{
        base: '1.5rem',
        sm: '2.5rem'
      }}
      p={isMiniVideoPlayer ? { base: '.25rem', sm: '.5rem' } : '0'}
    >
      {children}
    </ButtonGroup>
  );
}
