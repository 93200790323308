import { type Content } from '@tigerhall/core';
import { useGetContentCardQuery } from 'modules/application/modules/Home/queries';
import { TigerhallSkeleton } from '@tigerhall/ui-components';
import { Flex, Text } from '@chakra-ui/react';
import { AboutSection } from 'modules/application/modules/Profile/components/AboutSection';

import { MoreInfoHead } from './MoreInfoHead';
import { LikeUnlikeButton } from './LikeUnlikeButton';
import { SegmentsSection } from './SegmentsSection';
import { Authors } from './Authors';

export interface MoreInfoTabProps extends Pick<NonNullable<Content>, 'id'> {}

export function MoreInfoTab({ id }: Readonly<MoreInfoTabProps>) {
  const { data, loading } = useGetContentCardQuery({
    variables: { id },
    fetchPolicy: 'cache-first'
  });

  if (loading) {
    return (
      <TigerhallSkeleton
        variant="content_detail"
        dataTestId="loading-skeleton"
      />
    );
  }

  const content = data?.contentCard as Content;

  return (
    <Flex gap={'4'} direction={'column'} pb={'6'}>
      <MoreInfoHead content={content} />
      <LikeUnlikeButton content={content} />
      <Text color={'lightGrey.600'} fontSize={'sm'} lineHeight={'1.5'} px={'6'}>
        <AboutSection aboutContent={content?.preamble} hasAbout={false} />
      </Text>
      <SegmentsSection contentSegments={content.contentSegments} />
      <Authors
        experts={content.experts}
        hostedBy={content.__typename === 'Podcast' ? content?.hostedBy : null}
      />
    </Flex>
  );
}
