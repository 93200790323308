import React from 'react';
import { Flex } from '@chakra-ui/react';

export type Specs = {
  width: number;
  height: number;
  x: number;
  y: number;
};

type PanChildren = (params: {
  onMouseUp: (any) => any;
  onMouseLeave: (any) => any;
  onMouseDown: (any) => any;
  onMouseMove: (any) => any;
}) => React.ReactNode;

const PanTool: React.FC<{
  specs: Specs;
  onUpdatePan: (specs: Specs) => void;
  zoom: number;
  children: PanChildren;
}> = ({ children, specs, onUpdatePan, zoom }) => {
  const [isMoving, setIsMoving] = React.useState(false);
  const startPan = React.useCallback(() => setIsMoving(true), [setIsMoving]);
  const stopPan = React.useCallback(() => setIsMoving(false), [setIsMoving]);
  const updatePan = React.useCallback(
    (event: React.MouseEvent) => {
      if (!isMoving || !specs) {
        return;
      }

      onUpdatePan({
        ...specs,
        x: Math.min(specs.x + event.movementX / zoom),
        y: specs.y + event.movementY / zoom
      });
    },
    [isMoving, onUpdatePan, specs, zoom]
  );

  const panProps = {
    onMouseUp: stopPan,
    onMouseLeave: stopPan,
    onMouseMove: updatePan,
    onMouseDown: startPan
  };
  return (
    <Flex
      flex={1}
      bg="teal"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Flex
        position="absolute"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        left={0}
        right={0}
        top={0}
        bottom={0}
      >
        {children(panProps)}
        <Flex
          borderColor="#000000AA"
          left={0}
          right={0}
          top={0}
          bottom={0}
          pointerEvents="none"
          borderTopWidth={16}
          borderBottomWidth={16}
          borderLeftWidth={178}
          borderRightWidth={178}
          position="absolute"
        />
      </Flex>
    </Flex>
  );
};

export default PanTool;
