import { Box, VStack } from '@chakra-ui/react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import * as segment from '@tigerhall/analytics';
import { Post } from 'modules/application/modules/Feed/screens/Feed/components/Post';
import { PostActivity } from 'modules/application/modules/Feed/screens/Feed/components/PostActivity';
import {
  ContentActivity,
  ContentActivitySkeleton
} from 'modules/application/modules/Feed/screens/Feed/components/ContentActivity';

import { useGetUserActivitiesFeedQuery } from '../../../query/generated/userActivities.generated';
import NoDataTab from './NoDataTab';

interface ActivityTabProps {
  userId: string;
}

const DEFAULT_LIMIT = 10;

export function ActivityTab({ userId }: ActivityTabProps) {
  const { data, loading, error, fetchMore } = useGetUserActivitiesFeedQuery({
    variables: {
      userId,
      pagination: {
        limit: DEFAULT_LIMIT,
        afterCursor: undefined
      }
    },
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only'
  });

  const { edges: feedItems = [], meta } = data?.user?.userActivities ?? {};
  const isFeedAvailable = feedItems.length > 0;
  const hasNextPage = !loading && !!meta?.hasNext;

  const [targetRef] = useInfiniteScroll({
    onLoadMore: () => {
      segment.fetchMore({
        componentName: 'USER_FEED',
        fetchedItemCount: feedItems.length
      });

      fetchMore({
        variables: {
          userId,
          pagination: {
            limit: DEFAULT_LIMIT,
            afterCursor: meta?.nextCursor
          }
        }
      });
    },
    hasNextPage,
    loading,
    disabled: !!error,
    rootMargin: '0px 0px 500px 0px'
  });

  if (error) {
    return <NoDataTab title="activity" />;
  }

  return (
    <VStack spacing="1rem">
      {loading && !isFeedAvailable ? (
        <>
          <ContentActivitySkeleton hasComments />
          <ContentActivitySkeleton hasComments />
          <ContentActivitySkeleton hasComments />
        </>
      ) : null}
      {isFeedAvailable
        ? feedItems.map((feedItem) => {
            const { feed } = feedItem;
            const { __typename: type } = feed;

            switch (type) {
              case 'ContentActivity':
                return <ContentActivity key={feed.id} activity={feed} />;
              case 'Post':
                return <Post key={feed.id} post={feed} />;
              case 'PostActivity':
                return <PostActivity key={feed.id} activity={feed} />;
              default:
                return null;
            }
          })
        : null}
      {loading || hasNextPage ? (
        <Box width="100%" ref={targetRef}>
          <ContentActivitySkeleton hasComments />
        </Box>
      ) : null}
    </VStack>
  );
}
