import { Button, Flex, Text, useToast } from '@chakra-ui/react';
import { FormInput, TrackedForm } from '@tigerhall/components';
import { DarkPanel } from 'components/ui';
import { useUserChangePasswordMutation } from 'generated';
import * as React from 'react';

type FormValues = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export default function ChangePassword({ user }) {
  const toast = useToast();

  const [changePasswordMutation] = useUserChangePasswordMutation();

  const onSubmit = async (values: FormValues, { setError }) => {
    if (values.newPassword !== values.confirmPassword) {
      return setError('confirmPassword', {
        type: 'manual',
        message: 'Passwords do not match'
      });
    }

    try {
      await changePasswordMutation({
        variables: {
          id: user?.id,
          oldPassword: values?.oldPassword,
          newPassword: values?.newPassword
        }
      });

      toast({
        title: 'Success',
        description: 'Password updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
    } catch (e) {
      setError('oldPassword', {
        type: 'manual',
        message: 'Something went wrong'
      });
    }
  };

  return (
    <DarkPanel>
      <Text fontSize="1.25rem" fontWeight="700" color="white">
        Change Password
      </Text>
      <TrackedForm name="CHANGE_PASSWORD" onSubmit={onSubmit}>
        {({ formState: { isSubmitting, isValid } }) => (
          <Flex direction="column" gap="1rem" mt="1rem">
            <FormInput
              name="oldPassword"
              placeholder="Enter old password"
              label="Old Password"
              type="password"
              autoComplete="current-password"
              rules={{
                required: true
              }}
            />
            <FormInput
              name="newPassword"
              placeholder="Enter new password"
              label="New Password"
              type="password"
              autoComplete="new-password"
              rules={{
                required: true,
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                }
              }}
            />
            <FormInput
              name="confirmPassword"
              placeholder="Confirm password"
              label="Confirm Password"
              type="password"
              autoComplete="new-password"
              rules={{
                required: true
              }}
            />
            <Button
              type="submit"
              mt="1rem"
              alignSelf="flex-start"
              size="lg"
              isDisabled={!isValid}
              isLoading={isSubmitting}
            >
              Save Changes
            </Button>
          </Flex>
        )}
      </TrackedForm>
    </DarkPanel>
  );
}
