import { useNavigate } from 'react-router-dom';
import { IconMessageCircle } from '@tigerhall/icons';
import * as segment from '@tigerhall/analytics';
import { Icon } from '@chakra-ui/react';

import { ActionButton } from './ActionButton';

interface CommentOnContentProps {
  contentId: string;
}

export function CommentOnContent({ contentId }: CommentOnContentProps) {
  const navigate = useNavigate();
  const contentLink = `?content=${contentId}`;

  return (
    <ActionButton
      leftIcon={<Icon as={IconMessageCircle} />}
      onClick={() => {
        segment.commentButtonClicked({
          referenceId: contentId
        });
        navigate(contentLink);
      }}
    >
      Comment on Content
    </ActionButton>
  );
}
