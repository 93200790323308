import * as React from 'react';
import { OrganisationGroup, useBeforeUnload, User } from '@tigerhall/core';
import { getSelectedOrgId } from 'app/state';
import { BackButton } from 'components/ui/Buttons';
import { Heading } from '@chakra-ui/react';
import {
  useGetOrganisationGroupsWithFilterQuery,
  useGetOrganisationMembersQuery
} from 'generated';
import { useAppSelector } from 'hooks';
import { ActionModal } from 'components/ui/modals';
import { useNavigate } from 'react-router-dom';

import { CURRENT_PROGRESS } from '../../constants';
import { AssignmentConfirmation } from './components/AssignmentConfirmation';
import { AssignmentSuccess } from './components/AssignmentSuccess';
import { AssignmentContentSelection } from './components/AssignmentContentSelection';
import { AssignmentUsersSelection } from './components/AssignmentUsersSelection';
import { useAssignmentDraftContext } from '../../hooks/useAssignmentDraftContext';

const LEAVE_PROMPT_MESSAGE = 'You have unsaved changes, are you sure?';

function AssignContent({ currentProgress, setCurrentProgress }) {
  const { usersIds, groupsIds } = useAssignmentDraftContext();

  const [isEditing, setIsEditing] = React.useState(false);

  const organisationId = useAppSelector(getSelectedOrgId);
  const { data } = useGetOrganisationGroupsWithFilterQuery({
    skip: !organisationId,
    variables: {
      id: organisationId as string,
      filter: {
        limit: 1000, // fetch all groups at once
        offset: 0
      }
    }
  });

  const orgGroups = (data?.organisation.orgGroups.edges ||
    []) as OrganisationGroup[];

  const { data: dataMembers } = useGetOrganisationMembersQuery({
    skip: !organisationId,
    variables: {
      id: organisationId as string,
      filter: {
        limit: 10000, // todo: refactor this to fetch all users at once
        offset: 0
      }
    }
  });

  const memberEntities = (dataMembers?.organisation.members.edges ??
    []) as User[];

  const members = memberEntities?.filter((member) =>
    usersIds.includes(member.id)
  );

  const selectedOrgGroups = orgGroups?.filter((orgGroup) =>
    groupsIds.includes(orgGroup.id)
  );

  const allStepNames = [
    'Select Content',
    'Select Users & Groups',
    'Summary & Confirmation'
  ];

  switch (currentProgress) {
    case CURRENT_PROGRESS.CONTENT_SELECTION:
      return (
        <AssignmentContentSelection
          stepNames={allStepNames}
          forward={() => {
            setCurrentProgress(CURRENT_PROGRESS.USERS_SELECTION);
            setIsEditing(false);
          }}
          isEditing={isEditing}
          saveChanges={() => {
            setCurrentProgress(CURRENT_PROGRESS.CONFIRMATION);
            setIsEditing(false);
          }}
        />
      );

    case CURRENT_PROGRESS.USERS_SELECTION:
      return (
        <AssignmentUsersSelection
          stepNames={allStepNames}
          goBack={() => {
            setCurrentProgress(CURRENT_PROGRESS.CONTENT_SELECTION);
            setIsEditing(false);
          }}
          forward={() => {
            setCurrentProgress(CURRENT_PROGRESS.CONFIRMATION);
            setIsEditing(false);
          }}
          isEditing={isEditing}
        />
      );
    case CURRENT_PROGRESS.CONFIRMATION:
      return (
        <AssignmentConfirmation
          stepNames={allStepNames}
          goBack={() => {
            setCurrentProgress(CURRENT_PROGRESS.USERS_SELECTION);
            setIsEditing(false);
          }}
          members={members}
          setCurrentProgress={(progress, isEdit = false) => {
            setCurrentProgress(progress);
            setIsEditing(isEdit);
          }}
          selectedOrgGroups={selectedOrgGroups}
        />
      );

    case CURRENT_PROGRESS.DONE:
      return (
        <AssignmentSuccess
          assignAgain={() => {
            setCurrentProgress(CURRENT_PROGRESS.CONTENT_SELECTION);
            setIsEditing(false);
          }}
          stepNames={allStepNames}
        />
      );

    default:
      return null;
  }
}

const withUnloadEnsure = (Component) => (props) => {
  const { draft, clearDraft } = useAssignmentDraftContext();

  const [isActionModalOpen, setIsActionModalOpen] = React.useState(false);

  const [currentProgress, setCurrentProgress] = React.useState<string>(
    CURRENT_PROGRESS.CONTENT_SELECTION
  );

  const orgId = useAppSelector(getSelectedOrgId);
  const shouldWarnUser = draft.contents && draft.contents.length > 0;

  useBeforeUnload(shouldWarnUser, LEAVE_PROMPT_MESSAGE);

  const navigate = useNavigate();

  if (!orgId) {
    return null;
  }

  return (
    <>
      <BackButton mb="1.5rem" to="" onClick={() => setIsActionModalOpen(true)}>
        Back to Assignments
      </BackButton>

      <Heading fontSize="2xl" fontWeight="bold" pb="1.5rem">
        New Assignment
      </Heading>
      <Component
        key={orgId}
        {...props}
        currentProgress={currentProgress}
        setCurrentProgress={setCurrentProgress}
        draft={draft}
      />
      <ActionModal
        isOpen={isActionModalOpen}
        onClose={() => {
          setIsActionModalOpen(false);
        }}
        title="You have unsaved changes"
        type="warning"
        description={
          'You did not save the modifications you made to the assignment. Do you want to continue editing?'
        }
        primaryButtonText="Continue"
        primaryButtonOnClick={() => {
          setIsActionModalOpen(false);
        }}
        secondaryButtonText="Discard & Exit"
        secondaryButtonOnClick={() => {
          clearDraft();
          setIsActionModalOpen(false);
          navigate(-1);
        }}
      />
    </>
  );
};

export default withUnloadEnsure(AssignContent);
