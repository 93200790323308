import { menuAnatomy as t } from "./index32.mjs";
import { cssVar as a, createMultiStyleConfigHelpers as i, defineStyle as r } from "./index33.mjs";
const { defineMultiStyleConfig: n, definePartsStyle: s } = i(t.keys), e = a("menu-bg"), o = a("menu-shadow"), l = r({
  [e.variable]: "#fff",
  [o.variable]: "shadows.sm",
  _dark: {
    [e.variable]: "colors.darkGrey.400",
    [o.variable]: "shadows.dark-lg",
    color: "white"
  },
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",
  p: "0.5rem",
  color: "inherit",
  minW: "3xs",
  zIndex: 1,
  borderRadius: "lg",
  border: "none",
  bg: e.reference,
  boxShadow: o.reference
}), d = r({
  p: "0.5rem",
  transitionProperty: "background",
  transitionDuration: "ultra-fast",
  transitionTimingFunction: "ease-in",
  borderRadius: "base",
  fontSize: "sm",
  fontWeight: "medium",
  iconSpacing: "0.5rem",
  _hover: {
    [e.variable]: "colors.lightGrey.200",
    _dark: {
      [e.variable]: "colors.darkGrey.300"
    }
  },
  _focus: {
    [e.variable]: "colors.lightGrey.200",
    _dark: {
      [e.variable]: "colors.darkGrey.300"
    }
  },
  _active: {
    [e.variable]: "colors.lightGrey.200",
    _dark: {
      [e.variable]: "colors.darkGrey.400"
    }
  },
  _expanded: {
    [e.variable]: "colors.lightGrey.200",
    _dark: {
      [e.variable]: "colors.darkGrey.300"
    }
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed"
  },
  bg: e.reference
}), c = r({
  mx: 4,
  my: 2,
  fontWeight: "semibold",
  fontSize: "sm"
}), b = r({
  opacity: 0.6
}), m = r({
  border: 0,
  borderBottom: "1px solid",
  borderColor: "inherit",
  my: "2",
  opacity: 0.6
}), y = r({
  transitionProperty: "common",
  transitionDuration: "normal"
}), f = s({
  button: y,
  list: l,
  item: d,
  groupTitle: c,
  command: b,
  divider: m
}), p = n({
  baseStyle: f
});
export {
  p as Menu
};
