import { Box, HStack } from '@chakra-ui/react';
import { DummyCopies } from 'components/ui';
import { TigerhallSkeleton } from '@tigerhall/ui-components';

export function Skeleton() {
  return (
    <HStack spacing="1rem" p="2rem" pt="0.5rem">
      <DummyCopies count={10}>
        <Box w={164} h={230}>
          <TigerhallSkeleton variant="stream_story" />
        </Box>
      </DummyCopies>
    </HStack>
  );
}
