import * as React from 'react';
import { HeaderGroup } from 'react-table';
import { Flex, Text, HStack } from '@chakra-ui/react';

import { TableCell } from '../styles';

export const TableFooter = <D extends object = {}>({
  footerGroups
}: {
  footerGroups: HeaderGroup<D>[];
}) => (
  <Flex flexDirection="column" width="100%" userSelect="none">
    {footerGroups.map((footerGroup) => (
      <Flex
        {...footerGroup.getFooterGroupProps()}
        flex={1}
        flexDirection="row"
        background="teal.900"
        px={2}
      >
        {footerGroup.headers.map((column) => {
          const footerProps = column.getFooterProps();
          return (
            <HStack {...footerProps}>
              <TableCell>
                <Text
                  as="span"
                  fontSize="sm"
                  fontWeight="bold"
                  color="tigerOrange.500"
                >
                  {column.render('Footer')}
                </Text>
              </TableCell>
            </HStack>
          );
        })}
      </Flex>
    ))}
  </Flex>
);
