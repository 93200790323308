import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from './image.generated';
export type CategoryFragment = { __typename?: 'Category', id: string, name: string, image?: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string } | null };

export const CategoryFragmentDoc = gql`
    fragment Category on Category {
  id
  name
  image {
    ...Image
  }
}
    ${ImageFragmentDoc}`;