import { Text } from '@chakra-ui/react';
import { DarkPanel } from 'components/ui';
import * as React from 'react';

export default function OrgInfoCard({ organisation }) {
  return (
    <DarkPanel gap="1.5">
      <Text fontSize="1.25rem" fontWeight="700" color="white">
        Organization
      </Text>
      <Text fontSize="1rem" fontWeight="500" color="darkGrey.50">
        {organisation?.name}
      </Text>
    </DarkPanel>
  );
}
