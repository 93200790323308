import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgCaretDownFilled = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="th-icon"
    viewBox="0 0 24 24"
    focusable={false}
    color="white"
    width="1.25rem"
    height="1.25rem"
    ref={ref}
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path
      fill="currentColor"
      stroke="none"
      d="M18 9c.852 0 1.297.986.783 1.623l-.076.084-6 6a1 1 0 0 1-1.32.083l-.094-.083-6-6-.083-.094-.054-.077-.054-.096-.017-.036-.027-.067-.032-.108-.01-.053-.01-.06-.004-.057v-.118l.005-.058.009-.06.01-.052.032-.108.027-.067.07-.132.065-.09.073-.081.094-.083.077-.054.096-.054.036-.017.067-.027.108-.032.053-.01.06-.01.057-.004L18 9z"
    />
  </svg>
);
SvgCaretDownFilled.displayName = "IconCaretDownFilled";
const ForwardRef = forwardRef(SvgCaretDownFilled);
export default ForwardRef;
