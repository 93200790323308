import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text
} from '@chakra-ui/react';
import { Controller, UseFormReturn, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';

import { MentionInput } from '../components/MentionInput';
import { MentionFormInputProps } from '../utils/types';

export function MentionFormInput({
  name,
  rules,
  disabled = false,
  label,
  placeholder,
  referenceId,
  fetchSuggestions,
  focusOnMount = false,
  ...rest
}: MentionFormInputProps) {
  const methods = useFormContext() as UseFormReturn & {
    formName: string;
  };

  useEffect(() => {
    if (focusOnMount) {
      methods?.setFocus(name);
    }
  }, [focusOnMount, methods, methods?.setFocus, name]);

  if (methods === null) {
    return <Text color="state.error">Missing form context</Text>;
  }

  if (!methods.formName) {
    return <Text color="state.error">Missing form name</Text>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      render={({ field, fieldState }) => (
        <FormControl
          isInvalid={!!fieldState.error}
          isRequired={!!rules?.required}
          isDisabled={disabled}
        >
          <FormLabel>{label}</FormLabel>
          <MentionInput
            name={field.name}
            value={field?.value?.rawText || ''}
            placeholder={placeholder}
            fetchSuggestions={fetchSuggestions}
            onChange={(value) => {
              field.onChange(value);
            }}
            inputRef={field.ref}
            referenceId={referenceId}
            disabled={disabled}
            {...rest}
          />
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}
