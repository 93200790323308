import * as React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import type { User } from './types';
import { UserBubble, UserInfo } from './components';

export interface UserStoryProps {
  user: User;
  disableHover?: boolean;
}

export function UserStory({ user, disableHover = false }: UserStoryProps) {
  return (
    <Flex
      as="article"
      w={{
        base: '5.5rem',
        md: '10.25rem'
      }}
      h={{
        base: '8.5rem',
        md: '14.375rem'
      }}
      p={{
        md: '0.5rem'
      }}
      gap="0.5rem"
      direction="column"
      transition="all 100ms linear"
      _hover={
        !disableHover
          ? {
              borderRadius: '0.5rem',
              backgroundColor: 'darkGrey.400'
            }
          : undefined
      }
    >
      <Box
        position="relative"
        alignSelf="center"
        w={{
          base: '5.5rem',
          md: '9.25rem'
        }}
        h={{
          base: '5.5rem',
          md: '9.25rem'
        }}
      >
        <UserBubble image={user.image.uri} isHighlighted={false} />
      </Box>

      <UserInfo user={user} />
    </Flex>
  );
}
