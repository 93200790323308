import { LinkProps } from '@tigerhall/components';
import { Button, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export function LinkWrapper({ href, replace, children, ...props }: LinkProps) {
  return href.startsWith('#') || props.isExternal ? (
    <>
      {props.as === 'button' ? (
        <Button {...props} as={ChakraLink} href={href}>
          {children}
        </Button>
      ) : (
        <ChakraLink {...props} href={href}>
          {children}
        </ChakraLink>
      )}
    </>
  ) : (
    <>
      {props.as === 'button' ? (
        <Button {...props} as={Link} to={href} replace={replace}>
          {children}
        </Button>
      ) : (
        <ChakraLink {...props} as={Link} to={href} replace={replace}>
          {children}
        </ChakraLink>
      )}
    </>
  );
}
