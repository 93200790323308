import { PostMetricsSkeleton } from '../../../PostMetrics';
import { FeedItemActionsSkeleton } from '../../../FeedItemActions';
import { ActivityCommentSkeleton } from '../../../ActivityComment';
import { PostHeaderSkeleton } from '../../../PostHeader';
import { PostTextSkeleton } from '../../../PostText';

export interface PostBodySkeletonProps {
  hasComments?: boolean;
}

export function PostBodySkeleton({
  hasComments = false
}: PostBodySkeletonProps) {
  return (
    <>
      <PostHeaderSkeleton />
      <PostTextSkeleton />
      <PostMetricsSkeleton />
      <FeedItemActionsSkeleton numberOfButtons={3} />
      {hasComments && <ActivityCommentSkeleton />}
    </>
  );
}
