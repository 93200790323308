import * as React from 'react';
import { Heading, Text, VStack } from '@chakra-ui/react';

type HeaderDetailsProps = {
  heading: string;
  description: string;
};

export function HeaderDetails({ heading, description }: HeaderDetailsProps) {
  return (
    <VStack mb="2.5rem" spacing="1rem">
      <Heading as="h2" fontSize="1.75rem" color="white" textAlign="center">
        {heading}
      </Heading>
      <Text fontSize="lg" color="lightGrey.600" textAlign="center">
        {description}
      </Text>
    </VStack>
  );
}
