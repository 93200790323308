import { BoxProps, Heading } from '@chakra-ui/react';
import { DarkPanel } from 'components/ui/containers';
import React from 'react';

interface Props extends BoxProps {
  title: string;
  children: JSX.Element;
  loading?: boolean;
}

export function InnerBox({ title = '', children, loading, ...rest }: Props) {
  return (
    <DarkPanel {...rest}>
      <Heading fontSize="1.25rem" fontWeight="700" color="white">
        {title}
      </Heading>
      {children}
    </DarkPanel>
  );
}
