import { useState, useCallback } from 'react';
import { NetworkStatus } from '@apollo/client';
import { SimpleGrid, Skeleton } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import map from 'lodash/map';
import {
  AssignmentContent,
  ContentCardsFilter,
  ContentCardsSorting
} from '@tigerhall/core/lib/types';
import * as segment from '@tigerhall/analytics';
import {
  CONTENT_CARD_DIMENSIONS,
  ContentCardWithOverlay,
  TrackedButton
} from '@tigerhall/components';
import { DummyCopies, NoSearchResults } from 'components/ui';
import { useGetContentCardsQuery } from 'generated';

import { ContentCardSummary } from './ContentCardSummary';

export type InfiniteSelectableContentCardGridProps = {
  filter: ContentCardsFilter;
  contentCards: AssignmentContent[];
  setContentCards: (contentCards: AssignmentContent[]) => void;
};

const DEFAULT_FILTER_LIMIT = 10;

function Loader() {
  return (
    <DummyCopies count={DEFAULT_FILTER_LIMIT}>
      <Skeleton {...CONTENT_CARD_DIMENSIONS} rounded="lg" />
    </DummyCopies>
  );
}

/**
 * This component is used to render an grid of content cards that can be selected
 * and removed. It is used in the assignment creation flow.
 *
 * The content cards are fetched dynamically on user scroll using the
 * `useGetContentCardsQuery` hook based on the `filter` prop passed to this component.
 */
export function InfiniteSelectableContentCardGrid({
  filter,
  contentCards,
  setContentCards
}: InfiniteSelectableContentCardGridProps) {
  const [learnMoreAbout, setLearnMoreAbout] = useState<{
    id: string;
    contentType: 'Podcast' | 'Stream' | 'Event' | 'Ebook' | 'LearningPath';
  }>();

  const selectContent = useCallback(
    (content: AssignmentContent) => {
      setContentCards([...contentCards, content]);
    },
    [contentCards, setContentCards]
  );

  const removeContent = useCallback(
    (id: string) => {
      const filteredContentCards = contentCards.filter((c) => c.id !== id);
      setContentCards(filteredContentCards);
    },
    [contentCards, setContentCards]
  );

  const { data, loading, fetchMore, error, networkStatus } =
    useGetContentCardsQuery({
      variables: { filter, sorting: ContentCardsSorting.PublishedAt }
    });

  const normalized = {
    edges: data?.contentCards.edges || [],
    meta: data?.contentCards.meta || { total: 0, limit: 0, offset: 0 }
  };

  const hasNextPage =
    !loading && normalized.edges.length < normalized.meta.total;

  const [scrollRef] = useInfiniteScroll({
    loading,
    hasNextPage,

    onLoadMore: () => {
      const offset = normalized.meta.offset + normalized.meta.limit;

      fetchMore({
        variables: {
          filter: {
            ...filter,
            limit: DEFAULT_FILTER_LIMIT,
            offset
          }
        }
      });

      segment.fetchMore({
        componentName: 'InfiniteSelectableContentCardGrid',
        fetchedItemCount: offset
      });
    },

    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,

    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px'
  });

  const getContentCardSummaryActions = useCallback(() => {
    if (!learnMoreAbout?.id) {
      return null;
    }

    const isContentSelected = contentCards.some(
      (content) => content.id === learnMoreAbout.id
    );

    return isContentSelected ? (
      <TrackedButton
        name="Remove"
        flex={1}
        variant="outline"
        onClick={() => {
          removeContent(learnMoreAbout.id);
        }}
      >
        Remove
      </TrackedButton>
    ) : (
      <TrackedButton
        name="Select Content"
        flex={1}
        variant="outline"
        onClick={() => {
          selectContent(
            normalized.edges.find(
              (c) => c.id === learnMoreAbout.id
            ) as AssignmentContent
          );
        }}
      >
        Select Content
      </TrackedButton>
    );
  }, [
    contentCards,
    removeContent,
    learnMoreAbout?.id,
    normalized.edges,
    selectContent
  ]);

  if (
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables
  ) {
    return (
      <SimpleGrid
        spacing={6}
        templateColumns="repeat(auto-fill, minmax(240px, 1fr))"
      >
        <Loader />
      </SimpleGrid>
    );
  }

  if (normalized.edges.length === 0) {
    return <NoSearchResults />;
  }

  return (
    <>
      <SimpleGrid
        spacing={6}
        templateColumns="repeat(auto-fill, minmax(240px, 1fr))"
      >
        <AnimatePresence>
          {map(normalized.edges, (card: AssignmentContent) => (
            <motion.div
              key={card.id}
              ref={scrollRef}
              layout
              layoutId={card.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              // The element needs to exist in the DOM to be able to keep the
              // infinite scroll working. If we remove the element from the DOM
              // the infinite scroll will stop working because of missing `scrollRef`s
              hidden={contentCards.some((content) => content.id === card.id)}
            >
              <ContentCardWithOverlay
                {...card}
                overlayActions={[
                  {
                    label: 'Learn More',
                    variant: 'outline',
                    onClick: () => {
                      setLearnMoreAbout({
                        id: card?.id,
                        contentType: card.__typename
                      });
                    }
                  },
                  {
                    label: 'Select Content',
                    variant: 'solid',
                    onClick: () => selectContent(card)
                  }
                ]}
              />
            </motion.div>
          ))}
        </AnimatePresence>
        {networkStatus === NetworkStatus.fetchMore ? <Loader /> : null}
      </SimpleGrid>
      <ContentCardSummary
        id={learnMoreAbout?.id}
        contentType={learnMoreAbout?.contentType}
        isOpen={!!learnMoreAbout?.id}
        onClose={() => setLearnMoreAbout(undefined)}
        actions={getContentCardSummaryActions()}
      />
    </>
  );
}
