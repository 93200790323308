import { devtools } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { isSSR } from '@tigerhall/core';

type ThemeMode = 'light' | 'dark';
type ActiveCollapsiblePanel = 'textSize';

export interface PowerReadState {
  theme: ThemeMode;
  fontSize: number;
  chapterIndex: number;
  pageIndex: number;
  pagesTotal: number;
  isPlayingAudio: boolean;
  isStayingOnPage: boolean;
  customPagesTotal?: number;
  activeCollapsiblePanel?: ActiveCollapsiblePanel;
}

export interface PowerReadActions {
  setTheme: (theme: ThemeMode) => void;
  setFontSize: (fontSize: number) => void;
  setChapterIndex: (chapterIndex: number) => void;
  setPageIndex: (pageIndex: number) => void;
  setPagesTotal: (pagesTotal: number) => void;
  setCustomPagesTotal: (customPagesTotal: number) => void;
  setIsPlayingAudio: (isPlayingAudio: boolean) => void;
  setIsStayingOnPage: (isStayingOnPage: boolean) => void;
  setActiveCollapsiblePanel: (
    activeCollapsiblePanel: ActiveCollapsiblePanel | undefined
  ) => void;
  reset: () => void;
}

const getSystemTheme = (): ThemeMode => {
  if (isSSR) {
    return 'dark';
  }

  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
};

const initialState: Omit<PowerReadState, 'theme' | 'fontSize'> = {
  chapterIndex: 0,
  pageIndex: 0,
  pagesTotal: 0,
  isPlayingAudio: false,
  activeCollapsiblePanel: undefined,
  isStayingOnPage: false,
  customPagesTotal: 0
};

export const usePowerReadState = createWithEqualityFn<
  PowerReadState & PowerReadActions
>()(
  devtools(
    (set) => ({
      ...initialState,
      theme: getSystemTheme(),
      fontSize: 16,
      setTheme: (theme: ThemeMode) => set(() => ({ theme })),
      setFontSize: (fontSize: number) => set(() => ({ fontSize })),
      setChapterIndex: (chapterIndex: number) => set(() => ({ chapterIndex })),
      setPageIndex: (pageIndex: number) => set(() => ({ pageIndex })),
      setPagesTotal: (pagesTotal: number) => set(() => ({ pagesTotal })),
      setCustomPagesTotal: (customPagesTotal: number) =>
        set(() => ({ customPagesTotal })),
      setIsPlayingAudio: (isPlayingAudio: boolean) =>
        set(() => ({ isPlayingAudio })),
      setActiveCollapsiblePanel: (
        activeCollapsiblePanel: ActiveCollapsiblePanel | undefined
      ) => set(() => ({ activeCollapsiblePanel })),
      setIsStayingOnPage: (isStayingOnPage: boolean) =>
        set(() => ({ isStayingOnPage })),

      reset: () => set(() => ({ ...initialState }))
    }),

    {
      enabled: true,
      name: 'power-read-player-store'
    }
  ),
  shallow
);
