import { ComponentStyleConfig } from '@chakra-ui/react';

/**
 * If size is updated also updated the size here also --> ui/icons/.svgrrc.js
 */

export const Icon: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    boxSize: 5
  }
};
