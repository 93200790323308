import * as React from 'react';
import { Button, Text } from '@chakra-ui/react';
import {
  getStripeCustomerPortalUrl,
  redirectToStripePaymentGateway
} from '@tigerhall/core';
import { DarkPanel } from 'components/ui';
import { useGetCurrentUserQuery } from 'generated';
import { useAppSelector } from 'hooks';
import { getAccessToken, getOrgIdFromToken } from 'app/state';
import { TrackedLink } from '@tigerhall/components';

export function SubscriptionManagementSection() {
  const token = useAppSelector(getAccessToken);
  const organisationId = useAppSelector(getOrgIdFromToken);
  const hasValidOrganisation =
    organisationId && organisationId !== '00000000-0000-0000-0000-000000000000';

  const { data: currentUserData, loading } = useGetCurrentUserQuery({});
  const { subscription } = currentUserData?.me || {};

  const apiOrigin = import.meta.env.VITE_API_URL;

  // don't show if user is part of an ORG because this info
  // shoud be only visible to the ORG admin in /admin/settings
  if (!token || !apiOrigin || loading || hasValidOrganisation) {
    return null;
  }

  // show this to the user if they are not subscribed and is not
  // part of an ORG
  if (!subscription?.subscriptionId && !hasValidOrganisation) {
    return (
      <DarkPanel gap="1rem">
        <Text fontSize="1.25rem" fontWeight="700" color="white">
          Subscribe now!
        </Text>
        <Text fontSize="1rem" fontWeight="500" color="darkGrey.50">
          Get a Tigerhall subscription for unlimited access!
        </Text>
        <Button
          size="lg"
          mt="1rem"
          alignSelf="flex-start"
          onClick={() => {
            redirectToStripePaymentGateway({
              origin: apiOrigin || '',
              token,
              subscriptionType: 'individual',
              successRedirectUrl: `${
                import.meta.env.VITE_WEBAPP_URL
              }/signup/individual/confirmation`,
              cancelRedirectUrl: `${
                import.meta.env.VITE_WEBAPP_URL
              }/profile/settings`
            });
          }}
        >
          Subscribe Now
        </Button>
      </DarkPanel>
    );
  }

  const customerPortal = getStripeCustomerPortalUrl({
    origin: apiOrigin,
    token,
    returnUrl: window?.location?.href
  });

  return (
    <DarkPanel gap="1rem">
      <Text fontSize="1.25rem" fontWeight="700" color="white">
        Subscription
      </Text>
      <Text fontSize="1rem" fontWeight="500" color="darkGrey.50">
        View and manage your subscription.
      </Text>

      <TrackedLink
        href={customerPortal}
        as="button"
        name="MANAGE_SUBSCRIPTION"
        w="fit-content"
        size="lg"
        target="_blank"
      >
        Manage Subscription
      </TrackedLink>
    </DarkPanel>
  );
}
