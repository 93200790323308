import { ApolloError } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { setAccessToken } from 'app/state';
import { captureException } from '@sentry/react';
import { useMicrosoftTeams } from '@tigerhall/core';
import { useCallback, useState } from 'react';

import { useLoginMsTeamsMutation } from '../queries';
import { useAppDispatch } from '../../../hooks';

export type AuthType = 'standard' | 'msg-ext';

interface UseMicrosoftTeamsLoginReturn {
  isLoading: boolean;
  login: () => void;
}

export function useMicrosoftTeamsLogin(
  mode: AuthType = 'standard'
): UseMicrosoftTeamsLoginReturn {
  const { context, sdk } = useMicrosoftTeams();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const [mutation] = useLoginMsTeamsMutation();

  const toast = useToast({
    position: 'top',
    status: 'error',
    title: 'Authentication failed'
  });

  const login = useCallback<() => void>(async () => {
    setIsLoading(true);

    try {
      const token = await sdk.authentication.getAuthToken({});
      if (!token) {
        return toast({
          description:
            'Unable to get token from Microsoft Teams, please contact your microsoft teams administrator'
        });
      }

      const { data } = await mutation({
        variables: {
          token
        }
      });

      if (!data?.loginMSTeams?.success) {
        return toast({
          description: data?.loginMSTeams?.message ?? 'Something went wrong'
        });
      }

      if (mode === 'standard') {
        dispatch(
          setAccessToken({
            token: data?.loginMSTeams?.jwt
          })
        );
      } else {
        sdk.authentication.notifySuccess(data?.loginMSTeams?.jwt);
      }
    } catch (e) {
      console.error(e);
      captureException(e, {
        extra: {
          msTeamsUser: context?.user,
          msTeamsPage: context?.page,
          msTeamsTeam: context?.team
        }
      });

      if (e instanceof ApolloError) {
        return toast({
          description: e.message
        });
      }

      return toast({
        description:
          'Something went wrong, tigerhall has been notified so please try again later'
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    mode,
    sdk.authentication,
    context?.page,
    context?.team,
    context?.user,
    dispatch,
    mutation,
    toast
  ]);

  return {
    isLoading,
    login
  };
}
