import { Button, Flex, Text, useToast } from '@chakra-ui/react';
import { FormInput, TrackedForm } from '@tigerhall/components';
import { DarkPanel } from 'components/ui';
import { useUpdateUserMutation } from 'generated';
import React, { useCallback } from 'react';

export default function AccountDetails({ user }) {
  const [updateUserMutation] = useUpdateUserMutation({});
  const toast = useToast();

  const defaultValues = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email
  };

  const onSubmit = useCallback(
    async (data) => {
      await updateUserMutation({
        variables: {
          id: user?.id,
          input: {
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            coverImage: user?.coverImage?.id,
            avatarImage: user?.avatarImage?.id
          }
        }
      });
      toast({
        title: 'Success',
        description: 'Account Details Updated',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
    },
    [
      toast,
      updateUserMutation,
      user?.avatarImage?.id,
      user?.coverImage?.id,
      user?.id
    ]
  );

  return (
    <DarkPanel>
      <Text fontSize="1.25rem" fontWeight="700" color="white">
        Account Details
      </Text>
      <TrackedForm
        name="PROFILE_BASIC_INFO_UPDATE"
        onSubmit={onSubmit}
        formHookProps={{ defaultValues }}
      >
        {({ formState: { isSubmitting, isValid, isDirty } }) => (
          <Flex direction="column" gap="1rem" mt="1rem">
            <FormInput
              name="firstName"
              placeholder="First name"
              label="First Name"
              rules={{
                required: true
              }}
            />
            <FormInput
              name="lastName"
              placeholder="Last name"
              label="Last Name"
              rules={{
                required: true
              }}
            />
            <FormInput
              name="email"
              type="email"
              placeholder="Email"
              label="Email"
              rules={{
                required: true
              }}
            />
            <Button
              type="submit"
              size="lg"
              alignSelf="flex-start"
              mt="1rem"
              isLoading={isSubmitting}
              isDisabled={!isDirty || !isValid}
            >
              Save Changes
            </Button>
          </Flex>
        )}
      </TrackedForm>
    </DarkPanel>
  );
}
