import { VStack } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/react';
import { DummyCopies } from 'components/ui/DummyCopies';

export function UpNextContentSkeleton() {
  return (
    <VStack w={'full'} px={6} gap={2} py={4}>
      <DummyCopies count={4}>
        <Skeleton width="full" height="4rem" />
      </DummyCopies>
    </VStack>
  );
}
