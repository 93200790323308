import { useBreakpointValue } from '@chakra-ui/react';
import { IconX, IconPictureInPictureOff } from '@tigerhall/icons';

import { OptionButton } from '~/player/components/OptionButton';
import { OptionsGroup } from '~/player/components/OptionsGroup';
import { Overlay } from '~/player/components/Overlay';
import { OverlayFooterContainer } from '~/player/components/OverlayFooterContainer';
import { PlayerControls } from '~/player/components/PlayerControls';

interface MiniPlayerControlsProps {
  onMaximize: () => void;
  handleClose: () => void;
  isLiveContent?: boolean;
}

export function MiniPlayerControls({
  onMaximize,
  handleClose,
  isLiveContent = false
}: MiniPlayerControlsProps) {
  const isMobile = useBreakpointValue(
    {
      base: true,
      lg: false
    },
    {
      fallback: 'lg'
    }
  );
  return (
    <Overlay
      isMiniVideoPlayer
      header={
        <OptionsGroup isMiniVideoPlayer>
          <OptionButton
            label={'Back to full player'}
            icon={IconPictureInPictureOff}
            onClick={() => {
              onMaximize();
            }}
          />
          <OptionButton
            label={'Close player'}
            icon={IconX}
            onClick={() => {
              handleClose();
            }}
          />
        </OptionsGroup>
      }
      footer={
        isLiveContent ? null : (
          <OverlayFooterContainer
            paddingBottom={{
              base: 'calc(100% - 16px)',
              lg: 'calc(100% - 28px)'
            }}
          >
            <PlayerControls
              size={isMobile ? 'sm' : 'lg'}
              hideTrackControls
              hideSeekControls
              isMiniPlayer
            />
          </OverlayFooterContainer>
        )
      }
    />
  );
}
