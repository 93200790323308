import { TrackedLink } from '@tigerhall/components';
import { compactNumber } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';

interface ContentCommentsCountProps {
  contentId: string;
  count: number;
}

export function ContentCommentsCount({
  contentId,
  count
}: ContentCommentsCountProps) {
  const contentLink = `?content=${contentId}`;

  if (count === 0) {
    return null;
  }

  const compactCount = compactNumber(count);

  return (
    <TrackedLink
      name="COMMENTS_ON_CONTENT_LIST"
      href={contentLink}
      onClick={() => {
        segment.commentButtonClicked({
          referenceId: contentId
        });
      }}
    >
      {compactCount} {count === 1 ? 'comment' : 'comments'}
    </TrackedLink>
  );
}
