import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import { UserContentTrackingFragmentDoc } from '../../../../../../../fragments/generated/userContentTracking.generated';
import { LimitedUserFragmentDoc, NotificationPreferencesFragmentDoc, UserNotificationPreferenceFragmentDoc } from '../../../../../../../fragments/generated/user.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStreamSubtitlesQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
  subtitleLanguage?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']>;
}>;


export type GetStreamSubtitlesQuery = { __typename?: 'Query', stream?: { __typename?: 'Stream', id: string, subtitle: { __typename?: 'StreamSubtitles', vttUrl: string } } | null };

export type GetStreamForConnectedVideoPlayerQueryVariables = SchemaTypes.Exact<{
  audioLanguage?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']>;
  id: SchemaTypes.Scalars['String'];
}>;


export type GetStreamForConnectedVideoPlayerQuery = { __typename?: 'Query', stream?: { __typename: 'Stream', id: string, name: string, wentLiveAt?: any | null, source: SchemaTypes.StreamSource, preamble: string, upvoteCount: number, length: number, contentRatingByUser: SchemaTypes.ContentVote, userContentTracking: { __typename: 'UserContentTracking', id: string, current: number, currentPlacement: number, total: number, isAttending: boolean, isFinished: boolean, bookmarkedAt?: any | null, lastOpenedAt?: any | null, finishedAt?: any | null }, contentSegments: Array<{ __typename?: 'ContentSegment', paragraph: string, header: string }>, audioAvailableLanguages: Array<{ __typename?: 'Language', code: string, name: string }>, subtitleAvailableLanguages: Array<{ __typename?: 'Language', code: string, name: string }>, video: { __typename?: 'StreamMedia', url: string, language: string, orientation: SchemaTypes.MediaOrientation }, categories: Array<{ __typename?: 'Category', id: string, name: string }>, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, title: string, company?: string | null, isAuthenticatedUserFollowing: boolean, image: { __typename?: 'Image', id: string, uri: string } }>, image: { __typename?: 'Image', uri: string, id: string }, agenda: Array<{ __typename?: 'StreamAgendaBulletPoint', topic: string, description: string, isActive: boolean, secondsIntoRecordingTopicStartsAt?: number | null, speakers: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, company: string, jobTitle: string, isFollowing: boolean, isExpert: boolean, avatarImage: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string }, expertOrg?: { __typename?: 'Organisation', id: string } | null }> }> } | null };


export const GetStreamSubtitlesDocument = gql`
    query GetStreamSubtitles($id: String!, $subtitleLanguage: String) {
  stream(id: $id) {
    id
    subtitle(language: $subtitleLanguage) {
      vttUrl
    }
  }
}
    `;

/**
 * __useGetStreamSubtitlesQuery__
 *
 * To run a query within a React component, call `useGetStreamSubtitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamSubtitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamSubtitlesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      subtitleLanguage: // value for 'subtitleLanguage'
 *   },
 * });
 */
export function useGetStreamSubtitlesQuery(baseOptions: Apollo.QueryHookOptions<GetStreamSubtitlesQuery, GetStreamSubtitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStreamSubtitlesQuery, GetStreamSubtitlesQueryVariables>(GetStreamSubtitlesDocument, options);
      }
export function useGetStreamSubtitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStreamSubtitlesQuery, GetStreamSubtitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStreamSubtitlesQuery, GetStreamSubtitlesQueryVariables>(GetStreamSubtitlesDocument, options);
        }
export type GetStreamSubtitlesQueryHookResult = ReturnType<typeof useGetStreamSubtitlesQuery>;
export type GetStreamSubtitlesLazyQueryHookResult = ReturnType<typeof useGetStreamSubtitlesLazyQuery>;
export type GetStreamSubtitlesQueryResult = Apollo.QueryResult<GetStreamSubtitlesQuery, GetStreamSubtitlesQueryVariables>;
export const GetStreamForConnectedVideoPlayerDocument = gql`
    query GetStreamForConnectedVideoPlayer($audioLanguage: String, $id: String!) {
  stream(id: $id) {
    __typename
    id
    name
    wentLiveAt
    source
    preamble
    upvoteCount
    length
    contentRatingByUser
    userContentTracking {
      ...UserContentTracking
    }
    contentSegments {
      paragraph
      header
    }
    audioAvailableLanguages {
      code
      name
    }
    subtitleAvailableLanguages {
      code
      name
    }
    video(language: $audioLanguage) {
      url
      language
      orientation
    }
    categories {
      id
      name
    }
    experts {
      id
      firstName
      lastName
      image {
        id
        uri
      }
      title
      company
      isAuthenticatedUserFollowing
    }
    image {
      uri
      id
    }
    agenda {
      topic
      description
      isActive
      secondsIntoRecordingTopicStartsAt
      speakers {
        ...LimitedUser
      }
    }
  }
}
    ${UserContentTrackingFragmentDoc}
${LimitedUserFragmentDoc}`;

/**
 * __useGetStreamForConnectedVideoPlayerQuery__
 *
 * To run a query within a React component, call `useGetStreamForConnectedVideoPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamForConnectedVideoPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamForConnectedVideoPlayerQuery({
 *   variables: {
 *      audioLanguage: // value for 'audioLanguage'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStreamForConnectedVideoPlayerQuery(baseOptions: Apollo.QueryHookOptions<GetStreamForConnectedVideoPlayerQuery, GetStreamForConnectedVideoPlayerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStreamForConnectedVideoPlayerQuery, GetStreamForConnectedVideoPlayerQueryVariables>(GetStreamForConnectedVideoPlayerDocument, options);
      }
export function useGetStreamForConnectedVideoPlayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStreamForConnectedVideoPlayerQuery, GetStreamForConnectedVideoPlayerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStreamForConnectedVideoPlayerQuery, GetStreamForConnectedVideoPlayerQueryVariables>(GetStreamForConnectedVideoPlayerDocument, options);
        }
export type GetStreamForConnectedVideoPlayerQueryHookResult = ReturnType<typeof useGetStreamForConnectedVideoPlayerQuery>;
export type GetStreamForConnectedVideoPlayerLazyQueryHookResult = ReturnType<typeof useGetStreamForConnectedVideoPlayerLazyQuery>;
export type GetStreamForConnectedVideoPlayerQueryResult = Apollo.QueryResult<GetStreamForConnectedVideoPlayerQuery, GetStreamForConnectedVideoPlayerQueryVariables>;