import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeBroadcastStatusMutationVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
  status: SchemaTypes.StreamStatus;
}>;


export type ChangeBroadcastStatusMutation = { __typename?: 'Mutation', changeStreamStatus: { __typename?: 'Stream', id: string, status: SchemaTypes.StreamStatus } };


export const ChangeBroadcastStatusDocument = gql`
    mutation ChangeBroadcastStatus($id: ID!, $status: StreamStatus!) {
  changeStreamStatus(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type ChangeBroadcastStatusMutationFn = Apollo.MutationFunction<ChangeBroadcastStatusMutation, ChangeBroadcastStatusMutationVariables>;

/**
 * __useChangeBroadcastStatusMutation__
 *
 * To run a mutation, you first call `useChangeBroadcastStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBroadcastStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBroadcastStatusMutation, { data, loading, error }] = useChangeBroadcastStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChangeBroadcastStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeBroadcastStatusMutation, ChangeBroadcastStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeBroadcastStatusMutation, ChangeBroadcastStatusMutationVariables>(ChangeBroadcastStatusDocument, options);
      }
export type ChangeBroadcastStatusMutationHookResult = ReturnType<typeof useChangeBroadcastStatusMutation>;
export type ChangeBroadcastStatusMutationResult = Apollo.MutationResult<ChangeBroadcastStatusMutation>;
export type ChangeBroadcastStatusMutationOptions = Apollo.BaseMutationOptions<ChangeBroadcastStatusMutation, ChangeBroadcastStatusMutationVariables>;