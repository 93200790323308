import { type StreamStatus } from '@tigerhall/core';
import { type ReactNode, type RefObject, useEffect } from 'react';

import {
  MiniPlayerControls,
  VideoPlayerContainer,
  type VideoPlayerContainerProps
} from '~/player/components/VideoPlayerContainer';
import { useMediaState } from '~/player/context';
import { useFullscreen } from '~/player/hooks/useFullscreen';
import { getDidStreamEnd } from '~/player/utils/stream';
import { LiveContentEndedOverlay } from './LiveContentEndedOverlay';
import {
  LiveContentOverlay,
  type LiveContentOverlayProps
} from './LiveContentOverlay';
import { LiveContentOverlayLoader } from './LiveContentOverlayLoader';
import { LiveContentPlayPauseToggle } from './LiveContentPlayPauseToggle';

type ChildrenComponentProps = Pick<
  LiveContentOverlayProps,
  | 'featuredUser'
  | 'contentId'
  | 'contentTitle'
  | 'contentDescription'
  | 'urlToShare'
  | 'disableShare'
> &
  Pick<VideoPlayerContainerProps, 'isLiveStream'>;

export interface LiveContentActivePlayerProps extends ChildrenComponentProps {
  /**
   * For Videos, it is the `<video/>` element.
   */
  children: ReactNode;
  isMinimized?: boolean;
  onMaximize: () => void;
  handleClose: () => void;
  onMinimize: () => void;
  videoRef: RefObject<HTMLVideoElement>;
  streamStatus: StreamStatus;
  isBuffering: boolean;
}

export function LiveContentActivePlayer({
  children,
  handleClose,
  onMaximize,
  onMinimize,
  isMinimized = false,

  // from `VideoPlayerContainerProps`
  isLiveStream,

  // from `LiveContentOverlayProps`
  featuredUser,
  contentId,
  contentTitle,
  contentDescription,
  urlToShare,
  videoRef,
  streamStatus,
  isBuffering,
  disableShare
}: LiveContentActivePlayerProps) {
  const { isFullscreen, videoContainerRef, onFullscreenToggle } = useFullscreen(
    { videoRef }
  );

  const didStreamEnd = getDidStreamEnd(streamStatus);

  useEffect(() => {
    if (isFullscreen && didStreamEnd && document.fullscreenElement) {
      onFullscreenToggle();
    }
  }, [didStreamEnd, isFullscreen, onFullscreenToggle]);

  useEffect(() => {
    if (isMinimized && didStreamEnd) {
      onMaximize();
    }
  }, [isMinimized, onMaximize, didStreamEnd]);

  const isPlaying = useMediaState((state) => state.isPlaying);

  return (
    <VideoPlayerContainer
      isMinimized={isMinimized}
      containerRef={videoContainerRef}
      isLiveStream={isLiveStream}
    >
      {isBuffering ? <LiveContentOverlayLoader /> : null}

      {!isPlaying && <LiveContentPlayPauseToggle />}

      {didStreamEnd ? (
        <LiveContentEndedOverlay handleClose={handleClose} />
      ) : null}

      {children}
      {isMinimized ? (
        <MiniPlayerControls
          onMaximize={onMaximize}
          handleClose={handleClose}
          isLiveContent
        />
      ) : (
        <LiveContentOverlay
          contentId={contentId}
          contentTitle={contentTitle}
          contentDescription={contentDescription}
          onMinimize={onMinimize}
          isFullscreen={isFullscreen}
          onFullscreenToggle={onFullscreenToggle}
          urlToShare={urlToShare}
          disableShare={disableShare}
          /**
           * This is user's info presenting LIVE content
           */
          featuredUser={featuredUser}
        />
      )}
    </VideoPlayerContainer>
  );
}
