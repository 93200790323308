import {
  Box,
  Icon,
  IconButton,
  type IconButtonProps,
  ModalCloseButton
} from '@chakra-ui/react';
import {
  IconLanguage,
  IconMoon,
  IconSun,
  IconTextSize
} from '@tigerhall/icons';

import type { SharedTranscriptReaderProps } from '../types';

const commonStyle: Pick<IconButtonProps, 'boxShadow' | 'variant'> = {
  variant: 'unstyle',
  boxShadow: 'none'
};

export function Header({
  setConfig,
  config
}: Readonly<SharedTranscriptReaderProps>) {
  const { isDarkMode, isFontSizeSelectorOpen, isSelectLanguageSelectorOpen } =
    config;

  const iconColor = isDarkMode ? 'lightGrey.600' : 'darkGrey.300';

  return (
    <Box
      maxH="3.5rem"
      bg={isDarkMode ? 'darkGrey.400' : 'lightGrey.100'}
      p="1rem 1.5rem"
      display="flex"
      alignItems="center"
      gap="0.625rem"
      alignContent="center"
      borderTopLeftRadius={{ base: '0', md: '1rem' }}
      borderTopRightRadius={{ base: '0', md: '1rem' }}
      boxShadow="md"
      pos="relative"
    >
      <>
        <IconButton
          aria-label="Change font size"
          {...commonStyle}
          onClick={() =>
            setConfig({
              ...config,
              isFontSizeSelectorOpen: !isFontSizeSelectorOpen,
              isSelectLanguageSelectorOpen: false
            })
          }
          icon={
            <Icon
              as={IconTextSize}
              color={!isFontSizeSelectorOpen ? iconColor : 'tigerOrange.600'}
              w="1.5rem"
              h="1.5rem"
            />
          }
        />

        <IconButton
          aria-label={
            isDarkMode ? 'Change to light mode' : 'Change to dark mode'
          }
          {...commonStyle}
          onClick={() =>
            setConfig({
              ...config,
              isDarkMode: !isDarkMode
            })
          }
          icon={
            <Icon
              as={!isDarkMode ? IconSun : IconMoon}
              color={iconColor}
              w="1.5rem"
              h="1.5rem"
            />
          }
        />

        <IconButton
          aria-label="Choose language"
          {...commonStyle}
          onClick={() =>
            setConfig({
              ...config,
              isFontSizeSelectorOpen: false,
              isSelectLanguageSelectorOpen: !isSelectLanguageSelectorOpen
            })
          }
          icon={
            <Icon
              as={IconLanguage}
              color={
                !isSelectLanguageSelectorOpen ? iconColor : 'tigerOrange.600'
              }
              w="1.5rem"
              h="1.5rem"
            />
          }
        />
        <ModalCloseButton color={iconColor} />
      </>
    </Box>
  );
}
