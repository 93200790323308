import { Box, List } from '@chakra-ui/react';

import { DummyCopies } from '~/components/ui/DummyCopies';
import { ProfileBarBlockSkeleton } from '~/components/ui/User/ProfileBarBlock';

interface BlockedUsersLoadingSkeltonProps {
  readonly count?: number;
}

export function BlockedUsersLoadingSkelton({
  count = 10
}: Readonly<BlockedUsersLoadingSkeltonProps>) {
  return (
    <List spacing={4}>
      <DummyCopies count={count}>
        <Box minH="4.25rem">
          <ProfileBarBlockSkeleton />
        </Box>
      </DummyCopies>
    </List>
  );
}
