import * as React from 'react';
import { Button, Flex, Icon } from '@chakra-ui/react';
import { Content, ContentType, EventFilter } from '@tigerhall/core/lib/types';
import { IconEdit, IconPlus, IconTrash } from '@tigerhall/icons';
import { THLink } from '@tigerhall/ui-components';
import { InfiniteSingleTypeCardGrid, PageBanner } from 'components/ui';
import { useDeleteEventMutation, useGetContentByIdQuery } from 'generated';
import withAction from 'hoc/withAction';
import { useNavigate } from 'react-router-dom';
import { getOrgIdFromToken } from 'app/state';
import { useAppSelector } from 'hooks';
import {
  ContentSummaryModal,
  GenericHistoryProps,
  GenericSearchForm
} from 'modules/admin/components';

import { eventDetailsRoute } from '../../utils/routes';

const CreateEventAction = ({ children }: { children: React.ReactNode }) => (
  <THLink name="CREATE_EVENT" to={eventDetailsRoute('draft')}>
    {children}
  </THLink>
);

function EventsHistory({ setActionConfig }: Readonly<GenericHistoryProps>) {
  const orgId = useAppSelector(getOrgIdFromToken);
  const navigate = useNavigate();

  const [selectedEvent, setSelectedEvent] = React.useState<string>();

  const { data } = useGetContentByIdQuery({
    skip: !selectedEvent,
    variables: {
      id: selectedEvent as string
    }
  });

  const [filter, setFilter] = React.useState<EventFilter>({
    organisationID: [orgId as string],

    limit: 10,
    offset: 0
  });

  const getActionConfig = (card, refetch) => ({
    modalConfig: {
      title: 'Delete Experience',
      body: 'Are you sure you want to delete this experience?',
      primaryActionText: 'Delete',
      postAction: () => {
        refetch?.();
      }
    },
    mutationConfig: {
      variables: {
        id: card?.id
      }
    },
    toastConfig: {
      successDescription: 'Experience Deleted successfully.'
    }
  });

  return (
    <>
      <PageBanner
        title="Custom Experiences"
        description="Experiences are a great way for your teams to share their insights, get personalised advice from their seniors, and network within the organization. Create your own custom Experiences around specific topics, and invite the relevant individuals who would benefit from the conversation."
        action={
          <CreateEventAction>
            <Button size="lg" leftIcon={<IconPlus color="tigerOrange.600" />}>
              Create New Experience
            </Button>
          </CreateEventAction>
        }
      />
      <Flex mt="2rem" direction="column" gap="1rem">
        <GenericSearchForm
          setFilter={setFilter}
          nameLabel="Experience name"
          heading="Experiences History"
          formName="searchEventHistory"
        />

        <InfiniteSingleTypeCardGrid
          type={ContentType.Event}
          filter={filter}
          overlayActions={(card, refetch) => [
            {
              label: 'Learn More',
              variant: 'outline',
              onClick: () => {
                setSelectedEvent(card.id);
              }
            },
            {
              label: 'Edit',
              onClick: () => {
                navigate(eventDetailsRoute(card.id));
              },
              leftIcon: <Icon as={IconEdit} />
            },
            {
              label: 'Delete',
              onClick: () => {
                setActionConfig(getActionConfig(card, refetch));
              },
              leftIcon: <Icon as={IconTrash} />
            }
          ]}
        />
      </Flex>
      <ContentSummaryModal
        isOpen={!!data?.contentCard}
        onClose={() => setSelectedEvent(undefined)}
        content={data?.contentCard as Content}
        actions={
          <>
            <Button
              flex={1}
              variant="outline"
              leftIcon={<Icon as={IconEdit} />}
              onClick={() => {
                navigate(eventDetailsRoute(selectedEvent as string));
                setSelectedEvent(undefined);
              }}
            >
              Edit
            </Button>
          </>
        }
      />
    </>
  );
}
export default withAction({ actionMutation: useDeleteEventMutation })(
  EventsHistory
);
