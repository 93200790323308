import { Flex, LightMode, Progress, Text } from '@chakra-ui/react';

import { usePowerReadState } from '~/player/PowerRead/context/usePowerReadState';

export function ProgressBar() {
  const { pageIndex, pagesTotal, customPagesTotal } = usePowerReadState(
    (state) => {
      return {
        pageIndex: state.pageIndex,
        pagesTotal: state.pagesTotal,
        customPagesTotal: state.customPagesTotal
      };
    }
  );

  const progress = pageIndex + 1;
  const max = customPagesTotal || pagesTotal;

  return (
    <Flex width="100%" maxWidth="30rem" alignItems="center" gap={4}>
      <Text fontSize="sm" whiteSpace="nowrap" color="white">
        {`Page ${progress}/${max}`}
      </Text>
      <LightMode>
        <Progress
          value={progress}
          colorScheme="orange"
          isAnimated
          min={0}
          max={max}
          height="5px"
          w="100%"
          borderRadius="5px"
          bg="lightGrey.100"
          zIndex={10}
        />
      </LightMode>
    </Flex>
  );
}
