import * as React from 'react';
import { IconProps, Icon as ChakraIcon } from '@chakra-ui/react';
import { TrackedButton } from '@tigerhall/components';

type TableActionProps = {
  label: string;
  id: string;
  icon: React.ComponentType<IconProps>;
  onClick: (id: string) => void;
  isDisabled?: boolean;
  dataCy?: string;
};

export function TableAction({
  label,
  icon,
  onClick,
  isDisabled,
  dataCy = '',
  id
}: TableActionProps) {
  return (
    <TrackedButton
      name={label}
      variant="inline"
      isDisabled={isDisabled}
      onClick={() => onClick(id)}
      leftIcon={<ChakraIcon as={icon} w="1rem" h="1rem" />}
    >
      {label}
    </TrackedButton>
  );
}
