import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import * as segment from '@tigerhall/analytics';
import { TrackedLink } from '@tigerhall/components';
import { type GetOrganisationMediaQuery, type Organisation } from 'generated';

import { OrganisationLogo } from './OrganisationLogo';
import { CoverImage } from './CoverImage';

interface HomePageBannerProps {
  heading: string;
  logo: GetOrganisationMediaQuery['organisation']['logo'];
  org: Pick<Organisation, 'id' | 'name'>;
  coverImage: GetOrganisationMediaQuery['organisation']['coverImage'];
}

export function HomePageBanner({
  heading,
  logo,
  org,
  coverImage
}: HomePageBannerProps) {
  return (
    <Box id="org-content">
      <Text
        as="h2"
        color="white"
        fontWeight="bold"
        fontSize={{
          base: 'xl',
          md: '2xl'
        }}
        mb={{
          base: '1rem',
          md: '1.5rem'
        }}
      >
        {heading}
      </Text>
      <Flex
        flexDirection={{
          base: 'column',
          lg: 'row-reverse'
        }}
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        width="100%"
        borderRadius="2xl"
        border="2px solid"
        borderColor="teal.400"
        overflow="hidden"
        bgGradient="linear(teal.600 0%, black 100%)"
      >
        <CoverImage uri={coverImage?.uri} alt={coverImage?.alt ?? ''} />
        <VStack
          p="2rem"
          spacing={0}
          gap="1rem"
          flexWrap="wrap"
          alignItems={{
            base: 'center',
            lg: 'flex-start'
          }}
          maxWidth={{
            base: '100%',
            lg: '50%'
          }}
        >
          {logo?.uri ? (
            <OrganisationLogo {...logo} />
          ) : (
            <Text
              as="h3"
              fontSize="2xl"
              fontWeight="bold"
              color="lightGrey.200"
            >
              {org?.name}
            </Text>
          )}
          <Text
            fontSize="sm"
            color="lightGrey.200"
            width="100%"
            maxWidth="22rem"
            textAlign={{
              base: 'center',
              lg: 'left'
            }}
          >
            Easily access your assignments and content related to your
            organization in one place.
          </Text>

          <TrackedLink
            as="button"
            variant="solid"
            size="lg"
            name="EXPLORE_ORG"
            href="/organization"
            onClick={() => {
              segment.organisationContentClicked({
                organisationId: org?.id,
                organisationName: org?.name
              });
            }}
          >
            View Organization Content
          </TrackedLink>
        </VStack>
      </Flex>
    </Box>
  );
}
