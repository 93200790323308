import { Location } from 'history';

export function removeArgFromQueryString(
  location: Location,
  ...args: string[]
) {
  const params = new URLSearchParams(location.search);

  args.forEach((arg) => params.delete(arg));

  return `${location.pathname}?${params.toString()}`;
}
