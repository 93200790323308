import { Spinner } from '@chakra-ui/react';

type WizardContentProps = {
  children: React.ReactNode;
  loading?: boolean;
};

const WizardContent = ({ children, loading }: WizardContentProps) => (
  <>
    {loading ? (
      <Spinner alignSelf="center" my={80} size="lg" color="white" />
    ) : (
      children
    )}
  </>
);

export default WizardContent;
