import { useCallback, useState } from 'react';
import { Box, Icon } from '@chakra-ui/react';
import property from 'lodash/property';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { type SortingRule } from 'react-table';
import { NetworkStatus } from '@apollo/client';
import {
  type SortableAndResizableColumn,
  TableBodyFallback,
  Table as TableUI
} from '@tigerhall/ui-components';
import { IconCirclePlus, IconSquareOff } from '@tigerhall/icons';
import {
  type Assignment,
  AssignmentStatus,
  type SortOrderingInput
} from '@tigerhall/core';
import { StatusBadge, TrackedLink } from '@tigerhall/components';
import { useAppSelector } from 'hooks';
import { getSelectedOrgId } from 'app/state';
import {
  type GetOrgAssignmentsQueryVariables,
  useGetOrgAssignmentsQuery
} from 'generated';

import { TableFilters } from './TableFilters';

function formatRowDate(date: string) {
  return date ? DateTime.fromISO(date).toFormat('DDD') : '-';
}

const columns: SortableAndResizableColumn<Assignment>[] = [
  {
    id: 'assignmentTitle',
    Header: 'Assignment Title',
    width: 230,
    accessor: 'assignmentTitle'
  },
  {
    id: 'createdBy',
    Header: 'Created by',
    width: 180,
    accessor: (assignment) =>
      `${assignment?.createdBy?.firstName} ${assignment?.createdBy?.lastName}`,
    disableSortBy: true
  },
  {
    id: 'users',
    Header: 'Users',
    width: 100,
    accessor: (assignment) => assignment?.users?.meta?.total,
    disableSortBy: true
  },
  {
    id: 'orgGroups',
    Header: 'Groups',
    width: 200,
    accessor: (assignment) =>
      assignment?.orgGroups
        ? assignment?.orgGroups.map((group) => group.name).join(', ')
        : '-',
    disableSortBy: true
  },
  {
    id: 'createdAt',
    Header: 'Date Created',
    accessor: (assignment) => formatRowDate(assignment?.createdAt)
  },
  {
    id: 'dueDate',
    Header: 'Due Date',
    accessor: (assignment) => formatRowDate(assignment?.dueDate)
  },
  {
    id: 'updatedBy',
    Header: 'Last Updated by',
    accessor: (assignment) =>
      assignment?.updatedBy
        ? `${assignment.updatedBy?.firstName} ${assignment.updatedBy?.lastName}`
        : '-',
    disableSortBy: true
  },
  {
    id: 'updatedAt',
    Header: 'Last Updated',
    accessor: (assignment) => formatRowDate(assignment?.updatedAt)
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: (assignment) => {
      const { status } = assignment || {};
      if (!status) {
        return '-';
      }

      return (
        <StatusBadge
          status={status === AssignmentStatus.Active ? 'success' : 'error'}
          variant="inline"
          size="sm"
          textTransform="capitalize"
        >
          {status.toLowerCase()}
        </StatusBadge>
      );
    }
  }
];

const getRowId = property<Assignment, string>('id');
const INITIAL_LIMIT = 10;

export function AssignmentTable() {
  const orgId = useAppSelector(getSelectedOrgId);

  const [queryVariables, setQueryVariables] =
    useState<GetOrgAssignmentsQueryVariables>({
      id: orgId!,
      filter: {
        limit: INITIAL_LIMIT,
        offset: 0,
        title: '',
        status: [AssignmentStatus.Active, AssignmentStatus.Expired]
      }
    });

  const { data, loading, networkStatus } = useGetOrgAssignmentsQuery({
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    skip: !orgId
  });

  const refetchData = useCallback(
    (limit: number, offset: number, val: SortingRule<string>[]) => {
      const sorting: SortOrderingInput[] = [];

      val.forEach((current) => {
        sorting.push({
          field: current.id,
          direction: current.desc ? 'desc' : 'asc'
        });
      });

      setQueryVariables((current) => ({
        ...current,
        filter: {
          ...current.filter,
          limit: limit,
          offset: offset
        },
        sorting: { sorting }
      }));
    },
    [setQueryVariables]
  );

  const navigate = useNavigate();

  const onUpdateFilters = useCallback(
    (values) => {
      setQueryVariables((current) => ({
        ...current,
        filter: {
          ...current.filter,
          ...values
        }
      }));
    },
    [setQueryVariables]
  );

  return (
    <>
      <TableFilters onUpdate={onUpdateFilters} />
      <TableUI
        name="organizationAssignments"
        data={(data?.organisation?.assignments?.edges as Assignment[]) || []}
        columns={columns}
        loading={loading || networkStatus === NetworkStatus.refetch}
        onRowClick={(row) => {
          navigate(`/admin/assignments/${row.original.id}`);
        }}
        getRowId={getRowId}
        pageMeta={data?.organisation?.assignments?.meta}
        reFetchData={refetchData}
        emptyMessage={
          <TableBodyFallback
            icon={IconSquareOff}
            heading="You don't have any assignment history"
            subheading="There are no assignments to users yet. Create assignment to give your team a direction to grow and crush their goals. OR You have not assigned any assignments to users..."
          >
            <Box pt="1rem">
              <TrackedLink
                as="button"
                name="create assignment"
                href="/admin/assignments/draft"
                size="md"
                leftIcon={<Icon as={IconCirclePlus} color="tigerOrange.600" />}
              >
                Create Assignment
              </TrackedLink>
            </Box>
          </TableBodyFallback>
        }
      />
    </>
  );
}
