import { Flex } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import * as segment from '@tigerhall/analytics';
import { FormInput, TrackedButton, TrackedForm } from '@tigerhall/components';
import { IconTrash } from '@tigerhall/icons';
import { cache } from 'api/apollo';
import { OptionsMenuItem } from 'components/ui/OptionsMenu';
import { ActionModal } from 'components/ui/modals';
import { useDeleteCommentMutation } from 'generated';
import { useState, useTransition } from 'react';

export interface DeleteCommentOptionProps {
  commentId: string;
  isCommentOwner?: boolean;
  onDelete?: () => Promise<unknown> | void;
}

type FormFields = {
  reason?: string;
};

const OWNER_DELETE_COMMENT_HEADER = {
  title: 'Delete Comment',
  description: 'Are you sure you want to delete this comment?'
};

const ADMIN_DELETE_COMMENT_HEADER = {
  title: "Tell us your reason for deleting this user's comment",
  description: 'Please enter your reason'
};

const DELETE_WARNING =
  'Once a comment is deleted, it will be permanently removed and cannot be restored';

export function DeleteCommentOption({
  commentId,
  isCommentOwner = false,
  onDelete
}: DeleteCommentOptionProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [shouldOpenDeleteModal, setShouldOpenDeleteModal] = useState(false);
  const [, startTransition] = useTransition();

  const [deleteCommentMutation] = useDeleteCommentMutation();

  const toast = useToast({
    duration: 5000
  });

  const headerDetails = isCommentOwner
    ? OWNER_DELETE_COMMENT_HEADER
    : ADMIN_DELETE_COMMENT_HEADER;

  async function onSubmit(values: FormFields) {
    try {
      setIsLoading(true);
      await deleteCommentMutation({
        variables: {
          commentId,
          reason: values.reason
        }
      });

      await onDelete?.();
      setShouldOpenDeleteModal(false);

      startTransition(() => {
        cache.evict({
          id: cache.identify({
            __typename: 'Comment',
            id: commentId
          })
        });

        cache.gc();
        toast.closeAll();
      });
    } catch (error) {
      startTransition(() => {
        toast.closeAll();
        toast({
          title: 'Error',
          description: 'Comment could not be deleted. Please try again later.',
          status: 'error'
        });
      });
      captureException(new Error(`Error deleting comment`), {
        tags: {
          commentId: commentId
        },
        extra: {
          reason: values.reason,
          error
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  function onCancelDelete() {
    setShouldOpenDeleteModal(false);
  }

  return (
    <>
      <OptionsMenuItem
        icon={IconTrash}
        title="Delete Comment"
        description="Comment will be deleted forever"
        onClick={() => {
          segment.commentOptionsClicked({
            referenceId: commentId,
            buttonName: 'delete_comment'
          });
          setShouldOpenDeleteModal(true);
        }}
      />
      <ActionModal
        title={headerDetails.title}
        description={headerDetails.description}
        info={DELETE_WARNING}
        isOpen={shouldOpenDeleteModal}
        onClose={onCancelDelete}
      >
        <TrackedForm<FormFields>
          name="deleteComment"
          focus="reason"
          onSubmit={onSubmit}
        >
          {isCommentOwner ? null : (
            <FormInput
              name="reason"
              placeholder="Enter reason"
              type="text"
              label="Reason"
              rules={{
                required: 'Please enter a reason for deleting this comment'
              }}
            />
          )}
          <Flex gap="1rem" mt="1.5rem">
            <TrackedButton
              name="cancel"
              variant="outlineDark"
              onClick={onCancelDelete}
              size="lg"
              flex={1}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Cancel
            </TrackedButton>

            <TrackedButton
              name="submit"
              type="submit"
              variant="solidDark"
              size="lg"
              flex={1}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Delete
            </TrackedButton>
          </Flex>
        </TrackedForm>
      </ActionModal>
    </>
  );
}
