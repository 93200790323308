import { ContentType, type ContentTypename } from '@tigerhall/core';

/**
 * @useCase This functions are created to be used for commonComponents
 * to pass the Link/url/href to the Component so the component
 * can be reused in both webapp-website
 */

export interface LinkToContentProps {
  /** should be the type of content */
  typename: ContentType | ContentTypename;
  /** should be content id */
  id: string;
}

/**
 * @example ```ts
 * linkToExpert(props?.experts?.[0]?.id)
 * ```
 */
export function linkToExpert({ id }: Pick<LinkToContentProps, 'id'>) {
  return `/profile/${id}`;
}

/**
 * @example ```ts
 * linkToContent({ typename: props.__typename, id: props.id })
 * ```
 */
export function linkToContent({ typename, id }: LinkToContentProps) {
  const params = new URLSearchParams(window.location.search);

  params.delete('content');
  params.delete('trail');

  if (typename === ContentType.LearningPath || typename === 'LearningPath') {
    params.append('trail', id);
    return `?${params.toString()}`;
  }

  params.append('content', id);
  return `?${params.toString()}`;
}

/**
 * @example ```ts
 * linkToCategory(props?.categories[0]?.id)
 * ```
 */
export function linkToCategory({ id }: Pick<LinkToContentProps, 'id'>) {
  return `/content/category/${id}`;
}

interface GetPreviousRouteProps {
  fallback?: string;
}

/**
 * @description this function checks if the page has been accessed directly in
 * browser or accessed through application, and return correct route path.
 * If the page is accessed directly via it's link and page consist of
 * back button then it should return to home-page instead of being
 * un-responsive/opening browser homepage. Also, if this is the case then reset
 * the current stack of `history({ replace: true })`. This will prevent browser
 * back button to open previous page and thus not opening previous route again.
 */
export function getPreviousRoute({ fallback }: GetPreviousRouteProps = {}) {
  // `state` The value is `null` until the pushState() or replaceState() method is used.
  const hasHistoryState = Boolean(history.state);

  return hasHistoryState && history.state.idx > 0
    ? {
        prevRoute: -1,
        replace: false
      }
    : {
        prevRoute: fallback ?? '/home',
        replace: true
      };
}
