import { EngagementReportColumns as Columns, type EngagementReportFilter as Filter } from '@tigerhall/core';

import {
  type AllStaticFilters,
  type DynamicFilter,
  type ReportColumn,
  type ReportTemplate,
  StaticFilterType
} from '~/modules/admin/modules/reportBuilder/types';
import { apolloClient } from '~/api/apollo';
import {
  ExportEngagementReportDocument,
  type ExportEngagementReportMutation,
  type ExportEngagementReportMutationVariables
} from '~/modules/admin/modules/reportBuilder/queries';
import {
  transformStateToDefaultFilters,
  transformStaticDateToTimeRange
} from '~/modules/admin/modules/reportBuilder/utils';
import { type ReportBuilderWizardStoreState } from '~/modules/admin/modules/reportBuilder/stores';

export class EngagementReport implements ReportTemplate<Filter> {
  getName(): string {
    return 'User Engagement';
  }

  getDescription(): string {
    return 'Number of comments, likes, dislikes, livestream attended all in one place for any user.';
  }

  isEnabled(): boolean {
    return true;
  }

  getColumns(): ReportColumn[] {
    return [
      {
        id: Columns.UserName,
        name: 'Username'
      },
      {
        id: Columns.Email,
        name: 'Email'
      },
      {
        id: Columns.TigerhallUserId,
        name: 'Tigerhall User ID'
      },
      {
        id: Columns.Comments,
        name: 'Comments'
      },
      {
        id: Columns.ContentPiecesLiked,
        name: 'Content Pieces Liked'
      },
      {
        id: Columns.ContentPiecesDisliked,
        name: 'Content Pieces Disliked'
      },
      {
        id: Columns.FeedPosts,
        name: 'Feed posts'
      },
      {
        id: Columns.Reactions,
        name: 'Reactions'
      },
      {
        id: Columns.LivestreamAttended,
        name: 'Livestreams Attended'
      },
      {
        id: Columns.LivestreamChatsSent,
        name: 'Livestream Chats Sent'
      }
    ];
  }

  getSampleData(): Record<string, string | number | boolean> {
    return {
      [Columns.UserName]: 'Rachel Tan',
      [Columns.Email]: 'rachel.tan@tigerhall.com',
      [Columns.TigerhallUserId]: 'ad5826b6-8994-4779-814f-0d36a1adafcb',
      [Columns.ContentPiecesLiked]: 10,
      [Columns.ContentPiecesDisliked]: 5,
      [Columns.FeedPosts]: 2,
      [Columns.LivestreamChatsSent]: 3,
      [Columns.Comments]: 4,
      [Columns.Reactions]: 5,
      [Columns.LivestreamAttended]: 6
    };
  }

  getStaticFilters(): AllStaticFilters<Filter>[] {
    return [
      {
        name: 'timeFilter',
        label: 'Time Filter',
        type: StaticFilterType.DateTime,
        placeholder: 'Select a time period'
      },
      {
        name: 'includeArchivedUsers',
        label: 'Include Archived Users',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        isClearDisabled: true,
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' }
        ]
      }
    ];
  }

  getDynamicFilter(): DynamicFilter<Filter> | null {
    return null;
  }

  calculatePotentialRowCount(
    filter: Filter,
    audienceSize: number
  ): Promise<number> {
    return Promise.resolve(audienceSize);
  }

  async export(state: ReportBuilderWizardStoreState): Promise<void> {
    const { reportFilters: filter } = state;

    const standardFilter = await transformStateToDefaultFilters<Columns>(state);

    await apolloClient.mutate<
      ExportEngagementReportMutation,
      ExportEngagementReportMutationVariables
    >({
      mutation: ExportEngagementReportDocument,
      variables: {
        filter: {
          ...standardFilter,

          timeFilter: transformStaticDateToTimeRange(filter.timeFilter)
        }
      }
    });
  }
}
