import { useCallback } from 'react';
import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useOrganisationJoinWithInviteLinkMutation } from 'generated';
import {
  FormInput,
  FormPassword,
  TrackedForm,
  TrackedLink
} from '@tigerhall/components';
import { ApolloError } from '@apollo/client';

type Props = {
  onSuccess: (userCreds: { email: string; password: string }) => void;
};

type RouteParams = {
  orgId: string;
  token: string;
};

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export function InvitedForm({ onSuccess }: Props) {
  const { token } = useParams<RouteParams>();

  const [mutate] = useOrganisationJoinWithInviteLinkMutation({});

  const onSubmit = useCallback(
    async (values: FormData, { setError }) => {
      const user: FormData = { ...values };

      // we need to delete this so that we need don't it to the API
      // or the API will complain
      delete (user as Partial<FormData>).confirmPassword;

      try {
        await mutate({
          variables: {
            token: token as string,
            user
          }
        });

        onSuccess({
          email: user.email,
          password: user.password
        });
      } catch (e) {
        if (e instanceof ApolloError) {
          e.graphQLErrors.forEach((graphQLError) => {
            if (
              (
                graphQLError.extensions?.email as
                  | { occupied?: boolean }
                  | undefined
              )?.occupied
            ) {
              setError('email', {
                type: 'manual',
                message: 'Email is already in use'
              });
            }
          });
        }
      }
    },
    [mutate, onSuccess, token]
  );

  return (
    <TrackedForm name="joinOrgByLink" onSubmit={onSubmit}>
      {({ formState: { isSubmitting } }) => (
        <>
          <Flex direction="column" gap="1rem">
            <Heading
              fontSize="3xl"
              color="white"
              fontWeight="700"
              textAlign="center"
            >
              You&apos;re invited
            </Heading>
            <Text fontSize="md" color="white" textAlign="center" mb="2.5rem">
              This is the first step to achieving your life and career goals.
              See you on the inside!
            </Text>
            <FormInput
              placeholder="First name"
              name="firstName"
              label="Enter your first name"
              autoComplete="given-name"
              rules={{
                required: true,
                minLength: 2
              }}
            />
            <FormInput
              name="lastName"
              label="Enter your last name"
              placeholder="Last name"
              autoComplete="family-name"
              rules={{
                required: true,
                minLength: 2
              }}
            />
            <FormInput
              name="email"
              type="email"
              placeholder="Email"
              autoComplete="email"
              rules={{
                required: true
              }}
              label="Enter your email"
            />
            <FormInput
              name="company"
              type="text"
              label="Company"
              placeholder="Enter Company"
              autoComplete="company"
              rules={{
                required: true
              }}
            />
            <FormPassword
              name="password"
              label="Create a password"
              placeholder="Enter Password"
              autoComplete="new-password"
              rules={{
                required: true,
                minLength: {
                  value: 8,
                  message: 'The password must be at least 8 characters'
                }
              }}
            />

            <Button
              type="submit"
              width="100%"
              size="lg"
              isLoading={isSubmitting}
              mt="2.5rem"
            >
              Sign Up
            </Button>
            <Text fontSize="sm" textAlign="center">
              By signing up, you agree to our
              <TrackedLink
                name="termAndConditions"
                ml={1}
                color="tigerOrange.600"
                href="/terms-of-service"
                isExternal
              >
                Terms & Conditions.
              </TrackedLink>
            </Text>
          </Flex>
        </>
      )}
    </TrackedForm>
  );
}
