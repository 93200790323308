import { Box, Flex } from '@chakra-ui/react';
import { type User } from '@tigerhall/core/lib/types';
import {
  THLink,
  TigerhallSkeleton,
  Typography
} from '@tigerhall/ui-components';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import slice from 'lodash/slice';
import {
  useUserTopCategoriesQuery,
  useUserTopListenedExpertsQuery
} from 'generated';
import { IconChevronRight } from '@tigerhall/icons';
import { DummyCopies } from 'components/ui';
import { TrackedLink, UserStory } from '@tigerhall/components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Navigation } from 'swiper';

import NoDataTab from './NoDataTab';

type InterestTabType = {
  user: User;
};

const InterestTab = ({ user }: InterestTabType) => {
  let totalDuration = 0;

  const { data, loading } =
    useUserTopCategoriesQuery({
      variables: {
        id: user?.id
      },
      skip: !user?.id
    }) || {};

  forEach(slice(data?.user?.userTopCategories, 0, 5), (c) => {
    totalDuration += c?.duration || 0;
  });

  const { data: topExperts, loading: isLoadingTopExperts } =
    useUserTopListenedExpertsQuery({
      variables: {
        id: user?.id
      },
      skip: !user?.id
    });

  if (loading) {
    return null;
  }

  if (isEmpty(topExperts) && isEmpty(data)) {
    return <NoDataTab title="interest" />;
  }

  return (
    <>
      <Flex direction="column" pt="1.5rem">
        {(data?.user?.userTopCategories || [])?.length > 0 && (
          <>
            <Box pl="2rem">
              <Typography
                variant="heading4"
                color="lightGrey.200"
                fontWeight={700}
              >
                Content Ratings
              </Typography>
              <Typography
                variant="heading4"
                color="lightGrey.200"
                fontWeight={400}
                pt="0.25rem"
              >
                Time spent
              </Typography>
            </Box>

            <Flex direction="column" pl="2rem">
              {map(slice(data?.user?.userTopCategories, 0, 5), (cat) => {
                const duration = cat?.duration || 0;
                const category = cat?.category;

                return (
                  <>
                    <Flex mt={2}>
                      <Typography
                        variant="bodyXS"
                        color="darkGrey.400"
                        fontWeight={600}
                      >
                        {`${((duration / totalDuration) * 100)?.toFixed(0)}%`}
                      </Typography>
                      <THLink
                        name={category?.name || ''}
                        to={`/content/category/${category?.id}`}
                      >
                        <Flex
                          p={2}
                          bgColor="orange.600"
                          h="26px"
                          borderRadius="6px"
                          alignItems="center"
                          ml={2}
                          cursor="pointer"
                          textAlign="center"
                        >
                          <Typography
                            variant="detail3"
                            fontWeight={700}
                            color="white"
                            mr={1}
                            w="100%"
                          >
                            {category?.name}
                          </Typography>
                          <IconChevronRight />
                        </Flex>
                      </THLink>
                    </Flex>
                  </>
                );
              })}
            </Flex>
          </>
        )}
        <Box pt="1.5rem" pl="2rem">
          <Typography
            variant="heading4"
            mb={4}
            color="lightGrey.200"
            fontWeight={700}
          >
            By Thinkfluencers
          </Typography>
          <Typography
            variant="heading5"
            color="lightGrey.200"
            fontWeight={400}
            pt="0.25rem"
            mb={2}
          >
            Most viewed top 10
          </Typography>
        </Box>
        <Box
          css={{
            '&>div>div': {
              marginLeft: 0
            }
          }}
          mt={2}
        >
          <Swiper
            modules={[Mousewheel, Navigation]}
            spaceBetween={16}
            slidesPerView="auto"
            mousewheel={{
              forceToAxis: true,
              sensitivity: 1.5
            }}
            className="assignments-carousel"
            style={{
              paddingLeft: '2rem',
              paddingRight: '2rem',
              paddingBottom: '1rem'
            }}
          >
            {isLoadingTopExperts ? (
              <DummyCopies count={10}>
                <Box w={150} h={260}>
                  <TigerhallSkeleton variant="stream_story__light" />
                </Box>
              </DummyCopies>
            ) : (
              map(
                slice(topExperts?.user?.userTopListenedExperts, 0, 10),
                (u) => (
                  <SwiperSlide
                    key={u.id}
                    style={{
                      width: 'fit-content',
                      cursor: 'pointer'
                    }}
                  >
                    <TrackedLink
                      name="USER_AVATAR"
                      href={`/profile/${u.id}`}
                      key={u.id}
                    >
                      <UserStory
                        user={{
                          id: u.id,
                          firstName: u.firstName,
                          lastName: u.lastName,
                          jobTitle: u.jobTitle,
                          company: u.company ?? '',
                          image: u.avatarImage ?? { uri: '' }
                        }}
                      />
                    </TrackedLink>
                  </SwiperSlide>
                )
              )
            )}
          </Swiper>
        </Box>
      </Flex>
    </>
  );
};

export default InterestTab;
