import { Icon } from '@chakra-ui/react';
import { IconHelpCircle } from '@tigerhall/icons';
import { TrackedLink } from '@tigerhall/components';
import { SUPPORT_URL } from '@tigerhall/core';

export function Support() {
  return (
    <TrackedLink
      name="SUPPORT"
      href={SUPPORT_URL}
      isExternal
      as="button"
      aria-label="Support"
      borderRadius="lg"
      variant="unstyled"
      display="flex"
      alignItems="center"
      p=".5rem"
      _active={{
        background: 'darkGrey.400'
      }}
      _hover={{
        background: 'darkGrey.400'
      }}
    >
      <Icon
        as={IconHelpCircle}
        color="lightGrey.400"
        height="1.5rem"
        width="1.5rem"
      />
    </TrackedLink>
  );
}
