import { Button, Flex, Heading } from '@chakra-ui/react';
import { FormSelect } from '@tigerhall/components';
import { StreamStatus } from '@tigerhall/core';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useChangeBroadcastStatusMutation } from '../../../../../queries/generated';

export function FormSectionChangeStatus() {
  const [isLoading, setIsLoading] = useState(false);
  const [changeStatus] = useChangeBroadcastStatusMutation({});

  const { getValues } = useFormContext();

  const onClick = useCallback(async () => {
    setIsLoading(true);

    const { id, status } = getValues();

    await changeStatus({
      variables: {
        id,
        status: status.value
      }
    });

    setIsLoading(false);
  }, [changeStatus, getValues]);

  return (
    <Flex
      direction="column"
      backgroundColor="darkGrey.400"
      p="1rem"
      border="1px solid"
      borderColor="darkGrey.200"
      borderRadius="lg"
      gap="1rem"
    >
      <Heading>Change Status</Heading>
      <FormSelect
        name="status"
        label="Broadcast status"
        placeholder=""
        getOptionLabel={(o) => o.label}
        getOptionValue={(o) => o.value}
        options={[
          {
            label: 'SCHEDULED',
            value: StreamStatus.Created
          },
          {
            label: StreamStatus.Backstage,
            value: StreamStatus.Backstage
          },
          {
            label: StreamStatus.Live,
            value: StreamStatus.Live
          },
          {
            label: StreamStatus.Finished,
            value: StreamStatus.Finished
          }
        ]}
      />

      <Button type="button" onClick={onClick} isLoading={isLoading}>
        Change status
      </Button>
    </Flex>
  );
}
