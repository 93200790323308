import { Box, Button, Divider, Flex, Grid, GridItem } from '@chakra-ui/react';
import {
  FormDateTimePicker,
  FormImageUpload,
  FormInput,
  TrackedForm
} from '@tigerhall/components';
import { Link, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { UseFormReturn } from 'react-hook-form';
import * as React from 'react';
import {
  CategoriesSelect,
  ExpertsSelect,
  FormAdditionalInfo,
  OrganisationGroupSelect
} from 'components/form';
import { FullWidthBox } from 'components/ui';
import { EditorForm } from 'components/ui/lexical/form/EditorForm';
import { useGalleryImageUpload } from 'hooks';
import { FetchStreamQuery } from 'generated';

import { FormAgenda } from './components/FormAgenda';
import { FormSectionBroadcastDetails } from './components/FormSectionBroadcastDetails';
import { FormSectionChangeStatus } from './components/FormSectionChangeStatus';
import TaskSuccessComponent from '../../../../../../../../components/ui/TaskSuccessComponent';

export interface FormValues
  extends Pick<
    NonNullable<FetchStreamQuery['stream']>,
    | 'name'
    | 'preamble'
    | 'image'
    | 'experts'
    | 'categories'
    | 'organisationGroups'
    | 'contentSegments'
    | 'agenda'
  > {
  publishedAt: Date;
  broadcast: File;
}

interface Props {
  isDraft: boolean;

  onSubmit: (values: FormValues, form: UseFormReturn<FormValues>) => void;

  defaultValues: Partial<FormValues>;
  includePublicExperts?: boolean;
}

export function Form({
  isDraft,
  onSubmit,
  defaultValues,
  includePublicExperts
}: Props) {
  const navigate = useNavigate();
  const upload = useGalleryImageUpload();

  const buttons = () => [
    {
      variant: 'outline',
      onClick: () => {
        navigate(-1);
      },
      text: 'Cancel',
      id: 'cancel'
    },
    {
      type: 'submit',

      text: isDraft ? 'Create' : 'Update',
      id: 'create'
    }
  ];

  return (
    <TrackedForm
      name="CREATE_BROADCAST"
      onSubmit={onSubmit}
      formHookProps={{ defaultValues }}
    >
      {({ formState }) => {
        if (formState.isSubmitSuccessful && isDraft) {
          return (
            <TaskSuccessComponent description="You've created a new Broadcast">
              <Link to="/admin/broadcasts">
                <Button size="lg">Return to Overview</Button>
              </Link>
            </TaskSuccessComponent>
          );
        }

        return (
          <FullWidthBox
            title={isDraft ? 'New Broadcast' : 'Update Broadcast Details'}
            subTitle="Broadcast Details"
            buttons={buttons()}
          >
            <Grid templateColumns="repeat(3, 1fr)">
              <GridItem colSpan={2} pr="8" borderRight="1px solid #474747">
                <Grid gap="4" templateColumns="repeat(2, 1fr)">
                  <FormInput
                    name="name"
                    label="Broadcast Title"
                    placeholder="Enter broadcast title"
                    rules={{
                      required: 'Please select the broadcast title',
                      minLength: {
                        value: 3,
                        message: 'Broadcast title must be at least 3 characters'
                      },
                      maxLength: {
                        value: 85,
                        message: 'Broadcast title must be at most 85 characters'
                      }
                    }}
                  />

                  <ExpertsSelect
                    name="experts"
                    isMulti
                    required
                    includePublicExperts={includePublicExperts}
                  />
                  <CategoriesSelect name="categories" isMulti required />
                  <OrganisationGroupSelect name="organisationGroups" isMulti />
                </Grid>

                <Box mt="4">
                  <EditorForm
                    name="preamble"
                    label="Preamble"
                    placeholder="Add a 2-3 sentence description that tells people what this broadcast is about, topics that are covered, why they should watch it, and who it is by. "
                    rules={{
                      required: 'Please add a preamble'
                    }}
                    showHeadings={false}
                  />

                  <FormAdditionalInfo title="Additional Information" />

                  <Divider my="8" />

                  <FormAgenda />
                </Box>
              </GridItem>

              <GridItem pl="8">
                <Flex direction="column" gap="1rem">
                  {!isDraft && (
                    <>
                      <FormSectionBroadcastDetails />
                      <FormSectionChangeStatus />
                    </>
                  )}

                  <FormImageUpload
                    name="image"
                    label="Cover Image"
                    hint="(Minimum 800x600px resolution)"
                    upload={upload}
                    showResetButton={false}
                    rules={{
                      required: 'Please upload a cover image'
                    }}
                  />
                </Flex>
              </GridItem>
            </Grid>
          </FullWidthBox>
        );
      }}
    </TrackedForm>
  );
}
