import { useState } from 'react';
import { IconMessageReport } from '@tigerhall/icons';
import { OptionsMenuItem } from 'components/ui/OptionsMenu';
import { ReportModal } from 'components/ui/modals';
import * as segment from '@tigerhall/analytics';

export interface ReportPostOptionProps {
  postId: string;
}

export function ReportPostOption({ postId }: ReportPostOptionProps) {
  const [shouldOpenReportModal, setShouldOpenReportModal] = useState(false);

  return (
    <>
      <OptionsMenuItem
        icon={IconMessageReport}
        title="Report Post"
        description="Report an offensive post for review"
        onClick={() => {
          segment.postOptionsClicked({
            referenceId: postId,
            buttonName: 'report_post'
          });
          setShouldOpenReportModal(true);
        }}
      />
      <ReportModal
        isOpen={shouldOpenReportModal}
        onClose={() => {
          setShouldOpenReportModal(false);
        }}
        contentId={postId}
        contentTypeName="Post"
        heading="Report Post"
        subheading="Please choose the relevant reason for reporting this post"
      />
    </>
  );
}
