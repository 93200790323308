import { captureException } from '@sentry/react';
import {
  PowerRead,
  type PlayerModalProps,
  type PowerReadProps
} from '@tigerhall/components';
import { ContentVote } from '@tigerhall/core';
import {
  getContentUrl,
  getIsLastContentInPlaylist,
  getIsTemporarilyHiddenAndPaused,
  getPlaylistId,
  getPlaylistTitle,
  getPlaylistType,
  setAutoNextPlayToggle
} from 'app/state';
import { RichTextMarkdownWrapper } from 'components/ui/MarkdownWrapper/RichTextMarkdownWrapper';
import {
  useAppDispatch,
  useAppSelector,
  useFreeAccount,
  useNavigationListener
} from 'hooks';
import { useContentActions } from 'modules/application/hooks/useContentActions';
import { useCallback, useMemo } from 'react';

import { GetEbookForConnectedPowerReadPlayerQuery } from '../queries';

export interface PowerReadPlayerProps
  extends Pick<PowerReadProps, 'chapters' | 'audio'> {
  /**
   * Power Read information
   */
  powerRead: NonNullable<GetEbookForConnectedPowerReadPlayerQuery['ebook']>;
  /**
   * Function to be called when the transcript language changes
   */
  setContentLanguage: PowerReadProps['onChangeMediaLanguage'];
  /**
   * The currently selected transcript language
   */
  contentLanguage: PowerReadProps['selectedMediaLanguage'];
  /**
   * Function to be called when the audio language changes
   */
  setAudioLanguage: PowerReadProps['onChangeTranscriptLanguage'];
  /**
   * The currently selected audio language
   */
  onPageChange: PowerReadProps['onPageChange'];
  audioLanguage: PowerReadProps['selectedTranscriptLanguage'];
  onFinished: PowerReadProps['onFinished'];
  onClose: PlayerModalProps['onMinimize'];
  onMaximize: PowerReadProps['onMaximize'];
  isMinimized: PowerReadProps['isMinimized'];
  setIsMinimized: (value: boolean) => void;
}

export function PowerReadPlayer({
  powerRead,
  chapters,
  audio,
  onFinished,
  setAudioLanguage,
  audioLanguage,
  setContentLanguage,
  contentLanguage,
  onMaximize,
  isMinimized,
  setIsMinimized,
  onPageChange,
  onClose
}: Readonly<PowerReadPlayerProps>) {
  const {
    playlistId,
    playlistType,
    playlistTitle,
    isLastContentInPlaylist,
    isHidden,
    contentUrl
  } = useAppSelector((state) => ({
    playlistId: getPlaylistId(state),
    playlistType: getPlaylistType(state),
    playlistTitle: getPlaylistTitle(state),
    isLastContentInPlaylist: getIsLastContentInPlaylist(state),
    isHidden: getIsTemporarilyHiddenAndPaused(state),
    contentUrl: getContentUrl(state)
  }));

  const { rate, trackProgress, trackFinished } = useContentActions();

  const { isInPreviewMode } = useFreeAccount();

  useNavigationListener(() => {
    if (!isMinimized) {
      setIsMinimized(true);
    }
  });

  const meta: PowerReadProps['meta'] = useMemo(
    () => ({
      contentId: powerRead.id,
      contentName: powerRead.name,
      casting: powerRead.experts
        .map((expert) => `${expert.firstName} ${expert.lastName}`)
        .join(', ')
    }),
    [powerRead]
  );

  const onLike = useCallback(() => {
    rate(powerRead, ContentVote.Upvote).catch((error) =>
      captureException(error)
    );
  }, [rate, powerRead]);

  const onDislike = useCallback(() => {
    rate(powerRead, ContentVote.Downvote).catch((error) =>
      captureException(error)
    );
  }, [rate, powerRead]);

  const onContentLanguageChange = useCallback(
    (code: string) => setContentLanguage(code),
    [setContentLanguage]
  );

  const onAudioLanguageChange = useCallback(
    (code: string | null) => setAudioLanguage(code),
    [setAudioLanguage]
  );

  const onTrackProgress = useCallback(
    (progress: number) => {
      trackProgress({
        content: powerRead,
        progress,
        playlist: { playlistId, playlistType }
      }).catch((error) => captureException(error));
    },
    [powerRead, trackProgress]
  );

  const onTrackFinished = useCallback(() => {
    trackFinished(powerRead).catch((error) => captureException(error));
  }, [powerRead, trackFinished]);

  const isFinished = powerRead.userContentTracking.isFinished;

  const dispatch = useAppDispatch();
  function onToggleAutoPlayNext(value: boolean) {
    dispatch(setAutoNextPlayToggle(value));
  }

  const previewModeProps = isInPreviewMode
    ? { customPagesTotal: powerRead?.pagesCount, customDuration: audio?.length }
    : {};

  return (
    <PowerRead
      chapters={chapters}
      audio={audio}
      poster={powerRead.image?.uri ?? ''}
      meta={meta}
      previousProgress={powerRead.userContentTracking.currentPlacement}
      onTrackProgress={onTrackProgress}
      onTrackFinished={onTrackFinished}
      onFinished={onFinished}
      isFinished={isFinished}
      isHidden={isHidden}
      isMinimized={isMinimized}
      // content - primary source of content
      mediaLanguages={powerRead.availableLanguages}
      selectedMediaLanguage={contentLanguage}
      onChangeMediaLanguage={onContentLanguageChange}
      // audio - alternative source of content
      transcriptLanguages={powerRead.audioAvailableLanguages}
      selectedTranscriptLanguage={audioLanguage}
      onChangeTranscriptLanguage={onAudioLanguageChange}
      vote={powerRead.contentRatingByUser}
      onLike={onLike}
      onDislike={onDislike}
      onClose={() => {
        onClose();
        setIsMinimized(false);
      }}
      onMaximize={onMaximize}
      contentUrl={contentUrl}
      playlistType={playlistType}
      playlistTitle={playlistTitle}
      playlistId={playlistId}
      onAutoNextPlay={onToggleAutoPlayNext}
      isLastContentInPlaylist={isLastContentInPlaylist}
      onPageChange={onPageChange}
      {...previewModeProps}
    >
      {({ theme, fontSize, content = '' }) => (
        <RichTextMarkdownWrapper
          isDarkMode={theme === 'dark'}
          fontSize={fontSize}
        >
          {content}
        </RichTextMarkdownWrapper>
      )}
    </PowerRead>
  );
}
