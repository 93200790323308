import {
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack
} from '@chakra-ui/react';
import { useRef, useState } from 'react';

import { TextTime } from './components/TextTime';
import { useMediaPlayerContext, useMediaState } from '~/player/context';

export function ProgressSlider() {
  const { seekTo } = useMediaPlayerContext();

  const [progress, duration, customDuration] = useMediaState((state) => [
    state.progress,
    state.duration,
    state.customDuration
  ]);

  const max = customDuration || duration;

  const isSeeking = useRef(false);
  const [position, setSeekPosition] = useState(progress);

  return (
    <Flex direction="column" gap="0.75rem" width="100%">
      <Slider
        aria-label="podcast progress bar"
        defaultValue={progress}
        value={isSeeking.current ? position : progress}
        min={0}
        max={max}
        onChangeStart={() => {
          isSeeking.current = true;
        }}
        onChange={(val) => {
          setSeekPosition(val);
        }}
        onChangeEnd={(val) => {
          seekTo(val);
          isSeeking.current = false;
        }}
        focusThumbOnChange={false}
      >
        <SliderTrack bg="darkGrey.300">
          <SliderFilledTrack bg="tigerOrange.600" />
        </SliderTrack>
        <SliderThumb bg="tigerOrange.600" />
      </Slider>
      <Flex justifyContent="space-between">
        <TextTime time={isSeeking.current ? position : progress} />
        <TextTime time={max} />
      </Flex>
    </Flex>
  );
}
