import { Flex, HStack, Image, Stack, Text } from '@chakra-ui/react';
import { resizeImage } from '@tigerhall/core';
import { type User } from '@tigerhall/core/lib/types';

import { ProfileActions } from '~/modules/application/modules/Profile/screens/Profile/components/ProfileActions';
import { NameWithBadge } from '~/components/ui/User/NameWithBadge';

type ProfileInfoProps = {
  user: User;
  isCurrentUser: boolean;
};

export function ProfileInfo({
  user,
  isCurrentUser
}: Readonly<ProfileInfoProps>) {
  const { avatarImage, firstName, lastName, jobTitle, company } = user || {};

  const fullName = `${firstName} ${lastName}`;

  return (
    <Stack spacing="1rem">
      <Flex justify="space-between" mt="-5rem">
        <Image
          src={
            avatarImage?.uri
              ? resizeImage({
                  url: avatarImage?.uri,
                  width: 140,
                  height: 140
                })
              : ''
          }
          alt={`${fullName} from ${company}`}
          objectFit="cover"
          boxSize="140px"
          borderWidth=".25rem"
          borderStyle="solid"
          borderColor="darkGrey.300"
          borderRadius="full"
        />
        <HStack
          id="profile-actions"
          alignItems="flex-end"
          pt="1.5rem"
          spacing=".75rem"
        >
          <ProfileActions isSelfProfile={isCurrentUser} user={user} />
        </HStack>
      </Flex>
      <Flex direction="column" gap=".25rem">
        <NameWithBadge
          userId={user.id}
          disableLink
          spacing={0}
          badgeProps={{
            displayLabelAs: 'text',
            size: 'lg'
          }}
          fontSize="xl"
          color="lightGrey.200"
          fontWeight="bold"
        />
        <Text
          fontSize="sm"
          isTruncated
          color="lightGrey.600"
          fontWeight="medium"
        >
          {jobTitle || ''}
        </Text>
        <Text color="orange.600" fontSize="sm" fontWeight="medium">
          {company || ''}
        </Text>
      </Flex>
    </Stack>
  );
}
