import * as React from 'react';
import {
  Box,
  Center,
  Divider,
  Flex,
  Icon,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react';
import { IconCircleCheckFilled } from '@tigerhall/icons';
import {
  type SubscriptionType as StripeSubscriptionType,
  getStripePaymentGatewayUrl
} from '@tigerhall/core';
import { TrackedLink } from '@tigerhall/components';
import { getAccessToken, getCustomerType } from 'app/state';
import { useAppSelector } from 'hooks';
import { AppError } from 'utils';

import { type SubscriptionPlan, type SubscriptionType } from '../types';

interface PlansTableProps {
  plans: SubscriptionPlan[];
}

/**
 * This component should NOT be used for mobile devices because
 * it is not responsive. Should be only used for desktop devices.
 * There is a separate component for mobile devices.
 */
export function PlansTable({ plans }: PlansTableProps) {
  const token = useAppSelector(getAccessToken) || '';
  const customerType =
    (useAppSelector(
      getCustomerType
    )?.toLocaleLowerCase() as StripeSubscriptionType) || '';

  /**
   * This function groups the plans features by subscription type
   */
  const getAvailableOn = (subscriptionType: SubscriptionType) => {
    switch (subscriptionType) {
      case 'Individuals':
        return ['Individuals', 'Enterprises'];
      case 'Enterprises':
        return [null, 'Enterprises'];
      default:
        throw new AppError('Invalid subscription type.', {
          extra: {
            subscriptionType
          }
        });
    }
  };

  /**
   * This is an array of all the subscription features and the plan
   * that includes it.
   */
  const features = plans
    .map((plan) =>
      plan.features.map((feature) => ({
        description: feature,
        availableOn: plan.type
      }))
    )
    .flat();

  const getShouldRedirectToStripe = (subscriptionType: SubscriptionType) =>
    customerType && subscriptionType.toLowerCase().includes(customerType);

  return (
    <VStack
      width="100%"
      maxWidth="76rem"
      background="#151618"
      borderWidth={1}
      borderColor="#464A4D"
      borderRadius="1.25rem"
      p="1.5rem"
      spacing="1rem"
    >
      <SimpleGrid
        width="100%"
        alignItems="start"
        columns={plans.length + 1}
        gridTemplateColumns={'1.5fr repeat(2, minmax(0px, 1fr))'}
        spacing="2rem"
      >
        <Text
          color="white"
          fontWeight="bold"
          fontSize="1.5rem"
          textAlign="start"
        >
          Features
        </Text>
        {plans.map((plan) => (
          <Flex
            key={plan.heading}
            height="100%"
            gap="0.25rem"
            flexDirection="column"
            alignItems="center"
          >
            <Text
              as="span"
              color="#FF5900"
              fontWeight="bold"
              fontSize="1.5rem"
              lineHeight="1.3"
              textAlign="start"
            >
              {plan.heading}
            </Text>
            <Text
              as="span"
              color="white"
              fontWeight="bold"
              fontSize="sm"
              lineHeight="1.3"
              textAlign="start"
            >
              {plan.subheading}
            </Text>
            {plan?.description ? (
              <Text color="lightGrey.900" fontSize="sm" textAlign="start">
                {plan.description}
              </Text>
            ) : null}
            {plan?.cta?.link?.href ? (
              <Box mt="auto" pt="1.25rem" width="100%">
                {getShouldRedirectToStripe(plan.type) ? (
                  <TrackedLink
                    as="button"
                    name="upgradeToIndividual"
                    href={getStripePaymentGatewayUrl({
                      origin: import.meta.env.VITE_API_URL || '',
                      token,
                      subscriptionType: customerType,
                      successRedirectUrl: `${
                        import.meta.env.VITE_WEBAPP_URL
                      }/signup/${customerType}/confirmation`,
                      cancelRedirectUrl: window.location.href
                    })}
                    isExternal={true}
                    w="100%"
                    size="lg"
                    variant={plan?.cta?.theme || 'solid'}
                    data-cy={`paywall-${plan?.type.toLowerCase()}-link-desktop`}
                  >
                    Subscribe Now
                  </TrackedLink>
                ) : (
                  <TrackedLink
                    as="button"
                    name={plan?.cta?.link?.label}
                    href={plan?.cta?.link?.href}
                    isExternal={plan?.cta?.link?.isExternal}
                    w="100%"
                    size="lg"
                    variant={plan?.cta?.theme || 'solid'}
                    data-cy={`paywall-${plan?.type.toLowerCase()}-link-desktop`}
                  >
                    {plan?.cta?.link?.label}
                  </TrackedLink>
                )}
              </Box>
            ) : null}
          </Flex>
        ))}
      </SimpleGrid>
      {features?.map((feature) => {
        const availableOn = getAvailableOn(feature.availableOn);
        return (
          <React.Fragment key={feature.description}>
            <Divider />
            <SimpleGrid
              width="100%"
              alignItems="center"
              columns={plans.length + 1}
              gridTemplateColumns={'1.5fr repeat(2, minmax(0px, 1fr))'}
              spacing="2rem"
            >
              <Text color="white" fontSize="md">
                {feature.description}
              </Text>
              {availableOn.map((plan, index) =>
                plan ? (
                  <Center key={`${feature.description}${plan}${index}`}>
                    <Center
                      background="white"
                      rounded="full"
                      width="1.25rem"
                      height="1.25rem"
                      borderColor="#008083"
                      borderWidth="2px"
                    >
                      <Icon
                        color="#008083"
                        width="1.25rem"
                        height="1.25rem"
                        as={IconCircleCheckFilled}
                      />
                    </Center>
                  </Center>
                ) : (
                  <Center key={`${feature.description}${plan}${index}`} />
                )
              )}
            </SimpleGrid>
          </React.Fragment>
        );
      })}
    </VStack>
  );
}
