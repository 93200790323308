import { useMemo } from 'react';
import { captureException } from '@sentry/react';

import { useAppSelector } from '../redux';
import { getPlaylistId, getPlaylistType } from '~/app/state';
import type {
  AssignmentContentsData,
  PlaylistContent,
  TrailContentsData
} from '../useAutoplayContent/types';
import {
  useGetAssignmentContentsByIdQuery,
  useGetTrailContentsByIdQuery
} from './queries';

export function useGetUpNextQueueItems() {
  const playlistType = useAppSelector(getPlaylistType);
  const playlistId = useAppSelector(getPlaylistId);
  const { data: assignmentContentList, loading: isLoadingAssignment } =
    useGetAssignmentContentsByIdQuery({
      skip: playlistType !== 'Assignment',
      variables: { id: playlistId ?? '' },
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
        captureException(error, {
          tags: {
            playlistType,
            playlistId
          }
        });
      }
    });

  const { data: trailContentList, loading: isLoadingTrail } =
    useGetTrailContentsByIdQuery({
      skip: playlistType !== 'Trail',
      variables: { id: playlistId ?? '' },
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
        captureException(error, {
          tags: {
            playlistType,
            playlistId
          }
        });
      }
    });

  const queueItems: PlaylistContent[] = useMemo(() => {
    switch (playlistType) {
      case 'Assignment': {
        return (
          (assignmentContentList as AssignmentContentsData)?.assignment
            ?.contents.edges ?? []
        );
      }

      case 'Trail': {
        return (
          (trailContentList as TrailContentsData)?.learningPath?.contentCards ??
          []
        );
      }

      default: {
        return [];
      }
    }
  }, [assignmentContentList, playlistType, trailContentList]);

  return {
    isLoading: isLoadingAssignment || isLoadingTrail,
    queueItems
  };
}
