import { Expert, ExpertFilter } from '@tigerhall/core/lib/types';
import { FormSelectAsync } from '@tigerhall/components';
import { useFetchExpertsLazyQuery } from 'generated';
import property from 'lodash/property';
import * as React from 'react';
import { getSelectedOrgId } from 'app/state';
import { useAppSelector } from 'hooks';

interface Props {
  name: string;
  placeholder?: string;
  customLabel?: string;

  isMulti?: boolean;
  isClearable?: boolean;

  required?: boolean;
  includePublicExperts?: boolean;
}

export function ExpertsSelect({
  name,
  required = false,
  isMulti = false,
  isClearable = false,
  includePublicExperts = false,
  customLabel,
  placeholder
}: Props) {
  const org = useAppSelector(getSelectedOrgId);

  const DEFAULT_FILTER: ExpertFilter = {
    organisationID: includePublicExperts ? [] : [org as string],
    limit: 20
  };

  const [fetchExperts, { data, error, loading }] = useFetchExpertsLazyQuery({
    variables: {
      filter: DEFAULT_FILTER
    }
  });

  React.useEffect(() => {
    fetchExperts();
  }, [fetchExperts]);

  if (error) {
    return null;
  }

  const label = customLabel ?? (isMulti ? 'Thinkfluencers' : 'Thinkfluencer');

  return (
    <FormSelectAsync
      name={name}
      label={label}
      isMulti={isMulti}
      isClearable={isClearable}
      placeholder={placeholder ?? 'Select thinkfluencer'}
      getOptionLabel={(e) => (e ? `${e.firstName} ${e.lastName}` : '')}
      getOptionValue={property('id')}
      isLoading={loading}
      defaultOptions={data?.experts.edges as Expert[]}
      loadOptions={(inputValue, callback) => {
        fetchExperts({
          variables: {
            filter: {
              ...DEFAULT_FILTER,
              name: inputValue
            }
          }
        }).then(({ data: newResults }) => {
          callback(newResults?.experts.edges as Expert[]);
        });
      }}
      rules={{
        required: required ? 'Please select a thinkfluencer' : false
      }}
    />
  );
}
