import { Checkbox, FormControl, Text } from '@chakra-ui/react';
import * as React from 'react';
import {
  Controller,
  type UseFormReturn,
  useFormContext
} from 'react-hook-form';

type FormCheckboxProps = {
  name: string;
  text: string;
  isDisabled?: boolean;
  isRequired?: boolean;
};

export function FormCheckbox({
  name,
  isDisabled,
  isRequired,
  text
}: FormCheckboxProps) {
  const methods = useFormContext() as UseFormReturn & {
    formName: string;
  };

  if (methods === null) {
    return <Text color="state.error">Missing form context</Text>;
  }

  if (!methods.formName) {
    return <Text color="state.error">Missing form name</Text>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field }) => (
        <FormControl isRequired={isRequired} isDisabled={isDisabled}>
          <Checkbox
            size="lg"
            borderColor="white"
            alignItems="flex-start"
            onChange={field.onChange}
            name={field.name}
            isChecked={field.value}
          >
            <Text fontSize="sm" color="lightGrey.600">
              {text}
            </Text>
          </Checkbox>
        </FormControl>
      )}
    />
  );
}
