import {
  UserAssignmentStatisticsReportColumns as Columns,
  CompletionStatus,
  type ConsumptionFlag,
  type UserAssignmentStatisticsReportFilter as Filter
} from '@tigerhall/core';
import { type GenericRow } from '@tigerhall/components';

import {
  type AllStaticFilters,
  type DynamicFilter,
  type ReportColumn,
  type ReportTemplate,
  StaticFilterType
} from '../types';
import {
  ExportUserAssignmentStatisticsReportDocument,
  type ExportUserAssignmentStatisticsReportMutation,
  type ExportUserAssignmentStatisticsReportMutationVariables,
  GetOrgAssignmentsTitlesDocument,
  type GetOrgAssignmentsTitlesQuery,
  type GetOrgAssignmentsTitlesQueryVariables
} from '~/modules/admin/modules/reportBuilder/queries';
import { apolloClient } from '~/api/apollo';
import { type ReportBuilderWizardStoreState } from '~/modules/admin/modules/reportBuilder/stores';
import {
  extractStaticDropDownValue,
  transformStateToDefaultFilters
} from '~/modules/admin/modules/reportBuilder/utils';

export class UserAssignmentStatisticsReport implements ReportTemplate<Filter> {
  getName(): string {
    return 'User Assignment Statistics';
  }

  getDescription(): string {
    return 'Tracks progress, completion and time spent by users on assignments.';
  }

  isEnabled(): boolean {
    return true;
  }

  getColumns(): ReportColumn[] {
    return [
      {
        id: Columns.UserName,
        name: 'Username'
      },
      {
        id: Columns.Email,
        name: 'Email'
      },
      {
        id: Columns.TigerhallUserId,
        name: 'Tigerhall User ID'
      },
      {
        id: Columns.AssignmentName,
        name: 'Assignment Name'
      },
      {
        id: Columns.AssignmentId,
        name: 'Assignment ID'
      },
      {
        id: Columns.AssignmentCompletedDate,
        name: 'Assignment Completed Date'
      },
      {
        id: Columns.AssignmentCompletionStatus,
        name: 'Assignment Completion Status',
        description: 'Whether the user has completed the assignment or not'
      },
      {
        id: Columns.NumberOfContentPiecesCompletedByTheUser,
        name: 'Number of Content Pieces Completed by the User',
        description: 'The number of content pieces completed by the user'
      },
      {
        id: Columns.TotalNumberOfContentPiecesInTheAssignment,
        name: 'Total Number of Content Pieces in the Assignment',
        description: 'Total number of content pieces in the assignment'
      },
      {
        id: Columns.AssignmentProgressPercentageBasedOnContentPiece,
        name: 'Assignment Progress % (calculated at content piece)',
        description:
          'The number of content pieces the user has completed out of the total number of pieces in the assignment'
      },
      {
        id: Columns.AssignmentProgressPercentageBasedOnContentLength,
        name: 'Assignment Progress % (calculated at content length)',
        description: `Progress based on the consumption of the total assignment length. Shows 100% if the user has fully consumed all the pieces in the assignment. Any value less than 100% shows the user's total furthest progress of all pieces in the assignment.`
      },
      {
        id: Columns.AssignmentLength,
        name: 'Assignment Length',
        description: 'Estimated assignment length'
      },
      {
        id: Columns.AssignmentTimeSpent,
        name: 'Assignment Time Spent',
        description: 'Time spent by the user in assignment'
      },
      {
        id: Columns.AssignmentConsumptionFlag,
        name: 'Assignment Speed Runner',
        description:
          'Assignment is flagged if any of the content pieces are flagged. Content is flagged if the content is marked as completed and the time spent is less than half the estimated content length'
      }
    ];
  }

  getSampleData(): Record<string, string> {
    return {
      [Columns.UserName]: 'jane smith',
      [Columns.Email]: 'janesmith@example.com',
      [Columns.TigerhallUserId]: '4ab7f537-e4c2-42f7-9301-21349de0bf42',
      [Columns.AssignmentName]: 'Assignment 1',
      [Columns.AssignmentId]: '4ab7f537-e4c2-42f7-9301-21349de0bf42',
      [Columns.AssignmentCompletedDate]: '4 Jun 2024',
      [Columns.AssignmentCompletionStatus]: 'Completed',
      [Columns.NumberOfContentPiecesCompletedByTheUser]: '10',
      [Columns.TotalNumberOfContentPiecesInTheAssignment]: '10',
      [Columns.AssignmentProgressPercentageBasedOnContentPiece]: '100%',
      [Columns.AssignmentProgressPercentageBasedOnContentLength]: '100%',
      [Columns.AssignmentLength]: '5 hours 10 minutes 20 seconds',
      [Columns.AssignmentTimeSpent]: '5 hours 10 minutes 20 seconds',
      [Columns.AssignmentConsumptionFlag]: 'Yes'
    };
  }

  calculatePotentialRowCount(
    filter: Filter,
    audienceSize: number
  ): Promise<number> {
    const assignments = filter.assignments as unknown as GenericRow[];

    return Promise.resolve(
      assignments.reduce((acc, assignment) => {
        return acc + assignment.users.meta.total;
      }, 0)
    );
  }

  getStaticFilters(): AllStaticFilters<Filter>[] {
    return [
      {
        name: 'completionStatus',
        label: 'Assignment Completion Status',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        isMulti: true,
        options: [
          { label: 'Completed', value: CompletionStatus.Completed },
          { label: 'In Progress', value: CompletionStatus.InProgress },
          { label: 'Not Started', value: CompletionStatus.NotStarted }
        ]
      },
      {
        name: 'consumptionFlag',
        label: 'Assignment Speed Runner',
        placeholder: 'Select Filter',
        tooltip:
          'This will allow you to filter on users that have completed the assignment in a unrealistic time and most likely skipped large parts of the content',
        type: StaticFilterType.DropDown,
        options: [
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' }
        ]
      },
      {
        name: 'includeArchivedUsers',
        label: 'Include Archived Users',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        isClearDisabled: true,
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' }
        ]
      }
    ];
  }

  getDynamicFilter(): DynamicFilter<Filter> | null {
    return {
      id: 'assignments',
      label: 'Assignments',
      description: '',
      columns: [
        {
          id: 'name',
          name: 'Name'
        },
        {
          id: 'assignedUsers',
          name: 'Assigned Users',
          accessor: (row) => {
            return row.users.meta.total;
          }
        },
        {
          id: 'status',
          name: 'Status'
        }
      ],
      rules: {
        required: 'You must select at least one assignment'
      },
      dataSource: {
        query: async (context, keywords, limit, offset) => {
          const response = await apolloClient.query<
            GetOrgAssignmentsTitlesQuery,
            GetOrgAssignmentsTitlesQueryVariables
          >({
            query: GetOrgAssignmentsTitlesDocument,
            variables: {
              id: context['organisationId'] as string,
              filter: {
                title: keywords,
                limit,
                offset
              },
              sorting: {
                sorting: [{ field: 'assignmentTitle', direction: 'asc' }]
              }
            },

            fetchPolicy: 'no-cache'
          });

          return {
            edges: response.data.organisation.assignments.edges,
            meta: response.data.organisation.assignments.meta
          };
        }
      }
    };
  }

  async export(state: ReportBuilderWizardStoreState): Promise<void> {
    const { reportFilters: filter } = state;

    const standardFilter = await transformStateToDefaultFilters<Columns>(state);

    await apolloClient.mutate<
      ExportUserAssignmentStatisticsReportMutation,
      ExportUserAssignmentStatisticsReportMutationVariables
    >({
      mutation: ExportUserAssignmentStatisticsReportDocument,
      variables: {
        filter: {
          ...standardFilter,

          assignments: filter.assignments.map((r: GenericRow) => r.id),

          consumptionFlag: extractStaticDropDownValue(
            filter.consumptionFlag
          ) as ConsumptionFlag,

          completionStatus: extractStaticDropDownValue(
            filter.completionStatus
          ) as CompletionStatus[]
        }
      }
    });
  }
}
