import { Box, Flex, ScaleFade } from '@chakra-ui/react';

import { ProgressSlider } from '~/player/components/ProgressSlider';
import { PlayPauseButton } from '~/player/components/PlayerControls';
import { usePowerReadState } from '~/player/PowerRead/context/usePowerReadState';

export function AudioBookPlayer() {
  const { isPlayingAudio } = usePowerReadState((state) => {
    return {
      isPlayingAudio: state.isPlayingAudio
    };
  });

  return (
    <Box
      position="sticky"
      bottom={0}
      margin="0 auto"
      width="100%"
      maxWidth="25rem"
      padding={4}
    >
      <ScaleFade in={isPlayingAudio} unmountOnExit>
        <Flex
          width="100%"
          padding={3}
          gap={4}
          borderRadius="2xl"
          backgroundColor="blackAlpha.800"
          backdropFilter="auto"
          backdropBlur="4px"
        >
          <PlayPauseButton size="sm" shouldBeOrange={true} />
          <ProgressSlider />
        </Flex>
      </ScaleFade>
    </Box>
  );
}
