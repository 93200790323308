import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { FeedContentType, REACTIONS } from '@tigerhall/core';
import { TableBodyFallback, TigerhallSkeleton } from '@tigerhall/ui-components';
import { useGetPostReactionsByIdQuery } from 'generated';
import { getPreviousRoute } from 'utils';

import { ReactionsTypeTabs } from './components/ReactionsTypeTabs';

export function ReactionsModal() {
  const navigate = useNavigate();
  const { postId = '' } = useParams();

  const { data, loading, error } = useGetPostReactionsByIdQuery({
    variables: {
      postId: postId,
      type: FeedContentType.Post
    }
  });

  function closeModal() {
    const { prevRoute, replace } = getPreviousRoute({
      fallback: '/feed'
    });

    if (typeof prevRoute === 'string') {
      navigate(prevRoute, { replace });
    } else {
      navigate(prevRoute);
    }
  }

  const { reactions = [], totalCount = 0 } =
    data?.feedContentById?.__typename === 'Post'
      ? data.feedContentById.reactions
      : {};

  const tabs = reactions.map((reaction) => ({
    type: reaction.type,
    label: REACTIONS[reaction.type].label,
    emoji: REACTIONS[reaction.type].emoji,
    count: reaction.count
  }));

  return (
    <Modal
      size="2xl"
      isOpen={true}
      onClose={closeModal}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent border="1px solid" borderColor="darkGrey.300">
        <ModalHeader color="lightGrey.200">Reactions</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0">
          {error ? (
            <Center p="2rem" pb="4rem">
              <TableBodyFallback
                heading="Reposts are not available"
                subheading="This post may have been deleted or you may not have access to it."
              />
            </Center>
          ) : null}
          {tabs ? (
            <>
              <ReactionsTypeTabs tabs={tabs} totalCount={totalCount} />
            </>
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
