import { SkeletonText, Stack } from '@chakra-ui/react';

const Skeleton = () => (
  <Stack gap={4} h="100%">
    <SkeletonText mt="4" noOfLines={4} spacing="4" />
    <SkeletonText mt="4" noOfLines={4} spacing="4" />
    <SkeletonText mt="4" noOfLines={4} spacing="4" />
    <SkeletonText mt="4" noOfLines={4} spacing="4" />
  </Stack>
);

export default Skeleton;
