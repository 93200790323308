import * as React from 'react';
import { IconButton, ButtonProps, Icon } from '@chakra-ui/react';
import { IconChevronLeft } from '@tigerhall/icons';
import { Calendar, GetBackForwardPropsOptions } from 'dayzed';

import { DatepickerProps } from '../types';

export interface DatepickerBackBtnProps extends DatepickerProps {
  calendars: Calendar[];
  getBackProps: (data: GetBackForwardPropsOptions) => Record<string, any>;
}

const DefaultBtnStyle: ButtonProps = {
  variant: 'unstyled',
  size: 'sm',
  borderRadius: 'base'
};

export const DatepickerBackBtn: React.FC<DatepickerBackBtnProps> = (props) => {
  const { calendars, getBackProps } = props;
  const customBtnProps = props.propsConfigs?.dateNavBtnProps;
  return (
    <IconButton
      aria-label="Previous month"
      icon={<Icon as={IconChevronLeft} />}
      {...getBackProps({ calendars })}
      {...DefaultBtnStyle}
      {...customBtnProps}
    />
  );
};

export interface DatepickerForwardBtnProps extends DatepickerProps {
  calendars: Calendar[];
  getForwardProps: (data: GetBackForwardPropsOptions) => Record<string, any>;
}

export const DatepickerForwardBtn: React.FC<DatepickerForwardBtnProps> = (
  props
) => {
  const { calendars, getForwardProps } = props;
  const customBtnProps = props.propsConfigs?.dateNavBtnProps;
  return (
    <IconButton
      aria-label="Next month"
      icon={<Icon as={IconChevronLeft} transform="auto" rotate="180deg" />}
      {...getForwardProps({ calendars })}
      {...DefaultBtnStyle}
      {...customBtnProps}
    />
  );
};
