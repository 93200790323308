import { PartsStyleObject } from '@chakra-ui/theme-tools';
import { selectAnatomy as parts } from '@chakra-ui/anatomy';
import { ComponentStyleConfig } from '@chakra-ui/react';

const baseStyle: PartsStyleObject<typeof parts> = {
  field: {
    _placeholder: {
      color: 'teal.600',
      fontSize: '0.875rem',
      fontWeight: 'normal',
      lineHeight: '1.0625rem'
    },

    px: 3.5,
    py: 2
  }
};

const defaultProps = {
  variant: 'outline',
  size: 'sm'
};

export default {
  parts: parts.keys,
  baseStyle,
  defaultProps
} as ComponentStyleConfig;
