import { Flex, Text, VStack } from '@chakra-ui/layout';
import {
  CircularProgress,
  CircularProgressLabel,
  Icon
} from '@chakra-ui/react';
import { ProductTourState } from '@tigerhall/core';
import { IconBrandTigerhall } from '@tigerhall/icons';
import { setIsOnboardingStatus } from 'app/state';
import { useSetUserOnboardingCompletedMutation } from 'generated';
import { useAppDispatch, useUpdateProductTour } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function AccountCreationWaitScreen() {
  const [progress, setProgress] = useState(0);
  const { updateOnboardingStatus } = useUpdateProductTour();
  const dispatch = useAppDispatch();
  const [setOnboardingDone, { client }] = useSetUserOnboardingCompletedMutation(
    {
      onCompleted: () => {
        client
          .refetchQueries({
            include: ['GetCurrentUserOnboardingStatus']
          })
          .catch(() => {
            // todo: handle error
          });
      }
    }
  );

  const navigate = useNavigate();

  useEffect(() => {
    async function updateAndNavigate() {
      updateOnboardingStatus(ProductTourState.RemindLater);
      await setOnboardingDone();
      dispatch(setIsOnboardingStatus({ shouldOnboardUser: false }));

      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 1;
          if (newProgress >= 100) {
            clearInterval(interval);
            navigate('/signup/individual/success');
          }
          return newProgress;
        });
      }, 40);
      return () => {
        clearInterval(interval);
      };
    }

    void updateAndNavigate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <VStack h="100%" justifyContent="center" spacing="1rem">
      <CircularProgress
        value={progress}
        thickness="0.5rem"
        color="tigerOrange.600"
        trackColor="whiteAlpha.300"
        size="3.5rem"
      >
        <CircularProgressLabel>
          <Flex justifyContent="center">
            <Icon
              as={IconBrandTigerhall}
              height="1.25rem"
              color="tigerOrange.600"
            />
          </Flex>
        </CircularProgressLabel>
      </CircularProgress>
      <Text color="white" fontSize="2rem" fontWeight="bold">
        Hold On!
      </Text>
      <Text color="lightGrey.900" fontSize="xl" fontWeight="bold">
        Creating Your Account
      </Text>
    </VStack>
  );
}
