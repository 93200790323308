import { createBlobURL, logger, resizeImage } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToast } from '@chakra-ui/react';

export function useEditProfileImageupload({
  name,
  cropSize,
  getUpdatedImageUrl,
  upload
}) {
  const [cropImageUrl, setCropImageUrl] = useState<string | null>(null);
  const [isUploading, setUploading] = useState(false);
  const [isCropModalOpen, setCropModalOpen] = useState(false);
  const { setValue, watch, register, unregister } = useFormContext();
  const value = watch(name);

  const imageRef = useRef({
    name,
    value,
    currentImageName: null,
    type: 'image'
  });

  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  });

  const onImageUpload = useCallback(
    async (file: File) => {
      if (!file) {
        return;
      }

      segment.fileUploadSelected({
        fileSize: file.size,
        fileName: file.name,
        fileType: file.type,
        formName: name,
        fieldName: name
      });

      setUploading(true);

      try {
        const response = await upload(file);
        setValue(name, {
          id: response?.id,
          uri: resizeImage({
            url: response?.uri,
            width: 250
          })
        });
        getUpdatedImageUrl(response?.uri || '');
      } catch (error: any) {
        logger.error(error);
        toast({
          title: 'Error Ocurred.',
          description: 'Only .jpg and .png format images are allowed',
          status: 'error'
        });
      } finally {
        setUploading(false);
      }
    },
    [setValue, upload, name, getUpdatedImageUrl, toast]
  );

  const closeModal = useCallback(() => {
    setCropModalOpen(false);
    setCropImageUrl(null);
  }, [setCropModalOpen, setCropImageUrl]);

  const onCropApply = useCallback(
    (blob: Blob | null) => {
      if (!blob) {
        return;
      }

      const file = new File(
        [blob],
        imageRef?.current?.currentImageName ?? 'image.jpg',
        { type: 'image/jpg' }
      );

      onImageUpload(file);
      closeModal();
    },
    [closeModal, onImageUpload]
  );

  const onImageChange = useCallback(
    (event) => {
      const [file] = event.target.files;

      if (cropSize) {
        if (imageRef?.current) {
          imageRef.current.currentImageName = file.name;
        }

        setCropImageUrl(createBlobURL(file));
        setCropModalOpen(true);
      } else {
        onImageUpload(file);
      }

      // handling case if user set same image again
      // eslint-disable-next-line no-param-reassign
      event.target.value = null;
    },
    [setCropModalOpen, setCropImageUrl, imageRef, cropSize, onImageUpload]
  );

  useEffect(() => {
    register(name);
  }, [register, unregister, name]);

  return {
    cropImageUrl,
    closeModal,
    isCropModalOpen,
    onCropApply,
    onImageChange,
    isUploading
  };
}
