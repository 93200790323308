import { motion } from 'framer-motion';
import { forwardRef } from 'react';
import { URLSearchParams } from 'url';

import { CommentItem, CommentItemProps } from './CommentItem';
import { getIsBoxHighlighted } from './utils/constant';

interface SingleReplyProps
  extends Pick<CommentItemProps, 'referenceId' | 'referenceType' | 'onDelete'> {
  replyShown: CommentItemProps['comment'];
  searchParams: URLSearchParams;
  isOrganizationContent: boolean;
  refetch: () => void;
  parentView: string;
}

export const SingleReply = forwardRef<
  HTMLDivElement,
  Readonly<SingleReplyProps>
>(function SingleReply(
  {
    replyShown,
    referenceId,
    referenceType,
    searchParams,
    isOrganizationContent,
    refetch,
    onDelete,
    parentView
  },
  ref
) {
  return (
    <motion.div
      key={`${parentView}-${replyShown?.id}`}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }}
    >
      <CommentItem
        comment={replyShown}
        referenceId={referenceId}
        referenceType={referenceType}
        ref={ref}
        disableReply
        isHighlighted={getIsBoxHighlighted(replyShown?.id, searchParams)}
        onDelete={onDelete}
        refetch={refetch}
        isOrganizationContent={isOrganizationContent}
      />
    </motion.div>
  );
});
