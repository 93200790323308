import { Box, ButtonGroup, Flex, SlideFade } from '@chakra-ui/react';
import {
  ContentType,
  useScrollDirection,
  useScrollToTopOnDepsChange
} from '@tigerhall/core';
import { type RefObject, useRef } from 'react';

import { usePowerReadState } from '~/player/PowerRead/context/usePowerReadState';
import { Header, type HeaderProps } from '~/player/components/Header';
import { Overlay } from '~/player/components/Overlay';
import {
  OverlayBodyContainer,
  type OverlayBodyContainerProps
} from '~/player/components/OverlayBodyContainer';
import { AudioBookTrigger } from './AudioBookTrigger';
import {
  ChangeLanguageButton,
  type ChangeLanguageButtonProps
} from './ChangeLanguageButton';
import { ChangeTextSizeButton } from './ChangeTextSizeButton';
import { ChangeThemeButton } from './ChangeThemeButton';
import { CollapsiblePanel } from './CollapsiblePanel';
import { ProgressBar } from './Progress';
import { Reader, type ReaderProps } from './Reader';

export interface ActivePlayerProps
  extends Pick<
      ChangeLanguageButtonProps,
      | 'mediaLanguages'
      | 'selectedMediaLanguage'
      | 'onChangeMediaLanguage'
      | 'transcriptLanguages'
      | 'selectedTranscriptLanguage'
      | 'onChangeTranscriptLanguage'
    >,
    Omit<ReaderProps, 'onDone' | 'playlistType' | 'onNextContentPiece'>,
    Pick<HeaderProps, 'playlistTitle' | 'playlistType' | 'contentUrl'>,
    Pick<
      OverlayBodyContainerProps,
      'onAutoNextPlay' | 'isLastContentInPlaylist'
    > {
  onFinished: () => void;
  onTrackFinished: () => void;
  audioRef: RefObject<HTMLAudioElement>;
}

export function ActivePlayer({
  onFinished,
  onTrackFinished,
  audioRef,
  isFinished,

  // from `HeaderProps
  playlistTitle,
  playlistType,
  contentUrl,

  // from `ChangeLanguageButtonProps`
  mediaLanguages,
  selectedMediaLanguage,
  onChangeMediaLanguage,
  transcriptLanguages,
  selectedTranscriptLanguage,
  onChangeTranscriptLanguage,

  // from `OverlayBodyContainerProps`
  onAutoNextPlay,
  isLastContentInPlaylist,

  // from `ReaderProps`
  name,
  chapter,
  chaptersTotal,
  pages,
  children
}: Readonly<ActivePlayerProps>) {
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollDirection = useScrollDirection(containerRef);

  const { theme, pageIndex, isStayingOnPage, setIsStayingOnPage } =
    usePowerReadState((state) => {
      return {
        theme: state.theme,
        pageIndex: state.pageIndex,
        isStayingOnPage: state.isStayingOnPage,
        setIsStayingOnPage: state.setIsStayingOnPage
      };
    });

  useScrollToTopOnDepsChange(containerRef, [pageIndex]);

  const isDarkMode = theme === 'dark';

  const backgroundColor = isDarkMode ? 'darkGrey.400' : 'lightGrey.200';

  return (
    <Flex
      ref={containerRef}
      id="power-read-player"
      position={'relative'}
      width={'100%'}
      height={'100%'}
      background={backgroundColor}
      overflowY={'auto'}
      flexDirection={'column'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '8px'
        },
        '&::-webkit-scrollbar-track': {
          width: '6px'
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'lightGrey.900',
          borderRadius: '24px'
        }
      }}
    >
      <Box position={'sticky'} top={'0'}>
        <SlideFade offsetY="-10rem" in={scrollDirection === 'UP'}>
          <Flex
            width={'100%'}
            flexDirection={'column'}
            background={'darkGrey.400'}
            boxShadow="0 8px 12px 0 rgba(0, 0, 0, 0.15)"
            pt={{
              base: '2.5rem',
              lg: '0'
            }}
          >
            <Header
              contentType={ContentType.Ebook}
              playlistTitle={playlistTitle}
              playlistType={playlistType}
              contentUrl={contentUrl}
            />
            <Flex
              gap={{
                base: '0.5rem',
                lg: '2rem'
              }}
              px={{
                base: '1.5rem',
                lg: '3rem'
              }}
              py={{
                base: '0.5rem',
                lg: '1rem'
              }}
              flexDirection={{
                base: 'column',
                lg: 'row'
              }}
              justifyContent={'space-between'}
            >
              <ButtonGroup gap="2rem" justifyContent="space-between">
                <ChangeTextSizeButton />
                <ChangeThemeButton />
                <ChangeLanguageButton
                  mediaLanguages={mediaLanguages}
                  transcriptLanguages={transcriptLanguages}
                  selectedMediaLanguage={selectedMediaLanguage}
                  selectedTranscriptLanguage={selectedTranscriptLanguage}
                  onChangeMediaLanguage={onChangeMediaLanguage}
                  onChangeTranscriptLanguage={onChangeTranscriptLanguage}
                />
                {selectedTranscriptLanguage ? <AudioBookTrigger /> : null}
              </ButtonGroup>
              <ProgressBar />
            </Flex>
            <CollapsiblePanel />
          </Flex>
        </SlideFade>
      </Box>
      <Reader
        name={name}
        chapter={chapter}
        chaptersTotal={chaptersTotal}
        pages={pages}
        isFinished={isFinished}
        playlistType={playlistType}
        isLastContentInPlaylist={isLastContentInPlaylist}
        onDone={() => {
          onFinished();
          onTrackFinished();
        }}
        onNextContentPiece={() => {
          onAutoNextPlay(true);
        }}
      >
        {children}
      </Reader>
      {audioRef?.current?.ended && !isStayingOnPage ? (
        <Box inset={0}>
          <Overlay
            isAlwaysOpen
            header={
              <Header
                contentType={ContentType.Ebook}
                playlistTitle={playlistTitle}
                playlistType={playlistType}
                contentUrl={contentUrl}
              />
            }
            body={
              <Box justifyContent={'center'} alignItems={'center'} w={'full'}>
                <OverlayBodyContainer
                  onAutoNextPlay={(value: boolean) => {
                    setIsStayingOnPage(!value);
                    onAutoNextPlay(value);
                  }}
                  isLastContentInPlaylist={isLastContentInPlaylist}
                  playlistType={playlistType}
                />
              </Box>
            }
            footer={null}
          />
        </Box>
      ) : null}
    </Flex>
  );
}
