import {
  Box,
  Center,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { FeedContentType } from '@tigerhall/core';
import { TableBodyFallback } from '@tigerhall/ui-components';
import * as segment from '@tigerhall/analytics';
import { useGetPostRepostsByIdQuery } from 'generated';
import { getPreviousRoute } from 'utils';

import { PostSkeleton } from '../Post/PostSkeleton';
import { Post } from '../Post';
import { PostActivity } from '../PostActivity';

const DEFAULT_LIMIT = 20;

export function RepostsModal() {
  const navigate = useNavigate();
  const { postId = '' } = useParams();

  const { data, loading, error, fetchMore } = useGetPostRepostsByIdQuery({
    variables: {
      postId: postId,
      type: FeedContentType.Post,
      pagination: {
        limit: DEFAULT_LIMIT,
        afterCursor: undefined
      }
    }
  });

  function closeModal() {
    const { prevRoute, replace } = getPreviousRoute({
      fallback: '/feed'
    });

    if (typeof prevRoute === 'string') {
      navigate(prevRoute, { replace });
    } else {
      navigate(prevRoute);
    }
  }

  const {
    numberOfReposts = 0,
    reposts: { edges: reposts = [], meta = undefined } = {}
  } = data?.feedContentById.__typename === 'Post' ? data.feedContentById : {};

  const hasNextPage = !loading && !!meta?.hasNext;

  const [targetRef] = useInfiniteScroll({
    onLoadMore: () => {
      segment.fetchMore({
        componentName: 'REPOSTS',
        fetchedItemCount: reposts.length
      });

      fetchMore({
        variables: {
          pagination: {
            limit: DEFAULT_LIMIT,
            afterCursor: meta?.nextCursor
          }
        }
      });
    },
    hasNextPage,
    loading,
    disabled: !!error
  });

  return (
    <Modal
      size="2xl"
      isOpen={true}
      onClose={closeModal}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent border="1px solid" borderColor="darkGrey.300">
        <ModalHeader color="lightGrey.200">
          {numberOfReposts} Repost{numberOfReposts > 1 ? 's' : ''}
        </ModalHeader>
        <Divider mx="1rem" width="calc(100% - 2rem)" />
        <ModalCloseButton />
        <ModalBody p="0">
          {error ? (
            <Center p="2rem" pb="4rem">
              <TableBodyFallback
                heading="Reposts are not available"
                subheading="This post may have been deleted or you may not have access to it."
              />
            </Center>
          ) : null}
          {loading ? <PostSkeleton /> : null}
          {data ? (
            <VStack
              width="100%"
              maxWidth="48rem"
              height="auto"
              mx="auto"
              py="1rem"
              spacing="1rem"
            >
              {reposts.map((repost) => {
                const { feed } = repost;
                const { __typename: type } = feed;
                switch (type) {
                  case 'Post':
                    return <Post key={feed.id} post={feed} />;
                  case 'PostActivity':
                    return <PostActivity key={feed.id} activity={feed} />;
                  default:
                    return null;
                }
              })}
              {loading || hasNextPage ? (
                <Box width="100%" ref={targetRef}>
                  <PostSkeleton />
                </Box>
              ) : null}
            </VStack>
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
