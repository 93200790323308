import keys from 'lodash/keys';
import values from 'lodash/values';
import { useState } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { useDeepCompareEffect, useGenerateId } from '@tigerhall/core';

import {
  ImageVariant,
  type ImageVariantProps,
  ImageVariantSkeleton
} from './components/ImageVariant';
import {
  PillVariant,
  type PillVariantProps,
  PillVariantSkeleton
} from './components/PillVariant';
import { useLinkPreview } from './hooks';

type VariantProps = ImageVariantProps & PillVariantProps;

interface LinkPreviewProps {
  urls: string[];
  onRemove?: (url: string) => unknown;
  /**
   *
   * @description This will be called once the previews are shown
   * and will be passed only with the valid urls for which the response was successful
   */
  onPreview?: (urls: string[]) => unknown;
}

export function LinkPreview({
  urls,
  onRemove,
  onPreview
}: Readonly<LinkPreviewProps>) {
  const { loading, result } = useLinkPreview({ urls });
  const [preview, setPreview] = useState<VariantProps[]>([]);

  const generatedId = useGenerateId('link-preview-');

  useDeepCompareEffect(() => {
    const queryKeys = keys(result);
    const { validUrls, previewResult } = values(result).reduce<{
      validUrls: string[];
      previewResult: VariantProps[];
    }>(
      (acc, { data }, index) => {
        if (data?.getLinkPreview) {
          acc.validUrls.push(queryKeys[index]);

          acc.previewResult.push({
            description: data.getLinkPreview.description ?? '',
            title: data.getLinkPreview.title ?? '',
            url: data.getLinkPreview.uri ?? '',
            onRemove: onRemove ? () => onRemove(queryKeys[index]) : undefined,
            imageUrl: data.getLinkPreview.imageUrl ?? ''
          });
        }
        return acc;
      },
      { validUrls: [], previewResult: [] }
    );

    onPreview?.(validUrls);

    setPreview(previewResult);
  }, [result]);

  if (loading) {
    if (urls.length > 1) {
      return (
        <SimpleGrid gap={2} columns={{ base: 2, md: 3 }}>
          {urls.map((url) => (
            <PillVariantSkeleton key={`${url}-${generatedId}`} />
          ))}
        </SimpleGrid>
      );
    }
    return <ImageVariantSkeleton />;
  }

  return (
    <SimpleGrid gap={2} columns={preview.length > 1 ? { base: 2, md: 3 } : 1}>
      {preview.map((previewLink, index) => {
        const variantKey = `${previewLink.url}-${generatedId}-${index}`;
        if (preview.length > 1) {
          return <PillVariant {...previewLink} key={variantKey} />;
        }
        return <ImageVariant {...previewLink} key={variantKey} />;
      })}
    </SimpleGrid>
  );
}
