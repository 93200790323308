import {
  StreamInput,
  useCreateStreamMutation,
  useUpdateStreamMutation
} from 'generated';
import { UseFormSetError } from 'react-hook-form';
import { useToast } from '@chakra-ui/react';
import { useStructuredErrors } from '@tigerhall/core';
import { captureException } from '@sentry/react';
import { AppError } from 'utils';

import { FormValues } from '../components/Form';

export type MimickedError = {
  message?: string;
  type?: string;
};

interface UseManageStreamProps {
  updateStreamId?: string;
}

export function useManageBroadcast({
  updateStreamId
}: Readonly<UseManageStreamProps>) {
  const [createStreamMutation] = useCreateStreamMutation();
  const [updateStreamMutation] = useUpdateStreamMutation();

  const { captureMutationError, handleMutationError } = useStructuredErrors();

  const toast = useToast({
    duration: 2000
  });

  const handleError = (err: unknown) => {
    if (handleMutationError(err)) {
      return;
    }
    toast.closeAll();
    toast({
      title: err instanceof Error ? err.message : 'An error occurred',
      status: 'error'
    });
    captureException(err, err instanceof AppError ? { ...err.context } : {});
  };

  const create = async (
    input: StreamInput,
    setError: UseFormSetError<FormValues>
  ) => {
    try {
      toast({
        status: 'loading',
        title: 'We are processing your broadcast'
      });

      const result = await createStreamMutation({
        variables: {
          input
        }
      });

      captureMutationError(result.data?.createStream, setError, (description) =>
        toast({ description })
      );

      if (result.data?.createStream?.__typename !== 'Stream') {
        throw new Error('An error occurred while creating the broadcast');
      }

      toast({
        status: 'loading',
        title: 'Uploading broadcast'
      });
    } catch (error) {
      console.error(error);
      handleError(error);
    }
  };

  const update = async (
    input: StreamInput,
    setError: UseFormSetError<FormValues>
  ) => {
    try {
      toast({
        status: 'loading',
        title: 'Updating broadcast'
      });

      if (!updateStreamId) {
        throw new Error('updateStreamId not found');
      }

      const updatedBroadcast = await updateStreamMutation({
        variables: {
          updateStreamId,
          input
        }
      });

      captureMutationError(
        updatedBroadcast.data?.updateStream,
        setError,
        (description) => toast({ description })
      );
    } catch (error) {
      handleError(error);
    }
  };

  return { create, update };
}
