import * as React from 'react';
import { Icon } from '@chakra-ui/react';
import { ContentStepper, PrimaryButton } from 'components/ui';
import { IconCaretLeftFilled, IconCaretRightFilled } from '@tigerhall/icons';

import { SelectedUsersAndGroups } from '../../../components';
import { useAssignmentDraftContext } from '../../../hooks/useAssignmentDraftContext';

const DESCRIPTION = `Add or remove selected users and groups belonging to this assignment. OR Add or remove selected users and groups belonging to your organization to assign the relevant content to.`;

type AssignmentUsersSelectionProps = {
  stepNames: string[];
  forward: () => any;
  goBack: () => any;
  isEditing: boolean;
};

export function AssignmentUsersSelection({
  stepNames,
  forward,
  goBack,
  isEditing
}: Readonly<AssignmentUsersSelectionProps>) {
  const { usersIds, groupsIds, setUsersIds, setGroupsIds } =
    useAssignmentDraftContext();

  const isContinueButtonDisabled = React.useCallback(
    () => usersIds?.length <= 0 && groupsIds?.length <= 0,
    [usersIds, groupsIds]
  );

  const buttons: PrimaryButton[] = React.useMemo(
    () => [
      {
        text: 'Previous',
        onClick: goBack,
        variant: 'outline',
        backgroundColor: 'transparent',
        leftIcon: <Icon as={IconCaretLeftFilled} color="kindaYellow.50" />
      },
      {
        text: 'Next',
        onClick: forward,
        isDisabled: isContinueButtonDisabled(),
        rightIcon: <Icon as={IconCaretRightFilled} color="tigerOrange.600" />
      }
    ],
    [forward, goBack, isContinueButtonDisabled]
  );

  const buttonsOnEdit: PrimaryButton[] = React.useMemo(
    () => [
      {
        text: 'Go to Summary',
        onClick: forward,
        rightIcon: <Icon as={IconCaretRightFilled} color="tigerOrange.600" />
      }
    ],
    [forward]
  );

  return (
    <ContentStepper
      stepNames={stepNames}
      title="Select Users & Groups"
      currentStep={2}
      description={DESCRIPTION}
      buttons={isEditing ? buttonsOnEdit : buttons}
    >
      <SelectedUsersAndGroups
        selectedUserRowIds={usersIds}
        onUserRowSelection={setUsersIds}
        selectedGroupRowIds={groupsIds}
        onGroupRowSelection={setGroupsIds}
      />
    </ContentStepper>
  );
}
