import type { Pretty } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';

/**
 * Wrap the language change callback with the segment tracking
 * event.
 *
 * @example```ts
 * const handleLanguageChange = (code) => { ...}
 * const onLanguageChange = wrapLanguageChangeHandler(handleLanguageChange, { previousLanguage: 'en' });
 * onLanguageChange('es');
 * ```
 *
 * @param callback
 * @param eventProperties
 * @returns
 */
export function wrapLanguageChangeHandler<T extends string | null>(
  callback: (language: T) => void,
  {
    previousLanguage
  }: Pretty<Pick<segment.LanguageChanged, 'previousLanguage'>>
): (language: T) => void {
  return (code) => {
    segment.languageChanged({
      location: window.location.href,
      previousLanguage,
      selectedLanguage: code ?? 'none'
    });

    callback(code);
  };
}
