import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/reducer';

type State = {
  email: string;
};

const initialState: State = {
  email: ''
};

export const freeTrialSlice = createSlice({
  name: 'freeTrial',
  initialState,
  reducers: {
    setFreeTrialEmail: (state, action) => {
      state.email = action.payload;
    }
  }
});

export const { setFreeTrialEmail } = freeTrialSlice.actions;
export default freeTrialSlice.reducer;

export const getFreeTrialUserEmail = (state: RootState) =>
  state.freeTrial.email;
