import React from 'react';
import { BoxProps, Flex } from '@chakra-ui/react';

import { PrimaryButton } from './ContentBox';
import WizardContent from './components/WizardContent';
import { StepperHeader } from './components/StepperHeader';

type Props = {
  stepNames: string[];
  title?: string;
  subTitle?: string;
  description?: string;
  buttons?: PrimaryButton[];
  children: React.ReactNode;
  loading?: boolean;
  currentStep?: number;
} & BoxProps;

/**
 * The ContentStepper is used for content creation where we want to have
 * multiple steps in the creation process (revamped)
 *
 * @constructor
 */

export function ContentStepper({
  stepNames,
  title,
  currentStep,
  description,
  children,
  buttons: primaryButtons,
  loading,
  ...restProps
}: Props) {
  return (
    <Flex direction="column" {...restProps}>
      <StepperHeader
        stepNames={stepNames}
        currentStep={currentStep}
        primaryButtons={primaryButtons}
        title={title}
        description={description}
      />

      <WizardContent loading={loading}>{children}</WizardContent>
    </Flex>
  );
}
