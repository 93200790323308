import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  type Operation,
  from,
  split
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {
  type PersistedQueryLink,
  createPersistedQueryLink
} from '@apollo/client/link/persisted-queries';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { logger } from '@tigerhall/core';
import type {
  AssignmentConnection,
  CommentCursorConnection,
  ConsumableContentCollection,
  ContentConnection,
  EventConnection,
  FeedContentConnection,
  LearningPathConnection,
  NotificationConnection,
  PodcastConnection,
  StreamConnection,
  UserCollection,
  UserReactionConnection
} from '@tigerhall/core/lib/types';
import sha from 'sha.js';

import { getAccessToken } from '~/app/state';
import { store } from '~/app/store';
import handleAPIErrors from '../errors';
import {
  cursorPaginationPolicy,
  legacyCursorPaginationPolicy,
  offsetPaginationPolicy
} from './typePolicies';

export const cache = new InMemoryCache({
  addTypename: true,
  resultCaching: true,
  typePolicies: {
    /**
     * Don't add pagination type policies for Organisation
     */
    User: {
      fields: {
        userActivities: cursorPaginationPolicy<FeedContentConnection>(),
        finishedContent: offsetPaginationPolicy<ConsumableContentCollection>(),
        notifications: cursorPaginationPolicy<NotificationConnection>(),
        followings: offsetPaginationPolicy<UserCollection>(),
        suggestedUsersToFollow: offsetPaginationPolicy<UserCollection>()
      }
    },
    Post: {
      fields: {
        comments: cursorPaginationPolicy<CommentCursorConnection>(),
        reposts: cursorPaginationPolicy<FeedContentConnection>()
      }
    },
    /** Todo: after Backend adds pagination object for cursor pagination for comments,
     * we should replace the legacyCursorPagination func with cursorPaginationPolicy func
     * */
    Comment: {
      fields: {
        replies: legacyCursorPaginationPolicy<CommentCursorConnection>()
      }
    },
    Ebook: {
      fields: {
        comments: legacyCursorPaginationPolicy<CommentCursorConnection>()
      }
    },
    Podcast: {
      fields: {
        comments: legacyCursorPaginationPolicy<CommentCursorConnection>()
      }
    },
    Stream: {
      fields: {
        comments: legacyCursorPaginationPolicy<CommentCursorConnection>()
      }
    },
    Event: {
      fields: {
        comments: legacyCursorPaginationPolicy<CommentCursorConnection>()
      }
    },
    ReactionsSummary: {
      fields: {
        users: cursorPaginationPolicy<UserReactionConnection>({
          keyArgs: ['type']
        })
      }
    },
    Query: {
      fields: {
        feedV2: cursorPaginationPolicy<FeedContentConnection>(),
        streams: offsetPaginationPolicy<StreamConnection>(),
        events: offsetPaginationPolicy<EventConnection>(),
        podcasts: offsetPaginationPolicy<PodcastConnection>(),
        contentCards: offsetPaginationPolicy<ContentConnection>(),
        learningPaths: offsetPaginationPolicy<LearningPathConnection>(),
        suggestedUsersToFollow: offsetPaginationPolicy<UserCollection>()
      }
    }
  },
  possibleTypes: {
    ContentCard: ['Ebook', 'Event', 'Expert', 'Podcast', 'Stream']
  }
});

const webSocketLink = new WebSocketLink({
  uri: `${import.meta.env.VITE_WS_URL}/graphql`,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: () => {
      const token = getAccessToken(store.getState());
      if (token) {
        return {
          authorization: token
        };
      }

      return {};
    }
  }
});

// This uses a domain behind cloudflare argo that is the fastest way to connect
// to our API servers
const apiLink = new HttpLink({
  uri: `${import.meta.env.VITE_API_URL}/v2/`,
  fetch: fetch
});

// Network link checks the definition of the GraphQL definition and sends
// it either via the WebSocket link for subscriptions or default http
const networkLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  webSocketLink,
  apiLink
);

export function disconnectWebsocket(): void {
  // @ts-expect-error - subscriptionClient is private
  const client = webSocketLink.subscriptionClient;
  client.close(false, true);
}

function getToken(operation: Operation): string | null {
  if (Object.prototype.hasOwnProperty.call(operation.getContext(), 'token')) {
    return operation.getContext().token;
  }

  return getAccessToken(store.getState());
}

/**
 * Inject the authentication token into the requests made by apollo
 */
const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getToken(operation);

  if (token) {
    operation.setContext({
      headers: {
        authorization: `jwt ${token}`
      }
    });
  }

  return forward(operation);
});

/**
 * Checks the incoming error from the request and if it's a 401 error we log
 * the user out from the platform using keycloak
 */
function errorHandler(): ApolloLink {
  return onError((err) => {
    const { networkError, operation, graphQLErrors, response } = err;

    if (graphQLErrors?.[0].extensions?.code === 'PERSISTED_QUERY_NOT_FOUND') {
      return;
    }

    logger.debug({ networkError, response, operation, graphQLErrors });

    handleAPIErrors(graphQLErrors);
  });
}

// eslint-disable-next-line func-style
const encrypt: PersistedQueryLink.Options['sha256'] = (query) =>
  sha('sha256').update(query).digest('hex');

const link = createPersistedQueryLink({ sha256: encrypt }).concat(
  errorHandler().concat(from([authMiddleware, networkLink]))
);

/**
 * Export the constructed apollo client
 */
export const apolloClient = new ApolloClient({
  cache,
  link,
  name: 'WebApp',
  version: import.meta.env.VITE_SENTRY_RELEASE ?? 'dev'
});

export async function clearCache() {
  await apolloClient.resetStore();
}
