import { type ReactNode } from 'react';
import {
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  type PopoverProps,
  PopoverTrigger
} from '@chakra-ui/react';
import { ReactionType } from '@tigerhall/core';

import {
  ReactionItem,
  type ReactionItemProps
} from './components/ReactionItem';

const ORDERED_REACTIONS_LIST: ReactionType[] = [
  ReactionType.Heart,
  ReactionType.Bulb,
  ReactionType.Clap,
  ReactionType.Think,
  ReactionType.Smile,
  ReactionType.Tiger,
  ReactionType.Llama
];

interface ReactionsPopoverProps
  extends PopoverProps,
    Pick<ReactionItemProps, 'onReactionClicked'> {
  /**
   * The trigger of the popover. It's usually a `Button`.
   */
  children: ReactNode;
}

/**
 * ReactionsPopover is a component that renders a popover with reactions.
 * It's used to add reactions to posts and comments in the web app.
 *
 * This component extends the `Popover` component from Chakra UI,
 * so you can pass all the props from `Popover` to this component.
 * It also accepts the `onReactionClicked` prop to handle the click
 * event of the reactions.
 *
 * @see https://chakra-ui.com/docs/components/popover
 *
 * @example```jsx
 * <ReactionsPopover trigger="hover" onReactionClicked={(reaction) => console.log(reaction)}>
 *   <Button>Trigger</Button>
 * </ReactionsPopover>
 * ```
 */
export function ReactionsPopover({
  children,
  onReactionClicked,
  ...rest
}: ReactionsPopoverProps) {
  return (
    <Popover isLazy placement="top-start" openDelay={500} {...rest}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        width="fit-content"
        background="darkGrey.300"
        borderWidth={0}
        borderRadius="lg"
      >
        <PopoverBody p="0.5rem">
          <HStack spacing="0.5rem">
            {ORDERED_REACTIONS_LIST.map((reaction) => {
              return (
                <ReactionItem
                  key={`reaction-${reaction}`}
                  reaction={reaction}
                  onReactionClicked={onReactionClicked}
                />
              );
            })}
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
