import * as React from 'react';
import {
  LinkOverlay as ChakraLinkOverlay,
  LinkOverlayProps as ChakraLinkOverlayProps
} from '@chakra-ui/react';

import { useLink } from './LinkContext';

export interface LinkOverlayProps extends ChakraLinkOverlayProps {
  /**
   * Styles the `<Link />` as a link and extends the Chakra's `LinkProps`
   **/
  as?: 'a';

  /**
   * The path or URL to navigate to. This is the only required prop.
   **/
  href: string;

  /**
   * If we want to stop the
   */
  replace?: boolean;

  children?: React.ReactNode;
}

/**
 * Custom Link wrapper + Chakra UI LinkOverlay
 * This component has to be a `LinkBox` descendant.
 *
 * @see https://nextjs.org/docs/api-reference/next/link
 * @see https://chakra-ui.com/docs/components/link-overlay
 */
export function LinkOverlay({
  href,
  replace,
  children,
  ...props
}: LinkOverlayProps) {
  const { Link } = useLink();

  return (
    <ChakraLinkOverlay href={href} {...props} as={Link}>
      {children}
    </ChakraLinkOverlay>
  );
}
