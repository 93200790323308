import { FormSelect } from '@tigerhall/components';
import { useFetchCategoriesQuery } from 'generated';
import property from 'lodash/property';

export interface CategoriesSelectProps {
  name: string;
  isMulti?: boolean;
  isClearable?: boolean;
  required?: boolean;
}

export function CategoriesSelect({
  name,
  required = false,
  isMulti = false,
  isClearable = false
}: CategoriesSelectProps) {
  const { data, error } = useFetchCategoriesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        limit: 1000
      }
    }
  });

  if (error) {
    return null;
  }

  const options = data?.categories?.edges || [];

  return (
    <FormSelect
      name={name}
      label={isMulti ? 'Categories' : 'Category'}
      isMulti={isMulti}
      isClearable={isClearable}
      options={options}
      getOptionLabel={property('name')}
      getOptionValue={property('id')}
      placeholder="Select a category"
      rules={{
        required: required ? 'Please select a category' : false
      }}
    />
  );
}
