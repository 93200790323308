import * as segment from '@tigerhall/analytics';
import { ContentType } from '@tigerhall/core';
import {
  setPlayingContent,
  type PlayingContent,
  setIsLastContentInPlaylist
} from 'app/state';
import { useAppDispatch } from 'hooks';
import { useGetPlaylistInfoFromURL } from 'hooks/useGetPlaylistInfoFromURL';

type PlayVideoParams = Pick<PlayingContent, 'id'> & {
  isDemo?: boolean;
};

export function useVideoAction() {
  const dispatch = useAppDispatch();
  const { playlistType, playlistId, playlistTitle, contentUrl } =
    useGetPlaylistInfoFromURL();

  const playVideo = ({ isDemo, ...params }: PlayVideoParams) => {
    let onCompletionCallback = () => {};

    if (isDemo) {
      segment.storyStarted({});
      onCompletionCallback = () => {
        segment.storyCompleted({});
      };
      dispatch(setIsLastContentInPlaylist(true));
    }

    dispatch(
      setPlayingContent({
        ...params,
        playlistType,
        playlistId,
        playlistTitle,
        contentUrl,
        type: ContentType.Stream,
        onCompletionCallback
      })
    );
  };

  return {
    playVideo
  };
}
