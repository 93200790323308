import throttle from 'lodash/throttle';
import { useCallback, useEffect, useRef, useState } from 'react';

const DEFAULT_EVENTS = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
  'focus'
];

interface UseIsUserIdleArgs {
  /**
   * no of `seconds` after which user will be consider idle after no interactivity
   * eg: `10`, `120`(i.e 2 minutes)
   */
  seconds: number;
}

export function useIsUserIdle({ seconds }: Readonly<UseIsUserIdleArgs>) {
  const [isIdle, setIsIdle] = useState(false);
  const intervalIdRef = useRef(0);

  const startTimer = useCallback(() => {
    intervalIdRef.current = window.setTimeout(() => {
      setIsIdle(true);
    }, seconds * 1000);
  }, [seconds]);

  const resetTimer = useCallback(() => {
    clearInterval(intervalIdRef.current);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetIdleState = useCallback(
    throttle(() => {
      setIsIdle(false);
      resetTimer();
      startTimer();
    }, 1000),
    []
  );

  useEffect(() => {
    DEFAULT_EVENTS.forEach((eventName) => {
      document.addEventListener(eventName, resetIdleState);
    });

    return () => {
      DEFAULT_EVENTS.forEach((eventName) => {
        document.removeEventListener(eventName, resetIdleState);
      });
    };
  }, [resetIdleState]);

  useEffect(() => {
    startTimer();

    return () => {
      resetTimer();
    };
  }, [startTimer, resetTimer]);

  return {
    isIdle
  };
}
