import { Box, Skeleton } from '@chakra-ui/react';

export function TrailOfTheMonthSkeleton() {
  return (
    <Box
      p={{
        base: '0.75rem 1rem',
        md: '0.75rem 2rem'
      }}
      width="100%"
      height={{
        base: '30rem',
        md: '24rem'
      }}
    >
      <Skeleton rounded="lg" width="100%" height="100%" />
    </Box>
  );
}
