import { Divider, HStack, VStack } from '@chakra-ui/react';
import * as React from 'react';

interface FeedItemActionsProps {
  children: React.ReactNode;
}

export function FeedItemActions({ children }: FeedItemActionsProps) {
  return (
    <VStack
      mb="0.5rem"
      mx={{
        base: '1rem',
        md: '2rem'
      }}
      spacing="0.5rem"
    >
      <Divider borderColor="darkGrey.400" />
      <HStack spacing="0.5rem" width="100%">
        {children}
      </HStack>
    </VStack>
  );
}
