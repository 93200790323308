import { mode as t } from "./index31.mjs";
const r = {
  bg: "blackAlpha.600",
  zIndex: "overlay"
}, l = {
  display: "flex",
  zIndex: "modal",
  justifyContent: "center"
};
function a(e) {
  const { isFullHeight: o } = e;
  return {
    ...o && { height: "100vh" },
    zIndex: "modal",
    maxH: "100vh",
    bg: t("white", "darkGrey.200")(e),
    color: "inherit",
    boxShadow: t("lg", "dark-lg")(e)
  };
}
function n(e) {
  return {
    px: 6,
    pt: "4rem",
    bg: t("lightGrey.100", "darkGrey.700")(e),
    color: t("darkGrey.400", "white")(e),
    fontSize: "1.25rem",
    fontWeight: 700
  };
}
const i = {
  position: "absolute",
  top: "2rem",
  insetEnd: "2.5rem",
  color: "darkGrey.50",
  w: "2rem",
  h: "2rem",
  borderRadius: "full"
};
function y(e) {
  return {
    px: 6,
    py: 0,
    flex: 1,
    overflow: "auto",
    bg: t("lightGrey.100", "darkGrey.700")(e)
  };
}
function d(e) {
  return {
    px: 6,
    py: 4,
    bg: t("lightGrey.100", "darkGrey.700")(e)
  };
}
const b = (e) => ({
  overlay: r,
  dialogContainer: l,
  dialog: a(e),
  header: n(e),
  closeButton: i,
  body: y(e),
  footer: d(e)
}), s = {
  baseStyle: b
};
export {
  s as Drawer
};
