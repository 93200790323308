import { Flex } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import {
  BaseLoginLayout,
  FormEmail
} from 'modules/authentication/modules/Login/components';

export function LoginWithEmail() {
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <BaseLoginLayout>
        <Flex
          flex={1}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <FormEmail />
        </Flex>
      </BaseLoginLayout>
    </>
  );
}
