import Hls from 'hls.js';
import { RefObject, useEffect, useRef, useState } from 'react';
import { addBreadcrumb, captureException } from '@sentry/react';

export function useVideoHls(
  src: string,
  videoRef: RefObject<HTMLVideoElement>
) {
  const [isBuffering, setIsBuffering] = useState(false);
  const hlsRef = useRef(
    new Hls({
      fragLoadPolicy: {
        default: {
          maxTimeToFirstByteMs: 6000,
          maxLoadTimeMs: 8000,
          timeoutRetry: {
            maxNumRetry: 4,
            retryDelayMs: 0,
            maxRetryDelayMs: 0,
            shouldRetry: (retryConfig) => {
              return true;
            }
          },
          errorRetry: {
            maxNumRetry: 4,
            retryDelayMs: 1000,
            maxRetryDelayMs: 6000,
            shouldRetry: (retryConfig) => {
              return true;
            }
          }
        }
      },
      playlistLoadPolicy: {
        default: {
          maxTimeToFirstByteMs: 8000,
          maxLoadTimeMs: 8000,
          timeoutRetry: {
            maxNumRetry: Infinity,
            retryDelayMs: 500,
            maxRetryDelayMs: 2000,
            backoff: 'linear',
            shouldRetry: (retryConfig) => {
              return true;
            }
          },
          errorRetry: {
            maxNumRetry: Infinity,
            retryDelayMs: 500,
            maxRetryDelayMs: 2000,
            backoff: 'linear',
            shouldRetry: (retryConfig) => {
              return true;
            }
          }
        }
      }
    })
  );

  useEffect(
    function connectHlsEventsToSentry() {
      hlsRef.current.on(Hls.Events.ERROR, (event, payload) => {
        captureException(`HLS Error: ${payload.details}`, {
          extra: {
            ...payload
          }
        });

        if (payload.details === Hls.ErrorDetails.BUFFER_STALLED_ERROR) {
          setIsBuffering(true);
        }

        if (payload.details === Hls.ErrorDetails.BUFFER_APPEND_ERROR) {
          setIsBuffering(true);

          hlsRef.current.startLoad();
        }

        if (payload.fatal) {
          switch (payload.type) {
            case Hls.ErrorTypes.MEDIA_ERROR:
              hlsRef.current.recoverMediaError();
              break;

            case Hls.ErrorTypes.NETWORK_ERROR:
              // All retries and media options have been exhausted.
              // Immediately trying to restart loading could cause loop loading.
              // Consider modifying loading policies to best fit your asset and network
              // conditions (manifestLoadPolicy, playlistLoadPolicy, fragLoadPolicy).
              break;

            default:
              // cannot recover
              hlsRef.current.destroy();

              // This is a hail mary?
              window.location.reload();
              break;
          }
        }
      });

      hlsRef.current.on(Hls.Events.FRAG_BUFFERED, (event, payload) => {
        setIsBuffering(false);

        addBreadcrumb({
          type: 'video',
          level: 'info',
          message: 'Video stopped buffering',
          data: payload
        });
      });

      hlsRef.current.on(Hls.Events.MANIFEST_LOADING, (event, payload) => {
        setIsBuffering(true);

        addBreadcrumb({
          type: 'video',
          level: 'info',
          message: 'Loading manifest',
          data: payload
        });
      });

      hlsRef.current.on(Hls.Events.MANIFEST_PARSED, (event, payload) => {
        setIsBuffering(false);

        addBreadcrumb({
          type: 'video',
          level: 'info',
          message: 'Manifest parsed',
          data: payload
        });
      });

      hlsRef.current.on(Hls.Events.LEVEL_SWITCHING, (event, payload) => {
        addBreadcrumb({
          type: 'video',
          level: 'info',
          message: 'Switched quality level',
          data: payload
        });
      });
    },
    [src]
  );

  useEffect(
    function attachToVideoPlayer() {
      if (src.endsWith('m3u8') && videoRef.current) {
        hlsRef.current.attachMedia(videoRef.current);
        hlsRef.current.loadSource(src);
      }
    },
    [src, videoRef]
  );

  return { hls: hlsRef.current, isBuffering };
}
