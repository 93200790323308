import { Flex } from '@chakra-ui/react';
import { Typography } from '@tigerhall/ui-components';
import { ContentCardsSorting } from '@tigerhall/core/lib/types';
import { Helmet } from 'react-helmet';
import {
  ContentCardSection,
  TopicCloudSection,
  UpcomingExperiencesSection
} from 'modules/application/components';
import { FeaturedThinkfluencers } from 'modules/application/modules/Home/screens/Home/components/FeaturedThinkfluencers';

const Explore = () => (
  <>
    <Helmet>
      <title>Explore</title>
    </Helmet>
    <Flex
      as="header"
      justifyContent="space-between"
      bg="darkGrey.700"
      alignItems="center"
      minH="5.125rem"
      px="2rem"
    >
      <Typography
        variant="heading2"
        fontWeight="700"
        color="tigerOrange.600"
        alignSelf="center"
      >
        Explore
      </Typography>
    </Flex>
    <Flex
      gap={{ base: '1.5rem', md: '2.5rem' }}
      pb={{ base: '1.5rem', md: '2.5rem' }}
      direction="column"
      alignItems="flex-start"
    >
      <TopicCloudSection title="Browse By Topic" />
      <ContentCardSection
        sectionTitle="New"
        variables={{
          filter: {
            limit: 10,
            recent: true
          }
        }}
        enableFetchMore
      />
      <UpcomingExperiencesSection />
      <FeaturedThinkfluencers />
      <ContentCardSection
        sectionTitle="Trending"
        variables={{
          filter: {
            limit: 10
          },
          sorting: ContentCardsSorting.WeeklyTrending
        }}
        enableFetchMore
      />
      <ContentCardSection
        sectionTitle="Most Popular"
        variables={{
          filter: {
            limit: 10
          },
          sorting: ContentCardsSorting.Trending
        }}
        enableFetchMore
      />
    </Flex>
  </>
);

export default Explore;
