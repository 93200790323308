import { Avatar, Circle, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { resizeImage } from '@tigerhall/core';
import { DateTime } from 'luxon';

import { NameWithBadge } from '~/components/ui/User/NameWithBadge';
import { getAudienceIcon } from '../../../../../utils/privacySettings';
import { type PostAuthoringProps } from '../../Post/types';

export function PostAuthoring({
  user,
  createdAt,
  privacySettings
}: PostAuthoringProps) {
  const author = `${user.firstName || ''} ${user.lastName || ''}`;
  const imageUrl = user.avatarImage?.uri;

  return (
    <HStack>
      <Avatar
        src={resizeImage({
          url: imageUrl,
          width: 88,
          height: 88
        })}
        name={author}
        showBorder={true}
        borderWidth="2px"
        borderColor="darkGrey.300"
        size="md"
      />
      <VStack alignItems="flex-start" spacing="0.25rem">
        <NameWithBadge
          userId={user.id}
          spacing={1}
          fontSize="md"
          fontWeight="bold"
          color="lightGrey.200"
          noOfLines={1}
          badgeProps={{
            size: 'lg'
          }}
        />
        <HStack spacing="0.375rem">
          <Text
            as="span"
            fontSize="sm"
            fontWeight="normal"
            color="lightGrey.900"
            noOfLines={1}
          >
            {createdAt ? DateTime.fromISO(createdAt).toRelative() : null}
          </Text>
          <Circle size="0.125rem" bg="lightGrey.900" />
          <Icon as={getAudienceIcon(privacySettings)} color="lightGrey.900" />
        </HStack>
      </VStack>
    </HStack>
  );
}
