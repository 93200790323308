import { Flex, Heading, Text } from '@chakra-ui/react';
import { Button, StepIndicator, THLink } from '@tigerhall/ui-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type TaskSuccessProps = {
  // trailId?: string;
  assignTrail: () => void;
};

export function TrailSuccess({ assignTrail }: Readonly<TaskSuccessProps>) {
  const history = useNavigate();
  const onAssignTrail = () => {
    assignTrail();
    history(`/admin/assignments/draft`, { replace: true });
  };

  return (
    <Flex
      backgroundColor="darkGrey.400"
      p="2rem"
      justifyContent="center"
      borderRadius="md"
    >
      <Flex direction="column" alignItems="center" gap="1rem" maxW="46.25rem">
        <StepIndicator stepCount={3} currentStep={3} minW="20rem" />
        <Heading fontSize="1.75rem" fontWeight={700}>
          Success!
        </Heading>
        <Text textAlign="center">
          You&apos;ve created your own Trail! Awesome. Now, assign this trail to
          users of your team, or go back to the Overview page.
        </Text>
        <Flex gap="1rem" mt="2rem">
          <Button
            size="lg"
            variant="outline"
            text="Assign this Trail"
            onClick={onAssignTrail}
          />
          <THLink name="RETURN_TO_OVERVIEW" to="../">
            <Button
              text="Return to Overview"
              dataCy="return-to-overview"
              size="lg"
            />
          </THLink>
        </Flex>
      </Flex>
    </Flex>
  );
}
