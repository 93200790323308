import { useEffect, useRef } from 'react';
import { noop } from '@chakra-ui/utils';
import { StreamStatus } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';

import { useMediaAnalytics, useMediaPlayer } from '~/player/hooks';
import {
  MediaPlayerContextProvider,
  type MediaPlayerContextProviderProps
} from '~/player/context';
import { type MinimizedPlayerProps } from '~/player/components/MinimizedPlayer';
import {
  LiveContentActivePlayer,
  LiveContentActivePlayerProps
} from './components/LiveContentActivePlayer';
import { useVideoHls } from '~/player/hooks/useVideoHls';
import { getDidStreamEnd } from '~/player/utils/stream';

export interface LiveContentProps
  extends Pick<
      LiveContentActivePlayerProps,
      | 'featuredUser'
      | 'onMinimize'
      | 'onMaximize'
      | 'isMinimized'
      | 'urlToShare'
      | 'disableShare'
    >,
    Pick<MediaPlayerContextProviderProps, 'customDuration'>,
    Pick<MinimizedPlayerProps, 'onClose'> {
  src: string;
  meta: {
    contentId: string;
    contentName: string;
    preamble: string;
  };
  onTrackProgress: (progress: number) => void;
  onTrackFinished: () => void;
  onFinished?: () => void;
  streamStatus: StreamStatus;

  /**
   * If the player should be hidden without being unmounted.
   * This is useful if you want to keep the player state but not have it visible,
   * e.g. if you want to open a video and then resume the podcast after the video is closed.
   */
  isHidden?: boolean;
}

export function LiveContent({
  src,
  meta: { contentId, contentName, preamble },

  onTrackProgress,
  onTrackFinished,
  onFinished = noop,
  isHidden = false,

  // from `LiveContentActivePlayerProps`
  featuredUser,
  onMinimize,
  onMaximize,
  isMinimized,
  urlToShare,
  streamStatus,

  // from `MediaPlayerContextProviderProps`
  customDuration,

  // from `MinimizedPlayerProps`
  onClose,
  disableShare
}: Readonly<LiveContentProps>) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const didStreamEnd = getDidStreamEnd(streamStatus);

  useMediaAnalytics({
    contentId,
    contentType: 'Stream',
    onTrackProgress,
    onTrackFinished,
    disableTimeSpentReporting: didStreamEnd,
    playerState: isMinimized ? 'Minimized' : 'Maximized'
  });

  const player = useMediaPlayer(videoRef, {
    onFinished,
    customDuration,
    defaultAutoPlay: true
  });

  const { hls, isBuffering } = useVideoHls(src, videoRef);

  // Note: Pull this out in a hook with other segment
  // events to track live contents with web livestreams
  useEffect(() => {
    if (didStreamEnd) {
      segment.liveEventEndedScreen({
        contentId,
        contentName,
        type: 'broadcast'
      });
    }
  }, [contentId, contentName, didStreamEnd]);

  if (isHidden) {
    player.pause();
  }

  const handleClose = () => {
    // to make sure the video doesn't keep playing in the background
    // @see https://tigerhall.atlassian.net/browse/ENG-5559
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.src = '';
      hls.detachMedia();
      hls.destroy();
    }
    onClose();
  };

  return (
    <MediaPlayerContextProvider {...player}>
      <LiveContentActivePlayer
        contentId={contentId}
        contentTitle={contentName}
        contentDescription={preamble}
        featuredUser={featuredUser}
        handleClose={handleClose}
        onMaximize={onMaximize}
        onMinimize={onMinimize}
        isMinimized={isMinimized}
        urlToShare={urlToShare}
        videoRef={videoRef}
        streamStatus={streamStatus}
        isBuffering={isBuffering}
        disableShare={disableShare}
      >
        <video
          ref={videoRef}
          controls={false}
          src={src}
          autoPlay
          crossOrigin="anonymous"
          controlsList="nodownload"
          disablePictureInPicture
          playsInline
          style={{
            width: '100%',
            height: '100%',
            maxHeight: '100vh',
            objectFit: 'contain'
          }}
        ></video>
      </LiveContentActivePlayer>
    </MediaPlayerContextProvider>
  );
}
