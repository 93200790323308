import { useRef, useCallback, useEffect } from 'react';
import { useInterval, useTabFocusHandler } from '@tigerhall/core';

import {
  reportProgress,
  useAnalytics,
  type UseAnalyticsArgs
} from '~/player/hooks/useAnalytics';
import { usePowerReadState } from '~/player/PowerRead/context/usePowerReadState';

export function usePowerReadAnalytics({
  contentId,
  contentType,
  playlistId,
  playlistType,
  playerState,
  onTrackProgress,
  onTrackProgressIntervalMS = 10_000,
  onTrackFinished,
  disableTimeSpentReporting,
  isOffline = false,
  language
}: Readonly<UseAnalyticsArgs>) {
  const powerReadState = usePowerReadState((state) => ({
    chapterIndex: state.chapterIndex,
    pageIndex: state.pageIndex,
    pagesTotal: state.pagesTotal,
    isPlayingAudio: state.isPlayingAudio
  }));

  const { startedTrackingAt, prevReportingTime, markedAsFinished } =
    useAnalytics();

  // This is used to compare the current state to the previous state when reporting consumption analytics
  const prevReportingState = useRef(powerReadState);

  const { isFocused } = useTabFocusHandler();

  const reportTimeSpent = useCallback(
    (state: typeof powerReadState) => {
      const newReportingTime = Date.now();

      // How long time has passed for the person viewing since we last reported
      const duration = (newReportingTime - prevReportingTime.current) / 1000;

      // Too soon to report again, hold on cowboy
      if (duration < 2) {
        return;
      }

      /**
       * Update the previous reporting time before checking if we should report the time spent.
       * This is to make sure that later, if we decide to start reporting the time spent again,
       * we don't report the time spent that was accumulated during the time we were
       * not able to report the time spent.
       */
      prevReportingTime.current = newReportingTime;

      if (disableTimeSpentReporting) {
        return;
      }

      reportProgress({
        contentId,
        contentType,
        playlistId,
        playlistType,
        duration,
        position: {
          total: state.pagesTotal,
          previous: prevReportingState.current.pageIndex,
          current: state.pageIndex
        },
        startedTrackingAt: new Date(startedTrackingAt.current),
        playerState: !isFocused ? 'Backgrounded' : playerState,
        isOffline,
        language,
        mediaType: state.isPlayingAudio ? 'audio' : 'text'
      });

      onTrackProgress(state.pageIndex);

      prevReportingState.current = state;
    },
    [
      prevReportingTime,
      disableTimeSpentReporting,
      contentId,
      contentType,
      playlistId,
      playlistType,
      startedTrackingAt,
      isFocused,
      playerState,
      isOffline,
      onTrackProgress
    ]
  );

  /**
   * Mark the content as finished if the user has reached the last page.
   */
  useEffect(() => {
    if (
      powerReadState.pageIndex === powerReadState.pagesTotal - 1 &&
      !markedAsFinished.current
    ) {
      markedAsFinished.current = true;
      onTrackFinished();
    }

    reportTimeSpent(powerReadState);
  }, [markedAsFinished, onTrackFinished, powerReadState, reportTimeSpent]);

  /**
   * This useEffect is used to handle the tab-focus/sleep side effect.
   * We want to report the time spent when the user focuses or unfocuses the tab.
   */
  useEffect(() => {
    reportTimeSpent(powerReadState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  /**
   * Report the time spent at regular intervals.
   */
  useInterval(() => {
    reportTimeSpent(powerReadState);
  }, onTrackProgressIntervalMS);
}
