import {
  Box,
  Flex,
  HStack,
  Icon,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import { TigerhallSkeleton } from '@tigerhall/ui-components';
import { IconChevronDown } from '@tigerhall/icons';
import map from 'lodash/map';
import { type Expert } from '@tigerhall/core/lib/types';
import { useGetRecommendedExpertsQuery } from 'generated';
import { TrackedLink, UserStory } from '@tigerhall/components';
import { DummyCopies } from 'components/ui';
import { Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

interface FeaturedThinkfluencersProps {
  heading?: string;
  showSeeAll?: boolean;
}

export function FeaturedThinkfluencers({
  heading,
  showSeeAll = true
}: Readonly<FeaturedThinkfluencersProps>) {
  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { fallback: 'md', ssr: false }
  );

  const { error, loading, data } = useGetRecommendedExpertsQuery();

  if (error) {
    return null;
  }

  return (
    <Box w="100%">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        gap="1rem"
        px={{ base: '1rem', md: '2rem' }}
        pb="1rem"
      >
        <Text
          fontSize={{ base: '1.25rem', md: '2xl' }}
          fontWeight="bold"
          color="lightGrey.200"
          as="h2"
        >
          {heading ?? 'Featured Thinkfluencers'}
        </Text>

        {showSeeAll ? (
          <TrackedLink
            as="button"
            variant="inline"
            name="ALL_THINKFLUENCERS"
            href="/search?tab=People"
            fontWeight="bold"
            iconSpacing="0.25rem"
            rightIcon={
              <Icon
                as={IconChevronDown}
                boxSize="0.75rem"
                transform="rotate(-90deg)"
                aria-hidden
              />
            }
          >
            See All
          </TrackedLink>
        ) : null}
      </Flex>

      {loading ? (
        <HStack p="2rem" spacing="2rem">
          <DummyCopies count={10}>
            <Box w={164} h={230}>
              <TigerhallSkeleton variant="stream_story" />
            </Box>
          </DummyCopies>
        </HStack>
      ) : null}

      <Swiper
        modules={[Mousewheel, Navigation]}
        spaceBetween={16}
        slidesPerView="auto"
        mousewheel={{
          forceToAxis: true,
          sensitivity: 1.5
        }}
        className="content-cards-carousel"
        navigation={!isMobile}
        style={{
          paddingLeft: '2rem',
          paddingRight: '2rem'
        }}
      >
        {map(data?.me?.expertRecommendations.edges, (user: Expert) => (
          <SwiperSlide
            key={user.id}
            style={{
              width: 'fit-content',
              cursor: 'pointer'
            }}
          >
            <TrackedLink
              name="USER_AVATAR"
              href={`/profile/${user.id}`}
              key={user.id}
            >
              <UserStory
                user={{
                  id: user.id,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  jobTitle: user.title,
                  company: user.company ?? '',
                  image: user.image ?? { uri: '' }
                }}
              />
            </TrackedLink>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
