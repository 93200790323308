import * as React from 'react';
import { Flex, Heading, Progress, Text } from '@chakra-ui/react';
import { SingleFullWidthBox } from 'components/ui';

interface LoadingScreenProps {
  /**
   * Progress of the upload from 0 to 100
   */
  progress: number;

  /**
   * The loading screen title
   */
  title: string;
}

export function LoadingScreen({
  progress,
  title
}: Readonly<LoadingScreenProps>) {
  return (
    <SingleFullWidthBox title="" pb={8}>
      <Flex direction="column" alignItems="center">
        <Heading
          as="h2"
          fontWeight="700"
          color="white"
          mb={2}
          textTransform="uppercase"
        >
          {title}
        </Heading>

        <Text fontSize="lg" color="lightGrey.900" fontWeight="500" mb={4}>
          Please do not close this window or click back on your browser.
        </Text>

        <Progress
          size="md"
          w="80%"
          colorScheme="teal"
          borderRadius="5px"
          bg="black"
          min={0}
          max={100}
          value={progress}
        />
      </Flex>
    </SingleFullWidthBox>
  );
}
