import { Flex } from '@chakra-ui/react';
import { Typography } from '@tigerhall/ui-components';

const NoDataTab = ({ title }) => (
  <Flex mx={16} my={40} flexDirection="column" alignItems="center">
    <Typography variant="heading4" color="lightGrey.200">
      {`User has no ${title} data yet`}
    </Typography>
    <Typography variant="body" mt={4} color="lightGrey.200">
      Check back a little later.
    </Typography>
  </Flex>
);

export default NoDataTab;
