import { useMemo } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  useColorMode
} from '@chakra-ui/react';
import { AsyncSelect as ReactAsyncSelect } from 'chakra-react-select';
import {
  type UseControllerProps,
  Controller,
  useFormContext,
  UseFormReturn
} from 'react-hook-form';

import { useGooglePlacesSearch } from '../../hooks/useGooglePlacesSearch';
import { getSelectChakraStyles, scrollbarStylesForSelect } from './theme';

interface FormSelectGoogleMapsAddressProps {
  /**
   * The name of the input
   */
  name: string;

  /**
   * The label of the tooltip
   */
  label: string;

  /**
   * Placeholder text
   */
  placeholder: string;

  /**
   * If the component is disabled
   */
  disabled?: boolean;

  /**
   * If the selected option can be cleared
   */
  isClearable?: boolean;

  /**
   * react-hook-forms for rules
   */
  rules?: UseControllerProps['rules'];

  analyticsDisabled?: boolean;
}

export function FormSelectGoogleMapsAddress({
  name,
  label,
  placeholder,
  rules = {},
  isClearable = true,
  disabled = false,
  analyticsDisabled = false
}: FormSelectGoogleMapsAddressProps) {
  const { colorMode } = useColorMode();
  const search = useGooglePlacesSearch();

  const chakraStyles = useMemo(
    () => getSelectChakraStyles(colorMode),
    [colorMode]
  );

  const methods = useFormContext() as UseFormReturn & {
    formName: string;
  };

  if (methods === null) {
    return <Text color="state.error">Missing form context</Text>;
  }

  if (!methods.formName && !analyticsDisabled) {
    return <Text color="state.error">Missing form name</Text>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      render={({ field, fieldState }) => (
        <FormControl
          isInvalid={!!fieldState.error}
          isRequired={!!rules?.required}
          isDisabled={disabled}
          css={scrollbarStylesForSelect}
        >
          <FormLabel>{label}</FormLabel>
          <ReactAsyncSelect
            loadOptions={search}
            name={field.name}
            placeholder={placeholder}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            ref={field.ref}
            data-cy={`${methods.formName}-${name}`}
            getOptionLabel={(o) => o.description}
            getOptionValue={(o) => o.placeId}
            chakraStyles={chakraStyles}
            isClearable={isClearable}
            components={{
              DropdownIndicator: null
            }}
          />
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}
