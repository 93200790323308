import { ApolloError } from '@apollo/client';
import { User } from '@tigerhall/core/lib/types';
import { Navigate, useParams } from 'react-router-dom';
import {
  useGetCurrentUserQuery,
  useGetUserBasicInformationQuery
} from 'generated';
import { redirectToProfile } from 'modules/application/utils/routes';

import { Profile } from './components';

function OtherProfile() {
  const { profileId } = useParams<{ profileId: string }>();

  const { data: userData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first'
  });

  const {
    data: basicUserData,
    loading,
    error
  } = useGetUserBasicInformationQuery({
    variables: {
      id: profileId as string
    },
    fetchPolicy: 'cache-first'
  });

  const isBlocked =
    basicUserData?.user?.isBlockedByCurrentUser ||
    basicUserData?.user?.isBlockingCurrentUser ||
    false;

  if (profileId === userData?.me?.id) {
    return <Navigate to={redirectToProfile()} replace />;
  }

  return (
    <Profile
      user={basicUserData?.user as User}
      loading={loading}
      error={error as ApolloError}
      isCurrentUser={false}
      isBlocked={isBlocked}
    />
  );
}

export default OtherProfile;
