import { ContentType } from '@tigerhall/core';
import { setPlayingContent, type PlayingContent } from 'app/state';
import { useAppDispatch } from 'hooks';
import { useGetPlaylistInfoFromURL } from 'hooks/useGetPlaylistInfoFromURL';

type StartEbookParams = Pick<PlayingContent, 'id'>;

export function useEbookAction() {
  const dispatch = useAppDispatch();
  const { playlistType, playlistId, playlistTitle, contentUrl } =
    useGetPlaylistInfoFromURL();

  const startEbook = (params: StartEbookParams) => {
    dispatch(
      setPlayingContent({
        ...params,
        playlistType,
        playlistId,
        playlistTitle,
        contentUrl,
        type: ContentType.Ebook
      })
    );
  };

  return {
    startEbook
  };
}
