import { RouteObject } from 'react-router-dom';
import { ErrorBoundary } from 'components/tools/ErrorBoundary';

import BroadcastListing from './screens/BroadcastListing';
import { BroadcastForm } from './screens/BroadcastForm';
import { BroadcastSummary } from './screens/BroadcastSummary/BroadcastSummary';

const routes: RouteObject[] = [
  {
    path: 'broadcasts/',
    element: <BroadcastListing />,
    children: [
      {
        path: ':id/summary',
        element: <BroadcastSummary />
      }
    ]
  },
  {
    path: 'broadcasts/:idOrDraft/form',
    element: (
      <ErrorBoundary>
        <BroadcastForm />
      </ErrorBoundary>
    )
  }
];

export default routes;
