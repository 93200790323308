import { useEffect, useState } from 'react';
import { Button, Icon } from '@chakra-ui/react';
import * as segment from '@tigerhall/analytics';
import { IconCircleCheck, IconCirclePlus } from '@tigerhall/icons';

import { useGetCurrentUserQuery } from '~/generated';
import { useFollowUserToggle } from '~/modules/application/hooks/useFollowUserToggle';

interface FeedFollowButtonProps {
  isFollowing: boolean;
  userId: string;
}

export function FeedFollowButton({
  isFollowing,
  userId
}: Readonly<FeedFollowButtonProps>) {
  const { hadleFollowUserToggle, loading } = useFollowUserToggle();

  const [isAlreadyFollowing, setIsAlreadyFollowing] = useState(true);

  const { data } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first'
  });

  const loggedInUserId = data?.me?.id;

  useEffect(() => {
    if (!isFollowing) {
      setIsAlreadyFollowing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isAlreadyFollowing && loggedInUserId !== userId ? (
    <Button
      disabled={loading}
      zIndex={1}
      variant="ghost"
      onClick={() => {
        hadleFollowUserToggle(userId);
        segment.followButtonClicked({
          followerId: userId,
          isFollowing
        });
      }}
      leftIcon={<Icon as={isFollowing ? IconCircleCheck : IconCirclePlus} />}
      color={isFollowing ? 'lightGrey.900' : 'tigerOrange.500'}
    >
      {isFollowing ? 'Following' : 'Follow'}
    </Button>
  ) : null;
}
