import { HStack, Icon, List, ListItem, Text } from '@chakra-ui/react';
import {
  IconBan,
  IconBellOff,
  IconEyeOff,
  IconSettings
} from '@tigerhall/icons';

import { PromptModal } from '~/components/ui/modals';

interface BlockUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  isLoading: boolean;
}

export function BlockUserModal({
  isOpen,
  onClose,
  onClick,
  isLoading
}: Readonly<BlockUserModalProps>) {
  return (
    <PromptModal
      isOpen={isOpen}
      onClose={onClose}
      type="confirmation"
      heading="Block User"
      actionButton={{
        text: 'Block',
        isLoading: isLoading,
        onClick: onClick,
        'aria-label': 'Block User'
      }}
      cancelButton={{
        text: 'Cancel',
        onClick: onClose
      }}
    >
      <List spacing="1rem" pt={6}>
        <ListItem>
          <HStack gap="0.5rem">
            <Icon boxSize={5} as={IconBan} color="darkGrey.400" />
            <Text color="darkGrey.400" fontWeight="medium" fontSize="sm">
              They won&apos;t be able to see your activity and search for you.
            </Text>
          </HStack>
        </ListItem>

        <ListItem>
          <HStack gap="0.5rem">
            <Icon boxSize={5} as={IconEyeOff} color="darkGrey.400" />
            <Text color="darkGrey.400" fontWeight="medium" fontSize="sm">
              You will no longer see their activity.
            </Text>
          </HStack>
        </ListItem>

        <ListItem>
          <HStack gap="0.5rem">
            <Icon boxSize={5} as={IconBellOff} color="darkGrey.400" />
            <Text color="darkGrey.400" fontWeight="medium" fontSize="sm">
              They won’t be notified that you blocked them.
            </Text>
          </HStack>
        </ListItem>

        <ListItem>
          <HStack gap="0.5rem">
            <Icon boxSize={5} as={IconSettings} color="darkGrey.400" />
            <Text color="darkGrey.400" fontWeight="medium" fontSize="sm">
              You can unblock them at any time in Settings.
            </Text>
          </HStack>
        </ListItem>
      </List>
    </PromptModal>
  );
}
