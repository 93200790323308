import { SubscriptionPlan } from '../types';

export const subscriptionPlans: SubscriptionPlan[] = [
  {
    type: 'Individuals',
    heading: 'Individuals',
    subheading: 'For an individual user',
    plusFeatures: [],
    features: [
      'Unlimited access to all Tigerhall Content; under-20-minute Podcasts, Power Reads, Videos, Trails, Livestreams and Broadcasts',
      'A network of 2,000+ global Thinkfluencers',
      'Multi-device access on mobile, web, and Microsoft Teams',
      'Language and translation with more than 30 languages',
      'Social-native experience optimized for two-way interactions and feedback loops at scale'
    ],
    cta: {
      link: {
        id: 'individuals',
        href: '/signup/individual',
        label: 'Upgrade to Individual'
      },
      theme: 'outline'
    }
  },
  {
    type: 'Enterprises',
    heading: 'Enterprises',
    subheading: 'For more than 100 users',
    description: 'Get in touch for pricing.',
    plusFeatures: ['All Teams access'],
    features: [
      'Integration with Microsoft Teams, CRM platforms, ERP systems and corresponding tech support',
      'Custom content creation with Tigerhall Studio and livestream management',
      'Unified knowledge infrastructure for the storage and sharing of both internal and external knowledge',
      'Dynamic audience segmentation, directory sync and automated access permission management',
      'Progress and social sentiment analytics reporting'
    ],
    cta: {
      link: {
        id: 'enterprises',
        href: `${import.meta.env.VITE_WEB_URL}/contact/demo-request`,
        label: 'Request a Demo',
        isExternal: true
      },
      theme: 'solid'
    }
  }
];
