import * as React from 'react';
import {
  Assignment,
  AssignmentContent,
  AssignmentInput,
  getSessionStorage,
  OrganisationGroup,
  User
} from '@tigerhall/core';
import { ASSIGN_TRAIL_KEY } from 'modules/admin/constants';
import { typenameToContentType } from 'utils/content';

interface AssignmentDraftProviderProps {
  assignment?: Assignment;
  children: React.ReactNode;
}

type AssignmentDraftMeta = {
  title: Assignment['assignmentTitle'];
  reason: Assignment['assignmentReason'];
  dueDate: Assignment['dueDate'];
};

interface AssignmentDraftProps {
  contents: AssignmentContent[];
  usersIds: User['id'][];
  groupsIds: OrganisationGroup['id'][];
  title: AssignmentDraftMeta['title'];
  reason: AssignmentDraftMeta['reason'];
  dueDate: AssignmentDraftMeta['dueDate'];
}

interface AssignmentDraftContextProps {
  draft: AssignmentDraftProps;
  contents: AssignmentDraftProps['contents'];
  usersIds: AssignmentDraftProps['usersIds'];
  groupsIds: AssignmentDraftProps['groupsIds'];
  meta: AssignmentDraftMeta;
  setContents: React.Dispatch<
    React.SetStateAction<AssignmentDraftProps['contents']>
  >;
  setUsersIds: React.Dispatch<
    React.SetStateAction<AssignmentDraftProps['usersIds']>
  >;
  setGroupsIds: React.Dispatch<
    React.SetStateAction<AssignmentDraftProps['groupsIds']>
  >;
  setMeta: React.Dispatch<React.SetStateAction<AssignmentDraftMeta>>;
  clearDraft: () => void;
  parseDraftToInput: () => AssignmentInput;
}

const AssignmentDraftContext = React.createContext<
  AssignmentDraftContextProps | undefined
>(undefined);

export function AssignmentDraftProvider({
  assignment,
  children
}: Readonly<AssignmentDraftProviderProps>) {
  const assignTrail = getSessionStorage(
    ASSIGN_TRAIL_KEY
  ) as AssignmentContent | null;

  const defaultContents =
    (assignment?.contents?.edges as AssignmentContent[]) || assignTrail
      ? [assignTrail as AssignmentContent]
      : [];

  const [contents, setContents] =
    React.useState<AssignmentDraftContextProps['contents']>(defaultContents);

  const [usersIds, setUsersIds] = React.useState<
    AssignmentDraftContextProps['usersIds']
  >(assignment?.users?.edges?.map((user) => user?.id) || []);

  const [groupsIds, setGroupsIds] = React.useState<
    AssignmentDraftContextProps['groupsIds']
  >(assignment?.orgGroups?.map((group) => group?.id) || []);

  const [meta, setMeta] = React.useState<AssignmentDraftContextProps['meta']>({
    title: assignment?.assignmentTitle || '',
    reason: assignment?.assignmentReason || '',
    dueDate: assignment?.dueDate
  });

  function clearDraft() {
    setContents([]);
    setUsersIds([]);
    setGroupsIds([]);
    setMeta({
      title: '',
      reason: '',
      dueDate: undefined
    });
  }

  const draft = React.useMemo(
    () => ({
      contents,
      usersIds,
      groupsIds,
      ...meta
    }),
    [contents, groupsIds, meta, usersIds]
  );

  const parseDraftToInput = React.useCallback(
    (): AssignmentInput => ({
      contents: contents.map((contentCard, index) => ({
        id: contentCard.id,
        contentType: typenameToContentType(contentCard.__typename),
        index
      })),
      users: usersIds,
      orgGroups: groupsIds,
      assignmentTitle: meta.title,
      assignmentReason: meta.reason,
      dueDate: meta.dueDate
    }),
    [contents, groupsIds, meta.dueDate, meta.reason, meta.title, usersIds]
  );

  const value = React.useMemo(
    () => ({
      draft,
      setContents,
      setUsersIds,
      setGroupsIds,
      setMeta,
      clearDraft,
      parseDraftToInput,
      contents,
      usersIds,
      groupsIds,
      meta
    }),
    [contents, draft, groupsIds, meta, parseDraftToInput, usersIds]
  );

  return (
    <AssignmentDraftContext.Provider value={value}>
      {children}
    </AssignmentDraftContext.Provider>
  );
}

export function useAssignmentDraftContext() {
  const context = React.useContext(AssignmentDraftContext);

  if (!context) {
    throw new Error(
      'useAssignmentDraftContext must be used within a AssignmentDraftProvider'
    );
  }

  return context;
}
