import { useState } from 'react';

import { PodcastPlayer, type PodcastPlayerProps } from './components';
import {
  useGetPodcastForConnectedPodcastPlayerQuery,
  useGetPodcastTranscriptQuery
} from './queries';
import { PlayerLoader } from '../PlayerLoader';

interface PodcastPlayerConnectedProps
  extends Pick<
    PodcastPlayerProps,
    'onFinished' | 'onClose' | 'isMinimized' | 'onMaximize' | 'setIsMinimized'
  > {
  id: string;
  userPreferredLanguage: string;
}

export function PodcastPlayerConnected({
  id,
  onFinished,
  onClose,
  onMaximize,
  isMinimized,
  setIsMinimized,
  userPreferredLanguage
}: Readonly<PodcastPlayerConnectedProps>) {
  const [audioLanguage, setAudioLanguage] = useState<string>(
    userPreferredLanguage
  );
  const [transcriptLanguage, setTranscriptLanguage] = useState<string | null>(
    userPreferredLanguage
  );

  const { data: { podcast } = {} } =
    useGetPodcastForConnectedPodcastPlayerQuery({
      variables: {
        id,
        audioLanguage
      },
      fetchPolicy: 'cache-first',
      onCompleted: (data) => {
        /**
         * We are setting English as the audio language
         * if podcast is not available in the preferred language
         * set by the user from the languages in settings.
         */

        if (data.podcast?.episodes.length === 0) {
          setAudioLanguage('EN');
        }
      }
    });

  const { data: { podcast: podcastTranscripts } = {} } =
    useGetPodcastTranscriptQuery({
      variables: {
        id,
        transcriptLanguage
      },
      fetchPolicy: 'cache-first',
      skip: !transcriptLanguage,
      onCompleted: (data) => {
        /**
         * We are setting English as the transcript language
         * if a transcript for the podcast is not available in the preferred language
         * set by the user from the languages in settings.
         */

        if (!data.podcast?.transcripts.language) {
          setTranscriptLanguage('EN');
        }
      }
    });

  // if podcast episodes array is empty we keep showing the loader
  // until we query for default language content
  if (!podcast || podcast.episodes.length === 0) {
    return <PlayerLoader />;
  }

  return (
    <PodcastPlayer
      podcast={podcast}
      podcastTranscripts={podcastTranscripts}
      onFinished={onFinished}
      setAudioLanguage={setAudioLanguage}
      audioLanguage={audioLanguage}
      setTranscriptLanguage={setTranscriptLanguage}
      transcriptLanguage={transcriptLanguage}
      onClose={onClose}
      onMaximize={onMaximize}
      isMinimized={isMinimized}
      setIsMinimized={setIsMinimized}
    />
  );
}
