import { Box } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

type MeetingsEmbedProps = {
  paddingTop?: string;
};

export function MeetingsEmbed({ paddingTop }: MeetingsEmbedProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    script.async = true;

    const container = containerRef.current;

    if (container) {
      container.appendChild(script);
    }

    return () => {
      if (container) {
        container.removeChild(script);
      }
    };
  }, []);
  return (
    <Box
      pt={paddingTop ?? '3rem'}
      height="auto"
      minHeight="40rem"
      width="100%"
      ref={containerRef}
      className="meetings-iframe-container"
      data-src="https://meetings.hubspot.com/brittany-gunter/demo-team-booking?embed=true"
    />
  );
}
