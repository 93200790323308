import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chapter } from '@tigerhall/core';
import { RootState } from 'app/reducer';
import { EditorStateProps } from 'modules/admin/modules/ebooks/utils';

const initialState: EditorStateProps = {
  chapters: [{ pages: [''], index: 0, name: 'chapter 1' }],
  editorContent: '',
  currentPage: 0,
  chapterName: '',
  currentChapter: 0
};

export const ebookEditorSlice = createSlice({
  name: 'ebookEditor',
  initialState,
  reducers: {
    setContentToCurrentPage: (state) => {
      if (state.chapters[state.currentChapter]) {
        state.chapters[state.currentChapter].name = state.chapterName;
        state.chapters[state.currentChapter].pages[state.currentPage] =
          state.editorContent;
      }
    },
    setEditorContent: (state, action: PayloadAction<string>) => {
      state.editorContent = action.payload;
    },
    setEbookName: (state, action: PayloadAction<string>) => {
      state.ebookName = action.payload;
    },
    setChapterName: (state, action: PayloadAction<string>) => {
      state.chapterName = action.payload;
    },
    removeChapter: (state, action: PayloadAction<number>) => {
      const indexToRemove = action.payload;
      const totalChapters = state.chapters.length;
      const isLastChapter = indexToRemove === totalChapters - 1;

      if (state.currentChapter >= indexToRemove) {
        // If currentChapter was the last chapter
        if (isLastChapter && state.currentChapter !== 0) {
          state.currentChapter = totalChapters - 2;
        } else if (state.currentChapter !== 0) {
          state.currentChapter--;
        }
      }

      state.chapters = state.chapters.filter((_, dex) => dex !== indexToRemove);
      state.chapterName = state.chapters[state.currentChapter].name;
    },
    setChapter: (state, action: PayloadAction<number>) => {
      state.currentChapter = action.payload;
      state.currentPage = 0;
      state.chapterName = state.chapters[action.payload].name;
    },
    addChapter: (state) => {
      const chapters = state.chapters;
      const newChapterIndex = chapters.length;

      chapters.push({
        pages: [''],
        name: `Chapter ${newChapterIndex + 1}`,
        index: newChapterIndex
      });
      state.currentChapter = newChapterIndex;
      state.currentPage = 0;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    addPage: (state) => {
      const currentChapter = state.chapters[state.currentChapter];
      if (currentChapter) {
        currentChapter.pages.push('');
        state.currentPage = currentChapter.pages.length - 1;
      }
    },
    removePage: (state, action: PayloadAction<number>) => {
      const pageToRemove = action.payload;
      const currentChapter = state.chapters[state.currentChapter];
      const totalPages = currentChapter.pages.length;
      const isLastPage = pageToRemove + 1 === totalPages;

      if (state.currentPage >= pageToRemove) {
        if (isLastPage) {
          state.currentPage = totalPages - 2;
        } else if (state.currentPage !== 0) {
          state.currentPage--;
        }
      }

      currentChapter.pages.splice(pageToRemove, 1);
    },
    setChapters: (
      state,
      action: PayloadAction<Omit<Chapter, '__typename'>[]>
    ) => {
      if (action.payload.length) {
        state.chapters = action.payload;
      }
    },
    resetEbookEditorState: () => initialState
  }
});

export const {
  removeChapter,
  setChapter,
  addChapter,
  setPage,
  addPage,
  removePage,
  setChapters,
  resetEbookEditorState,
  setEditorContent,
  setContentToCurrentPage,
  setEbookName,
  setChapterName
} = ebookEditorSlice.actions;

export function getChapters(state: RootState) {
  return state.ebookEditor.chapters;
}

export function getPages(state: RootState) {
  const chapters = getChapters(state);
  const currentChapterIndex = state.ebookEditor.currentChapter;

  if (chapters[currentChapterIndex]) {
    return chapters[currentChapterIndex].pages;
  }

  return [];
}

export function getCurrentChapter(state: RootState) {
  const { chapters, currentChapter } = state.ebookEditor;
  return chapters[currentChapter];
}

export function getCurrentPage(state: RootState) {
  const currentChapter = getCurrentChapter(state);
  if (currentChapter) {
    return currentChapter.pages?.[state.ebookEditor.currentPage];
  }
  return null;
}

export function getCurrentChapterIndex(state: RootState) {
  return state.ebookEditor.currentChapter;
}

export function getCurrentPageIndex(state: RootState) {
  return state.ebookEditor.currentPage;
}

export function getEbookName(state: RootState) {
  return state.ebookEditor.ebookName;
}

export function getEbookEditorContent(state: RootState) {
  return state.ebookEditor.editorContent;
}

export function getChapterInputName(state: RootState) {
  return state.ebookEditor.chapterName;
}
