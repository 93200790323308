import { Center, Text, VStack } from '@chakra-ui/react';
import { Content } from '@tigerhall/core';
import { ContentCardSection } from 'modules/application/components';

import {
  useGetContentExpertAndCategoryIDsQuery,
  useGetRecommendedContentCardsLazyQuery
} from './queries';

export interface SuggestedTabProps {
  id: string;
}

interface ExpertsAndCategories {
  __typename: 'Ebook' | 'Event' | 'Podcast' | 'Stream';
  experts: { __typename: 'Expert'; id: string }[];
  categories: { __typename: 'Category'; id: string }[];
}

export function SuggestedTab({ id }: Readonly<SuggestedTabProps>) {
  const [getRecommendedContent, { data, loading, error }] =
    useGetRecommendedContentCardsLazyQuery();

  useGetContentExpertAndCategoryIDsQuery({
    variables: { id: id },
    fetchPolicy: 'cache-first',
    onCompleted: (response) => {
      const expertsAndCategories =
        response.expertsAndCategories as ExpertsAndCategories;

      const { experts, categories } = expertsAndCategories;

      const variables = {
        categoryFilter: {
          limit: 20,
          categories: categories.map((category) => category.id)
        },
        expertFilter: {
          limit: 20,
          experts: experts.map((expert) => expert.id)
        }
      };

      getRecommendedContent({
        variables: variables
      });
    }
  });

  /**
   *  Filtering `sameCategoryCards` & `sameExpertCards`
   *  Removing `__typename = 'LearningPath'` & `__typename = 'Expert'`
   *  as, these two contentCards won't have experts or categories inside.
   */
  const sameCategoryCards = data?.sameCategoryCards.edges.filter(
    (card) => card.__typename !== 'LearningPath' && card.__typename !== 'Expert'
  );

  const sameExpertCards = data?.sameExpertCards.edges.filter(
    (card) => card.__typename !== 'LearningPath' && card.__typename !== 'Expert'
  );

  return (
    <VStack
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      width={'full'}
      spacing={10}
      pb={10}
    >
      {error ? (
        <Center p={10} flexDirection={'column'} gap={2}>
          <Text fontSize={{ base: 'sm', lg: 'md' }} textAlign={'center'}>
            Unable to find relevant content at this time.
          </Text>
          <Text fontSize={{ base: 'xs', lg: 'sm' }} textAlign={'center'}>
            Please visit the home page for more content similar to this.
          </Text>
        </Center>
      ) : (
        <>
          <ContentCardSection
            contentCardData={sameExpertCards as Content[]}
            sectionId="suggested-picks"
            sectionTitle="With This Thinkfluencer"
            titleProps={{ fontSize: 'lg' }}
            dataLoading={loading}
          />
          <ContentCardSection
            contentCardData={sameCategoryCards as Content[]}
            sectionId="suggested-picks"
            sectionTitle="Under This Category"
            titleProps={{ fontSize: 'lg' }}
            dataLoading={loading}
          />
        </>
      )}
    </VStack>
  );
}
