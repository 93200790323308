import { Flex, Icon, Text, VStack } from '@chakra-ui/react';
import { type Stream } from '@tigerhall/core';
import { IconShare3 } from '@tigerhall/icons';
import {
  type ExpertBasicInfo,
  LiveBadge,
  ProfileBar
} from '@tigerhall/components';
import { Share } from 'components/ui/Share';
import { getUrlToShareForLiveContent } from 'components/ui/ContentPlayer/components/LiveContentConnected/utils';

export interface LiveHeaderProps {
  stream: Pick<Stream, 'id' | 'name' | 'preamble' | 'source'>;
  expert: ExpertBasicInfo;
}

export function LiveHeader({ expert, stream }: Readonly<LiveHeaderProps>) {
  const urlToShare = getUrlToShareForLiveContent({
    id: stream.id,
    source: stream.source
  });

  return (
    <VStack p={4} alignItems={'flex-start'} spacing={4}>
      <Flex
        justifyContent={'space-between'}
        w={'full'}
        alignItems={'flex-start'}
      >
        <ProfileBar profile={expert} size={'sm'} />
        <LiveBadge />
      </Flex>
      <Text color={'lightGrey.200'} fontWeight={'bold'} lineHeight={1.5}>
        {stream.name}
      </Text>
      <Share
        titleToShare={stream.name}
        descriptionToShare={stream.preamble}
        urlToShare={urlToShare}
        variant={'solidDark'}
        size={'md'}
        fontSize={'xs'}
        fontWeight={'medium'}
        color={'darkGrey.50'}
        borderRadius={'full'}
        iconSpacing={1}
        leftIcon={<Icon as={IconShare3} boxSize={'1rem'} />}
        content={{
          __typename: 'Stream',
          ...stream
        }}
      >
        Share
      </Share>
    </VStack>
  );
}
