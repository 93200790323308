import { Text } from '@chakra-ui/react';
import { DarkPanel } from 'components/ui';
import { TrackedLink } from '@tigerhall/components';

export function BlockedUsersSection() {
  return (
    <DarkPanel w="100%" gap="1.5rem">
      <Text fontSize="xl" fontWeight="700" color="white">
        Blocked Users
      </Text>
      <Text fontSize="md" fontWeight="500" color="darkGrey.50">
        View and manage users that you’ve blocked on the Tigerhall platform.
      </Text>
      <TrackedLink
        name="MANAGE_BLOCKED_USERS"
        href="/profile/settings/blocked-users"
        as="button"
        size="lg"
        maxW="20rem"
      >
        Manage Blocked Users
      </TrackedLink>
    </DarkPanel>
  );
}
