import * as React from 'react';
import { Image } from '@chakra-ui/react';
import { SimpleImage, resizeImage, transformDimensions } from '@tigerhall/core';

interface OrganisationLogoProps extends SimpleImage {
  width: number;
  height: number;
}

export function OrganisationLogo({
  uri,
  alt,
  ...props
}: OrganisationLogoProps) {
  if (!uri) {
    return null;
  }

  const { width, height } = transformDimensions({
    width: props.width,
    height: props.height,
    objectiveHeight: 52
  });

  return (
    <Image
      src={resizeImage({ url: uri, width, height })}
      alt={alt ?? ''}
      width={`${width / 16}rem`}
      height={`${height / 16}rem`}
      objectFit="contain"
    />
  );
}
