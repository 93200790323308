import { ButtonGroup, Icon, IconButton } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { IconThumbDown, IconThumbUp } from '@tigerhall/icons';
import { ContentVote } from '@tigerhall/core';

export interface RatingProps {
  /**
   * The user vote for the content.
   * If the user has not voted yet, this should be `UNDETERMINED`.
   */
  vote: ContentVote;
  /** Callback when the user clicks the like button. */
  onLike: () => void;
  /** Callback when the user clicks the dislike button. */
  onDislike: () => void;
  /**
   * This is to indicate that the user vote is being processed/loading.
   * If true, the like and dislike buttons will be disabled.
   *
   * @default false
   */
  isVoting?: boolean;
}

export const BUTTON_SIZE = '1.75rem';
const PADDING_FIX = '0.125rem';
const COMMON_BUTTON_PROPS = {
  fontSize: 'sm',
  minWidth: BUTTON_SIZE,
  height: BUTTON_SIZE
};

/**
 * Uncontrolled rating buttons for the content player.
 *
 * Both, the like and dislike buttons are rendered when the user has not voted yet.
 * If the user has voted, only the button for the vote is rendered.
 *
 * To control the vote, pass the `vote` prop, and to handle the user vote, pass the
 * `onLike`, `onDislike` and `onUnvote` props.
 *
 * @example```tsx
 * <Rating
 *     vote={vote}
 *     onLike={() => setVote(ContentVote.Upvote)}
 *     onDislike={() => setVote(ContentVote.Downvote)}
 *     onUnvote={() => setVote(undefined)}
 *   />
 * ```
 */
export function Rating({ vote, onLike, onDislike, isVoting }: RatingProps) {
  switch (vote) {
    case ContentVote.Upvote:
      return (
        <IconButton
          {...COMMON_BUTTON_PROPS}
          pb={PADDING_FIX}
          aria-label={'like'}
          variant={'solidDark'}
          backgroundColor={'tigerOrange.600'}
          icon={<Icon as={IconThumbUp} />}
          isDisabled={isVoting}
          onClick={() => {
            onLike();
          }}
          as={motion.button}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        />
      );
    case ContentVote.Downvote:
      return (
        <IconButton
          {...COMMON_BUTTON_PROPS}
          pt={PADDING_FIX}
          aria-label={'dislike'}
          variant={'solidDark'}
          backgroundColor={'tigerOrange.600'}
          icon={<Icon as={IconThumbDown} />}
          isDisabled={isVoting}
          onClick={() => {
            onDislike();
          }}
          as={motion.button}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        />
      );
    default:
      return (
        <ButtonGroup>
          <IconButton
            {...COMMON_BUTTON_PROPS}
            pb={PADDING_FIX}
            aria-label={'like'}
            variant={'outline'}
            icon={<Icon as={IconThumbUp} />}
            isDisabled={isVoting}
            onClick={onLike}
            as={motion.button}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
          <IconButton
            {...COMMON_BUTTON_PROPS}
            pt={PADDING_FIX}
            aria-label={'dislike'}
            variant={'outline'}
            icon={<Icon as={IconThumbDown} />}
            isDisabled={isVoting}
            onClick={onDislike}
            as={motion.button}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        </ButtonGroup>
      );
  }
}
