import { Fragment } from 'react';
import { Box, Divider, Text, VStack } from '@chakra-ui/react';
import { ProfileBar } from '@tigerhall/components';

import { type LiveGuestFragment } from './queries/generated';

interface GuestsListProps {
  experts: LiveGuestFragment[];
}

export function GuestsList({ experts }: Readonly<GuestsListProps>) {
  return (
    <>
      <VStack alignItems={'flex-start'} spacing={'3'} marginX={'4'}>
        <Text color={'lightGrey.200'} fontSize={'xl'} fontWeight={'bold'}>
          Guests
        </Text>
        <Box color={'lightGrey.600'} fontSize={'sm'} fontWeight={'medium'}>
          Curious about today&apos;s guests? Click on their profile to learn
          more about what they do and browse their content on Tigerhall.
        </Box>
      </VStack>
      <VStack spacing={'2'} w={'full'} alignItems={'flex-start'}>
        {experts.map((expert) => (
          <Fragment key={`guest-${expert.id}`}>
            <Divider />
            <Box paddingX={4} w={'full'}>
              <ProfileBar
                profile={{
                  ...expert,
                  // override this to make sure it's never `undefined`
                  __typename: 'Expert'
                }}
                size={'sm'}
              />
            </Box>
          </Fragment>
        ))}
        <Divider />
      </VStack>
    </>
  );
}
