import * as React from 'react';
import { BoxProps, Flex, Heading, HStack, Text } from '@chakra-ui/react';
import { TrackedButton, TrackedButtonProps } from '@tigerhall/components';
import { DarkPanel } from 'components/ui/containers/DarkPanel';

type Props = {
  /**
   * The primary title in orange
   */
  title: string;

  /**
   * This is the title within the actual box
   */
  subTitle?: string;

  /**
   * If you want to add some buttons or drop down menus on the right side
   */
  buttons?: TrackedButtonProps[];

  /**
   * If you want to add a component before the header
   */
  beforeHeaderSlot?: React.ReactNode;

  /**
   * The contents of the content box
   */
  children: React.ReactNode;
} & BoxProps;

export default function SingleFullWidthBox({
  title,
  subTitle,
  buttons,
  children,
  beforeHeaderSlot = null,
  ...rest
}: Props) {
  return (
    <DarkPanel gap="1.5rem" {...rest}>
      {beforeHeaderSlot}
      <Flex justifyContent="space-between">
        <Heading
          fontSize="1.5rem"
          fontWeight="700"
          color="white"
          alignSelf="center"
        >
          {title}
        </Heading>

        <HStack>
          {(buttons ?? []).map(({ name, ...button }) => (
            <TrackedButton size="md" key={name} name={name} {...button} />
          ))}
        </HStack>
      </Flex>

      {subTitle && (
        <Text fontSize="1rem" fontWeight="500" color="white">
          {subTitle}
        </Text>
      )}

      {children}
    </DarkPanel>
  );
}
