import { Box, Flex, Icon, type LayoutProps } from '@chakra-ui/react';
import { useCallback } from 'react';
import {
  IconInfoCircle,
  IconMessageCircle,
  IconPlaylistPlay,
  IconThumbUp
} from '@tigerhall/icons';
import * as segment from '@tigerhall/analytics';

import { AvailableTabs, TABS_LABELS } from '../DrawerTabs/utils/tabs';

export interface IndexedSelectionButtonsProps {
  onTap: (index: number) => void;
  tabs: AvailableTabs[];
}

const iconProps: LayoutProps = {
  w: 4,
  h: 4
};

export function IndexedSelectionButtons({
  onTap,
  tabs
}: IndexedSelectionButtonsProps) {
  const renderIcon = useCallback((tab: AvailableTabs) => {
    switch (tab) {
      case AvailableTabs.Comments:
      case AvailableTabs.Chat:
        return <Icon as={IconMessageCircle} {...iconProps} />;
      case AvailableTabs.LiveMoreInfo:
      case AvailableTabs.MoreInfo:
        return <Icon as={IconInfoCircle} {...iconProps} />;
      case AvailableTabs.Suggested:
        return <Icon as={IconThumbUp} {...iconProps} />;
      case AvailableTabs.UpNext:
        return <Icon as={IconPlaylistPlay} {...iconProps} />;
      default:
        return null;
    }
  }, []);

  return (
    <Flex background="black" w="full" justifyContent="space-around" gap="2">
      {tabs.map((tabId, index) => (
        <Box
          onClick={() => {
            onTap?.(index);
            segment.tabClicked({
              tabId: tabId,
              tabName: TABS_LABELS[tabId]
            });
          }}
          color="darkGrey.50"
          as="button"
          fontSize="sm"
          fontWeight="bold"
          key={tabId}
          display="flex"
          alignItems="center"
          gap="1"
          flex="1"
          justifyContent="center"
          py="5"
          px="4"
          lineHeight="4"
        >
          {renderIcon(tabId)}
          {TABS_LABELS[tabId]}
        </Box>
      ))}
    </Flex>
  );
}
