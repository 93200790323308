import { Box, VStack } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import * as segment from '@tigerhall/analytics';
import { TrackedButton, useTrackedForm } from '@tigerhall/components';
import { type Comment, type CommentType } from '@tigerhall/core/lib/types';
import { MentionFormInput } from 'components/ui/UserMentions/form/MentionFormInput';
import { type User } from 'components/ui/UserMentions/utils/types';
import {
  useCreateCommentMutation,
  useGetCurrentUserQuery,
  useGetSuggestedUsersToInteractLazyQuery
} from 'generated';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { FormProvider } from 'react-hook-form';

interface FormFields {
  replyInput: Comment;
}

export interface ReplyInputWrapperProps {
  refetch: () => void;
  commentId: string;
  referenceId: string;
  referenceType: Exclude<CommentType, CommentType.Feed>;
  isOrganizationContent: boolean;
  onReplyCancel: () => void;
}

export function ReplyInputWrapper({
  refetch,
  commentId,
  referenceId,
  referenceType,
  onReplyCancel,
  isOrganizationContent
}: Readonly<ReplyInputWrapperProps>) {
  const form = useTrackedForm<FormFields>('REPLY');
  const toast = useToast({
    duration: 2000
  });

  const [createCommentMutation] = useCreateCommentMutation();

  const [getSuggestedUser] = useGetSuggestedUsersToInteractLazyQuery({});

  const { data: currentUser } = useGetCurrentUserQuery();

  const fetchSuggestion = async <T extends readonly unknown[]>(
    query: string
  ): Promise<User<T>> => {
    const res = await getSuggestedUser({
      variables: {
        name: query,
        limit: 4,
        isMemberOfSameOrganisation: isOrganizationContent
      }
    });
    const users = res.data?.me?.suggestedUsersToInteractWith.edges ?? [];
    return users as User<T>;
  };
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = form;

  const isPostCommentReply = referenceType === 'POST';

  const addReply = React.useCallback(
    async (data: FormFields) => {
      try {
        const { rawText, mentions, comment } = data?.replyInput ?? '';
        if (isEmpty(rawText.replace(/ /g, ''))) {
          return;
        }
        segment?.commentSent({
          commentText: data?.replyInput.comment,
          commentType: isPostCommentReply ? 'POST_REPLY' : 'CONTENT_REPLY',
          referenceId,
          referenceType,
          parentComment: commentId,
          isThinkfluencer: currentUser?.me?.isExpert
        });

        await createCommentMutation({
          variables: {
            comment: {
              referenceId,
              comment,
              rawText,
              mentions,
              type: referenceType,
              parentComment: commentId
            }
          },
          onCompleted: () => {
            React.startTransition(() => {
              toast.closeAll();
              refetch();
              onReplyCancel();
              reset({
                replyInput: {
                  rawText: ''
                }
              });
            });
          }
        });
      } catch (error) {
        toast.closeAll();
        toast({
          title: 'Error',
          description:
            'An error occurred trying to reply to this comment. Please try again later.',
          status: 'error'
        });
        captureException(new Error('Error replying to comment'), {
          tags: {
            commentId: commentId,
            [isPostCommentReply ? 'postId' : 'contentId']: referenceId
          },
          extra: {
            values: data.replyInput,
            error
          }
        });
      }
    },
    [
      toast,
      isPostCommentReply,
      referenceId,
      referenceType,
      commentId,
      currentUser?.me?.isExpert,
      createCommentMutation,
      reset,
      onReplyCancel,
      refetch
    ]
  );

  return (
    <Box w="100%">
      <FormProvider {...form}>
        <VStack
          bg="darkGrey.400"
          borderRadius="0.5rem"
          borderColor="lightGrey.600"
          borderWidth="1px"
          minH={'4.5rem'}
          pos={'relative'}
          px={'.5rem'}
          alignItems={'flex-start'}
        >
          <Box
            w="100%"
            px={'.5rem'}
            css={{
              '& label': { marginBottom: '0rem' },
              '& input': { border: 'none', paddingLeft: '0rem' }
            }}
          >
            <MentionFormInput
              fetchSuggestions={fetchSuggestion}
              name="replyInput"
              label=""
              placeholder="Add a reply"
              referenceId={referenceId}
              focusOnMount
              disabled={isSubmitting}
            />
          </Box>

          <TrackedButton
            name="mention-send-button"
            type="submit"
            variant="solid"
            size="md"
            isLoading={isSubmitting}
            onClick={handleSubmit(addReply)}
            bottom={'0.5rem'}
            alignSelf={'flex-end'}
          >
            Send
          </TrackedButton>
        </VStack>
      </FormProvider>
    </Box>
  );
}
