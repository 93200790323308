import { User } from '@tigerhall/core/lib/types';
import { Modal } from 'components/ui/Modal';

import BookMentorshipModalContent from './components/BookMentorshipModalContent';
import BookMentorshipModalBody from './components/BookMentorshipModalBody';

type BookMentorshipModalType = {
  setMentorShipModalOpen: (open: boolean) => void;
  expert: User;
};

const BookMentorshipModal = ({
  setMentorShipModalOpen,
  expert
}: BookMentorshipModalType) => (
  <Modal title="Book Mentorship" size="md">
    <BookMentorshipModalContent onClose={() => setMentorShipModalOpen(false)}>
      <BookMentorshipModalBody
        expert={expert}
        setMentorShipModalOpen={setMentorShipModalOpen}
      />
    </BookMentorshipModalContent>
  </Modal>
);
export default BookMentorshipModal;
