import {
  ProfileBar,
  ProfileBarSkeleton,
  type ProfileBarProps
} from '@tigerhall/components';

import { useUserBaseProfile } from '~/components/ui/User/hooks/useUserBaseProfile';

interface ProfileBarControlledProps extends Omit<ProfileBarProps, 'profile'> {
  /**
   * The user ID to fetch the profile for.
   */
  userId: string;
}

export function ProfileBarControlled({
  userId,
  ...props
}: Readonly<ProfileBarControlledProps>) {
  const { profile, thinkfluencerScope } = useUserBaseProfile(userId);

  if (!profile) {
    return <ProfileBarSkeleton size={props.size} />;
  }

  return (
    <ProfileBar
      profile={profile}
      thinkfluencerScope={thinkfluencerScope}
      {...props}
    />
  );
}
