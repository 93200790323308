// new `Date().toGMTString()` has been deprecated
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toGMTString
// use `toUTCString()` instead

export const EXPIRE_TIME_MULTIPLIER = 60 * 60 * 1000;
export const DELETE_COOKIE_EXPIRE_HOURS = -1;
export const AUTH_COOKIE_NAME = 'authorization';
export const AUTH_COOKIE_EXPIRE_HOURS = 720;

type SameSiteValue = 'lax' | 'strict' | 'none';

const isClientSide = typeof window !== 'undefined';

export const getCookie = (name: string): string | null => {
  const cookieValue =
    isClientSide &&
    new RegExp(`(^|;) ?${name}=([^;]*)(;|$)`).exec(document.cookie);
  return cookieValue ? cookieValue[2] : null;
};

/**
 *
 * @param name name of the cookie
 * @param value value of the cookie
 * @param hours in total hours to expire the cookie
 * @param domain domian to set the cookie for
 * @param sameSite sameSite boolean prop
 */
export const setCookie = (
  name: string,
  value: string,
  hours?: number,
  domain?: string,
  sameSite: SameSiteValue = 'lax'
): void => {
  if (isClientSide) {
    let expires = '';

    if (hours) {
      const expireDate = new Date();
      expireDate.setTime(expireDate.getTime() + hours * EXPIRE_TIME_MULTIPLIER);
      expires = `;expires=${expireDate.toUTCString()}`;
    }

    const domainValue = domain ? `;domain=${domain}` : '';

    document.cookie = `${name}=${value};path=/;secure;sameSite=${sameSite}${domainValue}${expires}`;
  }
};

/**
 *
 * @param name name of the cookie
 * @param domain name of the domain for whom to delete cookie
 */
export const deleteCookie = (
  name: string,
  domain?: string
): ReturnType<typeof setCookie> => {
  // set the expire date in the past, so the cookie will be deleted
  setCookie(name, '', DELETE_COOKIE_EXPIRE_HOURS, domain);
};
