import * as React from 'react';
import { RouteObject } from 'react-router-dom';

import { AssignmentDraftProvider } from './hooks/useAssignmentDraftContext';
import {
  AssignContent,
  AssignmentDetails,
  EditAssignmentUsers,
  InactiveAssignments,
  ManageAssignments
} from './screens';

const routes: RouteObject[] = [
  {
    path: 'assignments/',
    children: [
      {
        path: 'draft',
        element: (
          <AssignmentDraftProvider>
            <AssignContent />
          </AssignmentDraftProvider>
        )
      },
      {
        index: true,
        element: <ManageAssignments />
      },
      {
        path: 'inactive',
        element: <InactiveAssignments />
      },
      {
        path: ':id/',
        children: [
          {
            index: true,
            element: <AssignmentDetails />
          },
          {
            path: 'edit',
            element: <EditAssignmentUsers />
          }
        ]
      }
    ]
  }
];

export default routes;
