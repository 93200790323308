import * as React from 'react';
import { Box, type BoxProps } from '@chakra-ui/react';

export interface StatusBadgeProps extends BoxProps {
  variant?: 'solid' | 'outline' | 'inline';
  size?: 'sm' | 'md' | 'lg';
  status: 'success' | 'disabled' | 'error' | 'warning' | 'info';
}

const statusColors = {
  success: { primary: 'green.300', secondary: 'green.800' },
  disabled: { primary: 'lightGrey.900', secondary: 'darkGrey.300' },
  error: { primary: 'red.200', secondary: 'red.700' },
  warning: { primary: 'kindaYellow.400', secondary: 'kindaYellow.900' },
  info: { primary: 'blue.100', secondary: 'blue.600' }
};

function getVariants(status: StatusBadgeProps['status']) {
  return {
    solid: {
      background: statusColors[status].secondary,
      color: statusColors[status].primary
    },
    outline: {
      background: 'transparent',
      color: statusColors[status].primary,
      borderColor: statusColors[status].primary
    },
    inline: {
      background: 'transparent',
      color: statusColors[status].primary,
      padding: 0
    }
  };
}

const sizes = {
  sm: {
    fontSize: 'xs',
    padding: '0.5rem 0.75rem'
  },
  md: {
    fontSize: 'sm',
    padding: '0.5rem 1rem'
  },
  lg: {
    fontSize: 'md',
    padding: '0.75rem 1.25rem'
  }
};

const baseStyles = {
  gap: '0.5rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  letterSpacing: 'wide',
  borderRadius: 'full',
  border: '1px solid',
  borderColor: 'transparent'
};

/**
 * TODO: this component only supports dark mode for now.
 * We need to add support for light mode.
 *
 * Design spec: https://www.figma.com/file/WLhIGuIkWTy7rf4QbRfxvA/New-Design-System?node-id=1751%3A16943&t=jFW3QH7bGlWb9jms-4
 */
export function StatusBadge({
  variant = 'solid',
  size = 'md',
  status = 'success',
  ...rest
}: StatusBadgeProps) {
  return (
    <Box
      as="span"
      {...baseStyles}
      {...sizes[size]}
      {...getVariants(status)[variant]}
      {...rest}
    />
  );
}
