import { Button, Flex, Heading } from '@chakra-ui/react';
import { useDeepCompareDebounce } from '@tigerhall/core';
import * as React from 'react';
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop
} from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview } from './canvasPreview';

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export interface ImageCropProps {
  src: string;
  size: { width: number; height: number };
  onSave: (blob: Blob) => void;
  circularCrop?: boolean;
}

export function ImageCrop({ src, size, onSave, circularCrop }: ImageCropProps) {
  const previewCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const imgRef = React.useRef<HTMLImageElement>(null);

  const [crop, setCrop] = React.useState<Crop>();
  const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>();

  const scale = 1;
  const rotate = 0;
  const aspect = size.width / size.height;

  useDeepCompareDebounce(
    () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  return src ? (
    <Flex
      direction="column"
      border="1px solid white"
      borderRadius={5}
      py={6}
      px={10}
    >
      <Flex justifyContent="space-between" mb={8}>
        <Heading color="orange">Crop image</Heading>
        <Flex>
          <Button
            variant="solid"
            minWidth="auto"
            onClick={() => {
              if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current
              ) {
                const canvas = canvasPreview(
                  imgRef.current,
                  previewCanvasRef.current || document.createElement('canvas'),
                  completedCrop,
                  scale,
                  rotate
                );

                canvas.toBlob((blob) => {
                  if (!blob) {
                    return;
                  }

                  onSave(blob);
                }, 'image/jpeg');
              }
            }}
          >
            Apply
          </Button>
        </Flex>
      </Flex>
      <ReactCrop
        crop={crop}
        onChange={(_, percentCrop) => setCrop(percentCrop)}
        onComplete={(c) => setCompletedCrop(c)}
        aspect={aspect}
        circularCrop={circularCrop}
      >
        <img
          ref={imgRef}
          alt="Crop me"
          src={src}
          style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
          onLoad={onImageLoad}
        />
      </ReactCrop>
    </Flex>
  ) : null;
}
