import { Flex, HStack } from '@chakra-ui/react';

import { PostOptions } from '../Post/components/PostOptions';
import { PostAuthoring } from './components/PostAuthoring';
import { FeedFollowButton } from './components/FeedFollowButton';
import { type PostHeaderProps } from '../Post/types';

export function PostHeader({
  user,
  createdAt,
  privacySettings,
  postId,
  onDelete,
  disableOptions
}: PostHeaderProps) {
  return (
    <HStack
      justifyContent="space-between"
      spacing="0.5rem"
      pt="1rem"
      pb="1.25rem"
      px={{
        base: '1rem',
        md: '1.5rem'
      }}
    >
      <PostAuthoring
        user={user}
        createdAt={createdAt}
        privacySettings={privacySettings}
      />
      <Flex alignItems="center" gap="1rem">
        <FeedFollowButton isFollowing={user.isFollowing} userId={user.id} />
        {disableOptions ? null : (
          <PostOptions postId={postId} authorId={user.id} onDelete={onDelete} />
        )}
      </Flex>
    </HStack>
  );
}
