import { Flex } from '@chakra-ui/react';
import { Category } from '@tigerhall/core/lib/types';
import map from 'lodash/map';
import React from 'react';
import { IconChevronRight } from '@tigerhall/icons';
import { Typography } from 'components';

type Props = {
  categories: Category[];
  onClick?: (id: string) => void;
};

const CategoryCloud = ({ categories, onClick }: Props) => {
  const sortedCategories = [...categories].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  return (
    <Flex w="100%" flexWrap="wrap" pb={2}>
      {map(sortedCategories, (category) => (
        <Flex
          flexDirection="row"
          key={category?.id}
          p={2}
          bgColor="teal.600"
          h="2rem"
          minH="2rem"
          borderRadius="6px"
          alignItems="center"
          onClick={() => onClick?.(category?.id)}
          mr={2}
          mt={2}
          cursor="pointer"
        >
          <Typography
            variant="detail3"
            weight="bold"
            color="white"
            mr={1}
            w="100%"
          >
            {category?.name}
          </Typography>
          <IconChevronRight height={10} />
        </Flex>
      ))}
    </Flex>
  );
};

export { CategoryCloud };
