import { useEffect, useRef } from 'react';
import { useLocation, Location } from 'react-router-dom';

interface NavigationListenerCallbackArgs {
  previousLocation: Location;
  currentLocation: Location;
}

/**
 * A hook that calls a callback function when the location changes
 * (i.e. when the user navigates to a new page).
 *
 * An use case for this is to minimize the content player when the user
 * navigates to a new page (i.e. when the user clicks on a link).
 *
 * @param callback
 */
export function useNavigationListener(
  callback?: ({
    previousLocation,
    currentLocation
  }: Readonly<NavigationListenerCallbackArgs>) => void
) {
  const location = useLocation();
  const previousLocation = useRef(location);

  useEffect(() => {
    if (previousLocation.current !== location) {
      callback?.({
        previousLocation: previousLocation.current,
        currentLocation: location
      });
    }

    previousLocation.current = location;
  }, [callback, location]);
}
