import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
export type BaseGroupFragment = { __typename: 'OrganisationGroup', id: string, name: string, numberOfMembers: number };

export type SelectionTableGroupFragment = { __typename: 'OrganisationGroup', id: string, name: string, numberOfMembers: number, createdBy?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null };

export type FullGroupFragment = { __typename: 'OrganisationGroup', createdAt: any, updatedAt: any, id: string, name: string, numberOfMembers: number, createdBy?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null, audience?: { __typename?: 'Audience', id: string } | null };

export const BaseGroupFragmentDoc = gql`
    fragment BaseGroup on OrganisationGroup {
  __typename
  id
  name
  numberOfMembers
}
    `;
export const SelectionTableGroupFragmentDoc = gql`
    fragment SelectionTableGroup on OrganisationGroup {
  ...BaseGroup
  createdBy {
    id
    firstName
    lastName
  }
}
    ${BaseGroupFragmentDoc}`;
export const FullGroupFragmentDoc = gql`
    fragment FullGroup on OrganisationGroup {
  ...BaseGroup
  createdBy {
    id
    firstName
    lastName
  }
  createdAt
  updatedAt
  audience {
    id
  }
}
    ${BaseGroupFragmentDoc}`;