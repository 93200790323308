import { noop } from '@chakra-ui/utils';
import type { AudioFile, Chapter } from '@tigerhall/core';
import { useEffect, useRef } from 'react';

import { useIsUserIdle } from '~/hooks';
import {
  type PowerReadState,
  usePowerReadState
} from '~/player/PowerRead/context/usePowerReadState';
import { usePowerReadAnalytics } from '~/player/PowerRead/hooks/usePowerReadAnalytics';
import {
  extractCurrentChapter,
  extractPagesFromChapters
} from '~/player/PowerRead/utils/powerRead';
import {
  MinimizedPlayer,
  type MinimizedPlayerProps
} from '~/player/components/MinimizedPlayer';
import { MediaPlayerContextProvider } from '~/player/context';
import {
  type UseMediaPlayerArgs,
  useMediaAnalytics,
  useMediaPlayer
} from '~/player/hooks';
import { type RatingProps } from '~/rating';
import {
  ActivePlayer,
  type ActivePlayerProps
} from './components/ActivePlayer';

export interface PowerReadProps
  extends Omit<
      ActivePlayerProps,
      'name' | 'chapter' | 'chaptersTotal' | 'pages' | 'onDone' | 'audioRef'
    >,
    Pick<UseMediaPlayerArgs, 'customDuration' | 'previousProgress'>,
    Pick<RatingProps, 'vote' | 'onLike' | 'onDislike'>,
    Pick<PowerReadState, 'customPagesTotal'>,
    Pick<MinimizedPlayerProps, 'onClose' | 'onMaximize'> {
  chapters: Pick<Chapter, 'name' | 'pages' | 'index'>[] | undefined;
  audio?: Pick<AudioFile, 'uri' | 'length'> | null;
  /**
   * The poster image for the podcast.
   * This will be used as the background image for the modal.
   */
  poster: string;
  meta: {
    contentId: string;
    contentName: string;
    casting: string;
  };

  onTrackProgress: (progress: number) => void;
  onTrackFinished: () => void;
  onFinished: () => void;
  /**
   * If the player should be hidden without being unmounted.
   * This is useful if you want to keep the player state but not have it visible,
   * e.g. if you want to open a video and then resume the podcast after the video is closed.
   */
  isFinished?: boolean;
  isHidden?: boolean;
  isMinimized?: boolean;
  playlistId?: string;
  onPageChange?: (pageNumber: number) => void;
}

export function PowerRead({
  chapters,
  audio,
  poster,
  meta: { contentId, contentName, casting },

  onTrackProgress,
  onTrackFinished,
  onPageChange,
  onFinished = noop,
  isFinished = false,
  isHidden = false,
  playlistId,

  // from `ActivePlayerProps`
  children,

  mediaLanguages,
  selectedMediaLanguage,
  onChangeMediaLanguage,

  transcriptLanguages,
  selectedTranscriptLanguage,
  onChangeTranscriptLanguage,

  isMinimized,
  onAutoNextPlay,
  playlistType,
  playlistTitle,
  contentUrl,
  isLastContentInPlaylist,

  // from `UseMediaPlayerArgs`
  customDuration,
  customPagesTotal,
  previousProgress,

  // from `RatingProps`
  vote,
  onLike,
  onDislike,

  // from `MinimizedPlayerProps`
  onClose,
  onMaximize
}: Readonly<PowerReadProps>) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const playerState = isMinimized ? 'Minimized' : 'Maximized';

  const {
    pageIndex,
    setPagesTotal,
    setChapterIndex,
    setIsPlayingAudio,
    setCustomPagesTotal,
    reset,
    isPlayingAudio
  } = usePowerReadState((state) => {
    return {
      pageIndex: state.pageIndex,
      setPagesTotal: state.setPagesTotal,
      setCustomPagesTotal: state.setCustomPagesTotal,
      setChapterIndex: state.setChapterIndex,
      setIsPlayingAudio: state.setIsPlayingAudio,
      reset: state.reset,
      isPlayingAudio: state.isPlayingAudio
    };
  });

  /** Set User Idle after 10 minutes of Inactivity */
  const { isIdle } = useIsUserIdle({ seconds: 60 * 10 });

  useEffect(() => {
    // cleanup function to reset the state
    return reset;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onPageChange) {
      onPageChange(pageIndex);
    }
  }, [onPageChange, pageIndex]);

  usePowerReadAnalytics({
    contentId,
    contentType: 'Ebook',
    playlistId,
    playlistType,
    onTrackProgress,
    onTrackFinished,
    disableTimeSpentReporting:
      (isMinimized || isIdle) && audioRef.current?.paused,
    playerState,
    language:
      isPlayingAudio && selectedTranscriptLanguage
        ? selectedTranscriptLanguage
        : selectedMediaLanguage,
    mediaType: isPlayingAudio ? 'audio' : 'text'
  });

  useMediaAnalytics({
    contentId,
    contentType: 'Ebook',
    playlistId,
    playlistType,
    onTrackProgress,
    onTrackFinished,
    // we only want to report "time spent" from `usePowerReadAnalytics`, not both
    disableTimeSpentReporting: true,
    playerState,
    language:
      isPlayingAudio && selectedTranscriptLanguage
        ? selectedTranscriptLanguage
        : selectedMediaLanguage,
    mediaType: isPlayingAudio ? 'audio' : 'text'
  });

  const player = useMediaPlayer(audioRef, {
    onFinished,
    customDuration,
    previousProgress,
    defaultAutoPlay: false
  });

  const chaptersTotal = chapters?.length;
  const currentChapter = chapters && extractCurrentChapter(chapters, pageIndex);
  const pages = chapters && extractPagesFromChapters(chapters);

  useEffect(() => {
    setPagesTotal(pages?.length ?? 0);
    setChapterIndex(currentChapter?.index ?? 0);

    if (customPagesTotal) {
      setCustomPagesTotal(customPagesTotal);
    }
  }, [
    currentChapter?.index,
    customDuration,
    customPagesTotal,
    pages?.length,
    setChapterIndex,
    setCustomPagesTotal,
    setPagesTotal
  ]);

  useEffect(() => {
    if (!selectedTranscriptLanguage) {
      player.pause();
      setIsPlayingAudio(false);
    }
  }, [selectedTranscriptLanguage, setIsPlayingAudio, player]);

  if (isHidden) {
    player.pause();
  }

  return (
    <MediaPlayerContextProvider {...player}>
      <audio
        ref={audioRef}
        style={{ display: 'none' }}
        src={audio?.uri}
        muted={false}
      />
      {isMinimized ? (
        <MinimizedPlayer
          poster={poster}
          onClose={onClose}
          onMaximize={onMaximize}
          name={contentName}
          casting={casting}
          vote={vote}
          onLike={onLike}
          onDislike={onDislike}
          hideTrackControls
          hideAllMediaControls={!audio?.uri}
          audioRef={audioRef}
          onAutoNextPlay={onAutoNextPlay}
        />
      ) : (
        <ActivePlayer
          name={contentName}
          chapter={currentChapter}
          chaptersTotal={chaptersTotal}
          pages={pages}
          onFinished={onFinished}
          contentUrl={contentUrl}
          onTrackFinished={onTrackFinished}
          mediaLanguages={mediaLanguages}
          selectedMediaLanguage={selectedMediaLanguage}
          onChangeMediaLanguage={onChangeMediaLanguage}
          transcriptLanguages={transcriptLanguages}
          selectedTranscriptLanguage={selectedTranscriptLanguage}
          onChangeTranscriptLanguage={onChangeTranscriptLanguage}
          audioRef={audioRef}
          onAutoNextPlay={onAutoNextPlay}
          isLastContentInPlaylist={isLastContentInPlaylist}
          playlistType={playlistType}
          playlistTitle={playlistTitle}
          isFinished={isFinished}
        >
          {children}
        </ActivePlayer>
      )}
    </MediaPlayerContextProvider>
  );
}
