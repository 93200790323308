import { VStack } from '@chakra-ui/react';
import { Typography } from '@tigerhall/ui-components';

interface Props {
  currentTab?: string;
  isCurrentUser?: boolean;
}

const NoFollowFollowers = ({ currentTab, isCurrentUser }: Props) => {
  let descriptionText = 'Check back a little later.';
  if (isCurrentUser) {
    if (currentTab === 'follower') {
      descriptionText = 'Interact with others in the app to build a following!';
    } else {
      descriptionText = 'People you follow would be shown here.';
    }
  }

  return (
    <VStack pt="6.5rem">
      <Typography
        px="0.75rem"
        variant="heading4"
        color="lightGrey.200"
        mx="0.75rem"
        my="0.75rem"
        fontWeight="bold"
      >
        {currentTab === 'follower'
          ? 'No Followers Yet'
          : 'Not Following Anyone Yet'}
      </Typography>
      <Typography
        px="0.75rem"
        variant="body"
        color="lightGrey.200"
        mx="0.625rem"
        fontWeight="normal"
      >
        {descriptionText}
      </Typography>
    </VStack>
  );
};

export default NoFollowFollowers;
