import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/reducer';

interface State {
  pageCount: number;
}

const INITIAL_STATE: State = {
  pageCount: 0
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: INITIAL_STATE,
  reducers: {
    incrementPageCount: (state) => {
      state.pageCount += 1;
    },

    decrementPageCount: (state) => {
      state.pageCount -= 1;
    }
  }
});

export const { decrementPageCount, incrementPageCount } =
  navigationSlice.actions;

export const getNavigationPageCount = (state: RootState): number =>
  state.navigation.pageCount;
