import {
  type BackgroundProps,
  type ButtonProps,
  Flex,
  Icon,
  LightMode,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  Text
} from '@chakra-ui/react';
import { TrackedButton } from '@tigerhall/components';
import {
  IconAlertTriangleFilled,
  IconCircleCheckFilled,
  IconInfoCircle
} from '@tigerhall/icons';
import { type MouseEventHandler, type ReactNode } from 'react';

export interface ActionModalProps {
  type?: 'warning' | 'success';
  title: string;
  isOpen: ModalProps['isOpen'];
  onClose: ModalProps['onClose'];
  size?: ModalProps['size'];
  overlayBackgroundColor?: BackgroundProps['backgroundColor'];
  description: string;
  primaryButtonText?: string;
  primaryButtonOnClick?: MouseEventHandler<HTMLButtonElement>;
  secondaryButtonText?: string;
  primaryButtonLeftIcon?: ButtonProps['leftIcon'];
  secondaryButtonOnClick?: MouseEventHandler<HTMLButtonElement>;
  info?: string;
  isLoading?: boolean;
  children?: ReactNode;
}

/**
 * @deprecated Use PromptModal Instead
 */
export function ActionModal({
  type,
  title,
  isOpen,
  onClose,
  size,
  overlayBackgroundColor,
  description,
  primaryButtonText,
  primaryButtonOnClick,
  primaryButtonLeftIcon,
  secondaryButtonText,
  secondaryButtonOnClick,
  isLoading,
  info,
  children
}: ActionModalProps) {
  function getIcon() {
    switch (type) {
      case 'warning':
        return (
          <Icon
            as={IconAlertTriangleFilled}
            color="soSunny.700"
            width="2.4rem"
            height="2.4rem"
          />
        );
      case 'success':
        return (
          <Icon
            as={IconCircleCheckFilled}
            color="green.500"
            width="2.4rem"
            height="2.4rem"
          />
        );
      default:
        break;
    }
  }
  return (
    <LightMode>
      <Modal isOpen={isOpen} size={size} onClose={onClose} isCentered>
        <ModalOverlay backgroundColor={overlayBackgroundColor} />
        <ModalContent border="1px solid" borderColor="lightGrey.200" p={0}>
          <ModalCloseButton />
          <ModalHeader
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap="1.5rem"
            justifyContent="center"
            pt="3rem"
          >
            {getIcon()}
            {title}
          </ModalHeader>
          <ModalBody display="flex" flexDirection="column" gap="1.5rem">
            <Text fontSize="md" color="black" opacity="0.9" textAlign="center">
              {description}
            </Text>
            {info ? (
              <Flex
                borderRadius="lg"
                backgroundColor="blue.50"
                alignItems="center"
                px="1rem"
                py="0.5rem"
                gap="1rem"
              >
                <Icon as={IconInfoCircle} color="blue.600" />
                <Text noOfLines={3} fontSize="sm" color="blue.600">
                  {info}
                </Text>
              </Flex>
            ) : null}
            {children}
          </ModalBody>
          <ModalFooter gap="1rem">
            {secondaryButtonText ? (
              <TrackedButton
                name={secondaryButtonText}
                variant="outlineDark"
                onClick={secondaryButtonOnClick}
                size="lg"
                flex={1}
                isLoading={isLoading}
              >
                {secondaryButtonText}
              </TrackedButton>
            ) : null}
            {primaryButtonText ? (
              <TrackedButton
                name={primaryButtonText}
                variant="solidDark"
                onClick={primaryButtonOnClick}
                size="lg"
                flex={1}
                leftIcon={primaryButtonLeftIcon}
                isLoading={isLoading}
              >
                {primaryButtonText}
              </TrackedButton>
            ) : null}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </LightMode>
  );
}
