import * as React from 'react';
import { Content, ContentType, LearningPath } from '@tigerhall/core';
import {
  ContentSummaryModal,
  ContentSummaryModalProps,
  LearningPathSummaryModal,
  LearningPathSummaryModalProps
} from 'modules/admin/components';
import {
  useGetContentByIdQuery,
  useGetLearningPathInfoByIdQuery
} from 'generated';
import { useToast } from '@chakra-ui/react';

type ContentCardSummaryProps = (
  | Omit<ContentSummaryModalProps, 'content'>
  | Omit<LearningPathSummaryModalProps, 'content'>
) & {
  id: string | undefined;
  contentType:
    | 'Podcast'
    | 'Stream'
    | 'Event'
    | 'Ebook'
    | 'LearningPath'
    | undefined;
};

export function ContentCardSummary({
  id,
  contentType,
  actions,
  isOpen,
  onClose
}: ContentCardSummaryProps) {
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true
  });

  const isLearningPath = contentType === 'LearningPath';

  const { data: learningPathData } = useGetLearningPathInfoByIdQuery({
    skip: !id || !isLearningPath,
    variables: {
      id: id as string
    },
    onError: () => {
      toast({
        title:
          'Not trail data found. Please try again later or contact support.',
        status: 'error'
      });
    }
  });

  const { data: contentData } = useGetContentByIdQuery({
    skip: !id || isLearningPath,
    variables: {
      id: id as string
    },
    onError: () => {
      toast({
        title:
          'Not content data found. Please try again later or contact support.',
        status: 'error'
      });
    }
  });

  if (isLearningPath && learningPathData?.learningPath) {
    return (
      <LearningPathSummaryModal
        isOpen={isOpen}
        onClose={onClose}
        content={learningPathData?.learningPath as LearningPath}
        actions={actions}
      />
    );
  }

  if (!isLearningPath && contentData?.contentCard) {
    return (
      <ContentSummaryModal
        isOpen={isOpen}
        onClose={onClose}
        content={contentData?.contentCard as Content}
        actions={actions}
      />
    );
  }

  return null;
}
