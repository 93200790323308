import { Divider, Flex } from '@chakra-ui/react';
import { Typography } from '@tigerhall/ui-components';
import * as React from 'react';

import FormAdditionalInfoSection from './FormAdditionalInfoSection';

interface Props {
  title: string;
}

export default function FormAdditionalInfo({ title }: Props) {
  return (
    <Flex flexDirection="column" flex={1}>
      <Divider my="8" />
      <Flex justify="space-between">
        <Typography mb={4} variant="heading2">
          {title}
        </Typography>
      </Flex>

      <Typography mb={4} variant="body">
        Include three actionable insights that users can takeaway from this
        piece. This could be action items or key summary points or general
        information that could improve understanding for users.
      </Typography>

      <FormAdditionalInfoSection index={0} />
    </Flex>
  );
}
