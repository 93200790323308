import { useEffect } from 'react';
import {
  Box,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
  useToast
} from '@chakra-ui/react';
import { Button } from '@tigerhall/ui-components';
import { type User } from '@tigerhall/core/lib/types';
import { useRequestMentorShipMutation } from 'generated';
import { IconClock, IconCoinFilled } from '@tigerhall/icons';

type BookMentorshipModalBodyProps = {
  expert: User;
  setMentorShipModalOpen: (open: boolean) => void;
};

const BookMentorshipModalBody = ({
  expert,
  setMentorShipModalOpen
}: BookMentorshipModalBodyProps) => {
  const toast = useToast();
  const [requestMentorShipMutation, { data, loading }] =
    useRequestMentorShipMutation({
      variables: {
        expertId: expert?.id
      }
    });

  useEffect(() => {
    if (data?.requestMentorship?.success) {
      toast({
        status: 'success',
        title: 'Mentorship requested'
      });
    }
  }, [data?.requestMentorship?.success, setMentorShipModalOpen, toast]);

  return (
    <VStack alignItems="flex-start" px={6} pb={6} spacing={6}>
      <Stack spacing={6}>
        <Heading color="lightGrey.200" fontWeight="bold" fontSize="2xl">
          Mentorship
        </Heading>
        <Text fontSize="lg" color="lightGrey.600">
          A personal mentorship session is your opportunity to meet this
          Thinkfluencer one-on-one and get advice tailored to your unique
          situation. All mentorship sessions are conducted virtually. When you
          request mentorship below, our team will get in touch with you via
          email to confirm the booking.
        </Text>
      </Stack>
      <HStack alignItems="flex-start">
        <Box>
          <IconCoinFilled color="tigerOrange.600" />
        </Box>
        <Stack>
          <Text fontSize="lg" fontWeight="700" color="tigerOrange.600">
            Price
          </Text>
          <Text fontSize="sm" color="lightGrey.600">
            {expert?.mentorPriceText || ''}
          </Text>
        </Stack>
      </HStack>
      <HStack alignItems="flex-start">
        <Box>
          <IconClock />
        </Box>
        <Stack>
          <Text fontSize="lg" fontWeight="700" color="tigerOrange.600">
            Availability
          </Text>
          <Text fontSize="sm" color="lightGrey.600">
            {expert?.mentorAvailableText || ''}
          </Text>
        </Stack>
      </HStack>
      <Box w="full" mt="2rem !important">
        <Button
          size="lg"
          width="100%"
          text="REQUEST MENTORSHIP"
          onClick={() => {
            requestMentorShipMutation();
          }}
          isLoading={loading}
        />
      </Box>
    </VStack>
  );
};

export default BookMentorshipModalBody;
