import { useNavigate, useParams } from 'react-router-dom';
import { redirectToStripePaymentGateway } from '@tigerhall/core';

type Campaign = {
  firstStep: 'login' | 'signup';
  heading: string;
  description: string;
  checkoutCampaignId: string;
};

const campaigns: { [key: string]: Campaign } = {
  'ey-professional-growth': {
    firstStep: 'signup',
    heading: 'Greetings, EY Badge holders!',
    description:
      'As part of your achievement as a first-time EY Badge holder, we are offering you a free 2-month access to Tigerhall.\n' +
      'Get unfiltered access to best practices, insights, and advice from over 1,500 global business leaders and industry experts.',
    checkoutCampaignId: 'ey'
  },

  'cisco-professional-growth': {
    firstStep: 'login',
    heading:
      'Cisco supports you with access to worldwide CXOs and industry experts!',
    description:
      'As part of Cisco’s support of your professional growth, upon your manager’s approval, you will be able to be reimbursed on 12-month unlimited access to Tigerhall. ' +
      'Get unfiltered access to best practices, insights and advice from over 1,500 global business executives, sales leaders and industry experts.',
    checkoutCampaignId: 'cisco'
  }
};

export function useSubscriptionCampaign() {
  const { campaign } = useParams();

  if (!campaign || !campaigns[campaign]) {
    return null;
  }

  const cfg = campaigns[campaign];

  return {
    ...cfg,

    redirectToCheckout: (token: string) => {
      // Don't delete existing accounts
      const cancelRedirectUrl =
        cfg.firstStep === 'login'
          ? `${import.meta.env.VITE_WEBAPP_URL}/signup-campaign/${campaign}`
          : `${
              import.meta.env.VITE_WEBAPP_URL
            }/signup-campaign/${campaign}/canceled?token=${token}`;

      redirectToStripePaymentGateway({
        origin: import.meta.env.VITE_API_URL,
        token: token,
        campaign: cfg.checkoutCampaignId,
        duration: 'yearly',
        subscriptionType: 'individual',

        successRedirectUrl: `${
          import.meta.env.VITE_WEBAPP_URL
        }/login?token=${token}`,

        cancelRedirectUrl
      });
    }
  };
}
