import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import * as sdk from '@microsoft/teams-js';

export interface MicrosoftTeamsReturn {
  sdk: typeof sdk;

  /**
   * If the micrsoft JS sdk is loading
   */
  loading: boolean;

  /**
   * If the app is running in Microsoft Teams environment
   */
  isInTeams: boolean;

  /**
   * If the app is accessed from a mobile device
   */
  isInMobile: boolean;

  /**
   * If the app is running in full screen mode
   */
  isFullScreen: boolean;

  /**
   * The context of the app
   */
  context?: sdk.app.Context;
}

const msTeamsContext = createContext<MicrosoftTeamsReturn>({
  sdk,
  loading: true,
  isInTeams: false,
  isInMobile: false,
  isFullScreen: false,
  context: undefined
});

interface MicrosoftTeamsProviderProps {
  children: ReactNode;
}

export function MicrosoftTeamsProvider({
  children
}: MicrosoftTeamsProviderProps) {
  const [isInTeams, setIsInTeams] = useState(false);
  const [loading, setLoading] = useState(true);
  const [context, setContext] = useState<sdk.app.Context>();
  const [isFullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    sdk.app
      .initialize()
      .then(() => {
        sdk.app
          .getContext()
          .then((ctx) => {
            batchedUpdates(() => {
              setIsInTeams(true);
              setContext(ctx);
              setFullScreen(!!ctx.page.isFullScreen);
              setLoading(false);
            });

            sdk.pages.registerFullScreenHandler((value) => {
              setFullScreen(value);
            });
          })
          .catch(() => {
            setIsInTeams(false);
            setLoading(false);
          });
      })
      .catch(() => {
        setIsInTeams(false);
        setLoading(false);
      });
  }, []);

  const clientType = context?.app.host.clientType ?? '';
  const isInMobile =
    clientType === sdk.HostClientType.android ||
    clientType === sdk.HostClientType.ios;

  const value: MicrosoftTeamsReturn = useMemo(
    () => ({
      sdk,
      loading,
      context,
      isInTeams,
      isInMobile,
      isFullScreen
    }),
    [context, isFullScreen, isInTeams, isInMobile, loading]
  );

  return (
    <msTeamsContext.Provider value={value}>{children}</msTeamsContext.Provider>
  );
}

export function useMicrosoftTeams(): MicrosoftTeamsReturn {
  return useContext(msTeamsContext);
}
