import {
  Box,
  Fade,
  Flex,
  type InteractivityProps,
  SlideFade,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react';
import throttle from 'lodash/throttle';
import { ElementRef, ReactNode, useCallback, useEffect, useRef } from 'react';

/**
 * Get the transition for the overlay.
 * In mobile, we want the transition to be instant because it happens on tap.
 *
 * @param isInstant If the transition should be instant or with a delay.
 */
function getTransition(isInstant: boolean) {
  return { exit: { delay: isInstant ? 0 : 0.3 }, enter: { duration: 0.3 } };
}

interface OverlayProps {
  /** Top side of the overlay. */
  header: ReactNode;
  /** Bottom side of the overlay. */
  footer: ReactNode;
  isAlwaysOpen?: boolean;
  isMiniVideoPlayer?: boolean;
  /** Body of the overlay
   *  Optional because we might not want to display anything in the overlay for some cases
   */
  body?: ReactNode;
}

const MS_TO_WAIT_FOR = 3000;

/**
 * Overlay component for the content player.
 * It consists of two parts, the header and the footer.
 * The first time the Overlay is mounted, it will be open. Then it will close and open on hover
 * (or tap on mobile).
 *
 * Also if you are using `Tooltip` inside the Overlay make sure to pass `openDelay={300}` prop
 * this is required when the user navigate using tab and the element is focused has tooltip, the position
 * calculated will be incorrect because the overlay animates and position of the tooltip changes over the course
 */
export function Overlay({
  header,
  footer,
  body,
  isAlwaysOpen,
  isMiniVideoPlayer
}: OverlayProps) {
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure({
    defaultIsOpen: true,
    isOpen: isAlwaysOpen
  });

  const isMobile = useBreakpointValue(
    {
      base: true,
      lg: false
    },
    {
      fallback: 'lg'
    }
  );

  const transition = getTransition(Boolean(isMobile));

  const timerRef = useRef(0);

  const footerRef = useRef<ElementRef<'div'>>(null);

  const closeOverlay = useCallback(() => {
    const activeElement = document.activeElement as HTMLElement;

    /**
     * This is to hide the tooltip we have in the player controls
     * because when the player control are hidden it doesn't hide by default
     */
    if (activeElement && footerRef.current?.contains(activeElement)) {
      activeElement?.blur();
    }

    onClose();
  }, [onClose]);

  const pointerEvents: InteractivityProps['pointerEvents'] = isOpen
    ? undefined
    : 'none';

  useEffect(() => {
    if (body) {
      onOpen();
    }
  }, [body, onOpen]);

  useEffect(() => {
    timerRef.current = window.setTimeout(() => {
      closeOverlay();
    }, MS_TO_WAIT_FOR);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [closeOverlay]);

  function resetAndStartTimer() {
    clearTimeout(timerRef.current);

    timerRef.current = window.setTimeout(() => {
      closeOverlay();
    }, MS_TO_WAIT_FOR);
  }

  const handleMouseMove = throttle(() => {
    if (isMobile) {
      return;
    }
    if (!isOpen) {
      onOpen();
    }

    resetAndStartTimer();
  }, MS_TO_WAIT_FOR);

  function handleMouseLeave() {
    if (body) {
      return;
    }
    closeOverlay();

    clearTimeout(timerRef.current);
  }

  function handleVideoAreaClick() {
    if (body) {
      return;
    }
    onToggle();

    resetAndStartTimer();
  }

  function handleFocus() {
    handleMouseMove();
  }

  return (
    <Fade in={isOpen} transition={transition}>
      <Flex
        data-testid="overlay-container"
        position={'absolute'}
        inset={0}
        flexDirection={'column'}
        justifyContent={'space-between'}
        backgroundColor={'blackAlpha.700'}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        overflow={'hidden'}
      >
        <SlideFade
          in={isOpen}
          offsetY="-3rem"
          transition={transition}
          /** Unmounting on exit, so that the FlexBox we have in the middle to control the player's visibility has complete height  */
          /*
           * We only want to happen this on mobile as on desktop user can navigate using tab
           */
          unmountOnExit={isMobile}
        >
          <Box
            pointerEvents={pointerEvents}
            pt={{
              base: isMiniVideoPlayer ? '.5rem' : '2.5rem',
              lg: '0'
            }}
            bgGradient={
              'linear(to-b, blackAlpha.600 0%, blackAlpha.400 69.27%, transparent 100%)'
            }
          >
            {header}
          </Box>
        </SlideFade>
        <Flex
          // we intentionally don't want this to be a "button" because we don't want
          // the focus ring nor the keyboard interaction
          aria-label={isOpen ? 'Hide controls' : 'Show controls'}
          flex={1}
          role={'button'}
          cursor={'default'}
          onClick={handleVideoAreaClick}
        >
          {body}
        </Flex>
        <SlideFade
          in={isOpen}
          offsetY="3rem"
          transition={transition}
          /** Unmounting on exit, so that the FlexBox we have in the middle to control the player's visibility has complete height
           * This allows user to click anywhere on the screen to bring the player Controls back
           *
           * We only want to happen this on mobile as on desktop user can navigate using tab
           */
          unmountOnExit={isMobile}
        >
          <Flex
            pointerEvents={pointerEvents}
            ref={footerRef}
            alignItems={'end'}
            height={'auto'}
            onFocus={handleFocus}
            bgGradient={
              'linear(to-b, transparent 0%, blackAlpha.400 9.41%, blackAlpha.600 47.8%, black 100%)'
            }
          >
            {footer}
          </Flex>
        </SlideFade>
      </Flex>
    </Fade>
  );
}
