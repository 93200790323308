import * as React from 'react';
import { Icon, IconButton, type IconProps } from '@chakra-ui/react';
import { IconMessageReport } from '@tigerhall/icons';
import { Content, LearningPath } from '@tigerhall/core';

import { ReportModal } from '../modals';

type ReportButtonProps = {
  contentId: Content['id'];
  contentTypeName: Content['__typename'] | LearningPath['__typename'];
  iconProps?: IconProps;
};

export function ReportButton({
  contentId,
  contentTypeName,
  iconProps
}: ReportButtonProps) {
  const [isReportModalOpen, setIsReportModalOpen] = React.useState(false);

  return (
    <>
      <IconButton
        role="button"
        aria-label="reportButton"
        height="1.5rem"
        width="1rem"
        size="xs"
        variant="unstyled"
        fontSize="20"
        display="flex"
        onClick={() => {
          setIsReportModalOpen(true);
        }}
        icon={
          <Icon as={IconMessageReport} {...iconProps} color="tigerOrange.600" />
        }
      />
      <ReportModal
        isOpen={isReportModalOpen}
        onClose={() => {
          setIsReportModalOpen(false);
        }}
        contentId={contentId}
        contentTypeName={contentTypeName}
      />
    </>
  );
}
