import { ErrorBoundary } from 'components/tools/ErrorBoundary';
import { Splash } from 'components/ui/Layout/components';
import * as React from 'react';
import { RouteObject } from 'react-router-dom';

const EbookHistory = React.lazy(() =>
  import('./screens/EbookHistory').then((module) => ({
    default: module.EbookHistory
  }))
);

const EbookContentEditor = React.lazy(() =>
  import('./screens/EbookForm').then((module) => ({
    default: module.EbookContentEditor
  }))
);

const EbookForm = React.lazy(() =>
  import('./screens/EbookForm').then((module) => ({
    default: module.EbookForm
  }))
);

const EbookSummary = React.lazy(() =>
  import('./screens/EbookSummary').then((module) => ({
    default: module.EbookSummary
  }))
);

const routes: RouteObject[] = [
  {
    path: 'ebooks/',
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<Splash />}>
          <EbookHistory />
        </React.Suspense>
      </ErrorBoundary>
    ),
    children: [
      {
        path: ':id/summary',
        element: (
          <ErrorBoundary>
            <React.Suspense fallback={<Splash />}>
              <EbookHistory />
            </React.Suspense>
          </ErrorBoundary>
        )
      }
    ]
  },
  {
    path: 'ebooks/:idOrDraft/form',
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<Splash />}>
          <EbookForm />
        </React.Suspense>
      </ErrorBoundary>
    )
  },
  {
    path: 'ebooks/:id/content-editor',
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<Splash />}>
          <EbookContentEditor />
        </React.Suspense>
      </ErrorBoundary>
    )
  },

  {
    path: 'ebooks/:id/success',
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<Splash />}>
          <EbookSummary />
        </React.Suspense>
      </ErrorBoundary>
    )
  }
];

export default routes;
