import { HStack } from '@chakra-ui/react';
import { ProfileBarSkeleton } from '@tigerhall/components';
import { type ComponentProps } from 'react';

import { BlockButtonSkeleton } from '~/components/ui/Buttons/BlockButton';

interface ProfileBarBlockSkeletonProps
  extends ComponentProps<typeof ProfileBarSkeleton> {}

export function ProfileBarBlockSkeleton({
  size
}: Readonly<ProfileBarBlockSkeletonProps>) {
  return (
    <HStack justifyContent="space-between">
      <ProfileBarSkeleton size={size} />;
      <BlockButtonSkeleton />
    </HStack>
  );
}
