import * as React from 'react';
import { useGenerateId } from '@tigerhall/core';

type DummyCopiesProps = {
  count: number;
  children: React.ReactNode;
};

/**
 * Using index as key is not recommended but we were using it in a lot of places,
 * mostly to render dummy copies of a component. This component is a wrapper
 * around the dummy copies so the issue of using index as key is contained
 * to this component and not spread across the codebase.
 */
export function DummyCopies({ count, children }: DummyCopiesProps) {
  const generatedId = useGenerateId('DUMMY');

  return (
    <>
      {Array(count)
        .fill('DUMMY')
        .map((dummy, index) => (
          <React.Fragment key={`${generatedId}${dummy}${index}`}>
            {children}
          </React.Fragment>
        ))}
    </>
  );
}
