import { Center } from '@chakra-ui/react';

interface LiveContentOverlayWrapperProps {
  children: React.ReactNode;
  isLoaderOverlay?: boolean;
}

export function LiveContentOverlayWrapper({
  children,
  isLoaderOverlay = false
}: LiveContentOverlayWrapperProps) {
  return (
    <Center
      w={'100%'}
      h={{ base: '50vh', lg: '100vh' }}
      bg={'blackAlpha.700'}
      flexDirection={'column'}
      gap={'2.5rem'}
      pos={'absolute'}
      backdropFilter="blur(10px)"
      zIndex={isLoaderOverlay ? 0 : 'zIndexOne'}
      top={'50%'}
      transform={'translateY(-50%)'}
      px={'.5rem'}
    >
      {children}
    </Center>
  );
}
