import { Splash } from 'components/ui/Layout/components';
import * as React from 'react';
import { RouteObject } from 'react-router-dom';

const Overview = React.lazy(() =>
  import('./screens/overview/Overview').then((module) => ({
    default: module.Overview
  }))
);

const ProfileView = React.lazy(() =>
  import('./screens/profile/routes/ProfileView').then((module) => ({
    default: module.ProfileView
  }))
);

const ProfileEdit = React.lazy(() =>
  import('./screens/profile/routes/ProfileEdit').then((module) => ({
    default: module.ProfileEdit
  }))
);

const routes: RouteObject[] = [
  {
    path: 'users/',
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<Splash />}>
            <Overview />
          </React.Suspense>
        )
      },
      {
        path: ':id',
        element: (
          <React.Suspense fallback={<Splash />}>
            <ProfileView />
          </React.Suspense>
        )
      },
      {
        path: ':id/edit',
        element: (
          <React.Suspense fallback={<Splash />}>
            <ProfileEdit />
          </React.Suspense>
        )
      }
    ]
  }
];

export default routes;
