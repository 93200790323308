import { useUpdateUserProductTourTrackingMutation } from 'generated/queries';
import { useCallback } from 'react';
import { ProductTourState } from '@tigerhall/core';

export function useUpdateProductTour() {
  const [updateOnboardingStatusMutation, { client }] =
    useUpdateUserProductTourTrackingMutation({
      onCompleted: () => {
        client.refetchQueries({
          include: ['getCurrentUser', 'getCurrentUserOnboardingStatus']
        });
      }
    });

  const updateOnboardingStatus = useCallback(
    (productTourState: ProductTourState) => {
      updateOnboardingStatusMutation({
        variables: {
          input: {
            state: productTourState,
            journeyProgress: 0
          }
        }
      });
    },
    [updateOnboardingStatusMutation]
  );

  return {
    updateOnboardingStatus
  };
}
