import { ReactionsPopover } from '@tigerhall/components';
import { useState } from 'react';
import {
  Button,
  type ButtonProps,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { ReactionType, type UserReaction } from '@tigerhall/core/lib/types';
import { captureException } from '@sentry/react';
import { REACTIONS } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import { useGetCommentByIdLazyQuery } from 'generated';

import { useToggleCommentReactionMutation } from '../queries/generated/reaction.generated';

const REACT_BUTTON_SHARED_PROPS: ButtonProps = {
  variant: 'ghost',
  size: 'sm',
  fontSize: 'sm',
  p: '0.25rem',
  borderRadius: 'base',
  lineHeight: 1
};

export interface ReactionProps {
  commentId;
  currentReaction?: Pick<UserReaction, 'id' | 'reactionType'> | null;
}

export function Reaction({ commentId, currentReaction }: ReactionProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const [toggleCommentReactionMutation] = useToggleCommentReactionMutation();

  const [refetchComment] = useGetCommentByIdLazyQuery({
    variables: {
      id: commentId
    },
    fetchPolicy: 'network-only'
  });

  async function handleReactionClicked(reaction: ReactionType) {
    setIsLoading(true);

    try {
      const isCurrentReaction =
        currentReaction?.reactionType &&
        REACTIONS[currentReaction.reactionType].label ===
          REACTIONS[reaction].label;

      onClose();

      segment.reactionClicked({
        addedOrRemoved: !isCurrentReaction,
        reactionType: REACTIONS[reaction].labelUppercase,
        referenceType: 'COMMENT',
        referenceId: commentId,
        location: window.location.pathname
      });

      await toggleCommentReactionMutation({
        variables: {
          referenceId: commentId,
          reactionType: reaction
        }
      });

      await refetchComment();
    } catch (error) {
      toast({
        title: 'Error',
        description:
          'An error occurred trying to react to this comment. Please try again later',
        status: 'error'
      });
      captureException(error, {
        tags: {
          commentId: commentId
        }
      });
    }

    setIsLoading(false);
  }

  return (
    <ReactionsPopover
      trigger="hover"
      isOpen={!isLoading && isOpen}
      onClose={onClose}
      onOpen={() => {
        segment.reactionsOpened({
          referenceType: 'COMMENT',
          referenceId: commentId
        });
        onOpen();
      }}
      onReactionClicked={handleReactionClicked}
    >
      {currentReaction?.reactionType ? (
        <Button
          {...REACT_BUTTON_SHARED_PROPS}
          isLoading={isLoading}
          color="tigerOrange.600"
          onClick={async () => {
            await handleReactionClicked(currentReaction.reactionType);
          }}
        >
          {REACTIONS[currentReaction.reactionType].label}
        </Button>
      ) : (
        <Button
          {...REACT_BUTTON_SHARED_PROPS}
          isLoading={isLoading}
          color="lightGrey.200"
          onClick={async () => {
            await handleReactionClicked(ReactionType.Heart);
          }}
        >
          Like
        </Button>
      )}
    </ReactionsPopover>
  );
}
