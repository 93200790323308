import { type MouseEvent, useEffect, useRef } from 'react';
import { Box, Flex, LinkBox } from '@chakra-ui/react';
import { LinkOverlay } from '@tigerhall/components';
import * as segment from '@tigerhall/analytics';

import { PostHeader } from '../../../PostHeader';
import { PostText } from '../../../PostText';
import { PostMetrics } from '../../../PostMetrics';
import { FeedItemActions } from '../../../FeedItemActions';
import { Reaction } from '../../../FeedItemActions/components/Reaction';
import { Comment } from '../../../FeedItemActions/components/Comment';
import { Repost } from '../../../FeedItemActions/components/Repost';
import { ReactionsCount } from '../../../PostMetrics/components/ReactionsCount';
import { CommentsCount } from '../../../PostMetrics/components/CommentsCount';
import { RepostsCount } from '../../../PostMetrics/components/RepostsCount';
import type { CustomPostProps, PostBodyProps } from '../../types';
import { FeedItemContainer } from '../../../FeedItemContainer';
import { PostLinkAttachments } from '../../../PostLinkAttachments';

function OriginalPost(props: PostBodyProps) {
  const isRepostWithThoughts = !!props.post.parent;

  return (
    <FeedItemContainer>
      <PostBody
        {...props}
        type={isRepostWithThoughts ? 'REPOST_WITH_THOUGHTS' : 'POST'}
      />
    </FeedItemContainer>
  );
}

export function PostBody({
  post,
  disableActions,
  disableMetrics,
  onDelete,
  disableOptions,
  type,
  isReposted
}: PostBodyProps) {
  const postRef = useRef(null);

  const isRepost = !!post.parent;

  useEffect(() => {
    const isProfilePage = window.location.pathname === '/profile';

    // we don't want to track impressions on user activities on profile page
    if (isProfilePage) {
      return;
    }

    const postElement = postRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          segment.feedPostImpression({
            referenceId: post.id,
            postType: type ?? 'POST'
          });
          if (postElement) {
            observer.unobserve(postElement);
          }
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.2
      }
    );

    if (postElement) {
      observer.observe(postElement);
    }

    return () => {
      if (postElement) {
        observer.unobserve(postElement);
      }
    };
  }, [post.id, type]);

  function handlePostClick(event: MouseEvent<HTMLAnchorElement>) {
    event.stopPropagation();

    const isPostPage = window.location.pathname.includes(`/feed/${post.id}`);
    // If user is already on the post page, don't navigate again
    if (isPostPage) {
      return;
    }

    segment.feedPostClicked({
      referenceId: post.id,
      postType: type ?? 'POST'
    });
  }

  return (
    <Flex direction="column" ref={postRef}>
      <LinkBox
        display="inherit"
        flexDirection="inherit"
        justifyContent="inherit"
        alignItems="inherit"
      >
        <PostHeader
          postId={post.id}
          user={post.user}
          createdAt={post.createdAt}
          privacySettings={post.privacySettings}
          onDelete={onDelete}
          disableOptions={disableOptions}
        />
        {post.description ? (
          <LinkOverlay
            href={`/feed/${post.id}`}
            onClick={handlePostClick}
            aria-label="See post"
            _hover={{
              textDecoration: 'none'
            }}
          >
            <PostText text={post.description} isReposted={isReposted} />
          </LinkOverlay>
        ) : null}
        <PostLinkAttachments attachments={post.attachments} />

        {isRepost ? (
          <Box
            px={{
              base: '1rem',
              md: '1.5rem'
            }}
            pb="1.25rem"
          >
            <OriginalPost
              post={post.parent as CustomPostProps}
              disableActions
              disableMetrics
              disableOptions
              isReposted
            />
          </Box>
        ) : null}
      </LinkBox>
      {disableMetrics ? null : (
        <PostMetrics
          leftSection={
            <ReactionsCount
              postId={post.id}
              reactions={post.reactions.reactions}
              count={post.reactions.totalCount}
            />
          }
          rightSection={
            <>
              <CommentsCount postId={post.id} count={post.numberOfComments} />
              <RepostsCount postId={post.id} count={post.numberOfReposts} />
            </>
          }
        />
      )}
      {disableActions ? null : (
        <FeedItemActions>
          <Reaction
            postId={post.id}
            currentReaction={post.reactions.userReacted}
          />
          <Comment postId={post.id} />
          {isRepost ? null : (
            <Repost postId={post.id} privacySetting={post.privacySettings} />
          )}
        </FeedItemActions>
      )}
    </Flex>
  );
}
