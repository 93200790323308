import { VStack, Stack } from '@chakra-ui/react';
import { TrackedLink } from '@tigerhall/components';

import appStoreImage from '../../../assets/appstore.svg';
import googlePlayImage from '../../../assets/google-play.svg';
import googlePlayLinkQR from '../../../assets/playstore-qrcode.svg';
import appStoreQR from '../../../assets/appstore-qrcode.svg';

export const iOSAppUrl =
  'https://apps.apple.com/sg/app/tigerhall/id1450973021?utm_source=website&utm_medium=appicon&utm_campaign=web';

export const androidAppUrl =
  'https://play.google.com/store/apps/details?id=com.tigerhall&hl=en&utm_source=website&utm_medium=appicon&utm_campaign=web';

export function DownloadAppButtons({ ...props }) {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Stack
      direction="row"
      justify="center"
      align="center"
      gap="2.5rem"
      pt="1rem"
      {...props}
    >
      <VStack spacing="0" gap="1rem">
        <TrackedLink
          name="googlePlayDownload"
          href={androidAppUrl}
          rel="nofollow"
        >
          <img
            src={googlePlayImage}
            alt="Download mobile application form google play"
          />
        </TrackedLink>
        <img
          src={googlePlayLinkQR}
          alt="Scan & Download mobile application form google play"
        />
      </VStack>
      <VStack spacing="0" gap="1rem">
        <TrackedLink name="appStoreDownload" href={iOSAppUrl}>
          <img
            src={appStoreImage}
            alt="Download mobile application form app store"
          />
        </TrackedLink>
        <img
          src={appStoreQR}
          alt="Download mobile application form app store"
        />
      </VStack>
    </Stack>
  );
}
