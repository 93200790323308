import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDataLakeLastUpdatedQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type GetDataLakeLastUpdatedQuery = { __typename?: 'Query', dataLakeLastUpdatedAt: any };

export type GetContentTitlesQueryVariables = SchemaTypes.Exact<{
  filter?: SchemaTypes.InputMaybe<SchemaTypes.ContentCardsFilter>;
  sorting?: SchemaTypes.InputMaybe<SchemaTypes.ContentCardsSorting>;
}>;


export type GetContentTitlesQuery = { __typename?: 'Query', contentCards: { __typename?: 'ContentConnection', edges: Array<{ __typename?: 'Ebook', id: string, name: string } | { __typename?: 'Event', id: string, name: string } | { __typename?: 'Expert', id: string, name: string } | { __typename?: 'LearningPath', id: string, name: string, contentCards: Array<{ __typename: 'Ebook', id: string } | { __typename: 'Event', id: string } | { __typename: 'Expert' } | { __typename: 'LearningPath' } | { __typename: 'Podcast', id: string } | { __typename: 'Stream', id: string }> } | { __typename?: 'Podcast', id: string, name: string } | { __typename?: 'Stream', id: string, name: string }>, meta: { __typename?: 'PageInfo', recommendationId?: string | null, total: number, offset: number, limit: number } } };

export type GetOrgAssignmentsTitlesQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
  filter: SchemaTypes.AssignmentsFilter;
  sorting?: SchemaTypes.InputMaybe<SchemaTypes.SortOrdering>;
}>;


export type GetOrgAssignmentsTitlesQuery = { __typename?: 'Query', organisation: { __typename: 'Organisation', id: string, assignments: { __typename?: 'AssignmentConnection', meta: { __typename?: 'PageInfo', total: number, offset: number, limit: number }, edges: Array<{ __typename?: 'Assignment', id: string, status?: SchemaTypes.AssignmentStatus | null, name: string, users?: { __typename?: 'UserCollection', meta: { __typename?: 'PageInfo', total: number } } | null }> } } };


export const GetDataLakeLastUpdatedDocument = gql`
    query GetDataLakeLastUpdated {
  dataLakeLastUpdatedAt
}
    `;

/**
 * __useGetDataLakeLastUpdatedQuery__
 *
 * To run a query within a React component, call `useGetDataLakeLastUpdatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataLakeLastUpdatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataLakeLastUpdatedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDataLakeLastUpdatedQuery(baseOptions?: Apollo.QueryHookOptions<GetDataLakeLastUpdatedQuery, GetDataLakeLastUpdatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataLakeLastUpdatedQuery, GetDataLakeLastUpdatedQueryVariables>(GetDataLakeLastUpdatedDocument, options);
      }
export function useGetDataLakeLastUpdatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataLakeLastUpdatedQuery, GetDataLakeLastUpdatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataLakeLastUpdatedQuery, GetDataLakeLastUpdatedQueryVariables>(GetDataLakeLastUpdatedDocument, options);
        }
export type GetDataLakeLastUpdatedQueryHookResult = ReturnType<typeof useGetDataLakeLastUpdatedQuery>;
export type GetDataLakeLastUpdatedLazyQueryHookResult = ReturnType<typeof useGetDataLakeLastUpdatedLazyQuery>;
export type GetDataLakeLastUpdatedQueryResult = Apollo.QueryResult<GetDataLakeLastUpdatedQuery, GetDataLakeLastUpdatedQueryVariables>;
export const GetContentTitlesDocument = gql`
    query GetContentTitles($filter: ContentCardsFilter, $sorting: ContentCardsSorting) {
  contentCards(filter: $filter, sorting: $sorting) {
    edges {
      ... on Ebook {
        id
        name
      }
      ... on Event {
        id
        name
      }
      ... on Podcast {
        id
        name
      }
      ... on Stream {
        id
        name
      }
      ... on Expert {
        id
        name: firstName
      }
      ... on LearningPath {
        id
        name
        contentCards {
          __typename
          ... on Ebook {
            id
          }
          ... on Event {
            id
          }
          ... on Podcast {
            id
          }
          ... on Stream {
            id
          }
        }
      }
    }
    meta {
      recommendationId
      total
      offset
      limit
    }
  }
}
    `;

/**
 * __useGetContentTitlesQuery__
 *
 * To run a query within a React component, call `useGetContentTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentTitlesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetContentTitlesQuery(baseOptions?: Apollo.QueryHookOptions<GetContentTitlesQuery, GetContentTitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentTitlesQuery, GetContentTitlesQueryVariables>(GetContentTitlesDocument, options);
      }
export function useGetContentTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentTitlesQuery, GetContentTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentTitlesQuery, GetContentTitlesQueryVariables>(GetContentTitlesDocument, options);
        }
export type GetContentTitlesQueryHookResult = ReturnType<typeof useGetContentTitlesQuery>;
export type GetContentTitlesLazyQueryHookResult = ReturnType<typeof useGetContentTitlesLazyQuery>;
export type GetContentTitlesQueryResult = Apollo.QueryResult<GetContentTitlesQuery, GetContentTitlesQueryVariables>;
export const GetOrgAssignmentsTitlesDocument = gql`
    query GetOrgAssignmentsTitles($id: String!, $filter: AssignmentsFilter!, $sorting: SortOrdering) {
  organisation(id: $id) {
    __typename
    id
    assignments(filter: $filter, sorting: $sorting) {
      meta {
        total
        offset
        limit
      }
      edges {
        id
        name: assignmentTitle
        status
        users {
          meta {
            total
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrgAssignmentsTitlesQuery__
 *
 * To run a query within a React component, call `useGetOrgAssignmentsTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgAssignmentsTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgAssignmentsTitlesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetOrgAssignmentsTitlesQuery(baseOptions: Apollo.QueryHookOptions<GetOrgAssignmentsTitlesQuery, GetOrgAssignmentsTitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgAssignmentsTitlesQuery, GetOrgAssignmentsTitlesQueryVariables>(GetOrgAssignmentsTitlesDocument, options);
      }
export function useGetOrgAssignmentsTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgAssignmentsTitlesQuery, GetOrgAssignmentsTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgAssignmentsTitlesQuery, GetOrgAssignmentsTitlesQueryVariables>(GetOrgAssignmentsTitlesDocument, options);
        }
export type GetOrgAssignmentsTitlesQueryHookResult = ReturnType<typeof useGetOrgAssignmentsTitlesQuery>;
export type GetOrgAssignmentsTitlesLazyQueryHookResult = ReturnType<typeof useGetOrgAssignmentsTitlesLazyQuery>;
export type GetOrgAssignmentsTitlesQueryResult = Apollo.QueryResult<GetOrgAssignmentsTitlesQuery, GetOrgAssignmentsTitlesQueryVariables>;