import React from 'react';
import {
  Box,
  Center,
  Flex,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  VStack
} from '@chakra-ui/react';
import map from 'lodash/map';

type TigerhallSkeletonProps = {
  variant:
    | 'card'
    | 'user'
    | 'cloud_item'
    | 'featured_carousel'
    | 'livestream'
    | 'content_detail'
    | 'profile_detail'
    | 'content_card'
    | 'stream_story'
    | 'content_white_card'
    | 'onboard_welcome_user'
    | 'stream_story__light';

  dataTestId?: string;
};

const TigerhallSkeleton = ({ variant, dataTestId }: TigerhallSkeletonProps) => {
  switch (variant) {
    case 'card':
      return (
        <Box width={240} height={248} data-testid={dataTestId}>
          <Skeleton
            mt="0.25rem"
            h={104}
            borderBottomRadius="0"
            borderTopRadius="0.5rem"
            w="100%"
            startColor="darkGrey.50"
            endColor="darkGrey.700"
          />
          <Box
            background="white"
            borderBottomRadius="0.5rem"
            height={126}
            p="0.5rem"
          >
            <SkeletonText
              noOfLines={2}
              startColor="darkGrey.200"
              endColor="darkGrey.400"
            />
          </Box>
        </Box>
      );
    case 'cloud_item':
      return (
        <Skeleton
          data-testid={dataTestId}
          fadeDuration={0.5}
          m="0 0.5rem 0.5rem 0"
          height="2.25rem"
          borderRadius="0.5rem"
          width="9rem"
          startColor="darkGrey.50"
          endColor="darkGrey.700"
        />
      );
    case 'user':
      return (
        <Flex
          data-testid={dataTestId}
          width="100%"
          background="transparent"
          alignItems="center"
          justifyContent="space-between"
          py="0.5rem"
        >
          <Flex alignItems="center">
            <SkeletonCircle size="3.5rem" />
            <SkeletonText ml="1rem" width="10rem" noOfLines={3} spacing="2" />
          </Flex>
          <Skeleton width="120px" height="40px" borderRadius={40} />
        </Flex>
      );
    case 'featured_carousel':
      return (
        <Box
          w="100%"
          h={500}
          bg="darkGrey.700"
          p="4rem"
          data-testid={dataTestId}
        >
          <Box>
            <Skeleton
              mt="1.5rem"
              w="5.5rem"
              h="2rem"
              startColor="orange.500"
              endColor="orange.700"
              mb="1.5rem"
            />
            <Skeleton
              w={360}
              h="0.75rem"
              startColor="white"
              endColor="#F7F7F7"
              mb="0.5rem"
            />
            <Skeleton
              w={240}
              h="0.75rem"
              startColor="white"
              endColor="#F7F7F7"
              mb="1.5rem"
            />
            <Skeleton
              width={120}
              height="0.5rem"
              startColor="white"
              endColor="#e7e7e7"
              mb="0.5rem"
            />
            <Skeleton
              width={150}
              height="6px"
              startColor="white"
              endColor="#f7f7f7"
              mb="0.5rem"
            />
            <Skeleton
              width={120}
              height="6px"
              startColor="orange.500"
              endColor="orange.700"
              mb="2rem"
            />
          </Box>
        </Box>
      );
    case 'livestream':
      return (
        <Box px={16} data-testid={dataTestId}>
          <SkeletonText width="20rem" noOfLines={1} spacing="2" mt={16} />
          <SkeletonText width="10rem" noOfLines={1} spacing="2" mt={4} />
          <Flex mt={8}>
            {map(
              Array.from(Array(20), (_) => (
                <Skeleton
                  fadeDuration={0.5}
                  w={150}
                  mb={10}
                  height={150}
                  startColor="darkGrey.50"
                  endColor="darkGrey.700"
                  mr={4}
                  borderRadius={999}
                />
              ))
            )}
          </Flex>
        </Box>
      );

    case 'onboard_welcome_user':
      return (
        <Center data-testid={dataTestId} height="100%" w="100%">
          <VStack
            padding="6"
            boxShadow="lg"
            alignItems="center"
            w="100%"
            spacing="0.62rem"
            justifyContent="center"
          >
            <SkeletonCircle size="12" />
            <Skeleton height="2rem" w="8rem" />
            <SkeletonText noOfLines={1} w="100%" />
            <SkeletonText noOfLines={1} w="60%" />
            <SkeletonText noOfLines={1} w="40%" />
            <Skeleton mt="2.5rem" height="3rem" w="100%" rounded={20} />
          </VStack>
        </Center>
      );

    case 'content_detail':
      return (
        <Box
          h="100vh"
          maxW="100vw"
          px={{ base: '1rem', md: '2rem' }}
          data-testid={dataTestId}
        >
          <SkeletonText width="20rem" noOfLines={1} spacing="2" mt={16} />
          <SkeletonText width="10rem" noOfLines={1} spacing="2" mt={4} />
        </Box>
      );
    case 'profile_detail':
      return (
        <Flex w="100%" justifyContent="center">
          <VStack
            data-testid={dataTestId}
            borderColor="darkGrey.200"
            borderWidth={{ base: 0, md: '0.5px' }}
            borderRadius={8}
            w="680px"
            mx="auto"
            m={{ base: 0, md: '1rem' }}
            backgroundColor="darkGrey.700"
          >
            <Box padding="2rem" w="full">
              <Box mb="1rem">
                <SkeletonText noOfLines={4} spacing="1rem" />
              </Box>
              <SkeletonCircle size="10" />
              <SkeletonText mt="2rem" noOfLines={6} spacing="1rem" />
            </Box>
          </VStack>
        </Flex>
      );
    case 'content_card':
      return (
        <Box
          data-testid={dataTestId}
          w="100%"
          h="100%"
          background="linear-gradient(180deg, #151618 0%, rgba(0, 31, 36, 0) 100%)"
          className="animate-shimmer"
        />
      );
    case 'content_white_card':
      return (
        <Skeleton data-testid={dataTestId} w="100%" h="100%" rounded="md" />
      );
    case 'stream_story':
      return (
        <VStack w="100%" h="100%" data-testid={dataTestId}>
          <Box
            border="3px solid #FF5900"
            mb="0.5rem"
            w={148}
            h={148}
            overflow="hidden"
            borderRadius="100%"
            background="linear-gradient(180deg, #151618 0%, rgba(0, 31, 36, 0) 100%)"
            className="animate-shimmer"
          />
          <Skeleton
            width={140}
            height="0.5rem"
            startColor="white"
            endColor="white"
          />
          <Skeleton
            width={150}
            height="6px"
            startColor="darkGrey.700"
            endColor="darkGrey.700"
          />
          <Skeleton
            width={120}
            height="6px"
            startColor="orange.500"
            endColor="orange.700"
          />
        </VStack>
      );
    case 'stream_story__light':
      return (
        <VStack w="100%" h="100%" data-testid={dataTestId}>
          <Box
            border="3px solid #FF5900"
            mb="0.5rem"
            w={150}
            h={150}
            overflow="hidden"
            borderRadius="100%"
            background="linear-gradient(45deg, #C7C7C7, transparent)"
            className="animate-shimmer"
          />
          <Skeleton
            width={140}
            height="0.5rem"
            startColor="white"
            endColor="white"
          />
          <Skeleton
            width={150}
            height="6px"
            startColor="#979797"
            endColor="#C7C7C7"
          />
          <Skeleton
            width={120}
            height="6px"
            startColor="orange.500"
            endColor="orange.700"
          />
        </VStack>
      );
    default:
      return null;
  }
};

export { TigerhallSkeleton };
