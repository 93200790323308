/**
 *
 * will return false for shorthand hexColor
 */
export function isHexColor(color: string) {
  const regexToTest = /^#([0-9a-f]{6})$/i;

  return regexToTest.test(color);
}

export function fromHexToOpacity50(color: string) {
  /**
   * Note 80 here is the alpha value which translate to 50% opacity
   */
  return isHexColor(color) ? color.concat('80') : color;
}
