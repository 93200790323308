import { getSelectedOrg } from 'app/state';
import { useAppSelector } from 'hooks';
import { useGetOrganisationMediaQuery } from 'modules/application/queries/generated/organisationMedia.generated';

import { HomePageBanner } from './components/HomePageBanner';
import { OrganisationPageBanner } from './components/OrganisationPageBanner';

interface OrganisationContentBannerProps {
  heading?: string;
  type: 'HOME' | 'ORGANISATION';
}

export function OrganisationContentBanner({
  heading = 'Your Organization’s Content',
  type
}: OrganisationContentBannerProps) {
  const org = useAppSelector(getSelectedOrg);

  const { data: organisationMediaQueryResponse } = useGetOrganisationMediaQuery(
    {
      skip: !org?.id,
      variables: {
        id: org?.id as string
      }
    }
  );

  const logo = organisationMediaQueryResponse?.organisation?.logo;
  const coverImage = organisationMediaQueryResponse?.organisation?.coverImage;

  if (!org?.id) {
    return null;
  }

  switch (type) {
    case 'HOME':
      return (
        <HomePageBanner
          heading={heading}
          logo={logo}
          org={org}
          coverImage={coverImage}
        />
      );
    case 'ORGANISATION':
      return <OrganisationPageBanner heading={heading} logo={logo} org={org} />;
    default:
      return null;
  }
}
