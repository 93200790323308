import {
  Box,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Image,
  Tooltip,
  VStack
} from '@chakra-ui/react';
import { resizeImage } from '@tigerhall/core';
import { IconArrowsDiagonal, IconX } from '@tigerhall/icons';
import { type RefObject, useEffect } from 'react';

import { Rating, type RatingProps } from '~/rating';
import {
  ContentInfo,
  type ContentInfoProps
} from '~/player/components/ContentInfo';
import { VolumeSlider } from '~/player/components/VolumeSlider';
import {
  PlayerControls,
  type PlayerControlsProps
} from '~/player/components/PlayerControls';
import { MinimizedSlider } from './components/MinimizedSlider';

export interface MinimizedPlayerProps
  extends Partial<RatingProps>,
    Pick<ContentInfoProps, 'name' | 'casting'>,
    Pick<PlayerControlsProps, 'hideTrackControls'> {
  /**
   * The featured image URL for the content.
   */
  poster: string;
  /**
   * Callback executed when the close button is clicked.
   */
  onClose: () => void;
  /**
   * Callback executed when the maximize button is clicked.
   */
  onMaximize?: () => void;
  /**
   * If the player should be hidden without being unmounted.
   * This is useful if you want to keep the player state but not have it visible,
   * e.g. if you want to open a video and then resume the podcast after the video is closed.
   */
  isHidden?: boolean;
  trackSelector?: JSX.Element;
  /**
   * This will hide all the media controls, including the play/pause button, the volume slider, etc.
   * This is useful to minimize, e.g. a power read without an available audio version.
   */
  hideAllMediaControls?: boolean;
  audioRef: RefObject<HTMLAudioElement>;
  onAutoNextPlay: (value: boolean) => void;
}

/**
 * This is the minimized player that is displayed at the bottom of the screen
 * when the player is minimized.
 *
 * @todo: add tests. It's a bit difficult to test the changes on different screen sizes.
 */
export function MinimizedPlayer({
  poster,
  onClose,
  onMaximize = () => {},
  isHidden = false,
  trackSelector,
  hideAllMediaControls,

  // from `ContentInfoProps`
  name,
  casting,

  // from `RatingProps`
  vote,
  onLike,
  onDislike,

  // from `PlayerControlsProps`
  hideTrackControls,

  /**
   * For AutoPlaying next content
   */
  audioRef,
  onAutoNextPlay
}: MinimizedPlayerProps) {
  function handleClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.stopPropagation();
    onClose();
  }

  function handleMaximize() {
    onMaximize();
  }

  const isAudioEnded = audioRef.current?.ended;

  useEffect(() => {
    if (isAudioEnded) {
      onAutoNextPlay(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAudioEnded]);

  return (
    <Flex
      display={isHidden ? 'none' : 'flex'}
      zIndex="popover"
      position="fixed"
      bottom={0}
      width="100%"
      height="6.5rem"
      py="0.75rem"
      px={{ base: '0.75rem', md: '1rem' }}
      bg="teal.800"
      gap={{
        base: '1rem',
        lg: '2rem'
      }}
      alignItems="center"
      justifyContent="space-between"
    >
      <MinimizedSlider />
      <Flex gap={'2rem'} alignItems={'center'}>
        <Tooltip label={'Full screen'} openDelay={500} hasArrow>
          <Flex
            as={'button'}
            onClick={handleMaximize}
            aria-label={'Full screen'}
            gap={{
              base: '1rem',
              lg: '2rem'
            }}
            alignItems="center"
          >
            <Image
              src={resizeImage({
                url: poster,
                width: 80,
                height: 80
              })}
              w={{
                base: '2.5rem',
                lg: '5rem'
              }}
              h={{
                base: '2.5rem',
                lg: '5rem'
              }}
              borderRadius="0.5rem"
            />
            <VStack
              direction="column"
              alignItems="stretch"
              spacing="0.375rem"
              width={'100%'}
              maxWidth={{
                base: '10rem',
                sm: '30rem',
                md: '20rem',
                xl: '30rem'
              }}
            >
              <ContentInfo
                name={name}
                casting={casting}
                size={'sm'}
                width={'100%'}
              />
              {trackSelector ? trackSelector : null}
            </VStack>
          </Flex>
        </Tooltip>
        <Box
          display={{
            base: 'none',
            xl: 'flex'
          }}
        >
          {vote && onLike && onDislike ? (
            <Rating vote={vote} onLike={onLike} onDislike={onDislike} />
          ) : null}
        </Box>
      </Flex>

      <Flex
        gap={'2rem'}
        display={{
          base: 'none',
          lg: 'flex'
        }}
      >
        {hideAllMediaControls ? null : (
          <>
            <PlayerControls size="sm" hideTrackControls={hideTrackControls} />
            <VolumeSlider />
          </>
        )}

        <ButtonGroup spacing="1rem" variant="ghost" size="md">
          <Tooltip label="Full screen" hasArrow>
            <IconButton
              aria-label="full screen"
              borderRadius="md"
              onClick={handleMaximize}
              icon={<Icon as={IconArrowsDiagonal} w="1.5rem" h="1.5rem" />}
            />
          </Tooltip>
          <Tooltip label="Close" hasArrow>
            <IconButton
              aria-label="close"
              borderRadius="md"
              onClick={handleClose}
              icon={<Icon as={IconX} w="1.5rem" h="1.5rem" />}
            />
          </Tooltip>
        </ButtonGroup>
      </Flex>
      <Flex
        gap={'1rem'}
        display={{
          base: 'flex',
          lg: 'none'
        }}
      >
        {hideAllMediaControls ? null : (
          <PlayerControls size="sm" hideTrackControls={hideTrackControls} />
        )}

        <Tooltip label="Close" hasArrow>
          <IconButton
            aria-label="close"
            borderRadius="md"
            variant="ghost"
            size="md"
            onClick={handleClose}
            icon={<Icon as={IconX} w="1.5rem" h="1.5rem" />}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
}
