import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgFileAnalytics = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    focusable={false}
    className="th-icon"
    color="white"
    width="1.25rem"
    height="1.25rem"
    ref={ref}
    {...props}
  >
    <path d="M0 0h24v24H0z" />
    <path
      fill="currentColor"
      d="M14 2a1 1 0 0 1 1 1v4h4a1 1 0 1 1 0 2h-4a2 2 0 0 1-2-2V3a1 1 0 0 1 1-1Z"
    />
    <path
      fill="currentColor"
      d="M7 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V8.41L13.59 4H7ZM4.88 2.88A3 3 0 0 1 7 2h7a1 1 0 0 1 .7.3l5 5a1 1 0 0 1 .3.7v11a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V5a3 3 0 0 1 .88-2.12Z"
    />
    <path
      fill="currentColor"
      d="M9 11a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1Zm3 4a1 1 0 0 1 1 1v1a1 1 0 1 1-2 0v-1a1 1 0 0 1 1-1Zm3-2a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Z"
    />
  </svg>
);
SvgFileAnalytics.displayName = "IconFileAnalytics";
const ForwardRef = forwardRef(SvgFileAnalytics);
export default ForwardRef;
