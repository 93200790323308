import * as React from 'react';
import { Button, VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { RANGE_DATES_SHORTCUTS } from '../utils/calanderUtils';

interface RangeShortcutsProps {
  name: string;
  onRangeShortcutClick: () => void;
}

export function RangeShortcuts({
  name,
  onRangeShortcutClick
}: RangeShortcutsProps) {
  const methods = useFormContext();

  return (
    <VStack alignItems="flex-start">
      {RANGE_DATES_SHORTCUTS.map((shortcut) => {
        return (
          <Button
            key={shortcut.id}
            variant="unstyled"
            size="sm"
            boxShadow="none"
            fontWeight="medium"
            borderBottom="1px solid"
            borderBottomColor="transparent"
            borderRadius={0}
            _hover={{
              borderBottomColor: 'tigerOrange.200'
            }}
            _focus={{
              borderBottomColor: 'tigerOrange.600'
            }}
            _active={{
              borderBottomColor: 'tigerOrange.600'
            }}
            onClick={() => {
              onRangeShortcutClick();
              methods.setValue(name, [
                shortcut.getStartDate(),
                shortcut.getEndDate()
              ]);
            }}
          >
            {shortcut.label}
          </Button>
        );
      })}
    </VStack>
  );
}
