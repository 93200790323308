import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Icon, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IconCircleMinus, IconCirclePlus } from '@tigerhall/icons';
import { SingleFullWidthBox } from 'components/ui';
import { TrackedLink } from '@tigerhall/components';

import { AssignmentTable } from './components';

export function ManageAssignments() {
  const navigate = useNavigate();

  const primaryButtonsConfig = [
    {
      name: 'inactive-assignments',
      children: 'Inactive Assignments',
      leftIcon: (
        <Icon as={IconCircleMinus} w="1rem" h="1rem" color="tigerOrange.600" />
      ),
      onClick: () => navigate('/admin/assignments/inactive')
    },
    {
      name: 'new-assignment',
      children: 'New Assignment',
      leftIcon: (
        <Icon as={IconCirclePlus} w="1rem" h="1rem" color="tigerOrange.600" />
      ),
      onClick: () => navigate('/admin/assignments/draft')
    }
  ];

  return (
    <>
      <Helmet>
        <title>Assignments</title>
      </Helmet>
      <SingleFullWidthBox
        title="Assignments"
        buttons={primaryButtonsConfig}
        flex={1}
      >
        <Text fontSize="sm" py="1rem">
          View your organizations assignment history and create new assignments
          in this page. An assignment can include multiple content types
          (podcasts, power reads, videos and trails) that needs to be completed
          by a predefined due date. A group&apos;s assignments are automatically
          assigned to newly added users. To view assignment statistics, please
          click on the &quot;Statistics&quot; tab in the side navigation bar on
          your left or{' '}
          <TrackedLink
            name="AssignmentStatistics"
            href="/admin/statistics"
            color="tigerOrange.200"
          >
            click here.
          </TrackedLink>
        </Text>
        <AssignmentTable />
      </SingleFullWidthBox>
    </>
  );
}
