import { type ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const FormLabel: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: (props) => ({
    fontSize: '0.875rem',
    color: mode('darkGrey.200', 'lightGrey.900')(props),

    '::first-letter': {
      textTransform: 'uppercase'
    }
  })
};

export const FormErrorMessage: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: (props) => {
    return {
      text: {
        fontSize: '0.875rem',
        lineHeight: '1.2',
        color: mode('red.500', 'lightGrey.600')(props)
      }
    };
  },
  parts: ['text', 'icon']
};
