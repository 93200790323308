import * as React from 'react';
import { FallbackRender } from '@sentry/react';

export interface PureScreenErrorFallbackProps
  extends React.ComponentProps<FallbackRender> {}

/**
 * Default fallback UI for the `ErrorBoundary` component.
 * This component uses plain HTML elements and CSS to render
 * the fallback UI. That means that this component can wrap
 * the whole application without any styling issues.
 *
 * @example
 * ```tsx
 * <ErrorBoundary fallback={PureScreenErrorFallback}>
 *   <App /> // ---> This is the component that throws the error
 * </ErrorBoundary>
 * ```
 */
export function PureScreenErrorFallback({
  error,
  resetError
}: PureScreenErrorFallbackProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        gap: '0.5rem',
        background: '#151618',
        width: '100%',
        height: 'auto',
        maxHeight: '100vh',
        padding: '10rem 2rem'
      }}
    >
      <span
        style={{
          fontSize: '4rem',
          textAlign: 'center'
        }}
      >
        ⚠️
      </span>
      <h1
        style={{
          color: '#fff',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          textAlign: 'center'
        }}
      >
        An error occurred:
      </h1>
      <p
        style={{
          color: '#fff',
          fontSize: '1rem',
          textAlign: 'center'
        }}
      >
        {error.name}: {error.message}
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignContent: 'center',
          gap: '1rem',
          paddingTop: '1rem'
        }}
      >
        <button
          type="button"
          onClick={resetError}
          style={{
            background: '#fff',
            color: '#151618',
            fontWeight: 'bold',
            padding: '0.5rem 1rem',
            borderRadius: '2rem'
          }}
        >
          Try Again
        </button>
        <button
          type="button"
          onClick={() => {
            window.localStorage.clear();
            window.location.reload();
          }}
          style={{
            background: 'transparent',
            color: '#fff',
            fontWeight: 'bold',
            padding: '0.5rem 1rem',
            borderRadius: '2rem',
            border: '1px solid #fff'
          }}
        >
          Logout & Refresh
        </button>
      </div>
    </div>
  );
}
