import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssignmentContentsByIdQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
}>;


export type GetAssignmentContentsByIdQuery = { __typename?: 'Query', assignment: { __typename: 'Assignment', id: string, assignmentTitle: string, contents?: { __typename?: 'ContentConnection', edges: Array<{ __typename: 'Ebook', id: string, name: string, length: number, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } } | { __typename: 'Event', id: string, name: string, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } } | { __typename?: 'Expert' } | { __typename: 'LearningPath', id: string, name: string, contentCards: Array<{ __typename: 'Ebook', id: string, name: string, length: number, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } } | { __typename: 'Event', id: string, name: string, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } } | { __typename?: 'Expert' } | { __typename?: 'LearningPath' } | { __typename: 'Podcast', id: string, name: string, length: number, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } } | { __typename: 'Stream', id: string, name: string, length: number, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } }> } | { __typename: 'Podcast', id: string, name: string, length: number, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } } | { __typename: 'Stream', id: string, name: string, length: number, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } }> } | null } };

export type GetTrailContentsByIdQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;


export type GetTrailContentsByIdQuery = { __typename?: 'Query', learningPath?: { __typename: 'LearningPath', id: string, name: string, contentCards: Array<{ __typename: 'Ebook', id: string, name: string, length: number, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } } | { __typename: 'Event', id: string, name: string, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } } | { __typename?: 'Expert' } | { __typename?: 'LearningPath' } | { __typename: 'Podcast', id: string, name: string, length: number, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } } | { __typename: 'Stream', id: string, name: string, length: number, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } }> } | null };

export type ContentBasicInfo_Ebook_Fragment = { __typename: 'Ebook', id: string, name: string, length: number, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } };

export type ContentBasicInfo_Event_Fragment = { __typename: 'Event', id: string, name: string, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } };

export type ContentBasicInfo_Expert_Fragment = { __typename?: 'Expert' };

export type ContentBasicInfo_LearningPath_Fragment = { __typename?: 'LearningPath' };

export type ContentBasicInfo_Podcast_Fragment = { __typename: 'Podcast', id: string, name: string, length: number, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } };

export type ContentBasicInfo_Stream_Fragment = { __typename: 'Stream', id: string, name: string, length: number, userContentTracking: { __typename?: 'UserContentTracking', id: string, isFinished: boolean } };

export type ContentBasicInfoFragment = ContentBasicInfo_Ebook_Fragment | ContentBasicInfo_Event_Fragment | ContentBasicInfo_Expert_Fragment | ContentBasicInfo_LearningPath_Fragment | ContentBasicInfo_Podcast_Fragment | ContentBasicInfo_Stream_Fragment;

export const ContentBasicInfoFragmentDoc = gql`
    fragment ContentBasicInfo on ContentCard {
  ... on Event {
    id
    name
    __typename
    userContentTracking {
      id
      isFinished
    }
  }
  ... on Ebook {
    id
    name
    length
    __typename
    userContentTracking {
      id
      isFinished
    }
  }
  ... on Podcast {
    id
    name
    length
    __typename
    userContentTracking {
      id
      isFinished
    }
  }
  ... on Stream {
    id
    name
    length
    __typename
    userContentTracking {
      id
      isFinished
    }
  }
}
    `;
export const GetAssignmentContentsByIdDocument = gql`
    query GetAssignmentContentsById($id: ID!) {
  assignment(id: $id) {
    id
    assignmentTitle
    __typename
    contents {
      edges {
        ...ContentBasicInfo
        ... on LearningPath {
          id
          name
          __typename
          contentCards {
            ...ContentBasicInfo
          }
        }
      }
    }
  }
}
    ${ContentBasicInfoFragmentDoc}`;

/**
 * __useGetAssignmentContentsByIdQuery__
 *
 * To run a query within a React component, call `useGetAssignmentContentsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentContentsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentContentsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssignmentContentsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentContentsByIdQuery, GetAssignmentContentsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentContentsByIdQuery, GetAssignmentContentsByIdQueryVariables>(GetAssignmentContentsByIdDocument, options);
      }
export function useGetAssignmentContentsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentContentsByIdQuery, GetAssignmentContentsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentContentsByIdQuery, GetAssignmentContentsByIdQueryVariables>(GetAssignmentContentsByIdDocument, options);
        }
export type GetAssignmentContentsByIdQueryHookResult = ReturnType<typeof useGetAssignmentContentsByIdQuery>;
export type GetAssignmentContentsByIdLazyQueryHookResult = ReturnType<typeof useGetAssignmentContentsByIdLazyQuery>;
export type GetAssignmentContentsByIdQueryResult = Apollo.QueryResult<GetAssignmentContentsByIdQuery, GetAssignmentContentsByIdQueryVariables>;
export const GetTrailContentsByIdDocument = gql`
    query GetTrailContentsById($id: String!) {
  learningPath(id: $id) {
    id
    name
    __typename
    contentCards {
      ...ContentBasicInfo
    }
  }
}
    ${ContentBasicInfoFragmentDoc}`;

/**
 * __useGetTrailContentsByIdQuery__
 *
 * To run a query within a React component, call `useGetTrailContentsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrailContentsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrailContentsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTrailContentsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTrailContentsByIdQuery, GetTrailContentsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrailContentsByIdQuery, GetTrailContentsByIdQueryVariables>(GetTrailContentsByIdDocument, options);
      }
export function useGetTrailContentsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrailContentsByIdQuery, GetTrailContentsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrailContentsByIdQuery, GetTrailContentsByIdQueryVariables>(GetTrailContentsByIdDocument, options);
        }
export type GetTrailContentsByIdQueryHookResult = ReturnType<typeof useGetTrailContentsByIdQuery>;
export type GetTrailContentsByIdLazyQueryHookResult = ReturnType<typeof useGetTrailContentsByIdLazyQuery>;
export type GetTrailContentsByIdQueryResult = Apollo.QueryResult<GetTrailContentsByIdQuery, GetTrailContentsByIdQueryVariables>;