import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  type ModalProps
} from '@chakra-ui/react';
import { type LearningPath } from '@tigerhall/core';
import { useNavigate } from 'react-router-dom';

import { LeftPanel, RightPanel } from './children';

export interface LearningPathSummaryModalProps {
  content: LearningPath;
  actions: React.ReactNode;
  isOpen: ModalProps['isOpen'];
  onClose?: ModalProps['onClose'];
}

export function LearningPathSummaryModal({
  actions,
  content,
  isOpen,
  onClose
}: Readonly<LearningPathSummaryModalProps>) {
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={isOpen}
      size="5xl"
      onClose={onClose ? onClose : () => navigate(-1)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent border="1px solid" borderColor="lightGrey.200" p={0}>
        <ModalCloseButton />
        <ModalBody p={0} borderRadius="md" minHeight="30rem">
          <Flex direction="row" flex={1} height="100%" minHeight="30rem">
            <Flex
              flex={1}
              p="1.5rem"
              direction="column"
              justifyContent="space-between"
              gap="3rem"
            >
              <LeftPanel content={content} actions={actions} />
            </Flex>
            <Flex
              flex={2}
              direction="column"
              borderRightRadius="md"
              bg="darkGrey.400"
              p="1.5rem"
            >
              <RightPanel content={content} />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
