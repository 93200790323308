import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import {
  IconBook,
  IconLive,
  IconRoute,
  IconHeadphones,
  IconMovie
} from '@tigerhall/icons';

export const IconComponent = ({ type }) => {
  switch (type) {
    case 'Podcast':
      return <IconHeadphones />;

    case 'Ebook':
      return <IconBook />;

    case 'Event':
      return <IconLive />;

    case 'Stream':
      return <IconMovie />;

    case 'LearningPath':
      return <IconRoute />;

    default:
      return <Text>{type}</Text>;
  }
};

export const ContentIcon = ({ contentType }) => (
  <Flex
    position="absolute"
    bottom="8px"
    left="8px"
    background="tigerOrange.600"
    borderRadius="12px"
    alignItems="center"
    justifyContent="center"
  >
    <IconComponent type={contentType} />
  </Flex>
);

export default ContentIcon;
