import {
  type ElementRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Box,
  Button,
  Circle,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react';
import { IconBell, IconCircleCheck, IconInbox } from '@tigerhall/icons';
import { NotificationViewerType } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import { Typography } from '@tigerhall/ui-components';
import {
  useGetUserNotificationsLazyQuery,
  useUpdateAllNotificationsAsReadMutation,
  useUpdateAllNotificationsAsSeenMutation
} from 'generated';
import { cache } from 'api/apollo';

import { NotificationItem } from './children';
import { LAYOUT_HEIGHT_WITHOUT_HEADER } from '../../const';
import { useNewNotificationsCountPoll } from './hooks';

export function NotificationsCenter() {
  const [shouldShowTooltip, setShouldShowTooltip] = useState(true);

  const buttonRef = useRef<ElementRef<'button'>>(null);

  useEffect(() => {
    const tooltipTimeout = setTimeout(() => {
      setShouldShowTooltip(false);
    }, 3000);

    return () => {
      clearTimeout(tooltipTimeout);
    };
  }, []);

  const [getUserNotifications, { data: userNotifications }] =
    useGetUserNotificationsLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        filter: {
          viewerType: NotificationViewerType.Web
        }
      }
    });

  // when closing the notifications center
  const [updateAllNotificationsAsSeen] =
    useUpdateAllNotificationsAsSeenMutation();

  // when the user clicks "mark all as read" button
  const [updateAllNotificationsAsRead] =
    useUpdateAllNotificationsAsReadMutation({
      variables: {
        filter: {
          viewerType: NotificationViewerType.Web
        }
      },
      onCompleted: () => {
        getUserNotifications();

        cache.evict({
          id: `User:${userNotifications?.me?.id}`,
          fieldName: 'newNotifications'
        });

        cache.gc();
      }
    });

  const newNotifications = useNewNotificationsCountPoll();

  const hasMoreNotifications =
    userNotifications?.me?.notifications?.meta?.hasNext;
  const nextNotificationCursor =
    userNotifications?.me?.notifications?.meta?.nextCursor;
  const notifications = userNotifications?.me?.notifications?.edges || [];

  const handleMarkAsRead = useCallback(() => {
    segment.buttonClicked({
      buttonName: 'Mark all as seen',
      location: window.location.pathname
    });

    updateAllNotificationsAsRead();
  }, [updateAllNotificationsAsRead]);

  return (
    <Box pr="0.5rem" zIndex={10}>
      <Popover
        isLazy
        onOpen={() => {
          segment.navBarClicked({
            section: 'Notification Centre',
            location: window.location.pathname
          });
          getUserNotifications();
        }}
        onClose={() => {
          updateAllNotificationsAsSeen();
        }}
      >
        <PopoverTrigger>
          <Button
            ref={buttonRef}
            aria-label="Notifications"
            position="relative"
            borderRadius="lg"
            variant="unstyled"
            display="flex"
            alignItems="center"
            p=".5rem"
            _active={{
              background: 'darkGrey.400'
            }}
            _hover={{
              background: 'darkGrey.400'
            }}
          >
            <Icon
              as={IconBell}
              color="lightGrey.400"
              height="1.5rem"
              width="1.5rem"
            />
            {newNotifications > 0 ? (
              <Circle
                position="absolute"
                top="0.5rem"
                right="0.5rem"
                size="0.5rem"
                background="tigerOrange.600"
              />
            ) : null}
            <Tooltip
              isOpen={shouldShowTooltip}
              hasArrow
              label={newNotifications}
              bg="white"
              px="0.75rem"
              py="0.5rem"
              borderRadius="md"
              color="darkGrey.400"
              fontWeight="bold"
              portalProps={{ containerRef: buttonRef }}
            >
              <Box position="absolute" bottom="0" left="0" right="0" />
            </Tooltip>
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            background="teal.900"
            borderWidth="1px"
            borderColor="darkGrey.200"
            overflow="hidden"
            width={{
              base: '100vw',
              md: '27.5rem'
            }}
            height={{
              base: LAYOUT_HEIGHT_WITHOUT_HEADER,
              md: '37.5rem'
            }}
          >
            <PopoverHeader
              background="darkGrey.700"
              borderColor="darkGrey.200"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px="1rem"
              py="0.75rem"
            >
              <Text fontSize="md">Notifications</Text>
              <Button
                variant="ghost"
                size="sm"
                color="white"
                rightIcon={
                  <Icon
                    as={IconCircleCheck}
                    height="0.875rem"
                    width="0.875rem"
                  />
                }
                _active={{
                  background: 'darkGrey.400'
                }}
                _hover={{
                  background: 'darkGrey.400'
                }}
                onClick={handleMarkAsRead}
              >
                <Text as="span" fontSize="sm">
                  Mark all as Read
                </Text>
              </Button>
            </PopoverHeader>
            <PopoverBody
              height="100%"
              p={0}
              overflow="auto"
              css={{
                '&::-webkit-scrollbar': {
                  width: '4px'
                },
                '&::-webkit-scrollbar-track': {
                  width: '6px'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#C4C4C4',
                  borderRadius: '24px'
                }
              }}
            >
              {notifications && notifications.length > 0 ? (
                <VStack spacing={0}>
                  {notifications.map(({ notification }) => (
                    <NotificationItem key={notification.id} {...notification} />
                  ))}
                  {notifications.length >= 6 && hasMoreNotifications ? (
                    <Button
                      width="100%"
                      variant="ghost"
                      size="sm"
                      color="white"
                      borderRadius={0}
                      mt="auto"
                      justifySelf="flex-end"
                      _active={{
                        background: 'darkGrey.400'
                      }}
                      _hover={{
                        background: 'darkGrey.400'
                      }}
                      onClick={() => {
                        segment.buttonClicked({
                          buttonName: 'Load More Notifications',
                          location: window.location.pathname
                        });
                        getUserNotifications({
                          variables: {
                            limit: 10,
                            afterCursor: nextNotificationCursor,
                            filter: {
                              viewerType: NotificationViewerType.Web
                            }
                          }
                        });
                      }}
                    >
                      Load more
                    </Button>
                  ) : null}
                </VStack>
              ) : (
                <VStack
                  width="100%"
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Icon
                    as={IconInbox}
                    color="darkGrey.50"
                    height="3rem"
                    width="3rem"
                  />
                  <Typography variant="detail2" color="darkGrey.50">
                    You have no new notifications
                  </Typography>
                </VStack>
              )}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
}
