import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrganisationMediaQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;


export type GetOrganisationMediaQuery = { __typename?: 'Query', organisation: { __typename: 'Organisation', id: string, logo?: { __typename?: 'Image', id: string, uri: string, alt: string, width: number, height: number } | null, coverImage?: { __typename?: 'Image', id: string, uri: string, alt: string } | null } };


export const GetOrganisationMediaDocument = gql`
    query GetOrganisationMedia($id: String!) {
  organisation(id: $id) {
    __typename
    id
    logo {
      id
      uri
      alt
      width
      height
    }
    coverImage {
      id
      uri
      alt
    }
  }
}
    `;

/**
 * __useGetOrganisationMediaQuery__
 *
 * To run a query within a React component, call `useGetOrganisationMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationMediaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganisationMediaQuery(baseOptions: Apollo.QueryHookOptions<GetOrganisationMediaQuery, GetOrganisationMediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationMediaQuery, GetOrganisationMediaQueryVariables>(GetOrganisationMediaDocument, options);
      }
export function useGetOrganisationMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationMediaQuery, GetOrganisationMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationMediaQuery, GetOrganisationMediaQueryVariables>(GetOrganisationMediaDocument, options);
        }
export type GetOrganisationMediaQueryHookResult = ReturnType<typeof useGetOrganisationMediaQuery>;
export type GetOrganisationMediaLazyQueryHookResult = ReturnType<typeof useGetOrganisationMediaLazyQuery>;
export type GetOrganisationMediaQueryResult = Apollo.QueryResult<GetOrganisationMediaQuery, GetOrganisationMediaQueryVariables>;