import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { ReactionsLabel, ReactionsEmoji, ReactionType } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';

import { ReactedUserList } from './ReactedUsersList';

interface ReactionsTypeTabsProps {
  tabs: {
    type: ReactionType;
    emoji: ReactionsEmoji;
    label: ReactionsLabel;
    count: number;
  }[];
  totalCount: number;
}

export function ReactionsTypeTabs({
  tabs,
  totalCount
}: ReactionsTypeTabsProps) {
  return (
    <Box flex={1} width="100%">
      <Tabs
        isFitted
        isLazy
        onChange={(index) => {
          segment.tabClicked({
            tabId: tabs[index].type,
            tabName: tabs[index].label
          });
        }}
      >
        <TabList>
          <Tab>All {totalCount}</Tab>
          {tabs.map((tab) => (
            <Tab key={tab.type}>
              {tab.emoji} {tab.count}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel>
            <ReactedUserList reactionType="All" />
          </TabPanel>
          {tabs.map((tab) => (
            <TabPanel key={tab.type}>
              <ReactedUserList reactionType={tab.type} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
}
