import * as React from 'react';
import * as segment from '@tigerhall/analytics';
import { hasOrgAdminAccess, getUserId } from 'app/state';
import { useAppSelector } from 'hooks';
import { OptionsMenuList } from 'components/ui/OptionsMenu';

import {
  ReportCommentOption,
  type ReportCommentOptionProps
} from './components/ReportCommentOption';
import {
  DeleteCommentOption,
  type DeleteCommentOptionProps
} from './components/DeleteCommentOption';

export interface CommentOptionsProps
  extends Pick<DeleteCommentOptionProps, 'onDelete'> {
  authorId: string;
  commentId: DeleteCommentOptionProps['commentId'];
}

export function CommentOptions({
  authorId,
  commentId,
  onDelete
}: CommentOptionsProps) {
  const isAdmin = useAppSelector(hasOrgAdminAccess);
  const currentUserId = useAppSelector(getUserId);

  const isCommentOwner = React.useMemo(() => {
    return authorId === currentUserId;
  }, [authorId, currentUserId]);

  const canEdit = React.useMemo(() => {
    return isCommentOwner;
  }, [isCommentOwner]);

  const canReport = React.useMemo(() => {
    return !isCommentOwner;
  }, [isCommentOwner]);

  const canDelete = React.useMemo(() => {
    return isCommentOwner || isAdmin;
  }, [isCommentOwner, isAdmin]);

  if (!canEdit && !canReport && !canDelete) {
    return null;
  }

  return (
    <>
      <OptionsMenuList
        onOpen={() => {
          segment.commentOptionsClicked({
            referenceId: commentId,
            buttonName: 'options'
          });
        }}
      >
        {/* todo: disabled until the functionality is impemented
          {canEdit && <EditCommentOption commentId={commentId}/>} 
          */}
        {canReport && <ReportCommentOption commentId={commentId} />}
        {canDelete && (
          <DeleteCommentOption
            commentId={commentId}
            onDelete={onDelete}
            isCommentOwner={isCommentOwner}
          />
        )}
      </OptionsMenuList>
    </>
  );
}
