import { DateTime } from 'luxon';
import { useRefreshTokenMutation } from 'generated';
import { captureException } from '@sentry/react';
import {
  AUTH_COOKIE_NAME,
  AUTH_COOKIE_EXPIRE_HOURS,
  getCookie,
  logger,
  setCookie
} from '@tigerhall/core';
import {
  getAccessToken,
  getTokenIssuedAt,
  logout,
  setAccessToken
} from 'app/state';
import { AUTH_COOKIE_DOMAIN } from 'utils/constant';
import { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from './redux';

/**
 * This hook will refresh the user token if the token was issues more than
 * 1 month ago, this way we prevent the user to be logged out because the
 * token expires in 3 months.
 *
 * It will also logout the user if the token is invalid.
 */
export function useRefreshToken() {
  const dispatch = useAppDispatch();

  const token = useAppSelector(getAccessToken);
  const iat = useAppSelector(getTokenIssuedAt);

  const tokenIssuedAt = iat ? DateTime.fromSeconds(iat) : undefined;
  const shouldRefreshToken = tokenIssuedAt
    ? tokenIssuedAt.plus({ month: 1 }).toMillis() < DateTime.local().toMillis()
    : false;

  const [refreshToken] = useRefreshTokenMutation();

  const refresh = useCallback(async () => {
    try {
      const { data } = await refreshToken();
      if (data?.refreshToken?.jwt) {
        dispatch(
          setAccessToken({
            token: data.refreshToken.jwt
          })
        );
      }
    } catch (error) {
      // we don't want to logout the user if the refresh token fails
      // because the user might simply be offline, so we just log the error
      logger.error(error);
    }
  }, [dispatch, refreshToken]);

  useEffect(() => {
    const isAuthInCookie = getCookie(AUTH_COOKIE_NAME);

    // if user clear the cookies so reassigning the token
    if (token && !isAuthInCookie) {
      setCookie(
        AUTH_COOKIE_NAME,
        `jwt ${token}`,
        AUTH_COOKIE_EXPIRE_HOURS,
        AUTH_COOKIE_DOMAIN
      );
    }
    if (token && shouldRefreshToken) {
      refresh().catch((e) => captureException(e));
    } else if (!token) {
      dispatch(logout());
    }
  }, [token, shouldRefreshToken, dispatch, refresh]);
}
