import React, { useState } from 'react';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { Modal as ModalComponent } from '@tigerhall/ui-components';

type MutationConfig = {
  variables: any;
};

type ToastConfig = {
  successTitle?: string;
  successDescription?: string;
  errorTitle?: string;
  errorDescription?: string;
};

export type ActionConfig = {
  modalConfig: { [key: string]: any };
  mutationConfig: MutationConfig;
  toastConfig: ToastConfig;
};

const DEFAULT_ACTION_CONFIG = {
  modalConfig: {},
  mutationConfig: {
    useAction: null,
    variables: {}
  },
  toastConfig: {}
};

const withAction =
  ({ actionMutation }) =>
  (WrappedComponent: any) => {
    return (props: any) => {
      const [actionConfig, setConfig] = useState<ActionConfig>(
        DEFAULT_ACTION_CONFIG
      );

      const { isOpen, onOpen, onClose } = useDisclosure();

      const setActionConfig = (config) => {
        setConfig(config);
        onOpen();
      };

      const toast = useToast({
        position: 'top-right',
        duration: 5000,
        isClosable: true
      });

      const { modalConfig, mutationConfig, toastConfig } = actionConfig;

      const { title, body, primaryActionText, postAction } = modalConfig;
      const { successTitle, successDescription, errorTitle, errorDescription } =
        toastConfig;

      const { variables } = mutationConfig;

      const [action, { loading }] = actionMutation();

      const onPressPrimaryButton = () => {
        action?.({ variables })
          .then(() => {
            onClose();
            toast({
              title: successTitle || 'Success',
              description: successDescription || '',
              status: 'success'
            });
            postAction();
          })
          .catch(() => {
            onClose();
            toast({
              title: errorTitle || 'Error Ocurred.',
              description: errorDescription || 'Please try in some time.',
              status: 'error'
            });
            postAction();
          });
      };
      return (
        <>
          <ModalComponent
            title={title}
            body={body}
            isOpen={isOpen}
            onClose={onClose}
            primaryActionText={primaryActionText || ''}
            primaryActionLoading={loading}
            primaryButtonOnClick={onPressPrimaryButton}
          />
          <WrappedComponent setActionConfig={setActionConfig} {...props} />
        </>
      );
    };
  };

export default withAction;
