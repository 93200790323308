import * as React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea,
  TextareaProps
} from '@chakra-ui/react';
import {
  Controller,
  UseControllerProps,
  useFormContext,
  UseFormReturn
} from 'react-hook-form';

interface FormTextareaProps
  extends Pick<
    TextareaProps,
    '_before' | '_after' | 'onKeyDown' | 'style' | 'placeholder' | 'h'
  > {
  /**
   * The name of the input
   */
  name: string;

  /**
   * The label of the tooltip
   */
  label: string;

  /**
   * If the component is disabled
   */
  disabled?: boolean;

  rules?: UseControllerProps['rules'];
  analyticsDisabled?: boolean;
}

export function FormTextarea({
  name,
  label,
  placeholder,
  rules = {},
  disabled = false,
  analyticsDisabled = false,
  ...props
}: FormTextareaProps) {
  const methods = useFormContext() as UseFormReturn & {
    formName: string;
  };

  if (methods === null) {
    return <Text color="state.error">Missing form context</Text>;
  }

  if (!methods.formName && !analyticsDisabled) {
    return <Text color="state.error">Missing form name</Text>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      defaultValue=""
      render={({ field, fieldState }) => (
        <FormControl
          isInvalid={!!fieldState.error}
          isRequired={!!rules?.required}
          isDisabled={disabled}
        >
          {label && <FormLabel>{label}</FormLabel>}
          <Textarea
            {...props}
            name={field.name}
            placeholder={placeholder}
            onChange={field.onChange}
            onBlur={field.onBlur}
            ref={field.ref}
            data-cy={`${methods.formName}-${name}`}
            value={field.value}
          />
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}
