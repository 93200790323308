import { type ComponentStyleConfig } from '@chakra-ui/react';

export const Switch: ComponentStyleConfig = {
  baseStyle: {
    track: {
      _dark: {
        bg: 'darkGrey.50',
        _checked: {
          bg: 'tigerOrange.600'
        }
      }
    }
  }
};
