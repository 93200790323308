import { Input as r } from "./index11.mjs";
const o = {
  // style object for base or default style
  baseStyle: (e) => ({
    ...r.baseStyle(e).field,
    minH: "10rem"
  }),
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    variant: "",
    focusBorderColor: "tigerOrange.800"
  }
};
export {
  o as Textarea
};
