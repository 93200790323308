import * as React from 'react';
import { Center, Flex, Icon, Text } from '@chakra-ui/react';
import { IconCirclePlus } from '@tigerhall/icons';

type AddNewMemberProps = {
  onClick: () => void;
};

export function AddNewMember({ onClick }: AddNewMemberProps) {
  return (
    <Center
      as="button"
      w="100%"
      h="6.25rem"
      onClick={onClick}
      border="2px dashed"
      borderColor="darkGrey.50"
      borderRadius={10}
    >
      <Flex gap={4} alignItems="center">
        <Icon as={IconCirclePlus} color="white" />
        <Text fontSize="md" color="white">
          Add more users
        </Text>
      </Flex>
    </Center>
  );
}
