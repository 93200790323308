import {
  Ebook,
  Event,
  LearningPathInput,
  Podcast,
  Stream
} from '@tigerhall/core';

import { LearningPathState } from '~/app/state';

export type ContentCards = (Ebook | Stream | Podcast | Event)[];

function mapToId(contents: { id: string }[]) {
  return contents.map((item) => item.id);
}

export function createLearningPathInput(
  orgId: string,
  draftMeta: LearningPathState['draft']['meta'],
  contents: ContentCards
): LearningPathInput | null {
  if (
    !draftMeta.name ||
    !draftMeta.description ||
    !draftMeta.image?.id ||
    !draftMeta.category?.id ||
    contents.length === 0
  ) {
    return null;
  }

  const ebooks = mapToId(
    contents.filter(
      (content): content is Ebook => content.__typename === 'Ebook'
    )
  );
  const podcasts = mapToId(
    contents.filter(
      (content): content is Podcast => content.__typename === 'Podcast'
    )
  );
  const events = mapToId(
    contents.filter(
      (content): content is Event => content.__typename === 'Event'
    )
  );
  const streams = mapToId(
    contents.filter(
      (content): content is Stream => content.__typename === 'Stream'
    )
  );
  const sortOrder = mapToId(contents);

  return {
    name: draftMeta.name,
    preamble: draftMeta.description,
    image: draftMeta.image.id,
    categories: [draftMeta.category.id],
    ebooks,
    podcasts,
    events,
    streams,
    sortOrder,
    organisationID: orgId,
    organisationGroups:
      draftMeta.organisationGroups?.map((group) => group.id) || []
  };
}
