import { ApolloProvider } from '@apollo/client';
import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react';
import { LinkProvider } from '@tigerhall/components';
import { Fonts, theme as baseTheme } from '@tigerhall/theme';
import { apolloClient } from 'api/apollo';
import { useTHForm } from 'hooks/useTHForm';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider } from 'react-hook-form';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from 'app/store';
import { MicrosoftTeamsProvider } from '@tigerhall/core';
import { LinkWrapper } from 'components/ui';
import { persistStore } from 'redux-persist';
import { Splash } from 'components/ui/Layout/components/Splash';

import { Router } from './Router';
import { ErrorBoundary, ScreenErrorFallback } from '../ErrorBoundary';
import { MicrosoftTeamsAuthGuard } from '../../containers';

const theme = extendTheme(baseTheme, {
  config: {
    initialColorMode: 'dark'
  },
  components: {
    Heading: {
      baseStyle: {
        // This override is intentional. We don't use the default font
        // for headings (Formula) in the webapp.
        fontFamily: 'body',
        // todo: we should remove this to use the lineHeight coming
        // from the theme but it breaks some components, including
        // the content cards. We should fix this.
        lineHeight: '1'
      }
    }
  }
});

const persistor = persistStore(store);

const App = () => {
  // This is a placeholder form so that useFormContext does not break in buttons not inside form
  // todo: this should be removed, all form elements should either be uncontrolled or inside a tracked form
  const form = useTHForm({ formName: '' });

  // This callback has been added because of a bug that caused the segment
  // identify to disappear for authenticated users and we only set the segment identity
  // after the user has logged in, so after sufficient time this can probably be removed!
  const onReduxHydrated = React.useCallback(() => {
    const { user } = store.getState();

    if (user.claims.sub) {
      window.analytics.identify(user.claims.sub);
    }

    if (user.claims.organisationId) {
      window.analytics.group(user.claims.organisationId);
    }
  }, []);

  return (
    <>
      <Helmet
        titleTemplate="%s | Tigerhall"
        defaultTitle="Tigerhall: Align and Drive Change With Speed and Agility"
      />
      <Provider store={store}>
        <PersistGate persistor={persistor} onBeforeLift={onReduxHydrated}>
          <ApolloProvider client={apolloClient}>
            <Fonts />
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />

            <ChakraProvider
              theme={theme}
              toastOptions={{
                defaultOptions: {
                  position: 'top-right',
                  isClosable: true,
                  duration: 5000
                }
              }}
            >
              <FormProvider {...form}>
                <LinkProvider
                  value={{
                    link: LinkWrapper
                  }}
                >
                  <ErrorBoundary fallback={ScreenErrorFallback}>
                    <MicrosoftTeamsProvider>
                      <MicrosoftTeamsAuthGuard />
                      <React.Suspense fallback={<Splash />}>
                        <Router />
                      </React.Suspense>
                    </MicrosoftTeamsProvider>
                  </ErrorBoundary>
                </LinkProvider>
              </FormProvider>
            </ChakraProvider>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
