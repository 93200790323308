import { useEffect, useRef, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { TrackedButton } from '@tigerhall/components';
import { ParsedText } from 'components/ui/ParsedText';

export interface CommentTextProps {
  text: string;
  expandButtonLabel?: string;
}

const NO_OF_LINES = 3;
const LINE_HEIGHT = 1.375;
// Here the size is in pixels so can be used in the `MAX_INITIAL_HEIGHT` calculation
const FONT_SIZE = 14;
const MAX_INITIAL_HEIGHT = NO_OF_LINES * LINE_HEIGHT * FONT_SIZE;

/**
 * TODO: The functionality of this component is pretty similar to `PostText`.
 * We should consider refactoring them into a single component (or at least
 * extract the common logic into a hook).
 */
export function CommentText({
  text,
  expandButtonLabel = '... See more'
}: Readonly<CommentTextProps>) {
  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const [hasSeeMoreButton, setHasSeeMoreButton] = useState(false);

  const textNode = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const { offsetHeight = 0 } = textNode.current ?? {};

    if (offsetHeight >= MAX_INITIAL_HEIGHT) {
      setHasSeeMoreButton(true);
    } else {
      setIsTextExpanded(false);
    }
  }, [text]);

  function handleExpandText() {
    setIsTextExpanded(true);
    setHasSeeMoreButton(false);
  }

  return (
    <Flex position="relative">
      <ParsedText
        textProps={{
          fontSize: FONT_SIZE,
          lineHeight: LINE_HEIGHT,
          noOfLines: isTextExpanded ? undefined : NO_OF_LINES,
          color: 'lightGrey.400'
        }}
        rawText={text}
        ref={textNode}
      />
      {hasSeeMoreButton ? (
        <Flex
          position="absolute"
          right={0}
          bottom={0}
          background="darkGrey.400"
        >
          <TrackedButton
            name="COMMENT_SEE_MORE"
            variant="unstyled"
            height="auto"
            fontWeight="medium"
            fontSize={FONT_SIZE}
            color="lightGrey.900"
            px="0.5rem"
            borderRadius={0}
            _hover={{
              background: 'darkGrey.400',
              color: 'tigerOrange.600'
            }}
            onFocus={handleExpandText}
            onClick={handleExpandText}
          >
            {expandButtonLabel}
          </TrackedButton>
        </Flex>
      ) : null}
    </Flex>
  );
}
