import * as React from 'react';
import * as Sentry from '@sentry/react';

import { ScreenErrorFallback } from './components/ScreenErrorFallback';
import { mergeErrorContext } from './utils';

interface ErrorBoundaryProps
  extends React.ComponentProps<typeof Sentry.ErrorBoundary> {}

/**
 * `ErrorBoundary` component that wraps the `Sentry.ErrorBoundary` component
 * and provides a default fallback UI. This component also merges the current
 * sentry scope with the error context that is available when we throw
 * an error using the `AppError` class instead of the native `Error` class.
 *
 * @see https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/
 *
 * @example
 * ```tsx
 * <ErrorBoundary>
 *   <Component /> // ---> This is the component that throws the error
 * </ErrorBoundary>
 * ```
 */
export function ErrorBoundary({ children, ...props }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={ScreenErrorFallback}
      beforeCapture={mergeErrorContext}
      {...props}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
