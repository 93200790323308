import { useState } from 'react';
import { Button, Flex, Icon } from '@chakra-ui/react';
import {
  ContentType,
  LearningPath,
  LearningPathFilter
} from '@tigerhall/core/lib/types';
import { IconEdit, IconPlus, IconTrash } from '@tigerhall/icons';
import { extractLearningPathContent, resizeImage } from '@tigerhall/core';
import { THLink } from '@tigerhall/ui-components';
import { useNavigate } from 'react-router-dom';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import { InfiniteSingleTypeCardGrid, PageBanner } from 'components/ui';
import {
  useDeleteLearningPathMutation,
  useGetLearningPathInfoByIdQuery
} from 'generated';
import withAction from 'hoc/withAction';
import { addContentCards, getOrgIdFromToken } from 'app/state';
import { useAppDispatch, useAppSelector } from 'hooks';
import { LearningPathSummaryModal } from 'modules/admin/components';
import {
  GenericHistoryProps,
  GenericSearchForm
} from 'modules/admin/components/GenericSearchForm';

import useDraftLearningPaths from '../../hooks/useDraftLearningPaths';
import { trailContentRoute } from '../../utils/getTrailsRoutes';

function TrailHistory({ setActionConfig }: Readonly<GenericHistoryProps>) {
  const orgId = useAppSelector(getOrgIdFromToken);
  const navigate = useNavigate();
  const [selectedLearningPath, setSelectedLearningPath] = useState<string>();

  const { data } = useGetLearningPathInfoByIdQuery({
    skip: !selectedLearningPath,
    variables: {
      id: selectedLearningPath as string
    }
  });

  const [filter, setFilter] = useState<LearningPathFilter>({
    organisationID: [orgId as string],

    limit: 10,
    offset: 0
  });

  const { actions } = useDraftLearningPaths();
  const dispatch = useAppDispatch();

  // TODO: This entire draft thing/hook/actions should die in the future :) ENG-7212
  // We have to push the Learning Path to Draft to be able to edit it
  function pushLearningPathToDraft(learningPath: LearningPath) {
    const content = extractLearningPathContent(learningPath);
    const contentIds = map(content, (contentData) => contentData?.id);

    dispatch(addContentCards(keyBy(content, 'id')));

    const meta = {
      name: learningPath?.name,
      description: learningPath?.preamble,
      category: learningPath?.categories[0],
      image: {
        id: learningPath?.image?.id,
        url: resizeImage({
          url: learningPath?.image?.uri || '',
          width: 250
        })
      },
      organisationGroups: learningPath?.organisationGroups ?? []
    };
    actions.setContentIds(contentIds);
    actions.addMeta(meta);
  }

  function getActionConfig(card, refetch) {
    return {
      modalConfig: {
        title: 'Delete Trail',
        body: 'Are you sure you want to delete this Trail?',
        primaryActionText: 'Delete',
        postAction: () => {
          refetch?.();
        }
      },
      mutationConfig: {
        variables: {
          id: card?.id
        }
      },
      toastConfig: {
        successDescription: 'Trail Deleted successfully.'
      }
    };
  }

  return (
    <>
      <PageBanner
        title="My Trails"
        description="Create a custom trail by picking content that best fits your team's unique needs. Fill out some details about the trail and hit publish! You can later assign these trails to your team members using the Manage Assignments tab to the left."
        action={
          <THLink name="CREATE_NEW_TRAIL" to={trailContentRoute('draft')}>
            <Button
              size="lg"
              data-cy="create-new-trail"
              leftIcon={<IconPlus color="tigerOrange.600" />}
            >
              New Trail
            </Button>
          </THLink>
        }
      />
      <Flex mt="2rem" direction="column" gap="1rem">
        <GenericSearchForm
          forTrail
          setFilter={setFilter}
          heading="Trails History"
          formName="trailHistorySearch"
          isThinkfluencerSearchEnabled={false}
        />
        <InfiniteSingleTypeCardGrid
          type={ContentType.LearningPath}
          filter={filter}
          overlayActions={(card, refetch) => [
            {
              label: 'Learn More',
              variant: 'outline',
              onClick: () => {
                setSelectedLearningPath(card.id);
              }
            },
            {
              label: 'Edit',
              onClick: () => {
                pushLearningPathToDraft(card as LearningPath);
                navigate(trailContentRoute(card.id));
              },
              leftIcon: <Icon as={IconEdit} />
            },
            {
              label: 'Delete',
              onClick: () => {
                setActionConfig(getActionConfig(card, refetch));
              },
              leftIcon: <Icon as={IconTrash} />
            }
          ]}
        />
        <LearningPathSummaryModal
          isOpen={!!data?.learningPath?.id}
          onClose={() => setSelectedLearningPath(undefined)}
          content={data?.learningPath as LearningPath}
          actions={
            <Button
              flex={1}
              variant="outline"
              onClick={() => {
                setSelectedLearningPath(undefined);
              }}
            >
              Close
            </Button>
          }
        />
      </Flex>
    </>
  );
}

export default withAction({ actionMutation: useDeleteLearningPathMutation })(
  TrailHistory
);
