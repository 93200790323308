import type { PlaylistType } from '@tigerhall/analytics';
import {
  useGetAssignmentTitleByIdLazyQuery,
  useGetTrailTitleByIdLazyQuery
} from 'components/ui/ContentPlayer/queries';
import { useEffect, useState } from 'react';
import { captureException } from '@sentry/react';

import type {
  AssignmentContentsData,
  TrailContentsData
} from './useAutoplayContent/types';

const PLAYLIST_TYPE_PARAM = {
  trail: 'trail',
  assignment: 'assignment'
};

export function useGetPlaylistInfoFromURL() {
  const [playlistTitle, setPlaylistTitle] = useState<string | undefined>();
  const contentUrl = window.location.href;
  const searchParams = new URLSearchParams(window.location.search);

  const isPlayingFromAssignment = searchParams.has(
    PLAYLIST_TYPE_PARAM.assignment
  );

  const assignmentId =
    searchParams.get(PLAYLIST_TYPE_PARAM.assignment) ?? undefined;

  const isPlayingFromTrail = searchParams.has(PLAYLIST_TYPE_PARAM.trail);

  const trailId = searchParams.get(PLAYLIST_TYPE_PARAM.trail) ?? undefined;

  let playlistId: string | undefined;
  let playlistType: PlaylistType | undefined;

  switch (true) {
    case isPlayingFromAssignment:
      playlistId = assignmentId;
      playlistType = 'Assignment';
      break;
    case isPlayingFromTrail:
      playlistId = trailId;
      playlistType = 'Trail';
      break;
    default:
      playlistId = undefined;
      playlistType = undefined;
  }

  const isPlaylist = isPlayingFromAssignment || isPlayingFromTrail;

  const [getAssignmentTitle] = useGetAssignmentTitleByIdLazyQuery({
    variables: { id: playlistId ?? '' },
    fetchPolicy: 'cache-first'
  });
  const [getTrailTitle] = useGetTrailTitleByIdLazyQuery({
    variables: { id: playlistId ?? '' },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (!isPlaylist) {
      return;
    }

    async function getPlaylistTitle() {
      if (playlistType === 'Trail') {
        const { data } = await getTrailTitle();

        const title = (data as TrailContentsData)?.learningPath.name;

        setPlaylistTitle(title);
      } else {
        const { data } = await getAssignmentTitle();

        const title = (data as AssignmentContentsData)?.assignment
          .assignmentTitle;

        setPlaylistTitle(title);
      }
    }
    getPlaylistTitle().catch((error) => {
      captureException(error, {
        tags: {
          playlistType
        }
      });
    });
  }, [getAssignmentTitle, getTrailTitle, isPlaylist, playlistType]);

  return {
    playlistId,
    playlistType,
    playlistTitle,
    contentUrl
  };
}
