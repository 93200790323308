import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgCircle = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="transparent"
    viewBox="0 0 24 24"
    focusable={false}
    className="th-icon"
    ref={ref}
    {...props}
  >
    <path
      stroke="#B2B8BB"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 12a9 9 0 1 0 18.001 0A9 9 0 0 0 3 12Z"
    />
  </svg>
);
SvgCircle.displayName = "IconCircle";
const ForwardRef = forwardRef(SvgCircle);
export default ForwardRef;
