import { HStack } from '@chakra-ui/react';
import { ProfileBar, type ProfileBarProps } from '@tigerhall/components';

import { useUserBaseProfile } from '~/components/ui/User/hooks/useUserBaseProfile';
import { BlockButton } from '~/components/ui/Buttons/BlockButton';
import { ProfileBarBlockSkeleton } from './ProfileBarBlockSkeleton';

interface ProfileBarBlockProps extends Omit<ProfileBarProps, 'profile'> {
  /**
   * The user ID to fetch the profile for.
   */
  userId: string;
}

export function ProfileBarBlock({
  userId,
  ...props
}: Readonly<ProfileBarBlockProps>) {
  const { profile, thinkfluencerScope } = useUserBaseProfile(userId);

  if (!profile) {
    return <ProfileBarBlockSkeleton size={props.size} />;
  }

  const { isExpert = false, isBlockedByCurrentUser = false } = profile;

  return (
    <HStack justifyContent="space-between">
      <ProfileBar
        profile={profile}
        thinkfluencerScope={thinkfluencerScope}
        {...props}
      />
      <BlockButton
        isBlocked={isBlockedByCurrentUser}
        isThinkfluencer={isExpert}
        userId={userId}
      />
    </HStack>
  );
}
