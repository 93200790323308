import { Flex } from '@chakra-ui/react';
import { FormInput, TrackedButton, TrackedForm } from '@tigerhall/components';
import { useSearchParams } from 'react-router-dom';
import { useLoginWithEmailHook } from 'modules/authentication/hook';

type FormFields = {
  email: string;
  password: string;
};

export function FormEmail() {
  const [params] = useSearchParams();

  const { triggerLogin } = useLoginWithEmailHook();

  // extract email from query params
  const email = params.get('e') ?? '';

  return (
    <TrackedForm<FormFields>
      name="Login"
      onSubmit={triggerLogin}
      formHookProps={{
        defaultValues: {
          email
        }
      }}
      focus={email ? 'password' : 'email'}
    >
      {({ formState: { isSubmitting } }) => {
        return (
          <Flex direction="column" gap="0.75rem">
            <FormInput
              name="email"
              type="email"
              label="Email"
              autoComplete="email"
              placeholder="Email"
              rules={{ required: true }}
            />
            <FormInput
              name="password"
              type="password"
              label="Password"
              autoComplete="current-password"
              placeholder="Password"
              rules={{ required: true }}
            />

            <TrackedButton
              name="login"
              mt="1.75rem"
              type="submit"
              size="lg"
              isLoading={isSubmitting}
              w="100%"
            >
              Login
            </TrackedButton>
          </Flex>
        );
      }}
    </TrackedForm>
  );
}
