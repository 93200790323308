import {
  Box,
  Button,
  Flex,
  HStack,
  LightMode,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import { TranslatedByAI } from '~/info-box/TranslatedByAI';
import { TrackedButton } from '~/tracked';
import { SelectOption } from './components';
import { type LanguagesProps } from './const';

export function LanguagesModal({
  isOpen,
  onClose,

  mediaHeading,
  transcriptHeading,

  mediaLanguages,
  transcriptLanguages,

  selectedMediaLanguage,
  selectedTranscriptLanguage,

  onChangeMediaLanguage,
  onChangeTranscriptLanguage
}: Readonly<LanguagesProps>) {
  const [newMediaLanguage, setNewMediaLanguage] = useState<string>(
    selectedMediaLanguage
  );

  const [newTranscriptLanguage, setNewTranscriptLanguage] = useState<
    string | null
  >(selectedTranscriptLanguage);

  useEffect(() => {
    // Setting `selectedTranscriptLanguage ?? null` in the `useState` doesn't work
    // when this re-renders, so we need to update the state here
    setNewTranscriptLanguage(selectedTranscriptLanguage);
  }, [selectedTranscriptLanguage]);

  const handleApply = useCallback(() => {
    onChangeMediaLanguage(newMediaLanguage);
    onChangeTranscriptLanguage(newTranscriptLanguage);
    onClose();
  }, [
    newMediaLanguage,
    newTranscriptLanguage,
    onChangeMediaLanguage,
    onChangeTranscriptLanguage,
    onClose
  ]);

  return (
    <LightMode>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="blackAlpha.800" />
        <ModalContent bg="lightGrey.100" maxWidth="35rem">
          <ModalBody
            display="flex"
            maxH="30rem"
            width="35rem"
            justifyContent="center"
            padding="0"
            zIndex="skipLink"
            overflow={'hidden'}
          >
            <Box maxW="35rem" width="100%" borderRadius="0.5rem">
              <HStack
                p={'1rem'}
                gap={4}
                bg={'lightGrey.400'}
                borderTopRadius={{ base: 'none', md: 'lg' }}
              >
                <ModalHeader
                  flex={1}
                  textAlign={'left'}
                  color={'darkGrey.400'}
                  p={0}
                  fontSize={'lg'}
                  fontWeight={'medium'}
                >
                  Change Language
                </ModalHeader>

                <ModalCloseButton
                  size={'md'}
                  pos={'static'}
                  marginStart={0}
                  color={'darkGrey.200'}
                />
              </HStack>
              <TranslatedByAI />
              <Flex
                p="1rem"
                width="100%"
                gap="1rem"
                maxH="80%"
                style={{ colorScheme: 'light' }}
              >
                <Box width="50%">
                  <Text
                    fontSize="md"
                    fontWeight="bold"
                    color="darkGrey.300"
                    padding="0.375rem 1.25rem 1.25rem"
                    as="h2"
                  >
                    {mediaHeading}
                  </Text>
                  {/* Increasing  paddingBottom to 10rem to show all options, which gets hidden behind the Modal Footer*/}
                  <Box maxH={'100%'} pb="10rem" overflowY="auto">
                    {mediaLanguages.map((lang) => (
                      <SelectOption
                        key={lang.code}
                        optionName={lang.name}
                        onClick={() => setNewMediaLanguage(lang.code)}
                        isSelected={newMediaLanguage === lang.code}
                      />
                    ))}
                  </Box>
                </Box>
                <Box width="50%">
                  <Text
                    fontSize="md"
                    fontWeight="bold"
                    color="darkGrey.300"
                    padding="0.375rem 1.25rem 1.25rem"
                    as="h2"
                  >
                    {transcriptHeading}
                  </Text>
                  <Box maxH={'100%'} pb="10rem" overflowY="auto">
                    <SelectOption
                      onClick={() => setNewTranscriptLanguage(null)}
                      optionName="Off"
                      isSelected={!newTranscriptLanguage}
                    />
                    {transcriptLanguages.map((lang) => (
                      <SelectOption
                        key={lang.code}
                        optionName={lang.name}
                        onClick={() => setNewTranscriptLanguage(lang.code)}
                        isSelected={newTranscriptLanguage === lang.code}
                      />
                    ))}
                  </Box>
                </Box>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter
            justifyContent={'space-between'}
            px={'1.5rem'}
            py={'1rem'}
            borderTopWidth={1}
            borderTopColor={'lightGrey.400'}
          >
            <Button size="lg" onClick={onClose} variant={'ghost'}>
              Cancel
            </Button>
            <TrackedButton
              size="lg"
              name="submit"
              type="submit"
              onClick={handleApply}
              variant="solidDark"
            >
              Apply
            </TrackedButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </LightMode>
  );
}
