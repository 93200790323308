import capitalize from 'lodash/capitalize';

import { Organisation, Pretty, User } from '~/types';

type ExtractedOrgFields = Pick<Organisation, 'type'>;

export type FormatSubscriptionUserType = Pick<User, 'subscriptionStatus'> & {
  organisation?: ExtractedOrgFields | null;
  organisations?: ExtractedOrgFields[];
};

/**
 *
 * @description this reutrn the user's plan type based on org type
 * mostly will be `ENTERPRISE` or `TEAMS`
 */
export const getUserPlanType = (
  organisation: Pick<Organisation, 'type'> | undefined | null
) => {
  if (!organisation) {
    return 'Individual';
  }

  return organisation?.type;
};

/**
 * Display the name of the current subscription plan of a user
 */
export function formatUserSubscriptionPlanName(
  user: Pretty<FormatSubscriptionUserType>
) {
  const status = capitalize(user?.subscriptionStatus ?? '');

  // need this because we can't fetch organisation field of other users
  let notCurrentUserOrg: null | ExtractedOrgFields = null;

  if (user.organisations) {
    notCurrentUserOrg = user.organisations[0];
  }

  const plan = capitalize(
    getUserPlanType(user?.organisation || notCurrentUserOrg) ?? ''
  );

  if (plan && status) {
    return `${plan} - ${status}`;
  }

  return status;
}
