import { HStack } from '@chakra-ui/react';
import { ProfileBar, type ProfileBarProps } from '@tigerhall/components';

import { FollowButton } from '~/components/ui/Buttons/FollowButton';
import { useUserBaseProfile } from '~/components/ui/User/hooks/useUserBaseProfile';
import { ProfileBarFollowSkeleton } from './ProfileBarFollowSkeleton';

interface ProfileBarFollowProps extends Omit<ProfileBarProps, 'profile'> {
  /**
   * The user ID to fetch the profile for.
   */
  userId: string;
}

export function ProfileBarFollow({
  userId,
  ...props
}: Readonly<ProfileBarFollowProps>) {
  const { profile, thinkfluencerScope } = useUserBaseProfile(userId);

  if (!profile) {
    return <ProfileBarFollowSkeleton size={props.size} />;
  }

  const { isFollowing = false } = profile;

  return (
    <HStack justifyContent="space-between">
      <ProfileBar
        profile={profile}
        thinkfluencerScope={thinkfluencerScope}
        {...props}
      />
      <FollowButton isFollowing={isFollowing} userId={userId} />
    </HStack>
  );
}
