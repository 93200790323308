import { j as f } from "./index78.mjs";
import * as u from "react";
import { Button as l } from "@chakra-ui/react";
import { useFormContext as x } from "react-hook-form";
import { buttonClicked as d } from "./index83.mjs";
const B = u.forwardRef(({ name: a, children: e, ...t }, i) => {
  const o = x();
  return /* @__PURE__ */ f.jsx(
    l,
    {
      ...t,
      onClick: (c) => {
        var m, n;
        const r = ((m = window == null ? void 0 : window.location) == null ? void 0 : m.pathname) || "";
        d({
          location: r,
          buttonName: a,
          formName: o == null ? void 0 : o.formName
        }), (n = t == null ? void 0 : t.onClick) == null || n.call(t, c);
      },
      ref: i,
      children: e
    }
  );
});
export {
  B as TrackedButton
};
