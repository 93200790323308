import { Button, type ButtonProps } from '@chakra-ui/react';
import * as segment from '@tigerhall/analytics';

import { useGetCurrentUserQuery } from '~/generated';
import { useFollowUserToggle } from '~/modules/application/hooks/useFollowUserToggle';

interface FollowButtonProps {
  /**
   * Whether the current logged-in user is following the owner if the `userId`
   */
  isFollowing: boolean;
  /**
   * The id of the user to follow/unfollow
   */
  userId: string;
  /**
   * The size of the button.
   *
   * @default 'md'
   */
  size?: ButtonProps['size'];
}

export function FollowButton({
  isFollowing,
  userId,
  size = 'md'
}: Readonly<FollowButtonProps>) {
  const { hadleFollowUserToggle, loading } = useFollowUserToggle();

  const { data } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first'
  });

  const loggedInUserId = data?.me?.id;

  return loggedInUserId !== userId ? (
    <Button
      isLoading={loading}
      zIndex="base"
      variant={isFollowing ? 'outline' : 'solid'}
      minWidth="6rem"
      size={size}
      onClick={() => {
        hadleFollowUserToggle(userId);
        segment.followButtonClicked({
          followerId: userId,
          isFollowing
        });
      }}
    >
      {isFollowing ? 'Following' : 'Follow'}
    </Button>
  ) : null;
}
