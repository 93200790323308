import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  type ModalProps
} from '@chakra-ui/react';
import { FeedContentType } from '@tigerhall/core';
import { TableBodyFallback } from '@tigerhall/ui-components';
import { useGetPostByIdQuery } from 'generated';
import { Suspense, lazy } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPreviousRoute } from 'utils';

import { PostSkeleton } from '../Post/PostSkeleton';

const Post = lazy(() =>
  import('../Post').then((module) => ({
    default: module.Post
  }))
);

const PostComments = lazy(() =>
  import('../PostComments').then((module) => ({
    default: module.PostComments
  }))
);

export interface PostModalProps
  extends Omit<ModalProps, 'children' | 'isOpen' | 'onClose' | 'onOpen'> {}

export function PostModal(props: PostModalProps) {
  const { postId } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useGetPostByIdQuery({
    skip: !postId,
    variables: {
      id: postId as string,
      type: FeedContentType.Post
    }
  });

  const post = data?.feedContentById;

  function closeModal() {
    const { prevRoute, replace } = getPreviousRoute({
      fallback: '/feed'
    });

    if (typeof prevRoute === 'string') {
      navigate(prevRoute, { replace });
    } else {
      navigate(prevRoute);
    }
  }

  return (
    <Modal
      size="2xl"
      isOpen={true}
      onClose={closeModal}
      /**
       * adding autoFocus to modal because every time we open another modal/popover
       * on top of it, the modal scrolls to top, and we do not want that.
       */

      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={false}
      {...props}
    >
      <ModalOverlay />
      <ModalContent border="1px solid" borderColor="darkGrey.300">
        <ModalCloseButton />
        <ModalHeader
          py="1.5rem"
          background={'whiteAlpha.200'}
          borderBottom={'1px'}
          borderColor="darkGrey.300"
        />
        <ModalBody p="0">
          {error ? (
            <Center p="2rem" pb="4rem">
              <TableBodyFallback
                heading="This post is not available"
                subheading="This post may have been deleted or you may not have access to it."
              />
            </Center>
          ) : null}
          {loading ? <PostSkeleton /> : null}
          {post?.__typename === 'Post' && (
            <>
              <Suspense fallback={<PostSkeleton />}>
                <Post post={post} onDelete={closeModal} />
              </Suspense>
              <Suspense fallback={null}>
                <PostComments postId={post.id} />
              </Suspense>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
