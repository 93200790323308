import * as React from 'react';
import {
  Box,
  Center,
  Flex,
  HStack,
  SlideFade,
  Stack,
  Text
} from '@chakra-ui/react';
import { FormInput } from '@tigerhall/components';
import { IconX } from '@tigerhall/icons';

export type MemberType = {
  firstName: string;
  lastName: string;
  email: string;
};

type Props = {
  memberNumber: number;
  onRemove: (number) => void;
  index: number;
  member: MemberType;
  hasError: boolean;
};

export function AddMemberForm({ memberNumber, onRemove, index }: Props) {
  return (
    <Box w="100%">
      <SlideFade in offsetY={-8}>
        <HStack
          w="100%"
          p={{ base: '1rem', md: 4 }}
          h="auto"
          spacing="1rem"
          border="1px solid"
          borderColor="lightGrey.600"
          borderRadius={10}
        >
          <Center w="1rem">
            <Text as="span" fontSize="md" color="white">
              {memberNumber}
            </Text>
          </Center>
          <form style={{ flex: 1 }} autoComplete="off">
            <Stack spacing="1rem">
              <Flex
                gap="1rem"
                flexDirection={{
                  base: 'column',
                  md: 'row'
                }}
              >
                <Box flex={1}>
                  <FormInput
                    label="First Name"
                    name={`member.${index}.firstName`}
                    rules={{ required: true }}
                    placeholder="First name"
                  />
                </Box>
                <Box flex={1}>
                  <FormInput
                    label="Last Name"
                    name={`member.${index}.lastName`}
                    rules={{ required: true }}
                    placeholder="Last name"
                  />
                </Box>
              </Flex>
              <FormInput
                name={`member.${index}.email`}
                rules={{ required: true }}
                placeholder="Email"
                type="email"
                label="Email"
              />
            </Stack>
          </form>
          {memberNumber > 1 && (
            <Center cursor="pointer" onClick={() => onRemove(memberNumber)}>
              <IconX />
            </Center>
          )}
        </HStack>
      </SlideFade>
    </Box>
  );
}
