import { type RefObject, useCallback, useEffect } from 'react';

import { useMediaState, type MediaStateStore } from '~/player/context/state';
import { useMediaReset } from '~/player/hooks/useMediaReset';

export interface UseMediaPlayerReturn
  extends Pick<
    MediaStateStore,
    'setVolume' | 'setPlaybackRate' | 'customDuration'
  > {
  /**
   * Move forward or backward in the media by the provided amount in ms
   *
   * @param value
   */
  seekDelta: (delta: number) => void;

  /**
   * Seek to a specific position in the media
   *
   * @param position
   */
  seekTo: (position: number) => void;

  /**
   * Start playing the media
   */
  play: () => void;

  /**
   * Pause the media
   */
  pause: () => void;

  /**
   * Toggle between playing and pausing the media
   */
  togglePlay: () => void;
}

export interface UseMediaPlayerArgs {
  /**
   * This callback is different from on track finished because this is
   * triggered when we really hit the end of the content piece.
   * Useful e.g. to play the next content piece.
   */
  onFinished: () => void;
  customDuration?: number;
  /**
   * How much the media has been played before by the user, in seconds.
   * This is useful to set the initial progress of the media when it's loaded.
   * We usually get this value from `userContentTracking.currentPlacement`.
   *
   * @default 0
   */
  previousProgress?: number;
  /**
   * If the media should start playing as soon as it's loaded.
   *
   * @default true
   */
  defaultAutoPlay?: boolean;
}

export const useMediaPlayer = (
  mediaElementRef: RefObject<HTMLVideoElement | HTMLAudioElement>,
  {
    onFinished,
    customDuration,
    previousProgress = 0,
    defaultAutoPlay = true
  }: UseMediaPlayerArgs
): UseMediaPlayerReturn => {
  const {
    isLoading,
    isPlaying,
    setDuration,
    setCustomDuration,
    setIsLoading,
    setIsPlaying,
    setDidJustSeek,
    setProgress,
    setPlaybackRate: setStatePlaybackRate,
    setVolume: setStateVolume,
  } = useMediaState((state) => ({
    isLoading: state.isLoading,
    isPlaying: state.isPlaying,
    setDuration: state.setDuration,
    setCustomDuration: state.setCustomDuration,
    setIsLoading: state.setIsLoading,
    setIsPlaying: state.setIsPlaying,
    setDidJustSeek: state.setDidJustSeek,
    setProgress: state.setProgress,
    setPlaybackRate: state.setPlaybackRate,
    setVolume: state.setVolume,
  }));

  useMediaReset(mediaElementRef);

  useEffect(() => {
    // If the media has been played before, set the progress to the previous value
    if (mediaElementRef.current && previousProgress > 0) {
      mediaElementRef.current.currentTime = previousProgress;
    }

    // this is intentional, we only want to run this effect once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customDuration) {
      setCustomDuration(customDuration);
    }
  }, [customDuration, setCustomDuration]);

  useEffect(() => {
    setIsPlaying(defaultAutoPlay);
  }, [defaultAutoPlay, setIsPlaying]);

  const seekDelta = useCallback(
    (value: number) => {
      if (mediaElementRef.current) {
        const time = mediaElementRef.current.currentTime + value;

        mediaElementRef.current.currentTime = time;

        setDidJustSeek(time);
      }
    },
    [mediaElementRef, setDidJustSeek]
  );

  const seekTo = useCallback(
    (value: number) => {
      if (mediaElementRef.current) {
        mediaElementRef.current.currentTime = value;

        setDidJustSeek(value);
      }
    },
    [mediaElementRef, setDidJustSeek]
  );

  const play = useCallback(() => {
    setIsPlaying(true);
  }, [setIsPlaying]);

  const pause = useCallback(() => {
    setIsPlaying(false);
  }, [setIsPlaying]);

  const togglePlay = useCallback(() => {
    setIsPlaying(!isPlaying);
  }, [isPlaying, setIsPlaying]);

  const setPlaybackRate = useCallback(
    (playbackRate: number) => {
      if (mediaElementRef.current) {
        mediaElementRef.current.playbackRate = playbackRate;

        setStatePlaybackRate(playbackRate);
      }
    },
    [mediaElementRef, setStatePlaybackRate]
  );

  const setVolume = useCallback(
    (volume: number) => {
      if (mediaElementRef.current) {
        mediaElementRef.current.volume = volume;
        setStateVolume(volume);
      }
    },
    [mediaElementRef, setStateVolume]
  );

  useEffect(() => {
    const onPressSpace = (e: KeyboardEvent) => {
      // don't perform podcast action if the user is typing something in input text
      if (
        document.activeElement?.nodeName.includes('INPUT') ||
        document.activeElement?.nodeName.includes('TEXTAREA')
      ) {
        return;
      }

      switch (e.code) {
        case 'Space':
          togglePlay();

          // prevent scrolling down the page
          // to be entirely honest I'm not sure if this is a good idea
          e.preventDefault();
          break;

        case 'ArrowLeft':
          seekDelta(-15);
          break;

        case 'ArrowRight':
          seekDelta(15);
          break;
      }
    };

    window.addEventListener('keyup', onPressSpace);

    return () => {
      window.removeEventListener('keyup', onPressSpace);
    };
  }, [seekDelta, isPlaying, togglePlay]);

  useEffect(() => {
    if (!mediaElementRef?.current) {
      return;
    }

    const mediaElement = mediaElementRef.current;

    const onPlaybackStatusChange = () => {
      setIsPlaying(!mediaElement.paused);
    };

    const onDurationChange = () => {
      setDuration(mediaElement.duration || 0);
    };

    const onTimeUpdate = () => {
      setProgress(mediaElement.currentTime);
    };

    const onEnded = () => {
      setProgress(mediaElement.currentTime);
      onFinished();
    };

    const onCanPlay = () => {
      // The default behavior is to request that the video auto plays
      // but this only works if the user has interacted with the document
      // prior to the video being loaded.
      //
      // What this does is check if the video is playing and if it's not
      // then it sets the state to not playing. So that the interface does not
      // show that it's playing while it actually needs the user to press play.
      if (isPlaying && mediaElement.paused) {
        setIsPlaying(false);
      }

      setStateVolume(mediaElement.volume);
    };

    const onLoadedMetadata = () => {
      if (isLoading) {
        setIsLoading(false);
      }
    };

    mediaElement.addEventListener('play', onPlaybackStatusChange);
    mediaElement.addEventListener('pause', onPlaybackStatusChange);
    mediaElement.addEventListener('durationchange', onDurationChange);
    mediaElement.addEventListener('timeupdate', onTimeUpdate);
    mediaElement.addEventListener('ended', onEnded);
    mediaElement.addEventListener('canplay', onCanPlay);
    mediaElement.addEventListener('loadedmetadata', onLoadedMetadata);

    return () => {
      mediaElement.removeEventListener('play', onPlaybackStatusChange);
      mediaElement.removeEventListener('pause', onPlaybackStatusChange);
      mediaElement.removeEventListener('durationchange', onDurationChange);
      mediaElement.removeEventListener('timeupdate', onTimeUpdate);
      mediaElement.removeEventListener('ended', onEnded);
      mediaElement.removeEventListener('canplay', onCanPlay);
      mediaElement.removeEventListener('loadedmetadata', onLoadedMetadata);
    };
  }, [
    isLoading,
    isPlaying,
    mediaElementRef,
    onFinished,
    setDuration,
    setIsLoading,
    setIsPlaying,
    setProgress,
    setStateVolume,
  ]);

  useEffect(() => {
    const playSafely = async () => {
      try {
        await mediaElementRef?.current?.play();
      } catch (e) {
        // extra safety. Similar to what we do in `onCanPlay`
        // what this does is it tries to play the video and if it fails
        // then it sets the state to not playing. So that the interface does not
        // show that it's playing while it actually needs the user to press play.
        setIsPlaying(false);
        console.warn(e);
      }
    };

    if (isPlaying) {
      playSafely();
    } else {
      mediaElementRef?.current?.pause();
    }
  }, [isPlaying, mediaElementRef, setIsPlaying]);

  return {
    seekTo,
    seekDelta,
    play,
    pause,
    togglePlay,
    setPlaybackRate,
    setVolume
  };
};
