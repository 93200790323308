import { Text } from '@chakra-ui/react';
import { DarkPanel } from 'components/ui';
import { type User, formatUserSubscriptionPlanName } from '@tigerhall/core';

interface ProfileInfoCardProps {
  user: User;
}

export default function ProfileInfoCard({
  user
}: Readonly<ProfileInfoCardProps>) {
  const { firstName, lastName } = user;

  return (
    <DarkPanel gap="1.5">
      <Text fontSize="1.25rem" fontWeight="700" color="white">
        {`${firstName} ${lastName}`}
      </Text>
      <Text fontSize="1rem" fontWeight="500" color="darkGrey.50">
        Current Membership Plan: {formatUserSubscriptionPlanName(user)}
      </Text>
    </DarkPanel>
  );
}
