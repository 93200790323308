import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoginMutationVariables = SchemaTypes.Exact<{
  email: SchemaTypes.Scalars['String'];
  password: SchemaTypes.Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', loginEmail?: { __typename?: 'AuthenticationResponse', jwt: string, success: boolean, message: string, finishedRegistration: boolean } | null };

export type LoginEmailV2MutationVariables = SchemaTypes.Exact<{
  email: SchemaTypes.Scalars['String'];
  password: SchemaTypes.Scalars['String'];
}>;


export type LoginEmailV2Mutation = { __typename?: 'Mutation', loginEmailV2: { __typename: 'AuthenticationError', message: string } | { __typename?: 'AuthenticationResponse', jwt: string, success: boolean, message: string, finishedRegistration: boolean } | { __typename: 'ValidationError', fieldErrors: Array<{ __typename?: 'FieldError', message: string, path: string }> } };

export type IsSsoEnabledMutationVariables = SchemaTypes.Exact<{
  email: SchemaTypes.Scalars['String'];
}>;


export type IsSsoEnabledMutation = { __typename?: 'Mutation', isSSOEnabled: boolean };

export type LoginLinkedInMutationVariables = SchemaTypes.Exact<{
  token: SchemaTypes.Scalars['String'];
  redirectUri: SchemaTypes.Scalars['String'];
}>;


export type LoginLinkedInMutation = { __typename?: 'Mutation', loginLinkedIn?: { __typename?: 'AuthenticationResponse', success: boolean, message: string, newAccount: boolean, jwt: string, userId: string, finishedRegistration: boolean } | null };

export type LoginAppleMutationVariables = SchemaTypes.Exact<{
  authorizationCode: SchemaTypes.Scalars['String'];
  firstName: SchemaTypes.Scalars['String'];
  lastName: SchemaTypes.Scalars['String'];
}>;


export type LoginAppleMutation = { __typename?: 'Mutation', loginApple?: { __typename?: 'AuthenticationResponse', success: boolean, message: string, newAccount: boolean, jwt: string, userId: string, finishedRegistration: boolean } | null };


export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  loginEmail(email: $email, password: $password) {
    jwt
    success
    message
    finishedRegistration
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoginEmailV2Document = gql`
    mutation loginEmailV2($email: String!, $password: String!) {
  loginEmailV2(email: $email, password: $password) {
    ... on AuthenticationError {
      __typename
      message
    }
    ... on ValidationError {
      __typename
      fieldErrors {
        message
        path
      }
    }
    ... on AuthenticationResponse {
      jwt
      success
      message
      finishedRegistration
    }
  }
}
    `;
export type LoginEmailV2MutationFn = Apollo.MutationFunction<LoginEmailV2Mutation, LoginEmailV2MutationVariables>;

/**
 * __useLoginEmailV2Mutation__
 *
 * To run a mutation, you first call `useLoginEmailV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginEmailV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginEmailV2Mutation, { data, loading, error }] = useLoginEmailV2Mutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginEmailV2Mutation(baseOptions?: Apollo.MutationHookOptions<LoginEmailV2Mutation, LoginEmailV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginEmailV2Mutation, LoginEmailV2MutationVariables>(LoginEmailV2Document, options);
      }
export type LoginEmailV2MutationHookResult = ReturnType<typeof useLoginEmailV2Mutation>;
export type LoginEmailV2MutationResult = Apollo.MutationResult<LoginEmailV2Mutation>;
export type LoginEmailV2MutationOptions = Apollo.BaseMutationOptions<LoginEmailV2Mutation, LoginEmailV2MutationVariables>;
export const IsSsoEnabledDocument = gql`
    mutation isSSOEnabled($email: String!) {
  isSSOEnabled(email: $email)
}
    `;
export type IsSsoEnabledMutationFn = Apollo.MutationFunction<IsSsoEnabledMutation, IsSsoEnabledMutationVariables>;

/**
 * __useIsSsoEnabledMutation__
 *
 * To run a mutation, you first call `useIsSsoEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIsSsoEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [isSsoEnabledMutation, { data, loading, error }] = useIsSsoEnabledMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsSsoEnabledMutation(baseOptions?: Apollo.MutationHookOptions<IsSsoEnabledMutation, IsSsoEnabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IsSsoEnabledMutation, IsSsoEnabledMutationVariables>(IsSsoEnabledDocument, options);
      }
export type IsSsoEnabledMutationHookResult = ReturnType<typeof useIsSsoEnabledMutation>;
export type IsSsoEnabledMutationResult = Apollo.MutationResult<IsSsoEnabledMutation>;
export type IsSsoEnabledMutationOptions = Apollo.BaseMutationOptions<IsSsoEnabledMutation, IsSsoEnabledMutationVariables>;
export const LoginLinkedInDocument = gql`
    mutation loginLinkedIn($token: String!, $redirectUri: String!) {
  loginLinkedIn(token: $token, redirectUri: $redirectUri) {
    success
    message
    newAccount
    jwt
    userId
    finishedRegistration
  }
}
    `;
export type LoginLinkedInMutationFn = Apollo.MutationFunction<LoginLinkedInMutation, LoginLinkedInMutationVariables>;

/**
 * __useLoginLinkedInMutation__
 *
 * To run a mutation, you first call `useLoginLinkedInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginLinkedInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginLinkedInMutation, { data, loading, error }] = useLoginLinkedInMutation({
 *   variables: {
 *      token: // value for 'token'
 *      redirectUri: // value for 'redirectUri'
 *   },
 * });
 */
export function useLoginLinkedInMutation(baseOptions?: Apollo.MutationHookOptions<LoginLinkedInMutation, LoginLinkedInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginLinkedInMutation, LoginLinkedInMutationVariables>(LoginLinkedInDocument, options);
      }
export type LoginLinkedInMutationHookResult = ReturnType<typeof useLoginLinkedInMutation>;
export type LoginLinkedInMutationResult = Apollo.MutationResult<LoginLinkedInMutation>;
export type LoginLinkedInMutationOptions = Apollo.BaseMutationOptions<LoginLinkedInMutation, LoginLinkedInMutationVariables>;
export const LoginAppleDocument = gql`
    mutation loginApple($authorizationCode: String!, $firstName: String!, $lastName: String!) {
  loginApple(
    authorizationCode: $authorizationCode
    firstName: $firstName
    lastName: $lastName
  ) {
    success
    message
    newAccount
    jwt
    userId
    finishedRegistration
  }
}
    `;
export type LoginAppleMutationFn = Apollo.MutationFunction<LoginAppleMutation, LoginAppleMutationVariables>;

/**
 * __useLoginAppleMutation__
 *
 * To run a mutation, you first call `useLoginAppleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAppleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAppleMutation, { data, loading, error }] = useLoginAppleMutation({
 *   variables: {
 *      authorizationCode: // value for 'authorizationCode'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useLoginAppleMutation(baseOptions?: Apollo.MutationHookOptions<LoginAppleMutation, LoginAppleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginAppleMutation, LoginAppleMutationVariables>(LoginAppleDocument, options);
      }
export type LoginAppleMutationHookResult = ReturnType<typeof useLoginAppleMutation>;
export type LoginAppleMutationResult = Apollo.MutationResult<LoginAppleMutation>;
export type LoginAppleMutationOptions = Apollo.BaseMutationOptions<LoginAppleMutation, LoginAppleMutationVariables>;