import { Icon, Square } from '@chakra-ui/react';
import { IconPlayerPlayFilled } from '@tigerhall/icons';

import { TrackedButton } from '~/tracked';
import { usePowerReadState } from '~/player/PowerRead/context/usePowerReadState';
import { useMediaPlayerContext, useMediaState } from '~/player/context';

const PLAYING_STYLE = {
  backgroundColor: 'tigerOrange.800',
  border: '1px solid',
  borderColor: 'tigerOrange.600'
};

export function AudioBookTrigger() {
  const { play, pause } = useMediaPlayerContext();
  const { isAudioAvailable } = useMediaState((state) => {
    return {
      isAudioAvailable: !!state.duration
    };
  });

  const { isPlayingAudio, setIsPlayingAudio } = usePowerReadState((state) => {
    return {
      isPlayingAudio: state.isPlayingAudio,
      setIsPlayingAudio: state.setIsPlayingAudio
    };
  });

  if (!isAudioAvailable) {
    return null;
  }

  return (
    <TrackedButton
      {...(isPlayingAudio ? PLAYING_STYLE : {})}
      name={isPlayingAudio ? 'EBOOK_PAUSE_BUTTON' : 'EBOOK_PLAY_BUTTON'}
      variant="outline"
      borderRadius="md"
      size="md"
      onClick={() => {
        if (isPlayingAudio) {
          pause();
          setIsPlayingAudio(false);
        } else {
          play();
          setIsPlayingAudio(true);
        }
      }}
      leftIcon={
        isPlayingAudio ? (
          <Icon as={Square} borderRadius="sm" backgroundColor="white" />
        ) : (
          <Icon as={IconPlayerPlayFilled} />
        )
      }
    >
      {isPlayingAudio ? 'Stop' : 'Listen'}
    </TrackedButton>
  );
}
