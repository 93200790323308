import * as React from 'react';
import { IconMessageReport } from '@tigerhall/icons';
import { OptionsMenuItem } from 'components/ui/OptionsMenu';
import { ReportModal } from 'components/ui/modals';
import * as segment from '@tigerhall/analytics';

export interface ReportCommentOptionProps {
  commentId: string;
}

export function ReportCommentOption({ commentId }: ReportCommentOptionProps) {
  const [shouldOpenReportModal, setShouldOpenReportModal] =
    React.useState(false);

  return (
    <>
      <OptionsMenuItem
        icon={IconMessageReport}
        title="Report Comment"
        description="Report this comment for review"
        onClick={() => {
          segment.commentOptionsClicked({
            referenceId: commentId,
            buttonName: 'report_comment'
          });
          setShouldOpenReportModal(true);
        }}
      />
      <ReportModal
        isOpen={shouldOpenReportModal}
        onClose={() => {
          setShouldOpenReportModal(false);
        }}
        contentId={commentId}
        contentTypeName="Comment"
        heading="Report Comment"
        subheading="Why are you reporting this comment?"
      />
    </>
  );
}
