import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgTopologyRing = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="th-icon"
    viewBox="0 0 24 24"
    focusable={false}
    color="white"
    width="1.25rem"
    height="1.25rem"
    ref={ref}
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M14 20a2 2 0 1 0-4 0 2 2 0 0 0 4 0zM14 4a2 2 0 1 0-4 0 2 2 0 0 0 4 0zM6 12a2 2 0 1 0-4 0 2 2 0 0 0 4 0zM22 12a2 2 0 1 0-4 0 2 2 0 0 0 4 0zM13.5 5.5l5 5M5.5 13.5l5 5M13.5 18.5l5-5M10.5 5.5l-5 5" />
  </svg>
);
SvgTopologyRing.displayName = "IconTopologyRing";
const ForwardRef = forwardRef(SvgTopologyRing);
export default ForwardRef;
