import React from 'react';
import { Box, Icon, IconProps } from '@chakra-ui/react';
import { IconPlus } from '@tigerhall/icons';

const SIZE = '16px';

type AddIconProps = IconProps & {
  color?: string;
};

export default function AddIcon(props: AddIconProps) {
  return (
    <Box
      // mr={2}
      width={SIZE}
      height={SIZE}
      backgroundColor="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius={8}
    >
      <Icon as={IconPlus} color={props?.color ?? 'orange.600'} {...props} />
    </Box>
  );
}
