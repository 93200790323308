import { Button, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FormInput, TrackedForm } from '@tigerhall/components';
import { useVerifyEmailExistMutation } from 'generated';
import { captureException } from '@sentry/react';

import { useIsSsoEnabledMutation } from '../queries/generated';

type FormFields = {
  email: string;
};

export function FormDetectSSOEnabled() {
  const navigate = useNavigate();
  const toast = useToast();
  const [isSSOEnabled] = useIsSsoEnabledMutation();
  const [verifyEmailExist] = useVerifyEmailExistMutation();

  const checkIfEmailExistThenLogin = async (values: FormFields) => {
    await verifyEmailExist({
      variables: {
        email: values.email
      }
    })
      .then((res) => {
        if (res.data?.emailExists) {
          navigate('/login/email?e=' + values.email);
        } else {
          toast({
            description: `We don't find your account. Create one now!`,
            status: 'warning'
          });
        }
      })
      .catch((error) => {
        toast({
          title: 'ERROR!',
          description: error.message,
          status: 'error'
        });
        captureException(error, {
          extra: values
        });
      });
  };

  const onSubmit = async (values: FormFields) => {
    const res = await isSSOEnabled({
      variables: {
        email: values.email
      }
    });

    if (res?.data?.isSSOEnabled) {
      window.location.href = `${
        import.meta.env.VITE_API_URL
      }/workos/login?email=${values.email}&returnUrl=${window.location.href}`;
    } else {
      await checkIfEmailExistThenLogin(values);
    }
  };

  return (
    <TrackedForm<FormFields> name="detectSSOEnabled" onSubmit={onSubmit}>
      {({ formState: { isSubmitting } }) => (
        <>
          <FormInput
            name="email"
            type="email"
            label="email"
            autoComplete="email"
            placeholder="Email"
            rules={{ required: true }}
          />
          <Button
            mt="2.5rem"
            type="submit"
            size="lg"
            w="100%"
            isLoading={isSubmitting}
          >
            Next
          </Button>
        </>
      )}
    </TrackedForm>
  );
}
