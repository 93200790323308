import { combineReducers } from '@reduxjs/toolkit';

import {
  assignmentsSlice,
  contentSlice,
  ebookEditorSlice,
  learningPathSlice,
  navigationSlice,
  organisationSlice,
  popupSlice,
  postSlice,
  sidebarSlice,
  userSlice,
  freeTrialSlice,
  playerSlice,
  audienceConditionsSlice,
} from './state';

export const rootReducer = combineReducers({
  assignments: assignmentsSlice.reducer,
  content: contentSlice.reducer,
  learningPaths: learningPathSlice.reducer,
  navigation: navigationSlice.reducer,
  organisations: organisationSlice.reducer,
  popup: popupSlice.reducer,
  post: postSlice.reducer,
  sidebar: sidebarSlice.reducer,
  user: userSlice.reducer,
  ebookEditor: ebookEditorSlice.reducer,
  freeTrial: freeTrialSlice.reducer,
  player: playerSlice.reducer,
  audienceConditions: audienceConditionsSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
