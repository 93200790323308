import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import { UserContentTrackingFragmentDoc } from '../../../../../../../fragments/generated/userContentTracking.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEbookContentByLanguageQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
  language?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']>;
}>;


export type GetEbookContentByLanguageQuery = { __typename?: 'Query', ebook?: { __typename?: 'Ebook', id: string, chapters: Array<{ __typename?: 'Chapter', name: string, index: number, pages: Array<string>, language?: string | null }> } | null };

export type GetEbookAudioByLanguageQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
  language?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']>;
}>;


export type GetEbookAudioByLanguageQuery = { __typename?: 'Query', ebook?: { __typename?: 'Ebook', id: string, episodes: { __typename?: 'EbookEpisode', language: string, episodes: Array<{ __typename?: 'Episode', name: string, audio?: { __typename?: 'AudioFile', id: string, length: any, uri: string } | null }> } } | null };

export type GetEbookForConnectedPowerReadPlayerQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;


export type GetEbookForConnectedPowerReadPlayerQuery = { __typename?: 'Query', ebook?: { __typename: 'Ebook', id: string, name: string, preamble: string, pagesCount: number, upvoteCount: number, downvoteCount: number, contentRatingByUser: SchemaTypes.ContentVote, userContentTracking: { __typename: 'UserContentTracking', id: string, current: number, currentPlacement: number, total: number, isAttending: boolean, isFinished: boolean, bookmarkedAt?: any | null, lastOpenedAt?: any | null, finishedAt?: any | null }, audioAvailableLanguages: Array<{ __typename?: 'Language', code: string, name: string }>, availableLanguages: Array<{ __typename?: 'Language', code: string, name: string }>, categories: Array<{ __typename?: 'Category', id: string, name: string }>, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string }>, image: { __typename?: 'Image', uri: string, id: string } } | null };


export const GetEbookContentByLanguageDocument = gql`
    query GetEbookContentByLanguage($id: String!, $language: String) {
  ebook(id: $id) {
    id
    chapters(language: $language) {
      name
      index
      pages
      language
    }
  }
}
    `;

/**
 * __useGetEbookContentByLanguageQuery__
 *
 * To run a query within a React component, call `useGetEbookContentByLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEbookContentByLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEbookContentByLanguageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetEbookContentByLanguageQuery(baseOptions: Apollo.QueryHookOptions<GetEbookContentByLanguageQuery, GetEbookContentByLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEbookContentByLanguageQuery, GetEbookContentByLanguageQueryVariables>(GetEbookContentByLanguageDocument, options);
      }
export function useGetEbookContentByLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEbookContentByLanguageQuery, GetEbookContentByLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEbookContentByLanguageQuery, GetEbookContentByLanguageQueryVariables>(GetEbookContentByLanguageDocument, options);
        }
export type GetEbookContentByLanguageQueryHookResult = ReturnType<typeof useGetEbookContentByLanguageQuery>;
export type GetEbookContentByLanguageLazyQueryHookResult = ReturnType<typeof useGetEbookContentByLanguageLazyQuery>;
export type GetEbookContentByLanguageQueryResult = Apollo.QueryResult<GetEbookContentByLanguageQuery, GetEbookContentByLanguageQueryVariables>;
export const GetEbookAudioByLanguageDocument = gql`
    query GetEbookAudioByLanguage($id: String!, $language: String) {
  ebook(id: $id) {
    id
    episodes(language: $language) {
      language
      episodes {
        name
        audio {
          id
          length
          uri
        }
      }
    }
  }
}
    `;

/**
 * __useGetEbookAudioByLanguageQuery__
 *
 * To run a query within a React component, call `useGetEbookAudioByLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEbookAudioByLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEbookAudioByLanguageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetEbookAudioByLanguageQuery(baseOptions: Apollo.QueryHookOptions<GetEbookAudioByLanguageQuery, GetEbookAudioByLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEbookAudioByLanguageQuery, GetEbookAudioByLanguageQueryVariables>(GetEbookAudioByLanguageDocument, options);
      }
export function useGetEbookAudioByLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEbookAudioByLanguageQuery, GetEbookAudioByLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEbookAudioByLanguageQuery, GetEbookAudioByLanguageQueryVariables>(GetEbookAudioByLanguageDocument, options);
        }
export type GetEbookAudioByLanguageQueryHookResult = ReturnType<typeof useGetEbookAudioByLanguageQuery>;
export type GetEbookAudioByLanguageLazyQueryHookResult = ReturnType<typeof useGetEbookAudioByLanguageLazyQuery>;
export type GetEbookAudioByLanguageQueryResult = Apollo.QueryResult<GetEbookAudioByLanguageQuery, GetEbookAudioByLanguageQueryVariables>;
export const GetEbookForConnectedPowerReadPlayerDocument = gql`
    query GetEbookForConnectedPowerReadPlayer($id: String!) {
  ebook(id: $id) {
    __typename
    id
    name
    preamble
    pagesCount
    upvoteCount
    downvoteCount
    contentRatingByUser
    userContentTracking {
      ...UserContentTracking
    }
    audioAvailableLanguages {
      code
      name
    }
    availableLanguages {
      code
      name
    }
    categories {
      id
      name
    }
    experts {
      id
      firstName
      lastName
    }
    image {
      uri
      id
    }
  }
}
    ${UserContentTrackingFragmentDoc}`;

/**
 * __useGetEbookForConnectedPowerReadPlayerQuery__
 *
 * To run a query within a React component, call `useGetEbookForConnectedPowerReadPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEbookForConnectedPowerReadPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEbookForConnectedPowerReadPlayerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEbookForConnectedPowerReadPlayerQuery(baseOptions: Apollo.QueryHookOptions<GetEbookForConnectedPowerReadPlayerQuery, GetEbookForConnectedPowerReadPlayerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEbookForConnectedPowerReadPlayerQuery, GetEbookForConnectedPowerReadPlayerQueryVariables>(GetEbookForConnectedPowerReadPlayerDocument, options);
      }
export function useGetEbookForConnectedPowerReadPlayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEbookForConnectedPowerReadPlayerQuery, GetEbookForConnectedPowerReadPlayerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEbookForConnectedPowerReadPlayerQuery, GetEbookForConnectedPowerReadPlayerQueryVariables>(GetEbookForConnectedPowerReadPlayerDocument, options);
        }
export type GetEbookForConnectedPowerReadPlayerQueryHookResult = ReturnType<typeof useGetEbookForConnectedPowerReadPlayerQuery>;
export type GetEbookForConnectedPowerReadPlayerLazyQueryHookResult = ReturnType<typeof useGetEbookForConnectedPowerReadPlayerLazyQuery>;
export type GetEbookForConnectedPowerReadPlayerQueryResult = Apollo.QueryResult<GetEbookForConnectedPowerReadPlayerQuery, GetEbookForConnectedPowerReadPlayerQueryVariables>;