import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { useCallback } from 'react';

import { useMediaPlayerContext, useMediaState } from '~/player/context';

const playbackRateOptions: { rate: number }[] = [
  { rate: 0.7 },
  { rate: 0.8 },
  { rate: 0.9 },
  { rate: 1.0 },
  { rate: 1.2 },
  { rate: 1.5 },
  { rate: 1.8 },
  { rate: 2.0 }
];

/**
 *
 * `openDelay={300}` is passed to all the tooltip because
 *  PlaybackRateSelector is used in Overlay Component to known the
 *  exact reason check here `ui/components/src/player/components/Overlay/Overlay.tsx`
 */

export function PlaybackRateSelector() {
  const { setPlaybackRate } = useMediaPlayerContext();
  const playbackRate = useMediaState((state) => state.playbackRate);

  const renderPlaybackRate = useCallback((rate: number) => {
    return `${rate.toFixed(1)}x`;
  }, []);

  return (
    <Box>
      <Menu computePositionOnMount autoSelect={false} placement="top">
        <Tooltip label="Playback speed" hasArrow openDelay={300}>
          <MenuButton w="3rem" h="2rem">
            <Text fontSize="md" fontWeight={'bold'} as={'span'}>
              {renderPlaybackRate(playbackRate)}
            </Text>
          </MenuButton>
        </Tooltip>
        <MenuList minW="4rem">
          {playbackRateOptions.map((playback) => (
            <MenuItem
              key={playback.rate}
              justifyContent="center"
              onClick={() => {
                setPlaybackRate(playback.rate);
              }}
              color="darkGrey.50"
            >
              {renderPlaybackRate(playback.rate)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
}
