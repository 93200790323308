import { Box } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import * as segment from '@tigerhall/analytics';
import { TrackedButton, useTrackedForm } from '@tigerhall/components';
import { type CommentType } from '@tigerhall/core';
import { MentionFormInput } from 'components/ui/UserMentions/form/MentionFormInput';
import { type User } from 'components/ui/UserMentions/utils/types';
import {
  type Comment,
  useCreateCommentMutation,
  useGetCurrentUserQuery,
  useGetSuggestedUsersToInteractLazyQuery
} from 'generated';
import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';

interface FormFields {
  commentInput: Comment;
}

interface CommentRepliesProps {
  refetch: () => void;
  referenceId: string;
  referenceType: Exclude<CommentType, CommentType.Feed>;
  isOrganizationContent?: boolean;
}

export function CommentInputWrapper({
  refetch,
  referenceId,
  referenceType,
  isOrganizationContent
}: Readonly<CommentRepliesProps>) {
  const toast = useToast({
    duration: 2000
  });

  const [createCommentMutation] = useCreateCommentMutation();

  const [getSuggestedUser] = useGetSuggestedUsersToInteractLazyQuery({});

  const { data: currentUser } = useGetCurrentUserQuery();

  const form = useTrackedForm<FormFields>('COMMENT');

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = form;

  const isPostComment = referenceType === 'POST';

  const isThinkfluencer = currentUser?.me?.isExpert;

  const addComment = useCallback(
    async (values: FormFields) => {
      try {
        const { rawText, mentions, comment } = values?.commentInput || {};
        if (isEmpty(rawText.replace(/ /g, ''))) {
          return;
        }

        segment?.commentSent({
          commentText: values?.commentInput.comment,
          commentType: isPostComment ? 'POST_COMMENT' : 'CONTENT_COMMENT',
          referenceId,
          referenceType,
          isThinkfluencer
        });

        await createCommentMutation({
          variables: {
            comment: {
              referenceId,
              comment,
              rawText,
              mentions,
              type: referenceType
            }
          }
        });
        toast.closeAll();
        reset({
          commentInput: {
            rawText: ''
          }
        });
      } catch (error) {
        toast.closeAll();
        toast({
          title: 'Error',
          description:
            'An error occurred trying to post your comment. Please try again later.',
          status: 'error'
        });
        captureException(new Error('Error while adding your comment'), {
          tags: {
            [isPostComment ? 'postId' : 'contentId']: referenceId
          },
          extra: {
            values: values.commentInput,
            error
          }
        });
      } finally {
        refetch();
      }
    },
    [
      toast,
      isPostComment,
      referenceId,
      referenceType,
      isThinkfluencer,
      createCommentMutation,
      reset,
      refetch
    ]
  );

  const fetchSuggestion = async <T extends readonly unknown[]>(
    query: string
  ): Promise<User<T>> => {
    const res = await getSuggestedUser({
      variables: {
        name: query,
        limit: 4,
        isMemberOfSameOrganisation: isOrganizationContent
      }
    });
    const users = res.data?.me?.suggestedUsersToInteractWith.edges ?? [];
    return users as User<T>;
  };

  return (
    <FormProvider {...form}>
      <>
        <Box
          w="100%"
          minHeight="3rem"
          px="0.75rem"
          whiteSpace="nowrap"
          display="flex"
          background="darkGrey.400"
          borderRadius="0.3rem"
          alignItems="flex-end"
          borderColor="darkGrey.200"
          borderWidth="1px"
          css={{
            '& label': { marginBottom: '0rem' }
          }}
        >
          <MentionFormInput
            fetchSuggestions={fetchSuggestion}
            name="commentInput"
            placeholder="Add a comment"
            referenceId={referenceId}
          />
          <TrackedButton
            name="mention-send-button"
            type="submit"
            variant="unstyled"
            size="md"
            minH="3rem"
            display="flex"
            color="lightGrey.900"
            padding="0.5rem"
            isLoading={isSubmitting}
            onClick={handleSubmit(addComment)}
          >
            Send
          </TrackedButton>
        </Box>
      </>
    </FormProvider>
  );
}
