import { Flex, Heading, Icon, Image, Text } from '@chakra-ui/react';
import {
  type Ebook,
  type Event,
  type Podcast,
  type Stream,
  contentTotalTime,
  formatShortContentDuration
} from '@tigerhall/core';
import * as React from 'react';
import { IconClock } from '@tigerhall/icons';
import { DateTime } from 'luxon';
import { ReportButton } from 'components/ui';

interface LeftPanelProps {
  content: Stream | Podcast | Ebook | Event;
  actions: React.ReactNode;
}

export function LeftPanel({ content, actions }: Readonly<LeftPanelProps>) {
  return (
    <>
      <Flex direction="column">
        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap="0.25rem">
            <Icon as={IconClock} color="white" w="0.75rem" h="0.75rem" />

            <Text fontSize="0.75rem" fontWeight="700" color="white" as="span">
              {formatShortContentDuration(contentTotalTime(content))}
              &nbsp;|&nbsp;
              {DateTime.fromISO(content.createdAt).toFormat('DD')}
            </Text>
          </Flex>
          <ReportButton
            contentId={content.id}
            contentTypeName={content.__typename}
          />
        </Flex>
        <Text
          fontSize="0.875rem"
          fontWeight="700"
          color="tigerOrange.600"
          mt="1.25rem"
        >
          {content.categories.map((c) => c.name).join(', ')}
        </Text>
        <Heading fontSize="1.25rem" fontWeight="700" color="white" mt="0.5rem">
          {content.name}
        </Heading>

        <Text
          fontSize="0.75rem"
          fontWeight="700"
          color="lightGrey.600"
          mt="1rem"
        >
          Thinkfluencers
        </Text>

        <Flex direction="column" gap="1.25rem" mt="1rem">
          {content.experts.map((e) => (
            <Flex h="3.25rem" gap="0.625rem" key={e.id}>
              <Image
                h="3.25rem"
                w="3.25rem"
                src={e.image?.uri}
                border="2px solid"
                borderColor="darkGrey.300"
                borderRadius="full"
              />
              <Flex direction="column">
                <Text
                  fontSize="0.875rem"
                  fontWeight="700"
                  color="lightGrey.200"
                >
                  {e.firstName} {e.lastName}
                </Text>
                <Text fontSize="0.75rem" fontWeight="500" color="lightGrey.600">
                  {e.title}
                </Text>
                <Text
                  fontSize="0.75rem"
                  fontWeight="500"
                  color="tigerOrange.600"
                >
                  {e.company}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex gap="1rem">{actions}</Flex>
    </>
  );
}
