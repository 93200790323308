import {
  Content,
  ContentCardsSorting,
  ContentSelection
} from '@tigerhall/core/lib/types';
import { Helmet } from 'react-helmet';
import { Box, Flex } from '@chakra-ui/react';
import { lazy, Fragment, Suspense } from 'react';
import { hasOrgMemberAccess } from 'app/state';
import { useAppSelector } from 'hooks';
import {
  ContentCardSection,
  TopicCloudSection,
  UpcomingExperiencesSection
} from 'modules/application/components';
import { OrganisationContentBanner } from 'modules/application/components/OrganisationContentBanner';

import {
  useGetCurrentUserRecentCategoriesQuery,
  useGetConsumableContentQuery,
  useGetRecommendContentQuery
} from '../../queries';
import { StreamList } from './components/StreamList';
import { FeaturedContentSection } from './components/FeaturedContentSection';
import { FeaturedThinkfluencers } from './components/FeaturedThinkfluencers';
import { TrailOfTheMonth } from './components/TrailOfTheMonth';
import {
  type HomeComponentsKeys,
  B2C_COMPONENTS_LIST,
  B2B_COMPONENTS_LIST
} from './utils/const';

const NewLanguageAlertModal = lazy(() =>
  import('./components/NewLanguageAlertModal').then((module) => ({
    default: module.NewLanguageAlertModal
  }))
);

const GuidedJourney = lazy(() =>
  import('./components/GuidedJourney').then((module) => ({
    default: module.GuidedJourney
  }))
);

const OnboardingWalkthroughPopup = lazy(() =>
  import('./components/OnboardingWalkthroughPopup').then((module) => ({
    default: module.OnboardingWalkthroughPopup
  }))
);

const ProfileProgressModal = lazy(() =>
  import('./components/ProfileProgressModal').then((module) => ({
    default: module.ProfileProgressModal
  }))
);

const NewAssignmentsList = lazy(() =>
  import('./components/NewAssignmentsList').then((module) => ({
    default: module.NewAssignmentsList
  }))
);

export function Home() {
  const isB2B = useAppSelector(hasOrgMemberAccess);

  const { data: leftOffData, loading: isLoadingLeftOffData } =
    useGetConsumableContentQuery({
      variables: {
        filter: {
          limit: 20,
          offset: 0
        }
      }
    });

  const { data: recommendedContentData, loading: isLoadingRecommendedContent } =
    useGetRecommendContentQuery();

  const { data: recentCategoriesData } =
    useGetCurrentUserRecentCategoriesQuery();

  const recentCategories =
    recentCategoriesData?.me?.recentlyConsumedCategories ?? [];

  const availableComponents: Record<HomeComponentsKeys, JSX.Element> = {
    streams: <StreamList />,
    organisationContentBanner: (
      <Box px={{ base: '1rem', md: '2rem' }} width="100%">
        <OrganisationContentBanner type="HOME" />
      </Box>
    ),
    topicCloud: <TopicCloudSection title="Browse By Topic" />,
    pickUpWhereYouLeftOff: (
      <ContentCardSection
        contentCardData={leftOffData?.user?.ongoingContent?.edges as Content[]}
        sectionTitle="Pick Up Where You Left Off"
        dataLoading={isLoadingLeftOffData}
      />
    ),
    bookmarked: (
      <ContentCardSection
        variables={{
          filter: { limit: 10, selection: ContentSelection.Bookmarked },
          sorting: ContentCardsSorting.LastOpenedAt
        }}
        sectionTitle="Bookmarked"
        enableFetchMore
      />
    ),
    new: (
      <ContentCardSection
        variables={{
          filter: {
            limit: 10,
            recent: true
          }
        }}
        sectionTitle="New"
        enableFetchMore
      />
    ),
    topPicks: <FeaturedContentSection heading="Top Picks" />,
    upcomingExperiences: <UpcomingExperiencesSection />,
    recommendedForYou: (
      <ContentCardSection
        contentCardData={
          recommendedContentData?.user?.contentRecommendations
            ?.edges as Content[]
        }
        sectionId="recommended-picks"
        sectionTitle="Recommended for You"
        dataLoading={isLoadingRecommendedContent}
      />
    ),
    trending: (
      <ContentCardSection
        variables={{
          filter: {
            limit: 10
          },
          sorting: ContentCardsSorting.Trending
        }}
        sectionTitle="Trending"
        enableFetchMore
      />
    ),
    mostPopularThisWeek: (
      <ContentCardSection
        sectionTitle="Most Popular This Week"
        variables={{
          filter: {
            limit: 10
          },
          sorting: ContentCardsSorting.WeeklyTrending
        }}
        enableFetchMore
      />
    ),
    trailOfTheMonth: <TrailOfTheMonth />,
    featuredThinkfluencers: <FeaturedThinkfluencers />,
    recentCategory1: recentCategories[0] && (
      <ContentCardSection
        variables={{
          filter: {
            limit: 10,
            categories: [recentCategories[0].id]
          }
        }}
        sectionTitle={`More from ${recentCategories[0].name}`}
        enableFetchMore
      />
    ),
    recentCategory2: recentCategories[1] && (
      <ContentCardSection
        variables={{
          filter: {
            limit: 10,
            categories: [recentCategories[1].id]
          }
        }}
        sectionTitle={`More from ${recentCategories[1].name}`}
        enableFetchMore
      />
    ),
    recentCategory3: recentCategories[2] && (
      <ContentCardSection
        variables={{
          filter: {
            limit: 10,
            categories: [recentCategories[2].id]
          }
        }}
        sectionTitle={`More from ${recentCategories[2].name}`}
        enableFetchMore
      />
    )
  };

  /**
   * the order of the components in the homepage is different for B2B and B2C users
   * @see https://tigerhall.atlassian.net/wiki/spaces/PD/pages/699826187/Home+Page+Consolidation#%3Anote%3A-Requirements-%26-User-Stories
   */
  const sortedComponentsList = isB2B
    ? B2B_COMPONENTS_LIST
    : B2C_COMPONENTS_LIST;

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Flex
        direction="column"
        gap={{ base: '1.5rem', md: '2.5rem' }}
        pb={{ base: '1.5rem', md: '2.5rem' }}
        alignItems="flex-start"
        width="100%"
      >
        {sortedComponentsList.map((componentKey) => (
          <Fragment key={componentKey}>
            {availableComponents[componentKey]}
          </Fragment>
        ))}
      </Flex>

      <Suspense fallback={null}>
        <NewLanguageAlertModal />
        <OnboardingWalkthroughPopup />
        <ProfileProgressModal />
        <GuidedJourney />
        <NewAssignmentsList />
      </Suspense>
    </>
  );
}
