import * as React from 'react';
import { Divider, HStack, Skeleton, SkeletonCircle } from '@chakra-ui/react';

export function ActivityHeaderSkeleton() {
  return (
    <>
      <HStack
        spacing="0.5rem"
        py="1rem"
        px={{
          base: '1rem',
          md: '2rem'
        }}
      >
        <SkeletonCircle size="1.5rem" />
        <Skeleton width="100%" maxWidth="24rem" height="1rem" />
      </HStack>
      <Divider borderColor="darkGrey.400" />
    </>
  );
}
