import {
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useToast
} from '@chakra-ui/react';
import { IconCopy } from '@tigerhall/icons';
import { copyTextToClipboard } from '@tigerhall/core';

import { TrackedButton } from '~/tracked';

export interface CopyInputProps {
  text: string;
  onCopyClicked?: () => void;
}

export function CopyInput({ text = '', onCopyClicked }: CopyInputProps) {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 2000
  });

  return (
    <InputGroup flexGrow={1} justifyContent="center" alignItems="center">
      <Input
        defaultValue={text}
        disabled
        type="text"
        role="textbox"
        pr="7rem"
      />
      <InputRightElement width="auto">
        <TrackedButton
          variant="ghost"
          size="md"
          name="Copy to clipboard"
          mr="0.5rem"
          role="button"
          leftIcon={<Icon as={IconCopy} />}
          onClick={() => {
            copyTextToClipboard(text, {
              onSuccess: () => {
                toast({
                  status: 'success',
                  description: 'Link copied to your clipboard!'
                });
              },
              onError: () => {
                toast({
                  status: 'error',
                  description: 'Failed to copy link to your clipboard!'
                });
              }
            });
            onCopyClicked?.();
          }}
        >
          Copy
        </TrackedButton>
      </InputRightElement>
    </InputGroup>
  );
}
