import { VStack } from '@chakra-ui/react';

import { ChatMessages } from './ChatMessages';
import { ChatInput } from './ChatInput';

export interface ChatTabProps {
  streamId: string;
}

export function ChatTab({ streamId }: Readonly<ChatTabProps>) {
  return (
    <VStack
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      width={'full'}
      height={'100%'}
      spacing={0}
    >
      <ChatMessages streamId={streamId} />
      <ChatInput streamId={streamId} />
    </VStack>
  );
}
