import { Box } from '@chakra-ui/react';

import {
  UncontrolledSelect,
  UncontrolledSelectProps
} from '~/form/components/Select/UncontrolledSelect';
import { useMediaPlayerContext, useMediaState } from '~/player/context';

export interface TrackSelectorProps {
  size: UncontrolledSelectProps['size'];
  trackIndex: number;
  tracks: {
    name: string;
    url: string;
    subtitlesUrl?: string;
  }[];
  setTrackIndex: (index: number) => void;
}

/**
 * A select component that allows the user to select a track from a list of tracks.
 * In Podcasts, this is used to select a specific episode from a list of episodes.
 * It can be used in the future to select a content from a playlist.
 */
export function TrackSelector({
  size,
  trackIndex,
  tracks,
  setTrackIndex
}: TrackSelectorProps) {
  const { play } = useMediaPlayerContext();
  const isPlaying = useMediaState((state) => state.isPlaying);

  if (tracks.length <= 1) {
    return null;
  }

  return (
    <Box
      width="100%"
      maxW={size === 'lg' ? 'unset' : '25rem'}
      display={size === 'sm' ? { base: 'none', sm: 'block' } : 'block'}
    >
      <UncontrolledSelect
        name="track"
        size={size}
        value={{
          value: trackIndex.toString(),
          label: tracks[trackIndex].name
        }}
        options={tracks.map(({ name }, index) => ({
          value: index.toString(),
          label: name
        }))}
        onChange={(val) => {
          if (val) {
            setTrackIndex(parseInt(val.value, 10));

            if (isPlaying) {
              play();
            }
          }
        }}
      />
    </Box>
  );
}
