import { TrackedLink } from '@tigerhall/components';
import { compactNumber } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';

interface CommentsCountProps {
  postId: string;
  count: number;
}

export function CommentsCount({ postId, count }: CommentsCountProps) {
  const postLink = `/feed/${postId}`;

  if (count === 0) {
    return null;
  }

  const compactCount = compactNumber(count);

  return (
    <TrackedLink
      name="COMMENTS_LIST"
      href={postLink}
      onClick={() => {
        segment.commentButtonClicked({
          referenceId: postId
        });
      }}
    >
      {compactCount} {count === 1 ? 'comment' : 'comments'}
    </TrackedLink>
  );
}
