import * as React from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { THLink, Typography } from '@tigerhall/ui-components';
import { useLocation, useMatch } from 'react-router-dom';
import * as segment from '@tigerhall/analytics';

interface MenuItemProps {
  id: string;
  label: string;
  activeIcon: React.ReactNode;
  inactiveIcon: React.ReactNode;
  linkTo: string;
}

export function MenuItem({
  linkTo,
  id,
  inactiveIcon,
  label,
  activeIcon
}: MenuItemProps) {
  const isSelected = useMatch({
    path: linkTo,
    end: false
  });
  const textColor = isSelected ? 'white' : 'darkGrey.50';
  const borderBgColor = isSelected ? 'orange.600' : 'transparent';
  const menuItemIcon = isSelected ? activeIcon : inactiveIcon;
  const { pathname } = useLocation();

  return (
    <THLink
      name={label}
      to={linkTo}
      onClick={() => {
        segment.navBarClicked({
          location: pathname,
          section: label
        });
      }}
    >
      <Flex
        direction="column"
        alignItems="center"
        w="4.5rem"
        key={id}
        h="4rem"
        pt={2}
        justifyContent="space-between"
        _hover={{
          '&>div:first-of-type': {
            bg: 'darkGrey.400',
            borderRadius: 8
          }
        }}
      >
        <Center flexDirection="column" w="100%" pt={1}>
          {menuItemIcon}
          <Typography
            variant="detail4"
            weight="500"
            color={textColor}
            mt="0.25rem"
            mb="0.125rem"
          >
            {label}
          </Typography>
        </Center>

        <Box
          w="3.5rem"
          h="0.125rem"
          borderRadius="0.25rem 0.25rem 0px 0px"
          backgroundColor={borderBgColor}
        />
      </Flex>
    </THLink>
  );
}
