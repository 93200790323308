import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import { ExpertFragmentDoc } from '../../../fragments/generated/expert.generated';
import { CategoryFragmentDoc } from '../../../fragments/generated/category.generated';
import { LimitedUserFragmentDoc, NotificationPreferencesFragmentDoc, UserNotificationPreferenceFragmentDoc } from '../../../fragments/generated/user.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserSetPasswordMutationVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
  password: SchemaTypes.Scalars['String'];
}>;


export type UserSetPasswordMutation = { __typename?: 'Mutation', userSetPassword: boolean };

export type CreateRegularUserMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreateUserInput;
}>;


export type CreateRegularUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string } | null };

export type VerifyEmailExistMutationVariables = SchemaTypes.Exact<{
  email: SchemaTypes.Scalars['String'];
}>;


export type VerifyEmailExistMutation = { __typename?: 'Mutation', emailExists: boolean };

export type GetRecommendedExpertsQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type GetRecommendedExpertsQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, expertRecommendations: { __typename?: 'ExpertCollection', edges: Array<{ __typename?: 'Expert', id: string, title: string, firstName: string, lastName: string, biography: string, createdAt: any, updatedAt: any, mentorAvailableText?: string | null, mentorPriceText?: string | null, isMentor: boolean, isAuthenticatedUserFollowing: boolean, company?: string | null, userId?: string | null, image: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string }, categories: Array<{ __typename?: 'Category', id: string, name: string, image?: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string } | null }> }> } } | null };

export type UserTopListenedExpertsQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;


export type UserTopListenedExpertsQuery = { __typename?: 'Query', user?: { __typename?: 'User', userTopListenedExperts: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, company: string, jobTitle: string, isFollowing: boolean, isExpert: boolean, avatarImage: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string }, expertOrg?: { __typename?: 'Organisation', id: string } | null }> } | null };

export type UserTopCategoriesQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;


export type UserTopCategoriesQuery = { __typename?: 'Query', user?: { __typename?: 'User', userTopCategories: Array<{ __typename?: 'TopCategoryConsumed', duration?: number | null, category?: { __typename?: 'Category', id: string, name: string, image?: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string } | null } | null }> } | null };

export type GetNotificationPreferencesQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type GetNotificationPreferencesQuery = { __typename?: 'Query', me?: { __typename: 'User', id: string, notificationPreferences: Array<{ __typename?: 'UserNotificationPreference', type: SchemaTypes.NotificationType, name: string, platform: SchemaTypes.NotificationPlatform, state: SchemaTypes.NotificationPreferenceState }> } | null };

export type ToggleNotificationPreferenceMutationVariables = SchemaTypes.Exact<{
  type: SchemaTypes.NotificationType;
  userID?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']>;
}>;


export type ToggleNotificationPreferenceMutation = { __typename?: 'Mutation', toggleNotificationPreference?: { __typename?: 'UserNotificationPreference', type: SchemaTypes.NotificationType, name: string, platform: SchemaTypes.NotificationPlatform, state: SchemaTypes.NotificationPreferenceState } | null };

export type RefreshTokenMutationVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken: { __typename?: 'AuthenticationResponse', jwt: string, success: boolean, finishedRegistration: boolean } };

export type GetListOfBlockedUsersQueryVariables = SchemaTypes.Exact<{
  filter: SchemaTypes.BlockedUsersFilter;
}>;


export type GetListOfBlockedUsersQuery = { __typename?: 'Query', me?: { __typename?: 'User', blockedUsers: { __typename?: 'UserCollection', meta: { __typename?: 'PageInfo', limit: number, offset: number, total: number }, edges: Array<{ __typename: 'User', id: string }> } } | null };


export const UserSetPasswordDocument = gql`
    mutation userSetPassword($id: ID!, $password: String!) {
  userSetPassword(id: $id, password: $password)
}
    `;
export type UserSetPasswordMutationFn = Apollo.MutationFunction<UserSetPasswordMutation, UserSetPasswordMutationVariables>;

/**
 * __useUserSetPasswordMutation__
 *
 * To run a mutation, you first call `useUserSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSetPasswordMutation, { data, loading, error }] = useUserSetPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UserSetPasswordMutation, UserSetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserSetPasswordMutation, UserSetPasswordMutationVariables>(UserSetPasswordDocument, options);
      }
export type UserSetPasswordMutationHookResult = ReturnType<typeof useUserSetPasswordMutation>;
export type UserSetPasswordMutationResult = Apollo.MutationResult<UserSetPasswordMutation>;
export type UserSetPasswordMutationOptions = Apollo.BaseMutationOptions<UserSetPasswordMutation, UserSetPasswordMutationVariables>;
export const CreateRegularUserDocument = gql`
    mutation createRegularUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    firstName
    lastName
    email
  }
}
    `;
export type CreateRegularUserMutationFn = Apollo.MutationFunction<CreateRegularUserMutation, CreateRegularUserMutationVariables>;

/**
 * __useCreateRegularUserMutation__
 *
 * To run a mutation, you first call `useCreateRegularUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegularUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegularUserMutation, { data, loading, error }] = useCreateRegularUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRegularUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateRegularUserMutation, CreateRegularUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRegularUserMutation, CreateRegularUserMutationVariables>(CreateRegularUserDocument, options);
      }
export type CreateRegularUserMutationHookResult = ReturnType<typeof useCreateRegularUserMutation>;
export type CreateRegularUserMutationResult = Apollo.MutationResult<CreateRegularUserMutation>;
export type CreateRegularUserMutationOptions = Apollo.BaseMutationOptions<CreateRegularUserMutation, CreateRegularUserMutationVariables>;
export const VerifyEmailExistDocument = gql`
    mutation VerifyEmailExist($email: String!) {
  emailExists(email: $email)
}
    `;
export type VerifyEmailExistMutationFn = Apollo.MutationFunction<VerifyEmailExistMutation, VerifyEmailExistMutationVariables>;

/**
 * __useVerifyEmailExistMutation__
 *
 * To run a mutation, you first call `useVerifyEmailExistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailExistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailExistMutation, { data, loading, error }] = useVerifyEmailExistMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useVerifyEmailExistMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailExistMutation, VerifyEmailExistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailExistMutation, VerifyEmailExistMutationVariables>(VerifyEmailExistDocument, options);
      }
export type VerifyEmailExistMutationHookResult = ReturnType<typeof useVerifyEmailExistMutation>;
export type VerifyEmailExistMutationResult = Apollo.MutationResult<VerifyEmailExistMutation>;
export type VerifyEmailExistMutationOptions = Apollo.BaseMutationOptions<VerifyEmailExistMutation, VerifyEmailExistMutationVariables>;
export const GetRecommendedExpertsDocument = gql`
    query getRecommendedExperts {
  me {
    id
    expertRecommendations {
      edges {
        ...Expert
      }
    }
  }
}
    ${ExpertFragmentDoc}`;

/**
 * __useGetRecommendedExpertsQuery__
 *
 * To run a query within a React component, call `useGetRecommendedExpertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedExpertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedExpertsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecommendedExpertsQuery(baseOptions?: Apollo.QueryHookOptions<GetRecommendedExpertsQuery, GetRecommendedExpertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecommendedExpertsQuery, GetRecommendedExpertsQueryVariables>(GetRecommendedExpertsDocument, options);
      }
export function useGetRecommendedExpertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecommendedExpertsQuery, GetRecommendedExpertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecommendedExpertsQuery, GetRecommendedExpertsQueryVariables>(GetRecommendedExpertsDocument, options);
        }
export type GetRecommendedExpertsQueryHookResult = ReturnType<typeof useGetRecommendedExpertsQuery>;
export type GetRecommendedExpertsLazyQueryHookResult = ReturnType<typeof useGetRecommendedExpertsLazyQuery>;
export type GetRecommendedExpertsQueryResult = Apollo.QueryResult<GetRecommendedExpertsQuery, GetRecommendedExpertsQueryVariables>;
export const UserTopListenedExpertsDocument = gql`
    query userTopListenedExperts($id: String!) {
  user(id: $id) {
    userTopListenedExperts {
      ...LimitedUser
    }
  }
}
    ${LimitedUserFragmentDoc}`;

/**
 * __useUserTopListenedExpertsQuery__
 *
 * To run a query within a React component, call `useUserTopListenedExpertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTopListenedExpertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTopListenedExpertsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserTopListenedExpertsQuery(baseOptions: Apollo.QueryHookOptions<UserTopListenedExpertsQuery, UserTopListenedExpertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTopListenedExpertsQuery, UserTopListenedExpertsQueryVariables>(UserTopListenedExpertsDocument, options);
      }
export function useUserTopListenedExpertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTopListenedExpertsQuery, UserTopListenedExpertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTopListenedExpertsQuery, UserTopListenedExpertsQueryVariables>(UserTopListenedExpertsDocument, options);
        }
export type UserTopListenedExpertsQueryHookResult = ReturnType<typeof useUserTopListenedExpertsQuery>;
export type UserTopListenedExpertsLazyQueryHookResult = ReturnType<typeof useUserTopListenedExpertsLazyQuery>;
export type UserTopListenedExpertsQueryResult = Apollo.QueryResult<UserTopListenedExpertsQuery, UserTopListenedExpertsQueryVariables>;
export const UserTopCategoriesDocument = gql`
    query userTopCategories($id: String!) {
  user(id: $id) {
    userTopCategories {
      category {
        ...Category
      }
      duration
    }
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useUserTopCategoriesQuery__
 *
 * To run a query within a React component, call `useUserTopCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTopCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTopCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserTopCategoriesQuery(baseOptions: Apollo.QueryHookOptions<UserTopCategoriesQuery, UserTopCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTopCategoriesQuery, UserTopCategoriesQueryVariables>(UserTopCategoriesDocument, options);
      }
export function useUserTopCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTopCategoriesQuery, UserTopCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTopCategoriesQuery, UserTopCategoriesQueryVariables>(UserTopCategoriesDocument, options);
        }
export type UserTopCategoriesQueryHookResult = ReturnType<typeof useUserTopCategoriesQuery>;
export type UserTopCategoriesLazyQueryHookResult = ReturnType<typeof useUserTopCategoriesLazyQuery>;
export type UserTopCategoriesQueryResult = Apollo.QueryResult<UserTopCategoriesQuery, UserTopCategoriesQueryVariables>;
export const GetNotificationPreferencesDocument = gql`
    query getNotificationPreferences {
  me {
    ...NotificationPreferences
  }
}
    ${NotificationPreferencesFragmentDoc}`;

/**
 * __useGetNotificationPreferencesQuery__
 *
 * To run a query within a React component, call `useGetNotificationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationPreferencesQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationPreferencesQuery, GetNotificationPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationPreferencesQuery, GetNotificationPreferencesQueryVariables>(GetNotificationPreferencesDocument, options);
      }
export function useGetNotificationPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationPreferencesQuery, GetNotificationPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationPreferencesQuery, GetNotificationPreferencesQueryVariables>(GetNotificationPreferencesDocument, options);
        }
export type GetNotificationPreferencesQueryHookResult = ReturnType<typeof useGetNotificationPreferencesQuery>;
export type GetNotificationPreferencesLazyQueryHookResult = ReturnType<typeof useGetNotificationPreferencesLazyQuery>;
export type GetNotificationPreferencesQueryResult = Apollo.QueryResult<GetNotificationPreferencesQuery, GetNotificationPreferencesQueryVariables>;
export const ToggleNotificationPreferenceDocument = gql`
    mutation toggleNotificationPreference($type: NotificationType!, $userID: ID) {
  toggleNotificationPreference(type: $type, userID: $userID) {
    ...UserNotificationPreference
  }
}
    ${UserNotificationPreferenceFragmentDoc}`;
export type ToggleNotificationPreferenceMutationFn = Apollo.MutationFunction<ToggleNotificationPreferenceMutation, ToggleNotificationPreferenceMutationVariables>;

/**
 * __useToggleNotificationPreferenceMutation__
 *
 * To run a mutation, you first call `useToggleNotificationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleNotificationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleNotificationPreferenceMutation, { data, loading, error }] = useToggleNotificationPreferenceMutation({
 *   variables: {
 *      type: // value for 'type'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useToggleNotificationPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<ToggleNotificationPreferenceMutation, ToggleNotificationPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleNotificationPreferenceMutation, ToggleNotificationPreferenceMutationVariables>(ToggleNotificationPreferenceDocument, options);
      }
export type ToggleNotificationPreferenceMutationHookResult = ReturnType<typeof useToggleNotificationPreferenceMutation>;
export type ToggleNotificationPreferenceMutationResult = Apollo.MutationResult<ToggleNotificationPreferenceMutation>;
export type ToggleNotificationPreferenceMutationOptions = Apollo.BaseMutationOptions<ToggleNotificationPreferenceMutation, ToggleNotificationPreferenceMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken {
  refreshToken {
    jwt
    success
    finishedRegistration
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const GetListOfBlockedUsersDocument = gql`
    query GetListOfBlockedUsers($filter: BlockedUsersFilter!) {
  me {
    blockedUsers(filter: $filter) {
      meta {
        limit
        offset
        total
      }
      edges {
        id
        __typename
      }
    }
  }
}
    `;

/**
 * __useGetListOfBlockedUsersQuery__
 *
 * To run a query within a React component, call `useGetListOfBlockedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfBlockedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfBlockedUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetListOfBlockedUsersQuery(baseOptions: Apollo.QueryHookOptions<GetListOfBlockedUsersQuery, GetListOfBlockedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfBlockedUsersQuery, GetListOfBlockedUsersQueryVariables>(GetListOfBlockedUsersDocument, options);
      }
export function useGetListOfBlockedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfBlockedUsersQuery, GetListOfBlockedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfBlockedUsersQuery, GetListOfBlockedUsersQueryVariables>(GetListOfBlockedUsersDocument, options);
        }
export type GetListOfBlockedUsersQueryHookResult = ReturnType<typeof useGetListOfBlockedUsersQuery>;
export type GetListOfBlockedUsersLazyQueryHookResult = ReturnType<typeof useGetListOfBlockedUsersLazyQuery>;
export type GetListOfBlockedUsersQueryResult = Apollo.QueryResult<GetListOfBlockedUsersQuery, GetListOfBlockedUsersQueryVariables>;