import * as React from 'react';
import { Box, Image, LinkBox } from '@chakra-ui/react';
import {
  getContentCardProgress,
  getContentPiecesCount,
  isFinished,
  resizeImage
} from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';

import { ContentTimeBadge, ContentTypeBadge } from '~/badges';
import {
  ContentBoxDetails,
  type ContentBoxDetailsLinksProps
} from './components/ContentBoxDetails';
import { ContentBoxEventTimer } from './components/ContentBoxEventTimer';
import { ContentBoxCompletion } from './components/ContentBoxCompletion';
import type { ContentBoxBaseProps } from './types';

export type ContentBarProps = ContentBoxBaseProps & ContentBoxDetailsLinksProps;

export function ContentBar(props: ContentBarProps) {
  const {
    slug,
    name,
    image,
    categories,
    experts,
    eventType,
    boxShadow,
    sectionName,
    sectionIndex,
    isAssigned,
    getPathToContent,
    getPathToCategory,
    getPathToExpert,
    disableUserProgress,
    onBookmarkClick
  } = props;

  const imageSrc = image?.uri || '';
  const imageAlt = image?.alt || '';
  const category = categories?.[0] || {};
  const expert = experts?.[0];

  const hasFinished = isFinished(props);
  const { consumed, total } = getContentCardProgress(props);

  // total can't be 0 because 0/0 = NaN
  const progress = hasFinished ? 100 : (consumed / (total || 1)) * 100;

  const contentPiecesCount = getContentPiecesCount(props);

  return (
    <LinkBox
      as="article"
      height={{
        base: '9rem',
        md: '10rem',
        lg: '13.25rem'
      }}
      width="100%"
      maxW="44rem"
      display="flex"
      flexDirection="row"
      rounded="lg"
      overflow="hidden"
      background="white"
      boxShadow={boxShadow}
      onClick={() => {
        segment.contentCardClicked({
          categories: categories?.map((c) => c.name),
          categoriesIds: categories?.map((c) => c.id),

          experts: experts?.map((e) => `${e.firstName} ${e.lastName}`),
          expertsIds: experts?.map((e) => e.id),

          contentId: props.id,
          contentName: props.name,
          contentType: props.__typename,

          duration: props.length,

          sectionName,
          cardIndexInList: sectionIndex,
          isAssigned
        });
      }}
    >
      <Box
        position="relative"
        height="100%"
        minHeight={{
          base: '6.5rem',
          lg: '7.5rem'
        }}
        width="60%"
        minWidth="40"
        background="lightGrey.400"
      >
        <Image
          src={resizeImage({ url: imageSrc, width: 288, height: 212 })}
          alt={imageAlt}
          width="100%"
          height="100%"
          objectFit="cover"
          opacity={hasFinished ? '0.5' : '1'}
        />
        {props.__typename === 'Event' ? (
          <>
            <ContentBoxEventTimer
              startsAt={props.startsAt}
              endsAt={props.endsAt}
            />
            <ContentTypeBadge
              typename={props.__typename}
              eventType={eventType}
              position="absolute"
              bottom="2"
              left="2"
            />
            <ContentTimeBadge
              typename={props.__typename}
              date={props.startsAt}
              position="absolute"
              bottom="2"
              right="2"
              iconProps={{ boxSize: 4 }}
            />
          </>
        ) : (
          <>
            {disableUserProgress ? null : <ContentBoxCompletion {...props} />}
            <ContentTypeBadge
              typename={props.__typename}
              position="absolute"
              bottom="2"
              left="2"
            />
            <ContentTimeBadge
              typename={props.__typename}
              duration={total}
              position="absolute"
              bottom="2"
              right="2"
              iconProps={{ boxSize: 4 }}
            />
          </>
        )}
      </Box>

      <ContentBoxDetails
        typename={props.__typename}
        getPathToExpert={getPathToExpert}
        getPathToContent={getPathToContent}
        getPathToCategory={getPathToCategory}
        name={name}
        slug={slug}
        category={category}
        expert={expert}
        progress={progress}
        disableUserProgress={disableUserProgress}
        contentPiecesCount={contentPiecesCount}
        as="bar"
        id={props.id}
        contentSegmentProps={props}
        onBookmarkClick={onBookmarkClick}
      />
    </LinkBox>
  );
}
