import * as React from 'react';
import { Image, Box } from '@chakra-ui/react';
import { SimpleImage, resizeImage } from '@tigerhall/core';

const IMAGE_SIZE = {
  mobile: {
    width: 768,
    height: 80
  },
  desktop: {
    width: 640,
    height: 240
  }
};

const DEFAULT_COVER_IMAGE =
  'https://images.prod.tigerhall.io/2023-07-21/a2bafd80-8b77-47f4-afdb-06878df6405b.jpg';

interface CoverImageProps {
  uri: SimpleImage['uri'] | undefined;
  alt: SimpleImage['alt'];
}

export function CoverImage({ uri, alt }: CoverImageProps) {
  const coverImageUri = uri ?? DEFAULT_COVER_IMAGE;

  return (
    <Box
      as="picture"
      width={{
        base: '100%',
        lg: '50%'
      }}
      height={{
        base: '5rem',
        lg: '15rem'
      }}
    >
      <source
        media="(max-width: 767px)"
        srcSet={`${resizeImage({
          url: coverImageUri,
          width: IMAGE_SIZE.mobile.width,
          height: IMAGE_SIZE.mobile.height
        })} 1x, ${resizeImage({
          url: coverImageUri,
          width: IMAGE_SIZE.mobile.width * 2,
          height: IMAGE_SIZE.mobile.height * 2
        })} 2x`}
      />
      <source
        media="(max-width: 1279px)"
        srcSet={`${resizeImage({
          url: coverImageUri,
          width: IMAGE_SIZE.desktop.width,
          height: IMAGE_SIZE.desktop.height
        })} 1x, ${resizeImage({
          url: coverImageUri,
          width: IMAGE_SIZE.desktop.width * 2,
          height: IMAGE_SIZE.desktop.height * 2
        })} 2x`}
      />
      <Image
        srcSet={`${resizeImage({
          url: coverImageUri,
          width: IMAGE_SIZE.desktop.width * 2,
          height: IMAGE_SIZE.desktop.height * 2
        })} 1x, ${resizeImage({
          url: coverImageUri,
          width: IMAGE_SIZE.desktop.width * 3,
          height: IMAGE_SIZE.desktop.height * 3
        })} 2x`}
        src={resizeImage({
          url: coverImageUri,
          width: IMAGE_SIZE.desktop.width * 2,
          height: IMAGE_SIZE.desktop.height * 2
        })}
        alt={alt}
        width="100%"
        height="100%"
        objectFit="cover"
        loading="eager"
      />
    </Box>
  );
}
