import * as React from 'react';
import { Box, Button, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { Content, ContentCard } from '@tigerhall/core/lib/types';
import {
  ContentCardFrame,
  ContentCardWithOverlay
} from '@tigerhall/components';
import find from 'lodash/find';
import map from 'lodash/map';
import { AnimatePresence, motion } from 'framer-motion';
import { IconEdit } from '@tigerhall/icons';
import { useGetContentByIdQuery } from 'generated';
import { ContentSummaryModal } from 'modules/admin/components';
import { DndSortableItem, DndSortableList } from 'components/tools/DragAndDrop';

type Props = {
  allContentCards: ContentCard[];
  selectedContentIds: string[];
  setSelectedContentIds?: (ids: any) => void;
  showEditIcon?: boolean;
  onEdit?: () => void;
  title?: string;
  dataCy?: string;
};

export default function SelectedContentCardList({
  allContentCards,
  selectedContentIds,
  setSelectedContentIds,
  showEditIcon,
  onEdit,
  title,
  dataCy
}: Props) {
  const [selectedContent, setSelectedContent] = React.useState<string>();

  const { data } = useGetContentByIdQuery({
    skip: !selectedContent,
    variables: {
      id: selectedContent as string
    }
  });

  const onCardUnSelect = (id: string) => {
    const updatedIds = selectedContentIds?.filter((value) => value !== id);
    setSelectedContentIds?.(updatedIds);
  };

  if (selectedContentIds?.length <= 0) {
    return null;
  }

  return (
    <Box flexDirection="column" mb={8} mt={4}>
      <Flex flexDirection="row" alignItems="center" gap="1.5rem" mb="1.5rem">
        <Heading color="white" fontSize="1.5rem">
          {title}
        </Heading>
        {showEditIcon && (
          <Flex
            flexDirection="row"
            onClick={onEdit}
            cursor="pointer"
            gap="0.5rem"
            alignSelf="center"
          >
            <IconEdit color="white" />
            <Text color="tigerOrange.600" fontSize="0.875rem">
              Edit
            </Text>
          </Flex>
        )}
      </Flex>
      <SimpleGrid
        templateColumns="repeat(auto-fill, minmax(240px, 1fr))"
        gap={6}
        data-cy={dataCy}
      >
        <AnimatePresence>
          <DndSortableList
            items={selectedContentIds.map((id) => ({ id }))}
            setItems={(items) =>
              setSelectedContentIds?.(items.map((item) => item.id))
            }
          >
            {map(selectedContentIds, (id, index) => (
              <DndSortableItem key={`sortable-${id}`} id={id}>
                <motion.div
                  layout
                  layoutId={id}
                  layoutDependency={id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <ContentCardFrame index={index + 1}>
                    <ContentCardWithOverlay
                      {...find(allContentCards, (o) => o?.id === id)}
                      overlayActions={[
                        {
                          label: 'Learn More',
                          variant: 'outline',
                          onClick: () => {
                            setSelectedContent(id);
                          }
                        },
                        {
                          label: 'Remove',
                          onClick: () => onCardUnSelect(id)
                        }
                      ]}
                    />
                  </ContentCardFrame>
                </motion.div>
              </DndSortableItem>
            ))}
          </DndSortableList>
        </AnimatePresence>
      </SimpleGrid>
      <ContentSummaryModal
        isOpen={!!data?.contentCard}
        onClose={() => setSelectedContent(undefined)}
        content={data?.contentCard as Content}
        actions={
          <>
            <Button
              flex={1}
              variant="outline"
              onClick={() => {
                onCardUnSelect(selectedContent || '');
                setSelectedContent(undefined);
              }}
            >
              Remove
            </Button>
          </>
        }
      />
    </Box>
  );
}
