import {
  HStack,
  Icon,
  type IconProps,
  Text,
  type TextProps,
  Tooltip
} from '@chakra-ui/react';
import { IconDiscountCheckFilled } from '@tigerhall/icons';

export type ThinkfluencerScope = 'public' | 'organization';
export type ThinkfluencerBadgeSize = 'sm' | 'md' | 'lg';
type LabelDisplay = 'tooltip' | 'text';

type BadgeSizeRecord = Record<'badge', IconProps['boxSize']>;
type LabelSizeRecord = Record<'label', TextProps['fontSize']>;
type SizeMapValue = BadgeSizeRecord & LabelSizeRecord;

const sizeMap: Record<ThinkfluencerBadgeSize, SizeMapValue> = {
  sm: {
    badge: 3,
    label: 'xs'
  },
  md: {
    badge: 4,
    label: 'sm'
  },
  lg: {
    badge: 5,
    label: 'md'
  }
};

const scopeToLabelMap: Record<ThinkfluencerScope, string> = {
  public: 'Verified Thinkfluencer',
  organization: 'Organization Thinkfluencer'
};

const scopeToColorMap: Record<ThinkfluencerScope, IconProps['color']> = {
  public: 'tigerOrange.600',
  organization: 'teal.500'
};

export interface ThinkfluencerBadgeProps {
  /**
   * The scope of the thinkfluencer.
   * This determines the label and color of the badge.
   */
  scope: ThinkfluencerScope;
  /**
   * The size of the component. This affects the size of the badge and label.
   *
   * @default 'md'
   */
  size?: ThinkfluencerBadgeSize;
  /**
   * How to display the label.
   * If 'tooltip', the label will be displayed as a tooltip on hover.
   * If 'text', the label will be displayed as text, next to the badge.
   *
   * @default 'tooltip'
   */
  displayLabelAs?: LabelDisplay;
}

/**
 * A badge to indicate if a user is a thinkfluencer, either for the public
 * or an organization.
 */
export function ThinkfluencerBadge({
  scope,
  size = 'md',
  displayLabelAs = 'tooltip'
}: Readonly<ThinkfluencerBadgeProps>) {
  const label = scopeToLabelMap[scope];
  const color = scopeToColorMap[scope];

  const badgeSize = sizeMap[size].badge;
  const labelSize = sizeMap[size].label;

  if (displayLabelAs === 'tooltip') {
    return (
      <Tooltip label={label} hasArrow>
        <Icon
          as={IconDiscountCheckFilled}
          color={color}
          boxSize={badgeSize}
          zIndex="base"
          aria-label={label}
          data-testid="thinkfluencer-badge"
        />
      </Tooltip>
    );
  }

  return (
    <HStack spacing={1}>
      <Icon
        as={IconDiscountCheckFilled}
        color={color}
        boxSize={badgeSize}
        aria-hidden
        data-testid="thinkfluencer-badge"
      />
      <Text as="span" fontSize={labelSize}>
        {label}
      </Text>
    </HStack>
  );
}
