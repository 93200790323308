import * as React from 'react';
import { Box, Button, Center, Flex, VStack, useToast } from '@chakra-ui/react';
import { Typography } from '@tigerhall/ui-components';
import { FormInput, FormTextarea, useTrackedForm } from '@tigerhall/components';
import { FormProvider } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';
import {
  useGetCurrentUserQuery,
  useUpdateUserProfileMutation
} from 'generated';
import { ProfileProgressBar } from 'modules/application/components';

import { EditProfileCover } from './components/EditProfileCover';
import { EditProfileHeader } from './components/EditProfileHeader';
import { OnWebSocials } from './components/OnWebSocials';
import { LAYOUT_HEIGHT_WITHOUT_HEADER } from '../../../../../../components/ui/Layout';
import { AppError } from '../../../../../../utils';

type FormFields = {
  bannerImg: {
    id: string;
    uri: string;
  } | null;
  profileImg: {
    id: string;
    uri: string;
  } | null;
  jobTitle: string;
  company: string;
  facebookLink: string | null;
  twitterHandle: string | null;
  instagramHandle: string | null;
  linkedinLink: string | null;
  websiteLink: string | null;
  biography: string | null;
};

export function EditProfile() {
  const toast = useToast();
  const toastIdRef = React.useRef<string | number>();
  const navigate = useNavigate();

  const [updateUserProfileMutation] = useUpdateUserProfileMutation();
  const { data, loading, error } = useGetCurrentUserQuery();

  const form = useTrackedForm<FormFields>('EDIT_PROFILE', {
    defaultValues: {
      ...data?.me
    }
  });

  const { reset } = form;

  React.useEffect(() => {
    reset({
      ...data?.me,
      profileImg: data?.me?.avatarImage,
      bannerImg: data?.me?.coverImage
    });
  }, [data?.me, reset]);

  const onSubmit = async (values: FormFields) => {
    if (
      !data?.me?.id ||
      !data?.me?.roles ||
      !data?.me?.firstName ||
      !data?.me?.lastName ||
      !data?.me?.email
    ) {
      throw new AppError(
        'Required user data is missing. Please refresh the page and try again.',
        {
          extra: {
            user: data?.me
          }
        }
      );
    }

    const variables = {
      id: data.me.id,
      input: {
        roles: data.me.roles,
        firstName: data.me.firstName,
        lastName: data.me.lastName,
        email: data.me.email,
        jobTitle: values.jobTitle,
        company: values.company,
        facebookLink: values.facebookLink,
        twitterHandle: values.twitterHandle,
        instagramHandle: values.instagramHandle,
        linkedinLink: values.linkedinLink,
        websiteLink: values.websiteLink,
        biography: values.biography,
        avatarImage: values?.profileImg?.id,
        coverImage: values?.bannerImg?.id
      }
    };

    toastIdRef.current = toast({
      status: 'loading',
      title: 'Updating profile'
    });

    try {
      const { errors } = await updateUserProfileMutation({
        variables
      });

      if (errors) {
        toast.update(toastIdRef.current, {
          status: 'error',
          title: 'The profile could not be updated'
        });

        captureException(errors, {
          extra: {
            variables
          }
        });
      } else {
        toast.update(toastIdRef.current, {
          status: 'success',
          title: 'Profile successfully updated'
        });
        navigate('/profile');
      }
    } catch (e) {
      toast.update(toastIdRef.current, {
        status: 'error',
        title: 'The profile could not be updated'
      });

      captureException(e, {
        extra: {
          variables
        }
      });
    }
  };

  if (loading || error) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>User Profile</title>
      </Helmet>
      <Flex
        flex={1}
        p={0}
        minH={LAYOUT_HEIGHT_WITHOUT_HEADER}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
        background="darkGrey.700"
        justifyContent="center"
      >
        <Box
          maxW="48rem"
          w="100%"
          css={{
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}
        >
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <EditProfileHeader isSubmitting={form.formState.isSubmitting} />
              <ProfileProgressBar
                variant="dark"
                profileStatusTitle="Your Profile"
                containerProps={{
                  mt: '2rem',
                  p: '2rem 1.5rem'
                }}
              />
              {/* <ProfileCompletionInfo /> */}
              <Box
                background="darkGrey.700"
                borderColor="darkGrey.200"
                borderWidth={{ base: 0, md: '0.5px' }}
                mt={{
                  base: 0,
                  md: '2rem'
                }}
                borderRadius={8}
                overflow="hidden"
              >
                <EditProfileCover
                  coverImg={data?.me?.coverImage?.uri || null}
                  profileImg={data?.me?.avatarImage?.uri || null}
                />
                <Box mx="1rem">
                  <VStack
                    align="stretch"
                    spacing="1.5rem"
                    display="flex"
                    flex={1}
                  >
                    <Box mt="3rem">
                      <Typography
                        variant="detail3"
                        color="lightGrey.200"
                        mt="5rem"
                      >
                        Name
                      </Typography>
                      <Typography
                        variant="detail3"
                        color="lightGrey.200"
                        mt="0.5rem"
                        mb="1rem"
                      >
                        {`${data?.me?.firstName} ${data?.me?.lastName}`}
                      </Typography>
                    </Box>
                    <Box mt="1.5rem">
                      <FormInput
                        type="text"
                        name="jobTitle"
                        label="Job Title"
                        placeholder="Job title"
                        rules={{
                          maxLength: {
                            value: 100,
                            message:
                              'You have reached the limit of 100 characters'
                          }
                        }}
                      />
                    </Box>
                    <Box mt="1rem">
                      <FormInput
                        name="company"
                        type="text"
                        label="Company Name"
                        placeholder="Company name"
                        rules={{
                          maxLength: {
                            value: 100,
                            message:
                              'You have reached the limit of 100 characters'
                          }
                        }}
                      />
                    </Box>
                    <OnWebSocials />
                    <Box mt="1rem">
                      <FormTextarea
                        name="biography"
                        label="About Me"
                        placeholder="Write something about yourself"
                      />
                    </Box>
                  </VStack>
                </Box>
                <Center>
                  <Button
                    type="submit"
                    variant="solid"
                    size="lg"
                    mt={8}
                    mb={8}
                    isLoading={form.formState.isSubmitting}
                  >
                    Save Changes
                  </Button>
                </Center>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </Flex>
    </>
  );
}
