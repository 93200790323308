import {
  AuthenticationError as AuthError,
  ValidationError as ValidateError,
  NotFoundError as NotAvailableError
} from '~/types';

export type Error = {
  message?: string;
  type?: string;
};

export type ErrorOccured = ValidateError | AuthError | NotAvailableError;

export type Data<T> = T extends ErrorOccured ? ErrorOccured : T;

type FieldName<T> = keyof T;

export type SetError<T> = (name: FieldName<T>, error: Error) => void;
export type HandleAuthError = (message: string) => void;

export enum ErrorType {
  AuthenticationError = 'AuthenticationError',
  ValidationError = 'ValidationError',
  NotFoundError = 'NotFoundError'
}

/**
 * @description - This is a generic error handler for the new `v2 graphql` API.
 *
 * ⚠️ Must be used by wrapping it inside a `try-catch` block.
 *
 * @param  data - Either the data or a validation/authentication error.
 *
 * @param  setError - From React Hook Form.
 *
 * @param  handleAuthError - To handle authentication errors, possibly with `toast`.
 *
 * @returns Nothing if there's no error. If there's an error, it throws an error.
 *
 * @example
 * ```
 * const toast = useToast({
 *   position: 'top-right',
 *   duration: 3000,
 *   isClosable: true
 * });
 *
 * const submit = async (values: FormFields, { setError }) => {
 *   try {
 *     const { data } = await createUser({ firstName: "John", lastName: "Doe" });
 *
 *     const handleAuthError = (message: string) => toast({
 *       title: message,
 *       status: 'error'
 *     });
 *
 *     captureMutationError(data.createUser, setError, handleAuthError);
 *   } catch (error) {
 *     console.log(error);
 *   }
 * }
 * ```
 * @summary It handles errors for the new GraphQL API.
 */
export function captureMutationError<T>(
  data: Data<T>,
  setError: SetError<T>,
  handleOtherError: HandleAuthError
) {
  const handleValidationError = (fieldErrors: ValidateError['fieldErrors']) => {
    fieldErrors?.forEach((err) => {
      setError(err?.path as keyof T, { type: 'manual', message: err?.message });
    });

    throw new Error(ErrorType.ValidationError);
  };

  const errorData = data as ErrorOccured;

  switch (errorData?.__typename) {
    case ErrorType.AuthenticationError:
    case ErrorType.NotFoundError:
      handleOtherError(errorData?.message);
      throw new Error(ErrorType.AuthenticationError);

    case ErrorType.ValidationError:
      return handleValidationError(errorData?.fieldErrors);

    default:
      return;
  }
}

/**
   *
   * @description this function will be used in catch block as a sidekick for captureMutationError
   * 
   * `USE` - to exit out of catch block if the error is handled properly
   * 
   * For handled error we don't want to send the error to Sentry
   *
   * @param error the error we received in catch block
   *
   * @returns boolean value if it is an handled error then true otherwise false
   * @example
   * ```
   *  catch (error) {
          if (handleMutationError(error)) {
            return;
          }
        }
   * ```
   */
export function handleMutationError(error: unknown) {
  const mutationErr = error as { message?: string };
  if (!mutationErr.message) {
    return false;
  }

  switch (mutationErr.message) {
    case ErrorType.ValidationError:
    case ErrorType.AuthenticationError:
    case ErrorType.NotFoundError:
      return true;
    default:
      return false;
  }
}

// this is needed to handle validation errors for mutation that are not form related
// as they don't have fields in the UI or a form
export function mimicSetError(
  toast: (options: { description?: string }) => void
) {
  return (path: string, mimickedError: Error) => {
    toast({ description: `${path}: ${mimickedError?.message}` });
  };
}

export function useStructuredErrors() {
  // these needs to be used together
  // how-to is explained on every funcitons declaration

  return { handleMutationError, captureMutationError, mimicSetError };
}
