import { Box, Center, Flex } from '@chakra-ui/react';
import {
  TigerhallSkeleton,
  TopicCloud,
} from '@tigerhall/ui-components';
import { DummyCopies } from 'components/ui';
import { HeaderText } from 'modules/authentication/modules/Onboarding/components/Common/HeaderText';
import { NextButton } from 'modules/authentication/modules/Onboarding/components/Common/NextButton';
import { useInterests } from 'modules/authentication/modules/Onboarding/hooks/useInterests';

type Props = {
  title?: string;
  subTitle?: string;
  primaryCtaText?: string;
  onComplete?: () => void;
};

export function Interests({
  title,
  subTitle,
  primaryCtaText,
  onComplete
}: Props) {
  const {
    selectedIDs,
    setSelectedIDs,
    handleClick,
    lifeGoalsData,
    loading,
    isUpdating
  } = useInterests({ onComplete });

  return (
    <Flex w="100%" maxWidth="96rem" flexDirection="column" alignItems="center">
      <HeaderText
        heading={title || 'Start following topics of interest'}
        description={
          subTitle ||
          "Next up, let's prioritize so you focus on what matters to you."
        }
        descriptionBold="Choose at least 3 topics you'd like to develop expertise in."
      />
      <Box pt="3.5rem">
        {!loading ? (
          <TopicCloud
            bgColor="transparent"
            topics={lifeGoalsData}
            selectedIDs={selectedIDs}
            onChange={(ids) => setSelectedIDs(ids)}
          />
        ) : (
          <Center width="100%" flexWrap="wrap">
            <DummyCopies count={22}>
              <TigerhallSkeleton variant="cloud_item" />
            </DummyCopies>
          </Center>
        )}
      </Box>
      {!loading && (
        <Center pt="3.5rem" width="100%" flexDirection="column">

          <NextButton
            isDisabled={isUpdating}
            text={primaryCtaText || 'NEXT'}
            onClick={() => handleClick(selectedIDs)}
            data-cy="interests-next-button"
          />
        </Center>
      )}
    </Flex>
  );
}
