import {
  FormCheckbox,
  FormDateTimePicker,
  FormInput
} from '@tigerhall/components';
import * as React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Text
} from '@chakra-ui/react';
import { useFieldArray } from 'react-hook-form';
import { DateTime } from 'luxon';
import { OrganisationMemberSelect } from 'components/form';
import { EditorForm } from 'components/ui/lexical/form/EditorForm';

export function FormAgenda() {
  const { fields, append, remove } = useFieldArray({
    name: 'agenda'
  });

  const minDate = DateTime.now().minus({ days: 1 }).toJSDate();

  return (
    <Flex direction="column" gap="1rem">
      <Heading>The Agenda</Heading>
      <Text>
        Adding an agenda for a broadcast provides viewers a structured roadmap,
        enhancing understanding, engagement, and anticipation, ensuring a
        cohesive and informative viewing experience.
      </Text>

      {fields.map((field, index) => (
        <Flex direction="column" gap="1rem" mb="1rem" key={field.id}>
          <Flex gap="1rem">
            <Box flex={1}>
              <FormInput
                name={`agenda.${index}.topic`}
                label="Topic"
                placeholder="Enter a topic for the broadcast"
                rules={{
                  required: 'Please select the broadcast topic',
                  minLength: {
                    value: 3,
                    message: 'Agenda topic must be at least 3 characters'
                  },
                  maxLength: {
                    value: 85,
                    message: 'Agenda topic title must be at most 20 characters'
                  }
                }}
              />
            </Box>

            <Box flex={1}>
              <FormDateTimePicker
                name={`agenda.${index}.estimatedStartTime`}
                label="Estimated start time"
                placeholder="Provide an optional start time for this topic in the agenda"
                minDate={minDate}
                rules={{
                  required: 'Please select the estimated start time'
                }}
              />
            </Box>
            <Box flex={1}>
              <OrganisationMemberSelect
                name={`agenda.${index}.speakers`}
                label="Speakers"
                isMulti={true}
                required={false}
              />
            </Box>
          </Flex>
          <Box flex={1}>
            <FormCheckbox name={`agenda.${index}.isActive`} text={'Live Now'} />
          </Box>
          <EditorForm
            name={`agenda.${index}.description`}
            label="Description"
            placeholder="Give a brief intro to this topic in the agenda"
            showHeadings={false}
          />
        </Flex>
      ))}

      <ButtonGroup>
        <Button
          onClick={() =>
            append({
              topic: '',
              description: '',
              estimatedStartTime: '',
              speakers: []
            })
          }
        >
          New topic
        </Button>
        <Button variant="outline" onClick={() => remove(fields.length - 1)}>
          Remove last topic
        </Button>
      </ButtonGroup>
    </Flex>
  );
}
