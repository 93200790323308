import * as React from 'react';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { IconSearch } from '@tigerhall/icons';
import { useFormContext } from 'react-hook-form';

type SearchBarInputType = {
  name?: string;
};
export function SearchBarInput({ name = '' }: SearchBarInputType) {
  const { register } = useFormContext();

  return (
    <InputGroup
      flexGrow={1}
      justifyContent="center"
      alignItems="center"
      borderColor="darkGrey.200"
      borderRadius="base"
    >
      <InputLeftElement
        m="0.75rem 0.5rem 0.75rem 0.75rem"
        width="1rem"
        h="1rem"
        pointerEvents="none"
        top="inherit"
      >
        <IconSearch color="white" height="1rem" width="1rem" />
      </InputLeftElement>
      <Input
        p="0.5rem 0.75rem 0.5rem 2.25rem"
        variant="unstyled"
        {...register(name)}
        placeholder="Search..."
        color="white"
        height="2.5rem"
        bg="darkGrey.400"
        _focus={{
          bg: 'darkGrey.700'
        }}
      />
    </InputGroup>
  );
}
