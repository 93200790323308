import { Box, Flex, Text } from '@chakra-ui/react';
import { BackButton } from 'components/ui/Buttons';
import { type GetOrganisationMediaQuery, type Organisation } from 'generated';

import { OrganisationLogo } from './OrganisationLogo';

interface OrganisationPageBannerProps {
  heading: string;
  logo: GetOrganisationMediaQuery['organisation']['logo'];
  org: Pick<Organisation, 'name'>;
}

export function OrganisationPageBanner({
  heading,
  logo,
  org
}: OrganisationPageBannerProps) {
  return (
    <Box
      width="100%"
      px={{ base: '1rem', md: '2rem' }}
      py="1.5rem"
      bgGradient="linear(teal.600 0%, darkGrey.700 100%)"
    >
      <Flex alignItems="center" justifyContent="space-between" marginBottom={4}>
        <BackButton to="/home">Back</BackButton>
        <Text
          as="h2"
          color="white"
          fontWeight="bold"
          fontSize={{
            base: 'xl',
            md: '2xl'
          }}
          align="right"
          maxWidth={60}
        >
          {heading}
        </Text>
      </Flex>
      <Flex
        flexDirection={{
          base: 'column',
          lg: 'row-reverse'
        }}
        justifyContent="center"
        alignItems="center"
        minHeight={16}
      >
        {logo?.uri ? (
          <OrganisationLogo {...logo} />
        ) : (
          <Text as="h3" fontSize="2xl" fontWeight="bold" color="lightGrey.200">
            {org?.name}
          </Text>
        )}
      </Flex>
    </Box>
  );
}
