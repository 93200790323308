import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';

import { useLimitLinkPreview } from '~/components/ui/LinkPreview/hooks';
import { setLinkAttachments } from '~/app/state';
import type { LinkMap } from '~/components/ui/LinkPreview/utils';
import { LinkPreview } from '~/components/ui/LinkPreview';
import { useAppDispatch } from '~/hooks';

interface PostLinkPreviewProps {
  urls: string[];
  /**
   * @description defaultUrls are urls for which preview should
   * be shown, this affects only for initial render (uncontrolled)
   */
  defaultUrls?: string[];
}

export function PostLinkPreview({
  urls,
  defaultUrls
}: Readonly<PostLinkPreviewProps>) {
  const { linksToShow, setUrlsMap } = useLimitLinkPreview({
    urls,
    noOfPreviewToLimit: 4
  });

  const dispatch = useAppDispatch();

  function onRemove(toRemoveUrl: string) {
    setUrlsMap((prev) => {
      const modified = { ...prev };
      const urlObj = modified[toRemoveUrl];
      if (urlObj) {
        urlObj.isRemoved = true;
      }
      return modified;
    });
  }

  function onPreview(previewUrls: string[]) {
    const previewLinksAttachments = previewUrls.map((url) => ({
      previewLink: url
    }));
    dispatch(setLinkAttachments(previewLinksAttachments));
  }

  /**
   * This sets isRemoved to true for urls which are already removed
   * e.g.
   * Creation of Post
   * -- we have 4 urls out which 2 are removed
   * -- user had added some urls and deleted previews for some and,
   * -- presses the back btn and we save the editing post in draft,
   * -- now user comes back we don't want to show previews for urls,
   * -- which are already removed
   *
   * Updating of Post
   * -- we have 3 urls out which 2 are removed,
   * -- user clicks on edit post button and is navigated
   * -- now we only want to show preview for one url.com
   *
   * Note this effect should only run for the initial render
   */
  useEffect(() => {
    if (defaultUrls) {
      setUrlsMap(
        urls.reduce<Record<string, LinkMap>>((acc, each) => {
          acc[each] = {
            url: each,
            isRemoved: !defaultUrls.includes(each)
          };
          return acc;
        }, {})
      );
    }
  }, []);

  return (
    <Box px={2} w={'full'}>
      <LinkPreview
        urls={linksToShow}
        onRemove={onRemove}
        onPreview={onPreview}
      />
    </Box>
  );
}
