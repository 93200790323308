import { Fragment, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import {
  Controller,
  type UseControllerProps,
  type UseFormReturn,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { type Props as DayzedHookProps } from 'dayzed';
import { DateTime } from 'luxon';

import { MONTH_NAMES_FULL, WEEKDAY_NAMES_SHORT } from './utils/calanderUtils';
import { CalendarPanel } from './components/CalendarPanel';
import { RangeShortcuts } from './components/RangeShortcuts';
import type {
  CalendarConfigs,
  DatepickerConfigs,
  DatepickerProps,
  OnDateSelected,
  PropsConfigs
} from './types';

interface RangeCalendarPanelProps {
  dayzedHookProps: DayzedHookProps;
  configs: CalendarConfigs;
  propsConfigs?: PropsConfigs;
  selected?: Date | Date[];
}

function RangeCalendarPanel({
  dayzedHookProps,
  configs,
  propsConfigs,
  selected
}: Readonly<RangeCalendarPanelProps>) {
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

  // Calendar level
  function onMouseLeave() {
    setHoveredDate(null);
  }

  // Date level
  function onMouseEnterHighlight(date: Date) {
    if (!Array.isArray(selected) || !selected?.length) {
      return;
    }
    setHoveredDate(date);
  }

  function isInRange(date: Date) {
    if (!Array.isArray(selected) || !selected?.length) {
      return false;
    }
    const firstSelected = selected[0];
    if (selected.length === 2) {
      const secondSelected = selected[1];
      return firstSelected < date && secondSelected > date;
    } else {
      return (
        hoveredDate &&
        ((firstSelected < date && hoveredDate >= date) ||
          (date < firstSelected && date >= hoveredDate))
      );
    }
  }

  return (
    <Flex onMouseLeave={onMouseLeave}>
      <CalendarPanel
        dayzedHookProps={dayzedHookProps}
        configs={configs}
        propsConfigs={propsConfigs}
        isInRange={isInRange}
        onMouseEnterHighlight={onMouseEnterHighlight}
      />
    </Flex>
  );
}

export interface FormRangeDatePickerProps extends DatepickerProps {
  /**
   * The name of the input
   */
  name: string;
  /**
   * The label of the tooltip
   */
  label: string;
  /**
   * Placeholder text
   */
  placeholder: string;
  id?: string;
  /**
   * If the component is disabled
   */
  disabled?: boolean;
  /**
   * react-hook-forms for rules
   */
  rules?: UseControllerProps['rules'];
  configs?: DatepickerConfigs;
  defaultIsOpen?: boolean;
  usePortal?: boolean;
  numberOfCalendars?: 1 | 2;
  analyticsDisabled?: boolean;
}

const DefaultConfigs: CalendarConfigs = {
  dateFormat: 'DD',
  monthNames: MONTH_NAMES_FULL,
  dayNames: WEEKDAY_NAMES_SHORT,
  firstDayOfWeek: 0
};

export function FormRangeDatePicker({
  name,
  label,
  placeholder,
  rules = {},
  disabled,
  id,
  configs,
  propsConfigs = {},
  usePortal,
  numberOfCalendars = 1,
  defaultIsOpen = false,
  // TODO: Maybe we should set this in `rules` but I am not sure
  // if that will work since `minDate` and `maxDate` alter the
  // UI of the calendar
  minDate,
  maxDate,
  analyticsDisabled = false
}: Readonly<FormRangeDatePickerProps>) {
  const [offset, setOffset] = useState(0);
  const { onOpen, onClose, isOpen } = useDisclosure({ defaultIsOpen });
  // we need to use watch to re-render the component when the internal value changes
  const selectedDates = useWatch({ name }) || [];

  const methods = useFormContext() as UseFormReturn & {
    formName: string;
  };

  if (methods === null) {
    return <Text color="state.error">Missing form context</Text>;
  }

  if (!methods.formName && !analyticsDisabled) {
    return <Text color="state.error">Missing form name</Text>;
  }

  const calendarConfigs: CalendarConfigs = {
    ...DefaultConfigs,
    ...configs
  };

  // dayzed utils
  // eslint-disable-next-line func-style -- we need to type this function
  const handleOnDateSelected: OnDateSelected = ({ selectable, date }) => {
    if (!selectable) {
      return;
    }

    const newDates = [...selectedDates];
    if (selectedDates.length) {
      if (selectedDates.length === 1) {
        const firstTime = selectedDates[0];
        if (firstTime < date) {
          newDates.push(date);
        } else {
          newDates.unshift(date);
          setOffset(0);
        }
        methods.setValue(name, newDates);
      } else if (newDates.length === 2) {
        setOffset(0);
        methods.setValue(name, [date]);
      }
    } else {
      newDates.push(date);
      methods.setValue(name, newDates);
      setOffset(0);
    }
  };

  function onPopoverClose() {
    onClose();
    setOffset(0);
  }

  function handleRangeShortcutClick() {
    setOffset(0);
  }

  const PopoverContentWrapper = usePortal ? Portal : Fragment;

  function getDatesString(values: Date[]) {
    // eventually we want to allow user to freely type their own input and parse the input
    let inputVisibleValue = values?.[0]
      ? `${DateTime.fromJSDate(values[0]).toFormat(calendarConfigs.dateFormat)}`
      : '';
    inputVisibleValue += values?.[1]
      ? ` - ${DateTime.fromJSDate(values[1]).toFormat(
          calendarConfigs.dateFormat
        )}`
      : '';
    return inputVisibleValue;
  }

  return (
    <Popover
      placement="bottom-start"
      variant="responsive"
      isOpen={disabled ? false : isOpen}
      onOpen={onOpen}
      onClose={onPopoverClose}
      isLazy
    >
      <PopoverTrigger>
        <Box>
          <Controller
            name={name}
            control={methods.control}
            rules={rules}
            render={({ field, fieldState }) => (
              <FormControl
                isInvalid={!!fieldState.error}
                isRequired={!!rules?.required}
                isDisabled={disabled}
              >
                <FormLabel>{label}</FormLabel>
                <Input
                  name={field.name}
                  autoComplete="off"
                  placeholder={placeholder}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={getDatesString(field.value)}
                  ref={field.ref}
                  data-cy={`${methods.formName}-${name}`}
                  onClick={(e) => {
                    e.preventDefault();
                    onOpen();
                  }}
                  onKeyPress={(e) => {
                    if (e.key === ' ' && !isOpen) {
                      e.preventDefault();
                      onOpen();
                    }
                  }}
                  id={id}
                  {...propsConfigs?.inputProps}
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </Box>
      </PopoverTrigger>
      <PopoverContentWrapper>
        <PopoverContent
          width="100%"
          background="darkGrey.400"
          color="white"
          borderColor="lightGrey.600"
          {...propsConfigs?.popoverCompProps?.popoverContentProps}
        >
          <PopoverBody
            display="flex"
            padding="1.5rem"
            gap="2rem"
            {...propsConfigs.popoverCompProps?.popoverBodyProps}
          >
            <RangeShortcuts
              name={name}
              onRangeShortcutClick={handleRangeShortcutClick}
            />
            <Flex direction="column" gap="1rem" justifyContent="space-between">
              <RangeCalendarPanel
                dayzedHookProps={{
                  onDateSelected: handleOnDateSelected,
                  selected: selectedDates,
                  date: selectedDates?.[1] || selectedDates?.[0] || new Date(),
                  minDate: minDate,
                  maxDate: maxDate,
                  offset: offset,
                  onOffsetChanged: setOffset,
                  firstDayOfWeek: calendarConfigs.firstDayOfWeek,
                  monthsToDisplay: numberOfCalendars
                }}
                configs={calendarConfigs}
                propsConfigs={propsConfigs}
                selected={selectedDates}
              />
              <HStack alignSelf="flex-end">
                <Button
                  variant="solid"
                  size="sm"
                  onClick={() => {
                    onPopoverClose();
                  }}
                >
                  Done
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    methods.setValue(name, '');
                    onPopoverClose();
                  }}
                >
                  Clear
                </Button>
              </HStack>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </PopoverContentWrapper>
    </Popover>
  );
}
