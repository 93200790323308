import * as React from 'react';
import { Box, Center, Icon, Image } from '@chakra-ui/react';
import { resizeImage } from '@tigerhall/core';
import { IconCirclePlus, IconProgressCircle } from '@tigerhall/icons';

import { useEditProfileImageupload } from '../hooks/useEditProfileImageupload';
import { ImageCropModal, ImageCropModalProps } from './ImageCropModal';

interface EditProfileImageUploadProps {
  name: string;
  /**
   * Upload callback used to upload the image to the backend
   *
   * @param file
   */
  upload: (file: File) => Promise<{ id: string; uri: string }>;
  width?: string;
  height?: string;
  getUpdatedImageUrl: (value: string) => void;
  cropSize: ImageCropModalProps['size'];
  imageUrl: ImageCropModalProps['src'];
  circularCrop?: ImageCropModalProps['circularCrop'];
  boxSize?: string;
  borderRadius?: string;
  dataCy?: string;
}

export function EditProfileImageUpload({
  name,
  upload,
  cropSize,
  imageUrl,
  width,
  height,
  boxSize,
  borderRadius,
  dataCy,
  getUpdatedImageUrl,
  circularCrop
}: EditProfileImageUploadProps) {
  const {
    cropImageUrl,
    closeModal,
    isCropModalOpen,
    onCropApply,
    onImageChange,
    isUploading
  } = useEditProfileImageupload({ name, cropSize, getUpdatedImageUrl, upload });

  return (
    <>
      {cropImageUrl && (
        <ImageCropModal
          closeModal={closeModal}
          isCropModalOpen={isCropModalOpen}
          src={cropImageUrl}
          onSave={onCropApply}
          size={cropSize}
          circularCrop={circularCrop}
        />
      )}
      <Box
        w={width}
        h={height}
        borderRadius={borderRadius}
        overflow="hidden"
        position="relative"
        css={{
          '& input': {
            cursor: 'pointer'
          }
        }}
      >
        <input
          type="file"
          data-cy={dataCy}
          onChange={onImageChange}
          accept="image/*"
          style={{
            width: '100%',
            height: '100%',
            opacity: 0,
            position: 'absolute',
            zIndex: 1
          }}
        />
        <Center
          position="absolute"
          top="50%"
          left="50%"
          zIndex={0}
          transform="translate(-50%,-50%)"
        >
          <Icon
            as={isUploading ? IconProgressCircle : IconCirclePlus}
            color="white"
            height="1.5rem"
            width="1.5rem"
          />
        </Center>
        <Image
          w="100%"
          h="100%"
          objectFit="cover"
          src={
            imageUrl
              ? resizeImage({
                  url: imageUrl,
                  width: cropSize?.width || 680,
                  height: cropSize?.height || 170
                })
              : ''
          }
          background="whiteAlpha.200"
          alt="Profile or Cover image"
          boxSize={boxSize}
        />
      </Box>
    </>
  );
}
