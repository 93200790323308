import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  useToast,
  Box,
  HStack
} from '@chakra-ui/react';
import * as segment from '@tigerhall/analytics';
import { useRegisterSW } from 'virtual:pwa-register/react';

const updateIntervalMS = 1000 * 60 * 30; // 30 minutes

/**
 * This component is used to display a toast to the user when a new version of the web app is available
 * through the service worker. Once the user clicks the update button, the service worker will be updated
 * and the page will be reloaded.
 *
 * It will also automatically check for service worker updates every 30 minutes, and update the service worker
 * if a new version is available. This is relevant for users who leave the web app open for a long time.
 *
 * This component is used in the root of `apps/webapp/src/components/tools/App/Router.tsx`
 *
 * @see https://vite-pwa-org.netlify.app/frameworks/react.html
 */
export function ReloadPrompt() {
  const toast = useToast();
  const id = 'update-prompt';

  const {
    needRefresh: [shouldRefresh],
    updateServiceWorker
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      if (r) {
        setInterval(() => {
          r.update();
        }, updateIntervalMS);
      }
    },
    onRegisterError(error) {
      console.warn('SW registration error', error);
    }
  });

  if (shouldRefresh && !toast.isActive(id)) {
    segment.versionUpdatePromptDisplayed({});

    toast({
      id,
      position: 'bottom-right',
      duration: null,
      render: (props) => (
        <Alert
          status="warning"
          variant="solid"
          rounded="md"
          gap={{
            base: '1rem',
            md: '2rem'
          }}
        >
          <HStack>
            <AlertIcon />
            <Box>
              <AlertTitle>New version available</AlertTitle>
              <AlertDescription>
                Click update to get the latest version of Tigerhall
              </AlertDescription>
            </Box>
          </HStack>
          <HStack>
            <Button
              variant="solidDark"
              size="md"
              ml={3}
              onClick={() => {
                segment.versionUpdated({});

                updateServiceWorker(true);
                props.onClose();
              }}
            >
              Update
            </Button>
          </HStack>
        </Alert>
      )
    });
  }

  return null;
}
