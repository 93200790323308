import { mode as r } from "./index31.mjs";
const o = {
  // style object for base or default style
  baseStyle: (e) => ({
    fontSize: "0.875rem",
    color: r("darkGrey.200", "lightGrey.900")(e),
    "::first-letter": {
      textTransform: "uppercase"
    }
  })
}, s = {
  // style object for base or default style
  baseStyle: (e) => ({
    text: {
      fontSize: "0.875rem",
      lineHeight: "1.2",
      color: r("red.500", "lightGrey.600")(e)
    }
  }),
  parts: ["text", "icon"]
};
export {
  s as FormErrorMessage,
  o as FormLabel
};
