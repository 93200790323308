export type SubscriptionType = 'individual' | 'team';

export interface GetStripePaymentGatewayProps {
  /**
   * The page to redirect to
   */
  origin: string;

  /**
   * user jwt
   */
  token: string;

  /**
   * Renewal period for the subscription
   */
  duration?: 'monthly' | 'yearly';

  /**
   * Specifying a campaign can overwrite the default configuration for the subscription
   */
  campaign?: string;

  /**
   * The type of subscription to pay for
   */
  subscriptionType: SubscriptionType;

  /**
   * URL to redirect the user after payment success
   */
  successRedirectUrl: string;

  /**
   * URL to redirect the user if cancels the payment
   */
  cancelRedirectUrl: string;
}

/**
 * Gets the payment gateway page URL to redirect the user
 * to pay for a subscription plan for a team or an individual
 *
 * @example ```ts
 * const paymentGatewayUrl = getStripePaymentGatewayUrl({
 *  origin: 'https://www.example.com',
 *  token: 'userTokenExample',
 *  subscriptionType: 'individual',
 *  successRedirectUrl: 'https://www.example.com/confirmation',
 *  cancelRedirectUrl: 'https://www.example.com/cancel',
 * })
 * ```
 */
export function getStripePaymentGatewayUrl({
  origin,
  token,
  campaign = '',
  duration = 'monthly',
  subscriptionType,
  successRedirectUrl,
  cancelRedirectUrl
}: GetStripePaymentGatewayProps) {
  const params = new URLSearchParams({
    jwt: token,
    campaign,
    duration,
    successRedirectUrl,
    cancelRedirectUrl
  });

  return `${origin}/stripe/create-checkout-session${
    subscriptionType === 'individual' ? '-individual' : ''
  }?${params.toString()}`;
}

/**
 * Redirects to the payment gateway page to pay for a
 * subscription plan for a team or an individual
 *
 * @example ```ts
 * redirectToStripePaymentGateway({
 *  origin: 'https://www.example.com',
 *  token: 'userTokenExample',
 *  subscriptionType: 'individual',
 *  successRedirectUrl: 'https://www.example.com/confirmation',
 *  cancelRedirectUrl: 'https://www.example.com/cancel',
 * })
 * ```
 */
export function redirectToStripePaymentGateway(
  props: GetStripePaymentGatewayProps
): void {
  const paymentGatewayUrl = getStripePaymentGatewayUrl(props);

  if (typeof window !== 'undefined') {
    window.location.replace(paymentGatewayUrl);
  }
}

interface GetStripeCustomerPortalProps {
  origin: string;
  token: string;
  returnUrl: string;
}

export function getStripeCustomerPortalUrl({
  origin,
  token,
  returnUrl
}: GetStripeCustomerPortalProps): string {
  const stripeCustomerPortal = new URL(`${origin}/stripe/portal`);

  stripeCustomerPortal.searchParams.set('jwt', token);
  stripeCustomerPortal.searchParams.set('returnUrl', returnUrl);

  return stripeCustomerPortal.toString();
}
