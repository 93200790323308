import { Text } from '@chakra-ui/react';
import { DarkPanel } from 'components/ui';
import { TrackedLink } from '@tigerhall/components';

export function CommunicationPreferencesSection() {
  return (
    <DarkPanel w="100%" gap="1.5rem">
      <Text fontSize="xl" fontWeight="700" color="white">
        Communication Preferences
      </Text>
      <Text fontSize="md" fontWeight="500" color="darkGrey.50">
        Enable or disable app push notifications or emails.
      </Text>
      <TrackedLink
        name="CHANGE_MY_PREF"
        href="/profile/settings/communication-preferences"
        as="button"
        size="lg"
        maxW="20rem"
      >
        Change My Preference
      </TrackedLink>
    </DarkPanel>
  );
}
