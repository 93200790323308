import { Skeleton, Text, type TextProps } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import {
  TrackedLink,
  ThinkfluencerBadgeWrapper,
  type ThinkfluencerBadgeWrapperProps
} from '@tigerhall/components';
import * as segment from '@tigerhall/analytics';

import { useUserBaseProfile } from '../hooks/useUserBaseProfile';

interface NameWithBadgeProps extends Omit<TextProps, 'children'> {
  /**
   * The user ID to fetch the profile for.
   */
  userId: string;
  /**
   * Whether the name should be a link to the user's profile.
   */
  disableLink?: boolean;
  /**
   * Gap between the name and the thinkfluencer badge.
   */
  spacing?: ThinkfluencerBadgeWrapperProps['spacing'];
  /**
   * The thinkfluencer `scope` is calculated inside this component, that's
   * why it's not exposed as a prop.
   */
  badgeProps?: Omit<
    NonNullable<ThinkfluencerBadgeWrapperProps['badgeProps']>,
    'scope'
  >;
}

/**
 * This is a super-charged `Text` component that displays a user's full name as
 * a plain text or a link to the user's profile. It also adds the `ThinkfluencerBadge`
 * if the user is a Thinkfluencer.
 *
 * @example```tsx
 *   <NameWithBadge
 *     userId="123"
 *     spacing={1}
 *     badgeProps={{
 *       size: 'sm'
 *     }} />
 * ```
 */
export function NameWithBadge({
  userId,
  disableLink,
  spacing,
  badgeProps = {},
  ...props
}: Readonly<NameWithBadgeProps>) {
  const location = useLocation();
  const { profile, thinkfluencerScope } = useUserBaseProfile(userId);

  const thinkfluencerBadgeProps = thinkfluencerScope
    ? ({
        scope: thinkfluencerScope,
        ...badgeProps
      } as const)
    : undefined;

  if (!profile) {
    return <Skeleton width="5rem" height="1rem" />;
  }

  const userProfileUrl = `/profile/${userId}`;
  const fullName = `${profile.firstName || ''} ${profile.lastName || ''}`;

  return (
    <ThinkfluencerBadgeWrapper
      badgeProps={thinkfluencerBadgeProps}
      spacing={spacing}
    >
      <Text {...props}>
        {disableLink ? (
          fullName
        ) : (
          <TrackedLink
            name="USER_PROFILE"
            href={userProfileUrl}
            onClick={() => {
              segment.profileClicked({
                href: userProfileUrl,
                userId,
                profileName: fullName,
                location: location.pathname,
                isExpert: Boolean(thinkfluencerScope)
              });
            }}
          >
            {fullName}
          </TrackedLink>
        )}
      </Text>
    </ThinkfluencerBadgeWrapper>
  );
}
