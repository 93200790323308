import * as React from 'react';
import { Flex, Heading, SimpleGrid, Text } from '@chakra-ui/layout';
import { FormInput, TrackedRTForm } from '@tigerhall/components';
import {
  CategoriesSelect,
  ExpertsSelect,
  OrganisationGroupSelect
} from 'components/form';

import { type GenericFormValues, type GenericSearchFormProps } from './types';

export function GenericSearchForm({
  setFilter,
  forTrail = false,
  formName,
  heading,
  nameLabel,
  isThinkfluencerSearchEnabled = true
}: Readonly<GenericSearchFormProps>) {
  const onUpdate = React.useCallback(
    (values: GenericFormValues) => {
      // TO ONLY PASS FIELDS IF THEY ARE AVAILABLE IN THEIR FILTER
      const filter = {
        name: values.name,
        categories: values.category ? [values.category.id] : [],
        organisationGroups: values.group ? [values.group.id] : [],
        ...(!forTrail && { experts: values.expert ? [values.expert.id] : [] })
      };

      setFilter((state) => ({ ...state, ...filter }));
    },
    [setFilter, forTrail]
  );
  return (
    <>
      <Heading fontSize="2xl" fontWeight="bold" color="white">
        {heading}
      </Heading>
      <Flex
        direction="column"
        border="1px solid"
        borderColor="darkGrey.400"
        p="1rem"
        borderRadius="md"
        gap="1rem"
      >
        <Text color="white" fontSize="lg" fontWeight="bold">
          Search
        </Text>
        <TrackedRTForm name={formName} onSubmit={onUpdate}>
          <SimpleGrid
            columns={!isThinkfluencerSearchEnabled ? 3 : 4}
            gap={!isThinkfluencerSearchEnabled ? 3 : 4}
          >
            <FormInput
              name="name"
              type="text"
              placeholder="Enter content title"
              label={nameLabel ?? 'Search'}
            />
            {isThinkfluencerSearchEnabled ? (
              <ExpertsSelect name="expert" isClearable={true} />
            ) : null}
            <CategoriesSelect name="category" isClearable={true} />
            <OrganisationGroupSelect name="group" />
          </SimpleGrid>
        </TrackedRTForm>
      </Flex>
    </>
  );
}
