import { Flex, Text } from '@chakra-ui/react';
import {
  Ebook,
  Event,
  extractContentTypeFromContent,
  formatContentType,
  Podcast,
  Stream
} from '@tigerhall/core';
import { ContentIconComponent } from '@tigerhall/ui-components';
import { RichTextMarkdownWrapper } from 'components/ui/MarkdownWrapper/RichTextMarkdownWrapper';
import * as React from 'react';

interface RightPanelProps {
  content: Stream | Podcast | Ebook | Event;
}

export function RightPanel({ content }: Readonly<RightPanelProps>) {
  return (
    <>
      <Flex gap="0.5rem">
        <ContentIconComponent type={content?.__typename} />
        <Text fontSize="0.75rem" fontWeight="700" color="white">
          {formatContentType(extractContentTypeFromContent(content))}
        </Text>
      </Flex>
      <RichTextMarkdownWrapper fontSize={14}>
        {content.preamble}
      </RichTextMarkdownWrapper>
    </>
  );
}
