import * as React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import { BackButton } from 'components/ui/Buttons/BackButton';

interface HeaderBoxProps {
  title: string;
  children?: React.ReactNode;
  headingColor?: string;
  fillColor?: string;
}

export function HeaderBox({ title, children, headingColor }: HeaderBoxProps) {
  return (
    <Box pt="2rem" bg="darkGrey.700" w="100%">
      <Flex px="2rem" pb="1.5rem" alignItems="center" cursor="pointer">
        <BackButton size="md" variant="solidDark" iconSpacing={0} p={0}>
          {''}
        </BackButton>
        <Heading
          fontSize="2xl"
          color={headingColor || 'tigerOrange.600'}
          paddingLeft="1.5rem"
        >
          {title}
        </Heading>
      </Flex>
      {children}
    </Box>
  );
}
