import { ApolloError } from '@apollo/client';
import { User } from '@tigerhall/core/lib/types';
import { useGetCurrentUserQuery } from 'generated';

import { Profile } from './components';

const MyProfile = () => {
  const { data, loading, error } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-and-network'
  });
  return (
    <Profile
      user={data?.me as User}
      loading={loading}
      error={error as ApolloError}
      isCurrentUser
    />
  );
};

export default MyProfile;
