import {
  LightMode,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps as CModalProps,
  Text
} from '@chakra-ui/react';
import React from 'react';

import { THButton } from '../buttons';

export type ModalProps = Omit<CModalProps, 'children'> & {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  body?: string | React.ReactNode;
  content?: React.ReactNode;
  primaryActionText?: string;
  primaryActionLoading?: boolean;
  primaryButtonOnClick?: () => Promise<void> | void;
  secondaryActionText?: string;
  secondaryButtonOnClick?: () => void;
  contentBoxProps?: Record<string, unknown>;
  dataCy?: string;
};

/**
 * @deprecated: Don't use this component it's messy!
 *
 * @param props
 * @constructor
 */
export default function ModalComponent(props: ModalProps) {
  const {
    isOpen,
    onClose,
    title,
    body,
    content,
    primaryActionText,
    primaryButtonOnClick,
    secondaryActionText = 'Cancel',
    secondaryButtonOnClick = onClose,
    primaryActionLoading,
    size
  } = props;
  return (
    <LightMode>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick
        size={size}
      >
        <ModalOverlay />
        <ModalContent>
          {content || (
            <>
              <ModalHeader>{title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {typeof body === 'string' ? (
                  <Text fontSize={16} color="black" textAlign="center">
                    {body}
                  </Text>
                ) : (
                  body
                )}
              </ModalBody>

              <ModalFooter gap="1rem">
                <THButton
                  onClick={secondaryButtonOnClick}
                  variant="outlineDark"
                  size="lg"
                  name={secondaryActionText}
                  flex={1}
                >
                  {secondaryActionText}
                </THButton>
                <THButton
                  isLoading={primaryActionLoading}
                  variant="solidDark"
                  onClick={primaryButtonOnClick}
                  size="lg"
                  flex={1}
                >
                  {primaryActionText}
                </THButton>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </LightMode>
  );
}
