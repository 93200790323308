import { useCallback } from 'react';

import {
  BlockThinkfluencerModal,
  BlockUserModal,
  UnblockUserModal
} from './components';
import { useBlockUnblockUser } from '~/hooks';

interface BlockUnblockUserModalProps {
  userId: string;
  isThinkfluencer: boolean;
  isBlockedByCurrentUser: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export function BlockUnblockUserModal({
  userId,
  isThinkfluencer,
  isBlockedByCurrentUser,
  isOpen,
  onClose
}: Readonly<BlockUnblockUserModalProps>) {
  const { blockUser, unblockUser, loading } = useBlockUnblockUser();

  const onBlockUser = useCallback(async () => {
    await blockUser(userId);
    onClose();
  }, [blockUser, userId]);

  const onUnblockUser = useCallback(async () => {
    await unblockUser(userId);
    onClose();
  }, [unblockUser, userId]);

  if (!userId) return null;

  if (isThinkfluencer)
    return <BlockThinkfluencerModal isOpen={isOpen} onClose={onClose} />;

  return isBlockedByCurrentUser ? (
    <UnblockUserModal
      isOpen={isOpen}
      onClose={onClose}
      onClick={onUnblockUser}
      isLoading={loading}
    />
  ) : (
    <BlockUserModal
      isOpen={isOpen}
      onClose={onClose}
      onClick={onBlockUser}
      isLoading={loading}
    />
  );
}
