import { useMemo } from 'react';
import {
  type FormLabelProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Text,
  Tooltip,
  useColorMode
} from '@chakra-ui/react';
import { IconHelpCircle } from '@tigerhall/icons';
import * as segment from '@tigerhall/analytics';
import {
  type MultiValue,
  type SingleValue,
  Select as ReactSelect
} from 'chakra-react-select';
import {
  type UseControllerProps,
  Controller,
  UseFormReturn,
  useFormContext
} from 'react-hook-form';

import { getSelectChakraStyles, scrollbarStylesForSelect } from './theme';

export interface FormSelectProps<T extends object> {
  /**
   * The name of the input
   */
  name: string;

  /**
   * The label of the field
   */
  label: string;

  /**
   * Show a help icon with a tooltip
   */
  tooltip?: string;

  /**
   * Hide the label
   * @default false
   */
  hideLabel?: boolean;

  /**
   * Placeholder text
   */
  placeholder: string;

  /**
   * If the component is disabled
   */
  disabled?: boolean;

  /**
   * If multiple options can be selected
   */
  isMulti?: boolean;

  /**
   * If the selected option can be cleared
   */
  isClearable?: boolean;

  /**
   * react-hook-forms for rules
   */
  rules?: UseControllerProps['rules'];

  /**
   * The options to select from
   */
  options: T[];

  getOptionValue?: (o: T) => string;
  getOptionLabel?: (o: T) => string;

  /**
   * Callback which will be called when option is selected
   * @param option The selected option
   */
  onSelectionChange?: (option: MultiValue<T> | SingleValue<T>) => void;

  hideSelectedOptions?: boolean;
  analyticsDisabled?: boolean;
}

export function FormSelect<
  T extends {
    label?: string;
    value?: unknown;
    [key: string]: unknown;
  }
>({
  name,
  label,
  tooltip = undefined,
  placeholder,
  options,
  rules = {},
  isMulti = false,
  isClearable = false,
  disabled = false,
  getOptionValue,
  getOptionLabel,
  onSelectionChange,
  hideSelectedOptions = false,
  analyticsDisabled = false,
  hideLabel = false
}: FormSelectProps<T>) {
  const { colorMode } = useColorMode();

  const chakraStyles = useMemo(
    () => getSelectChakraStyles<T>(colorMode),
    [colorMode]
  );

  const methods = useFormContext() as UseFormReturn & {
    formName: string;
  };

  const ariaLabelForInput: FormLabelProps = {
    ...(hideLabel && {
      'aria-label': label,
      'aria-labelledby': undefined
    })
  };

  if (methods === null) {
    return <Text color="state.error">Missing form context</Text>;
  }

  if (!methods.formName && !analyticsDisabled) {
    return <Text color="state.error">Missing form name</Text>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      defaultValue={isMulti ? [] : null}
      render={({ field, fieldState }) => (
        <FormControl
          isInvalid={!!fieldState.error}
          isRequired={!!rules?.required}
          isDisabled={disabled}
          css={scrollbarStylesForSelect}
        >
          {!hideLabel && (
            <FormLabel
              {...ariaLabelForInput}
              display="flex"
              alignItems="center"
            >
              {label}

              {tooltip ? (
                <Tooltip label={tooltip} placement="top">
                  <Icon as={IconHelpCircle} w="1rem" h="1rem" pl="0.125rem" />
                </Tooltip>
              ) : null}
            </FormLabel>
          )}
          <ReactSelect
            name={field.name}
            placeholder={placeholder}
            onChange={(val) => {
              segment.dropdownSelectionClicked({
                dropdownName: name,
                // selection is `string` so we can't use `getOptionValue` because
                // `getOptionValue` can be `string | number | boolean` etc.
                selection:
                  isMulti && Array.isArray(val)
                    ? getOptionLabel?.(
                        (val as MultiValue<T>)[val.length - 1]
                      ) || (val as MultiValue<T>)[val.length - 1]?.label
                    : getOptionLabel?.(val as SingleValue<T> as T) ||
                      (val as SingleValue<T> as T)?.label,
                location: window.location.pathname
              });

              field.onChange(val);
              onSelectionChange?.(val);
            }}
            onBlur={field.onBlur}
            value={field.value}
            ref={field.ref}
            data-cy={`${methods.formName}-${name}`}
            options={options}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            chakraStyles={chakraStyles}
            isMulti={isMulti}
            isClearable={isClearable}
            hideSelectedOptions={hideSelectedOptions}
          />
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}
