import { Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { TrackedLink } from '@tigerhall/components';
import { IconBrandTigerhallVertical } from '@tigerhall/icons';
import {
  getOrgIdFromToken,
  getSelectedOrg,
  hasOrgAdminAccess
} from 'app/state';
import { useAppSelector } from 'hooks';
import * as React from 'react';
import * as segment from '@tigerhall/analytics';

const SUBJECT = 'Tigerhall Access';
const MESSAGE =
  'Hello,%0D%0A %0D%0AAfter attempting to login to my Tigerhall account, I was informed that my account has expired, and to contact the account administrator for more information.%0D%0A %0D%0ACould you please assist me with this issue?';

const WEB_BOOK_MEETING_MODAL_URL = `${
  import.meta.env.VITE_WEB_URL
}/?modal=book-meeting`;

const EMAIL_URL = `mailto:?subject=${SUBJECT}&body=${MESSAGE}`;

const ADMIN_PROPS = {
  subHeading:
    'Please contact our Customer Success team for more information on your subscription.',
  buttonLabel: 'Book a Meeting',
  redirectionUrl: WEB_BOOK_MEETING_MODAL_URL,
  target: '_self'
};

const ORG_USER_PROPS = {
  subHeading:
    'Please contact your administrator for more information on your subscription.',
  buttonLabel: 'Email Administrator',
  redirectionUrl: EMAIL_URL,
  target: '_blank'
};

export function SubscriptionExpiredOverlay() {
  const isOrgAdmin = useAppSelector(hasOrgAdminAccess);
  const orgId = useAppSelector(getOrgIdFromToken);
  const org = useAppSelector(getSelectedOrg);

  const messagingProps = isOrgAdmin ? ADMIN_PROPS : ORG_USER_PROPS;

  React.useEffect(() => {
    if (orgId) {
      segment.enterpriseOverlay({
        organisationId: orgId,
        organisationName: org?.name ?? '',
        isAdmin: isOrgAdmin
      });
    }
  }, [orgId, isOrgAdmin, org?.name]);

  return (
    <Flex
      minH="100vh"
      position="relative"
      px="1.5rem"
      justifyContent="center"
      alignItems="center"
      direction="column"
      textAlign="center"
      bgGradient="radial-gradient(51.67% 46.08% at 100% 0%, rgba(255, 86, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), radial-gradient(48.76% 53.95% at 0% 100%, rgba(255, 86, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #000"
    >
      <Icon
        as={IconBrandTigerhallVertical}
        width="5.625rem"
        pos="absolute"
        top="5rem"
        height="3.125rem"
        color="tigerOrange.600"
      />
      <Heading
        color="white"
        mb="0.5rem"
        fontSize={{
          base: 'xl',
          md: '3xl'
        }}
        as="h2"
      >
        Your account has expired
      </Heading>
      <Text
        fontWeight="normal"
        fontSize={{
          base: 'sm',
          md: 'xl'
        }}
        color="lightGrey.600"
        mb="2.5rem"
      >
        {messagingProps.subHeading}
      </Text>

      <TrackedLink
        as="button"
        href={messagingProps.redirectionUrl}
        size="lg"
        name="RESUBSCRIBE_INITIALIZED"
        target={messagingProps.target}
      >
        {messagingProps.buttonLabel}
      </TrackedLink>
    </Flex>
  );
}
