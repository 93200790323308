import { PermissionIdentifier } from '@tigerhall/core';

import { useGetCurrentUserAccessLevelQuery } from '../generated';

export function useAccessLevels() {
  const { data } = useGetCurrentUserAccessLevelQuery({
    fetchPolicy: 'cache-first'
  });

  const permissions = data?.me?.permissions ?? [];

  const accessLevels = permissions
    .filter((ele) => ele?.isAllowed === true)
    .map((ele) => ele?.permission?.identifier) as PermissionIdentifier[];

  const hasAccess = (level: PermissionIdentifier) => {
    return accessLevels.includes(level);
  };

  return { hasAccess, accessLevels };
}
