import {
  Avatar,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  Text,
  useToast
} from '@chakra-ui/react';
import { createBlobURL, logger, resizeImage } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import * as React from 'react';
import {
  Controller,
  type UseControllerProps,
  type UseFormReturn,
  useController,
  useFormContext
} from 'react-hook-form';

import { ImageCropTool } from './children';

type FormImageUploadProps = {
  /**
   * Name in the react-form-hook to map to
   */
  name: string;

  /**
   * Label showed to push the update to complete the upload action
   */
  label: string;

  /**
   * Optional label hint in a smaller gray font
   */
  hint?: string;

  /**
   * Upload callback used to upload the image to the backend
   *
   * @param file
   */
  upload: (file: File) => Promise<{ id: string; uri: string }>;

  /**
   * When cropSize is passed we trigger a cropping modal once the user selects an image
   *
   * @deprecated: don't use this for now, the designs need to be updated
   */
  cropSize?: {
    height: number;
    width: number;
  };

  /**
   * If the reset button should be visible, this allows them to set the value to null
   */
  showResetButton?: boolean;

  /**
   * Image uploading is currently disabled
   */
  disabled?: boolean;

  /**
   * react-hook-form validation rules
   */
  rules?: UseControllerProps['rules'];

  /**
   * If the image should be used as an avatar
   * We make the image rounded and limit the size to 200px
   */
  avatar?: boolean;
};

export function FormImageUpload({
  name,
  label,
  upload,
  disabled,
  rules = {},
  hint = '(Supported formats: .png .jpeg .jpg)',
  cropSize = undefined,
  showResetButton = true,
  avatar = false
}: FormImageUploadProps) {
  const [cropImageUrl, setCropImageUrl] = React.useState<string | null>(null);
  const [isUploading, setUploading] = React.useState(false);
  const [isCropModalOpen, setCropModalOpen] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const {
    field: { value, onBlur, onChange },
    fieldState: { error }
  } = useController({ name });

  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  });

  const imageRef = React.useRef({
    name,
    value,
    currentImageName: null,
    type: 'image'
  });

  const onImageUpload = React.useCallback(
    (file: File) => {
      if (!file) {
        return;
      }

      segment.fileUploadSelected({
        fileSize: file.size,
        fileName: file.name,
        fileType: file.type,
        formName: name,
        fieldName: label
      });

      setUploading(true);

      upload(file)
        .then((response) => {
          onChange({
            id: response?.id,
            uri: resizeImage({
              url: response?.uri,
              width: 250
            })
          });
        })
        .catch((err) => {
          logger.error(err);
          toast({
            title: 'Error Ocurred.',
            description: 'Only .jpg and .png format images are allowed',
            status: 'error'
          });
        })
        .finally(() => {
          setUploading(false);
        });
    },
    [name, label, upload, onChange, toast]
  );

  const closeModal = React.useCallback(() => {
    setCropModalOpen(false);
    setCropImageUrl(null);
  }, [setCropModalOpen, setCropImageUrl]);

  const onCropApply = React.useCallback(
    (url: string, blob: Blob | null) => {
      if (!blob) {
        return;
      }

      const file = new File(
        [blob],
        imageRef?.current?.currentImageName ?? 'image.jpg',
        { type: 'image/jpg' }
      );

      onImageUpload(file);
      closeModal();
    },
    [closeModal, onImageUpload]
  );

  const onImageChange = React.useCallback(
    (event) => {
      const [file] = event.target.files;

      if (cropSize) {
        if (imageRef?.current) {
          imageRef.current.currentImageName = file.name;
        }

        setCropImageUrl(createBlobURL(file));
        setCropModalOpen(true);
      } else {
        onImageUpload(file);
      }

      // handling case if user set same image again

      event.target.value = null;
    },
    [setCropModalOpen, imageRef, cropSize, onImageUpload]
  );

  const methods = useFormContext() as UseFormReturn & {
    formName: string;
  };

  if (methods === null) {
    return <Text color="state.error">Missing form context</Text>;
  }

  if (!methods.formName) {
    console.warn('Missing form name for element name ' + name);
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      render={({ fieldState }) => (
        <FormControl
          isInvalid={!!error}
          isRequired={!!rules?.required}
          isDisabled={disabled}
        >
          <FormLabel>{label}</FormLabel>
          <input
            type="file"
            ref={inputRef}
            style={{ display: 'none' }}
            name={name}
            onChange={onImageChange}
            onBlur={onBlur}
            accept="image/*"
            data-cy="image-upload-input"
          />
          <Flex
            direction="column"
            backgroundColor="darkGrey.400"
            p="1rem"
            border="1px solid"
            borderColor="darkGrey.200"
            borderRadius="lg"
            width={avatar ? 'fit-content' : '100%'}
          >
            {hint ? (
              <Text
                fontSize="0.875rem"
                color="lightGrey.400"
                fontWeight="500"
                mb="1rem"
              >
                {hint}
              </Text>
            ) : null}

            {avatar ? (
              <Avatar
                src={resizeImage({
                  url: value?.uri || '',
                  width: cropSize?.width || 200,
                  height: cropSize?.height
                })}
                backgroundColor="darkGrey.50"
                width={cropSize?.width || 200}
                height={cropSize?.height || 200}
                mt={2}
              />
            ) : (
              <Image
                // todo: setting a default resize op to 200px if cropSize not specified
                // will cause images to be somewhat grainy if used in larger images
                src={resizeImage({
                  url: value?.uri || '',
                  width: cropSize?.width || 200,
                  height: cropSize?.height
                })}
                // todo: add the proper image
                // backgroundImage={<CalendarIcon />}
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
                backgroundColor="darkGrey.50"
                minHeight={200}
                minWidth={200}
                width="100%"
                border={fieldState?.error ? '1px solid' : 0}
                borderColor="state.error"
                outline={0}
                mt={2}
                overflow="hidden"
              />
            )}

            {cropImageUrl ? (
              <Modal
                onClose={closeModal}
                isOpen={isCropModalOpen}
                isCentered
                size="736px"
              >
                <ModalCloseButton />
                <ModalContent backgroundColor="black" width="auto">
                  <ImageCropTool
                    image={cropImageUrl}
                    onApply={onCropApply}
                    cropSize={
                      cropSize as {
                        width: number;
                        height: number;
                      }
                    }
                  />
                </ModalContent>
              </Modal>
            ) : null}
            <HStack mt={4} spacing={3}>
              <Button
                colorScheme="white"
                size="md"
                onClick={() => inputRef.current?.click()}
                isLoading={isUploading}
              >
                Upload Image
              </Button>
              {showResetButton ? (
                <Button
                  variant="outline"
                  size="md"
                  onClick={() => {
                    onChange(name, null);
                  }}
                >
                  Clear
                </Button>
              ) : null}
            </HStack>
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </Flex>
        </FormControl>
      )}
    />
  );
}
