import * as React from 'react';
import { Box, Flex, Heading, VStack } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import filter from 'lodash/filter';
import map from 'lodash/map';
import {
  NotificationPlatform,
  NotificationPreferenceState,
  UserNotificationPreference
} from '@tigerhall/core/lib/types';
import {
  useGetCurrentUserQuery,
  useGetNotificationPreferencesQuery,
  useToggleNotificationPreferenceMutation
} from 'generated';
import * as segment from '@tigerhall/analytics';
import { BackButton } from 'components/ui/Buttons';

import { Section } from './components/Section';
import { PreferenceItem } from './components/PreferenceItem';

export function CommunicationPreferences() {
  const { data, loading, refetch } = useGetNotificationPreferencesQuery();
  const { data: user } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first'
  });

  const notificationPreferences = data?.me?.notificationPreferences;

  const pushNotificationPreferences = filter(
    notificationPreferences,
    (pref) => pref.platform === NotificationPlatform?.PushNotification
  );

  const emailNotificationPreferences = filter(
    notificationPreferences,
    (pref) => pref.platform === NotificationPlatform?.Email
  );

  const [toggleNotificationPreference] =
    useToggleNotificationPreferenceMutation();

  if (loading) {
    return null;
  }

  const onToggleNotification = async (
    pref: Omit<UserNotificationPreference, '__typename'>,
    isChecked: boolean
  ) => {
    segment.toggleClicked({
      toggleName: pref.name,
      toggleSelection: isChecked
        ? NotificationPreferenceState.Disabled
        : NotificationPreferenceState.Enabled
    });

    await toggleNotificationPreference({
      variables: {
        userID: user?.me?.id,
        type: pref.type
      }
    });
    await refetch();
  };

  return (
    <>
      <Helmet>
        <title>Communication Preferences</title>
      </Helmet>
      <VStack w="100%" py="2.5rem" spacing="2rem">
        <Box ml="4.5rem" w="fit-content" alignSelf="flex-start">
          <BackButton />
        </Box>
        <Flex alignSelf="center" w="42.5rem" direction="column">
          <Heading
            variant="as"
            fontSize="1.75rem"
            fontWeight="700"
            color="tigerOrange.600"
          >
            Communication Preferences
          </Heading>
          <Section title="Push notification settings">
            <Box>
              {map(pushNotificationPreferences, (pref) => (
                <PreferenceItem
                  preference={pref}
                  isChecked={
                    pref.state === NotificationPreferenceState?.Enabled
                  }
                  onToggleNotification={onToggleNotification}
                />
              ))}
            </Box>
          </Section>
          <Section title="Email notification settings">
            <Box>
              {map(emailNotificationPreferences, (pref) => (
                <PreferenceItem
                  preference={pref}
                  isChecked={!!NotificationPreferenceState?.Enabled}
                  onToggleNotification={onToggleNotification}
                />
              ))}
            </Box>
          </Section>
        </Flex>
      </VStack>
    </>
  );
}
