import {
  Modal,
  ModalBody,
  ModalContent,
  type StyleProps
} from '@chakra-ui/react';
import type { ReactNode } from 'react';

export interface PlayerModalProps {
  /**
   * If the player should be visible at all
   */
  isOpen: boolean;
  /**
   * If the player should be hidden without being unmounted.
   *
   * @default false
   */
  isHidden?: boolean;
  /**
   * If `false`, the modal will be full screen.
   * If `true`, the modal height will be auto to fit the minimized player.
   * This means that the minimized version of the players should be handled by the player itself.
   *
   * @default false
   */
  isMinimized?: boolean;
  /**
   * Function to be called when the modal is "closed".
   * Usually used to set `isMinimized` to true.
   */
  onMinimize: () => void;
  children: ReactNode;
  contentHeight?: StyleProps['minH'];
}

/**
 * Uncontrolled player modal.
 * Intended to be used as a container for the different content players and playlists.
 *
 * @example```tsx
 * <PlayerModal
 *  isOpen={isOpen}
 *  isHidden={isHidden}
 *  isMinimized={isMinimized}
 *  onMinimize={() => {
 *    setIsMinimized(true);
 *  }}
 * >
 *  <Podcast {...podcastProps} />
 * </PlayerModal>
 * ```
 */
export function PlayerModal({
  isOpen,
  isHidden = false,
  isMinimized = false,
  onMinimize = () => {},
  children,
  contentHeight
}: Readonly<PlayerModalProps>) {
  const handleClose = () => {
    onMinimize();
  };

  return (
    <Modal
      isOpen={isOpen}
      size={'full'}
      motionPreset="scale"
      onClose={handleClose}
      autoFocus={false}
      trapFocus={!isMinimized}
      useInert={!isMinimized}
      blockScrollOnMount={!isMinimized}
      preserveScrollBarGap
      scrollBehavior={'inside'}
      closeOnOverlayClick={false}
    >
      <ModalContent
        /** keeping `maxH: 100vh` hides the bottom sheet on mobile browser */
        minH={isMinimized ? 'auto' : contentHeight || '100%'}
        display={isHidden ? 'none' : 'flex'}
        backgroundColor="lightGrey.200"
        containerProps={
          isMinimized
            ? {
                height: 'auto',
                overflow: 'unset'
              }
            : {
                overflow: 'hidden'
              }
        }
      >
        <ModalBody display="flex" p={0}>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
