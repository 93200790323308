import { type RouteObject } from 'react-router-dom';
import { Splash } from 'components/ui/Layout/components';
import { Suspense, lazy } from 'react';

import {
  ContentCompleted,
  EditProfile,
  Followers,
  MyProfile,
  OtherProfile,
  Settings
} from './screens';
import {
  BlockedUsers,
  CommunicationPreferences,
  UserTopics
} from './screens/Settings/screens';

const Language = lazy(() =>
  import('./screens/Settings/screens/Language').then((module) => ({
    default: module.Language
  }))
);

const routes: RouteObject[] = [
  {
    path: '/profile/',
    children: [
      {
        index: true,
        element: <MyProfile />
      },
      {
        path: 'content-completed',
        element: <ContentCompleted />
      },
      {
        path: 'edit',
        element: <EditProfile />
      },
      {
        path: 'settings/',
        children: [
          {
            index: true,
            element: <Settings />
          },
          {
            path: 'topics',
            element: <UserTopics />
          },
          {
            path: 'language',
            element: (
              <Suspense fallback={<Splash />}>
                <Language />
              </Suspense>
            )
          },

          {
            path: 'communication-preferences',
            element: <CommunicationPreferences />
          },
          {
            path: 'blocked-users',
            element: <BlockedUsers />
          }
        ]
      },
      {
        path: ':profileId/',
        children: [
          {
            index: true,
            element: <OtherProfile />
          },
          {
            path: 'follower-following',
            element: <Followers />
          }
        ]
      }
    ]
  }
];

export default routes;
