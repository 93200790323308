import { Button, type ButtonProps, Icon, Text } from '@chakra-ui/react';
import { IconCircleCheckFilled } from '@tigerhall/icons';

interface SelectOptionProps {
  onClick: ButtonProps['onClick'];
  optionName: string;
  isSelected: boolean;
}

export function SelectOption({
  onClick,
  optionName,
  isSelected
}: Readonly<SelectOptionProps>) {
  return (
    <Button
      justifyContent="space-between"
      display="flex"
      variant="unstyled"
      alignItems="center"
      borderRadius="none"
      borderBottomWidth="1px"
      width="100%"
      borderBottomColor="lightGrey.400"
      minH="2.75rem"
      padding="1rem 0.875rem"
      onClick={onClick}
      background={isSelected ? 'lightGrey.300' : ''}
      aria-selected={isSelected}
      role="option"
    >
      <Text fontSize="md" fontWeight="medium" color="darkGrey.400" as="span">
        {optionName}
      </Text>

      {isSelected ? (
        <Icon
          aria-selected={true}
          as={IconCircleCheckFilled}
          w="1rem"
          h="1rem"
          color="green.500"
        />
      ) : null}
    </Button>
  );
}
