import { Progress as r } from "@chakra-ui/react";
const s = {
  // style object for base or default style
  baseStyle: {
    width: "100%",
    background: "lightGrey.600"
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    xs: {
      height: "0.125em"
    }
  },
  // styles for different visual variants ("outline", "solid")
  variants: {},
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    ...r.defaultProps,
    colorScheme: "tigerOrange"
  }
};
export {
  s as Progress
};
