import { Navigate, RouteObject } from 'react-router-dom';
import { SubscriptionPlan } from 'modules/authentication/screens';
import { useAppSelector } from 'hooks';
import { getAccessToken } from 'app/state';

import { TeamSignUp } from './screens/Team';

export const redirectToTeamSignup = (): string => '/signup/team';
export const redirectToMembersRoute = (): string => '/signup/team/add-users';
export const redirectToSuccessRoute = (): string => '/signup/team/confirmation';

function RouteGuard({ children }) {
  const token = useAppSelector(getAccessToken);
  if (token && token !== null) {
    return <Navigate to="/home" replace />;
  }
  return children;
}

export const routes: RouteObject[] = [
  {
    path: '/signup',
    children: [
      {
        index: true,
        element: (
          <RouteGuard>
            <SubscriptionPlan />
          </RouteGuard>
        )
      },
      {
        // redirect to new route for teams
        path: '/signup/details',
        element: <Navigate to="/signup/team" replace />
      },
      {
        // redirect to new route for teams
        path: '/signup/add-users',
        element: <Navigate to="/signup/team/add-users" replace />
      },
      {
        // redirect to new route for teams
        path: '/signup/success-page',
        element: <Navigate to="/signup/team/confirmation" replace />
      },
      {
        path: '/signup/team',
        children: [
          {
            index: true,
            element: <TeamSignUp step={1} />
          },
          {
            path: '/signup/team/add-users',
            element: <TeamSignUp step={2} />
          },
          {
            path: '/signup/team/confirmation',
            element: <TeamSignUp step={3} />
          }
        ]
      }
    ]
  }
];
