const r = {
  // style object for base or default style
  baseStyle: {
    fontWeight: "bold",
    boxShadow: "lg",
    letterSpacing: "0.015em",
    borderRadius: "full"
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    sm: {
      px: "0.75rem",
      height: "1.75rem",
      fontSize: "xs"
    },
    md: {
      px: "1rem",
      height: "2.5rem",
      fontSize: "sm"
    },
    lg: {
      px: "1.25rem",
      height: "3rem",
      fontSize: "md"
    }
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    unstyled: {
      background: "transparent",
      boxShadow: "none",
      _hover: {
        background: "blackAlpha.100"
      },
      _active: {
        background: "blackAlpha.100"
      }
    },
    ghost: { boxShadow: "none" },
    inline: {
      background: "transparent",
      boxShadow: "none",
      color: "tigerOrange.500",
      fontWeight: "500",
      fontSize: "sm",
      px: "0.5rem",
      _hover: {
        background: "transparent",
        textDecoration: "underline"
      },
      _active: {
        background: "transparent"
      }
    },
    solid: {
      color: "darkGrey.400",
      background: "white",
      _hover: { background: "lightGrey.400", color: "black" },
      _active: { background: "lightGrey.200", color: "black" }
    },
    // primaryPill
    solidDark: {
      color: "white",
      background: "darkGrey.400",
      _hover: {
        background: "black",
        _disabled: {
          background: "darkGrey.400"
        }
      },
      _active: { background: "black" }
    },
    // primaryPillDark
    outline: {
      color: "white",
      background: "transparent",
      border: "1px solid",
      borderColor: "white",
      _hover: {
        background: "whiteAlpha.200",
        border: "1px solid",
        borderColor: "kindaYellow.50",
        color: "kindaYellow.50"
      },
      _active: {
        background: "whiteAlpha.100",
        border: "1px solid",
        borderColor: "kindaYellow.50",
        color: "kindaYellow.50"
      }
    },
    // primaryPillOutline
    outlineDark: {
      color: "darkGrey.400",
      background: "transparent",
      border: "1px solid",
      borderColor: "darkGrey.400",
      _hover: {
        background: "blackAlpha.100",
        border: "1px solid",
        borderColor: "black",
        color: "black"
      },
      _active: {
        background: "blackAlpha.50",
        border: "1px solid",
        borderColor: "black",
        color: "black"
      }
    },
    // primaryPillOutlineDark
    secondary: {
      fontWeight: "medium",
      color: "lightGrey.900",
      background: "black",
      _hover: { background: "darkGrey.400" },
      _active: { background: "darkGrey.300" }
    },
    // secondaryPill
    secondaryGrey: {
      fontWeight: "medium",
      color: "lightGrey.900",
      background: "darkGrey.700",
      _hover: { background: "darkGrey.400" },
      _active: { background: "darkGrey.300" }
    }
    // secondaryPillGrey
  },
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {}
};
export {
  r as Button
};
