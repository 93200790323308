import { ErrorBoundary } from 'components/tools/ErrorBoundary';
import { Splash } from 'components/ui/Layout/components';
import { lazy, Suspense } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

import { CreatePostModal } from './screens/Feed/components/CreatePostModal';
import { PostModal } from './screens/Feed/components/PostModal';
import { ReactionsModal } from './screens/Feed/components/ReactionsModal';
import { RepostsModal } from './screens/Feed/components/RepostsModal';

const Feed = lazy(() =>
  import('./screens/Feed').then((module) => ({
    default: module.Feed
  }))
);

export const routes: RouteObject[] = [
  {
    path: '/feed/',
    element: (
      <ErrorBoundary>
        <Suspense fallback={<Splash />}>
          <Feed />
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    ),
    children: [
      {
        path: 'create/',
        element: <CreatePostModal isCentered />
      },
      {
        path: ':postId/',
        element: <PostModal />
      },
      {
        path: ':postId/reposts',
        element: <RepostsModal />
      },
      {
        path: ':postId/reactions',
        element: <ReactionsModal />
      },
      {
        path: ':postId/comment/:commentId',
        element: <PostModal />
      }
    ]
  }
];
