import { Flex } from '@chakra-ui/react';
import * as segment from '@tigerhall/analytics';
import includes from 'lodash/includes';
import map from 'lodash/map';
import remove from 'lodash/remove';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import TopicCloudItem, { Topic } from './TopicCloudItem';

type TopicCloudProps = {
  topics: Topic[];
  bgColor?: string;
  align?: 'start' | 'end';
  selectedIDs?: string[];
  mode?: 'view' | 'edit';

  /**
   * When the mode is set to edit this will be called when the values change
   *
   * @param ids
   */
  onChange?: (ids: string[]) => void;

  /**
   * When the mode is set to view this will be called with the topic select
   *
   * @param id
   */
  onClick?: (id: string) => void;
};

const TopicCloud = ({
  topics,
  onChange,
  onClick,
  bgColor,
  align,
  selectedIDs,
  mode = 'edit'
}: TopicCloudProps) => {
  const [selectedTopicIds, setSelectedTopicIds] = useState<any>(
    selectedIDs || []
  );

  const { pathname } = useLocation();

  useEffect(() => {
    if (mode === 'edit') {
      onChange?.(selectedTopicIds);
    }
  }, [mode, onChange, selectedTopicIds]);

  const onTopicClick = (id: string, topic: Topic) => {
    if (mode === 'edit') {
      if (includes(selectedTopicIds, id)) {
        segment.topicToggleClicked({
          category: topic?.name,
          categoryId: id,
          isSelected: false,
          location: pathname
        });

        setSelectedTopicIds(remove(selectedTopicIds, (i) => i !== id));
      } else {
        segment.topicToggleClicked({
          category: topic?.name,
          categoryId: id,
          isSelected: true,
          location: pathname
        });

        setSelectedTopicIds([...selectedTopicIds, id]);
      }
    } else {
      onClick?.(id);
    }
  };

  return (
    <Flex
      bgColor={bgColor || 'orange.200'}
      w="100%"
      flexWrap="wrap"
      justifyContent={align ? `flex-${align}` : 'center'}
    >
      {map(topics, (topic) => (
        <TopicCloudItem
          isSelected={includes(selectedTopicIds, topic?.id)}
          item={topic}
          key={topic.id}
          onClick={(id) => onTopicClick(id, topic)}
        />
      ))}
    </Flex>
  );
};

export { TopicCloud };
