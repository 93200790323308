import { Flex, Icon, IconButton, Spinner, Tooltip } from '@chakra-ui/react';
import {
  IconRewindForward15,
  IconPlayerSkipForwardFilled,
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
  IconPlayerSkipBackFilled,
  IconRewindBackward15
} from '@tigerhall/icons';
import { MouseEvent } from 'react';

import { useMediaPlayerContext, useMediaState } from '~/player/context';

export function PlayPauseButton({
  size,
  shouldBeOrange
}: {
  size: 'sm' | 'md' | 'lg' | 'xl';
  shouldBeOrange: boolean;
}) {
  const { togglePlay } = useMediaPlayerContext();

  const [isPlaying, isLoading] = useMediaState((state) => [
    state.isPlaying,
    state.isLoading
  ]);

  let outer = '2rem';
  let inner = '1rem';

  switch (size) {
    case 'sm':
      outer = '2rem';
      inner = '1rem';
      break;
    case 'md':
      outer = '3rem';
      inner = '1.25rem';
      break;
    case 'lg':
      outer = '3.5rem';
      inner = '1.5rem';
      break;
    case 'xl':
      outer = '4.5rem';
      inner = '1.75rem';
      break;

    default:
      break;
  }

  const orangeButtonProps = {
    background: 'tigerOrange.600',
    _hover: {
      background: 'tigerOrange.500'
    },
    _active: {
      background: 'tigerOrange.500'
    }
  };

  if (isLoading) {
    return (
      <Spinner
        width={outer}
        height={outer}
        color="tigerOrange.600"
        thickness="2px"
      />
    );
  }

  return (
    <IconButton
      {...(shouldBeOrange ? orangeButtonProps : {})}
      aria-label={isPlaying ? 'pause' : 'play'}
      onClick={(event: MouseEvent<HTMLButtonElement>) => {
        // stop propagation so that we don't open the maximized player
        event.stopPropagation();

        togglePlay();
      }}
      w={outer}
      minW={outer}
      h={outer}
      minH={outer}
      icon={
        <Icon
          as={isPlaying ? IconPlayerPauseFilled : IconPlayerPlayFilled}
          w={inner}
          h={inner}
          color="black"
        />
      }
    />
  );
}

export interface PlayerControlsProps {
  size: 'sm' | 'md' | 'lg' | 'xl';
  hideTrackControls?: boolean;
  hideSeekControls?: boolean;
  isMiniPlayer?: boolean;
}

/**
 *
 * `openDelay={300}` is passed to all the tooltip because
 *  PlayerControls is used in Overlay Component to known the
 *  exact reason check here `ui/components/src/player/components/Overlay/Overlay.tsx`
 */

export function PlayerControls({
  size,
  hideTrackControls = false,
  hideSeekControls = false,
  isMiniPlayer = false
}: PlayerControlsProps) {
  const { seekDelta, onNextTrack, onPrevTrack } = useMediaPlayerContext();

  const isSmall = size === 'sm';

  const buttonsBaseGap = hideTrackControls ? '1.5rem' : '1rem';
  const buttonsGap = isSmall
    ? '1rem'
    : {
        base: buttonsBaseGap,
        sm: '1.75rem'
      };

  const miscButtonBaseDisplay = isSmall ? 'none' : 'block';
  const miscButtonSize = isSmall ? '1.5rem' : '2rem';

  return (
    <Flex
      data-testid="player-controls"
      width={'100%'}
      gap={buttonsGap}
      alignSelf="center"
      alignItems="center"
      justifyContent={{
        base: hideTrackControls ? 'center' : 'space-between',
        sm: 'center'
      }}
    >
      {!hideSeekControls && (
        <Tooltip label="Rewind 15 seconds" hasArrow openDelay={300}>
          <IconButton
            aria-label="rewind"
            variant="unstyled"
            minWidth={miscButtonSize}
            height={miscButtonSize}
            display={{ base: miscButtonBaseDisplay, lg: 'block' }}
            icon={
              <Icon
                as={IconRewindBackward15}
                w={miscButtonSize}
                h={miscButtonSize}
                color="white"
              />
            }
            onClick={() => seekDelta(-15)}
          />
        </Tooltip>
      )}

      {!hideTrackControls && (
        <Tooltip label="Previous track" hasArrow openDelay={300}>
          <IconButton
            aria-label="previous track"
            variant="unstyled"
            minWidth={miscButtonSize}
            height={miscButtonSize}
            display={{ base: miscButtonBaseDisplay, lg: 'block' }}
            icon={
              <Icon
                as={IconPlayerSkipBackFilled}
                w={miscButtonSize}
                h={miscButtonSize}
                color="white"
              />
            }
            onClick={() => onPrevTrack()}
          />
        </Tooltip>
      )}

      <PlayPauseButton size={size} shouldBeOrange={!isMiniPlayer} />

      {!hideTrackControls && (
        <Tooltip label="Next track" hasArrow openDelay={300}>
          <IconButton
            aria-label="next track"
            variant="unstyled"
            minWidth={miscButtonSize}
            height={miscButtonSize}
            display={{ base: miscButtonBaseDisplay, lg: 'block' }}
            icon={
              <Icon
                as={IconPlayerSkipForwardFilled}
                w={miscButtonSize}
                h={miscButtonSize}
                color="white"
              />
            }
            onClick={() => onNextTrack()}
          />
        </Tooltip>
      )}
      {!hideSeekControls && (
        <Tooltip label="Skip 15 seconds" hasArrow openDelay={300}>
          <IconButton
            aria-label="skip"
            variant="unstyled"
            minWidth={miscButtonSize}
            height={miscButtonSize}
            display={{ base: miscButtonBaseDisplay, lg: 'block' }}
            icon={
              <Icon
                as={IconRewindForward15}
                w={miscButtonSize}
                h={miscButtonSize}
                color="white"
              />
            }
            onClick={() => seekDelta(15)}
          />
        </Tooltip>
      )}
    </Flex>
  );
}
