import { useLiveStreamSubscription } from 'generated';

import {
  LiveStreamPlayer,
  type LiveStreamPlayerProps
} from './components/LiveStreamPlayer';
import { PlayerLoader } from '../PlayerLoader';

interface LiveStreamConnectedProps
  extends Pick<
    LiveStreamPlayerProps,
    'onFinished' | 'onClose' | 'isMinimized' | 'onMaximize' | 'setIsMinimized'
  > {
  id: string;
}

export function LiveStreamConnected({
  id,
  onFinished,
  onClose,
  onMaximize,
  isMinimized,
  setIsMinimized
}: Readonly<LiveStreamConnectedProps>) {
  const { data: { stream } = {} } = useLiveStreamSubscription({
    variables: {
      id
    }
  });

  if (!stream) {
    return <PlayerLoader />;
  }

  return (
    <LiveStreamPlayer
      stream={stream}
      onFinished={onFinished}
      onClose={onClose}
      onMaximize={onMaximize}
      isMinimized={isMinimized}
      setIsMinimized={setIsMinimized}
    />
  );
}
