import * as React from 'react';
import { useDeepCompareEffect, useTimeoutFn } from 'react-use';

export type UseDebounceReturn = [() => boolean | null, () => void];

/**
 * @deprecated useDeepCompareDebeounce from react-use
 *
 * @param fn
 * @param ms
 * @param deps
 */
export const useDeepCompareDebounce = (
  fn: () => void,
  ms: number = 0,
  deps: React.DependencyList = []
): UseDebounceReturn => {
  const [isReady, cancel, reset] = useTimeoutFn(fn, ms);

  useDeepCompareEffect(reset, deps);

  return [isReady, cancel];
};
