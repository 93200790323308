import { Box, Divider, Flex } from '@chakra-ui/react';

import { type MentionListProps } from '../utils/types';
import { ProfileBarControlled } from '~/components/ui/User/ProfileBarControlled';

export function MentionList({ suggestion, focused }: MentionListProps) {
  return (
    <Box
      w="22.5rem"
      borderRadius="0.5rem"
      backgroundColor={focused ? 'whiteAlpha.100' : 'darkGrey.400'}
    >
      <Flex
        p="1rem"
        alignItems="center"
        gap="0.68rem"
        color="darkGrey.400"
        w="full"
        _hover={{ backgroundColor: 'whiteAlpha.100' }}
      >
        <ProfileBarControlled
          userId={suggestion.id as string}
          size="sm"
          disableLinkOverlay
        />
      </Flex>
      <Divider margin="auto" width="full" />
    </Box>
  );
}
