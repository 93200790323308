import isEmpty from 'lodash/isEmpty';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/reducer';
import { Category, LearningPath, OrganisationGroup } from '@tigerhall/core';

const EMPTY_ARRAY = [];

// ------------------------------------
// Reducer
// ------------------------------------

export interface LearningPathState {
  draft: {
    meta: {
      name: string | null;
      description: string | null;
      category: Pick<Category, 'id' | '__typename' | 'name' | 'image'> | null;
      image: { id: string; url: string; isDefault?: boolean } | null;
      organisationGroups?: Pick<
        OrganisationGroup,
        'id' | '__typename' | 'name'
      >[];
    };
    contentIds: string[];
  };
  entities: { [pathId: string]: LearningPath };
}

const initialState: LearningPathState = {
  draft: {
    meta: {
      name: null,
      description: null,
      category: null,
      image: null,
      organisationGroups: []
    },
    contentIds: []
  },
  entities: {}
};

export const learningPathSlice = createSlice({
  name: 'learningPath',
  initialState,
  reducers: {
    addDraftAssignmentMeta: (state, action) => {
      state.draft.meta = {
        ...state.draft.meta,
        ...action.payload
      };
    },

    setDraftCustomTrailContentIds: (state, action) => {
      state.draft.contentIds = action.payload;
    },

    clearDraft: (state) => {
      state.draft = initialState.draft;
    }
  }
});

export const {
  setDraftCustomTrailContentIds,
  addDraftAssignmentMeta,
  clearDraft
} = learningPathSlice.actions;

// ------------------------------------
// Selectors
// ------------------------------------

export function getDraftLearningPathContentIds(state: RootState): string[] {
  return state.learningPaths?.draft?.contentIds ?? EMPTY_ARRAY;
}

export function getDraftMeta(
  state: RootState
): LearningPathState['draft']['meta'] {
  return state.learningPaths?.draft?.meta ?? {};
}

export function getDraftIsEmpty(state: RootState): boolean {
  const contentIds = getDraftLearningPathContentIds(state);
  const meta = getDraftMeta(state);
  return (
    isEmpty(meta.name) &&
    isEmpty(meta.description) &&
    isEmpty(meta.category) &&
    isEmpty(meta.image) &&
    contentIds.length === 0
  );
}
