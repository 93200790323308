import { FontSizeSlider, type FontSizeSliderProps } from './FontSizeSlider';
import {
  LanguageSelector,
  type LanguageSelectorProps
} from './LanguageSelector';

interface OptionsProps extends FontSizeSliderProps, LanguageSelectorProps {}

export function Options({
  setConfig,
  config,
  availableLanguages,
  setTranscriptLanguage
}: Readonly<OptionsProps>) {
  const { isFontSizeSelectorOpen, isSelectLanguageSelectorOpen } = config;
  return (
    <>
      {isFontSizeSelectorOpen && (
        <FontSizeSlider config={config} setConfig={setConfig} />
      )}

      {isSelectLanguageSelectorOpen && (
        <LanguageSelector
          config={config}
          setTranscriptLanguage={setTranscriptLanguage}
          availableLanguages={availableLanguages}
        />
      )}
    </>
  );
}
