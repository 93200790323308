import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgProgressCircle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    focusable={false}
    className="th-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.173 2.761a10 10 0 1 1 7.654 18.478A10 10 0 0 1 8.173 2.761ZM12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16Z"
      clipRule="evenodd"
    />
    <path fill="currentColor" d="M12 6a6 6 0 0 1 6 6h-6V6Z" />
  </svg>
);
SvgProgressCircle.displayName = "IconProgressCircle";
const ForwardRef = forwardRef(SvgProgressCircle);
export default ForwardRef;
