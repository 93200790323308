import type {
  Pretty,
  Podcast,
  Stream,
  Event,
  Ebook,
  LearningPath
} from '~/types';

type ContentWithTypename<T extends { __typename: string }> = T & {
  __typename: T['__typename'];
};

export function isStream<T extends { __typename: string }>(
  content: ContentWithTypename<T>
): content is T & { __typename: Stream['__typename'] } {
  return content.__typename === 'Stream';
}

export function isPodcast<T extends { __typename: string }>(
  content: ContentWithTypename<T>
): content is T & { __typename: Podcast['__typename'] } {
  return content.__typename === 'Podcast';
}

export function isEvent<T extends { __typename: string }>(
  content: ContentWithTypename<T>
): content is T & { __typename: Event['__typename'] } {
  return content.__typename === 'Event';
}

export function isEbook<T extends { __typename: string }>(
  content: ContentWithTypename<T>
): content is T & { __typename: Ebook['__typename'] } {
  return content.__typename === 'Ebook';
}

export function isLearningPath<T extends { __typename: string }>(
  content: ContentWithTypename<T>
): content is T & { __typename: LearningPath['__typename'] } {
  return content.__typename === 'LearningPath';
}

/**
 * Infer the content type from the `__typename` field.
 *
 * @example```ts
 * type ExpectedContent =
 *   | { __typename: 'Stream' }
 *   | { __typename: 'Podcast' }
 *   | { __typename: 'Event' }
 *   | { __typename: 'Ebook' }
 *
 * function foo(content: ExpectedContent) {
 *   // do something
 * }
 *
 * type Content = {
 *   __typename: 'Stream' | 'Podcast' | 'Event' | 'Ebook'
 *  id: string
 * }
 *
 * const content: Content = { __typename: 'Stream', id: '1' }
 *
 * foo(content) // Will throw an error because `'Stream' | 'Podcast' | 'Event' | 'Ebook'` is not assignable to `'Stream'`
 *
 * foo(narrowContentType(content)) // Will work! because `narrowContentType` will narrow the type to `'Stream'`
 * ```
 */
export function narrowContentType<T extends { __typename: string }>(
  content: ContentWithTypename<T>
) {
  if (isStream(content)) {
    return content;
  }
  if (isPodcast(content)) {
    return content;
  }
  if (isEvent(content)) {
    return content;
  }
  if (isEbook(content)) {
    return content;
  }
  if (isLearningPath(content)) {
    return content;
  }
  throw new Error(
    `Can't narrow the content type. Unknown content type: ${content.__typename}`
  );
}

/**
 * For a given `T`, returns a type that is the same as `T` but with all properties in `K` made optional.
 *
 * @example```ts
 * type Foo = { a: string; b: number; c: boolean };
 * type FooWithOptionalA = PartialBy<Foo, 'a'>;
 * // { a?: string; b: number; c: boolean }
 * ```
 */
export function makePropertyOptional<T, K extends keyof T>(
  obj: T,
  key: K
): Pretty<Omit<T, K> & Partial<Pick<T, K>>> {
  return {
    ...obj,
    [key]: undefined
  };
}

/**
 * For a given `T`, returns a type that is the same as `T` but with all properties in `K` made required.
 *
 * @example```ts
 * type Foo = { a?: string; b: number; c: boolean };
 * type FooWithRequiredA = RequiredBy<Foo, 'a'>;
 * // { a: string; b: number; c: boolean }
 * ```
 */
export function makePropertyRequired<T, K extends keyof T>(
  obj: T,
  key: K
): Pretty<Omit<T, K> & Required<Pick<T, K>>> {
  return {
    ...obj,
    [key]: obj[key]
  } as Pretty<Omit<T, K> & Required<Pick<T, K>>>;
}
