import { Divider, Flex, Text } from '@chakra-ui/react';
import * as segment from '@tigerhall/analytics';
import { TrackedButton } from '@tigerhall/components';
import { type User } from '@tigerhall/core';
import {
  type Comment,
  CommentType,
  type Pretty
} from '@tigerhall/core/lib/types';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { Reaction, type ReactionProps } from './components/Reaction';
import {
  ReactionsCount,
  type ReactionsCountProps
} from './components/ReactionsCount';

export interface CommentActionsProps {
  referenceId: string;
  referenceType?: Exclude<CommentType, CommentType.Feed>;
  comment: Pretty<
    Pick<Comment, 'id' | 'createdAt'> & {
      user: Pretty<Pick<User, 'id' | 'firstName' | 'lastName'>>;
      usersReactToTheComment: {
        totalCount: number;
        reactions: ReactionsCountProps['reactions'];
        userReacted?: ReactionProps['currentReaction'];
      };
    }
  >;
  hideReply?: boolean;
  onReplyButtonClick: () => void;
}

export function CommentActions({
  referenceId,
  referenceType,
  comment,
  hideReply,
  onReplyButtonClick
}: CommentActionsProps) {
  const reactionsCount = comment.usersReactToTheComment.totalCount ?? 0;
  const reactions = comment.usersReactToTheComment.reactions ?? [];
  const currentReaction = comment.usersReactToTheComment.userReacted;

  const username = `${comment.user.firstName} ${comment.user.lastName}`;

  function handleReplyClick() {
    onReplyButtonClick();
    segment.commentReplyButtonClicked({
      authorId: comment.user.id,
      authorName: username,
      referenceId,
      referenceType: referenceType === CommentType.Post ? 'POST' : 'COMMENT'
    });
  }

  const timestamp = useMemo(() => {
    const now = DateTime.local();
    const date = DateTime.fromISO(comment.createdAt);

    return now.diff(date, 'seconds').seconds > 5
      ? date.toRelative()
      : 'Just now';
  }, [comment.createdAt]);

  return (
    <Flex justifyContent="space-between" alignItems="center" maxHeight="1.2rem">
      <Flex gap="0.5rem" alignItems="center">
        <Text as="span" fontSize="sm" color="darkGrey.200">
          {timestamp}
        </Text>
        <Reaction commentId={comment.id} currentReaction={currentReaction} />
        <ReactionsCount
          commentId={comment.id}
          reactions={reactions}
          count={reactionsCount}
        />
        {hideReply ? null : (
          <>
            <Divider
              orientation="vertical"
              borderColor="darkGrey.200"
              height="1rem"
            />
            <TrackedButton
              name="COMMENT_REPLY"
              onClick={handleReplyClick}
              as="button"
              variant="ghost"
              size="sm"
              fontSize="sm"
              p="0.25rem"
              borderRadius="base"
              lineHeight={1}
              color="lightGrey.200"
            >
              Reply
            </TrackedButton>
          </>
        )}
      </Flex>
    </Flex>
  );
}
