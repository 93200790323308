import { useCallback } from 'react';
import { Box, Button, Flex, HStack, Icon } from '@chakra-ui/react';
import map from 'lodash/map';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  IconBrandTigerhallHorizontal,
  IconHome,
  IconMenu2,
  IconNews
} from '@tigerhall/icons';
import { OrganisationSelection } from 'components/ui/containers/PageContainer/components';
import { hasOrgAdminAccess, isSidebarExpanded, setIsExpanded } from 'app/state';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TrackedRTForm } from '@tigerhall/components';
import { SearchBarInput } from 'components/ui/SearchBarInput';

import {
  Calendar,
  MenuItem,
  NotificationsCenter,
  ProfileMenu
} from './children';
import { Support } from '../Support';
import { HEADER_HEIGHT } from './const';

/**
 * As we are passing down the icons to child.
 * So couldn't find a better way to do this, So took 2 different keys for both active and inactive icons.
 */
const MENU_ITEMS = [
  {
    id: 'home',
    label: 'Home',
    activeIcon: (
      <Icon
        as={IconHome}
        color="lightGrey.400"
        width="1.5rem"
        height="1.5rem"
      />
    ),
    inactiveIcon: (
      <Icon as={IconHome} color="darkGrey.50" width="1.5rem" height="1.5rem" />
    ),
    linkTo: '/home'
  },

  {
    id: 'feed',
    label: 'Feed',
    activeIcon: (
      <Icon
        as={IconNews}
        color="lightGrey.400"
        width="1.5rem"
        height="1.5rem"
      />
    ),
    inactiveIcon: (
      <Icon as={IconNews} color="darkGrey.50" width="1.5rem" height="1.5rem" />
    ),
    linkTo: '/feed'
  }
];

function SearchBar() {
  const [params] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const onUpdate = useCallback(
    (values) => {
      const q = params.get('q');
      if (
        (q !== values.query && location.pathname === '/search') ||
        (values.query.length > 0 && location.pathname !== '/search')
      ) {
        params.set('q', values.query);
        navigate(`/search?${params.toString()}`);
      }
    },
    [location.pathname, navigate, params]
  );

  return (
    <Flex flexGrow={1} display={{ base: 'none', md: 'block' }}>
      <TrackedRTForm
        debounceMS={200}
        onSubmit={onUpdate}
        formHookProps={{
          defaultValues: {
            query: params.get('q') || ''
          }
        }}
        name="headerSearch"
      >
        <SearchBarInput name="query" />
      </TrackedRTForm>
    </Flex>
  );
}

function Hamburger() {
  const dispatch = useAppDispatch();
  const isCollapsed = !useAppSelector(isSidebarExpanded);

  const setIsCollapsed = (value: boolean) => {
    dispatch(setIsExpanded(!value));
  };

  return (
    <Button
      aria-label="mobile menu"
      justifyContent="center"
      alignItems="center"
      minH="0"
      height="2.5rem"
      width="2.5rem"
      minW="0"
      maxH="2.5rem"
      borderRadius="0.5rem"
      _hover={{ background: 'darkGrey.400' }}
      _active={{
        borderWidth: '0.125rem',
        borderColor: 'darkGrey.50'
      }}
      _focus={{
        borderWidth: '0.125rem',
        borderColor: !isCollapsed ? 'darkGrey.50' : 'transparent',
        background: !isCollapsed ? 'darkGrey.400' : 'none'
      }}
      backgroundColor="transparent"
      variant="unstyled"
      onClick={() => setIsCollapsed(!isCollapsed)}
      display={{
        base: 'flex',
        md: 'none'
      }}
    >
      <Icon as={IconMenu2} boxSize={7} color="teal.100" />
    </Button>
  );
}

export function Header() {
  const isOrgAdmin = useAppSelector(hasOrgAdminAccess);

  return (
    <Flex
      backgroundColor="black"
      px={{
        base: '0.5rem',
        md: '1rem'
      }}
      py={4}
      alignItems="center"
      justifyContent="space-between"
      as="header"
      width="100%"
      borderBottom="1px"
      borderColor="darkGrey.300"
      height={HEADER_HEIGHT}
      gap="1rem"
    >
      <HStack spacing={0} p={0} gap="1rem">
        <Hamburger />

        <Box display={{ base: 'none', md: 'flex' }}>
          <Icon
            as={IconBrandTigerhallHorizontal}
            width={'6.5rem'}
            height={7}
            color="tigerOrange.600"
          />
        </Box>

        <HStack
          spacing="0.25rem"
          p={0}
          justifyContent="flex-end"
          display={{ base: 'none', md: 'flex' }}
        >
          {map(MENU_ITEMS, (item) => (
            <MenuItem
              key={item.id}
              label={item.label}
              inactiveIcon={item.inactiveIcon}
              activeIcon={item.activeIcon}
              id={item.id}
              linkTo={item.linkTo}
            />
          ))}
        </HStack>
      </HStack>

      <SearchBar />

      <HStack gap={2} spacing={0}>
        {isOrgAdmin ? (
          <Box
            w={{
              md: '10rem',
              lg: '14rem'
            }}
            zIndex={10}
            backgroundColor="darkGrey.700"
            display={{ base: 'none', md: 'block' }}
          >
            <OrganisationSelection />
          </Box>
        ) : null}

        <Support />
        <Calendar />
        <NotificationsCenter />
        <ProfileMenu />
      </HStack>
    </Flex>
  );
}
