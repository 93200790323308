import * as React from 'react';
import * as segment from '@tigerhall/analytics';
import { ButtonProps, Button } from '@chakra-ui/react';
import { useFormContext, UseFormReturn } from 'react-hook-form';

export type TrackedButtonProps = {
  name: string;
} & ButtonProps;

export const TrackedButton = React.forwardRef<
  HTMLButtonElement,
  TrackedButtonProps
>(({ name, children, ...props }, ref) => {
  const form = useFormContext() as UseFormReturn & {
    formName: string;
  };

  return (
    <Button
      {...props}
      onClick={(args) => {
        const location = window?.location?.pathname || '';

        segment.buttonClicked({
          location,
          buttonName: name,
          formName: form?.formName
        });

        props?.onClick?.(args);
      }}
      ref={ref}
    >
      {children}
    </Button>
  );
});
