import { Box, Flex, type StackProps, VStack } from '@chakra-ui/layout';
import { Icon, Progress, Text } from '@chakra-ui/react';
import {
  IconCircleCheckColor,
  IconCircleCheckFilled,
  IconCircleStar,
  IconFilledCircleStar
} from '@tigerhall/icons';
import { colors as themeColors } from '@tigerhall/theme';
import { useMemo } from 'react';

import { useProfileProgressAndSuggetion } from './hooks';

type ProfileProgressBarProps = {
  variant?: 'dark' | 'light';
  containerProps?: StackProps;
  profileStatusTitle?: string;
};

const colorVariants = {
  dark: {
    text: 'lightGrey.600',
    container: 'black',
    barBg: 'darkGrey.400'
  },
  light: {
    text: 'darkGrey.300',
    container: 'lightGrey.400',
    barBg: 'darkGrey.50'
  }
};

const getProgressIconForDarkVariant = (
  iconType: 'starIcon' | 'checkIcon',
  progress: number
) => {
  if (iconType === 'checkIcon') {
    return progress === 100 ? (
      <Icon
        as={IconCircleCheckFilled}
        w={'1.125rem'}
        h={'1.125rem'}
        color="tigerOrange.600"
      />
    ) : (
      <IconCircleCheckColor />
    );
  }
  return progress >= 80 ? <IconFilledCircleStar /> : <IconCircleStar />;
};

export function ProfileProgressBar({
  variant = 'light',
  containerProps,
  profileStatusTitle = 'Your Profile Status'
}: ProfileProgressBarProps) {
  const { progress, progressSuggesion, progressStatus } =
    useProfileProgressAndSuggetion();
  const isDarkVariant = variant === 'dark';
  const colors = colorVariants[variant];
  const milestone = useMemo(() => {
    const getIconBaseOnProgress = (iconType: 'starIcon' | 'checkIcon') => {
      if (isDarkVariant) {
        return getProgressIconForDarkVariant(iconType, progress);
      }

      if (iconType === 'checkIcon') {
        return progress >= 80 ? (
          <Icon
            as={IconCircleCheckFilled}
            w={'1.125rem'}
            h={'1.125rem'}
            color="tigerOrange.600"
          />
        ) : (
          <IconCircleCheckColor />
        );
      }

      return progress === 100 ? <IconFilledCircleStar /> : <IconCircleStar />;
    };

    return {
      checkIcon: getIconBaseOnProgress('checkIcon'),
      starIcon: getIconBaseOnProgress('starIcon')
    };
  }, [isDarkVariant, progress]);

  return (
    <VStack
      p="1rem"
      alignItems="flex-start"
      spacing="0.5rem"
      rounded="0.5rem"
      w="full"
      bg={colors.container}
      {...containerProps}
    >
      <Flex>
        <Text fontSize="0.875rem" fontWeight="700" color={colors.text}>
          {profileStatusTitle}:
        </Text>
        <Text
          fontSize="0.875rem"
          ml="0.5rem"
          fontWeight="700"
          color="tigerOrange.600"
        >
          {progressStatus}
        </Text>
      </Flex>
      <Box pos="relative" width="100%">
        <Progress
          css={{
            '& div': {
              backgroundColor:
                progress >= 50
                  ? themeColors.tigerOrange[600]
                  : themeColors.tigerOrange[300]
            }
          }}
          bg={colors.barBg}
          h="0.5rem"
          rounded="full"
          value={progress}
        />
        <Box pos="absolute" w="1.5rem" top="-0.3rem" right="18%">
          {isDarkVariant ? milestone.starIcon : milestone.checkIcon}
        </Box>
        <Box pos="absolute" w="1.5rem" top="-0.3rem" right="-0.5rem">
          {isDarkVariant ? milestone.checkIcon : milestone.starIcon}
        </Box>
      </Box>
      <Text fontSize="0.875rem" color={colors.text}>
        {progressSuggesion}
      </Text>
    </VStack>
  );
}
