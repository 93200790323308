import { Heading, VStack, useToast } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { Button } from '@tigerhall/ui-components';
import * as segment from '@tigerhall/analytics';
import { FormInput, FormRadio } from '@tigerhall/components';
import { useDeleteUserAccountMutation } from 'generated';
import { apolloClient } from 'api/apollo';
import { logout } from 'app/state';
import { useAppDispatch } from 'hooks';
import { useTHForm } from 'hooks/useTHForm';

const leavingReasons = [
  {
    value: 'I’m having trouble finding what I want to listen/read',
    label: 'I’m having trouble finding what I want to listen/read'
  },
  {
    value: 'I wasn’t using the app enough',
    label: 'I wasn’t using the app enough'
  },
  {
    value: 'The subscriptions are too expensive for me',
    label: 'The subscriptions are too expensive for me'
  },
  {
    value: 'The content topics aren’t relevant to me',
    label: 'The content topics aren’t relevant to me'
  },
  {
    value: 'I don’t want to learn from these experts',
    label: 'I don’t want to learn from these experts'
  }
];

export function LeavingReasonModalBody() {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [deleteUserAccountMutation, { loading }] =
    useDeleteUserAccountMutation();

  const form = useTHForm({
    formName: 'DELETE_ACCOUNT',
    mode: 'onChange',
    defaultValues: {
      specific_reason: '',
      other_reason: ''
    }
  });

  const {
    handleSubmit,
    formState: { isDirty }
  } = form;

  const onSubmit = handleSubmit((values) => {
    segment.confirmationButtonClicked({
      buttonName: 'delete account',
      location: 'settings'
    });

    const reasons = values
      ? Object?.values(values).filter((value) => !!value)
      : [];

    deleteUserAccountMutation({
      variables: {
        reasons
      }
    })
      .then((res) => {
        if (res?.data?.deleteUser?.success) {
          apolloClient.clearStore().then(() => {
            toast({
              status: 'success',
              title: 'Your account has been deleted'
            });
            dispatch(logout());
          });
        }
      })
      .catch((err) => {
        toast({
          status: 'error',
          title: err.message
        });
      });
  });

  return (
    <FormProvider {...form}>
      <VStack width="100%" alignItems="flex-start" spacing="1.5rem">
        <Heading as="h3" fontSize="xl" color="tigerOrange.600" fontWeight="700">
          Why are you leaving us?
        </Heading>
        <FormRadio label="" name="specific_reason" options={leavingReasons} />
        <FormInput
          name="other_reason"
          label="Other Reason"
          placeholder="Enter reason"
        />
        <Button
          size="lg"
          w="full"
          variant="solidDark"
          text="Delete Account"
          disabled={!isDirty}
          onClick={onSubmit}
          isLoading={loading}
        />
      </VStack>
    </FormProvider>
  );
}
