import { Box, Heading } from '@chakra-ui/react';
import { type Stream } from '@tigerhall/core/lib/types';
import {
  useGetCurrentUserQuery,
  useGetOngoingLiveStreamsSubscription,
  useGetStreamsQuery
} from 'generated';
import { useMemo } from 'react';

import { List, Skeleton } from './components';
import { type StreamWithProductDemo } from './components/List';

export function StreamList() {
  const { data: livestreams } = useGetOngoingLiveStreamsSubscription({
    shouldResubscribe: true
  });
  const { data, loading: isUserDataLoading } = useGetCurrentUserQuery();

  // Stored streams
  const { data: { streams } = {}, loading } = useGetStreamsQuery({
    variables: {
      filter: { offset: 0, limit: 10, uniqueExpertStream: true },
      sorting: {
        sorting: [{ field: 'publishedAt', direction: 'desc' }]
      }
    }
  });
  const finishedStreams = streams?.edges || [];
  const ongoingStreams = livestreams?.onGoingStreams || [];
  const demoStream = data?.me?.productTourTracking.stream as Stream;

  const allStreams = useMemo(() => {
    if (isUserDataLoading || loading) {
      return [];
    }
    const streamsWithoutDemo = [
      ...ongoingStreams,
      ...finishedStreams
    ] as Stream[];

    const didWatchDemoStream = demoStream.userContentTracking?.isFinished;

    const productTourStartDate = new Date(
      data?.me?.productTourTracking?.startedAt
    );

    const newStartDateAfter7Days = productTourStartDate.setDate(
      productTourStartDate.getDate() + 7
    );

    const currentTimestamp = new Date().getTime();

    if (didWatchDemoStream || newStartDateAfter7Days < currentTimestamp) {
      return streamsWithoutDemo;
    } else {
      return [
        { isDemoStream: true, ...demoStream },
        ...streamsWithoutDemo
      ] as StreamWithProductDemo[];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [livestreams, streams, demoStream, data]);

  if (allStreams.length === 0 && !loading) {
    return null;
  }

  return (
    <Box
      width="100%"
      py={{
        base: '0.75rem',
        md: '1rem'
      }}
      background="whiteAlpha.100"
      borderBottom="1px solid"
      borderBottomColor="darkGrey.300"
    >
      <Heading
        fontSize={{
          base: 'xl',
          md: '2xl'
        }}
        px={{
          base: '1rem',
          md: '2rem'
        }}
        pb="1rem"
        color="lightGrey.200"
      >
        Watch & Discover
      </Heading>
      {loading ? <Skeleton /> : <List streams={allStreams} />}
    </Box>
  );
}
