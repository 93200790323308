import { Divider, Stack, Text, VStack } from '@chakra-ui/react';
import { type User } from '@tigerhall/core/lib/types';
import { ProfileInfo } from 'modules/application/modules/Profile/screens/Profile/components/ProfileInfo';
import { Icon } from '@chakra-ui/icon';
import { IconBan } from '@tigerhall/icons';

type BlockedProfileProps = {
  user: User;
};

export function BlockedProfileView({ user }: Readonly<BlockedProfileProps>) {
  return (
    <Stack spacing={6} px={{ base: '1rem', md: '2rem' }} w="full">
      <ProfileInfo user={user} isCurrentUser={false} />
      <Divider borderColor="darkGrey.300" />

      <VStack height={416} justifyContent="center" spacing="1rem">
        <Icon boxSize={10} as={IconBan} color="lightGrey.900" />
        <Text
          color="lightGrey.200"
          textAlign="center"
          fontSize="2xl"
          fontWeight="700"
        >
          Content not available
        </Text>
      </VStack>
    </Stack>
  );
}
