import { type RefObject, useEffect } from 'react';

import { useMediaState } from '~/player/context';

/**
 * This hook resets the volume and playback rate of the media element to the
 * values stored in the media state, when a new media element is mounted.
 *
 * When the media element is unmounted, we reset the media state to its initial
 * values, except for the volume and playback rate.
 *
 * @param mediaElementRef The ref to the media element
 */
export function useMediaReset(
  mediaElementRef: RefObject<HTMLVideoElement | HTMLAudioElement>
) {
  const [playbackRate, volume, reset] = useMediaState((state) => [
    state.playbackRate,
    state.volume,
    state.reset
  ]);

  useEffect(() => {
    const mediaElement = mediaElementRef.current;

    if (!mediaElement) {
      return;
    }

    if (playbackRate && playbackRate !== 1) {
      mediaElementRef.current.playbackRate = playbackRate;
    }

    // `volume === 0` means that the user has muted the player
    if (volume || volume === 0) {
      mediaElementRef.current.volume = volume;
    }

    return () => {
      reset();
    };

    // this is intentional, we only want to run this if the `mediaElementRef` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaElementRef]);
}
