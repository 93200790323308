import { Box } from '@chakra-ui/react';
import { IconLanguage } from '@tigerhall/icons';
import { useState } from 'react';

import { AIBadge } from '~/badges';
import { Languages, type LanguagesProps } from '~/player/components/Languages';
import { OptionButton } from '~/player/components/OptionButton';
import { wrapLanguageChangeHandler } from '~/player/utils/tracking';

export interface ChangeLanguageButtonProps
  extends Pick<
    LanguagesProps,
    | 'mediaLanguages'
    | 'selectedMediaLanguage'
    | 'onChangeMediaLanguage'
    | 'transcriptLanguages'
    | 'selectedTranscriptLanguage'
    | 'onChangeTranscriptLanguage'
  > {}

export function ChangeLanguageButton({
  // from `LanguagesProps`
  mediaLanguages,
  selectedMediaLanguage,
  onChangeMediaLanguage,

  // from `LanguagesProps`
  transcriptLanguages,
  selectedTranscriptLanguage,
  onChangeTranscriptLanguage
}: Readonly<ChangeLanguageButtonProps>) {
  const [isLanguageSelectionOpen, setIsLanguageSelectionOpen] = useState(false);

  // we only allow the user to change the language if there are multiple media languages (more than the default)
  // or at least one transcript language (the default is `null`)
  const shouldShowLanguageSelector =
    mediaLanguages.length > 1 || transcriptLanguages.length > 0;

  const handleMediaLanguageChange = wrapLanguageChangeHandler(
    onChangeMediaLanguage,
    {
      previousLanguage: selectedMediaLanguage
    }
  );

  const handleTranscriptLanguageChange = wrapLanguageChangeHandler(
    onChangeTranscriptLanguage,
    {
      previousLanguage: selectedTranscriptLanguage ?? 'none'
    }
  );

  return (
    <>
      {shouldShowLanguageSelector && (
        <Box pos={'relative'}>
          <AIBadge variant="mini" pos={'absolute'} top={0} right={0} />
          <OptionButton
            label={'Change Language'}
            icon={IconLanguage}
            onClick={() => {
              setIsLanguageSelectionOpen(true);
            }}
          />
        </Box>
      )}
      <Languages
        mediaHeading={'Power Read'}
        transcriptHeading={'Audio'}
        mediaLanguages={mediaLanguages}
        transcriptLanguages={transcriptLanguages}
        selectedMediaLanguage={selectedMediaLanguage}
        selectedTranscriptLanguage={selectedTranscriptLanguage}
        onChangeMediaLanguage={handleMediaLanguageChange}
        onChangeTranscriptLanguage={handleTranscriptLanguageChange}
        isOpen={isLanguageSelectionOpen}
        onClose={() => {
          setIsLanguageSelectionOpen(false);
        }}
      />
    </>
  );
}
