import { Box, Flex, Icon } from '@chakra-ui/react';
import { IconBrandTigerhallHorizontal } from '@tigerhall/icons';
import * as React from 'react';

export function ResetPasswordContainer({ children }) {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      direction="column"
      w="100vw"
      h="100vh"
      backgroundColor="darkGrey.700"
    >
      <Flex w="100%" h="100%" maxW="27rem" direction="column" py="3.5rem">
        <Box mx="auto" flex={1} alignSelf="flex-start">
          <Icon
            as={IconBrandTigerhallHorizontal}
            w="8rem"
            h="2.5rem"
            color="tigerOrange.600"
          />
        </Box>

        <Box flexGrow={1}>{children}</Box>
      </Flex>
    </Flex>
  );
}
