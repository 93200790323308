import * as React from 'react';
import {
  Center,
  Collapse,
  Flex,
  HStack,
  Icon,
  ListItem,
  Text,
  UnorderedList,
  VStack,
  useDisclosure
} from '@chakra-ui/react';
import {
  IconChevronDown,
  IconCircleCheckFilled,
  IconCirclePlus
} from '@tigerhall/icons';
import {
  type SubscriptionType as StripeSubscriptionType,
  getStripePaymentGatewayUrl
} from '@tigerhall/core';
import { TrackedLink } from '@tigerhall/components';
import { getAccessToken, getCustomerType } from 'app/state';
import { useAppSelector } from 'hooks';

import { type SubscriptionPlan } from '../types';

export function PlanCard({
  type,
  heading,
  subheading,
  description,
  plusFeatures,
  features,
  cta
}: SubscriptionPlan) {
  const { isOpen, onToggle } = useDisclosure();
  const token = useAppSelector(getAccessToken) || '';
  const customerType =
    (useAppSelector(
      getCustomerType
    )?.toLocaleLowerCase() as StripeSubscriptionType) || '';

  if (!features) {
    return null;
  }

  const getShouldRedirectToStripe = (subscriptionType: typeof type) =>
    customerType && subscriptionType.toLowerCase().includes(customerType);

  return (
    <Flex
      flexDirection="column"
      background="#151618"
      width="100%"
      maxWidth="36.25rem"
      height="auto"
      gap={6}
      padding="2rem"
      borderWidth={1}
      borderColor="#464A4D"
      borderRadius="1.25rem"
    >
      <VStack
        spacing={1}
        alignItems="start"
        position="relative"
        as="button"
        onClick={onToggle}
      >
        <Icon
          position="absolute"
          right={0}
          color="white"
          width={{ base: '1.125rem', lg: '1.25rem' }}
          height={{ base: '1.125rem', lg: '1.25rem' }}
          transition="transform 0.2s ease-in-out"
          transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
          as={IconChevronDown}
        />
        <Text
          as="span"
          color="#FF5900"
          fontWeight="bold"
          fontSize={{ base: '1.5rem', lg: '2rem' }}
          lineHeight="1.3"
          textAlign="start"
        >
          {heading}
        </Text>
        <Text
          as="span"
          color="white"
          fontWeight="bold"
          fontSize={{ base: 'sm', lg: 'lg' }}
          lineHeight="1.3"
          textAlign="start"
        >
          {subheading}
        </Text>
        {description ? (
          <Text
            color="lightGrey.900"
            fontSize={{ base: 'sm', lg: 'lg' }}
            lineHeight="1.3"
            textAlign="start"
          >
            {description}
          </Text>
        ) : null}
      </VStack>
      <Collapse in={isOpen} animateOpacity>
        <UnorderedList
          listStyleType="none"
          display="flex"
          flexDirection="column"
          alignItems="start"
          gap={3}
          m={0}
          mb={6}
        >
          {plusFeatures?.map((plusFeature) => (
            <ListItem display="flex" gap={4} key={`plan-card-${plusFeature}`}>
              <Center
                background="white"
                rounded="full"
                width={{ base: '1.125rem', lg: '1.25rem' }}
                height={{ base: '1.125rem', lg: '1.25rem' }}
                borderColor="#249EA0"
                borderWidth="2px"
              >
                <Icon
                  color="#249EA0"
                  width={{ base: '1.125rem', lg: '1.25rem' }}
                  height={{ base: '1.125rem', lg: '1.25rem' }}
                  as={IconCirclePlus}
                />
              </Center>
              <Text
                color="white"
                fontSize={{
                  base: 'sm',
                  lg: 'lg'
                }}
                fontWeight="bold"
                textAlign="start"
              >
                {plusFeature}
              </Text>
            </ListItem>
          ))}
          {features?.map((feature) => (
            <ListItem display="flex" gap={4} key={`plan-card-${feature}`}>
              <Center
                background="white"
                rounded="full"
                width={{ base: '1.125rem', lg: '1.25rem' }}
                height={{ base: '1.125rem', lg: '1.25rem' }}
                borderColor="#008083"
                borderWidth="2px"
              >
                <Icon
                  color="#008083"
                  width={{ base: '1.125rem', lg: '1.25rem' }}
                  height={{ base: '1.125rem', lg: '1.25rem' }}
                  as={IconCircleCheckFilled}
                />
              </Center>
              <Text
                color="white"
                fontSize={{
                  base: 'md',
                  lg: 'lg'
                }}
                textAlign="start"
              >
                {feature}
              </Text>
            </ListItem>
          ))}
        </UnorderedList>
        <HStack
          width="full"
          spacing="4"
          alignItems="center"
          justifyContent="center"
        >
          {cta?.link?.href ? (
            <TrackedLink
              as="button"
              name={cta?.link?.label}
              href={
                getShouldRedirectToStripe(type)
                  ? getStripePaymentGatewayUrl({
                      origin: import.meta.env.VITE_API_URL || '',
                      token,
                      subscriptionType: customerType,
                      successRedirectUrl: `${
                        import.meta.env.VITE_WEBAPP_URL
                      }/signup/${customerType}/confirmation`,
                      cancelRedirectUrl: window.location.href
                    })
                  : cta?.link?.href
              }
              isExternal={
                getShouldRedirectToStripe(type) || cta?.link?.isExternal
              }
              w="100%"
              size="lg"
              variant={cta?.theme || 'solid'}
              data-cy={`paywall-${type.toLowerCase()}-link-mobile`}
            >
              {cta?.link?.label}
            </TrackedLink>
          ) : null}
        </HStack>
      </Collapse>
    </Flex>
  );
}
