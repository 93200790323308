import { Box, Flex, Icon, useBreakpointValue } from '@chakra-ui/react';
import {
  IconCaretLeftFilled,
  IconCaretRightFilled,
  IconCircleCheck,
  IconCircleCheckFilled
} from '@tigerhall/icons';
import { type PlaylistType } from '@tigerhall/analytics';
import isUndefined from 'lodash/isUndefined';

import { TrackedButton } from '~/tracked';
import { usePowerReadState } from '~/player/PowerRead/context/usePowerReadState';

interface NavigationProps {
  onDone: () => void;
  onNextContentPiece: () => void;
  playlistType: PlaylistType | undefined;
  isLastContentInPlaylist: boolean;
  isFinished?: boolean;
}

export function Navigation({
  onDone,
  onNextContentPiece,
  playlistType,
  isLastContentInPlaylist,
  isFinished
}: Readonly<NavigationProps>) {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const { theme, pageIndex, pagesTotal, setPageIndex, customPagesTotal } =
    usePowerReadState((state) => {
      return {
        theme: state.theme,
        pageIndex: state.pageIndex,
        pagesTotal: state.pagesTotal,
        customPagesTotal: state.customPagesTotal,
        setPageIndex: state.setPageIndex
      };
    });

  const isFirstPage = pageIndex === 0;
  const isLastPage = pageIndex === (customPagesTotal || pagesTotal) - 1;
  const isActualLastPage = pageIndex === pagesTotal - 1;

  const navigationButtonVariant = theme === 'dark' ? 'outline' : 'outlineDark';
  const nextContentPieceButtonVariant =
    theme === 'dark' ? 'solid' : 'solidDark';

  function getCompletedButton() {
    if (!isLastPage) {
      return;
    }
    if (!isFinished) {
      return (
        <Flex justifyContent="center" mt={{ base: 6, lg: 8 }}>
          <Flex flexDir={'column'} gap={2}>
            <Box
              as={'label'}
              display={'inline-flex'}
              alignItems={'center'}
              justifyContent={'center'}
              bg={'darkGrey.200'}
              color={'white'}
              borderRadius={'full'}
              fontWeight={'bold'}
              fontSize={'sm'}
              letterSpacing={'0.015em'}
              boxShadow={'lg'}
              px={4}
              minH={10}
            >
              Progressing..
            </Box>
            <TrackedButton
              aria-label="Mark as Completed"
              name="Done"
              variant={'inline'}
              size={{ base: 'md', lg: 'md' }}
              color={'tigerOrange.500'}
              onClick={onDone}
              leftIcon={<Icon as={IconCircleCheck} color={'tigerOrange.500'} />}
            >
              Mark as Completed
            </TrackedButton>
          </Flex>
        </Flex>
      );
    }

    return (
      <Flex justifyContent="center" mt={{ base: 6, lg: 8 }}>
        <Box
          as={'label'}
          display={'inline-flex'}
          alignItems={'center'}
          bg={'green.900'}
          color={'white'}
          borderRadius={'full'}
          fontWeight={'bold'}
          fontSize={'sm'}
          letterSpacing={'0.015em'}
          boxShadow={'lg'}
          px={4}
          minH={10}
        >
          <Icon as={IconCircleCheckFilled} color={'green.500'} mr={2} />
          Completed
        </Box>
      </Flex>
    );
  }

  function getPreviousButton() {
    if (isFirstPage && isLastPage) {
      return null;
    }
    return (
      <TrackedButton
        aria-label="Previous Page"
        name="Previous_Page"
        variant={navigationButtonVariant}
        size={'lg'}
        onClick={() => {
          setPageIndex(pageIndex - 1);
        }}
        iconSpacing={isMobile ? 0 : 2}
        leftIcon={<Icon as={IconCaretLeftFilled} />}
        isDisabled={isFirstPage}
      >
        {!isMobile && ' Previous Page'}
      </TrackedButton>
    );
  }

  function getNextContentPieceButton() {
    if (isUndefined(playlistType) || isLastContentInPlaylist) {
      return null;
    }
    return (
      <TrackedButton
        aria-label="Next Content Piece"
        name="Next_Content_Piece"
        variant={nextContentPieceButtonVariant}
        size={'lg'}
        onClick={onNextContentPiece}
        rightIcon={<Icon as={IconCaretRightFilled} />}
      >
        {isMobile ? 'Next Content' : 'Next Content Piece'}
      </TrackedButton>
    );
  }

  function getNextButton() {
    if (isLastPage) {
      return getNextContentPieceButton();
    }

    return (
      <TrackedButton
        aria-label="Next Page"
        name="Next_Page"
        variant={navigationButtonVariant}
        size={'lg'}
        onClick={() => {
          if (isActualLastPage) {
            onDone();
            return;
          }
          setPageIndex(pageIndex + 1);
        }}
        iconSpacing={isMobile ? 0 : 2}
        rightIcon={<Icon as={IconCaretRightFilled} />}
      >
        {!isMobile && 'Next Page'}
      </TrackedButton>
    );
  }

  return (
    <Flex flexDir={'column'} pb={12}>
      {getCompletedButton()}
      <Flex justifyContent="space-between" mt={{ base: 6, lg: 8 }}>
        {getPreviousButton()}
        {getNextButton()}
      </Flex>
    </Flex>
  );
}
