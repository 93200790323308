import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import property from 'lodash/property';
import { Box, Center, Flex, SimpleGrid, Skeleton } from '@chakra-ui/react';
import {
  TableOptions as ReactTableOptions,
  UseRowSelectOptions,
  useTable,
  useFlexLayout
} from 'react-table';
import { Global } from '@emotion/react';
import * as segment from '@tigerhall/analytics';

import {
  TableHeader,
  DefaultColumnHeader,
  TableBody,
  TableBulkActions,
  TableFooter,
  TableBodyFallback
} from './components';
import { TableProps, ColumnParams } from './types';
import {
  createUseTableSelection,
  createUseTablePagination,
  createUseTableSorting,
  createUseTableResizeColumns
} from './plugins';
import { StyledTable, tableRootStyle } from './styles';

export function createColumn<D extends object = {}>(
  id: string,
  header: string,
  { accessor, ...rest }: ColumnParams<D> = {},
  tooltip?: boolean,
  label?: string
) {
  return {
    id,
    Header: (
      <DefaultColumnHeader header={header} tooltip={tooltip} label={label} />
    ),
    accessor: accessor ?? property(id),
    disableSortBy: true,
    ...rest
  };
}

/** TODO: Redo this thing in `ui/components` */
const Table = <D extends object = {}>({
  name,
  columns,
  data,
  loading,
  tableHeading,
  pageMeta,
  onRowClick,
  reFetchData,
  emptyMessage,
  onRowSelection,
  tableProps,
  selectedRowIds,
  getRowId,
  bulkActions,
  formatStats,
  showFooter = false,
  style = {},
  topRightSlot,
  rowsSizes,
  rowsAlias,
  initialSortBy = [],
  emptyRowsCount = 10,
  customStylingForRows
}: TableProps<D>) => {
  const tableData = React.useMemo(() => data, [data]);
  const tableColumns = React.useMemo(() => columns, [columns]);

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 50, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 350 // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const options: ReactTableOptions<D> & UseRowSelectOptions<D> = {
    data: tableData,
    columns: tableColumns,
    defaultColumn,
    // helps avoiding re-rendering of the table unnecessarily when the data changes
    // reference: https://github.com/TanStack/table/issues/2369#issuecomment-644481605
    autoResetSortBy: false,
    getRowId,
    initialState: {
      sortBy: initialSortBy
    }
  };

  const onRowClickHandler: typeof onRowClick = onRowClick
    ? (row) => {
        segment.tableRowClicked({
          tableName: name || 'unknown',
          rowId: row.id,
          location: window.location.pathname
        });
        onRowClick(row);
      }
    : undefined;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    rows,
    renderPaginator
  } = useTable<D>(
    options,
    useFlexLayout,
    ...createUseTableSorting({ reFetchData }),
    ...createUseTablePagination<D>({
      reFetchData,
      pageMeta,
      sizes: rowsSizes,
      rowsAlias
    }),
    ...createUseTableSelection({
      onRowSelection,
      onRowClick: onRowClickHandler,
      selectedRowIds
    }),
    ...createUseTableResizeColumns({ columns })
  );

  return (
    <>
      <Global styles={tableRootStyle} />
      {bulkActions && !isEmpty(bulkActions) && (
        <Flex justifyContent="space-between" alignItems="center">
          <TableBulkActions
            actions={bulkActions}
            selectedIds={selectedRowIds}
            formatStats={formatStats}
          />
          {topRightSlot ? topRightSlot : null}
        </Flex>
      )}
      <Flex flexDirection="column" maxWidth="100%" width="100%" data-cy="table">
        {!!tableHeading && <Box>{tableHeading}</Box>}
        <StyledTable
          {...getTableProps()}
          {...tableProps}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
              height: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#BBB8B2',
              borderRadius: '24px'
            }
          }}
          style={style}
          emptyRowsCount={emptyRowsCount}
        >
          <TableHeader headerGroups={headerGroups} />
          {loading || (page?.length === 0 && isEmpty(data)) ? (
            <Center
              width="100%"
              height="100%"
              minHeight={`${emptyRowsCount * 36}px`}
            >
              {loading ? (
                <TableBodyFallback
                  isLoading
                  heading="Calculating data"
                  subheading="Hold on! It may take some time to load the table data"
                />
              ) : (
                emptyMessage ?? <TableBodyFallback />
              )}
            </Center>
          ) : (
            <SimpleGrid position="relative">
              <TableBody
                prepareRow={prepareRow}
                getTableBodyProps={getTableBodyProps}
                emptyRowsCount={emptyRowsCount}
                rows={page ?? rows}
                customStylingForRows={customStylingForRows}
              />
            </SimpleGrid>
          )}
          {showFooter && <TableFooter footerGroups={footerGroups} />}
        </StyledTable>
        {loading && !pageMeta && (
          <Center pt="1.25rem">
            <Skeleton height="2rem" width="17rem" />
          </Center>
        )}
        {!!pageMeta && <Box flexDirection="row">{renderPaginator?.()}</Box>}
      </Flex>
    </>
  );
};

export default Table;
