import * as Sentry from '@sentry/react';

type AppErrorContext = Parameters<typeof Sentry.captureException>[1];

/**
 * This is a custom error that allows us to send extra
 * context as tags, extra, etc. to Sentry when we throw an error.
 *
 * This is powerful always that is used with the `ErrorBoundary` component.
 *
 * @example
 * ```ts
 * throw new AppError('Something went wrong', {
 *   tags: {
 *     foo: 'bar',
 *   },
 *   extra: {
 *     foo: 'bar',
 *   },
 * });
 * ```
 */
export class AppError extends Error {
  context?: AppErrorContext;

  constructor(message: string, context?: AppErrorContext) {
    super(message);
    this.context = context;

    // Ensure the name of this error is the same as the class name
    this.name = this.constructor.name;
  }
}
