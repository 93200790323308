import { ApolloError } from '@apollo/client';
import {
  Box,
  Button as ChakraButton,
  Flex,
  HStack,
  Link,
  Text,
  VStack,
  useToast
} from '@chakra-ui/react';
import * as segment from '@tigerhall/analytics';
import { FormInput, TrackedForm } from '@tigerhall/components';
import { Button } from '@tigerhall/ui-components';
import { useCreateTeamsAccountMutation } from 'generated';
import { useAppDispatch } from 'hooks';
import { HeaderDetails } from 'modules/authentication/modules/SignUp/components/HeaderDetails';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { setAccessToken } from 'app/state';
import { useCallback } from 'react';

type FormFields = {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  password: string;
  confirmPassword: string;
};

export function SignUpForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  // Mutation that creates the account
  // Changed the error handling temporary speciafically for email untill it gets changed from backend
  const [createTeamsAccount] = useCreateTeamsAccountMutation();

  const onSubmit = useCallback(
    async (values: FormFields, { setError }) => {
      try {
        const response = await createTeamsAccount({
          variables: {
            input: {
              company: values.company,
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              password: values.password
            }
          }
        });
        /**
         * @note This Event Needs to at TeamsSignUp Detail page
         *  Please Move this event to Teams Signup Details Page when/if available
         */
        segment.formSubmitted({
          formName: 'subscriptionPlanTeamsSignupDetails',
          formLocation: window.location.pathname
        });

        toast({
          position: 'top-right',
          title: 'Teams account created successfully',
          status: 'success',
          duration: 2000,
          isClosable: true
        });

        dispatch(
          setAccessToken({
            token: response.data?.createTeamsAccount?.auth.jwt as string
          })
        );

        navigate('/signup/team/add-users');
      } catch (err) {
        if (err instanceof ApolloError) {
          if (
            err.graphQLErrors[0].message ===
            'failed to create organisation: the organisation name already exist'
          ) {
            setError('company', {
              type: 'manual',
              message: 'This company name is already being used'
            });

            return;
          }

          setError('email', {
            type: 'manual',
            message: 'Email is already in use'
          });
        }
      }
    },
    [createTeamsAccount, dispatch, navigate, toast]
  );

  function handleClick() {
    segment.buttonClicked({
      buttonName: 'subscriptionPlansTeamsSignup',
      location: window?.location?.pathname
    });
  }

  return (
    <>
      <VStack
        pt={{
          base: '3rem',
          md: '5rem'
        }}
        w="100%"
        css={{
          '& input+span.chakra-text': {
            color: '#FFB673',
            fontSize: '0.75rem',
            lineHeight: '1.3',
            fontWeight: 'normal'
          }
        }}
      >
        <Box w="100%">
          <HeaderDetails
            heading="Sign up for a 7-day free trial"
            description="Cancellations during the trial period won't be charged."
          />
        </Box>

        <TrackedForm<FormFields>
          name="subscriptionPlansTeamsSignup"
          onSubmit={onSubmit}
        >
          {({ getValues, formState: { isSubmitting } }) => (
            <Flex maxW="35rem" direction="column" gap="1rem" mx="auto">
              <Flex
                gap="1.5rem"
                flexDirection={{
                  base: 'column',
                  md: 'row'
                }}
              >
                <Box flex={1}>
                  <FormInput
                    type="text"
                    name="firstName"
                    label="First Name"
                    rules={{
                      required: true
                    }}
                    placeholder="First name"
                    autoComplete="given-name"
                  />
                </Box>
                <Box flex={1}>
                  <FormInput
                    name="lastName"
                    label="Last Name"
                    rules={{
                      required: true
                    }}
                    placeholder="Last name"
                    type="text"
                    autoComplete="family-name"
                  />
                </Box>
              </Flex>

              <FormInput
                label="Email"
                name="email"
                rules={{
                  required: true
                }}
                placeholder="Email"
                type="email"
                autoComplete="email"
              />

              <FormInput
                name="company"
                label="Company"
                rules={{
                  required: true,
                  minLength: {
                    value: 2,
                    message: '* Company name should be at least 2 characters'
                  }
                }}
                placeholder="Company"
                type="text"
                autoComplete="organization"
              />

              <Flex
                gap="1.5rem"
                flexDirection={{
                  base: 'column',
                  md: 'row'
                }}
              >
                <Box flex={1}>
                  <FormInput
                    name="password"
                    rules={{
                      required: true,
                      minLength: {
                        value: 8,
                        message: '* Password should be min 8 characters'
                      }
                    }}
                    label="Password"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                  />
                </Box>
                <Box flex={1}>
                  <FormInput
                    name="confirmPassword"
                    rules={{
                      required: true,
                      validate: (value) => {
                        const { password } = getValues();
                        return (
                          password === value || 'Your Password does not match'
                        );
                      }
                    }}
                    label="Confirm Password"
                    type="password"
                    placeholder="Confirm password"
                    autoComplete="new-password"
                  />
                </Box>
              </Flex>

              <Box pt="1rem">
                <Button
                  text="Sign up"
                  data-cy="submit"
                  onClick={handleClick}
                  type="submit"
                  width="100%"
                  size="lg"
                  isLoading={isSubmitting}
                />
              </Box>
            </Flex>
          )}
        </TrackedForm>
      </VStack>
      <HStack alignItems="center" mt="auto" height="100%">
        <Text fontSize="sm" color="lightGrey.200">
          Already have an account?
        </Text>
        <Link
          as={RouterLink}
          to="/login"
          fontWeight="bold"
          transform="auto"
          transitionDuration=".3s"
          textDecoration="none"
          fontSize="sm"
          color="lightGrey.200"
          _hover={{
            translateY: '-0.15rem'
          }}
        >
          <ChakraButton
            onClick={() => {
              segment.buttonClicked({
                buttonName: 'Login Now',
                location: window.location.pathname
              });
            }}
            variant="outline"
            borderRadius="md"
            size="sm"
          >
            Login Now
          </ChakraButton>
        </Link>
      </HStack>
    </>
  );
}
