import * as React from 'react';
import { type FallbackRender } from '@sentry/react';
import { Center, HStack } from '@chakra-ui/react';
import { TableBodyFallback } from '@tigerhall/ui-components';
import { TrackedButton } from '@tigerhall/components';

export interface ScreenErrorFallbackProps
  extends React.ComponentProps<FallbackRender> {}

/**
 * Default fallback UI for the `ErrorBoundary` component.
 * For this component to work the `ErrorBoundary` component must be
 * wrapped around the `ChakraProvider`, since this component uses
 * components from `@chakra-ui/react`.
 *
 * @example
 * ```tsx
 * <ChakraProvider>
 *   <ErrorBoundary fallback={ScreenErrorFallback}>
 *     <Screen /> // ---> This is the component that throws the error
 *   </ErrorBoundary>
 * </ChakraProvider>
 * ```
 */
export function ScreenErrorFallback({
  error,
  resetError
}: ScreenErrorFallbackProps) {
  return (
    <Center
      background="darkGrey.700"
      width="100%"
      height="auto"
      maxHeight="100vh"
      py="10rem"
    >
      <TableBodyFallback
        heading="An error occurred"
        subheading={`${error.name}: ${error.message}.`}
      >
        <HStack spacing="1rem" p="1rem">
          <TrackedButton name="ERROR_BOUNDARY_RESET" onClick={resetError}>
            Try Again
          </TrackedButton>
          <TrackedButton
            name="ERROR_BOUNDARY_CLEAR_CACHE"
            variant="outline"
            onClick={() => {
              window.localStorage.clear();
              window.location.reload();
            }}
          >
            Logout & Refresh
          </TrackedButton>
        </HStack>
      </TableBodyFallback>
    </Center>
  );
}
