import { Button, HStack, Link, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export function FreeTrialFooter() {
  return (
    <HStack spacing="0.5rem">
      <Text
        fontSize="md"
        fontWeight="500"
        userSelect="none"
        color="lightGrey.600"
      >
        Have an account?
      </Text>
      <Link
        as={RouterLink}
        to="/login"
        transform="auto"
        transitionDuration=".3s"
        _hover={{
          translateY: '-0.15rem'
        }}
      >
        <Button variant="outline" size="sm" color="white" borderRadius="full">
          Log in Now
        </Button>
      </Link>
    </HStack>
  );
}
