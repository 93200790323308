import * as React from 'react';
import { Button, Flex, Icon } from '@chakra-ui/react';
import {
  Content,
  ContentType,
  StreamFilter,
  StreamSource
} from '@tigerhall/core/lib/types';
import { TrackedLink } from '@tigerhall/components';
import { IconEdit, IconPlus, IconTrash } from '@tigerhall/icons';
import { InfiniteSingleTypeCardGrid, PageBanner } from 'components/ui';
import { useDeleteStreamMutation, useGetContentByIdQuery } from 'generated';
import withAction from 'hoc/withAction';
import { useNavigate } from 'react-router-dom';
import { getOrgIdFromToken } from 'app/state';
import { useAppSelector } from 'hooks';
import { ContentSummaryModal } from 'modules/admin/components';
import {
  GenericHistoryProps,
  GenericSearchForm
} from 'modules/admin/components/GenericSearchForm';

import { broadcastFormRoute } from '../../utils';

function BroadcastListing({ setActionConfig }: Readonly<GenericHistoryProps>) {
  const orgId = useAppSelector(getOrgIdFromToken);
  const navigate = useNavigate();

  const [selectedBroadcast, setSelectedBroadcast] = React.useState<string>();

  const { data } = useGetContentByIdQuery({
    skip: !selectedBroadcast,
    variables: {
      id: selectedBroadcast as string
    }
  });

  const [filter, setFilter] = React.useState<StreamFilter>({
    organisationID: [orgId as string],
    limit: 10,
    offset: 0,
    source: [StreamSource.BroadcastAwsIvs, StreamSource.BroadcastCloudflareLive]
  });
  const getActionConfig = (card, refetch) => ({
    modalConfig: {
      title: 'Delete Broadcast',
      body: 'Are you sure you want to delete this broadcast?',
      primaryActionText: 'Delete',
      postAction: () => {
        refetch?.();
      }
    },
    mutationConfig: {
      variables: {
        id: card?.id
      }
    },
    toastConfig: {
      successDescription: 'Broadcast Deleted successfully.'
    }
  });

  return (
    <>
      <PageBanner
        description="Create a custom broadcast that best fits your team's unique needs. Fill out some details about the broadcast and hit publish! You can later assign these podcasts to your team members using the Manage Assignments tab to the left."
        title="Broadcasts"
        action={
          <TrackedLink
            name="UPLOAD_BROADCAST"
            href={broadcastFormRoute('draft')}
          >
            <Button leftIcon={<IconPlus color="tigerOrange.600" />} size="lg">
              Upload your broadcast
            </Button>
          </TrackedLink>
        }
      />
      <Flex mt="2rem" direction="column" gap="1rem">
        <GenericSearchForm
          setFilter={setFilter}
          heading="Broadcasts History"
          formName="searchBroadcastHistory"
        />
        <InfiniteSingleTypeCardGrid
          type={ContentType.Stream}
          filter={filter}
          overlayActions={(card, refetch) => [
            {
              label: 'Learn More',
              variant: 'outline',
              onClick: () => {
                setSelectedBroadcast(card.id);
              }
            },
            {
              label: 'Edit',
              onClick: () => {
                navigate(broadcastFormRoute(card.id));
              },
              leftIcon: <Icon as={IconEdit} />
            },
            {
              label: 'Delete',
              onClick: () => {
                setActionConfig(getActionConfig(card, refetch));
              },
              leftIcon: <Icon as={IconTrash} />
            }
          ]}
        />
      </Flex>
      <ContentSummaryModal
        isOpen={!!data?.contentCard}
        onClose={() => setSelectedBroadcast(undefined)}
        content={data?.contentCard as Content}
        actions={
          <>
            <Button
              flex={1}
              variant="outline"
              leftIcon={<Icon as={IconEdit} />}
              onClick={() => {
                navigate(broadcastFormRoute(selectedBroadcast as string));
                setSelectedBroadcast(undefined);
              }}
            >
              Edit
            </Button>
          </>
        }
      />
    </>
  );
}

export default withAction({ actionMutation: useDeleteStreamMutation })(
  BroadcastListing
);
