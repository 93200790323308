import { useRef } from 'react';
import Sheet, { SheetRef } from 'react-modal-sheet';
import { colors } from '@tigerhall/theme';

interface BottomDrawerProps {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export function BottomDrawer({ children, isOpen, onClose }: BottomDrawerProps) {
  const ref = useRef<SheetRef>();
  return (
    <Sheet
      ref={ref}
      isOpen={isOpen}
      onClose={onClose}
      // opens up to 70% of the screen
      snapPoints={[0.7, 0]}
      initialSnap={0}
    >
      <Sheet.Container
        style={{
          background: colors.darkGrey[700]
        }}
      >
        <Sheet.Header />
        <Sheet.Content disableDrag>
          <Sheet.Scroller>{children}</Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
}
