import { Box } from '@chakra-ui/react';
import { type RefObject, useEffect, useMemo } from 'react';

export interface VideoPlayerContainerProps {
  isMinimized: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  orientation?: 'PORTRAIT' | 'LANDSCAPE';
  /**
   * This is required to modify the minimized player width
   * as the liveStream has different aspect ratio than, other video types.
   */
  isLiveStream?: boolean;
  videoRef?: RefObject<HTMLVideoElement>;
  onAutoNextPlay?: (value: boolean) => void;
}

export function VideoPlayerContainer({
  isMinimized,
  containerRef,
  orientation = 'PORTRAIT',
  isLiveStream = false,
  videoRef,
  onAutoNextPlay,
  children
}: VideoPlayerContainerProps) {
  const minimizedPlayerWidth = useMemo(() => {
    if (isLiveStream) {
      return 'auto';
    }
    return orientation === 'LANDSCAPE'
      ? { base: '300px', lg: '450px' }
      : { base: '200px', lg: '300px' };
  }, [isLiveStream, orientation]);

  const isVideoEnded = videoRef?.current?.ended;

  useEffect(() => {
    if (isMinimized && isVideoEnded) {
      onAutoNextPlay?.(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVideoEnded, isMinimized]);

  if (isMinimized) {
    return (
      <Box
        position={'fixed'}
        width={minimizedPlayerWidth}
        borderRadius={'1rem'}
        height={'auto'}
        background={'black'}
        overflow={'hidden'}
        bottom={{ base: '1rem', lg: '2rem' }}
        right={{ base: '1rem', lg: '2rem' }}
        border={'1px solid'}
        borderColor={'lightGrey.900'}
      >
        {children}
      </Box>
    );
  }
  return (
    <Box
      ref={containerRef}
      position={'relative'}
      width={'100%'}
      /** setting `overflow: hidden` here creates an unusual issue
       *  in iOS devices, when video player is returned to normal mode from Full Screen
       *  the controls hide behind the video player,
       *  preventing users from interacting with video player controls
       *
       *  For the above described reason: commenting this `overflow: hidden` here and keeping it commented for future references.
       */
      // overflow={'hidden'}
      borderRadius={0}
      height={'100%'}
      background={'black'}
      bottom={'unset'}
      right={'unset'}
      border={'none'}
    >
      {children}
    </Box>
  );
}
