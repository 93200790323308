import { FormSelect } from '@tigerhall/components';
import { getSelectedOrgId } from 'app/state';
import { useGetOrganisationGroupsQuery } from 'generated';
import { useAppSelector } from 'hooks';
import property from 'lodash/property';

type Props = {
  name: string;

  label?: string;

  placeholder?: string;
  /**
   * Hide the label
   * @default false
   */
  hideLabel?: boolean;

  required?: boolean;
  isMulti?: boolean;

  variant?: 'outline' | 'solid';
  colorScheme?: 'white' | 'teal';
};

export function OrganisationGroupSelect({
  name,
  label,
  required = false,
  isMulti = false,
  placeholder,
  hideLabel = false
}: Props) {
  const org = useAppSelector(getSelectedOrgId);

  const { data, error } = useGetOrganisationGroupsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      orgId: org as string
    }
  });

  if (error) {
    return null;
  }

  const options = data?.organisation?.groups || [];

  // This looks a bit weird but we cannot do a sort operation on options directly
  // because that data is read only
  const sorted = [...options].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <FormSelect
      name={name}
      label={label || (isMulti ? 'Organization Groups' : 'Organization Group')}
      isMulti={isMulti}
      isClearable={true}
      options={sorted}
      getOptionLabel={property('name')}
      getOptionValue={property('id')}
      placeholder={placeholder ?? 'Select group'}
      rules={{ required }}
      hideLabel={hideLabel}
    />
  );
}
