import { RouteObject } from 'react-router-dom';
import { ErrorBoundary } from 'components/tools/ErrorBoundary';

import VideoHistory from './screens/VideoHistory';
import { VideoForm } from './screens/VideoForm';
import VideoSummary from './screens/VideoSummary';

const routes: RouteObject[] = [
  {
    path: 'videos/',
    element: <VideoHistory />,
    children: [
      {
        path: ':id/summary',
        element: <VideoSummary />
      }
    ]
  },
  {
    path: 'videos/:idOrDraft/form',
    element: (
      <ErrorBoundary>
        <VideoForm />
      </ErrorBoundary>
    )
  }
];

export default routes;
