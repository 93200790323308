import {
  NotificationViewerType,
  useGetUserNewNotificationsCountQuery
} from 'generated';
import { useTabFocusHandler } from '@tigerhall/core';
import { useEffect } from 'react';

/**
 * Polls the server to get the new notifications count.
 * Only polls when the document is focused, and stops polling when the document is not focused.
 *
 * @param pollInterval The interval in milliseconds to poll the server. Default is 60_000 (1 minute)
 * @returns The new notifications count
 */
export function useNewNotificationsCountPoll(pollInterval: number = 60_000) {
  const { isFocused } = useTabFocusHandler();

  const {
    data: userNotificationsCount,
    stopPolling,
    startPolling
  } = useGetUserNewNotificationsCountQuery({
    pollInterval,
    variables: {
      filter: {
        viewerType: NotificationViewerType.Web
      }
    }
  });

  useEffect(() => {
    if (isFocused) {
      startPolling(pollInterval);
    } else {
      stopPolling();
    }
  }, [isFocused, pollInterval, startPolling, stopPolling]);

  const { newNotifications = 0 } = userNotificationsCount?.me || {};

  return newNotifications;
}
