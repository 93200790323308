import { type MouseEventHandler } from 'react';
import {
  Box,
  type BoxProps,
  Icon,
  IconButton,
  Tooltip
} from '@chakra-ui/react';
import { IconChevronDown } from '@tigerhall/icons';

interface MinimizePlayerButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  containerProps?: BoxProps;
}

export function MinimizePlayerButton({
  onClick,
  containerProps
}: MinimizePlayerButtonProps) {
  return (
    <Box
      position={'absolute'}
      top={{
        base: '2.5rem',
        lg: '0'
      }}
      right={0}
      px={{
        base: '1.5rem',
        lg: '3rem'
      }}
      py={{
        base: '0.5rem',
        lg: '2rem'
      }}
      {...containerProps}
    >
      <Tooltip label={'Minimize'} hasArrow>
        <IconButton
          aria-label={'minimize'}
          variant={'ghost'}
          display={'flex'}
          onClick={onClick}
          background={'blackAlpha.500'}
          border={'1px solid'}
          borderColor={'whiteAlpha.400'}
          w={10}
          h={10}
          icon={
            <Icon as={IconChevronDown} w={'2rem'} h={'2rem'} color={'white'} />
          }
        />
      </Tooltip>
    </Box>
  );
}
