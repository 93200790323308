import { Box, Flex, Spinner } from '@chakra-ui/react';
import { theme } from '@tigerhall/ui-components';
import * as React from 'react';
import {
  LabelList,
  Line,
  LineChart as CoreLineChart,
  LineProps,
  ResponsiveContainer,
  XAxis
} from 'recharts';

import { InnerBox, StatisticFallback } from '../components';

export type LineEntry = {
  name: string;
  value: number;
};

type Props = {
  title: string;
  data: LineEntry[];
  loading?: boolean;
};

const PRIMARY_COLOR = theme.colors.orange['600'];

const LINE_DOT = {
  stroke: PRIMARY_COLOR,
  strokeWidth: 2,
  fill: PRIMARY_COLOR
};

const LineChart: React.FC<Props> = ({ title, data, loading }) => (
  <InnerBox title={title} width="100%">
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mt={10}
    >
      {loading && (
        <Flex height={250} alignItems="center">
          <Spinner size="lg" />
        </Flex>
      )}
      {!loading && data?.length === 0 && <StatisticFallback height={250} />}
      {!loading && data?.length > 0 && (
        <ResponsiveContainer width="100%" height={250}>
          <CoreLineChart
            data={data}
            margin={{
              top: 20,
              right: 60,
              left: 60,
              bottom: 5
            }}
          >
            <XAxis dataKey="name" stroke="white" axisLine={false} />
            <Line
              dataKey="value"
              type="linear"
              stroke={PRIMARY_COLOR as LineProps['stroke']}
              dot={LINE_DOT as LineProps['dot']}
            >
              <LabelList
                dataKey="value"
                position="top"
                style={{ fill: 'white' }}
              />
            </Line>
          </CoreLineChart>
        </ResponsiveContainer>
      )}
    </Box>
  </InnerBox>
);

export { LineChart };
