import { Flex, Text } from '@chakra-ui/react';
import * as React from 'react';
import hero from 'modules/authentication/assets/hero.jpg';

export function Hero() {
  return (
    <Flex
      display={{ base: 'none', lg: 'flex' }}
      flex={1}
      backgroundImage={`linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${hero});`}
      backgroundSize="cover"
      backgroundPosition="center"
      justifyContent="center"
      alignItems="center"
      p="4rem"
    >
      <Text
        fontSize="2.125rem"
        color="white"
        fontWeight="800"
        lineHeight="4rem"
        maxW="50rem"
      >
        Where You Come From Should Never Get in the Way of Where You Want to Go
      </Text>
    </Flex>
  );
}
