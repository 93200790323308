import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetContentActivityCountQueryVariables = SchemaTypes.Exact<{
  id?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['ID']>;
}>;


export type GetContentActivityCountQuery = { __typename?: 'Query', contentCard: { __typename?: 'Ebook', id: string, numberOfCommenters: number } | { __typename?: 'Event', id: string, numberOfCommenters: number } | { __typename?: 'Expert' } | { __typename?: 'LearningPath' } | { __typename?: 'Podcast', id: string, numberOfCommenters: number } | { __typename?: 'Stream', id: string, numberOfCommenters: number } };

export type GetPostActivityCountQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
}>;


export type GetPostActivityCountQuery = { __typename?: 'Query', feedContentById: { __typename?: 'ContentActivity', id: string } | { __typename?: 'Post', id: string, numberOfCommenters: number, reactions: { __typename?: 'ReactionsSummary', totalCount: number } } | { __typename?: 'PostActivity', id: string } };


export const GetContentActivityCountDocument = gql`
    query GetContentActivityCount($id: ID) {
  contentCard(id: $id) {
    ... on Podcast {
      id
      numberOfCommenters
    }
    ... on Ebook {
      id
      numberOfCommenters
    }
    ... on Event {
      id
      numberOfCommenters
    }
    ... on Stream {
      id
      numberOfCommenters
    }
  }
}
    `;

/**
 * __useGetContentActivityCountQuery__
 *
 * To run a query within a React component, call `useGetContentActivityCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentActivityCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentActivityCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentActivityCountQuery(baseOptions?: Apollo.QueryHookOptions<GetContentActivityCountQuery, GetContentActivityCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentActivityCountQuery, GetContentActivityCountQueryVariables>(GetContentActivityCountDocument, options);
      }
export function useGetContentActivityCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentActivityCountQuery, GetContentActivityCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentActivityCountQuery, GetContentActivityCountQueryVariables>(GetContentActivityCountDocument, options);
        }
export type GetContentActivityCountQueryHookResult = ReturnType<typeof useGetContentActivityCountQuery>;
export type GetContentActivityCountLazyQueryHookResult = ReturnType<typeof useGetContentActivityCountLazyQuery>;
export type GetContentActivityCountQueryResult = Apollo.QueryResult<GetContentActivityCountQuery, GetContentActivityCountQueryVariables>;
export const GetPostActivityCountDocument = gql`
    query GetPostActivityCount($id: ID!) {
  feedContentById(id: $id, type: POST) {
    id
    ... on Post {
      id
      numberOfCommenters
      reactions {
        totalCount
      }
    }
  }
}
    `;

/**
 * __useGetPostActivityCountQuery__
 *
 * To run a query within a React component, call `useGetPostActivityCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostActivityCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostActivityCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPostActivityCountQuery(baseOptions: Apollo.QueryHookOptions<GetPostActivityCountQuery, GetPostActivityCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostActivityCountQuery, GetPostActivityCountQueryVariables>(GetPostActivityCountDocument, options);
      }
export function useGetPostActivityCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostActivityCountQuery, GetPostActivityCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostActivityCountQuery, GetPostActivityCountQueryVariables>(GetPostActivityCountDocument, options);
        }
export type GetPostActivityCountQueryHookResult = ReturnType<typeof useGetPostActivityCountQuery>;
export type GetPostActivityCountLazyQueryHookResult = ReturnType<typeof useGetPostActivityCountLazyQuery>;
export type GetPostActivityCountQueryResult = Apollo.QueryResult<GetPostActivityCountQuery, GetPostActivityCountQueryVariables>;