import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgCircleStar = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    focusable={false}
    className="th-icon"
    ref={ref}
    {...props}
  >
    <circle
      cx={9.014}
      cy={9.013}
      r={8.5}
      fill="#fff"
      stroke="#B2B8BB"
      transform="rotate(.086 9.014 9.013)"
    />
    <path
      fill="#FAB269"
      d="M8.66 5.274a.377.377 0 0 1 .718 0l.682 2.112c.05.156.195.261.359.261l2.219.004c.365 0 .516.468.22.682l-1.797 1.302a.377.377 0 0 0-.137.421l.682 2.112a.377.377 0 0 1-.58.42l-1.794-1.306a.377.377 0 0 0-.444 0l-1.797 1.3a.377.377 0 0 1-.58-.422l.69-2.11a.377.377 0 0 0-.137-.421L5.171 8.322a.377.377 0 0 1 .223-.682l2.218.003a.377.377 0 0 0 .36-.26l.688-2.11Z"
    />
  </svg>
);
SvgCircleStar.displayName = "IconCircleStar";
const ForwardRef = forwardRef(SvgCircleStar);
export default ForwardRef;
