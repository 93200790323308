import { Flex } from '@chakra-ui/react';
import * as React from 'react';

interface FeedItemContainerProps {
  children: React.ReactNode;
}

export function FeedItemContainer({ children }: FeedItemContainerProps) {
  return (
    <Flex
      as="article"
      width="100%"
      direction="column"
      backgroundColor="whiteAlpha.200"
      borderRadius="md"
      borderBottom="1px solid"
      borderBottomColor="darkGrey.300"
    >
      {children}
    </Flex>
  );
}
