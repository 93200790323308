import {
  LightMode,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';

import { LeavingReasonModalBody } from './components/LeavingReasonModalBody';

type LeaveReasonModalType = {
  setModalOpen: (open: boolean) => void;
};

const LeavingReasonModal = ({ setModalOpen }: LeaveReasonModalType) => (
  <LightMode>
    <Modal isOpen onClose={() => setModalOpen(false)} size="md" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody padding="2rem">
          <LeavingReasonModalBody />
        </ModalBody>
      </ModalContent>
    </Modal>
  </LightMode>
);
export default LeavingReasonModal;
