import { RefObject, useEffect, useRef, useState } from 'react';

/**
 * Extending Video Element with webkit method for `webkitEnterFullscreen`
 */
interface HTMLVideoElementWithWebkit extends HTMLVideoElement {
  webkitEnterFullscreen?(): Promise<void>;
}

interface UseFullscreenProps {
  /**
   * videoRef to convert the video into Full Screen in iOS devices
   */
  videoRef: RefObject<HTMLVideoElementWithWebkit>;
}

interface UseFullscreenReturn {
  /**
   * state value to change the Fullscreen icon
   */
  isFullscreen: boolean;

  /**
   * videoContainerRef to convert the container into Full Screen in web and android devices
   */
  videoContainerRef: RefObject<HTMLDivElement>;

  /**
   * Toggle between full screen and normal mode
   */
  onFullscreenToggle: () => void;
}

export function useFullscreen({
  videoRef
}: UseFullscreenProps): UseFullscreenReturn {
  const [isFullscreen, setIsFullscreen] = useState(false);

  /**
   * Making full screen to the player container instead of video to avoid the default controls on full screen mode
   *
   * But this only works with Web & Android devices
   *
   * Reason: `requestFullscreen` API is not supported by iOS
   */
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const onFullscreenToggle = () => {
    const videoContainer = videoContainerRef.current;

    if (!videoContainer) {
      return;
    }

    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
    } else if (videoContainer.requestFullscreen) {
      /**
       * Comes inside `if` condition when video.requestFullscreen API is present
       * For Web and mostly all Android Devices
       */
      videoContainer.requestFullscreen();
      setIsFullscreen(true);
    } else {
      /**
       * Comes to this if condition when video.requestFullscreen API is not present
       * all iOS devices
       *
       * Fullscreen exit on iOS device is handled by native controls, so no need to call `webkitExitFullscreen`
       *
       * Todo: when iOS start supporting `requestFullscreen` API we can remove this. iOS 17.3 might have the fullScreen API
       */
      const video = videoRef.current;
      video?.webkitEnterFullscreen?.();
    }
  };

  useEffect(() => {
    const exitHandler = () => {
      if (!document.fullscreenElement) {
        setIsFullscreen(false);
      }
    };

    document.addEventListener('fullscreenchange', exitHandler, false);

    return () => {
      document.removeEventListener('fullscreenchange', exitHandler);
    };
  }, []);

  return {
    isFullscreen,
    videoContainerRef,
    onFullscreenToggle
  };
}
