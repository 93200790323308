import { createListenerMiddleware } from '@reduxjs/toolkit';
import decodeJwt from 'jwt-decode';
import * as braze from '@braze/web-sdk';
import * as Sentry from '@sentry/react';
import {
  AUTH_COOKIE_NAME,
  AUTH_COOKIE_EXPIRE_HOURS,
  deleteCookie,
  setCookie
} from '@tigerhall/core';
import { AUTH_COOKIE_DOMAIN } from 'utils/constant';

import {
  setPlayingContent,
  logout,
  organisationSlice,
  setAccessToken
} from './state';
import { apolloClient } from '../api/apollo';
import type { RootState } from './reducer';

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: setAccessToken,
  effect: async (action) => {
    if (!action.payload.token) {
      return window.analytics.reset();
    }

    const claims: { sub: string; organisationId: string } = decodeJwt(
      action.payload.token
    );

    await window.analytics.identify(claims.sub);
    await window.analytics.group(claims.organisationId);

    // interestingly enough the docs says we shouldn't track the logout calls
    braze.changeUser(claims.sub);

    Sentry.setUser({
      id: claims.sub,
      segment: claims.organisationId
    });

    Sentry.setTag('organisationId', claims.organisationId);

    // to avoid setting cookie in multiple places
    setCookie(
      AUTH_COOKIE_NAME,
      `jwt ${action.payload.token}`,
      AUTH_COOKIE_EXPIRE_HOURS,
      AUTH_COOKIE_DOMAIN
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: logout,
  effect: async () => {
    Sentry.setUser(null);
    localStorage.clear();

    window.analytics.reset();

    // It's important that this call is last because it's async can could
    // otherwise cause problems with race conditions
    await apolloClient.clearStore();
    deleteCookie(AUTH_COOKIE_NAME, AUTH_COOKIE_DOMAIN);
  }
});

listenerMiddleware.startListening({
  type: organisationSlice.actions.setSelectedOrganisation.name,
  effect: (action, api) => {
    const { player } = api.getState() as RootState;

    if (player.content?.id) {
      api.dispatch(setPlayingContent(undefined));
    }
  }
});

listenerMiddleware.startListening({
  type: 'persist/REHYDRATE',
  effect: (action, api) => {
    const { user } = api.getState() as RootState;

    if (user.claims.sub) {
      braze.changeUser(user.claims.sub);

      Sentry.setUser({
        id: user.claims.sub,
        segment: user.claims.organisationId ?? undefined
      });

      Sentry.setTag('organisationId', user.claims.organisationId ?? undefined);
    }
  }
});
