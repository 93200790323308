import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';
import type { ModalProps } from '@chakra-ui/react';
import { IconCircleCheck } from '@tigerhall/icons';
import { type Language } from '@tigerhall/core';

import { TrackedButton } from '~/tracked';
import { type TranscriptReaderConfig } from './types';
import { Header } from './components/Header';
import { Options } from './components/Options';
import { Content, type ContentProps } from './components/Content';

interface Transcripts {
  language: string;
  transcripts: { page: string }[];
}

export interface TranscriptReaderProps
  extends Pick<ContentProps, 'markdownComponent'> {
  isOpen: boolean;
  onClose: () => void;

  transcripts: Transcripts;
  currentEpisodeIndex: number;

  transcriptLanguages: Pick<Language, 'name' | 'code'>[];
  setTranscriptLanguage: (code: string | null) => void;

  isFinished: boolean;
  onMarkAsDone: () => void;

  portalProps?: ModalProps['portalProps'];
}

export function TranscriptReader({
  isOpen,
  onClose,

  transcripts,
  currentEpisodeIndex,

  transcriptLanguages,
  setTranscriptLanguage,

  isFinished,
  onMarkAsDone,

  markdownComponent,
  portalProps
}: Readonly<TranscriptReaderProps>) {
  const parentRef = useRef<HTMLElement | HTMLDivElement>();

  const transcript = transcripts?.transcripts?.[currentEpisodeIndex]?.page;

  const [config, setConfig] = useState<TranscriptReaderConfig>({
    transcript,
    isDarkMode: true,
    isFontSizeSelectorOpen: false,
    isSelectLanguageSelectorOpen: !transcripts?.language,
    fontSize: 16,
    selectedLanguage: transcripts?.language
  });

  const { isDarkMode } = config;

  const modifiedPortalProps = portalProps ?? {
    appendToParentPortal: false,
    containerRef: parentRef as React.RefObject<HTMLElement>
  };

  /**
   * Sync config with transcripts when it changes
   */

  useEffect(() => {
    setConfig((prev) => ({
      ...prev,
      transcript,
      isSelectLanguageSelectorOpen: !transcripts?.language,
      selectedLanguage: transcripts?.language
    }));
  }, [transcripts, transcript]);

  return (
    <>
      <Box
        ref={parentRef as React.LegacyRef<HTMLDivElement>}
        position="relative"
        zIndex="skipLink"
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="full"
        portalProps={modifiedPortalProps}
      >
        <ModalOverlay backgroundColor="blackAlpha.800" onClick={onClose} />
        <ModalContent
          width="100%"
          maxH="100vh"
          maxW={{
            base: '100vw',
            md: '48rem'
          }}
          bg="none"
          alignSelf="baseline"
        >
          <ModalBody
            p="0"
            minW="100%"
            maxH={{
              base: '100vh',
              md: '90vh'
            }}
            bg="none"
            display="flex"
            flexDirection="column"
            margin={{ base: 'auto', md: '2rem auto' }}
            borderRadius={{ base: '0', md: '1rem' }}
            border={{ base: '', md: '0.06rem solid #4D4B46' }}
          >
            <>
              <Header config={config} setConfig={setConfig} />
              <Options
                config={config}
                setConfig={setConfig}
                availableLanguages={transcriptLanguages}
                setTranscriptLanguage={setTranscriptLanguage}
              />

              <Flex
                bg={isDarkMode ? 'darkGrey.400' : 'lightGrey.100'}
                p="1.5rem"
                flexDirection="column"
                justifyContent="space-between"
                flex={1}
                overflow="auto"
                borderBottomLeftRadius={{ base: '0', md: '1rem' }}
                borderBottomRightRadius={{ base: '0', md: '1rem' }}
              >
                <Content
                  config={config}
                  markdownComponent={markdownComponent}
                />
                <Flex justify="center" my="2rem">
                  <TrackedButton
                    name="MARK_AS_READ"
                    size="lg"
                    variant={isDarkMode ? 'outline' : 'outlineDark'}
                    isDisabled={isFinished}
                    minW={{
                      base: '100%',
                      md: '20rem'
                    }}
                    onClick={onMarkAsDone}
                    iconSpacing="1rem"
                    leftIcon={
                      <Icon
                        as={IconCircleCheck}
                        width="1rem"
                        height="1rem"
                        color="green.500"
                      />
                    }
                  >
                    {isFinished ? 'Marked as done' : 'Mark as Read'}
                  </TrackedButton>
                </Flex>
              </Flex>
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
