import {
  Flex,
  Icon,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tooltip
} from '@chakra-ui/react';
import { IconVolume, IconVolumeOff } from '@tigerhall/icons';

import { useMediaPlayerContext, useMediaState } from '~/player/context';

export function VolumeSlider() {
  const { setVolume } = useMediaPlayerContext();

  const volume = useMediaState((state) => state.volume);

  return (
    <Flex direction="row" gap="0.75rem" display={{ base: 'none', lg: 'flex' }}>
      <Tooltip label={volume === 0 ? 'Unmute' : 'Mute'} hasArrow>
        <IconButton
          aria-label={volume === 0 ? 'unmute' : 'mute'}
          variant="ghost"
          display="flex"
          icon={
            <Icon
              as={volume === 0 ? IconVolumeOff : IconVolume}
              w="1.5rem"
              h="1.5rem"
              color="darkGrey.50"
            />
          }
          onClick={(e) => {
            setVolume(volume === 0 ? 1 : 0);
          }}
        />
      </Tooltip>
      <Slider
        aria-label="Volume"
        defaultValue={volume * 100}
        min={0}
        max={100}
        step={1}
        onChangeEnd={(val) => {
          setVolume(val / 100);
        }}
        w="3.75rem"
        focusThumbOnChange
      >
        <SliderTrack bg="white" h="0.125rem">
          <SliderFilledTrack bg="white" h="0.125rem" />
        </SliderTrack>
        <SliderThumb bg="white" h="0.75rem" w="0.75rem" />
      </Slider>
    </Flex>
  );
}
