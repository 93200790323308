import { Box, Button, Text } from '@chakra-ui/react';
import { ReactionType, REACTIONS } from '@tigerhall/core';

export interface ReactionItemProps {
  /** The reaction type. */
  reaction: ReactionType;
  /**
   * The callback to handle the click event of the reaction.
   * @param reaction The reaction type.
   */
  onReactionClicked: (reaction: ReactionType) => void;
}

export function ReactionItem({
  reaction,
  onReactionClicked
}: ReactionItemProps) {
  const id = `reaction-${reaction}`;

  return (
    <Box position="relative" role="group">
      <Text
        id={id}
        as="span"
        position="absolute"
        background="black"
        color="white"
        px="0.75rem"
        py="0.25rem"
        top="-2.75rem"
        left="50%"
        transform="translateX(-50%)"
        borderRadius="full"
        fontSize="xs"
        opacity={0}
        transition="opacity"
        transitionDuration="0.3s"
        _groupHover={{ opacity: 1 }}
      >
        {REACTIONS[reaction].label}
      </Text>
      <Button
        variant="unstyled"
        aria-labelledby={id}
        p={0}
        display="flex"
        fontSize="2rem"
        minWidth="unset"
        transformOrigin="bottom"
        _groupHover={{
          transform: 'scale(1.5)'
        }}
        onClick={() => {
          onReactionClicked(reaction);
        }}
      >
        {REACTIONS[reaction].emoji}
      </Button>
    </Box>
  );
}
