import { Spinner } from '@chakra-ui/react';

import { LiveContentOverlayWrapper } from './LiveContentOverlayWrapper';

export function LiveContentOverlayLoader() {
  return (
    <LiveContentOverlayWrapper isLoaderOverlay={true}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="whiteAlpha.400"
        color="tigerOrange.600"
        width={{ base: '3rem', lg: '4.5rem' }}
        height={{ base: '3rem', lg: '4.5rem' }}
      />
    </LiveContentOverlayWrapper>
  );
}
