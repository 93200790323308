import * as React from 'react';
import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { IconLive } from '@tigerhall/icons';

import type { User } from './types';
import { UserBubble, UserInfo } from './components';

export interface VideoStoryProps {
  users: User[];
  featuredUser: User;

  isAlreadyConsumed: boolean;
  isLive: boolean;
  isBackstage: boolean;
  isProductDemoStream?: boolean;
}

export function VideoStory({
  users,
  featuredUser,
  isAlreadyConsumed,
  isLive = false,
  isBackstage = false,
  isProductDemoStream = false
}: Readonly<VideoStoryProps>) {
  const noOfExperts = users.length;
  const shouldShowNoOfParticipants = noOfExperts > 3;

  const paddingFactor = 8;
  const width =
    users.length > 1 ? Math.min(users.length, 3) * paddingFactor : 0;

  /**
   * this is to make sure that the featured host image is always the one
   * shown in front of the other users
   *
   * TODO: add a test for this case to avoid any regression
   */
  const portrayedUsers = [
    ...users.filter((user) => user.id !== featuredUser.id).slice(0, 2),
    featuredUser
  ];

  const getAlignmentForStreamLabel = () => {
    if (isProductDemoStream) {
      return { base: '1.25rem', md: '2.5rem' };
    }
    return {
      base: isLive ? '1.25rem' : '0.5rem',
      md: isLive ? '2.5rem' : '1.5rem'
    };
  };

  return (
    <Flex
      as="article"
      w={{
        base: '5.5rem',
        md: '10.25rem'
      }}
      h={{
        base: '8.5rem',
        md: '14.375rem'
      }}
      p={{
        md: '0.5rem'
      }}
      gap="0.5rem"
      direction="column"
      transition="all 100ms linear"
      _hover={{
        borderRadius: '0.5rem',
        backgroundColor: { base: 'unset', md: 'darkGrey.400' }
      }}
    >
      <Box
        position="relative"
        alignSelf="center"
        w={{
          base: '5.5rem',
          md: '9.25rem'
        }}
        h={{
          base: '5.5rem',
          md: '9.25rem'
        }}
      >
        {portrayedUsers.slice(0, 3).map((portrayedUser, index) => {
          return (
            <Box
              key={portrayedUser.id}
              position="absolute"
              top={`${paddingFactor * index * 1.5}px`}
              left={`${paddingFactor * index * 1.5}px`}
              w={{
                base: `calc(5.5rem - ${width}px)`,
                md: `calc(9.25rem - ${width}px)`
              }}
              h={{
                base: `calc(5.5rem - ${width}px)`,
                md: `calc(9.25rem - ${width}px)`
              }}
            >
              <UserBubble
                image={portrayedUser?.image.uri}
                isHighlighted={isLive || isBackstage || !isAlreadyConsumed}
                isProductDemoStream={isProductDemoStream}
              />
            </Box>
          );
        })}

        {shouldShowNoOfParticipants ? (
          <Flex
            position="absolute"
            w={{
              base: '1.25rem',
              md: '2rem'
            }}
            h={{
              base: '1.25rem',
              md: '2rem'
            }}
            top={{
              base: '1.0rem',
              md: '1.5rem'
            }}
            right="0"
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
            backgroundColor="tigerOrange.600"
          >
            <Text
              color="white"
              fontSize={{ base: '0.625rem', md: '0.875rem' }}
              fontWeight="bold"
              mb="0.125rem"
            >
              {`+${noOfExperts - 3}`}
            </Text>
          </Flex>
        ) : null}

        {isLive || isBackstage || isProductDemoStream ? (
          <Flex
            position="absolute"
            borderRadius="4px"
            h="1.25rem"
            px={{ base: '0.25rem', md: '0.5rem' }}
            py={{ base: '0.125rem', md: '0.25rem' }}
            bottom="-1"
            left={getAlignmentForStreamLabel()}
            bgColor={!isProductDemoStream ? 'tigerOrange.600' : 'darkGrey.300'}
            alignItems="center"
            justifyContent="center"
            alignSelf="flex-end"
          >
            {!isProductDemoStream ? (
              <>
                <Icon
                  as={IconLive}
                  color="white"
                  w={{ base: '0.75rem', md: '1rem' }}
                  h={{ base: '0.75rem', md: '1rem' }}
                />
                <Text
                  fontSize={{ base: '0.5rem', md: '0.75rem' }}
                  fontWeight="700"
                  color="white"
                  ml="0.5rem"
                >
                  {isLive ? 'LIVE' : 'BACKSTAGE'}
                </Text>
              </>
            ) : (
              <Text
                fontSize={{ base: '0.5rem', md: '0.75rem' }}
                fontWeight="700"
                color="white"
                textAlign="center"
              >
                🔥 Watch
              </Text>
            )}
          </Flex>
        ) : null}
      </Box>

      <UserInfo user={featuredUser} />
    </Flex>
  );
}
