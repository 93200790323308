import * as React from 'react';
import {
  Box,
  ButtonGroup,
  HStack,
  Progress,
  Text,
  VStack
} from '@chakra-ui/react';
import { type Content, type Expert } from '@tigerhall/core/lib/types';

import { Share } from '~/share';
import { LinkOverlay, useLink } from '~/link';
import { type ContentBoxBaseProps } from '../types';
import { ContentBoxTrailLength } from './ContentBoxTrailLength';
import { BookmarkButton } from './BookmarkButton';

type ContentBoxDetailsBaseProps = {
  typename: ContentBoxBaseProps['__typename'];
  name: ContentBoxBaseProps['name'];
  slug: ContentBoxBaseProps['slug'];
  category: Pick<ContentBoxBaseProps['categories'][0], 'slug' | 'name' | 'id'>;
  expert?: Pick<Expert, 'slug' | 'firstName' | 'lastName' | 'company' | 'id'>;
  progress?: number;
  disableUserProgress?: ContentBoxBaseProps['disableUserProgress'];
  contentPiecesCount?: number;
  as: 'bar' | 'card';
  id?: string;
  contentSegmentProps?: Content;
  onBookmarkClick?: ContentBoxBaseProps['onBookmarkClick'];
};

export interface ContentBoxDetailsLinksProps {
  getPathToContent: (
    content: Pick<ContentBoxDetailsProps, 'typename' | 'slug' | 'id'>
  ) => string;
  getPathToCategory: (category: ContentBoxDetailsProps['category']) => string;
  getPathToExpert: (expert: ContentBoxDetailsProps['expert']) => string;
  disableShareButton?: boolean;
}

type ContentBoxDetailsProps = ContentBoxDetailsBaseProps &
  ContentBoxDetailsLinksProps;

export function ContentBoxDetails({
  typename,
  name,
  slug,
  category,
  expert,
  progress = 0,
  disableUserProgress,
  contentPiecesCount,
  as = 'card',
  id,
  getPathToContent,
  getPathToCategory,
  getPathToExpert,
  contentSegmentProps,
  onBookmarkClick,
  disableShareButton = false
}: ContentBoxDetailsProps) {
  const { Link } = useLink();

  const isContentCard = as === 'card';

  const pathToContent = getPathToContent?.({ typename, slug, id });
  const pathToCategory = getPathToCategory?.(category);
  const pathToExpert =
    expert?.id || expert?.slug ? getPathToExpert?.(expert) : undefined;

  const expertName = `${expert?.firstName || ''} ${expert?.lastName || ''}`;

  return (
    <VStack spacing="0" width="100%">
      <VStack spacing="0" width="100%">
        {!!progress &&
        isContentCard &&
        typename !== 'Event' &&
        !disableUserProgress ? (
          <Progress width="100%" height="0.125rem" value={progress} />
        ) : null}
        {typename === 'LearningPath' && contentPiecesCount !== undefined && (
          <ContentBoxTrailLength contentPieces={contentPiecesCount} />
        )}
      </VStack>
      <VStack
        padding="2"
        paddingBottom={isContentCard ? '3' : '2'}
        flex="1"
        justifyContent="start"
        alignItems="start"
        spacing="0"
        width="100%"
      >
        <Text
          as="h3"
          fontSize="x-small"
          lineHeight="shorter"
          textTransform="uppercase"
          textAlign="left"
          color="darkGrey.200"
          noOfLines={1}
        >
          {pathToCategory ? (
            <Link color="darkGrey.200" href={pathToCategory}>
              {category.name}
            </Link>
          ) : (
            category.name
          )}
        </Text>

        <Text
          as="h2"
          fontWeight="bold"
          fontSize={{
            base: 'sm',
            lg: 'md'
          }}
          lineHeight="1.2"
          textAlign="left"
          color="black"
          paddingTop="0.5"
          noOfLines={3}
        >
          {pathToContent ? (
            <LinkOverlay color="black" href={pathToContent}>
              {name}
            </LinkOverlay>
          ) : (
            name
          )}
        </Text>

        {expert?.firstName ? (
          <Box paddingTop="2">
            <>
              <Text
                as="span"
                fontSize="xs"
                lineHeight="shorter"
                textAlign="left"
                color="darkGrey.300"
                noOfLines={1}
              >
                {pathToExpert ? (
                  <Link color="darkGrey.300" href={pathToExpert}>
                    {expertName}
                  </Link>
                ) : (
                  expertName
                )}
              </Text>
              <Text
                as="span"
                fontSize="xs"
                fontWeight="bold"
                lineHeight="shorter"
                textAlign="left"
                color="darkGrey.200"
                noOfLines={1}
              >
                {expert?.company || ''}
              </Text>
            </>
          </Box>
        ) : null}
        {!isContentCard ? (
          <HStack
            position="relative"
            width="100%"
            justifyContent="end"
            style={{
              marginTop: 'auto'
            }}
          >
            {!!progress && typename !== 'Event' && !disableUserProgress && (
              <Progress size="xs" value={progress} width="100%" rounded="sm" />
            )}
            <ButtonGroup justifyContent="end">
              <Share
                urlToShare={`${
                  typeof window !== 'undefined' ? window.location.origin : ''
                }${pathToContent}`}
                titleToShare={name}
                descriptionToShare={`${typename} - ${category?.name}`}
                content={contentSegmentProps}
                aria-label="Share"
                color="tigerOrange.600"
              />

              <BookmarkButton
                content={contentSegmentProps}
                onClick={onBookmarkClick}
              />
            </ButtonGroup>
          </HStack>
        ) : (
          <ButtonGroup
            position="absolute"
            bottom="1"
            right="1"
            justifyContent="end"
            spacing="0"
          >
            {!disableShareButton && (
              <Share
                urlToShare={`${
                  typeof window !== 'undefined' ? window.location.origin : ''
                }${pathToContent}`}
                titleToShare={name}
                descriptionToShare={`${typename} - ${category?.name}`}
                content={contentSegmentProps}
                aria-label="Share"
                color="tigerOrange.600"
              />
            )}

            <BookmarkButton
              content={contentSegmentProps}
              onClick={onBookmarkClick}
            />
          </ButtonGroup>
        )}
      </VStack>
    </VStack>
  );
}
