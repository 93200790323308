import { Box, Flex, Text } from '@chakra-ui/react';
import { useContentMeter, useFreeAccount } from 'hooks';
import { Button } from '@tigerhall/ui-components';
import useCommonModal from 'modules/application/hooks/useCommonModal';
import { useFlag } from '@unleash/proxy-client-react';
import { MODALTYPES } from 'components/ui/modals';
import { Flags } from '@tigerhall/core';

export const FREE_ACCOUNT_HEIGHT = '3rem';

const WEB_BOOK_MEETING_MODAL = `${
  import.meta.env.VITE_WEB_URL
}/?modal=book-meeting`;

export function FreeAccountInfo() {
  const {
    isInPreviewMode,
    freeTrialLeftDays,
    canSubscribe,
    hasFreeSubscription,
    hasTrialSubscription
  } = useFreeAccount();
  const isFreeTrialEnabled = useFlag(Flags.FreeTrial);

  const shouldShowBanner = hasFreeSubscription || hasTrialSubscription;

  const { openModal } = useCommonModal();
  const { meterValue, meterLimit } = useContentMeter();

  const onSubscribeButtonClicked = () => {
    if (shouldShowBanner && canSubscribe) {
      return openModal(MODALTYPES.Payment);
    } else {
      window.open(WEB_BOOK_MEETING_MODAL);
    }
  };

  if (shouldShowBanner) {
    const getPreviewModeStatusCopy = () => {
      if (isFreeTrialEnabled) {
        if (isInPreviewMode) {
          return (
            <>
              <Text fontSize="sm" fontWeight="bold " color="lightGrey.900">
                You Have Limited Access
              </Text>
              <Text fontSize="sm" fontWeight="medium" color="white" ml={1}>
                | Limited to short preview of content
              </Text>
            </>
          );
        }
        return (
          <>
            <Text fontSize="sm" fontWeight="bold " color="lightGrey.900">
              Free Trial Mode |
            </Text>
            <Text fontSize="sm" fontWeight="medium" color="soSunny.600" ml={1}>
              {`${Math.round(Number(freeTrialLeftDays))} Days left`}
            </Text>
          </>
        );
      }
      return (
        <>
          <Text fontSize="sm" fontWeight="bold " color="lightGrey.900">
            Free Trial Mode |
          </Text>
          <Text fontSize="sm" fontWeight="medium" color="soSunny.600" ml={1}>
            {`Monthly free content limit ${
              meterValue > 3 ? 3 : meterValue
            }/${meterLimit}`}
          </Text>
        </>
      );
    };

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        bg="teal.900"
        maxH={FREE_ACCOUNT_HEIGHT}
        height="100%"
        p="0.75rem 1.5rem"
      >
        <Flex>{getPreviewModeStatusCopy()}</Flex>
        <Button
          onClick={onSubscribeButtonClicked}
          text={canSubscribe ? 'Subscribe Now' : 'Discuss Enterprise Options'}
          variant="solid"
        />
      </Box>
    );
  }
  return null;
}
