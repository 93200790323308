function l(r, n = {}) {
  let s = !1;
  function a() {
    if (!s) {
      s = !0;
      return;
    }
    throw new Error(
      "[anatomy] .part(...) should only be called once. Did you mean to use .extend(...) ?"
    );
  }
  function i(...e) {
    a();
    for (const t of e)
      n[t] = c(t);
    return l(r, n);
  }
  function f(...e) {
    for (const t of e)
      t in n || (n[t] = c(t));
    return l(r, n);
  }
  function b() {
    return Object.fromEntries(
      Object.entries(n).map(([t, o]) => [t, o.selector])
    );
  }
  function j() {
    return Object.fromEntries(
      Object.entries(n).map(([t, o]) => [t, o.className])
    );
  }
  function c(e) {
    const u = `chakra-${(["container", "root"].includes(e ?? "") ? [r] : [r, e]).filter(Boolean).join("__")}`;
    return {
      className: u,
      selector: `.${u}`,
      toString: () => e
    };
  }
  return {
    parts: i,
    toPart: c,
    extend: f,
    selectors: b,
    classnames: j,
    get keys() {
      return Object.keys(n);
    },
    __type: {}
  };
}
export {
  l as anatomy
};
