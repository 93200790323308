import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import { LimitedUserFragmentDoc, NotificationPreferencesFragmentDoc, UserNotificationPreferenceFragmentDoc } from '../../../../../../../../../../../fragments/generated/user.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LiveGuestFragment = { __typename?: 'Expert', id: string, firstName: string, lastName: string, title: string, company?: string | null, isAuthenticatedUserFollowing: boolean, image: { __typename?: 'Image', id: string, uri: string } };

export type GetLiveStreamMoreInfoQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;


export type GetLiveStreamMoreInfoQuery = { __typename?: 'Query', stream?: { __typename: 'Stream', id: string, source: SchemaTypes.StreamSource, contentSegments: Array<{ __typename?: 'ContentSegment', paragraph: string, header: string }>, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, title: string, company?: string | null, isAuthenticatedUserFollowing: boolean, image: { __typename?: 'Image', id: string, uri: string } }>, hosts: Array<{ __typename?: 'StreamHost', expert: { __typename?: 'Expert', id: string, firstName: string, lastName: string, title: string, company?: string | null, isAuthenticatedUserFollowing: boolean, image: { __typename?: 'Image', id: string, uri: string } } }> } | null };

export type LiveBroadcastAgendaFragment = { __typename?: 'StreamAgendaBulletPoint', topic: string, description: string, isActive: boolean, secondsIntoRecordingTopicStartsAt?: number | null, estimatedStartTime?: any | null, speakers: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, company: string, jobTitle: string, isFollowing: boolean, isExpert: boolean, avatarImage: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string }, expertOrg?: { __typename?: 'Organisation', id: string } | null }> };

export type GetBroadcastAgendaSubscriptionVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
}>;


export type GetBroadcastAgendaSubscription = { __typename?: 'Subscription', stream?: { __typename: 'Stream', id: string, agenda: Array<{ __typename?: 'StreamAgendaBulletPoint', topic: string, description: string, isActive: boolean, secondsIntoRecordingTopicStartsAt?: number | null, estimatedStartTime?: any | null, speakers: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, company: string, jobTitle: string, isFollowing: boolean, isExpert: boolean, avatarImage: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string }, expertOrg?: { __typename?: 'Organisation', id: string } | null }> }> } | null };

export const LiveGuestFragmentDoc = gql`
    fragment LiveGuest on Expert {
  id
  firstName
  lastName
  image {
    id
    uri
  }
  title
  company
  isAuthenticatedUserFollowing
}
    `;
export const LiveBroadcastAgendaFragmentDoc = gql`
    fragment LiveBroadcastAgenda on StreamAgendaBulletPoint {
  topic
  description
  isActive
  secondsIntoRecordingTopicStartsAt
  estimatedStartTime
  speakers {
    ...LimitedUser
  }
}
    ${LimitedUserFragmentDoc}`;
export const GetLiveStreamMoreInfoDocument = gql`
    query GetLiveStreamMoreInfo($id: String!) {
  stream(id: $id) {
    __typename
    id
    source
    contentSegments {
      paragraph
      header
    }
    experts {
      ...LiveGuest
    }
    hosts {
      expert {
        ...LiveGuest
      }
    }
  }
}
    ${LiveGuestFragmentDoc}`;

/**
 * __useGetLiveStreamMoreInfoQuery__
 *
 * To run a query within a React component, call `useGetLiveStreamMoreInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveStreamMoreInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveStreamMoreInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLiveStreamMoreInfoQuery(baseOptions: Apollo.QueryHookOptions<GetLiveStreamMoreInfoQuery, GetLiveStreamMoreInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLiveStreamMoreInfoQuery, GetLiveStreamMoreInfoQueryVariables>(GetLiveStreamMoreInfoDocument, options);
      }
export function useGetLiveStreamMoreInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLiveStreamMoreInfoQuery, GetLiveStreamMoreInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLiveStreamMoreInfoQuery, GetLiveStreamMoreInfoQueryVariables>(GetLiveStreamMoreInfoDocument, options);
        }
export type GetLiveStreamMoreInfoQueryHookResult = ReturnType<typeof useGetLiveStreamMoreInfoQuery>;
export type GetLiveStreamMoreInfoLazyQueryHookResult = ReturnType<typeof useGetLiveStreamMoreInfoLazyQuery>;
export type GetLiveStreamMoreInfoQueryResult = Apollo.QueryResult<GetLiveStreamMoreInfoQuery, GetLiveStreamMoreInfoQueryVariables>;
export const GetBroadcastAgendaDocument = gql`
    subscription GetBroadcastAgenda($id: ID!) {
  stream(id: $id) {
    __typename
    id
    agenda {
      ...LiveBroadcastAgenda
    }
  }
}
    ${LiveBroadcastAgendaFragmentDoc}`;

/**
 * __useGetBroadcastAgendaSubscription__
 *
 * To run a query within a React component, call `useGetBroadcastAgendaSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetBroadcastAgendaSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBroadcastAgendaSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBroadcastAgendaSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetBroadcastAgendaSubscription, GetBroadcastAgendaSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetBroadcastAgendaSubscription, GetBroadcastAgendaSubscriptionVariables>(GetBroadcastAgendaDocument, options);
      }
export type GetBroadcastAgendaSubscriptionHookResult = ReturnType<typeof useGetBroadcastAgendaSubscription>;
export type GetBroadcastAgendaSubscriptionResult = Apollo.SubscriptionResult<GetBroadcastAgendaSubscription>;