import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import { UserContentTrackingFragmentDoc } from '../../../../../../../fragments/generated/userContentTracking.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPodcastForConnectedPodcastPlayerQueryVariables = SchemaTypes.Exact<{
  audioLanguage?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']>;
  id: SchemaTypes.Scalars['String'];
}>;


export type GetPodcastForConnectedPodcastPlayerQuery = { __typename?: 'Query', podcast?: { __typename: 'Podcast', id: string, name: string, preamble: string, upvoteCount: number, contentRatingByUser: SchemaTypes.ContentVote, userContentTracking: { __typename: 'UserContentTracking', id: string, current: number, currentPlacement: number, total: number, isAttending: boolean, isFinished: boolean, bookmarkedAt?: any | null, lastOpenedAt?: any | null, finishedAt?: any | null }, audioAvailableLanguages: Array<{ __typename?: 'Language', code: string, name: string }>, transcriptAvailableLanguages: Array<{ __typename?: 'Language', code: string, name: string }>, episodes: Array<{ __typename?: 'Episode', language?: string | null, name: string, audio?: { __typename?: 'AudioFile', uri: string, id: string, length: any } | null }>, categories: Array<{ __typename?: 'Category', id: string, name: string }>, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string }>, image: { __typename?: 'Image', uri: string, id: string } } | null };

export type GetPodcastTranscriptQueryVariables = SchemaTypes.Exact<{
  transcriptLanguage?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']>;
  id: SchemaTypes.Scalars['String'];
}>;


export type GetPodcastTranscriptQuery = { __typename?: 'Query', podcast?: { __typename: 'Podcast', id: string, userContentTracking: { __typename: 'UserContentTracking', id: string, current: number, currentPlacement: number, total: number, isAttending: boolean, isFinished: boolean, bookmarkedAt?: any | null, lastOpenedAt?: any | null, finishedAt?: any | null }, transcriptAvailableLanguages: Array<{ __typename?: 'Language', code: string, name: string }>, transcripts: { __typename?: 'PodcastTranscript', language: string, transcripts: Array<{ __typename?: 'Transcript', page: string }> } } | null };


export const GetPodcastForConnectedPodcastPlayerDocument = gql`
    query GetPodcastForConnectedPodcastPlayer($audioLanguage: String, $id: String!) {
  podcast(id: $id) {
    __typename
    id
    name
    preamble
    upvoteCount
    contentRatingByUser
    userContentTracking {
      ...UserContentTracking
    }
    audioAvailableLanguages {
      code
      name
    }
    transcriptAvailableLanguages {
      code
      name
    }
    episodes(language: $audioLanguage) {
      audio {
        uri
        id
        length
      }
      language
      name
    }
    categories {
      id
      name
    }
    experts {
      id
      firstName
      lastName
    }
    image {
      uri
      id
    }
  }
}
    ${UserContentTrackingFragmentDoc}`;

/**
 * __useGetPodcastForConnectedPodcastPlayerQuery__
 *
 * To run a query within a React component, call `useGetPodcastForConnectedPodcastPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPodcastForConnectedPodcastPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPodcastForConnectedPodcastPlayerQuery({
 *   variables: {
 *      audioLanguage: // value for 'audioLanguage'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPodcastForConnectedPodcastPlayerQuery(baseOptions: Apollo.QueryHookOptions<GetPodcastForConnectedPodcastPlayerQuery, GetPodcastForConnectedPodcastPlayerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPodcastForConnectedPodcastPlayerQuery, GetPodcastForConnectedPodcastPlayerQueryVariables>(GetPodcastForConnectedPodcastPlayerDocument, options);
      }
export function useGetPodcastForConnectedPodcastPlayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPodcastForConnectedPodcastPlayerQuery, GetPodcastForConnectedPodcastPlayerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPodcastForConnectedPodcastPlayerQuery, GetPodcastForConnectedPodcastPlayerQueryVariables>(GetPodcastForConnectedPodcastPlayerDocument, options);
        }
export type GetPodcastForConnectedPodcastPlayerQueryHookResult = ReturnType<typeof useGetPodcastForConnectedPodcastPlayerQuery>;
export type GetPodcastForConnectedPodcastPlayerLazyQueryHookResult = ReturnType<typeof useGetPodcastForConnectedPodcastPlayerLazyQuery>;
export type GetPodcastForConnectedPodcastPlayerQueryResult = Apollo.QueryResult<GetPodcastForConnectedPodcastPlayerQuery, GetPodcastForConnectedPodcastPlayerQueryVariables>;
export const GetPodcastTranscriptDocument = gql`
    query GetPodcastTranscript($transcriptLanguage: String, $id: String!) {
  podcast(id: $id) {
    __typename
    id
    userContentTracking {
      ...UserContentTracking
    }
    transcriptAvailableLanguages {
      code
      name
    }
    transcripts(language: $transcriptLanguage) {
      language
      transcripts {
        page
      }
    }
  }
}
    ${UserContentTrackingFragmentDoc}`;

/**
 * __useGetPodcastTranscriptQuery__
 *
 * To run a query within a React component, call `useGetPodcastTranscriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPodcastTranscriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPodcastTranscriptQuery({
 *   variables: {
 *      transcriptLanguage: // value for 'transcriptLanguage'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPodcastTranscriptQuery(baseOptions: Apollo.QueryHookOptions<GetPodcastTranscriptQuery, GetPodcastTranscriptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPodcastTranscriptQuery, GetPodcastTranscriptQueryVariables>(GetPodcastTranscriptDocument, options);
      }
export function useGetPodcastTranscriptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPodcastTranscriptQuery, GetPodcastTranscriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPodcastTranscriptQuery, GetPodcastTranscriptQueryVariables>(GetPodcastTranscriptDocument, options);
        }
export type GetPodcastTranscriptQueryHookResult = ReturnType<typeof useGetPodcastTranscriptQuery>;
export type GetPodcastTranscriptLazyQueryHookResult = ReturnType<typeof useGetPodcastTranscriptLazyQuery>;
export type GetPodcastTranscriptQueryResult = Apollo.QueryResult<GetPodcastTranscriptQuery, GetPodcastTranscriptQueryVariables>;