import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import { LiveGuestFragmentDoc } from '../../components/DrawerContent/components/DrawerTabs/components/LiveMoreInfoTab/queries/generated/liveMoreInfo.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLiveStreamInfoByIdQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;


export type GetLiveStreamInfoByIdQuery = { __typename?: 'Query', stream?: { __typename: 'Stream', id: string, name: string, source: SchemaTypes.StreamSource, preamble: string, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, title: string, company?: string | null, isAuthenticatedUserFollowing: boolean, image: { __typename?: 'Image', id: string, uri: string } }>, hosts: Array<{ __typename?: 'StreamHost', expert: { __typename?: 'Expert', id: string, firstName: string, lastName: string, title: string, company?: string | null, isAuthenticatedUserFollowing: boolean, image: { __typename?: 'Image', id: string, uri: string } } }> } | null };


export const GetLiveStreamInfoByIdDocument = gql`
    query GetLiveStreamInfoById($id: String!) {
  stream(id: $id) {
    __typename
    id
    name
    source
    preamble
    experts {
      ...LiveGuest
    }
    hosts {
      expert {
        ...LiveGuest
      }
    }
  }
}
    ${LiveGuestFragmentDoc}`;

/**
 * __useGetLiveStreamInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetLiveStreamInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveStreamInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveStreamInfoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLiveStreamInfoByIdQuery(baseOptions: Apollo.QueryHookOptions<GetLiveStreamInfoByIdQuery, GetLiveStreamInfoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLiveStreamInfoByIdQuery, GetLiveStreamInfoByIdQueryVariables>(GetLiveStreamInfoByIdDocument, options);
      }
export function useGetLiveStreamInfoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLiveStreamInfoByIdQuery, GetLiveStreamInfoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLiveStreamInfoByIdQuery, GetLiveStreamInfoByIdQueryVariables>(GetLiveStreamInfoByIdDocument, options);
        }
export type GetLiveStreamInfoByIdQueryHookResult = ReturnType<typeof useGetLiveStreamInfoByIdQuery>;
export type GetLiveStreamInfoByIdLazyQueryHookResult = ReturnType<typeof useGetLiveStreamInfoByIdLazyQuery>;
export type GetLiveStreamInfoByIdQueryResult = Apollo.QueryResult<GetLiveStreamInfoByIdQuery, GetLiveStreamInfoByIdQueryVariables>;