import { useEffect } from 'react';

export function DisableInstallAppPrompt() {
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', function (e) {
      e.preventDefault();
      return false;
    });
  }, []);

  return null;
}
