const zt = (c, e) => {
  const r = JSON.stringify(
    {
      type: "Typewriter JSON Schema Validation Error",
      description: `You made an analytics call (${c.event}) using Typewriter that doesn't match the Tracking Plan spec.`,
      errors: e
    },
    void 0,
    2
  );
  console.warn(r);
};
let a = () => window.analytics;
function n(c) {
  a = c.analytics ? () => c.analytics || window.analytics : a;
}
function l(c = {}) {
  return {
    ...c,
    context: {
      ...c.context || {},
      typewriter: {
        language: "typescript",
        version: "9.1.0"
      }
    }
  };
}
function i(c, e, r) {
  const t = a();
  t && t.track("Page Viewed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function u(c, e, r) {
  const t = a();
  t && t.track("Segment Consent Preference Updated", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function g(c, e, r) {
  const t = a();
  t && t.track("access_level_created", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function d(c, e, r) {
  const t = a();
  t && t.track("access_level_deleted", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function f(c, e, r) {
  const t = a();
  t && t.track("access_level_updated", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function y(c, e, r) {
  const t = a();
  t && t.track("account_created", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function x(c, e, r) {
  const t = a();
  t && t.track("account_deleted", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function k(c, e, r) {
  const t = a();
  t && t.track("add_note_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function _(c, e, r) {
  const t = a();
  t && t.track("add_to_calendar", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function v(c, e, r) {
  const t = a();
  t && t.track("add_to_schedule", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function w(c, e, r) {
  const t = a();
  t && t.track("admin_export_statistics", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function m(c, e, r) {
  const t = a();
  t && t.track("assignment_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function C(c, e, r) {
  const t = a();
  t && t.track("assignment_created", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function h(c, e, r) {
  const t = a();
  t && t.track("assignment_deleted", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function b(c, e, r) {
  const t = a();
  t && t.track("assignment_finished", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function S(c, e, r) {
  const t = a();
  t && t.track("assignment_made_inactive", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function P(c, e, r) {
  const t = a();
  t && t.track("assignment_started", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function T(c, e, r) {
  const t = a();
  t && t.track("attempted_to_consume_content_over_freemium_limit", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function O(c, e, r) {
  const t = a();
  t && t.track("book_a_spot_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function A(c, e, r) {
  const t = a();
  t && t.track("bookmark_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function B(c, e, r) {
  const t = a();
  t && t.track("button_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function E(c, e, r) {
  const t = a();
  t && t.track("canceled_button_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function F(c, e, r) {
  const t = a();
  t && t.track("card_swiped", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function U(c, e, r) {
  const t = a();
  t && t.track("carousel_changed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function L(c, e, r) {
  const t = a();
  t && t.track("carousel_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function R(c, e, r) {
  const t = a();
  t && t.track("clear_cache_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function V(c, e, r) {
  const t = a();
  t && t.track("comment_button_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function D(c, e, r) {
  const t = a();
  t && t.track("comment_deleted_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function j(c, e, r) {
  const t = a();
  t && t.track("comment_options_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function I(c, e, r) {
  const t = a();
  t && t.track("comment_reply_button_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function J(c, e, r) {
  const t = a();
  t && t.track("comment_sent", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function M(c, e, r) {
  const t = a();
  t && t.track("confirmation_button_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function N(c, e, r) {
  const t = a();
  t && t.track("content_autoplayed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Y(c, e, r) {
  const t = a();
  t && t.track("content_card_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function H(c, e, r) {
  const t = a();
  t && t.track("content_consumed_at_interval", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function W(c, e, r) {
  const t = a();
  t && t.track("content_finished", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function $(c, e, r) {
  const t = a();
  t && t.track("content_playback_state_changed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function z(c, e, r) {
  const t = a();
  t && t.track("content_position_seek", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function q(c, e, r) {
  const t = a();
  t && t.track("content_preview_completed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function G(c, e, r) {
  const t = a();
  t && t.track("content_searched", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function K(c, e, r) {
  const t = a();
  t && t.track("content_started", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Q(c, e, r) {
  const t = a();
  t && t.track("delete_account_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function X(c, e, r) {
  const t = a();
  t && t.track("drag_drop_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Z(c, e, r) {
  const t = a();
  t && t.track("dropdown_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function s(c, e, r) {
  const t = a();
  t && t.track("dropdown_selection_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function p(c, e, r) {
  const t = a();
  t && t.track("edit_email_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function o(c, e, r) {
  const t = a();
  t && t.track("emoji_picker_opened", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ee(c, e, r) {
  const t = a();
  t && t.track("emoji_picker_selection", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function te(c, e, r) {
  const t = a();
  t && t.track("enterprise_overlay", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ce(c, e, r) {
  const t = a();
  t && t.track("event_attended", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function re(c, e, r) {
  const t = a();
  t && t.track("event_booked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ae(c, e, r) {
  const t = a();
  t && t.track("feed_post_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ne(c, e, r) {
  const t = a();
  t && t.track("feed_post_impression", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function le(c, e, r) {
  const t = a();
  t && t.track("feed_post_started", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ie(c, e, r) {
  const t = a();
  t && t.track("fetch_more", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ue(c, e, r) {
  const t = a();
  t && t.track("file_upload_selected", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ge(c, e, r) {
  const t = a();
  t && t.track("follow_button_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function de(c, e, r) {
  const t = a();
  t && t.track("footer_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function fe(c, e, r) {
  const t = a();
  t && t.track("form_field_filled", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ye(c, e, r) {
  const t = a();
  t && t.track("form_submitted", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function xe(c, e, r) {
  const t = a();
  t && t.track("form_viewed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ke(c, e, r) {
  const t = a();
  t && t.track("free_content_count", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function _e(c, e, r) {
  const t = a();
  t && t.track("free_content_limit_reached", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ve(c, e, r) {
  const t = a();
  t && t.track("invited_to_join_organisation", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function we(c, e, r) {
  const t = a();
  t && t.track("language_changed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function me(c, e, r) {
  const t = a();
  t && t.track("like_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ce(c, e, r) {
  const t = a();
  t && t.track("live_event_ended_screen", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function he(c, e, r) {
  const t = a();
  t && t.track("livestream_joined", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function be(c, e, r) {
  const t = a();
  t && t.track("livestream_message_sent", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Se(c, e, r) {
  const t = a();
  t && t.track("login", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Pe(c, e, r) {
  const t = a();
  t && t.track("logout", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Te(c, e, r) {
  const t = a();
  t && t.track("marketing_video_completed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Oe(c, e, r) {
  const t = a();
  t && t.track("marketing_video_played", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ae(c, e, r) {
  const t = a();
  t && t.track("mention_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Be(c, e, r) {
  const t = a();
  t && t.track("mention_finished", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ee(c, e, r) {
  const t = a();
  t && t.track("mention_removed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Fe(c, e, r) {
  const t = a();
  t && t.track("mention_started", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ue(c, e, r) {
  const t = a();
  t && t.track("nav_bar_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Le(c, e, r) {
  const t = a();
  t && t.track("nav_button_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Re(c, e, r) {
  const t = a();
  t && t.track("new_invitation_accepted", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ve(c, e, r) {
  const t = a();
  t && t.track("new_notifications_count", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function De(c, e, r) {
  const t = a();
  t && t.track("new_registered_b2c_user", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function je(c, e, r) {
  const t = a();
  t && t.track("newsletter_subscribed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ie(c, e, r) {
  const t = a();
  t && t.track("note_cancel_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Je(c, e, r) {
  const t = a();
  t && t.track("note_close_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Me(c, e, r) {
  const t = a();
  t && t.track("note_delete_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ne(c, e, r) {
  const t = a();
  t && t.track("note_edit_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ye(c, e, r) {
  const t = a();
  t && t.track("note_saved_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function He(c, e, r) {
  const t = a();
  t && t.track("note_updated", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function We(c, e, r) {
  const t = a();
  t && t.track("notes_button_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function $e(c, e, r) {
  const t = a();
  t && t.track("notification_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ze(c, e, r) {
  const t = a();
  t && t.track("onboarding_completed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function qe(c, e, r) {
  const t = a();
  t && t.track("organisation_content_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ge(c, e, r) {
  const t = a();
  t && t.track("poll_vote_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ke(c, e, r) {
  const t = a();
  t && t.track("post_audience_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Qe(c, e, r) {
  const t = a();
  t && t.track("post_audience_selected", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Xe(c, e, r) {
  const t = a();
  t && t.track("post_created", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ze(c, e, r) {
  const t = a();
  t && t.track("post_options_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function se(c, e, r) {
  const t = a();
  t && t.track("product_tour_completed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function pe(c, e, r) {
  const t = a();
  t && t.track("product_tour_started", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function oe(c, e, r) {
  const t = a();
  t && t.track("profile_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function et(c, e, r) {
  const t = a();
  t && t.track("profile_updated", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function tt(c, e, r) {
  const t = a();
  t && t.track("promoted_to_thinkfluencer", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ct(c, e, r) {
  const t = a();
  t && t.track("pulled_to_refresh", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function rt(c, e, r) {
  const t = a();
  t && t.track("reaction_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function at(c, e, r) {
  const t = a();
  t && t.track("reactions_opened", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function nt(c, e, r) {
  const t = a();
  t && t.track("repost_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function lt(c, e, r) {
  const t = a();
  t && t.track("repost_created", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function it(c, e, r) {
  const t = a();
  t && t.track("repost_with_your_thoughts_created", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ut(c, e, r) {
  const t = a();
  t && t.track("search", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function gt(c, e, r) {
  const t = a();
  t && t.track("share_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function dt(c, e, r) {
  const t = a();
  t && t.track("share_externally_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ft(c, e, r) {
  const t = a();
  t && t.track("share_to_feed_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function yt(c, e, r) {
  const t = a();
  t && t.track("shared_content", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function xt(c, e, r) {
  const t = a();
  t && t.track("sign_out_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function kt(c, e, r) {
  const t = a();
  t && t.track("slider_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function _t(c, e, r) {
  const t = a();
  t && t.track("story_completed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function vt(c, e, r) {
  const t = a();
  t && t.track("story_started", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function wt(c, e, r) {
  const t = a();
  t && t.track("subscription_cancelled", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function mt(c, e, r) {
  const t = a();
  t && t.track("subscription_changed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ct(c, e, r) {
  const t = a();
  t && t.track("subscription_started", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function ht(c, e, r) {
  const t = a();
  t && t.track("support_email_link_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function bt(c, e, r) {
  const t = a();
  t && t.track("support_help_centre_link_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function St(c, e, r) {
  const t = a();
  t && t.track("tab_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Pt(c, e, r) {
  const t = a();
  t && t.track("table_row_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Tt(c, e, r) {
  const t = a();
  t && t.track("table_sort_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ot(c, e, r) {
  const t = a();
  t && t.track("thinkfluencer_first_content_published", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function At(c, e, r) {
  const t = a();
  t && t.track("thinkfluencer_for_one_year", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Bt(c, e, r) {
  const t = a();
  t && t.track("time_on_screen", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Et(c, e, r) {
  const t = a();
  t && t.track("toggle_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ft(c, e, r) {
  const t = a();
  t && t.track("toggle_orientation_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ut(c, e, r) {
  const t = a();
  t && t.track("topic_button_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Lt(c, e, r) {
  const t = a();
  t && t.track("topic_toggle_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Rt(c, e, r) {
  const t = a();
  t && t.track("trail_completion_info", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Vt(c, e, r) {
  const t = a();
  t && t.track("trail_finished", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Dt(c, e, r) {
  const t = a();
  t && t.track("user_access_level_changed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function jt(c, e, r) {
  const t = a();
  t && t.track("user_created", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function It(c, e, r) {
  const t = a();
  t && t.track("user_mentioned", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Jt(c, e, r) {
  const t = a();
  t && t.track("version_update_prompt_displayed", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Mt(c, e, r) {
  const t = a();
  t && t.track("version_updated", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Nt(c, e, r) {
  const t = a();
  t && t.track("view_reactions_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Yt(c, e, r) {
  const t = a();
  t && t.track("view_reposts_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Ht(c, e, r) {
  const t = a();
  t && t.track("web_vitals", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
function Wt(c, e, r) {
  const t = a();
  t && t.track("website_like_clicked", c || {}, { ...e, context: {
    ...(e == null ? void 0 : e.context) || {},
    typewriter: {
      language: "typescript",
      version: ""
    }
  } }, r);
}
const $t = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions: n,
  /**
   * Fires a 'PageViewed' track call.
   *
   * @param PageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pageViewed: i,
  /**
   * Fires a 'SegmentConsentPreferenceUpdated' track call.
   *
   * @param SegmentConsentPreferenceUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  segmentConsentPreferenceUpdated: u,
  /**
   * Fires a 'AccessLevelCreated' track call.
   *
   * @param AccessLevelCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accessLevelCreated: g,
  /**
   * Fires a 'AccessLevelDeleted' track call.
   *
   * @param AccessLevelDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accessLevelDeleted: d,
  /**
   * Fires a 'AccessLevelUpdated' track call.
   *
   * @param AccessLevelUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accessLevelUpdated: f,
  /**
   * Fires a 'AccountCreated' track call.
   *
   * @param AccountCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accountCreated: y,
  /**
   * Fires a 'AccountDeleted' track call.
   *
   * @param AccountDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accountDeleted: x,
  /**
   * Fires a 'AddNoteClicked' track call.
   *
   * @param AddNoteClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  addNoteClicked: k,
  /**
   * Fires a 'AddToCalendar' track call.
   *
   * @param AddToCalendar props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  addToCalendar: _,
  /**
   * Fires a 'AddToSchedule' track call.
   *
   * @param AddToSchedule props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  addToSchedule: v,
  /**
   * Fires a 'AdminExportStatistics' track call.
   *
   * @param AdminExportStatistics props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  adminExportStatistics: w,
  /**
   * Fires a 'AssignmentClicked' track call.
   *
   * @param AssignmentClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignmentClicked: m,
  /**
   * Fires a 'AssignmentCreated' track call.
   *
   * @param AssignmentCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignmentCreated: C,
  /**
   * Fires a 'AssignmentDeleted' track call.
   *
   * @param AssignmentDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignmentDeleted: h,
  /**
   * Fires a 'AssignmentFinished' track call.
   *
   * @param AssignmentFinished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignmentFinished: b,
  /**
   * Fires a 'AssignmentMadeInactive' track call.
   *
   * @param AssignmentMadeInactive props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignmentMadeInactive: S,
  /**
   * Fires a 'AssignmentStarted' track call.
   *
   * @param AssignmentStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  assignmentStarted: P,
  /**
   * Fires a 'AttemptedToConsumeContentOverFreemiumLimit' track call.
   *
   * @param AttemptedToConsumeContentOverFreemiumLimit props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  attemptedToConsumeContentOverFreemiumLimit: T,
  /**
   * Fires a 'BookASpotClicked' track call.
   *
   * @param BookASpotClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bookASpotClicked: O,
  /**
   * Fires a 'BookmarkClicked' track call.
   *
   * @param BookmarkClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bookmarkClicked: A,
  /**
   * Fires a 'ButtonClicked' track call.
   *
   * @param ButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  buttonClicked: B,
  /**
   * Fires a 'CanceledButtonClicked' track call.
   *
   * @param CanceledButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  canceledButtonClicked: E,
  /**
   * Fires a 'CardSwiped' track call.
   *
   * @param CardSwiped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  cardSwiped: F,
  /**
   * Fires a 'CarouselChanged' track call.
   *
   * @param CarouselChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  carouselChanged: U,
  /**
   * Fires a 'CarouselClicked' track call.
   *
   * @param CarouselClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  carouselClicked: L,
  /**
   * Fires a 'ClearCacheClicked' track call.
   *
   * @param ClearCacheClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  clearCacheClicked: R,
  /**
   * Fires a 'CommentButtonClicked' track call.
   *
   * @param CommentButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentButtonClicked: V,
  /**
   * Fires a 'CommentDeletedClicked' track call.
   *
   * @param CommentDeletedClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentDeletedClicked: D,
  /**
   * Fires a 'CommentOptionsClicked' track call.
   *
   * @param CommentOptionsClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentOptionsClicked: j,
  /**
   * Fires a 'CommentReplyButtonClicked' track call.
   *
   * @param CommentReplyButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentReplyButtonClicked: I,
  /**
   * Fires a 'CommentSent' track call.
   *
   * @param CommentSent props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentSent: J,
  /**
   * Fires a 'ConfirmationButtonClicked' track call.
   *
   * @param ConfirmationButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  confirmationButtonClicked: M,
  /**
   * Fires a 'ContentAutoplayed' track call.
   *
   * @param ContentAutoplayed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentAutoplayed: N,
  /**
   * Fires a 'ContentCardClicked' track call.
   *
   * @param ContentCardClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentCardClicked: Y,
  /**
   * Fires a 'ContentConsumedAtInterval' track call.
   *
   * @param ContentConsumedAtInterval props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentConsumedAtInterval: H,
  /**
   * Fires a 'ContentFinished' track call.
   *
   * @param ContentFinished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentFinished: W,
  /**
   * Fires a 'ContentPlaybackStateChanged' track call.
   *
   * @param ContentPlaybackStateChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentPlaybackStateChanged: $,
  /**
   * Fires a 'ContentPositionSeek' track call.
   *
   * @param ContentPositionSeek props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentPositionSeek: z,
  /**
   * Fires a 'ContentPreviewCompleted' track call.
   *
   * @param ContentPreviewCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentPreviewCompleted: q,
  /**
   * Fires a 'ContentSearched' track call.
   *
   * @param ContentSearched props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentSearched: G,
  /**
   * Fires a 'ContentStarted' track call.
   *
   * @param ContentStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentStarted: K,
  /**
   * Fires a 'DeleteAccountClicked' track call.
   *
   * @param DeleteAccountClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  deleteAccountClicked: Q,
  /**
   * Fires a 'DragDropClicked' track call.
   *
   * @param DragDropClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  dragDropClicked: X,
  /**
   * Fires a 'DropdownClicked' track call.
   *
   * @param DropdownClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  dropdownClicked: Z,
  /**
   * Fires a 'DropdownSelectionClicked' track call.
   *
   * @param DropdownSelectionClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  dropdownSelectionClicked: s,
  /**
   * Fires a 'EditEmailClicked' track call.
   *
   * @param EditEmailClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  editEmailClicked: p,
  /**
   * Fires a 'EmojiPickerOpened' track call.
   *
   * @param EmojiPickerOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  emojiPickerOpened: o,
  /**
   * Fires a 'EmojiPickerSelection' track call.
   *
   * @param EmojiPickerSelection props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  emojiPickerSelection: ee,
  /**
   * Fires a 'EnterpriseOverlay' track call.
   *
   * @param EnterpriseOverlay props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  enterpriseOverlay: te,
  /**
   * Fires a 'EventAttended' track call.
   *
   * @param EventAttended props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  eventAttended: ce,
  /**
   * Fires a 'EventBooked' track call.
   *
   * @param EventBooked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  eventBooked: re,
  /**
   * Fires a 'FeedPostClicked' track call.
   *
   * @param FeedPostClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  feedPostClicked: ae,
  /**
   * Fires a 'FeedPostImpression' track call.
   *
   * @param FeedPostImpression props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  feedPostImpression: ne,
  /**
   * Fires a 'FeedPostStarted' track call.
   *
   * @param FeedPostStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  feedPostStarted: le,
  /**
   * Fires a 'FetchMore' track call.
   *
   * @param FetchMore props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  fetchMore: ie,
  /**
   * Fires a 'FileUploadSelected' track call.
   *
   * @param FileUploadSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  fileUploadSelected: ue,
  /**
   * Fires a 'FollowButtonClicked' track call.
   *
   * @param FollowButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  followButtonClicked: ge,
  /**
   * Fires a 'FooterClicked' track call.
   *
   * @param FooterClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  footerClicked: de,
  /**
   * Fires a 'FormFieldFilled' track call.
   *
   * @param FormFieldFilled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  formFieldFilled: fe,
  /**
   * Fires a 'FormSubmitted' track call.
   *
   * @param FormSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  formSubmitted: ye,
  /**
   * Fires a 'FormViewed' track call.
   *
   * @param FormViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  formViewed: xe,
  /**
   * Fires a 'FreeContentCount' track call.
   *
   * @param FreeContentCount props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  freeContentCount: ke,
  /**
   * Fires a 'FreeContentLimitReached' track call.
   *
   * @param FreeContentLimitReached props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  freeContentLimitReached: _e,
  /**
   * Fires a 'InvitedToJoinOrganisation' track call.
   *
   * @param InvitedToJoinOrganisation props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  invitedToJoinOrganisation: ve,
  /**
   * Fires a 'LanguageChanged' track call.
   *
   * @param LanguageChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  languageChanged: we,
  /**
   * Fires a 'LikeClicked' track call.
   *
   * @param LikeClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  likeClicked: me,
  /**
   * Fires a 'LiveEventEndedScreen' track call.
   *
   * @param LiveEventEndedScreen props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  liveEventEndedScreen: Ce,
  /**
   * Fires a 'LivestreamJoined' track call.
   *
   * @param LivestreamJoined props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  livestreamJoined: he,
  /**
   * Fires a 'LivestreamMessageSent' track call.
   *
   * @param LivestreamMessageSent props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  livestreamMessageSent: be,
  /**
   * Fires a 'Login' track call.
   *
   * @param Login props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  login: Se,
  /**
   * Fires a 'Logout' track call.
   *
   * @param Logout props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  logout: Pe,
  /**
   * Fires a 'MarketingVideoCompleted' track call.
   *
   * @param MarketingVideoCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  marketingVideoCompleted: Te,
  /**
   * Fires a 'MarketingVideoPlayed' track call.
   *
   * @param MarketingVideoPlayed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  marketingVideoPlayed: Oe,
  /**
   * Fires a 'MentionClicked' track call.
   *
   * @param MentionClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mentionClicked: Ae,
  /**
   * Fires a 'MentionFinished' track call.
   *
   * @param MentionFinished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mentionFinished: Be,
  /**
   * Fires a 'MentionRemoved' track call.
   *
   * @param MentionRemoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mentionRemoved: Ee,
  /**
   * Fires a 'MentionStarted' track call.
   *
   * @param MentionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mentionStarted: Fe,
  /**
   * Fires a 'NavBarClicked' track call.
   *
   * @param NavBarClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  navBarClicked: Ue,
  /**
   * Fires a 'NavButtonClicked' track call.
   *
   * @param NavButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  navButtonClicked: Le,
  /**
   * Fires a 'NewInvitationAccepted' track call.
   *
   * @param NewInvitationAccepted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  newInvitationAccepted: Re,
  /**
   * Fires a 'NewNotificationsCount' track call.
   *
   * @param NewNotificationsCount props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  newNotificationsCount: Ve,
  /**
   * Fires a 'NewRegisteredB2CUser' track call.
   *
   * @param NewRegisteredB2CUser props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  newRegisteredB2CUser: De,
  /**
   * Fires a 'NewsletterSubscribed' track call.
   *
   * @param NewsletterSubscribed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  newsletterSubscribed: je,
  /**
   * Fires a 'NoteCancelClicked' track call.
   *
   * @param NoteCancelClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteCancelClicked: Ie,
  /**
   * Fires a 'NoteCloseClicked' track call.
   *
   * @param NoteCloseClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteCloseClicked: Je,
  /**
   * Fires a 'NoteDeleteClicked' track call.
   *
   * @param NoteDeleteClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteDeleteClicked: Me,
  /**
   * Fires a 'NoteEditClicked' track call.
   *
   * @param NoteEditClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteEditClicked: Ne,
  /**
   * Fires a 'NoteSavedClicked' track call.
   *
   * @param NoteSavedClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteSavedClicked: Ye,
  /**
   * Fires a 'NoteUpdated' track call.
   *
   * @param NoteUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteUpdated: He,
  /**
   * Fires a 'NotesButtonClicked' track call.
   *
   * @param NotesButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  notesButtonClicked: We,
  /**
   * Fires a 'NotificationClicked' track call.
   *
   * @param NotificationClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  notificationClicked: $e,
  /**
   * Fires a 'OnboardingCompleted' track call.
   *
   * @param OnboardingCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  onboardingCompleted: ze,
  /**
   * Fires a 'OrganisationContentClicked' track call.
   *
   * @param OrganisationContentClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  organisationContentClicked: qe,
  /**
   * Fires a 'PollVoteClicked' track call.
   *
   * @param PollVoteClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pollVoteClicked: Ge,
  /**
   * Fires a 'PostAudienceClicked' track call.
   *
   * @param PostAudienceClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postAudienceClicked: Ke,
  /**
   * Fires a 'PostAudienceSelected' track call.
   *
   * @param PostAudienceSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postAudienceSelected: Qe,
  /**
   * Fires a 'PostCreated' track call.
   *
   * @param PostCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postCreated: Xe,
  /**
   * Fires a 'PostOptionsClicked' track call.
   *
   * @param PostOptionsClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postOptionsClicked: Ze,
  /**
   * Fires a 'ProductTourCompleted' track call.
   *
   * @param ProductTourCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productTourCompleted: se,
  /**
   * Fires a 'ProductTourStarted' track call.
   *
   * @param ProductTourStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productTourStarted: pe,
  /**
   * Fires a 'ProfileClicked' track call.
   *
   * @param ProfileClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  profileClicked: oe,
  /**
   * Fires a 'ProfileUpdated' track call.
   *
   * @param ProfileUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  profileUpdated: et,
  /**
   * Fires a 'PromotedToThinkfluencer' track call.
   *
   * @param PromotedToThinkfluencer props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  promotedToThinkfluencer: tt,
  /**
   * Fires a 'PulledToRefresh' track call.
   *
   * @param PulledToRefresh props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pulledToRefresh: ct,
  /**
   * Fires a 'ReactionClicked' track call.
   *
   * @param ReactionClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  reactionClicked: rt,
  /**
   * Fires a 'ReactionsOpened' track call.
   *
   * @param ReactionsOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  reactionsOpened: at,
  /**
   * Fires a 'RepostClicked' track call.
   *
   * @param RepostClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  repostClicked: nt,
  /**
   * Fires a 'RepostCreated' track call.
   *
   * @param RepostCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  repostCreated: lt,
  /**
   * Fires a 'RepostWithYourThoughtsCreated' track call.
   *
   * @param RepostWithYourThoughtsCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  repostWithYourThoughtsCreated: it,
  /**
   * Fires a 'Search' track call.
   *
   * @param Search props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  search: ut,
  /**
   * Fires a 'ShareClicked' track call.
   *
   * @param ShareClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  shareClicked: gt,
  /**
   * Fires a 'ShareExternallyClicked' track call.
   *
   * @param ShareExternallyClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  shareExternallyClicked: dt,
  /**
   * Fires a 'ShareToFeedClicked' track call.
   *
   * @param ShareToFeedClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  shareToFeedClicked: ft,
  /**
   * Fires a 'SharedContent' track call.
   *
   * @param SharedContent props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sharedContent: yt,
  /**
   * Fires a 'SignOutClicked' track call.
   *
   * @param SignOutClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  signOutClicked: xt,
  /**
   * Fires a 'SliderClicked' track call.
   *
   * @param SliderClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sliderClicked: kt,
  /**
   * Fires a 'StoryCompleted' track call.
   *
   * @param StoryCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyCompleted: _t,
  /**
   * Fires a 'StoryStarted' track call.
   *
   * @param StoryStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyStarted: vt,
  /**
   * Fires a 'SubscriptionCancelled' track call.
   *
   * @param SubscriptionCancelled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionCancelled: wt,
  /**
   * Fires a 'SubscriptionChanged' track call.
   *
   * @param SubscriptionChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionChanged: mt,
  /**
   * Fires a 'SubscriptionStarted' track call.
   *
   * @param SubscriptionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionStarted: Ct,
  /**
   * Fires a 'SupportEmailLinkClicked' track call.
   *
   * @param SupportEmailLinkClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  supportEmailLinkClicked: ht,
  /**
   * Fires a 'SupportHelpCentreLinkClicked' track call.
   *
   * @param SupportHelpCentreLinkClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  supportHelpCentreLinkClicked: bt,
  /**
   * Fires a 'TabClicked' track call.
   *
   * @param TabClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tabClicked: St,
  /**
   * Fires a 'TableRowClicked' track call.
   *
   * @param TableRowClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tableRowClicked: Pt,
  /**
   * Fires a 'TableSortClicked' track call.
   *
   * @param TableSortClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tableSortClicked: Tt,
  /**
   * Fires a 'ThinkfluencerFirstContentPublished' track call.
   *
   * @param ThinkfluencerFirstContentPublished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  thinkfluencerFirstContentPublished: Ot,
  /**
   * Fires a 'ThinkfluencerForOneYear' track call.
   *
   * @param ThinkfluencerForOneYear props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  thinkfluencerForOneYear: At,
  /**
   * Fires a 'TimeOnScreen' track call.
   *
   * @param TimeOnScreen props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  timeOnScreen: Bt,
  /**
   * Fires a 'ToggleClicked' track call.
   *
   * @param ToggleClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  toggleClicked: Et,
  /**
   * Fires a 'ToggleOrientationClicked' track call.
   *
   * @param ToggleOrientationClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  toggleOrientationClicked: Ft,
  /**
   * Fires a 'TopicButtonClicked' track call.
   *
   * @param TopicButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  topicButtonClicked: Ut,
  /**
   * Fires a 'TopicToggleClicked' track call.
   *
   * @param TopicToggleClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  topicToggleClicked: Lt,
  /**
   * Fires a 'TrailCompletionInfo' track call.
   *
   * @param TrailCompletionInfo props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  trailCompletionInfo: Rt,
  /**
   * Fires a 'TrailFinished' track call.
   *
   * @param TrailFinished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  trailFinished: Vt,
  /**
   * Fires a 'UserAccessLevelChanged' track call.
   *
   * @param UserAccessLevelChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  userAccessLevelChanged: Dt,
  /**
   * Fires a 'UserCreated' track call.
   *
   * @param UserCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  userCreated: jt,
  /**
   * Fires a 'UserMentioned' track call.
   *
   * @param UserMentioned props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  userMentioned: It,
  /**
   * Fires a 'VersionUpdatePromptDisplayed' track call.
   *
   * @param VersionUpdatePromptDisplayed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  versionUpdatePromptDisplayed: Jt,
  /**
   * Fires a 'VersionUpdated' track call.
   *
   * @param VersionUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  versionUpdated: Mt,
  /**
   * Fires a 'ViewReactionsClicked' track call.
   *
   * @param ViewReactionsClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  viewReactionsClicked: Nt,
  /**
   * Fires a 'ViewRepostsClicked' track call.
   *
   * @param ViewRepostsClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  viewRepostsClicked: Yt,
  /**
   * Fires a 'WebVitals' track call.
   *
   * @param WebVitals props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  webVitals: Ht,
  /**
   * Fires a 'WebsiteLikeClicked' track call.
   *
   * @param WebsiteLikeClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  websiteLikeClicked: Wt
};
new Proxy($t, {
  get(c, e) {
    return typeof e == "string" && c.hasOwnProperty(e) ? c[e] : () => {
      console.warn(`⚠️  You made an analytics call (${String(e)}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`);
      const r = a();
      r && r.track(
        "Unknown Analytics Call Fired",
        {
          method: e
        },
        l()
      );
    };
  }
});
export {
  g as accessLevelCreated,
  d as accessLevelDeleted,
  f as accessLevelUpdated,
  y as accountCreated,
  x as accountDeleted,
  k as addNoteClicked,
  _ as addToCalendar,
  v as addToSchedule,
  w as adminExportStatistics,
  m as assignmentClicked,
  C as assignmentCreated,
  h as assignmentDeleted,
  b as assignmentFinished,
  S as assignmentMadeInactive,
  P as assignmentStarted,
  T as attemptedToConsumeContentOverFreemiumLimit,
  O as bookASpotClicked,
  A as bookmarkClicked,
  B as buttonClicked,
  E as canceledButtonClicked,
  F as cardSwiped,
  U as carouselChanged,
  L as carouselClicked,
  R as clearCacheClicked,
  V as commentButtonClicked,
  D as commentDeletedClicked,
  j as commentOptionsClicked,
  I as commentReplyButtonClicked,
  J as commentSent,
  M as confirmationButtonClicked,
  N as contentAutoplayed,
  Y as contentCardClicked,
  H as contentConsumedAtInterval,
  W as contentFinished,
  $ as contentPlaybackStateChanged,
  z as contentPositionSeek,
  q as contentPreviewCompleted,
  G as contentSearched,
  K as contentStarted,
  zt as defaultValidationErrorHandler,
  Q as deleteAccountClicked,
  X as dragDropClicked,
  Z as dropdownClicked,
  s as dropdownSelectionClicked,
  p as editEmailClicked,
  o as emojiPickerOpened,
  ee as emojiPickerSelection,
  te as enterpriseOverlay,
  ce as eventAttended,
  re as eventBooked,
  ae as feedPostClicked,
  ne as feedPostImpression,
  le as feedPostStarted,
  ie as fetchMore,
  ue as fileUploadSelected,
  ge as followButtonClicked,
  de as footerClicked,
  fe as formFieldFilled,
  ye as formSubmitted,
  xe as formViewed,
  ke as freeContentCount,
  _e as freeContentLimitReached,
  ve as invitedToJoinOrganisation,
  we as languageChanged,
  me as likeClicked,
  Ce as liveEventEndedScreen,
  he as livestreamJoined,
  be as livestreamMessageSent,
  Se as login,
  Pe as logout,
  Te as marketingVideoCompleted,
  Oe as marketingVideoPlayed,
  Ae as mentionClicked,
  Be as mentionFinished,
  Ee as mentionRemoved,
  Fe as mentionStarted,
  Ue as navBarClicked,
  Le as navButtonClicked,
  Re as newInvitationAccepted,
  Ve as newNotificationsCount,
  De as newRegisteredB2CUser,
  je as newsletterSubscribed,
  Ie as noteCancelClicked,
  Je as noteCloseClicked,
  Me as noteDeleteClicked,
  Ne as noteEditClicked,
  Ye as noteSavedClicked,
  He as noteUpdated,
  We as notesButtonClicked,
  $e as notificationClicked,
  ze as onboardingCompleted,
  qe as organisationContentClicked,
  i as pageViewed,
  Ge as pollVoteClicked,
  Ke as postAudienceClicked,
  Qe as postAudienceSelected,
  Xe as postCreated,
  Ze as postOptionsClicked,
  se as productTourCompleted,
  pe as productTourStarted,
  oe as profileClicked,
  et as profileUpdated,
  tt as promotedToThinkfluencer,
  ct as pulledToRefresh,
  rt as reactionClicked,
  at as reactionsOpened,
  nt as repostClicked,
  lt as repostCreated,
  it as repostWithYourThoughtsCreated,
  ut as search,
  u as segmentConsentPreferenceUpdated,
  n as setTypewriterOptions,
  gt as shareClicked,
  dt as shareExternallyClicked,
  ft as shareToFeedClicked,
  yt as sharedContent,
  xt as signOutClicked,
  kt as sliderClicked,
  _t as storyCompleted,
  vt as storyStarted,
  wt as subscriptionCancelled,
  mt as subscriptionChanged,
  Ct as subscriptionStarted,
  ht as supportEmailLinkClicked,
  bt as supportHelpCentreLinkClicked,
  St as tabClicked,
  Pt as tableRowClicked,
  Tt as tableSortClicked,
  Ot as thinkfluencerFirstContentPublished,
  At as thinkfluencerForOneYear,
  Bt as timeOnScreen,
  Et as toggleClicked,
  Ft as toggleOrientationClicked,
  Ut as topicButtonClicked,
  Lt as topicToggleClicked,
  Rt as trailCompletionInfo,
  Vt as trailFinished,
  Dt as userAccessLevelChanged,
  jt as userCreated,
  It as userMentioned,
  Jt as versionUpdatePromptDisplayed,
  Mt as versionUpdated,
  Nt as viewReactionsClicked,
  Yt as viewRepostsClicked,
  Ht as webVitals,
  Wt as websiteLikeClicked
};
