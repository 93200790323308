import {
  Box,
  Fade,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useBoolean
} from '@chakra-ui/react';
import { useRef, useState } from 'react';

import { useMediaPlayerContext, useMediaState } from '~/player/context';

/**
 * The progress bar for the minimized player.
 */
export function MinimizedSlider() {
  const { seekTo } = useMediaPlayerContext();

  const [progress, duration, customDuration] = useMediaState((state) => [
    state.progress,
    state.duration,
    state.customDuration
  ]);

  const max = customDuration || duration;

  const isSeeking = useRef(false);

  const [isHovering, { on, off }] = useBoolean();
  const [position, setSeekPosition] = useState(progress);

  return (
    <Box
      position="absolute"
      bottom="5.5rem"
      left="0"
      right="0"
      onMouseOver={on}
      onMouseOut={off}
    >
      <Slider
        aria-label="progress bar"
        defaultValue={progress}
        value={isSeeking.current ? position : progress}
        min={0}
        max={max}
        onChangeStart={() => {
          isSeeking.current = true;
        }}
        onChange={(val) => {
          setSeekPosition(val);
        }}
        onChangeEnd={(val) => {
          seekTo(val);
          isSeeking.current = false;
        }}
        focusThumbOnChange={false}
      >
        <SliderTrack bg="darkGrey.300">
          <SliderFilledTrack bg="tigerOrange.600" />
        </SliderTrack>

        <Fade in={isHovering}>
          <SliderThumb bg="tigerOrange.600" />
        </Fade>
      </Slider>
    </Box>
  );
}
