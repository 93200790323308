import { Center, Icon } from '@chakra-ui/react';
import { IconCircleCheck } from '@tigerhall/icons';
import { Typography } from '@tigerhall/ui-components';
import { motion } from 'framer-motion';

export function Success() {
  return (
    <>
      <Center
        as={motion.div}
        animate={{
          scale: [0.8, 1],
          opacity: [0, 1]
        }}
      >
        <Icon as={IconCircleCheck} color="green.600" boxSize="5rem" />
      </Center>
      <Center w="100%" mt="2.5rem" flexDirection="column">
        <Typography
          variant="heading2"
          color="white"
          fontWeight="700"
          textAlign="center"
          lineHeight={1.2}
        >
          You’re all set up!!
        </Typography>
        <Typography
          variant="heading5"
          color="lightGrey.900"
          fontWeight="400"
          mt="0.5rem"
          textAlign="center"
          lineHeight={1.5}
        >
          Check your inbox to download the invoice against your purchase
        </Typography>
        <Typography
          variant="heading5"
          color="white"
          fontWeight="700"
          mt="0.5rem"
          textAlign="center"
          lineHeight={1.5}
        >
          You’ll be redirected in 5 seconds...
        </Typography>
      </Center>
    </>
  );
}
