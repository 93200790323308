import { Flex, Stack, Text } from '@chakra-ui/react';
import { Button, StepIndicator } from '@tigerhall/ui-components';
import map from 'lodash/map';
import React from 'react';

import { type PrimaryButton } from '../ContentBox';
import ContentBoxTitle from './ContentBoxTitle';

function renderButtons(primaryButtons) {
  return map(primaryButtons, (item) => {
    const ButtonContainer = item?.container;

    const button = (
      <Button
        {...item}
        key={item.text}
        iconName={item.iconName}
        text={item.text}
        onClick={item.onClick}
        size="lg"
        isLoading={item.loading}
        data-cy={item.dataCy}
      />
    );
    return ButtonContainer ? (
      <ButtonContainer {...item.containerProps}>{button}</ButtonContainer>
    ) : (
      button
    );
  });
}

type WizardHeaderProps = {
  title?: string;
  description?: string;
  currentStep?: number;
  primaryButtons?: PrimaryButton[];
};

const WizardHeader = ({
  currentStep,
  title,
  primaryButtons,
  description
}: WizardHeaderProps) => (
  <Stack
    backgroundColor="darkGrey.400"
    mb="8"
    py="8"
    flex={1}
    borderRadius="10px"
    boxShadow="0px 3px 6px rgba(0, 0, 0, 0.160784);"
    justifyContent="center"
    alignItems="center"
  >
    {/* Step wizard */}
    <Flex mb="4">
      {currentStep ? (
        <StepIndicator
          showStepText={false}
          currentStep={currentStep}
          stepCount={3}
          width={220}
        />
      ) : null}
    </Flex>

    {/* Title */}
    <Flex flexDirection="row" justifyContent="center">
      <ContentBoxTitle mb={0}>Step {currentStep}:</ContentBoxTitle>
      <ContentBoxTitle color="White" ml={2} mb={0}>
        {title}
      </ContentBoxTitle>
    </Flex>

    {description ? (
      <Text
        fontSize="1rem"
        lineHeight="1.5rem"
        fontWeight={500}
        color="lightGrey.200"
        maxW={'46.25rem'}
        textAlign="center"
      >
        {description}
      </Text>
    ) : null}

    {primaryButtons ? (
      <Flex
        pt="8"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
      >
        {renderButtons(primaryButtons)}
      </Flex>
    ) : null}
  </Stack>
);

export default WizardHeader;
