import { create } from 'zustand';
import { type GenericRow } from '@tigerhall/components';
import { AudienceBoolFilterInput } from '@tigerhall/core';
import { devtools } from 'zustand/middleware';

import {
  AllReportTemplates,
  OneOfTheReportTemplates,
  ReportTemplateId
} from '~/modules/admin/modules/reportBuilder/reports';
import type { DynamicGroupConditionFormValues } from '~/modules/admin/modules/audiences/modals/ConditionsModal/types';

export interface UserFilters {
  filterByUsers: GenericRow[];
  filterByGroups: GenericRow[];
  filterByAudience: {
    api: AudienceBoolFilterInput;
    form: DynamicGroupConditionFormValues;
  } | null;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ReportBuilderWizardStoreState {
  /**
   * Name of the report
   */
  name: string;
  setName: (name: string) => void;

  /**
   * The instances of the report
   */
  template?: OneOfTheReportTemplates;

  /**
   * The template id from OneOfTheReportTemplates object
   */
  templateId?: ReportTemplateId;
  setTemplate: (templateId: ReportTemplateId) => void;

  /**
   * A set of the columns that have been selected to be part of the report
   */
  selectedColumns: Set<string>;
  setSelectedColumns: (columns: string[]) => void;

  /**
   * The users filters are who should be part of this report and they are standard across all reports
   */
  userFilters: UserFilters;
  setUserFilters: (filter: UserFilters) => void;

  /**
   * The value of the selected filers from the template after the user has done the select criteria step
   *
   * todo: the type should be the same as the generic in the ReportTemplate but i'm not good enough at typescript
   */
  reportFilters: Record<string, any>;
  setReportFilters: (filter: Record<string, any>) => void;

  /**
   * Utility function that helps determine which section to shown in the criteria step
   */
  userFilterSelectionMethod: 'users' | 'groups' | 'audience' | null;
  setUserFilterSelectionMethod: (
    method: 'users' | 'groups' | 'audience' | null
  ) => void;

  isCustomAudienceModalOpen: boolean;
  setIsCustomAudienceModalOpen: (isOpen: boolean) => void;

  /**
   * Restore the state to the default state
   */
  reset(): void;
}

const defaultState = {
  name: '',
  templateId: undefined,
  template: undefined,
  shouldIncludeArchivedUsers: false,
  selectedColumns: new Set<string>(),
  userFilters: {
    filterByUsers: [],
    filterByGroups: [],
    filterByAudience: null
  },
  reportFilters: {
    includeArchivedUsers: { value: 'false', label: 'No' }
  },
  userFilterSelectionMethod: null,
  isCustomAudienceModalOpen: false
};

/**
 * Zustand store that stores the state of the entire report builder wizard
 */
export const useReportBuilderWizardStore =
  create<ReportBuilderWizardStoreState>()(
    devtools((set) => ({
      ...defaultState,

      setName: (name: string) => set({ name }),

      setTemplate: (templateId: ReportTemplateId) => {
        const template = AllReportTemplates[templateId];

        return set((state) => ({
          ...defaultState,

          // Keep the name of the report
          name: state.name,

          template,
          templateId,

          // By default, lets mark all columns as selected when you change template
          selectedColumns: new Set(template.getColumns().map((c) => c.id))
        }));
      },

      setSelectedColumns: (columns: string[]) => {
        return set({
          selectedColumns: new Set(columns)
        });
      },

      setUserFilters: (filter: UserFilters) => {
        return set({
          userFilters: filter
        });
      },

      setReportFilters: (filter: Record<string, any>) => {
        return set({
          reportFilters: filter
        });
      },

      setUserFilterSelectionMethod: (method) => {
        if (method === null) {
          return set({
            userFilterSelectionMethod: method,
            isCustomAudienceModalOpen: false,
            userFilters: {
              filterByUsers: [],
              filterByGroups: [],
              filterByAudience: null
            }
          });
        }

        return set({
          isCustomAudienceModalOpen: method === 'audience',
          userFilterSelectionMethod: method
        });
      },

      setIsCustomAudienceModalOpen: (isOpen) => {
        return set({
          isCustomAudienceModalOpen: isOpen
        });
      },

      reset: () => {
        return set({
          ...defaultState
        });
      }
    }))
  );
