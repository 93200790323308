import { RouteObject } from 'react-router-dom';

import EventsHistory from './screens/EventsHistory';
import EventSuccess from './screens/EventSuccess';
import EventForm from './screens/EventForm';

const routes: RouteObject[] = [
  {
    path: 'events/',
    element: <EventsHistory />
  },
  {
    path: 'events/:idOrDraft/details',
    element: <EventForm />
  },
  {
    path: 'events/:idOrDraft/success',
    element: <EventSuccess />
  }
];

export default routes;
