import { Center, CenterProps, Icon, Text } from '@chakra-ui/react';
import { IconCirclesAi } from '@tigerhall/icons';

export interface AIBadgeProps extends CenterProps {
  variant?: 'mini' | 'miniWithIcon' | 'full';
}

const DARK_BLUE_AI = '#2300FA';
const PURPLE_AI = '#9E00FF';

const variantStyles = {
  mini: {
    height: 3.5,
    width: 3.5
  },
  miniWithIcon: {
    borderRadius: 100,
    px: 2,
    py: 1
  },
  full: {}
};

const baseStyles: CenterProps = {
  gap: 0.5,
  bg: `linear-gradient(102deg, ${DARK_BLUE_AI} 7.09%, ${PURPLE_AI} 100%)`,
  p: 1,
  borderRadius: 4,
  width: 'fit-content',
  height: 'fit-content',
  flexWrap: 'nowrap'
};

export function AIBadge({ variant = 'full', ...rest }: AIBadgeProps) {
  return (
    <Center {...baseStyles} {...variantStyles[variant]} {...rest}>
      <Text
        as={'span'}
        fontSize={variant === 'full' ? 'xs' : '0.625rem'}
        color={'white'}
        whiteSpace={'nowrap'}
      >
        {variant === 'full' ? 'AI Powered' : 'AI'}
      </Text>
      {variant !== 'mini' && (
        <Icon as={IconCirclesAi} boxSize={3} color={'white'} />
      )}
    </Center>
  );
}
