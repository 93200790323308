import { Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button } from '@chakra-ui/react';
import map from 'lodash/map';
import {
  Expert,
  Image,
  Stream,
  StreamStatus,
  StreamSource,
  UserContentTracking
} from '@tigerhall/core';
import { VideoStory, VideoStoryProps } from '@tigerhall/components';
import * as segment from '@tigerhall/analytics';
import { useLayoutDimensions } from 'hooks/useLayoutDimensions';
import { useContentActions } from 'modules/application/hooks/useContentActions';
import { useVideoAction } from 'modules/application/hooks/useVideoAction';

type MinimalExpert = Pick<
  Expert,
  'id' | 'firstName' | 'lastName' | 'title' | 'company'
> & { image?: Pick<Image, 'uri'> | null };

type MinimalStream = Pick<Stream, '__typename' | 'id' | 'source' | 'status'> & {
  experts: MinimalExpert[];
  hosts: {
    isFeatured: boolean;
    expert: MinimalExpert;
  }[];

  userContentTracking: Pick<
    UserContentTracking,
    'finishedAt' | 'currentPlacement'
  >;
};

export interface StreamWithProductDemo extends MinimalStream {
  isDemoStream?: boolean;
}

export interface ListProps {
  streams: StreamWithProductDemo[];
}

function getExpertsAndConvertToUser({
  source,
  hosts,
  experts
}: Pick<
  MinimalStream,
  'source' | 'hosts' | 'experts'
>): (VideoStoryProps['users'][0] & { isFeatured: boolean })[] {
  const users = (function () {
    if (
      source === StreamSource.Upload ||
      source === StreamSource.BroadcastCloudflareLive ||
      source === StreamSource.BroadcastAwsIvs
    ) {
      return experts.map((e, index: number) => ({
        ...e,
        jobTitle: e.title,
        isFeatured: index === 0
      }));
    }

    return hosts.map((h) => ({
      ...h.expert,
      isFeatured: h.isFeatured
    }));
  })();

  return users.map((user) => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    jobTitle: user.title,
    company: user.company ?? '',
    image: user.image ?? { uri: '' },
    isFeatured: user.isFeatured
  }));
}

export function List({ streams }: ListProps) {
  const { isMobile } = useLayoutDimensions();

  const { playMedia } = useContentActions();

  const { playVideo } = useVideoAction();

  function handleStreamClick(stream: StreamWithProductDemo) {
    const { id, isDemoStream } = stream;
    if (isDemoStream) {
      playVideo({ id, isDemo: true });
      return;
    }
    playMedia(stream);
  }

  return (
    <Swiper
      modules={[Navigation, Mousewheel]}
      spaceBetween={16}
      slidesPerView="auto"
      mousewheel={{
        forceToAxis: true,
        sensitivity: 1.5
      }}
      className="assignments-carousel"
      style={{
        paddingLeft: isMobile ? '1rem' : '2rem',
        paddingRight: isMobile ? '1rem' : '2rem'
      }}
    >
      {map(streams, (stream) => {
        const {
          id,
          source,
          status,
          experts,
          userContentTracking,
          hosts,
          isDemoStream
        } = stream;
        const users = getExpertsAndConvertToUser({ source, experts, hosts });

        if (users.length === 0) {
          return null;
        }

        let featuredUser = users.find((e) => e.isFeatured);
        if (!featuredUser) {
          featuredUser = users[0];
        }

        return (
          <SwiperSlide
            key={id}
            style={{
              width: 'fit-content',
              cursor: 'pointer'
            }}
          >
            <Button
              variant="unstyled"
              id={id}
              height="fit-content"
              className="stream-stories"
              onClick={() => {
                segment.buttonClicked({
                  location: location.pathname,
                  buttonName: 'STREAM_PLAYER',
                  queryParams: location?.search
                });

                handleStreamClick(stream);
              }}
            >
              <VideoStory
                users={users}
                featuredUser={featuredUser}
                isLive={status === StreamStatus.Live}
                isBackstage={status === StreamStatus.Backstage}
                isAlreadyConsumed={
                  userContentTracking.currentPlacement > 0 ||
                  !!userContentTracking.finishedAt
                }
                isProductDemoStream={isDemoStream}
              />
            </Button>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
