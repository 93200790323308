import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export function CountDownTimer() {
  const [countdownState, setCountdownState] = useState({
    secondsRemaining: 5,
    valueToReduce: 100
  });

  useEffect(() => {
    if (countdownState.secondsRemaining === 0) {
      return;
    }

    const intervalId = setInterval(() => {
      setCountdownState((prevState) => {
        if (prevState.secondsRemaining > 0) {
          const newSecondsRemaining = Math.max(
            prevState.secondsRemaining - 1,
            0
          );
          const newValueToReduce = prevState.valueToReduce - 20;
          return {
            secondsRemaining: newSecondsRemaining,
            valueToReduce: newValueToReduce
          };
        }

        return {
          secondsRemaining: 0,
          valueToReduce: 0
        };
      });
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup function
  }, [countdownState.secondsRemaining]);

  return (
    <CircularProgress
      value={countdownState.valueToReduce}
      size="24px"
      color="tigerOrange.600"
      thickness="4px"
    >
      <CircularProgressLabel
        color={'white'}
        fontSize={'sm'}
        fontWeight={'bold'}
      >
        {countdownState.secondsRemaining}
      </CircularProgressLabel>
    </CircularProgress>
  );
}
