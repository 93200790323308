import { useEffect, useCallback } from 'react';
import { useDisclosure } from '@chakra-ui/react';

export function useTranscriptDisclosure() {
  const { isOpen: isOpenTranscriptReader, onClose, onOpen } = useDisclosure();

  const replaceParams = (params: URLSearchParams) => {
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${params.toString()}`
    );
  };

  const addTranscriptParam = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    params.set('transcript', 'true');
    replaceParams(params);
  }, []);

  const removeTranscriptParam = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    params.delete('transcript');
    replaceParams(params);
  }, []);

  const onOpenTranscriptReader = useCallback(() => {
    addTranscriptParam();
    onOpen();
  }, [addTranscriptParam, onOpen]);

  const onCloseTranscriptReader = useCallback(() => {
    removeTranscriptParam();
    onClose();
  }, [removeTranscriptParam, onClose]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const shouldOpenTranscriptReader = params.get('transcript') === 'true';

    if (shouldOpenTranscriptReader && !isOpenTranscriptReader) {
      onOpenTranscriptReader();
    }
  }, [isOpenTranscriptReader, onOpenTranscriptReader]);

  return {
    isOpenTranscriptReader,
    onCloseTranscriptReader,
    onOpenTranscriptReader,
    addTranscriptParam,
    removeTranscriptParam
  };
}
