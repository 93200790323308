import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { resizeImage } from '@tigerhall/core';
import { Image } from '@tigerhall/core/lib/types';
import {
  addDraftAssignmentMeta,
  clearDraft,
  getContentCards,
  getDraftIsEmpty,
  getDraftLearningPathContentIds,
  getDraftMeta,
  LearningPathState,
  setDraftCustomTrailContentIds
} from 'app/state';
import { useAppDispatch, useAppSelector } from 'hooks';

const CLEAR_STATE_DEBOUNCE = 100;

type DraftMeta = LearningPathState['draft']['meta'];

export default function useDraftLearningPaths() {
  const contentIds = useAppSelector(getDraftLearningPathContentIds);
  const draftMeta = useAppSelector(getDraftMeta);
  const dispatch = useAppDispatch();

  const { idOrDraft } = useParams<{ idOrDraft: string }>();
  const isDraftEmpty = useAppSelector(getDraftIsEmpty);
  const contents = useAppSelector((state) =>
    getContentCards(state, contentIds)
  );

  function validateDraftMeta(draft: DraftMeta): draft is Required<DraftMeta> {
    return (
      !isEmpty(draft.name) &&
      !isEmpty(draft.category) &&
      !isEmpty(draft.image) &&
      !isEmpty(draft.description)
    );
  }

  const actions = useMemo(
    () => ({
      setContentIds: (ids: string[]) => {
        dispatch(setDraftCustomTrailContentIds(ids));
      },
      addMeta: (meta: Partial<DraftMeta>) => {
        dispatch(addDraftAssignmentMeta(meta));
      },
      setDefaultImage: () => {
        /** if we have no content, and we already have image on draftMeta, then we can't set default image **/

        if (contentIds.length <= 0 || draftMeta.image) {
          return;
        }

        const defaultImage = contents[0]?.image as Image | null;

        dispatch(
          addDraftAssignmentMeta({
            ...draftMeta,
            image: {
              id: defaultImage!.id,
              url: resizeImage({
                url: defaultImage?.uri || '',
                width: 250
              }),
              isDefault: true
            }
          })
        );
      },
      clearDraft: debounce(() => {
        dispatch(clearDraft());
      }, CLEAR_STATE_DEBOUNCE)
    }),
    [contentIds.length, contents, dispatch, draftMeta]
  );

  const isValidDraftMeta = validateDraftMeta(draftMeta);
  const isValidDraft = isValidDraftMeta && contentIds.length > 0;

  return {
    idOrDraft,
    contentIds,
    actions,
    draftMeta,
    contents,
    isDraftEmpty,
    isValidDraftMeta,
    isValidDraft
  };
}
