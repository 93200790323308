import { Skeleton } from '@chakra-ui/react';
import * as React from 'react';

export function FeaturedContentCardSkeleton() {
  return (
    <Skeleton
      height={{
        base: '14rem',
        lg: '26.25rem'
      }}
      width={{
        base: '18.75',
        lg: '35rem'
      }}
      rounded="2xl"
      overflow="hidden"
      flexShrink={0}
    />
  );
}
