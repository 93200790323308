import values from 'lodash/values';
import { useMemo, useState } from 'react';
import { useDeepCompareEffect } from '@tigerhall/core';

import type { LinkMap } from '../utils';
import { filterLinksToShow } from '../utils';

interface UseLimitLinkPreviewArgs {
  urls: string[];
  noOfPreviewToLimit: number;
}

export function useLimitLinkPreview({
  urls,
  noOfPreviewToLimit
}: Readonly<UseLimitLinkPreviewArgs>) {
  const [urlsMap, setUrlsMap] = useState<Record<string, LinkMap>>({});

  const linksToShow = useMemo(() => {
    return filterLinksToShow(values(urlsMap), noOfPreviewToLimit);
  }, [noOfPreviewToLimit, urlsMap]);

  useDeepCompareEffect(() => {
    setUrlsMap((prevUrlsMap) => {
      return urls.reduce<Record<string, LinkMap>>((acc, each) => {
        acc[each] = {
          url: each,
          isRemoved: prevUrlsMap[each]?.isRemoved ?? false
        };
        return acc;
      }, {});
    });
  }, [urls]);

  return {
    linksToShow,
    urlsMap,
    setUrlsMap
  };
}
