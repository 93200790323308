import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { Marquee } from '@tigerhall/components';
import {
  IconCircle,
  IconCircleCheckFilled,
  IconProgressCircle
} from '@tigerhall/icons';
import { getPlayingContent } from 'app/state';
import { useAppSelector } from 'hooks';
import { useEbookAction } from 'modules/application/hooks/useEbookAction';
import { usePodcastAction } from 'modules/application/hooks/usePodcastAction';
import { useVideoAction } from 'modules/application/hooks/useVideoAction';
import type {
  ForwardRefExoticComponent,
  RefAttributes,
  SVGAttributes
} from 'react';

import { QueueItemDetails } from './QueueItemDetails';
import type { PlaylistContent } from '~/hooks/useAutoplayContent/types';

interface QueueItemProps {
  isContentPartOfTrail?: boolean;
  content: PlaylistContent;
  contentIndex: string;
  isTrailHeader?: boolean;
}

type IconType = ForwardRefExoticComponent<
  SVGAttributes<SVGElement> & RefAttributes<SVGSVGElement>
>;

export function QueueItem({
  content,
  contentIndex,
  isContentPartOfTrail,
  isTrailHeader = false
}: Readonly<QueueItemProps>) {
  const playingContent = useAppSelector(getPlayingContent);

  const contentStatus = {
    isPlaying: false,
    isFinished: false,
    isNotVisited: true
  };

  if (content.__typename === 'LearningPath') {
    contentStatus.isFinished = !content.contentCards.some(
      (item) => !item.userContentTracking.isFinished
    );
    contentStatus.isPlaying = content.contentCards.some(
      (trailCard) => trailCard.id === playingContent?.id
    );

    contentStatus.isNotVisited =
      !contentStatus.isFinished && !contentStatus.isPlaying;
  } else {
    contentStatus.isFinished = content.userContentTracking.isFinished;
    contentStatus.isPlaying = content.id === playingContent?.id;
    contentStatus.isNotVisited =
      !contentStatus.isFinished && !contentStatus.isPlaying;
  }

  let icon: IconType;
  let iconColor: string;

  switch (true) {
    case contentStatus.isFinished:
      icon = IconCircleCheckFilled;
      iconColor = 'green.500';
      break;
    case contentStatus.isPlaying:
      icon = IconProgressCircle;
      iconColor = 'white';
      break;
    default:
      icon = IconCircle;
      iconColor = 'lightGrey.900';
      break;
  }

  const title = contentStatus.isPlaying ? (
    <Marquee
      fontSize={'sm'}
      fontWeight={'bold'}
      textAlign={'start'}
      color={'white'}
    >
      {content?.name}
    </Marquee>
  ) : (
    <Text
      fontSize={'sm'}
      fontWeight={'bold'}
      color={'white'}
      textAlign={'start'}
      noOfLines={1}
    >
      {content?.name}
    </Text>
  );

  const { playVideo } = useVideoAction();
  const { playPodcast } = usePodcastAction();
  const { startEbook } = useEbookAction();

  function onQueueItemClick() {
    if (content?.__typename === 'Podcast') {
      playPodcast({ id: content.id });
    } else if (content?.__typename === 'Stream') {
      playVideo({ id: content.id });
    } else if (content?.__typename === 'Ebook') {
      startEbook({ id: content.id });
    }
  }

  return (
    <Flex
      w={'full'}
      gap={2}
      px={6}
      py={4}
      cursor={'pointer'}
      alignItems={'center'}
      as={isTrailHeader ? 'div' : 'button'}
      onClick={isTrailHeader ? undefined : onQueueItemClick}
      _hover={isTrailHeader ? {} : { bg: 'darkGrey.400' }}
      bg={
        contentStatus.isPlaying && !isTrailHeader ? 'darkGrey.400' : undefined
      }
    >
      {isContentPartOfTrail ? (
        <Box
          minW={4}
          minH={4}
          borderLeftColor={'darkGrey.200'}
          borderLeftWidth={2}
          borderBottomWidth={2}
          borderBottomLeftRadius={4}
          borderBottomColor={'darkGrey.200'}
          mb={4}
        />
      ) : null}
      <Text
        minW={isContentPartOfTrail ? 9 : 5}
        fontSize={'xs'}
        fontWeight={'bold'}
        textAlign={'left'}
      >
        {contentIndex}
      </Text>
      <Flex direction={'column'} overflow={'hidden'} w={'full'} gap={3}>
        <Flex w={'full'} alignItems={'center'} gap={3}>
          <Icon
            as={icon}
            width={'1.125rem'}
            height={'1.125rem'}
            color={iconColor}
          />
          {title}
        </Flex>
        <QueueItemDetails item={content} />
      </Flex>
    </Flex>
  );
}
