import { Flex, FlexProps } from '@chakra-ui/react';

interface OverlayFooterContainerProps extends FlexProps {
  children: React.ReactNode;
}

/**
 * Container for the overlay footer.
 * Its objective is simply to provide a consistent style and spacing
 * wherever the `Overlay` is used.
 */
export function OverlayFooterContainer({
  children,
  ...props
}: OverlayFooterContainerProps) {
  return (
    <Flex
      width={'100%'}
      maxWidth={'50rem'}
      mx={'auto'}
      p={{
        base: '1.5rem 1.5rem 2rem 1.5rem',
        lg: '1.5rem 3.5rem 2rem 3.5rem'
      }}
      flexDirection={'column'}
      gap={'1rem'}
      {...props}
    >
      {children}
    </Flex>
  );
}
