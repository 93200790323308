import { StreamSource } from '@tigerhall/core';

interface GetUrlToShareForLiveContentArgs {
  id: string;
  source: StreamSource;
}

export function getUrlToShareForLiveContent({
  id,
  source
}: GetUrlToShareForLiveContentArgs) {
  let streamType = '';

  switch (source) {
    case StreamSource.BroadcastAwsIvs:
    case StreamSource.BroadcastCloudflareLive:
      streamType = 'broadcast';
      break;

    case StreamSource.LivestreamAgora:
      streamType = 'livestream';
      break;

    default:
      streamType = 'unknown-type';
      break;
  }

  return `${window.location.origin}/home?${streamType}=${id}`;
}

type GetExpertsArgs<Host, Expert> = {
  source?: StreamSource;
  hosts?: Host;
  experts?: Expert;
};

export function getExperts<
  Host extends { expert: Expert[0] }[],
  Expert extends unknown[]
>({ source, hosts, experts }: GetExpertsArgs<Host, Expert>) {
  if (source === StreamSource.LivestreamAgora) {
    return hosts?.map(({ expert }) => expert) ?? [];
  }
  return experts ?? [];
}
