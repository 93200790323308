import * as React from 'react';
import {
  IconMovie,
  IconHeadphones,
  IconBook,
  IconRoute,
  IconTicket,
  IconLive
} from '@tigerhall/icons';
import { Box, BoxProps, Icon, type IconProps } from '@chakra-ui/react';
import {
  Content,
  LearningPath,
  Event,
  EventType
} from '@tigerhall/core/lib/types';

interface EventTypeIconProps {
  /** Type of the content */
  typename: Extract<Content['__typename'], 'Event'>;
  /** Type of the event */
  eventType?: Event['eventType'];
}

interface NoEventTypeIconProps {
  /** Type of the content */
  typename:
    | Exclude<Content['__typename'], 'Event'>
    | LearningPath['__typename'];
  /** Type of the event */
  eventType?: never;
}

type ContentTypeIconProps = {
  iconProps?: IconProps;
};

type ContentTypeBadgeProps = BoxProps &
  ContentTypeIconProps &
  (EventTypeIconProps | NoEventTypeIconProps);

function ContentTypeIcon({
  typename,
  iconProps
}: ContentTypeIconProps & NoEventTypeIconProps) {
  switch (typename) {
    case 'Podcast':
      return <Icon as={IconHeadphones} {...iconProps} />;

    case 'Ebook':
      return <Icon as={IconBook} {...iconProps} />;

    case 'Stream':
      return <Icon as={IconMovie} {...iconProps} />;

    case 'LearningPath':
      return <Icon as={IconRoute} {...iconProps} />;

    default:
      throw new Error('Unexpected typename');
  }
}

function EventTypeIcon({
  eventType,
  iconProps
}: ContentTypeIconProps & Omit<EventTypeIconProps, 'typename'>) {
  switch (eventType) {
    case EventType.Livestream:
      return <Icon as={IconLive} {...iconProps} />;

    case EventType.VirtualEvent:
      return <Icon as={IconTicket} {...iconProps} />;

    case EventType.SmallGroupSession:
      return <Icon as={IconTicket} {...iconProps} />;

    default:
      return <Icon as={IconLive} {...iconProps} />;
  }
}

export function ContentTypeBadge({
  typename,
  eventType,
  iconProps,
  ...rest
}: ContentTypeBadgeProps) {
  const defaultProps: BoxProps = {
    background: 'tigerOrange.600',
    color: 'white',
    rounded: 'full',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.25rem'
  };

  return (
    <Box {...defaultProps} {...rest}>
      {typename === 'Event' ? (
        <EventTypeIcon eventType={eventType} iconProps={iconProps} />
      ) : (
        <ContentTypeIcon typename={typename} iconProps={iconProps} />
      )}
    </Box>
  );
}
