import {
  Box,
  type BoxProps,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Text,
  type TypographyProps,
  UnorderedList
} from '@chakra-ui/react';
import ReactMarkdown, { type Options } from 'react-markdown';
import { logger } from '@tigerhall/core';
import rehypeExternalLinks from 'rehype-external-links';
import remarkGfm from 'remark-gfm';

export interface MarkdownProps {
  children: string;
  components?: Options['components'];
  isDarkMode?: boolean;
  fontSize?: TypographyProps['fontSize'];
  fontWeight?: TypographyProps['fontWeight'];
  color?: BoxProps['color'];
}

function markdownComponents(isDarkMode: boolean): MarkdownProps['components'] {
  return {
    h1: ({ node, ...props }) => (
      <Heading
        as="h1"
        my="1.125rem"
        lineHeight="1.5"
        fontSize="2em"
        fontWeight="bold"
        color={isDarkMode ? 'lightGrey.200' : 'darkGrey.300'}
        {...props}
      />
    ),
    h2: ({ node, ...props }) => (
      <Text
        as="h2"
        my="1.125rem"
        lineHeight="1.5"
        fontSize="1.75em"
        fontWeight="semibold"
        color={isDarkMode ? 'lightGrey.200' : 'darkGrey.300'}
        {...props}
      />
    ),
    h3: ({ node, ...props }) => (
      <Text
        as="h3"
        my="1.125rem"
        lineHeight="1.5"
        fontSize="1.5em"
        fontWeight="semibold"
        color={isDarkMode ? 'lightGrey.200' : 'darkGrey.300'}
        {...props}
      />
    ),
    h4: ({ node, ...props }) => (
      <Text
        as="h4"
        my="1.125rem"
        lineHeight="1.5"
        fontSize="1.25em"
        fontWeight="semibold"
        color={isDarkMode ? 'lightGrey.200' : 'darkGrey.300'}
        {...props}
      />
    ),
    h5: ({ node, ...props }) => (
      <Text
        as="h5"
        my="1.125rem"
        lineHeight="1.5"
        fontSize="1.125em"
        fontWeight="semibold"
        color={isDarkMode ? 'lightGrey.200' : 'darkGrey.300'}
        {...props}
      />
    ),
    h6: ({ node, ...props }) => (
      <Text
        as="h6"
        my="1.125rem"
        lineHeight="1.5"
        fontSize="1em"
        fontWeight="semibold"
        color={isDarkMode ? 'lightGrey.200' : 'darkGrey.300'}
        {...props}
      />
    ),
    p: ({ node, ...props }) => (
      <Text
        fontSize="1em"
        lineHeight="1.5"
        my="1.125rem"
        fontWeight="medium"
        color={isDarkMode ? 'lightGrey.200' : 'darkGrey.300'}
        {...props}
      />
    ),
    a: ({ node, ...props }) => (
      <Link
        my="1.125rem"
        fontSize="1em"
        color="tigerOrange.500"
        fontWeight="medium"
        lineHeight="1.5"
        {...props}
      />
    ),
    ul: ({ node, ...props }) => (
      <UnorderedList
        fontSize="1em"
        my="1.125rem"
        lineHeight="1.5"
        ml="2xl"
        color={isDarkMode ? 'lightGrey.200' : 'darkGrey.300'}
        {...props}
      />
    ),
    ol: ({ node, ...props }) => (
      <OrderedList
        fontSize="1em"
        my="1.125rem"
        ml="2xl"
        lineHeight="1.5"
        color={isDarkMode ? 'lightGrey.200' : 'darkGrey.300'}
        {...props}
      />
    ),
    li: ({ node, ...props }) => (
      <ListItem
        fontSize="1em"
        fontWeight="medium"
        lineHeight="1.5"
        color={isDarkMode ? 'lightGrey.200' : 'darkGrey.300'}
        {...props}
      />
    )
  };
}

export function RichTextMarkdownWrapper({
  children,
  components,
  isDarkMode = true,
  fontSize = 16,
  fontWeight = undefined,
  color = undefined
}: MarkdownProps) {
  if (!children) {
    logger.error('No markdown children to show');
    return null;
  }

  return (
    <Box fontSize={fontSize} fontWeight={fontWeight} color={color}>
      <ReactMarkdown
        components={{
          ...markdownComponents(isDarkMode),
          ...components
        }}
        rehypePlugins={[
          [rehypeExternalLinks, { target: '_blank', rel: 'noopener' }]
        ]}
        remarkPlugins={[remarkGfm]}
      >
        {children}
      </ReactMarkdown>
    </Box>
  );
}
