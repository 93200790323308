import { Flex, GridItem, HStack, Skeleton } from '@chakra-ui/react';

export function PillVariantSkeleton() {
  return (
    <GridItem
      height={16}
      borderRadius={8}
      padding={3}
      bg={'blackAlpha.100'}
      borderWidth={1}
    >
      <Flex direction={'column'} gap={2}>
        <HStack>
          <Skeleton height={4} width={4} />
          <Skeleton height={4} width={40} />
          <Skeleton height={4} width={4} />
        </HStack>
        <HStack>
          <Skeleton height={4} width={44} />
        </HStack>
      </Flex>
    </GridItem>
  );
}
