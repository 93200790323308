import { Button } from '@tigerhall/ui-components';

type NextButtonProps = {
  text: string;
  onClick: (e: any) => void;
  isDisabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'data-cy'?: string;
};

export function NextButton({
  text,
  onClick,
  isDisabled,
  'data-cy': dataCy
}: NextButtonProps) {
  return (
    <Button
      text={text}
      type="submit"
      width="100%"
      maxWidth="30rem"
      isDisabled={isDisabled}
      size="lg"
      onClick={onClick}
      data-cy={dataCy}
    />
  );
}
