import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgAlertTriangle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="th-icon"
    viewBox="0 0 24 24"
    focusable={false}
    color="white"
    width="1.25rem"
    height="1.25rem"
    ref={ref}
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M12 9v4M10.363 3.591 2.257 17.125a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636-2.87L13.637 3.59a1.914 1.914 0 0 0-3.274 0zM12 16h.01" />
  </svg>
);
SvgAlertTriangle.displayName = "IconAlertTriangle";
const ForwardRef = forwardRef(SvgAlertTriangle);
export default ForwardRef;
