import {
  Box,
  Flex,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react';
import {
  BottomDrawer,
  MinimizePlayerButton,
  PlayerModal,
  SideDrawer
} from '@tigerhall/components';
import { ContentType, StreamSource } from '@tigerhall/core';
import {
  getContentUrl,
  getIsMinimized,
  getIsTemporarilyHiddenAndPaused,
  getPlayingContent,
  getPlaylistType,
  setIsMinimized as setIsMinimizedPlayerState
} from 'app/state';
import { useAppDispatch, useAppSelector, useAutoplayContent } from 'hooks';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { useGetStreamSourceQuery } from 'generated';
import { captureException } from '@sentry/react';

import { ChatReplayBottomSheet } from './components/DrawerContent/components/DrawerTabs/components/ChatReplayTab';
import { IndexedSelectionButtons } from './components/DrawerContent/components/Buttons/IndexedSelectionButtons';
import { LiveChat } from './components/DrawerContent/components/DrawerOverlays/components/LiveChat';
import { getPlayerTabs } from './components/DrawerContent/components/DrawerTabs/utils/tabs';
import { getUrlToShareForLiveContent } from './components/LiveContentConnected/utils';
import { PlayerConnected } from './components/PlayerConnected';
import { PlayerConnectedDrawerContent } from './components/PlayerConnectedDrawerContent';
import { useGetLiveStreamInfoByIdLazyQuery } from './queries';

/**
 * @description
 * This component ensures that its child elements take the full width and height of the container.
 * It is particularly useful in a flexbox environment where the child element's height or width
 * might otherwise exceed that of the parent container. By positioning the child absolutely within
 * the relative container, it restricts the child's dimensions to match those of the parent.
 */
function ConstrainedBox({ children }: { children: ReactNode }) {
  return (
    <Box w={'full'} pos={'relative'} flex={1}>
      <Box position={'absolute'} inset={0}>
        {children}
      </Box>
    </Box>
  );
}

export function ContentPlayer() {
  /**
   * This Handles the autoplay of content in trail/assignments
   */
  useAutoplayContent();

  const dispatch = useAppDispatch();
  const playingContent = useAppSelector(getPlayingContent);
  const isHidden = useAppSelector(getIsTemporarilyHiddenAndPaused);
  const contentUrl = useAppSelector(getContentUrl);
  const playlistType = useAppSelector(getPlaylistType);
  const isMinimized = useAppSelector(getIsMinimized);

  const { data: streamData } = useGetStreamSourceQuery({
    variables: { id: playingContent?.id as string },
    skip: !playingContent?.id || playingContent.type !== ContentType.Stream,
    fetchPolicy: 'cache-first'
  });

  function setIsMinimized(value: boolean) {
    dispatch(setIsMinimizedPlayerState(value));
  }

  const isPlaylist = !!playlistType;

  const [tabIndex, setTabIndex] = useState(0);

  const [getLiveStreamInfo, { data: liveContentMetaData }] =
    useGetLiveStreamInfoByIdLazyQuery({
      variables: {
        id: playingContent?.id ?? ''
      },
      fetchPolicy: 'cache-first'
    });

  const isMobile = useBreakpointValue(
    { base: true, lg: false },
    {
      fallback: 'lg',
      ssr: false
    }
  );

  const [isLandscape] = useMediaQuery('(orientation: landscape)', {
    ssr: false,
    fallback: false
  });

  useEffect(() => {
    if (
      playingContent?.type === StreamSource.BroadcastAwsIvs ||
      playingContent?.type === StreamSource.BroadcastCloudflareLive ||
      playingContent?.type === StreamSource.LivestreamAgora
    ) {
      getLiveStreamInfo().catch((err) => {
        captureException(err, {
          tags: {
            contentId: playingContent.id,
            contentType: playingContent.type
          }
        });
      });
    }
  }, [getLiveStreamInfo, playingContent?.id, playingContent?.type]);

  const shouldShowDrawer = useMemo(() => {
    return !isMinimized && !(isLandscape && isMobile);
  }, [isMinimized, isLandscape, isMobile]);

  const {
    isOpen: isBottomDrawerOpen,
    onClose: handleBottomDrawerClose,
    onOpen: handleBottomDrawerOpen
  } = useDisclosure();

  const meta = useMemo(
    () => ({
      contentId: liveContentMetaData?.stream?.id ?? '',
      contentName: liveContentMetaData?.stream?.name ?? '',
      preamble: liveContentMetaData?.stream?.preamble ?? ''
    }),
    [
      liveContentMetaData?.stream?.id,
      liveContentMetaData?.stream?.name,
      liveContentMetaData?.stream?.preamble
    ]
  );

  if (!playingContent?.id) {
    return null;
  }

  const isLiveContent =
    playingContent.type === StreamSource.BroadcastCloudflareLive ||
    playingContent.type === StreamSource.BroadcastAwsIvs ||
    playingContent.type === StreamSource.LivestreamAgora;

  const playerTabs = getPlayerTabs({
    isLive: isLiveContent,
    isPlaylist: isPlaylist,
    type: playingContent?.type,
    source: streamData?.stream?.source,
    isMobile
  });

  const urlToShare = isLiveContent
    ? getUrlToShareForLiveContent({
        id: playingContent.id,
        source: playingContent.type as StreamSource
      })
    : contentUrl ?? '';

  return (
    <PlayerModal
      isOpen={Boolean(playingContent?.id)}
      isHidden={isHidden}
      isMinimized={isMinimized}
      onMinimize={() => {
        setIsMinimized(true);
      }}
      contentHeight={'100dvh'}
    >
      <Flex w={'full'} flexDir={'column'} overflow={'hidden'}>
        <ConstrainedBox>
          <PlayerConnected
            isMinimized={isMinimized}
            setIsMinimized={setIsMinimized}
          />
          {!isMinimized && (
            <MinimizePlayerButton
              onClick={() => setIsMinimized(true)}
              containerProps={{
                display: {
                  base: isLiveContent ? 'none' : 'block',
                  lg: 'block'
                }
              }}
            />
          )}
        </ConstrainedBox>

        {isMobile && shouldShowDrawer ? (
          <Box w={'full'}>
            {isLiveContent ? (
              <LiveChat
                streamId={playingContent?.id}
                urlToShare={urlToShare}
                titleToShare={meta.preamble}
                descriptionToShare={meta.contentName}
              />
            ) : null}
            <IndexedSelectionButtons
              tabs={playerTabs}
              onTap={(index) => {
                handleBottomDrawerOpen();
                setTabIndex(index);
              }}
            />
            <BottomDrawer
              isOpen={isBottomDrawerOpen}
              onClose={handleBottomDrawerClose}
            >
              <PlayerConnectedDrawerContent tabIndex={tabIndex} />
            </BottomDrawer>
          </Box>
        ) : null}

        <ChatReplayBottomSheet
          playingContent={playingContent}
          isMinimized={isMinimized}
          source={streamData?.stream?.source}
        />
      </Flex>

      {!isMobile && shouldShowDrawer ? (
        <SideDrawer>
          <PlayerConnectedDrawerContent tabIndex={tabIndex} />
        </SideDrawer>
      ) : null}
    </PlayerModal>
  );
}
