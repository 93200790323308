import type { QueryResult } from '@apollo/client';
import { useState } from 'react';
import keys from 'lodash/keys';
import values from 'lodash/values';
import zipObject from 'lodash/zipObject';
import { type Exact, useDeepCompareEffect } from '@tigerhall/core';
import { captureException } from '@sentry/react';

import {
  type GetLinkPreviewQuery,
  useGetLinkPreviewLazyQuery
} from '../queries';

export type ReturnTypeOfGetLinkPreview = QueryResult<
  GetLinkPreviewQuery,
  Exact<{
    link: string;
  }>
>;

interface UseLinkPreviewArgs {
  urls: string[];
}

export function useLinkPreview({ urls }: Readonly<UseLinkPreviewArgs>) {
  const [getLinkPreview] = useGetLinkPreviewLazyQuery();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<
    Record<string, ReturnTypeOfGetLinkPreview>
  >({});

  async function fetchPreviewForAllUrls() {
    setLoading(true);

    type UrlsZipObject = Record<string, ReturnType<typeof getLinkPreview>>;

    try {
      /**
       *  The ZipObject helper will return the object in below format
       *  we need this as the requested url and the response url might be different,
       *  and we need a unique identifier which equals the description and in previews
       *  this also prevents duplicate calls for the same link
       *
       * @example
       * ``json
           {
           "www.example1.com": {
             "data": {
               "linkPreview": {
                  "url": "https://example1.com", "title": "Mock Title"
                }
              }
           },
           "www.example2.com": {
             "data": {
               "linkPreview": {
                  "url": "https://example2.com", "title": "Mock Title"
                }
              }
            }
           }
       *  ```
       */
      const urlsZipObject = urls.reduce<UrlsZipObject>((acc, url) => {
        acc[url] = getLinkPreview({
          variables: { link: url },
          onError(error) {
            captureException(error, {
              extra: {
                urls: url
              }
            });
          }
        });
        return acc;
      }, {});

      const resultObject = zipObject(
        keys(urlsZipObject),
        await Promise.all(values(urlsZipObject))
      );

      setLoading(false);

      setResult(resultObject);
    } catch (error) {
      setLoading(false);
      captureException(error, {
        extra: {
          urls: urls.toString()
        }
      });
    }
  }

  useDeepCompareEffect(() => {
    fetchPreviewForAllUrls().catch((error) =>
      captureException(error, {
        extra: {
          urls: urls.toString()
        }
      })
    );
  }, [urls]);

  return {
    loading,
    result
  };
}
