import {
  NavigationType,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks';
import { decrementPageCount, incrementPageCount } from 'app/state';

const Analytics = () => {
  const history = useLocation();
  const dispatch = useAppDispatch();
  const navigationType = useNavigationType();

  useEffect(() => {
    const listener = () => {
      if (navigationType === NavigationType.Pop) {
        dispatch(decrementPageCount());
      }
    };

    window.addEventListener('popstate', listener);

    return () => {
      window.removeEventListener('popstate', listener);
    };
  }, [dispatch, navigationType]);

  useEffect(() => {
    if (window.location.search.includes('token=')) {
      // remove token from url before sending to analytics
      const url = new URL(
        window.location.href.replace(/token=[^&]+/, 'token=secret')
      );

      // send custom page view to analytics
      window.analytics.page({
        path: url.pathname,
        referrer: document.referrer,
        search: url.search,
        title: document.title,
        url: url.href
      });
    } else {
      window.analytics.page();
    }

    if (navigationType === NavigationType.Push) {
      dispatch(incrementPageCount());
    }
  }, [dispatch, history, navigationType]);

  return null;
};

export default Analytics;
