import { Box, Flex, Icon, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { IconChevronDown, IconShare3 } from '@tigerhall/icons';
import { Share } from '@tigerhall/components';

import { ChatInput } from '../../../DrawerTabs/components/ChatTab/ChatInput';
import { ChatMessages } from '../../../DrawerTabs/components/ChatTab/ChatMessages';

export interface LiveChatProps {
  streamId: string;
  urlToShare: string;
  titleToShare: string;
  descriptionToShare: string;
}

export function LiveChat({
  streamId,
  urlToShare,
  titleToShare,
  descriptionToShare
}: Readonly<LiveChatProps>) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isShareIconShowing,
    onClose: hideShareIcon,
    onOpen: showShareIcon
  } = useDisclosure({ defaultIsOpen: true });

  return (
    <VStack
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      width={'full'}
      spacing={0}
      color={'white'}
      position={'relative'}
      bg={'black'}
    >
      <Box
        visibility={isOpen ? 'visible' : 'hidden'}
        bottom={isOpen ? '3rem' : '-10rem'}
        opacity={isOpen ? 1 : 0}
        zIndex={isOpen ? 2 : -1}
        position={'absolute'}
        transition={'all 200ms'}
        width={'full'}
      >
        <Flex
          px={'3'}
          py={'4'}
          gap={'2'}
          as={'button'}
          onClick={onClose}
          alignItems={'center'}
          fontSize={'sm'}
          fontWeight={'bold'}
        >
          <Icon as={IconChevronDown} />
          Hide Live Chat
        </Flex>
        <ChatMessages streamId={streamId} disableOverlay />
      </Box>

      <Flex w={'full'} alignItems={'center'} height={'3rem'}>
        {isOpen ? (
          <ChatInput
            streamId={streamId}
            onInputFocusChange={(focused) =>
              focused ? hideShareIcon() : showShareIcon()
            }
            showSendButton={!isShareIconShowing}
          />
        ) : (
          <Flex
            px={'3'}
            py={'4'}
            gap={'2'}
            as={'button'}
            onClick={onOpen}
            alignItems={'center'}
            fontSize={'sm'}
            fontWeight={'bold'}
            flex={'1'}
          >
            <Icon as={IconChevronDown} transform="rotate(-180deg)" />
            Live Chat
          </Flex>
        )}
        {isShareIconShowing && (
          <Share
            urlToShare={urlToShare}
            titleToShare={titleToShare}
            descriptionToShare={descriptionToShare}
            content={{
              __typename: 'Stream',
              id: streamId,
              name: titleToShare
            }}
            aria-label="Share"
            color="white"
            pr={'1.625rem'}
          >
            <VStack>
              <Icon fontSize={'1.5rem'} as={IconShare3} />
              <Text fontSize={'xx-small'} color={'white'} mt={0}>
                Share
              </Text>
            </VStack>
          </Share>
        )}
      </Flex>
    </VStack>
  );
}
