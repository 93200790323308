import * as React from 'react';
import { Center, Skeleton } from '@chakra-ui/react';

import { FeedItemContainer } from '../FeedItemContainer';
import { ActivityHeaderSkeleton } from '../ActivityHeader';
import { PostMetricsSkeleton } from '../PostMetrics';
import { FeedItemActionsSkeleton } from '../FeedItemActions';
import { ActivityCommentSkeleton } from '../ActivityComment';

interface ContentActivitySkeletonProps {
  hasComments?: boolean;
}

export function ContentActivitySkeleton({
  hasComments = false
}: ContentActivitySkeletonProps) {
  return (
    <FeedItemContainer>
      <ActivityHeaderSkeleton />
      <Center
        my="1.25rem"
        mx={{
          base: '1rem',
          md: '2rem'
        }}
      >
        <Skeleton
          height={{
            base: '9rem',
            md: '10rem',
            lg: '13.25rem'
          }}
          width="100%"
          maxW="44rem"
          rounded="lg"
        />
      </Center>
      <PostMetricsSkeleton />
      <FeedItemActionsSkeleton numberOfButtons={1} />
      {hasComments && <ActivityCommentSkeleton />}
    </FeedItemContainer>
  );
}
