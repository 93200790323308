import { useState } from 'react';
import { IconHeart } from '@tigerhall/icons';
import {
  ReactionType,
  UserReaction,
  FeedContentType,
  REACTIONS
} from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import { Icon, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { ReactionsPopover } from '@tigerhall/components';
import { captureException } from '@sentry/react';
import { useGetPostByIdLazyQuery } from 'generated';

import { ActionButton } from '../ActionButton';
import { useTogglePostReactionMutation } from './queries/generated/reaction.generated';

interface ReactionProps {
  postId: string;
  currentReaction?: Pick<UserReaction, 'id' | 'reactionType'> | null;
}

export function Reaction({ postId, currentReaction }: ReactionProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast({ duration: 2000 });
  const [togglePostReactionMutation] = useTogglePostReactionMutation();

  const [refetchPost] = useGetPostByIdLazyQuery({
    variables: {
      id: postId,
      type: FeedContentType.Post
    },
    fetchPolicy: 'network-only'
  });

  async function handleReactionClicked(reaction: ReactionType) {
    setIsLoading(true);

    try {
      const isCurrentReaction =
        currentReaction?.reactionType &&
        REACTIONS[currentReaction?.reactionType].label ===
          REACTIONS[reaction].label;

      onClose();

      segment.reactionClicked({
        addedOrRemoved: !isCurrentReaction,
        reactionType: REACTIONS[reaction].labelUppercase,
        referenceType: 'POST',
        referenceId: postId,
        location: window.location.pathname
      });

      await togglePostReactionMutation({
        variables: {
          referenceId: postId,
          reactionType: reaction
        }
      });

      await refetchPost();
    } catch (error) {
      toast({
        title: 'Error',
        description:
          'An error occurred trying to react to this post. Please try again later',
        status: 'error'
      });
      captureException(error, {
        tags: {
          postId: postId
        }
      });
    }

    setIsLoading(false);
  }

  return (
    <ReactionsPopover
      trigger="hover"
      isOpen={!isLoading && isOpen}
      onClose={onClose}
      onOpen={() => {
        segment.reactionsOpened({
          referenceType: 'POST',
          referenceId: postId
        });
        onOpen();
      }}
      onReactionClicked={handleReactionClicked}
    >
      {currentReaction?.reactionType ? (
        <ActionButton
          isLoading={isLoading}
          leftIcon={
            <Text as="span">
              {REACTIONS[currentReaction.reactionType].emoji}
            </Text>
          }
          color="tigerOrange.400"
          onClick={async () => {
            await handleReactionClicked(currentReaction.reactionType);
          }}
        >
          {REACTIONS[currentReaction.reactionType].label}
        </ActionButton>
      ) : (
        <ActionButton
          isLoading={isLoading}
          leftIcon={<Icon as={IconHeart} />}
          onClick={async () => {
            await handleReactionClicked(ReactionType.Heart);
          }}
        >
          Like
        </ActionButton>
      )}
    </ReactionsPopover>
  );
}
