import * as React from 'react';
import { Button, Icon } from '@chakra-ui/react';
import { Content, LearningPath } from '@tigerhall/core/lib/types';
import { IconBookmarkFilled, IconBookmark } from '@tigerhall/icons';

type BookmarkButtonProps = {
  hide?: boolean;
  content?:
    | Pick<Content, 'userContentTracking' | '__typename'>
    | Pick<LearningPath, 'userCollectionTracking' | '__typename'>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export function BookmarkButton({
  hide = false,
  content,
  onClick
}: BookmarkButtonProps) {
  const userTracking =
    content?.__typename === 'LearningPath'
      ? content?.userCollectionTracking
      : content?.userContentTracking;

  if (hide || !userTracking || !onClick) {
    return null;
  }

  const isBookmarked = userTracking?.bookmarkedAt;

  return (
    <Button
      variant="unstyled"
      display="flex"
      size="sm"
      fontSize="16"
      borderRadius="md"
      aria-label="Bookmark"
      onClick={onClick}
    >
      {/* Value of isBookmarked is not updated (without hardrefresh of page)
                 whereas bookmarkedAt is updated with timestamp and null when toggled */}

      <Icon
        as={isBookmarked ? IconBookmarkFilled : IconBookmark}
        color="tigerOrange.600"
      />
    </Button>
  );
}
