import { Outlet, RouteObject } from 'react-router-dom';
import * as React from 'react';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';

import { routes as followTopicRoutes } from './modules/followtopics';
import { RouteGuard } from './components';

const Login = React.lazy(() =>
  import('./screens/Login').then((module) => ({
    default: module.Login
  }))
);

const routes: RouteObject[] = [
  {
    path: '/msteams',
    element: (
      <FluentProvider theme={teamsLightTheme}>
        <RouteGuard>
          <Outlet />
        </RouteGuard>
      </FluentProvider>
    ),
    children: [
      {
        path: 'login',
        element: (
          <React.Suspense>
            <Login mode="standard" />
          </React.Suspense>
        )
      },
      {
        path: 'msg-ext-login',
        element: (
          <React.Suspense>
            <Login mode="msg-ext" />
          </React.Suspense>
        )
      },

      ...followTopicRoutes
    ]
  }
];

export default routes;
