/**
 * This is a wrapper over useForm to allow us to handle analytics in one place
 */

import * as segment from '@tigerhall/analytics';
import { useCallback, useEffect } from 'react';
import {
  FieldValues,
  useForm,
  UseFormProps,
  UseFormReturn
} from 'react-hook-form';

export type THFormProps = {
  formName: string;
};

/**
 * @deprecated Use TrackedForm from @tigerhall/components instead
 */
export function useTHForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>(
  props?: UseFormProps<TFieldValues, TContext> & THFormProps
): UseFormReturn<TFieldValues, TContext> & THFormProps {
  useEffect(() => {
    if (props?.formName) {
      segment.formViewed({
        formName: props.formName
      });
    }
  }, [props?.formName]);

  const formProps = useForm(props);

  const handleSubmit = useCallback(
    (handler) =>
      formProps.handleSubmit((args) => {
        if (props?.formName) {
          segment.formSubmitted({
            formName: props?.formName
          });
        }
        handler(args);
      }),
    [formProps, props?.formName]
  );

  return { ...formProps, formName: props?.formName || '', handleSubmit };
}
