import { Box, Fade, HStack } from '@chakra-ui/react';
import { useState } from 'react';

import { ExpandCollapseIcon } from './components';

interface SideDrawerProps {
  children?: React.ReactNode;
}

export function SideDrawer({ children }: Readonly<SideDrawerProps>) {
  const [isOpen, setOpen] = useState(true);

  function handleToggle() {
    setOpen((open) => !open);
  }

  return (
    <HStack pos={'relative'} w={'max-content'} spacing={'0'}>
      <ExpandCollapseIcon isOpen={isOpen} onToggle={handleToggle} />
      <Box
        maxW={'30rem'}
        w={isOpen ? '30rem' : '0'}
        visibility={isOpen ? 'visible' : 'hidden'}
        bg={'darkGrey.700'}
        color={'white'}
        h={'full'}
        py={'4'}
        left={0}
        transition={'all 800ms'}
      >
        <Fade
          in={isOpen}
          style={{
            height: '100%'
          }}
          transition={{
            exit: { duration: 0.1 },
            enter: { delay: 0.5, duration: 0.5 }
          }}
        >
          {children}
        </Fade>
      </Box>
    </HStack>
  );
}
