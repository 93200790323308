import type { Chapter } from '@tigerhall/core';

export function extractCurrentChapter<
  T extends Partial<Chapter> & Pick<Chapter, 'pages'>
>(chapters: T[], pageIndex: number): T | undefined {
  let tempPagesCount = 0;

  return chapters.find((chapter) => {
    if (tempPagesCount + chapter.pages.length < pageIndex + 1) {
      tempPagesCount += chapter.pages.length;
      return false;
    }
    return true;
  });
}

export function extractPagesFromChapters(
  chapters: Pick<Chapter, 'pages'>[]
): string[] {
  return chapters.reduce(
    (acc, chapter) => [...acc, ...chapter.pages],
    [] as string[]
  );
}
