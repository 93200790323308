import * as React from 'react';
import { Heading, Text, VStack } from '@chakra-ui/react';

type HeaderTextProps = {
  heading: string;
  description?: string;
  descriptionBold?: string;
};

export function HeaderText({
  heading,
  description,
  descriptionBold
}: HeaderTextProps) {
  return (
    <VStack spacing={0} textAlign="center" mt="3.5rem">
      <Heading as="h2" fontSize="1.75rem" color="tigerOrange.600" pb="1rem">
        {heading}
      </Heading>
      {!!description && (
        <Text fontSize="md" color="lightGrey.200" pb="0.5rem">
          {description}
        </Text>
      )}
      {!!descriptionBold && (
        <Text fontSize="md" fontWeight="700" color="lightGrey.200">
          {descriptionBold}
        </Text>
      )}
    </VStack>
  );
}
