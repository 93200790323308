import * as React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import * as segment from '@tigerhall/analytics';
import { useFormContext, UseFormReturn } from 'react-hook-form';

/**
 * @deprecated Use the dedicated button from @tigerhall/components instead
 */
export const THButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const form = useFormContext() as UseFormReturn & {
      formName: string;
    };
    return (
      <Button
        {...props}
        onClick={(args) => {
          const location = window?.location?.pathname || '';
          segment.buttonClicked({
            location,
            buttonName:
              typeof props?.children === 'string'
                ? props?.children
                : props?.name || '',
            formName: form?.formName
          });
          props?.onClick?.(args);
        }}
        ref={ref}
      />
    );
  }
);
