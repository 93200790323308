import { noop } from '@chakra-ui/utils';
import { useRef } from 'react';

import {
  MinimizedPlayer,
  type MinimizedPlayerProps
} from '~/player/components/MinimizedPlayer';
import { TrackSelector } from '~/player/components/TrackSelector';
import { MediaPlayerContextProvider } from '~/player/context';
import {
  type UseMediaPlayerArgs,
  useMediaAnalytics,
  useMediaPlayer
} from '~/player/hooks';
import {
  ActivePlayer,
  type ActivePlayerProps
} from './components/ActivePlayer';
import { BgImage } from './components/BgImage';

export interface PodcastProps
  extends Omit<ActivePlayerProps, 'children' | 'name' | 'casting' | 'audioRef'>,
    Pick<UseMediaPlayerArgs, 'customDuration' | 'previousProgress'>,
    Pick<MinimizedPlayerProps, 'onClose' | 'onMaximize'> {
  /**
   * The poster image for the podcast.
   * This will be used as the background image for the modal.
   */
  poster: string;
  autoPlay?: boolean;
  muted?: boolean;
  meta: {
    contentId: string;
    contentName: string;
    casting: string;
  };
  onTrackProgress: (progress: number) => void;
  onTrackFinished: () => void;
  onFinished?: () => void;
  /**
   * If the user completed the podcast.
   * This will be used to determine if the user should be able to mark the podcast as done.
   */
  isFinished?: boolean;
  /**
   * If the player should be hidden without being unmounted.
   * This is useful if you want to keep the player state but not have it visible,
   * e.g. if you want to open a video and then resume the podcast after the video is closed.
   */
  isHidden?: boolean;
  isMinimized?: boolean;
  playlistId?: string;
}

export function Podcast({
  poster,
  autoPlay = true,
  muted = false,
  meta: { contentId, contentName, casting },

  onTrackProgress,
  onTrackFinished,
  onFinished = noop,
  isFinished = false,
  isHidden = false,
  playlistId,

  // from `ActivePlayerProps`
  tracks,
  trackIndex = 0,
  setTrackIndex,
  urlToShare,
  contentDescription,
  playlistTitle,
  playlistType,
  contentUrl,

  transcripts,
  markdownComponent,

  mediaLanguages,
  selectedMediaLanguage,
  onChangeMediaLanguage,

  transcriptLanguages,
  selectedTranscriptLanguage,
  onChangeTranscriptLanguage,

  vote,
  onLike,
  onDislike,

  isMinimized,
  onAutoNextPlay,
  isLastContentInPlaylist,

  // from `UseMediaPlayerArgs`
  customDuration,
  previousProgress,
  // from `MinimizedPlayerProps`
  onClose,
  onMaximize
}: Readonly<PodcastProps>) {
  const audioRef = useRef<HTMLAudioElement>(null);

  useMediaAnalytics({
    contentId,
    contentType: 'Podcast',
    playlistId,
    playlistType,
    onTrackProgress,
    onTrackFinished,
    playerState: isMinimized ? 'Minimized' : 'Maximized',
    language: selectedMediaLanguage,
    mediaType: 'audio'
  });

  const player = useMediaPlayer(audioRef, {
    onFinished,
    customDuration,
    previousProgress,
    defaultAutoPlay: autoPlay
  });

  function onNextTrack() {
    if (trackIndex === tracks.length - 1) {
      setTrackIndex(0);
    } else {
      setTrackIndex(trackIndex + 1);
    }
  }

  function onPrevTrack() {
    if (trackIndex === 0) {
      setTrackIndex(tracks.length - 1);
    } else {
      setTrackIndex(trackIndex - 1);
    }
  }

  if (isHidden) {
    player.pause();
  }

  return (
    <MediaPlayerContextProvider
      {...player}
      onNextTrack={onNextTrack}
      onPrevTrack={onPrevTrack}
    >
      <audio
        ref={audioRef}
        style={{ display: 'none' }}
        src={tracks[trackIndex].url}
        autoPlay={autoPlay}
        muted={muted}
      />

      {isMinimized ? (
        <MinimizedPlayer
          poster={poster}
          onClose={onClose}
          onMaximize={onMaximize}
          name={contentName}
          casting={casting}
          vote={vote}
          onLike={onLike}
          onDislike={onDislike}
          hideTrackControls={tracks.length <= 1}
          audioRef={audioRef}
          onAutoNextPlay={onAutoNextPlay}
          trackSelector={
            <TrackSelector
              size="sm"
              tracks={tracks}
              trackIndex={trackIndex}
              setTrackIndex={setTrackIndex}
            />
          }
        />
      ) : (
        <ActivePlayer
          onTrackFinished={onTrackFinished}
          isFinished={isFinished}
          tracks={tracks}
          trackIndex={trackIndex}
          setTrackIndex={setTrackIndex}
          transcripts={transcripts}
          markdownComponent={markdownComponent}
          mediaLanguages={mediaLanguages}
          selectedMediaLanguage={selectedMediaLanguage}
          onChangeMediaLanguage={onChangeMediaLanguage}
          transcriptLanguages={transcriptLanguages}
          selectedTranscriptLanguage={selectedTranscriptLanguage}
          onChangeTranscriptLanguage={onChangeTranscriptLanguage}
          vote={vote}
          onLike={onLike}
          onDislike={onDislike}
          name={contentName}
          casting={casting}
          urlToShare={urlToShare}
          contentDescription={contentDescription}
          playlistTitle={playlistTitle}
          playlistType={playlistType}
          audioRef={audioRef}
          onAutoNextPlay={onAutoNextPlay}
          isLastContentInPlaylist={isLastContentInPlaylist}
          contentUrl={contentUrl}
        >
          <BgImage url={poster} alt={contentName} />
        </ActivePlayer>
      )}
    </MediaPlayerContextProvider>
  );
}
