import React from 'react';
import { Content } from '@tigerhall/core/lib/types';
import merge from 'lodash/merge';
import { GetEventsQueryVariables, useGetEventsQuery } from 'generated';

import ContentCardSection from './ContentCardSection';

type Props = {
  variables?: GetEventsQueryVariables;
};

export default function UpcomingExperiencesSection({ variables }: Props) {
  const { data, loading } = useGetEventsQuery({
    variables: merge<GetEventsQueryVariables, GetEventsQueryVariables>(
      {
        filter: {
          limit: 20,
          onlyFutureEvents: true
        }
      },
      variables || { filter: {} }
    )
  });

  if (loading) {
    return null;
  }

  return (
    <ContentCardSection
      sectionTitle="Upcoming Experiences"
      contentCardData={(data?.events.edges || []) as Content[]}
      dataLoading={loading}
    />
  );
}
