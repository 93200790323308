/* eslint-disable import/order */
/**
 * We are following Chakra-ui's recommended advanced pattern
 * for theming scalable projects.
 *
 * [Check it out](https://chakra-ui.com/docs/styled-system/theming/customize-theme#scaling-out-your-project)
 */

// theme/index.js
import { extendTheme } from '@chakra-ui/react';

// Component style overrides
import * as Components from './components';

// Foundational style overrides
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  fonts,
  letterSpacings,
  lineHeights,
  shadows,
  zIndices
} from './foundations';

// Global style overrides
import { styles } from './global';

const overrides = {
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false
  },

  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
  shadows,
  zIndices,
  breakpoints,
  styles,
  components: { ...Components }
};

export const theme = extendTheme(overrides);
