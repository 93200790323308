import * as React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { ErrorBoundary } from 'components/tools/ErrorBoundary';
import { Splash } from 'components/ui/Layout/components';

import { Home } from './screens/Home';

const Calendar = React.lazy(() =>
  import('./screens/Calendar').then((m) => ({ default: m.Calendar }))
);

const routes: RouteObject[] = [
  {
    path: '/home',
    children: [
      {
        index: true,
        // we don't import this lazily because it's the first page users see
        element: (
          <ErrorBoundary>
            <Home />
          </ErrorBoundary>
        )
      },
      {
        // redirect to new route
        path: 'organisation-content',
        element: <Navigate to="/organization" replace />
      },
      {
        // redirect to new route
        path: 'schedule',
        element: <Navigate to="/home/calendar" replace />
      },
      {
        path: 'calendar',
        element: (
          <ErrorBoundary>
            <React.Suspense fallback={<Splash />}>
              <Calendar />
            </React.Suspense>
          </ErrorBoundary>
        )
      }
    ]
  }
];

export default routes;
