import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgUsers = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="th-icon"
    viewBox="0 0 24 24"
    focusable={false}
    color="white"
    width="1.25rem"
    height="1.25rem"
    ref={ref}
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M5 7a4 4 0 1 0 8 0 4 4 0 1 0-8 0M3 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2M16 3.13a4 4 0 0 1 0 7.75M21 21v-2a4 4 0 0 0-3-3.85" />
  </svg>
);
SvgUsers.displayName = "IconUsers";
const ForwardRef = forwardRef(SvgUsers);
export default ForwardRef;
