import { useAppSelector } from '~/hooks';
import { getSelectedOrgId } from '~/app/state';
import { getThinkfluencerScope } from '~/utils';
import { useGetUserBaseProfileQuery } from '../queries/generated/user.generated';

/**
 * Fetches the user's base profile and returns it along with its thinkfluencer scope.
 * @param userId The user ID to fetch the profile for.
 */
export function useUserBaseProfile(userId: string) {
  const signedInUserOrgId = useAppSelector(getSelectedOrgId) || '';

  const { data } = useGetUserBaseProfileQuery({
    variables: {
      id: userId
    },
    fetchPolicy: 'cache-first'
  });

  const profile = data?.user;

  const thinkfluencerScope = getThinkfluencerScope({
    isExpert: profile?.isExpert,
    expertOrg: profile?.expertOrg,
    signedInUserOrgId
  });

  return {
    profile,
    thinkfluencerScope
  };
}
