export interface MentionBlock {
  type: 'MENTION';
  userId: string;
  text: string;
  url?: never;
}

export interface TextBlock {
  type: 'TEXT';
  text: string;
  userId?: never;
  url?: never;
}

export interface LinkBlock {
  type: 'LINK';
  text: string;
  url: string;
  userId?: never;
}

export type ParsedTextBlock = MentionBlock | TextBlock | LinkBlock;

const groupedRegex =
  /@\[(?<username>.*?)\]\((?<userId>.*?)\)|(?<url>(?:https?:\/\/|www\.?)(?=.{4,256}$)(?!-)(?:[a-zA-Z0-9-]{1,63}\.?)+(?:\/[a-zA-Z0-9-]{1,63})*\/?(?:#[a-zA-Z0-9-]{1,63})?(?:(?:\?[-a-zA-Z0-9@:%._+~#=]+)(?:&[-a-zA-Z0-9@:%._+~#=]+)*)?)/g;

export function getParsedText(text: string): ParsedTextBlock[] {
  const matches = [...text.matchAll(groupedRegex)];

  const result: ParsedTextBlock[] = [];

  // If there's no matched pattern, simply return the whole text.
  if (!matches.length) {
    result.push({
      type: 'TEXT',
      text: text
    });
  }

  matches.forEach((match, index) => {
    const startIndex = match.index ?? 0;
    const endIndex = startIndex + (match[0].length ?? 0);

    const { url, userId, username } = match.groups ?? {};

    // Extract the first part of text before pushing any pattern and push to results
    const firstPartBeforeFirstPattern = text.slice(0, startIndex);
    if (index === 0 && firstPartBeforeFirstPattern) {
      result.push({
        type: 'TEXT',
        text: firstPartBeforeFirstPattern
      });
    }

    // now push the parts which are extracted from patterns
    if (url) {
      result.push({
        type: 'LINK',
        text: url,
        url
      });
    } else if (userId && username) {
      result.push({
        type: 'MENTION',
        text: username,
        userId: userId
      });
    }

    // now fetch all the plain text between the current and next pattern(if pattern is last extract remaining text too), and push to results
    const textPartBetweenPatterns = text.slice(
      endIndex,
      matches[index + 1]?.index
    );
    if (textPartBetweenPatterns) {
      result.push({
        type: 'TEXT',
        text: textPartBetweenPatterns
      });
    }
  });

  return result;
}
