import { setSessionStorageObject } from '@tigerhall/core';
import { useCreateTrailMutation, useEditTrailMutation } from 'generated';
import { getSelectedOrgId } from 'app/state';
import { ASSIGN_TRAIL_KEY } from 'modules/admin/constants';
import { useAppSelector } from 'hooks';
import { captureException } from '@sentry/react';
import { useToast } from '@chakra-ui/react';

import useDraftLearningPaths from './useDraftLearningPaths';
import {
  createLearningPathInput,
  type ContentCards
} from '../utils/createLearningPathInput';

function useUpsertTrails() {
  const { draftMeta, contents, idOrDraft, actions } = useDraftLearningPaths();
  const organisationId = useAppSelector(getSelectedOrgId);

  const toast = useToast();

  const [createTrail, createResponse] = useCreateTrailMutation();
  const [updateTrail, updateResponse] = useEditTrailMutation();

  const isDraft = idOrDraft === 'draft';

  function getInputAndError() {
    if (!organisationId) {
      return { input: null, error: 'Organisation ID is required' };
    }

    const input = createLearningPathInput(
      organisationId,
      draftMeta,
      contents as ContentCards
    );

    if (input === null) {
      return { input: null, error: 'Please, fill all the required fields' };
    }

    return { input, error: null };
  }

  async function upsertTrail() {
    const { input, error } = getInputAndError();

    if (!input) {
      toast({
        description: error,
        status: 'error'
      });
      return;
    }

    try {
      if (isDraft) {
        await createTrail({ variables: { input } });
      } else {
        await updateTrail({ variables: { input, id: idOrDraft as string } });
      }
      actions.clearDraft();
    } catch (e) {
      captureException(e, { extra: { input, draftMeta } });
    }
  }

  const { data, loading, error } = isDraft ? createResponse : updateResponse;

  function assignTrail() {
    if (!data?.result) {
      return;
    }
    setSessionStorageObject(ASSIGN_TRAIL_KEY, data.result);
  }

  return {
    upsertTrail,
    data,
    loading,
    error,
    assignTrail
  };
}

export default useUpsertTrails;
