import {
  ENTERPRISE_DATA,
  INDIVIDUAL_DATA,
  PlanDataType,
  TEAM_DATA
} from 'fixtures/payment-fixtures';
import { ContentType } from '@tigerhall/core';

/** @returns {number} */
export const combinedCollectionTime = ({ podcasts, ebooks }) => {
  const podcastTime = podcasts
    ? podcasts.reduce((combinedTime, { length }) => combinedTime + length, 0)
    : 0;

  const ebookTime = ebooks
    ? ebooks.reduce(
        (combinedTime, { readingTime }) => combinedTime + readingTime * 60,
        0
      )
    : 0;

  return podcastTime + ebookTime;
};

export const roundToMinutesOrHours = (
  secondsTotal: number,
  shortenText: string | boolean
) => {
  const hours = Math.floor(secondsTotal / 3600);
  const minutes = Math.floor(secondsTotal / 60) - hours * 60;
  const hourText = hours > 1 ? 'hours' : 'hour';

  if (shortenText === 'shortest') {
    if (secondsTotal < 60) {
      return `${secondsTotal}s`;
    }
    return hours >= 1 ? `${hours}h ${minutes}m` : `${minutes}m`;
  }

  if (shortenText) {
    if (secondsTotal < 60) {
      return `${secondsTotal} secs`;
    }

    return hours >= 1
      ? `${hours} ${hourText} ${minutes} min`
      : `${minutes} min`;
  }

  if (hours > 1) {
    return `${hours} ${hourText} ${minutes} minutes`;
  }

  if (secondsTotal < 60) {
    return `${secondsTotal} seconds`;
  }

  return `${minutes} minutes`;
};

export type PlanType = 'Individuals' | 'Teams' | 'Enterprises';

export const getPlanTypeData = (planType: PlanType): PlanDataType => {
  switch (planType) {
    case 'Individuals':
      return INDIVIDUAL_DATA;
    case 'Teams':
      return TEAM_DATA;
    case 'Enterprises':
      return ENTERPRISE_DATA;
    default:
      return INDIVIDUAL_DATA;
  }
};

export function typenameToContentType(typename: string): ContentType {
  switch (typename) {
    case 'Stream':
      return ContentType.Stream;
    case 'Podcast':
      return ContentType.Podcast;
    case 'Event':
      return ContentType.Event;
    case 'Ebook':
      return ContentType.Ebook;
    case 'LearningPath':
      return ContentType.LearningPath;
    default:
      return ContentType.Stream;
  }
}
