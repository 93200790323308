import { Flex, Text } from '@chakra-ui/react';
import { DarkPanel } from 'components/ui';
import React from 'react';

export default function UserAchievements({ user }) {
  return (
    <DarkPanel gap="1.5rem">
      <Text fontSize="1.25rem" fontWeight="700" color="white">
        Achievements
      </Text>
      <Flex direction="column" gap="1rem">
        <Flex justifyContent="space-between">
          <Text fontSize="1rem" fontWeight="500" color="darkGrey.50">
            Experiences attended
          </Text>
          <Text fontSize="1rem" fontWeight="500" color="darkGrey.50">
            {user?.stats?.eventsAttended}
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontSize="1rem" fontWeight="500" color="darkGrey.50">
            Power Reads read
          </Text>
          <Text fontSize="1rem" fontWeight="500" color="darkGrey.50">
            {user?.stats?.ebooksRead}
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontSize="1rem" fontWeight="500" color="darkGrey.50">
            Podcasts completed
          </Text>
          <Text fontSize="1rem" fontWeight="500" color="darkGrey.50">
            {user?.stats?.podcastsCompleted}
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontSize="1rem" fontWeight="500" color="darkGrey.50">
            Videos completed
          </Text>
          <Text fontSize="1rem" fontWeight="500" color="darkGrey.50">
            {user?.stats?.videosCompleted}
          </Text>
        </Flex>
      </Flex>
    </DarkPanel>
  );
}
