import { Box, Flex } from '@chakra-ui/react';
import { ContentType } from '@tigerhall/core';
import {
  IconLanguage,
  IconMaximize,
  IconMinimize,
  IconSubtitle
} from '@tigerhall/icons';
import { type ReactNode, type RefObject, useState } from 'react';

import { AIBadge } from '~/badges';
import {
  ContentInfo,
  type ContentInfoProps
} from '~/player/components/ContentInfo';
import { Header, type HeaderProps } from '~/player/components/Header';
import { Languages, type LanguagesProps } from '~/player/components/Languages';
import { OptionButton } from '~/player/components/OptionButton';
import { OptionsGroup } from '~/player/components/OptionsGroup';
import { Overlay } from '~/player/components/Overlay';
import { OverlayFooterContainer } from '~/player/components/OverlayFooterContainer';
import { PlaybackRateSelector } from '~/player/components/PlaybackRateSelector';
import { PlayerControls } from '~/player/components/PlayerControls';
import { ProgressSlider } from '~/player/components/ProgressSlider';
import type { TrackSelectorProps } from '~/player/components/TrackSelector';
import {
  MiniPlayerControls,
  VideoPlayerContainer,
  type VideoPlayerContainerProps
} from '~/player/components/VideoPlayerContainer';
import { VolumeSlider } from '~/player/components/VolumeSlider';
import { useFullscreen } from '~/player/hooks/useFullscreen';
import { wrapLanguageChangeHandler } from '~/player/utils/tracking';
import { Rating, type RatingProps } from '~/rating';
import { Share } from '~/share';
import {
  OverlayBodyContainer,
  type OverlayBodyContainerProps
} from '../../components/OverlayBodyContainer';

export interface ActivePlayerProps
  extends Partial<RatingProps>,
    Pick<
      LanguagesProps,
      | 'mediaLanguages'
      | 'selectedMediaLanguage'
      | 'onChangeMediaLanguage'
      | 'transcriptLanguages'
      | 'selectedTranscriptLanguage'
      | 'onChangeTranscriptLanguage'
    >,
    Pick<ContentInfoProps, 'name' | 'casting'>,
    Pick<TrackSelectorProps, 'tracks'>,
    Pick<HeaderProps, 'playlistTitle' | 'playlistType' | 'contentUrl'>,
    Pick<
      OverlayBodyContainerProps,
      'onAutoNextPlay' | 'isLastContentInPlaylist'
    >,
    Pick<VideoPlayerContainerProps, 'isMinimized' | 'orientation'> {
  /**
   * For Videos, it is the `<video/>` element.
   */
  children: ReactNode;
  onSubtitlesToggle: () => void;
  isSubtitleActive: boolean;
  onMaximize: () => void;
  handleClose: () => void;
  /**
   * Passing the videoRef in-order to handle full-screen mode in iOS
   */
  videoRef: RefObject<HTMLVideoElement>;
  urlToShare: string;
  contentDescription: string;
}

export function ActivePlayer({
  children,
  onSubtitlesToggle,
  onMaximize,
  handleClose,
  isSubtitleActive,
  videoRef,
  contentDescription,
  urlToShare,

  // from `VideoPlayerContainerProps`
  orientation,
  isMinimized = false,

  // from `OverlayBodyContainerProps`
  onAutoNextPlay,
  isLastContentInPlaylist,

  // from `HeaderProps
  playlistTitle,
  playlistType,
  contentUrl,

  // from `TrackSelectorProps`
  tracks,

  // from `LanguagesProps`
  mediaLanguages,
  selectedMediaLanguage,
  onChangeMediaLanguage,

  // from `LanguagesProps`
  transcriptLanguages,
  selectedTranscriptLanguage,
  onChangeTranscriptLanguage,

  // from `RatingProps`
  vote,
  onLike,
  onDislike,

  // from `ContentInfoProps`
  name,
  casting
}: ActivePlayerProps) {
  const [isLanguageSelectionOpen, setIsLanguageSelectionOpen] = useState(false);

  const shouldShowLanguageSelector =
    mediaLanguages.length > 1 || transcriptLanguages.length > 0;

  // We only show the subtitles toggle if there are subtitles available and the user has selected a subtitles language
  // if not, we assume that the subtitles are turned off, so we don't show the toggle
  const shouldShowSubtitlesToggle =
    transcriptLanguages.length > 0 && !!selectedTranscriptLanguage;

  const handleAudioLanguageChange = wrapLanguageChangeHandler(
    onChangeMediaLanguage,
    {
      previousLanguage: selectedMediaLanguage
    }
  );

  const handleTranscriptLanguageChange = wrapLanguageChangeHandler(
    onChangeTranscriptLanguage,
    {
      previousLanguage: selectedTranscriptLanguage ?? 'none'
    }
  );

  const { isFullscreen, videoContainerRef, onFullscreenToggle } = useFullscreen(
    { videoRef }
  );

  return (
    <VideoPlayerContainer
      isMinimized={isMinimized}
      containerRef={videoContainerRef}
      orientation={orientation}
      videoRef={videoRef}
      onAutoNextPlay={onAutoNextPlay}
    >
      {children}
      {isMinimized ? (
        <MiniPlayerControls onMaximize={onMaximize} handleClose={handleClose} />
      ) : (
        <>
          <Overlay
            header={
              <Header
                contentType={ContentType.Stream}
                playlistTitle={playlistTitle}
                playlistType={playlistType}
                contentUrl={contentUrl}
              />
            }
            body={
              videoRef?.current?.ended ? (
                <OverlayBodyContainer
                  onAutoNextPlay={onAutoNextPlay}
                  isLastContentInPlaylist={isLastContentInPlaylist}
                  playlistType={playlistType}
                />
              ) : null
            }
            footer={
              <OverlayFooterContainer>
                <Flex justifyContent={'space-between'} gap={'1rem'}>
                  <ContentInfo name={name} casting={casting} width={'10rem'} />
                  {vote && onLike && onDislike ? (
                    <Rating vote={vote} onLike={onLike} onDislike={onDislike} />
                  ) : null}
                </Flex>
                <ProgressSlider />
                <PlayerControls
                  size={'lg'}
                  hideTrackControls={tracks.length <= 1}
                />
                <OptionsGroup>
                  <Share
                    urlToShare={urlToShare}
                    titleToShare={name}
                    descriptionToShare={contentDescription}
                    color="darkGrey.50"
                    sx={{ svg: { width: '1.5rem', height: '1.5rem' } }}
                  />
                  <PlaybackRateSelector />
                  {shouldShowLanguageSelector ? (
                    <Box pos={'relative'}>
                      <OptionButton
                        label={'Change Language'}
                        icon={IconLanguage}
                        iconColor="darkGrey.50"
                        onClick={() => {
                          setIsLanguageSelectionOpen(true);
                        }}
                      />
                      <AIBadge
                        variant="mini"
                        pos={'absolute'}
                        top={0}
                        right={0}
                      />
                    </Box>
                  ) : null}
                  {shouldShowSubtitlesToggle ? (
                    <OptionButton
                      label={'Turn subtitles on/off'}
                      icon={IconSubtitle}
                      iconColor={
                        isSubtitleActive ? 'tigerOrange.600' : 'darkGrey.50'
                      }
                      onClick={() => {
                        onSubtitlesToggle();
                      }}
                    />
                  ) : null}
                  <OptionButton
                    label={
                      isFullscreen ? 'Exit full screen ' : 'Enter full screen'
                    }
                    iconColor="darkGrey.50"
                    icon={isFullscreen ? IconMinimize : IconMaximize}
                    onClick={() => {
                      onFullscreenToggle();
                    }}
                  />
                  <VolumeSlider />
                </OptionsGroup>
              </OverlayFooterContainer>
            }
          />
          <Languages
            mediaHeading={'Audio'}
            transcriptHeading={'Subtitles'}
            mediaLanguages={mediaLanguages}
            transcriptLanguages={transcriptLanguages}
            selectedMediaLanguage={selectedMediaLanguage}
            selectedTranscriptLanguage={selectedTranscriptLanguage}
            onChangeMediaLanguage={handleAudioLanguageChange}
            onChangeTranscriptLanguage={handleTranscriptLanguageChange}
            isOpen={isLanguageSelectionOpen}
            onClose={() => {
              setIsLanguageSelectionOpen(false);
            }}
          />
        </>
      )}
    </VideoPlayerContainer>
  );
}
