import { Flex } from '@chakra-ui/react';
import { StreamSource } from '@tigerhall/core';
import { getPlayingContent, temporarilyPauseAndHide } from 'app/state';
import { type MODALTYPES } from 'components/ui/modals';
import {
  useAppDispatch,
  useAppSelector,
  useCurrentAsLastLocation
} from 'hooks';
import isNull from 'lodash/isNull';
import { useLiveVideoActions } from 'modules/application/hooks/useLiveVideoActions';
import { type ReactNode, Suspense, lazy, useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

const CommonModal = lazy(() =>
  import('components/ui/modals').then((m) => ({
    default: m.CommonModal
  }))
);

const ContentModal = lazy(() =>
  import('./components/ContentModal').then((m) => ({ default: m.ContentModal }))
);

const LearningPathModal = lazy(() =>
  import('./components/LearningPathModal').then((m) => ({
    default: m.LearningPathModal
  }))
);

const AssignmentModal = lazy(() =>
  import('./components/AssignmentModal').then((m) => ({
    default: m.AssignmentModal
  }))
);

function createRedirectUrl(searchParams: URLSearchParams) {
  return window.location.pathname.concat('?', searchParams.toString());
}

/**
 * We can't or shouldn't render multiple modals on top of each other so returns the single model to render
 */
function ModalByPriority() {
  const dispatch = useAppDispatch();
  const playingContent = useAppSelector(getPlayingContent);
  const [searchParams] = useSearchParams();
  const { playLiveVideo } = useLiveVideoActions();
  useCurrentAsLastLocation();

  useEffect(() => {
    if (searchParams.has('power-read')) {
      dispatch(temporarilyPauseAndHide());
    }
  }, [searchParams, dispatch]);

  switch (true) {
    case searchParams.has('broadcast'): {
      const id = searchParams.get('broadcast') as string;

      if (playingContent?.id === id) {
        searchParams.delete('broadcast');

        return <Navigate to={createRedirectUrl(searchParams)} replace />;
      }

      playLiveVideo({
        type: StreamSource.BroadcastCloudflareLive,
        id
      });
      return null;
    }
    case searchParams.has('livestream'): {
      const id = searchParams.get('livestream');

      if (isNull(id)) {
        return null;
      }

      if (playingContent?.id === id) {
        searchParams.delete('livestream');
        return <Navigate to={createRedirectUrl(searchParams)} replace />;
      }

      playLiveVideo({
        type: StreamSource.LivestreamAgora,
        id
      });

      return null;
    }
    case searchParams.has('podcast'): {
      const id = searchParams.get('podcast') as string;
      return <Navigate to={`?content=${id}`} />;
    }
    case searchParams.has('video'): {
      const id = searchParams.get('video') as string;
      return <Navigate to={`?content=${id}`} />;
    }
    case searchParams.has('power-read'): {
      const id = searchParams.get('power-read') as string;
      return <Navigate to={`?content=${id}`} />;
    }
    case searchParams.has('modal'):
      return (
        <Suspense fallback={null}>
          <CommonModal modalType={searchParams.get('modal') as MODALTYPES} />
        </Suspense>
      );

    case searchParams.has('content'):
      return (
        <Suspense fallback={null}>
          <ContentModal id={searchParams.get('content') as string} />
        </Suspense>
      );

    case searchParams.has('trail'):
      return (
        <Suspense fallback={null}>
          <LearningPathModal id={searchParams.get('trail') as string} />
        </Suspense>
      );

    case searchParams.has('assignment'):
      return (
        <Suspense fallback={null}>
          <AssignmentModal id={searchParams.get('assignment') as string} />
        </Suspense>
      );
    default:
      return null;
  }
}

interface ModalContentContainerProps {
  children?: ReactNode;
}

export function ContentModalContainer({
  children
}: Readonly<ModalContentContainerProps>) {
  return (
    <Flex
      direction="column"
      bg="darkGrey.700"
      h="100%"
      flex={1}
      overflowY="auto"
      css={{
        '&::-webkit-scrollbar': {
          width: '8px'
        },
        '&::-webkit-scrollbar-track': {
          width: '6px'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#C4C4C4',
          borderRadius: '24px'
        }
      }}
    >
      <ModalByPriority />
      {children}
    </Flex>
  );
}
