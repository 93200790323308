import React from 'react';
import { useLocation } from 'react-router-dom';
import { setIsExpanded } from 'app/state';
import { useAppDispatch } from 'hooks';

export function useCloseSidebarOnMobileNavigation(
  isMobile: boolean,
  isExpanded: boolean
) {
  const location = useLocation();
  const lastLocation = React.useRef(location);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (isMobile && isExpanded && location !== lastLocation.current) {
      dispatch(setIsExpanded(false));
      lastLocation.current = location;
    }
  }, [location, dispatch, isMobile, isExpanded]);

  return null;
}
