import { Flex, Heading } from '@chakra-ui/react';
import { FormInput } from '@tigerhall/components';

export function FormSectionBroadcastDetails() {
  return (
    <Flex
      direction="column"
      backgroundColor="darkGrey.400"
      p="1rem"
      border="1px solid"
      borderColor="darkGrey.200"
      borderRadius="lg"
    >
      <Heading mb="1rem">Broadcast configuration</Heading>
      <FormInput
        name="broadcastConfiguration.rtmpUrl"
        label="RTMP Url"
        placeholder="Enter RTMP Url"
        disabled
      />

      <FormInput
        name="broadcastConfiguration.streamKey"
        label="Stream key"
        placeholder="Enter RTMP Url"
        disabled
      />
    </Flex>
  );
}
