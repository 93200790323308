import { Box, Button, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { IconBrandTigerhallHorizontal } from '@tigerhall/icons';
import { Hero } from 'modules/authentication/modules/Login/components/Hero';
import { Link } from 'react-router-dom';
import * as segment from '@tigerhall/analytics';
import { TrackedLink } from '@tigerhall/components';
import { VStack } from '@chakra-ui/layout';

const TIGERHALL_WEB_URL: string = import.meta.env.VITE_WEB_URL;

export function BaseLoginLayout({ children }) {
  return (
    <Flex
      flex={1}
      direction="row"
      minW="100vw"
      minH="100vh"
      backgroundColor="darkGrey.700"
    >
      <Flex
        flex={1}
        direction="column"
        p={{
          base: '2rem',
          sm: '3.5rem',
          lg: '3.5rem'
        }}
      >
        <Flex
          flex={1}
          px={{
            lg: '3rem'
          }}
          pb="1.5rem"
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Box mx="auto" flex={1}>
            <Icon
              as={IconBrandTigerhallHorizontal}
              w="8rem"
              h="2.5rem"
              color="tigerOrange.600"
            />
          </Box>

          <Box w="100%" maxW="27rem" flexGrow={1}>
            <Heading
              fontSize="1.25rem"
              lineHeight="1.5rem"
              textAlign="center"
              color="white"
              fontWeight="700"
              my="2.5rem"
            >
              Login
            </Heading>
            {children}
            <VStack gap={'1rem'}>
              <Flex alignItems="center" justifyContent="center" mt={'1rem'}>
                <Link to="/reset-password" data-cy="forgot-password">
                  <Text fontSize="sm" color="tigerOrange.600" as="span">
                    Forgot password?
                  </Text>
                </Link>
              </Flex>
              <Flex
                direction="row"
                gap="1rem"
                alignItems="center"
                justifyContent="center"
              >
                <Text fontSize="sm" color="lightGrey.900" as="span">
                  Do not have an account?
                </Text>
                <Link to="/signup" data-cy="sign-up-now-button">
                  <Button
                    onClick={() => {
                      segment.buttonClicked({
                        buttonName: 'Sign Up Now',
                        location: window.location.pathname
                      });
                    }}
                    variant="outline"
                    borderRadius="md"
                    size="sm"
                  >
                    Sign Up Now
                  </Button>
                </Link>
              </Flex>
              <Text fontSize="sm" textAlign="center">
                By logging in, you agree to our
                <TrackedLink
                  name="termAndConditions"
                  ml={1}
                  color="tigerOrange.600"
                  href={`${TIGERHALL_WEB_URL}/terms-of-service`}
                  isExternal
                >
                  Terms & Conditions.
                </TrackedLink>
              </Text>
            </VStack>
          </Box>
        </Flex>

        <TrackedLink
          name="VISIT_TIGERHALL_DOT_COM"
          href={TIGERHALL_WEB_URL}
          target="_blank"
          pos="absolute"
          bottom="2.5rem"
          left="2.5rem"
          fontSize="sm"
          fontWeight="normal"
          color="tigerOrange.600"
        >
          Visit Tigerhall.com
        </TrackedLink>
      </Flex>
      <Hero />
    </Flex>
  );
}
