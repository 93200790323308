import { VStack, Text } from '@chakra-ui/layout';
import { Center } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInterests } from 'modules/authentication/modules/Onboarding/hooks/useInterests';
import { DummyCopies } from 'components/ui';
import { TigerhallSkeleton } from '@tigerhall/ui-components';
import { TrackedButton } from '@tigerhall/components';

import { InterestSelectorList } from './children';

export function TopicInterestSelector() {
  const navigate = useNavigate();

  const navigateToCreatingAccount = useCallback(() => {
    navigate('/signup/individual/creating-account');
  }, [navigate]);

  const {
    loading,
    lifeGoalsData,
    setSelectedIDs,
    selectedIDs,
    handleClick,
    isUpdating
  } = useInterests({
    onComplete: navigateToCreatingAccount
  });

  return (
    <VStack spacing="3.25rem">
      <Text color="white" fontSize="3xl" textAlign="center" fontWeight="bold">
        Select the topics you are interested in following
      </Text>
      {loading ? (
        <Center width="100%" flexWrap="wrap">
          <DummyCopies count={10}>
            <TigerhallSkeleton variant="cloud_item" />
          </DummyCopies>
        </Center>
      ) : (
        <InterestSelectorList
          setSelectedIDs={setSelectedIDs}
          selectedIDs={selectedIDs}
          lifeGoadData={lifeGoalsData}
        />
      )}
      <TrackedButton
        style={{ marginTop: '4.25rem' }}
        size="lg"
        w="100%"
        maxW="27rem"
        isLoading={isUpdating}
        onClick={() => handleClick(selectedIDs)}
        name="interestSubmit"
      >
        Confirm
      </TrackedButton>
    </VStack>
  );
}
