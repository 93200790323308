import * as React from 'react';
import {
  ButtonProps,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react';
import { IconUsersGroup, IconUser } from '@tigerhall/icons';
import { OrganisationGroup, User } from '@tigerhall/core/lib/types';
import { Table, TableBodyFallback } from '@tigerhall/ui-components';
import { ContentPanel } from 'components/ui';

import { getContentGroupsColumns, getContentUsersColumns } from '../table';

type UsersAndGroupsProps = {
  members: User[];
  orgGroups?: OrganisationGroup[];
  loading: boolean;
  inlineButtons?: (ButtonProps & { text: string })[];
};

export function UsersAndGroups({
  members,
  loading,
  orgGroups,
  inlineButtons
}: Readonly<UsersAndGroupsProps>) {
  const USER_COLUMNS = getContentUsersColumns();
  const GROUP_COLUMNS = getContentGroupsColumns();

  const TABLE_DATA = {
    users: {
      data: members,
      columns: USER_COLUMNS,
      total: members?.length ?? 0
    },
    groups: {
      data: orgGroups,
      columns: GROUP_COLUMNS,
      total: orgGroups?.length ?? 0
    }
  };

  return (
    <Flex direction="column" gap="1.5rem">
      <ContentPanel
        heading="Selected Users & Groups"
        inlineButtons={inlineButtons}
      >
        <Tabs isLazy>
          <TabList>
            <Tab>{`Users (${TABLE_DATA.users.total})`}</Tab>
            <Tab>{`Groups (${TABLE_DATA.groups.total})`}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel padding="0" pt="1.5rem">
              <Table
                name="assignmentUsers"
                loading={loading}
                data={TABLE_DATA.users?.data ?? []}
                columns={TABLE_DATA.users?.columns}
                rowsSizes={[TABLE_DATA.users?.data?.length ?? 0]}
                emptyMessage={
                  <TableBodyFallback
                    icon={IconUser}
                    heading="No User Selected"
                    subheading="You have not assigned any user to this assignment"
                  />
                }
              />
            </TabPanel>
            <TabPanel padding="0" pt="1.5rem">
              <Table
                name="assignmentGroups"
                loading={loading}
                data={TABLE_DATA.groups?.data ?? []}
                columns={TABLE_DATA.groups?.columns}
                rowsSizes={[TABLE_DATA.groups?.data?.length ?? 0]}
                emptyMessage={
                  <TableBodyFallback
                    icon={IconUsersGroup}
                    heading="No Group Selected"
                    subheading="You have not assigned any group to this assignment"
                  />
                }
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ContentPanel>
    </Flex>
  );
}
