const styles = {
  baseStyle: {
    body: {
      backgroundColor: 'teal.900',
      width: 'auto',
      px: '32px'
    },
    header: {
      backgroundColor: 'teal.900',
      fontWeight: '700',
      color: 'orange.600'
    },
    closeButton: {
      color: 'white',
      m: 5
    }
  },

  defaultProps: {
    size: 'lg'
  }
};

export default styles;
