import { mode as t } from "./index31.mjs";
const o = {
  // style object for base or default style
  baseStyle: (e) => ({
    fontSize: "xl",
    fontWeight: "medium",
    // todo: this should be 1.5 but it will break many components,
    // including the content cards. This change has to be done
    // carefully in the near future.
    lineHeight: "1.375",
    // same as short
    letterSpacing: "0.015em",
    color: t("darkGrey.400", "lightGrey.900")(e)
  }),
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {},
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    size: "",
    variant: "",
    colorScheme: ""
  }
};
export {
  o as Text
};
