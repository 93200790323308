import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  EntryLevelValue,
  StreamInput,
  StreamSource
} from '@tigerhall/core/lib/types';
import { useAppSelector } from 'hooks';
import { getSelectedOrgId } from 'app/state';
import { useToast } from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';

import { Form, FormValues } from './components/Form';
import { useFetchStreamQuery } from '../../queries/generated';
import { useManageStream } from './hooks';

export function VideoForm() {
  const { idOrDraft } = useParams();

  const orgId = useAppSelector(getSelectedOrgId);

  const [hasError, setHasError] = React.useState(false);

  const toast = useToast();

  const isDraft = idOrDraft === 'draft';

  const { createStream, updateStream, progress } = useManageStream({
    updateStreamId: !isDraft ? idOrDraft : undefined,
    setHasError
  });

  const { data, loading, error } = useFetchStreamQuery({
    variables: {
      id: idOrDraft as string
    },

    skip: isDraft
  });

  const onSubmit = React.useCallback(
    async (values: FormValues, form: UseFormReturn<FormValues>) => {
      // reset error state before submitting
      if (hasError) {
        setHasError(false);
      }

      if (!form) {
        toast({
          description: 'form is not found'
        });
        return;
      }

      const { setError } = form;

      const input: StreamInput = {
        name: values.name,
        source: StreamSource.Upload,
        preamble: values.preamble,

        contentSegments: values?.contentSegments.map((s) => ({
          header: s?.header,
          paragraph: s?.paragraph
        })),

        image: values.image?.id ?? '',
        experts: values.experts.map((e) => e?.id),
        categories: values.categories.map((c) => c?.id),

        organisationID: orgId,
        organisationGroups: values.organisationGroups?.map((g) => g?.id) || [],

        publishedAt: values.publishedAt,

        isSensitive: false,
        entryLevel: EntryLevelValue.Beginner
      };

      if (isDraft) {
        await createStream(input, setError, values.video);
      } else {
        await updateStream(input, setError);
      }
    },
    [hasError, orgId, isDraft, createStream, updateStream, toast]
  );

  if (loading || error || !orgId) {
    return null;
  }

  const defaultValues = isDraft
    ? { contentSegments: [{ header: '', paragraph: '' }] }
    : {
        ...data?.stream,
        publishedAt:
          data?.stream?.publishedAt && new Date(data.stream.publishedAt)
      };

  return (
    <Form
      isDraft={isDraft}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      uploadProgress={progress}
      isUploadingError={hasError}
      includePublicExperts={false}
    />
  );
}
