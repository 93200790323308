import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgPlaylistPlay = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    focusable={false}
    className="th-icon"
    color="white"
    width="1.25rem"
    height="1.25rem"
    ref={ref}
    {...props}
  >
    <g fill="currentcolor" fillRule="evenodd" clipRule="evenodd">
      <path d="M4 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1ZM16.53 12.152A1 1 0 0 0 15 13v5a1 1 0 0 0 1.53.848l4-2.5a1 1 0 0 0 0-1.696l-4-2.5ZM4 12a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1ZM4 16a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Z" />
    </g>
  </svg>
);
SvgPlaylistPlay.displayName = "IconPlaylistPlay";
const ForwardRef = forwardRef(SvgPlaylistPlay);
export default ForwardRef;
