import { Center, Flex, Spinner, Text, VStack } from '@chakra-ui/react';
import { Fragment } from 'react';
import { RichTextMarkdownWrapper } from 'components/ui/MarkdownWrapper/RichTextMarkdownWrapper';
import { getExperts } from 'components/ui/ContentPlayer/components/LiveContentConnected/utils';

import {
  useGetBroadcastAgendaSubscription,
  useGetLiveStreamMoreInfoQuery
} from './queries/generated';
import { BroadcastAgenda } from './BroadcastAgenda';
import { GuestsList } from './GuestsList';

export interface LiveMoreInfoTabProps {
  streamId: string;
}

export function LiveMoreInfoTab({ streamId }: Readonly<LiveMoreInfoTabProps>) {
  const { data: moreInfo, loading: isLoadingMoreInfo } =
    useGetLiveStreamMoreInfoQuery({
      variables: {
        id: streamId
      }
    });

  const { data: broadcastAgenda } = useGetBroadcastAgendaSubscription({
    variables: {
      id: streamId
    }
  });

  const contentSegments = moreInfo?.stream?.contentSegments ?? [];

  const experts = getExperts({
    source: moreInfo?.stream?.source,
    hosts: moreInfo?.stream?.hosts,
    experts: moreInfo?.stream?.experts
  });

  const agenda = broadcastAgenda?.stream?.agenda ?? [];

  const isAgendaAvailable = agenda.length > 0;

  if (isLoadingMoreInfo) {
    return (
      <Center width={'full'} height={'full'} p={'2rem'} gap={4}>
        <Spinner color={'tigerOrange.600'} />
        <Text color={'lightGrey.600'} fontSize={'sm'}>
          Loading...
        </Text>
      </Center>
    );
  }

  return (
    <Flex direction={'column'} alignItems={'flex-start'} w={'full'} gap={6}>
      <VStack alignItems={'flex-start'} spacing={'3'} marginX={'4'}>
        {contentSegments.map((content) => (
          <Fragment key={content.header}>
            <Text color={'lightGrey.200'} fontSize={'xl'} fontWeight={'bold'}>
              {content.header}
            </Text>
            <RichTextMarkdownWrapper fontSize={'sm'} color={'lightGrey.600'}>
              {content.paragraph}
            </RichTextMarkdownWrapper>
          </Fragment>
        ))}
      </VStack>

      {isAgendaAvailable ? (
        <BroadcastAgenda agenda={agenda} />
      ) : (
        <GuestsList experts={experts} />
      )}
    </Flex>
  );
}
