import * as React from 'react';
import { Flex, Icon, IconButton, Text, VStack } from '@chakra-ui/react';
import { IconCaretDownFilled } from '@tigerhall/icons';

type PagesCounterProps = {
  label?: string | number;
  onClickUp: (() => void) | undefined;
  onClickDown: (() => void) | undefined;
};

export function PagesCounter({
  label,
  onClickUp,
  onClickDown
}: PagesCounterProps) {
  return (
    <Flex
      display="flex"
      alignItems="center"
      justifyContent="center"
      border="1px solid"
      borderColor="darkGrey.200"
      borderRadius="base"
      backgroundColor="darkGrey.400"
      height="2rem"
    >
      <Text
        as="span"
        width={10}
        textAlign="center"
        fontSize="xs"
        color="inherit"
      >
        {label}
      </Text>
      <VStack py="0.125rem" spacing="0.125rem" height="100%" pr=".125rem">
        <IconButton
          variant="solid"
          height="1rem"
          minWidth="1rem"
          aria-label="up"
          onClick={onClickUp}
          isDisabled={!onClickUp}
          borderRadius="0.125rem"
          background="white"
          icon={
            <Icon
              as={IconCaretDownFilled}
              w=".5rem"
              h=".5rem"
              display="flex"
              justifyContent="center"
              color="inherit"
              transform="auto"
              rotate="180deg"
            />
          }
        />
        <IconButton
          variant="solid"
          height="1rem"
          minWidth="1rem"
          aria-label="down"
          onClick={onClickDown}
          isDisabled={!onClickDown}
          borderRadius="0.125rem"
          background="white"
          icon={
            <Icon
              as={IconCaretDownFilled}
              w=".5rem"
              h=".5rem"
              display="flex"
              justifyContent="center"
              color="inherit"
            />
          }
        />
      </VStack>
    </Flex>
  );
}
