import {
  CommentType,
  PostActivityType,
  type PostActivity as PostActivityInterface
} from '@tigerhall/core';

import { FeedItemContainer } from '../FeedItemContainer';
import { ActivityHeader, type ActivityHeaderProps } from '../ActivityHeader';
import { PostBody } from '../Post/components/PostBody';
import { ActivityComment, type ActivityCommentProps } from '../ActivityComment';
import { PostBodyProps } from '../Post/types';

export interface PostActivityProps
  extends Pick<ActivityCommentProps, 'onDelete'> {
  activity: {
    activityType: PostActivityInterface['activityType'];
    user: ActivityHeaderProps['user'];
    post: PostBodyProps['post'];
    comment?: ActivityCommentProps['comment'] | null;
  };
}

export function PostActivity({ activity, onDelete }: PostActivityProps) {
  const { activityType } = activity;
  const isInstantRepost = activityType === PostActivityType.Reposted;

  return (
    <FeedItemContainer>
      <ActivityHeader
        user={activity.user}
        activityType={activity.activityType}
        postOrContentId={activity.post.id}
      />
      <PostBody
        post={activity.post}
        type={isInstantRepost ? 'INSTANT_REPOST' : 'ACTIVITY'}
      />
      {!!activity.comment && (
        <ActivityComment
          referenceId={activity.post.id}
          referenceType={CommentType.Post}
          comment={activity.comment}
          onDelete={onDelete}
        />
      )}
    </FeedItemContainer>
  );
}
