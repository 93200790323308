import { Navigate, RouteObject, useParams } from 'react-router-dom';

import { Explore } from './screens';

function Redirect({ to }: { to: 'topic' | 'category' }) {
  const params = useParams<{ id: string }>();
  return <Navigate to={`/content/${to}/${params.id}`} replace />;
}

const routes: RouteObject[] = [
  {
    path: 'explore/',
    children: [
      {
        index: true,
        element: <Explore />
      },
      {
        path: 'topic/:id',
        element: <Redirect to="topic" />
      },
      {
        path: 'category/:id',
        element: <Redirect to="category" />
      }
    ]
  }
];

export default routes;
