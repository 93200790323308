import { useEffect, useState } from 'react';

export const hasFocus = () =>
  typeof document !== 'undefined' && document.hasFocus();

export function useTabFocusHandler() {
  const [isFocused, setIsFocused] = useState<boolean>(hasFocus);

  useEffect(() => {
    setIsFocused(hasFocus());

    const onFocus = () => setIsFocused(true);
    const onBlur = () => setIsFocused(false);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return {
    isFocused
  };
}
