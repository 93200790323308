import { Box, Center, VStack } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { useMicrosoftTeams } from '@tigerhall/core';
import { type User } from '@tigerhall/core/lib/types';
import { type ApolloError } from '@apollo/client';
import { TigerhallSkeleton } from '@tigerhall/ui-components';
import { BannerImage } from 'modules/application/modules/Profile/components/BannerImage';
import DefaultBannerImage from 'assets/images/profileDefaultCover.jpg';
import { useParams } from 'react-router-dom';

import { BackButton } from '~/components/ui/Buttons';
import { BlockedProfileView } from '~/modules/application/modules/Profile/screens/Profile/components/BlockedProfileView';
import { ProfileDetails } from '~/modules/application/modules/Profile/screens/Profile/components/ProfileDetails';
import { useGetPublicUserInformationQuery } from '~/generated';

type ProfileProps = {
  user: User;
  loading: boolean;
  error: ApolloError;
  isCurrentUser: boolean;
  isBlocked?: boolean;
};

function Profile({
  user: basicUserDetails,
  loading,
  error,
  isCurrentUser,
  isBlocked
}: Readonly<ProfileProps>) {
  const { profileId } = useParams<{ profileId: string }>();

  const {
    data,
    loading: isLoadingPublicInformation,
    error: errorInPublicInformation
  } = useGetPublicUserInformationQuery({
    variables: {
      id: profileId as string
    },
    skip: isBlocked || isCurrentUser
  });

  const user = (
    isBlocked || isCurrentUser ? basicUserDetails : data?.user
  ) as User;

  const { isInTeams } = useMicrosoftTeams();

  if (loading || isLoadingPublicInformation) {
    return (
      <Box mt={4}>
        <TigerhallSkeleton variant="profile_detail" />
      </Box>
    );
  }

  if (error || errorInPublicInformation) {
    const message = error?.message ?? errorInPublicInformation?.message;
    return (
      <Box w="48rem" mx="auto" background="lightGrey.200">
        <Center>{message || 'error occurred!'}</Center>
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {user.firstName} {user.lastName} - Profile
        </title>
      </Helmet>
      <Box
        css={{
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <Box
          maxW={{ base: '100vw', md: '48rem' }}
          w="100%"
          mx="auto"
          mt={{
            base: 0,
            md: '2rem'
          }}
        >
          {isInTeams ? (
            <Box display={{ base: 'none', md: 'block' }} pb="2rem">
              <BackButton />
            </Box>
          ) : null}
          <VStack
            mb={4}
            backgroundColor="darkGrey.700"
            borderColor="darkGrey.300"
            borderWidth={{ base: 0, md: '1px' }}
            borderRadius={{
              base: 0,
              md: '2rem'
            }}
            height="auto"
            position="relative"
            overflow="hidden"
          >
            <Box height="170px" maxHeight="170px" w="full">
              <BannerImage
                bannerImgUri={
                  user?.coverImage?.uri
                    ? user?.coverImage?.uri
                    : DefaultBannerImage
                }
              />
            </Box>

            <VStack
              height="auto"
              alignItems="flex-start"
              w="full"
              spacing="1.5rem"
            >
              {isBlocked ? (
                <BlockedProfileView user={user} />
              ) : (
                <ProfileDetails isCurrentUser={isCurrentUser} user={user} />
              )}
            </VStack>
          </VStack>
        </Box>
      </Box>
    </>
  );
}
export default Profile;
