import { VStack } from '@chakra-ui/react';
import {
  SignInWithGoogle,
  SignInWithMicrosoft,
  SignInWithLinkedIn
} from 'modules/authentication/components';

export function SocialLogins() {
  return (
    <VStack spacing="0.75rem" w="full">
      <SignInWithGoogle />
      <SignInWithMicrosoft />
      <SignInWithLinkedIn />
    </VStack>
  );
}
