import React from 'react';
import { BoxProps, Flex } from '@chakra-ui/react';

import { PrimaryButton } from './ContentBox';
import WizardHeader from './components/WizardHeader';
import WizardContent from './components/WizardContent';

type Props = {
  title?: string;
  subTitle?: string;
  description?: string;
  buttons?: PrimaryButton[];
  children: React.ReactNode;
  loading?: boolean;
  currentStep?: number;
} & BoxProps;

/**
 * The ContentWizard is used for content creation where we want to have
 * multiple steps in the creation process
 *
 * @constructor
 */
const ContentWizard = ({
  title,
  currentStep,
  description,
  children,
  buttons: primaryButtons,
  loading,
  ...restProps
}: Props) => (
  <Flex direction="column" {...restProps}>
    <WizardHeader
      currentStep={currentStep}
      primaryButtons={primaryButtons}
      title={title}
      description={description}
    />

    <WizardContent loading={loading}>{children}</WizardContent>
  </Flex>
);

export default ContentWizard;
