import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCurrentLoggedInUserQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type GetCurrentLoggedInUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, isExpert: boolean, lastName: string, firstName: string, avatarImage: { __typename?: 'Image', alt: string, blurHash: string, id: string, uri: string } } | null };


export const GetCurrentLoggedInUserDocument = gql`
    query GetCurrentLoggedInUser {
  user: me {
    id
    isExpert
    lastName
    firstName
    avatarImage {
      alt
      blurHash
      id
      uri
    }
  }
}
    `;

/**
 * __useGetCurrentLoggedInUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentLoggedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentLoggedInUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentLoggedInUserQuery, GetCurrentLoggedInUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentLoggedInUserQuery, GetCurrentLoggedInUserQueryVariables>(GetCurrentLoggedInUserDocument, options);
      }
export function useGetCurrentLoggedInUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentLoggedInUserQuery, GetCurrentLoggedInUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentLoggedInUserQuery, GetCurrentLoggedInUserQueryVariables>(GetCurrentLoggedInUserDocument, options);
        }
export type GetCurrentLoggedInUserQueryHookResult = ReturnType<typeof useGetCurrentLoggedInUserQuery>;
export type GetCurrentLoggedInUserLazyQueryHookResult = ReturnType<typeof useGetCurrentLoggedInUserLazyQuery>;
export type GetCurrentLoggedInUserQueryResult = Apollo.QueryResult<GetCurrentLoggedInUserQuery, GetCurrentLoggedInUserQueryVariables>;