import * as React from 'react';
import { Box, Image, LinkBox } from '@chakra-ui/react';
import {
  getContentCardProgress,
  isFinished,
  resizeImage
} from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';

import { ContentTimeBadge, ContentTypeBadge } from '~/badges';
import {
  ContentBoxDetails,
  type ContentBoxDetailsLinksProps
} from './components/ContentBoxDetails';
import { ContentBoxEventTimer } from './components/ContentBoxEventTimer';
import { ContentBoxCompletion } from './components/ContentBoxCompletion';
import type { ContentBoxBaseProps } from './types';

export const CONTENT_CARD_DIMENSIONS = {
  height: {
    base: '15.5rem',
    lg: '17rem'
  },
  minWidth: {
    base: '15rem',
    lg: '15.25rem'
  },
  maxWidth: {
    base: '15rem',
    lg: '16.25rem'
  }
};

export type ContentCardProps = ContentBoxBaseProps &
  ContentBoxDetailsLinksProps;

export function ContentCard(props: ContentCardProps) {
  const {
    slug,
    name,
    image,
    categories,
    experts,
    eventType,
    boxShadow,
    sectionName,
    sectionIndex,
    isAssigned,
    getPathToContent,
    getPathToCategory,
    getPathToExpert,
    disableUserProgress,
    onBookmarkClick,
    disableShareButton
  } = props;

  const imageSrc = image?.uri || '';
  const imageAlt = image?.alt || '';
  const category = categories?.[0] || {};
  const expert = experts?.[0];

  const hasFinished = isFinished(props);
  const { consumed, total } = getContentCardProgress(props);

  // total can't be 0 because 0/0 = NaN
  const progress = hasFinished ? 100 : (consumed / (total || 1)) * 100;
  const contentPiecesCount =
    props.__typename === 'LearningPath' ? props?.sortOrder?.length : 0;

  return (
    <LinkBox
      as="article"
      {...CONTENT_CARD_DIMENSIONS}
      display="flex"
      flexDirection="column"
      rounded="lg"
      overflow="hidden"
      background="white"
      boxShadow={boxShadow}
      onClick={() => {
        segment.contentCardClicked({
          categories: categories?.map((c) => c.name),
          categoriesIds: categories?.map((c) => c.id),

          experts: experts?.map((e) => `${e.firstName} ${e.lastName}`),
          expertsIds: experts?.map((e) => e.id),

          contentId: props.id,
          contentName: props.name,
          contentType: props.__typename,

          duration: props.length,

          sectionName,
          cardIndexInList: sectionIndex,
          isAssigned
        });
      }}
    >
      <Box
        position="relative"
        height={{
          base: '6.5rem',
          lg: '5.5rem'
        }}
        minHeight={{
          base: '6.5rem',
          lg: '7.5rem'
        }}
        width="100%"
        background="lightGrey.400"
        overflow="hidden"
      >
        <Image
          src={resizeImage({ url: imageSrc, width: 244, height: 120 })}
          alt={imageAlt}
          width="100%"
          height="100%"
          objectFit="cover"
          opacity={hasFinished ? '0.5' : '1'}
        />
        {props.__typename === 'Event' ? (
          <>
            <ContentBoxEventTimer
              startsAt={props.startsAt}
              endsAt={props.endsAt}
              iconProps={{ boxSize: 4 }}
            />
            <ContentTypeBadge
              typename={props.__typename}
              eventType={eventType}
              position="absolute"
              bottom="2"
              left="2"
              iconProps={{
                boxSize: {
                  base: '3',
                  lg: '4'
                }
              }}
            />
            <ContentTimeBadge
              typename={props.__typename}
              date={props.startsAt}
              position="absolute"
              bottom="2"
              right="2"
              iconProps={{
                boxSize: 4
              }}
            />
          </>
        ) : (
          <>
            {disableUserProgress ? null : <ContentBoxCompletion {...props} />}
            <ContentTypeBadge
              typename={props.__typename}
              position="absolute"
              bottom="2"
              left="2"
              iconProps={{
                boxSize: {
                  base: '3',
                  lg: '4'
                }
              }}
            />
            <ContentTimeBadge
              typename={props.__typename}
              duration={total}
              position="absolute"
              bottom="2"
              right="2"
              iconProps={{
                boxSize: 4
              }}
            />
          </>
        )}
      </Box>

      <ContentBoxDetails
        typename={props.__typename}
        getPathToExpert={getPathToExpert}
        getPathToContent={getPathToContent}
        getPathToCategory={getPathToCategory}
        name={name}
        slug={slug}
        category={category}
        expert={expert}
        progress={progress}
        disableUserProgress={disableUserProgress}
        contentPiecesCount={contentPiecesCount}
        as="card"
        id={props.id}
        contentSegmentProps={props}
        onBookmarkClick={onBookmarkClick}
        disableShareButton={disableShareButton}
      />
    </LinkBox>
  );
}
