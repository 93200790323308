import { Divider, Flex, VStack, useBreakpointValue } from '@chakra-ui/react';
import { Flags, useMicrosoftTeams } from '@tigerhall/core';
import { useAccessLevels, useAppSelector, useLayoutDimensions } from 'hooks';
import { hasSuperAdminAccess, isSidebarExpanded } from 'app/state';
import { useFlag } from '@unleash/proxy-client-react';
import { useColorModeValue } from '@chakra-ui/system';

import {
  SIDEBAR_WIDTH_EXPANDED,
  getMenuItems,
  logoutMenuItem,
  supportMenuItem
} from './utils';
import { SidebarMenuListItem, SidebarMenuSection } from './children';
import { useCloseSidebarOnMobileNavigation } from './hooks';

export function Sidebar() {
  const isSuperAdmin = useAppSelector(hasSuperAdminAccess);
  const isExpanded = useAppSelector(isSidebarExpanded);

  const { isInTeams } = useMicrosoftTeams();

  const { accessLevels } = useAccessLevels();

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    {
      fallback: 'md',
      ssr: false
    }
  );

  useCloseSidebarOnMobileNavigation(Boolean(isMobile), isExpanded);

  const hiddenRoutes: string[] = [];

  if (!useFlag(Flags.ReportBuilder)) {
    hiddenRoutes.push('/admin/report-builder');
  }

  const menu = getMenuItems(
    Boolean(isMobile),
    isInTeams,
    isSuperAdmin,
    hiddenRoutes,
    accessLevels
  );

  const hasMenuItems = menu.length > 0;

  const bgColor = useColorModeValue('white', 'black');
  const { contentHeight } = useLayoutDimensions();

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      backgroundColor={bgColor}
      borderRight="1px solid"
      borderRightColor="darkGrey.300"
      boxShadow="dark-lg"
      height="100%"
      maxH={contentHeight}
      width={{
        base: isExpanded ? '100%' : 0,
        md: hasMenuItems ? SIDEBAR_WIDTH_EXPANDED : 0
      }}
      transition="width 200ms ease-out"
      overflowY="auto"
    >
      <VStack
        alignItems="stretch"
        divider={<Divider border="1x solid" borderColor="darkGrey.300" />}
      >
        {menu.map((item, index) => (
          <SidebarMenuSection key={item.label ?? index} section={item} />
        ))}
      </VStack>

      <VStack alignItems="stretch">
        {isInTeams || isMobile ? (
          <SidebarMenuListItem item={logoutMenuItem} />
        ) : null}

        <SidebarMenuListItem item={supportMenuItem} />
      </VStack>
    </Flex>
  );
}
