import * as React from 'react';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea,
  TextareaProps
} from '@chakra-ui/react';
import {
  Controller,
  UseControllerProps,
  useFormContext,
  UseFormReturn
} from 'react-hook-form';
import { sanitizeCSSContent } from '@tigerhall/core';

interface FormAutoResizableTextareaProps
  extends Pick<
    TextareaProps,
    | '_before'
    | '_after'
    | 'onKeyDown'
    | 'style'
    | 'placeholder'
    | 'onFocus'
    | 'onBlur'
    | 'onSelect'
  > {
  /**
   * The name of the input
   */
  name: string;

  /**
   * The label of the tooltip
   */
  label: string;

  /**
   * If the component is disabled
   */
  disabled?: boolean;

  rules?: UseControllerProps['rules'];
  analyticsDisabled?: boolean;
}

export function FormAutoResizableTextarea({
  name,
  label,
  placeholder,
  rules = {},
  disabled = false,
  analyticsDisabled = false,
  style,
  ...props
}: FormAutoResizableTextareaProps) {
  const methods = useFormContext() as UseFormReturn & {
    formName: string;
  };

  if (methods === null) {
    return <Text color="state.error">Missing form context</Text>;
  }

  if (!methods.formName && !analyticsDisabled) {
    return <Text color="state.error">Missing form name</Text>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      defaultValue=""
      render={({ field, fieldState }) => (
        <FormControl
          isInvalid={!!fieldState.error}
          isRequired={!!rules?.required}
          isDisabled={disabled}
        >
          {label && <FormLabel>{label}</FormLabel>}
          <Box
            display={'grid'}
            _after={{
              /**
               * This has to be a replica of the textarea styles and content,
               * so that the textarea can auto-resize properly based on the content.
               */
              content: `"${sanitizeCSSContent(field.value)} "`,
              whiteSpace: 'pre-wrap',
              visibility: 'hidden',
              fontSize: '1rem',
              fontWeight: 500,
              fontFamily: 'body',
              lineHeight: 'inherit',
              paddingX: 4,
              paddingY: 2,
              gridArea: '1 / 1 / 2 / 2',
              overflow: 'hidden'
            }}
          >
            <Textarea
              {...props}
              style={{
                /**
                 * @todo
                 * hardcoding the `minHeight` because we only have one use case for this.
                 * Ideally we should get this from the theme.
                 */
                minHeight: '3rem',
                height: 'auto',
                overflow: 'hidden',
                resize: 'none',
                gridArea: '1 / 1 / 2 / 2',
                ...style
              }}
              name={field.name}
              placeholder={placeholder}
              onChange={field.onChange}
              onBlur={(event) => {
                field.onBlur();
                props?.onBlur?.(event);
              }}
              ref={field.ref}
              data-cy={`${methods.formName}-${name}`}
              value={field.value}
            />
          </Box>
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}
