import { HStack, Skeleton } from '@chakra-ui/react';
import * as React from 'react';

export function PostMetricsSkeleton() {
  return (
    <HStack
      justifyContent="space-between"
      mb="1.25rem"
      mx={{
        base: '1rem',
        md: '2rem'
      }}
      spacing="0.75rem"
      fontSize="md"
      color="lightGrey.200"
    >
      <Skeleton width="7rem" height="1rem" />
      <Skeleton width="7rem" height="1rem" />
    </HStack>
  );
}
