/**
 * We use 2 main fonts, **PP Formula** and **PP Neue Montreal**.
 *
 * **Formula header**: (Only For Main Headers)
 * Used for main headings, for titles on the top of the hierarchy,
 * with messages with intent of promotion, only uses extrabold.
 * _(Only uses PP Formula Fonts)_
 *
 * **Montreal header**: (For Header + Buttons)
 * Used for secondary headers, descriptive section titles and CTA buttons.
 * Different font weights. When used with Formula header in the same space,
 * the montreal header should always be smaller. _(Only uses PP Neue Montreal Fonts)_
 *
 * **Body**: (For Body Copy + Input Fields)
 * Used for body copy with paragraphs of text, input fields.
 * has slightly more paragraph spacing than headers. _(Only uses PP Neue Montreal Fonts)_
 *
 * **Timer font**:
 * Used on timer countdowns. _(Only uses Roboto Mono Fonts)_
 *
 * Learn more about:
 * - [How to use custom fonts with Chakra](https://chakra-ui-git-fix-typescript-autocomplete.chakra-ui.vercel.app/guides/custom-fonts)
 * - [Typography in our design system](https://www.figma.com/file/WLhIGuIkWTy7rf4QbRfxvA/New-Design-System?node-id=74%3A1591)
 */

import { Global } from '@emotion/react';

/**
 * Routes for the fonts:
 *
 * **PP Formula**:
 * `/fonts/PPFormula/web/`
 *
 * **PP Neue Montreal**:
 * `/fonts/PPNeueMontreal/web/`
 *
 * **Roboto Mono**:
 * `/fonts/RobotoMono/web/`
 */
export function Fonts() {
  return (
    <Global
      styles={`
        /* -------- PP Formula -------- */
        @font-face {
            font-family: 'PP Formula';
            src: local('PPFormula-ExtraBold'), local('PPFormula-Extrabold'),
                url('/fonts/PPFormula/web/PPFormula-Extrabold.woff2') format('woff2'),
                url('/fonts/PPFormula/web/PPFormula-Extrabold.woff') format('woff');
            font-weight: 800;
            font-style: normal;
            font-display: swap;
            size-adjust: 101.2%;
        }

        /* -------- PP Neue Montreal -------- */
        @font-face {
            font-family: 'PP Neue Montreal';
            src: local('PPNeueMontreal-Bold'), local('PPNeueMontreal-Bold'),
                url('/fonts/PPNeueMontreal/web/PPNeueMontreal-Bold.woff2') format('woff2'),
                url('/fonts/PPNeueMontreal/web/PPNeueMontreal-Bold.woff') format('woff');
            font-weight: 700;
            font-style: normal;
            font-display: swap;
            size-adjust: 103%;
        }
        
        @font-face {
            font-family: 'PP Neue Montreal';
            src: local('PPNeueMontreal-SemiBolditalic'), local('PPNeueMontreal-SemiBolditalic'),
                url('/fonts/PPNeueMontreal/web/PPNeueMontreal-SemiBolditalic.woff2') format('woff2'),
                url('/fonts/PPNeueMontreal/web/PPNeueMontreal-SemiBolditalic.woff') format('woff');
            font-weight: 600;
            font-style: italic;
            font-display: swap;
            size-adjust: 106%;
        }
        
        @font-face {
            font-family: 'PP Neue Montreal';
            src: local('PPNeueMontreal-Medium'), local('PPNeueMontreal-Medium'),
                url('/fonts/PPNeueMontreal/web/PPNeueMontreal-Medium.woff2') format('woff2'),
                url('/fonts/PPNeueMontreal/web/PPNeueMontreal-Medium.woff') format('woff');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
            size-adjust: 104.2%;
        }


        @font-face {
            font-family: 'PP Neue Montreal';
            src: local('PPNeueMontreal-LightItalic'), local('PPNeueMontreal-LightItalic'),
                url('/fonts/PPNeueMontreal/web/PPNeueMontreal-LightItalic.woff2') format('woff2'),
                url('/fonts/PPNeueMontreal/web/PPNeueMontreal-LightItalic.woff') format('woff');
            font-weight: 375;
            font-style: italic;
            font-display: swap;
            size-adjust: 111%;
        }

        @font-face {
            font-family: 'PP Neue Montreal';
            src: local('PPNeueMontreal-Light'), local('PPNeueMontreal-Light'),
                url('/fonts/PPNeueMontreal/web/PPNeueMontreal-Light.woff2') format('woff2'),
                url('/fonts/PPNeueMontreal/web/PPNeueMontreal-Light.woff') format('woff');
            font-weight: 375;
            font-style: normal;
            font-display: swap;
        }

        /* -------- Roboto Mono -------- */
        @font-face {
            font-family: 'Roboto Mono';
            src: local('Roboto Mono Bold'), local('RobotoMono-Bold'),
                url('/fonts/RobotoMono/web/RobotoMono-Bold.woff2') format('woff2'),
                url('/fonts/RobotoMono/web/RobotoMono-Bold.woff') format('woff');
            font-weight: 700;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Roboto Mono';
            src: local('Roboto Mono SemiBold'), local('RobotoMono-SemiBold'),
                url('/fonts/RobotoMono/web/RobotoMono-SemiBold.woff2') format('woff2'),
                url('/fonts/RobotoMono/web/RobotoMono-SemiBold.woff') format('woff');
                font-weight: 600;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Roboto Mono';
            src: local('Roboto Mono Medium'), local('RobotoMono-Medium'),
                url('/fonts/RobotoMono/web/RobotoMono-Medium.woff2') format('woff2'),
                url('/fonts/RobotoMono/web/RobotoMono-Medium.woff') format('woff');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
        }
        
        @font-face {
            font-family: 'Roboto Mono';
            src: local('Roboto Mono Regular'), local('RobotoMono-Regular'),
            url('/fonts/RobotoMono/web/RobotoMono-Regular.woff2') format('woff2'),
            url('/fonts/RobotoMono/web/RobotoMono-Regular.woff') format('woff');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: 'Roboto Mono';
            src: local('Roboto Mono Light'), local('RobotoMono-Light'),
                url('/fonts/RobotoMono/web/RobotoMono-Light.woff2') format('woff2'),
                url('/fonts/RobotoMono/web/RobotoMono-Light.woff') format('woff');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
        }
    `}
    />
  );
}
