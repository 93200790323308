import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgMoodPlus = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="th-icon"
    viewBox="0 0 24 24"
    focusable={false}
    color="white"
    width="1.25rem"
    height="1.25rem"
    ref={ref}
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M20.985 12.528a9 9 0 1 0-8.45 8.456M16 19h6M19 16v6M9 10h.01M15 10h.01" />
    <path d="M9.5 15c.658.64 1.56 1 2.5 1s1.842-.36 2.5-1" />
  </svg>
);
SvgMoodPlus.displayName = "IconMoodPlus";
const ForwardRef = forwardRef(SvgMoodPlus);
export default ForwardRef;
