import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue
} from '@chakra-ui/react';
import * as segment from '@tigerhall/analytics';
import { Content } from '@tigerhall/core';
import { useGetStreamSourceQuery } from 'generated';

import { ChatReplayTab } from './components/ChatReplayTab';
import { ChatTab } from './components/ChatTab';
import { CommentsTab } from './components/CommentsTab';
import { LiveMoreInfoTab } from './components/LiveMoreInfoTab';
import { MoreInfoTab } from './components/MoreInfoTab';
import { SuggestedTab } from './components/SuggestedTab';
import { UpNextTab } from './components/UpNextTab';
import {
  AvailableTabs,
  TABS_LABELS,
  getPlayerTabs,
  type GetPlayerTabsArgs
} from './utils/tabs';

export interface DrawerTabsProps extends GetPlayerTabsArgs {
  /**
   * we will need `__typename` when we implement other tabs that
   * are common to different content types. That's why we added it
   * here even it isn't being used yet.
   *
   * @todo: delete this comment after you use `__typename`
   */
  content: Pick<Content, '__typename' | 'id'>;
  defaultSelectedTab?: number;
}

export function DrawerTabs({
  isLive,
  isPlaylist,
  type,
  content,
  defaultSelectedTab
}: Readonly<DrawerTabsProps>) {
  const isMobile = useBreakpointValue(
    { base: true, lg: false },
    {
      fallback: 'lg',
      ssr: false
    }
  );

  const { data: streamData } = useGetStreamSourceQuery({
    variables: { id: content?.id },
    skip: !content?.id || content.__typename !== 'Stream',
    fetchPolicy: 'cache-first'
  });

  const playerTabs = getPlayerTabs({
    isLive,
    isPlaylist,
    type,
    isMobile,
    source: streamData?.stream?.source
  });

  function renderTab(tab: AvailableTabs) {
    switch (tab) {
      case AvailableTabs.UpNext:
        return <UpNextTab />;
      case AvailableTabs.LiveMoreInfo:
        return <LiveMoreInfoTab streamId={content.id} />;
      case AvailableTabs.MoreInfo:
        return <MoreInfoTab {...content} />;
      case AvailableTabs.Comments:
        return <CommentsTab contentId={content.id} />;
      case AvailableTabs.Chat:
        return <ChatTab streamId={content.id} />;
      case AvailableTabs.Suggested:
        return <SuggestedTab id={content.id} />;
      case AvailableTabs.ChatReplay:
        return <ChatReplayTab id={content.id} />;
      default:
        return null;
    }
  }

  return (
    <Tabs
      isLazy
      position={'relative'}
      overflow={'hidden'}
      display={'flex'}
      flexDirection={'column'}
      height={'100%'}
      lazyBehavior="keepMounted"
      defaultIndex={defaultSelectedTab}
    >
      <TabList>
        {playerTabs.map((tabId) => (
          <Tab
            w="full"
            fontSize="sm"
            key={`tab-${tabId}`}
            onClick={() =>
              segment.tabClicked({
                tabId: tabId,
                tabName: TABS_LABELS[tabId]
              })
            }
          >
            {TABS_LABELS[tabId]}
          </Tab>
        ))}
      </TabList>
      <TabPanels
        display={'flex'}
        flex={1}
        height="100%"
        // need to subtract the height of the tab list, which is 3rem to avoid overflow
        maxHeight={'calc(100% - 3rem)'}
        w={'100%'}
      >
        {playerTabs.map((tabId) => (
          <TabPanel
            p="1rem 0 0 0"
            key={`tab-panel-${tabId}`}
            width={'full'}
            height={'100%'}
            overflow={'auto'}
          >
            {renderTab(tabId)}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
