import { useBreakpointValue } from '@chakra-ui/react';

import { LanguagesProps } from './const';
import { LanguagesModal } from './LanguagesModal';
import { LanguagesBottomSheet } from './LanguagesBottomSheet';

export function Languages(props: Readonly<LanguagesProps>) {
  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { fallback: 'md' }
  );

  if (isMobile) {
    return <LanguagesBottomSheet {...props} />;
  }

  return <LanguagesModal {...props} />;
}
