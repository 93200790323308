import {
  AssignmentStatusForReports,
  UserContentStatisticsReportColumns as Columns,
  CompletionStatus,
  ConsumptionFlag,
  ContentCardsSorting,
  ContentType,
  type UserContentStatisticsReportFilter as Filter,
  formatContentType
} from '@tigerhall/core';
import { type GenericRow, type TableSelectDataResponse } from '@tigerhall/components';

import {
  type AllStaticFilters,
  type DynamicFilter,
  type ReportColumn,
  type ReportTemplate,
  StaticFilterType
} from '../types';
import { apolloClient } from '~/api/apollo';
import {
  ExportUserContentStatisticsReportDocument,
  type ExportUserContentStatisticsReportMutation,
  type ExportUserContentStatisticsReportMutationVariables,
  GetContentTitlesDocument,
  type GetContentTitlesQuery,
  type GetContentTitlesQueryVariables
} from '~/modules/admin/modules/reportBuilder/queries';
import { type ReportBuilderWizardStoreState } from '~/modules/admin/modules/reportBuilder/stores';
import {
  extractStaticDropDownValue,
  transformStateToDefaultFilters
} from '~/modules/admin/modules/reportBuilder/utils';

export class UserContentStatisticsReport implements ReportTemplate<Filter> {
  getName(): string {
    return 'User Content Statistics';
  }

  getDescription(): string {
    return 'Summary of time spent and completion by the user on any content piece.';
  }

  isEnabled(): boolean {
    return true;
  }

  getColumns(): ReportColumn[] {
    return [
      {
        id: Columns.UserName,
        name: 'Username'
      },
      {
        id: Columns.Email,
        name: 'Email'
      },
      {
        id: Columns.TigerhallUserId,
        name: 'Tigerhall User ID'
      },
      {
        id: Columns.ContentName,
        name: 'Content Name'
      },
      {
        id: Columns.OrganisationContentOrPublicContent,
        name: 'Organization Content or Public Content',
        description:
          'Denotes whether the content is from the organization or public content'
      },
      {
        id: Columns.ContentAssignedToUser,
        name: 'Content Assigned to User',
        description: 'Whether the content has been assigned to the user or not'
      },
      {
        id: Columns.ContentId,
        name: 'Content ID'
      },
      {
        id: Columns.ContentStartDate,
        name: 'Content Start Date'
      },
      {
        id: Columns.ContentCompletionDate,
        name: 'Content Completion Date'
      },
      {
        id: Columns.ContentCompletionStatus,
        name: 'Content Completion Status',
        description: 'Whether the user has completed the content piece or not'
      },
      {
        id: Columns.ContentProgressPercentage,
        name: 'Content Progress %',
        description: `Shows 100% if the user has fully consumed or marked the piece as completed. Any value less than 100% shows the user's furthest progress.`
      },
      {
        id: Columns.EstimatedContentLength,
        name: 'Estimated Content Length',
        description: 'Total length of the content piece'
      },
      {
        id: Columns.TimeSpentOnContent,
        name: 'Time Spent on Content',
        description: 'Time spent on content accounting for playback speed.'
      },
      {
        id: Columns.ContentConsumptionFlag,
        name: 'Content Speed Run',
        description:
          'Content is flagged if the content is marked as completed and the time spent is less than half the estimated content length'
      }
    ];
  }

  getSampleData(): Record<string, string | number> {
    return {
      [Columns.ContentAssignedToUser]: 'Assigned',
      [Columns.ContentCompletionDate]: '2 Jul 2024',
      [Columns.ContentCompletionStatus]: 'Completed',
      [Columns.ContentConsumptionFlag]: 'Yes',
      [Columns.ContentId]: 'C101',
      [Columns.ContentName]: 'Introduction to Programming',
      [Columns.ContentProgressPercentage]: '100%',
      [Columns.ContentStartDate]: '2 Jul 2024',
      [Columns.Email]: 'user@example.com',
      [Columns.EstimatedContentLength]: '5 hours 10 minutes 20 seconds',
      [Columns.TigerhallUserId]: 'TH789456',
      [Columns.TimeSpentOnContent]: '5 hours 10 minutes 20 seconds',
      [Columns.UserName]: 'jane smith',
      [Columns.OrganisationContentOrPublicContent]: 'Organization Content'
    };
  }

  getStaticFilters(): AllStaticFilters<Filter>[] {
    return [
      {
        name: 'completionStatus',
        label: 'Content Completion Status',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        isMulti: true,
        options: [
          { value: CompletionStatus.Completed, label: 'Completed' },
          { value: CompletionStatus.InProgress, label: 'In Progress' },
          { value: CompletionStatus.NotStarted, label: 'Not Started' }
        ]
      },
      {
        name: 'consumptionFlag',
        label: 'Content Speed Runner',
        tooltip: 'Check if the user jumped to the end of the content?',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        options: [
          { value: ConsumptionFlag.Yes, label: 'Yes' },
          { value: ConsumptionFlag.No, label: 'No' }
        ]
      },
      {
        name: 'assignmentStatus',
        label: 'Assigned Content',
        tooltip: 'Do you only want to see content that was assigned?',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        options: [
          {
            value: AssignmentStatusForReports.Assigned,
            label: 'Assigned'
          },
          {
            value: AssignmentStatusForReports.NotAssigned,
            label: 'Not Assigned'
          }
        ]
      },
      {
        name: 'includeArchivedUsers',
        label: 'Include Archived Users',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        isClearDisabled: true,
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' }
        ]
      }
    ];
  }

  getDynamicFilter(): DynamicFilter<Filter> | null {
    return {
      id: 'contents',
      label: 'Content Pieces',
      description: 'Filter the report on a number of content pieces',

      columns: [
        {
          id: 'name',
          name: 'Name'
        },
        {
          id: 'format',
          name: 'Format',
          accessor: (row: GenericRow) => {
            return formatContentType(row['__typename'].toUpperCase(), false);
          }
        }
      ],

      rules: {
        required: 'You must select at least one content piece for the report'
      },

      dataSource: {
        query: async (
          context,
          keywords,
          limit,
          offset
        ): Promise<TableSelectDataResponse> => {
          const response = await apolloClient.query<
            GetContentTitlesQuery,
            GetContentTitlesQueryVariables
          >({
            query: GetContentTitlesDocument,
            variables: {
              filter: {
                types: [
                  ContentType.Podcast,
                  ContentType.Event,
                  ContentType.Stream,
                  ContentType.Ebook
                ],
                keywords,
                offset,
                limit
              },
              sorting: ContentCardsSorting.PublishedAt
            },

            /**
             * Todo figure out why cache is breaking my pagination
             */
            fetchPolicy: 'no-cache'
          });

          return {
            edges: response.data.contentCards.edges,
            meta: response.data.contentCards.meta
          };
        }
      }
    };
  }

  calculatePotentialRowCount(
    filter: Filter,
    audienceSize: number
  ): Promise<number> {
    return Promise.resolve((filter.contents?.length ?? 0) * audienceSize);
  }

  async export(state: ReportBuilderWizardStoreState): Promise<void> {
    const { reportFilters: filter } = state;

    const standardFilter = await transformStateToDefaultFilters<Columns>(state);

    await apolloClient.mutate<
      ExportUserContentStatisticsReportMutation,
      ExportUserContentStatisticsReportMutationVariables
    >({
      mutation: ExportUserContentStatisticsReportDocument,
      variables: {
        filter: {
          ...standardFilter,

          contents: filter.contents.map((r: GenericRow) => r.id),
          assignmentStatus: extractStaticDropDownValue(
            filter.assignmentStatus
          ) as AssignmentStatusForReports,
          consumptionFlag: extractStaticDropDownValue(
            filter.consumptionFlag
          ) as ConsumptionFlag,
          completionStatus: extractStaticDropDownValue(
            filter.completionStatus
          ) as CompletionStatus[]
        }
      }
    });
  }
}
