import * as React from 'react';
import {
  ButtonProps,
  LinkProps as ChakraLinkProps,
  RightJoinProps
} from '@chakra-ui/react';

interface CustomButtonLinkProps
  extends RightJoinProps<ChakraLinkProps, ButtonProps> {
  /** Styles the `<Link />` as a button and extends the Chakra's `ButtonProps` */
  as: 'button';
  /** The path or URL to navigate to. This is the only required prop. */
  href: string;
  replace?: boolean;
  children?: React.ReactNode;
}

interface CustomAnchorLinkProps extends ChakraLinkProps {
  /**
   * Styles the `<Link />` as a link and extends the Chakra's `LinkProps`
   **/
  as?: 'a';

  /**
   * The path or URL to navigate to. This is the only required prop.
   **/
  href: string;

  /**
   * If we want to stop the
   */
  replace?: boolean;

  children?: React.ReactNode;
}

/**
 * If `as === 'button'` this type extends from `ButtonProps`, if not,
 * it extends from `ChakraLinkProps`
 */
export type LinkProps = CustomButtonLinkProps | CustomAnchorLinkProps;

const context = React.createContext<{
  link: (props: LinkProps) => JSX.Element;
}>({
  link: ({ children }) => {
    console.error('No link provider found');

    return <>{children}</>;
  }
});

context.displayName = 'LinkProvider';

export const LinkProvider = context.Provider;
export const LinkConsumer = context.Consumer;

export const useLink = () => {
  const ctx = React.useContext(context);
  return { Link: ctx.link };
};
