import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  type FormLabelProps,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { Fragment, useState } from 'react';
import {
  Controller,
  type UseControllerProps,
  type UseFormReturn,
  useFormContext,
  useWatch
} from 'react-hook-form';

import { MONTH_NAMES_FULL, WEEKDAY_NAMES_SHORT } from './utils/calanderUtils';
import { CalendarPanel } from './components/CalendarPanel';
import { TimePicker } from './components/TimePicker';
import type {
  CalendarConfigs,
  DatepickerConfigs,
  DatepickerProps,
  OnDateSelected
} from './types';

export interface FormDateTimePickerProps extends DatepickerProps {
  /**
   * The name of the input
   */
  name: string;
  /**
   * The label of the tooltip
   */
  label: string;
  /**
   * Hide the label
   * @default false
   */
  hideLabel?: boolean;
  /**
   * Placeholder text
   */
  placeholder: string;
  id?: string;
  /**
   * If the component is disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * Rules extended from `react-hook-forms` controller.
   * @see https://react-hook-form.com/docs/usecontroller
   */
  rules?: UseControllerProps['rules'];
  /**
   * The configs for the calendar. It extends the `CalendarConfigs` type.
   */
  configs?: DatepickerConfigs;
  /**
   * If the popover should be open by default.
   * @default false
   */
  defaultIsOpen?: boolean;
  /**
   * If the popover should be rendered in a portal.
   * @default false
   */
  usePortal?: boolean;
  /**
   * If `true` it won't be mandatory to get the `formName` from `useFormContext`,
   * which is used for analytics.
   * @default false
   */
  analyticsDisabled?: boolean;
}

export const DATE_TIME_PICKER_FORMAT = 'DDD, T ZZZZ';

const DEFAULT_CONFIG: CalendarConfigs = {
  dateFormat: DATE_TIME_PICKER_FORMAT,
  monthNames: MONTH_NAMES_FULL,
  dayNames: WEEKDAY_NAMES_SHORT,
  firstDayOfWeek: 0
};

export function FormDateTimePicker({
  name,
  label,
  placeholder,
  rules = {},
  disabled,
  id,
  configs,
  propsConfigs,
  usePortal,
  defaultIsOpen = false,
  // TODO: Maybe we should set this in `rules` but I am not sure
  // if that will work since `minDate` and `maxDate` alter the
  // UI of the calendar
  minDate,
  maxDate,
  analyticsDisabled = false,
  hideLabel = false
}: Readonly<FormDateTimePickerProps>) {
  const [offset, setOffset] = useState(0);
  const { onOpen, onClose, isOpen } = useDisclosure({ defaultIsOpen });
  const selectedDate =
    useWatch({ name }) ||
    DateTime.now()
      .plus({ hours: 1 })
      .set({ minute: 0, second: 0, millisecond: 0 })
      .toJSDate();

  const methods = useFormContext() as UseFormReturn & {
    formName: string;
  };

  if (!methods.formName && !analyticsDisabled) {
    return <Text color="state.error">Missing form name</Text>;
  }

  if (!methods.formName) {
    console.warn('Missing form name for element name ' + name);
  }

  const calendarConfigs: CalendarConfigs = {
    ...DEFAULT_CONFIG,
    ...configs
  };

  function onPopoverClose() {
    onClose();
    setOffset(0);
  }

  // dayzed utils
  // eslint-disable-next-line func-style -- we need to type this function
  const handleOnDateSelected: OnDateSelected = ({ selectable, date }) => {
    if (!selectable) {
      return;
    }
    setOffset(0);
    if (date instanceof Date && !isNaN(date.getTime())) {
      const incomingDateTime = DateTime.fromJSDate(date);

      const isNoTimeSelected =
        incomingDateTime.hour === 0 &&
        incomingDateTime.minute === 0 &&
        incomingDateTime.second === 0 &&
        incomingDateTime.millisecond === 0;

      let newDateTime = incomingDateTime;

      // if the user has not selected a time but only changed the date,
      // we keep the existing time so the user doesn't have to re-select the time
      // every time they change the date.
      if (isNoTimeSelected) {
        const existingDateTime = DateTime.fromJSDate(selectedDate);

        newDateTime = incomingDateTime.set({
          hour: existingDateTime.hour
        });
      }

      methods.setValue(name, newDateTime.toJSDate());
    }
  };

  const PopoverContentWrapper = usePortal ? Portal : Fragment;

  const ariaLabelForInput: FormLabelProps = {
    ...(hideLabel && {
      'aria-label': label,
      'aria-labelledby': undefined
    })
  };

  return (
    <Popover
      placement="bottom-start"
      variant="responsive"
      isOpen={disabled ? false : isOpen}
      onOpen={onOpen}
      onClose={onPopoverClose}
      isLazy
    >
      <PopoverTrigger>
        <Box flex={1}>
          <Controller
            name={name}
            control={methods.control}
            rules={rules}
            render={({ field, fieldState }) => (
              <FormControl
                isInvalid={!!fieldState.error}
                isRequired={!!rules?.required}
                isDisabled={disabled}
              >
                {!hideLabel && (
                  <FormLabel {...ariaLabelForInput}>{label}</FormLabel>
                )}
                <Input
                  name={field.name}
                  autoComplete="off"
                  placeholder={placeholder}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={
                    field.value
                      ? DateTime.fromJSDate(field.value).toFormat(
                          calendarConfigs.dateFormat
                        )
                      : ''
                  }
                  ref={field.ref}
                  data-cy={`${methods.formName}-${name}`}
                  onClick={(e) => {
                    e.preventDefault();
                    onOpen();
                  }}
                  onKeyPress={(e) => {
                    if (e.key === ' ' && !isOpen) {
                      e.preventDefault();
                      onOpen();
                    }
                  }}
                  id={id}
                  {...propsConfigs?.inputProps}
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </Box>
      </PopoverTrigger>
      <PopoverContentWrapper>
        <PopoverContent
          width="100%"
          background="darkGrey.400"
          color="white"
          {...propsConfigs?.popoverCompProps?.popoverContentProps}
        >
          <PopoverBody
            display="flex"
            flexDirection="column"
            padding="1.5rem"
            gap="1rem"
            {...propsConfigs?.popoverCompProps?.popoverBodyProps}
          >
            <CalendarPanel
              dayzedHookProps={{
                showOutsideDays: false,
                onDateSelected: handleOnDateSelected,
                selected: selectedDate,
                date: selectedDate,
                minDate: minDate,
                maxDate: maxDate,
                offset: offset,
                onOffsetChanged: setOffset,
                firstDayOfWeek: calendarConfigs.firstDayOfWeek
              }}
              configs={calendarConfigs}
              propsConfigs={propsConfigs}
            />
            <Divider borderColor="darkGrey.200" />
            <HStack justifyContent="space-between">
              <TimePicker
                selectedDate={selectedDate}
                setSelectedDate={(date) => methods.setValue(name, date)}
              />
              <HStack alignSelf="flex-end">
                <Button
                  variant="solid"
                  size="sm"
                  onClick={() => {
                    onPopoverClose();
                  }}
                >
                  Done
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    methods.setValue(name, '');
                    onPopoverClose();
                  }}
                >
                  Clear
                </Button>
              </HStack>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </PopoverContentWrapper>
    </Popover>
  );
}
