import * as React from 'react';
import last from 'lodash/last';
import {
  type Column,
  type ColumnInstance,
  type Hooks,
  useResizeColumns
} from 'react-table';
import { IconDotsVertical } from '@tigerhall/icons';
import { Flex, Icon } from '@chakra-ui/react';
import theme from 'theme';

const lightBackground = theme.colors.teal['800'];

const getHeaderProps =
  <D extends object = {}>({ columns }: { columns: Column<D>[] }) =>
  (props: any, { column }: { column: ColumnInstance<D> }) => {
    const lastColumn = last(columns);
    return [
      props,
      {
        resizing: column.id !== lastColumn?.id &&
          column.canResize &&
          !(column?.flexGrow === 0 && column?.flexShrink === 0) && (
            <Flex
              width=".5rem"
              background="transparent"
              justifyContent="center"
              alignItems="center"
              {...column.getResizerProps()}
            >
              <Icon
                as={IconDotsVertical}
                color="darkGrey.50"
                boxSize=".75rem"
              />
              <Icon
                as={IconDotsVertical}
                color="darkGrey.50"
                boxSize=".75rem"
                transform="auto"
                translateX="-.4rem"
              />
            </Flex>
          )
      }
    ];
  };

export function createUseTableResizeColumns<D extends object = {}>({
  columns
}: {
  columns: Column<D>[];
}): any {
  return [
    useResizeColumns,
    (hooks: Hooks<D>) => {
      hooks.getHeaderProps.push(getHeaderProps({ columns }));
    }
  ];
}
