import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgLive = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    focusable={false}
    className="th-icon"
    color="white"
    width="1.25rem"
    height="1.25rem"
    ref={ref}
    {...props}
  >
    <g fill="currentcolor" fillRule="evenodd" clipRule="evenodd">
      <path d="M10.586 10.586a2 2 0 1 1 2.828 2.828 2 2 0 0 1-2.828-2.828Z" />
      <path d="M7.12 4.37a1 1 0 0 1-.148 1.407A7.983 7.983 0 0 0 4 12a7.983 7.983 0 0 0 2.972 6.223 1 1 0 1 1-1.258 1.554A9.983 9.983 0 0 1 2 12a9.983 9.983 0 0 1 3.714-7.777 1 1 0 0 1 1.406.148Zm9.76 0a1 1 0 0 1 1.406-.147A9.983 9.983 0 0 1 22 12a9.983 9.983 0 0 1-3.714 7.777 1 1 0 1 1-1.258-1.554A7.983 7.983 0 0 0 20 12a7.983 7.983 0 0 0-2.972-6.223 1 1 0 0 1-.148-1.406ZM9.799 7.4A1 1 0 0 1 9.6 8.8 3.992 3.992 0 0 0 8 12c0 1.308.627 2.469 1.6 3.2A1 1 0 0 1 8.4 16.8 5.993 5.993 0 0 1 6 12c0-1.963.944-3.707 2.399-4.8a1 1 0 0 1 1.4.2Zm4.402 0a1 1 0 0 1 1.4-.2A5.993 5.993 0 0 1 18 12a5.993 5.993 0 0 1-2.399 4.8 1 1 0 0 1-1.201-1.6A3.992 3.992 0 0 0 16 12c0-1.308-.627-2.47-1.6-3.2a1 1 0 0 1-.199-1.4Z" />
    </g>
  </svg>
);
SvgLive.displayName = "IconLive";
const ForwardRef = forwardRef(SvgLive);
export default ForwardRef;
