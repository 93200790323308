export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AudienceCriteriaJSON: { [key: string]: any };
  Map: any;
  Time: any;
  Uint32: any;
  Uint64: any;
  Upload: any;
};

export enum ActivationByUserReportColumns {
  AccountActivationDate = 'ACCOUNT_ACTIVATION_DATE',
  ActivationStatus = 'ACTIVATION_STATUS',
  Country = 'COUNTRY',
  DepartmentName = 'DEPARTMENT_NAME',
  Email = 'EMAIL',
  EmployeeFunction = 'EMPLOYEE_FUNCTION',
  Groups = 'GROUPS',
  LastActivityDate = 'LAST_ACTIVITY_DATE',
  LastOnline = 'LAST_ONLINE',
  ManagerEmail = 'MANAGER_EMAIL',
  ManagerFirstName = 'MANAGER_FIRST_NAME',
  ManagerLastName = 'MANAGER_LAST_NAME',
  ManagerName = 'MANAGER_NAME',
  PreferredLanguage = 'PREFERRED_LANGUAGE',
  Region = 'REGION',
  TigerhallUserId = 'TIGERHALL_USER_ID',
  Title = 'TITLE',
  UserFirstName = 'USER_FIRST_NAME',
  UserLastName = 'USER_LAST_NAME',
  UserName = 'USER_NAME'
}

export type ActivationByUserReportFilter = {
  accountActivationTimeFilter?: InputMaybe<TimeRangeFilter>;
  audienceId?: InputMaybe<Scalars['ID']>;
  columns: Array<ActivationByUserReportColumns>;
  groups?: InputMaybe<Array<Scalars['ID']>>;
  includeArchivedUsers?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated use includeArchivedUsers instead */
  includeBlockedUsers?: InputMaybe<Scalars['Boolean']>;
  lastOnlineTimeFilter?: InputMaybe<TimeRangeFilter>;
  reportName: Scalars['String'];
  /** @deprecated use accountActivationTimeFilter and lastOnlineTimeFilter, will be removed after frontend updates the code */
  timeFilter?: InputMaybe<TimeRangeFilter>;
  users?: InputMaybe<Array<Scalars['ID']>>;
};

export enum ActivationOverviewReportColumns {
  ActiveUsers = 'ACTIVE_USERS',
  ContractedUsers = 'CONTRACTED_USERS',
  EnabledAccounts = 'ENABLED_ACCOUNTS',
  LicenceAdoption = 'LICENCE_ADOPTION',
  NumberOfUsersConsumingContent = 'NUMBER_OF_USERS_CONSUMING_CONTENT',
  TimeSpentOnContentByUsers = 'TIME_SPENT_ON_CONTENT_BY_USERS'
}

export type ActivationOverviewReportFilter = {
  audienceId?: InputMaybe<Scalars['ID']>;
  columns: Array<ActivationOverviewReportColumns>;
  groups?: InputMaybe<Array<Scalars['ID']>>;
  includeArchivedUsers?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated use includeArchivedUsers instead */
  includeBlockedUsers?: InputMaybe<Scalars['Boolean']>;
  reportName: Scalars['String'];
  timeFilter?: InputMaybe<TimeRangeFilter>;
  users?: InputMaybe<Array<Scalars['ID']>>;
};

export type AddIamRoleUsersInput = {
  emails?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type AddUserRequest = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type AddUserResponse = {
  __typename: 'AddUserResponse';
  email?: Maybe<Scalars['String']>;
  error: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type ApplyToEventWaitListInput = {
  eventId: Scalars['String'];
  questionnaireResponse?: InputMaybe<Scalars['String']>;
};

export type AssigmentUserStatistics = {
  __typename: 'AssigmentUserStatistics';
  completedPieces: Scalars['Int'];
  completionRate: Scalars['Int'];
  ebooks: Scalars['Int'];
  ebooksCompleted: Scalars['Int'];
  podcasts: Scalars['Int'];
  podcastsCompleted: Scalars['Int'];
  streams: Scalars['Int'];
  streamsCompleted: Scalars['Int'];
  totalPieces: Scalars['Int'];
  user: User;
};

export type Assignment = {
  __typename: 'Assignment';
  /** The reason for assignment which is (Optional but not null) which would be displayed to the users */
  assignmentReason: Scalars['String'];
  /** The title of the assignment which is displayed internally */
  assignmentTitle: Scalars['String'];
  /** Background image of the assignment */
  backgroundImage?: Maybe<Image>;
  /**
   * Indicate the time of when user complete this assignment.
   * If this field empty, it means user have not completed yet.
   *
   * Note: This field can only be resolve if called from user obj as parent.
   */
  completedAt?: Maybe<Scalars['Time']>;
  /**
   * Percentage progress of the assignment (Average out of all trails and contents)
   * Note: This field can only be resolve if called from user obj as parent.
   */
  completionRate: Scalars['Int'];
  /**
   * All the contents associated with this assignment
   * @deprecated faced backward incompatibility issue, use contents instead
   */
  contentCards?: Maybe<ContentConnection>;
  /** All the contents associated with this assignment (for backward incompatibility issue with mobile) */
  contents?: Maybe<ContentConnection>;
  /** When the assignment was created */
  createdAt: Scalars['Time'];
  /** Who created the assignment */
  createdBy: User;
  /** The due date to finish the assignment */
  dueDate?: Maybe<Scalars['Time']>;
  /** Unique ID of the assignment */
  id: Scalars['ID'];
  /**
   * All the learning paths associated with this assigned content
   * @deprecated use contents instead to get all contents including learning paths
   */
  learningPaths: LearningPathConnection;
  /** Total time of the assignment (Sum of all trails and contents in seconds). */
  length: Scalars['Int'];
  /** The groups to which this assignment has been assigned */
  orgGroups: Array<OrganisationGroup>;
  /**
   * Indicate whether the assignment is 'NEW' or 'VIEWED'
   *
   * Note: This field can only be resolve if called from user obj as parent.
   */
  state?: Maybe<AssignmentState>;
  /** Retrieve basic statistics about the assignment */
  statistics: AssignmentUserStatisticsConnection;
  /** The status of assignment (ACTIVE / INACTIVE / EXPIRED) */
  status?: Maybe<AssignmentStatus>;
  /**
   * Total time user spent for the assignment in seconds.
   * Note: This field can only be resolve if called from user obj as parent.
   */
  timeConsumed: Scalars['Int'];
  /** The last time the assignment was updated */
  updatedAt: Scalars['Time'];
  /** Who last updated the assignment */
  updatedBy?: Maybe<User>;
  /**
   * Statistics of assigned and completed contents per assignment.
   * Note: This field can only be resolve if called from user obj as parent.
   */
  userAssignmentStatistics: UserAssignmentStatistics;
  /** All the users that have received the assignment */
  users?: Maybe<UserCollection>;
};

export type AssignmentStatisticsArgs = {
  filter: AssignmentStatisticsFilterInput;
  pagination: OffsetPagination;
};

export type AssignmentConnection = {
  __typename: 'AssignmentConnection';
  edges: Array<Assignment>;
  meta: PageInfo;
};

export type AssignmentContentInput = {
  contentType: ContentType;
  id: Scalars['ID'];
  index: Scalars['Int'];
};

export type AssignmentContentStatistics = {
  __typename: 'AssignmentContentStatistics';
  /** The average completion rate for this content piece */
  avgCompletionRate: Scalars['Float'];
  /** The ID of the content piece */
  contentId: Scalars['ID'];
  /** The estimated length of the content piece */
  contentLength: Scalars['Int'];
  /** The name of the content piece */
  contentName: Scalars['String'];
  /** Total amount of time the users have spent on this content piece */
  durationConsumed: Scalars['Int'];
  /** The number of users that have been assigned this content piece */
  usersAssigned: Scalars['Int'];
};

export type AssignmentGroupStatistics = {
  __typename: 'AssignmentGroupStatistics';
  /** The average completion rate for this content piece */
  avgCompletionRate: Scalars['Float'];
  /** Totalt amount of time the users have spent on this content piece */
  durationConsumed: Scalars['Int'];
  /** The ID of the content piece */
  groupID: Scalars['ID'];
  /** The name of the content piece */
  groupName: Scalars['String'];
  /** The number of users that have been assigned this content piece */
  peopleInTotal: Scalars['Int'];
};

export type AssignmentInput = {
  /** Optional reason to display to the users */
  assignmentReason: Scalars['String'];
  /** Internal title of assignment */
  assignmentTitle: Scalars['String'];
  /**
   * List of contents to be part of the assignment.
   *
   * Note: the learning paths needs to be input here.
   */
  contents?: InputMaybe<Array<AssignmentContentInput>>;
  /** The due date to finish the assignment */
  dueDate?: InputMaybe<Scalars['Time']>;
  /**
   * List of learning path ids to be part of the assignment
   * @deprecated use contents to fill with learning path to ensure ordering
   */
  learningPaths?: InputMaybe<Array<Scalars['ID']>>;
  /** List of organisation groups to assign the content to */
  orgGroups?: InputMaybe<Array<Scalars['ID']>>;
  /** The status of assignment (ACTIVE / INACTIVE / EXPIRED) */
  status?: InputMaybe<AssignmentStatus>;
  /** List of users to assign the content to */
  users: Array<Scalars['ID']>;
};

export type AssignmentMemberStatistics = {
  __typename: 'AssignmentMemberStatistics';
  /** The number of content pieces they have completed */
  assignmentsCompleted: Scalars['Int'];
  /** The number of content piece they have been assigned in total */
  assignmentsInTotal: Scalars['Int'];
  /** Average completion rate of the assigned content */
  avgCompletionRate: Scalars['Float'];
  /** CSV of group that the user belongs to */
  groupNames: Scalars['String'];
  /** Total amount of time they have spent on their assignments */
  totalTimeConsumed: Scalars['Int'];
  /** The ID of the user */
  userId: Scalars['ID'];
  /** Their username which is a concat of first name and last name */
  username: Scalars['String'];
};

export enum AssignmentState {
  New = 'NEW',
  Viewed = 'VIEWED'
}

export type AssignmentStatisticsFilterInput = {
  /** Filter by the users email */
  email?: InputMaybe<Scalars['String']>;
  /**
   * Filter by groups
   *
   * This will look at all the user groups the assigned users are a member of and not only the groups assigned to the specific assignment
   * this will allow us to deep dive into the analytics at a much deeper level
   */
  groups?: InputMaybe<Array<Scalars['ID']>>;
  /** Filter by the users name */
  name?: InputMaybe<Scalars['String']>;
};

export enum AssignmentStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Inactive = 'INACTIVE'
}

export enum AssignmentStatusForReports {
  Assigned = 'Assigned',
  NotAssigned = 'Not_Assigned'
}

export type AssignmentUserStatisticsConnection = {
  __typename: 'AssignmentUserStatisticsConnection';
  edges: Array<AssigmentUserStatistics>;
  meta: PageInfo;
};

export type AssignmentsFilter = {
  id?: InputMaybe<Scalars['ID']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  /** Note: Assignment state filter only works for user obj as parent */
  state?: InputMaybe<AssignmentState>;
  status?: InputMaybe<Array<AssignmentStatus>>;
  title?: InputMaybe<Scalars['String']>;
};

export type AssociationError = {
  __typename: 'AssociationError';
  id: Scalars['String'];
  message: Scalars['String'];
  type: Scalars['String'];
};

export type AttachmentContentInput = {
  contentId: Scalars['ID'];
};

export type AttachmentFileInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  uri: Scalars['String'];
};

export type AttachmentImageInput = {
  description?: InputMaybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type AttachmentInput = {
  content?: InputMaybe<AttachmentContentInput>;
  file?: InputMaybe<AttachmentFileInput>;
  image?: InputMaybe<AttachmentImageInput>;
  previewLink?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<AttachmentVideoInput>;
};

export type AttachmentVideoInput = {
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  uri: Scalars['String'];
};

export type Audience = {
  __typename: 'Audience';
  createdAt: Scalars['Time'];
  criteria: Scalars['AudienceCriteriaJSON'];
  description: Scalars['String'];
  id: Scalars['ID'];
  /** When the audience is being refreshed, this will be true */
  isRefreshing: Scalars['Boolean'];
  /** This happens when the user has updated the criteria but not refreshed the audience */
  isUsersListInSyncWithCriteria: Scalars['Boolean'];
  /** When we last refreshed the users in the audience */
  lastRefreshedAt?: Maybe<Scalars['Time']>;
  name: Scalars['String'];
  /**
   * When we next plan on refreshing the users in the audience
   *
   * If null, the audience will not be refreshed automatically
   */
  nextRefreshAt?: Maybe<Scalars['Time']>;
  /** The frequency at which the audience will be refreshed */
  refreshInterval: AudienceRefreshInterval;
  /**
   * When this value is set to true it will remove users that no longer match the criteria of the audience when the
   * audience is refreshed either by the trigger frequency or manually
   */
  removeUsersInNextRefreshThatNoLongerMeetCriteria: Scalars['Boolean'];
  updatedAt: Scalars['Time'];
  /** Fetch the users in the audience */
  users: UserCollection;
  /** View a history of all actions applied to this audience */
  usersAuditLog: AudienceAuditLogConnection;
};

export type AudienceUsersAuditLogArgs = {
  pagination: OffsetPagination;
};

export type AudienceAuditLog = {
  __typename: 'AudienceAuditLog';
  action: AudienceMemberAction;
  attributes: Scalars['String'];
  createdAt: Scalars['Time'];
  description: Scalars['String'];
  user: User;
};

export type AudienceAuditLogConnection = {
  __typename: 'AudienceAuditLogConnection';
  edges: Array<AudienceAuditLog>;
  meta: PageInfo;
};

export type AudienceBoolFilterInput = {
  all?: InputMaybe<Array<AudienceCriteriaInput>>;
  any?: InputMaybe<Array<AudienceCriteriaInput>>;
  not?: InputMaybe<AudienceBoolFilterInput>;
};

export type AudienceCriteriaInput = {
  bool?: InputMaybe<AudienceBoolFilterInput>;
  date?: InputMaybe<AudienceDateFilterInput>;
  directlyReportsTo?: InputMaybe<AudienceDirectlyReportsToFilterInput>;
  groupAssociation?: InputMaybe<AudienceGroupAssociationFilterInput>;
  indirectlyReportsTo?: InputMaybe<AudienceIndirectlyReportsToFilterInput>;
  number?: InputMaybe<AudienceNumberFilterInput>;
  string?: InputMaybe<AudienceStringFilterInput>;
};

export type AudienceDateFilterInput = {
  fieldId: Scalars['ID'];
  interval?: InputMaybe<AudienceDateInterval>;
  operator: AudienceDateOperator;
  value: DateValueInput;
};

export enum AudienceDateInterval {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export enum AudienceDateOperator {
  After = 'AFTER',
  Before = 'BEFORE',
  Eq = 'EQ',
  WithinLast = 'WITHIN_LAST',
  WithinNext = 'WITHIN_NEXT'
}

export type AudienceDirectlyReportsToFilterInput = {
  /** The user ID of the person who the user reports to */
  users: Array<Scalars['ID']>;
};

export type AudienceField = {
  __typename: 'AudienceField';
  /** Provide autocompletion on distinct values that exist */
  autocomplete: Array<Scalars['String']>;
  /** When this field was created */
  createdAt: Scalars['Time'];
  /** Field description */
  description: Scalars['String'];
  /** Field ID */
  id: Scalars['ID'];
  /** Field name */
  name: Scalars['String'];
  /** What type of field this is, this dictates what kind of operators can be used */
  type: AudienceFieldType;
};

export type AudienceFieldAutocompleteArgs = {
  query: Scalars['String'];
};

export type AudienceFieldInput = {
  createdAt: Scalars['Time'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  type: AudienceFieldType;
};

export enum AudienceFieldType {
  Date = 'DATE',
  Number = 'NUMBER',
  String = 'STRING'
}

export type AudienceFilterFunc = {
  __typename: 'AudienceFilterFunc';
  /** How this function field can be used */
  description: Scalars['String'];
  /** Display name */
  name: Scalars['String'];
  /** Unique identifier for the func field */
  type: AudienceFilterFuncType;
};

export enum AudienceFilterFuncType {
  DirectlyReportsTo = 'DIRECTLY_REPORTS_TO',
  GroupAssociation = 'GROUP_ASSOCIATION',
  IndirectlyReportsTo = 'INDIRECTLY_REPORTS_TO'
}

/** The ability to filter users by existing group associations */
export type AudienceGroupAssociationFilterInput = {
  /** The group ID of the group the user is associated with */
  groupIds: Array<Scalars['ID']>;
  /** The state of the association */
  state: AudienceGroupAssociationState;
};

export enum AudienceGroupAssociationState {
  /** The user is a member of all of the groups to be included */
  MemberOfAll = 'MEMBER_OF_ALL',
  /** The user is a member of any of the groups to be included */
  MemberOfAny = 'MEMBER_OF_ANY',
  /** The user is not a member of all of the groups to be included */
  NotMemberOfAll = 'NOT_MEMBER_OF_ALL',
  /** The user is not a member of any of the groups to be included */
  NotMemberOfAny = 'NOT_MEMBER_OF_ANY'
}

/** The ability to filter users by whom they report to */
export type AudienceIndirectlyReportsToFilterInput = {
  /**
   * The depth of the hierarchy to search for the user
   *
   * Examples:
   * A depth of 1 means that the user is a direct report
   * A depth of 2 means that the user is a direct report or a direct report of a direct report
   */
  depth?: InputMaybe<Scalars['Int']>;
  /** The user ID of the person who the user reports to */
  users: Array<Scalars['ID']>;
};

export enum AudienceMemberAction {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type AudienceNumberFilterInput = {
  fieldId: Scalars['ID'];
  operator: AudienceNumberOperator;
  value: Scalars['Float'];
};

export enum AudienceNumberOperator {
  AtLeast = 'AT_LEAST',
  AtMost = 'AT_MOST',
  Eq = 'EQ'
}

export type AudienceRefreshInterval = {
  __typename: 'AudienceRefreshInterval';
  /** days would be available only for WEEKLY frequency */
  days?: Maybe<Array<WeeklyTriggerDay>>;
  frequency: TriggerFrequency;
};

export type AudienceStringFilterInput = {
  fieldId: Scalars['ID'];
  operator: AudienceStringOperator;
  value: Scalars['String'];
};

export enum AudienceStringOperator {
  Contains = 'CONTAINS',
  EndsWith = 'ENDS_WITH',
  Eq = 'EQ',
  Neq = 'NEQ',
  NotContains = 'NOT_CONTAINS',
  StartsWith = 'STARTS_WITH'
}

export type AudienceUserPreviewInput = {
  filter: AudienceBoolFilterInput;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type AudioFile = {
  __typename: 'AudioFile';
  contentType: Scalars['String'];
  createdAt: Scalars['Time'];
  filename: Scalars['String'];
  id: Scalars['ID'];
  length: Scalars['Uint32'];
  mediaType?: Maybe<Scalars['String']>;
  originalName: Scalars['String'];
  sizeInBytes: Scalars['Uint64'];
  uri: Scalars['String'];
};

export type AuthenticationError = {
  __typename: 'AuthenticationError';
  message: Scalars['String'];
};

export type AuthenticationResponse = {
  __typename: 'AuthenticationResponse';
  /**
   * Finished registration represents if the user has finished the registration process
   *
   * For tigerhall teams this represents if the user has selection a subscription plan interval and provided
   * the credit card credentials for the initial subscription/trial
   */
  finishedRegistration: Scalars['Boolean'];
  /** The JWT access token that should be used in sub-sequence api requests to authenticate the user */
  jwt: Scalars['String'];
  /** If the authentication is unsuccessful this message will contain the reason why */
  message: Scalars['String'];
  /**
   * If this is the first time a user logs into the platform.
   *
   * This is generally used in conjunction with 3rd party authentication so that we know if they should
   * proceed with the onboarding process.
   */
  newAccount: Scalars['Boolean'];
  /** If the request was successful */
  success: Scalars['Boolean'];
  /** The user ID */
  userId: Scalars['ID'];
};

export type BlockedUsersFilter = {
  /** The number of items to return */
  limit?: InputMaybe<Scalars['Int']>;
  /** Filter by user name */
  name?: InputMaybe<Scalars['String']>;
  /** The number of items to skip before starting to collect the result set */
  offset?: InputMaybe<Scalars['Int']>;
};

export enum CanvasType {
  Onboarding = 'ONBOARDING'
}

export type Category = {
  __typename: 'Category';
  /** Connent associated to this categorys */
  contents: ContentConnection;
  /** Experts that specialise in this category */
  experts: ExpertCollection;
  id: Scalars['ID'];
  /** Image representing the category */
  image?: Maybe<Image>;
  /**
   * The index of this category if it belongs to specific org.
   * The org categories index can be modified via retool|backoffice.
   */
  index?: Maybe<Scalars['Int']>;
  /** Name of the category */
  name: Scalars['String'];
  /** Which organisation this category belongs to */
  organisation?: Maybe<Organisation>;
  /** Which organisation group this category belongs to */
  organisationGroups: Array<OrganisationGroup>;
  /** URL Friendly slug that can be used as a unique identifier */
  slug: Scalars['String'];
};

export type CategoryContentsArgs = {
  filter: ContentCardsFilter;
};

export type CategoryExpertsArgs = {
  filter?: InputMaybe<ExpertFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type CategoryConnection = {
  __typename: 'CategoryConnection';
  edges: Array<Category>;
  meta: PageInfo;
};

export type CategoryFilter = {
  hasOrganisationID?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  organisationID?: InputMaybe<Array<Scalars['ID']>>;
};

export type CategoryIndexInput = {
  categoryId: Scalars['ID'];
  index: Scalars['Int'];
  name: Scalars['String'];
  organisationId: Scalars['ID'];
};

export type CategoryInput = {
  image?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  organisationID?: InputMaybe<Scalars['ID']>;
};

export type Chapter = {
  __typename: 'Chapter';
  index: Scalars['Int'];
  language?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pages: Array<Scalars['String']>;
};

export type ChapterInput = {
  index: Scalars['Int'];
  language?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  pages: Array<Scalars['String']>;
};

export type Chat = {
  __typename: 'Chat';
  /** If the user has unread messages */
  hasUnreadMessages: Scalars['Boolean'];
  /** This ID actually refers to the object the chat refers to (Might change this in the future) */
  id: Scalars['ID'];
  /** The last message the chat has been sent */
  lastMessage?: Maybe<ChatMessage>;
  /** When the user last checked the chat, this is triggered when the frontend subscribes to new chat messages */
  lastViewed?: Maybe<Scalars['Time']>;
  /** Retrieve all of the messages for this chat */
  messages: ChatMessageConnection;
  notificationPreference: NotificationPreferenceState;
};

export type ChatMessagesArgs = {
  pagination?: InputMaybe<CursorPaginationInput>;
};

export type ChatNotificationPreferenceArgs = {
  type: NotificationType;
};

export type ChatEdge = {
  __typename: 'ChatEdge';
  cursor: Scalars['String'];
  message: ChatMessage;
};

export type ChatMessage = {
  __typename: 'ChatMessage';
  chat: Chat;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  /** Information about the users mentioned in the chat message */
  mentions: Array<Mention>;
  message: Scalars['String'];
  rawText?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Time'];
  user: User;
};

export type ChatMessageConnection = {
  __typename: 'ChatMessageConnection';
  edges: Array<ChatEdge>;
  meta: CursorInfo;
};

export type ClickAnalyticsInput = {
  documentID: Scalars['String'];
  query: Scalars['String'];
  requestID?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type Cohort = {
  __typename: 'Cohort';
  createdAt: Scalars['Time'];
  createdBy: User;
  deleted?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inviteStatus: CohortInviteStatus;
  learningPath: LearningPath;
  name: Scalars['String'];
  /** @deprecated Use inviteStatus instead */
  status: CohortStatus;
  timeline: Array<Timeline>;
  updatedAt: Scalars['Time'];
  users: CohortUserConnection;
};

export type CohortUsersArgs = {
  filter?: InputMaybe<CohortFilter>;
  pagination?: InputMaybe<CursorPaginationInput>;
};

export type CohortConnection = {
  __typename: 'CohortConnection';
  edges: Array<CohortEdge>;
  meta: CursorInfo;
};

export type CohortEdge = {
  __typename: 'CohortEdge';
  cohort: Cohort;
  cursor: Scalars['String'];
};

export type CohortFilter = {
  inviteStatus?: InputMaybe<Array<CohortInviteStatus>>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  /** Deprecated: Use the inviteStatus filter instead to find the ongoing cohorts */
  status?: InputMaybe<Array<CohortStatus>>;
};

export enum CohortInviteStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type CohortInviteUserListFilter = {
  includeOnlyInvitedUsers?: InputMaybe<Scalars['Boolean']>;
  inviteStatus?: InputMaybe<Array<CohortInviteStatus>>;
  searchByName?: InputMaybe<Scalars['String']>;
};

export enum CohortStatus {
  Finished = 'FINISHED',
  Ongoing = 'ONGOING'
}

export type CohortUser = {
  __typename: 'CohortUser';
  lastInvitedAt: Scalars['Time'];
  nudgeEnabled: Scalars['Boolean'];
  status: CohortInviteStatus;
  user: User;
};

export type CohortUserConnection = {
  __typename: 'CohortUserConnection';
  edges: Array<CohortUserEdge>;
  meta: CursorInfo;
};

export type CohortUserEdge = {
  __typename: 'CohortUserEdge';
  cohortUser: CohortUser;
  cursor: Scalars['String'];
};

export enum CollectionType {
  CuratedList = 'CURATED_LIST',
  LearningPath = 'LEARNING_PATH'
}

export type Comment = {
  __typename: 'Comment';
  belongTo: CommentableContent;
  comment: Scalars['String'];
  content?: Maybe<ContentCard>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  lastReply?: Maybe<Comment>;
  /** Information about the users mentioned in the comment */
  mentions: Array<Mention>;
  numberOfLikes: Scalars['Int'];
  numberOfReplies: Scalars['Int'];
  parent?: Maybe<Comment>;
  /** parsed comment with mentions support */
  rawText: Scalars['String'];
  /** Note: Do not pass more than 1 sortOrderingInput as it will break the functionality of cursor */
  replies: CommentCursorConnection;
  updatedAt: Scalars['Time'];
  user: User;
  /** This presents if the user has rated the comment */
  userCommentRating: CommentRating;
  /**
   * Retrieve all the users who liked a specific comment
   * @deprecated use usersReactToTheComment instead
   */
  usersLikedTheComment: UserCollection;
  /** Retrieve all users that reacted to the comment */
  usersReactToTheComment: ReactionsSummary;
};

export type CommentRepliesArgs = {
  afterCursor?: InputMaybe<Scalars['String']>;
  beforeCursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sorting?: InputMaybe<SortOrdering>;
};

export type CommentUsersLikedTheCommentArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sorting?: InputMaybe<SortOrdering>;
};

export type CommentCursorConnection = {
  __typename: 'CommentCursorConnection';
  edges: Array<CommentEdge>;
  meta: CursorInfo;
};

export type CommentEdge = {
  __typename: 'CommentEdge';
  comment: Comment;
  cursor: Scalars['String'];
};

export type CommentFilter = {
  /** Retrieve actiites that happened after this timestamp */
  after?: InputMaybe<Scalars['String']>;
  /** Retrieve activities that happened before this timestamp */
  before?: InputMaybe<Scalars['String']>;
  /** The number of items to return */
  limit: Scalars['Int'];
};

export enum CommentRating {
  Like = 'LIKE',
  Undetermined = 'UNDETERMINED'
}

export enum CommentType {
  Content = 'CONTENT',
  Feed = 'FEED',
  Post = 'POST'
}

export type CommentableContent =
  | Ebook
  | Event
  | FeedActivity
  | LearningPath
  | Podcast
  | Post
  | Stream;

export enum CompletionStatus {
  Completed = 'Completed',
  InProgress = 'In_Progress',
  NotStarted = 'Not_Started'
}

export type ConflictError = {
  __typename: 'ConflictError';
  associationErrors: Array<AssociationError>;
};

/** Content that can be consumed */
export type ConsumableContent = Ebook | LearningPath | Podcast | Stream;

export type ConsumableContentCollection = {
  __typename: 'ConsumableContentCollection';
  edges: Array<ConsumableContent>;
  meta: PageInfo;
};

export type ConsumableContentFilter = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export enum ConsumptionFlag {
  No = 'No',
  Yes = 'Yes'
}

/** This presents all the content the user can consume */
export type Content = Ebook | Event | Podcast | Stream;

export type ContentActivity = FeedContent & {
  __typename: 'ContentActivity';
  /** comment is only available if ContentActivityType is either COMMENT or REPLY_ON_COMMENT */
  comment?: Maybe<Comment>;
  content: Content;
  contentActivityType: FeedActivityType;
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  user: User;
};

/**
 * Old system for content cards
 *
 * Deprecated: should use one of Content, SearchableContent or Consumable
 */
export type ContentCard =
  | Ebook
  | Event
  | Expert
  | LearningPath
  | Podcast
  | Stream;

export type ContentCardsFilter = {
  /** Selected categories */
  categories?: InputMaybe<Array<Scalars['String']>>;
  /** Filter based on the creation time of the content */
  createdAt?: InputMaybe<TimeRangeFilter>;
  /** Filter out basic content from response if they exist in trails in similar response (we check by total response) */
  dontDuplicateContentFromTrails?: InputMaybe<Scalars['Boolean']>;
  /** Filter on the length of the content */
  duration?: InputMaybe<IntRangeFilter>;
  /** Filter on specific entry levels */
  entryLevels?: InputMaybe<Array<EntryLevelValue>>;
  /** Filter for contents that are not in the org categories */
  excludeOrgCategories?: InputMaybe<Scalars['Boolean']>;
  /** Select experts */
  experts?: InputMaybe<Array<Scalars['String']>>;
  /** Retrieve content by a specific set of content ids */
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Include unpublished content, can only be set to true as an org admin or tigerhall admin account */
  includeDrafts?: InputMaybe<Scalars['Boolean']>;
  /**
   * If experts should be included on the content
   *
   * Deprecated: Specify the explicit types you want in the types field
   */
  includeExperts?: InputMaybe<Scalars['Boolean']>;
  /** If expert is able to create livestream */
  isAbleToLivestream?: InputMaybe<Scalars['Boolean']>;
  /** If the included experts should be a mentor */
  isMentor?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the specified keywords */
  keywords?: InputMaybe<Scalars['String']>;
  /** Language filter to filter content by language */
  languages?: InputMaybe<Array<Scalars['String']>>;
  /** Selected lifegoals */
  lifegoals?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  /** If the content included should be org specific */
  orgSpecific?: InputMaybe<Scalars['Boolean']>;
  /** Only include recently published pages */
  recent?: InputMaybe<Scalars['Boolean']>;
  /** A number of predefined filters for a user to specify */
  selection?: InputMaybe<ContentSelection>;
  /** Filter on a specific content type */
  types?: InputMaybe<Array<ContentType>>;
};

export enum ContentCardsSorting {
  BookmarkedAt = 'BOOKMARKED_AT',
  FinishedAt = 'FINISHED_AT',
  LastOpenedAt = 'LAST_OPENED_AT',
  MyUserActivity = 'MY_USER_ACTIVITY',
  PublishedAt = 'PUBLISHED_AT',
  Rating = 'RATING',
  Trending = 'TRENDING',
  UpdatedAt = 'UPDATED_AT',
  WeeklyTrending = 'WEEKLY_TRENDING'
}

export type ContentConnection = {
  __typename: 'ContentConnection';
  edges: Array<ContentCard>;
  meta: PageInfo;
};

/** These metadata parameters are sent by the client for inclusion in Segment content tracking events */
export type ContentMetadata = {
  /** If the user is consuming content from assignment page */
  assignmentId?: InputMaybe<Scalars['ID']>;
  /** If the user is consuming content from a Trail */
  learningPathId?: InputMaybe<Scalars['ID']>;
};

export type ContentRating = {
  __typename: 'ContentRating';
  content: ContentCard;
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  rating: Scalars['Float'];
  review?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Time']>;
  user: User;
  vote: ContentVote;
};

export type ContentRatingConnection = {
  __typename: 'ContentRatingConnection';
  edges: Array<ContentRating>;
  meta: PageInfo;
};

export type ContentRatingInput = {
  contentId: Scalars['String'];
  rating: Scalars['Float'];
  review?: InputMaybe<Scalars['String']>;
};

export type ContentSegment = {
  __typename: 'ContentSegment';
  header: Scalars['String'];
  paragraph: Scalars['String'];
};

export type ContentSegmentInput = {
  header: Scalars['String'];
  paragraph: Scalars['String'];
};

export enum ContentSelection {
  Attended = 'ATTENDED',
  Bookmarked = 'BOOKMARKED',
  DailyGoals = 'DAILY_GOALS',
  Favorites = 'FAVORITES',
  Finished = 'FINISHED',
  ForMe = 'FOR_ME',
  Free = 'FREE',
  MyExperts = 'MY_EXPERTS',
  Ongoing = 'ONGOING',
  OrganisationContent = 'ORGANISATION_CONTENT',
  ToConsume = 'TO_CONSUME',
  Upcoming = 'UPCOMING'
}

export enum ContentStatisticsReportColumns {
  AverageTimeSpentByUserOnContent = 'AVERAGE_TIME_SPENT_BY_USER_ON_CONTENT',
  ContentId = 'CONTENT_ID',
  ContentName = 'CONTENT_NAME',
  CumulativeTimeSpentOnContent = 'CUMULATIVE_TIME_SPENT_ON_CONTENT',
  EstimatedContentLength = 'ESTIMATED_CONTENT_LENGTH',
  NumberOfCommentsAndReplies = 'NUMBER_OF_COMMENTS_AND_REPLIES',
  NumberOfCompletions = 'NUMBER_OF_COMPLETIONS',
  NumberOfDislikes = 'NUMBER_OF_DISLIKES',
  NumberOfLikes = 'NUMBER_OF_LIKES',
  NumberOfShares = 'NUMBER_OF_SHARES'
}

export type ContentStatisticsReportFilter = {
  /** fetch content stats from the users matching the conditions in the provided dynamic audience id */
  audienceId?: InputMaybe<Scalars['ID']>;
  /** all the columns user wants to see in the report */
  columns: Array<ContentStatisticsReportColumns>;
  /** fetch content stats for the list of provided contents only */
  contents?: InputMaybe<Array<Scalars['ID']>>;
  /** fetch content stats from the provided list of groups only */
  groups?: InputMaybe<Array<Scalars['ID']>>;
  /** whether to include archived users or not */
  includeArchivedUsers: Scalars['Boolean'];
  /** name of the report */
  reportName: Scalars['String'];
  /** fetch content stats from the provided list of users only */
  users?: InputMaybe<Array<Scalars['ID']>>;
};

export enum ContentType {
  Category = 'CATEGORY',
  Ebook = 'EBOOK',
  Event = 'EVENT',
  Expert = 'EXPERT',
  LearningPath = 'LEARNING_PATH',
  LifeGoal = 'LIFE_GOAL',
  Podcast = 'PODCAST',
  Stream = 'STREAM'
}

export type ContentUserNote = {
  __typename: 'ContentUserNote';
  body: Scalars['String'];
  contentCard: ContentCard;
  createdAt: Scalars['Time'];
  headline: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['Time'];
  user: User;
};

export type ContentUserNoteFilter = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export enum ContentVote {
  Downvote = 'DOWNVOTE',
  Undetermined = 'UNDETERMINED',
  Upvote = 'UPVOTE'
}

export type Coordinates = {
  __typename: 'Coordinates';
  lat: Scalars['String'];
  lon: Scalars['String'];
};

export type CoordinatesInput = {
  lat: Scalars['String'];
  lon: Scalars['String'];
};

export type Coupon = {
  __typename: 'Coupon';
  code: Scalars['String'];
  createdAt: Scalars['Time'];
  duration: Scalars['String'];
  durationInMonths?: Maybe<Scalars['Int']>;
  organisation?: Maybe<Organisation>;
  organisationId?: Maybe<Scalars['ID']>;
  percentOff: Scalars['Int'];
  remainingCharges: Scalars['Int'];
  restrictedContent?: Maybe<Scalars['String']>;
  restrictedSubscriptionDuration?: Maybe<Scalars['Int']>;
  restrictedSubscriptionLevel?: Maybe<SubscriptionLevel>;
  restrictedToFirstTimeUser?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['Time'];
  usedCharges: Scalars['Int'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type CouponConnection = {
  __typename: 'CouponConnection';
  edges: Array<Coupon>;
  meta: PageInfo;
};

export type CouponFilter = {
  code?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organisationId?: InputMaybe<Scalars['String']>;
};

export type CouponInput = {
  code: Scalars['String'];
  duration: Scalars['String'];
  durationInMonths?: InputMaybe<Scalars['Int']>;
  organisationId?: InputMaybe<Scalars['String']>;
  percentOff: Scalars['Int'];
  remainingCharges: Scalars['Int'];
  restrictedContent?: InputMaybe<Scalars['String']>;
  restrictedSubscriptionDuration?: InputMaybe<Scalars['Int']>;
  restrictedSubscriptionLevel?: InputMaybe<Scalars['String']>;
  restrictedToFirstTimeUser?: InputMaybe<Scalars['Boolean']>;
};

export type CreateAccountMsTeamsResult = {
  __typename: 'CreateAccountMSTeamsResult';
  organisation: Organisation;
  token: Scalars['String'];
  user: User;
};

export type CreateAudienceInput = {
  /** The filter criteria for the audience */
  criteria: AudienceBoolFilterInput;
  /** User provided description of the audience */
  description: Scalars['String'];
  /** Name of the audience */
  name: Scalars['String'];
  /**
   * When this value is set to true it will remove users that no longer match the criteria of the audience when the
   * audience is refreshed either by the trigger frequency or manually
   *
   * Note: This should be enabled (true) in FE by default
   */
  removeUsersInNextRefreshThatNoLongerMeetCriteria: Scalars['Boolean'];
  /** The frequency at which the audience should be refreshed */
  triggerFrequency: TriggerFrequency;
  /** If the trigger frequency is weekly then you specify which days */
  weeklyTriggerDays?: InputMaybe<Array<WeeklyTriggerDay>>;
};

export type CreateCohortInput = {
  learningPathId: Scalars['ID'];
  name: Scalars['String'];
  users?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateContentUserNote = {
  body: Scalars['String'];
  headline: Scalars['String'];
};

export type CreateEbookResult = Ebook | ValidationError;

export type CreateIamRoleResult = IamRole | ValidationError;

export type CreateOrganisationGroupInput = {
  audienceId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type CreatePodcastResult = Podcast | ValidationError;

export type CreatePostInput = {
  attachments: Array<InputMaybe<AttachmentInput>>;
  description?: InputMaybe<Scalars['String']>;
  mentions?: InputMaybe<Array<MentionInput>>;
  parentPost?: InputMaybe<Scalars['ID']>;
  privacy: PrivacySettings;
};

export type CreateStreamResult = Stream | ValidationError;

export type CreateTeamsInput = {
  company: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type CreateTeamsResult = {
  __typename: 'CreateTeamsResult';
  auth: AuthenticationResponse;
  organisation: Organisation;
  user: User;
};

export type CreateUserInput = {
  biography?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  /** facebookLink represents facebook profile url */
  facebookLink?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  /** twitterHandle represents instagram handle name */
  instagramHandle?: InputMaybe<Scalars['String']>;
  joiningReason?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  /** linkedinLink represents linkedin profile url */
  linkedinLink?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  /** twitterHandle represents twitter handle name */
  twitterHandle?: InputMaybe<Scalars['String']>;
  websiteLink?: InputMaybe<Scalars['String']>;
};

export type CreateUserResult = User | ValidationError;

export type CurrentlyFeaturedContent = {
  __typename: 'CurrentlyFeaturedContent';
  config?: Maybe<FeaturedContent>;
  content?: Maybe<ContentCard>;
};

export type CursorInfo = {
  __typename: 'CursorInfo';
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  nextCursor: Scalars['String'];
  prevCursor: Scalars['String'];
};

export type CursorPaginationInput = {
  afterCursor?: InputMaybe<Scalars['String']>;
  beforeCursor?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
};

export type DateValueInput = {
  intValue?: InputMaybe<Scalars['Int']>;
  timeValue?: InputMaybe<Scalars['Time']>;
};

export type DeleteOrganisationGroupResult =
  | ConflictError
  | NoContent
  | NotFoundError;

export enum DeviceOperation {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type Ebook = {
  __typename: 'Ebook';
  /**
   * The amount of time spent on by users
   * @deprecated Use timeSpentOnByUsers insteads
   */
  activityCount: Scalars['Int'];
  /** Audio available languages on the ebook */
  audioAvailableLanguages: Array<Language>;
  /** Available languages on the ebook */
  availableLanguages: Array<Language>;
  /** Content categories related to this ebook */
  categories: Array<Category>;
  /** The actual chapters of the ebook */
  chapters: Array<Chapter>;
  /**
   * Comments on content
   * Note: Do not pass more than 1 sortOrderingInput as it will break the functionality of cursor
   */
  comments: CommentCursorConnection;
  /** Tells whether content is rated by user or not */
  contentRatingByUser: ContentVote;
  contentSegments: Array<ContentSegment>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  /** The number of downvotes the content piece has received */
  downvoteCount: Scalars['Int'];
  entryLevel: EntryLevelValue;
  /** Episodes associated with the ebook */
  episodes: EbookEpisode;
  /**
   * Experts that are part of this ebook
   * @deprecated No longer applicable, use participants field instead
   */
  experts: Array<Expert>;
  id: Scalars['ID'];
  image: Image;
  isAssigned: Scalars['Boolean'];
  isFeatured: Scalars['Boolean'];
  /** @deprecated No longer applicable */
  isFree: Scalars['Boolean'];
  /** isSensitive represents extra filter on fetching events to make person privacy intact */
  isSensitive: Scalars['Boolean'];
  /** Learning paths that include this content piece */
  learningPaths: LearningPathConnection;
  length: Scalars['Int'];
  name: Scalars['String'];
  notificationPreference: NotificationPreferenceState;
  numberOfCommenters: Scalars['Int'];
  numberOfComments: Scalars['Int'];
  /** The number of reviews the content piece as received */
  numberOfReviews: Scalars['Int'];
  organisation?: Maybe<Organisation>;
  organisationGroups: Array<OrganisationGroup>;
  /**
   * Total number of pages across all chapters in the ebook
   * Mostly used to show the count to free users when viewing in preview mode
   */
  pagesCount: Scalars['Int'];
  /** Experts that are part of this ebook */
  participants: Array<User>;
  preamble: Scalars['String'];
  publishedAt?: Maybe<Scalars['Time']>;
  /** Averge rating from 1-5 the content piece has received */
  rating: Scalars['Float'];
  /** @deprecated Use length instead */
  readingTime?: Maybe<Scalars['Int']>;
  /** Which regions the content piece can be viewed in */
  regionRestrictions: Array<Scalars['String']>;
  /** Retrieve content that is related to this content piece */
  relatedContent: Array<ContentCard>;
  /** URL Friendly slug that can be used as a unique identifier */
  slug: Scalars['String'];
  /** The amount of time spent on by users */
  timeSpentOnByUsers: Scalars['Int'];
  /** Whether any tranlsation job is running for this ebook or not */
  translationOngoing: Scalars['Boolean'];
  updatedAt: Scalars['Time'];
  /** The number of upvotes the content piece has received */
  upvoteCount: Scalars['Int'];
  /** The users current progress within the book */
  userContentTracking: UserContentTracking;
};

export type EbookChaptersArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type EbookCommentsArgs = {
  afterCursor?: InputMaybe<Scalars['String']>;
  beforeCursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sorting?: InputMaybe<SortOrdering>;
};

export type EbookEpisodesArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type EbookLearningPathsArgs = {
  filter: LearningPathFilter;
  sorting?: InputMaybe<SortOrdering>;
};

export type EbookLengthArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type EbookNotificationPreferenceArgs = {
  type: NotificationType;
};

export type EbookPublishedAtArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type EbookReadingTimeArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type EbookRelatedContentArgs = {
  limit: Scalars['Int'];
};

export type EbookAudioInput = {
  episodes: Array<InputMaybe<EpisodeInput>>;
  length?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['Time']>;
};

export type EbookConnection = {
  __typename: 'EbookConnection';
  edges: Array<Ebook>;
  meta: PageInfo;
};

export type EbookEpisode = {
  __typename: 'EbookEpisode';
  episodes: Array<Episode>;
  language: Scalars['String'];
  length?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['Time']>;
};

export type EbookFilter = {
  categories?: InputMaybe<Array<Scalars['ID']>>;
  entryLevel?: InputMaybe<Array<EntryLevelValue>>;
  experts?: InputMaybe<Array<Scalars['ID']>>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  /** includeSensitiveContent filter the content basis of sensitivity of content */
  includeSensitiveContent?: InputMaybe<Scalars['Boolean']>;
  languages?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  onlyRated?: InputMaybe<Scalars['Boolean']>;
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  organisationID?: InputMaybe<Array<Scalars['ID']>>;
};

export type EbookInput = {
  categories?: InputMaybe<Array<Scalars['ID']>>;
  chapters?: InputMaybe<Array<ChapterInput>>;
  contentSegments: Array<ContentSegmentInput>;
  entryLevel: EntryLevelValue;
  experts?: InputMaybe<Array<Scalars['ID']>>;
  image: Scalars['String'];
  /** @deprecated No longer applicable */
  isFree?: InputMaybe<Scalars['Boolean']>;
  /** isSensitive represents extra filter on fetching events to make person privacy intact */
  isSensitive: Scalars['Boolean'];
  name: Scalars['String'];
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  organisationID?: InputMaybe<Scalars['ID']>;
  preamble: Scalars['String'];
  publishedAt?: InputMaybe<Scalars['Time']>;
  readingTime: Scalars['Int'];
  /** Which regions the content piece can be viewed in */
  regionRestrictions?: InputMaybe<Array<Scalars['String']>>;
  /** Tags represents hash tags which will be attached to content for better search and better content delivery */
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export enum EngagementReportColumns {
  Comments = 'COMMENTS',
  ContentPiecesDisliked = 'CONTENT_PIECES_DISLIKED',
  ContentPiecesLiked = 'CONTENT_PIECES_LIKED',
  Email = 'EMAIL',
  FeedPosts = 'FEED_POSTS',
  LivestreamAttended = 'LIVESTREAM_ATTENDED',
  LivestreamChatsSent = 'LIVESTREAM_CHATS_SENT',
  Reactions = 'REACTIONS',
  TigerhallUserId = 'TIGERHALL_USER_ID',
  UserName = 'USER_NAME'
}

export type EngagementReportFilter = {
  /** fetch engagements for the users matching the conditions in the provided dynamic audience id */
  audienceId?: InputMaybe<Scalars['ID']>;
  /** all the columns user wants to see in the report */
  columns: Array<EngagementReportColumns>;
  /** fetch engagements for the provided list of groups only */
  groups?: InputMaybe<Array<Scalars['ID']>>;
  /** whether to include archived users or not */
  includeArchivedUsers: Scalars['Boolean'];
  /** name of the report */
  reportName: Scalars['String'];
  /** fetch engagements done by user in the provided time range */
  timeFilter?: InputMaybe<TimeRangeFilter>;
  /** fetch engagements for the provided list of users only */
  users?: InputMaybe<Array<Scalars['ID']>>;
};

export enum EntryLevelValue {
  Advanced = 'ADVANCED',
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE'
}

export type Episode = {
  __typename: 'Episode';
  audio?: Maybe<AudioFile>;
  audioId: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type EpisodeInput = {
  audio: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type Event = {
  __typename: 'Event';
  /** The number of users attending an event */
  attendeeCount: Scalars['Int'];
  /** The attendees of an event */
  attendees: Array<Maybe<User>>;
  /** Categories related to this event */
  categories: Array<Category>;
  /**
   * Comments on content
   * Note: Do not pass more than 1 sortOrderingInput as it will break the functionality of cursor
   */
  comments: CommentCursorConnection;
  /** If the event is online the conference url should be provided */
  conferenceUrl?: Maybe<Scalars['String']>;
  /** Tells whether content is rated by user or not */
  contentRatingByUser: ContentVote;
  /** Extended information about the event */
  contentSegments: Array<ContentSegment>;
  /** When the event was created */
  createdAt: Scalars['Time'];
  /** When the event was deleted */
  deletedAt?: Maybe<Scalars['Time']>;
  /** The number of downvotes the content piece has received */
  downvoteCount: Scalars['Int'];
  /** What time the event is finished */
  endsAt?: Maybe<Scalars['Time']>;
  /** The levelt of the content being discussed such as beginner, advanced, experts etc */
  entryLevel: EntryLevelValue;
  /** Which type of event this is */
  eventType: EventType;
  /**
   * Experts that are hosting this event
   * @deprecated No longer applicable, use hosts field instead
   */
  experts: Array<Expert>;
  /** Experts that are hosting this event */
  hosts: Array<User>;
  id: Scalars['ID'];
  /** The image that portrays this event */
  image: Image;
  isAssigned: Scalars['Boolean'];
  /**
   * If the event is currently featured
   * @deprecated We don't know why this exists
   */
  isFeatured: Scalars['Boolean'];
  /**
   * If the event is free
   * @deprecated No longer applicable
   */
  isFree: Scalars['Boolean'];
  /** If the event is a online event */
  isOnline: Scalars['Boolean'];
  /** isSensitive represents extra filter on fetching events to make person privacy intact */
  isSensitive: Scalars['Boolean'];
  /** Trails related to this event */
  learningPaths: LearningPathConnection;
  /** Geo location for opening google maps etc */
  location?: Maybe<Location>;
  /** A shorter version of the location that we use to display in the app */
  locationDisplayName?: Maybe<Scalars['String']>;
  /** The max number of attendees an event can have */
  maxAttendees: Scalars['Int'];
  /** Name of the event */
  name: Scalars['String'];
  notificationPreference: NotificationPreferenceState;
  numberOfCommenters: Scalars['Int'];
  numberOfComments: Scalars['Int'];
  /** Which organisation this event is available for. If no organisation then it's a public event */
  organisation?: Maybe<Organisation>;
  /** Which groups within an organisation this event is available for. */
  organisationGroups: Array<OrganisationGroup>;
  /** Short description of the event */
  preamble: Scalars['String'];
  /** When the event should be published */
  publishedAt?: Maybe<Scalars['Time']>;
  /** If the waitlist is enabled then this is the questions the users need to reply to when signing up */
  questionnaire: Scalars['String'];
  /** Which regions the content piece can be viewed in */
  regionRestrictions: Array<Scalars['String']>;
  /** Retrieve content that is related to this content piece */
  relatedContent: Array<ContentCard>;
  /**
   * The required subscription level to attend this event
   *
   * Our more expensive events such as dinners with experts cost us more money to arrange so we need to restrict
   * this to users that are paying for it.
   * @deprecated No longer relevant
   */
  requiredSubscriptionLevel?: Maybe<SubscriptionLevel>;
  /** URL Friendly slug that can be used as a unique identifier */
  slug: Scalars['String'];
  /** What time the event starts */
  startsAt?: Maybe<Scalars['Time']>;
  /** stream will only be available if eventType is LIVESTREAM */
  stream?: Maybe<Stream>;
  /**
   * Single purchase ticket currency
   * @deprecated We no longer support single ticket purcases in the app
   */
  ticketCurrency: Scalars['String'];
  /**
   * The price of a single ticket purchase
   * @deprecated We no longer support single ticket purcases in the app
   */
  ticketPrice: Scalars['Int'];
  /** When the event was last updated */
  updatedAt: Scalars['Time'];
  /** The number of upvotes the content piece has received */
  upvoteCount: Scalars['Int'];
  /** User content tracking related to the event */
  userContentTracking: UserContentTracking;
  /** Users on the wait list */
  waitList: Array<EventWaitListAttendee>;
  /** If the wait list is enabled */
  waitListEnabled: Scalars['Boolean'];
};

export type EventCommentsArgs = {
  afterCursor?: InputMaybe<Scalars['String']>;
  beforeCursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sorting?: InputMaybe<SortOrdering>;
};

export type EventLearningPathsArgs = {
  filter: LearningPathFilter;
  sorting?: InputMaybe<SortOrdering>;
};

export type EventNotificationPreferenceArgs = {
  type: NotificationType;
};

export type EventRelatedContentArgs = {
  limit: Scalars['Int'];
};

export type EventWaitListArgs = {
  withHidden: Scalars['Boolean'];
};

export type EventConnection = {
  __typename: 'EventConnection';
  edges: Array<Event>;
  meta: PageInfo;
};

export type EventFilter = {
  /** Filter by specific categories */
  categories?: InputMaybe<Array<Scalars['String']>>;
  /** Use this to filter events that end after the specified time */
  endsAt?: InputMaybe<Scalars['Time']>;
  /** Filter by specific content levels */
  entryLevel?: InputMaybe<Array<EntryLevelValue>>;
  /** Use this to filter between different type of events to show */
  eventType?: InputMaybe<Array<EventType>>;
  /** Filter BY specific experts */
  experts?: InputMaybe<Array<Scalars['String']>>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  /** includeSensitiveContent filter the content basis of sensitivity of content */
  includeSensitiveContent?: InputMaybe<Scalars['Boolean']>;
  /** Pagination limit, defaults to 20 */
  limit?: InputMaybe<Scalars['Int']>;
  /** Filter by the event name */
  name?: InputMaybe<Scalars['String']>;
  /** Pagination offset, defaults to 0 */
  offset?: InputMaybe<Scalars['Int']>;
  /** Only show events that are in the future */
  onlyFutureEvents?: InputMaybe<Scalars['Boolean']>;
  /** Filter by specific organisation groups */
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  /** Filter by specific organisations */
  organisationID?: InputMaybe<Array<Scalars['ID']>>;
  /** Use this to filter events that start after the specified time */
  startsAt?: InputMaybe<Scalars['Time']>;
  /** Use this to filter events that start between the range of time */
  startsAtRange?: InputMaybe<TimeRangeFilter>;
  /**
   * Filter by events that require a specific subscription level
   * @deprecated No longer relevant
   */
  subscriptionLevel?: InputMaybe<Scalars['String']>;
};

export type EventInput = {
  /** Which categories/topics this event is associated with */
  categories: Array<Scalars['ID']>;
  /** If the event is online the conference url should be provided */
  conferenceUrl?: InputMaybe<Scalars['String']>;
  /** Content segments are just another way of structuring more information about the content */
  contentSegments: Array<ContentSegmentInput>;
  /** What time the event is finished */
  endsAt?: InputMaybe<Scalars['Time']>;
  /** What level of content will be discussed in the event */
  entryLevel: EntryLevelValue;
  /** What kind of event this is */
  eventType: EventType;
  /** Experts that will be hosting the event */
  experts: Array<Scalars['ID']>;
  /** Which image ID to use */
  image: Scalars['ID'];
  /**
   * If the event is free to attend
   * @deprecated No longer applicable
   */
  isFree?: InputMaybe<Scalars['Boolean']>;
  /** If the event is a online event */
  isOnline: Scalars['Boolean'];
  /** isSensitive represents extra filter on fetching events to make person privacy intact */
  isSensitive: Scalars['Boolean'];
  /** Geo spatial data for physical events */
  location?: InputMaybe<LocationInput>;
  /** A customed named location name */
  locationDisplayName?: InputMaybe<Scalars['String']>;
  /** The max number of attendees that can join this event */
  maxAttendees: Scalars['Int'];
  /** The name of the event */
  name: Scalars['String'];
  /** Which organistion groups that can see the event */
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  /** Which organisation this event is associated with */
  organisationID?: InputMaybe<Scalars['ID']>;
  /** A short description on what the event is about */
  preamble: Scalars['String'];
  /** When the event should be published */
  publishedAt?: InputMaybe<Scalars['Time']>;
  /** If the wait list is enabled ths following question will be asked to the users to reply when signing up to the event */
  questionnaire: Scalars['String'];
  /** Which regions the content piece can be viewed in */
  regionRestrictions?: InputMaybe<Array<Scalars['String']>>;
  /** @deprecated No longer relevant */
  requiredSubscriptionLevel?: InputMaybe<Scalars['String']>;
  /** What time the event starts */
  startsAt?: InputMaybe<Scalars['Time']>;
  /** Tags represents hash tags which will be attached to content for better search and better content delivery */
  tags?: InputMaybe<Array<Scalars['String']>>;
  /**
   * Which currency to charge the users in
   * @deprecated We no longer support single event ticket purchases
   */
  ticketCurrency?: InputMaybe<Scalars['String']>;
  /**
   * How much each entry ticket costs for users without a subscription
   * @deprecated We no longer support single event ticket purchases
   */
  ticketPrice?: InputMaybe<Scalars['Int']>;
  /** If the wait list should be enabled, if it is then when users sign up they get put on a wait list */
  waitListEnabled: Scalars['Boolean'];
};

export enum EventType {
  AskMeAnything = 'ASK_ME_ANYTHING',
  Conference = 'CONFERENCE',
  Livestream = 'LIVESTREAM',
  MeetInPerson = 'MEET_IN_PERSON',
  SmallGroupSession = 'SMALL_GROUP_SESSION',
  VirtualEvent = 'VIRTUAL_EVENT',
  Webinar = 'WEBINAR'
}

export type EventWaitListAttendee = {
  __typename: 'EventWaitListAttendee';
  createdAt: Scalars['Time'];
  event: Event;
  hiddenAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  isAttending: Scalars['Boolean'];
  questionnaireResponse: Scalars['String'];
  updatedAt: Scalars['Time'];
  user: User;
};

export type Expert = {
  __typename: 'Expert';
  /** Experts biography */
  biography: Scalars['String'];
  /** A list of categories in which the expert is deemed an expert */
  categories: Array<Category>;
  /** The name of the company that the expert worked for */
  company?: Maybe<Scalars['String']>;
  /** Retrieve content related in which the expert has been part of the creation */
  content: ContentConnection;
  /** Count of content expert has created */
  contentCreatedCount: Scalars['Int'];
  /** When the expert was created */
  createdAt: Scalars['Time'];
  /** If the expert is live this is the live stream ID */
  currentLiveStreamId?: Maybe<Scalars['ID']>;
  /** The email of the expert */
  email: Scalars['String'];
  /** Their first name */
  firstName: Scalars['String'];
  /** Identifier of the expert */
  id: Scalars['ID'];
  /** Profile/Avatar picture */
  image: Image;
  /** If the current authenticated user is following this expert */
  isAuthenticatedUserFollowing: Scalars['Boolean'];
  /** If an expert is a mentor */
  isMentor: Scalars['Boolean'];
  /** Their last name */
  lastName: Scalars['String'];
  /** If the mentor is available for mentoring this contains some brief information to their capabilities */
  mentorAvailableText?: Maybe<Scalars['String']>;
  /** How much the mentor would charge for a session */
  mentorPriceText?: Maybe<Scalars['String']>;
  /** An organisation which the expert is associated to */
  organisation?: Maybe<Organisation>;
  /** Which organisation group the expert is associated */
  organisationGroups: Array<OrganisationGroup>;
  /** Deprecated */
  priority: Scalars['Int'];
  /** Which regions the expert should be available in */
  regionRestrictions?: Maybe<Array<Scalars['String']>>;
  /** URL Friendly slug that can be used as a unique identifier */
  slug: Scalars['String'];
  /** The title of the expert */
  title: Scalars['String'];
  /** When the expert was updated */
  updatedAt: Scalars['Time'];
  /** Which user is associated to the expert, can only be access by the user it's self or an admin */
  user?: Maybe<User>;
  /** The userID associated to the expert thats publicly available */
  userId?: Maybe<Scalars['ID']>;
};

export type ExpertContentArgs = {
  filter: ContentCardsFilter;
  sorting: ContentCardsSorting;
};

export type ExpertCollection = {
  __typename: 'ExpertCollection';
  edges: Array<Expert>;
  meta: PageInfo;
};

export type ExpertFilter = {
  /** Filter by categories associated to the experts */
  categories?: InputMaybe<Array<Scalars['ID']>>;
  /** Search for experts by email */
  email?: InputMaybe<Scalars['String']>;
  /** Only retrieve the experts that the authenticted user performing this request follows */
  followedByAuthenticatedUser?: InputMaybe<Scalars['Boolean']>;
  /** Ensures that the experts have the permission to livestream */
  isAbleToLivestream?: InputMaybe<Scalars['Boolean']>;
  /** That the expert profile has been associted to a user account */
  isAssociatedToUser?: InputMaybe<Scalars['Boolean']>;
  /** Only return experts that offer mentorship session */
  isMentor?: InputMaybe<Scalars['Boolean']>;
  /** Job title */
  jobTitle?: InputMaybe<Scalars['String']>;
  /** Filter by life goals associated the categories */
  lifeGoals?: InputMaybe<Array<Scalars['String']>>;
  /** The number of experts to return */
  limit?: InputMaybe<Scalars['Int']>;
  /** Search for experts by name */
  name?: InputMaybe<Scalars['String']>;
  /** Offset of the results return */
  offset?: InputMaybe<Scalars['Int']>;
  /** Experts that belong to a specific organisaiton group */
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  /** Experts that belong to any of the organisations */
  organisationID?: InputMaybe<Array<Scalars['ID']>>;
};

export type ExpertInput = {
  /** Biography */
  biography: Scalars['String'];
  /** Array of category ids */
  categories: Array<Scalars['ID']>;
  /** The company the expert works at */
  company?: InputMaybe<Scalars['String']>;
  /** The experts email address */
  email: Scalars['String'];
  /** First name */
  firstName: Scalars['String'];
  /** Image ID */
  image: Scalars['ID'];
  /** If the expert is a mentor */
  isMentor: Scalars['Boolean'];
  /** Last name */
  lastName: Scalars['String'];
  /** Where the mentor is available to mentor */
  mentorAvailableText?: InputMaybe<Scalars['String']>;
  /** What price to show for mentorship */
  mentorPriceText?: InputMaybe<Scalars['String']>;
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  organisationID?: InputMaybe<Scalars['ID']>;
  priority: Scalars['Int'];
  /** Which regions the expert should be available in */
  regionRestrictions?: InputMaybe<Array<Scalars['String']>>;
  /** The experts title */
  title: Scalars['String'];
};

export type FeaturedContent = {
  __typename: 'FeaturedContent';
  content?: Maybe<ContentCard>;
  createdAt: Scalars['Time'];
  header: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Image>;
  /** @deprecated No longer applicable */
  isFree: Scalars['Boolean'];
  linkToURL: Scalars['String'];
  organisation?: Maybe<Organisation>;
  organisationGroups: Array<OrganisationGroup>;
  paragraph: Scalars['String'];
  promotedFrom: Scalars['String'];
  promotedUntil: Scalars['String'];
  screen: Scalars['String'];
  screenParams: Scalars['Map'];
  updatedAt: Scalars['Time'];
};

export type FeaturedContentConnection = {
  __typename: 'FeaturedContentConnection';
  edges: Array<FeaturedContent>;
  meta: PageInfo;
};

export type FeaturedContentFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  onlyVisible?: InputMaybe<Scalars['Boolean']>;
};

export type FeaturedContentInput = {
  contentId?: InputMaybe<Scalars['String']>;
  contentType?: InputMaybe<ContentType>;
  header?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['ID']>;
  /** @deprecated No longer applicable */
  isFree?: InputMaybe<Scalars['Boolean']>;
  linkToURL?: InputMaybe<Scalars['String']>;
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  organisationId?: InputMaybe<Scalars['ID']>;
  paragraph?: InputMaybe<Scalars['String']>;
  promotedFrom: Scalars['Time'];
  promotedUntil: Scalars['Time'];
  screen?: InputMaybe<Scalars['String']>;
  screenParams?: InputMaybe<Scalars['Map']>;
};

export type FeedActivity = {
  __typename: 'FeedActivity';
  /** The author is the first person that performed the activity */
  author: User;
  /**
   * Comments on content
   * Note: Do not pass more than 1 sortOrderingInput as it will break the functionality of cursor
   */
  comments: CommentCursorConnection;
  /** A union of all the different content types that should match to the activity feed */
  content: FeedActivityContent;
  /** Other users that have performed the same action but after the initial "author" */
  contributors: Array<User>;
  /** When the activity was created */
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  /** If the currently authenticated user has liked the activity */
  likedByUser: Scalars['Boolean'];
  /** @deprecated we dont allow commenting on content_activity anymore */
  numberOfComments: Scalars['Int'];
  /** @deprecated we dont allow likes on content_activity anymore */
  numberOfLikes: Scalars['Int'];
  /** What kind of activity was performed */
  type: FeedActivityType;
  /** When the activity was last updated */
  updatedAt: Scalars['Time'];
};

export type FeedActivityCommentsArgs = {
  afterCursor?: InputMaybe<Scalars['String']>;
  beforeCursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sorting?: InputMaybe<SortOrdering>;
};

export type FeedActivityContent =
  | Comment
  | Ebook
  | Event
  | Expert
  | LearningPath
  | Podcast
  | Stream
  | User;

export enum FeedActivityType {
  Comment = 'COMMENT',
  FinishedContent = 'FINISHED_CONTENT',
  FollowUser = 'FOLLOW_USER',
  NewelyPublishContent = 'NEWELY_PUBLISH_CONTENT',
  RatedContent = 'RATED_CONTENT',
  ReplyOnComment = 'REPLY_ON_COMMENT',
  ReportedContent = 'REPORTED_CONTENT'
}

export type FeedConnection = {
  __typename: 'FeedConnection';
  edges: Array<FeedEdge>;
  meta: CursorInfo;
};

export type FeedContent = {
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  user: User;
};

export type FeedContentConnection = {
  __typename: 'FeedContentConnection';
  edges: Array<FeedContentEdge>;
  meta: CursorInfo;
};

export type FeedContentEdge = {
  __typename: 'FeedContentEdge';
  cursor: Scalars['String'];
  feed: FeedContent;
};

export enum FeedContentType {
  ContentActivity = 'CONTENT_ACTIVITY',
  Post = 'POST',
  PostActivity = 'POST_ACTIVITY'
}

export type FeedEdge = {
  __typename: 'FeedEdge';
  cursor: Scalars['String'];
  feed: FeedActivity;
};

export type FeedFilter = {
  /** Retrieve actiites that happened after this timestamp */
  afterCursor?: InputMaybe<Scalars['String']>;
  /** Retrieve activities that happened before this timestamp */
  beforeCursor?: InputMaybe<Scalars['String']>;
  /** The number of items to return */
  limit: Scalars['Int'];
  /** Only return specific types of activity feed */
  types: Array<FeedActivityType>;
};

export type FeedPostAttachments = {
  __typename: 'FeedPostAttachments';
  attachmentType: Scalars['String'];
  content?: Maybe<FeedActivityContent>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type FieldError = {
  __typename: 'FieldError';
  message: Scalars['String'];
  path: Scalars['String'];
};

export type FollowUserSuggestions = {
  __typename: 'FollowUserSuggestions';
  edges: Array<User>;
  meta: PageInfo;
};

export type FollowersFollowingFilter = {
  isAMemberOfSameOrganisation?: InputMaybe<Scalars['Boolean']>;
  isExpert?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  query?: InputMaybe<Scalars['String']>;
};

export type GraphDataPoint = {
  __typename: 'GraphDataPoint';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type IamRole = {
  __typename: 'IAMRole';
  canEdit?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organisationId?: Maybe<Scalars['ID']>;
  permissions: Array<PermissionStatus>;
  roleType: Scalars['String'];
  updatedAt: Scalars['Time'];
  users?: Maybe<UserCollection>;
};

export type IamRoleUsersArgs = {
  pagination?: InputMaybe<OffsetPagination>;
};

export type IamRoleConnection = {
  __typename: 'IAMRoleConnection';
  edges: Array<IamRole>;
  meta: PageInfo;
};

export type IamRoleInput = {
  name: Scalars['String'];
  orgId?: InputMaybe<Scalars['ID']>;
  permissions: Array<PermissionInput>;
  roleType: IamRoleType;
};

export enum IamRoleType {
  Org = 'ORG',
  System = 'SYSTEM'
}

export type IamRoleUsersResult = IamRole | NotFoundError | ValidationError;

export enum IdentityType {
  LoginGoogle = 'LOGIN_GOOGLE',
  LoginMicrosoft = 'LOGIN_MICROSOFT'
}

export type Image = {
  __typename: 'Image';
  alt: Scalars['String'];
  blurHash: Scalars['String'];
  contentType: Scalars['String'];
  createdAt: Scalars['Time'];
  filename: Scalars['String'];
  height: Scalars['Int'];
  id: Scalars['ID'];
  organisationID?: Maybe<Scalars['ID']>;
  originalName: Scalars['String'];
  sizeInBytes: Scalars['Int'];
  uri: Scalars['String'];
  width: Scalars['Int'];
};

export type ImageConnection = {
  __typename: 'ImageConnection';
  edges: Array<Image>;
  meta: PageInfo;
};

export type ImageFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  organisationID?: InputMaybe<Array<Scalars['ID']>>;
};

export type IntRangeFilter = {
  from?: InputMaybe<Scalars['Int']>;
  until?: InputMaybe<Scalars['Int']>;
};

export type Invitation = {
  __typename: 'Invitation';
  acceptedAt?: Maybe<Scalars['Time']>;
  createdAt: Scalars['Time'];
  createdBy: User;
  email: Scalars['String'];
  /**
   * Shows if the invitation is expired due to canceled by admin.
   * Only shown if invitation already passed the expiresAt.
   */
  expiredByAdmin?: Maybe<Scalars['Boolean']>;
  expiresAt: Scalars['Time'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  organisation: Organisation;
  status: OrganisationInvitationStatus;
  user: User;
};

export type InvitationConnection = {
  __typename: 'InvitationConnection';
  edges: Array<Invitation>;
  meta: PageInfo;
};

export type InvitationFilter = {
  /** @deprecated Should use status filter instead */
  accepted?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Should use status filter instead */
  acceptedAtRange?: InputMaybe<TimeRangeFilter>;
  createdAtRange?: InputMaybe<TimeRangeFilter>;
  createdByIds?: InputMaybe<Array<Scalars['ID']>>;
  email?: InputMaybe<Scalars['String']>;
  /** @deprecated Should use status filter instead */
  expiredByAdmin?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Should use status filter instead */
  expiresAtRange?: InputMaybe<TimeRangeFilter>;
  limit: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  /** @deprecated Not needed because invitation was a resolver inside an organisation */
  organisationIds?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<Array<OrganisationInvitationStatus>>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type IsCouponValid = {
  __typename: 'IsCouponValid';
  message?: Maybe<Scalars['String']>;
  valid: Scalars['Boolean'];
};

export type Language = {
  __typename: 'Language';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type LearningPath = {
  __typename: 'LearningPath';
  categories: Array<Category>;
  /** Retrieve the associated cohorts that are part of this learning path */
  cohorts?: Maybe<CohortConnection>;
  /**
   * Percentage progress of the trail (Average out of all contents)
   * Note: This field can only be resolve if called from user obj as parent.
   */
  completionRate: Scalars['Int'];
  /** All the content cards associated to the learning path exposed under a single field and correctly sorted */
  contentCards: Array<ContentCard>;
  contentSegments: Array<ContentSegment>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  /** @deprecated Use the `contentCards` field instead */
  ebooks: Array<Ebook>;
  /** @deprecated Use the `contentCards` field instead */
  events: Array<Event>;
  featuredCategory?: Maybe<Category>;
  featuredLifegoal?: Maybe<Lifegoal>;
  id: Scalars['ID'];
  image: Image;
  isAssigned: Scalars['Boolean'];
  /** @deprecated No longer applicable */
  isFree?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  numberOfCommenters: Scalars['Int'];
  numberOfComments: Scalars['Int'];
  organisation?: Maybe<Organisation>;
  organisationGroups: Array<OrganisationGroup>;
  /** Pending cohort invites for user to accept/reject on the given learning path */
  pendingCohortInvites: Scalars['Int'];
  /** @deprecated Use the `contentCards` field instead */
  podcasts: Array<Podcast>;
  preamble: Scalars['String'];
  /** Which regions the content piece can be viewed in */
  regionRestrictions: Array<Scalars['String']>;
  /** URL Friendly slug that can be used as a unique identifier */
  slug: Scalars['String'];
  /** @deprecated The content returned from `contentCards` should already be correctly sorted */
  sortOrder: Array<Scalars['ID']>;
  /** @deprecated Use the `contentCards` field instead */
  streams: Array<Stream>;
  /**
   * If strict content progression is "true" then the content must be consumed in a serial fashion starting with the first
   * and ending with the last content piece
   */
  strictContentProgression: Scalars['Boolean'];
  updatedAt: Scalars['Time'];
  userCollectionTracking?: Maybe<UserCollectionTracking>;
};

export type LearningPathCohortsArgs = {
  filter?: InputMaybe<LearningPathCohortFilter>;
  pagination?: InputMaybe<CursorPaginationInput>;
};

export type LearningPathCohortFilter = {
  inviteStatus?: InputMaybe<CohortInviteStatus>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type LearningPathConnection = {
  __typename: 'LearningPathConnection';
  edges: Array<LearningPath>;
  meta: PageInfo;
};

export type LearningPathFilter = {
  categories?: InputMaybe<Array<Scalars['ID']>>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated No longer applicable */
  isFree?: InputMaybe<Scalars['Boolean']>;
  keywords?: InputMaybe<Scalars['String']>;
  lifegoal?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  organisationID?: InputMaybe<Array<Scalars['ID']>>;
};

export type LearningPathInput = {
  categories: Array<Scalars['ID']>;
  contentSegments?: InputMaybe<Array<ContentSegmentInput>>;
  ebooks: Array<Scalars['ID']>;
  events: Array<Scalars['ID']>;
  featuredCategory?: InputMaybe<Scalars['String']>;
  featuredLifegoal?: InputMaybe<Scalars['String']>;
  image: Scalars['ID'];
  /** @deprecated No longer applicable */
  isFree?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  organisationID?: InputMaybe<Scalars['ID']>;
  podcasts: Array<Scalars['ID']>;
  preamble: Scalars['String'];
  /** Which regions the content piece can be viewed in */
  regionRestrictions?: InputMaybe<Array<Scalars['String']>>;
  sortOrder: Array<Scalars['ID']>;
  streams: Array<Scalars['ID']>;
  /**
   * If not provided the existing value will be retained, else it controls if the content must be controlled in a serial
   * fashion or can be completed in any order
   */
  strictContentProgression?: InputMaybe<Scalars['Boolean']>;
};

export type Lifegoal = {
  __typename: 'Lifegoal';
  /** Associated categories */
  categories: Array<Category>;
  /** The number of content pieces */
  contentCount?: Maybe<Scalars['Int']>;
  /** When it was created */
  createdAt: Scalars['Time'];
  /** An emoji representation of the life goal */
  emoji?: Maybe<Scalars['String']>;
  /** Experts that have content published content within this life goal or categories */
  experts: ExpertCollection;
  /**
   * Small icon used in the old explore page
   * @deprecated No longer used after the 1.13.9 release
   */
  icon?: Maybe<Image>;
  id: Scalars['ID'];
  /**
   * Image of the life goal, currently only used in the trail listing page
   * @deprecated No longer used after the 1.13.9 release
   */
  image?: Maybe<Image>;
  /** isFollowing attribute verify life goal is followed by user or not */
  isFollowing: Scalars['Boolean'];
  /** Retrieve the learning paths associated with this life goal */
  learningPaths: LearningPathConnection;
  /** Name of the life goal */
  name: Scalars['String'];
  /** If the life goal belongs to a specific organisation */
  organisation?: Maybe<Organisation>;
  /** URL Friendly slug that can be used as a unique identifier */
  slug: Scalars['String'];
  /** When it was last updated */
  updatedAt: Scalars['Time'];
};

export type LifegoalLearningPathsArgs = {
  filter: LearningPathFilter;
  sorting: SortOrdering;
};

export type LifegoalCollection = {
  __typename: 'LifegoalCollection';
  edges: Array<Lifegoal>;
  meta: PageInfo;
};

export type LifegoalFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organisationID?: InputMaybe<Array<Scalars['ID']>>;
};

export type LifegoalInput = {
  /** Categories associated to the life goal */
  categories?: InputMaybe<Array<Scalars['ID']>>;
  /** Emoji representation of the life goal */
  emoji: Scalars['String'];
  /** Image ID to use for the icon */
  icon?: InputMaybe<Scalars['ID']>;
  /** Image ID */
  image: Scalars['ID'];
  /** Name of the life goal */
  name: Scalars['String'];
  /** If the life goal belongs to a specific organisation */
  organisationID?: InputMaybe<Scalars['ID']>;
};

export enum LikeType {
  Comment = 'COMMENT',
  Feed = 'FEED'
}

/**
 * LikeableContent represents activities which can be liked by like mutation.
 * Note: this is deprecated, use ToggleReact
 */
export type LikeableContent = Comment | FeedActivity;

export type LinkPreview = {
  __typename: 'LinkPreview';
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

/**
 * If you filter by codes, coupons, userIds and orgIds it will be retrieve with `OR` logic
 * and get all of those you input from stripe if they exist.
 */
export type ListStripePromotionCodeInput = {
  /**
   * Note: if you want to search by list of codes or list of coupons, ensure that you pass the full (case sensitive)
   * codes or coupons
   */
  codes?: InputMaybe<Array<Scalars['String']>>;
  coupons?: InputMaybe<Array<Scalars['String']>>;
  createdAfter?: InputMaybe<Scalars['Time']>;
  createdBefore?: InputMaybe<Scalars['Time']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  organisationIds?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type LiveStreamInput = {
  /** Name of the livestream */
  name: Scalars['String'];
  /** If only specific groups within the organisation should see the video */
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  /** If the stream should only be visible by a single organisation */
  organisationID?: InputMaybe<Scalars['ID']>;
};

export type Location = {
  __typename: 'Location';
  city: Scalars['String'];
  coordinates: Coordinates;
  name: Scalars['String'];
};

export type LocationInput = {
  city: Scalars['String'];
  coordinates: CoordinatesInput;
  name: Scalars['String'];
};

export type LoginEmailResult =
  | AuthenticationError
  | AuthenticationResponse
  | ValidationError;

export type MSteamsChannelTabFollowTopic = {
  __typename: 'MSteamsChannelTabFollowTopic';
  channelID: Scalars['String'];
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  teamID: Scalars['String'];
  tenantID: Scalars['ID'];
  topics: Array<Lifegoal>;
  updatedAt: Scalars['Time'];
};

export type ManualVideoBroadcastConfigurationInput = {
  /** The external URL in the provider */
  externalID: Scalars['String'];
  /** The HLS playback URL for the stream */
  playbackURL: Scalars['String'];
  /** The RTMP ingest URL for the stream */
  rtmpUrl: Scalars['String'];
  /** The stream key to the video file */
  streamKey: Scalars['String'];
};

export enum MediaOrientation {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT'
}

export type Mention = {
  __typename: 'Mention';
  id: Scalars['ID'];
  /** Holds the placement of the mention in the comment */
  index: Scalars['Int'];
  mentionType: MentionType;
  target: Mentioned;
  /**
   * ID of the target. Can be user or an Organisation
   * TargetId should ideally resolve from mentions service. Since apollos'service
   * federated graph does not support subscriptions, need to resolve all the fields
   * requested by subscription mutations from core until we have a better solution
   * in place.
   */
  targetId: Scalars['ID'];
  targetType: TargetType;
};

export type MentionInput = {
  index: Scalars['Int'];
  targetId: Scalars['ID'];
  targetType: TargetType;
};

export enum MentionType {
  Comment = 'COMMENT',
  Feed = 'FEED',
  Post = 'POST'
}

export type Mentioned = User;

export type MonthlyPromotedLearningPath = {
  __typename: 'MonthlyPromotedLearningPath';
  description: Scalars['String'];
  learninPath?: Maybe<LearningPath>;
};

export type Mutation = {
  __typename: 'Mutation';
  /**
   * This mutation to add answer to question
   * @deprecated Use addAnswerToPollQuestionV2 instead it will return answer opiton object in return
   */
  addAnswerToPollQuestion: Scalars['Boolean'];
  /** This mutation to add answer to question v2 which return answer options object */
  addAnswerToPollQuestionV2: PollQuestionResponse;
  /** add new users to the cohort */
  addCohortMembers: Cohort;
  /**
   * When a user joins a stream and they are invited to join as a host then this is the API call tha makes them visible
   *
   * todo: rename this method to better match what it does
   */
  addHost: Stream;
  /** add users to IAM role either via csv or by selecting users from dropdown */
  addIAMRoleUsers?: Maybe<IamRoleUsersResult>;
  applyToEventWaitList: EventWaitListAttendee;
  /** cancelOrganisationSubscription will cancel the subscription for the provided organisation immediately */
  cancelOrganisationSubscription: NoContent;
  cancelWaitlistApplication?: Maybe<Event>;
  /** Change the broadcast hls playback of a stream, can only be with with broadcast streams */
  changeBroadcastConfiguration: Stream;
  /** Change the isActive of a livestream */
  changeStreamActiveAgendaTopic: Stream;
  /** Change the status of a stream, can only be with with broadcast streams */
  changeStreamStatus: Stream;
  consumeCoupon?: Maybe<SubscriptionModel>;
  /** convertEbookTextToAudio converts ebooks chapters text to audio in provided voice type */
  convertEbookTextToAudio: Scalars['Boolean'];
  /**
   * convertPodcastSummaryToAudio converts podcast transcript summary to audio in provided voice type
   * This doesn't generate for English language as that needs to be original podcast audio
   */
  convertPodcastSummaryToAudio: Scalars['Boolean'];
  /** Create a user using microsoft teams access token. The mutation will also create the user's organisation if it does not exist. */
  createAccountMSTeams?: Maybe<CreateAccountMsTeamsResult>;
  createAssignment: Assignment;
  /** Create a new audience that can be used for targeting users in different features of our platform */
  createAudience: Audience;
  createCategory?: Maybe<Category>;
  /** createChatMessage id represent target content id for which people are talking about */
  createChatMessage: ChatMessage;
  /** creates a new trail cohort */
  createCohort: Cohort;
  /** Add a comment */
  createComment: Comment;
  /** Create content user note */
  createContentUserNote: ContentUserNote;
  createCoupon?: Maybe<Coupon>;
  createEbook?: Maybe<CreateEbookResult>;
  createEvent?: Maybe<Event>;
  createFeaturedContent?: Maybe<FeaturedContent>;
  /** Take a pre-signed URL and create an image from it. */
  createGalleryImageFromPreSignedUrl: Image;
  /** creates new iam role */
  createIAMRole?: Maybe<CreateIamRoleResult>;
  createInstantRepost: Post;
  createLearningPath?: Maybe<LearningPath>;
  createLifegoal?: Maybe<Lifegoal>;
  /** Create a live stream and if an organisation is specified then it's restricted to that organisation */
  createLiveStream: Stream;
  /**
   * Create a live stream based on existing event. If the event is for a specific org, it must match the passed organisationID.
   * Note: organisation and organisationGroups info will be inherited as declared in the event.
   */
  createLiveStreamFromEvent: Stream;
  createOrganisation?: Maybe<Organisation>;
  createOrganisationGroup?: Maybe<OrganisationGroup>;
  createPodcast?: Maybe<CreatePodcastResult>;
  /** This mutation is used to add poll questions */
  createPollQuestion: PollQuestionResponse;
  /**
   * createPost is used to create post or repost with thoughts.
   * Note: cannot create instant repost from this mutation.
   */
  createPost: Scalars['Boolean'];
  /** Create a stream with a basic info */
  createStream?: Maybe<CreateStreamResult>;
  createStripePromoCode?: Maybe<Array<StripePromotionCode>>;
  createSubscriptionPlan?: Maybe<SubscriptionPlan>;
  /** Create tigerhall teams user account and organisation */
  createTeamsAccount?: Maybe<CreateTeamsResult>;
  /** @deprecated use createUserV2 mutation instead */
  createUser?: Maybe<User>;
  /**
   * createUserV2 creates a user
   * it returns union of User and ValidationErrors, if any of the input(s) are invalid it returns all the validation errors combined else return created user
   */
  createUserV2: CreateUserResult;
  deactivateStripePromoCode: Scalars['Boolean'];
  deleteAssignment: Scalars['Boolean'];
  deleteCategory: Scalars['String'];
  /** delete a comment */
  deleteComment: Scalars['Boolean'];
  /** Delete an existing content user note */
  deleteContentUserNote: Scalars['Boolean'];
  deleteCoupon?: Maybe<NoContent>;
  deleteEbook: Scalars['String'];
  deleteEvent: Scalars['String'];
  deleteFeaturedContent: NoContent;
  /** delete an iam role */
  deleteIAMRole: Scalars['Boolean'];
  /** Delete an image */
  deleteImage: NoContent;
  deleteLearningPath: NoContent;
  deleteLifegoal: NoContent;
  /** deleteNotification deletes the notification */
  deleteNotification: NoContent;
  deleteOrganisation: NoContent;
  deleteOrganisationGroup?: Maybe<DeleteOrganisationGroupResult>;
  deletePodcast: Scalars['String'];
  /** This mutation is used to delete poll question */
  deletePollQuestion: Scalars['Boolean'];
  deletePost: Scalars['Boolean'];
  /** Delete a stream, remove a stream from the system */
  deleteStream: Scalars['String'];
  deleteSubscriptionPlan: NoContent;
  /** Delete a user from the platform */
  deleteUser: NoContent;
  disbandCohort: Scalars['Boolean'];
  /** Check if the email the user is trying to sign up with already exists */
  emailExists: Scalars['Boolean'];
  /**
   * exportActivationByUserReport: Create a list of all logged in or active users in the platform.
   * This will trigger a background process that will send a link to the user that initiated the export
   */
  exportActivationByUserReport: ReportBuilderResult;
  /**
   * exportActivationOverviewReport: Get an overview of number of licenses adopted along with active users count in the platform.
   * This will trigger a background process that will send a link to the user that initiated the export
   */
  exportActivationOverviewReport: ReportBuilderResult;
  /**
   * Export user statistics
   *
   * This will trigger a background process that will send a link to the user that initiated the export"
   */
  exportAssignmentStatistics: Scalars['Boolean'];
  /**
   * exportContentStatisticsReport: Content statistics for all the org content.
   * This will trigger a background process that will send a link to the user that initiated the export
   */
  exportContentStatisticsReport: ReportBuilderResult;
  /**
   * exportEngagementReport: Engagement report for all the users
   * This will trigger a background process that will send a link to the user that initiated the export
   */
  exportEngagementReport: ReportBuilderResult;
  /**
   * exportUserAssignmentStatisticsReport: Assignment statistics for all the org users.
   * This will trigger a background process that will send a link to the user that initiated the export
   */
  exportUserAssignmentStatisticsReport: ReportBuilderResult;
  /**
   * exportUserCommentsReport: creates the reports for comments added by users
   * This will trigger a background process that will send a link to the user that initiated the export
   */
  exportUserCommentsReport: ReportBuilderResult;
  /**
   * exportUserContentStatisticsReport: Content statistics for all the org content and public content that they have consumed or consuming by the given org users.
   * This will trigger a background process that will send a link to the user that initiated the export
   */
  exportUserContentStatisticsReport: ReportBuilderResult;
  /**
   * exportUserTrailStatisticsReport: Trail statistics for all the trails in the org.
   * This will trigger a background process that will send a link to the user that initiated the export
   */
  exportUserTrailStatisticsReport: ReportBuilderResult;
  followAllUsersInOrg: Scalars['Boolean'];
  /**
   * generatePodcastTranscript generates the English transcript for a podcast
   * if translateTranscripts is true, then translates them to all the languages
   */
  generatePodcastTranscript: Scalars['Boolean'];
  /**
   * generateStreamSubtitles generates the English subtitles for a stream
   * if translateSubtitles is true, then translates them to all the languages
   */
  generateStreamSubtitles: Scalars['Boolean'];
  /** Create a pre signed URL for the user to upload an object to s3 */
  getPreSignedUploadUrl: PreSignedUploadUrl;
  /** To send the invitation reminder to host for joining the livestream */
  inviteHost: Scalars['Boolean'];
  /** To add new hosts to the livestream and send them notification to join it. */
  invitesAddHosts: Scalars['Boolean'];
  /** When a host rejects the invitation to join the livestream */
  invitesReject: Scalars['Boolean'];
  /** This will return if the provided version of the mobile app (WITHOUT CodePUSH) is up to date */
  isAppUp2Date: Scalars['Boolean'];
  isCouponValid: IsCouponValid;
  /** Check if the email, well actually if the domain is sso enabled */
  isSSOEnabled: Scalars['Boolean'];
  leaveCohort: Scalars['Boolean'];
  /**
   * Login with apple will verify the authentication nonce and either create an account or login based on an existing account
   *
   * Note: Apple will only return the first & last name on the first authentication in the mobile app
   * so on the following request we can simple call this method with an empty string, calling it with null will break it.
   *
   * CustomBundleId changes which clientId we should use to generate and validate the authorizationCode against apple
   */
  loginApple?: Maybe<AuthenticationResponse>;
  /**
   * Login with email with authenticate the request against an existing account
   *
   * If backoffice is provided and the user has the correct permissions the JWT will be generated with a nil organisation
   */
  loginEmail?: Maybe<AuthenticationResponse>;
  /**
   * loginEmailV2 with authenticate the request against an existing account
   * If backoffice is provided and the user has the correct permissions the JWT will be generated with a nil organisation
   * It returns union of AuthenticationResponse, ValidationErrors and AuthenticationError
   * if user credentials are wrong it returns the validation errors, if there is issue with user such as blocked, deleted it returns AuthenticationError else on success AuthenticationResponse
   */
  loginEmailV2: LoginEmailResult;
  /** Login with facebook will create a new account if no user with the provided facebook id exists */
  loginFB?: Maybe<AuthenticationResponse>;
  /**
   * Login with linkedin will verify the authorization code (NOT Access token) against the linkedin API.
   *
   * For linkedin to provide the authorization code the redirect_uri must be set to the same value as the one provided in
   * the request initiated on the client side.
   */
  loginLinkedIn?: Maybe<AuthenticationResponse>;
  /**
   * Login a user using microsoft teams access token
   *
   * Note: This will only work if the user already exists, we cannot provision new users using this method
   */
  loginMSTeams?: Maybe<AuthenticationResponse>;
  /** Manage the service associated to a user for push notifications */
  manageUserDevices: NoContent;
  /** markAllNotificationRead will mark all the user notifications as read */
  markAllNotificationRead: NotificationConnection;
  markAssignmentsAsViewed: NoContent;
  /** markNotificationRead will mark that notification as read */
  markNotificationRead: Notification;
  /** markNotificationsOld is for when user opens the notification center to mark all the new notifications as unread */
  markNotificationsOld: User;
  /** nudgeCohortUser send notification to user to accept or reject cohort invitation */
  nudgeCohortUser: Cohort;
  organisationAddUser?: Maybe<NoContent>;
  organisationAdminNudgeAllUsers?: Maybe<NoContent>;
  organisationAdminNudgeUser?: Maybe<NoContent>;
  organisationExportAssignmentStatisticsByAssignment: NoContent;
  organisationExportAssignmentStatisticsByGroup: NoContent;
  organisationExportAssignmentStatisticsByUser: NoContent;
  organisationExportMainStatistics: NoContent;
  organisationExportUserAssignmentStatistics: NoContent;
  organisationGroupAddMultiUser?: Maybe<Array<AddUserResponse>>;
  organisationGroupAddMultipleUser?: Maybe<NoContent>;
  organisationGroupAddUser?: Maybe<NoContent>;
  organisationGroupRemoveMultiUser?: Maybe<Array<AddUserResponse>>;
  organisationGroupRemoveUser?: Maybe<NoContent>;
  /**
   * Import users in a batch
   *
   * If the users are not added to the organisation using the batch import it is because they
   * most likely already exist in the system. And for security purposes they cannot be added to the organisation
   * by a mear user.
   */
  organisationImportUsers?: Maybe<Array<OrganisationUserImportResponse>>;
  /** Join an organisation by using an invite link with a token */
  organisationJoinViaInviteLink: Scalars['Boolean'];
  organisationRemoveMultiUser?: Maybe<Array<AddUserResponse>>;
  /**
   * Accepts either userId or email of the user to be removed.
   * If both are present, the userId will be used as default.
   */
  organisationRemoveUser?: Maybe<NoContent>;
  /** organisationSubscription will create the subscription for the provided organisation */
  organisationSubscription: NoContent;
  /** Rate a content piece, can either be UPVOTE, DOWNVOTE, UNDETERMINED */
  rateContent: ContentRating;
  /**
   * Reauthorize with organisation will create a new JWT associated to the provided organisation ID if the user has
   * the permissioned to do so
   */
  reauthorizeWithOrg: AuthenticationResponse;
  /**
   * Refresh the users for an audience
   *
   * If async is true then the refresh will be done in the background and the audience will be returned immediately
   */
  refreshAudienceUsers: Audience;
  /** refreshToken will create a new JWT and attach updated user value to jwt token */
  refreshToken: AuthenticationResponse;
  /** Register a new follow topic channel tab */
  registerFollowTopicTab: MSteamsChannelTabFollowTopic;
  removeAttendee?: Maybe<Event>;
  /** removes a user from the cohort, can be removed by creator or admins */
  removeCohortUser: Cohort;
  /** Remove a host from the live stream */
  removeHost: Stream;
  /** remove users from IAM role */
  removeIAMRoleUsers?: Maybe<IamRoleUsersResult>;
  /** Revoke the invitation for a user to join an organisation */
  removeUserOrgInvitation: NoContent;
  /** Report the content on the platform for a moderator to review */
  reportContent: Scalars['Boolean'];
  /** Connect a user with a mentor */
  requestMentorship: NoContent;
  /** Resend the invitation email to existing user that was not part of the organisation */
  resendUserInvitationEmail: NoContent;
  resetPasswordFinalize: NoContent;
  resetPasswordInitiate: NoContent;
  /** This mutation to reset given poll question answer so that user can change it's answer later */
  resetPollQuestionAnswer: Scalars['Boolean'];
  /** This mutation is used to track clicks on content based on the recommendation id  and query string from a search result when using contentCards */
  searchResultClicked: Scalars['Boolean'];
  /** updates the featured host */
  setFeaturedHost: Stream;
  /** Mark the user as either archived or unarchive it */
  setUserArchivedState: User;
  /** Block a user from viewing your profile, feed or any other actions */
  setUserBlockedState: User;
  skipSubscriptionTrial?: Maybe<User>;
  /** Start stream, This should be called after creating a live stream once we have figured out the Agora UID */
  startStream: Stream;
  /** Stop stream, This should be called to stop a live stream */
  stopStream: Scalars['Boolean'];
  /**
   * streamGoLive, This should be called after starting live stream and when hosts are in holding area
   * and ready to go live
   */
  streamGoLive: Stream;
  /** Send a chat message to a live stream */
  streamPostMessage: Scalars['Boolean'];
  /** Allow submitting suggestions to the content / marketing team */
  suggestNewPoll: Scalars['Boolean'];
  /** Swap the order of two hosts in a stream */
  swapHost: Stream;
  /** Create teams organisation with the existing user as org admin, if no individual active subscription exists for the user */
  switchUserToTeams?: Maybe<CreateTeamsResult>;
  toggleContentAsArchived: NoContent;
  /** @deprecated we no longer support free content */
  toggleContentAsFree: NoContent;
  /** @deprecated we no longer support free content */
  toggleLearningPathAsFree: NoContent;
  /**
   * toggleLike like a activity base on type and return activity in return
   * this works as generic function to like any activity
   * right now supports comment and feed will extends this mutation further in future
   */
  toggleLike: LikeableContent;
  /** Toggle if you should receive notifications a certain type of notifications on a specific content piece */
  toggleMuteNotification: NotificationPreferenceState;
  /**
   * Toggle mute on an entire notification type
   *
   * The optional userID value can only be passed by admins and will override
   */
  toggleNotificationPreference?: Maybe<UserNotificationPreference>;
  /** referenceId is either the commentId OR postId OR feedActivityId */
  toggleReact: ReactionsSummary;
  /**
   * toggleUserAsBillable toggles user as billable/non-billable
   * billable means this users will be counted towards the number of licences(user count) set during contract
   */
  toggleUserAsBillable: User;
  /** Toggle a user as an expert */
  toggleUserAsExpert: User;
  toggleWaitListAttendee: EventWaitListAttendee;
  /**
   * translateEbook translates ebook from sourceLanguageCode to targetLanguageCodes
   * targetLanguageCodes if sent ebook will be translated only in those languages and if not sent ebook will be translated in all the supported languages by us
   */
  translateEbook: Scalars['Boolean'];
  /**
   * translatePodcastTranscript translates podcast transcript in English to targetLanguageCodes
   * targetLanguageCodes if sent, english transcript will be translated only in those languages and if not sent it will be translated in all the supported languages by us
   * voiceType if sent, create the audio for languages sent in targetLanguageCodes
   */
  translatePodcastTranscript: Scalars['Boolean'];
  /**
   * translateStreamSubtitles translates stream subtitles to targetLanguageCodes
   * targetLanguageCodes if sent stream subtitles will be translated only in those languages and if not sent it will be translated in all the supported languages by us
   */
  translateStreamSubtitles: Scalars['Boolean'];
  /** For the users in an organisation we need to trigger the onboarding canvas */
  triggerBrazeCanvas: Scalars['Boolean'];
  unblockUserAuth: NoContent;
  /**
   * Unregister a follow topic channel tab
   *
   * Note: the id is the same as the channels entityId
   */
  unregisterFollowTopicTab: Scalars['Boolean'];
  updateAssignment: Assignment;
  updateAssignmentStatus: Assignment;
  /** Update an existing audience */
  updateAudience: Audience;
  /** Update the refresh interval without passing all of the data */
  updateAudienceRefreshInterval: Audience;
  updateCanvasOnboardingID: Organisation;
  updateCategory?: Maybe<Category>;
  updateCategoryIndex: NoContent;
  /** updates the invitation status of cohort */
  updateCohortInvitationStatus: Cohort;
  /** update a comment */
  updateComment: Comment;
  /** Update an existing content user note */
  updateContentUserNote: ContentUserNote;
  updateCoupon?: Maybe<Coupon>;
  updateEbook?: Maybe<UpdateEbookResult>;
  updateEvent?: Maybe<Event>;
  updateFeaturedContent?: Maybe<FeaturedContent>;
  /** Update the topics of a follow topic channel tab */
  updateFollowTopicTab: MSteamsChannelTabFollowTopic;
  /** update an existing iam role */
  updateIAMRole?: Maybe<UpdateIamRoleResult>;
  /** Updates alt tag of image */
  updateImageAltTag?: Maybe<Image>;
  updateLearningPath?: Maybe<LearningPath>;
  updateLifegoal?: Maybe<Lifegoal>;
  /**
   * To update livestream details while it's in progress i.e backstage and it publish the updated changes of stream which can be subsribed to
   * via ongoingStreams or streams subscription
   */
  updateLiveStream?: Maybe<Stream>;
  updateOrganisation?: Maybe<Organisation>;
  updateOrganisationGroup?: Maybe<OrganisationGroup>;
  updatePodcast?: Maybe<UpdatePodcastResult>;
  /** This mutation is used to update poll question */
  updatePollQuestion: PollQuestionResponse;
  /** @deprecated Use updatePostV2 instead */
  updatePost: Scalars['Boolean'];
  updatePostV2: UpdatePostV2Result;
  /** Update a stream, should only be used for updating properties of a recorded stream */
  updateStream?: Maybe<UpdateStreamResult>;
  /**
   * updates the livestream creator. Super admin / creator can pass the creator rights to other host.
   * Once the creator is updated, the previous creator can even leave the stream if they want to
   */
  updateStreamCreator: Stream;
  updateSubscriptionPlan?: Maybe<SubscriptionPlan>;
  /** Update user is called from the application to update the users profile */
  updateUser?: Maybe<User>;
  /** Introduce a new method that allows us to update a user and thinkfluencer settings in the same request */
  updateUserAsAdmin: User;
  /** Update a user as an org admin gives the ability to promote to a livestream host and thinkfluencer */
  updateUserAsOrgAdmin: User;
  /** Update the users avatar image, if null is provided for the image it's removed */
  updateUserAvatarImage: User;
  /** Update the users cover image, if null is provided for the image it's removed */
  updateUserCoverImage: User;
  /** Update a users life goals */
  updateUserLifegoals: NoContent;
  /** updateUserPreferredLanguage updates the preferred language of a user. */
  updateUserPreferredLanguage?: Maybe<User>;
  /** Updates a user's product tour tracking */
  updateUserProductTourTracking: ProductTourTracking;
  /** Upload an image */
  uploadImage?: Maybe<Image>;
  upsertContentRating: NoContent;
  upsertEbookChaptersAndAudio?: Maybe<UpsertEbookChaptersAndAudioResult>;
  upsertPodcastAudioAndTranscript?: Maybe<UpsertPodcastAudioAndTranscriptResult>;
  /** Create / Update stream's media / subtitle. */
  upsertStreamMedia?: Maybe<UpsertStreamMediaResult>;
  /** Updates a user's accepted community guideline */
  userAcceptCommunityGuideline: Scalars['Boolean'];
  userCancelSubscription?: Maybe<User>;
  userChangePassword: NoContent;
  userChangePaymentToken?: Maybe<User>;
  userChangeSubscription?: Maybe<User>;
  /** userInAppSubscription verify in-app purchase and store transaction details in our system */
  userInAppSubscription: NoContent;
  /** @deprecated Replaced by userLearningPathToggleBookmarkedV2 that sends back the learningPath */
  userLearningPathToggleBookmarked?: Maybe<UserCollectionTracking>;
  userLearningPathToggleBookmarkedV2: LearningPath;
  userMarkCollectionAsFinished: NoContent;
  userMarkContentAsFinished: ContentCard;
  /** Updates a user's onboarding completed status */
  userSetOnboardingCompleted: User;
  userSetPassword: Scalars['Boolean'];
  userSubscribeToPlan: NoContent;
  /** @deprecated replaced by setUserArchivedState */
  userToggleBlockedState?: Maybe<User>;
  /** This mutation is used to follow or unfollow another user */
  userToggleFollow: User;
  /**
   * Toggle following a collection allows the user to toggle the state of following a collection
   *
   * The third variable "force" is optional and if provided will set the toggle state to that value
   */
  userToggleFollowCollection?: Maybe<UserCollectionTracking>;
  /** @deprecated use the follow user mutation instead */
  userToggleFollowExpert?: Maybe<Expert>;
  userTrackContentOpened: ContentCard;
  userTrackContentProgress: ContentCard;
  userTrackContentToggleAttending: ContentCard;
  userTrackContentToggleBookmarked: ContentCard;
  /** @deprecated It does not actually toggle, instead use userMarkContentAsFinished mutation */
  userTrackContentToggleFinished: ContentCard;
};

export type MutationAddAnswerToPollQuestionArgs = {
  input: PollQuestionAnswer;
};

export type MutationAddAnswerToPollQuestionV2Args = {
  input: PollQuestionAnswer;
};

export type MutationAddCohortMembersArgs = {
  cohortId: Scalars['ID'];
  users: Array<Scalars['ID']>;
};

export type MutationAddHostArgs = {
  streamId: Scalars['ID'];
  uid: Scalars['Int'];
};

export type MutationAddIamRoleUsersArgs = {
  id: Scalars['ID'];
  input: AddIamRoleUsersInput;
};

export type MutationApplyToEventWaitListArgs = {
  input?: InputMaybe<ApplyToEventWaitListInput>;
};

export type MutationCancelOrganisationSubscriptionArgs = {
  id: Scalars['ID'];
};

export type MutationCancelWaitlistApplicationArgs = {
  eventId: Scalars['String'];
};

export type MutationChangeBroadcastConfigurationArgs = {
  configuration: ManualVideoBroadcastConfigurationInput;
  id: Scalars['ID'];
};

export type MutationChangeStreamActiveAgendaTopicArgs = {
  agendaIndex: Scalars['Int'];
  streamId: Scalars['ID'];
};

export type MutationChangeStreamStatusArgs = {
  id: Scalars['ID'];
  status: StreamStatus;
};

export type MutationConsumeCouponArgs = {
  code: Scalars['String'];
};

export type MutationConvertEbookTextToAudioArgs = {
  id: Scalars['ID'];
  voiceType: SpeechVoiceType;
};

export type MutationConvertPodcastSummaryToAudioArgs = {
  id: Scalars['ID'];
  voiceType: SpeechVoiceType;
};

export type MutationCreateAccountMsTeamsArgs = {
  accessToken: Scalars['String'];
  organisationName: Scalars['String'];
};

export type MutationCreateAssignmentArgs = {
  input?: InputMaybe<AssignmentInput>;
  organisationID: Scalars['ID'];
};

export type MutationCreateAudienceArgs = {
  input: CreateAudienceInput;
};

export type MutationCreateCategoryArgs = {
  input?: InputMaybe<CategoryInput>;
};

export type MutationCreateChatMessageArgs = {
  id: Scalars['ID'];
  mentions?: InputMaybe<Array<InputMaybe<MentionInput>>>;
  message?: InputMaybe<Scalars['String']>;
  rawText?: InputMaybe<Scalars['String']>;
};

export type MutationCreateCohortArgs = {
  input: CreateCohortInput;
};

export type MutationCreateCommentArgs = {
  comment: PostComment;
};

export type MutationCreateContentUserNoteArgs = {
  contentId: Scalars['ID'];
  input?: InputMaybe<CreateContentUserNote>;
};

export type MutationCreateCouponArgs = {
  input?: InputMaybe<CouponInput>;
};

export type MutationCreateEbookArgs = {
  input: EbookInput;
};

export type MutationCreateEventArgs = {
  input: EventInput;
};

export type MutationCreateFeaturedContentArgs = {
  input?: InputMaybe<FeaturedContentInput>;
};

export type MutationCreateGalleryImageFromPreSignedUrlArgs = {
  url: Scalars['String'];
};

export type MutationCreateIamRoleArgs = {
  input?: InputMaybe<IamRoleInput>;
};

export type MutationCreateInstantRepostArgs = {
  postId: Scalars['ID'];
};

export type MutationCreateLearningPathArgs = {
  input?: InputMaybe<LearningPathInput>;
};

export type MutationCreateLifegoalArgs = {
  input?: InputMaybe<LifegoalInput>;
};

export type MutationCreateLiveStreamArgs = {
  featuredHost?: InputMaybe<Scalars['ID']>;
  invitedHosts: Array<Scalars['ID']>;
  name: Scalars['String'];
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  organisationID?: InputMaybe<Scalars['ID']>;
};

export type MutationCreateLiveStreamFromEventArgs = {
  eventID: Scalars['ID'];
  featuredHost?: InputMaybe<Scalars['ID']>;
  invitedHosts: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type MutationCreateOrganisationArgs = {
  input: OrganisationInput;
};

export type MutationCreateOrganisationGroupArgs = {
  input: CreateOrganisationGroupInput;
  orgId: Scalars['String'];
};

export type MutationCreatePodcastArgs = {
  input: PodcastInput;
};

export type MutationCreatePollQuestionArgs = {
  input: PollQuestionInput;
};

export type MutationCreatePostArgs = {
  input: CreatePostInput;
};

export type MutationCreateStreamArgs = {
  input: StreamInput;
};

export type MutationCreateStripePromoCodeArgs = {
  input: StripePromotionCodeInput;
};

export type MutationCreateSubscriptionPlanArgs = {
  input?: InputMaybe<SubscriptionPlanInput>;
};

export type MutationCreateTeamsAccountArgs = {
  input?: InputMaybe<CreateTeamsInput>;
};

export type MutationCreateUserArgs = {
  input?: InputMaybe<CreateUserInput>;
};

export type MutationCreateUserV2Args = {
  input?: InputMaybe<CreateUserInput>;
};

export type MutationDeactivateStripePromoCodeArgs = {
  id: Scalars['String'];
};

export type MutationDeleteAssignmentArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCategoryArgs = {
  id: Scalars['String'];
};

export type MutationDeleteCommentArgs = {
  comment: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteContentUserNoteArgs = {
  noteId: Scalars['ID'];
};

export type MutationDeleteCouponArgs = {
  code: Scalars['String'];
};

export type MutationDeleteEbookArgs = {
  id: Scalars['String'];
};

export type MutationDeleteEventArgs = {
  id: Scalars['String'];
};

export type MutationDeleteFeaturedContentArgs = {
  id: Scalars['String'];
};

export type MutationDeleteIamRoleArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteImageArgs = {
  id: Scalars['String'];
};

export type MutationDeleteLearningPathArgs = {
  id: Scalars['String'];
};

export type MutationDeleteLifegoalArgs = {
  id: Scalars['String'];
};

export type MutationDeleteNotificationArgs = {
  id: Scalars['String'];
};

export type MutationDeleteOrganisationArgs = {
  id: Scalars['String'];
};

export type MutationDeleteOrganisationGroupArgs = {
  id: Scalars['String'];
};

export type MutationDeletePodcastArgs = {
  id: Scalars['String'];
};

export type MutationDeletePollQuestionArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePostArgs = {
  post: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteStreamArgs = {
  id: Scalars['String'];
};

export type MutationDeleteSubscriptionPlanArgs = {
  id: Scalars['String'];
};

export type MutationDeleteUserArgs = {
  id?: InputMaybe<Scalars['String']>;
  reasons?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationDisbandCohortArgs = {
  cohortId: Scalars['ID'];
};

export type MutationEmailExistsArgs = {
  email: Scalars['String'];
};

export type MutationExportActivationByUserReportArgs = {
  filter: ActivationByUserReportFilter;
};

export type MutationExportActivationOverviewReportArgs = {
  filter: ActivationOverviewReportFilter;
};

export type MutationExportAssignmentStatisticsArgs = {
  filter: AssignmentStatisticsFilterInput;
  id: Scalars['ID'];
};

export type MutationExportContentStatisticsReportArgs = {
  filter: ContentStatisticsReportFilter;
};

export type MutationExportEngagementReportArgs = {
  filter: EngagementReportFilter;
};

export type MutationExportUserAssignmentStatisticsReportArgs = {
  filter: UserAssignmentStatisticsReportFilter;
};

export type MutationExportUserCommentsReportArgs = {
  filter: UserCommentsReportFilter;
};

export type MutationExportUserContentStatisticsReportArgs = {
  filter: UserContentStatisticsReportFilter;
};

export type MutationExportUserTrailStatisticsReportArgs = {
  filter: UserTrailStatisticsReportFilter;
};

export type MutationGeneratePodcastTranscriptArgs = {
  id: Scalars['ID'];
  translateTranscripts: Scalars['Boolean'];
};

export type MutationGenerateStreamSubtitlesArgs = {
  id: Scalars['ID'];
  translateSubtitles: Scalars['Boolean'];
};

export type MutationGetPreSignedUploadUrlArgs = {
  contentType: Scalars['String'];
  name: Scalars['String'];
};

export type MutationInviteHostArgs = {
  hostID: Scalars['ID'];
  streamID: Scalars['ID'];
};

export type MutationInvitesAddHostsArgs = {
  hosts: Array<Scalars['ID']>;
  streamID: Scalars['ID'];
};

export type MutationInvitesRejectArgs = {
  streamID: Scalars['ID'];
};

export type MutationIsAppUp2DateArgs = {
  appVersion: Scalars['String'];
};

export type MutationIsCouponValidArgs = {
  coupon: Scalars['String'];
  planId: Scalars['String'];
};

export type MutationIsSsoEnabledArgs = {
  email: Scalars['String'];
};

export type MutationLeaveCohortArgs = {
  cohortId: Scalars['ID'];
};

export type MutationLoginAppleArgs = {
  authorizationCode: Scalars['String'];
  customBundleId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type MutationLoginEmailArgs = {
  backoffice?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationLoginEmailV2Args = {
  backoffice?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationLoginFbArgs = {
  token: Scalars['String'];
};

export type MutationLoginLinkedInArgs = {
  redirectUri?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type MutationLoginMsTeamsArgs = {
  accessToken: Scalars['String'];
};

export type MutationManageUserDevicesArgs = {
  deviceId: Scalars['String'];
  operation: DeviceOperation;
};

export type MutationMarkAllNotificationReadArgs = {
  filter?: InputMaybe<NotificationFilter>;
};

export type MutationMarkAssignmentsAsViewedArgs = {
  assignmentIds: Array<Scalars['ID']>;
};

export type MutationMarkNotificationReadArgs = {
  id: Scalars['ID'];
};

export type MutationNudgeCohortUserArgs = {
  cohortId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationOrganisationAddUserArgs = {
  level?: InputMaybe<SubscriptionLevel>;
  organisationId: Scalars['String'];
  role: OrganisationRole;
  userId: Scalars['String'];
};

export type MutationOrganisationAdminNudgeAllUsersArgs = {
  organisationId: Scalars['ID'];
};

export type MutationOrganisationAdminNudgeUserArgs = {
  organisationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationOrganisationExportAssignmentStatisticsByAssignmentArgs = {
  filter: StatisticsByAssignmentFilter;
  orgId: Scalars['ID'];
};

export type MutationOrganisationExportAssignmentStatisticsByGroupArgs = {
  filter: StatisticsByGroupFilter;
  orgId: Scalars['ID'];
};

export type MutationOrganisationExportAssignmentStatisticsByUserArgs = {
  filter: StatisticsByUserFilter;
  orgId: Scalars['ID'];
};

export type MutationOrganisationExportMainStatisticsArgs = {
  filter: OrgStatisticsFilter;
  orgId: Scalars['ID'];
};

export type MutationOrganisationExportUserAssignmentStatisticsArgs = {
  orgId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationOrganisationGroupAddMultiUserArgs = {
  groupId: Scalars['ID'];
  users: Array<AddUserRequest>;
};

export type MutationOrganisationGroupAddMultipleUserArgs = {
  groupId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type MutationOrganisationGroupAddUserArgs = {
  groupId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationOrganisationGroupRemoveMultiUserArgs = {
  groupId: Scalars['ID'];
  users: Array<AddUserRequest>;
};

export type MutationOrganisationGroupRemoveUserArgs = {
  groupId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationOrganisationImportUsersArgs = {
  groupId?: InputMaybe<Scalars['String']>;
  orgId: Scalars['String'];
  subscription?: InputMaybe<SubscriptionLevel>;
  users: Array<OrganisationUserImport>;
};

export type MutationOrganisationJoinViaInviteLinkArgs = {
  token: Scalars['ID'];
  user?: InputMaybe<CreateUserInput>;
};

export type MutationOrganisationRemoveMultiUserArgs = {
  organisationId: Scalars['ID'];
  users: Array<AddUserRequest>;
};

export type MutationOrganisationRemoveUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  organisationId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type MutationOrganisationSubscriptionArgs = {
  input: OrganisationSubscriptionInput;
};

export type MutationRateContentArgs = {
  contentId: Scalars['ID'];
  vote: ContentVote;
};

export type MutationReauthorizeWithOrgArgs = {
  id: Scalars['ID'];
};

export type MutationRefreshAudienceUsersArgs = {
  async?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type MutationRegisterFollowTopicTabArgs = {
  channelId: Scalars['String'];
  teamId: Scalars['String'];
  topics: Array<Scalars['ID']>;
};

export type MutationRemoveAttendeeArgs = {
  eventId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationRemoveCohortUserArgs = {
  cohortId?: InputMaybe<Scalars['ID']>;
  userIds: Array<Scalars['ID']>;
};

export type MutationRemoveHostArgs = {
  hostId?: InputMaybe<Scalars['ID']>;
  streamId: Scalars['ID'];
  uid: Scalars['Int'];
};

export type MutationRemoveIamRoleUsersArgs = {
  id: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};

export type MutationRemoveUserOrgInvitationArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationReportContentArgs = {
  comment?: InputMaybe<Scalars['String']>;
  contentID?: InputMaybe<Scalars['ID']>;
  contentType: ReportableContentType;
  reason: Scalars['String'];
  reportableContentID?: InputMaybe<Scalars['ID']>;
};

export type MutationRequestMentorshipArgs = {
  expertId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type MutationResendUserInvitationEmailArgs = {
  invitationIds: Array<Scalars['ID']>;
  orgId: Scalars['ID'];
};

export type MutationResetPasswordFinalizeArgs = {
  input: ResetPasswordFinalizeInput;
};

export type MutationResetPasswordInitiateArgs = {
  email: Scalars['String'];
};

export type MutationResetPollQuestionAnswerArgs = {
  questionId: Scalars['ID'];
};

export type MutationSearchResultClickedArgs = {
  input: ClickAnalyticsInput;
};

export type MutationSetFeaturedHostArgs = {
  hostId: Scalars['ID'];
  streamId: Scalars['ID'];
};

export type MutationSetUserArchivedStateArgs = {
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
};

export type MutationSetUserBlockedStateArgs = {
  id: Scalars['ID'];
  isBlocked: Scalars['Boolean'];
};

export type MutationSkipSubscriptionTrialArgs = {
  userId?: InputMaybe<Scalars['String']>;
};

export type MutationStartStreamArgs = {
  goToBackStage?: InputMaybe<Scalars['Boolean']>;
  streamId: Scalars['ID'];
  uid: Scalars['Int'];
};

export type MutationStopStreamArgs = {
  streamId: Scalars['ID'];
};

export type MutationStreamGoLiveArgs = {
  streamId: Scalars['ID'];
  uid: Scalars['Int'];
};

export type MutationStreamPostMessageArgs = {
  input?: InputMaybe<StreamChatMessageInput>;
};

export type MutationSuggestNewPollArgs = {
  input: PollSuggestion;
};

export type MutationSwapHostArgs = {
  newOrder: Scalars['Int'];
  order: Scalars['Int'];
  streamId: Scalars['ID'];
};

export type MutationToggleContentAsArchivedArgs = {
  contentType: ContentType;
  id: Scalars['ID'];
};

export type MutationToggleContentAsFreeArgs = {
  contentType: ContentType;
  id: Scalars['ID'];
};

export type MutationToggleLearningPathAsFreeArgs = {
  id: Scalars['ID'];
};

export type MutationToggleLikeArgs = {
  referenceId: Scalars['ID'];
  type: LikeType;
};

export type MutationToggleMuteNotificationArgs = {
  contentId: Scalars['ID'];
  type: NotificationType;
};

export type MutationToggleNotificationPreferenceArgs = {
  type: NotificationType;
  userID?: InputMaybe<Scalars['ID']>;
};

export type MutationToggleReactArgs = {
  reactionRefType: ReactionReferenceType;
  reactionType: ReactionType;
  referenceId: Scalars['ID'];
};

export type MutationToggleUserAsBillableArgs = {
  id: Scalars['ID'];
};

export type MutationToggleUserAsExpertArgs = {
  id: Scalars['ID'];
};

export type MutationToggleWaitListAttendeeArgs = {
  id: Scalars['String'];
};

export type MutationTranslateEbookArgs = {
  id: Scalars['ID'];
  sourceLanguageCode: Scalars['String'];
  targetLanguageCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationTranslatePodcastTranscriptArgs = {
  id: Scalars['ID'];
  targetLanguageCodes?: InputMaybe<Array<Scalars['String']>>;
  voiceType?: InputMaybe<SpeechVoiceType>;
};

export type MutationTranslateStreamSubtitlesArgs = {
  id: Scalars['ID'];
  targetLanguageCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationTriggerBrazeCanvasArgs = {
  canvasId: Scalars['ID'];
  canvasType: CanvasType;
  groupIds: Array<Scalars['ID']>;
  organisationId: Scalars['ID'];
};

export type MutationUnblockUserAuthArgs = {
  id: Scalars['ID'];
};

export type MutationUnregisterFollowTopicTabArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateAssignmentArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<AssignmentInput>;
};

export type MutationUpdateAssignmentStatusArgs = {
  id: Scalars['ID'];
  status: AssignmentStatus;
};

export type MutationUpdateAudienceArgs = {
  id: Scalars['ID'];
  input: UpdateAudienceInput;
};

export type MutationUpdateAudienceRefreshIntervalArgs = {
  id: Scalars['ID'];
  triggerFrequency: TriggerFrequency;
  weeklyTriggerDays?: InputMaybe<Array<WeeklyTriggerDay>>;
};

export type MutationUpdateCanvasOnboardingIdArgs = {
  canvasOnboardingID: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationUpdateCategoryArgs = {
  id: Scalars['String'];
  input?: InputMaybe<CategoryInput>;
};

export type MutationUpdateCategoryIndexArgs = {
  input: Array<CategoryIndexInput>;
};

export type MutationUpdateCohortInvitationStatusArgs = {
  cohortId: Scalars['ID'];
  status: CohortInviteStatus;
};

export type MutationUpdateCommentArgs = {
  update: UpdateComment;
};

export type MutationUpdateContentUserNoteArgs = {
  input?: InputMaybe<UpdateContentUserNote>;
  noteId: Scalars['ID'];
};

export type MutationUpdateCouponArgs = {
  code: Scalars['String'];
  input?: InputMaybe<CouponInput>;
};

export type MutationUpdateEbookArgs = {
  id: Scalars['String'];
  input: EbookInput;
};

export type MutationUpdateEventArgs = {
  id: Scalars['String'];
  input: EventInput;
};

export type MutationUpdateFeaturedContentArgs = {
  id: Scalars['String'];
  input?: InputMaybe<FeaturedContentInput>;
};

export type MutationUpdateFollowTopicTabArgs = {
  id: Scalars['ID'];
  topics: Array<Scalars['ID']>;
};

export type MutationUpdateIamRoleArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<IamRoleInput>;
};

export type MutationUpdateImageAltTagArgs = {
  alt: Scalars['String'];
  id: Scalars['ID'];
};

export type MutationUpdateLearningPathArgs = {
  id: Scalars['String'];
  input?: InputMaybe<LearningPathInput>;
};

export type MutationUpdateLifegoalArgs = {
  id: Scalars['String'];
  input?: InputMaybe<LifegoalInput>;
};

export type MutationUpdateLiveStreamArgs = {
  id: Scalars['ID'];
  input: LiveStreamInput;
};

export type MutationUpdateOrganisationArgs = {
  id: Scalars['String'];
  input: OrganisationInput;
};

export type MutationUpdateOrganisationGroupArgs = {
  id: Scalars['String'];
  input: UpdateOrganisationGroupInput;
};

export type MutationUpdatePodcastArgs = {
  id: Scalars['String'];
  input: PodcastInput;
};

export type MutationUpdatePollQuestionArgs = {
  id: Scalars['ID'];
  input: PollQuestionInput;
};

export type MutationUpdatePostArgs = {
  input: UpdatePostInput;
};

export type MutationUpdatePostV2Args = {
  input: UpdatePostInput;
};

export type MutationUpdateStreamArgs = {
  id: Scalars['String'];
  input: StreamInput;
};

export type MutationUpdateStreamCreatorArgs = {
  newCreatorId: Scalars['ID'];
  streamId: Scalars['ID'];
};

export type MutationUpdateSubscriptionPlanArgs = {
  id: Scalars['String'];
  input?: InputMaybe<SubscriptionPlanInput>;
};

export type MutationUpdateUserArgs = {
  id: Scalars['String'];
  input?: InputMaybe<UpdateUserInput>;
};

export type MutationUpdateUserAsAdminArgs = {
  id: Scalars['ID'];
  input: UpdateExpertUserInput;
};

export type MutationUpdateUserAsOrgAdminArgs = {
  id: Scalars['ID'];
  input: UpdateUserAsOrgAdmin;
};

export type MutationUpdateUserAvatarImageArgs = {
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MutationUpdateUserCoverImageArgs = {
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MutationUpdateUserLifegoalsArgs = {
  lifeGoals: Array<Scalars['ID']>;
};

export type MutationUpdateUserPreferredLanguageArgs = {
  language: Scalars['String'];
};

export type MutationUpdateUserProductTourTrackingArgs = {
  input: UpdateUserProductTourTrackingInput;
};

export type MutationUploadImageArgs = {
  alt?: InputMaybe<Scalars['String']>;
  image: Scalars['Upload'];
};

export type MutationUpsertContentRatingArgs = {
  input: ContentRatingInput;
};

export type MutationUpsertEbookChaptersAndAudioArgs = {
  audioInput?: InputMaybe<EbookAudioInput>;
  chaptersInput?: InputMaybe<UpsertEbookChaptersInput>;
  ebookId: Scalars['ID'];
  language: Scalars['String'];
};

export type MutationUpsertPodcastAudioAndTranscriptArgs = {
  audio?: InputMaybe<PodcastAudioInput>;
  language: Scalars['String'];
  podcastId: Scalars['ID'];
  transcript?: InputMaybe<PodcastTranscriptInput>;
};

export type MutationUpsertStreamMediaArgs = {
  input: UpsertStreamMediaInput;
};

export type MutationUserCancelSubscriptionArgs = {
  reasons: Array<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MutationUserChangePasswordArgs = {
  id: Scalars['String'];
  input: UserChangePasswordInput;
};

export type MutationUserChangePaymentTokenArgs = {
  stripeToken: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type MutationUserChangeSubscriptionArgs = {
  coupon?: InputMaybe<Scalars['String']>;
  planId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type MutationUserInAppSubscriptionArgs = {
  input: UserInAppSubscriptionInput;
};

export type MutationUserLearningPathToggleBookmarkedArgs = {
  id: Scalars['ID'];
};

export type MutationUserLearningPathToggleBookmarkedV2Args = {
  id: Scalars['ID'];
};

export type MutationUserMarkCollectionAsFinishedArgs = {
  collection: UserCollectionTrackingInput;
};

export type MutationUserMarkContentAsFinishedArgs = {
  contentMetadata?: InputMaybe<ContentMetadata>;
  id: Scalars['ID'];
};

export type MutationUserSetPasswordArgs = {
  id: Scalars['ID'];
  password: Scalars['String'];
};

export type MutationUserSubscribeToPlanArgs = {
  coupon?: InputMaybe<Scalars['String']>;
  planId: Scalars['String'];
  stripeToken: Scalars['String'];
  trial?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MutationUserToggleBlockedStateArgs = {
  id: Scalars['String'];
};

export type MutationUserToggleFollowArgs = {
  personToFollow: Scalars['ID'];
};

export type MutationUserToggleFollowCollectionArgs = {
  collectionType: CollectionType;
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type MutationUserToggleFollowExpertArgs = {
  expertId: Scalars['String'];
  id: Scalars['String'];
};

export type MutationUserTrackContentOpenedArgs = {
  id: Scalars['String'];
  type?: InputMaybe<ContentType>;
};

export type MutationUserTrackContentProgressArgs = {
  contentMetadata?: InputMaybe<ContentMetadata>;
  data: TrackingData;
  id: Scalars['String'];
  type?: InputMaybe<ContentType>;
};

export type MutationUserTrackContentToggleAttendingArgs = {
  id: Scalars['String'];
};

export type MutationUserTrackContentToggleBookmarkedArgs = {
  id: Scalars['String'];
  type?: InputMaybe<ContentType>;
};

export type MutationUserTrackContentToggleFinishedArgs = {
  id: Scalars['String'];
  type?: InputMaybe<ContentType>;
};

export type NoContent = {
  __typename: 'NoContent';
  success: Scalars['Boolean'];
};

export type NotFoundError = {
  __typename: 'NotFoundError';
  message: Scalars['String'];
};

export type NotesConnection = {
  __typename: 'NotesConnection';
  edges: Array<ContentUserNote>;
  meta: PageInfo;
};

export type Notification = {
  __typename: 'Notification';
  body: Scalars['String'];
  createdAt: Scalars['Time'];
  deepLink: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Image>;
  readAt?: Maybe<Scalars['Time']>;
  state: NotificationState;
  title: Scalars['String'];
  type?: Maybe<NotificationType>;
  updatedAt: Scalars['Time'];
};

export type NotificationConnection = {
  __typename: 'NotificationConnection';
  edges: Array<NotificationEdge>;
  meta: CursorInfo;
};

export type NotificationEdge = {
  __typename: 'NotificationEdge';
  cursor: Scalars['String'];
  notification: Notification;
};

export type NotificationFilter = {
  viewerType?: InputMaybe<NotificationViewerType>;
};

export enum NotificationPlatform {
  Email = 'EMAIL',
  PushNotification = 'PUSH_NOTIFICATION'
}

export enum NotificationPreferenceState {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export enum NotificationState {
  New = 'NEW',
  Read = 'READ',
  Unread = 'UNREAD'
}

export enum NotificationType {
  Assignment = 'ASSIGNMENT',
  ChatMessages = 'CHAT_MESSAGES',
  CohortInvitation = 'COHORT_INVITATION',
  CohortInvitationAccepted = 'COHORT_INVITATION_ACCEPTED',
  CohortInvitationRejected = 'COHORT_INVITATION_REJECTED',
  Comment = 'COMMENT',
  Follow = 'FOLLOW',
  GeneralNewsletter = 'GENERAL_NEWSLETTER',
  Like = 'LIKE',
  LivestreamInvitedAsHost = 'LIVESTREAM_INVITED_AS_HOST',
  LivestreamIsLive = 'LIVESTREAM_IS_LIVE',
  MarketingCommunications = 'MARKETING_COMMUNICATIONS',
  OrganisationInvitationAccepted = 'ORGANISATION_INVITATION_ACCEPTED',
  OrganisationInvitationRejected = 'ORGANISATION_INVITATION_REJECTED',
  ProductUpdates = 'PRODUCT_UPDATES',
  ReplyOnComment = 'REPLY_ON_COMMENT',
  ThinkfluencerNewContent = 'THINKFLUENCER_NEW_CONTENT'
}

export enum NotificationViewerType {
  All = 'ALL',
  Mobile = 'MOBILE',
  Web = 'WEB'
}

export type OffsetPagination = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type OnboardingQuestionsFilter = {
  /** Filter on questions shown for a specific purpose */
  purpose?: InputMaybe<ShowOnScreenType>;
};

/**
 * Filter users by their status
 *
 * In all likely hood, this will be removed in the future and replaced by a UserStatus enum
 */
export enum OrgMemberStatus {
  /** @deprecated Use enabled instead */
  Active = 'ACTIVE',
  /**
   * Don't filter on any specific status
   *
   * If no value is specified
   */
  All = 'ALL',
  /** The user has been blocked from accessing the application, they can be unblocked */
  Archived = 'ARCHIVED',
  /** @deprecated Use archived instead */
  Blocked = 'BLOCKED',
  /** Default status, means the user has a valid account and they can login */
  Enabled = 'ENABLED',
  /** @deprecated will be removed in the future */
  PendingActivation = 'PENDING_ACTIVATION'
}

export type OrgStatisticsFilter = {
  frequency?: InputMaybe<OrgStatsGroupingFrequency>;
  from?: InputMaybe<Scalars['Time']>;
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  until?: InputMaybe<Scalars['Time']>;
};

export enum OrgStatsGroupingFrequency {
  Day = 'day',
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year'
}

export type OrgStatsMostActiveUser = {
  __typename: 'OrgStatsMostActiveUser';
  averageCompletionRate: Scalars['Float'];
  startedContentPieces: Scalars['Int'];
  timeSpentInSeconds: Scalars['Int'];
  userId: Scalars['ID'];
  username: Scalars['String'];
};

export type OrgStatsMostPopularContent = {
  __typename: 'OrgStatsMostPopularContent';
  averageCompletionRate: Scalars['Float'];
  contentId: Scalars['ID'];
  contentName: Scalars['String'];
  contentType: ContentType;
  numberOfUsers: Scalars['Int'];
  timeSpentInSeconds: Scalars['Int'];
};

export type Organisation = {
  __typename: 'Organisation';
  /**
   * activeUsers show non blocked users of organisation
   * @deprecated use organisation.members.meta.total instead
   */
  activeUsers: Scalars['Int'];
  /**
   * The users are that marked as org admins are available here
   * @deprecated use iamRoles to find users with different permissions
   */
  admins: Array<User>;
  /**
   * Get the statistics for an organisation grouped by content
   * @deprecated use the new assignment statistics query (assignmentStatisticsByAssignment)
   */
  assignmentContentStatistics: Array<AssignmentContentStatistics>;
  /**
   * Get the statistics for an organisation grouped by organisation groups
   * @deprecated use the new assignment statistics query (assignmentStatisticsByGroup)
   */
  assignmentGroupStatistics: Array<AssignmentGroupStatistics>;
  /**
   * Get the statistics for each member in terms of completion rate
   * @deprecated use the new assignment statistics query (assignmentStatisticsByUser)
   */
  assignmentMemberStatistics: Array<AssignmentMemberStatistics>;
  /** List statistics per assignment for the organisation */
  assignmentStatisticsByAssignment: StatisticsByAssignmentConnection;
  /** List assignment statistics per org group for the organisation */
  assignmentStatisticsByGroup: StatisticsByGroupConnection;
  /** List assignment statistics per user for the organisation */
  assignmentStatisticsByUser: StatisticsByUserConnection;
  /** List of assignments created by this organisation */
  assignments: AssignmentConnection;
  /** canvas onboarding ID of the organisation */
  canvasOnboardingID: Scalars['ID'];
  /** organisation cover image */
  coverImage?: Maybe<Image>;
  /** When the organisation was created */
  createdAt: Scalars['Time'];
  /** The user that created the organisation */
  createdBy?: Maybe<User>;
  /** When the organisation was archived */
  deletedAt?: Maybe<Scalars['Time']>;
  /**
   * The groups associated within the organisation
   * @deprecated This will be deprecated in future as it doesnt allow for pagination, please use orgGroups instead
   */
  groups: Array<OrganisationGroup>;
  iamRoles: IamRoleConnection;
  /** Unique identifier */
  id: Scalars['ID'];
  /** The invitation token is used to generate a link where members of a company can sign up at will */
  invitationToken: Scalars['ID'];
  invitations: InvitationConnection;
  /** organisation logo */
  logo?: Maybe<Image>;
  /** The percentage of users that have accessed the application */
  memberAdoptionRate: Scalars['Float'];
  /** Retrieve the members of the organisation */
  members: UserCollection;
  /** The number of users that have access the application */
  membersLoggedIn: Scalars['Int'];
  /**
   * Return the most popular content listened to by users within this organisation
   *
   * At most, this endpoint will return 20 content pieces.
   */
  mostPopularContent: Array<Content>;
  /** The brand name of the company */
  name: Scalars['String'];
  /** A map of which of the onboarding templates the organisation has overwritten */
  onboardingJourneyOverride: Scalars['Map'];
  /** The groups associated within the organisation (paginate, filter and sortable) */
  orgGroups: OrganisationGroupConnection;
  /** The average number of new content pieces started */
  statisticsAverageContentPiecesStart: Array<GraphDataPoint>;
  /** The average time spent on content group by provided frequency */
  statisticsAverageTimeSpent: Array<GraphDataPoint>;
  /** Leader bord for the most active users */
  statisticsMostActiveUsers: Array<OrgStatsMostActiveUser>;
  /** The most popular categories based on the consumption of content cards */
  statisticsMostPopularCategories: Array<PieDataPoint>;
  /** Leader board for the most popular content */
  statisticsMostPopularContent: Array<OrgStatsMostPopularContent>;
  /** The most popular experts based on the consumption of their content cards */
  statisticsMostPopularExperts: Array<PieDataPoint>;
  /** The number of users active in the provided frequency */
  statisticsUserActivity: Array<GraphDataPoint>;
  /**
   * Call this to get a sso url for accessing the stripe customer portal for this account.
   *
   * Note: Do not include this in every single request please.
   * @deprecated this has been replaced by a http endpoint that does a 301 redirect
   */
  stripeCustomerPortal: Scalars['String'];
  /** The subscription associated to the organisation */
  subscription?: Maybe<SubscriptionModel>;
  /** What type of organisation this is */
  type: OrganisationCustomerType;
  /** When the organisation was last updated */
  updatedAt: Scalars['Time'];
};

export type OrganisationAssignmentStatisticsByAssignmentArgs = {
  filter: StatisticsByAssignmentFilter;
};

export type OrganisationAssignmentStatisticsByGroupArgs = {
  filter: StatisticsByGroupFilter;
};

export type OrganisationAssignmentStatisticsByUserArgs = {
  filter: StatisticsByUserFilter;
};

export type OrganisationAssignmentsArgs = {
  filter: AssignmentsFilter;
  sorting?: InputMaybe<SortOrdering>;
};

export type OrganisationIamRolesArgs = {
  pagination?: InputMaybe<OffsetPagination>;
};

export type OrganisationInvitationsArgs = {
  field: InvitationFilter;
  sorting?: InputMaybe<SortOrdering>;
};

export type OrganisationMembersArgs = {
  filter?: InputMaybe<OrganisationMemberFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type OrganisationOrgGroupsArgs = {
  filter?: InputMaybe<OrganisationGroupFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type OrganisationStatisticsAverageContentPiecesStartArgs = {
  filter: OrgStatisticsFilter;
};

export type OrganisationStatisticsAverageTimeSpentArgs = {
  filter: OrgStatisticsFilter;
};

export type OrganisationStatisticsMostActiveUsersArgs = {
  filter: OrgStatisticsFilter;
};

export type OrganisationStatisticsMostPopularCategoriesArgs = {
  filter: OrgStatisticsFilter;
};

export type OrganisationStatisticsMostPopularContentArgs = {
  filter: OrgStatisticsFilter;
};

export type OrganisationStatisticsMostPopularExpertsArgs = {
  filter: OrgStatisticsFilter;
};

export type OrganisationStatisticsUserActivityArgs = {
  filter: OrgStatisticsFilter;
};

export type OrganisationStripeCustomerPortalArgs = {
  returnURL: Scalars['String'];
};

export type OrganisationConnection = {
  __typename: 'OrganisationConnection';
  edges: Array<Organisation>;
  meta: PageInfo;
};

export enum OrganisationCustomerType {
  Enterprise = 'ENTERPRISE',
  Teams = 'TEAMS'
}

export type OrganisationFilter = {
  accessType?: InputMaybe<OrganisationSubscriptionAccessType>;
  hasSubscription?: InputMaybe<Scalars['Boolean']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<OrganisationCustomerType>;
};

export type OrganisationGroup = {
  __typename: 'OrganisationGroup';
  assignments?: Maybe<Array<Assignment>>;
  audience?: Maybe<Audience>;
  createdAt: Scalars['Time'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  isRefreshingAudience?: Maybe<Scalars['Boolean']>;
  /**
   * for a newly created org group via dynamic audience,
   * this information could take sometime to be fully populated
   * depending on the size of audience users needed to be sync
   */
  members: Array<User>;
  name: Scalars['String'];
  numberOfMembers: Scalars['Int'];
  organisation: Organisation;
  updatedAt: Scalars['Time'];
};

export type OrganisationGroupConnection = {
  __typename: 'OrganisationGroupConnection';
  edges: Array<OrganisationGroup>;
  meta: PageInfo;
};

export type OrganisationGroupFilter = {
  createdAt?: InputMaybe<TimeRangeFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type OrganisationInput = {
  coverImage?: InputMaybe<Scalars['ID']>;
  /**
   * Location from where the enterprise organisation is being created/updated
   * Mainly used for a use case where we are creating a 14 day demo access for enterprise org via retool
   */
  creationSource?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export enum OrganisationInvitationStatus {
  Accepted = 'ACCEPTED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Rescinded = 'RESCINDED'
}

export type OrganisationMemberFilter = {
  /** The users need to be created between these two date range */
  createdBetween?: InputMaybe<TimeRangeFilter>;
  /** Filter the users by the provided email */
  email?: InputMaybe<Scalars['String']>;
  /** Retrieve users by a specific group id */
  groupId?: InputMaybe<Scalars['ID']>;
  /** If specified it will filter the users to either admin/not-admin or both */
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  /** If specified it will filter the users to either billable/not-billable or both */
  isBillable?: InputMaybe<Scalars['Boolean']>;
  /** If specified it will filter the users to either manager/non-manager or both */
  isManager?: InputMaybe<Scalars['Boolean']>;
  /** The number of users returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** Filter the users by name */
  name?: InputMaybe<Scalars['String']>;
  /** Offset pagination */
  offset?: InputMaybe<Scalars['Int']>;
  /** Status of user account */
  status?: InputMaybe<OrgMemberStatus>;
};

export enum OrganisationRole {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export type OrganisationStats = {
  __typename: 'OrganisationStats';
  data: Array<Maybe<Array<Scalars['String']>>>;
  headers: Array<Scalars['String']>;
};

export enum OrganisationSubscriptionAccessType {
  Contracted = 'CONTRACTED',
  Demo = 'DEMO',
  Pilot = 'PILOT'
}

export type OrganisationSubscriptionInput = {
  accessType: OrganisationSubscriptionAccessType;
  endDate?: InputMaybe<Scalars['Time']>;
  licencesCount: Scalars['Int'];
  orgId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Time']>;
};

export type OrganisationUserImport = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type OrganisationUserImportResponse = {
  __typename: 'OrganisationUserImportResponse';
  email: Scalars['String'];
  error: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename: 'PageInfo';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  recommendationId?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
};

export type Permission = {
  __typename: 'Permission';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identifier: PermissionIdentifier;
  name: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export enum PermissionIdentifier {
  CreateCustomContent = 'CREATE_CUSTOM_CONTENT',
  CreateEditAssignments = 'CREATE_EDIT_ASSIGNMENTS',
  EditUsersAndGroups = 'EDIT_USERS_AND_GROUPS',
  ExcludeFromReports = 'EXCLUDE_FROM_REPORTS',
  SetAccessLevels = 'SET_ACCESS_LEVELS',
  ViewAndExportStatistics = 'VIEW_AND_EXPORT_STATISTICS'
}

export type PermissionInput = {
  id: Scalars['ID'];
  isAllowed: Scalars['Boolean'];
};

export type PermissionStatus = {
  __typename: 'PermissionStatus';
  isAllowed?: Maybe<Scalars['Boolean']>;
  permission?: Maybe<Permission>;
};

export enum Persona {
  CarrerCalvin = 'CARRER_CALVIN',
  CeoToBeCarl = 'CEO_TO_BE_CARL',
  CorporateCharlie = 'CORPORATE_CHARLIE',
  CuriousChristina = 'CURIOUS_CHRISTINA',
  ImproverIan = 'IMPROVER_IAN',
  NetworkNatalie = 'NETWORK_NATALIE'
}

export type PersonaConnection = {
  __typename: 'PersonaConnection';
  edges: Array<PersonaQuestionAnswer>;
  meta: PageInfo;
};

export type PersonaQuestion = {
  __typename: 'PersonaQuestion';
  id: Scalars['ID'];
  imageUri: Scalars['String'];
  question: Scalars['String'];
};

export type PersonaQuestionAnswer = {
  __typename: 'PersonaQuestionAnswer';
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  question: PersonaQuestion;
  result: Scalars['Boolean'];
};

export type PieDataPoint = {
  __typename: 'PieDataPoint';
  id: Scalars['ID'];
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type Podcast = {
  __typename: 'Podcast';
  /**
   * The amount of time spent on by users
   * @deprecated Use timeSpentOnByUsers insteads
   */
  activityCount: Scalars['Int'];
  /** Available languages on the podcast */
  audioAvailableLanguages: Array<Language>;
  categories: Array<Category>;
  /**
   * Comments on content
   * Note: Do not pass more than 1 sortOrderingInput as it will break the functionality of cursor
   */
  comments: CommentCursorConnection;
  /** Tells whether content is rated by user or not */
  contentRatingByUser: ContentVote;
  contentSegments: Array<ContentSegment>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  /** The number of downvotes the content piece has received */
  downvoteCount: Scalars['Int'];
  entryLevel: EntryLevelValue;
  episodes: Array<Episode>;
  /** @deprecated No longer applicable, use participants field instead */
  experts: Array<Expert>;
  hostedBy?: Maybe<Array<Maybe<User>>>;
  id: Scalars['ID'];
  image: Image;
  isAssigned: Scalars['Boolean'];
  isFeatured: Scalars['Boolean'];
  /** @deprecated No longer applicable */
  isFree: Scalars['Boolean'];
  /** isSensitive represents extra filter on fetching events to make person privacy intact */
  isSensitive: Scalars['Boolean'];
  learningPaths: LearningPathConnection;
  length: Scalars['Int'];
  name: Scalars['String'];
  notificationPreference: NotificationPreferenceState;
  numberOfCommenters: Scalars['Int'];
  numberOfComments: Scalars['Int'];
  /** The number of reviews the content piece as received */
  numberOfReviews: Scalars['Int'];
  /** Which organisation this event is available for. If no organisation then it's a public event */
  organisation?: Maybe<Organisation>;
  /** Which groups within an organisation this event is available for. */
  organisationGroups: Array<OrganisationGroup>;
  /** Experts */
  participants: Array<User>;
  preamble: Scalars['String'];
  publishedAt?: Maybe<Scalars['Time']>;
  /** Averge rating from 1-5 the content piece has received */
  rating: Scalars['Float'];
  /** Which regions the content piece can be viewed in */
  regionRestrictions: Array<Scalars['String']>;
  /** Retrieve content that is related to this content piece */
  relatedContent: Array<ContentCard>;
  /** URL Friendly slug that can be used as a unique identifier */
  slug: Scalars['String'];
  /** The amount of time spent on by users */
  timeSpentOnByUsers: Scalars['Int'];
  /** Available languages on the podcast's transcripts */
  transcriptAvailableLanguages: Array<Language>;
  /** The transcript file of the podcast ( default english) */
  transcripts: PodcastTranscript;
  updatedAt: Scalars['Time'];
  /** The number of upvotes the content piece has received */
  upvoteCount: Scalars['Int'];
  userContentTracking: UserContentTracking;
};

export type PodcastCommentsArgs = {
  afterCursor?: InputMaybe<Scalars['String']>;
  beforeCursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sorting?: InputMaybe<SortOrdering>;
};

export type PodcastEpisodesArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type PodcastLearningPathsArgs = {
  filter: LearningPathFilter;
  sorting?: InputMaybe<SortOrdering>;
};

export type PodcastLengthArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type PodcastNotificationPreferenceArgs = {
  type: NotificationType;
};

export type PodcastPublishedAtArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type PodcastRelatedContentArgs = {
  limit: Scalars['Int'];
};

export type PodcastTranscriptsArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type PodcastAudioInput = {
  episodeName: Scalars['String'];
  publishedAt?: InputMaybe<Scalars['Time']>;
  /** s3PresignedUrl path in s3 received after uploading the video via pre signed url */
  s3PresignedUrl?: InputMaybe<Scalars['String']>;
};

export type PodcastConnection = {
  __typename: 'PodcastConnection';
  edges: Array<Podcast>;
  meta: PageInfo;
};

export type PodcastFilter = {
  categories?: InputMaybe<Array<Scalars['ID']>>;
  entryLevel?: InputMaybe<Array<EntryLevelValue>>;
  experts?: InputMaybe<Array<Scalars['ID']>>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  /** includeSensitiveContent filter the content basis of sensitivity of content */
  includeSensitiveContent?: InputMaybe<Scalars['Boolean']>;
  languages?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  onlyRated?: InputMaybe<Scalars['Boolean']>;
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  organisationID?: InputMaybe<Array<Scalars['ID']>>;
};

export type PodcastInput = {
  categories: Array<Scalars['ID']>;
  contentSegments: Array<ContentSegmentInput>;
  entryLevel: EntryLevelValue;
  experts: Array<Scalars['ID']>;
  hostedBy?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  image: Scalars['String'];
  /** @deprecated No longer applicable */
  isFree?: InputMaybe<Scalars['Boolean']>;
  /** isSensitive represents extra filter on fetching events to make person privacy intact */
  isSensitive: Scalars['Boolean'];
  /** @deprecated This is calculate automatically on the backend for each language */
  length?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  organisationID?: InputMaybe<Scalars['ID']>;
  preamble: Scalars['String'];
  publishedAt?: InputMaybe<Scalars['Time']>;
  /** Which regions the content piece can be viewed in */
  regionRestrictions?: InputMaybe<Array<Scalars['String']>>;
  /** Tags represents hash tags which will be attached to content for better search and better content delivery */
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type PodcastTranscript = {
  __typename: 'PodcastTranscript';
  language: Scalars['String'];
  length?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['Time']>;
  transcripts: Array<Transcript>;
};

export type PodcastTranscriptInput = {
  page: Scalars['String'];
  publishedAt?: InputMaybe<Scalars['Time']>;
};

export type PollQuestionAnswer = {
  /** The answer provided by the user */
  answerId: Scalars['ID'];
  /** The question id displayed to the user */
  questionId: Scalars['ID'];
};

export type PollQuestionAnswerOption = {
  /** The answer text that is displayed to the user */
  answer: Scalars['String'];
  /**
   * Unique identifier of the poll question answer
   *
   * This should be NULL when creating new poll questions and must be provided when updating
   */
  id?: InputMaybe<Scalars['ID']>;
  /** The image to display if the question type is MCQ (Multiple choice question) if the type is boolean it's not used. */
  imageID?: InputMaybe<Scalars['ID']>;
};

export type PollQuestionAnswerOptions = {
  __typename: 'PollQuestionAnswerOptions';
  /** The poll question answer displayed to the user */
  answer: Scalars['String'];
  /** Unique answer identifier */
  id: Scalars['ID'];
  /** The image to be displayed when asking the question */
  image?: Maybe<Image>;
  /** Vote count represents number of user selected this particular opiton for answer. */
  voteCount: Scalars['Int'];
};

export type PollQuestionConnection = {
  __typename: 'PollQuestionConnection';
  edges: Array<PollQuestionResponse>;
  meta: PageInfo;
};

export type PollQuestionFilter = {
  /** Filter the question basis of time frame */
  activeFrom?: InputMaybe<Scalars['Time']>;
  /** Filter the question basis of time frame */
  activeUntil?: InputMaybe<Scalars['Time']>;
  /** Filter already answered question bases on authenticated user */
  excludeAlreadyAnswered?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Typo in the field name, use excludeAlreadyAnswered instead */
  exludeAlreadyAnswered?: InputMaybe<Scalars['Boolean']>;
  inAppDisplay?: InputMaybe<Scalars['Boolean']>;
  /** The number of items to return */
  limit: Scalars['Int'];
  /** Offset in the pagination */
  offset: Scalars['Int'];
  /** Filter the question basis of polling purpose either it can be daily polls or for onboarding purpose */
  purpose?: InputMaybe<ShowOnScreenType>;
  /** Query the question */
  query?: InputMaybe<Scalars['String']>;
  /** Filter the question basis of question type */
  questionType?: InputMaybe<QuestionType>;
  /**
   * Filter the question basis of screenType
   * @deprecated Dont use this anymore instead use purpose
   */
  showOnScreen?: InputMaybe<ShowOnScreenType>;
};

export type PollQuestionInput = {
  /** Filter the question basis of time frame */
  activeFrom: Scalars['Time'];
  /** Filter the question basis of time frame */
  activeUntil: Scalars['Time'];
  /** The different alternatives to answer with */
  answerOptions: Array<PollQuestionAnswerOption>;
  /** An image ID should be provided if the QuestionType is BOOLEAN since it's a yes or no question */
  imageID?: InputMaybe<Scalars['ID']>;
  /** Define the purpose of the polls like daily polls onboarding as so on. */
  purpose: ShowOnScreenType;
  /** The question displayed to the user */
  question: Scalars['String'];
  /** Filter the question basis of question type */
  questionType: QuestionType;
};

export type PollQuestionResponse = {
  __typename: 'PollQuestionResponse';
  /** If the current question is active or not */
  active: Scalars['Boolean'];
  /** Start time of the poll question */
  activeFrom: Scalars['Time'];
  /** End time of the poll question */
  activeUntil: Scalars['Time'];
  /** A list of alternatives for the user to answer the poll with */
  answerOptions: Array<PollQuestionAnswerOptions>;
  /** The name of the clevertap user property in which we want to persist this answer */
  clevertapKey: Scalars['String'];
  /** The unique identifier of the poll question */
  id: Scalars['ID'];
  /** The image to be displayed when asking the question */
  image?: Maybe<Image>;
  /** Define the purpose of the polls like daily polls onboarding as so on. */
  purpose: ShowOnScreenType;
  /** The question displayed to the user */
  question: Scalars['String'];
  /** Which type of question this is */
  questionType: QuestionType;
  /** The selected answer that the authenticated user voted for */
  selectedAnswer?: Maybe<PollQuestionAnswerOptions>;
};

export type PollSuggestion = {
  suggestion: Scalars['String'];
};

export type Post = FeedContent & {
  __typename: 'Post';
  attachments: Array<Maybe<FeedPostAttachments>>;
  comments: CommentCursorConnection;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  /** description will be null if it's instant repost */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Information about the users mentioned in the post */
  mentions: Array<Mention>;
  numberOfCommenters: Scalars['Int'];
  numberOfComments: Scalars['Int'];
  /** numberOfInstantRepostsByUser will show the number of instant reposts done by current user */
  numberOfInstantRepostsByUser: Scalars['Int'];
  numberOfReposts: Scalars['Int'];
  /** numberOfRepostsWithThoughtsByUser will show the number of reposts with thoughts done by current user */
  numberOfRepostsWithThoughtsByUser: Scalars['Int'];
  parent?: Maybe<Post>;
  privacySettings: PrivacySettings;
  reactions: ReactionsSummary;
  reposts: FeedContentConnection;
  updatedAt?: Maybe<Scalars['Time']>;
  user: User;
};

export type PostCommentsArgs = {
  pagination?: InputMaybe<CursorPaginationInput>;
  sorting?: InputMaybe<SortOrdering>;
};

export type PostRepostsArgs = {
  pagination?: InputMaybe<CursorPaginationInput>;
};

export type PostActivity = FeedContent & {
  __typename: 'PostActivity';
  activityType: PostActivityType;
  /** comment is only available if ActivityType is either COMMENTED or REPLIED */
  comment?: Maybe<Comment>;
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  post: Post;
  user: User;
};

export enum PostActivityType {
  Commented = 'COMMENTED',
  Reacted = 'REACTED',
  Replied = 'REPLIED',
  Reposted = 'REPOSTED'
}

export type PostComment = {
  /** To be deprecated: Use raw text instead */
  comment: Scalars['String'];
  mentions?: InputMaybe<Array<InputMaybe<MentionInput>>>;
  parentComment?: InputMaybe<Scalars['ID']>;
  rawText?: InputMaybe<Scalars['String']>;
  referenceId: Scalars['ID'];
  type: CommentType;
};

export type PreSignedUploadUrl = {
  __typename: 'PreSignedUploadUrl';
  /** How long the URL is valid for in seconds */
  expiresIn: Scalars['Int'];
  /** Copy these exact headers to the request when uploading and DO NOT modify or it will fail with a 403 error */
  headers: Scalars['Map'];
  /** Target this URL when doing the upload */
  url: Scalars['String'];
};

export enum PrivacySettings {
  Organisation = 'ORGANISATION',
  OrganisationAnnouncement = 'ORGANISATION_ANNOUNCEMENT',
  PlatformAnnouncement = 'PLATFORM_ANNOUNCEMENT',
  Public = 'PUBLIC',
  PublicThinkfluencerPost = 'PUBLIC_THINKFLUENCER_POST'
}

export enum ProductTourState {
  DoNotShow = 'DO_NOT_SHOW',
  Finished = 'FINISHED',
  RemindLater = 'REMIND_LATER',
  ShowAround = 'SHOW_AROUND'
}

export type ProductTourTracking = {
  __typename: 'ProductTourTracking';
  /** Unique identifier makes the apollo cache happy */
  id: Scalars['ID'];
  /** Indicates the step number the user is at in the onboarding journey */
  journeyProgress: Scalars['Int'];
  /** This is used in conjunction with the REMIND_LATER, and the frontend should show the tour again after this value */
  showAgainAfter?: Maybe<Scalars['Time']>;
  /** This tells when user started the product tour, frontend will use in places like not showing onboarding video after 7 days etc. */
  startedAt?: Maybe<Scalars['Time']>;
  /** Indicates the tour preference set by the user. */
  state: ProductTourState;
  /** This is the stream that should be viewed as part of the onboarding product tour */
  stream: Stream;
  /** Indicates the type of user. Can be either B2B or B2C */
  userType: UserType;
};

export type Query = {
  __typename: 'Query';
  assignment: Assignment;
  /** list assignments that the content belongs too */
  assignmentsForContent: AssignmentConnection;
  /** Retrieve a audience by id */
  audience: Audience;
  audienceField: AudienceField;
  /** Fetch all fields available to the organisation */
  audienceFields: Array<AudienceField>;
  /** Fetch Audience Filter Function fields available to the organisation */
  audienceFilterFuncs: Array<AudienceFilterFunc>;
  /** Preview the users that would belong to the audience if it was created */
  audienceUserPreview: UserCollection;
  categories: CategoryConnection;
  category?: Maybe<Category>;
  /** Retrieve a comment by it's ID */
  chat: Chat;
  /** A group of users i.e cohort that are going on a trail together, a user can be part of multiple cohorts that do the same trail */
  cohort: Cohort;
  cohorts: CohortConnection;
  /** Retrieve a comment by it's ID */
  comment: Comment;
  /** Retrieve the comment as well as the comments surrounding it */
  commentsSurroundingID: SurroundingCommentsConnection;
  /** This will change only to accept id in the future once the new website has been released. */
  contentCard: ContentCard;
  contentCards: ContentConnection;
  contentRating: ContentRatingConnection;
  coupon: Coupon;
  coupons?: Maybe<CouponConnection>;
  /** @deprecated Dont use this because we now have an array of featured content in the app */
  currentlyFeaturedContent?: Maybe<ContentCard>;
  /** @deprecated Dont use this because we now have an array of featured content in the app */
  currentlyFeaturedContentWithConfig?: Maybe<CurrentlyFeaturedContent>;
  /**
   * This timestamp represents the last time the data lake was updated
   *
   * It does NOT guarantee that the data older than this timestamp cannot be added at a later time because we do
   * not have the ability to control the data coming from the Customer Data Platform (CDP) segment.io
   */
  dataLakeLastUpdatedAt: Scalars['Time'];
  /** this will be change to only accept ID in new website */
  ebook?: Maybe<Ebook>;
  ebooks: EbookConnection;
  /** this will be change to only accept ID in new website */
  event?: Maybe<Event>;
  events: EventConnection;
  /** this will be change to only accept ID in new website */
  expert?: Maybe<Expert>;
  experts: ExpertCollection;
  featuredContent?: Maybe<FeaturedContent>;
  featuredContentEntries: FeaturedContentConnection;
  feed?: Maybe<FeedConnection>;
  feedById: FeedActivity;
  feedContentById: FeedContent;
  /**
   * feedV2 is used to display all feed contents from users
   * that were followed. It is meant to replace feed query and
   * it shows the content activities, posts and post activities;
   * instead of only content activities in feed query.
   */
  feedV2: FeedContentConnection;
  getLearningPathsByUserTracking: LearningPathConnection;
  /** getLinkPreview gets the preview details like image etc for a given link */
  getLinkPreview: LinkPreview;
  iamRole?: Maybe<IamRole>;
  /** Retrieve an images by it's identifier */
  image?: Maybe<Image>;
  images: ImageConnection;
  /** Returns the list of all supported languages in ascending order of names with English at the top followed by the user preferred language(if present) */
  languages: Array<Language>;
  learningPath?: Maybe<LearningPath>;
  learningPaths: LearningPathConnection;
  /** Retrieve the life goal by ID */
  lifegoal?: Maybe<Lifegoal>;
  /** Retrieve the life goals */
  lifegoals: LifegoalCollection;
  listStripePromoCode?: Maybe<Array<StripePromotionCode>>;
  /** Currently authenticated user */
  me?: Maybe<User>;
  /**
   * Retrieve the channel tab configuration
   *
   * Note: the id should be the same as the entityId of the channel tab
   */
  msTeamsFollowTopicTab: MSteamsChannelTabFollowTopic;
  organisation: Organisation;
  organisationGroup?: Maybe<OrganisationGroup>;
  organisations: OrganisationConnection;
  /** query to fetch all the permissions info that system support currently */
  permissions: Array<Permission>;
  /** this will be change to only accept ID in new website */
  podcast?: Maybe<Podcast>;
  podcasts: PodcastConnection;
  /** Retrieve a specific poll question by it's unique id */
  pollQuestion: PollQuestionResponse;
  /** Retrieve all the metadata questions to ask the users */
  pollQuestions: PollQuestionConnection;
  /**
   * The promoted learning path of this month
   *
   * Note: This is nullable
   */
  promotedLearningPathOfTheMonth?: Maybe<MonthlyPromotedLearningPath>;
  /** QuerySuggestion This will help you to get query suggestion on heading and content fields */
  querySuggestion: Array<Scalars['String']>;
  /** scheduledLivestreamEvents will list all events within +-24hours that have reserved livestream created with the creator as a host. */
  scheduledLivestreamEvents: EventConnection;
  /** Retrieve a content by slug and content-type */
  slug?: Maybe<SlugContent>;
  /** this will be change to only accept ID in new website */
  stream?: Maybe<Stream>;
  streamChatMessage?: Maybe<StreamChatMessage>;
  streamChatMessages: Array<Maybe<StreamChatMessage>>;
  streams: StreamConnection;
  subscriptionPlan: SubscriptionPlan;
  subscriptionPlans: SubscriptionPlanConnection;
  subscriptions: SubscriptionConnection;
  /** TODO: Need confirmation from nellie what will be the filter to list users */
  suggestUsersForCohort?: Maybe<UserCollection>;
  /** List of tax rates for admin available with different filters */
  taxRates: TaxRateConnection;
  /** Return specific timeline details for the cohort */
  timelineEntry: Timeline;
  /** Get a user by their ID */
  user?: Maybe<User>;
  /** Retrieve all the tax rates available for the user's CF countryCode */
  userTaxRates: TaxRateConnection;
  users: UserCollection;
  /** Retrieve list of users who can livestream */
  usersWhoCanLivestream: UserCollection;
  /** query to show streams in watch section on marketing website */
  watchStreams: StreamConnection;
};

export type QueryAssignmentArgs = {
  id: Scalars['ID'];
};

export type QueryAssignmentsForContentArgs = {
  contentType: ContentType;
  id: Scalars['ID'];
};

export type QueryAudienceArgs = {
  id: Scalars['ID'];
};

export type QueryAudienceFieldArgs = {
  id: Scalars['ID'];
};

export type QueryAudienceUserPreviewArgs = {
  input: AudienceUserPreviewInput;
};

export type QueryCategoriesArgs = {
  filter?: InputMaybe<CategoryFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QueryCategoryArgs = {
  id: Scalars['String'];
};

export type QueryChatArgs = {
  id: Scalars['ID'];
};

export type QueryCohortArgs = {
  id: Scalars['ID'];
};

export type QueryCohortsArgs = {
  filter?: InputMaybe<CohortFilter>;
  pagination?: InputMaybe<CursorPaginationInput>;
};

export type QueryCommentArgs = {
  id: Scalars['ID'];
};

export type QueryCommentsSurroundingIdArgs = {
  afterLimit?: InputMaybe<Scalars['Int']>;
  beforeLimit?: InputMaybe<Scalars['Int']>;
  commentId: Scalars['ID'];
};

export type QueryContentCardArgs = {
  contentType?: InputMaybe<ContentType>;
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type QueryContentCardsArgs = {
  filter?: InputMaybe<ContentCardsFilter>;
  sorting?: InputMaybe<ContentCardsSorting>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryContentRatingArgs = {
  contentId: Scalars['String'];
  sorting?: InputMaybe<SortOrdering>;
};

export type QueryCouponArgs = {
  code: Scalars['String'];
};

export type QueryCouponsArgs = {
  filter?: InputMaybe<CouponFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QueryEbookArgs = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type QueryEbooksArgs = {
  filter?: InputMaybe<EbookFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QueryEventArgs = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type QueryEventsArgs = {
  filter?: InputMaybe<EventFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QueryExpertArgs = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type QueryExpertsArgs = {
  filter?: InputMaybe<ExpertFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QueryFeaturedContentArgs = {
  id: Scalars['String'];
};

export type QueryFeaturedContentEntriesArgs = {
  filter?: InputMaybe<FeaturedContentFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QueryFeedArgs = {
  filter: FeedFilter;
};

export type QueryFeedByIdArgs = {
  id: Scalars['ID'];
};

export type QueryFeedContentByIdArgs = {
  id: Scalars['ID'];
  type: FeedContentType;
};

export type QueryFeedV2Args = {
  pagination?: InputMaybe<CursorPaginationInput>;
};

export type QueryGetLearningPathsByUserTrackingArgs = {
  filter?: InputMaybe<UserCollectionTrackingFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QueryGetLinkPreviewArgs = {
  link: Scalars['String'];
};

export type QueryIamRoleArgs = {
  id: Scalars['ID'];
};

export type QueryImageArgs = {
  id: Scalars['String'];
};

export type QueryImagesArgs = {
  filter?: InputMaybe<ImageFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QueryLearningPathArgs = {
  id: Scalars['String'];
};

export type QueryLearningPathsArgs = {
  filter?: InputMaybe<LearningPathFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QueryLifegoalArgs = {
  id: Scalars['String'];
};

export type QueryLifegoalsArgs = {
  filter?: InputMaybe<LifegoalFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QueryListStripePromoCodeArgs = {
  input: ListStripePromotionCodeInput;
};

export type QueryMsTeamsFollowTopicTabArgs = {
  id: Scalars['ID'];
};

export type QueryOrganisationArgs = {
  id: Scalars['String'];
};

export type QueryOrganisationGroupArgs = {
  id: Scalars['String'];
};

export type QueryOrganisationsArgs = {
  filter?: InputMaybe<OrganisationFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QueryPodcastArgs = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type QueryPodcastsArgs = {
  filter?: InputMaybe<PodcastFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QueryPollQuestionArgs = {
  id: Scalars['ID'];
};

export type QueryPollQuestionsArgs = {
  filter?: InputMaybe<PollQuestionFilter>;
};

export type QueryQuerySuggestionArgs = {
  query: Scalars['String'];
};

export type QueryScheduledLivestreamEventsArgs = {
  sorting?: InputMaybe<SortOrdering>;
};

export type QuerySlugArgs = {
  contentType: ContentType;
  slug: Scalars['String'];
};

export type QueryStreamArgs = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type QueryStreamChatMessageArgs = {
  id: Scalars['String'];
};

export type QueryStreamChatMessagesArgs = {
  id: Scalars['String'];
  input?: InputMaybe<StreamChatMessagesFilter>;
};

export type QueryStreamsArgs = {
  filter?: InputMaybe<StreamFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QuerySubscriptionPlanArgs = {
  id: Scalars['String'];
};

export type QuerySubscriptionPlansArgs = {
  filter?: InputMaybe<SubscriptionPlanFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QuerySubscriptionsArgs = {
  filter?: InputMaybe<SubscriptionFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QuerySuggestUsersForCohortArgs = {
  cohortId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<CohortInviteUserListFilter>;
  pagination?: InputMaybe<OffsetPagination>;
};

export type QueryTaxRatesArgs = {
  filter?: InputMaybe<TaxRateFilter>;
};

export type QueryTimelineEntryArgs = {
  id: Scalars['ID'];
};

export type QueryUserArgs = {
  id: Scalars['String'];
};

export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type QueryUsersWhoCanLivestreamArgs = {
  name?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<OffsetPagination>;
};

export type QueryWatchStreamsArgs = {
  pagination?: InputMaybe<OffsetPagination>;
};

export enum QuestionType {
  Boolean = 'BOOLEAN',
  /** Multiple choice question */
  Mcq = 'MCQ'
}

export type ReactableContent = Comment | FeedActivity;

export type ReactionCount = {
  __typename: 'ReactionCount';
  count: Scalars['Int'];
  emoji: Scalars['String'];
  type: ReactionType;
};

export enum ReactionReferenceType {
  Comment = 'COMMENT',
  Contentactivity = 'CONTENTACTIVITY',
  Post = 'POST'
}

export enum ReactionType {
  Bulb = 'BULB',
  Clap = 'CLAP',
  Heart = 'HEART',
  Llama = 'LLAMA',
  Smile = 'SMILE',
  Think = 'THINK',
  Tiger = 'TIGER'
}

export type ReactionsSummary = {
  __typename: 'ReactionsSummary';
  reactions: Array<ReactionCount>;
  totalCount: Scalars['Int'];
  /**
   * userReacted exist if user have reacted to the referenceType before.
   * else it will be null.
   */
  userReacted?: Maybe<UserReaction>;
  users: UserReactionConnection;
};

export type ReactionsSummaryUsersArgs = {
  pagination?: InputMaybe<CursorPaginationInput>;
  type?: InputMaybe<ReactionType>;
};

export type ReportBuilderResult = {
  __typename: 'ReportBuilderResult';
  temporalWorkflowId: Scalars['String'];
};

export enum ReportableContentType {
  Comment = 'COMMENT',
  Ebook = 'EBOOK',
  Event = 'EVENT',
  LearningPath = 'LEARNING_PATH',
  Podcast = 'PODCAST',
  Post = 'POST',
  Stream = 'STREAM',
  User = 'USER'
}

export type ResetPasswordFinalizeInput = {
  email: Scalars['String'];
  nonce: Scalars['String'];
  password: Scalars['String'];
};

/** Content that can be searched for */
export type SearchableContent =
  | Ebook
  | Event
  | Expert
  | LearningPath
  | Podcast
  | Stream;

export type SearchableContentCollection = {
  __typename: 'SearchableContentCollection';
  edges: Array<SearchableContent>;
  meta: PageInfo;
};

export enum ShowOnScreenType {
  Dailypolls = 'DAILYPOLLS',
  Onboarding = 'ONBOARDING'
}

/** This presents all the contents that have slug */
export type SlugContent =
  | Category
  | Ebook
  | Event
  | LearningPath
  | Lifegoal
  | Podcast
  | Stream
  | User;

export type SortOrdering = {
  sorting?: InputMaybe<Array<SortOrderingInput>>;
};

export type SortOrderingInput = {
  direction: Scalars['String'];
  field: Scalars['String'];
};

export enum SpeechVoiceType {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type StatisticsByAssignmentConnection = {
  __typename: 'StatisticsByAssignmentConnection';
  edges: Array<StatisticsByAssignmentResponse>;
  meta: PageInfo;
};

export type StatisticsByAssignmentFilter = {
  assignmentStatus?: InputMaybe<Array<AssignmentStatus>>;
  assignmentTitle?: InputMaybe<Scalars['String']>;
  createdBetween?: InputMaybe<TimeRangeFilter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  organisationGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type StatisticsByAssignmentResponse = {
  __typename: 'StatisticsByAssignmentResponse';
  assignmentId: Scalars['ID'];
  assignmentTitle: Scalars['String'];
  completionRate: Scalars['Float'];
  durationConsumed: Scalars['Float'];
  isTotal: Scalars['Boolean'];
  percentUsersCompleted: Scalars['Float'];
  percentUsersLiked: Scalars['Float'];
  percentUsersStarted: Scalars['Float'];
  usersAssigned: Scalars['Int'];
  usersCompleted: Scalars['Int'];
  usersStarted: Scalars['Int'];
};

export type StatisticsByGroupConnection = {
  __typename: 'StatisticsByGroupConnection';
  edges: Array<StatisticsByGroupResponse>;
  meta: PageInfo;
};

export type StatisticsByGroupFilter = {
  assignmentStatus?: InputMaybe<Array<AssignmentStatus>>;
  createdBetween?: InputMaybe<TimeRangeFilter>;
  groupName?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  organisationGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type StatisticsByGroupResponse = {
  __typename: 'StatisticsByGroupResponse';
  assignmentId: Scalars['ID'];
  assignmentTitle: Scalars['String'];
  completionRate: Scalars['Float'];
  durationConsumed: Scalars['Float'];
  groupId: Scalars['ID'];
  groupName: Scalars['String'];
  isTotal: Scalars['Boolean'];
  percentUsersLiked: Scalars['Float'];
  totalDurationConsumed: Scalars['Float'];
  usersAssigned: Scalars['Int'];
  usersCompleted: Scalars['Int'];
  usersStarted: Scalars['Int'];
};

export type StatisticsByUserConnection = {
  __typename: 'StatisticsByUserConnection';
  edges: Array<StatisticsByUserResponse>;
  meta: PageInfo;
};

export type StatisticsByUserFilter = {
  assignmentStatus?: InputMaybe<Array<AssignmentStatus>>;
  createdBetween?: InputMaybe<TimeRangeFilter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  organisationGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  userName?: InputMaybe<Scalars['String']>;
};

export type StatisticsByUserResponse = {
  __typename: 'StatisticsByUserResponse';
  assignmentAssigned: Scalars['Int'];
  assignmentCompleted: Scalars['Int'];
  assignmentId: Scalars['ID'];
  assignmentStarted: Scalars['Int'];
  assignmentTitle: Scalars['String'];
  completionRate: Scalars['Float'];
  dateCompletion?: Maybe<Scalars['Time']>;
  durationConsumed: Scalars['Float'];
  email: Scalars['String'];
  isTotal: Scalars['Boolean'];
  userId: Scalars['ID'];
  userName: Scalars['String'];
};

export type Stream = {
  __typename: 'Stream';
  /**
   * The amount of time spent on by users
   * @deprecated Use timeSpentOnByUsers insteads
   */
  activityCount: Scalars['Int'];
  /** The agenda for the stream */
  agenda: Array<StreamAgendaBulletPoint>;
  /** Lists all the available languages available on this stream for audio */
  audioAvailableLanguages: Array<Language>;
  /** When the stream is of type broadcast, this is the configuration used for ingesting the video into the cloud provider */
  broadcastConfiguration?: Maybe<VideoBroadcastConfiguration>;
  /** Categories associated to the content */
  categories: Array<Category>;
  /** Agora channel identifier */
  channel: Scalars['String'];
  /**
   * Comments on content
   * Note: Do not pass more than 1 sortOrderingInput as it will break the functionality of cursor
   */
  comments: CommentCursorConnection;
  /** Tells whether content is rated by user or not */
  contentRatingByUser: ContentVote;
  /** These are short segments that we display beneth the content card */
  contentSegments: Array<ContentSegment>;
  /** When the stream was created */
  createdAt: Scalars['Time'];
  /**
   * The user that created the live stream
   * @deprecated No longer applicable, use createdByUser field instead
   */
  createdBy?: Maybe<Expert>;
  /** The user that created the live stream */
  createdByUser?: Maybe<User>;
  /** When the stream was deleted */
  deletedAt?: Maybe<Scalars['Time']>;
  /** The number of downvotes the content piece has received */
  downvoteCount: Scalars['Int'];
  /** Level of the content */
  entryLevel: EntryLevelValue;
  /**
   * The experts associated with the publish content
   * @deprecated No longer applicable, use participants field instead
   */
  experts: Array<Expert>;
  /** List of users who have hosted the stream. */
  hostedBy?: Maybe<Array<Maybe<User>>>;
  /**
   * A list of hosts in the stream, their order and if they have accepted the invite.
   * This should only be used during the part where the stream is live
   */
  hosts: Array<StreamHost>;
  id: Scalars['ID'];
  /** Image used when display a conten card for the stream */
  image: Image;
  isAssigned: Scalars['Boolean'];
  /**
   * If users without a subscription can view the stream
   * @deprecated No longer applicable
   */
  isFree: Scalars['Boolean'];
  /** isSensitive represents extra filter on fetching events to make person privacy intact */
  isSensitive: Scalars['Boolean'];
  /** Learning paths associated to this stream */
  learningPaths: LearningPathConnection;
  /** Length of the stream in seconds */
  length: Scalars['Int'];
  /** Messages that were sent by users during the live stream */
  messages: Array<StreamChatMessage>;
  /** Name of the stream as selected by expert or tigerhall */
  name: Scalars['String'];
  notificationPreference: NotificationPreferenceState;
  numberOfCommenters: Scalars['Int'];
  numberOfComments: Scalars['Int'];
  /** The number of reviews the content piece as received */
  numberOfReviews: Scalars['Int'];
  /** Which organisation this event is available for. If no organisation then it's a public event */
  organisation?: Maybe<Organisation>;
  /** Which groups within an organisation this event is available for. */
  organisationGroups: Array<OrganisationGroup>;
  /** The experts associated with the publish content */
  participants: Array<User>;
  /** Short introduction */
  preamble: Scalars['String'];
  /** When the stream was published */
  publishedAt?: Maybe<Scalars['Time']>;
  /** Averge rating from 1-5 the content piece has received */
  rating: Scalars['Float'];
  /** Which regions the content piece can be viewed in */
  regionRestrictions: Array<Scalars['String']>;
  /** Retrieve content that is related to this content piece */
  relatedContent: Array<ContentCard>;
  /** URL Friendly slug that can be used as a unique identifier */
  slug: Scalars['String'];
  /** The origin of the stream */
  source: StreamSource;
  /**
   * The stream transitions through a number of states while being created as a live stream or
   * directly from the authoring tools
   */
  status: StreamStatus;
  /**
   * The subtitle url to the video on S3 in the provided language
   * If language not provided, it defaults to users preferred language and if that is not available too, finally defaults to EN
   */
  subtitle: StreamSubtitles;
  /** Lists all the available languages available on this stream for subtitles */
  subtitleAvailableLanguages: Array<Language>;
  /** The amount of time spent on by users */
  timeSpentOnByUsers: Scalars['Int'];
  /** Whether any tranlsation job is running for this stream subtitle or not */
  translationOngoing: Scalars['Boolean'];
  /** @deprecated Use source instead, this will be removed in the coming release */
  type: StreamSource;
  /** When the stream was last updated */
  updatedAt: Scalars['Time'];
  /** The number of upvotes the content piece has received */
  upvoteCount: Scalars['Int'];
  /** The authenticated users current progress tracking */
  userContentTracking: UserContentTracking;
  /**
   * The video url to the video on S3 in the provided language
   * If language not provided, it defaults to users preferred language and if that is not available too, finally defaults to EN
   */
  video: StreamMedia;
  /** The URL to the video on S3 in the default language EN */
  videoUrl: Scalars['String'];
  /** When a stream transitioned from backstage to live */
  wentLiveAt?: Maybe<Scalars['Time']>;
};

export type StreamCommentsArgs = {
  afterCursor?: InputMaybe<Scalars['String']>;
  beforeCursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sorting?: InputMaybe<SortOrdering>;
};

export type StreamLearningPathsArgs = {
  filter: LearningPathFilter;
  sorting?: InputMaybe<SortOrdering>;
};

export type StreamLengthArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type StreamMessagesArgs = {
  limit: Scalars['Int'];
};

export type StreamNotificationPreferenceArgs = {
  type: NotificationType;
};

export type StreamPublishedAtArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type StreamRelatedContentArgs = {
  limit: Scalars['Int'];
};

export type StreamSubtitleArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type StreamVideoArgs = {
  language?: InputMaybe<Scalars['String']>;
};

export type StreamAgendaBulletPoint = {
  __typename: 'StreamAgendaBulletPoint';
  /** The description of the agenda item */
  description: Scalars['String'];
  /** The estimated start time of the agenda item, this should NOT be used to indicate which agenda item is currently being discussed */
  estimatedStartTime?: Maybe<Scalars['Time']>;
  /** If this is the current agenda bullet point being discussed */
  isActive: Scalars['Boolean'];
  /** After the video has been turned into a recording this will be the time the agenda item started */
  secondsIntoRecordingTopicStartsAt?: Maybe<Scalars['Int']>;
  /** The users that are presenting on the topic in our agenda */
  speakers: Array<User>;
  /** The topic of the agenda item */
  topic: Scalars['String'];
};

export type StreamAgendaBulletPointInput = {
  /** The description of the agenda item */
  description: Scalars['String'];
  /** The estimated start time of the agenda item, this should NOT be used to indicate which agenda item is currently being discussed */
  estimatedStartTime?: InputMaybe<Scalars['Time']>;
  /** If this is the current agenda bullet point being discussed */
  isActive: Scalars['Boolean'];
  /** After the video has been turned into a recording this will be the time the agenda item started */
  secondsIntoRecordingTopicStartsAt?: InputMaybe<Scalars['Int']>;
  /** The users that are presenting on the topic in our agenda */
  speakers: Array<Scalars['ID']>;
  /** The topic of the agenda item */
  topic: Scalars['String'];
};

export type StreamChatMessage = {
  __typename: 'StreamChatMessage';
  content: Scalars['String'];
  id: Scalars['ID'];
  streamId: Scalars['ID'];
  timestamp: Scalars['Int'];
  user: User;
};

export type StreamChatMessageInput = {
  content: Scalars['String'];
  streamId: Scalars['ID'];
  /** @deprecated Int is a signed i32 and we are sending unix timestamps in ms which requires i64 so this field broke with the apollo router introduction and is now deprecated and will be removed in the future. */
  timestamp?: InputMaybe<Scalars['Int']>;
};

export type StreamChatMessagesFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type StreamConnection = {
  __typename: 'StreamConnection';
  edges: Array<Stream>;
  meta: PageInfo;
};

export type StreamFilter = {
  categories?: InputMaybe<Array<Scalars['ID']>>;
  createdBefore?: InputMaybe<Scalars['Time']>;
  entryLevel?: InputMaybe<Array<EntryLevelValue>>;
  /** If specified, only show streams that are hosted by the provided */
  experts?: InputMaybe<Array<Scalars['ID']>>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  /** includeSensitiveContent filter the content basis of sensitivity of content */
  includeSensitiveContent?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  /** Simple wildcard search on the name */
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  onlyRated?: InputMaybe<Scalars['Boolean']>;
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  organisationID?: InputMaybe<Array<Scalars['ID']>>;
  /** If specified, only show the provided sources */
  source?: InputMaybe<Array<InputMaybe<StreamSource>>>;
  /** If specified, only show the provided statuses */
  status?: InputMaybe<Array<InputMaybe<StreamStatus>>>;
  uniqueExpertStream?: InputMaybe<Scalars['Boolean']>;
};

export type StreamHost = {
  __typename: 'StreamHost';
  /** If they have accepted the invite, if null then they have not replied */
  accepted?: Maybe<Scalars['Boolean']>;
  /**
   * The expert profile
   * @deprecated No longer applicable, use host field instead
   */
  expert: Expert;
  /** The expert profile */
  host: User;
  /** signifies if user is featured host or not */
  isFeatured: Scalars['Boolean'];
  /** Order in which to sort the hosts */
  order: Scalars['Int'];
  /** Agora UID during live stream */
  uid: Scalars['Uint32'];
  /** The users ID */
  userId: Scalars['ID'];
};

export type StreamInput = {
  /**
   * The agenda for the stream
   *
   * Note: if null is provided then we ignore the agenda, if you want to remove the agenda then provide an empty array
   */
  agenda?: InputMaybe<Array<StreamAgendaBulletPointInput>>;
  /** Which categories are related to this stream */
  categories: Array<Scalars['ID']>;
  /** In-depth description of the stream */
  contentSegments: Array<ContentSegmentInput>;
  /** Level of the content being discussed */
  entryLevel: EntryLevelValue;
  /** Experts that are part of this content piece */
  experts: Array<Scalars['ID']>;
  /** List of users hosting the stream */
  hostedBy?: InputMaybe<Array<Scalars['ID']>>;
  /** The image that the stream should have */
  image: Scalars['String'];
  /** isSensitive represents extra filter on fetching events to make person privacy intact */
  isSensitive: Scalars['Boolean'];
  /** Name of the stream */
  name: Scalars['String'];
  /** If only specific groups within the organisation should see the video */
  organisationGroups?: InputMaybe<Array<Scalars['ID']>>;
  /** If the stream should only be visible by a single organisation */
  organisationID?: InputMaybe<Scalars['ID']>;
  /** A brief introduction to the topic */
  preamble: Scalars['String'];
  /** When the stream should be published */
  publishedAt?: InputMaybe<Scalars['Time']>;
  /** Which regions the content piece can be viewed in */
  regionRestrictions?: InputMaybe<Array<Scalars['String']>>;
  /** What type of video this is, if not provided will default to RECORDING */
  source?: InputMaybe<StreamSource>;
  /** Tags represents hash tags which will be attached to content for better search and better content delivery */
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type StreamMedia = {
  __typename: 'StreamMedia';
  height: Scalars['Int'];
  language: Scalars['String'];
  length: Scalars['Int'];
  orientation: MediaOrientation;
  publishedAt?: Maybe<Scalars['Time']>;
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type StreamMediaInput = {
  /** When the stream media should be published */
  publishedAt?: InputMaybe<Scalars['Time']>;
  /** s3PresignedUrl path in s3 received after uploading the video via pre signed url */
  s3PresignedUrl?: InputMaybe<Scalars['String']>;
};

export enum StreamSource {
  /** An ongoing AWS Interactive Video Service broadcast */
  BroadcastAwsIvs = 'BROADCAST_AWS_IVS',
  /** An ongoing Cloudflare Live broadcast */
  BroadcastCloudflareLive = 'BROADCAST_CLOUDFLARE_LIVE',
  /** An ongoing Agora livestream */
  LivestreamAgora = 'LIVESTREAM_AGORA',
  /** An uploaded recording or a recording from a live stream */
  Upload = 'UPLOAD'
}

export enum StreamStatus {
  /**
   * A stream will transition into this status when startStream is called and user goes in backstage to
   * test out their video etc before going live to the audience
   */
  Backstage = 'BACKSTAGE',
  /** Newly created streams that are going to be used for live streams are created with this status */
  Created = 'CREATED',
  /** A stream will transition into this status either when it's uploaded or after a live event has finished */
  Encoding = 'ENCODING',
  /** Finished is the status assigned to a live stream once the host(s) have stopped streaming */
  Finished = 'FINISHED',
  /** A stream will transition into this status when user is ready to go live an the agora UID is provided */
  Live = 'LIVE',
  /**
   * The status a stream has once it's been transition into a recording, it's also the default status for streams
   * that are not created to be live streams.
   */
  Recording = 'RECORDING',
  /** Future streams that are created from an event are created with this status */
  Reserved = 'RESERVED'
}

export type StreamSubtitles = {
  __typename: 'StreamSubtitles';
  /** The language of the subtitles using ISO 639-1 */
  language: Scalars['String'];
  /** @deprecated Going to be removed. Not applicable for stream subtitles */
  length?: Maybe<Scalars['Int']>;
  /** When the subtitles were published */
  publishedAt?: Maybe<Scalars['Time']>;
  /** URL To the SRT file */
  srtUrl: Scalars['String'];
  /** @deprecated Use srt url instead */
  url?: Maybe<Scalars['String']>;
  /** URL To the VTT file */
  vttUrl: Scalars['String'];
};

export type StripeCoupon = {
  __typename: 'StripeCoupon';
  /**
   * if coupon is not active, all promo code that uses this coupon
   * will also automatically be unusable
   */
  active: Scalars['Boolean'];
  createdAt: Scalars['Time'];
  id: Scalars['String'];
  /**
   * indicate max amount of times this coupon and including
   * all it's child promo code can be redeem.
   */
  maxRedemptions: Scalars['Int'];
  /** percentage off discount for this coupon */
  percentOff: Scalars['Float'];
  /** the expiry date for this coupon and all it's child promo code */
  redeemBy?: Maybe<Scalars['Time']>;
  totalRedeemed: Scalars['Int'];
};

/** This presents all the types that can be a stripe customer */
export type StripeCustomer = Organisation | User;

export type StripePromotionCode = {
  __typename: 'StripePromotionCode';
  /**
   * indicate if the promo code is still usable.
   * Once deactivated, promo code cant be reactivated.
   */
  active: Scalars['Boolean'];
  code: Scalars['String'];
  coupon?: Maybe<StripeCoupon>;
  createdAt: Scalars['Time'];
  /**
   * the customer that has been authorized to use the code.
   * if this is empty, the promo code can be use by any registered stripe customer.
   */
  customer?: Maybe<StripeCustomer>;
  id: Scalars['String'];
  /**
   * indicate max amount of times this promo code can be redeem.
   * each customer can only redeem the promo code once.
   * promo code max redemption must be lower than it's base coupon's.
   */
  maxRedemptions: Scalars['Int'];
  totalRedeemed: Scalars['Int'];
};

export type StripePromotionCodeInput = {
  code: Scalars['String'];
  duration: Scalars['String'];
  maxRedemptions?: InputMaybe<Scalars['Uint64']>;
  onlyFirstTimeTransaction: Scalars['Boolean'];
  organisationIds?: InputMaybe<Array<Scalars['String']>>;
  percentOff: Scalars['Float'];
  redeemableUntil?: InputMaybe<Scalars['Time']>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type Subscription = {
  __typename: 'Subscription';
  /** Subscribe to the chat messages of a stream */
  chatMessages?: Maybe<StreamChatMessage>;
  /** Subscribe to the chat messages generic method */
  chatMessagesV2?: Maybe<ChatMessage>;
  /** This subscription pushes out all the on-going live streams that are currently live */
  onGoingStreams: Array<Stream>;
  /** Subscribe to a specific live stream to receive all the updates from it */
  stream?: Maybe<Stream>;
};

export type SubscriptionChatMessagesArgs = {
  streamId: Scalars['ID'];
};

export type SubscriptionChatMessagesV2Args = {
  id: Scalars['ID'];
};

export type SubscriptionStreamArgs = {
  id: Scalars['ID'];
};

export type SubscriptionConnection = {
  __typename: 'SubscriptionConnection';
  edges: Array<SubscriptionModel>;
  meta: PageInfo;
};

export type SubscriptionFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** SubscriptionLevel denotes what type of subscription plan the user belongs to */
export enum SubscriptionLevel {
  /** @deprecated No longer relevant */
  CommunityAccess = 'COMMUNITY_ACCESS',
  /** @deprecated No longer relevant */
  DigitalAccess = 'DIGITAL_ACCESS',
  Enterprise = 'ENTERPRISE',
  /** @deprecated No longer relevant */
  Free = 'FREE',
  /** @deprecated No longer relevant */
  FullAccess = 'FULL_ACCESS',
  Individual = 'INDIVIDUAL',
  Teams = 'TEAMS'
}

export type SubscriptionModel = {
  __typename: 'SubscriptionModel';
  accessType: OrganisationSubscriptionAccessType;
  billingPeriod?: Maybe<Scalars['String']>;
  cancelAtPeriodEnd: Scalars['Boolean'];
  coupon?: Maybe<Coupon>;
  couponCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  currentPeriodEnd?: Maybe<Scalars['Time']>;
  customerId?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['Time']>;
  /**
   * history list all previous ended (due to exceed subscription duration) or canceled
   * subscriptions
   *
   * only some fields are populated for historical subscriptions which are
   * customerId,subscriptionId,status,startAt,paidAt,currentPeriodEnd,cancelAtPeriodEnd
   */
  history?: Maybe<Array<SubscriptionModel>>;
  id: Scalars['ID'];
  maxSeatsCount: Scalars['Int'];
  paidAt?: Maybe<Scalars['Time']>;
  paymentPlatform: Scalars['String'];
  seats: Scalars['Int'];
  startAt?: Maybe<Scalars['Time']>;
  status: Scalars['String'];
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionPlan: SubscriptionPlan;
  taxRate: Array<TaxRate>;
  trialUntil?: Maybe<Scalars['Time']>;
  updatedAt: Scalars['Time'];
};

export type SubscriptionPlan = {
  __typename: 'SubscriptionPlan';
  amount: Scalars['Int'];
  countryCode: Scalars['String'];
  createdAt: Scalars['Time'];
  currency: Scalars['String'];
  deletedAt?: Maybe<Scalars['Time']>;
  /** @deprecated no longer relevant with new subscription types */
  digitalAccess?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  interval: Scalars['Int'];
  show: Scalars['Boolean'];
  stripePlanId: Scalars['String'];
  subscriptionLevel: SubscriptionLevel;
  updatedAt: Scalars['Time'];
};

export type SubscriptionPlanConnection = {
  __typename: 'SubscriptionPlanConnection';
  edges: Array<SubscriptionPlan>;
  meta: PageInfo;
};

export type SubscriptionPlanFilter = {
  basedOnGeoTagging?: InputMaybe<Scalars['Boolean']>;
  countryCode?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  /** @deprecated no longer relevant with new subscription types */
  digitalAccess?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  show?: InputMaybe<Scalars['Boolean']>;
  subscriptionLevel?: InputMaybe<Scalars['String']>;
};

export type SubscriptionPlanInput = {
  amount: Scalars['Int'];
  countryCode: Scalars['String'];
  currency: Scalars['String'];
  interval: Scalars['Int'];
  show: Scalars['Boolean'];
  subscriptionLevel: Scalars['String'];
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Free = 'FREE',
  Trial = 'TRIAL'
}

export type SuggestedUsersToFollowFilter = {
  /**
   * Include users that the current authenticated user is already following
   *
   * Default: false
   */
  includeUsersAlreadyFollowed?: InputMaybe<Scalars['Boolean']>;
  isAMemberOfSameOrganisation?: InputMaybe<Scalars['Boolean']>;
  isExpert?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  query?: InputMaybe<Scalars['String']>;
};

export type SuggestedUsersToInteractWithFilter = {
  isExpert?: InputMaybe<Scalars['Boolean']>;
  isMemberOfSameOrganisation?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
};

export type SurroundingCommentsConnection = {
  __typename: 'SurroundingCommentsConnection';
  comment: Comment;
  edges: Array<CommentEdge>;
  meta: CursorInfo;
};

export enum TargetType {
  Organisation = 'ORGANISATION',
  User = 'USER'
}

export type TaxRate = {
  __typename: 'TaxRate';
  active: Scalars['Boolean'];
  country: Scalars['String'];
  created: Scalars['Int'];
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  inclusive: Scalars['Boolean'];
  jurisdiction?: Maybe<Scalars['String']>;
  livemode?: Maybe<Scalars['Boolean']>;
  percentage: Scalars['Float'];
  state?: Maybe<Scalars['String']>;
  stripeTaxRateID: Scalars['String'];
  taxType: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type TaxRateConnection = {
  __typename: 'TaxRateConnection';
  edges: Array<TaxRate>;
  meta: PageInfo;
};

export type TaxRateFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  inclusive?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type TimeRangeFilter = {
  from: Scalars['Time'];
  to: Scalars['Time'];
};

/** Shows the timeline of a specific cohort */
export type Timeline = {
  __typename: 'Timeline';
  /** Count of users currently on the content */
  activeUserCount: Scalars['Int'];
  /** The chat associated to this timeline */
  chat: Chat;
  /** Content */
  content: ContentCard;
  /** Users which will be displayed over the content */
  featuredUsers: Array<User>;
  /** Unique identifier for the timeline */
  id: Scalars['ID'];
  /** List of all the users on the content */
  users: UserCursorCollection;
};

/** Shows the timeline of a specific cohort */
export type TimelineUsersArgs = {
  filter?: InputMaybe<TimelineUserFilter>;
  pagination?: InputMaybe<CursorPaginationInput>;
};

export type TimelineUserFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type TopCategoryConsumed = {
  __typename: 'TopCategoryConsumed';
  category?: Maybe<Category>;
  duration?: Maybe<Scalars['Int']>;
};

export type TrackingData = {
  current: Scalars['Int'];
  currentPlacement: Scalars['Int'];
  /** @deprecated backend automatically calculates this value */
  total?: InputMaybe<Scalars['Int']>;
};

export type Transcript = {
  __typename: 'Transcript';
  index: Scalars['Int'];
  page: Scalars['String'];
};

/**
 * TriggerFrequency allows users to choose when they want the audience to be automatically re-triggered.
 * For HOURLY, SIX_HOURLY, DAILY, WEEKLY we always compute based on current time.
 * For WEEKLY, user can choose which day to re-compute (can be more than 1).
 * For MONTHLY it will set for 1st of the month (we force due to some months have less day that other).
 */
export enum TriggerFrequency {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Never = 'NEVER',
  SixHourly = 'SIX_HOURLY',
  Weekly = 'WEEKLY'
}

export type UpdateAudienceInput = {
  /** The filter criteria for the audience */
  criteria: AudienceBoolFilterInput;
  /** User provided description of the audience */
  description: Scalars['String'];
  /** Name of the audience */
  name: Scalars['String'];
  /**
   * When this value is set to true it will remove users that no longer match the criteria of the audience when the
   * audience is refreshed either by the trigger frequency or manually
   */
  removeUsersInNextRefreshThatNoLongerMeetCriteria: Scalars['Boolean'];
  /** The frequency at which the audience should be refreshed */
  triggerFrequency: TriggerFrequency;
  /** If the trigger frequency is weekly then you specify which days */
  weeklyTriggerDays?: InputMaybe<Array<WeeklyTriggerDay>>;
};

export type UpdateComment = {
  /** To be deprecated: Use raw text instead */
  comment: Scalars['String'];
  commentId: Scalars['ID'];
  mentions?: InputMaybe<Array<InputMaybe<MentionInput>>>;
  rawText?: InputMaybe<Scalars['String']>;
  type: CommentType;
};

export type UpdateContentUserNote = {
  body: Scalars['String'];
  headline: Scalars['String'];
};

export type UpdateEbookResult = Ebook | NotFoundError | ValidationError;

/** Backoffice needs an API that updates both user and backoffice fields */
export type UpdateExpertUserInput = {
  /** Avatar image ID */
  avatarImage?: InputMaybe<Scalars['ID']>;
  /** Update the users biography */
  biography?: InputMaybe<Scalars['String']>;
  /** Which categories this user is considered to be an expert in */
  categories: Array<Scalars['ID']>;
  /** The users company */
  company?: InputMaybe<Scalars['String']>;
  /** Cover image ID */
  coverImage?: InputMaybe<Scalars['ID']>;
  /**
   * The users email
   *
   * This will be removed from this API Call in the future
   */
  email: Scalars['String'];
  /** facebookLink represents facebook profile url */
  facebookLink?: InputMaybe<Scalars['String']>;
  /** The users first name */
  firstName: Scalars['String'];
  /** twitterHandle represents instagram handle name */
  instagramHandle?: InputMaybe<Scalars['String']>;
  /** If an expert is a mentor */
  isMentor: Scalars['Boolean'];
  /** The users job title */
  jobTitle?: InputMaybe<Scalars['String']>;
  /** The users last name */
  lastName: Scalars['String'];
  /** linkedinLink represents linkedin profile url */
  linkedinLink?: InputMaybe<Scalars['String']>;
  /** If the mentor is available for mentoring this contains some brief information to their capabilities */
  mentorAvailableText: Scalars['String'];
  /** How much the mentor would charge for a session */
  mentorPriceText: Scalars['String'];
  /** If specified only groups within the provided organisation can view this thinkfluencer */
  organisationGroups: Array<Scalars['ID']>;
  /** If specified only users that belong to this organisation can view this thinkfluencer */
  organisationID?: InputMaybe<Scalars['ID']>;
  /** How important the thinkfluencer is when it comes to listen thinkfluencers */
  priority: Scalars['Int'];
  /** If the thinkfluencer is restricted to certain regions */
  restrictedRegions: Array<Scalars['String']>;
  /** Which roles the user should have, this only applies if the updating user is an admin */
  roles?: InputMaybe<Array<Scalars['String']>>;
  /** twitterHandle represents twitter handle name */
  twitterHandle?: InputMaybe<Scalars['String']>;
  /** Link to the users website */
  websiteLink?: InputMaybe<Scalars['String']>;
};

export type UpdateIamRoleResult = IamRole | NotFoundError | ValidationError;

export type UpdateOrganisationGroupInput = {
  name: Scalars['String'];
};

export type UpdatePodcastResult = NotFoundError | Podcast | ValidationError;

export type UpdatePostInput = {
  attachments: Array<InputMaybe<AttachmentInput>>;
  description: Scalars['String'];
  mentions?: InputMaybe<Array<MentionInput>>;
  postId: Scalars['ID'];
  privacy: PrivacySettings;
};

export type UpdatePostV2Result = NotFoundError | Post | ValidationError;

export type UpdateStreamResult = NotFoundError | Stream | ValidationError;

/** Backoffice org admins should be able to update a number of fields on a user */
export type UpdateUserAsOrgAdmin = {
  /** Avatar image ID */
  avatarImage?: InputMaybe<Scalars['ID']>;
  /** Update the users biography */
  biography: Scalars['String'];
  /** The users company */
  company: Scalars['String'];
  /** Cover image ID */
  coverImage?: InputMaybe<Scalars['ID']>;
  /** The users email */
  email: Scalars['String'];
  /** facebookLink represents facebook profile url */
  facebookLink: Scalars['String'];
  /** The users first name */
  firstName: Scalars['String'];
  /** twitterHandle represents instagram handle name */
  instagramHandle: Scalars['String'];
  /** If the user should be able to perform organisation live streams */
  isAbleToLiveStream: Scalars['Boolean'];
  /** If the user should be considered an expert within their own organisation, if called will add the org filter */
  isExpert: Scalars['Boolean'];
  /** The users job title */
  jobTitle: Scalars['String'];
  /** The users last name */
  lastName: Scalars['String'];
  /** linkedinLink represents linkedin profile url */
  linkedinLink: Scalars['String'];
  /** twitterHandle represents twitter handle name */
  twitterHandle: Scalars['String'];
  /** Link to the users website */
  websiteLink: Scalars['String'];
};

export type UpdateUserInput = {
  /** Avatar image ID */
  avatarImage?: InputMaybe<Scalars['ID']>;
  /** Update the users biography */
  biography?: InputMaybe<Scalars['String']>;
  /** The users businessEmail email */
  businessEmail?: InputMaybe<Scalars['String']>;
  /** The users company */
  company?: InputMaybe<Scalars['String']>;
  /** Cover image ID */
  coverImage?: InputMaybe<Scalars['ID']>;
  /**
   * The users email
   *
   * This will be removed from this API Call in the future
   */
  email: Scalars['String'];
  /** facebookLink represents facebook profile url */
  facebookLink?: InputMaybe<Scalars['String']>;
  /** The users first name */
  firstName: Scalars['String'];
  /** twitterHandle represents instagram handle name */
  instagramHandle?: InputMaybe<Scalars['String']>;
  /** The users job title */
  jobTitle?: InputMaybe<Scalars['String']>;
  /** The users last name */
  lastName: Scalars['String'];
  /** linkedinLink represents linkedin profile url */
  linkedinLink?: InputMaybe<Scalars['String']>;
  /** The users mobile number */
  mobileNumber?: InputMaybe<Scalars['String']>;
  /**
   * The users password, if not specific it's not updated.
   *
   * This will be removed in the future
   */
  password?: InputMaybe<Scalars['String']>;
  /** Which roles the user should have, this only applies if the updating user is an admin */
  roles?: InputMaybe<Array<Scalars['String']>>;
  /** Users job seniority */
  seniority?: InputMaybe<Scalars['String']>;
  /** twitterHandle represents twitter handle name */
  twitterHandle?: InputMaybe<Scalars['String']>;
  /** Link to the users website */
  websiteLink?: InputMaybe<Scalars['String']>;
};

export type UpdateUserLifegoalsInput = {
  lifegoals?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateUserProductTourTrackingInput = {
  /**
   * Indicates the step number the user is at in the onboarding journey
   *
   * This should be called after each step
   */
  journeyProgress: Scalars['Int'];
  state: ProductTourState;
};

export type UpsertEbookChaptersAndAudioResult =
  | Ebook
  | NotFoundError
  | ValidationError;

export type UpsertEbookChaptersInput = {
  chapters: Array<ChapterInput>;
  language: Scalars['String'];
  /** @deprecated calculated at backend now */
  length?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['Time']>;
};

export type UpsertPodcastAudioAndTranscriptResult =
  | NotFoundError
  | Podcast
  | ValidationError;

export type UpsertStreamMediaInput = {
  /** The StreamID. */
  id: Scalars['ID'];
  /** The language for media / subtitle. */
  language: Scalars['String'];
  /** input related to stream subtitle */
  subtitle?: InputMaybe<StreamMediaInput>;
  /** input related to stream video */
  video?: InputMaybe<StreamMediaInput>;
};

export type UpsertStreamMediaResult = NotFoundError | Stream | ValidationError;

export type User = {
  __typename: 'User';
  acceptedCommunityGuidelineAt?: Maybe<Scalars['Time']>;
  /** @deprecated Use subscription status instead. */
  accessLevel: SubscriptionLevel;
  /** If the user is archived this will show when that happened */
  archivedAt?: Maybe<Scalars['Time']>;
  /** General statistics over all the content pieces the user has been assigned */
  assignmentContentStatistics: Array<UserAssignmentContentStatistics>;
  /** Statistics over how well the user their assignments */
  assignmentStatistics: UserAssignmentStatistics;
  /** Assignments associated to the user */
  assignments: AssignmentConnection;
  authBlockUntil?: Maybe<Scalars['Time']>;
  /** Custom avatar image */
  avatarImage: Image;
  /** Users biography */
  biography?: Maybe<Scalars['String']>;
  /** @deprecated Use archivedAt instead */
  blocked: Scalars['Boolean'];
  /** All list of all the users blocked by this user */
  blockedUsers: UserCollection;
  /** Business email of the user */
  businessEmail: Scalars['String'];
  /** Trail cohorts that the user belong to */
  cohorts: CohortConnection;
  company: Scalars['String'];
  /**
   * Indicates whether the user has completed his/her onboarding process
   * @deprecated Replaced by isOnboardingCompleted to match proper naming conventions
   */
  completedOnboarding: Scalars['Boolean'];
  /** @deprecated This is deprecated and will be removed in the next major release */
  consumedTrialContent?: Maybe<Array<UserConsumedTrialContent>>;
  /** Retrieve the notes created by a user */
  contentCardsWithNotes: ContentConnection;
  /** Count of content user has completed */
  contentCompletedCount: Scalars['Int'];
  /** Count of content user has created */
  contentCreatedCount: Scalars['Int'];
  /** Get the recommended content for the user based on the Merlin project */
  contentRecommendations: ContentConnection;
  couponCode: Scalars['String'];
  couponRemainingCharges: Scalars['Int'];
  couponVisible: Scalars['Boolean'];
  /** Custom cover image if the user has uploaded an image */
  coverImage?: Maybe<Image>;
  /** When the user was created */
  createdAt: Scalars['Time'];
  creditCard?: Maybe<Array<UserCreditCard>>;
  /** When the user was deleted */
  deletedAt?: Maybe<Scalars['Time']>;
  devices: Array<Scalars['String']>;
  email: Scalars['String'];
  /** All of the events that the user has signed up to */
  events: EventConnection;
  /**
   * Exposes if the user is associated to an expert profile
   * @deprecated Use other fields like isExpert to know if current user is an Expert
   */
  expert?: Maybe<Expert>;
  /** Categories in which this user is considered an expert */
  expertInTheseCategories: Array<Category>;
  /** We have this field because backoffice needs a way to associate thinkfluencer to organisers */
  expertOrg?: Maybe<Organisation>;
  /**
   * Which groups within the org this user is visible to
   * @deprecated dont use for the same reason a thinfkluencerOrg
   */
  expertOrgGroups: Array<OrganisationGroup>;
  /** A list of expert recommendations for a user */
  expertRecommendations: ExpertCollection;
  facebookId?: Maybe<Scalars['String']>;
  /** facebookLink represents facebook profile url */
  facebookLink?: Maybe<Scalars['String']>;
  /** The users social feed */
  feed: FeedConnection;
  /** Content the user has finished */
  finishedContent: ConsumableContentCollection;
  firstName: Scalars['String'];
  /** No. of users following current user */
  followerCount: Scalars['Int'];
  /** user follower list */
  followers: UserCollection;
  /** No. of users followed by current user */
  followingCount: Scalars['Int'];
  /** user following list */
  followings: UserCollection;
  /** freeTrialEndsAt datetime when B2C user 30 days free trial will end */
  freeTrialEndsAt?: Maybe<Scalars['Time']>;
  googleId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** twitterHandle represents instagram handle name */
  instagramHandle?: Maybe<Scalars['String']>;
  /**
   * IsBillable tells if user added to any organisation, should they be count towards their billable users or not
   * billable means those users will counted towards the number of licences(user count) set during contract
   */
  isBillable: Scalars['Boolean'];
  /** If the current authenticated user is blocking this user */
  isBlockedByCurrentUser: Scalars['Boolean'];
  /** If the returned user is blocking the authenticated user */
  isBlockingCurrentUser: Scalars['Boolean'];
  /** If the user is an expert */
  isExpert: Scalars['Boolean'];
  /** isFollowing flag to check user follow the respective person or not */
  isFollowing: Scalars['Boolean'];
  /** If an expert is a mentor */
  isMentor: Scalars['Boolean'];
  /** Indicates whether the user has completed his/her onboarding process */
  isOnboardingCompleted: Scalars['Boolean'];
  jobTitle: Scalars['String'];
  joiningReason: Scalars['String'];
  lastName: Scalars['String'];
  /** The last time a API call was made from this users device */
  lastOnlineAt?: Maybe<Scalars['Time']>;
  /** Learning paths sorted by (1) in progress, (2) not started and (3) completed */
  learningPathSortedByStatus: LearningPathConnection;
  lifegoals: Array<Lifegoal>;
  /** linkedinLink represents linkedin profile url */
  linkedinLink?: Maybe<Scalars['String']>;
  /** If the mentor is available for mentoring this contains some brief information to their capabilities */
  mentorAvailableText?: Maybe<Scalars['String']>;
  /** How much the mentor would charge for a session */
  mentorPriceText?: Maybe<Scalars['String']>;
  microsoftId?: Maybe<Scalars['String']>;
  mobileNumber: Scalars['String'];
  /** Count of contents user has opened in current month */
  monthlyConsumedContentCount: Scalars['Int'];
  /** The number of new notifications user received since last opening the notification centre */
  newNotifications: Scalars['Int'];
  newsletter: Scalars['Boolean'];
  /** Show the users current notification preferences */
  notificationPreferences: Array<UserNotificationPreference>;
  /** All the user notifications */
  notifications: NotificationConnection;
  /** Content the user has started but is yet to finish */
  ongoingContent: ConsumableContentCollection;
  /**
   * Organisation reflects the organisation in the JWT token associated to the GraphQL API call
   *
   * **Note:** This ONLY works when called against the current user ID
   */
  organisation?: Maybe<Organisation>;
  /** Organisation groups the user is associated to */
  organisationGroups?: Maybe<Array<OrganisationGroup>>;
  /** Organisations the user is associated to, will be deprecated in the future allowing only a single organisatio */
  organisations: Array<Organisation>;
  /** The number of pending cohort invitations */
  pendingCohortInvitations: Scalars['Int'];
  permissions: Array<PermissionStatus>;
  /** @deprecated This is deprecated and will be removed in the next major release */
  persona?: Maybe<Persona>;
  /** @deprecated This is deprecated and will be removed in the next major release */
  personaQuestionAnswers: PersonaConnection;
  /** Preferred language of the user to watch or read content on */
  preferredLanguage?: Maybe<Scalars['String']>;
  /** Prioritize the experts in search results */
  priority: Scalars['Int'];
  productTourTracking: ProductTourTracking;
  /** Categories of which the user has recently consumed content from */
  recentlyConsumedCategories: Array<Category>;
  /** Retrieve the onboarding questions the user has replied to */
  repliedPollQuestions?: Maybe<PollQuestionConnection>;
  roles: Array<Scalars['String']>;
  seniority: Scalars['String'];
  /** URL Friendly slug that can be used as a unique identifier */
  slug: Scalars['String'];
  stats: UserStats;
  /** The amount of time a user has spent consuming content in seconds */
  statsContentConsumedTotal: Scalars['Int'];
  /**
   * Status of user account.
   * @deprecated we should not be mixing org member status with the user entity
   */
  status: OrgMemberStatus;
  stripeCustomers: Scalars['Map'];
  subscription?: Maybe<SubscriptionModel>;
  /** Current subscriptionStatus of the user. Can be free, active or trial */
  subscriptionStatus: SubscriptionStatus;
  /**
   * user following list from fastlane along with a set of users that the user
   * can possibly follow based on connections
   */
  suggestedUsersToFollow: UserCollection;
  suggestedUsersToInteractWith: UserCollection;
  /** twitterHandle represents twitter handle name */
  twitterHandle?: Maybe<Scalars['String']>;
  /** When the user was last updated */
  updatedAt: Scalars['Time'];
  /**
   * userActivities is used to display all the feed contents that
   * has been performed by other user or yourself
   */
  userActivities: FeedContentConnection;
  /** user top categories consumed */
  userTopCategories: Array<TopCategoryConsumed>;
  /** user top listened experts */
  userTopListenedExperts: Array<User>;
  /** Users personal website */
  websiteLink?: Maybe<Scalars['String']>;
};

export type UserAssignmentsArgs = {
  filter: AssignmentsFilter;
  sorting?: InputMaybe<SortOrdering>;
};

export type UserBlockedUsersArgs = {
  filter: BlockedUsersFilter;
};

export type UserCohortsArgs = {
  filter: CohortFilter;
  pagination?: InputMaybe<CursorPaginationInput>;
};

export type UserContentCardsWithNotesArgs = {
  filter: ContentUserNoteFilter;
};

export type UserEventsArgs = {
  filter?: InputMaybe<EventFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type UserFeedArgs = {
  filter: FeedFilter;
};

export type UserFinishedContentArgs = {
  filter: ConsumableContentFilter;
};

export type UserFollowersArgs = {
  filter?: InputMaybe<FollowersFollowingFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type UserFollowingsArgs = {
  filter?: InputMaybe<FollowersFollowingFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type UserLearningPathSortedByStatusArgs = {
  pagination: OffsetPagination;
};

export type UserNewNotificationsArgs = {
  filter?: InputMaybe<NotificationFilter>;
};

export type UserNotificationsArgs = {
  filter?: InputMaybe<NotificationFilter>;
  pagination?: InputMaybe<CursorPaginationInput>;
};

export type UserOngoingContentArgs = {
  filter: ConsumableContentFilter;
};

export type UserOrganisationsArgs = {
  isAdmin?: InputMaybe<Scalars['Boolean']>;
};

export type UserRepliedPollQuestionsArgs = {
  filter: OnboardingQuestionsFilter;
  pagination: OffsetPagination;
};

export type UserSuggestedUsersToFollowArgs = {
  filter?: InputMaybe<SuggestedUsersToFollowFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type UserSuggestedUsersToInteractWithArgs = {
  filter: SuggestedUsersToInteractWithFilter;
  sorting?: InputMaybe<SortOrdering>;
};

export type UserUserActivitiesArgs = {
  pagination?: InputMaybe<CursorPaginationInput>;
};

export type UserAssignmentContentStatistics = {
  __typename: 'UserAssignmentContentStatistics';
  /** The users progress in percent */
  completionRate: Scalars['Float'];
  /** The ID of the content piece */
  contentId: Scalars['ID'];
  /** The estimated length of the content piece */
  contentLength: Scalars['Int'];
  /** The name of the content piece */
  contentName: Scalars['String'];
};

export type UserAssignmentStatistics = {
  __typename: 'UserAssignmentStatistics';
  assignedEbooks: Scalars['Int'];
  assignedPodcasts: Scalars['Int'];
  assignedStreams: Scalars['Int'];
  assignedTrails: Scalars['Int'];
  completedEbooks: Scalars['Int'];
  completedPodcasts: Scalars['Int'];
  completedStreams: Scalars['Int'];
  completedTrails: Scalars['Int'];
};

export enum UserAssignmentStatisticsReportColumns {
  AssignmentCompletedDate = 'ASSIGNMENT_COMPLETED_DATE',
  AssignmentCompletionStatus = 'ASSIGNMENT_COMPLETION_STATUS',
  AssignmentConsumptionFlag = 'ASSIGNMENT_CONSUMPTION_FLAG',
  AssignmentId = 'ASSIGNMENT_ID',
  AssignmentLength = 'ASSIGNMENT_LENGTH',
  AssignmentName = 'ASSIGNMENT_NAME',
  AssignmentProgressPercentageBasedOnContentLength = 'ASSIGNMENT_PROGRESS_PERCENTAGE_BASED_ON_CONTENT_LENGTH',
  AssignmentProgressPercentageBasedOnContentPiece = 'ASSIGNMENT_PROGRESS_PERCENTAGE_BASED_ON_CONTENT_PIECE',
  AssignmentTimeSpent = 'ASSIGNMENT_TIME_SPENT',
  Email = 'EMAIL',
  NumberOfContentPiecesCompletedByTheUser = 'NUMBER_OF_CONTENT_PIECES_COMPLETED_BY_THE_USER',
  TigerhallUserId = 'TIGERHALL_USER_ID',
  TotalNumberOfContentPiecesInTheAssignment = 'TOTAL_NUMBER_OF_CONTENT_PIECES_IN_THE_ASSIGNMENT',
  UserName = 'USER_NAME'
}

export type UserAssignmentStatisticsReportFilter = {
  assignments?: InputMaybe<Array<Scalars['ID']>>;
  audienceId?: InputMaybe<Scalars['ID']>;
  columns: Array<UserAssignmentStatisticsReportColumns>;
  completionStatus?: InputMaybe<Array<CompletionStatus>>;
  consumptionFlag?: InputMaybe<ConsumptionFlag>;
  groups?: InputMaybe<Array<Scalars['ID']>>;
  includeArchivedUsers?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated use includeArchivedUsers instead */
  includeBlockedUsers?: InputMaybe<Scalars['Boolean']>;
  reportName: Scalars['String'];
  users?: InputMaybe<Array<Scalars['ID']>>;
};

export type UserChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type UserCollection = {
  __typename: 'UserCollection';
  edges: Array<User>;
  meta: PageInfo;
};

export type UserCollectionTracking = {
  __typename: 'UserCollectionTracking';
  bookmarkedAt?: Maybe<Scalars['Time']>;
  createdAt: Scalars['Time'];
  /** @deprecated Will be removed in the future because we have dropped curated lists */
  curatedListId?: Maybe<Scalars['ID']>;
  finishedAt?: Maybe<Scalars['Time']>;
  following?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  learningPathId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['Time']>;
};

export type UserCollectionTrackingFilter = {
  /** Don't return trails or curated lists that been finished */
  excludeFinished?: InputMaybe<Scalars['Boolean']>;
  finishedAt?: InputMaybe<Scalars['Time']>;
  following?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  /** @deprecated Will be removed in the future because we have dropped curated lists */
  type?: InputMaybe<CollectionType>;
};

export type UserCollectionTrackingInput = {
  /** @deprecated Will be removed in the future because we have dropped curated lists */
  collectionType?: InputMaybe<CollectionType>;
  /** @deprecated Will be removed in the future because we have dropped curated lists */
  curatedListId?: InputMaybe<Scalars['ID']>;
  finishedAt?: InputMaybe<Scalars['Time']>;
  following?: InputMaybe<Scalars['Boolean']>;
  learningPathId?: InputMaybe<Scalars['ID']>;
};

export enum UserCommentsLocation {
  Content = 'CONTENT',
  Feed = 'FEED'
}

export enum UserCommentsReportColumns {
  CommentDetail = 'COMMENT_DETAIL',
  CommentType = 'COMMENT_TYPE',
  ContentTitle = 'CONTENT_TITLE',
  Date = 'DATE',
  Email = 'EMAIL',
  Link = 'LINK',
  Location = 'LOCATION',
  TigerhallUserId = 'TIGERHALL_USER_ID',
  UserName = 'USER_NAME'
}

export type UserCommentsReportFilter = {
  /** fetch comments for the users matching the conditions in the provided dynamic audience id */
  audienceId?: InputMaybe<Scalars['ID']>;
  /** all the columns user wants to see in the report */
  columns: Array<UserCommentsReportColumns>;
  /** fetch comments on the provided list of contents only */
  contents?: InputMaybe<Array<Scalars['ID']>>;
  /** fetch comments for the provided list of groups only */
  groups?: InputMaybe<Array<Scalars['ID']>>;
  /** whether to include archived users or not */
  includeArchivedUsers: Scalars['Boolean'];
  /** fetch comments on the given location i.e either feed or content */
  location?: InputMaybe<UserCommentsLocation>;
  /** name of the report */
  reportName: Scalars['String'];
  /** fetch comments added by user in the provided time range */
  timeFilter?: InputMaybe<TimeRangeFilter>;
  /** fetch comments for the provided list of users only */
  users?: InputMaybe<Array<Scalars['ID']>>;
};

export type UserConsumedTrialContent = {
  __typename: 'UserConsumedTrialContent';
  consumedAt: Scalars['Time'];
  contentId: Scalars['ID'];
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export enum UserContentStatisticsReportColumns {
  ContentAssignedToUser = 'CONTENT_ASSIGNED_TO_USER',
  ContentCompletionDate = 'CONTENT_COMPLETION_DATE',
  ContentCompletionStatus = 'CONTENT_COMPLETION_STATUS',
  ContentConsumptionFlag = 'CONTENT_CONSUMPTION_FLAG',
  ContentId = 'CONTENT_ID',
  ContentName = 'CONTENT_NAME',
  ContentProgressPercentage = 'CONTENT_PROGRESS_PERCENTAGE',
  ContentStartDate = 'CONTENT_START_DATE',
  Email = 'EMAIL',
  EstimatedContentLength = 'ESTIMATED_CONTENT_LENGTH',
  OrganisationContentOrPublicContent = 'ORGANISATION_CONTENT_OR_PUBLIC_CONTENT',
  TigerhallUserId = 'TIGERHALL_USER_ID',
  TimeSpentOnContent = 'TIME_SPENT_ON_CONTENT',
  UserName = 'USER_NAME'
}

export type UserContentStatisticsReportFilter = {
  assignmentStatus?: InputMaybe<AssignmentStatusForReports>;
  audienceId?: InputMaybe<Scalars['ID']>;
  columns: Array<UserContentStatisticsReportColumns>;
  completionStatus?: InputMaybe<Array<CompletionStatus>>;
  consumptionFlag?: InputMaybe<ConsumptionFlag>;
  contents?: InputMaybe<Array<Scalars['ID']>>;
  groups?: InputMaybe<Array<Scalars['ID']>>;
  includeArchivedUsers?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated use includeArchivedUsers instead */
  includeBlockedUsers?: InputMaybe<Scalars['Boolean']>;
  reportName: Scalars['String'];
  users?: InputMaybe<Array<Scalars['ID']>>;
};

export type UserContentTracking = {
  __typename: 'UserContentTracking';
  EbookId?: Maybe<Scalars['String']>;
  EventId?: Maybe<Scalars['String']>;
  PodcastId?: Maybe<Scalars['String']>;
  StreamId?: Maybe<Scalars['String']>;
  bookmarkedAt?: Maybe<Scalars['Time']>;
  checkedInAt?: Maybe<Scalars['Time']>;
  createdAt: Scalars['Time'];
  /** Current represents the current page in an ebook, episode in a podcast (zero indexed) or for streams it should simply be 1 */
  current: Scalars['Int'];
  /** CurrentPlacement represents the current time in the podcast or stream for ebooks it will be zero */
  currentPlacement: Scalars['Int'];
  finishedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  isAttending: Scalars['Boolean'];
  /** @deprecated use bookmarkedAt */
  isBookmarked: Scalars['Boolean'];
  /** @deprecated use bookmarkedAt */
  isFavorite: Scalars['Boolean'];
  isFinished: Scalars['Boolean'];
  lastOpenedAt?: Maybe<Scalars['Time']>;
  /** Retrieve a users notes on the content card */
  notes: NotesConnection;
  /** Total represents the total number of pages in an ebook, episodes in a podcast or for streams it should simply be 1 */
  total: Scalars['Int'];
  updatedAt: Scalars['Time'];
  userRating?: Maybe<Scalars['Float']>;
};

export type UserContentTrackingNotesArgs = {
  filter: ContentUserNoteFilter;
};

export type UserCreditCard = {
  __typename: 'UserCreditCard';
  /**
   * Brand of credit card used
   *
   * - "American Express"
   * - "Discover"
   * - "Diners Club"
   * - "JCB"
   * - "MasterCard"
   * - "Unknown"
   * - "UnionPay"
   * - "Visa"
   */
  brand: Scalars['String'];
  /** Expiration month of the credit card */
  expMonth: Scalars['Int'];
  /** Expiration year of the credit card */
  expYear: Scalars['Int'];
  /**
   * What type of card
   *
   * - credit
   * - debit
   * - prepaid
   * - unknown
   */
  funding: Scalars['String'];
  /** Last four digits of the credit card */
  last4: Scalars['String'];
  /** Can either be apple_pay / google_pay or an empty string */
  tokenizationMethod: Scalars['String'];
};

export type UserCursorCollection = {
  __typename: 'UserCursorCollection';
  edges: Array<UserEdge>;
  meta: CursorInfo;
};

export type UserEdge = {
  __typename: 'UserEdge';
  cursor: Scalars['String'];
  user: User;
};

export type UserFilter = {
  createdAt?: InputMaybe<TimeRangeFilter>;
  email?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type UserInAppSubscriptionInput = {
  planId: Scalars['ID'];
  transactionReceipt: Scalars['String'];
};

export type UserNotificationPreference = {
  __typename: 'UserNotificationPreference';
  name: Scalars['String'];
  platform: NotificationPlatform;
  state: NotificationPreferenceState;
  type: NotificationType;
};

export type UserReaction = {
  __typename: 'UserReaction';
  createdAt: Scalars['Time'];
  emoji: Scalars['String'];
  id: Scalars['ID'];
  reactionType: ReactionType;
  user: User;
};

export type UserReactionConnection = {
  __typename: 'UserReactionConnection';
  edges: Array<UserReactionEdge>;
  meta: CursorInfo;
};

export type UserReactionEdge = {
  __typename: 'UserReactionEdge';
  cursor: Scalars['String'];
  userReaction: UserReaction;
};

export enum UserRole {
  Admin = 'admin',
  Expert = 'expert',
  Guest = 'guest',
  Livestream = 'livestream',
  OrgAdmin = 'org_admin',
  OrgLivestream = 'org_livestream',
  User = 'user'
}

export type UserStats = {
  __typename: 'UserStats';
  ebooksRead: Scalars['Int'];
  eventsAttended: Scalars['Int'];
  eventsBooked: Scalars['Int'];
  podcastsCompleted: Scalars['Int'];
  videosCompleted: Scalars['Int'];
};

export enum UserTrailStatisticsReportColumns {
  Email = 'EMAIL',
  NumberOfContentPiecesCompletedByTheUser = 'NUMBER_OF_CONTENT_PIECES_COMPLETED_BY_THE_USER',
  TigerhallUserId = 'TIGERHALL_USER_ID',
  TotalNumberOfContentPiecesInTheTrail = 'TOTAL_NUMBER_OF_CONTENT_PIECES_IN_THE_TRAIL',
  TrailCompletionDate = 'TRAIL_COMPLETION_DATE',
  TrailCompletionStatus = 'TRAIL_COMPLETION_STATUS',
  TrailConsumptionFlag = 'TRAIL_CONSUMPTION_FLAG',
  TrailId = 'TRAIL_ID',
  TrailLength = 'TRAIL_LENGTH',
  TrailName = 'TRAIL_NAME',
  TrailProgressPercentageBasedOnContentLength = 'TRAIL_PROGRESS_PERCENTAGE_BASED_ON_CONTENT_LENGTH',
  TrailProgressPercentageBasedOnContentPiece = 'TRAIL_PROGRESS_PERCENTAGE_BASED_ON_CONTENT_PIECE',
  TrailTimeSpent = 'TRAIL_TIME_SPENT',
  UserName = 'USER_NAME'
}

export type UserTrailStatisticsReportFilter = {
  audienceId?: InputMaybe<Scalars['ID']>;
  columns: Array<UserTrailStatisticsReportColumns>;
  completionStatus?: InputMaybe<Array<CompletionStatus>>;
  consumptionFlag?: InputMaybe<ConsumptionFlag>;
  groups?: InputMaybe<Array<Scalars['ID']>>;
  includeArchivedUsers?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated use includeArchivedUsers instead */
  includeBlockedUsers?: InputMaybe<Scalars['Boolean']>;
  reportName: Scalars['String'];
  trails?: InputMaybe<Array<Scalars['ID']>>;
  users?: InputMaybe<Array<Scalars['ID']>>;
};

export enum UserType {
  B2B = 'B2B',
  B2C = 'B2C'
}

export type ValidationError = {
  __typename: 'ValidationError';
  fieldErrors: Array<FieldError>;
};

export type VideoBroadcastConfiguration = {
  __typename: 'VideoBroadcastConfiguration';
  /** The RTMP ingest URL for the stream */
  rtmpUrl: Scalars['String'];
  /** The stream key to the video file */
  streamKey: Scalars['String'];
};

export enum WeeklyTriggerDay {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}
