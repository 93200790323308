import * as React from 'react';
import { formatShortContentDuration } from '@tigerhall/core';
import { IconClock } from '@tigerhall/icons';
import { Text, Icon } from '@chakra-ui/react';

import type { NoEventTimeBadgeProps, ComponentsProps } from '../types';

export function ContentDuration({
  duration,
  iconProps,
  textProps
}: Omit<NoEventTimeBadgeProps, 'typename'> & ComponentsProps) {
  const formattedDuration = duration
    ? formatShortContentDuration(duration)
    : undefined;

  return (
    <>
      <Icon as={IconClock} {...iconProps} />
      <Text
        color="white"
        lineHeight="none"
        fontWeight="bold"
        fontSize={{
          base: 'x-small',
          lg: 'xs'
        }}
        noOfLines={1}
        {...textProps}
      >
        {formattedDuration}
      </Text>
    </>
  );
}
