import { useBreakpointValue } from '@chakra-ui/react';
import {
  LiveStream,
  type ExpertBasicInfo,
  type LiveContentProps,
  type PlayerModalProps
} from '@tigerhall/components';
import { getIsTemporarilyHiddenAndPaused, getOrgIdFromToken } from 'app/state';
import { getUrlToShareForLiveContent } from 'components/ui/ContentPlayer/components/LiveContentConnected/utils';
import { LiveStreamSubscription } from 'generated';
import { useAppSelector, useNavigationListener } from 'hooks';
import { useContentActions } from 'modules/application/hooks/useContentActions';
import { useCallback, useMemo } from 'react';

// Accenture India, Adobe
const ORGS_TO_USE_PROXY = [
  '5116a693-75a1-4df0-96d9-b87acbaa8ae0',
  'f5dd5589-9333-42e9-85c3-5b41175aef84'
];

export interface LiveStreamPlayerProps {
  /**
   * Stream object
   */
  stream: NonNullable<LiveStreamSubscription['stream']>;
  onFinished?: LiveContentProps['onFinished'];
  onClose: PlayerModalProps['onMinimize'];
  onMaximize: LiveContentProps['onMaximize'];
  isMinimized: LiveContentProps['isMinimized'];
  setIsMinimized: (value: boolean) => void;
}

export function LiveStreamPlayer({
  stream,
  onFinished,
  onMaximize,
  isMinimized,
  setIsMinimized,
  onClose
}: Readonly<LiveStreamPlayerProps>) {
  const isHidden = useAppSelector(getIsTemporarilyHiddenAndPaused);
  const { trackProgress, trackFinished } = useContentActions();

  useNavigationListener(() => {
    if (!isMinimized) {
      setIsMinimized(true);
    }
  });

  const isMobile = useBreakpointValue(
    { base: true, lg: false },
    {
      fallback: 'lg',
      ssr: false
    }
  );

  const meta = useMemo(
    () => ({
      contentId: stream.id,
      contentName: stream.name,
      preamble: stream.preamble
    }),
    [stream]
  );

  const orgId = useAppSelector(getOrgIdFromToken);

  const params = new URLSearchParams(window.location.search);

  const hasProxyDebugParam = params.get('proxy') === 'true';

  const shouldUseProxy =
    hasProxyDebugParam || (!!orgId && ORGS_TO_USE_PROXY.includes(orgId));

  const featuredHost =
    stream.hosts.find((host) => host.isFeatured) || stream.hosts[0];

  const featuredUser: ExpertBasicInfo = {
    ...featuredHost.expert,
    __typename: 'Expert'
  };

  const onTrackProgress = useCallback(
    (progress: number) => trackProgress({ content: stream, progress }),
    [stream, trackProgress]
  );

  const onTrackFinished = useCallback(
    () => trackFinished(stream),
    [stream, trackFinished]
  );

  const urlToShare = getUrlToShareForLiveContent({
    id: stream.id,
    source: stream.source
  });

  return (
    <LiveStream
      channel={stream.channel}
      appId={import.meta.env.VITE_AGORA_APP_ID}
      shouldUseProxy={shouldUseProxy}
      meta={meta}
      featuredUser={featuredUser}
      featureHostId={featuredHost.uid}
      urlToShare={urlToShare}
      disableShare={isMobile}
      onTrackProgress={onTrackProgress}
      onTrackFinished={onTrackFinished}
      onFinished={onFinished}
      isHidden={isHidden}
      isMinimized={isMinimized}
      streamStatus={stream.status}
      onClose={() => {
        onClose();
        setIsMinimized(false);
      }}
      onMaximize={onMaximize}
      onMinimize={() => {
        setIsMinimized(true);
      }}
    />
  );
}
