import { Button, HStack, Text, useToast } from '@chakra-ui/react';
import * as segment from '@tigerhall/analytics';
import { TrackedLink } from '@tigerhall/components';
import { SUPPORT_URL } from '@tigerhall/core';
import { useState } from 'react';
import { captureException } from '@sentry/react';

import { useAppSelector } from '~/hooks';
import { getCustomerType } from '~/app/state';
import { clearCache } from '~/api/apollo';
import { DarkPanel } from '~/components/ui';
import { LeavingReasonModal } from '../LeavingReasonModal';

export function UserAccountActions() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const customerType = useAppSelector(getCustomerType);
  const toast = useToast();

  const isEnterpriseOrTeamUser =
    customerType === 'ENTERPRISE' || customerType === 'TEAM';

  function onDeleteButtonClick() {
    setIsModalOpen(true);
    segment.deleteAccountClicked({
      customerType
    });
  }

  async function onClearCacheClick() {
    await clearCache();
    segment.clearCacheClicked({
      customerType
    });

    toast({
      title: 'Success',
      description: 'Cache cleared',
      status: 'success',
      duration: 5000,
      isClosable: true
    });
  }

  return (
    <>
      {isModalOpen ? (
        <LeavingReasonModal setModalOpen={setIsModalOpen} />
      ) : null}

      <DarkPanel>
        <Text fontSize="1rem" fontWeight="500" color="darkGrey.50">
          To share your ideas and feedback, drop a note to
          support@tigerhall.com. For technical support visit our{' '}
          <TrackedLink
            name="SUPPORT"
            href={SUPPORT_URL}
            color="tigerOrange.400"
          >
            Help Center
          </TrackedLink>
          . Thank you!
        </Text>
        <HStack mt={8} spacing={4}>
          <Button
            variant="outline"
            size="lg"
            onClick={() => {
              onClearCacheClick().catch((error) => {
                captureException(error, {
                  extra: { customerType }
                });
              });
            }}
          >
            Clear Cache
          </Button>
          {!isEnterpriseOrTeamUser ? (
            <Button variant="outline" size="lg" onClick={onDeleteButtonClick}>
              Delete Account
            </Button>
          ) : null}
        </HStack>
      </DarkPanel>
    </>
  );
}
