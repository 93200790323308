import {
  Flex,
  GridItem,
  Icon,
  IconButton,
  Image,
  LinkBox,
  Text
} from '@chakra-ui/react';
import { resizeImage } from '@tigerhall/core';
import { IconLink, IconX } from '@tigerhall/icons';
import { LinkOverlay } from '@tigerhall/components';

export interface PillVariantProps {
  title: string;
  description: string;
  url: string;
  favIconUrl?: string;
  onRemove?: () => unknown;
}

export function PillVariant({
  title,
  description,
  url,
  favIconUrl,
  onRemove
}: Readonly<PillVariantProps>) {
  return (
    <GridItem
      p={3}
      flex={1}
      borderWidth={1}
      borderColor={'whiteAlpha.500'}
      borderRadius={8}
      bg={'blackAlpha.100'}
      pos={'relative'}
      _hover={{ borderColor: 'whiteAlpha.600' }}
    >
      <LinkBox>
        <Flex justifyContent={'space-between'} gap={1}>
          <Flex gap={2} alignItems={'center'}>
            {favIconUrl ? (
              <Image
                alt={'FavIcon'}
                objectFit="cover"
                src={resizeImage({
                  url: favIconUrl,
                  width: 20,
                  height: 20
                })}
                boxSize={'1rem'}
              />
            ) : (
              <Icon
                as={IconLink}
                boxSize={'1rem'}
                color={'lightGrey.600'}
                data-testid={'pill-variant-link'}
              />
            )}
            <Text fontSize={'sm'} noOfLines={1} mr={5} fontWeight={'bold'}>
              <LinkOverlay
                href={url}
                isExternal
                _hover={{ textDecoration: 'none' }}
              >
                {title}
              </LinkOverlay>
            </Text>
            {onRemove ? (
              <IconButton
                aria-label={'Remove Link Preview'}
                onClick={onRemove}
                size={'xs'}
                variant={'ghost'}
                pos={'absolute'}
                right={0}
                icon={
                  <Icon as={IconX} boxSize={'1rem'} color={'lightGrey.600'} />
                }
              />
            ) : null}
          </Flex>
        </Flex>
        <Text fontSize={'sm'} noOfLines={1}>
          {description}
        </Text>
      </LinkBox>
    </GridItem>
  );
}
