import { ContentType } from '@tigerhall/core';
import { setPlayingContent, type PlayingContent } from 'app/state';
import { useAppDispatch } from 'hooks';
import { useGetPlaylistInfoFromURL } from 'hooks/useGetPlaylistInfoFromURL';

type PlayPodcastParams = Pick<PlayingContent, 'id'>;

export function usePodcastAction() {
  const dispatch = useAppDispatch();
  const { playlistType, playlistId, playlistTitle, contentUrl } =
    useGetPlaylistInfoFromURL();

  const playPodcast = (params: PlayPodcastParams) => {
    dispatch(
      setPlayingContent({
        ...params,
        playlistType,
        playlistId,
        playlistTitle,
        contentUrl,
        type: ContentType.Podcast
      })
    );
  };

  return {
    playPodcast
  };
}
