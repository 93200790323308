import { UIEventHandler, useState, useTransition } from 'react';

/**
 * Hook to show an overlay when the user has scrolled to the top or bottom of a scrollable container,
 * to indicate that there is more content to be seen.
 */
export function useEdgesOverlay() {
  const [, startTransition] = useTransition();

  const [overlayState, setOverlayState] = useState({
    hasTopOverlay: false,
    hasBottomOverlay: false
  });

  const handleScroll: UIEventHandler<HTMLDivElement> = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    const updatedOverlayShowing = {
      hasBottomOverlay: scrollTop + clientHeight < scrollHeight,
      hasTopOverlay: !!scrollTop
    };

    if (
      overlayState.hasBottomOverlay !==
        updatedOverlayShowing.hasBottomOverlay ||
      overlayState.hasTopOverlay !== updatedOverlayShowing.hasTopOverlay
    ) {
      startTransition(() => {
        setOverlayState(updatedOverlayShowing);
      });
    }
  };

  return {
    handleScroll,
    hasTopOverlay: overlayState.hasTopOverlay,
    hasBottomOverlay: overlayState.hasBottomOverlay
  };
}
