import * as React from 'react';
import { Modal, ModalCloseButton, ModalContent } from '@chakra-ui/react';

import { ImageCrop, ImageCropProps } from './ImageCrop';

export interface ImageCropModalProps extends ImageCropProps {
  closeModal: () => void;
  isCropModalOpen: boolean;
}

export function ImageCropModal({
  closeModal,
  isCropModalOpen,
  src,
  onSave,
  size,
  circularCrop
}: ImageCropModalProps) {
  return (
    <Modal onClose={closeModal} isOpen={isCropModalOpen} size="3xl">
      <ModalCloseButton />
      <ModalContent backgroundColor="black" width="auto">
        <ImageCrop
          src={src}
          size={size}
          onSave={onSave}
          circularCrop={circularCrop}
        />
      </ModalContent>
    </Modal>
  );
}
