import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssignmentTitleByIdQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
}>;


export type GetAssignmentTitleByIdQuery = { __typename?: 'Query', assignment: { __typename: 'Assignment', id: string, assignmentTitle: string } };

export type GetTrailTitleByIdQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;


export type GetTrailTitleByIdQuery = { __typename?: 'Query', learningPath?: { __typename: 'LearningPath', id: string, name: string } | null };


export const GetAssignmentTitleByIdDocument = gql`
    query GetAssignmentTitleById($id: ID!) {
  assignment(id: $id) {
    id
    assignmentTitle
    __typename
  }
}
    `;

/**
 * __useGetAssignmentTitleByIdQuery__
 *
 * To run a query within a React component, call `useGetAssignmentTitleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentTitleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentTitleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssignmentTitleByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentTitleByIdQuery, GetAssignmentTitleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentTitleByIdQuery, GetAssignmentTitleByIdQueryVariables>(GetAssignmentTitleByIdDocument, options);
      }
export function useGetAssignmentTitleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentTitleByIdQuery, GetAssignmentTitleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentTitleByIdQuery, GetAssignmentTitleByIdQueryVariables>(GetAssignmentTitleByIdDocument, options);
        }
export type GetAssignmentTitleByIdQueryHookResult = ReturnType<typeof useGetAssignmentTitleByIdQuery>;
export type GetAssignmentTitleByIdLazyQueryHookResult = ReturnType<typeof useGetAssignmentTitleByIdLazyQuery>;
export type GetAssignmentTitleByIdQueryResult = Apollo.QueryResult<GetAssignmentTitleByIdQuery, GetAssignmentTitleByIdQueryVariables>;
export const GetTrailTitleByIdDocument = gql`
    query GetTrailTitleById($id: String!) {
  learningPath(id: $id) {
    id
    name
    __typename
  }
}
    `;

/**
 * __useGetTrailTitleByIdQuery__
 *
 * To run a query within a React component, call `useGetTrailTitleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrailTitleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrailTitleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTrailTitleByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTrailTitleByIdQuery, GetTrailTitleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrailTitleByIdQuery, GetTrailTitleByIdQueryVariables>(GetTrailTitleByIdDocument, options);
      }
export function useGetTrailTitleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrailTitleByIdQuery, GetTrailTitleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrailTitleByIdQuery, GetTrailTitleByIdQueryVariables>(GetTrailTitleByIdDocument, options);
        }
export type GetTrailTitleByIdQueryHookResult = ReturnType<typeof useGetTrailTitleByIdQuery>;
export type GetTrailTitleByIdLazyQueryHookResult = ReturnType<typeof useGetTrailTitleByIdLazyQuery>;
export type GetTrailTitleByIdQueryResult = Apollo.QueryResult<GetTrailTitleByIdQuery, GetTrailTitleByIdQueryVariables>;