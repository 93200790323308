import * as React from 'react';
import { ColumnInstance, Hooks, TableOptions, useSortBy } from 'react-table';
import { Icon, VStack } from '@chakra-ui/react';
import { IconCaretDownFilled } from '@tigerhall/icons';

import { TableProps } from '../types';

type Params = {
  reFetchData?: TableProps['reFetchData'];
  pageMeta?: TableProps['pageMeta'];
};

const ICON_SIZE = '.375rem';

const createOptions =
  <D extends object = {}>({ reFetchData }: Params) =>
  (options: TableOptions<D>) => {
    const sortingOptions = {
      manualSortBy: !!reFetchData
    };
    return {
      ...options,
      ...sortingOptions
    };
  };

const TriangleDown = () => (
  <Icon as={IconCaretDownFilled} w={ICON_SIZE} h={ICON_SIZE} />
);

const TriangleUp = () => (
  <Icon
    as={IconCaretDownFilled}
    w={ICON_SIZE}
    h={ICON_SIZE}
    transform="auto"
    rotate="180deg"
  />
);

const getSortedIcon = (isSortedDesc: boolean | undefined) => {
  return isSortedDesc ? <TriangleDown /> : <TriangleUp />;
};

const getHeaderProps = <D extends object = {}>(
  props: any,
  { column }: { column: ColumnInstance<D> }
) => {
  // Don't show sorting on header selection because that will never work
  if (props.key === 'header_selection') {
    return [props, {}];
  }

  return [
    props,
    {
      sorting: (
        <VStack mx="0.4rem" spacing={0} justifyContent="center">
          {column.isSorted ? (
            getSortedIcon(column.isSortedDesc)
          ) : (
            <>
              <TriangleUp />
              <TriangleDown />
            </>
          )}
        </VStack>
      )
    }
  ];
};

/** handle onChange and checked props for Select all button
 **/
export function createUseTableSorting<D extends object = {}>({
  reFetchData
}: Params): any {
  return [
    useSortBy,
    (hooks: Hooks<D>) => {
      hooks.useOptions.push(createOptions({ reFetchData }));
      hooks.getHeaderProps.push(getHeaderProps);
    }
  ];
}
