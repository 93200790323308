import { ReactionType } from '~/types';

export type ReactionsEmoji =
  (typeof REACTIONS)[keyof typeof REACTIONS]['emoji'];

export type ReactionsLabel =
  (typeof REACTIONS)[keyof typeof REACTIONS]['label'];

export type ReactionsLabeUppercase =
  (typeof REACTIONS)[keyof typeof REACTIONS]['labelUppercase'];

export const REACTIONS = {
  [ReactionType.Heart]: {
    emoji: '🧡',
    label: 'Like',
    labelUppercase: 'LIKE'
  },
  [ReactionType.Bulb]: {
    emoji: '💡',
    label: 'Insightful',
    labelUppercase: 'INSIGHTFUL'
  },
  [ReactionType.Clap]: {
    emoji: '👏',
    label: 'Celebrate',
    labelUppercase: 'CELEBRATE'
  },
  [ReactionType.Think]: {
    emoji: '🤔',
    label: 'Curious',
    labelUppercase: 'CURIOUS'
  },
  [ReactionType.Smile]: {
    emoji: '😂',
    label: 'Joy',
    labelUppercase: 'JOY'
  },
  [ReactionType.Tiger]: {
    emoji: '🐯',
    label: 'Roar',
    labelUppercase: 'ROAR'
  },
  [ReactionType.Llama]: {
    emoji: '🦙',
    label: 'Meh',
    labelUppercase: 'MEH'
  }
} as const;
