import { useMemo } from 'react';
import { Flex, Spinner, Text, useBreakpointValue } from '@chakra-ui/react';
import { useGetStreamChatMessagesQuery } from 'generated';

import { SingleChatMessage } from '../ChatTab';

export interface ChatReplayTabProps {
  id: string;
}

export function ChatReplayTab({ id }: Readonly<ChatReplayTabProps>) {
  const { data, loading } = useGetStreamChatMessagesQuery({
    fetchPolicy: 'cache-first',
    variables: {
      id: id
    }
  });

  const isMobile = useBreakpointValue(
    { base: true, lg: false },
    {
      fallback: 'lg',
      ssr: false
    }
  );

  const sortedStreamChatMessages = useMemo(
    () =>
      [...(data?.streamChatMessages || [])]?.sort((messageA, messageB) =>
        (messageA?.timestamp || 0) > (messageB?.timestamp || 0) ? 1 : -1
      ),
    [data?.streamChatMessages]
  );

  const streamChatMessages = sortedStreamChatMessages || [];

  if (loading) {
    return (
      <Flex boxSize={'full'} alignItems={'center'} justifyContent={'center'}>
        <Flex
          pos={'relative'}
          px={4}
          gap={4}
          /** Need To Translate the component as the tab has padding top and bottom of 1rem
           *  If not done will look off centred
           */
          transform={{ lg: 'translateY( -2rem)' }}
          alignItems={'center'}
        >
          <Text color={'lightGrey.600'} fontSize={{ base: 'md', lg: 'lg' }}>
            Loading Chats
          </Text>
          <Spinner color="tigerOrange.600" emptyColor="whiteAlpha.200" />
        </Flex>
      </Flex>
    );
  }

  if (streamChatMessages.length === 0) {
    return (
      <Flex boxSize={'full'} alignItems={'center'} justifyContent={'center'}>
        <Flex
          pos={'relative'}
          flexDir={'column'}
          gap={3}
          maxW={'20rem'}
          /** Need To Translate the component as the tab has padding top and bottom of 1rem
           *  If not done will look off centred
           */
          transform={{ lg: 'translateY( -2rem)' }}
        >
          <Text
            color={'lightGrey.200'}
            fontWeight={'bold'}
            fontSize={{ base: 'xl', lg: '2xl' }}
            textAlign={'center'}
          >
            No messages
          </Text>
          <Text
            color={'lightGrey.600'}
            fontSize={'md'}
            lineHeight={1.5}
            textAlign={'center'}
          >
            No messages were recorded during this livestream.
          </Text>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex flexDir={'column'} px={4} gap={isMobile ? 1 : 3} h={'full'}>
      {streamChatMessages.filter(Boolean).map((message, index) => (
        <SingleChatMessage
          containerStyles={index === 0 ? { mt: 'auto' } : {}}
          key={`chat-message-${message?.id}`}
          nameForTrackLink="CHAT_REPLAY_USER"
          userId={message?.user?.id}
          userName={`${message?.user.firstName} ${message?.user.lastName?.[0]}`}
          message={message?.content}
        />
      ))}
    </Flex>
  );
}
