import * as React from 'react';
import { Box, VStack } from '@chakra-ui/react';

import { PlanCard } from './children/PlanCard';
import { PlansTable } from './children/PlansTable';
import { subscriptionPlans } from './fixtures';

export function Paywall() {
  return (
    <VStack width="100%" pt="5rem" px="1rem">
      <Box
        display={{
          base: 'none',
          lg: 'flex'
        }}
      >
        <PlansTable plans={subscriptionPlans} />
      </Box>
      <VStack
        width="100%"
        display={{
          base: 'flex',
          lg: 'none'
        }}
      >
        {subscriptionPlans.map((plan) => (
          <PlanCard
            key={plan.heading}
            type={plan.type}
            heading={plan.heading}
            subheading={plan.subheading}
            description={plan.description}
            plusFeatures={plan.plusFeatures?.map((plusFeature) => plusFeature)}
            features={plan.features.map((feature) => feature)}
            cta={plan?.cta}
          />
        ))}
      </VStack>
    </VStack>
  );
}
