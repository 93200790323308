import { createContext, ReactNode, useContext, useMemo } from 'react';

import { UseMediaPlayerReturn } from '../hooks';

interface UseMediaPlayerReturnExtended extends UseMediaPlayerReturn {
  onNextTrack: () => void;
  onPrevTrack: () => void;
}

interface MediaPlayerContextReturn extends UseMediaPlayerReturnExtended {
  isContextLoaded: boolean;
}

const mock = () => console.warn('MediaPlayerContextProvider not found');

const MediaPlayerContext = createContext<MediaPlayerContextReturn>({
  isContextLoaded: false,

  seekDelta: mock,
  seekTo: mock,
  play: mock,
  pause: mock,
  togglePlay: mock,
  setVolume: mock,
  setPlaybackRate: mock,

  onNextTrack: mock,
  onPrevTrack: mock
});

export interface MediaPlayerContextProviderProps
  extends Omit<UseMediaPlayerReturnExtended, 'onNextTrack' | 'onPrevTrack'> {
  children: ReactNode;

  /** override the default `onNextTrack` callback to be optional */
  onNextTrack?: () => void;
  /** override the default `onPrevTrack` callback to be optional */
  onPrevTrack?: () => void;
}

export function MediaPlayerContextProvider({
  children,
  ...props
}: MediaPlayerContextProviderProps) {
  const value = useMemo(
    () => ({
      onNextTrack: mock,
      onPrevTrack: mock,
      ...props,

      isContextLoaded: true
    }),
    [props]
  );

  return (
    <MediaPlayerContext.Provider value={value}>
      {children}
    </MediaPlayerContext.Provider>
  );
}

export function useMediaPlayerContext() {
  const context = useContext(MediaPlayerContext);

  if (!context) {
    throw new Error(
      'useMediaPlayerContext must be used within a MediaPlayerContextProvider'
    );
  }

  return context;
}
