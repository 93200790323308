import * as React from 'react';
import { Box, Icon, IconButton, Image } from '@chakra-ui/react';
import { resizeImage } from '@tigerhall/core';
import { IconChevronLeft } from '@tigerhall/icons';
import { useNavigate } from 'react-router-dom';

type BannerImageProps = {
  bannerImgUri: string;
  name?: string;
};

export function BannerImage({ bannerImgUri, name = '' }: BannerImageProps) {
  const navigate = useNavigate();

  return (
    <>
      <IconButton
        aria-label="close"
        position="absolute"
        minH="2.25rem"
        minW="2.25rem"
        zIndex="1"
        icon={<Icon as={IconChevronLeft} w="1rem" h="1rem" color="white" />}
        left={4}
        top={4}
        backgroundColor="darkGrey.400"
        _hover={{
          bg: 'black'
        }}
        borderRadius="full"
        borderColor="none"
        onClick={() => {
          navigate(-1);
        }}
        display={{ base: 'block', md: 'none' }}
      />
      <Image
        w="full"
        h="100%"
        src={
          bannerImgUri
            ? resizeImage({ url: bannerImgUri, width: 680, height: 170 })
            : ''
        }
        alt={name}
        objectFit="cover"
        borderTopRadius={{
          base: 0,
          md: '2rem'
        }}
      />
    </>
  );
}
