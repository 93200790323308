import { Button, type ButtonProps, useDisclosure } from '@chakra-ui/react';

import { useGetCurrentUserQuery } from '~/generated';
import { BlockUnblockUserModal } from '~/components/ui/modals';

interface BlockButtonProps {
  /**
   * Whether the current logged-in user is blocking the owner of the `userId`
   */
  isBlocked: boolean;
  /**
   * Whether the owner of the `userId` is a thinkfluencer
   */
  isThinkfluencer: boolean;
  /**
   * The id of the user to follow/unfollow
   */
  userId: string;
  /**
   * The size of the button.
   *
   * @default 'md'
   */
  size?: ButtonProps['size'];
}

export function BlockButton({
  isBlocked,
  isThinkfluencer,
  userId,
  size = 'md'
}: Readonly<BlockButtonProps>) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first'
  });

  const loggedInUserId = data?.me?.id;

  return loggedInUserId !== userId ? (
    <>
      <Button
        zIndex="base"
        variant="solid"
        minWidth="6rem"
        size={size}
        onClick={onOpen}
      >
        {isBlocked ? 'Unblock' : 'Block'}
      </Button>
      <BlockUnblockUserModal
        userId={userId}
        isThinkfluencer={isThinkfluencer}
        isBlockedByCurrentUser={isBlocked}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  ) : null;
}
