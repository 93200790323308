import { Flex, Text } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import type { PlaylistType } from '@tigerhall/analytics';
import {
  type DisplayableContentType,
  EventType,
  getContentName
} from '@tigerhall/core';

import { TrackedLink } from '~/tracked';

function getParentTypeHeader(parentType: PlaylistType | string) {
  switch (parentType) {
    case 'Assignment':
      return 'PLAYING FROM ASSIGNMENT';
    case 'Trail':
      return 'PLAYING FROM TRAIL';
    default:
      return '';
  }
}

interface ContentHeaderProps {
  contentType: DisplayableContentType;
  playlistTitle?: string;
  playlistType?: PlaylistType;
  contentUrl?: string;
}

interface PlaylistHeaderProps {
  contentType: DisplayableContentType;
  playlistTitle: string;
  playlistType: PlaylistType;
  contentUrl: string;
}

export type HeaderProps = ContentHeaderProps | PlaylistHeaderProps;

/**
 * Header component for the player.
 * Contains the name and type of the content's parent (trail or assignment) if any.
 * If the content is not playing from a parent, no text is displayed but only the minimize button.
 */
export function Header({
  contentType,
  playlistTitle,
  playlistType,
  contentUrl
}: Readonly<HeaderProps>) {
  const parentName = playlistTitle;
  const parentType = playlistType ?? '';
  const hasParent = getParentTypeHeader(parentType) && parentName;

  let contentPath = '';

  function getContentPath(link: string) {
    const url = new URL(link);
    if (url.protocol === 'http:' || url.protocol === 'https:') {
      contentPath = `${url.pathname}${url.search}`;
    }
  }

  if (contentUrl) {
    try {
      getContentPath(contentUrl);
    } catch (error) {
      captureException(error);
    }
  }

  const contentTypeName = getContentName(contentType, EventType.Livestream);

  /**
   * necessary to match the vertical alignment of the absolute minimize button
   * when this header has 1 line of text (independent) vs 2 lines of text (with parent)
   */
  const verticalPadding = hasParent
    ? {
        base: '0.5rem',
        md: '2rem'
      }
    : {
        base: '1rem',
        md: '2.5rem'
      };

  return (
    <Flex
      width={'100%'}
      maxWidth={'50rem'}
      height={'fit-content'}
      mx={'auto'}
      px={{
        base: '5rem',
        md: '8rem'
      }}
      py={verticalPadding}
      flexDirection={'column'}
      gap={'0.25rem'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {hasParent ? (
        <>
          <Text
            fontSize={{
              base: 'xs',
              md: 'sm'
            }}
            textAlign={'center'}
            textTransform={'uppercase'}
            color={'lightGrey.400'}
          >
            {getParentTypeHeader(parentType)}
          </Text>

          {contentPath ? (
            <TrackedLink
              name="playlist-link"
              href={contentPath}
              fontSize={'md'}
              textAlign={'center'}
              color={'white'}
              width={'100%'}
              noOfLines={1}
              _hover={{ textDecoration: 'none' }}
            >
              {parentName}
            </TrackedLink>
          ) : (
            <Text
              fontSize={'md'}
              textAlign={'center'}
              color={'white'}
              width={'100%'}
              noOfLines={1}
            >
              {parentName}
            </Text>
          )}
        </>
      ) : (
        <Text
          fontSize={{
            base: 'xs',
            md: 'sm'
          }}
          textAlign={'center'}
          textTransform={'uppercase'}
          color={'lightGrey.400'}
        >
          {contentTypeName}
        </Text>
      )}
    </Flex>
  );
}
