import { BoxProps, Text } from '@chakra-ui/react';
import React from 'react';

const ContentBoxTitle: React.FC<BoxProps> = ({ children, ...props }) => (
  <Text
    fontSize="2rem"
    fontWeight={700}
    lineHeight="2.375rem"
    color="tigerOrange.600"
    {...props}
  >
    {children}
  </Text>
);

export default ContentBoxTitle;
