import * as React from 'react';
import { usePagination, Hooks, TableOptions, TableInstance } from 'react-table';
import { useDeepCompareEffect } from '@tigerhall/core';

import { TablePaginator, PaginationProps } from '../components/TablePaginator';
import { TableProps } from '../types';

type Params = {
  reFetchData?: TableProps['reFetchData'];
  pageMeta?: TableProps['pageMeta'];
} & Pick<PaginationProps, 'sizes' | 'rowsAlias'>;

const createOptions =
  <D extends object = {}>({
    reFetchData,
    pageMeta,
    sizes
  }: Pick<Params, 'reFetchData' | 'pageMeta' | 'sizes'>) =>
  (options: TableOptions<D>) => {
    const paginationOptions = {
      manualPagination: !!reFetchData,
      initialState: {
        pageSize: pageMeta?.limit ?? sizes?.[0] ?? 10,
        pageIndex: 0
      },
      pageCount: pageMeta?.total
        ? Math.ceil(pageMeta.total / pageMeta.limit)
        : undefined
    };
    return {
      ...options,
      ...paginationOptions,
      initialState: {
        ...(options?.initialState ?? {}),
        ...(paginationOptions?.initialState ?? {})
      }
    };
  };

const addInstanceFields =
  <D extends object = {}>({
    reFetchData,
    sizes,
    rowsAlias
  }: Pick<Params, 'reFetchData' | 'sizes' | 'rowsAlias'>) =>
  (instance: TableInstance<D>) => {
    const {
      canPreviousPage,
      canNextPage,
      pageCount,
      gotoPage,
      setPageSize,
      state: { pageIndex, pageSize, sortBy }
    } = instance;

    // note: DO NOT add refetch data to the deps list or the component wil get
    // stuck in a render loop and i'm not 100% sure why because reFetchData should
    // always be wrapped in a useCallback
    useDeepCompareEffect(() => {
      if (reFetchData) {
        reFetchData(pageSize, pageIndex * pageSize, sortBy);
      }
    }, [pageSize, pageIndex, sortBy]);

    Object.assign(instance, {
      ...instance,
      renderPaginator: () => (
        <TablePaginator
          {...{
            canPreviousPage,
            canNextPage,
            pageCount,
            gotoPage,
            setPageSize,
            pageIndex,
            pageSize,
            sizes,
            rowsAlias
          }}
        />
      )
    });
  };

/** handle onChange and checked props for Select all button
 **/
export function createUseTablePagination<D extends object = {}>({
  reFetchData,
  pageMeta,
  sizes,
  rowsAlias
}: Params) {
  return [
    usePagination,
    (hooks: Hooks<D>) => {
      hooks.useOptions.push(createOptions({ reFetchData, pageMeta, sizes }));
      hooks.useInstance.push(
        addInstanceFields({ reFetchData, sizes, rowsAlias })
      );
    }
  ];
}
