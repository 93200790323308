import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
export type CursorInfoFieldsFragment = { __typename?: 'CursorInfo', hasNext?: boolean | null, hasPrev?: boolean | null, nextCursor: string, prevCursor: string };

export const CursorInfoFieldsFragmentDoc = gql`
    fragment CursorInfoFields on CursorInfo {
  hasNext
  hasPrev
  nextCursor
  prevCursor
}
    `;