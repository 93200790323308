import { type ChakraStylesConfig } from 'chakra-react-select';

const fontStyles = {
  fontSize: 'xs',
  fontWeight: 500,
  lineHeight: '1.2rem'
};

/**
 * TODO: extend `SelectChakraStyles` from `tigerhall/form` and override properties as needed.
 * We need separate styles for this component because, even it shares some properties with our
 * design system's Select, is not the same. Some properties are different like font size, font color,
 * border radius and height.
 */
export const SelectChakraStyles: ChakraStylesConfig = {
  container: (provided) => ({
    ...provided
  }),

  control: (provided) => ({
    ...provided,
    borderRadius: 'base',
    minHeight: '2rem'
  }),

  input: (provided, props) => ({
    ...provided,
    p: 0,

    _placeholder: {
      ...fontStyles,
      margin: '0.75rem',
      color: 'darkGrey.200'
    }
  }),

  singleValue: (provided, props) => ({
    ...provided,
    ...fontStyles,
    color: 'lightGrey.400'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  menu: (provided) => ({
    ...provided,
    zIndex: 'dropdown'
  }),

  menuList: (provided) => ({
    ...provided,
    minW: '4rem',

    border: '1px solid',
    borderColor: 'lightGrey.600',
    borderRadius: 'base',

    backgroundColor: 'darkGrey.400',
    color: 'lightGrey.400',
    marginTop: '0.25rem'
  }),

  option: (provided, state) => ({
    ...provided,
    ...fontStyles,
    color: 'lightGrey.400',
    backgroundColor: state.isSelected ? 'darkGrey.200' : 'darkGrey.400'
  })
};
