import { Navigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { setAccessToken } from 'app/state';
import { useEffect } from 'react';

import { routes as loginRoutes } from './modules/Login';
import { routes as resetPasswordRoutes } from './modules/ResetPassword';
import { routes as campaignRoutes } from './modules/Campaign';
import { routes as getIndividualSignUpRoutes } from './modules/SignUp';
import { Invited } from './screens';
import { BaseSignUpLayout } from './modules/SignUp/components/BaseSignUpLayout';
import {
  AccountCreationSuccess,
  AccountCreationWaitScreen,
  EmailAndSocialLoginForm,
  FreeTrialSignUpForm,
  FreeTrialWelcome,
  TopicInterestSelector
} from './modules/SignUp/screens/ClaimFreeTrial';
import { Confirmation } from './modules/SignUp/screens/Confirmation';

function Logout() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAccessToken({ token: null }));
  }, [dispatch]);

  return <Navigate to="/home" replace />;
}

export function getAuthRoutes() {
  return [
    {
      /**
       * Invited is used for sending out links to users with a token that can be
       * used to quickly join an organisation
       */
      path: '/invited/:token',
      element: <Invited />
    },

    {
      path: '/signup/individual',
      element: <BaseSignUpLayout />,
      children: [
        {
          index: true,
          element: <EmailAndSocialLoginForm />
        },
        {
          path: '/signup/individual/claim-free-trial',
          element: <FreeTrialSignUpForm />
        },
        {
          path: '/signup/individual/welcome',
          element: <FreeTrialWelcome />
        },
        {
          path: '/signup/individual/interest-select',
          element: <TopicInterestSelector />
        },
        {
          path: '/signup/individual/creating-account',
          element: <AccountCreationWaitScreen />
        },
        {
          path: '/signup/individual/success',
          element: <AccountCreationSuccess />
        },
        {
          path: '/signup/individual/confirmation',
          element: <Confirmation />
        }
      ]
    },

    {
      path: '/logout',
      element: <Logout />
    },

    ...getIndividualSignUpRoutes,
    ...resetPasswordRoutes,
    ...loginRoutes,
    ...campaignRoutes
  ];
}
