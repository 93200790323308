import { Box, Icon, Text, Flex } from '@chakra-ui/react';
import { IconCircleCheck } from '@tigerhall/icons';
import type { Language } from '@tigerhall/core';

import { TrackedButton } from '~/tracked';
import type { SharedTranscriptReaderProps } from '../types';

type SimpleLanguage = Pick<Language, 'name' | 'code'>;

export interface LanguageSelectorProps
  extends Pick<SharedTranscriptReaderProps, 'config'> {
  availableLanguages: SimpleLanguage[];
  setTranscriptLanguage: (code: string | null) => void;
}

export function LanguageSelector({
  config,
  availableLanguages,
  setTranscriptLanguage
}: Readonly<LanguageSelectorProps>) {
  const { selectedLanguage, isDarkMode } = config;

  const handleLanguageSelection = (lang: SimpleLanguage) => {
    setTranscriptLanguage(lang.code);
  };
  const textColor = isDarkMode ? 'white' : 'black';

  return (
    <Flex
      p="1rem 1.5rem"
      bg={isDarkMode ? 'black' : 'white'}
      maxH="16rem"
      flexDirection="column"
    >
      <Box textAlign="center">
        <Text color={textColor} fontSize="sm" fontWeight="medium">
          Change Language
        </Text>
      </Box>
      <Box mt="0.5rem" pr="0.5em" overflow="auto" flex="1">
        {availableLanguages?.map((availableLang) => (
          <TrackedButton
            key={availableLang.code}
            name="LANGUAGE_OPTION"
            display="flex"
            variant="ghost"
            p="0.5rem 1rem"
            minH="2.5rem"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            onClick={() => handleLanguageSelection(availableLang)}
            color={
              availableLang.code === selectedLanguage
                ? 'tigerOrange.600'
                : textColor
            }
            fontSize="md"
            fontWeight="bold"
            rightIcon={
              selectedLanguage === availableLang.code ? (
                <Icon
                  as={IconCircleCheck}
                  width="1rem"
                  height="1rem"
                  color="green.500"
                />
              ) : undefined
            }
          >
            {availableLang.name}
          </TrackedButton>
        ))}
      </Box>
    </Flex>
  );
}
