import * as React from 'react';
import {
  HStack,
  Icon,
  MenuItem,
  type MenuItemProps,
  Text,
  VStack
} from '@chakra-ui/react';

export interface OptionsMenuItemProps extends Omit<MenuItemProps, 'icon'> {
  title: string;
  description: string;
  icon: React.ForwardRefExoticComponent<
    React.SVGAttributes<SVGElement> & React.RefAttributes<SVGSVGElement>
  >;
}

export function OptionsMenuItem({
  title,
  description,
  icon,
  iconSpacing = '0.5rem',
  ...props
}: OptionsMenuItemProps) {
  return (
    <MenuItem pr="2rem" aria-label={title} {...props}>
      <VStack alignItems="flex-start" spacing="0.25rem">
        <HStack
          spacing={iconSpacing}
          color="darkGrey.400"
          _dark={{
            color: 'white'
          }}
        >
          <Icon
            as={icon}
            display="flex"
            height="1rem"
            width="1rem"
            color="inherit"
          />
          <Text fontSize="inherit" fontWeight="bold" color="inherit">
            {title}
          </Text>
        </HStack>
        <Text fontSize="inherit" fontWeight="inherit">
          {description}
        </Text>
      </VStack>
    </MenuItem>
  );
}
