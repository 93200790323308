import { HStack, Icon, Text } from '@chakra-ui/react';
import { getContentCardProgress, isFinished } from '@tigerhall/core';
import {
  type Ebook,
  type LearningPath,
  type Podcast,
  type Stream
} from '@tigerhall/core/lib/types';
import { IconCircleCheckFilled } from '@tigerhall/icons';

import { IconPieChartProgress } from '~/icon';

/**
 * Renders a completion indicator for a content box
 * in the top left corner.
 *
 * This is not used for Events (experiences) because there
 * we don't want to show the completion indicator but
 * rather the time left until the event starts or if
 * it has already started or ended.
 */
export function ContentBoxCompletion(
  content: LearningPath | Stream | Podcast | Ebook
) {
  const { consumed, total } = getContentCardProgress(content);
  const progress = Math.round((consumed / total) * 100);

  const isCompleted = isFinished(content);
  const isInProgress = !isCompleted && progress > 0 && progress < 100;

  return (
    <>
      {isInProgress ? (
        <HStack
          position="absolute"
          top="0"
          left="0"
          bg="tigerOrange.50"
          py="0.5rem"
          px="0.5rem"
          borderRadius="0 0 0.3rem"
          spacing="0.3rem"
          justifyContent="center"
        >
          <IconPieChartProgress
            boxSize={4}
            color="tigerOrange.400"
            progress={progress}
          />
          <Text
            as="span"
            fontSize={{
              base: '2xs',
              lg: 'xs'
            }}
            fontWeight="bold"
            whiteSpace="nowrap"
            color="darkGrey.400"
          >
            {`${progress}% Completed`}
          </Text>
        </HStack>
      ) : null}
      {isCompleted ? (
        <HStack
          position="absolute"
          top="0"
          left="0"
          bg="white"
          py="0.5rem"
          px="0.5rem"
          borderRadius="0 0 0.3rem"
          spacing="0.3rem"
          justifyContent="center"
        >
          <Icon as={IconCircleCheckFilled} boxSize={4} color="green.500" />
          <Text
            as="span"
            fontSize={{
              base: '2xs',
              lg: 'xs'
            }}
            fontWeight="bold"
            whiteSpace="nowrap"
            color="darkGrey.300"
          >
            Completed
          </Text>
        </HStack>
      ) : null}
    </>
  );
}
