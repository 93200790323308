import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
  useLocation
} from 'react-router-dom';
import { Layout } from 'components/ui/Layout';
import { routes as adminRoutes } from 'modules/admin';
import { getAuthRoutes } from 'modules/authentication';
import { routes as applicationRoutes } from 'modules/application';
import { routes as msTeamsRoutes } from 'modules/msteams';
import { routes as miscRoutes } from 'modules/misc';
import { getAccessToken } from 'app/state';
import { wrapCreateBrowserRouter } from '@sentry/react';
import { useAppSelector } from 'hooks';

import { ScrollToTop } from '../ScrollToTop';
import { RefreshToken } from '../RefreshToken';
import { Analytics } from './components';
import { SearchParamsToast } from '../SearchParamsToast';
import { ReloadPrompt } from './components/ReloadPrompt';
import { DisableInstallAppPrompt } from './components/DisableInstallAppPrompt';

const Index = () => {
  const isLoggedIn = !!useAppSelector(getAccessToken);
  const { search } = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return <Navigate to={`/home${search}`} replace />;
};

export function Router() {
  const sentryCreateBrowserRouter =
    wrapCreateBrowserRouter(createBrowserRouter);

  const authRoutes = getAuthRoutes();

  const router = sentryCreateBrowserRouter([
    {
      path: '/',
      element: (
        <>
          <Analytics />
          <ScrollToTop />
          <RefreshToken />
          <SearchParamsToast />
          <ReloadPrompt />
          <DisableInstallAppPrompt />
          <Layout />
        </>
      ),
      children: [
        { index: true, element: <Index /> },
        // Add all the module routes here
        ...miscRoutes,
        ...adminRoutes,
        ...applicationRoutes,
        ...authRoutes,
        ...msTeamsRoutes
      ]
    }
  ]);

  return <RouterProvider router={router} />;
}
