import * as React from 'react';
import { HStack, Text } from '@chakra-ui/react';

type ContentBoxTrailLengthProps = {
  /** Amount of content pieces in the trail */
  contentPieces: number;
};

export function ContentBoxTrailLength({
  contentPieces
}: ContentBoxTrailLengthProps) {
  return (
    <HStack
      as="p"
      background="darkGrey.400"
      justifyContent="space-between"
      width="100%"
      padding={2}
    >
      <Text
        as="span"
        color="white"
        lineHeight="shorter"
        fontWeight="bold"
        fontSize={{
          base: 'x-small',
          lg: 'xs'
        }}
      >
        TRAIL
      </Text>
      <Text
        as="span"
        color="white"
        lineHeight="shorter"
        fontWeight="bold"
        fontSize={{
          base: 'x-small',
          lg: 'xs'
        }}
      >
        {contentPieces} Content Pieces
      </Text>
    </HStack>
  );
}
