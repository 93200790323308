import * as React from 'react';
import { Flex, Icon } from '@chakra-ui/react';
import { IconCirclePlus } from '@tigerhall/icons';
import { Button } from '@tigerhall/ui-components';
import TaskSuccessComponent from 'components/ui/TaskSuccessComponent';
import { ContentStepper } from 'components/ui';
import { TrackedButton, TrackedLink } from '@tigerhall/components';

type AssignmentSuccessProps = {
  assignAgain?: () => void;
  assignmentId?: string;
  stepNames: string[];
};

export function AssignmentSuccess({
  assignAgain,
  assignmentId,
  stepNames
}: Readonly<AssignmentSuccessProps>) {
  return (
    <Flex
      pb="3rem"
      backgroundColor="darkGrey.400"
      borderRadius="10px"
      justifyContent="center"
    >
      <ContentStepper stepNames={stepNames} currentStep={4}>
        <TaskSuccessComponent description="You’ve successfully assigned content to your teammates, giving them a direction in which to grow and crush their goals.">
          <Flex gap="1rem">
            <TrackedLink
              name="VIEW_ASSIGNMENT"
              href={`/admin/assignments/${assignmentId}`}
            >
              <Button text="View Assignment" size="lg" variant="outline" />
            </TrackedLink>
            <TrackedButton
              name="addNewAssignment"
              variant="solid"
              onClick={assignAgain}
              size="lg"
              leftIcon={<Icon as={IconCirclePlus} color="tigerOrange.600" />}
            >
              Add New Assignment
            </TrackedButton>
          </Flex>
        </TaskSuccessComponent>
      </ContentStepper>
    </Flex>
  );
}
