import {
  ActivationByUserReportColumns as Columns,
  type ActivationByUserReportFilter as Filter
} from '@tigerhall/core';

import {
  type AllStaticFilters,
  type DynamicFilter,
  type ReportColumn,
  type ReportTemplate,
  StaticFilterType
} from '~/modules/admin/modules/reportBuilder/types';
import { type ReportBuilderWizardStoreState } from '~/modules/admin/modules/reportBuilder/stores';
import { apolloClient } from '~/api/apollo';
import {
  ExportActivationByUserReportDocument,
  type ExportActivationByUserReportMutation,
  type ExportActivationByUserReportMutationVariables
} from '~/modules/admin/modules/reportBuilder/queries';
import {
  transformStateToDefaultFilters,
  transformStaticDateToTimeRange
} from '~/modules/admin/modules/reportBuilder/utils';

export class ActivationByUserReport implements ReportTemplate<Filter> {
  getName(): string {
    return 'Activation by User';
  }

  getDescription(): string {
    return 'List of all logged in or active users in the platform.';
  }

  isEnabled(): boolean {
    return true;
  }

  getColumns(): ReportColumn[] {
    return [
      {
        id: Columns.UserName,
        name: 'User Name'
      },
      {
        id: Columns.UserFirstName,
        name: 'User First Name'
      },
      {
        id: Columns.UserLastName,
        name: 'User Last Name'
      },
      {
        id: Columns.Email,
        name: 'Email'
      },
      {
        id: Columns.ActivationStatus,
        name: 'Activation Status',
        description: `Whether the user's account has been enabled or not`
      },
      {
        id: Columns.AccountActivationDate,
        name: 'Account Activation Date',
        description: 'Date when the user first logged in'
      },
      {
        id: Columns.LastOnline,
        name: 'Last Online',
        description: 'Date when the user last logged in'
      },
      {
        id: Columns.Title,
        name: 'Title'
      },
      {
        id: Columns.Groups,
        name: 'Groups'
      },
      {
        id: Columns.ManagerName,
        name: 'Manager Name'
      },
      {
        id: Columns.ManagerFirstName,
        name: 'Manager First Name'
      },
      {
        id: Columns.ManagerLastName,
        name: 'Manager Last Name'
      },
      {
        id: Columns.ManagerEmail,
        name: 'Manager Email'
      },
      {
        id: Columns.TigerhallUserId,
        name: 'Tigerhall User ID'
      }
    ];
  }

  getSampleData(): Record<string, string> {
    return {
      [Columns.AccountActivationDate]: '2 Jul 2024',
      [Columns.ActivationStatus]: 'Enabled',
      [Columns.Email]: 'user@example.com',
      [Columns.Groups]: 'Group 1, Group 2, Group 3',
      [Columns.LastOnline]: '2 Jul 2024',
      [Columns.ManagerEmail]: 'manager@example.com',
      [Columns.ManagerFirstName]: 'John',
      [Columns.ManagerLastName]: 'Doe',
      [Columns.ManagerName]: 'John Doe',
      [Columns.TigerhallUserId]: 'TH123456',
      [Columns.Title]: 'Senior Developer',
      [Columns.UserFirstName]: 'Jane',
      [Columns.UserLastName]: 'Smith',
      [Columns.UserName]: 'jane smith'
    };
  }

  getStaticFilters(): AllStaticFilters<Filter>[] {
    return [
      {
        name: 'accountActivationTimeFilter',
        label: 'Account Activation',
        placeholder: 'Select a time period',
        tooltip: '',
        type: StaticFilterType.DateTime
      },
      {
        name: 'lastOnlineTimeFilter',
        label: 'Last Online',
        placeholder: 'Select a time period',
        tooltip: '',
        type: StaticFilterType.DateTime
      },
      {
        name: 'includeArchivedUsers',
        label: 'Include Archived Users',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        isClearDisabled: true,
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' }
        ]
      }
    ];
  }

  getDynamicFilter(): DynamicFilter<Filter> | null {
    return null;
  }

  calculatePotentialRowCount(
    filter: Filter,
    audienceSize: number
  ): Promise<number> {
    return Promise.resolve(audienceSize);
  }

  async export(state: ReportBuilderWizardStoreState): Promise<void> {
    const { reportFilters: filter } = state;

    const standardFilter = await transformStateToDefaultFilters<Columns>(state);

    await apolloClient.mutate<
      ExportActivationByUserReportMutation,
      ExportActivationByUserReportMutationVariables
    >({
      mutation: ExportActivationByUserReportDocument,
      variables: {
        filter: {
          ...standardFilter,

          accountActivationTimeFilter: transformStaticDateToTimeRange(
            filter.accountActivationTimeFilter
          ),

          lastOnlineTimeFilter: transformStaticDateToTimeRange(
            filter.lastOnlineTimeFilter
          )
        }
      }
    });
  }
}
