import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgCircleOutCheck = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    focusable={false}
    className="th-icon"
    ref={ref}
    {...props}
  >
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M26.605 6.29A15 15 0 1 0 35.5 20v-1.533a1.667 1.667 0 1 1 3.334 0V20a18.34 18.34 0 0 1-13.136 17.572 18.333 18.333 0 1 1 2.264-34.328 1.667 1.667 0 1 1-1.357 3.045" />
      <path d="M38.345 5.488c.651.65.652 1.705.001 2.357L21.68 24.528a1.667 1.667 0 0 1-2.357 0l-5-5a1.667 1.667 0 0 1 2.357-2.356l3.82 3.82L35.989 5.49a1.667 1.667 0 0 1 2.357-.001" />
    </g>
  </svg>
);
SvgCircleOutCheck.displayName = "IconCircleOutCheck";
const ForwardRef = forwardRef(SvgCircleOutCheck);
export default ForwardRef;
