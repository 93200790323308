import { StreamSource } from '@tigerhall/core';
import { setPlayingContent, type PlayingContent } from 'app/state';
import { useAppDispatch } from 'hooks';
import { useGetPlaylistInfoFromURL } from 'hooks/useGetPlaylistInfoFromURL';

type PlayLiveVideoParams = Pick<PlayingContent, 'id'> & {
  type: Extract<
    PlayingContent['type'],
    | StreamSource.BroadcastCloudflareLive
    | StreamSource.BroadcastAwsIvs
    | StreamSource.LivestreamAgora
  >;
};

/**
 * Used for Livestream and Broadcast actions. It's named `useLiveVideoAction`
 * to make sure the name doesn't suggest that it's related to only one of these.
 */
export function useLiveVideoActions() {
  const dispatch = useAppDispatch();
  const { playlistType, playlistId, playlistTitle, contentUrl } =
    useGetPlaylistInfoFromURL();

  const playLiveVideo = (params: PlayLiveVideoParams) => {
    switch (params.type) {
      case StreamSource.BroadcastCloudflareLive:
      case StreamSource.BroadcastAwsIvs:
      case StreamSource.LivestreamAgora:
        dispatch(
          setPlayingContent({
            ...params,
            playlistType,
            playlistId,
            playlistTitle,
            contentUrl,
            onCompletionCallback: () => {}
          })
        );
        break;
      default:
        throw new Error('Unknown live video type');
    }
  };

  return {
    playLiveVideo
  };
}
