import { runIfFn } from '@chakra-ui/utils';
import * as segment from '@tigerhall/analytics';
import * as React from 'react';
import {
  FieldPath,
  FieldValues,
  FormProvider,
  UseFormProps,
  UseFormReturn
} from 'react-hook-form';
import { useTrackVisibility } from 'react-intersection-observer-hook';

import { useTrackedForm } from '../../hooks/useTrackedForm';

export interface TrackedFormProps<
  TFieldValues extends FieldValues,
  TContext = any
> {
  /**
   * Form name
   */
  name: string;

  /**
   * onSubmit callback
   *
   * @param data
   * @param setError
   */
  onSubmit: (
    data: TFieldValues,
    form: UseFormReturn<TFieldValues>
  ) => Promise<void> | void;

  /**
   * Field name to target the focus on
   */
  focus?: FieldPath<TFieldValues>;

  /**
   * Additional props to pass to the underlying `react-hook-form` form.
   */
  formHookProps?: UseFormProps<TFieldValues, TContext>;

  /**
   * Contents of the form
   */
  children:
    | React.ReactNode
    | (({ formState }: UseFormReturn<TFieldValues>) => React.ReactNode);
}

/**
 * This component is a wrapper around the FormProvider from react-hook-form.
 *
 * It enforces the user of the `useTrackedForm` hook to provide a name for the form,
 * and a form observer to track the form viewed event.
 *
 * @param name
 * @param onSubmit
 * @param formHookProps
 * @param focus
 * @param children
 * @constructor
 */
export function TrackedForm<T extends FieldValues>({
  name,
  onSubmit,
  formHookProps = {},
  focus = undefined,
  children
}: TrackedFormProps<T>) {
  const form = useTrackedForm(name, formHookProps);
  const [ref, { isVisible }] = useTrackVisibility();

  const wrappedOnSubmit = React.useCallback(
    async (values: T) => {
      await onSubmit(values, form);
    },
    [onSubmit, form]
  );

  React.useEffect(() => {
    if (focus) {
      form.setFocus(focus);
    }
  }, [focus, form, form.setFocus]);

  React.useEffect(() => {
    if (isVisible) {
      segment.formViewed({
        formName: name,
        formLocation: window.location.pathname
      });
    }
  }, [name, isVisible]);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(wrappedOnSubmit)}
        name={name}
        style={{ width: '100%' }}
        ref={ref}
        /** added the `noValidate` prop to avoid default HTML5 form validation
         */
        noValidate
      >
        {runIfFn(children, form)}
      </form>
    </FormProvider>
  );
}
