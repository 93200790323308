import { Flex, Text } from '@chakra-ui/react';
import { Topic, TopicCloud } from '@tigerhall/ui-components';
import { useNavigate } from 'react-router-dom';
import { Lifegoal } from '@tigerhall/core/lib/types';
import BackgroundImage from 'assets/images/TopicCloudBackground.jpeg';
import { useGetLifeGoalsQuery } from 'generated';
import { getSelectedOrg } from 'app/state';
import { useAppSelector } from 'hooks';

interface TopicCloudSectionProps {
  title: string;
  subTitle?: string;

  /**
   * Filter the topics displayed on the ones that exclusively belong to an organisation.
   *
   * Note: when the filter on org is activated we display categories and not topics any more.
   * Yes this is super fucking confusing.
   */
  filterOnOrg?: true;
}

function TopicCloudSection({
  title,
  subTitle,
  filterOnOrg
}: Readonly<TopicCloudSectionProps>) {
  const org = useAppSelector(getSelectedOrg);
  const navigate = useNavigate();

  const { data, loading } = useGetLifeGoalsQuery({
    variables: {
      filter: {
        organisationID: filterOnOrg ? [org?.id as string] : null,
        limit: 40,
        offset: 0
      }
    }
  });

  if (loading) {
    return null;
  }

  const topics: Topic[] = (function filterTopics() {
    if (!filterOnOrg) {
      return data?.lifegoals.edges as Topic[];
    }

    return ((data?.lifegoals.edges || []) as Lifegoal[])
      .map((l: Lifegoal) => l.categories)
      .flat()
      .map((c) => ({ ...c, emoji: '' }));
  })();

  if (!topics?.length) {
    return null;
  }

  return (
    <Flex
      direction="column"
      bg="orange.600"
      maxW="100%"
      mx={{
        base: '1rem',
        md: '2rem'
      }}
      my="1rem"
      p="1rem 2rem"
      borderRadius="0.5rem"
      alignItems="center"
      backgroundPosition="bottom left"
      backgroundImage={BackgroundImage}
      backgroundSize="cover"
    >
      <Text fontSize="2xl" as="h3" fontWeight="bold" color="white" mb={4}>
        {title}
      </Text>
      <Text fontSize="lg" fontWeight="semibold" mb={4}>
        {subTitle}
      </Text>
      <TopicCloud
        topics={topics}
        align="start"
        mode="view"
        bgColor="transparent"
        onClick={(id: string) => {
          if (filterOnOrg) {
            navigate(`/content/category/${id}`);
          } else {
            navigate(`/content/topic/${id}`);
          }
        }}
      />
    </Flex>
  );
}

export default TopicCloudSection;
