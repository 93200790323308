import { useGetCurrentUserOnboardingStatusQuery } from 'generated';
import { getAccessToken, setIsOnboardingStatus } from 'app/state';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from './redux';

/**
 * This hook must be called at the top of the components where we want to handle this logic.
 */
export function useOnboardingFlow() {
  const dispatch = useAppDispatch();
  const isLoggedIn = !!useAppSelector(getAccessToken);
  const { data, loading } = useGetCurrentUserOnboardingStatusQuery({
    fetchPolicy: 'network-only',
    skip: !isLoggedIn
  });

  useEffect(() => {
    if (data?.me?.isOnboardingCompleted === false) {
      dispatch(setIsOnboardingStatus({ shouldOnboardUser: true }));
    } else {
      dispatch(setIsOnboardingStatus({ shouldOnboardUser: false }));
    }
  }, [loading, data, dispatch]);

  return {
    loading: isLoggedIn ? loading : false
  };
}
