import * as React from 'react';
import {
  Box,
  LinkOverlay as ChakraLinkOverlay,
  Flex,
  Image,
  LinkBox,
  Text,
  VStack
} from '@chakra-ui/react';
import {
  contentTotalTime,
  extractContentTypeFromContent,
  formatContentType,
  resizeImage
} from '@tigerhall/core';
import {
  ContentTimeBadge,
  LinkOverlay,
  TrackedLink
} from '@tigerhall/components';
import * as segment from '@tigerhall/analytics';
import { linkToContent, linkToExpert } from 'utils/routes';

import type {
  FeaturedContentCardProps,
  FeaturedEbook,
  FeaturedEvent,
  FeaturedPodcast,
  FeaturedStream
} from './types';

const IMAGE_SIZE = {
  mobile: {
    width: 298,
    height: 224
  },
  desktop: {
    width: 560,
    height: 420
  }
};

const TIME_BADGE_DEFAULT_PROPS: Omit<
  React.ComponentProps<typeof ContentTimeBadge>,
  'typename' | 'duration' | 'date' | 'hasTime'
> = {
  background: 'transparent',
  px: '0',
  spacing: {
    base: '0.25rem',
    sm: '0.5rem'
  },
  iconProps: {
    boxSize: 4
  },
  textProps: {
    fontSize: {
      base: 'xs',
      lg: 'lg'
    }
  }
};

export function FeaturedContentCard<
  T extends FeaturedEbook | FeaturedStream | FeaturedPodcast | FeaturedEvent
>(props: FeaturedContentCardProps<T>) {
  const {
    content,
    index,
    isVisibleOnFirstPaint,
    header,
    paragraph,
    linkToURL,
    organisation
  } = props;

  if (!content || (!content && !header)) {
    return null;
  }

  const { id, name, image, experts, categories } = content ?? {};

  const imageSrc = image?.uri ?? '';
  const imageAlt = image?.alt ?? '';

  const hasSingleExpert = experts?.length === 1;

  const expert = experts?.[0];
  const expertName = `${expert?.firstName ?? ''} ${expert?.lastName ?? ''}`;

  const shouldUseContentLink = !!content?.id && !!content?.__typename;

  function handleOverlayClick() {
    segment.carouselClicked({
      categories: categories?.map((c) => c.name),
      categoriesIds: categories?.map((c) => c.id),

      experts: experts?.map((e) => `${e.firstName} ${e.lastName}`),
      expertsIds: experts?.map((e) => e.id),

      contentId: id,
      contentName: name,
      contentType: content?.__typename,

      indexInList: index,
      targetUrl: ''
    });
  }

  return (
    <LinkBox
      as="article"
      height={{
        base: '14rem',
        lg: '26.25rem'
      }}
      width={{
        base: '18.75rem',
        lg: '35rem'
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      rounded="2xl"
      position="relative"
      overflow="hidden"
      padding="1.5rem 4rem 1.5rem 1rem"
      role="group"
      _before={{
        position: 'absolute',
        content: '""',
        height: '100%',
        width: '100%',
        inset: 0,
        // to make sure the gradient is always behind the content but in front of the image
        zIndex: -1,
        background:
          'linear-gradient(90deg, rgba(0, 0, 0, 0.80) 32.29%, rgba(0, 0, 0, 0.30) 100%)'
      }}
    >
      <picture>
        <source
          media="(max-width: 991px)"
          srcSet={`${resizeImage({
            url: imageSrc,
            width: IMAGE_SIZE.mobile.width,
            height: IMAGE_SIZE.mobile.height
          })} 1x, ${resizeImage({
            url: imageSrc,
            width: IMAGE_SIZE.mobile.width * 2,
            height: IMAGE_SIZE.mobile.height * 2
          })} 2x`}
        />
        <Image
          srcSet={`${resizeImage({
            url: imageSrc,
            width: IMAGE_SIZE.desktop.width,
            height: IMAGE_SIZE.desktop.height
          })} 1x, ${resizeImage({
            url: imageSrc,
            width: IMAGE_SIZE.desktop.width * 2,
            height: IMAGE_SIZE.desktop.height * 2
          })} 2x`}
          src={resizeImage({
            url: imageSrc,
            width: IMAGE_SIZE.desktop.width,
            height: IMAGE_SIZE.desktop.height
          })}
          alt={imageAlt}
          objectFit="cover"
          width="100%"
          height="100%"
          position="absolute"
          inset={0}
          // to make sure the image is always behind the content
          zIndex={-2}
          loading={isVisibleOnFirstPaint ? 'eager' : 'lazy'}
          decoding={isVisibleOnFirstPaint ? 'auto' : 'async'}
          transition="transform 0.3s ease-in-out"
          _groupHover={{
            transform: 'scale(1.2)'
          }}
        />
      </picture>
      <VStack
        padding="0.5rem"
        flex="1"
        justifyContent="start"
        alignItems="start"
        spacing="0"
        width="100%"
        height="auto"
      >
        {!!content && (
          <Flex
            p="0.25rem 0.5rem"
            background="black"
            borderRadius="md"
            alignItems="center"
            justifyContent="center"
            alignSelf="flex-start"
          >
            <Text
              as="span"
              fontSize={{
                base: 'xs',
                lg: 'lg'
              }}
              fontWeight="bold"
              color="lightGrey.600"
              noOfLines={1}
            >
              {organisation?.name ??
                formatContentType(extractContentTypeFromContent(content))}
            </Text>
          </Flex>
        )}

        <Text
          as="h3"
          fontWeight="bold"
          fontSize={{
            base: 'md',
            lg: '2xl'
          }}
          lineHeight="normal"
          textAlign="left"
          color="white"
          paddingTop={{
            base: '1rem',
            lg: '1.5rem'
          }}
          noOfLines={3}
        >
          {shouldUseContentLink ? (
            <LinkOverlay
              color="white"
              href={linkToContent({
                typename: content?.__typename,
                id: id ?? ''
              })}
              onClick={handleOverlayClick}
            >
              {name ?? header}
            </LinkOverlay>
          ) : (
            <ChakraLinkOverlay
              color="white"
              isExternal
              href={linkToURL}
              onClick={handleOverlayClick}
            >
              {name ?? header}
            </ChakraLinkOverlay>
          )}
        </Text>

        <Box
          paddingTop={{
            base: '0.25rem',
            lg: '0.5rem'
          }}
        >
          {hasSingleExpert && expert?.id ? (
            <>
              <Text
                as="span"
                fontSize={{
                  base: 'xs',
                  lg: 'lg'
                }}
                lineHeight="shorter"
                textAlign="left"
                color="lightGrey.600"
                noOfLines={1}
              >
                <TrackedLink
                  name="FEATURED_CONTENT_THINKFLUENCER"
                  color="lightGrey.600"
                  href={linkToExpert({ id: expert.id })}
                >
                  {expertName}
                </TrackedLink>
              </Text>
              <Text
                as="span"
                fontSize={{
                  base: 'xs',
                  lg: 'lg'
                }}
                textAlign="left"
                color="tigerOrange.600"
                noOfLines={1}
              >
                {expert?.company ?? ''}
              </Text>
            </>
          ) : (
            <Text
              as="span"
              fontSize={{
                base: 'xs',
                lg: 'lg'
              }}
              textAlign="left"
              color="lightGrey.600"
              noOfLines={1}
            >
              {paragraph || 'Multiple Thinkfluencers'}
            </Text>
          )}
        </Box>
        <Box
          paddingTop={{
            base: '1rem',
            lg: '1.5rem'
          }}
        >
          {content.__typename === 'Event' ? (
            <ContentTimeBadge
              typename={content?.__typename}
              date={content?.startsAt}
              hasTime
              {...TIME_BADGE_DEFAULT_PROPS}
            />
          ) : (
            <ContentTimeBadge
              typename={content.__typename}
              duration={content ? contentTotalTime(content) : 0}
              {...TIME_BADGE_DEFAULT_PROPS}
            />
          )}
        </Box>
      </VStack>
    </LinkBox>
  );
}
