import * as React from 'react';
import {
  Circle,
  HStack,
  Skeleton,
  SkeletonCircle,
  VStack
} from '@chakra-ui/react';

export function PostAuthoringSkeleton() {
  return (
    <HStack>
      <SkeletonCircle size="3rem" />
      <VStack alignItems="flex-start" spacing="0.25rem">
        <Skeleton width="10rem" height="1rem" />
        <HStack spacing="0.375rem">
          <Skeleton width="5rem" height="0.75rem" />
          <Circle size="0.125rem" bg="lightGrey.900" />
          <SkeletonCircle size="1rem" />
        </HStack>
      </VStack>
    </HStack>
  );
}
