import * as React from 'react';
import { Flex, Heading, Icon, SimpleGrid, useToast } from '@chakra-ui/react';
import { OrganisationGroup, User } from '@tigerhall/core/lib/types';
import {
  FormDateTimePicker,
  FormInput,
  TrackedRTForm
} from '@tigerhall/components';
import { IconEdit, IconCaretLeftFilled } from '@tigerhall/icons';
import { ContentPanel, ContentStepper, DarkPanel } from 'components/ui';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import { ActionModal } from 'components/ui/modals';
import { useAppSelector } from 'hooks';
import { getSelectedOrg } from 'app/state';
import { useCreateAssignmentMutation } from 'generated';

import { CURRENT_PROGRESS } from '../../../constants';
import { AssignmentSuccess } from './AssignmentSuccess';
import { SelectedContentCards, UsersAndGroups } from '../../../components';
import { useAssignmentDraftContext } from '../../../hooks/useAssignmentDraftContext';

const DESCRIPTION = `This is where you make sure you've accurately matched the content to your teammates. Add a name and due date to the assignment, and let your team know why you think they'll benefit from completing it.`;

type FormValues = { title: string; reason: string; dueDate: DateTime };

type AssignmentConfirmationProps = {
  stepNames: string[];
  goBack: () => void;
  members: User[];
  setCurrentProgress: (progress: string, idEdit?: boolean) => void;
  selectedOrgGroups: OrganisationGroup[];
};

export function AssignmentConfirmation({
  stepNames,
  goBack,
  setCurrentProgress,
  members,
  selectedOrgGroups
}: Readonly<AssignmentConfirmationProps>) {
  const { id: orgId = '' } = useAppSelector(getSelectedOrg) || {};
  const {
    usersIds,
    groupsIds,
    contents,
    meta,
    setContents,
    setMeta,
    parseDraftToInput,
    clearDraft
  } = useAssignmentDraftContext();
  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  });

  const [isActionModalOpen, setIsActionModalOpen] = React.useState(false);

  const isContinueButtonDisabled = React.useCallback(
    () =>
      (usersIds?.length <= 0 && groupsIds?.length <= 0) ||
      contents?.length <= 0 ||
      isEmpty(meta?.title) ||
      isEmpty(meta?.reason),
    [meta, contents, usersIds, groupsIds]
  );

  const onChange = React.useCallback(
    (values: FormValues) => {
      setMeta(values);
    },
    [setMeta]
  );

  const [createAssignment, { data: createAssignmentResponse }] =
    useCreateAssignmentMutation();

  const createAssignmentHandler = React.useCallback(async () => {
    setIsActionModalOpen(false);
    const assignmnetInput = parseDraftToInput();
    try {
      await createAssignment({
        variables: {
          input: assignmnetInput,
          orgId
        }
      });
      clearDraft();
    } catch (error) {
      toast({
        title:
          'An error occurred while creating the assignment. Please try again later or contact support.',
        status: 'error'
      });
    }
  }, [createAssignment, orgId, parseDraftToInput, clearDraft, toast]);

  if (createAssignmentResponse?.createAssignment?.id) {
    return (
      <AssignmentSuccess
        assignAgain={() => {
          setCurrentProgress(CURRENT_PROGRESS.CONTENT_SELECTION);
        }}
        assignmentId={createAssignmentResponse.createAssignment.id}
        stepNames={stepNames}
      />
    );
  }

  return (
    <ContentStepper
      stepNames={stepNames}
      title="Confirm Assigned Content "
      currentStep={3}
      subTitle="Assign Trails"
      buttons={[
        {
          text: 'Previous',
          onClick: goBack,
          variant: 'outline',
          leftIcon: <Icon as={IconCaretLeftFilled} color="kindaYellow.50" />
        },
        {
          text: 'Create Assignment',
          onClick: () => setIsActionModalOpen(true),
          isDisabled: isContinueButtonDisabled()
        }
      ]}
      description={DESCRIPTION}
    >
      <Flex direction="column" gap="2rem">
        <DarkPanel gap="1rem">
          <Heading fontSize="1.5rem" fontWeight="700" color="white">
            Assignment Details
          </Heading>
          <TrackedRTForm<FormValues>
            name="ASSIGNMENT_CONFIRMATION"
            onSubmit={onChange}
            formHookProps={{
              defaultValues: {
                title: meta.title,
                reason: meta.reason,
                dueDate: meta.dueDate
              }
            }}
          >
            <Flex direction="column" gap="1rem">
              <SimpleGrid columns={3} gap="1rem">
                <FormInput
                  name="title"
                  label="Assignment Title"
                  placeholder="Enter Assignment title"
                  rules={{
                    required: true
                  }}
                />
                <FormInput
                  name="reason"
                  label="Purpose/Goal"
                  placeholder="What is the purpose/goal of assignment?"
                  rules={{
                    required: true
                  }}
                />
                <FormDateTimePicker
                  name="dueDate"
                  label="Due Date"
                  placeholder="Set due date for Assignment"
                  minDate={new Date()}
                />
              </SimpleGrid>
            </Flex>
          </TrackedRTForm>
        </DarkPanel>
        <ContentPanel
          heading={`Selected Content (${contents.length || 0})`}
          inlineButtons={[
            {
              id: 'edit',
              text: 'Edit',
              leftIcon: <Icon as={IconEdit} w="1rem" h="1rem" />,
              onClick: () => {
                setCurrentProgress(CURRENT_PROGRESS.CONTENT_SELECTION, true);
              }
            }
          ]}
        >
          <SelectedContentCards
            contentCards={contents}
            setContentCards={setContents}
            actions={['LearnMore', 'Remove']}
            shouldHideFrame
          />
        </ContentPanel>

        <UsersAndGroups
          inlineButtons={[
            {
              id: 'edit',
              text: 'Edit',
              leftIcon: <Icon as={IconEdit} w="1rem" h="1rem" />,
              onClick: () => {
                setCurrentProgress(CURRENT_PROGRESS.USERS_SELECTION, true);
              }
            }
          ]}
          members={members}
          orgGroups={selectedOrgGroups}
          loading={false}
        />
      </Flex>
      <ActionModal
        isOpen={isActionModalOpen}
        onClose={() => {
          setIsActionModalOpen(false);
        }}
        title="Create Assignment"
        description={
          'Once the Assignment is created you will be not able to edit the Selected Content. Please check before continuing.'
        }
        primaryButtonText="Create"
        primaryButtonOnClick={createAssignmentHandler}
        secondaryButtonText="Check again"
        secondaryButtonOnClick={() => setIsActionModalOpen(false)}
      />
    </ContentStepper>
  );
}
