import * as React from 'react';
import { Box, HStack, Skeleton } from '@chakra-ui/react';

import { FeaturedContentCardSkeleton } from './components/FeaturedContentCard';
import { DummyCopies } from '../DummyCopies';

interface FeaturedContentCarouselSkeletonProps {
  hasHeading?: boolean;
}

export function FeaturedContentCarouselSkeleton({
  hasHeading = false
}: FeaturedContentCarouselSkeletonProps) {
  return (
    <Box width="100%">
      {hasHeading ? (
        <Skeleton height="2rem" width="16rem" mb="1.5rem" ml="2rem" />
      ) : null}
      {/* spacing in px to match Swiper `spaceBetween` property used in the carousel */}
      <HStack overflow="hidden" spacing="24px" px="2rem">
        <DummyCopies count={6}>
          <FeaturedContentCardSkeleton />
        </DummyCopies>
      </HStack>
    </Box>
  );
}
