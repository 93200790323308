import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignmentStatisticsQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
  filter: SchemaTypes.AssignmentStatisticsFilterInput;
  pagination: SchemaTypes.OffsetPagination;
}>;


export type AssignmentStatisticsQuery = { __typename?: 'Query', assignment: { __typename: 'Assignment', id: string, statistics: { __typename?: 'AssignmentUserStatisticsConnection', edges: Array<{ __typename?: 'AssigmentUserStatistics', completedPieces: number, completionRate: number, ebooks: number, ebooksCompleted: number, podcasts: number, podcastsCompleted: number, streams: number, streamsCompleted: number, user: { __typename?: 'User', id: string, firstName: string, lastName: string, organisationGroups?: Array<{ __typename?: 'OrganisationGroup', id: string, name: string }> | null } }>, meta: { __typename?: 'PageInfo', limit: number, offset: number, total: number } } } };

export type ExportAssignmentStatisticsMutationVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
  filter: SchemaTypes.AssignmentStatisticsFilterInput;
}>;


export type ExportAssignmentStatisticsMutation = { __typename?: 'Mutation', exportAssignmentStatistics: boolean };


export const AssignmentStatisticsDocument = gql`
    query AssignmentStatistics($id: ID!, $filter: AssignmentStatisticsFilterInput!, $pagination: OffsetPagination!) {
  assignment(id: $id) {
    __typename
    id
    statistics(filter: $filter, pagination: $pagination) {
      edges {
        completedPieces
        completionRate
        ebooks
        ebooksCompleted
        podcasts
        podcastsCompleted
        streams
        streamsCompleted
        user {
          id
          firstName
          lastName
          organisationGroups {
            id
            name
          }
        }
      }
      meta {
        limit
        offset
        total
      }
    }
  }
}
    `;

/**
 * __useAssignmentStatisticsQuery__
 *
 * To run a query within a React component, call `useAssignmentStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentStatisticsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAssignmentStatisticsQuery(baseOptions: Apollo.QueryHookOptions<AssignmentStatisticsQuery, AssignmentStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignmentStatisticsQuery, AssignmentStatisticsQueryVariables>(AssignmentStatisticsDocument, options);
      }
export function useAssignmentStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignmentStatisticsQuery, AssignmentStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignmentStatisticsQuery, AssignmentStatisticsQueryVariables>(AssignmentStatisticsDocument, options);
        }
export type AssignmentStatisticsQueryHookResult = ReturnType<typeof useAssignmentStatisticsQuery>;
export type AssignmentStatisticsLazyQueryHookResult = ReturnType<typeof useAssignmentStatisticsLazyQuery>;
export type AssignmentStatisticsQueryResult = Apollo.QueryResult<AssignmentStatisticsQuery, AssignmentStatisticsQueryVariables>;
export const ExportAssignmentStatisticsDocument = gql`
    mutation ExportAssignmentStatistics($id: ID!, $filter: AssignmentStatisticsFilterInput!) {
  exportAssignmentStatistics(id: $id, filter: $filter)
}
    `;
export type ExportAssignmentStatisticsMutationFn = Apollo.MutationFunction<ExportAssignmentStatisticsMutation, ExportAssignmentStatisticsMutationVariables>;

/**
 * __useExportAssignmentStatisticsMutation__
 *
 * To run a mutation, you first call `useExportAssignmentStatisticsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAssignmentStatisticsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAssignmentStatisticsMutation, { data, loading, error }] = useExportAssignmentStatisticsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportAssignmentStatisticsMutation(baseOptions?: Apollo.MutationHookOptions<ExportAssignmentStatisticsMutation, ExportAssignmentStatisticsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportAssignmentStatisticsMutation, ExportAssignmentStatisticsMutationVariables>(ExportAssignmentStatisticsDocument, options);
      }
export type ExportAssignmentStatisticsMutationHookResult = ReturnType<typeof useExportAssignmentStatisticsMutation>;
export type ExportAssignmentStatisticsMutationResult = Apollo.MutationResult<ExportAssignmentStatisticsMutation>;
export type ExportAssignmentStatisticsMutationOptions = Apollo.BaseMutationOptions<ExportAssignmentStatisticsMutation, ExportAssignmentStatisticsMutationVariables>;