import type { TableSelectColumn, TableSelectDataSource } from '@tigerhall/components';
import { UseControllerProps } from 'react-hook-form';

import { ReportBuilderWizardStoreState } from './stores';

export enum StaticFilterType {
  DropDown,
  DateTime
}

/**
 * The most simplistic type of filter that
 */
interface StaticFilter<T extends Record<string, any>> {
  name: keyof T;
  label: string;
  placeholder: string;
  type: StaticFilterType;

  tooltip?: string;
}

export interface DropDownFilter<T extends object> extends StaticFilter<T> {
  type: StaticFilterType.DropDown;
  isMulti?: boolean;
  isClearDisabled?: boolean;

  options: {
    label: string;
    value: string;
  }[];
}

export interface DateTimeFilter<T extends object> extends StaticFilter<T> {
  type: StaticFilterType.DateTime;
}

export type AllStaticFilters<T extends object> =
  | DropDownFilter<T>
  | DateTimeFilter<T>;

export interface DynamicFilter<T extends object> {
  /**
   * Map the dynamic filter to one of the fields in the report mutation
   */
  id: keyof T;

  /**
   * Label of the filter
   */
  label: string;

  /**
   * Description of the purpose of the filter
   */
  description: string;

  /**
   * React hook form rules for the filter
   */
  rules?: UseControllerProps['rules'];

  /**
   * Which columns to display
   */
  columns: TableSelectColumn[];

  /**
   * Where to fetch the data from
   */
  dataSource: TableSelectDataSource;
}

export interface ReportColumn {
  id: string;
  name: string;
  description?: string;
  subColumns?: ReportColumn[];
}

export interface ReportTemplate<F extends Record<string, any>> {
  getName(): string;

  getDescription(): string;

  isEnabled(): boolean;

  getColumns(): ReportColumn[];

  getSampleData(): Record<string, string | number | boolean>;

  getStaticFilters(): AllStaticFilters<F>[];

  getDynamicFilter(): DynamicFilter<F> | null;

  calculatePotentialRowCount(filter: F, audienceSize: number): Promise<number>;

  export(state: ReportBuilderWizardStoreState): Promise<void>;
}

export enum ReportBuilderRoutes {
  TemplateStep = 'report-builder/template',
  SelectYourCriteria = 'report-builder/select-your-criteria',
  ColumnsAndFilterStep = 'report-builder/columns-and-filters',
  ReviewAndExportStep = 'report-builder/review-and-export'
}


export enum ReportBuilderSteps {
  Template = 'template',
  SelectYourCriteria = 'select-your-criteria',
  ColumnsAndFilter = 'columns-and-filters',
  ReviewAndExport = 'review-and-export'
}