import { lazy, Suspense } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text
} from '@chakra-ui/react';
import {
  Controller,
  UseControllerProps,
  useFormContext,
  UseFormReturn
} from 'react-hook-form';

const LexicalEditor = lazy(() =>
  import('../LexicalEditor').then((module) => ({
    default: module.LexicalEditor
  }))
);

export interface EditorFormProps {
  name: string;
  rules?: UseControllerProps['rules'];
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  showHeadings?: boolean | null;
  isPostEditor?: boolean;
}

export function EditorForm({
  name,
  rules,
  disabled = false,
  label,
  placeholder,
  showHeadings = true,
  isPostEditor = false
}: EditorFormProps) {
  const methods = useFormContext() as UseFormReturn & {
    formName: string;
  };

  if (methods === null) {
    return <Text color="state.error">Missing form context</Text>;
  }

  if (!methods.formName) {
    return <Text color="state.error">Missing form name</Text>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      render={({ field, fieldState }) => (
        <FormControl
          isInvalid={!!fieldState.error}
          isRequired={!!rules?.required}
          isDisabled={disabled}
        >
          <FormLabel>{label}</FormLabel>
          <Suspense>
            <LexicalEditor
              placeholder={placeholder}
              initialState={isPostEditor ? field.value.markdown : field.value}
              onChange={({ markdown }) => {
                field.onChange(markdown);
              }}
              showHeadings={showHeadings}
              isPostEditor={isPostEditor}
            />
          </Suspense>
          <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}
