import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Box, Text, VStack } from '@chakra-ui/react';
import { type Content } from '@tigerhall/core/lib/types';
import * as segment from '@tigerhall/analytics';
import map from 'lodash/map';
import * as React from 'react';
import { ContentBarWithLinks } from 'components/ui/ContentBox';

import { useContentCompletedQuery } from '../../../query/generated/content.generated';
import Skeleton from './Skeleton';

const ContentInfiniteScroll = () => {
  const { data, loading, fetchMore, error } = useContentCompletedQuery({
    variables: {
      offset: 0,
      limit: 10
    }
  });

  const hasNextPage =
    !loading &&
    (data?.me?.finishedContent?.edges?.length || 0) <
      (data?.me?.finishedContent?.meta?.total || 0);

  const [targetRef] = useInfiniteScroll({
    loading,
    hasNextPage,

    onLoadMore: () => {
      const offset = (data?.me?.finishedContent.meta.offset || 0) + 10;

      fetchMore({
        variables: {
          limit: 10,
          offset
        }
      });

      segment.fetchMore({
        componentName: 'ContentInfiniteScroll',
        fetchedItemCount: offset
      });
    },

    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,

    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px'
  });

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Box>
      <Text
        as="h4"
        fontSize="lg"
        fontWeight="bold"
        color="lightGrey.200"
        mb={4}
      >
        {data?.me?.finishedContent.meta.total} Content Pieces Completed
      </Text>
      <VStack gap={4} justifyContent="stretch">
        {map(data?.me?.finishedContent.edges, (edge: Content, index) => (
          <ContentBarWithLinks
            sectionName="Content Pieces Completed"
            sectionCardIndex={index}
            {...edge}
          />
        ))}
        <Box ref={targetRef} />
      </VStack>
    </Box>
  );
};

export default ContentInfiniteScroll;
