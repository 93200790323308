import * as React from 'react';
import { Icon, type StackProps, Text, VStack } from '@chakra-ui/react';
import { IconChartDots } from '@tigerhall/icons';

interface StatisticFallbackProps extends StackProps {
  heading?: string;
  subheading?: string;
}

export function StatisticFallback({
  heading: customHeading,
  subheading: customSubheading,
  ...rest
}: StatisticFallbackProps) {
  const heading = customHeading || 'No Data Available';
  const subheading = customSubheading || 'Try changing the filter properties';

  return (
    <VStack spacing=".5rem" justifyContent="center" {...rest}>
      <Icon
        as={IconChartDots}
        width="3.25rem"
        height="3.25rem"
        color="lightGrey.900"
      />
      <Text fontSize="lg" fontWeight="bold" color="lightGrey.900">
        {heading}
      </Text>
      <Text fontSize="md" color="darkGrey.50">
        {subheading}
      </Text>
    </VStack>
  );
}
