import { extendTheme as o } from "@chakra-ui/react";
import * as e from "./index29.mjs";
import { colors as r } from "./index3.mjs";
import { fonts as t, fontSizes as i, fontWeights as m, lineHeights as s, letterSpacings as n } from "./index4.mjs";
import { shadows as f } from "./index5.mjs";
import { zIndices as p } from "./index7.mjs";
import { breakpoints as d } from "./index6.mjs";
import { styles as l } from "./index28.mjs";
const a = {
  config: {
    initialColorMode: "dark",
    useSystemColorMode: !1
  },
  colors: r,
  fonts: t,
  fontSizes: i,
  fontWeights: m,
  lineHeights: s,
  letterSpacings: n,
  shadows: f,
  zIndices: p,
  breakpoints: d,
  styles: l,
  components: { ...e }
}, z = o(a);
export {
  z as theme
};
