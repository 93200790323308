import { Button, Text } from '@chakra-ui/react';
import { ProductTourState } from '@tigerhall/core';
import { setShowGuidedPopups } from 'app/state';
import { DarkPanel } from 'components/ui';
import { useAppDispatch, useUpdateProductTour } from 'hooks';
import { useNavigate } from 'react-router-dom';

export function ProductTourSection() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { updateOnboardingStatus } = useUpdateProductTour();

  const handleStartProductTour = () => {
    updateOnboardingStatus(ProductTourState.ShowAround);
    dispatch(setShowGuidedPopups());
    navigate('/home');
  };

  return (
    <DarkPanel w="100%" gap="1.5rem">
      <Text fontSize="xl" fontWeight="bold" color="white">
        Product Tour
      </Text>
      <Text fontSize="md" fontWeight="500" color="darkGrey.50">
        Get to the know the core features of the Tigerhall app.
      </Text>
      <Button size="lg" onClick={handleStartProductTour} maxW="8.3rem">
        Take a Tour
      </Button>
    </DarkPanel>
  );
}
