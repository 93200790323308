import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/reducer';

export type State = {
  didShowNewLanguageFeaturePopup: boolean;
  showProfileSetupModal: boolean;
  shouldShowOnboardWalkthroughModal: boolean;
  shoudShowProfileTour: boolean;
  shouldShowGuidedTour: boolean;
  didShownGuidedtour: boolean;
  showCommunityGuidelineModal: boolean;
};

const initialState: State = {
  didShowNewLanguageFeaturePopup: false,
  showProfileSetupModal: false,
  shouldShowOnboardWalkthroughModal: false,
  shoudShowProfileTour: false,
  shouldShowGuidedTour: false,
  didShownGuidedtour: false,
  showCommunityGuidelineModal: false
};

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    setHasNewLanguageFeaturePopupShown: (
      state: State,
      action: PayloadAction<{ didShowNewLanguageFeaturePopup: boolean }>
    ) => ({
      ...state,
      didShowNewLanguageFeaturePopup:
        action.payload.didShowNewLanguageFeaturePopup
    }),
    setToggleProfileSetupModal: (state, action) => ({
      ...state,
      didShownGuidedtour: true,
      showProfileSetupModal: action.payload
        ? action.payload
        : !state.showProfileSetupModal
    }),
    setToggleGuidedJourneyModal: (state, action) => ({
      ...state,
      shouldShowOnboardWalkthroughModal: action.payload
        ? action.payload
        : !state.shouldShowOnboardWalkthroughModal
    }),
    setShowProfileGuidePopup: (state) => ({
      ...state,
      shouldShowGuidedTour: false,
      shoudShowProfileTour: true,
      showProfileSetupModal: false
    }),
    setStopProfileTourPopup: (state: State) => {
      return {
        ...state,
        shoudShowProfileTour: false,
        shouldShowGuidedTour: false
      };
    },
    setShowGuidedPopups: (state: State) => {
      return {
        ...state,
        shouldShowGuidedTour: true,
        showProfileSetupModal: false
      };
    },
    setOnboardingToInnitialState: (state: State) => {
      return {
        ...initialState,
        didShowNewLanguageFeaturePopup: state.didShowNewLanguageFeaturePopup
      };
    },
    setShowCommunityGuidelineModal: (state: State, action) => {
      return {
        ...state,
        showCommunityGuidelineModal: action.payload
      };
    }
  }
});

export const getHasNewLanguageFeaturePopupShown =
  () =>
  (state: RootState): boolean =>
    state.popup.didShowNewLanguageFeaturePopup;

export const getIsShowProfileSetupModal =
  () =>
  (state: RootState): boolean =>
    state.popup.showProfileSetupModal;

export const getShouldShowOnboardingWalkthroughModal =
  () =>
  (state: RootState): boolean =>
    state.popup.shouldShowOnboardWalkthroughModal;

export const getIsShowCommunityGuidelineModal =
  () =>
  (state: RootState): boolean =>
    state.popup.showCommunityGuidelineModal;

export const getisShowProfileTour =
  () =>
  (state: RootState): boolean =>
    state.popup.shoudShowProfileTour;

export const getIsDidShownGuidedTour =
  () =>
  (state: RootState): boolean =>
    state.popup.didShownGuidedtour;

export const getIsShowGuidedTour =
  () =>
  (state: RootState): boolean =>
    state.popup.shouldShowGuidedTour;

export const {
  setHasNewLanguageFeaturePopupShown,
  setShowProfileGuidePopup,
  setToggleProfileSetupModal,
  setStopProfileTourPopup,
  setToggleGuidedJourneyModal,
  setOnboardingToInnitialState,
  setShowGuidedPopups,
  setShowCommunityGuidelineModal
} = popupSlice.actions;
