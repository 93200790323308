import { lazy, Suspense } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { useSubscriptionCampaign } from './hooks';
import { Splash } from '../../../../components/ui/Layout/components';

const CampaignLandingScreen = lazy(() =>
  import('./screens/CampaignLandingScreen').then((m) => ({
    default: m.CampaignLandingScreen
  }))
);

const CampaignCheckoutCanceledScreen = lazy(() =>
  import('./screens/CampaignCheckoutCanceledScreen').then((m) => ({
    default: m.CampaignCheckoutCanceledScreen
  }))
);

function CampaignExistsGuard() {
  const campaign = useSubscriptionCampaign();

  if (!campaign) {
    return <Navigate to={'/login'} />;
  }

  return <Outlet />;
}

export const routes: RouteObject[] = [
  {
    path: '/signup-campaign/:campaign',
    element: <CampaignExistsGuard />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Splash />}>
            <CampaignLandingScreen />
          </Suspense>
        )
      },
      {
        path: 'canceled',
        element: (
          <Suspense fallback={<Splash />}>
            <CampaignCheckoutCanceledScreen />
          </Suspense>
        )
      }
    ]
  }
];
