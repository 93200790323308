import * as React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { Button, THLink } from '@tigerhall/ui-components';
import { BackButton } from 'components/ui/Buttons';
import { Interests } from 'modules/authentication/modules/Onboarding/components/Interests';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

export function UserTopics() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>My Topics</title>
      </Helmet>
      <Box
        overflowY="scroll"
        justifyContent="flex-start"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px'
          },
          '&::-webkit-scrollbar-track': {
            width: '6px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#C4C4C4',
            borderRadius: '24px'
          }
        }}
      >
        <Box mt="2.5em" ml="4.5rem">
          <BackButton>Back</BackButton>
        </Box>
        <VStack spacing={0} py="1.5rem" flex={1} bg="teal.1000">
          <Interests
            title="My Topics of Interest"
            subTitle={`Let's prioritize so you focus on what matters to you.`}
            primaryCtaText="Save"
            onComplete={() => navigate(-1)}
          />
          <Box pt="1.5rem">
            <THLink name="CANCEL" to="../">
              <Button
                text="Cancel"
                as="span"
                variant="ghost"
                size="lg"
                px="1.5rem"
                minWidth="30rem"
              />
            </THLink>
          </Box>
        </VStack>
      </Box>
    </>
  );
}
