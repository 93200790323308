// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

export interface PageViewed {
    path?:     string;
    referrer?: string;
    search?:   string;
    title?:    string;
    url?:      string;
    [property: string]: any;
}

/**
 * DO NOT EDIT. Event generated to store end users consent preferences for Unify and Twilio
 * Engage.
 */
export interface SegmentConsentPreferenceUpdated {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When an org creates a new access level
 */
export interface AccessLevelCreated {
    /**
     * The name of the access level
     */
    accessLevel?: string;
    /**
     * The organization ID
     */
    orgId?: string;
    [property: string]: any;
}

/**
 * When an existing access level has been deleted
 */
export interface AccessLevelDeleted {
    /**
     * The name of the access level
     */
    accessLevel?: string;
    /**
     * The organization ID
     */
    orgId?: string;
    [property: string]: any;
}

/**
 * When an existing access level has been edited (name and permissions)
 */
export interface AccessLevelUpdated {
    /**
     * The name of the access level
     */
    accessLevel?: string;
    /**
     * The organization ID
     */
    orgId?: string;
    [property: string]: any;
}

/**
 * When an account is created, applies to any mode of creation (such as excel uploads, sign
 * ups in web/app etc)
 */
export interface AccountCreated {
    channel?:          string;
    organisationId?:   string;
    organisationName?: string;
    [property: string]: any;
}

/**
 * When an account is deleted in the tigerhall - either by the user in app or admin in
 * backoffice
 */
export interface AccountDeleted {
    organisationId?:   string;
    organisationName?: string;
    [property: string]: any;
}

/**
 * When a user clicks "Add note" while consuming a piece of content
 */
export interface AddNoteClicked {
    /**
     * The id of the content
     */
    contentId?: string;
    /**
     * The name of the content
     */
    contentName?: string;
    /**
     * The type of the content
     */
    contentType?: string;
    /**
     * The name of the screen/page that the content is on, or the path
     */
    location?: string;
    [property: string]: any;
}

/**
 * Triggered when the user adds an event to their calendar
 */
export interface AddToCalendar {
    /**
     * The id of the content
     */
    contentId?: string;
    /**
     * e.g. google, outlook, etc. Added because we can get this service value from AddEvent,
     * implemented in web and webapp.
     */
    service?: string;
    /**
     * Title of the event
     */
    title?: string;
    /**
     * URL shared in the event
     */
    url?: string;
    [property: string]: any;
}

/**
 * When a user adds an event to their calendar after booking a seat for the experience in
 * mobile
 */
export interface AddToSchedule {
    /**
     * The id of the content
     */
    contentId?: string;
    [property: string]: any;
}

/**
 * When an org admin exports the statistics in stats page
 */
export interface AdminExportStatistics {
    /**
     * The name of the exported file
     */
    fileName: string;
    /**
     * The link with which user can access exported file
     */
    fileUrl: string;
    /**
     * Which statistics type user has exported
     */
    statisticsType: string;
    [property: string]: any;
}

/**
 * When a user clicks on any assignment card under 'My assignments' section found in 'view
 * organsiation content' segment
 */
export interface AssignmentClicked {
    /**
     * ID of the assignment
     */
    assignmentId?: string;
    /**
     * The name of the assignment
     */
    assignmentName?: string;
    [property: string]: any;
}

/**
 * When an org admin or super admin creates an assignment in the admin portal
 */
export interface AssignmentCreated {
    /**
     * todo: remove?
     */
    assignmentContentIds?: any[];
    /**
     * The title of the assignment
     */
    assignmentTitle?: string;
    /**
     * The id of the user who created the assignment
     */
    createdBy?: string;
    /**
     * The date and time when assignment is created
     */
    creationDate?: Date;
    /**
     * The date and time when assignment is due
     */
    dueDate?: Date;
    [property: string]: any;
}

/**
 * When an org admin or super admin deletes an assignment in the admin portal
 */
export interface AssignmentDeleted {
    /**
     * The id of the user who deleted the assignment
     */
    assignedById: string;
    /**
     * The name of the user who deleted the assignment
     */
    assignedByName: string;
    /**
     * ID of the assignment
     */
    assignmentId: string;
    /**
     * Title of the assignment
     */
    assignmentTitle: string;
    [property: string]: any;
}

/**
 * When every content piece found in an assignment is marked as completed (90% is the mark
 * for each content piece)
 */
export interface AssignmentFinished {
    /**
     * The id of the assignment
     */
    assignmentId: string;
    /**
     * The title of the assignment
     */
    assignmentTitle: string;
    [property: string]: any;
}

/**
 * When an super admin or org admin makes an active assignment inactive.
 */
export interface AssignmentMadeInactive {
    /**
     * todo: remove?
     */
    assignmentContentIds?: any[];
    /**
     * The title of the assignment
     */
    assignmentTitle?: string;
    /**
     * The id of the user who created the assignment
     */
    createdBy?: string;
    [property: string]: any;
}

/**
 * When an assignment is assigned to the user
 */
export interface AssignmentStarted {
    /**
     * The Id of the assignment
     */
    assignmentId: string;
    /**
     * Title of the assignment
     */
    assignmentName: string;
    [property: string]: any;
}

/**
 * When user attempts to consume content above the freemium limit currently set at 3 content
 * pieces (will no longer be in use after 30 day trial kicks in)
 */
export interface AttemptedToConsumeContentOverFreemiumLimit {
    contentId?: string;
    [property: string]: any;
}

/**
 * When a user clicks on 'book a seat' for an event or livestream
 */
export interface BookASpotClicked {
    /**
     * ID of the content
     */
    contentId?: string;
    /**
     * The name of the screen/page that the content is on, or the path
     */
    location?: string;
    [property: string]: any;
}

/**
 * When a user bookmarks a piece of content
 */
export interface BookmarkClicked {
    /**
     * ID of the content
     */
    contentId?: string;
    /**
     * Name of the content
     */
    contentName?: string;
    /**
     * Type of content
     */
    contentType?: BookmarkClickedContentType;
    /**
     * Eg. URL for web, screenName for mobile
     */
    location?: string;
    /**
     * Title of the section / group where the bookmarked element is, if any. e.g. in the webapp,
     * the title of the carousel section where a bookmarked content card is.
     */
    section?: string;
    /**
     * Bookmarked selected or unselected
     */
    state?: BookmarkClickedState;
    [property: string]: any;
}

/**
 * Type of content
 */
export type BookmarkClickedContentType = "Ebook" | "Event" | "LearningPath" | "Podcast" | "Stream";

/**
 * Bookmarked selected or unselected
 */
export type BookmarkClickedState = "selected" | "unselected";

/**
 * When the user clicks a button
 */
export interface ButtonClicked {
    /**
     * The name of the button
     */
    buttonName: string;
    /**
     * The button name, todo: remove?
     */
    buttonType?: string;
    /**
     * The name of the form
     */
    formName?: string;
    /**
     * The destination link
     */
    href?: string;
    /**
     * An extra field for name if the button name is different (e.g for a Strapi marketing
     * website component).
     */
    label?: string;
    /**
     * The name of the screen/page that the button is on, or the path
     */
    location: string;
    /**
     * The order of the strapi component on the page. Eg. if there are two image sections, the
     * first position should be 1.
     */
    sectionPositionIndexByType?: number;
    /**
     * todo: remove?
     */
    value?: string;
    [property: string]: any;
}

/**
 * When a user clicks a cancel button in the mobile application
 */
export interface CanceledButtonClicked {
    /**
     * The name of the button
     */
    buttonName?: string;
    /**
     * The name of the screen/page that the button is on, or the path
     */
    location?: string;
    [property: string]: any;
}

/**
 * When a user swipes on a card in the card swiping feature
 */
export interface CardSwiped {
    answer:        Answer;
    cardId?:       string;
    cardQuestion?: string;
    /**
     * E.g. onboarding
     */
    purpose?: string;
    [property: string]: any;
}

export type Answer = "No" | "Yes";

/**
 * When a user scrolls or clicks to change the carousel content.
 */
export interface CarouselChanged {
    /**
     * The screen or page where the change occurs
     */
    location?:            string;
    newContentId?:        string;
    newContentName?:      string;
    previousContentId?:   string;
    previousContentName?: string;
    [property: string]: any;
}

/**
 * When a user clicks a content in Top Picks (note: will be removed soon)
 */
export interface CarouselClicked {
    categories?:    any[];
    categoriesIds?: any[];
    contentId?:     string;
    contentName?:   string;
    contentType?:   string;
    duration?:      number;
    /**
     * The names of the experts
     */
    experts?: any[];
    /**
     * The ids of the experts
     */
    expertsIds?: any[];
    /**
     * What position the featured banner is in the list.
     */
    indexInList?:   number;
    isFreeContent?: boolean;
    /**
     * The location or page where the click occurs
     */
    location?:         string;
    targetUrl?:        string;
    wasButtonClicked?: boolean;
    [property: string]: any;
}

/**
 * When a user clicks clear cache in the mobile application. This button can be found in
 * Profile -> Settings Page
 */
export interface ClearCacheClicked {
    customerType?: CustomerType | null;
    [property: string]: any;
}

export type CustomerType = "ENTERPRISE" | "INDIVIDUAL" | "TEAM";

/**
 * When the comment button that appears below a post is clicked
 */
export interface CommentButtonClicked {
    location?:    string;
    referenceId?: string;
    [property: string]: any;
}

/**
 * When a user clicks to delete their own comment or when an admin clicks to delete someone
 * else's comment (Note: comment_options_clicked with button name as comment_deleted also
 * gets triggered, so this is duplicate and can be removed in future)
 */
export interface CommentDeletedClicked {
    /**
     * Id of the comment
     */
    commentId?:   string;
    commentType?: string;
    /**
     * Id of the content
     */
    contentId?: string;
    /**
     * Type of the content
     */
    contentType?: string;
    location?:    string;
    /**
     * If reply, id of the parent comment
     */
    parentComment?: string;
    [property: string]: any;
}

/**
 * When a user clicks the ellipses found on the top right hand corner of a comment (Note: it
 * also gets triggered when any options are clicked inside it)
 */
export interface CommentOptionsClicked {
    /**
     * edit_comment, delete_comment, report_comment
     */
    buttonName?:  string;
    commentType?: string;
    referenceId?: string;
    [property: string]: any;
}

/**
 * When a user clicks the reply button found in a parent comment or type a new thread in a
 * post
 */
export interface CommentReplyButtonClicked {
    /**
     * The name of the person who is being replied to
     */
    authorId: string;
    /**
     * The ID of the person who is being replied to
     */
    authorName:  string;
    referenceId: string;
    /**
     * POST, REPOST_WITH_THOUGHTS, INSTANT_REPOST, COMMENT, REPLY
     */
    referenceType: CommentReplyButtonClickedReferenceType;
    [property: string]: any;
}

/**
 * POST, REPOST_WITH_THOUGHTS, INSTANT_REPOST, COMMENT, REPLY
 */
export type CommentReplyButtonClickedReferenceType = "COMMENT" | "INSTANT_REPOST" | "POST" | "REPLY" | "REPOST_WITH_THOUGHTS";

/**
 * When a user clicks the send button after typing a comment (Note carlos: a comment or a
 * reply. You can differentiate it using the property `commentType` which can be
 * `CONTENT_COMMENT || CONTENT_REPLY || POST_COMMENT || POST_REPLY`)
 */
export interface CommentSent {
    /**
     * The text of the message
     */
    commentText?: string;
    /**
     * Post Comment, Post Reply, Content Comment, Content Reply
     */
    commentType?: CommentType;
    /**
     * Id of the content
     */
    contentId?: string;
    /**
     * Type of the content
     */
    contentType?: { [key: string]: any };
    /**
     * User who added comment is thinkfluencer or not
     */
    isThinkfluencer?: boolean;
    /**
     * The location of the comment
     */
    location?: string;
    /**
     * If reply, the id of the parent comment
     */
    parentComment?: string;
    /**
     * Id of post or content
     */
    referenceId?: string;
    /**
     * POST,REPOST_WITH_THOUGHTS,INSTANT_REPOST,CONTENT
     */
    referenceType?: CommentSentReferenceType;
    [property: string]: any;
}

/**
 * Post Comment, Post Reply, Content Comment, Content Reply
 */
export type CommentType = " CONTENT_REPLY" | "CONTENT_COMMENT" | "CONTENT_REPLY" | "FEED_COMMENT" | "FEED_REPLY" | "POST_COMMENT" | "POST_REPLY";

/**
 * POST,REPOST_WITH_THOUGHTS,INSTANT_REPOST,CONTENT
 */
export type CommentSentReferenceType = "CONTENT" | "INSTANT_REPOST" | "POST" | "REPOST_WITH_THOUGHTS";

/**
 * When a user clicks a confirmation button to take an action especially for livestreams,
 * experiences and cohorts (in web app is is only used in one place, when the user
 * "confirms" that wants to delete their account) (In mobile, it is for all the confirm
 * buttons in mobile. It have attribute buttonName which tells which modal's confirm button
 * is clicked.
 */
export interface ConfirmationButtonClicked {
    /**
     * The name of the button
     */
    buttonName?: string;
    /**
     * The name of the screen/page that the button is on, or the path
     */
    location?: string;
    [property: string]: any;
}

/**
 * When content is autoplayed as part of a trail or assignment.
 */
export interface ContentAutoplayed {
    /**
     * The state of the player while consuming the content piece. It can be "Maximized",
     * "Minimized" or "Backgrounded".
     */
    state: ContentAutoplayedState;
    [property: string]: any;
}

/**
 * The state of the player while consuming the content piece. It can be "Maximized",
 * "Minimized" or "Backgrounded".
 */
export type ContentAutoplayedState = "Backgrounded" | "Maximized" | "Minimized";

/**
 * When the user clicks a content card from a section or a search result.
 */
export interface ContentCardClicked {
    /**
     * Which index the card has in the content list starting from zero
     */
    cardIndexInList?: number;
    categories?:      any[];
    categoriesIds?:   any[];
    contentId:        string;
    contentName?:     string;
    contentType:      string;
    duration?:        number;
    experts?:         any[];
    expertsIds?:      any[];
    /**
     * It is `true` if this content is part of an assignment given to the user that clicks the
     * card.
     */
    isAssigned?:    boolean;
    isFreeContent?: boolean;
    sectionName:    string;
    [property: string]: any;
}

/**
 * Triggered at regular intervals of 10 seconds when a user is consuming content
 */
export interface ContentConsumedAtInterval {
    /**
     * The ID of the content piece that the user is consuming
     */
    contentId: string;
    /**
     * The type of the content
     */
    contentType: ContentConsumedAtIntervalContentType;
    /**
     * How long the user has been consuming this content piece, this should be triggered in
     * intervals of max ten seconds.
     */
    duration: number;
    /**
     * For content where applicable it should be (position.current - position.previos) /
     * playbackRate
     */
    durationByPositionDelta?: number;
    /**
     * If podcasts contains multiple episode then this is the zero-indexed episode number
     */
    episodeIndex?: number;
    /**
     * If the user is watching a stream that is currently live
     */
    isLive?: boolean;
    /**
     * If this event has been originally triggered when the user didn't have internet access.
     */
    isOffline?: boolean;
    /**
     * The language in which user is consuming content
     */
    language?: string;
    /**
     * If the content type permits how quickly the content is being played at.
     */
    playbackRate?: number;
    /**
     * The ID of the playlist the user is consuming the content piece from.
     */
    playlistId?: string;
    /**
     * The type of the playlist. It can be "Trail" or "Assignment".
     */
    playlistType?: PlaylistType;
    position:      Position;
    /**
     * When we started tracking this activity
     */
    startedTrackingAt: Date;
    /**
     * The state of the player while consuming the content piece. It can be "Maximized",
     * "Minimized" or "Backgrounded".
     */
    state?: ContentAutoplayedState;
    [property: string]: any;
}

/**
 * The type of the content
 */
export type ContentConsumedAtIntervalContentType = "Ebook" | "Event" | "Podcast" | "Stream";

/**
 * The type of the playlist. It can be "Trail" or "Assignment".
 */
export type PlaylistType = "Assignment" | "Trail";

export interface Position {
    /**
     * The position where the user currently is once this is tracked
     */
    current: number;
    /**
     * The previous current value
     */
    previous: number;
    /**
     * The total number of seconds or pages in a content page, this will be null for livestreams
     * because we don't know the duration.
     */
    total?: number;
    [property: string]: any;
}

/**
 * When user has completed 90% of a content piece or marks it as finished or go to the last
 * page of the power read (Note carlos: in case of marketing website power reads, it's when
 * the user reaches the end of the power read body)
 */
export interface ContentFinished {
    /**
     * The id of the content
     */
    contentId: string;
    /**
     * The name of the content
     */
    contentName: string;
    /**
     * The type of the content
     */
    contentType: ContentFinishedContentType;
    /**
     * If the content is assigned to user or not
     */
    isAssigned?: boolean;
    [property: string]: any;
}

/**
 * The type of the content
 */
export type ContentFinishedContentType = "ebook" | "event" | "podcast" | "stream";

/**
 * When the playback state of media changes while a user toggles between pause and play
 * (Note carlos: applies to videos and podcasts only - need a ticket to add the event back
 * in web app)
 */
export interface ContentPlaybackStateChanged {
    /**
     * The id of the content
     */
    contentId: string;
    /**
     * The type of the content
     */
    contentType: ContentPlaybackStateChangedContentType;
    /**
     * The position at which state changed
     */
    currentPosition: number;
    /**
     * The state of play like paused, started, buffered etc
     */
    state: ContentPlaybackStateChangedState;
    [property: string]: any;
}

/**
 * The type of the content
 */
export type ContentPlaybackStateChangedContentType = "Podcast" | "Stream";

/**
 * The state of play like paused, started, buffered etc
 */
export type ContentPlaybackStateChangedState = "buffering" | "paused" | "playing";

/**
 * When the user jumps forward or backwards in a podcast or video
 */
export interface ContentPositionSeek {
    /**
     * The id of the content
     */
    contentId: string;
    /**
     * The type of the content
     */
    contentType: ContentConsumedAtIntervalContentType;
    /**
     * If this is a podcast with multiple episodes this should be the episode index.
     */
    episodeIndex?: number;
    /**
     * From what position the user jumped from in seconds from 10
     */
    positionFrom: number;
    /**
     * To what position the user jumped in seconds e.g 60
     */
    positionTo: number;
    [property: string]: any;
}

/**
 * When a user completes the 20% mark(preview) of a content piece  to trigger a pop-up with
 * the restart over or subscribe now.
 */
export interface ContentPreviewCompleted {
    /**
     * The names of the categories associated
     */
    categories?: any[];
    /**
     * The ids of the categories associated
     */
    categoriesIds?: any[];
    /**
     * The id of the content
     */
    contentId: string;
    /**
     * The name of the content
     */
    contentName: string;
    /**
     * The type of the content
     */
    contentType: string;
    /**
     * The names of the experts
     */
    experts?: any[];
    /**
     * The ids of the experts
     */
    expertsIds?: any[];
    /**
     * The tray from which the content was played
     */
    section?: string;
    [property: string]: any;
}

/**
 * When a user searches for any term in the search box
 */
export interface ContentSearched {
    /**
     * The names of the categories associated
     */
    category?: string;
    /**
     * The ids of the categories associated
     */
    categoryId?: string;
    /**
     * The type of the content
     */
    contentType?: string;
    /**
     * The length of the content
     */
    duration?: string;
    /**
     * The name of the expert
     */
    expert?: string;
    /**
     * The id of the expert
     */
    expertId?: string;
    /**
     * The user who searched is mentor or not
     */
    isMentor?: boolean;
    /**
     * The search query
     */
    searchTerm?: string;
    [property: string]: any;
}

/**
 * When a user clicks to start a content piece for the first time.
 */
export interface ContentStarted {
    /**
     * The id of the content
     */
    contentID?: string;
    /**
     * The name of the content
     */
    contentName?: string;
    /**
     * The type of the content
     */
    contentType?: string;
    /**
     * If the content is assigned to user or not
     */
    isAssigned?: boolean;
    /**
     * The page on which the event was triggered
     */
    location?: string;
    [property: string]: any;
}

/**
 * When a user clicks delete account in their profile setting
 */
export interface DeleteAccountClicked {
    customerType?: CustomerType | null;
    [property: string]: any;
}

/**
 * When a user drags and drops a piece of content
 */
export interface DragDropClicked {
    /**
     * The end position of the content
     */
    endPosition?: number;
    /**
     * The page on which the drag and drop occurs
     */
    location?: string;
    /**
     * The starting position of the content
     */
    startPosition?: number;
    [property: string]: any;
}

/**
 * When a user clicks the dropdown
 */
export interface DropdownClicked {
    /**
     * The name of the dropdown
     */
    dropdownName?: string;
    /**
     * The page/location on which the dropdown was selected
     */
    location?: string;
    [property: string]: any;
}

/**
 * When the user clicks a selection from any dropdown menu.
 */
export interface DropdownSelectionClicked {
    /**
     * The dates range selected by the user
     */
    dateRangeSelection?: string;
    /**
     * The name of the dropdown
     */
    dropdownName?: string;
    /**
     * Whether item is selected or unselected
     */
    isSelected?: boolean;
    /**
     * The page/location on which the dropdown is located
     */
    location?: string;
    /**
     * What the user selected in the dropdown
     */
    selection?: string;
    [property: string]: any;
}

/**
 * When a user clicks to edit their email address in the signup screen
 */
export interface EditEmailClicked {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When the Emoji Picker popover is opened. Currently available in the broadcast and
 * livestream chat.
 */
export interface EmojiPickerOpened {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When an emoji from the Emoji Picker popover is selected/clicked.
 */
export interface EmojiPickerSelection {
    /**
     * Selected/clicked emoji from the Emoji Picker.
     */
    emoji: string;
    /**
     * If the selected emoji is a custom emoji. The library we use in the web app supports
     * adding custom emojis as Slack does, however, we are not enabling this option yet but
     * might do it in the future.
     */
    isCustom: boolean;
    /**
     * Plain text name of the selected/clicked emoji from the Emoji Picker. e.g. "grinning"
     */
    name: string;
    [property: string]: any;
}

/**
 * When an expired enterprise user logs in, they see an overlay stating their account has
 * expired while accessing the product.
 */
export interface EnterpriseOverlay {
    /**
     * The id of org user belongs to
     */
    organisationId: string;
    /**
     * The name of org user belongs to
     */
    organisationName: string;
    [property: string]: any;
}

/**
 * When a user books a seat for an experience (event) and joins the corresponding livestream
 */
export interface EventAttended {
    /**
     * The names of all the categories associated
     */
    categories?: string;
    /**
     * The ids of all the categories associated
     */
    categoriesId?: string;
    /**
     * The id of the content
     */
    contentId?: string;
    /**
     * The type of the content
     */
    contentType?: string;
    /**
     * The names of all the experts
     */
    experts?: string;
    /**
     * The ids of all the experts
     */
    expertsId?: string;
    [property: string]: any;
}

/**
 * When a user books a seat for an experience (event)
 */
export interface EventBooked {
    /**
     * The id of the content
     */
    contentId: string;
    /**
     * The name of the content
     */
    contentName: string;
    /**
     * Date time of when the event will start
     */
    eventStartsAt: string;
    /**
     * Type of event like Livestream, Meet_In_Person etc. Currently only Livestream is getting
     * used.
     */
    eventType?: string;
    /**
     * The univesral link for event content card
     */
    experienceLink?: string;
    /**
     * The company names of all the experts
     */
    expertCompany?: any[];
    /**
     * The names of all the experts
     */
    expertName: any[];
    /**
     * The job titles of all the experts
     */
    expertTitle?: any[];
    /**
     * If the event type is livestream, then name of the livestream
     */
    livestreamName?: string;
    /**
     * Add event to calendar url
     */
    url: string;
    [property: string]: any;
}

/**
 * When a user clicks on post in feed and it opens as separate modal.
 */
export interface FeedPostClicked {
    postType?:   string;
    referenceId: string;
    [property: string]: any;
}

/**
 * When a user sees a post, it's marked as an impression
 */
export interface FeedPostImpression {
    postType?:   string;
    referenceId: string;
    [property: string]: any;
}

/**
 * When a user clicks into the post area to start a new post
 */
export interface FeedPostStarted {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When a user scrolls to get more content in a feed or chat (In web: in feed, chat, profile
 * activity, post comments, post reactions, post reposts) (In mobile: For contents, post
 * comments, feed, profile activity, notifications)
 */
export interface FetchMore {
    /**
     * Where the fetch is occurring
     */
    componentName?: string;
    /**
     * Measuring how far the user is scrolling
     */
    fetchedItemCount?: number;
    [property: string]: any;
}

/**
 * When a user uploads an image file for cover image or profile image in the formats like
 * jpg, jpeg & png.
 */
export interface FileUploadSelected {
    /**
     * The name of the field
     */
    fieldName?: string;
    /**
     * The name of the file
     */
    fileName?: string;
    /**
     * The size of the file
     */
    fileSize: number;
    /**
     * The file type
     */
    fileType: string;
    /**
     * The name of the form
     */
    formName: string;
    [property: string]: any;
}

/**
 * When a user clicks the follow button which can either follow or unfollow another user
 */
export interface FollowButtonClicked {
    /**
     * Id of the user which the current user is following or unfollowing
     */
    followerId?: string;
    /**
     * Whether the target user is already following the person clicking the follow button. True
     * means the target user is following the user. False means the target user does not follow
     * the user.
     */
    isFollowBack?: boolean;
    /**
     * When a user toggles between following and unfollowing a user
     */
    isFollowing: boolean;
    /**
     * Where a user clicks the follow button
     */
    location?: string;
    [property: string]: any;
}

/**
 * when an user clicks on any link in the footer in marketing website
 */
export interface FooterClicked {
    /**
     * The screen/page where the user clicked the footer
     */
    location: string;
    /**
     * The name of the link/section clicked: content, thinkfluencers, resources, sitemap,
     * contactUs, careers, privacyPolicy, termsConditions, linkedin, facebook, twitter,
     * instagram, appStoreCTA, googlePlayCta
     */
    section: string;
    [property: string]: any;
}

/**
 * This should only trigger this using onblur event of the element (Used for followers and
 * following search bar, on button clicks for suggest language submit, create trail group
 * submit, send button in chat)
 */
export interface FormFieldFilled {
    /**
     * The form field the user focused on and then blurred
     */
    fieldName?: string;
    /**
     * A unique identifier for the form, this is used by hubspot in the marketing website
     */
    formId?: string;
    /**
     * Where the user filled out the form
     */
    formLocation?: string;
    /**
     * The name of of the form
     */
    formName: string;
    /**
     * If the field was modified before the blur event was triggered (Reflects to
     * react-hook-form isDirty)
     */
    isModified?: { [key: string]: any };
    /**
     * The field value
     */
    value?: any;
    [property: string]: any;
}

/**
 * When a user submits a form (It is triggered for any input field (form) without submit
 * button too)
 */
export interface FormSubmitted {
    /**
     * A unique identifier for the form, this is used by hubspot in the marketing website
     */
    formId?: string;
    /**
     * Where the user filled out the form
     */
    formLocation?: string;
    /**
     * The name of of the form
     */
    formName?: string;
    [property: string]: any;
}

/**
 * When user views a form
 */
export interface FormViewed {
    /**
     * A unique identifier for the form, this is used by hubspot in the marketing website
     */
    formId?: string;
    /**
     * The screen or path of where the user filled out the form
     */
    formLocation?: string;
    /**
     * The name of of the form
     */
    formName: string;
    [property: string]: any;
}

/**
 * Counts when a free content piece is consumed for a user with a free account
 */
export interface FreeContentCount {
    /**
     * The id of the content
     */
    contentId?: string;
    /**
     * The name of the content
     */
    contentName?: string;
    /**
     * The type of the content
     */
    contentType?: string;
    /**
     * e.g content piece 1, 2 or 3
     */
    demoNumber?: number;
    /**
     * The ids of all the experts of this content
     */
    expertIds?: any[];
    /**
     * The names of all the experts of this content
     */
    expertNames?: string;
    [property: string]: any;
}

/**
 * When free account user completes 3 pieces
 */
export interface FreeContentLimitReached {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When a user gets invited to join organisation from the admin through an excel upload or a
 * single user
 */
export interface InvitedToJoinOrganisation {
    /**
     * Whether the user is b2b user (true) or b2c user (false)
     */
    isB2bUser: boolean;
    /**
     * Name of the organisation that send the invitation
     */
    orgName: string;
    /**
     * The url for user to accept the invitation
     */
    url: string;
    [property: string]: any;
}

/**
 * When a user changes the language of a content piece
 */
export interface LanguageChanged {
    /**
     * path or URL in the mobile app or webapp
     */
    location?: string;
    /**
     * The previous language that was selected
     */
    previousLanguage?: string;
    /**
     * New language selected
     */
    selectedLanguage?: string;
    [property: string]: any;
}

/**
 * When a user clicks the like button to like or unlike a content piece
 */
export interface LikeClicked {
    /**
     * The id of the content
     */
    contentId?: string;
    /**
     * The name of the content
     */
    contentName?: string;
    /**
     * The type of the content
     */
    contentType?: string;
    /**
     * Whether user is thinkfluencer or not
     */
    isThinkfluencer?: boolean;
    /**
     * Records the state of the answer (whether the user is liking or unliking)
     */
    state?: LikeClickedState;
    [property: string]: any;
}

/**
 * Records the state of the answer (whether the user is liking or unliking)
 */
export type LikeClickedState = "liked" | "undetermined" | "unliked";

/**
 * When a user views/reaches the end screen of livestream or broadcast
 */
export interface LiveEventEndedScreen {
    /**
     * The id of the live event
     */
    contentId: string;
    /**
     * The name of the live event
     */
    contentName: string;
    /**
     * The type of the live event i.e livestream or broadcast
     */
    type: string;
    [property: string]: any;
}

/**
 * When a user joins a livestream (Note carlos: I see we're triggering this event manually
 * also from the marketing website, should we remove it?)
 */
export interface LivestreamJoined {
    /**
     * The id of the livestream
     */
    contentId: string;
    /**
     * The name of the livestream
     */
    contentName: string;
    /**
     * If it's a org livestream, the id of the org
     */
    organisationId?: string;
    /**
     * If it's a org livestream, the name of the org
     */
    organisationName?: string;
    [property: string]: any;
}

/**
 * When a user sends a message or comment in livestream
 */
export interface LivestreamMessageSent {
    /**
     * The id of the livestream
     */
    contentId: string;
    /**
     * The name of the livestream
     */
    contentName: string;
    /**
     * Message sent by user
     */
    message: string;
    /**
     * If it's a org livestream, the id of the org
     */
    organisationId?: string;
    /**
     * If it's a org livestream, the name of the org
     */
    organisationName?: string;
    [property: string]: any;
}

/**
 * When a user logs in using apple or linkedin account
 */
export interface Login {
    channel?: string;
    [property: string]: any;
}

/**
 * When a user logs out
 */
export interface Logout {
    userId?: { [key: string]: any };
    [property: string]: any;
}

/**
 * When a video is played and completed in the marketing website
 */
export interface MarketingVideoCompleted {
    adAssetId?:       any[];
    adEnabled?:       boolean;
    adPodId?:         any[];
    adType?:          any[];
    bitrate?:         number;
    contentAssetIds?: any[];
    contentPodIds?:   any[];
    framerate?:       number;
    fullscreen?:      string;
    livestream?:      boolean;
    position?:        number;
    quality?:         string;
    sessionId?:       string;
    sound?:           number;
    totalLength?:     number;
    videoPlayer?:     string;
    [property: string]: any;
}

/**
 * When a video is played in the marketing website
 */
export interface MarketingVideoPlayed {
    adAssetId?:       any[];
    adEnabled?:       boolean;
    adPodId?:         any[];
    adType?:          any[];
    bitrate?:         number;
    contentAssetIds?: any[];
    contentPodIds?:   any[];
    framerate?:       number;
    fullscreen?:      string;
    livestream?:      boolean;
    position?:        number;
    quality?:         string;
    sessionId?:       string;
    sound?:           number;
    totalLength?:     number;
    videoPlayer?:     string;
    [property: string]: any;
}

/**
 * When a user clicks on a mention link to go to the mentioned user profile
 */
export interface MentionClicked {
    location?:       string;
    mentionedUserId: string;
    [property: string]: any;
}

/**
 * When a user selects to mention another user but has not sent the mention yet
 */
export interface MentionFinished {
    /**
     * The location (screen, path, url) where the reaction was clicked.
     */
    location:    string;
    referenceId: string;
    /**
     * POST, REPOST_WITH_THOUGHTS, INSTANT_REPOST, COMMENT, REPLY
     */
    referenceType?: CommentReplyButtonClickedReferenceType;
    [property: string]: any;
}

/**
 * When user starts typing a mention and presses backspace to delete the mention
 */
export interface MentionRemoved {
    isExpert?:          boolean;
    location?:          string;
    mentionedUserId?:   string;
    mentionedUserName?: string;
    referenceId?:       string;
    referenceType?:     string;
    [property: string]: any;
}

/**
 * When a user starts typing a mention using @
 */
export interface MentionStarted {
    location?:    string;
    referenceId?: string;
    /**
     * POST, REPOST_WITH_THOUGHTS, INSTANT_REPOST, COMMENT, REPLY
     */
    referenceType?: string;
    [property: string]: any;
}

/**
 * When a user clicks an action on the navigation bar
 */
export interface NavBarClicked {
    /**
     * The name of the dropdown. For example, profile dropdown
     */
    dropdownName?: string;
    /**
     * My Profile, Account Settings, Logout
     */
    dropdownSelection?: string;
    /**
     * The page on which the user was when they clicked the nav bar item
     */
    location?: string;
    /**
     * Home, Explore, Trails, Feed, Profile, Profile Dropdown, Notifications, Schedule, My
     * Trails, Search, Enterprises, Content, Features, About Us
     */
    section?: string;
    [property: string]: any;
}

/**
 * When a user clicks a navigation button (In marketing the navigation buttons are in the
 * header, in web app the navigation buttons are in the header and sidebar)
 */
export interface NavButtonClicked {
    /**
     * The section or page where a user clicks the navigation button
     */
    location?: string;
    /**
     * Includes: Open Livestream Studio, View Assigned Content, View My Topics of Interest, View
     * All Notes, Share Tigerhall, Change Communication Preferences, Account Details, Change
     * Password, Edit Profile, Back, Close, Topic button
     */
    navButtonName?: string;
    /**
     * Query Params present in url when this event is triggered. For eg, anything present after
     * ? in url
     */
    queryParams?: string;
    [property: string]: any;
}

/**
 * When an existing user accepts an invitation to join the organisation, sent by the
 * organisation admin
 */
export interface NewInvitationAccepted {
    organisationId?:  string;
    organisationName: string;
    userEmail:        string;
    userId?:          string;
    userName:         string;
    [property: string]: any;
}

/**
 * When a new notification is sent to user, total number of new notifications user has till
 * that point of time. New notification means when user has not seen the notification even
 * once.
 */
export interface NewNotificationsCount {
    /**
     * Total number of new notifications count for the user
     */
    notificationsCount: number;
    /**
     * the intended client platform to show the count (MOBILE / WEB)
     */
    viewerType: string;
    [property: string]: any;
}

/**
 * When new b2c user is registered
 */
export interface NewRegisteredB2CUser {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When a user subscribes to Tigerhall newsletter(specificaly to the newsletter subscription
 * form from hubspot)
 */
export interface NewsletterSubscribed {
    id?:   string;
    name?: string;
    [property: string]: any;
}

/**
 * When a user clicks cancel after opening a note
 */
export interface NoteCancelClicked {
    /**
     * The id of the content
     */
    contentId: string;
    /**
     * The name of the content
     */
    contentName: string;
    /**
     * The type of the content
     */
    contentType: ContentConsumedAtIntervalContentType;
    [property: string]: any;
}

/**
 * When a user closes a note
 */
export interface NoteCloseClicked {
    /**
     * The id of the content
     */
    contentId?: { [key: string]: any };
    /**
     * The name of the content
     */
    contentName?: { [key: string]: any };
    /**
     * The type of the content
     */
    contentType?: { [key: string]: any };
    [property: string]: any;
}

/**
 * When a user deletes a note
 */
export interface NoteDeleteClicked {
    /**
     * The id of the content
     */
    contentId?: { [key: string]: any };
    /**
     * The name of the content
     */
    contentName?: { [key: string]: any };
    /**
     * The type of the content
     */
    contentType?: { [key: string]: any };
    [property: string]: any;
}

/**
 * When a user edits a note
 */
export interface NoteEditClicked {
    /**
     * The id of the content
     */
    contentId?: { [key: string]: any };
    /**
     * The name of the content
     */
    contentName?: { [key: string]: any };
    /**
     * The type of the content
     */
    cotentType?: { [key: string]: any };
    [property: string]: any;
}

/**
 * When a user saves a note
 */
export interface NoteSavedClicked {
    /**
     * The id of the content
     */
    contentId: string;
    /**
     * The name of the content
     */
    contentName: string;
    /**
     * The type of the content
     */
    contentType: ContentConsumedAtIntervalContentType;
    [property: string]: any;
}

/**
 * When a user updates a note
 */
export interface NoteUpdated {
    /**
     * The id of the content
     */
    contentId: string;
    /**
     * The name of the content
     */
    contentName: string;
    /**
     * The type of the content
     */
    contentType: ContentConsumedAtIntervalContentType;
    [property: string]: any;
}

/**
 * When a user clicks on the note button section
 */
export interface NotesButtonClicked {
    /**
     * The id of the content
     */
    contentId?: { [key: string]: any };
    /**
     * The name of the content
     */
    contentName?: { [key: string]: any };
    /**
     * The type of the content
     */
    contentType?: { [key: string]: any };
    [property: string]: any;
}

/**
 * When a used clicks on a notification in the notifications center
 */
export interface NotificationClicked {
    /**
     * Body content of the notification
     */
    body?: string;
    /**
     * pathname where the action occured
     */
    location?: string;
    /**
     * Title of the notification
     */
    title: string;
    /**
     * Type of notification
     */
    type: NotificationClickedType | null;
    [property: string]: any;
}

export type NotificationClickedType = "ASSIGNMENT" | "CHAT_MESSAGES" | "COHORT_INVITATION" | "COHORT_INVITATION_ACCEPTED" | "COHORT_INVITATION_REJECTED" | "COMMENT" | "FOLLOW" | "GENERAL_NEWSLETTER" | "LIKE" | "LIVESTREAM_INVITED_AS_HOST" | "LIVESTREAM_IS_LIVE" | "MARKETING_COMMUNICATIONS" | "ORGANISATION_INVITATION_ACCEPTED" | "ORGANISATION_INVITATION_REJECTED" | "PRODUCT_UPDATES" | "REPLY_ON_COMMENT" | "THINKFLUENCER_NEW_CONTENT";

/**
 * When a user completes the onboarding flow
 */
export interface OnboardingCompleted {
    /**
     * Shows the topics a user selected during onboarding
     */
    onboardingTopicsSelected?: any[];
    [property: string]: any;
}

/**
 * When a user clicks to view organisation content page
 */
export interface OrganisationContentClicked {
    /**
     * The id of the organisation
     */
    organisationId?: string;
    /**
     * The name of the organisation
     */
    organisationName?: string;
    [property: string]: any;
}

/**
 * When a user clicks on a poll answer
 */
export interface PollVoteClicked {
    /**
     * The answer the user clicks on
     */
    pollAnswer?: string;
    /**
     * The id of the correct answer of the poll
     */
    pollAnswerId?: string;
    /**
     * The poll ID
     */
    pollId?: string;
    /**
     * The poll question
     */
    pollQuestion?: string;
    [property: string]: any;
}

/**
 * While creating a post, when the user clicks the dropdown that lists the audience of the
 * post e.g. public, organization only, organization announcement, etc.
 */
export interface PostAudienceClicked {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * "
 * When the user then selects one of the audiences in the dropdown menu, replacing the
 * pre-selected audience or original audience chosen"
 */
export interface PostAudienceSelected {
    /**
     * org_announcement, org_only, public, tigerhall_announcement
     */
    postAudience: PostAudience;
    [property: string]: any;
}

/**
 * org_announcement, org_only, public, tigerhall_announcement
 *
 * Public, Organization Only, Organization Announcement, Tigerhall-Wide Announcement
 */
export type PostAudience = "org_announcement" | "org_only" | "public" | "tigerhall_announcement";

/**
 * When a post is created.
 */
export interface PostCreated {
    /**
     * Public, Organization Only, Organization Announcement, Tigerhall-Wide Announcement
     */
    postAudience: PostAudience;
    /**
     * Post, Edited Post, Repost, Share
     */
    postType: PostType;
    [property: string]: any;
}

/**
 * Post, Edited Post, Repost, Share
 */
export type PostType = "edited_post" | "post" | "repost" | "share";

/**
 * When a user clicks the post ellipses
 */
export interface PostOptionsClicked {
    /**
     * share_post, edit_post, delete_post, report_post
     */
    buttonName?:  string;
    referenceId?: string;
    [property: string]: any;
}

/**
 * When the user clicks WATCH the product tour video
 */
export interface ProductTourCompleted {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When a user starts the product tour after the onboarding_completed fires
 */
export interface ProductTourStarted {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When a user clicks on a profile icon or link leading to a profile page (Note carlos:
 * correct, but in the webapp we have links to profiles in many different places, not all of
 * the links to profiles are triggering this event)
 */
export interface ProfileClicked {
    /**
     * The page or path of the profile
     */
    href?: string;
    /**
     * True or false
     */
    isThinkfluencer?: boolean;
    /**
     * Page or path where the user clicked the profile
     */
    location?: string;
    /**
     * The name of the user profile
     */
    profileName?: string;
    /**
     * The userId of the profile
     */
    userId?: string;
    [property: string]: any;
}

/**
 * When a user updates their profile
 */
export interface ProfileUpdated {
    /**
     * If a user updates their profile during onboarding
     */
    duringOnboarding?: boolean;
    /**
     * The level of the profile when a user updates their profile
     */
    profileLevel?: string;
    [property: string]: any;
}

/**
 * When user is promoted to thinkfluencer in the admin portal
 */
export interface PromotedToThinkfluencer {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When a user refreshes the screen by scrolling up to pull new content
 */
export interface PulledToRefresh {
    /**
     * Where the refresh occurs
     */
    location?: string;
    [property: string]: any;
}

/**
 * When a user clicks or taps a reaction
 */
export interface ReactionClicked {
    /**
     * Positive = a user has added a reaction. Negative = a user has removed a reaction.
     */
    addedOrRemoved?: boolean;
    /**
     * The location (screen, path, url) where the reaction was clicked.
     */
    location?: string;
    /**
     * LIKE, INSIGHTFUL, CELEBRATE, CURIOUS, JOY, ROAR, MEH
     */
    reactionType: ReactionType;
    referenceId:  string;
    /**
     * POST, REPOST_WITH_THOUGHTS, COMMENT, REPLY
     */
    referenceType: ReactionClickedReferenceType;
    [property: string]: any;
}

/**
 * LIKE, INSIGHTFUL, CELEBRATE, CURIOUS, JOY, ROAR, MEH
 */
export type ReactionType = "CELEBRATE" | "CURIOUS" | "INSIGHTFUL" | "JOY" | "LIKE" | "MEH" | "ROAR";

/**
 * POST, REPOST_WITH_THOUGHTS, COMMENT, REPLY
 */
export type ReactionClickedReferenceType = "COMMENT" | "POST" | "REPLY" | "REPOST_WITH_THOUGHTS";

/**
 * When a user hovers or holds to open the list of reactions (note: reactions is only
 * available for feed posts and comments)
 */
export interface ReactionsOpened {
    /**
     * The location (screen, path, url) where the reaction was clicked.
     */
    location?:   string;
    referenceId: string;
    /**
     * POST, REPOST_WITH_THOUGHTS, INSTANT_REPOST, COMMENT, REPLY
     */
    referenceType: CommentReplyButtonClickedReferenceType;
    [property: string]: any;
}

/**
 * When a user clicks on the "repost" or "repost with your thoughts" button of any post. We
 * can differentiate which one with the property `buttonName` which can have the values
 * "REPOST_WITH_THOUGHTS" or "INSTANT_REPOST")
 */
export interface RepostClicked {
    /**
     * Repost With Thoughts, Repost
     */
    buttonName?: ButtonName;
    [property: string]: any;
}

/**
 * Repost With Thoughts, Repost
 */
export type ButtonName = "INSTANT_REPOST" | "REPOST_WITH_THOUGHTS";

/**
 * When a user reposts someone else's post
 */
export interface RepostCreated {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When a user reposts someone else's post with some text from their own as well
 */
export interface RepostWithYourThoughtsCreated {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When a user uses the search feature even if the input is blank
 */
export interface Search {
    /**
     * The search term user types. It can also be an empty text
     */
    keywords: string;
    /**
     * The tab the user is searching on
     */
    type: SearchType;
    [property: string]: any;
}

/**
 * The tab the user is searching on
 */
export type SearchType = "All" | "Experiences" | "People" | "Podcasts" | "PowerReads" | "Trails" | "Videos";

/**
 * When user clicks on share on content piece (not used for now but will be used in future)
 */
export interface ShareClicked {
    contentId?:   string;
    contentName?: string;
    experts?:     string;
    expertsId?:   string;
    [property: string]: any;
}

export interface ShareExternallyClicked {
    contentId?:   string;
    contentName?: string;
    expertId?:    string;
    expertIds?:   any[];
    [property: string]: any;
}

export interface ShareToFeedClicked {
    categories?:         any[];
    categoriesId?:       any[];
    contentDescription?: string;
    contentId?:          string;
    contentName?:        string;
    contentType?:        string;
    duration?:           number;
    experts?:            any[];
    expertsID?:          any[];
    [property: string]: any;
}

/**
 * When the user shares a content piece
 */
export interface SharedContent {
    categories?:         any[];
    categoriesIds?:      any[];
    contentDescription?: string;
    /**
     * The id of the content
     */
    contentId:        string;
    contentImageUrl?: string;
    /**
     * The name of the content
     */
    contentName: string;
    /**
     * The type of the content
     */
    contentType: string;
    /**
     * The duration of the content
     */
    duration?: number;
    /**
     * The name of the experts
     */
    experts?: any[];
    /**
     * The id of the experts
     */
    expertsIds?: any[];
    /**
     * remove?
     */
    isFreeContent?: boolean;
    /**
     * Page or path from where the user shared the content
     */
    location?:    string;
    sectionName?: string;
    [property: string]: any;
}

/**
 * When a user clicks sign out
 */
export interface SignOutClicked {
    /**
     * Where the sign out occurs
     */
    location?: string;
    [property: string]: any;
}

/**
 * When a user clicks a slider dot to move a slider
 */
export interface SliderClicked {
    /**
     * The screen or path that the slider is on.
     */
    location?: string;
    [property: string]: any;
}

/**
 * When a video in a story finishes
 */
export interface StoryCompleted {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When a video in a story starts
 */
export interface StoryStarted {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When a subscription is cancelled (this event received from stripe)
 */
export interface SubscriptionCancelled {
    /**
     * The type of user account i.e individual, teams, enterprise
     */
    accountType: string;
    /**
     * The currency of subscription plan taken
     */
    currency?: string;
    /**
     * The date when subscription ends/cancelled
     */
    date?: string;
    /**
     * The price of subscription plan taken
     */
    price?: number;
    [property: string]: any;
}

/**
 * When a user changes their subscription or when a subscription ends
 */
export interface SubscriptionChanged {
    /**
     * The type of user account i.e individual, teams, enterprise
     */
    accountType: string;
    /**
     * Currency of subscription plan taken
     */
    currency?: string;
    /**
     * The date when subscription changed
     */
    date?: string;
    /**
     * todo: remove?
     */
    previousPlan?: string;
    /**
     * The price of subscription plan taken
     */
    price?: number;
    /**
     * todo: remove?
     */
    promoCode?: string;
    /**
     * todo: remove?
     */
    usedPromo?: boolean;
    [property: string]: any;
}

/**
 * When any user starts a new subscription (includes individual B2C, enterprise and teams)
 */
export interface SubscriptionStarted {
    /**
     * The type of user account i.e individual, teams, enterprise
     */
    accountType: string;
    /**
     * Currency of subscription plan taken
     */
    currency?: string;
    /**
     * Start date of subscription
     */
    date?: string;
    /**
     * The price of subscription plan taken
     */
    price?: number;
    /**
     * todo: remove?
     */
    promoCode?: string;
    /**
     * todo: remove?
     */
    usedPromo?: boolean;
    [property: string]: any;
}

/**
 * When a user clicks the support email link
 */
export interface SupportEmailLinkClicked {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When a user clicks the help centre link
 */
export interface SupportHelpCentreLinkClicked {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * When a user clicks a tab in any component with tabs in the web or marketing website or
 * when a mobile user clicks any tab like the profile screen, content screen assignment
 * screen etc
 */
export interface TabClicked {
    /**
     * The location of the tab
     */
    location?: string;
    /**
     * The tab ID
     */
    tabId?: string;
    /**
     * Tabs including: Activity, Content & Expertise, Interests, General, Settings
     */
    tabName?: string;
    [property: string]: any;
}

/**
 * Fired when a clickable table row is clicked.
 */
export interface TableRowClicked {
    /**
     * The path or name of the screen/page where the clicked element is.
     */
    location: string;
    /**
     * ID of the row.
     */
    rowId?: string;
    /**
     * Name of the table containing the clicked row.
     */
    tableName: string;
    [property: string]: any;
}

/**
 * Fired when the header of a sortable column is clicked.
 */
export interface TableSortClicked {
    /**
     * ID of the sorted column.
     */
    columnId?: string;
    /**
     * Direction or sort order. If the value of this field is `null` that means the sort order
     * was restored to the initial state where the column is not sorted.
     */
    direction?: Direction | null;
    /**
     * Header label of the sorted column.
     */
    header?: string;
    [property: string]: any;
}

export type Direction = "asc" | "desc";

/**
 * When thinkfluencer first publish their public content. This helps us to enroll them into
 * a canvas in Braze
 */
export interface ThinkfluencerFirstContentPublished {
    /**
     * name of the published content
     */
    contentName: string;
    /**
     * url (content in marketing website) of the published content
     */
    contentUrl: string;
    [property: string]: any;
}

/**
 * When thinkfluencer has been in the app for one year. This helps us to enroll them into a
 * canvas in Braze
 */
export interface ThinkfluencerForOneYear {
    /**
     * This property is added as a workaround to generate type for events without properties
     */
    _?: null;
    [property: string]: any;
}

/**
 * Track the users time on each screen. When user navigates, it is triggered for the last
 * accessed screen with the time duration spent on the screen
 */
export interface TimeOnScreen {
    /**
     * The identifier for the current screen
     */
    identifier?: string;
    /**
     * The page or screenName
     */
    location?: string;
    /**
     * Duration of time spent (2 decimal spaces)
     */
    seconds?: number;
    /**
     * The identifier e.g. podcast ID for the target screen
     */
    targetIdentifier?: string;
    /**
     * The location of the next screen
     */
    targetLocation?: string;
    [property: string]: any;
}

/**
 * When the user clicks a toggle button. Eg when you update your preference for push and
 * email notifications available in Profile -> Settings Page.
 */
export interface ToggleClicked {
    /**
     * The name of the toggle button
     */
    toggleName?: string;
    /**
     * What the user answered
     */
    toggleSelection?: string;
    [property: string]: any;
}

/**
 * When a user clicks the button to switch orientation
 */
export interface ToggleOrientationClicked {
    /**
     * The content piece in which they changed the orientation
     */
    contentId: string;
    /**
     * The new orientation the user has changed to
     */
    newOrientation: string;
    [property: string]: any;
}

/**
 * When a user clicks on "topic" or "category" buttons in web app or marketing website (e.g.
 * topics in explore page, topics selection during onboarding, user's interests topics in
 * profile, the organization custom categories in the organization content page)
 */
export interface TopicButtonClicked {
    /**
     * Name of the topic
     */
    buttonName: string;
    /**
     * Page or path where the user clicked on the topic button
     */
    location: string;
    [property: string]: any;
}

/**
 * When the user changer their topic of interest from settings page
 */
export interface TopicToggleClicked {
    /**
     * The  name of the category
     */
    category?: string;
    /**
     * The  id of the category
     */
    categoryId?: string;
    /**
     * If the category is selected or unselected
     */
    isSelected?: boolean;
    /**
     * The name of the screen, page or path
     */
    location?: string;
    [property: string]: any;
}

export interface TrailCompletionInfo {
    trails: any[];
    [property: string]: any;
}

/**
 * Triggered in 2 ways. When a user finishes any content piece from anywhere in the app, it
 * will get checked in backend if finishing this content piece also finishes any trail this
 * content piece is part of and if yes it gets triggered.
 *
 * Secondly, when user is on a trail page itself and user finished the last unfinished
 * content piece in trail, frontend let's backend know that trail has been completed for
 * user and then this event gets triggered.
 */
export interface TrailFinished {
    /**
     * Id of the trail
     */
    trailId: string;
    /**
     * Name of the trail
     */
    trailName: string;
    [property: string]: any;
}

/**
 * When a particular user’s access level has been modified
 */
export interface UserAccessLevelChanged {
    /**
     * Shows the updated access levels for the user
     */
    updatedAccessLevels?: any[];
    [property: string]: any;
}

/**
 * When user clicks on "Submit" button while using "Sign up with Email". Includes checkbox
 * info that can be sent to Braze
 */
export interface UserCreated {
    company?:             string;
    email?:               string;
    firstName?:           string;
    lastName?:            string;
    optInForNewsletters?: boolean;
    sendMoreInfo?:        boolean;
    [property: string]: any;
}

/**
 * When a user mention is successfully sent
 */
export interface UserMentioned {
    /**
     * Is the mentioned user a Thinkfluencer.
     */
    isExpert: boolean;
    /**
     * The location (screen, path, url) where the reaction was clicked.
     */
    location?:          string;
    mentionedUserId?:   string;
    mentionedUserName?: string;
    referenceId?:       string;
    referenceType?:     CommentReplyButtonClickedReferenceType;
    [property: string]: any;
}

/**
 * When the webapp prompt to update to latest version is on the users screen
 */
export interface VersionUpdatePromptDisplayed {
    /**
     * The current version the user is on
     */
    currentVersion?: string;
    /**
     * The new version the user will update to if they click on the update button
     */
    newVersion?: string;
    [property: string]: any;
}

/**
 * When the user updates their webapp through the prompt displayed
 */
export interface VersionUpdated {
    /**
     * The current version the user is on
     */
    currentVersion?: string;
    /**
     * The new version the user has updated to by clicking on the update button
     */
    newVersion?: string;
    [property: string]: any;
}

/**
 * When the user clicks on the number of reactions in a post.
 */
export interface ViewReactionsClicked {
    referenceId?: string;
    [property: string]: any;
}

/**
 * When the user clicks the number of reposts in a post.
 */
export interface ViewRepostsClicked {
    referenceId?: string;
    [property: string]: any;
}

export interface WebVitals {
    /**
     * A unique ID representing this particular metric instance. Can be used to dedupe multiple
     * values sent for the same metric instance, or to group multiple deltas together and
     * calculate a total.
     */
    id: string;
    /**
     * Path where the event happened.
     */
    location?: string;
    /**
     * The name of the metric (in acronym form)
     */
    name: Name;
    /**
     * The delta between the current value and the last-reported value.
     */
    value: number;
    [property: string]: any;
}

/**
 * The name of the metric (in acronym form)
 */
export type Name = "CLS" | "FCP" | "FID" | "INP" | "LCP" | "TTFB";

/**
 * When a user clicks a like or dislike button on the marketing website.
 */
export interface WebsiteLikeClicked {
    /**
     * The page or path of the profile
     */
    href?: string;
    /**
     * Records the state of the answer (whether the user is liking or unliking)
     */
    state: WebsiteLikeClickedState;
    [property: string]: any;
}

/**
 * Records the state of the answer (whether the user is liking or unliking)
 */
export type WebsiteLikeClickedState = "Dislike" | "Like";


/**
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 * Make sure to also include the TypeScript declarations with: `npm install @segment/analytics-next` (install with --save-dev for type definitions only).
 *
 * If you don't want to use the snippet, you can also install the `@segment/analytics-next` library as a *production* dependency and use it like this: 
 * ```ts
 * import { AnalyticsBrowser } from '@segment/analytics-next' 
 * import { setTypewriterOptions } from './analytics'
 *
 * const analytics = AnalyticsBrowser.load({ writeKey: 'SEGMENT_WRITE_KEY' })
 * 
 * setTypewriterOptions({ analytics: analytics })
 */
import type { AnalyticsSnippet, Analytics, AnalyticsBrowser, Options } from '@segment/analytics-next'

declare global {
    interface Window {
        analytics: AnalyticsSnippet;
    }
}

type AnyAnalytics = AnalyticsSnippet | Analytics | AnalyticsBrowser

/** The callback exposed by analytics.js. */
export type Callback = () => void

export type ViolationHandler = (
    message: Record<string, any>,
    violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (message, violations) => {
    const msg = JSON.stringify(
        {
            type: 'Typewriter JSON Schema Validation Error',
            description:
                `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
                'Tracking Plan spec.',
            errors: violations,
        },
        undefined,
        2,
    );

    console.warn(msg);
};


let analytics: () => AnyAnalytics | undefined = () => {
    return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
    /**
     * Underlying analytics instance where analytics calls are forwarded on to.
     * Defaults to window.analytics.
     */
    analytics?: AnyAnalytics;
    /**
     * Handler fired when if an event does not match its spec. This handler
     * does not fire in production mode, because it requires inlining the full
     * JSON Schema spec for each event in your Tracking Plan.
     *
     * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
     * if a message does not match the spec. Otherwise, errors will be logged to stderr.
     */
    onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
    analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
}


/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Options = {}): Options {
    return {
        ...message,
        context: {
            ...(message.context || {}),
            typewriter: {
                language: 'typescript',
                version: '9.1.0',
            },
        },
    };
}

/**
 * Fires a 'PageViewed' track call.
 *
 * @param PageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pageViewed(props: PageViewed, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('Page Viewed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SegmentConsentPreferenceUpdated' track call.
 *
 * @param SegmentConsentPreferenceUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function segmentConsentPreferenceUpdated(props: SegmentConsentPreferenceUpdated, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('Segment Consent Preference Updated', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AccessLevelCreated' track call.
 *
 * @param AccessLevelCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accessLevelCreated(props: AccessLevelCreated, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('access_level_created', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AccessLevelDeleted' track call.
 *
 * @param AccessLevelDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accessLevelDeleted(props: AccessLevelDeleted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('access_level_deleted', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AccessLevelUpdated' track call.
 *
 * @param AccessLevelUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accessLevelUpdated(props: AccessLevelUpdated, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('access_level_updated', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AccountCreated' track call.
 *
 * @param AccountCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accountCreated(props: AccountCreated, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('account_created', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AccountDeleted' track call.
 *
 * @param AccountDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accountDeleted(props: AccountDeleted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('account_deleted', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AddNoteClicked' track call.
 *
 * @param AddNoteClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addNoteClicked(props: AddNoteClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('add_note_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AddToCalendar' track call.
 *
 * @param AddToCalendar props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addToCalendar(props: AddToCalendar, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('add_to_calendar', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AddToSchedule' track call.
 *
 * @param AddToSchedule props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addToSchedule(props: AddToSchedule, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('add_to_schedule', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AdminExportStatistics' track call.
 *
 * @param AdminExportStatistics props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function adminExportStatistics(props: AdminExportStatistics, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('admin_export_statistics', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AssignmentClicked' track call.
 *
 * @param AssignmentClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function assignmentClicked(props: AssignmentClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('assignment_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AssignmentCreated' track call.
 *
 * @param AssignmentCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function assignmentCreated(props: AssignmentCreated, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('assignment_created', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AssignmentDeleted' track call.
 *
 * @param AssignmentDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function assignmentDeleted(props: AssignmentDeleted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('assignment_deleted', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AssignmentFinished' track call.
 *
 * @param AssignmentFinished props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function assignmentFinished(props: AssignmentFinished, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('assignment_finished', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AssignmentMadeInactive' track call.
 *
 * @param AssignmentMadeInactive props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function assignmentMadeInactive(props: AssignmentMadeInactive, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('assignment_made_inactive', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AssignmentStarted' track call.
 *
 * @param AssignmentStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function assignmentStarted(props: AssignmentStarted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('assignment_started', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'AttemptedToConsumeContentOverFreemiumLimit' track call.
 *
 * @param AttemptedToConsumeContentOverFreemiumLimit props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function attemptedToConsumeContentOverFreemiumLimit(props: AttemptedToConsumeContentOverFreemiumLimit, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('attempted_to_consume_content_over_freemium_limit', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookASpotClicked' track call.
 *
 * @param BookASpotClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookASpotClicked(props: BookASpotClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('book_a_spot_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'BookmarkClicked' track call.
 *
 * @param BookmarkClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bookmarkClicked(props: BookmarkClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('bookmark_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ButtonClicked' track call.
 *
 * @param ButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function buttonClicked(props: ButtonClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('button_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CanceledButtonClicked' track call.
 *
 * @param CanceledButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function canceledButtonClicked(props: CanceledButtonClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('canceled_button_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CardSwiped' track call.
 *
 * @param CardSwiped props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cardSwiped(props: CardSwiped, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('card_swiped', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CarouselChanged' track call.
 *
 * @param CarouselChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function carouselChanged(props: CarouselChanged, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('carousel_changed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CarouselClicked' track call.
 *
 * @param CarouselClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function carouselClicked(props: CarouselClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('carousel_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ClearCacheClicked' track call.
 *
 * @param ClearCacheClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function clearCacheClicked(props: ClearCacheClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('clear_cache_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CommentButtonClicked' track call.
 *
 * @param CommentButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function commentButtonClicked(props: CommentButtonClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('comment_button_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CommentDeletedClicked' track call.
 *
 * @param CommentDeletedClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function commentDeletedClicked(props: CommentDeletedClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('comment_deleted_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CommentOptionsClicked' track call.
 *
 * @param CommentOptionsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function commentOptionsClicked(props: CommentOptionsClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('comment_options_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CommentReplyButtonClicked' track call.
 *
 * @param CommentReplyButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function commentReplyButtonClicked(props: CommentReplyButtonClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('comment_reply_button_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'CommentSent' track call.
 *
 * @param CommentSent props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function commentSent(props: CommentSent, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('comment_sent', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ConfirmationButtonClicked' track call.
 *
 * @param ConfirmationButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function confirmationButtonClicked(props: ConfirmationButtonClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('confirmation_button_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ContentAutoplayed' track call.
 *
 * @param ContentAutoplayed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentAutoplayed(props: ContentAutoplayed, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('content_autoplayed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ContentCardClicked' track call.
 *
 * @param ContentCardClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentCardClicked(props: ContentCardClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('content_card_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ContentConsumedAtInterval' track call.
 *
 * @param ContentConsumedAtInterval props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentConsumedAtInterval(props: ContentConsumedAtInterval, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('content_consumed_at_interval', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ContentFinished' track call.
 *
 * @param ContentFinished props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentFinished(props: ContentFinished, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('content_finished', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ContentPlaybackStateChanged' track call.
 *
 * @param ContentPlaybackStateChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentPlaybackStateChanged(props: ContentPlaybackStateChanged, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('content_playback_state_changed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ContentPositionSeek' track call.
 *
 * @param ContentPositionSeek props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentPositionSeek(props: ContentPositionSeek, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('content_position_seek', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ContentPreviewCompleted' track call.
 *
 * @param ContentPreviewCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentPreviewCompleted(props: ContentPreviewCompleted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('content_preview_completed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ContentSearched' track call.
 *
 * @param ContentSearched props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentSearched(props: ContentSearched, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('content_searched', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ContentStarted' track call.
 *
 * @param ContentStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentStarted(props: ContentStarted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('content_started', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'DeleteAccountClicked' track call.
 *
 * @param DeleteAccountClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function deleteAccountClicked(props: DeleteAccountClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('delete_account_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'DragDropClicked' track call.
 *
 * @param DragDropClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function dragDropClicked(props: DragDropClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('drag_drop_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'DropdownClicked' track call.
 *
 * @param DropdownClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function dropdownClicked(props: DropdownClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('dropdown_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'DropdownSelectionClicked' track call.
 *
 * @param DropdownSelectionClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function dropdownSelectionClicked(props: DropdownSelectionClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('dropdown_selection_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'EditEmailClicked' track call.
 *
 * @param EditEmailClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function editEmailClicked(props: EditEmailClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('edit_email_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'EmojiPickerOpened' track call.
 *
 * @param EmojiPickerOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function emojiPickerOpened(props: EmojiPickerOpened, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('emoji_picker_opened', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'EmojiPickerSelection' track call.
 *
 * @param EmojiPickerSelection props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function emojiPickerSelection(props: EmojiPickerSelection, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('emoji_picker_selection', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'EnterpriseOverlay' track call.
 *
 * @param EnterpriseOverlay props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function enterpriseOverlay(props: EnterpriseOverlay, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('enterprise_overlay', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'EventAttended' track call.
 *
 * @param EventAttended props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function eventAttended(props: EventAttended, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('event_attended', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'EventBooked' track call.
 *
 * @param EventBooked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function eventBooked(props: EventBooked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('event_booked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FeedPostClicked' track call.
 *
 * @param FeedPostClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function feedPostClicked(props: FeedPostClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('feed_post_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FeedPostImpression' track call.
 *
 * @param FeedPostImpression props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function feedPostImpression(props: FeedPostImpression, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('feed_post_impression', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FeedPostStarted' track call.
 *
 * @param FeedPostStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function feedPostStarted(props: FeedPostStarted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('feed_post_started', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FetchMore' track call.
 *
 * @param FetchMore props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function fetchMore(props: FetchMore, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('fetch_more', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FileUploadSelected' track call.
 *
 * @param FileUploadSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function fileUploadSelected(props: FileUploadSelected, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('file_upload_selected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FollowButtonClicked' track call.
 *
 * @param FollowButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function followButtonClicked(props: FollowButtonClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('follow_button_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FooterClicked' track call.
 *
 * @param FooterClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function footerClicked(props: FooterClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('footer_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FormFieldFilled' track call.
 *
 * @param FormFieldFilled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function formFieldFilled(props: FormFieldFilled, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('form_field_filled', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FormSubmitted' track call.
 *
 * @param FormSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function formSubmitted(props: FormSubmitted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('form_submitted', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FormViewed' track call.
 *
 * @param FormViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function formViewed(props: FormViewed, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('form_viewed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FreeContentCount' track call.
 *
 * @param FreeContentCount props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function freeContentCount(props: FreeContentCount, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('free_content_count', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'FreeContentLimitReached' track call.
 *
 * @param FreeContentLimitReached props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function freeContentLimitReached(props: FreeContentLimitReached, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('free_content_limit_reached', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'InvitedToJoinOrganisation' track call.
 *
 * @param InvitedToJoinOrganisation props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function invitedToJoinOrganisation(props: InvitedToJoinOrganisation, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('invited_to_join_organisation', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'LanguageChanged' track call.
 *
 * @param LanguageChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function languageChanged(props: LanguageChanged, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('language_changed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'LikeClicked' track call.
 *
 * @param LikeClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function likeClicked(props: LikeClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('like_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'LiveEventEndedScreen' track call.
 *
 * @param LiveEventEndedScreen props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function liveEventEndedScreen(props: LiveEventEndedScreen, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('live_event_ended_screen', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'LivestreamJoined' track call.
 *
 * @param LivestreamJoined props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function livestreamJoined(props: LivestreamJoined, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('livestream_joined', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'LivestreamMessageSent' track call.
 *
 * @param LivestreamMessageSent props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function livestreamMessageSent(props: LivestreamMessageSent, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('livestream_message_sent', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'Login' track call.
 *
 * @param Login props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function login(props: Login, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('login', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'Logout' track call.
 *
 * @param Logout props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function logout(props: Logout, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('logout', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingVideoCompleted' track call.
 *
 * @param MarketingVideoCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingVideoCompleted(props: MarketingVideoCompleted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('marketing_video_completed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MarketingVideoPlayed' track call.
 *
 * @param MarketingVideoPlayed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingVideoPlayed(props: MarketingVideoPlayed, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('marketing_video_played', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MentionClicked' track call.
 *
 * @param MentionClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function mentionClicked(props: MentionClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('mention_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MentionFinished' track call.
 *
 * @param MentionFinished props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function mentionFinished(props: MentionFinished, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('mention_finished', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MentionRemoved' track call.
 *
 * @param MentionRemoved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function mentionRemoved(props: MentionRemoved, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('mention_removed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'MentionStarted' track call.
 *
 * @param MentionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function mentionStarted(props: MentionStarted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('mention_started', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NavBarClicked' track call.
 *
 * @param NavBarClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function navBarClicked(props: NavBarClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('nav_bar_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NavButtonClicked' track call.
 *
 * @param NavButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function navButtonClicked(props: NavButtonClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('nav_button_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NewInvitationAccepted' track call.
 *
 * @param NewInvitationAccepted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function newInvitationAccepted(props: NewInvitationAccepted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('new_invitation_accepted', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NewNotificationsCount' track call.
 *
 * @param NewNotificationsCount props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function newNotificationsCount(props: NewNotificationsCount, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('new_notifications_count', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NewRegisteredB2CUser' track call.
 *
 * @param NewRegisteredB2CUser props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function newRegisteredB2CUser(props: NewRegisteredB2CUser, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('new_registered_b2c_user', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NewsletterSubscribed' track call.
 *
 * @param NewsletterSubscribed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function newsletterSubscribed(props: NewsletterSubscribed, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('newsletter_subscribed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NoteCancelClicked' track call.
 *
 * @param NoteCancelClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function noteCancelClicked(props: NoteCancelClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('note_cancel_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NoteCloseClicked' track call.
 *
 * @param NoteCloseClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function noteCloseClicked(props: NoteCloseClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('note_close_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NoteDeleteClicked' track call.
 *
 * @param NoteDeleteClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function noteDeleteClicked(props: NoteDeleteClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('note_delete_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NoteEditClicked' track call.
 *
 * @param NoteEditClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function noteEditClicked(props: NoteEditClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('note_edit_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NoteSavedClicked' track call.
 *
 * @param NoteSavedClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function noteSavedClicked(props: NoteSavedClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('note_saved_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NoteUpdated' track call.
 *
 * @param NoteUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function noteUpdated(props: NoteUpdated, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('note_updated', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NotesButtonClicked' track call.
 *
 * @param NotesButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function notesButtonClicked(props: NotesButtonClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('notes_button_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'NotificationClicked' track call.
 *
 * @param NotificationClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function notificationClicked(props: NotificationClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('notification_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'OnboardingCompleted' track call.
 *
 * @param OnboardingCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function onboardingCompleted(props: OnboardingCompleted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('onboarding_completed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'OrganisationContentClicked' track call.
 *
 * @param OrganisationContentClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function organisationContentClicked(props: OrganisationContentClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('organisation_content_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PollVoteClicked' track call.
 *
 * @param PollVoteClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pollVoteClicked(props: PollVoteClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('poll_vote_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PostAudienceClicked' track call.
 *
 * @param PostAudienceClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postAudienceClicked(props: PostAudienceClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('post_audience_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PostAudienceSelected' track call.
 *
 * @param PostAudienceSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postAudienceSelected(props: PostAudienceSelected, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('post_audience_selected', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PostCreated' track call.
 *
 * @param PostCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postCreated(props: PostCreated, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('post_created', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PostOptionsClicked' track call.
 *
 * @param PostOptionsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postOptionsClicked(props: PostOptionsClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('post_options_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ProductTourCompleted' track call.
 *
 * @param ProductTourCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productTourCompleted(props: ProductTourCompleted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('product_tour_completed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ProductTourStarted' track call.
 *
 * @param ProductTourStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productTourStarted(props: ProductTourStarted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('product_tour_started', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ProfileClicked' track call.
 *
 * @param ProfileClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function profileClicked(props: ProfileClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('profile_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ProfileUpdated' track call.
 *
 * @param ProfileUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function profileUpdated(props: ProfileUpdated, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('profile_updated', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PromotedToThinkfluencer' track call.
 *
 * @param PromotedToThinkfluencer props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function promotedToThinkfluencer(props: PromotedToThinkfluencer, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('promoted_to_thinkfluencer', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'PulledToRefresh' track call.
 *
 * @param PulledToRefresh props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pulledToRefresh(props: PulledToRefresh, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('pulled_to_refresh', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ReactionClicked' track call.
 *
 * @param ReactionClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function reactionClicked(props: ReactionClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('reaction_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ReactionsOpened' track call.
 *
 * @param ReactionsOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function reactionsOpened(props: ReactionsOpened, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('reactions_opened', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'RepostClicked' track call.
 *
 * @param RepostClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function repostClicked(props: RepostClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('repost_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'RepostCreated' track call.
 *
 * @param RepostCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function repostCreated(props: RepostCreated, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('repost_created', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'RepostWithYourThoughtsCreated' track call.
 *
 * @param RepostWithYourThoughtsCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function repostWithYourThoughtsCreated(props: RepostWithYourThoughtsCreated, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('repost_with_your_thoughts_created', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'Search' track call.
 *
 * @param Search props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function search(props: Search, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('search', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ShareClicked' track call.
 *
 * @param ShareClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function shareClicked(props: ShareClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('share_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ShareExternallyClicked' track call.
 *
 * @param ShareExternallyClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function shareExternallyClicked(props: ShareExternallyClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('share_externally_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ShareToFeedClicked' track call.
 *
 * @param ShareToFeedClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function shareToFeedClicked(props: ShareToFeedClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('share_to_feed_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SharedContent' track call.
 *
 * @param SharedContent props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sharedContent(props: SharedContent, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('shared_content', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SignOutClicked' track call.
 *
 * @param SignOutClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function signOutClicked(props: SignOutClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('sign_out_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SliderClicked' track call.
 *
 * @param SliderClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sliderClicked(props: SliderClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('slider_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'StoryCompleted' track call.
 *
 * @param StoryCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storyCompleted(props: StoryCompleted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('story_completed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'StoryStarted' track call.
 *
 * @param StoryStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storyStarted(props: StoryStarted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('story_started', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SubscriptionCancelled' track call.
 *
 * @param SubscriptionCancelled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subscriptionCancelled(props: SubscriptionCancelled, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('subscription_cancelled', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SubscriptionChanged' track call.
 *
 * @param SubscriptionChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subscriptionChanged(props: SubscriptionChanged, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('subscription_changed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SubscriptionStarted' track call.
 *
 * @param SubscriptionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subscriptionStarted(props: SubscriptionStarted, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('subscription_started', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SupportEmailLinkClicked' track call.
 *
 * @param SupportEmailLinkClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function supportEmailLinkClicked(props: SupportEmailLinkClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('support_email_link_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'SupportHelpCentreLinkClicked' track call.
 *
 * @param SupportHelpCentreLinkClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function supportHelpCentreLinkClicked(props: SupportHelpCentreLinkClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('support_help_centre_link_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'TabClicked' track call.
 *
 * @param TabClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tabClicked(props: TabClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('tab_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'TableRowClicked' track call.
 *
 * @param TableRowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tableRowClicked(props: TableRowClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('table_row_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'TableSortClicked' track call.
 *
 * @param TableSortClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tableSortClicked(props: TableSortClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('table_sort_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ThinkfluencerFirstContentPublished' track call.
 *
 * @param ThinkfluencerFirstContentPublished props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function thinkfluencerFirstContentPublished(props: ThinkfluencerFirstContentPublished, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('thinkfluencer_first_content_published', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ThinkfluencerForOneYear' track call.
 *
 * @param ThinkfluencerForOneYear props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function thinkfluencerForOneYear(props: ThinkfluencerForOneYear, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('thinkfluencer_for_one_year', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'TimeOnScreen' track call.
 *
 * @param TimeOnScreen props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function timeOnScreen(props: TimeOnScreen, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('time_on_screen', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ToggleClicked' track call.
 *
 * @param ToggleClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function toggleClicked(props: ToggleClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('toggle_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ToggleOrientationClicked' track call.
 *
 * @param ToggleOrientationClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function toggleOrientationClicked(props: ToggleOrientationClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('toggle_orientation_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'TopicButtonClicked' track call.
 *
 * @param TopicButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function topicButtonClicked(props: TopicButtonClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('topic_button_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'TopicToggleClicked' track call.
 *
 * @param TopicToggleClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function topicToggleClicked(props: TopicToggleClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('topic_toggle_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'TrailCompletionInfo' track call.
 *
 * @param TrailCompletionInfo props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function trailCompletionInfo(props: TrailCompletionInfo, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('trail_completion_info', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'TrailFinished' track call.
 *
 * @param TrailFinished props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function trailFinished(props: TrailFinished, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('trail_finished', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'UserAccessLevelChanged' track call.
 *
 * @param UserAccessLevelChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function userAccessLevelChanged(props: UserAccessLevelChanged, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('user_access_level_changed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'UserCreated' track call.
 *
 * @param UserCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function userCreated(props: UserCreated, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('user_created', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'UserMentioned' track call.
 *
 * @param UserMentioned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function userMentioned(props: UserMentioned, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('user_mentioned', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'VersionUpdatePromptDisplayed' track call.
 *
 * @param VersionUpdatePromptDisplayed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function versionUpdatePromptDisplayed(props: VersionUpdatePromptDisplayed, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('version_update_prompt_displayed', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'VersionUpdated' track call.
 *
 * @param VersionUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function versionUpdated(props: VersionUpdated, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('version_updated', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ViewReactionsClicked' track call.
 *
 * @param ViewReactionsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function viewReactionsClicked(props: ViewReactionsClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('view_reactions_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'ViewRepostsClicked' track call.
 *
 * @param ViewRepostsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function viewRepostsClicked(props: ViewRepostsClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('view_reposts_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'WebVitals' track call.
 *
 * @param WebVitals props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function webVitals(props: WebVitals, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('web_vitals', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}
/**
 * Fires a 'WebsiteLikeClicked' track call.
 *
 * @param WebsiteLikeClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function websiteLikeClicked(props: WebsiteLikeClicked, options?: Options, callback?: Callback): void {


    const a = analytics();
    if (a) {
        a.track('website_like_clicked', props || {}, {...options,   context: {
            ...(options?.context || {}),
            typewriter: {
                language: 'typescript',
                version: '',
            },
        },}, callback);
    }
}

const clientAPI = {
    /**
     * Updates the run-time configuration of this Typewriter client.
     *
     * @param {TypewriterOptions} options - the options to upsert
     *
     * @typedef {Object} TypewriterOptions
     * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
     * 		calls are forwarded on to. Defaults to window.analytics.
     * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
     * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
     * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
     * 		will be logged to stderr.
     */
    setTypewriterOptions,

    /**
     * Fires a 'PageViewed' track call.
     *
     * @param PageViewed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    pageViewed,
    /**
     * Fires a 'SegmentConsentPreferenceUpdated' track call.
     *
     * @param SegmentConsentPreferenceUpdated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    segmentConsentPreferenceUpdated,
    /**
     * Fires a 'AccessLevelCreated' track call.
     *
     * @param AccessLevelCreated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    accessLevelCreated,
    /**
     * Fires a 'AccessLevelDeleted' track call.
     *
     * @param AccessLevelDeleted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    accessLevelDeleted,
    /**
     * Fires a 'AccessLevelUpdated' track call.
     *
     * @param AccessLevelUpdated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    accessLevelUpdated,
    /**
     * Fires a 'AccountCreated' track call.
     *
     * @param AccountCreated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    accountCreated,
    /**
     * Fires a 'AccountDeleted' track call.
     *
     * @param AccountDeleted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    accountDeleted,
    /**
     * Fires a 'AddNoteClicked' track call.
     *
     * @param AddNoteClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    addNoteClicked,
    /**
     * Fires a 'AddToCalendar' track call.
     *
     * @param AddToCalendar props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    addToCalendar,
    /**
     * Fires a 'AddToSchedule' track call.
     *
     * @param AddToSchedule props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    addToSchedule,
    /**
     * Fires a 'AdminExportStatistics' track call.
     *
     * @param AdminExportStatistics props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    adminExportStatistics,
    /**
     * Fires a 'AssignmentClicked' track call.
     *
     * @param AssignmentClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    assignmentClicked,
    /**
     * Fires a 'AssignmentCreated' track call.
     *
     * @param AssignmentCreated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    assignmentCreated,
    /**
     * Fires a 'AssignmentDeleted' track call.
     *
     * @param AssignmentDeleted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    assignmentDeleted,
    /**
     * Fires a 'AssignmentFinished' track call.
     *
     * @param AssignmentFinished props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    assignmentFinished,
    /**
     * Fires a 'AssignmentMadeInactive' track call.
     *
     * @param AssignmentMadeInactive props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    assignmentMadeInactive,
    /**
     * Fires a 'AssignmentStarted' track call.
     *
     * @param AssignmentStarted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    assignmentStarted,
    /**
     * Fires a 'AttemptedToConsumeContentOverFreemiumLimit' track call.
     *
     * @param AttemptedToConsumeContentOverFreemiumLimit props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    attemptedToConsumeContentOverFreemiumLimit,
    /**
     * Fires a 'BookASpotClicked' track call.
     *
     * @param BookASpotClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookASpotClicked,
    /**
     * Fires a 'BookmarkClicked' track call.
     *
     * @param BookmarkClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    bookmarkClicked,
    /**
     * Fires a 'ButtonClicked' track call.
     *
     * @param ButtonClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    buttonClicked,
    /**
     * Fires a 'CanceledButtonClicked' track call.
     *
     * @param CanceledButtonClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    canceledButtonClicked,
    /**
     * Fires a 'CardSwiped' track call.
     *
     * @param CardSwiped props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    cardSwiped,
    /**
     * Fires a 'CarouselChanged' track call.
     *
     * @param CarouselChanged props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    carouselChanged,
    /**
     * Fires a 'CarouselClicked' track call.
     *
     * @param CarouselClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    carouselClicked,
    /**
     * Fires a 'ClearCacheClicked' track call.
     *
     * @param ClearCacheClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    clearCacheClicked,
    /**
     * Fires a 'CommentButtonClicked' track call.
     *
     * @param CommentButtonClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    commentButtonClicked,
    /**
     * Fires a 'CommentDeletedClicked' track call.
     *
     * @param CommentDeletedClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    commentDeletedClicked,
    /**
     * Fires a 'CommentOptionsClicked' track call.
     *
     * @param CommentOptionsClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    commentOptionsClicked,
    /**
     * Fires a 'CommentReplyButtonClicked' track call.
     *
     * @param CommentReplyButtonClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    commentReplyButtonClicked,
    /**
     * Fires a 'CommentSent' track call.
     *
     * @param CommentSent props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    commentSent,
    /**
     * Fires a 'ConfirmationButtonClicked' track call.
     *
     * @param ConfirmationButtonClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    confirmationButtonClicked,
    /**
     * Fires a 'ContentAutoplayed' track call.
     *
     * @param ContentAutoplayed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    contentAutoplayed,
    /**
     * Fires a 'ContentCardClicked' track call.
     *
     * @param ContentCardClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    contentCardClicked,
    /**
     * Fires a 'ContentConsumedAtInterval' track call.
     *
     * @param ContentConsumedAtInterval props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    contentConsumedAtInterval,
    /**
     * Fires a 'ContentFinished' track call.
     *
     * @param ContentFinished props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    contentFinished,
    /**
     * Fires a 'ContentPlaybackStateChanged' track call.
     *
     * @param ContentPlaybackStateChanged props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    contentPlaybackStateChanged,
    /**
     * Fires a 'ContentPositionSeek' track call.
     *
     * @param ContentPositionSeek props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    contentPositionSeek,
    /**
     * Fires a 'ContentPreviewCompleted' track call.
     *
     * @param ContentPreviewCompleted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    contentPreviewCompleted,
    /**
     * Fires a 'ContentSearched' track call.
     *
     * @param ContentSearched props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    contentSearched,
    /**
     * Fires a 'ContentStarted' track call.
     *
     * @param ContentStarted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    contentStarted,
    /**
     * Fires a 'DeleteAccountClicked' track call.
     *
     * @param DeleteAccountClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    deleteAccountClicked,
    /**
     * Fires a 'DragDropClicked' track call.
     *
     * @param DragDropClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    dragDropClicked,
    /**
     * Fires a 'DropdownClicked' track call.
     *
     * @param DropdownClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    dropdownClicked,
    /**
     * Fires a 'DropdownSelectionClicked' track call.
     *
     * @param DropdownSelectionClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    dropdownSelectionClicked,
    /**
     * Fires a 'EditEmailClicked' track call.
     *
     * @param EditEmailClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    editEmailClicked,
    /**
     * Fires a 'EmojiPickerOpened' track call.
     *
     * @param EmojiPickerOpened props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    emojiPickerOpened,
    /**
     * Fires a 'EmojiPickerSelection' track call.
     *
     * @param EmojiPickerSelection props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    emojiPickerSelection,
    /**
     * Fires a 'EnterpriseOverlay' track call.
     *
     * @param EnterpriseOverlay props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    enterpriseOverlay,
    /**
     * Fires a 'EventAttended' track call.
     *
     * @param EventAttended props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    eventAttended,
    /**
     * Fires a 'EventBooked' track call.
     *
     * @param EventBooked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    eventBooked,
    /**
     * Fires a 'FeedPostClicked' track call.
     *
     * @param FeedPostClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    feedPostClicked,
    /**
     * Fires a 'FeedPostImpression' track call.
     *
     * @param FeedPostImpression props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    feedPostImpression,
    /**
     * Fires a 'FeedPostStarted' track call.
     *
     * @param FeedPostStarted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    feedPostStarted,
    /**
     * Fires a 'FetchMore' track call.
     *
     * @param FetchMore props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    fetchMore,
    /**
     * Fires a 'FileUploadSelected' track call.
     *
     * @param FileUploadSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    fileUploadSelected,
    /**
     * Fires a 'FollowButtonClicked' track call.
     *
     * @param FollowButtonClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    followButtonClicked,
    /**
     * Fires a 'FooterClicked' track call.
     *
     * @param FooterClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    footerClicked,
    /**
     * Fires a 'FormFieldFilled' track call.
     *
     * @param FormFieldFilled props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    formFieldFilled,
    /**
     * Fires a 'FormSubmitted' track call.
     *
     * @param FormSubmitted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    formSubmitted,
    /**
     * Fires a 'FormViewed' track call.
     *
     * @param FormViewed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    formViewed,
    /**
     * Fires a 'FreeContentCount' track call.
     *
     * @param FreeContentCount props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    freeContentCount,
    /**
     * Fires a 'FreeContentLimitReached' track call.
     *
     * @param FreeContentLimitReached props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    freeContentLimitReached,
    /**
     * Fires a 'InvitedToJoinOrganisation' track call.
     *
     * @param InvitedToJoinOrganisation props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    invitedToJoinOrganisation,
    /**
     * Fires a 'LanguageChanged' track call.
     *
     * @param LanguageChanged props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    languageChanged,
    /**
     * Fires a 'LikeClicked' track call.
     *
     * @param LikeClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    likeClicked,
    /**
     * Fires a 'LiveEventEndedScreen' track call.
     *
     * @param LiveEventEndedScreen props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    liveEventEndedScreen,
    /**
     * Fires a 'LivestreamJoined' track call.
     *
     * @param LivestreamJoined props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    livestreamJoined,
    /**
     * Fires a 'LivestreamMessageSent' track call.
     *
     * @param LivestreamMessageSent props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    livestreamMessageSent,
    /**
     * Fires a 'Login' track call.
     *
     * @param Login props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    login,
    /**
     * Fires a 'Logout' track call.
     *
     * @param Logout props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    logout,
    /**
     * Fires a 'MarketingVideoCompleted' track call.
     *
     * @param MarketingVideoCompleted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingVideoCompleted,
    /**
     * Fires a 'MarketingVideoPlayed' track call.
     *
     * @param MarketingVideoPlayed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    marketingVideoPlayed,
    /**
     * Fires a 'MentionClicked' track call.
     *
     * @param MentionClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    mentionClicked,
    /**
     * Fires a 'MentionFinished' track call.
     *
     * @param MentionFinished props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    mentionFinished,
    /**
     * Fires a 'MentionRemoved' track call.
     *
     * @param MentionRemoved props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    mentionRemoved,
    /**
     * Fires a 'MentionStarted' track call.
     *
     * @param MentionStarted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    mentionStarted,
    /**
     * Fires a 'NavBarClicked' track call.
     *
     * @param NavBarClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    navBarClicked,
    /**
     * Fires a 'NavButtonClicked' track call.
     *
     * @param NavButtonClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    navButtonClicked,
    /**
     * Fires a 'NewInvitationAccepted' track call.
     *
     * @param NewInvitationAccepted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    newInvitationAccepted,
    /**
     * Fires a 'NewNotificationsCount' track call.
     *
     * @param NewNotificationsCount props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    newNotificationsCount,
    /**
     * Fires a 'NewRegisteredB2CUser' track call.
     *
     * @param NewRegisteredB2CUser props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    newRegisteredB2CUser,
    /**
     * Fires a 'NewsletterSubscribed' track call.
     *
     * @param NewsletterSubscribed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    newsletterSubscribed,
    /**
     * Fires a 'NoteCancelClicked' track call.
     *
     * @param NoteCancelClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    noteCancelClicked,
    /**
     * Fires a 'NoteCloseClicked' track call.
     *
     * @param NoteCloseClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    noteCloseClicked,
    /**
     * Fires a 'NoteDeleteClicked' track call.
     *
     * @param NoteDeleteClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    noteDeleteClicked,
    /**
     * Fires a 'NoteEditClicked' track call.
     *
     * @param NoteEditClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    noteEditClicked,
    /**
     * Fires a 'NoteSavedClicked' track call.
     *
     * @param NoteSavedClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    noteSavedClicked,
    /**
     * Fires a 'NoteUpdated' track call.
     *
     * @param NoteUpdated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    noteUpdated,
    /**
     * Fires a 'NotesButtonClicked' track call.
     *
     * @param NotesButtonClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    notesButtonClicked,
    /**
     * Fires a 'NotificationClicked' track call.
     *
     * @param NotificationClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    notificationClicked,
    /**
     * Fires a 'OnboardingCompleted' track call.
     *
     * @param OnboardingCompleted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    onboardingCompleted,
    /**
     * Fires a 'OrganisationContentClicked' track call.
     *
     * @param OrganisationContentClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    organisationContentClicked,
    /**
     * Fires a 'PollVoteClicked' track call.
     *
     * @param PollVoteClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    pollVoteClicked,
    /**
     * Fires a 'PostAudienceClicked' track call.
     *
     * @param PostAudienceClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    postAudienceClicked,
    /**
     * Fires a 'PostAudienceSelected' track call.
     *
     * @param PostAudienceSelected props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    postAudienceSelected,
    /**
     * Fires a 'PostCreated' track call.
     *
     * @param PostCreated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    postCreated,
    /**
     * Fires a 'PostOptionsClicked' track call.
     *
     * @param PostOptionsClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    postOptionsClicked,
    /**
     * Fires a 'ProductTourCompleted' track call.
     *
     * @param ProductTourCompleted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    productTourCompleted,
    /**
     * Fires a 'ProductTourStarted' track call.
     *
     * @param ProductTourStarted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    productTourStarted,
    /**
     * Fires a 'ProfileClicked' track call.
     *
     * @param ProfileClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    profileClicked,
    /**
     * Fires a 'ProfileUpdated' track call.
     *
     * @param ProfileUpdated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    profileUpdated,
    /**
     * Fires a 'PromotedToThinkfluencer' track call.
     *
     * @param PromotedToThinkfluencer props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    promotedToThinkfluencer,
    /**
     * Fires a 'PulledToRefresh' track call.
     *
     * @param PulledToRefresh props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    pulledToRefresh,
    /**
     * Fires a 'ReactionClicked' track call.
     *
     * @param ReactionClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    reactionClicked,
    /**
     * Fires a 'ReactionsOpened' track call.
     *
     * @param ReactionsOpened props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    reactionsOpened,
    /**
     * Fires a 'RepostClicked' track call.
     *
     * @param RepostClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    repostClicked,
    /**
     * Fires a 'RepostCreated' track call.
     *
     * @param RepostCreated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    repostCreated,
    /**
     * Fires a 'RepostWithYourThoughtsCreated' track call.
     *
     * @param RepostWithYourThoughtsCreated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    repostWithYourThoughtsCreated,
    /**
     * Fires a 'Search' track call.
     *
     * @param Search props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    search,
    /**
     * Fires a 'ShareClicked' track call.
     *
     * @param ShareClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    shareClicked,
    /**
     * Fires a 'ShareExternallyClicked' track call.
     *
     * @param ShareExternallyClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    shareExternallyClicked,
    /**
     * Fires a 'ShareToFeedClicked' track call.
     *
     * @param ShareToFeedClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    shareToFeedClicked,
    /**
     * Fires a 'SharedContent' track call.
     *
     * @param SharedContent props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    sharedContent,
    /**
     * Fires a 'SignOutClicked' track call.
     *
     * @param SignOutClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    signOutClicked,
    /**
     * Fires a 'SliderClicked' track call.
     *
     * @param SliderClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    sliderClicked,
    /**
     * Fires a 'StoryCompleted' track call.
     *
     * @param StoryCompleted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    storyCompleted,
    /**
     * Fires a 'StoryStarted' track call.
     *
     * @param StoryStarted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    storyStarted,
    /**
     * Fires a 'SubscriptionCancelled' track call.
     *
     * @param SubscriptionCancelled props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    subscriptionCancelled,
    /**
     * Fires a 'SubscriptionChanged' track call.
     *
     * @param SubscriptionChanged props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    subscriptionChanged,
    /**
     * Fires a 'SubscriptionStarted' track call.
     *
     * @param SubscriptionStarted props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    subscriptionStarted,
    /**
     * Fires a 'SupportEmailLinkClicked' track call.
     *
     * @param SupportEmailLinkClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    supportEmailLinkClicked,
    /**
     * Fires a 'SupportHelpCentreLinkClicked' track call.
     *
     * @param SupportHelpCentreLinkClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    supportHelpCentreLinkClicked,
    /**
     * Fires a 'TabClicked' track call.
     *
     * @param TabClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    tabClicked,
    /**
     * Fires a 'TableRowClicked' track call.
     *
     * @param TableRowClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    tableRowClicked,
    /**
     * Fires a 'TableSortClicked' track call.
     *
     * @param TableSortClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    tableSortClicked,
    /**
     * Fires a 'ThinkfluencerFirstContentPublished' track call.
     *
     * @param ThinkfluencerFirstContentPublished props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    thinkfluencerFirstContentPublished,
    /**
     * Fires a 'ThinkfluencerForOneYear' track call.
     *
     * @param ThinkfluencerForOneYear props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    thinkfluencerForOneYear,
    /**
     * Fires a 'TimeOnScreen' track call.
     *
     * @param TimeOnScreen props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    timeOnScreen,
    /**
     * Fires a 'ToggleClicked' track call.
     *
     * @param ToggleClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    toggleClicked,
    /**
     * Fires a 'ToggleOrientationClicked' track call.
     *
     * @param ToggleOrientationClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    toggleOrientationClicked,
    /**
     * Fires a 'TopicButtonClicked' track call.
     *
     * @param TopicButtonClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    topicButtonClicked,
    /**
     * Fires a 'TopicToggleClicked' track call.
     *
     * @param TopicToggleClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    topicToggleClicked,
    /**
     * Fires a 'TrailCompletionInfo' track call.
     *
     * @param TrailCompletionInfo props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    trailCompletionInfo,
    /**
     * Fires a 'TrailFinished' track call.
     *
     * @param TrailFinished props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    trailFinished,
    /**
     * Fires a 'UserAccessLevelChanged' track call.
     *
     * @param UserAccessLevelChanged props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    userAccessLevelChanged,
    /**
     * Fires a 'UserCreated' track call.
     *
     * @param UserCreated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    userCreated,
    /**
     * Fires a 'UserMentioned' track call.
     *
     * @param UserMentioned props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    userMentioned,
    /**
     * Fires a 'VersionUpdatePromptDisplayed' track call.
     *
     * @param VersionUpdatePromptDisplayed props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    versionUpdatePromptDisplayed,
    /**
     * Fires a 'VersionUpdated' track call.
     *
     * @param VersionUpdated props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    versionUpdated,
    /**
     * Fires a 'ViewReactionsClicked' track call.
     *
     * @param ViewReactionsClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    viewReactionsClicked,
    /**
     * Fires a 'ViewRepostsClicked' track call.
     *
     * @param ViewRepostsClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    viewRepostsClicked,
    /**
     * Fires a 'WebVitals' track call.
     *
     * @param WebVitals props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    webVitals,
    /**
     * Fires a 'WebsiteLikeClicked' track call.
     *
     * @param WebsiteLikeClicked props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 	call is fired.
     */
    websiteLikeClicked,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
    get(target, method) {
        if (typeof method === 'string' && target.hasOwnProperty(method)) {
            return target[method as keyof typeof clientAPI];
        }

        return () => {
            console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`);
            const a = analytics();
            if (a) {
                a.track(
                    'Unknown Analytics Call Fired',
                    {
                        method,
                    },
                    withTypewriterContext(),
                );
            }
        };
    },
});

