import * as React from 'react';
import { Box, HStack, Icon, Text } from '@chakra-ui/react';
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconWorldWww
} from '@tigerhall/icons';
import { type User } from '@tigerhall/core/lib/types';
import { TrackedLink } from '@tigerhall/components';

type ProfileSocialLinksProps = {
  userSocialLinks: {
    facebookLink?: User['facebookLink'];
    twitterHandle?: User['twitterHandle'];
    instagramHandle?: User['instagramHandle'];
    linkedinLink?: User['linkedinLink'];
    websiteLink?: User['websiteLink'];
  };
};

type PossibleSocialConnections =
  keyof ProfileSocialLinksProps['userSocialLinks'];

const socialConnections: readonly PossibleSocialConnections[] = [
  'facebookLink',
  'twitterHandle',
  'instagramHandle',
  'linkedinLink',
  'websiteLink'
] as const;

const getSocialIcon = (connection: PossibleSocialConnections) => {
  switch (connection) {
    case 'twitterHandle':
      return IconBrandTwitter;
    case 'facebookLink':
      return IconBrandFacebook;
    case 'instagramHandle':
      return IconBrandInstagram;
    case 'linkedinLink':
      return IconBrandLinkedin;
    case 'websiteLink':
      return IconWorldWww;
    default:
      return IconWorldWww;
  }
};

function getSocialMediaRedirectUrl(
  connection: PossibleSocialConnections,
  value: string
) {
  switch (connection) {
    case 'twitterHandle':
      return `https://twitter.com/${value}`;
    case 'instagramHandle':
      return `https://instagram.com/${value}`;
    case 'facebookLink':
    case 'linkedinLink':
    case 'websiteLink': {
      if (!value.startsWith('https://')) {
        return `https://${value}`;
      }
      return `${value}`;
    }
    default:
      return '';
  }
}

export function ProfileSocialLinks({
  userSocialLinks
}: ProfileSocialLinksProps) {
  const {
    facebookLink,
    twitterHandle,
    instagramHandle,
    linkedinLink,
    websiteLink
  } = userSocialLinks || {};
  const socialLinks = React.useMemo(
    () => ({
      facebookLink,
      twitterHandle,
      instagramHandle,
      linkedinLink,
      websiteLink
    }),
    [facebookLink, instagramHandle, linkedinLink, twitterHandle, websiteLink]
  );

  return (
    <Box as="section">
      <Text as="h2" fontSize="2xl" color="lightGrey.200" fontWeight="700">
        On the web
      </Text>
      <HStack mt="0.5rem">
        {socialConnections?.map((connection) => {
          const socialLink = socialLinks?.[connection];

          if (!socialLink) {
            return null;
          }

          return (
            <TrackedLink
              key={connection}
              name="PROFILE_SOCIAL_LINK"
              href={getSocialMediaRedirectUrl(connection, socialLink)}
              as="button"
              variant="solidDark"
              width="3rem"
              height="3rem"
              display="flex"
              justifyContent="center"
            >
              <Icon
                as={getSocialIcon(connection)}
                color="white"
                height="2rem"
                width="2rem"
              />
            </TrackedLink>
          );
        })}
      </HStack>
    </Box>
  );
}
