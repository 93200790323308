import * as React from 'react';
import { Button, Flex, Icon } from '@chakra-ui/react';
import { Content, ContentType, StreamFilter } from '@tigerhall/core/lib/types';
import { TrackedLink } from '@tigerhall/components';
import { IconEdit, IconPlus, IconTrash } from '@tigerhall/icons';
import { InfiniteSingleTypeCardGrid, PageBanner } from 'components/ui';
import { useDeleteStreamMutation, useGetContentByIdQuery } from 'generated';
import withAction from 'hoc/withAction';
import { useNavigate } from 'react-router-dom';
import { getOrgIdFromToken } from 'app/state';
import { useAppSelector } from 'hooks';
import { ContentSummaryModal } from 'modules/admin/components';
import {
  GenericHistoryProps,
  GenericSearchForm
} from 'modules/admin/components/GenericSearchForm';

import { videoFormRoute } from '../../utils/getVideoRoutes';

function VideoHistory({ setActionConfig }: Readonly<GenericHistoryProps>) {
  const orgId = useAppSelector(getOrgIdFromToken);
  const navigate = useNavigate();

  const [selectedVideo, setSelectedVideo] = React.useState<string>();

  const { data } = useGetContentByIdQuery({
    skip: !selectedVideo,
    variables: {
      id: selectedVideo as string
    }
  });

  const [filter, setFilter] = React.useState<StreamFilter>({
    organisationID: [orgId as string],
    limit: 10,
    offset: 0
  });

  const getActionConfig = (card, refetch) => ({
    modalConfig: {
      title: 'Delete Video',
      body: 'Are you sure you want to delete this video?',
      primaryActionText: 'Delete',
      postAction: () => {
        refetch?.();
      }
    },
    mutationConfig: {
      variables: {
        id: card?.id
      }
    },
    toastConfig: {
      successDescription: 'Video Deleted successfully.'
    }
  });

  return (
    <>
      <PageBanner
        description="Create a custom video that best fits your team's unique needs. Fill out some details about the video and hit publish! You can later assign these podcasts to your team members using the Manage Assignments tab to the left."
        title="Videos"
        action={
          <TrackedLink name="UPLOAD_VIDEO" href={videoFormRoute('draft')}>
            <Button leftIcon={<IconPlus color="tigerOrange.600" />} size="lg">
              Upload your video
            </Button>
          </TrackedLink>
        }
      />
      <Flex mt="2rem" direction="column" gap="1rem">
        <GenericSearchForm
          setFilter={setFilter}
          heading="Videos History"
          formName="searchVideoHistory"
        />
        <InfiniteSingleTypeCardGrid
          type={ContentType.Stream}
          filter={filter}
          overlayActions={(card, refetch) => [
            {
              label: 'Learn More',
              variant: 'outline',
              onClick: () => {
                setSelectedVideo(card.id);
              }
            },
            {
              label: 'Edit',
              onClick: () => {
                navigate(videoFormRoute(card.id));
              },
              leftIcon: <Icon as={IconEdit} />
            },
            {
              label: 'Delete',
              onClick: () => {
                setActionConfig(getActionConfig(card, refetch));
              },
              leftIcon: <Icon as={IconTrash} />
            }
          ]}
        />
      </Flex>
      <ContentSummaryModal
        isOpen={!!data?.contentCard}
        onClose={() => setSelectedVideo(undefined)}
        content={data?.contentCard as Content}
        actions={
          <>
            <Button
              flex={1}
              variant="outline"
              leftIcon={<Icon as={IconEdit} />}
              onClick={() => {
                navigate(videoFormRoute(selectedVideo as string));
                setSelectedVideo(undefined);
              }}
            >
              Edit
            </Button>
          </>
        }
      />
    </>
  );
}

export default withAction({ actionMutation: useDeleteStreamMutation })(
  VideoHistory
);
