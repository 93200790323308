import * as React from 'react';
import { FormSelect } from '@tigerhall/components';
import { ContentType } from '@tigerhall/core';

type ContentTypeSelectProps = {
  name: string;
  isMulti?: boolean;
  isClearable?: boolean;
  required?: boolean;
};

export type ContentTypeSelectOption = {
  label: string;
  value: ContentType[];
};

export const DEFAULT_CONTENT_TYPE_SELECT_OPTIONS: ContentTypeSelectOption[] = [
  {
    label: 'All content',
    value: [
      ContentType.LearningPath,
      ContentType.Ebook,
      ContentType.Podcast,
      ContentType.Stream
    ]
  },
  {
    label: 'Trails',
    value: [ContentType.LearningPath]
  },
  {
    label: 'Power Reads',
    value: [ContentType.Ebook]
  },
  {
    label: 'Podcasts',
    value: [ContentType.Podcast]
  },
  {
    label: 'Videos',
    value: [ContentType.Stream]
  }
];

export function ContentTypeSelect({
  name,
  isMulti = false,
  isClearable = false,
  required = false
}: ContentTypeSelectProps) {
  return (
    <FormSelect
      name={name}
      label="Content Type"
      isMulti={isMulti}
      isClearable={isClearable}
      options={DEFAULT_CONTENT_TYPE_SELECT_OPTIONS}
      placeholder="All content"
      rules={{
        required
      }}
    />
  );
}
