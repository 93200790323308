import * as React from 'react';
import { TrackedButton } from '@tigerhall/components';
import { IconFolderDown } from '@tigerhall/icons';
import { Icon, useToast } from '@chakra-ui/react';

import { useExportAssignmentStatisticsMutation } from '../../../queries';

interface ExportButtonProps {
  id: string;

  filter: {
    name: string;
    email: string;
    groups: string[];
  };
}

export function ExportButton({ id, filter }: Readonly<ExportButtonProps>) {
  const toast = useToast({
    position: 'top-right'
  });

  const [mutation, { loading }] = useExportAssignmentStatisticsMutation({
    variables: {
      id,
      filter
    }
  });

  const onClick = React.useCallback(async () => {
    try {
      await mutation();

      toast({
        status: 'info',
        title: 'Export queued',
        description:
          'The export is being processed and will be send to your email once it is ready.'
      });
    } catch (e) {
      toast({
        status: 'error',
        title: 'Export failed',
        description:
          'Something went wrong while queueing the export. Please try again later.'
      });
    }
  }, [toast, mutation]);

  return (
    <TrackedButton
      name="export_assignment_staitstics"
      variant="outline"
      size="md"
      isLoading={loading}
      onClick={onClick}
      rightIcon={<Icon as={IconFolderDown} />}
    >
      Export
    </TrackedButton>
  );
}
