import { Avatar, Box, Flex } from '@chakra-ui/react';
import { resizeImage } from '@tigerhall/core';
import { CommentType, Pretty, SimpleImage } from '@tigerhall/core/lib/types';
import { THLink } from '@tigerhall/ui-components';
import { CommentFragment } from 'generated';
import { redirectToUserProfile } from 'modules/application/utils/routes';
import * as React from 'react';

import { AnimatedReplyBox } from '../ReplyInputBox/AnimatedReplyBox';
import {
  AVATAR_SPACING,
  AVATAR_WIDTH,
  COMMENT_PADDING_LEFT
} from '../utils/constant';
import {
  CommentActions,
  CommentActionsProps
} from './components/CommentActions';
import { CommentBox, CommentBoxProps } from './components/CommentBox';

export interface CommentItemProps extends Pick<CommentBoxProps, 'onDelete'> {
  referenceId: string;
  referenceType: Exclude<CommentType, CommentType.Feed>;
  comment: Pretty<
    CommentActionsProps['comment'] & CommentBoxProps['comment']
  > & {
    belongTo?: CommentFragment['belongTo'];
    user: {
      avatarImage?: SimpleImage;
    };
  };
  replies?: React.ReactNode;
  disableReply?: boolean;
  isHighlighted?: boolean;
  forwardedRef?: React.Ref<HTMLDivElement>;
  refetch: () => void;
  isOrganizationContent: boolean;
}

export const CommentItem = React.forwardRef<HTMLDivElement, CommentItemProps>(
  (
    {
      referenceId,
      referenceType,
      comment,
      replies,
      disableReply = false,
      isHighlighted = false,
      onDelete,
      refetch,
      isOrganizationContent
    },
    ref
  ) => {
    const [isUserReplying, setIsUserReplying] = React.useState(false);

    const onReplyButtonClick = () => {
      setIsUserReplying(true);
    };

    const onReplyCancel = () => {
      setIsUserReplying(false);
    };

    return (
      <>
        <Flex gap="0.25rem" width="100%" flexDirection="column">
          <Flex gap={AVATAR_SPACING + 'rem'}>
            <THLink
              name="USER_AVATAR"
              user={comment?.user}
              to={redirectToUserProfile(comment?.user?.id)}
            >
              <Avatar
                width={AVATAR_WIDTH + 'rem'}
                height={AVATAR_WIDTH + 'rem'}
                borderRadius="full"
                src={resizeImage({
                  url: comment?.user.avatarImage?.uri ?? '',
                  width: 96,
                  height: 96
                })}
              />
            </THLink>
            <CommentBox
              comment={comment}
              onDelete={onDelete}
              isBoxHighlighted={isHighlighted}
              ref={ref}
            />
          </Flex>
          <Box pl={COMMENT_PADDING_LEFT + 'rem'}>
            <CommentActions
              comment={comment}
              referenceId={referenceId}
              referenceType={referenceType}
              hideReply={disableReply}
              onReplyButtonClick={onReplyButtonClick}
            />
          </Box>
        </Flex>
        {isUserReplying ? (
          <Box pl={COMMENT_PADDING_LEFT + 'rem'} zIndex={'zIndexOne'}>
            <AnimatedReplyBox
              comment={comment}
              referenceId={referenceId}
              referenceType={referenceType}
              refetch={refetch}
              isOrganizationContent={isOrganizationContent}
              onReplyCancel={onReplyCancel}
            />
          </Box>
        ) : null}
        {!!replies && (
          <Flex
            pl={COMMENT_PADDING_LEFT + 'rem'}
            flexDirection="column"
            gap="1rem"
          >
            {replies}
          </Flex>
        )}
      </>
    );
  }
);
