import { useLocation } from 'react-router-dom';
import { useLastLocation } from '@tigerhall/core';

/**
 * Sets the current location in a cookie so we can redirect back to it after login.
 */
export function useCurrentAsLastLocation() {
  const location = useLocation();
  const currentLocation = location.pathname + location.hash + location.search;

  const { setLastLocation } = useLastLocation();
  setLastLocation({ location: currentLocation });
}
