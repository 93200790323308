import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import { IconCircleOutCheck } from '@tigerhall/icons';
import { useFreeAccount } from 'hooks';
import * as React from 'react';
import * as segment from '@tigerhall/analytics';
import type { PlayingContent } from 'app/state';
import { useGetContentByIdLazyQuery } from 'generated';
export interface PreviewLimitReachedWallProps {
  onUpgradePlan: () => void;
  onWatchAgain: () => void;
  isVisible: boolean;
  content: Pick<PlayingContent, 'id' | 'type'>;
}

export function PreviewLimitReachedWall({
  onUpgradePlan,
  onWatchAgain,
  isVisible,
  content
}: Readonly<PreviewLimitReachedWallProps>) {
  const { canSubscribe } = useFreeAccount();

  const [getContentById] = useGetContentByIdLazyQuery({
    variables: {
      id: content.id
    }
  });

  React.useEffect(() => {
    if (isVisible) {
      getContentById().then((data) => {
        const contentDetails = data.data?.contentCard;
        if (
          contentDetails?.__typename === 'Ebook' ||
          contentDetails?.__typename === 'Stream' ||
          contentDetails?.__typename === 'Podcast'
        ) {
          const expertIds: string[] = [];
          const experts: string[] = [];
          contentDetails.experts.forEach((expert) => {
            expertIds.push(expert.id);
            experts.push(`${expert.firstName} ${expert.lastName}`);
          });

          const categories: string[] = [];
          const categoryIds: string[] = [];

          contentDetails.categories.forEach((category) => {
            categoryIds.push(category.id);
            categories.push(category.name);
          });

          segment.contentPreviewCompleted({
            contentId: content.id,
            contentType: contentDetails?.__typename,
            contentName: contentDetails.name,
            expertsIds: expertIds,
            experts: experts,
            categories: categories,
            categoriesIds: categoryIds
          });
        }
      });
    }
  }, [isVisible, content, getContentById]);

  return (
    <Box bg="black" w="full" h="100vh">
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        gap="2rem"
        userSelect="none"
        w="full"
        h="full"
        background="blackAlpha.700"
        backdropFilter="blur(1.5rem)"
      >
        <VStack gap="1.5rem" spacing="0" w="full" maxW="20rem">
          <VStack>
            <IconCircleOutCheck
              viewBox="0 0 40 40"
              width="2.5rem"
              height="2.5rem"
            />
            <Text mb="2rem" fontSize="3xl" color="white">
              Your Preview is Over
            </Text>
          </VStack>
          <VStack w="full">
            {canSubscribe && (
              <Button onClick={onUpgradePlan} size="lg" w="full">
                Subscribe now
              </Button>
            )}
            <Button onClick={onWatchAgain} variant="outline" size="lg" w="full">
              Restart
            </Button>
          </VStack>
        </VStack>
      </Flex>
    </Box>
  );
}
