import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { PageContainer } from 'components/ui';
import { useAppSelector } from 'hooks';
import { useCurrentAsLastLocation } from 'hooks/useCurrentAsLastLocation';
import { Splash } from 'components/ui/Layout/components';
import { ErrorBoundary } from 'components/tools/ErrorBoundary';

import { routes as assignmentRoutes } from './modules/assignments';
import { routes as eventRoutes } from './modules/events';
import { routes as trailRoutes } from './modules/trails';
import { routes as userRoutes } from './modules/users';
import { routes as groupRoutes } from './modules/groups';
import { routes as videoRoutes } from './modules/videos';
import { routes as podcastRoutes } from './modules/podcasts';
import { routes as ebookRoutes } from './modules/ebooks';
import { routes as accessLevelsRoutes } from './modules/accessLevel';
import { routes as broadcastRoutes } from './modules/broadcast';
import { routes as reportBuilderRoutes } from './modules/reportBuilder';

const Settings = React.lazy(() =>
  import('./screens').then((module) => ({
    default: module.Settings
  }))
);

const Statistics = React.lazy(() =>
  import('./screens').then((module) => ({
    default: module.Statistics
  }))
);

const RouteGuard = () => {
  useCurrentAsLastLocation();

  return (
    <PageContainer>
      <Helmet titleTemplate="%s | Admin Portal" defaultTitle="Admin Portal" />
      <Outlet />
    </PageContainer>
  );
};

function DebugEnv() {
  const user = useAppSelector((state) => state.user);

  if (user.claims.roles.indexOf('admin') === -1) {
    return <Navigate to="/home" replace />;
  }

  return <pre>{JSON.stringify(import.meta.env, null, 2)}</pre>;
}

const routes: RouteObject[] = [
  {
    path: '/admin/',

    element: <RouteGuard />,

    children: [
      {
        path: 'debug',
        element: <DebugEnv />
      },
      {
        path: 'settings',
        element: (
          <ErrorBoundary>
            <React.Suspense fallback={<Splash />}>
              <Settings />
            </React.Suspense>
          </ErrorBoundary>
        )
      },

      {
        path: 'statistics',
        element: (
          <ErrorBoundary>
            <React.Suspense fallback={<Splash />}>
              <Statistics />
            </React.Suspense>
          </ErrorBoundary>
        )
      },

      ...assignmentRoutes,
      ...userRoutes,
      ...groupRoutes,

      // Content
      ...trailRoutes,
      ...eventRoutes,
      ...videoRoutes,
      ...podcastRoutes,
      ...ebookRoutes,
      ...broadcastRoutes,
      ...accessLevelsRoutes,
      ...reportBuilderRoutes
    ]
  }
];

export default routes;
