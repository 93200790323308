import { Flex } from '@chakra-ui/react';
import { Button, THLink } from '@tigerhall/ui-components';
import TaskSuccessComponent from 'components/ui/TaskSuccessComponent';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { eventDetailsRoute, eventHistoryRoute } from '../../utils/routes';

const EventSuccess = () => {
  const navigate = useNavigate();

  const { idOrDraft } = useParams();

  const description =
    idOrDraft === 'draft'
      ? `You've created a custom Experience for your teams! Make sure you invite people, either by assigning the Experience to them, or by sharing the link below. `
      : `You've updated an Experience! Make sure you invite people, either by assigning the Experience to them, or by sharing the link below. `;

  return (
    <TaskSuccessComponent description={description}>
      <Flex gap="1rem">
        <Button
          size="lg"
          text="Create New Experience"
          onClick={() => {
            navigate(eventDetailsRoute('draft'), { replace: true });
          }}
          flex={1}
        />

        <THLink name="RETURN_TO_OVERVIEW" to={eventHistoryRoute()}>
          <Button
            size="lg"
            text="Return To Overview"
            variant="outline"
            backgroundColor="transparent"
          />
        </THLink>
      </Flex>
    </TaskSuccessComponent>
  );
};

export default EventSuccess;
