import { Avatar, Flex, Text, VStack } from '@chakra-ui/react';
import { TrackedButton, TrackedLink } from '@tigerhall/components';
import { Pretty, resizeImage } from '@tigerhall/core';

import { CommentActionsProps } from '../CommentItem/components/CommentActions';
import { CommentBoxProps } from '../CommentItem/components/CommentBox';
import { useGetCurrentLoggedInUserQuery } from '../queries';
import { AVATAR_SPACING, AVATAR_WIDTH } from '../utils/constant';
import {
  ReplyInputWrapper,
  type ReplyInputWrapperProps
} from './ReplyInputWrapper';

export interface ReplyInputBoxProps
  extends Pick<
    ReplyInputWrapperProps,
    | 'referenceId'
    | 'referenceType'
    | 'isOrganizationContent'
    | 'onReplyCancel'
    | 'refetch'
  > {
  comment: Pretty<CommentActionsProps['comment'] & CommentBoxProps['comment']>;
}

export function ReplyInputBox({
  comment,
  referenceId,
  referenceType,
  isOrganizationContent,
  refetch,
  onReplyCancel
}: Readonly<ReplyInputBoxProps>) {
  const { data: currentUser } = useGetCurrentLoggedInUserQuery();

  return (
    <Flex w={'100%'} direction={'column'}>
      <Flex gap={AVATAR_SPACING + 'rem'}>
        <TrackedLink name="USER_AVATAR" href={'/profile'}>
          <Avatar
            width={AVATAR_WIDTH + 'rem'}
            height={AVATAR_WIDTH + 'rem'}
            borderRadius="full"
            src={resizeImage({
              url: currentUser?.user?.avatarImage?.uri ?? '',
              width: 96,
              height: 96
            })}
          />
        </TrackedLink>
        <VStack w={'100%'} alignItems={'flex-start'}>
          <ReplyInputWrapper
            isOrganizationContent={isOrganizationContent}
            referenceId={referenceId}
            referenceType={referenceType}
            commentId={comment.id}
            refetch={refetch}
            onReplyCancel={onReplyCancel}
          />
          <TrackedButton
            name="REPLY_CANCEL"
            variant={'link'}
            onClick={onReplyCancel}
            gap={1}
            _hover={{
              textDecoration: 'none'
            }}
          >
            <Text as={'span'} fontSize={'sm'}>
              Click to
            </Text>
            <Text
              as={'span'}
              fontSize={'sm'}
              textDecoration={'underline'}
              color={'tigerOrange.400'}
              cursor={'pointer'}
            >
              cancel
            </Text>
          </TrackedButton>
        </VStack>
      </Flex>
    </Flex>
  );
}
