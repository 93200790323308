import '@total-typescript/ts-reset';
import isEmpty from 'lodash/isEmpty';
import { Box } from '@chakra-ui/react';

import { LinkPreview } from '~/components/ui/LinkPreview/LinkPreview';
import { useLimitLinkPreview } from '~/components/ui/LinkPreview/hooks';
import type { FeedPostAttachments, Maybe } from '~/generated';

interface PostLinkAttachmentsProps {
  attachments: Maybe<FeedPostAttachments>[];
}

export function PostLinkAttachments({
  attachments
}: Readonly<PostLinkAttachmentsProps>) {
  const isUrlPreviewEnabled = true;

  const previewUrls = attachments.map((each) => each?.uri).filter(Boolean);

  const { linksToShow } = useLimitLinkPreview({
    urls: previewUrls,
    noOfPreviewToLimit: 4
  });

  if (!isUrlPreviewEnabled || isEmpty(linksToShow)) {
    return null;
  }

  return (
    <Box px={{ base: 4, md: 6 }} pb={5}>
      <LinkPreview urls={linksToShow} />
    </Box>
  );
}
