import { Avatar, Divider, HStack, Text } from '@chakra-ui/react';
import {
  type ContentActivity,
  type PostActivity,
  type Pretty,
  type SimpleImage,
  type User,
  resizeImage
} from '@tigerhall/core';

import { NameWithBadge } from '~/components/ui/User/NameWithBadge';
import { ActivityDescription } from './components/ActivityDescription';

interface ActivityUser extends Pick<User, 'id' | 'firstName' | 'lastName'> {
  avatarImage?: SimpleImage;
  isExpert?: User['isExpert'];
  expertOrg?: {
    id: string;
  } | null;
}

export type ActivityType =
  | ContentActivity['contentActivityType']
  | PostActivity['activityType'];

export interface ActivityHeaderProps {
  user: Pretty<ActivityUser>;
  activityType: ActivityType;
  /** ID of the Post or Content that the activity is related to */
  postOrContentId: string;
}

export function ActivityHeader({
  user,
  postOrContentId,
  activityType
}: Readonly<ActivityHeaderProps>) {
  const author = `${user.firstName || ''} ${user.lastName || ''}`;
  const imageUrl = user.avatarImage?.uri ?? '';

  return (
    <>
      <HStack
        spacing="0.5rem"
        py="1rem"
        px={{
          base: '1rem',
          md: '1.5rem'
        }}
      >
        <Avatar
          src={resizeImage({
            url: imageUrl,
            width: 48,
            height: 48
          })}
          name={author}
          showBorder={true}
          borderWidth="1px"
          borderColor="darkGrey.300"
          size="xs"
        />
        <Text>
          <NameWithBadge
            userId={user.id}
            spacing={1}
            as="span"
            fontSize="sm"
            fontWeight="bold"
            color="lightGrey.200"
            badgeProps={{
              size: 'md'
            }}
          />{' '}
          <ActivityDescription
            activityType={activityType}
            postOrContentId={postOrContentId}
            as="span"
            fontSize="sm"
            color="lightGrey.200"
          />
        </Text>
      </HStack>
      <Divider borderColor="darkGrey.400" />
    </>
  );
}
