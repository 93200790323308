import { mode as e } from "./index31.mjs";
const d = {
  // style object for base or default style
  baseStyle: (r) => ({
    field: {
      _placeholder: {
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: "1.5rem",
        margin: "0.75rem",
        color: e("darkGrey.50", "darkGrey.200")(r)
      },
      color: e("darkGrey.300", "lightGrey.400")(r),
      // Stop the input from being resized when the hover is triggered
      border: "1px",
      borderColor: e("lightGrey.600", "darkGrey.200")(r),
      fontSize: "1rem",
      fontWeight: 500,
      fontFamily: "body",
      background: e("white", "darkGrey.400")(r),
      _hover: {
        borderColor: e("darkGrey.200", "lightGrey.900")(r)
      },
      _focus: {
        borderColor: e("darkGrey.300", "lightGrey.600")(r)
      },
      _disabled: {
        backgroundColor: e("lightGrey.400", "black")(r),
        borderColor: e("lightGrey.600", "darkGrey.700")(r)
      },
      _invalid: {
        borderColor: e("red.500", "red.400")(r)
      }
    }
  }),
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    md: {
      field: {
        height: "3rem"
      }
    }
  },
  // styles for different visual variants ("outline", "solid")
  variants: {},
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    size: "md",
    variant: "",
    focusBorderColor: "tigerOrange.800"
  }
};
export {
  d as Input
};
