import { captureException } from '@sentry/react';
import {
  type PlayerModalProps,
  Video,
  type VideoProps
} from '@tigerhall/components';
import { ContentVote } from '@tigerhall/core';
import {
  getContentUrl,
  getIsContentPreviewOver,
  getIsLastContentInPlaylist,
  getIsTemporarilyHiddenAndPaused,
  getPlaylistId,
  getPlaylistTitle,
  getPlaylistType,
  setAutoNextPlayToggle,
  setIsContentPreviewOver
} from 'app/state';
import {
  useAppDispatch,
  useAppSelector,
  useFreeAccount,
  useNavigationListener
} from 'hooks';
import { useContentActions } from 'modules/application/hooks/useContentActions';
import { useCallback, useMemo, useState } from 'react';

import { type GetStreamForConnectedVideoPlayerQuery } from '../queries';

export interface VideoPlayerProps {
  /**
   * Stream object
   */
  stream: NonNullable<GetStreamForConnectedVideoPlayerQuery['stream']>;
  subtitlesUrl?: string;
  /**
   * Function to be called when the audio language changes
   */
  setAudioLanguage: VideoProps['onChangeMediaLanguage'];
  /**
   * The currently selected audio language
   */
  audioLanguage: VideoProps['selectedMediaLanguage'];
  /**
   * Function to be called when the subtitle language changes
   */
  setSubtitleLanguage: VideoProps['onChangeTranscriptLanguage'];
  /**
   * The currently selected subtitle language
   */
  subtitleLanguage: VideoProps['selectedTranscriptLanguage'];
  onFinished?: VideoProps['onFinished'];
  onClose: PlayerModalProps['onMinimize'];
  onMaximize: VideoProps['onMaximize'];
  isMinimized: VideoProps['isMinimized'];
  setIsMinimized: (value: boolean) => void;
}

export function VideoPlayer({
  stream,
  subtitlesUrl,
  onFinished,
  setAudioLanguage,
  audioLanguage,
  setSubtitleLanguage,
  subtitleLanguage,
  onMaximize,
  isMinimized,
  setIsMinimized,
  onClose
}: Readonly<VideoPlayerProps>) {
  const [trackIndex, setTrackIndex] = useState(0);

  const {
    playlistId,
    playlistType,
    playlistTitle,
    isLastContentInPlaylist,
    isPreviewLimitConsumed,
    isHidden,
    contentUrl
  } = useAppSelector((state) => ({
    playlistId: getPlaylistId(state),
    playlistType: getPlaylistType(state),
    playlistTitle: getPlaylistTitle(state),
    isLastContentInPlaylist: getIsLastContentInPlaylist(state),
    isPreviewLimitConsumed: getIsContentPreviewOver(state),
    isHidden: getIsTemporarilyHiddenAndPaused(state),
    contentUrl: getContentUrl(state)
  }));

  const { isInPreviewMode } = useFreeAccount();
  const dispatch = useAppDispatch();

  const { rate, trackProgress, trackFinished } = useContentActions();

  useNavigationListener(() => {
    if (!isMinimized) {
      setIsMinimized(true);
    }
  });

  const meta = useMemo(
    () => ({
      contentId: stream.id,
      contentName: stream.name,
      casting: stream.experts
        .map((expert) => `${expert.firstName} ${expert.lastName}`)
        .join(', ')
    }),
    [stream]
  );

  const onLike = useCallback(() => {
    rate(stream, ContentVote.Upvote).catch((error) => captureException(error));
  }, [rate, stream]);

  const onDislike = useCallback(() => {
    rate(stream, ContentVote.Downvote).catch((error) =>
      captureException(error)
    );
  }, [rate, stream]);

  const onAudioLanguageChange = useCallback(
    (code: string) => setAudioLanguage(code),
    [setAudioLanguage]
  );

  const onSubtitleLanguageChange = useCallback(
    (code: string | null) => setSubtitleLanguage(code),
    [setSubtitleLanguage]
  );

  const onTrackProgress = useCallback(
    (progress: number) => {
      trackProgress({
        content: stream,
        progress,
        playlist: { playlistId, playlistType }
      }).catch((error) => captureException(error));
    },
    [stream, trackProgress]
  );

  const onTrackFinished = useCallback(() => {
    trackFinished(stream).catch((error) => captureException(error));
  }, [stream, trackFinished]);

  const onPlayerMaximize = useCallback(() => {
    onMaximize();
    if (isPreviewLimitConsumed) {
      dispatch(setIsContentPreviewOver(false));
    }
  }, [dispatch, isPreviewLimitConsumed, onMaximize]);

  const tracks = [
    {
      name: stream.name,
      url: stream.video.url,
      subtitlesUrl
    }
  ];

  const urlToShare = window.location.href;

  function onToggleAutoPlayNext(value: boolean) {
    dispatch(setAutoNextPlayToggle(value));
  }

  return (
    <Video
      meta={meta}
      onTrackProgress={onTrackProgress}
      isHidden={isHidden}
      previousProgress={
        isInPreviewMode ? 0 : stream.userContentTracking.currentPlacement
      }
      onTrackFinished={onTrackFinished}
      onFinished={onFinished}
      isMinimized={isMinimized}
      tracks={tracks}
      trackIndex={trackIndex}
      setTrackIndex={setTrackIndex}
      mediaLanguages={stream.audioAvailableLanguages}
      selectedMediaLanguage={audioLanguage}
      onChangeMediaLanguage={onAudioLanguageChange}
      transcriptLanguages={stream.subtitleAvailableLanguages}
      selectedTranscriptLanguage={subtitleLanguage}
      onChangeTranscriptLanguage={onSubtitleLanguageChange}
      vote={stream.contentRatingByUser}
      onLike={onLike}
      onDislike={onDislike}
      onClose={() => {
        onClose();
        setIsMinimized(false);
      }}
      onMaximize={onPlayerMaximize}
      urlToShare={urlToShare}
      contentDescription={stream.preamble}
      playlistTitle={playlistTitle}
      playlistType={playlistType}
      onAutoNextPlay={onToggleAutoPlayNext}
      orientation={stream.video.orientation}
      isLastContentInPlaylist={isLastContentInPlaylist}
      playlistId={playlistId}
      customDuration={isInPreviewMode ? stream.length : undefined}
      contentUrl={contentUrl}
    />
  );
}
