import * as React from 'react';
import { DateTime } from 'luxon';
import { IconCalendarEvent } from '@tigerhall/icons';
import { Text, Icon } from '@chakra-ui/react';

import type { EventTimeBadgeProps, ComponentsProps } from '../types';

export function EventDate({
  date,
  iconProps,
  textProps
}: Omit<EventTimeBadgeProps, 'typename'> & ComponentsProps) {
  const eventDate = DateTime.fromISO(date, { setZone: true });

  return (
    <>
      <Icon as={IconCalendarEvent} {...iconProps} />
      <Text
        as="time"
        dateTime={eventDate.toISO()}
        color="white"
        lineHeight="none"
        fontWeight="bold"
        fontSize={{
          base: 'x-small',
          lg: 'xs'
        }}
        noOfLines={1}
        {...textProps}
      >
        {eventDate.toFormat("dd LLL '|' ZZZZ")}
      </Text>
    </>
  );
}
