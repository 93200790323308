import { Text, VStack } from '@chakra-ui/react';
import { TrackedButton } from '@tigerhall/components';

import { DownloadAppButtons } from './DownloadAppButtons';

export function InvitedSuccess({ onButtonClick }) {
  return (
    <VStack spacing="0" gap="2.5rem">
      <Text
        as="h2"
        textAlign="center"
        color="white"
        fontSize="3xl"
        fontWeight="bold"
      >
        You&apos;re in!
      </Text>
      <Text textAlign="center" fontSize="md">
        Simply head to App Store or Google Play Store to use Tigerhall on the go
      </Text>
      <DownloadAppButtons />
      <TrackedButton
        variant="outline"
        size="lg"
        name="InvitationSignUpContinueToWeb"
        onClick={onButtonClick}
      >
        Continue on the Web
      </TrackedButton>
    </VStack>
  );
}
