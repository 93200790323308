import { EditorForm } from '~/components/ui/lexical/form/EditorForm';

export function PostTextContent() {
  return (
    <EditorForm
      name="description"
      placeholder="What’s on your mind?"
      isPostEditor={true}
    />
  );
}
