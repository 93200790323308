import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import * as segment from '@tigerhall/analytics';
import { User } from '@tigerhall/core/lib/types';
import * as React from 'react';

import { ActivityTab } from './ActivityTab';
import ContentExpertiseTab from './ContentExpertiseTab';
import InterestTab from './InterestTab';

type ProfileTabsType = {
  isCurrentUser: boolean;
  user: User;
};

export function ProfileTabs({ isCurrentUser, user }: ProfileTabsType) {
  const renderInterestContentTab = () => {
    if (isCurrentUser) {
      return <InterestTab user={user} />;
    }

    return <ContentExpertiseTab user={user} />;
  };

  const tabs = [
    {
      id: 'activity',
      name: 'Activity'
    },
    {
      id: 'interests',
      name: isCurrentUser ? 'Interests' : 'Content & Expertise'
    }
  ];

  return (
    <>
      <Box
        flex={1}
        width="100%"
        css={{
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <Tabs
          isFitted
          onChange={(index) => {
            segment.tabClicked({
              tabId: tabs[index].id,
              tabName: tabs[index].name
            });
          }}
        >
          <TabList>
            <Tab>{tabs[0].name}</Tab>
            <Tab>{tabs[1].name}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <ActivityTab userId={user.id} />
            </TabPanel>
            <TabPanel p={0}>{renderInterestContentTab()}</TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}
