import * as React from 'react';
import {
  Button,
  Circle,
  HStack,
  Icon,
  LightMode,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react';
import { capitalizeFirst } from '@tigerhall/core';

import { getSocialMediaIcon, shareUrls, socialMediaList } from './utils';
import { CopyInput } from '~/copy-input';

export interface ShareModalProps {
  heading?: string;
  urlToShare: string;
  titleToShare: string;
  twitterUsername?: string;
  isOpen: boolean;
  onClose: () => void;
}
export function ShareModal({
  heading = 'Share',
  urlToShare,
  titleToShare,
  twitterUsername,
  isOpen,
  onClose
}: ShareModalProps) {
  const shareUrlsList = shareUrls({
    url: urlToShare,
    text: titleToShare,
    twitterUsername,
    appVersion: false
  });

  function openWindowToShare(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    socialMediaName: string
  ) {
    e.preventDefault();

    const windowW = 600;
    const windowH = 400;
    const left = window.screen.width / 2 - windowW / 2;
    const top = window.screen.height / 2 - windowH / 2;

    window.open(
      shareUrlsList[socialMediaName],
      undefined,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${windowW}, height=${windowH}, top=${top}, left=${left}`
    );
  }

  return (
    <LightMode>
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent borderTopRadius="md" p="2rem">
          <ModalHeader fontWeight="bold" fontSize="xl">
            {heading}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <VStack spacing="1rem">
              <HStack
                color="white"
                justifyContent="center"
                alignItems="center"
                spacing="0.75rem"
              >
                {socialMediaList.map((socialMedia) => (
                  <Button
                    key={socialMedia}
                    type="button"
                    role="button"
                    data-share={socialMedia}
                    variant="unstyled"
                    rounded="lg"
                    p="0.5rem"
                    height="auto"
                    display="flex"
                    flexDirection="column"
                    gap="0.5rem"
                    _hover={{
                      backgroundColor: 'lightGrey.200'
                    }}
                    onClick={(e) => openWindowToShare(e, socialMedia)}
                  >
                    <Circle
                      size="3.25rem"
                      border="1px solid"
                      borderColor="lightGrey.600"
                    >
                      <Icon
                        width="1rem"
                        height="1rem"
                        aria-hidden
                        color="darkGrey.400"
                        as={getSocialMediaIcon(socialMedia)}
                      />
                    </Circle>
                    <Text as="span" fontSize="2xs">
                      {capitalizeFirst(socialMedia)}
                    </Text>
                  </Button>
                ))}
              </HStack>
              <Text fontSize="xs" fontWeight="bold">
                Or share with link
              </Text>
              <CopyInput text={urlToShare} />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </LightMode>
  );
}
