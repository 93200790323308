import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoginMsTeamsMutationVariables = SchemaTypes.Exact<{
  token: SchemaTypes.Scalars['String'];
}>;


export type LoginMsTeamsMutation = { __typename?: 'Mutation', loginMSTeams?: { __typename?: 'AuthenticationResponse', finishedRegistration: boolean, jwt: string, message: string, newAccount: boolean, userId: string, success: boolean } | null };


export const LoginMsTeamsDocument = gql`
    mutation loginMSTeams($token: String!) {
  loginMSTeams(accessToken: $token) {
    finishedRegistration
    jwt
    message
    newAccount
    userId
    success
  }
}
    `;
export type LoginMsTeamsMutationFn = Apollo.MutationFunction<LoginMsTeamsMutation, LoginMsTeamsMutationVariables>;

/**
 * __useLoginMsTeamsMutation__
 *
 * To run a mutation, you first call `useLoginMsTeamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMsTeamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMsTeamsMutation, { data, loading, error }] = useLoginMsTeamsMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginMsTeamsMutation(baseOptions?: Apollo.MutationHookOptions<LoginMsTeamsMutation, LoginMsTeamsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMsTeamsMutation, LoginMsTeamsMutationVariables>(LoginMsTeamsDocument, options);
      }
export type LoginMsTeamsMutationHookResult = ReturnType<typeof useLoginMsTeamsMutation>;
export type LoginMsTeamsMutationResult = Apollo.MutationResult<LoginMsTeamsMutation>;
export type LoginMsTeamsMutationOptions = Apollo.BaseMutationOptions<LoginMsTeamsMutation, LoginMsTeamsMutationVariables>;