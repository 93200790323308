import * as React from 'react';
import {
  BoxProps,
  ButtonProps,
  Flex,
  Heading,
  HStack,
  Text
} from '@chakra-ui/react';
import { TrackedButton } from '@tigerhall/components';
import { DarkPanel } from 'components/ui/containers/DarkPanel';

type ContentPanelProps = {
  /**
   * The primary heading in orange
   */
  heading: string;

  /**
   * This is the heading within the actual box
   */
  subheading?: string;

  /**
   * If you want to add some text buttons with heading
   */
  inlineButtons?: (ButtonProps & { text: string; hide?: boolean })[];

  /**
   * Slot for the right side of the heading.
   * This is useful for adding buttons, status indicators, etc.
   */
  headingRightSlot?: React.ReactNode;

  /**
   * The contents of the content box
   */
  children: React.ReactNode;
} & BoxProps;

export function ContentPanel({
  heading,
  subheading,
  inlineButtons,
  children,
  headingRightSlot,
  ...rest
}: ContentPanelProps) {
  return (
    <DarkPanel gap="1.5rem" {...rest}>
      <HStack justifyContent="space-between">
        <HStack spacing="1.5rem">
          <Heading
            fontSize="2xl"
            fontWeight="bold"
            color="white"
            alignSelf="center"
          >
            {heading}
          </Heading>

          {inlineButtons && (
            <HStack spacing="0.5rem">
              {inlineButtons?.map(
                (inlineButton) =>
                  !inlineButton.hide && (
                    <TrackedButton
                      key={inlineButton.text}
                      name={inlineButton.text}
                      variant="inline"
                      {...inlineButton}
                    >
                      {inlineButton.text}
                    </TrackedButton>
                  )
              )}
            </HStack>
          )}
        </HStack>

        <HStack spacing="0.5rem">{headingRightSlot}</HStack>
      </HStack>

      <Flex direction="column" borderRadius="lg">
        {subheading && (
          <Text fontSize="md" fontWeight="medium" color="white" mb="1.5rem">
            {subheading}
          </Text>
        )}

        {children}
      </Flex>
    </DarkPanel>
  );
}
