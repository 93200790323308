import {
  type ChakraTheme,
  extendTheme,
  withDefaultColorScheme
} from '@chakra-ui/react';
import { theme as baseTheme } from '@tigerhall/theme';

import * as components from './components';

const theme: ChakraTheme = extendTheme(
  baseTheme,
  {
    initialColorMode: 'dark',
    colors: {
      orange: baseTheme?.colors.tigerOrange,
      gradients: {
        teal: {
          '100': 'linear-gradient(#003238, #001315)',
          '200': 'linear-gradient(180deg, #003238 0%, #001315 100%)'
        },
        lightGrey: {
          '300': 'linear-gradient(#464A4D, #151618)',
          '400': 'linear-gradient(#333738, #151618)'
        }
      }
    },
    components: {
      Checkbox: {
        baseStyle: {
          control: {
            bg: 'white',
            _checked: {
              bg: 'orange.600'
            }
          }
        }
      },
      ...components
    }
  },
  withDefaultColorScheme({
    colorScheme: 'orange'
  })
) as ChakraTheme;

export default theme;
