import {
  type ButtonProps,
  Icon,
  IconButton,
  type IconButtonProps,
  type IconProps,
  Tooltip
} from '@chakra-ui/react';

interface OptionButtonProps {
  label: string;
  icon: React.ForwardRefExoticComponent<
    React.SVGAttributes<SVGElement> & React.RefAttributes<SVGSVGElement>
  >;
  onClick: IconButtonProps['onClick'];
  /**
   * Icon Size default value 1.5rem.
   *
   * @default "1.5rem"
   */
  iconSize?: string;
  /**
   * Icon Color default value lightGrey.400.
   *
   * @default "lightGrey.400"
   */
  iconColor?: string;
  iconButtonProps?: ButtonProps;
  iconProps?: IconProps;
}

/**
 * `IconButton` with a `Tooltip` that displays a label.
 * Used for the content player options.
 * also `openDelay={300}` is passed to all the tooltip because
 * OptionButton is used in Overlay Component to known the
 * exact reason check here `ui/components/src/player/components/Overlay/Overlay.tsx`
 */

export function OptionButton({
  label,
  icon,
  iconSize = '1.5rem',
  iconColor = 'lightGrey.400',
  onClick,
  iconButtonProps,
  iconProps
}: OptionButtonProps) {
  return (
    <Tooltip label={label} hasArrow openDelay={300}>
      <IconButton
        aria-label={label}
        borderRadius="0.5rem"
        variant="ghost"
        onClick={onClick}
        {...iconButtonProps}
        icon={
          <Icon
            as={icon}
            width={iconSize}
            height={iconSize}
            color={iconColor}
            {...iconProps}
          />
        }
      />
    </Tooltip>
  );
}
