import { Text, VStack } from '@chakra-ui/react';
import { TrackedButton } from '@tigerhall/components';
import { TigerhallSkeleton } from '@tigerhall/ui-components';
import { useGetCurrentUserQuery } from 'generated';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function FreeTrialWelcome() {
  const navigate = useNavigate();
  const { data, loading } = useGetCurrentUserQuery();

  const handleStartClick = useCallback(() => {
    navigate('/signup/individual/interest-select');
  }, [navigate]);

  if (loading) {
    return <TigerhallSkeleton variant="onboard_welcome_user" />;
  }
  return (
    <VStack spacing="0" gap="0.75rem" h="100%" justifyContent="center">
      <Text fontSize="2.5rem">🥳</Text>
      <Text color="white" fontWeight="bold" fontSize="2rem">
        Hello, {data?.me?.firstName}!
      </Text>
      <Text color="lightGrey.400" fontSize="lg" textAlign="center">
        Welcome to Tigerhall! You&apos;re on your way to achieving your goals,
        with a community to support you along the way.
      </Text>
      <TrackedButton
        variant="solid"
        type="submit"
        size="lg"
        borderRadius="full"
        w="100%"
        name="onboardingStart"
        onClick={handleStartClick}
        style={{
          marginTop: '1.5rem'
        }}
      >
        Let&apos;s Start
      </TrackedButton>
    </VStack>
  );
}
