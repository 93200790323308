import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

const ERROR_MESSAGES = {
  '401': 'You are not authorized to access this page. Please login to continue.'
};

/**
 * This component is used to show a toast based on the search params,
 * using the URL as a state manager that will trigger the toast.
 *
 * Right now, it is used to show a global error toast based on the
 * error code in the URL search params.
 *
 * This component is used in `Router` along with other tool components
 * like `Analytics` and `RefreshToken`.
 */
export function SearchParamsToast() {
  const [searchParams] = useSearchParams();
  const toast = useToast();

  const errorCode = searchParams.get('error');

  useEffect(() => {
    if (errorCode) {
      toast({
        title: 'Error',
        description:
          ERROR_MESSAGES[errorCode] ||
          'Something went wrong. Please try again.',
        status: 'error'
      });
    }
  }, [errorCode, toast]);

  return null;
}
