import { ComponentStyleConfig } from '@chakra-ui/react';

const styles: ComponentStyleConfig = {
  parts: ['content'],
  baseStyle: {
    modalContent: {
      width: 'auto'
    }
  }
};

export default styles;
