import * as React from 'react';
import { Splash } from 'components/ui/Layout/components';
import { RouteObject } from 'react-router-dom';
import { ErrorBoundary } from 'components/tools/ErrorBoundary';

const AccessLevel = React.lazy(() =>
  import('./AccessLevels').then((module) => ({
    default: module.AccessLevels
  }))
);

const CreateAccessLevel = React.lazy(() =>
  import('./CreateAccessLevel').then((module) => ({
    default: module.CreateAccessLevel
  }))
);

const routes: RouteObject[] = [
  {
    path: 'access-levels/',
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<Splash />}>
          <AccessLevel />
        </React.Suspense>
      </ErrorBoundary>
    )
  },
  {
    path: 'access-levels/:idOrDraft',
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<Splash />}>
          <CreateAccessLevel />
        </React.Suspense>
      </ErrorBoundary>
    )
  }
];

export default routes;
