import * as React from 'react';
import { Flex, Heading, useDisclosure, VStack } from '@chakra-ui/react';
import { Modal } from 'components';
import isEmpty from 'lodash/isEmpty';
import { IdType } from 'react-table';

import { BulkAction } from '../types';
import { TableAction } from './TableAction';

type TableBulkActionsType<D extends object = {}> = {
  actions: BulkAction[];
  selectedIds?: IdType<D>[];
  formatStats?: ({ selectedIds }) => React.ReactNode;
};

const TableBulkActions: React.FC<TableBulkActionsType> = ({
  actions,
  selectedIds,
  formatStats = () => null
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalProps, setModalProps] = React.useState({});

  const modalParams = React.useMemo(
    () => ({ isOpen, onOpen, onClose, setModalProps }),
    [isOpen, onOpen, onClose, setModalProps]
  );

  const onAction = React.useCallback(
    (id: string) => {
      const action = actions.find((item) => item.id === id);
      if (!action) {
        return;
      }
      action.onAction?.({ modalParams, ...action });
    },
    [actions, modalParams]
  );

  if (isEmpty(actions)) {
    return null;
  }

  return (
    <>
      <Modal {...modalProps} isOpen={isOpen} onClose={onClose} />
      <VStack alignItems="start">
        <Heading
          as="span"
          fontSize="md"
          fontWeight="bold"
          color="white"
          pl="0.5rem"
        >
          {formatStats({ selectedIds })}
        </Heading>
        <Flex direction="row">
          {actions.map((action) => (
            <TableAction
              key={action.id}
              {...action}
              isDisabled={action.isDisabled ?? (selectedIds?.length ?? 0) === 0}
              onClick={onAction}
              data-cy={`bulk-action-${action.id}`}
            />
          ))}
        </Flex>
      </VStack>
    </>
  );
};

export default TableBulkActions;
