import { Center } from '@chakra-ui/react';
import {
  CommentType,
  type ContentActivity as ContentActivityInterface
} from '@tigerhall/core';
import {
  ContentBarWithLinks,
  type ContentBarWithLinksProps
} from 'components/ui/ContentBox/ContentBarWithLinks';

import { FeedItemContainer } from '../FeedItemContainer';
import { ActivityHeader, type ActivityHeaderProps } from '../ActivityHeader';
import { FeedItemActions } from '../FeedItemActions';
import { CommentOnContent } from '../FeedItemActions/components/CommentOnContent';
import { PostMetrics } from '../PostMetrics';
import { ContentCommentsCount } from '../PostMetrics/components/ContentCommentsCount';
import { ActivityComment, type ActivityCommentProps } from '../ActivityComment';

export interface ContentActivityProps
  extends Pick<ActivityCommentProps, 'onDelete'> {
  activity: {
    contentActivityType: ContentActivityInterface['contentActivityType'];
    user: ActivityHeaderProps['user'];
    content: ContentBarWithLinksProps;
    comment?: ActivityCommentProps['comment'] | null;
  };
}

export function ContentActivity({ activity, onDelete }: ContentActivityProps) {
  return (
    <FeedItemContainer>
      <ActivityHeader
        user={activity.user}
        activityType={activity.contentActivityType}
        postOrContentId={activity.content.id}
      />
      <Center
        my="1.25rem"
        mx={{
          base: '1rem',
          md: '2rem'
        }}
      >
        <ContentBarWithLinks
          sectionName="Content Activity"
          {...activity.content}
        />
      </Center>
      <PostMetrics
        rightSection={
          <ContentCommentsCount
            contentId={activity.content.id}
            count={activity.content.numberOfComments}
          />
        }
      />
      <FeedItemActions>
        <CommentOnContent contentId={activity.content.id} />
      </FeedItemActions>
      {!!activity.comment && (
        <ActivityComment
          referenceId={activity.content.id}
          referenceType={CommentType.Content}
          comment={activity.comment}
          onDelete={onDelete}
        />
      )}
    </FeedItemContainer>
  );
}
