import { Box, Text, VStack } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/react';
import { useLastLocation } from '@tigerhall/core';
import { IconCircleCheckFilled } from '@tigerhall/icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function AccountCreationSuccess() {
  const [countdown, setCountdown] = useState(3);
  const { lastLocation } = useLastLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(interval);
          if (lastLocation) {
            navigate(lastLocation);
          } else {
            navigate('/home');
          }
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [lastLocation, navigate]);

  return (
    <VStack h="100%" justifyContent="center" spacing="1rem">
      <Box bg="white" width="3.375rem" height="3.375rem" borderRadius="full">
        <Icon as={IconCircleCheckFilled} color="green.500" w="100%" h="100%" />
      </Box>
      <Text color="white" fontSize="2rem" fontWeight="bold">
        Account Created
      </Text>
      <Text color="lightGrey.900" fontSize="1.25rem" fontWeight="bold">
        You will be redirected in {countdown} seconds
      </Text>
    </VStack>
  );
}
