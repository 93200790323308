import { Text, type FlexProps } from '@chakra-ui/react';

import {
  ThinkfluencerBadge,
  type ThinkfluencerBadgeProps
} from '~/user/components/ThinkfluencerBadge';

export interface ThinkfluencerBadgeWrapperProps {
  /**
   * Name of the user, usually wrapped in another component such as
   * `Text` or `LinkOverlay`
   */
  children: React.ReactNode;
  /**
   * Props of the thinkfluencer badge. If not defined, no thinkfluencer
   * badge will be shown.
   */
  badgeProps?: ThinkfluencerBadgeProps;
  /**
   * Gap between the name and the thinkfluencer badge.
   *
   * @default 2
   */
  spacing?: FlexProps['gap'];
}

/**
 * Use this component wherever you want to render the name of the user if
 * it is expected that the user is possibly a Thinkfluencer, in which case
 * this component will add the `ThinkfluencerBadge` in the right place.
 *
 * @example```tsx
 *   <ThinkfluencerBadgeWrapper {...args}>
 *     <Text noOfLines={1}>
 *       Donald Biden
 *     </Text>
 *   </ThinkfluencerBadgeWrapper>
 * ```
 */
export function ThinkfluencerBadgeWrapper({
  children,
  badgeProps,
  spacing = 2
}: Readonly<ThinkfluencerBadgeWrapperProps>) {
  /**
   * don't render the `ThinkfluencerBadge` if the thinkfluencer scope is
   * not defined.
   */
  if (!badgeProps?.scope) {
    return <>{children}</>;
  }

  /**
   * place the `ThinkfluencerBadge` under the name if `displayLabelAs`
   * equals is `text`.
   */
  const direction: FlexProps['direction'] =
    badgeProps.displayLabelAs === 'text' ? 'column' : 'row';

  /**
   * if the badge is next to the name (direction === row), center vertically.
   */
  const alignItems: FlexProps['alignItems'] =
    direction === 'row' ? 'center' : 'start';

  return (
    <Text
      as="span"
      display="inline-flex"
      gap={spacing}
      flexDirection={direction}
      alignItems={alignItems}
    >
      {children}
      <ThinkfluencerBadge {...badgeProps} />
    </Text>
  );
}
