import { useDisclosure } from '@chakra-ui/react';
import * as segment from '@tigerhall/analytics';
import { IconShare3 } from '@tigerhall/icons';
import { OptionsMenuItem } from 'components/ui/OptionsMenu';
import { ShareModal, useShareModal } from '@tigerhall/components';
import { captureException } from '@sentry/react';

export interface SharePostOptionProps {
  postId: string;
}

export function SharePostOption({ postId }: Readonly<SharePostOptionProps>) {
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: false
  });

  const urlToShare = `${import.meta.env.VITE_WEBAPP_URL}/feed/${postId}`;
  const titleToShare = 'See this post on Tigerhall';
  const descriptionToShare = '🐯';

  const { isAvailable, openShareModal } = useShareModal({
    urlToShare,
    title: titleToShare,
    description: descriptionToShare,
    onOpen
  });

  if (!isAvailable) {
    return null;
  }

  return (
    <>
      <OptionsMenuItem
        icon={IconShare3}
        title="Share"
        description="Share via another application"
        onClick={(event) => {
          segment.postOptionsClicked({
            referenceId: postId,
            buttonName: 'share_post'
          });

          openShareModal(event).catch((e) => {
            captureException(e, {
              tags: {
                postId
              }
            });
          });
        }}
      />
      <ShareModal
        isOpen={isOpen}
        onClose={onClose}
        heading="Share Post"
        titleToShare={titleToShare}
        urlToShare={urlToShare}
      />
    </>
  );
}
