import { type ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const Text: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: (props) => ({
    fontSize: 'xl',
    fontWeight: 'medium',
    // todo: this should be 1.5 but it will break many components,
    // including the content cards. This change has to be done
    // carefully in the near future.
    lineHeight: '1.375', // same as short
    letterSpacing: '0.015em',

    color: mode('darkGrey.400', 'lightGrey.900')(props)
  }),

  // styles for different sizes ("sm", "md", "lg")
  sizes: {},

  // styles for different visual variants ("outline", "solid")
  variants: {},

  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    size: '',
    variant: '',
    colorScheme: ''
  }
};
