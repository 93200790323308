import * as React from 'react';
import { RouteObject } from 'react-router-dom';
import { Splash } from 'components/ui/Layout/components';

import { routes as homeRoutes } from './modules/Home';
import { routes as profileRoutes } from './modules/Profile';
import { routes as contentRoutes } from './modules/Content';
import { routes as exploreRoutes } from './modules/Explore';
import { routes as organisationRoutes } from './modules/Organisation';
import { routes as feedRoutes } from './modules/Feed';

const Search = React.lazy(() =>
  import('./screens/Search').then((m) => ({ default: m.Search }))
);

const routes: RouteObject[] = [
  {
    path: '/search',
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<Splash />}>
            <Search />
          </React.Suspense>
        )
      }
    ]
  },
  ...homeRoutes,
  ...exploreRoutes,
  ...contentRoutes,
  ...profileRoutes,
  ...organisationRoutes,
  ...feedRoutes
];

export default routes;
