import {
  Icon,
  Spinner,
  type SpinnerProps,
  type StackProps,
  Text,
  VStack
} from '@chakra-ui/react';
import { IconAlertTriangleFilled } from '@tigerhall/icons';
import {
  type ForwardRefExoticComponent,
  type RefAttributes,
  type SVGAttributes
} from 'react';

interface TableBodyFallbackProps extends StackProps {
  heading?: string;
  subheading?: string;
  /**
   * Icon from `@tigerhall/icons` to display in the fallback.
   * Defaults to the `IconAlertTriangleFilled`.
   */
  icon?: ForwardRefExoticComponent<
    SVGAttributes<SVGElement> & RefAttributes<SVGSVGElement>
  >;
  isLoading?: boolean;
  spinnerColor?: SpinnerProps['color'];
}

/**
 * Fallback component to display when there is no data in the table.
 *
 * @todo: we should probably make this a generic component that can be used in other places (move to `@tigerhall/components`)
 */
export function TableBodyFallback({
  heading: customHeading,
  subheading: customSubheading,
  spinnerColor = 'lightGrey.900',
  icon,
  isLoading,
  children,
  ...rest
}: Readonly<TableBodyFallbackProps>) {
  const heading = customHeading || 'No Data Available';
  const subheading = customSubheading || 'Try changing the filter properties';

  return (
    <VStack spacing=".5rem" justifyContent="center" {...rest}>
      {isLoading ? (
        <Spinner width="3.25rem" height="3.25rem" color={spinnerColor} />
      ) : (
        <Icon
          as={icon || IconAlertTriangleFilled}
          width="3.25rem"
          height="3.25rem"
          color="lightGrey.900"
        />
      )}

      <Text fontSize="lg" fontWeight="bold" color="lightGrey.900">
        {heading}
      </Text>
      <Text fontSize="md" color="darkGrey.50">
        {subheading}
      </Text>
      {children}
    </VStack>
  );
}
