import { useNavigate } from 'react-router-dom';
import * as segment from '@tigerhall/analytics';
import { PrivacySettings } from '@tigerhall/core';
import { IconEdit } from '@tigerhall/icons';
import {
  resetPost,
  setParentPost,
  setParentPostPrivacySetting
} from 'app/state';
import { OptionsMenuItem } from 'components/ui/OptionsMenu';
import { useAppDispatch } from 'hooks';

export interface RepostWithThoughtsProps {
  postId: string;
  privacySetting: PrivacySettings;
}

export function RepostWithThoughts({
  postId,
  privacySetting
}: RepostWithThoughtsProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function startRepost() {
    segment.repostClicked({
      buttonName: 'REPOST_WITH_THOUGHTS'
    });

    dispatch(resetPost());
    dispatch(setParentPostPrivacySetting(privacySetting));
    dispatch(setParentPost(postId));
    navigate('/feed/create/');
  }

  return (
    <OptionsMenuItem
      icon={IconEdit}
      title="Repost With Your Thoughts"
      description="Create a new post with this post attached"
      onClick={startRepost}
    />
  );
}
