import { useEffect, useState } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import BannerImageIcon from 'assets/images/profileDefaultCover.jpg';

import { EditProfileImageUpload } from './EditProfileImageUpload';
import { useGalleryImageUpload } from '../../../../../../../hooks';

type EditProfileCoverProps = {
  profileImg: string | null;
  coverImg: string | null;
};

type ImagesURLState = {
  coverImageURL: string;
  profileImageURL: string;
};

export function EditProfileCover({
  profileImg,
  coverImg
}: EditProfileCoverProps) {
  const upload = useGalleryImageUpload();

  const [imageURLs, setImageURLs] = useState<ImagesURLState>({
    profileImageURL: '',
    coverImageURL: ''
  });

  useEffect(() => {
    if (profileImg) {
      setImageURLs((prev) => ({ ...prev, profileImageURL: profileImg }));
    }
    if (coverImg) {
      setImageURLs((prev) => ({ ...prev, coverImageURL: coverImg }));
    }
  }, [profileImg, coverImg]);

  return (
    <VStack w="680px" mx="auto" position="relative">
      <Box height="170px" maxHeight="170px" w="full">
        <EditProfileImageUpload
          upload={upload}
          imageUrl={imageURLs.coverImageURL || BannerImageIcon}
          cropSize={{
            height: 170,
            width: 678
          }}
          name="bannerImg"
          getUpdatedImageUrl={(imageUrl) =>
            setImageURLs((prev) => ({ ...prev, coverImageURL: imageUrl }))
          }
          width="678px"
          height="170px"
          dataCy="cover-image-upload-input"
        />
      </Box>
      <VStack
        alignItems="flex-start"
        px="2rem"
        w="full"
        position="absolute"
        top="7rem"
      >
        <EditProfileImageUpload
          upload={upload}
          imageUrl={imageURLs.profileImageURL}
          cropSize={{
            height: 140,
            width: 140
          }}
          name="profileImg"
          getUpdatedImageUrl={(imageUrl) =>
            setImageURLs((prev) => ({ ...prev, profileImageURL: imageUrl }))
          }
          borderRadius="full"
          boxSize="140px"
          circularCrop
          dataCy="profile-image-upload-input"
        />
      </VStack>
    </VStack>
  );
}
