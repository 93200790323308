import * as React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';

import { SidebarMenuListItem } from './SidebarMenuListItem';
import { type MenuSectionType } from '../utils';

interface SidebarMenuItemProps {
  section: MenuSectionType;
}

export function SidebarMenuSection({ section }: SidebarMenuItemProps) {
  const textColor = useColorModeValue('darkGrey.700', 'white');

  return (
    <Flex direction="column" py="0.5rem">
      {section.label ? (
        <Text
          as="span"
          fontSize="xs"
          fontWeight="bold"
          textTransform="uppercase"
          whiteSpace="nowrap"
          noOfLines={1}
          color={textColor}
          px="1rem"
          py="0.75rem"
        >
          {section.label}
        </Text>
      ) : null}

      {section.children.map((child) => (
        <SidebarMenuListItem key={child.route} item={child} />
      ))}
    </Flex>
  );
}
