import { resizeImage } from '@tigerhall/core';
import { Image } from '@chakra-ui/react';

interface BgImageProps {
  url: string;
  alt?: string;
}

/**
 * Optimized background image for the podcast player.
 */
export function BgImage({ url, alt }: BgImageProps) {
  return (
    <picture>
      <source
        media="(max-width: 480px)"
        srcSet={`${resizeImage({
          url,
          width: 480
        })} 1x, ${resizeImage({
          url,
          width: 768
        })} 2x`}
      />
      <source
        media="(max-width: 768px)"
        srcSet={`${resizeImage({
          url,
          width: 768
        })} 1x, ${resizeImage({
          url,
          width: 1280
        })} 2x`}
      />
      <source
        media="(max-width: 1280px)"
        srcSet={`${resizeImage({
          url,
          width: 1280
        })} 1x, ${resizeImage({
          url,
          width: 1920
        })} 2x`}
      />
      <Image
        src={resizeImage({
          url,
          width: 1920
        })}
        alt={alt ?? ''}
        objectFit="cover"
        width="100%"
        height="100%"
        position="absolute"
        inset={0}
        filter="auto"
        brightness="45%"
      />
    </picture>
  );
}
