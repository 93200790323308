import { Center } from '@chakra-ui/react';
import {
  CommentWithReplies,
  type CommentWithRepliesProps
} from 'components/ui/Comments/CommentWithReplies';

export interface ActivityCommentProps
  extends Pick<
    CommentWithRepliesProps,
    'referenceId' | 'referenceType' | 'onDelete'
  > {
  comment: CommentWithRepliesProps['comment'] & {
    parent?: CommentWithRepliesProps['comment'] | null;
  };
}

export function ActivityComment({
  referenceId,
  referenceType,
  comment,
  onDelete
}: Readonly<ActivityCommentProps>) {
  const hasParent = !!comment?.parent?.id;

  return (
    <Center
      py="1.25rem"
      px={{
        base: '1rem',
        md: '2rem'
      }}
      borderTop="1px solid"
      borderTopColor="darkGrey.400"
    >
      <CommentWithReplies
        referenceId={referenceId}
        referenceType={referenceType}
        comment={hasParent ? comment.parent! : comment}
        parentView="activity-comment"
        onDelete={onDelete}
      />
    </Center>
  );
}
