import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLearningPathInfoByIdQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;


export type GetLearningPathInfoByIdQuery = { __typename?: 'Query', learningPath?: { __typename?: 'LearningPath', id: string, name: string, preamble: string, createdAt: any, updatedAt: any, categories: Array<{ __typename?: 'Category', id: string, name: string }>, contentCards: Array<{ __typename?: 'Ebook', id: string, name: string, preamble: string, readingTime?: number | null, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, company?: string | null, title: string, isAuthenticatedUserFollowing: boolean }> } | { __typename?: 'Event', id: string, name: string, preamble: string, startsAt?: any | null, endsAt?: any | null, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, company?: string | null, title: string, isAuthenticatedUserFollowing: boolean }> } | { __typename?: 'Expert' } | { __typename?: 'LearningPath' } | { __typename?: 'Podcast', id: string, name: string, preamble: string, length: number, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, company?: string | null, title: string, isAuthenticatedUserFollowing: boolean }> } | { __typename?: 'Stream', id: string, name: string, preamble: string, length: number, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, company?: string | null, title: string, isAuthenticatedUserFollowing: boolean }> }> } | null };

export type LearningPathInfoFragment = { __typename?: 'LearningPath', id: string, name: string, preamble: string, createdAt: any, updatedAt: any, categories: Array<{ __typename?: 'Category', id: string, name: string }>, contentCards: Array<{ __typename?: 'Ebook', id: string, name: string, preamble: string, readingTime?: number | null, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, company?: string | null, title: string, isAuthenticatedUserFollowing: boolean }> } | { __typename?: 'Event', id: string, name: string, preamble: string, startsAt?: any | null, endsAt?: any | null, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, company?: string | null, title: string, isAuthenticatedUserFollowing: boolean }> } | { __typename?: 'Expert' } | { __typename?: 'LearningPath' } | { __typename?: 'Podcast', id: string, name: string, preamble: string, length: number, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, company?: string | null, title: string, isAuthenticatedUserFollowing: boolean }> } | { __typename?: 'Stream', id: string, name: string, preamble: string, length: number, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, company?: string | null, title: string, isAuthenticatedUserFollowing: boolean }> }> };

export type ExpertBasicInfoFragment = { __typename?: 'Expert', id: string, firstName: string, lastName: string, company?: string | null, title: string, isAuthenticatedUserFollowing: boolean };

export type ContentCardBasicInfo_Ebook_Fragment = { __typename?: 'Ebook', id: string, name: string, preamble: string, readingTime?: number | null, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, company?: string | null, title: string, isAuthenticatedUserFollowing: boolean }> };

export type ContentCardBasicInfo_Event_Fragment = { __typename?: 'Event', id: string, name: string, preamble: string, startsAt?: any | null, endsAt?: any | null, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, company?: string | null, title: string, isAuthenticatedUserFollowing: boolean }> };

export type ContentCardBasicInfo_Expert_Fragment = { __typename?: 'Expert' };

export type ContentCardBasicInfo_LearningPath_Fragment = { __typename?: 'LearningPath' };

export type ContentCardBasicInfo_Podcast_Fragment = { __typename?: 'Podcast', id: string, name: string, preamble: string, length: number, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, company?: string | null, title: string, isAuthenticatedUserFollowing: boolean }> };

export type ContentCardBasicInfo_Stream_Fragment = { __typename?: 'Stream', id: string, name: string, preamble: string, length: number, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, company?: string | null, title: string, isAuthenticatedUserFollowing: boolean }> };

export type ContentCardBasicInfoFragment = ContentCardBasicInfo_Ebook_Fragment | ContentCardBasicInfo_Event_Fragment | ContentCardBasicInfo_Expert_Fragment | ContentCardBasicInfo_LearningPath_Fragment | ContentCardBasicInfo_Podcast_Fragment | ContentCardBasicInfo_Stream_Fragment;

export const ExpertBasicInfoFragmentDoc = gql`
    fragment ExpertBasicInfo on Expert {
  id
  firstName
  lastName
  company
  title
  isAuthenticatedUserFollowing
}
    `;
export const ContentCardBasicInfoFragmentDoc = gql`
    fragment ContentCardBasicInfo on ContentCard {
  ... on Podcast {
    id
    name
    preamble
    length
    experts {
      ...ExpertBasicInfo
    }
  }
  ... on Ebook {
    id
    name
    preamble
    readingTime
    experts {
      ...ExpertBasicInfo
    }
  }
  ... on Event {
    id
    name
    preamble
    startsAt
    endsAt
    experts {
      ...ExpertBasicInfo
    }
  }
  ... on Stream {
    id
    name
    preamble
    length
    experts {
      ...ExpertBasicInfo
    }
  }
}
    ${ExpertBasicInfoFragmentDoc}`;
export const LearningPathInfoFragmentDoc = gql`
    fragment LearningPathInfo on LearningPath {
  id
  name
  preamble
  createdAt
  updatedAt
  categories {
    id
    name
  }
  contentCards {
    ...ContentCardBasicInfo
  }
}
    ${ContentCardBasicInfoFragmentDoc}`;
export const GetLearningPathInfoByIdDocument = gql`
    query GetLearningPathInfoById($id: String!) {
  learningPath(id: $id) {
    ...LearningPathInfo
  }
}
    ${LearningPathInfoFragmentDoc}`;

/**
 * __useGetLearningPathInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetLearningPathInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningPathInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningPathInfoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLearningPathInfoByIdQuery(baseOptions: Apollo.QueryHookOptions<GetLearningPathInfoByIdQuery, GetLearningPathInfoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLearningPathInfoByIdQuery, GetLearningPathInfoByIdQueryVariables>(GetLearningPathInfoByIdDocument, options);
      }
export function useGetLearningPathInfoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLearningPathInfoByIdQuery, GetLearningPathInfoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLearningPathInfoByIdQuery, GetLearningPathInfoByIdQueryVariables>(GetLearningPathInfoByIdDocument, options);
        }
export type GetLearningPathInfoByIdQueryHookResult = ReturnType<typeof useGetLearningPathInfoByIdQuery>;
export type GetLearningPathInfoByIdLazyQueryHookResult = ReturnType<typeof useGetLearningPathInfoByIdLazyQuery>;
export type GetLearningPathInfoByIdQueryResult = Apollo.QueryResult<GetLearningPathInfoByIdQuery, GetLearningPathInfoByIdQueryVariables>;