import { ErrorBoundary } from 'components/tools/ErrorBoundary';
import { Splash } from 'components/ui/Layout/components';
import * as React from 'react';
import { RouteObject } from 'react-router-dom';

const UploadCompleted = React.lazy(() =>
  import('./screens/PodcastForm/components/Form/components').then((module) => ({
    default: module.UploadCompleted
  }))
);

const PodcastHistory = React.lazy(() =>
  import('./screens/PodcastHistory').then((module) => ({
    default: module.default
  }))
);

const PodcastSummary = React.lazy(() =>
  import('./screens/PodcastSummary').then((module) => ({
    default: module.default
  }))
);

const PodcastForm = React.lazy(() =>
  import('./screens/PodcastForm').then((module) => ({
    default: module.PodcastForm
  }))
);

const routes: RouteObject[] = [
  {
    path: 'podcasts/',
    element: (
      <React.Suspense fallback={<Splash />}>
        <PodcastHistory />
      </React.Suspense>
    ),
    children: [
      {
        path: ':id/summary',
        element: (
          <React.Suspense fallback={<Splash />}>
            <PodcastSummary />
          </React.Suspense>
        )
      }
    ]
  },
  {
    path: 'podcasts/:idOrDraft/form',
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<Splash />}>
          <PodcastForm />
        </React.Suspense>
      </ErrorBoundary>
    )
  },
  {
    path: 'podcasts/:id/success',
    element: (
      <React.Suspense fallback={<Splash />}>
        <UploadCompleted />
      </React.Suspense>
    )
  }
];

export default routes;
