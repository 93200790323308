import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import { FullGroupFragmentDoc } from '../fragments/generated/group.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganisationFragment = { __typename: 'Organisation', id: string, name: string, type: SchemaTypes.OrganisationCustomerType, activeUsers: number, createdAt: any, updatedAt: any };

export type GetOrganisationByIdQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;


export type GetOrganisationByIdQuery = { __typename?: 'Query', organisation: { __typename: 'Organisation', id: string, name: string, type: SchemaTypes.OrganisationCustomerType, activeUsers: number, createdAt: any, updatedAt: any, subscription?: { __typename?: 'SubscriptionModel', id: string, billingPeriod?: string | null, currentPeriodEnd?: any | null, cancelAtPeriodEnd: boolean, paidAt?: any | null, customerId?: string | null, status: string, trialUntil?: any | null, createdAt: any, updatedAt: any } | null, groups: Array<{ __typename?: 'OrganisationGroup', id: string, name: string }>, members: { __typename?: 'UserCollection', meta: { __typename?: 'PageInfo', total: number } } } };

export type GetOrganisationGroupsQueryVariables = SchemaTypes.Exact<{
  orgId: SchemaTypes.Scalars['String'];
}>;


export type GetOrganisationGroupsQuery = { __typename?: 'Query', organisation: { __typename?: 'Organisation', id: string, groups: Array<{ __typename?: 'OrganisationGroup', id: string, name: string, numberOfMembers: number, assignments?: Array<{ __typename?: 'Assignment', id: string, assignmentTitle: string }> | null }> } };

export type GetOrganisationMembersQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
  filter?: SchemaTypes.InputMaybe<SchemaTypes.OrganisationMemberFilter>;
  sorting?: SchemaTypes.InputMaybe<SchemaTypes.SortOrdering>;
}>;


export type GetOrganisationMembersQuery = { __typename?: 'Query', organisation: { __typename: 'Organisation', id: string, members: { __typename?: 'UserCollection', edges: Array<{ __typename?: 'User', id: string, firstName: string, lastName: string, email: string, roles: Array<string>, status: SchemaTypes.OrgMemberStatus, lastOnlineAt?: any | null, archivedAt?: any | null, statsContentConsumedTotal: number, subscriptionStatus: SchemaTypes.SubscriptionStatus, organisationGroups?: Array<{ __typename?: 'OrganisationGroup', id: string, name: string }> | null }>, meta: { __typename?: 'PageInfo', total: number, offset: number, limit: number } } } };

export type GetOrganisationGroupsWithFilterQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
  filter: SchemaTypes.OrganisationGroupFilter;
  sorting?: SchemaTypes.InputMaybe<SchemaTypes.SortOrdering>;
}>;


export type GetOrganisationGroupsWithFilterQuery = { __typename?: 'Query', organisation: { __typename: 'Organisation', id: string, orgGroups: { __typename?: 'OrganisationGroupConnection', edges: Array<{ __typename: 'OrganisationGroup', createdAt: any, updatedAt: any, id: string, name: string, numberOfMembers: number, createdBy?: { __typename?: 'User', id: string, firstName: string, lastName: string } | null, audience?: { __typename?: 'Audience', id: string } | null }>, meta: { __typename?: 'PageInfo', total: number, offset: number, limit: number } } } };

export type GetAllOrganisationsQueryVariables = SchemaTypes.Exact<{
  filter?: SchemaTypes.InputMaybe<SchemaTypes.OrganisationFilter>;
  sorting?: SchemaTypes.InputMaybe<SchemaTypes.SortOrdering>;
}>;


export type GetAllOrganisationsQuery = { __typename?: 'Query', organisations: { __typename?: 'OrganisationConnection', meta: { __typename?: 'PageInfo', total: number }, edges: Array<{ __typename?: 'Organisation', id: string, name: string, type: SchemaTypes.OrganisationCustomerType }> } };

export type OrganisationRemoveUserMutationVariables = SchemaTypes.Exact<{
  userId: SchemaTypes.Scalars['String'];
  organisationId: SchemaTypes.Scalars['String'];
}>;


export type OrganisationRemoveUserMutation = { __typename?: 'Mutation', organisationRemoveUser?: { __typename?: 'NoContent', success: boolean } | null };

export type OrganisationAddUserMutationVariables = SchemaTypes.Exact<{
  userId: SchemaTypes.Scalars['String'];
  organisationId: SchemaTypes.Scalars['String'];
  role: SchemaTypes.OrganisationRole;
}>;


export type OrganisationAddUserMutation = { __typename?: 'Mutation', organisationAddUser?: { __typename?: 'NoContent', success: boolean } | null };

export type OrganisationAddUsersMutationVariables = SchemaTypes.Exact<{
  organisationId: SchemaTypes.Scalars['String'];
  groupId?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['String']>;
  users: Array<SchemaTypes.OrganisationUserImport> | SchemaTypes.OrganisationUserImport;
}>;


export type OrganisationAddUsersMutation = { __typename?: 'Mutation', organisationImportUsers?: Array<{ __typename?: 'OrganisationUserImportResponse', firstName?: string | null, lastName?: string | null, email: string, error: string }> | null };

export type OrganisationJoinWithInviteLinkMutationVariables = SchemaTypes.Exact<{
  token: SchemaTypes.Scalars['ID'];
  user?: SchemaTypes.InputMaybe<SchemaTypes.CreateUserInput>;
}>;


export type OrganisationJoinWithInviteLinkMutation = { __typename?: 'Mutation', organisationJoinViaInviteLink: boolean };

export type ReauthorizeWithOrgMutationVariables = SchemaTypes.Exact<{
  orgId: SchemaTypes.Scalars['ID'];
}>;


export type ReauthorizeWithOrgMutation = { __typename?: 'Mutation', reauthorizeWithOrg: { __typename?: 'AuthenticationResponse', jwt: string } };

export const OrganisationFragmentDoc = gql`
    fragment organisation on Organisation {
  __typename
  id
  name
  type
  activeUsers
  createdAt
  updatedAt
}
    `;
export const GetOrganisationByIdDocument = gql`
    query getOrganisationById($id: String!) {
  organisation(id: $id) {
    ...organisation
    subscription {
      id
      billingPeriod
      currentPeriodEnd
      cancelAtPeriodEnd
      paidAt
      customerId
      status
      trialUntil
      createdAt
      updatedAt
    }
    groups {
      id
      name
    }
    members {
      meta {
        total
      }
    }
  }
}
    ${OrganisationFragmentDoc}`;

/**
 * __useGetOrganisationByIdQuery__
 *
 * To run a query within a React component, call `useGetOrganisationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganisationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrganisationByIdQuery, GetOrganisationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationByIdQuery, GetOrganisationByIdQueryVariables>(GetOrganisationByIdDocument, options);
      }
export function useGetOrganisationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationByIdQuery, GetOrganisationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationByIdQuery, GetOrganisationByIdQueryVariables>(GetOrganisationByIdDocument, options);
        }
export type GetOrganisationByIdQueryHookResult = ReturnType<typeof useGetOrganisationByIdQuery>;
export type GetOrganisationByIdLazyQueryHookResult = ReturnType<typeof useGetOrganisationByIdLazyQuery>;
export type GetOrganisationByIdQueryResult = Apollo.QueryResult<GetOrganisationByIdQuery, GetOrganisationByIdQueryVariables>;
export const GetOrganisationGroupsDocument = gql`
    query getOrganisationGroups($orgId: String!) {
  organisation(id: $orgId) {
    id
    groups {
      id
      name
      numberOfMembers
      assignments {
        id
        assignmentTitle
      }
    }
  }
}
    `;

/**
 * __useGetOrganisationGroupsQuery__
 *
 * To run a query within a React component, call `useGetOrganisationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationGroupsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetOrganisationGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganisationGroupsQuery, GetOrganisationGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationGroupsQuery, GetOrganisationGroupsQueryVariables>(GetOrganisationGroupsDocument, options);
      }
export function useGetOrganisationGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationGroupsQuery, GetOrganisationGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationGroupsQuery, GetOrganisationGroupsQueryVariables>(GetOrganisationGroupsDocument, options);
        }
export type GetOrganisationGroupsQueryHookResult = ReturnType<typeof useGetOrganisationGroupsQuery>;
export type GetOrganisationGroupsLazyQueryHookResult = ReturnType<typeof useGetOrganisationGroupsLazyQuery>;
export type GetOrganisationGroupsQueryResult = Apollo.QueryResult<GetOrganisationGroupsQuery, GetOrganisationGroupsQueryVariables>;
export const GetOrganisationMembersDocument = gql`
    query getOrganisationMembers($id: String!, $filter: OrganisationMemberFilter, $sorting: SortOrdering) {
  organisation(id: $id) {
    __typename
    id
    members(filter: $filter, sorting: $sorting) {
      edges {
        id
        firstName
        lastName
        email
        roles
        status
        lastOnlineAt
        archivedAt
        statsContentConsumedTotal
        subscriptionStatus
        organisationGroups {
          id
          name
        }
      }
      meta {
        total
        offset
        limit
      }
    }
  }
}
    `;

/**
 * __useGetOrganisationMembersQuery__
 *
 * To run a query within a React component, call `useGetOrganisationMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetOrganisationMembersQuery(baseOptions: Apollo.QueryHookOptions<GetOrganisationMembersQuery, GetOrganisationMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationMembersQuery, GetOrganisationMembersQueryVariables>(GetOrganisationMembersDocument, options);
      }
export function useGetOrganisationMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationMembersQuery, GetOrganisationMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationMembersQuery, GetOrganisationMembersQueryVariables>(GetOrganisationMembersDocument, options);
        }
export type GetOrganisationMembersQueryHookResult = ReturnType<typeof useGetOrganisationMembersQuery>;
export type GetOrganisationMembersLazyQueryHookResult = ReturnType<typeof useGetOrganisationMembersLazyQuery>;
export type GetOrganisationMembersQueryResult = Apollo.QueryResult<GetOrganisationMembersQuery, GetOrganisationMembersQueryVariables>;
export const GetOrganisationGroupsWithFilterDocument = gql`
    query GetOrganisationGroupsWithFilter($id: String!, $filter: OrganisationGroupFilter!, $sorting: SortOrdering) {
  organisation(id: $id) {
    __typename
    id
    orgGroups(filter: $filter, sorting: $sorting) {
      edges {
        ...FullGroup
      }
      meta {
        total
        offset
        limit
      }
    }
  }
}
    ${FullGroupFragmentDoc}`;

/**
 * __useGetOrganisationGroupsWithFilterQuery__
 *
 * To run a query within a React component, call `useGetOrganisationGroupsWithFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationGroupsWithFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationGroupsWithFilterQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetOrganisationGroupsWithFilterQuery(baseOptions: Apollo.QueryHookOptions<GetOrganisationGroupsWithFilterQuery, GetOrganisationGroupsWithFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganisationGroupsWithFilterQuery, GetOrganisationGroupsWithFilterQueryVariables>(GetOrganisationGroupsWithFilterDocument, options);
      }
export function useGetOrganisationGroupsWithFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationGroupsWithFilterQuery, GetOrganisationGroupsWithFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganisationGroupsWithFilterQuery, GetOrganisationGroupsWithFilterQueryVariables>(GetOrganisationGroupsWithFilterDocument, options);
        }
export type GetOrganisationGroupsWithFilterQueryHookResult = ReturnType<typeof useGetOrganisationGroupsWithFilterQuery>;
export type GetOrganisationGroupsWithFilterLazyQueryHookResult = ReturnType<typeof useGetOrganisationGroupsWithFilterLazyQuery>;
export type GetOrganisationGroupsWithFilterQueryResult = Apollo.QueryResult<GetOrganisationGroupsWithFilterQuery, GetOrganisationGroupsWithFilterQueryVariables>;
export const GetAllOrganisationsDocument = gql`
    query getAllOrganisations($filter: OrganisationFilter, $sorting: SortOrdering) {
  organisations(filter: $filter, sorting: $sorting) {
    meta {
      total
    }
    edges {
      id
      name
      type
    }
  }
}
    `;

/**
 * __useGetAllOrganisationsQuery__
 *
 * To run a query within a React component, call `useGetAllOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrganisationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetAllOrganisationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllOrganisationsQuery, GetAllOrganisationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOrganisationsQuery, GetAllOrganisationsQueryVariables>(GetAllOrganisationsDocument, options);
      }
export function useGetAllOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOrganisationsQuery, GetAllOrganisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOrganisationsQuery, GetAllOrganisationsQueryVariables>(GetAllOrganisationsDocument, options);
        }
export type GetAllOrganisationsQueryHookResult = ReturnType<typeof useGetAllOrganisationsQuery>;
export type GetAllOrganisationsLazyQueryHookResult = ReturnType<typeof useGetAllOrganisationsLazyQuery>;
export type GetAllOrganisationsQueryResult = Apollo.QueryResult<GetAllOrganisationsQuery, GetAllOrganisationsQueryVariables>;
export const OrganisationRemoveUserDocument = gql`
    mutation organisationRemoveUser($userId: String!, $organisationId: String!) {
  organisationRemoveUser(userId: $userId, organisationId: $organisationId) {
    success
  }
}
    `;
export type OrganisationRemoveUserMutationFn = Apollo.MutationFunction<OrganisationRemoveUserMutation, OrganisationRemoveUserMutationVariables>;

/**
 * __useOrganisationRemoveUserMutation__
 *
 * To run a mutation, you first call `useOrganisationRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationRemoveUserMutation, { data, loading, error }] = useOrganisationRemoveUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useOrganisationRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationRemoveUserMutation, OrganisationRemoveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationRemoveUserMutation, OrganisationRemoveUserMutationVariables>(OrganisationRemoveUserDocument, options);
      }
export type OrganisationRemoveUserMutationHookResult = ReturnType<typeof useOrganisationRemoveUserMutation>;
export type OrganisationRemoveUserMutationResult = Apollo.MutationResult<OrganisationRemoveUserMutation>;
export type OrganisationRemoveUserMutationOptions = Apollo.BaseMutationOptions<OrganisationRemoveUserMutation, OrganisationRemoveUserMutationVariables>;
export const OrganisationAddUserDocument = gql`
    mutation organisationAddUser($userId: String!, $organisationId: String!, $role: OrganisationRole!) {
  organisationAddUser(
    userId: $userId
    organisationId: $organisationId
    role: $role
  ) {
    success
  }
}
    `;
export type OrganisationAddUserMutationFn = Apollo.MutationFunction<OrganisationAddUserMutation, OrganisationAddUserMutationVariables>;

/**
 * __useOrganisationAddUserMutation__
 *
 * To run a mutation, you first call `useOrganisationAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationAddUserMutation, { data, loading, error }] = useOrganisationAddUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organisationId: // value for 'organisationId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useOrganisationAddUserMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationAddUserMutation, OrganisationAddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationAddUserMutation, OrganisationAddUserMutationVariables>(OrganisationAddUserDocument, options);
      }
export type OrganisationAddUserMutationHookResult = ReturnType<typeof useOrganisationAddUserMutation>;
export type OrganisationAddUserMutationResult = Apollo.MutationResult<OrganisationAddUserMutation>;
export type OrganisationAddUserMutationOptions = Apollo.BaseMutationOptions<OrganisationAddUserMutation, OrganisationAddUserMutationVariables>;
export const OrganisationAddUsersDocument = gql`
    mutation organisationAddUsers($organisationId: String!, $groupId: String, $users: [OrganisationUserImport!]!) {
  organisationImportUsers(
    orgId: $organisationId
    groupId: $groupId
    users: $users
  ) {
    firstName
    lastName
    email
    error
  }
}
    `;
export type OrganisationAddUsersMutationFn = Apollo.MutationFunction<OrganisationAddUsersMutation, OrganisationAddUsersMutationVariables>;

/**
 * __useOrganisationAddUsersMutation__
 *
 * To run a mutation, you first call `useOrganisationAddUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationAddUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationAddUsersMutation, { data, loading, error }] = useOrganisationAddUsersMutation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      groupId: // value for 'groupId'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useOrganisationAddUsersMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationAddUsersMutation, OrganisationAddUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationAddUsersMutation, OrganisationAddUsersMutationVariables>(OrganisationAddUsersDocument, options);
      }
export type OrganisationAddUsersMutationHookResult = ReturnType<typeof useOrganisationAddUsersMutation>;
export type OrganisationAddUsersMutationResult = Apollo.MutationResult<OrganisationAddUsersMutation>;
export type OrganisationAddUsersMutationOptions = Apollo.BaseMutationOptions<OrganisationAddUsersMutation, OrganisationAddUsersMutationVariables>;
export const OrganisationJoinWithInviteLinkDocument = gql`
    mutation organisationJoinWithInviteLink($token: ID!, $user: CreateUserInput) {
  organisationJoinViaInviteLink(token: $token, user: $user)
}
    `;
export type OrganisationJoinWithInviteLinkMutationFn = Apollo.MutationFunction<OrganisationJoinWithInviteLinkMutation, OrganisationJoinWithInviteLinkMutationVariables>;

/**
 * __useOrganisationJoinWithInviteLinkMutation__
 *
 * To run a mutation, you first call `useOrganisationJoinWithInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganisationJoinWithInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organisationJoinWithInviteLinkMutation, { data, loading, error }] = useOrganisationJoinWithInviteLinkMutation({
 *   variables: {
 *      token: // value for 'token'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useOrganisationJoinWithInviteLinkMutation(baseOptions?: Apollo.MutationHookOptions<OrganisationJoinWithInviteLinkMutation, OrganisationJoinWithInviteLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganisationJoinWithInviteLinkMutation, OrganisationJoinWithInviteLinkMutationVariables>(OrganisationJoinWithInviteLinkDocument, options);
      }
export type OrganisationJoinWithInviteLinkMutationHookResult = ReturnType<typeof useOrganisationJoinWithInviteLinkMutation>;
export type OrganisationJoinWithInviteLinkMutationResult = Apollo.MutationResult<OrganisationJoinWithInviteLinkMutation>;
export type OrganisationJoinWithInviteLinkMutationOptions = Apollo.BaseMutationOptions<OrganisationJoinWithInviteLinkMutation, OrganisationJoinWithInviteLinkMutationVariables>;
export const ReauthorizeWithOrgDocument = gql`
    mutation reauthorizeWithOrg($orgId: ID!) {
  reauthorizeWithOrg(id: $orgId) {
    jwt
  }
}
    `;
export type ReauthorizeWithOrgMutationFn = Apollo.MutationFunction<ReauthorizeWithOrgMutation, ReauthorizeWithOrgMutationVariables>;

/**
 * __useReauthorizeWithOrgMutation__
 *
 * To run a mutation, you first call `useReauthorizeWithOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReauthorizeWithOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reauthorizeWithOrgMutation, { data, loading, error }] = useReauthorizeWithOrgMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useReauthorizeWithOrgMutation(baseOptions?: Apollo.MutationHookOptions<ReauthorizeWithOrgMutation, ReauthorizeWithOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReauthorizeWithOrgMutation, ReauthorizeWithOrgMutationVariables>(ReauthorizeWithOrgDocument, options);
      }
export type ReauthorizeWithOrgMutationHookResult = ReturnType<typeof useReauthorizeWithOrgMutation>;
export type ReauthorizeWithOrgMutationResult = Apollo.MutationResult<ReauthorizeWithOrgMutation>;
export type ReauthorizeWithOrgMutationOptions = Apollo.BaseMutationOptions<ReauthorizeWithOrgMutation, ReauthorizeWithOrgMutationVariables>;