import * as React from 'react';
import {
  Center,
  HStack,
  Icon,
  SlideFade,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react';
import { TrackedButton } from '@tigerhall/components';
import { ActionModal } from 'components/ui/modals';
import { IconBrandTigerhallVertical } from '@tigerhall/icons';

interface SplashProps {
  timeout?: number;
}

export function Splash({ timeout = 5000 }: SplashProps) {
  const [didTimeout, setDidTimeout] = React.useState(false);
  const [shouldOpenConfirmationModal, setShouldOpenConfirmationModal] =
    React.useState(false);

  React.useEffect(() => {
    const splashTimeout = setTimeout(() => {
      setDidTimeout(true);
    }, timeout);

    return () => {
      clearTimeout(splashTimeout);
    };
  }, [timeout]);

  return (
    <Center background="darkGrey.700" width="100%" height="100vh">
      <VStack spacing="2rem">
        <Icon
          as={IconBrandTigerhallVertical}
          width={64}
          height={44}
          color="tigerOrange.600"
        />
        <Spinner
          thickness="4px"
          emptyColor="whiteAlpha.200"
          color="tigerOrange.600"
          size="lg"
        />
        <SlideFade in={didTimeout} offsetY="20px">
          <VStack spacing="1rem" role="alert">
            <Text color="lightGrey.600" fontSize="md">
              This is taking longer than usual. Maybe try one of these options?
            </Text>
            <HStack spacing="1rem">
              <TrackedButton
                name="TIMEOUT_REFRESH"
                onClick={() => window.location.reload()}
              >
                Refresh Page
              </TrackedButton>
              <TrackedButton
                name="TIMEOUT_CLEAR_DATA"
                variant="outline"
                onClick={() => {
                  setShouldOpenConfirmationModal(true);
                }}
              >
                Clear Cache & Refresh
              </TrackedButton>
            </HStack>
          </VStack>
        </SlideFade>
      </VStack>
      <ActionModal
        type="warning"
        title="Clear Cache & Refresh"
        description="You will be logged out of the app. Are you sure you want to clear your cache and refresh?"
        isOpen={shouldOpenConfirmationModal}
        onClose={() => {
          setShouldOpenConfirmationModal(false);
        }}
        primaryButtonOnClick={() => {
          window.localStorage.clear();
          window.location.reload();
        }}
        primaryButtonText="Continue"
        secondaryButtonOnClick={() => {
          setShouldOpenConfirmationModal(false);
        }}
        secondaryButtonText="Cancel"
      />
    </Center>
  );
}
