import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';
import * as segment from '@tigerhall/analytics';
import { compactNumber } from '@tigerhall/core';
import type { User } from '@tigerhall/core/lib/types';
import { useGetFollowersQuery, useGetFollowingsQuery } from 'generated';

import { LAYOUT_HEIGHT_WITHOUT_HEADER } from '~/components/ui/Layout';
import { HeaderBox } from '../../components/common/HeaderBox';
import {
  type ActiveStatusType,
  FollowerFollowingPanel
} from '../../components/FollowerFollowingPanel';

interface LocationState {
  isFollowers: boolean;
  isCurrentUser: boolean;
}

export function Followers() {
  const location = useLocation();

  const { profileId } = useParams<{ profileId: string }>();

  const { isFollowers, isCurrentUser } =
    (location?.state as LocationState) || {};

  const [activeStatus, setActiveStatus] = useState<ActiveStatusType>({
    tabIndex: isFollowers ? 0 : 1,
    followersSearchValue: '',
    followingsSearchValue: ''
  });

  const { data: followersData, loading: isLoadingFollowersData } =
    useGetFollowersQuery({
      variables: {
        id: profileId || '',
        filter: {
          limit: 100,
          offset: 0,
          query: activeStatus.followersSearchValue
        }
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !profileId
    });

  const { data: followingsData, loading: isLoadingFollowingsData } =
    useGetFollowingsQuery({
      variables: {
        id: profileId || '',
        filter: {
          limit: 100,
          offset: 0,
          query: activeStatus.followingsSearchValue
        }
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !profileId
    });

  const { followers, firstName, lastName } = followersData?.user || {};
  const { followings } = followingsData?.user || {};
  const currentTab = activeStatus?.tabIndex === 0 ? 'follower' : 'following';

  const followersTotal = compactNumber(followers?.meta.total ?? 0);
  const follwingTotal = compactNumber(followings?.meta.total ?? 0);

  return (
    <>
      <Helmet>
        <title>Follower/Following</title>
      </Helmet>
      <Box flex={1} bg="darkGrey.700">
        <VStack
          maxW="48rem"
          w="100%"
          mx="auto"
          h={LAYOUT_HEIGHT_WITHOUT_HEADER}
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}
          background="darkGrey.700"
          borderColor="darkGrey.200"
          borderWidth={{
            base: 0,
            md: '0.5px'
          }}
          mt={{
            base: 0,
            md: '2rem'
          }}
          borderRadius="2xl"
        >
          <HeaderBox
            headingColor="lightGrey.200"
            title={`${firstName || ''} ${lastName || ''}`}
          >
            <Tabs
              w="100%"
              flex={1}
              isFitted
              defaultIndex={isFollowers ? 0 : 1}
              onChange={(activeIndex) => {
                segment.tabClicked({
                  tabId: activeIndex === 0 ? 'followers' : 'following',
                  tabName: activeIndex === 0 ? 'FOLLOWERS' : 'FOLLOWING'
                });
                setActiveStatus((prev) => ({ ...prev, tabIndex: activeIndex }));
              }}
            >
              <TabList>
                <Tab>{followersTotal} Followers</Tab>
                <Tab>{follwingTotal || 0} Following</Tab>
              </TabList>
              <TabPanels
                backgroundColor="darkGrey.700"
                flex={1}
                px={{ base: 0, md: '1rem' }}
              >
                <TabPanel>
                  <FollowerFollowingPanel
                    loading={isLoadingFollowersData}
                    currentList={(followers?.edges || []) as User[]}
                    followCount={`${followersTotal} Followers`}
                    currentTab={currentTab}
                    isCurrentUser={isCurrentUser}
                    setActiveStatus={setActiveStatus}
                  />
                </TabPanel>
                <TabPanel>
                  <FollowerFollowingPanel
                    loading={isLoadingFollowingsData}
                    currentList={(followings?.edges || []) as User[]}
                    followCount={`Following ${follwingTotal} people`}
                    currentTab={currentTab}
                    isCurrentUser={isCurrentUser}
                    setActiveStatus={setActiveStatus}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </HeaderBox>
        </VStack>
      </Box>
    </>
  );
}
