import { TrackedLink } from '@tigerhall/components';
import * as segment from '@tigerhall/analytics';

import { ParsedTextBlock } from './utils/getParsedText';

export function GetComponentByType({
  type,
  text,
  userId,
  url
}: ParsedTextBlock) {
  switch (type) {
    case 'MENTION': {
      return (
        <TrackedLink
          name={type}
          href={`/profile/${userId}`}
          fontWeight="bold"
          fontSize="inherit"
          color="tigerOrange.400"
          onClick={() => {
            segment.mentionClicked({
              mentionedUserId: userId
            });
          }}
        >
          {text}
        </TrackedLink>
      );
    }
    case 'LINK': {
      // check if https protocol is missing or not?
      const hasSecureProtocol = /^https?:\/\//.test(url);

      return (
        <TrackedLink
          name="EXTERNAL_LINK"
          href={hasSecureProtocol ? url : `https://${url}`}
          isExternal
          fontSize="inherit"
          color="tigerOrange.400"
        >
          {text}
        </TrackedLink>
      );
    }
    default:
      return <>{text}</>;
  }
}
