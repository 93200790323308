import { RouteObject } from 'react-router-dom';

import {
  PasswordResetSuccessfully,
  ResetPassword,
  SendConfirmationCode
} from './screens';

export const routes: RouteObject[] = [
  {
    path: '/reset-password',
    children: [
      {
        index: true,
        element: <SendConfirmationCode />
      },
      {
        path: 'new-password',
        element: <ResetPassword />
      },
      {
        path: 'success',
        element: <PasswordResetSuccessfully />
      }
    ]
  }
];
