import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserBaseProfileFragment = { __typename: 'User', id: string, firstName: string, lastName: string, company: string, jobTitle: string, isExpert: boolean, isFollowing: boolean, isBlockedByCurrentUser: boolean, isBlockingCurrentUser: boolean, avatarImage: { __typename?: 'Image', id: string, uri: string, alt: string }, expertOrg?: { __typename?: 'Organisation', id: string } | null };

export type GetUserBaseProfileQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;


export type GetUserBaseProfileQuery = { __typename?: 'Query', user?: { __typename: 'User', id: string, firstName: string, lastName: string, company: string, jobTitle: string, isExpert: boolean, isFollowing: boolean, isBlockedByCurrentUser: boolean, isBlockingCurrentUser: boolean, avatarImage: { __typename?: 'Image', id: string, uri: string, alt: string }, expertOrg?: { __typename?: 'Organisation', id: string } | null } | null };

export const UserBaseProfileFragmentDoc = gql`
    fragment UserBaseProfile on User {
  __typename
  id
  firstName
  lastName
  company
  jobTitle
  isExpert
  isFollowing
  avatarImage {
    id
    uri
    alt
  }
  expertOrg {
    id
  }
  isBlockedByCurrentUser
  isBlockingCurrentUser
}
    `;
export const GetUserBaseProfileDocument = gql`
    query GetUserBaseProfile($id: String!) {
  user(id: $id) {
    ...UserBaseProfile
  }
}
    ${UserBaseProfileFragmentDoc}`;

/**
 * __useGetUserBaseProfileQuery__
 *
 * To run a query within a React component, call `useGetUserBaseProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBaseProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBaseProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserBaseProfileQuery(baseOptions: Apollo.QueryHookOptions<GetUserBaseProfileQuery, GetUserBaseProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserBaseProfileQuery, GetUserBaseProfileQueryVariables>(GetUserBaseProfileDocument, options);
      }
export function useGetUserBaseProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserBaseProfileQuery, GetUserBaseProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserBaseProfileQuery, GetUserBaseProfileQueryVariables>(GetUserBaseProfileDocument, options);
        }
export type GetUserBaseProfileQueryHookResult = ReturnType<typeof useGetUserBaseProfileQuery>;
export type GetUserBaseProfileLazyQueryHookResult = ReturnType<typeof useGetUserBaseProfileLazyQuery>;
export type GetUserBaseProfileQueryResult = Apollo.QueryResult<GetUserBaseProfileQuery, GetUserBaseProfileQueryVariables>;