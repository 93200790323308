import { Box, Icon, IconButton, Image, LinkBox, Text } from '@chakra-ui/react';
import { resizeImage } from '@tigerhall/core';
import { IconX } from '@tigerhall/icons';
import { LinkOverlay } from '@tigerhall/components';

export interface ImageVariantProps {
  title: string;
  description: string;
  url: string;
  imageUrl: string;
  onRemove?: () => unknown;
}

export function ImageVariant({
  title,
  description,
  url,
  imageUrl,
  onRemove
}: Readonly<ImageVariantProps>) {
  return (
    <LinkBox borderRadius={8} overflow={'hidden'} w={'full'}>
      <Box pos={'relative'} w={'full'}>
        <Image
          w="full"
          objectFit="cover"
          src={resizeImage({ url: imageUrl, width: 542 })}
          alt="Link Preview"
          aspectRatio={1.91 / 1}
        />
        {onRemove ? (
          <IconButton
            aria-label="Remove Link Preview"
            variant={'ghost'}
            position={'absolute'}
            zIndex={'zIndexOne'}
            top={0}
            right={0}
            bg={'blackAlpha.700'}
            m={2}
            alignSelf={'flex-end'}
            onClick={onRemove}
            icon={<Icon as={IconX} boxSize={'1rem'} color={'lightGrey.600'} />}
          />
        ) : null}
      </Box>
      <Box bg={'white'} p={4} gap={1}>
        <Text
          color={'darkGrey.200'}
          fontSize={{ base: 'xs', md: 'sm' }}
          fontWeight={'medium'}
          noOfLines={1}
          as={'span'}
        >
          {url}
        </Text>
        <Text
          fontSize={{ base: 'sm', md: 'md' }}
          color={'black'}
          fontWeight={'bold'}
          noOfLines={1}
        >
          <LinkOverlay
            href={url}
            isExternal
            _hover={{ textDecoration: 'none' }}
          >
            {title}
          </LinkOverlay>
        </Text>
        <Text
          color={'darkGrey.300'}
          fontSize={{ base: 'xs', md: 'sm' }}
          fontWeight={'medium'}
          noOfLines={2}
        >
          {description}
        </Text>
      </Box>
    </LinkBox>
  );
}
