import * as React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { FlagProvider } from '@unleash/proxy-client-react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
  SENTRY_DENY_URLS,
  SENTRY_IGNORE_ERRORS,
  isProduction
} from '@tigerhall/core';
import * as braze from '@braze/web-sdk';
import { ErrorBoundary } from 'components/tools/ErrorBoundary';
import { PureScreenErrorFallback } from 'components/tools/ErrorBoundary/components/PureScreenErrorFallback';
import { App } from 'components/tools';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/mousewheel';
import 'swiper/css/keyboard';
import 'swiper/css/autoplay';

import './global.scss';
import './components/ui/lexical/style/style.css';

if (isProduction() && import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    // defined in `gitlab-ci.yml`
    release: import.meta.env.VITE_SENTRY_RELEASE,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      })
    ],
    sampleRate: 1.0,
    tracesSampleRate: 0.6,
    ignoreErrors: SENTRY_IGNORE_ERRORS,
    denyUrls: SENTRY_DENY_URLS
  });
}

// initialize the SDK
braze.initialize(import.meta.env.VITE_BRAZE_API_KEY as string, {
  baseUrl: import.meta.env.VITE_BRAZE_SDK_ENDPOINT as string,
  enableLogging: !import.meta.env.PROD,
  allowUserSuppliedJavascript: true,
  manageServiceWorkerExternally: true,
  minimumIntervalBetweenTriggerActionsInSeconds: 3
});

braze.automaticallyShowInAppMessages();
braze.openSession();

const flagConfig = {
  url: import.meta.env.VITE_FLAG_API_URL, // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey: import.meta.env.VITE_FLAG_KEY, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  appName: import.meta.env.VITE_FLAG_APP, // The name of your application. It's only used for identifying your application
  refreshInterval: 15 // How often (in seconds) the client should poll the proxy for updates
};

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <FlagProvider config={flagConfig}>
      <ErrorBoundary fallback={PureScreenErrorFallback}>
        <App />
      </ErrorBoundary>
    </FlagProvider>
  </React.StrictMode>
);
