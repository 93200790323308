import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle
} from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const tiger = definePartsStyle({
  control: defineStyle({
    boder: '1px solid',
    borderColor: 'darkGrey.300',
    _checked: {
      backgroundColor: 'tigerOrange.600',
      borderColor: 'tigerOrange.600',
      _hover: {
        backgroundColor: 'tigerOrange.600',
        borderColor: 'tigerOrange.600'
      }
    }
  }),
  icon: defineStyle({
    color: 'white',
    _checked: {
      color: 'white'
    }
  })
});

export const Checkbox = defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    tiger
  },
  defaultProps: {
    variant: 'tiger'
  }
});
