import {
  StreamInput,
  useCreateStreamMutation,
  useUpdateStreamMutation,
  useUpsertStreamMediaMutation
} from 'generated';
import { UseFormSetError } from 'react-hook-form';
import { useFileUpload } from 'hooks';
import { useToast } from '@chakra-ui/react';
import { useStructuredErrors } from '@tigerhall/core';
import { captureException } from '@sentry/react';
import { AppError } from 'utils';

import { FormValues } from '../components/Form';

export type MimickedError = {
  message?: string;
  type?: string;
};

interface UseManageStreamProps {
  updateStreamId?: string;
  setHasError?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useManageStream({
  updateStreamId,
  setHasError
}: Readonly<UseManageStreamProps>) {
  const [createStreamMutation] = useCreateStreamMutation();
  const [updateStreamMutation] = useUpdateStreamMutation();
  const [upsertMedia] = useUpsertStreamMediaMutation();

  const { captureMutationError, handleMutationError, mimicSetError } =
    useStructuredErrors();

  const { upload, progress } = useFileUpload();

  const toast = useToast({
    duration: 2000
  });

  const handleError = (err: unknown) => {
    if (handleMutationError(err)) {
      return;
    }
    toast.closeAll();
    toast({
      title: err instanceof Error ? err.message : 'An error occurred',
      status: 'error'
    });
    captureException(err, err instanceof AppError ? { ...err.context } : {});
  };

  const upsertStream = async (
    id: string,
    publishedAt: StreamInput['publishedAt'],
    s3PresignedUrl?: string
  ) => {
    return await upsertMedia({
      variables: {
        input: {
          id,
          language: 'EN',
          video: {
            publishedAt,
            ...(s3PresignedUrl ? { s3PresignedUrl } : undefined)
          }
        }
      }
    });
  };

  const createStream = async (
    input: StreamInput,
    setError: UseFormSetError<FormValues>,
    stream: File
  ) => {
    try {
      toast({
        status: 'loading',
        title: 'We are processing your video'
      });

      const result = await createStreamMutation({
        variables: {
          input
        }
      });

      captureMutationError(result.data?.createStream, setError, (description) =>
        toast({ description })
      );

      if (result.data?.createStream?.__typename !== 'Stream') {
        throw new Error('An error ocurred while creating the video');
      }

      toast({
        status: 'loading',
        title: 'Uploading video'
      });

      const url = await upload(stream);

      const newStreamId = result.data?.createStream?.id;

      const res = await upsertStream(newStreamId, input.publishedAt, url);

      captureMutationError(
        res.data?.upsertStreamMedia,
        mimicSetError(toast),
        (description) => toast({ description })
      );
    } catch (error) {
      setHasError?.(true);
      handleError(error);
    }
  };

  const updateStream = async (
    input: StreamInput,
    setError: UseFormSetError<FormValues>
  ) => {
    try {
      toast({
        status: 'loading',
        title: 'Updating video'
      });

      if (!updateStreamId) {
        throw new Error('updateStreamId not found');
      }

      const updatedVideo = await updateStreamMutation({
        variables: {
          updateStreamId,
          input
        }
      });

      captureMutationError(
        updatedVideo.data?.updateStream,
        setError,
        (description) => toast({ description })
      );

      const res = await upsertStream(updateStreamId, input.publishedAt);

      captureMutationError(
        res.data?.upsertStreamMedia,
        mimicSetError(toast),
        (description) => toast({ description })
      );
    } catch (error) {
      setHasError?.(true);
      handleError(error);
    }
  };

  return { createStream, updateStream, progress };
}
