import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Icon, Stack } from '@chakra-ui/react';
import { useLoginWithEmailHook } from 'modules/authentication/hook';
import { IconBrandTigerhallVertical } from '@tigerhall/icons';

import { InvitedForm } from './components/InvitedForm';
import { InvitedSuccess } from './components/InvitedSuccess';

type State = 'FORM' | 'SUCCESS';

export interface UserCreds {
  email: string;
  password: string;
}

export function Invited() {
  const [state, setState] = useState<State>('FORM');
  const { triggerLogin } = useLoginWithEmailHook();
  const [userCreds, setUserCreds] = useState<UserCreds>({
    email: '',
    password: ''
  });

  function getComponent() {
    switch (state) {
      case 'FORM':
        return (
          <InvitedForm
            onSuccess={(credentials: UserCreds) => {
              setState('SUCCESS');
              setUserCreds(credentials);
            }}
          />
        );
      case 'SUCCESS':
        return (
          <InvitedSuccess
            onButtonClick={async () => {
              const { email, password } = userCreds;
              await triggerLogin({ email, password }, { setError: null });
            }}
          />
        );

      default:
        throw new Error('Unsupported state');
    }
  }

  return (
    <>
      <Helmet>
        <title>Join Tigerhall for Teams</title>
      </Helmet>
      <Box
        backgroundColor="darkGrey.700"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        height="auto"
        minHeight="100vh"
        p="1rem"
      >
        <Stack
          spacing="0"
          width="100%"
          maxWidth="25rem"
          alignItems="center"
          padding="1rem"
          gap="1rem"
        >
          <Box width="7.5rem">
            <Icon
              as={IconBrandTigerhallVertical}
              width="7.5rem"
              height={'auto'}
              color="tigerOrange.600"
            />
          </Box>
          <Box py="1rem">{getComponent()}</Box>
        </Stack>
      </Box>
    </>
  );
}
