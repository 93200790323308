import {
  Box,
  Center,
  Spinner,
  type SystemStyleObject,
  Text,
  VStack,
  useBreakpointValue
} from '@chakra-ui/react';
import { useEdgesOverlay } from '@tigerhall/core';
import {
  type GetStreamChatMessagesQuery,
  useGetStreamChatMessagesQuery,
  useStreamChatMessagesSubscription
} from 'generated';
import { useLayoutEffect, useRef, useState, useTransition } from 'react';
import { useAppSelector } from 'hooks';
import { getUserId } from 'app/state';

import { SingleChatMessage } from './SingleChatMessage';

/**
 * Here we use `SystemStyleObject` because the `content` property is not a valid
 * CSS property, so it's not available in the `StyleProps` type.
 */
const OVERLAY_STYLES: SystemStyleObject = {
  content: '"."',
  color: 'transparent',
  display: 'block',
  position: 'sticky',
  width: '100%',
  height: '3rem',
  lineHeight: '3rem',
  left: 0,
  right: 0
};

interface ChatMessagesProps {
  streamId: string;
  disableOverlay?: boolean;
}

export function ChatMessages({
  streamId,
  disableOverlay
}: Readonly<ChatMessagesProps>) {
  const userId = useAppSelector(getUserId);
  const { handleScroll, hasTopOverlay, hasBottomOverlay } = useEdgesOverlay();

  const isMobile = useBreakpointValue(
    { base: true, lg: false },
    {
      fallback: 'lg',
      ssr: false
    }
  );

  const [, startTransition] = useTransition();

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = useState<
    GetStreamChatMessagesQuery['streamChatMessages']
  >([]);

  const { loading: isLoadingMessages } = useGetStreamChatMessagesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: streamId
    },
    onCompleted: (data) => {
      const messagesToUpdate = [...(data?.streamChatMessages || [])];

      messagesToUpdate.sort(
        (previousMessage, currentMessage) =>
          (previousMessage?.timestamp || 0) - (currentMessage?.timestamp || 0)
      );

      setMessages(messagesToUpdate || []);
    }
  });

  function scrollToBottom() {
    // the `setTimeout` is to make sure the scroll is done after the new message is added
    setTimeout(() => {
      if (!scrollContainerRef.current) {
        return;
      }

      scrollContainerRef.current.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }, 200);
  }

  useStreamChatMessagesSubscription({
    shouldResubscribe: true,
    variables: {
      streamId
    },

    onData: (res) => {
      if (res?.data?.data?.chatMessages) {
        const messagesToUpdate = [...messages, res.data.data.chatMessages];

        startTransition(() => {
          setMessages(messagesToUpdate);
        });

        const isLastMessageSendByCurrentUserUser =
          res.data.data.chatMessages.user.id === userId;

        // if the last message is sent by the current user, scroll to the bottom
        if (isLastMessageSendByCurrentUserUser) {
          scrollToBottom();
        }
      }
    }
  });

  // This code is for smooth scrolling of liveStream chat
  useLayoutEffect(() => {
    // the bottom overlay is visible indicates that there are more messages which means the user has scrolled up
    if (!hasBottomOverlay) {
      scrollToBottom();
    }
  }, [hasBottomOverlay, messages]);

  // loading screen
  if (!messages.length && isLoadingMessages) {
    return (
      <Center width={'full'} height={'full'} p={'2rem'} gap={4}>
        <Spinner color={'tigerOrange.600'} />
        <Text color={'lightGrey.600'} fontSize={'sm'}>
          Loading chat...
        </Text>
      </Center>
    );
  }

  // no results screen
  if (!messages.length && !isLoadingMessages) {
    return (
      <Center width={'full'} height={'full'} p={'2rem'}>
        <Text color={'lightGrey.600'} fontSize={'sm'}>
          No messages yet. Be the first one!
        </Text>
      </Center>
    );
  }

  return (
    <Box
      width={'full'}
      position={'relative'}
      overflow={'auto'}
      ref={scrollContainerRef}
      height={'full'}
      maxH={isMobile ? '13.5rem' : 'auto'}
      onScroll={handleScroll}
      _before={{
        ...OVERLAY_STYLES,
        visibility: hasTopOverlay && !disableOverlay ? 'visible' : 'hidden',
        top: -1,
        bgGradient: 'linear(to-b, darkGrey.700, transparent)'
      }}
      _after={{
        ...OVERLAY_STYLES,
        visibility: hasBottomOverlay && !disableOverlay ? 'visible' : 'hidden',
        bottom: -1,
        bgGradient: 'linear(to-t, darkGrey.700, transparent)'
      }}
    >
      <VStack
        alignItems={'flex-start'}
        spacing={isMobile ? 1 : 3}
        paddingX={4}
        marginTop={isMobile ? 0 : '-2rem'}
        flex={1}
        width={'full'}
      >
        {messages.map((message) => (
          <SingleChatMessage
            key={`chat-message-${message?.id}`}
            nameForTrackLink="LIVESTREAM_CHAT_USER"
            userId={message?.user?.id}
            userName={`${message?.user.firstName} ${message?.user.lastName?.[0]}`}
            message={message?.content}
          />
        ))}
      </VStack>
    </Box>
  );
}
