import * as React from 'react';
import property from 'lodash/property';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, Heading, Stack, useToast } from '@chakra-ui/react';
import {
  type AssignmentContent,
  AssignmentStatus,
  type OrganisationGroup,
  type User
} from '@tigerhall/core/lib/types';
import { TrackedButton } from '@tigerhall/components';
import { BackButton } from 'components/ui/Buttons';
import { ActionModal, type ActionModalProps } from 'components/ui/modals';
import {
  useGetOrgAssignmentQuery,
  useUpdateAssignmentMutation
} from 'generated';
import { getSelectedOrg } from 'app/state';
import { useAppSelector } from 'hooks';

import { SelectedUsersAndGroups } from '../../components';
import { typenameToContentType } from '../../../../../../utils/content';

export function EditAssignmentUsers() {
  const { id: assignmentId } = useParams<{ id: string }>();
  const { id: orgId } = useAppSelector(getSelectedOrg) || {};
  const navigate = useNavigate();
  const toast = useToast({ position: 'top-right', isClosable: true });

  const [isEdited, setIsEdited] = React.useState(false);
  const [selectedGroupsIds, setSelectedGroupsIds] = React.useState<
    OrganisationGroup['id'][]
  >([]);
  const [selectedUsersIds, setSelectedUsersIds] = React.useState<User['id'][]>(
    []
  );
  const [actionModal, setActionModal] = React.useState<ActionModalProps>({
    isOpen: false,
    title: '',
    onClose: () => {},
    description: ''
  });

  const { data: getOrgAssignmentResponse, loading } = useGetOrgAssignmentQuery({
    skip: !orgId || !assignmentId,
    variables: {
      orgId: orgId as string,
      assignmentId: assignmentId as string
    },
    onCompleted: (data) => {
      const assignmentDetails = data?.organisation.assignments.edges[0] ?? null;
      const users = assignmentDetails?.users?.edges ?? [];
      const orgGroups = (assignmentDetails?.orgGroups ??
        []) as OrganisationGroup[];
      setSelectedUsersIds(users.map(property('id')));
      setSelectedGroupsIds(orgGroups.map(property('id')));
    }
  });

  const [updateAssignment] = useUpdateAssignmentMutation();

  if (loading || !orgId || !assignmentId) {
    return null;
  }

  const assignmentDetails =
    getOrgAssignmentResponse?.organisation.assignments.edges[0] ?? null;
  const isInactive = assignmentDetails?.status === AssignmentStatus.Inactive;
  const selectedContent =
    (assignmentDetails?.contents?.edges?.filter(
      (content) => content.__typename !== 'Event'
    ) as AssignmentContent[]) || [];

  if (isInactive) {
    navigate(`/admin/assignments/${assignmentId}`);
  }

  function resetConfirmationModal() {
    setActionModal({
      isOpen: false,
      title: '',
      onClose: () => {},
      description: '',
      primaryButtonText: '',
      primaryButtonOnClick: () => {},
      secondaryButtonText: '',
      secondaryButtonOnClick: () => {}
    });
  }

  async function updateSelectedUsersAndGroups() {
    resetConfirmationModal();
    try {
      if (!assignmentId) {
        throw new Error('No assignment id');
      }
      await updateAssignment({
        variables: {
          id: assignmentId,
          input: {
            assignmentTitle: assignmentDetails?.assignmentTitle || '',
            assignmentReason: assignmentDetails?.assignmentReason || '',
            dueDate: assignmentDetails?.dueDate,
            contents: selectedContent.map((content, index) => ({
              id: content.id,
              contentType: typenameToContentType(content.__typename),
              index
            })),
            users: selectedUsersIds,
            orgGroups: selectedGroupsIds
          }
        }
      });
      setIsEdited(false);
      toast({
        title: 'Selected users and groups updated successfully',
        status: 'success'
      });
    } catch (error) {
      toast({
        title:
          'Error saving changes. Please try again later or contact support.',
        status: 'error'
      });
    }
  }

  function openUpdateConfirmationModal() {
    setActionModal({
      isOpen: true,
      title: 'Confirm update',
      onClose: resetConfirmationModal,
      description:
        'Are you sure you want to update the selected users and groups?',
      primaryButtonText: 'Update',
      primaryButtonOnClick: updateSelectedUsersAndGroups,
      secondaryButtonText: 'Cancel',
      secondaryButtonOnClick: resetConfirmationModal
    });
  }
  function onBackButtonClick() {
    if (isEdited) {
      setActionModal({
        isOpen: true,
        title: 'You have unsaved changes',
        type: 'warning',
        onClose: resetConfirmationModal,
        description:
          'You did not update the selected users and groups. Do you want to update it before leaving?',
        primaryButtonText: 'Update',
        primaryButtonOnClick: async () => {
          await updateSelectedUsersAndGroups();
          navigate(-1);
        },
        secondaryButtonText: 'Discard & Exit',
        secondaryButtonOnClick: () => navigate(-1)
      });
    } else {
      navigate(-1);
    }
  }

  return (
    <Flex direction="column" gap="2rem">
      <BackButton to="" onClick={onBackButtonClick}>
        Back
      </BackButton>
      <Stack
        direction="row"
        backgroundColor="darkGrey.400"
        py="2rem"
        px="2rem"
        borderRadius="10px"
        boxShadow="0rem 0.19rem 0.37rem rgba(0, 0, 0, 0.160784);"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading fontSize="2rem" color="tigerOrange.600">
          Edit Selected Users & Groups
        </Heading>
        <TrackedButton
          name="updateAssignmentUsersAndGroups"
          onClick={openUpdateConfirmationModal}
          size="lg"
          isDisabled={!isEdited}
        >
          Update
        </TrackedButton>
      </Stack>
      <SelectedUsersAndGroups
        selectedUserRowIds={selectedUsersIds}
        onUserRowSelection={(selectedRowsIds) => {
          setSelectedUsersIds(selectedRowsIds);
          setIsEdited(true);
        }}
        selectedGroupRowIds={selectedGroupsIds}
        onGroupRowSelection={(selectedRowsIds) => {
          setSelectedGroupsIds(selectedRowsIds);
          setIsEdited(true);
        }}
      />
      <ActionModal {...actionModal} />
    </Flex>
  );
}
