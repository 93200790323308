import map from 'lodash/map';
import { Box, Text } from '@chakra-ui/react';
import { type Maybe } from '@tigerhall/core';

import { ProfileBarFollow } from '~/components/ui/User/ProfileBarFollow';

interface AuthorsProps {
  experts: { id: string }[];
  hostedBy?: Maybe<Maybe<{ id: string }>[]>;
}

export function Authors({ hostedBy, experts }: Readonly<AuthorsProps>) {
  return (
    <>
      {experts?.length ? (
        <>
          <Text
            color={'lightGrey.200'}
            fontSize={'lg'}
            fontWeight={'bold'}
            px={'6'}
          >
            Thinkfluencers
          </Text>
          {map(experts, (expert) => (
            <Box paddingX={4} w={'full'} key={`thinkfluencer-${expert.id}`}>
              <ProfileBarFollow userId={expert.id} size={'sm'} />
            </Box>
          ))}
        </>
      ) : null}

      {hostedBy?.length ? (
        <>
          <Text
            color={'lightGrey.200'}
            fontSize={'lg'}
            fontWeight={'bold'}
            px={'6'}
          >
            Hosted By
          </Text>
          {map(hostedBy, (host) =>
            host ? (
              <Box key={`host-${host.id}`} paddingX={4} w={'full'}>
                <ProfileBarFollow userId={host.id} size={'sm'} />
              </Box>
            ) : null
          )}
        </>
      ) : null}
    </>
  );
}
