import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { TrackedButton } from '@tigerhall/components';
import { MODALTYPES } from 'components/ui/modals';
import useCommonModal from 'modules/application/hooks/useCommonModal';

interface PreviewWallProps extends FlexProps {
  onClose?: () => void;
}

export function PreviewWall({ onClose, ...props }: Readonly<PreviewWallProps>) {
  const { openModal } = useCommonModal();

  const onUpgradePlan = () => {
    if (onClose) {
      onClose();
    }
    openModal(MODALTYPES.Payment);
  };

  return (
    <Flex
      w="full"
      h="18.75rem"
      p={{ base: '2rem', md: '4rem' }}
      bgGradient="linear(360deg, #383733 0%, rgba(56, 55, 51, 0.95) 64%, rgba(56, 55, 51, 0.9) 100%)"
      flexDir="column"
      justifyContent="flex-end"
      alignItems="center"
      gap="0.75rem"
      pos="absolute"
      bottom="0"
      {...props}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        gap="1rem"
        textAlign="center"
      >
        <Text fontSize="lg" color="tigerOrange.600" fontWeight="bold">
          You Have Limited Access
        </Text>
        <Text fontSize="md" color="white">
          You can now only access a short preview of each content piece on
          Tigerhall
        </Text>
        <TrackedButton onClick={onUpgradePlan} name="signUpNow" size="lg">
          Subscribe Now
        </TrackedButton>
      </Flex>
    </Flex>
  );
}
