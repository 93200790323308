import { Flex, type FlexProps } from '@chakra-ui/react';

import { Marquee } from '~/marquee';

export interface ContentInfoProps {
  name: string;
  casting: string;
  size?: 'sm' | 'lg';
  width?: FlexProps['width'];
}

/**
 * Contains the name and casting of the podcast.
 */
export function ContentInfo({
  name,
  casting,
  size,
  width = '100%'
}: ContentInfoProps) {
  const nameFontSize = size === 'sm' ? 'sm' : 'xl';

  return (
    <Flex flexDirection={'column'} gap={'0.25rem'} flex={1} width={width}>
      <Marquee
        fontSize={nameFontSize}
        fontWeight={'bold'}
        textAlign={'start'}
        color={'white'}
      >
        {name}
      </Marquee>
      <Marquee fontSize={'sm'} textAlign={'start'} color={'lightGrey.900'}>
        {casting}
      </Marquee>
    </Flex>
  );
}
