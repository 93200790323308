import { type ReactNode, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Stack,
  VStack
} from '@chakra-ui/react';
import { type ContentCard } from '@tigerhall/core/lib/types';
import { FormImageUpload, FormInput } from '@tigerhall/components';
import { THLink } from '@tigerhall/ui-components';
import { CategoriesSelect } from 'components/form';
import ContentWizard from 'components/ui/containers/ContentWizard';
import { useTHForm } from 'hooks/useTHForm';
import isEmpty from 'lodash/isEmpty';
import useDraftLearningPaths from 'modules/admin/modules/trails/hooks/useDraftLearningPaths';
import useUpsertTrail from 'modules/admin/modules/trails/hooks/useUpsertTrail';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import { ActionModal } from 'components/ui/modals';
import { EditorForm } from 'components/ui/lexical/form/EditorForm';
import { captureException } from '@sentry/react';

import SelectedContentCardList from '../TrailContent/SelectedContentCardList';
import { TrailSuccess } from '../TrailSuccess';
import {
  trailContentRoute,
  trailDetailsRoute
} from '../../utils/getTrailsRoutes';
import { OrganisationGroupSelectAsync } from '~/components/form/Select/OrganisationGroupSelectAsync';
import { useGalleryImageUpload } from '~/hooks';

const DESCRIPTION =
  'Make sure the content and details are in order. You can always go back to make any changes before you proceed.';

function TrailDetailsAction({
  children,
  idOrDraft
}: Readonly<{
  idOrDraft: string;
  children: ReactNode;
}>) {
  return (
    <THLink name="TRAIL_DETAILS" to={trailDetailsRoute(idOrDraft)}>
      {children}
    </THLink>
  );
}

export function TrailSummary() {
  const {
    actions,
    draftMeta,
    contentIds: selectedContentIds,
    contents,
    isValidDraft,
    isValidDraftMeta
  } = useDraftLearningPaths();

  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const navigate = useNavigate();
  const { idOrDraft } = useParams<{ idOrDraft: string }>();
  const { upsertTrail, data, loading, error, assignTrail } = useUpsertTrail();
  const upload = useGalleryImageUpload();

  function onEditContent() {
    navigate(trailContentRoute(idOrDraft as string));
  }

  const trailCreationId = data?.result?.id;

  useEffect(() => {
    if (trailCreationId) {
      return;
    }

    if (selectedContentIds.length <= 0) {
      navigate(trailContentRoute(idOrDraft as string), { replace: true });
      return;
    }

    if (!isValidDraftMeta) {
      navigate(trailDetailsRoute(idOrDraft as string), { replace: true });
    }
  }, [
    navigate,
    idOrDraft,
    isValidDraftMeta,
    selectedContentIds.length,
    trailCreationId
  ]);

  function onContinue() {
    upsertTrail().catch((e) => captureException(e));
  }

  const form = useTHForm({
    formName: 'CREATE_TRAIL_SUMMARY',
    defaultValues: draftMeta
  });

  const values = form.watch();

  useDeepCompareEffect(() => {
    actions.addMeta(values);
  }, [values]);

  if (!error && !loading && !isEmpty(trailCreationId)) {
    return <TrailSuccess assignTrail={assignTrail} />;
  }

  function renderLoader() {
    return (
      <Flex alignItems="center" justifyContent="center" py={16}>
        Loading...
      </Flex>
    );
  }

  return (
    <ContentWizard
      description={DESCRIPTION}
      currentStep={3}
      title="Confirmation"
      buttons={[
        {
          text: 'Back',
          container: TrailDetailsAction,
          containerProps: { idOrDraft },
          variant: 'outline',
          backgroundColor: 'transparent'
        },
        {
          text: 'Confirm',
          onClick:
            idOrDraft === 'draft'
              ? onContinue
              : () => setIsActionModalOpen(true),
          isDisabled: !isValidDraft,
          dataCy: 'confirm-button'
        }
      ]}
      m={0}
    >
      {!loading ? (
        <Stack direction="column" spacing="2rem">
          <Box
            backgroundColor="darkGrey.700"
            p="1.5rem"
            border="1px"
            borderRadius="lg"
            borderColor="darkGrey.400"
            boxShadow="lg"
          >
            <Heading color="white" fontSize="1.5rem" mb="1.5rem">
              Trail Details
            </Heading>

            <FormProvider {...form}>
              <Grid templateColumns="repeat(3, 1fr)">
                <GridItem colSpan={2} pr="4" borderRight="1px solid #474747">
                  <VStack flex={1} spacing="4" alignItems="stretch">
                    <FormInput
                      type="text"
                      name="name"
                      label="Trail Name"
                      placeholder="Enter a trail name"
                      rules={{
                        required: true
                      }}
                    />

                    <EditorForm
                      name="description"
                      label="Trail Description"
                      placeholder="Add a 2-3 sentence description to help your team understand what they can look forward to in this Trail. "
                      rules={{
                        required: true
                      }}
                    />

                    <HStack>
                      <CategoriesSelect name="category" required />
                      <OrganisationGroupSelectAsync
                        name="organisationGroups"
                        isMulti
                        label={'Organization Groups'}
                      />
                    </HStack>
                  </VStack>
                </GridItem>

                <GridItem pl="4">
                  <FormImageUpload
                    name="image"
                    label="Trail Image"
                    upload={upload}
                    showResetButton={false}
                    rules={{
                      required: true
                    }}
                  />
                </GridItem>
              </Grid>
            </FormProvider>
          </Box>

          <Box
            backgroundColor="darkGrey.700"
            px="1.5rem"
            py="0.5rem"
            border="1px"
            borderRadius="lg"
            borderColor="darkGrey.400"
            boxShadow="lg"
          >
            <SelectedContentCardList
              showEditIcon
              onEdit={onEditContent}
              title="Content In This Trail"
              allContentCards={contents as ContentCard[]}
              selectedContentIds={selectedContentIds}
              setSelectedContentIds={actions.setContentIds}
            />
          </Box>
        </Stack>
      ) : (
        renderLoader()
      )}
      <ActionModal
        isOpen={isActionModalOpen}
        onClose={() => {
          setIsActionModalOpen(false);
        }}
        title="Edit Trail"
        description={
          'This trail might be part of an assignment. Modifying this trail will have an impact on the assignment and its statistics.'
        }
        primaryButtonText="Confirm"
        primaryButtonOnClick={onContinue}
        secondaryButtonText="Cancel"
        secondaryButtonOnClick={() => setIsActionModalOpen(false)}
      />
    </ContentWizard>
  );
}
