export const SUPPORT_URL =
  'https://tigerhall.atlassian.net/servicedesk/customer/portals';
export const ADMIN_SUPPORT_URL =
  'https://tigerhall.atlassian.net/servicedesk/customer/topic/82bf3983-82af-44ed-8de6-ef0e50d3a97c';

export const SENIORITY_LEVELS = [
  {
    id: 'founder',
    label: 'Founder'
  },
  {
    id: 'c-level',
    label: 'C-Level'
  },
  {
    id: 'vp',
    label: 'VP'
  },
  {
    id: 'director',
    label: 'Director'
  },
  {
    id: 'manager',
    label: 'Manager'
  },
  {
    id: 'senior',
    label: 'Senior'
  },
  {
    id: 'junior',
    label: 'Junior'
  }
];

export const FREE_CONTENT_CONSUMPTION_LIMIT = 3;

export const REPORT_CONTENT_REASONS = [
  {
    label: 'Political Bias'
  },
  {
    label: 'Untrue Facts'
  },
  {
    label: 'Intellectual Property Theft'
  },
  {
    label: 'Audio Issue'
  },
  {
    label: 'Video Issue'
  },
  {
    label: 'Difficulty Playing Podcast'
  },
  {
    label: 'Difficulty Playing Video'
  },
  {
    label: 'Difficulty Loading Content'
  },
  {
    label: 'Wrong Content Played'
  },
  {
    label: 'Other'
  }
];

export const REPORT_POST_AND_COMMENT_REASONS = [
  {
    label: 'Misleading information'
  },
  {
    label: 'Fraud or scam'
  },
  {
    label: 'Bullying, trolling or mocking'
  },
  {
    label: 'Hateful or abusive speech'
  },
  {
    label: 'Sharing irrelevant information'
  },
  {
    label: 'Defamation'
  },
  {
    label: 'Inciting violence or is a threat'
  },
  {
    label: 'Other'
  }
];

export const DOMAIN = 'tigerhall.com';

/**
 * recommended by sentry to avoid unactionable errors
 * @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
 */
export const SENTRY_IGNORE_ERRORS = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // we get unhandled Rejected promise issue logged in sentry
  // it seems to be caused due an issue with Outlook.
  // Given that most of the users are coming from Windows..
  // It is safe ignoring
  // Please see the issue link:https://github.com/getsentry/sentry-javascript/issues/3440
  'Non-Error promise rejection captured'
];

/**
 * recommended by sentry to avoid unactionable errors
 * @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
 */
export const SENTRY_DENY_URLS = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
];

/**
 * Manage the feature flags here:
 * https://gitlab.com/tigerhall/feature-flags/-/feature_flags
 */
export enum Flags {
  FeedCreatePlatformAnnouncement = 'feed_create_platform_announcement',
  DynamicGroup = 'dynamic_group',
  FreeTrial = 'free_trial',
  ReportBuilder = 'report_builder',
  ReportBuilderSelectCriteria = 'report_builder_select_criteria',
  UrlPreview = 'url_preview'
}

/**
 * If the beta flag you need is related to an existing feature flag,
 * then the beta flag should be named as the feature flag with the suffix "Beta".
 *
 * Manage the beta flags here:
 * https://gitlab.com/tigerhall/feature-flags/-/feature_flags
 */
export enum BetaFlags {
  DynamicGroupBeta = 'dynamic_group_beta',
  ReportBuilderBeta = 'report_builder_beta'
}
