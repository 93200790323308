import {
  LiveContentPlayer,
  type LiveContentPlayerProps
} from './components/LiveContentPlayer';
import { useGetStreamForConnectedLiveVideoPlayerSubscription } from './queries';
import { PlayerLoader } from '../PlayerLoader';

interface LiveContentConnectedProps
  extends Pick<
    LiveContentPlayerProps,
    'onFinished' | 'onClose' | 'isMinimized' | 'onMaximize' | 'setIsMinimized'
  > {
  id: string;
}

export function LiveContentConnected({
  id,
  onFinished,
  onClose,
  onMaximize,
  isMinimized,
  setIsMinimized
}: LiveContentConnectedProps) {
  const { data: { stream } = {} } =
    useGetStreamForConnectedLiveVideoPlayerSubscription({
      variables: {
        id
      }
    });

  if (!stream) {
    return <PlayerLoader />;
  }

  return (
    <LiveContentPlayer
      stream={stream}
      onFinished={onFinished}
      onClose={onClose}
      onMaximize={onMaximize}
      isMinimized={isMinimized}
      setIsMinimized={setIsMinimized}
    />
  );
}
