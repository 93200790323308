import { IconMoon, IconSun } from '@tigerhall/icons';

import { OptionButton } from '~/player/components/OptionButton';
import { usePowerReadState } from '~/player/PowerRead/context/usePowerReadState';

export function ChangeThemeButton() {
  const { theme, setTheme } = usePowerReadState((state) => {
    return {
      theme: state.theme,
      setTheme: state.setTheme
    };
  });

  const isDarkMode = theme === 'dark';

  return (
    <OptionButton
      label={isDarkMode ? 'Light Mode' : 'Dark Mode'}
      icon={isDarkMode ? IconSun : IconMoon}
      onClick={() => {
        setTheme(isDarkMode ? 'light' : 'dark');
      }}
    />
  );
}
