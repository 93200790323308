function i(r) {
  return !Number.isInteger(parseFloat(r.toString()));
}
function o(r, e = "-") {
  return r.replace(/\s+/g, e);
}
function t(r) {
  const e = o(r.toString());
  return e.includes("\\.") ? r : i(r) ? e.replace(".", "\\.") : r;
}
function u(r, e = "") {
  return [e, t(r)].filter(Boolean).join("-");
}
function c(r, e) {
  return `var(${t(r)}${e ? `, ${e}` : ""})`;
}
function s(r, e = "") {
  return `--${u(r, e)}`;
}
function a(r, e) {
  const n = s(r, void 0);
  return {
    variable: n,
    reference: c(n, f(void 0))
  };
}
function f(r) {
  return typeof r == "string" ? r : r == null ? void 0 : r.reference;
}
export {
  u as addPrefix,
  a as cssVar,
  i as isDecimal,
  s as toVar,
  c as toVarRef
};
