import {
  type BoxProps,
  Button,
  type ButtonProps,
  Flex,
  HStack,
  Heading
} from '@chakra-ui/react';
import React from 'react';

type Props = {
  title: string;
  subTitle?: string;
  buttons?: {
    id: string;
    text: string;
    onClick?: (any) => void;
    loading?: boolean;
    variant?: ButtonProps['variant'];
  }[];

  children: React.ReactNode;
} & BoxProps;

export default function FullWidthBox({
  title,
  subTitle,
  buttons,
  children,
  ...rest
}: Props) {
  return (
    <Flex direction="column" flex={1} width="100%" py={8} {...rest}>
      <Flex
        alignContent="center"
        justifyContent="space-between"
        backgroundColor="darkGrey.400"
        borderRadius="lg"
        p="2rem"
        mb="2rem"
      >
        <Heading fontSize="2rem" color="tigerOrange.600" alignSelf="center">
          {title}
        </Heading>

        <HStack>
          {(buttons ?? []).map(({ id, text, ...button }) => (
            <Button key={id} {...button} size="lg">
              {text}
            </Button>
          ))}
        </HStack>
      </Flex>

      {subTitle ? (
        <Heading color="white" fontSize="1.5rem" fontWeight="700" mb="1rem">
          {subTitle}
        </Heading>
      ) : null}

      {children}
    </Flex>
  );
}
