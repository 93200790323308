import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ToggleCommentReactionMutationVariables = SchemaTypes.Exact<{
  reactionType: SchemaTypes.ReactionType;
  referenceId: SchemaTypes.Scalars['ID'];
}>;


export type ToggleCommentReactionMutation = { __typename?: 'Mutation', toggleReact: { __typename?: 'ReactionsSummary', totalCount: number, userReacted?: { __typename?: 'UserReaction', id: string, reactionType: SchemaTypes.ReactionType } | null } };


export const ToggleCommentReactionDocument = gql`
    mutation ToggleCommentReaction($reactionType: ReactionType!, $referenceId: ID!) {
  toggleReact(
    reactionType: $reactionType
    reactionRefType: COMMENT
    referenceId: $referenceId
  ) {
    userReacted {
      id
      reactionType
    }
    totalCount
  }
}
    `;
export type ToggleCommentReactionMutationFn = Apollo.MutationFunction<ToggleCommentReactionMutation, ToggleCommentReactionMutationVariables>;

/**
 * __useToggleCommentReactionMutation__
 *
 * To run a mutation, you first call `useToggleCommentReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCommentReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCommentReactionMutation, { data, loading, error }] = useToggleCommentReactionMutation({
 *   variables: {
 *      reactionType: // value for 'reactionType'
 *      referenceId: // value for 'referenceId'
 *   },
 * });
 */
export function useToggleCommentReactionMutation(baseOptions?: Apollo.MutationHookOptions<ToggleCommentReactionMutation, ToggleCommentReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleCommentReactionMutation, ToggleCommentReactionMutationVariables>(ToggleCommentReactionDocument, options);
      }
export type ToggleCommentReactionMutationHookResult = ReturnType<typeof useToggleCommentReactionMutation>;
export type ToggleCommentReactionMutationResult = Apollo.MutationResult<ToggleCommentReactionMutation>;
export type ToggleCommentReactionMutationOptions = Apollo.BaseMutationOptions<ToggleCommentReactionMutation, ToggleCommentReactionMutationVariables>;