import { captureException } from '@sentry/react';
import {
  type PlayerModalProps,
  Podcast,
  type PodcastProps
} from '@tigerhall/components';
import { ContentVote } from '@tigerhall/core';
import {
  getContentUrl,
  getIsLastContentInPlaylist,
  getIsTemporarilyHiddenAndPaused,
  getPlaylistId,
  getPlaylistTitle,
  getPlaylistType,
  setAutoNextPlayToggle
} from 'app/state';
import {
  useAppDispatch,
  useAppSelector,
  useFreeAccount,
  useNavigationListener
} from 'hooks';
import { useContentActions } from 'modules/application/hooks/useContentActions';
import { useCallback, useMemo, useState } from 'react';

import type {
  GetPodcastForConnectedPodcastPlayerQuery,
  GetPodcastTranscriptQuery
} from '../queries';
import { SubOnlyProtectedMarkdownComponent } from './SubOnlyProtectedMarkdownComponent';

export interface PodcastPlayerProps {
  /**
   * Podcast object
   */
  podcast: NonNullable<GetPodcastForConnectedPodcastPlayerQuery['podcast']>;
  /**
   * Podcast Transcripts object
   */
  podcastTranscripts: GetPodcastTranscriptQuery['podcast'];
  /**
   * Function to be called when the transcript language changes
   */
  setTranscriptLanguage: PodcastProps['onChangeTranscriptLanguage'];
  /**
   * The currently selected transcript language
   */
  transcriptLanguage: PodcastProps['selectedTranscriptLanguage'];
  /**
   * Function to be called when the audio language changes
   */
  setAudioLanguage: PodcastProps['onChangeMediaLanguage'];
  /**
   * The currently selected audio language
   */
  audioLanguage: PodcastProps['selectedMediaLanguage'];
  onFinished?: PodcastProps['onFinished'];
  onClose: PlayerModalProps['onMinimize'];
  onMaximize: PodcastProps['onMaximize'];
  isMinimized: PodcastProps['isMinimized'];
  setIsMinimized: (value: boolean) => void;
}

export function PodcastPlayer({
  podcast,
  podcastTranscripts,
  onFinished,
  setAudioLanguage,
  audioLanguage,
  setTranscriptLanguage,
  transcriptLanguage,
  onMaximize,
  isMinimized,
  setIsMinimized,
  onClose
}: Readonly<PodcastPlayerProps>) {
  const [episodeIndex, setEpisodeIndex] = useState(0);

  const {
    playlistId,
    playlistType,
    playlistTitle,
    isLastContentInPlaylist,
    isHidden,
    contentUrl
  } = useAppSelector((state) => ({
    playlistId: getPlaylistId(state),
    playlistType: getPlaylistType(state),
    playlistTitle: getPlaylistTitle(state),
    isLastContentInPlaylist: getIsLastContentInPlaylist(state),
    isHidden: getIsTemporarilyHiddenAndPaused(state),
    contentUrl: getContentUrl(state)
  }));
  const dispatch = useAppDispatch();
  const { isInPreviewMode } = useFreeAccount();

  const { rate, trackProgress, trackFinished } = useContentActions();

  useNavigationListener(() => {
    if (!isMinimized) {
      setIsMinimized(true);
    }
  });

  const meta: PodcastProps['meta'] = useMemo(
    () => ({
      contentId: podcast.id,
      contentName: podcast.name,
      casting: podcast.experts
        .map((expert) => `${expert.firstName} ${expert.lastName}`)
        .join(', ')
    }),
    [podcast]
  );

  const episodes = useMemo(
    () =>
      podcast.episodes.map((episode) => ({
        name: episode.name,
        url: episode.audio?.uri ?? '',
        length: episode.audio?.length ?? 0
      })),
    // `episodes` is not memoized if we set `podcast.episodes` as a dependency,
    // so we use the `podcast.id` and `audioLanguage` instead because they are
    // the only things that can change the episodes from the podcast query
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [podcast.id, audioLanguage]
  );

  const onLike = useCallback(() => {
    rate(podcast, ContentVote.Upvote).catch((error) => captureException(error));
  }, [rate, podcast]);

  const onDislike = useCallback(() => {
    rate(podcast, ContentVote.Downvote).catch((error) =>
      captureException(error)
    );
  }, [rate, podcast]);

  const onAudioLanguageChange = useCallback(
    (code: string) => setAudioLanguage(code),
    [setAudioLanguage]
  );

  const onTranscriptLanguageChange = useCallback(
    (code: string | null) => setTranscriptLanguage(code),
    [setTranscriptLanguage]
  );

  const onTrackProgress = useCallback(
    (progress: number) => {
      trackProgress({
        content: podcast,
        progress,
        playlist: { playlistId, playlistType }
      }).catch((error) => captureException(error));
    },
    [podcast, trackProgress]
  );

  const onTrackFinished = useCallback(() => {
    trackFinished(podcast).catch((error) => captureException(error));
  }, [podcast, trackFinished]);

  const isFinished = podcast.userContentTracking.isFinished;

  const urlToShare = window.location.href;

  function onToggleAutoPlayNext(value: boolean) {
    dispatch(setAutoNextPlayToggle(value));
  }
  return (
    <Podcast
      poster={podcast.image?.uri ?? ''}
      meta={meta}
      previousProgress={
        isInPreviewMode ? 0 : podcast.userContentTracking.currentPlacement
      }
      onTrackProgress={onTrackProgress}
      onTrackFinished={onTrackFinished}
      onFinished={onFinished}
      isFinished={isFinished}
      isHidden={isHidden}
      isMinimized={isMinimized}
      tracks={episodes}
      trackIndex={episodeIndex}
      setTrackIndex={setEpisodeIndex}
      transcripts={podcastTranscripts?.transcripts}
      markdownComponent={SubOnlyProtectedMarkdownComponent}
      mediaLanguages={podcast.audioAvailableLanguages}
      selectedMediaLanguage={audioLanguage}
      onChangeMediaLanguage={onAudioLanguageChange}
      transcriptLanguages={podcast.transcriptAvailableLanguages}
      selectedTranscriptLanguage={transcriptLanguage}
      onChangeTranscriptLanguage={onTranscriptLanguageChange}
      vote={podcast.contentRatingByUser}
      onLike={onLike}
      onDislike={onDislike}
      onClose={() => {
        onClose();
        setIsMinimized(false);
      }}
      onMaximize={onMaximize}
      urlToShare={urlToShare}
      contentDescription={podcast.preamble}
      playlistType={playlistType}
      playlistTitle={playlistTitle}
      playlistId={playlistId}
      onAutoNextPlay={onToggleAutoPlayNext}
      isLastContentInPlaylist={isLastContentInPlaylist}
      customDuration={
        isInPreviewMode ? episodes[episodeIndex].length : undefined
      }
      contentUrl={contentUrl}
    />
  );
}
