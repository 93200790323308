import { Maybe, TimeRangeFilter, TriggerFrequency } from '@tigerhall/core';

import { ReportBuilderWizardStoreState } from '../stores';
import { apolloClient } from '~/api/apollo';
import {
  CreateAudienceDocument,
  CreateAudienceMutation,
  CreateAudienceMutationVariables
} from '~/generated';
import { AppError } from '~/utils';

async function getAudienceId(
  name: string,
  criteria?: ReportBuilderWizardStoreState['userFilters']['filterByAudience']
): Promise<string | null> {
  if (!criteria) {
    return null;
  }

  const resp = await apolloClient.mutate<
    CreateAudienceMutation,
    CreateAudienceMutationVariables
  >({
    mutation: CreateAudienceDocument,
    variables: {
      input: {
        name: `${name} - report builder audience`,
        description: 'Audience for report builder',
        triggerFrequency: TriggerFrequency.Never,
        removeUsersInNextRefreshThatNoLongerMeetCriteria: true,
        weeklyTriggerDays: [],
        criteria: criteria.api
      }
    }
  });

  if (!resp.data) {
    throw new AppError('Failed to create audience', {
      extra: { ...resp }
    });
  }

  return resp.data.createAudience.id;
}

export async function transformStateToDefaultFilters<T>({
  userFilters: { filterByUsers, filterByGroups, filterByAudience },
  reportFilters: { includeArchivedUsers },
  name,
  selectedColumns
}: ReportBuilderWizardStoreState) {
  const users =
    filterByUsers.length === 0 ? null : filterByUsers.map((r) => r.id);

  const groups =
    filterByGroups.length === 0 ? null : filterByGroups.map((r) => r.id);

  const audienceId = await getAudienceId(name, filterByAudience);

  return {
    reportName: name,
    columns: Array.from(selectedColumns) as T[],
    users,
    groups,
    audienceId,
    includeArchivedUsers: includeArchivedUsers.value !== 'false'
  };
}

export function transformStaticDateToTimeRange(
  input?: Date[]
): Maybe<TimeRangeFilter> {
  if (!input || input.length === 0) {
    return null;
  }

  return {
    from: input[0],
    to: input[1] ?? null
  };
}

type Option = { label: string; value: string };

export function extractStaticDropDownValue(
  opt: Option | Option[] | null
): string | string[] | null {
  if (!opt) {
    return null;
  }

  if (Array.isArray(opt)) {
    return opt.map((o) => o.value);
  }

  return opt.value;
}
