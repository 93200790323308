export function setSessionStorageObject(
  key: string,
  value: Record<string, unknown>
) {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
}

export function getSessionStorage(key: string) {
  if (typeof window !== 'undefined') {
    const value = window.sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }
}

export function removeSessionStorage(key: string) {
  if (typeof window !== 'undefined') {
    window.sessionStorage.removeItem(key);
  }
}
