import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { FormInput } from '@tigerhall/components';
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconWorldWww
} from '@tigerhall/icons';

type SocialInputFieldTypes = {
  name: string;
  icon: React.ReactNode;
  placeholder: string;
  showAt?: boolean;
  dataCy: string;
};

const SocialInputField: SocialInputFieldTypes[] = [
  {
    name: 'facebookLink',
    icon: <IconBrandFacebook color="white" />,
    placeholder: 'www.facebook.com/example',
    dataCy: 'facebook-field-input'
  },
  {
    name: 'twitterHandle',
    icon: <IconBrandTwitter color="white" />,
    placeholder: 'Twitter',
    showAt: true,
    dataCy: 'twitter-field-input'
  },
  {
    name: 'instagramHandle',
    icon: <IconBrandInstagram color="white" />,
    placeholder: 'Instagram',
    showAt: true,
    dataCy: 'instagram-field-input'
  },
  {
    name: 'linkedinLink',
    icon: <IconBrandLinkedin color="white" />,
    placeholder: 'www.linkedin.com/in/example',
    dataCy: 'linkedin-field-input'
  },
  {
    name: 'websiteLink',
    icon: <IconWorldWww color="white" />,
    placeholder: 'www.example.com',
    dataCy: 'website-field-input'
  }
];

export function OnWebSocials() {
  return (
    <Box mt="1rem">
      <Text fontSize="lg" fontWeight="semibold" color="lightGrey.200">
        On The Web
      </Text>
      <VStack mt={2} align="stretch" spacing=".75rem" display="flex" flex={1}>
        {SocialInputField.map((socialLinks) => (
          <Flex alignItems="center" position="relative" key={socialLinks.name}>
            <Box w="1.5rem" pt="1.2rem" mr={1}>
              {socialLinks.icon}
            </Box>

            <Box
              w="full"
              mt="0.5rem"
              css={{
                '& input': {
                  ...(socialLinks?.showAt && { paddingLeft: '1.25rem' })
                }
              }}
            >
              <FormInput
                name={socialLinks.name}
                type="text"
                placeholder={socialLinks.placeholder}
                label=""
              />
              {socialLinks.showAt ? (
                <Text
                  fontSize="md"
                  position="absolute"
                  left="2rem"
                  bottom="0.8rem"
                  color="lightGrey.200"
                  zIndex={1}
                >
                  @
                </Text>
              ) : null}
            </Box>
          </Flex>
        ))}
      </VStack>
    </Box>
  );
}
