import { Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';

import {
  AVATAR_SPACING,
  AVATAR_WIDTH,
  COMMENT_PADDING_LEFT
} from '../utils/constant';

export function CommentItemSkeleton() {
  return (
    <Flex gap="0.25rem" width="100%" flexDirection="column">
      <Flex gap={AVATAR_SPACING + 'rem'}>
        <SkeletonCircle size={AVATAR_WIDTH + 'rem'} />
        <Skeleton flex={1} height="4rem" width="100%" rounded="md" />
      </Flex>
      <Flex pl={COMMENT_PADDING_LEFT + 'rem'} gap="0.5rem" pt="0.75rem">
        <Skeleton width="6rem" height="1rem" />
        <Skeleton width="6rem" height="1rem" />
      </Flex>
    </Flex>
  );
}
