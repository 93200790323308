import { useState } from 'react';
import { Button, HStack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import type { User } from '@tigerhall/core/lib/types';
import { redirectToEditProfile } from 'modules/application/utils/routes';
import { IconBan, IconUserFilled } from '@tigerhall/icons';
import { Icon } from '@chakra-ui/icon';

import { BookMentorshipModal } from '../modals/BookMentorshipModal';
import { FollowButton } from '~/components/ui/Buttons';
import { Actions } from '~/modules/application/modules/Profile/components/Actions';

interface ProfileActionsProps {
  isSelfProfile: boolean;
  user: User;
}

export function ProfileActions({
  isSelfProfile,
  user
}: Readonly<ProfileActionsProps>) {
  const { isFollowing, isMentor } = user || {};

  const navigate = useNavigate();

  const [isMentorshipModalOpen, setIsMentorshipModalOpen] = useState(false);

  if (isSelfProfile) {
    return (
      <>
        <Button
          variant="outline"
          textTransform="initial"
          size="lg"
          onClick={() => navigate(redirectToEditProfile())}
        >
          Edit Profile
        </Button>
        <Actions isSelfProfile={isSelfProfile} user={user} />
      </>
    );
  }

  if (user.isBlockedByCurrentUser || user.isBlockingCurrentUser) {
    return (
      <>
        <HStack
          padding="0.75rem"
          backgroundColor="whiteAlpha.100"
          borderRadius="0.5rem"
        >
          <Icon boxSize={5} as={IconBan} color="darkGrey.50" />
          <Text
            color="darkGrey.50"
            fontSize="md"
            fontWeight="400"
            fontStyle="initial"
            lineHeight="xl"
          >
            User blocked
          </Text>
        </HStack>
        <Actions isSelfProfile={false} user={user} />
      </>
    );
  }

  if (isMentor) {
    return (
      <>
        {isMentorshipModalOpen ? (
          <BookMentorshipModal
            setMentorShipModalOpen={setIsMentorshipModalOpen}
            expert={user}
          />
        ) : null}
        <Button
          variant="solidDark"
          size="lg"
          textTransform="initial"
          rightIcon={<IconUserFilled />}
          onClick={() => setIsMentorshipModalOpen(true)}
        >
          Book mentorship
        </Button>
        <FollowButton isFollowing={isFollowing} userId={user.id} size="lg" />
        <Actions isSelfProfile={isSelfProfile} user={user} />
      </>
    );
  }
  return (
    <>
      <FollowButton isFollowing={isFollowing} userId={user.id} size="lg" />
      <Actions isSelfProfile={isSelfProfile} user={user} />
    </>
  );
}
