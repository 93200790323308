import type {
  AssignmentConnection,
  CommentCursorConnection,
  ConsumableContentCollection,
  ContentConnection,
  EventConnection,
  FeedContentConnection,
  LearningPathConnection,
  NotificationConnection,
  PodcastConnection,
  StreamConnection,
  UserCollection,
  UserReactionConnection,
} from '@tigerhall/core/lib/types';
import cloneDeep from 'lodash/cloneDeep';
import isNumber from 'lodash/isNumber';

export const SKIP_MERGE = 'skip_merge';

export function offsetPaginationPolicy<
  T extends
    | ContentConnection
    | ConsumableContentCollection
    | PodcastConnection
    | StreamConnection
    | EventConnection
    | LearningPathConnection
    | UserCollection
    | AssignmentConnection
>() {
  return {
    keyArgs: (args: any) => {
      const fields = cloneDeep(args);
      delete fields?.filter?.limit;
      delete fields?.filter?.offset;

      return JSON.stringify(fields);
    },

    merge(existing: T, incoming: T, { args }: { args: any }) {
      if (isNumber(args?.filter?.offset) && args?.filter?.offset !== 0) {
        const mergedEdges = existing?.edges ? existing?.edges.slice(0) : [];

        for (let i = 0; i < incoming?.edges?.length; i++) {
          mergedEdges[args?.filter?.offset + i] = incoming?.edges[i];
        }

        return {
          ...existing,
          ...incoming,
          edges: mergedEdges
        };
      }
      return incoming;
    }
  };
}

interface CursorPaginationPolicyProps {
  keyArgs?: string[];
}

export function cursorPaginationPolicy<
  T extends
    | NotificationConnection
    | FeedContentConnection
    | CommentCursorConnection
    | UserReactionConnection
>(props?: CursorPaginationPolicyProps) {
  return {
    // * use this key for skipping the default merge strategy incase of conflicting query. So, everything data will be fetched fresh without using any cache
    keyArgs: props?.keyArgs ?? [],
    merge(existing: T, incoming: T, { args }: { args: any }) {
      if (!existing) {
        return incoming;
      }

      if (!incoming?.edges?.length) {
        return existing;
      }

      // copied from mobile codebase
      if (incoming?.meta?.nextCursor === SKIP_MERGE) {
        return {
          ...incoming,
          meta: existing?.meta
        };
      }

      if (
        args?.pagination?.afterCursor ===
        existing?.edges?.[existing.edges.length - 1]?.cursor
      ) {
        return {
          meta: {
            ...existing.meta,
            hasNext: incoming.meta.hasNext,
            nextCursor: incoming.meta.nextCursor,
            hasPrev: existing.meta.hasPrev,
            prevCursor: existing.meta.prevCursor
          },
          edges: [...existing.edges, ...incoming.edges]
        };
      }

      if (args?.pagination?.beforeCursor === existing?.edges?.[0]?.cursor) {
        return {
          meta: {
            ...existing.meta,
            hasPrev: incoming.meta.hasPrev || existing.meta.hasPrev,
            prevCursor: incoming.meta.prevCursor || existing.meta.prevCursor,
            hasNext: existing.meta.hasNext,
            nextCursor: existing.meta.nextCursor
          },
          edges: [...incoming.edges, ...existing.edges]
        };
      }

      // default case
      return incoming;
    }
  };
}

export function legacyCursorPaginationPolicy<T extends CommentCursorConnection>(
  props?: CursorPaginationPolicyProps
) {
  return {
    // * use this key for skipping the default merge strategy incase of conflicting query. So, everything data will be fetched fresh without using any cache
    keyArgs: props?.keyArgs ?? [],
    merge(existing: T, incoming: T, { args }: { args: any }) {
      if (!existing) {
        return incoming;
      }

      if (!incoming?.edges?.length) {
        return existing;
      }

      // copied from mobile codebase
      if (incoming?.meta?.nextCursor === SKIP_MERGE) {
        return {
          ...incoming,
          meta: existing?.meta
        };
      }

      if (
        args?.afterCursor ===
        existing?.edges?.[existing.edges.length - 1]?.cursor
      ) {
        return {
          meta: {
            ...existing.meta,
            hasNext: incoming.meta.hasNext,
            nextCursor: incoming.meta.nextCursor,
            hasPrev: existing.meta.hasPrev,
            prevCursor: existing.meta.prevCursor
          },
          edges: [...existing.edges, ...incoming.edges]
        };
      }

      if (args?.beforeCursor === existing?.edges?.[0]?.cursor) {
        return {
          meta: {
            ...existing.meta,
            hasPrev: incoming.meta.hasPrev || existing.meta.hasPrev,
            prevCursor: incoming.meta.prevCursor || existing.meta.prevCursor,
            hasNext: existing.meta.hasNext,
            nextCursor: existing.meta.nextCursor
          },
          edges: [...incoming.edges, ...existing.edges]
        };
      }

      // default case
      return incoming;
    }
  };
}
