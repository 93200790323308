import { MentionInput, TargetType } from '@tigerhall/core';

export function extractMentions(markdown: string): {
  mentions: MentionInput[];
} {
  const mentionPattern = /@\[(.+?)\]\((.+?)\)/g;
  const mentions: MentionInput[] = [];
  let match: RegExpExecArray | null;

  while ((match = mentionPattern.exec(markdown)) !== null) {
    mentions.push({
      targetId: match[2],
      index: match.index,
      targetType: TargetType.User as const // Explicitly type as const to ensure it's the correct literal type
    });
  }

  return { mentions };
}
