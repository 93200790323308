import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from './image.generated';
import { CategoryFragmentDoc } from './category.generated';
export type LifeGoalFragment = { __typename?: 'Lifegoal', id: string, name: string, contentCount?: number | null, image?: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string } | null, categories: Array<{ __typename?: 'Category', id: string, name: string, image?: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string } | null }>, icon?: { __typename?: 'Image', id: string, uri: string, alt: string, filename: string } | null };

export const LifeGoalFragmentDoc = gql`
    fragment LifeGoal on Lifegoal {
  id
  name
  image {
    ...Image
  }
  categories {
    ...Category
  }
  contentCount
  icon {
    ...Image
  }
}
    ${ImageFragmentDoc}
${CategoryFragmentDoc}`;