import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/reducer';
import { AudienceConditionsFormValues } from 'modules/admin/modules/audiences/modals/ConditionsModal/types';

const defaultConditions = {
  any: [
    {
      bool: {
        all: [
          {
            condition: undefined,
            operator: undefined,
            value: ''
          }
        ]
      }
    }
  ]
};

interface State {
  conditionsStructureForUI: AudienceConditionsFormValues;
}

const INITIAL_STATE: State = {
  conditionsStructureForUI:
    defaultConditions as unknown as AudienceConditionsFormValues
};

export const audienceConditionsSlice = createSlice({
  name: 'audienceConditions',
  initialState: INITIAL_STATE,
  reducers: {
    setConditionsStructureForUI(
      state,
      action: PayloadAction<State['conditionsStructureForUI']>
    ): State {
      return {
        ...state,
        conditionsStructureForUI: action.payload
      };
    },
    resetConditions(): State {
      return INITIAL_STATE;
    }
  }
});

export const { setConditionsStructureForUI, resetConditions } =
  audienceConditionsSlice.actions;

export default audienceConditionsSlice.reducer;

// ------------------------------------
// Selectors
// ------------------------------------
export const getConditionsStructureForUI = (state: RootState) =>
  state.audienceConditions.conditionsStructureForUI;
