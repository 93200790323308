import { Box } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { type DataFunc, Mention, MentionsInput } from 'react-mentions';

import {
  mentionDefaultStyle,
  mentionInputDefaultStyle
} from '../style/defaultStyle';
import {
  getUsers,
  handleChange,
  handleMentionFinised,
  handleMentionsStartEvent,
  renderLoadingIndicator
} from '../utils';
import type {
  MentionInputProps,
  MentionsPreviousStateRef,
  SuggestionData
} from '../utils/types';
import { CustomSuggestionsContainer } from './CustomSuggestionsContainer';
import { MentionList } from './MentionList';

export function MentionInput({
  name,
  placeholder,
  value,
  onChange,
  inputRef,
  fetchSuggestions,
  referenceId,
  ...rest
}: MentionInputProps) {
  const [isLoading, setIsLoading] = useState(false);

  const fetchUsers: DataFunc = async (query, callback) => {
    await getUsers({
      fetchSuggestions,
      query,
      setIsLoading,
      callback,
      isLoading
    });
  };

  const mentionsPreviousState: MentionsPreviousStateRef = useRef({
    mentions: [],
    length: 0
  });

  useEffect(() => {
    renderLoadingIndicator(isLoading);
  }, [isLoading]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        maxWidth="100%"
        className="mention-container"
        __css={{
          '& > .mentions': {
            maxWidth: '100%',
            minWidth: '100%',
            '& > .mentions__control': {
              minHeight: '3rem',
              '& .mentions__input': {
                padding: '0.7rem',
                wordBreak: 'break',
                alignSelf: 'center',
                gap: '1rem',
                color: 'lightGrey.400',
                outline: 'none',
                overflow: 'scroll',
                borderRadius: '0.3rem'
              }
            }
          }
        }}
      >
        <MentionsInput
          name={name}
          inputRef={inputRef}
          value={value}
          onKeyDown={(e) => handleMentionsStartEvent({ e, referenceId })}
          className="mentions"
          placeholder={placeholder ?? 'use the @ symbol to tag other users.'}
          customSuggestionsContainer={(children) => (
            <CustomSuggestionsContainer>{children}</CustomSuggestionsContainer>
          )}
          style={mentionInputDefaultStyle}
          onChange={(_, rawText, newPlainText, mentions) => {
            handleChange({
              onChange,
              rawText,
              newPlainText,
              mentions,
              mentionsPreviousState,
              referenceId
            });
          }}
          onPaste={(event) => {
            // Prevents duplicate text when pasting. The user can still paste
            event.preventDefault();
          }}
          allowSuggestionsAboveCursor={true}
          allowSpaceInQuery={true}
          autoFocus={false}
          {...rest}
        >
          <Mention
            className="mention-suggestion"
            trigger="@"
            style={mentionDefaultStyle}
            isLoading={isLoading}
            onAdd={() => handleMentionFinised(referenceId)}
            markup="@[__display__](__id__) "
            renderSuggestion={(
              suggestion,
              search,
              highlightedDisplay,
              index,
              focused
            ) => (
              <MentionList
                focused={focused}
                // over-riding the internal type of suggestion because
                // need to add more fields to suggestion object
                suggestion={suggestion as unknown as SuggestionData}
              />
            )}
            data={fetchUsers}
            displayTransform={(_, display) => `@${display} `}
          />
        </MentionsInput>
      </Box>
    </>
  );
}
