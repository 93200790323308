import { IconX } from '@tigerhall/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text
} from '@chakra-ui/react';
import * as React from 'react';
import {
  Controller,
  type UseControllerProps,
  type UseFormReturn,
  useController,
  useFormContext
} from 'react-hook-form';

export interface FormUploadVideo {
  name: string;
  label: string;
  hint?: string;
  disabled?: boolean;
  rules?: UseControllerProps['rules'];
  analyticsDisabled?: boolean;
}

export function FormVideoUpload({
  name,
  label,
  hint = '(Supported formats: .mp4 .wav)',
  disabled = false,
  rules = {},
  analyticsDisabled = false
}: FormUploadVideo) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const {
    field: { value, onBlur, onChange },
    fieldState: { error }
  } = useController({
    name: name
  });

  const onRemove = React.useCallback(() => {
    onChange(null);
  }, [onChange]);

  const onElementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    onChange(file);
  };

  const methods = useFormContext() as UseFormReturn & {
    formName: string;
  };

  if (methods === null) {
    return <Text color="state.error">Missing form context</Text>;
  }

  if (!methods.formName && !analyticsDisabled) {
    return <Text color="state.error">Missing form name</Text>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      render={({ field, fieldState }) => (
        <FormControl
          isInvalid={!!error}
          isRequired={!!rules?.required}
          isDisabled={disabled}
        >
          <FormLabel>{label}</FormLabel>
          <Flex
            direction="column"
            backgroundColor="darkGrey.400"
            p="1rem"
            border="1px solid"
            borderColor="darkGrey.200"
            borderRadius="lg"
          >
            {hint ? (
              <Text
                fontSize="0.875rem"
                color="lightGrey.400"
                fontWeight="500"
                mb="1rem"
              >
                {hint}
              </Text>
            ) : null}
            {value ? (
              <Box
                bg="darkGrey.700"
                borderRadius="md"
                borderWidth="1px"
                borderColor="darkGrey.200"
                p="0.5rem"
              >
                <Flex justifyContent="space-between">
                  <Flex direction="column" justifyContent="space-between">
                    <Text fontSize="0.875rem" color="lightGrey.900">
                      Video selected:
                    </Text>
                    <Text fontSize="1rem" fontWeight="770" color="white">
                      {value.name}
                    </Text>
                  </Flex>
                  <Flex
                    w={12}
                    h={12}
                    borderLeft="1px"
                    borderColor="#6F6F6F"
                    alignSelf="center"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    onClick={onRemove}
                  >
                    <IconX height={4} width={4} />
                  </Flex>
                </Flex>
              </Box>
            ) : (
              <Box position="relative">
                <Input
                  name={field.name}
                  type="file"
                  ref={(instance) => {
                    field.ref(instance);
                    // @ts-expect-error - ref.current is read-only to enforce good practices but we need to set it here
                    inputRef.current = instance;
                  }}
                  style={{ display: 'none' }}
                  onChange={onElementChange}
                  onBlur={onBlur}
                  accept="video/mp4,video/x-m4v,video/*"
                  data-cy={`${methods.formName}-${name}`}
                  required={!!rules?.required}
                />

                <Button
                  size="md"
                  onClick={() => inputRef.current?.click()}
                  data-cy={`${methods.formName}-${name}-upload-button`}
                >
                  Upload/Browse Video
                </Button>
              </Box>
            )}
            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </Flex>
        </FormControl>
      )}
    />
  );
}
