import { SubscriptionStatus } from '@tigerhall/core/lib/types';
import { useGetFreeTrialUserInfoQuery } from 'queries/user/generated/me.generated';
import { getAccessToken } from 'app/state';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { useAppSelector } from './redux';

interface FreeAccountReturns {
  /**
   * Numbers of trial days left for a free user
   */
  freeTrialLeftDays: number;

  /**
   * if user is allowed to subscribe a plan.
   */
  canSubscribe: boolean;

  /**
   * for showing preview mode -`20%` limit
   */
  isInPreviewMode: boolean;

  /**
   * when `SubscriptionStatus` is `FREE`
   */
  hasFreeSubscription: boolean;

  /**
   * when `SubscriptionStatus` is `TRIAL`
   */
  hasTrialSubscription: boolean;
}

export function useFreeAccount(): FreeAccountReturns {
  const { data } = useGetFreeTrialUserInfoQuery({
    fetchPolicy: 'cache-and-network'
  });

  const { me: currentUser } = data || {};

  const accessToken = useAppSelector(getAccessToken);
  const isUserLoggedIn = Boolean(accessToken);
  const hasOrg = Boolean(currentUser?.organisation);

  const endsAt = currentUser?.organisation?.subscription?.endsAt;

  const freeTrialEndDate = hasOrg ? endsAt : currentUser?.freeTrialEndsAt;

  const hasFreeSubscription =
    currentUser?.subscriptionStatus === SubscriptionStatus.Free;

  const hasTrialSubscription =
    currentUser?.subscriptionStatus === SubscriptionStatus.Trial;

  const isInPreviewMode = hasFreeSubscription && isUserLoggedIn;

  const freeTrialLeftDays = useMemo(() => {
    if (!hasTrialSubscription) {
      return 0;
    }

    const currentDate = DateTime.now();

    const targetEndDate = DateTime.fromISO(freeTrialEndDate);

    const difference = targetEndDate.diff(currentDate, ['days']);

    return difference.toObject().days ?? 0;
  }, [freeTrialEndDate, hasTrialSubscription]);

  const canSubscribe = !currentUser?.organisation?.type;

  return {
    freeTrialLeftDays,
    canSubscribe,
    isInPreviewMode,
    hasFreeSubscription,
    hasTrialSubscription
  };
}
