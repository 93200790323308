import * as React from 'react';
import { HStack, VStack, Text, SimpleGrid, Box, Stack } from '@chakra-ui/react';
import { useDayzed, Props as DayzedHookProps } from 'dayzed';

import { ArrowKeysReact } from '../utils/reactKeysArrow';
import { CalendarConfigs, DatepickerProps } from '../types';
import { DatepickerBackBtn, DatepickerForwardBtn } from './DatePickerNavBtns';
import { DayOfMonth } from './DayOfMonth';

interface CalendarPanelProps extends DatepickerProps {
  dayzedHookProps: Omit<DayzedHookProps, 'children' | 'render'>;
  configs: CalendarConfigs;
  onMouseEnterHighlight?: (date: Date) => void;
  isInRange?: (date: Date) => boolean | null;
}

export const CalendarPanel: React.FC<CalendarPanelProps> = ({
  dayzedHookProps,
  configs,
  propsConfigs,
  onMouseEnterHighlight,
  isInRange
}) => {
  const renderProps = useDayzed(dayzedHookProps);
  const { calendars, getBackProps, getForwardProps } = renderProps;

  const weekdayNames = React.useMemo(() => {
    const firstDayOfWeek = configs.firstDayOfWeek;
    const dayNames = configs.dayNames;
    if (firstDayOfWeek && firstDayOfWeek > 0) {
      return configs.dayNames
        .slice(firstDayOfWeek, dayNames.length)
        .concat(dayNames.slice(0, firstDayOfWeek));
    }
    return dayNames;
  }, [configs.firstDayOfWeek, configs.dayNames]);

  // looking for a useRef() approach to replace it
  const getKeyOffset = React.useCallback((num: number) => {
    const e = document.activeElement;
    const buttons = document.querySelectorAll('button');
    buttons.forEach((el, i) => {
      const newNodeKey = i + num;
      if (el === e) {
        if (newNodeKey <= buttons.length - 1 && newNodeKey >= 0) {
          buttons[newNodeKey].focus();
        } else {
          buttons[0].focus();
        }
      }
    });
  }, []);

  const arrowKeysReact = new ArrowKeysReact({
    left: () => {
      getKeyOffset(-1);
    },
    right: () => {
      getKeyOffset(1);
    },
    up: () => {
      getKeyOffset(-7);
    },
    down: () => {
      getKeyOffset(7);
    }
  });

  if (calendars.length <= 0) {
    return null;
  }

  return (
    <Stack
      className="datepicker-calendar"
      gap="1.5rem"
      direction={{
        base: 'column',
        md: 'row'
      }}
      {...arrowKeysReact.getEvents()}
    >
      {calendars.map((calendar, calendarIdx) => {
        return (
          <VStack key={`calendar-${calendarIdx}`} height="100%" spacing="2rem">
            <HStack justifyContent="space-between" width="100%">
              <Text as="span" fontSize="sm" textAlign="center" color="white">
                {configs.monthNames[calendar.month]} {calendar.year}
              </Text>
              <HStack>
                <DatepickerBackBtn
                  calendars={calendars}
                  getBackProps={getBackProps}
                  propsConfigs={propsConfigs}
                />
                <DatepickerForwardBtn
                  calendars={calendars}
                  getForwardProps={getForwardProps}
                  propsConfigs={propsConfigs}
                />
              </HStack>
            </HStack>
            <SimpleGrid columns={7} rowGap="0.25rem" textAlign="center">
              {weekdayNames.map((day, dayIdx) => (
                <Text
                  fontSize="xs"
                  fontWeight="bold"
                  key={`calendar-${calendarIdx}-day-${dayIdx}`}
                  color="white"
                  pb=".5rem"
                >
                  {day}
                </Text>
              ))}
              {calendar.weeks.map((week, weekIdx) => {
                return week.map((dateObj, index) => {
                  const key = `${calendar.month}-${calendar.year}-${weekIdx}-${index}`;
                  if (!dateObj) {
                    return <Box key={key} />;
                  }
                  const { date } = dateObj;
                  return (
                    <DayOfMonth
                      key={key}
                      dateObj={dateObj}
                      propsConfigs={propsConfigs}
                      renderProps={renderProps}
                      isInRange={isInRange && isInRange(date)}
                      onMouseEnter={() => {
                        if (onMouseEnterHighlight) {
                          onMouseEnterHighlight(date);
                        }
                      }}
                    />
                  );
                });
              })}
            </SimpleGrid>
          </VStack>
        );
      })}
    </Stack>
  );
};
