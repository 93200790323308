import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Text
} from '@chakra-ui/react';
import * as React from 'react';
import {
  Controller,
  UseControllerProps,
  UseFormReturn,
  useFormContext
} from 'react-hook-form';

export interface FormInputProps {
  /**
   * The name of the input
   */
  name: string;
  /**
   * The type of the input form
   * @default "text"
   */
  type?: Exclude<InputProps['type'], 'radio'>;
  /**
   * The label of the tooltip
   */
  label: string;
  /**
   * Hide the label
   * @default false
   */
  hideLabel?: boolean;
  /**
   * Placeholder text
   */
  placeholder: string;
  /**
   * If the component is disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * Helps the browser auto complete the contents for this field and should be one of
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values
   */
  autoComplete?: InputProps['autoComplete'];
  /**
   * Rules extended from `react-hook-forms` controller.
   * @see https://react-hook-form.com/docs/usecontroller
   */
  rules?: UseControllerProps['rules'];
  /**
   * If `true` it won't be mandatory to get the `formName` from `useFormContext`,
   * which is used for analytics.
   * @default false
   */
  analyticsDisabled?: boolean;

  /**
   * The optional left element to be displayed
   */
  leftAddon?: React.ReactNode;

  /**
   * The optional right element to be displayed
   */
  rightAddon?: React.ReactNode;
}

export function FormInput({
  name,
  type = 'text',
  label,
  placeholder,
  autoComplete,
  rules = {},
  disabled = false,
  analyticsDisabled = false,
  leftAddon,
  rightAddon,
  hideLabel = false
}: FormInputProps) {
  const methods = useFormContext() as UseFormReturn & {
    formName: string;
  };

  const ariaLabelForInput: FormLabelProps = {
    ...(hideLabel && {
      'aria-label': label,
      'aria-labelledby': undefined
    })
  };

  if (methods === null) {
    return <Text color="state.error">Missing form context</Text>;
  }

  if (!methods.formName && !analyticsDisabled) {
    return <Text color="state.error">Missing form name</Text>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rules}
      defaultValue={null}
      render={({ field, fieldState }) => (
        <FormControl
          isInvalid={!!fieldState.error}
          isRequired={!!rules?.required}
          isDisabled={disabled}
        >
          {!hideLabel && <FormLabel {...ariaLabelForInput}>{label}</FormLabel>}
          <InputGroup>
            {leftAddon && <InputLeftElement>{leftAddon}</InputLeftElement>}
            <Input
              name={field.name}
              type={type}
              autoComplete={autoComplete}
              placeholder={placeholder}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              ref={field.ref}
              data-cy={`${methods.formName}-${name}`}
            />
            {rightAddon && <InputRightElement>{rightAddon}</InputRightElement>}
          </InputGroup>
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}
