import { useToast } from '@chakra-ui/react';
import { TrackedForm, FormInput, TrackedButton } from '@tigerhall/components';
import { getFreeTrialUserEmail, setFreeTrialEmail } from 'app/state';
import { useVerifyEmailExistMutation } from 'generated';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';

type FormFields = {
  email: string;
};

export function FreeTrialEmailForm() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [verifyEmailExist] = useVerifyEmailExistMutation();
  const toast = useToast();
  const freeTrialEmail = useAppSelector(getFreeTrialUserEmail);

  const handleEmailFormSubmission = useCallback(
    async (value: FormFields) => {
      const { email } = value;

      try {
        const { data } = await verifyEmailExist({
          variables: {
            email
          }
        });

        if (data?.emailExists) {
          toast({
            description:
              'You are already part of Tigerhall. Login to your account',
            status: 'warning'
          });
          return;
        }

        dispatch(setFreeTrialEmail(value.email));
        navigate('/signup/individual/claim-free-trial');
      } catch (error) {
        toast({
          title: 'ERROR!',
          description:
            error instanceof Error ? error.message : 'Something went wrong.',
          status: 'error'
        });
        captureException(error, {
          extra: {
            email
          }
        });
      }
    },
    [verifyEmailExist, dispatch, navigate, toast]
  );

  return (
    <TrackedForm<FormFields>
      name="freeTrialEmailForm"
      onSubmit={handleEmailFormSubmission}
      formHookProps={{
        defaultValues: {
          email: freeTrialEmail
        }
      }}
    >
      {({ formState: { isSubmitting } }) => (
        <>
          <FormInput
            name="email"
            type="email"
            label="Your Work Email"
            autoComplete="email"
            placeholder="Enter Your Work Email"
            rules={{ required: true }}
          />
          <TrackedButton
            mt="2.5rem"
            type="submit"
            size="lg"
            w="100%"
            name="freeTrialEmailSubmit"
            isLoading={isSubmitting}
          >
            Next
          </TrackedButton>
        </>
      )}
    </TrackedForm>
  );
}
