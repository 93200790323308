import { useToast } from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { useStructuredErrors } from '@tigerhall/core';
import { setAccessToken } from 'app/state';
import { useLoginEmailV2Mutation } from 'generated';
import { useAppDispatch } from 'hooks';
import { useNavigate } from 'react-router-dom';

type FormFields = {
  email: string;
  password: string;
};

export function useLoginWithEmailHook() {
  const navigate = useNavigate();
  const [login] = useLoginEmailV2Mutation();
  const dispatch = useAppDispatch();
  const { captureMutationError, handleMutationError } = useStructuredErrors();
  const toast = useToast({ status: 'error' });

  const triggerLogin = async (
    { email, password }: FormFields,
    { setError }
  ) => {
    try {
      const res = await login({
        variables: {
          email: email,
          password: password
        }
      });

      const data = res?.data?.loginEmailV2;

      if (!data) {
        throw new Error("Response doesn't contain any data");
      }

      captureMutationError(data, setError, (description) =>
        toast({ description })
      );
      const isAuthResponse = data?.__typename === 'AuthenticationResponse';

      if (!isAuthResponse) {
        throw new Error('No Authentication Response');
      }

      const { jwt } = data;

      if (!jwt) {
        throw new Error('jwt token not found');
      }

      dispatch(
        setAccessToken({
          token: jwt
        })
      );

      navigate('/home');
    } catch (err) {
      if (handleMutationError(err)) {
        return;
      }

      toast({
        description: (err as { message: string })?.message
      });

      captureException(err, {
        extra: {
          email
        }
      });
    }
  };

  return {
    triggerLogin
  };
}
