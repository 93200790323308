import AppleSignin from 'react-apple-signin-auth';
import { useLoginAppleMutation } from 'generated';
import { Button, Center, Icon, Text } from '@chakra-ui/react';
import { IconBrandApple } from '@tigerhall/icons';
import { setAccessToken } from 'app/state';
import { useAppDispatch } from 'hooks';
import * as segment from '@tigerhall/analytics';

/**
 * Sign in with Apple button
 *
 * If you ever try and work with this component, you will be cursed with bad luck....
 *
 * Joking aside, you need to be using ngrok because Apple requires https (which is fair enough) but only only that
 * you also need access to the Apple developer console to add the ngrok as a valid domain.
 *
 * Go here to update the ngrok domains
 * https://developer.apple.com/account/resources/identifiers/serviceId/edit/3656U9XJQP
 */
export function SignInWithApple() {
  const dispatch = useAppDispatch();
  const [mutation] = useLoginAppleMutation();

  if (localStorage.getItem('showAppleSSO') !== 'true') {
    return null;
  }

  const clientId =
    localStorage.get('appleClientId') || import.meta.env.VITE_APPLE_CLIENT_ID;

  const redirectURI =
    localStorage.get('appleRedirectUri') ||
    import.meta.env.VITE_APPLE_REDIRECT_URI;

  return (
    <AppleSignin
      authOptions={{
        clientId,
        redirectURI,
        usePopup: true
      }}
      onSuccess={async ({ authorization }) => {
        const response = await mutation({
          variables: {
            authorizationCode: authorization.code,
            firstName: authorization?.user?.firstName || '',
            lastName: authorization?.user?.lastName || ''
          }
        });

        dispatch(
          setAccessToken({
            token: response.data?.loginApple?.jwt as string
          })
        );
      }}
      onError={(error) => {
        console.log(error);
      }}
      render={(props) => (
        <Button
          leftIcon={<Icon as={IconBrandApple} />}
          w="100%"
          p="0.75rem"
          minH="2.7rem"
          onClick={() => {
            segment.buttonClicked({
              location: window.location.pathname,
              buttonName: 'APPLE_SSO_LOGIN'
            });

            props.onClick();
          }}
        >
          <Center>
            <Text color="darkGrey.300" fontSize="1rem" my="0.75rem" as="span">
              Sign in with Apple
            </Text>
          </Center>
        </Button>
      )}
    />
  );
}
