import { CloseButton, Flex } from '@chakra-ui/react';
import React from 'react';

type Props = {
  children: React.ReactNode;
  onClose: () => void;
};

const BookMentorshipModalContent = ({ children, onClose }: Props) => (
  <>
    <Flex w="100%" justifyContent="flex-end" onClick={onClose} p={6} pb={3}>
      <CloseButton color="orange.600" size="lg" w="16px" h="16px" />
    </Flex>
    {children}
  </>
);

export default BookMentorshipModalContent;
