import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportActivationByUserReportMutationVariables = SchemaTypes.Exact<{
  filter: SchemaTypes.ActivationByUserReportFilter;
}>;


export type ExportActivationByUserReportMutation = { __typename?: 'Mutation', exportActivationByUserReport: { __typename?: 'ReportBuilderResult', temporalWorkflowId: string } };

export type ExportActivationOverviewReportMutationVariables = SchemaTypes.Exact<{
  filter: SchemaTypes.ActivationOverviewReportFilter;
}>;


export type ExportActivationOverviewReportMutation = { __typename?: 'Mutation', exportActivationOverviewReport: { __typename?: 'ReportBuilderResult', temporalWorkflowId: string } };

export type ExportUserContentStatisticsReportMutationVariables = SchemaTypes.Exact<{
  filter: SchemaTypes.UserContentStatisticsReportFilter;
}>;


export type ExportUserContentStatisticsReportMutation = { __typename?: 'Mutation', exportUserContentStatisticsReport: { __typename?: 'ReportBuilderResult', temporalWorkflowId: string } };

export type ExportUserAssignmentStatisticsReportMutationVariables = SchemaTypes.Exact<{
  filter: SchemaTypes.UserAssignmentStatisticsReportFilter;
}>;


export type ExportUserAssignmentStatisticsReportMutation = { __typename?: 'Mutation', exportUserAssignmentStatisticsReport: { __typename?: 'ReportBuilderResult', temporalWorkflowId: string } };

export type ExportUserTrailStatisticsReportMutationVariables = SchemaTypes.Exact<{
  filter: SchemaTypes.UserTrailStatisticsReportFilter;
}>;


export type ExportUserTrailStatisticsReportMutation = { __typename?: 'Mutation', exportUserTrailStatisticsReport: { __typename?: 'ReportBuilderResult', temporalWorkflowId: string } };

export type ExportEngagementReportMutationVariables = SchemaTypes.Exact<{
  filter: SchemaTypes.EngagementReportFilter;
}>;


export type ExportEngagementReportMutation = { __typename?: 'Mutation', exportEngagementReport: { __typename?: 'ReportBuilderResult', temporalWorkflowId: string } };

export type ExportUserCommentsReportMutationVariables = SchemaTypes.Exact<{
  filter: SchemaTypes.UserCommentsReportFilter;
}>;


export type ExportUserCommentsReportMutation = { __typename?: 'Mutation', exportUserCommentsReport: { __typename?: 'ReportBuilderResult', temporalWorkflowId: string } };

export type ExportContentStatisticsReportMutationVariables = SchemaTypes.Exact<{
  filter: SchemaTypes.ContentStatisticsReportFilter;
}>;


export type ExportContentStatisticsReportMutation = { __typename?: 'Mutation', exportContentStatisticsReport: { __typename?: 'ReportBuilderResult', temporalWorkflowId: string } };

export type GetOrgUsersListQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
  filter?: SchemaTypes.InputMaybe<SchemaTypes.OrganisationMemberFilter>;
  isCurrentUserAdmin: SchemaTypes.Scalars['Boolean'];
}>;


export type GetOrgUsersListQuery = { __typename?: 'Query', organisation: { __typename: 'Organisation', id: string, members: { __typename?: 'UserCollection', edges: Array<{ __typename?: 'User', id: string, firstName: string, lastName: string, email?: string, avatarImage: { __typename?: 'Image', id: string, uri: string, blurHash: string } }>, meta: { __typename?: 'PageInfo', total: number, offset: number, limit: number } } } };

export type GetOrgCreatedAtQueryVariables = SchemaTypes.Exact<{
  organisationId: SchemaTypes.Scalars['String'];
}>;


export type GetOrgCreatedAtQuery = { __typename?: 'Query', organisation: { __typename?: 'Organisation', createdAt: any, id: string, members: { __typename?: 'UserCollection', meta: { __typename?: 'PageInfo', total: number } } } };


export const ExportActivationByUserReportDocument = gql`
    mutation ExportActivationByUserReport($filter: ActivationByUserReportFilter!) {
  exportActivationByUserReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportActivationByUserReportMutationFn = Apollo.MutationFunction<ExportActivationByUserReportMutation, ExportActivationByUserReportMutationVariables>;

/**
 * __useExportActivationByUserReportMutation__
 *
 * To run a mutation, you first call `useExportActivationByUserReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportActivationByUserReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportActivationByUserReportMutation, { data, loading, error }] = useExportActivationByUserReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportActivationByUserReportMutation(baseOptions?: Apollo.MutationHookOptions<ExportActivationByUserReportMutation, ExportActivationByUserReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportActivationByUserReportMutation, ExportActivationByUserReportMutationVariables>(ExportActivationByUserReportDocument, options);
      }
export type ExportActivationByUserReportMutationHookResult = ReturnType<typeof useExportActivationByUserReportMutation>;
export type ExportActivationByUserReportMutationResult = Apollo.MutationResult<ExportActivationByUserReportMutation>;
export type ExportActivationByUserReportMutationOptions = Apollo.BaseMutationOptions<ExportActivationByUserReportMutation, ExportActivationByUserReportMutationVariables>;
export const ExportActivationOverviewReportDocument = gql`
    mutation ExportActivationOverviewReport($filter: ActivationOverviewReportFilter!) {
  exportActivationOverviewReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportActivationOverviewReportMutationFn = Apollo.MutationFunction<ExportActivationOverviewReportMutation, ExportActivationOverviewReportMutationVariables>;

/**
 * __useExportActivationOverviewReportMutation__
 *
 * To run a mutation, you first call `useExportActivationOverviewReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportActivationOverviewReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportActivationOverviewReportMutation, { data, loading, error }] = useExportActivationOverviewReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportActivationOverviewReportMutation(baseOptions?: Apollo.MutationHookOptions<ExportActivationOverviewReportMutation, ExportActivationOverviewReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportActivationOverviewReportMutation, ExportActivationOverviewReportMutationVariables>(ExportActivationOverviewReportDocument, options);
      }
export type ExportActivationOverviewReportMutationHookResult = ReturnType<typeof useExportActivationOverviewReportMutation>;
export type ExportActivationOverviewReportMutationResult = Apollo.MutationResult<ExportActivationOverviewReportMutation>;
export type ExportActivationOverviewReportMutationOptions = Apollo.BaseMutationOptions<ExportActivationOverviewReportMutation, ExportActivationOverviewReportMutationVariables>;
export const ExportUserContentStatisticsReportDocument = gql`
    mutation ExportUserContentStatisticsReport($filter: UserContentStatisticsReportFilter!) {
  exportUserContentStatisticsReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportUserContentStatisticsReportMutationFn = Apollo.MutationFunction<ExportUserContentStatisticsReportMutation, ExportUserContentStatisticsReportMutationVariables>;

/**
 * __useExportUserContentStatisticsReportMutation__
 *
 * To run a mutation, you first call `useExportUserContentStatisticsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUserContentStatisticsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUserContentStatisticsReportMutation, { data, loading, error }] = useExportUserContentStatisticsReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportUserContentStatisticsReportMutation(baseOptions?: Apollo.MutationHookOptions<ExportUserContentStatisticsReportMutation, ExportUserContentStatisticsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportUserContentStatisticsReportMutation, ExportUserContentStatisticsReportMutationVariables>(ExportUserContentStatisticsReportDocument, options);
      }
export type ExportUserContentStatisticsReportMutationHookResult = ReturnType<typeof useExportUserContentStatisticsReportMutation>;
export type ExportUserContentStatisticsReportMutationResult = Apollo.MutationResult<ExportUserContentStatisticsReportMutation>;
export type ExportUserContentStatisticsReportMutationOptions = Apollo.BaseMutationOptions<ExportUserContentStatisticsReportMutation, ExportUserContentStatisticsReportMutationVariables>;
export const ExportUserAssignmentStatisticsReportDocument = gql`
    mutation ExportUserAssignmentStatisticsReport($filter: UserAssignmentStatisticsReportFilter!) {
  exportUserAssignmentStatisticsReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportUserAssignmentStatisticsReportMutationFn = Apollo.MutationFunction<ExportUserAssignmentStatisticsReportMutation, ExportUserAssignmentStatisticsReportMutationVariables>;

/**
 * __useExportUserAssignmentStatisticsReportMutation__
 *
 * To run a mutation, you first call `useExportUserAssignmentStatisticsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUserAssignmentStatisticsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUserAssignmentStatisticsReportMutation, { data, loading, error }] = useExportUserAssignmentStatisticsReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportUserAssignmentStatisticsReportMutation(baseOptions?: Apollo.MutationHookOptions<ExportUserAssignmentStatisticsReportMutation, ExportUserAssignmentStatisticsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportUserAssignmentStatisticsReportMutation, ExportUserAssignmentStatisticsReportMutationVariables>(ExportUserAssignmentStatisticsReportDocument, options);
      }
export type ExportUserAssignmentStatisticsReportMutationHookResult = ReturnType<typeof useExportUserAssignmentStatisticsReportMutation>;
export type ExportUserAssignmentStatisticsReportMutationResult = Apollo.MutationResult<ExportUserAssignmentStatisticsReportMutation>;
export type ExportUserAssignmentStatisticsReportMutationOptions = Apollo.BaseMutationOptions<ExportUserAssignmentStatisticsReportMutation, ExportUserAssignmentStatisticsReportMutationVariables>;
export const ExportUserTrailStatisticsReportDocument = gql`
    mutation ExportUserTrailStatisticsReport($filter: UserTrailStatisticsReportFilter!) {
  exportUserTrailStatisticsReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportUserTrailStatisticsReportMutationFn = Apollo.MutationFunction<ExportUserTrailStatisticsReportMutation, ExportUserTrailStatisticsReportMutationVariables>;

/**
 * __useExportUserTrailStatisticsReportMutation__
 *
 * To run a mutation, you first call `useExportUserTrailStatisticsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUserTrailStatisticsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUserTrailStatisticsReportMutation, { data, loading, error }] = useExportUserTrailStatisticsReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportUserTrailStatisticsReportMutation(baseOptions?: Apollo.MutationHookOptions<ExportUserTrailStatisticsReportMutation, ExportUserTrailStatisticsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportUserTrailStatisticsReportMutation, ExportUserTrailStatisticsReportMutationVariables>(ExportUserTrailStatisticsReportDocument, options);
      }
export type ExportUserTrailStatisticsReportMutationHookResult = ReturnType<typeof useExportUserTrailStatisticsReportMutation>;
export type ExportUserTrailStatisticsReportMutationResult = Apollo.MutationResult<ExportUserTrailStatisticsReportMutation>;
export type ExportUserTrailStatisticsReportMutationOptions = Apollo.BaseMutationOptions<ExportUserTrailStatisticsReportMutation, ExportUserTrailStatisticsReportMutationVariables>;
export const ExportEngagementReportDocument = gql`
    mutation ExportEngagementReport($filter: EngagementReportFilter!) {
  exportEngagementReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportEngagementReportMutationFn = Apollo.MutationFunction<ExportEngagementReportMutation, ExportEngagementReportMutationVariables>;

/**
 * __useExportEngagementReportMutation__
 *
 * To run a mutation, you first call `useExportEngagementReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportEngagementReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportEngagementReportMutation, { data, loading, error }] = useExportEngagementReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportEngagementReportMutation(baseOptions?: Apollo.MutationHookOptions<ExportEngagementReportMutation, ExportEngagementReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportEngagementReportMutation, ExportEngagementReportMutationVariables>(ExportEngagementReportDocument, options);
      }
export type ExportEngagementReportMutationHookResult = ReturnType<typeof useExportEngagementReportMutation>;
export type ExportEngagementReportMutationResult = Apollo.MutationResult<ExportEngagementReportMutation>;
export type ExportEngagementReportMutationOptions = Apollo.BaseMutationOptions<ExportEngagementReportMutation, ExportEngagementReportMutationVariables>;
export const ExportUserCommentsReportDocument = gql`
    mutation ExportUserCommentsReport($filter: UserCommentsReportFilter!) {
  exportUserCommentsReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportUserCommentsReportMutationFn = Apollo.MutationFunction<ExportUserCommentsReportMutation, ExportUserCommentsReportMutationVariables>;

/**
 * __useExportUserCommentsReportMutation__
 *
 * To run a mutation, you first call `useExportUserCommentsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportUserCommentsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportUserCommentsReportMutation, { data, loading, error }] = useExportUserCommentsReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportUserCommentsReportMutation(baseOptions?: Apollo.MutationHookOptions<ExportUserCommentsReportMutation, ExportUserCommentsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportUserCommentsReportMutation, ExportUserCommentsReportMutationVariables>(ExportUserCommentsReportDocument, options);
      }
export type ExportUserCommentsReportMutationHookResult = ReturnType<typeof useExportUserCommentsReportMutation>;
export type ExportUserCommentsReportMutationResult = Apollo.MutationResult<ExportUserCommentsReportMutation>;
export type ExportUserCommentsReportMutationOptions = Apollo.BaseMutationOptions<ExportUserCommentsReportMutation, ExportUserCommentsReportMutationVariables>;
export const ExportContentStatisticsReportDocument = gql`
    mutation ExportContentStatisticsReport($filter: ContentStatisticsReportFilter!) {
  exportContentStatisticsReport(filter: $filter) {
    temporalWorkflowId
  }
}
    `;
export type ExportContentStatisticsReportMutationFn = Apollo.MutationFunction<ExportContentStatisticsReportMutation, ExportContentStatisticsReportMutationVariables>;

/**
 * __useExportContentStatisticsReportMutation__
 *
 * To run a mutation, you first call `useExportContentStatisticsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportContentStatisticsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportContentStatisticsReportMutation, { data, loading, error }] = useExportContentStatisticsReportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportContentStatisticsReportMutation(baseOptions?: Apollo.MutationHookOptions<ExportContentStatisticsReportMutation, ExportContentStatisticsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportContentStatisticsReportMutation, ExportContentStatisticsReportMutationVariables>(ExportContentStatisticsReportDocument, options);
      }
export type ExportContentStatisticsReportMutationHookResult = ReturnType<typeof useExportContentStatisticsReportMutation>;
export type ExportContentStatisticsReportMutationResult = Apollo.MutationResult<ExportContentStatisticsReportMutation>;
export type ExportContentStatisticsReportMutationOptions = Apollo.BaseMutationOptions<ExportContentStatisticsReportMutation, ExportContentStatisticsReportMutationVariables>;
export const GetOrgUsersListDocument = gql`
    query GetOrgUsersList($id: String!, $filter: OrganisationMemberFilter, $isCurrentUserAdmin: Boolean!) {
  organisation(id: $id) {
    __typename
    id
    members(filter: $filter) {
      edges {
        id
        firstName
        lastName
        email @include(if: $isCurrentUserAdmin)
        avatarImage {
          id
          uri
          blurHash
        }
      }
      meta {
        total
        offset
        limit
      }
    }
  }
}
    `;

/**
 * __useGetOrgUsersListQuery__
 *
 * To run a query within a React component, call `useGetOrgUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgUsersListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      isCurrentUserAdmin: // value for 'isCurrentUserAdmin'
 *   },
 * });
 */
export function useGetOrgUsersListQuery(baseOptions: Apollo.QueryHookOptions<GetOrgUsersListQuery, GetOrgUsersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgUsersListQuery, GetOrgUsersListQueryVariables>(GetOrgUsersListDocument, options);
      }
export function useGetOrgUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgUsersListQuery, GetOrgUsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgUsersListQuery, GetOrgUsersListQueryVariables>(GetOrgUsersListDocument, options);
        }
export type GetOrgUsersListQueryHookResult = ReturnType<typeof useGetOrgUsersListQuery>;
export type GetOrgUsersListLazyQueryHookResult = ReturnType<typeof useGetOrgUsersListLazyQuery>;
export type GetOrgUsersListQueryResult = Apollo.QueryResult<GetOrgUsersListQuery, GetOrgUsersListQueryVariables>;
export const GetOrgCreatedAtDocument = gql`
    query GetOrgCreatedAt($organisationId: String!) {
  organisation(id: $organisationId) {
    createdAt
    id
    members {
      meta {
        total
      }
    }
  }
}
    `;

/**
 * __useGetOrgCreatedAtQuery__
 *
 * To run a query within a React component, call `useGetOrgCreatedAtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgCreatedAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgCreatedAtQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetOrgCreatedAtQuery(baseOptions: Apollo.QueryHookOptions<GetOrgCreatedAtQuery, GetOrgCreatedAtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgCreatedAtQuery, GetOrgCreatedAtQueryVariables>(GetOrgCreatedAtDocument, options);
      }
export function useGetOrgCreatedAtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgCreatedAtQuery, GetOrgCreatedAtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgCreatedAtQuery, GetOrgCreatedAtQueryVariables>(GetOrgCreatedAtDocument, options);
        }
export type GetOrgCreatedAtQueryHookResult = ReturnType<typeof useGetOrgCreatedAtQuery>;
export type GetOrgCreatedAtLazyQueryHookResult = ReturnType<typeof useGetOrgCreatedAtLazyQuery>;
export type GetOrgCreatedAtQueryResult = Apollo.QueryResult<GetOrgCreatedAtQuery, GetOrgCreatedAtQueryVariables>;