import {
  type BoxProps,
  HStack,
  Icon,
  type IconProps,
  Text,
  type TextProps
} from '@chakra-ui/react';
import { IconInfoCircle } from '@tigerhall/icons';
import * as React from 'react';

interface InfoBoxProps extends BoxProps {
  /**
   * The children of the component.
   * It's recommended to use a `string` as children. This is because the `children`
   * is wrapped in a `Text` component from Chakra UI.
   *
   */
  children: React.ReactNode;

  /** styles to override the default info-box icon styling
   *  ideally we should only have to change the `color | boxSize` of the info-box icon
   */
  iconStyles?: Pick<IconProps, 'color' | 'boxSize'>;

  /** styles to override the default info-box text styling
   *  ideally we should only have to change the `color | fontSize | fontWeight` of the info-box text
   */
  textStyles?: Pick<TextProps, 'color' | 'fontSize' | 'fontWeight'>;
}

/**
 * InfoBox is a component that renders a box with an info icon and a text.
 * It's usually used to display information to the user.
 *
 * If the `children` is nullish, the component will not be rendered.
 *
 * @example```jsx
 * <InfoBox>
 *   This is an info box
 * </InfoBox>
 * ```
 */
export function InfoBox({
  children,
  iconStyles = {},
  textStyles = {},
  ...rest
}: Readonly<InfoBoxProps>) {
  if (!children) {
    return null;
  }

  return (
    <HStack
      role="alert"
      width="100%"
      background="whiteAlpha.300"
      borderRadius="md"
      justifyContent="center"
      p={{
        base: '0.5rem',
        lg: '0.25rem 0.5rem'
      }}
      {...rest}
    >
      <Icon as={IconInfoCircle} color="blue.400" {...iconStyles} />
      <Text fontSize="md" color="lightGrey.600" {...textStyles}>
        {children}
      </Text>
    </HStack>
  );
}
