import { Flex, Text } from '@chakra-ui/react';

import { type User, useGetCurrentUserQuery } from '~/generated';
import { useAppSelector } from '~/hooks';
import { getSelectedOrg } from '~/app/state';
import AccountDetails from './components/AccountDetails';
import ChangePassword from './components/ChangePassword';
import { CommunicationPreferencesSection } from './components/CommunicationPreferencesSection';
import OrgInfoCard from './components/OrgInfoCard';
import ProfileInfoCard from './components/ProfileInfoCard';
import { SubscriptionManagementSection } from './components/SubscriptionManagementSection';
import { UserAccountActions } from './components/UserAccountActions';
import UserAchievements from './components/UserAchievements';
import UserTopics from './components/UserTopics';
import { LanguageSection } from './components/LanguageSection';
import { ProductTourSection } from './components/ProductTourSection';
import { BlockedUsersSection } from '~/modules/application/modules/Profile/screens/Settings/components/BlockedUsersSection';

export default function Settings() {
  const { data: { me: user } = {}, loading } = useGetCurrentUserQuery({});

  const selectedOrg = useAppSelector(getSelectedOrg);

  if (loading) {
    return null;
  }

  return (
    <Flex
      flex={1}
      backgroundColor="darkGrey.700"
      justifyContent="center"
      py="3rem"
    >
      <Flex
        direction="column"
        gap="1rem"
        maxW="50rem"
        w="100%"
        height="100%"
        mx="1rem"
      >
        <Text fontSize="1.75rem" fontWeight="700" color="tigerOrange.600">
          General
        </Text>

        <Flex w="100%" gap="1rem" direction={{ base: 'column', md: 'row' }}>
          <ProfileInfoCard user={user as User} />
          {selectedOrg ? <OrgInfoCard organisation={selectedOrg} /> : null}
        </Flex>

        <Flex
          w="100%"
          gap="1rem"
          mb="1rem"
          direction={{ base: 'column', md: 'row' }}
        >
          <UserTopics />
          <UserAchievements user={user} />
        </Flex>

        <ProductTourSection />

        <Text fontSize="1.75rem" fontWeight="700" color="tigerOrange.600">
          Settings
        </Text>

        <Flex gap="1rem" w="100%" direction={{ base: 'column', md: 'row' }}>
          <AccountDetails user={user} />
          <ChangePassword user={user} />
        </Flex>

        <LanguageSection />
        <Flex w="100%" gap="1rem" direction={{ base: 'column', md: 'row' }}>
          <CommunicationPreferencesSection />
          <BlockedUsersSection />
        </Flex>
        <SubscriptionManagementSection />
        <UserAccountActions />
      </Flex>
    </Flex>
  );
}
