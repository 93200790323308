import { deleteCookie, getCookie } from '@tigerhall/core';
import { getAccessToken, setAccessToken } from 'app/state';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

/**
 * Check if the user is already logged in,
 * if not, try to get the JWT token from a cookie
 * and log the user in.
 */
export function useCrossDomainSession() {
  const accessToken = useAppSelector(getAccessToken);
  const dispatch = useAppDispatch();

  if (accessToken) {
    return;
  }

  const crossDomainAccessToken = getCookie('th_auth');

  if (!crossDomainAccessToken) {
    return;
  }

  deleteCookie('th_auth');
  dispatch(setAccessToken({ token: crossDomainAccessToken }));
}
