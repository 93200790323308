import { Suspense, lazy, useEffect, useRef } from 'react';
import { StreamStatus } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import { noop } from '@chakra-ui/utils';

import { useMediaAnalytics, useMediaPlayer } from '~/player/hooks';
import { type MinimizedPlayerProps } from '~/player/components/MinimizedPlayer';
import {
  LiveContentActivePlayer,
  LiveContentActivePlayerProps
} from '../LiveContent/components/LiveContentActivePlayer';
import { getDidStreamEnd } from '~/player/utils/stream';
import { LiveStreamVideoProps } from './components/LiveStreamVideo';
import { MediaPlayerContextProvider } from '~/player/context';

export interface LiveStreamProps
  extends Pick<
      LiveContentActivePlayerProps,
      | 'featuredUser'
      | 'onMinimize'
      | 'onMaximize'
      | 'isMinimized'
      | 'urlToShare'
      | 'disableShare'
    >,
    Pick<MinimizedPlayerProps, 'onClose'>,
    Pick<
      LiveStreamVideoProps,
      'appId' | 'shouldUseProxy' | 'channel' | 'featureHostId'
    > {
  meta: {
    contentId: string;
    contentName: string;
    preamble: string;
  };
  onTrackProgress: (progress: number) => void;
  onTrackFinished: () => void;
  onFinished?: () => void;
  streamStatus: StreamStatus;

  /**
   * If the player should be hidden without being unmounted.
   * This is useful if you want to keep the player state but not have it visible,
   * e.g. if you want to open a video and then resume the podcast after the video is closed.
   */
  isHidden?: boolean;
}

const LiveStreamVideo = lazy(() =>
  import('./components/LiveStreamVideo').then((module) => ({
    default: module.LiveStreamVideo
  }))
);

export function LiveStream({
  meta: { contentId, contentName, preamble },

  onTrackProgress,
  onTrackFinished,
  onFinished = noop,
  // Not used as of now
  isHidden = false,

  // from `LiveContentActivePlayerProps`
  featuredUser,
  onMinimize,
  onMaximize,
  isMinimized,
  urlToShare,
  streamStatus,

  // from `MinimizedPlayerProps`
  onClose,
  disableShare,

  // from 'LiveStreamVideoProps'
  appId,
  shouldUseProxy,
  channel,
  featureHostId
}: Readonly<LiveStreamProps>) {
  const videoRef = useRef(null);
  const didStreamEnd = getDidStreamEnd(streamStatus);

  useMediaAnalytics({
    contentId,
    contentType: 'Stream',
    onTrackProgress,
    onTrackFinished,
    disableTimeSpentReporting: didStreamEnd,
    playerState: isMinimized ? 'Minimized' : 'Maximized',
    isLive: !didStreamEnd
  });

  const player = useMediaPlayer(videoRef, {
    onFinished,
    customDuration: 0,
    defaultAutoPlay: true
  });

  useEffect(() => {
    if (didStreamEnd) {
      segment.liveEventEndedScreen({
        contentId,
        contentName,
        type: 'livestream'
      });
    }
  }, [contentId, contentName, didStreamEnd]);

  const handleClose = () => {
    onClose();
  };

  return (
    <MediaPlayerContextProvider {...player}>
      <LiveContentActivePlayer
        contentId={contentId}
        contentTitle={contentName}
        contentDescription={preamble}
        featuredUser={featuredUser}
        handleClose={handleClose}
        onMaximize={onMaximize}
        onMinimize={onMinimize}
        isMinimized={isMinimized}
        urlToShare={urlToShare}
        videoRef={videoRef}
        streamStatus={streamStatus}
        isBuffering={false}
        disableShare={disableShare}
        isLiveStream
      >
        <Suspense fallback={null}>
          <LiveStreamVideo
            channel={channel}
            featureHostId={featureHostId}
            appId={appId}
            shouldUseProxy={shouldUseProxy}
            isMinimized={isMinimized}
            ref={videoRef}
          />
        </Suspense>
      </LiveContentActivePlayer>
    </MediaPlayerContextProvider>
  );
}
