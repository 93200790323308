import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type ContentType, type StreamSource } from '@tigerhall/core';
import type { PlaylistType } from '@tigerhall/analytics';
import type { RootState } from 'app/reducer';

export interface PlayingContent {
  id: string;
  type:
    | Extract<
        ContentType,
        ContentType.Stream | ContentType.Podcast | ContentType.Ebook
      >
    | Extract<
        StreamSource,
        | StreamSource.BroadcastCloudflareLive
        | StreamSource.BroadcastAwsIvs
        | StreamSource.LivestreamAgora
      >;
  playlistType: PlaylistType | undefined;
  playlistId: string | undefined;
  playlistTitle: string | undefined;
  contentUrl: string;
  /**
   * please do not perform analytics related events
   * through this if those events conflicts with preview mode
   */
  onCompletionCallback?: () => void;
}

interface State {
  content: PlayingContent | undefined;
  isAutoNextPlayEnabled: boolean | undefined;
  isLastContentInPlaylist: boolean;
  isTemporarilyHiddenAndPaused: boolean;
  isMinimized: boolean;
  isContentPreviewOver: boolean;
}

const INITIAL_STATE: State = {
  content: undefined,
  isAutoNextPlayEnabled: false,
  isLastContentInPlaylist: false,
  isTemporarilyHiddenAndPaused: false,
  isMinimized: false,
  isContentPreviewOver: false
};

export const playerSlice = createSlice({
  name: 'player',
  initialState: INITIAL_STATE,
  reducers: {
    setPlayingContent(state, action: PayloadAction<State['content']>): State {
      return {
        ...state,
        content: action.payload,
        isAutoNextPlayEnabled: undefined
      };
    },
    setAutoNextPlayToggle: (
      state,
      action: PayloadAction<State['isAutoNextPlayEnabled']>
    ): State => ({
      ...state,
      isAutoNextPlayEnabled: action.payload
    }),
    setIsLastContentInPlaylist: (
      state,
      action: PayloadAction<State['isLastContentInPlaylist']>
    ): State => ({
      ...state,
      isLastContentInPlaylist: action.payload
    }),
    temporarilyPauseAndHide: (state): State => ({
      ...state,
      isTemporarilyHiddenAndPaused: true
    }),
    resetTemporaryState: (state): State => ({
      ...state,
      isTemporarilyHiddenAndPaused: false
    }),
    setIsMinimized: (
      state,
      action: PayloadAction<State['isMinimized']>
    ): State => ({
      ...state,
      isMinimized: action.payload
    }),
    setIsContentPreviewOver: (state, action): State => ({
      ...state,
      isContentPreviewOver: Boolean(action.payload)
    })
  }
});

export const {
  setPlayingContent,
  temporarilyPauseAndHide,
  resetTemporaryState,
  setAutoNextPlayToggle,
  setIsLastContentInPlaylist,
  setIsMinimized,
  setIsContentPreviewOver
} = playerSlice.actions;

export default playerSlice.reducer;

// ------------------------------------
// Selectors
// ------------------------------------
export function getIsPlayingContent(state: RootState) {
  return !!state.player.content;
}
export function getPlayingContent(state: RootState) {
  return state.player.content;
}
export function getIsContentPreviewOver(state: RootState) {
  return state.player.isContentPreviewOver;
}
export function getIsAutoNextPlayEnabled(state: RootState) {
  return state.player.isAutoNextPlayEnabled;
}
export function getIsLastContentInPlaylist(state: RootState) {
  return state.player.isLastContentInPlaylist;
}
export function getPlaylistType(state: RootState) {
  return state.player.content?.playlistType;
}
export function getPlaylistId(state: RootState) {
  return state.player.content?.playlistId;
}
export function getPlaylistTitle(state: RootState) {
  return state.player.content?.playlistTitle;
}
export function getContentUrl(state: RootState) {
  return state.player.content?.contentUrl;
}
export function getIsTemporarilyHiddenAndPaused(state: RootState) {
  return state.player.isTemporarilyHiddenAndPaused;
}

export function getIsMinimized(state: RootState) {
  return state.player.isMinimized;
}
