import { FREE_CONTENT_CONSUMPTION_LIMIT } from '@tigerhall/core';
import { DateTime } from 'luxon';
import { useGetFreeTrialUserInfoQuery } from 'queries/user/generated/me.generated';

export function useContentMeter() {
  const { data } = useGetFreeTrialUserInfoQuery({
    fetchPolicy: 'cache-and-network'
  });
  const { me: currentUser } = data || {};

  const monthlyConsumedContentCount =
    currentUser?.monthlyConsumedContentCount || 0;

  const currentDate = DateTime.now();
  const freeTrialEndDate = currentUser?.freeTrialEndsAt;

const calculateTrialLeftDays = () => {
 const targetEndDate = DateTime.fromISO(freeTrialEndDate);
 const difference = targetEndDate.diff(currentDate, ["days"]);
 return difference.toObject().days;
};

const trialLeftDays = freeTrialEndDate ? calculateTrialLeftDays() : undefined;

  const isMeterExpired = monthlyConsumedContentCount >= 3 && !trialLeftDays;

  return {
    meterLimit: FREE_CONTENT_CONSUMPTION_LIMIT,
    meterValue: monthlyConsumedContentCount,
    isMeterExpired,
    trialLeftDays
  };
}
