import { Box, Flex } from '@chakra-ui/react';
import { ContentType } from '@tigerhall/core';
import { IconLanguage, IconSubtitle } from '@tigerhall/icons';
import { type ReactNode, type RefObject, useState } from 'react';

import { AIBadge } from '~/badges';
import {
  ContentInfo,
  type ContentInfoProps
} from '~/player/components/ContentInfo';
import { Header, type HeaderProps } from '~/player/components/Header';
import { Languages, type LanguagesProps } from '~/player/components/Languages';
import { OptionButton } from '~/player/components/OptionButton';
import { OptionsGroup } from '~/player/components/OptionsGroup';
import { Overlay } from '~/player/components/Overlay';
import {
  OverlayBodyContainer,
  type OverlayBodyContainerProps
} from '~/player/components/OverlayBodyContainer';
import { OverlayFooterContainer } from '~/player/components/OverlayFooterContainer';
import { PlaybackRateSelector } from '~/player/components/PlaybackRateSelector';
import { PlayerControls } from '~/player/components/PlayerControls';
import { ProgressSlider } from '~/player/components/ProgressSlider';
import {
  TrackSelector,
  type TrackSelectorProps
} from '~/player/components/TrackSelector';
import { VolumeSlider } from '~/player/components/VolumeSlider';
import { wrapLanguageChangeHandler } from '~/player/utils/tracking';
import { Rating, type RatingProps } from '~/rating';
import { Share } from '~/share';
import { useTranscriptDisclosure } from '../hooks/useTranscriptDisclosure';
import {
  TranscriptReader,
  type TranscriptReaderProps
} from './TranscriptReader';

export interface ActivePlayerProps
  extends Partial<RatingProps>,
    Pick<
      LanguagesProps,
      | 'mediaLanguages'
      | 'selectedMediaLanguage'
      | 'onChangeMediaLanguage'
      | 'transcriptLanguages'
      | 'selectedTranscriptLanguage'
      | 'onChangeTranscriptLanguage'
    >,
    Pick<ContentInfoProps, 'name' | 'casting'>,
    Pick<TranscriptReaderProps, 'markdownComponent'>,
    Pick<TrackSelectorProps, 'tracks' | 'trackIndex' | 'setTrackIndex'>,
    Pick<HeaderProps, 'playlistTitle' | 'playlistType' | 'contentUrl'>,
    Pick<
      OverlayBodyContainerProps,
      'onAutoNextPlay' | 'isLastContentInPlaylist'
    > {
  /**
   * transcripts would be `undefined` if user sets transcripts to `off`
   */
  transcripts: TranscriptReaderProps['transcripts'] | undefined;
  /**
   * For Podcast, it is the featured image.
   */
  children: ReactNode;
  audioRef: RefObject<HTMLAudioElement>;

  onTrackFinished: () => void;
  isFinished?: boolean;
  urlToShare: string;
  contentDescription: string;
}

export function ActivePlayer({
  children,

  onTrackFinished,
  isFinished = false,
  contentDescription,
  urlToShare,
  audioRef,

  // from `OverlayBodyContainerProps`
  onAutoNextPlay,
  isLastContentInPlaylist,

  // from `HeaderProps`
  playlistType,
  playlistTitle,
  contentUrl,

  // from `TrackSelectorProps`
  tracks,
  trackIndex,
  setTrackIndex,

  // from `TranscriptReaderProps`
  transcripts,
  markdownComponent,

  // from `LanguagesProps`
  mediaLanguages,
  selectedMediaLanguage,
  onChangeMediaLanguage,

  // from `LanguagesProps`
  transcriptLanguages,
  selectedTranscriptLanguage,
  onChangeTranscriptLanguage,

  // from `RatingProps`
  vote,
  onLike,
  onDislike,

  // from `ContentInfoProps`
  name,
  casting
}: ActivePlayerProps) {
  const [isLanguageSelectionOpen, setIsLanguageSelectionOpen] = useState(false);

  const {
    isOpenTranscriptReader,
    onCloseTranscriptReader,
    onOpenTranscriptReader
  } = useTranscriptDisclosure();

  const shouldShowLanguageSelector =
    mediaLanguages.length > 1 || transcriptLanguages.length > 0;

  const shouldShowTranscriptReader =
    Boolean(transcripts) && transcriptLanguages.length > 0;

  const handleAudioLanguageChange = wrapLanguageChangeHandler(
    onChangeMediaLanguage,
    {
      previousLanguage: selectedMediaLanguage
    }
  );

  const handleTranscriptLanguageChange = wrapLanguageChangeHandler(
    onChangeTranscriptLanguage,
    {
      previousLanguage: selectedTranscriptLanguage ?? 'none'
    }
  );

  return (
    <Box
      position={'relative'}
      width={'100%'}
      height={'100%'}
      background={'black'}
      overflow={'hidden'}
    >
      {children}
      <Overlay
        isAlwaysOpen
        header={
          <Header
            contentType={ContentType.Podcast}
            playlistTitle={playlistTitle}
            playlistType={playlistType}
            contentUrl={contentUrl}
          />
        }
        body={
          audioRef?.current?.ended ? (
            <OverlayBodyContainer
              onAutoNextPlay={onAutoNextPlay}
              isLastContentInPlaylist={isLastContentInPlaylist}
              playlistType={playlistType}
            />
          ) : null
        }
        footer={
          <OverlayFooterContainer>
            <TrackSelector
              size="sm"
              tracks={tracks}
              trackIndex={trackIndex}
              setTrackIndex={setTrackIndex}
            />
            <Flex justifyContent={'space-between'} gap={'1rem'}>
              <ContentInfo name={name} casting={casting} width={'10rem'} />
              {vote && onLike && onDislike ? (
                <Rating vote={vote} onLike={onLike} onDislike={onDislike} />
              ) : null}
            </Flex>
            <ProgressSlider />
            <PlayerControls
              size={'lg'}
              hideTrackControls={tracks.length <= 1}
            />
            <OptionsGroup>
              <Share
                urlToShare={urlToShare}
                titleToShare={name}
                descriptionToShare={contentDescription}
                color="darkGrey.50"
                sx={{ svg: { width: '1.5rem', height: '1.5rem' } }}
              />
              <PlaybackRateSelector />
              {shouldShowLanguageSelector ? (
                <Box pos={'relative'}>
                  <OptionButton
                    label={'Change Language'}
                    icon={IconLanguage}
                    iconColor="darkGrey.50"
                    onClick={() => {
                      setIsLanguageSelectionOpen(true);
                    }}
                  />
                  <AIBadge variant="mini" pos={'absolute'} top={0} right={0} />
                </Box>
              ) : null}
              {shouldShowTranscriptReader ? (
                <OptionButton
                  label={'Show Transcript'}
                  icon={IconSubtitle}
                  iconColor="darkGrey.50"
                  onClick={onOpenTranscriptReader}
                />
              ) : null}
              <VolumeSlider />
            </OptionsGroup>
          </OverlayFooterContainer>
        }
      />

      <Languages
        mediaHeading={'Audio'}
        transcriptHeading={'Transcript'}
        mediaLanguages={mediaLanguages}
        transcriptLanguages={transcriptLanguages}
        selectedMediaLanguage={selectedMediaLanguage}
        selectedTranscriptLanguage={selectedTranscriptLanguage}
        onChangeMediaLanguage={handleAudioLanguageChange}
        onChangeTranscriptLanguage={handleTranscriptLanguageChange}
        isOpen={isLanguageSelectionOpen}
        onClose={() => {
          setIsLanguageSelectionOpen(false);
        }}
      />
      {transcripts ? (
        <TranscriptReader
          transcripts={transcripts}
          currentEpisodeIndex={trackIndex}
          transcriptLanguages={transcriptLanguages}
          setTranscriptLanguage={handleTranscriptLanguageChange}
          isFinished={isFinished}
          onMarkAsDone={onTrackFinished}
          markdownComponent={markdownComponent}
          isOpen={isOpenTranscriptReader}
          onClose={onCloseTranscriptReader}
        />
      ) : null}
    </Box>
  );
}
