import type { ColorMode, Interpolation } from '@chakra-ui/react';
import type { ChakraStylesConfig, GroupBase } from 'chakra-react-select';

const basePlaceHolderStyling = {
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: '1.5rem',
  margin: '0.75rem'
};

const dimensions = {
  sm: '1.5rem',
  md: '3rem',
  lg: '3rem'
};

function getCommonChakraStyles<
  Option,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(): ChakraStylesConfig<Option, IsMulti, Group> {
  return {
    valueContainer: (provided, state) => ({
      ...provided,
      padding: 0,
      overflowY: 'scroll',
      gap: 2
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: dimensions[state.selectProps?.size ?? 'lg'],
      ...(state.isMulti ? { padding: 2 } : { padding: 2, paddingLeft: 4 }),
      paddingRight: 3,
      maxHeight: '5.5rem'
    }),
    multiValue: (provided) => ({
      ...provided,
      margin: 0,
      paddingY: 1,
      paddingX: 2,
      borderRadius: 'base',
      borderWidth: 1,
      borderColor: 'darkGrey.200'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 'dropdown'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '1.875rem',
      alignSelf: 'flex-start'
    }),
    clearIndicator: (provided) => ({
      ...provided,
      margin: 0
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0
    })
  };
}

export function getSelectChakraStyles<
  Option,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(colorMode: ColorMode): ChakraStylesConfig<Option, IsMulti, Group> {
  switch (colorMode) {
    case 'light': {
      return {
        ...getCommonChakraStyles<Option, IsMulti, Group>(),
        input: (provided) => ({
          ...provided,
          p: 0,
          _placeholder: {
            ...basePlaceHolderStyling,
            color: 'darkGrey.50'
          }
        }),

        menuList: (provided) => ({
          ...provided,
          border: '1px solid',
          borderColor: 'darkGrey.200',
          borderRadius: 'base',
          backgroundColor: 'white',
          color: 'black'
        }),

        option: (provided, state) => ({
          ...provided,
          color: 'black',
          backgroundColor: state.isSelected ? 'lightGrey.200' : 'white'
        })
      };
    }

    case 'dark':
    default: {
      return {
        ...getCommonChakraStyles<Option, IsMulti, Group>(),
        input: (provided) => ({
          ...provided,
          p: 0,
          _placeholder: {
            ...basePlaceHolderStyling,
            color: 'darkGrey.200'
          }
        }),

        menuList: (provided) => ({
          ...provided,
          border: '1px solid',
          borderColor: 'lightGrey.600',
          borderRadius: 'base',
          backgroundColor: 'darkGrey.400',
          color: 'lightGrey.400'
        }),

        option: (provided, state) => ({
          ...provided,
          color: 'lightGrey.600',
          backgroundColor: state.isSelected ? 'darkGrey.200' : 'darkGrey.400'
        })
      };
    }
  }
}

export const scrollbarStylesForSelect: Interpolation<{}> = {
  '& ::-webkit-scrollbar': {
    width: '4px'
  },
  '& ::-webkit-scrollbar-track': {
    width: '6px'
  },
  '& ::-webkit-scrollbar-thumb': {
    background: '#C4C4C4',
    borderRadius: '24px'
  }
};
