import { useCallback } from 'react';
import { captureException } from '@sentry/react';

import { useUserToggleFollowMutation } from '~/generated';

export function useFollowUserToggle() {
  const [userToggleFollowMutation, { loading }] = useUserToggleFollowMutation();

  const hadleFollowUserToggle = useCallback(
    (userId: string) => {
      userToggleFollowMutation({
        variables: {
          id: userId
        }
      }).catch((err) => {
        captureException(err, {
          extra: {
            userId
          }
        });
      });
    },
    [userToggleFollowMutation]
  );

  return {
    hadleFollowUserToggle,
    loading
  };
}
