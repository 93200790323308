import * as React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import {
  ContentCardFrame,
  ContentCardWithOverlay,
  TrackedButton
} from '@tigerhall/components';
import { AssignmentContent } from '@tigerhall/core/lib/types';
import { AnimatePresence, motion } from 'framer-motion';
import map from 'lodash/map';
import { DndSortableItem, DndSortableList } from 'components/tools/DragAndDrop';
import { ContentCardSummary } from 'components/ui/Cards/InfiniteSelectableContentCardGrid/ContentCardSummary';

import { ACTION_TYPES, Actions } from '../constants';

// If `setContentCards` is passed in, then we need to pass in the `actions` prop
interface SelectedContentCardsMultiActionProps {
  contentCards: AssignmentContent[];
  setContentCards: (contentCards: AssignmentContent[]) => void;
  actions: Actions[];
  shouldHideSummaryActions?: boolean;
  shouldHideFrame?: boolean;
}

// If the only action is `LearnMore`, then we don't need to pass in the `setContentCards` prop
interface SelectedContentCardsSingleActionProps {
  contentCards: AssignmentContent[];
  setContentCards?: never;
  actions?: [Extract<Actions, 'LearnMore'>];
  shouldHideFrame?: boolean;
}

type SelectedContentCardsProps =
  | SelectedContentCardsMultiActionProps
  | SelectedContentCardsSingleActionProps;

export function SelectedContentCards({
  contentCards,
  setContentCards,
  actions,
  shouldHideFrame = false
}: Readonly<SelectedContentCardsProps>) {
  const [learnMoreAbout, setLearnMoreAbout] = React.useState<{
    id: string;
    contentType: 'Podcast' | 'Stream' | 'Event' | 'Ebook' | 'LearningPath';
  }>();

  const removeContent = React.useCallback(
    (id: string) => {
      const updatedContentCards = contentCards?.filter(
        (content) => content.id !== id
      );
      setContentCards?.(updatedContentCards);
    },
    [contentCards, setContentCards]
  );

  const getContentCardSummaryActions = React.useCallback(() => {
    if (!learnMoreAbout?.id) {
      return null;
    }

    return (
      <TrackedButton
        name="Remove"
        flex={1}
        variant="outline"
        isDisabled={!actions || !actions.some((action) => action === 'Remove')}
        onClick={() => {
          removeContent?.(learnMoreAbout.id);
          setLearnMoreAbout(undefined);
        }}
      >
        Remove
      </TrackedButton>
    );
  }, [actions, learnMoreAbout?.id, removeContent]);

  function getOverlayActions(
    actionsList: Actions[],
    {
      id,
      contentType
    }: {
      id: string;
      contentType: 'Podcast' | 'Stream' | 'Event' | 'Ebook' | 'LearningPath';
    }
  ) {
    const allActions: any = [];
    actionsList.forEach((action) => {
      switch (action) {
        case ACTION_TYPES.LearnMore:
          allActions.push({
            label: 'Learn More',
            variant: 'outline',
            onClick: () => {
              setLearnMoreAbout({
                id,
                contentType
              });
            }
          });
          break;

        case ACTION_TYPES.Remove:
          allActions.push({
            label: 'Remove',
            onClick: () => removeContent(id)
          });
          break;
        default:
          break;
      }
    });
    return allActions;
  }

  if (contentCards?.length <= 0) {
    return null;
  }

  return (
    <>
      <SimpleGrid
        templateColumns="repeat(auto-fill, minmax(240px, 1fr))"
        gap={6}
      >
        <AnimatePresence>
          <DndSortableList items={contentCards} setItems={setContentCards}>
            {contentCards &&
              map(contentCards, (card) => (
                <DndSortableItem
                  key={`sortable-${card.id}`}
                  id={card.id}
                  isDisabled={!setContentCards}
                >
                  <motion.div
                    layout
                    layoutId={card.id}
                    layoutDependency={card.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {shouldHideFrame ? (
                      <ContentCardWithOverlay
                        {...card}
                        overlayActions={getOverlayActions(actions ?? [], {
                          id: card.id,
                          contentType: card.__typename
                        })}
                      />
                    ) : (
                      <ContentCardFrame>
                        <ContentCardWithOverlay
                          {...card}
                          overlayActions={getOverlayActions(actions ?? [], {
                            id: card.id,
                            contentType: card.__typename
                          })}
                        />
                      </ContentCardFrame>
                    )}
                  </motion.div>
                </DndSortableItem>
              ))}
          </DndSortableList>
        </AnimatePresence>
      </SimpleGrid>
      <ContentCardSummary
        id={learnMoreAbout?.id}
        contentType={learnMoreAbout?.contentType}
        isOpen={!!learnMoreAbout?.id}
        onClose={() => setLearnMoreAbout(undefined)}
        actions={getContentCardSummaryActions()}
      />
    </>
  );
}
