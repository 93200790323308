import { TrackedLink } from '@tigerhall/components';
import { ReactionCount, compactNumber } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';

interface ReactionsCountProps {
  postId: string;
  reactions: Pick<ReactionCount, 'emoji'>[];
  count: number;
}

/**
 * @todo This will be a link that opens the reactions screen.
 * Will be done in Post Improvements.
 * Remember to add the segment event onClick.
 */
export function ReactionsCount({
  postId,
  reactions,
  count
}: ReactionsCountProps) {
  const reactionsLink = `/feed/${postId}/reactions`;

  if (count === 0) {
    return null;
  }

  const compactCount = compactNumber(count);

  const emojisList = reactions.map((reaction) => reaction.emoji);
  const emoijs = emojisList.slice(0, 3).join('');

  return (
    <TrackedLink
      name="REACTIONS_LIST"
      href={reactionsLink}
      onClick={() => {
        segment.viewReactionsClicked({
          referenceId: postId
        });
      }}
    >
      {emoijs}&nbsp;{compactCount}
    </TrackedLink>
  );
}
