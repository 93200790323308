import { Box, Flex, Icon, Text, VStack } from '@chakra-ui/react';
import { IconBrandTigerhallHorizontal } from '@tigerhall/icons';
import { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { BackButton } from 'components/ui/Buttons';
import { TrackedLink } from '@tigerhall/components';

import { FreeTrialFooter } from '../screens/ClaimFreeTrial';

const signupBaseRoute = '/signup/individual';

export function BaseSignUpLayout() {
  const { pathname } = useLocation();

  const shouldShowBackButton = useMemo(() => {
    return pathname.includes(`${signupBaseRoute}/claim-free-trial`);
  }, [pathname]);

  const shouldShowTitleAndFooter = useMemo(() => {
    return (
      pathname === signupBaseRoute ||
      pathname.includes(`${signupBaseRoute}/claim-free-trial`)
    );
  }, [pathname]);

  const config = useMemo(() => {
    const isInteresetSelectPath = pathname.includes(
      `${signupBaseRoute}/interest-select`
    );
    return {
      maxW: isInteresetSelectPath ? '52rem' : '27rem',
      spacing: shouldShowTitleAndFooter || isInteresetSelectPath ? '2.5rem' : 0,
      height: shouldShowTitleAndFooter ? 'full' : '100vh'
    };
  }, [pathname, shouldShowTitleAndFooter]);

  return (
    <Flex
      w="full"
      h={config.height}
      bg="black"
      justifyContent={shouldShowBackButton ? 'space-between' : 'center'}
      p="3.5rem"
      direction={{ base: shouldShowBackButton ? 'column' : 'row', md: 'row' }}
    >
      {shouldShowBackButton ? (
        <BackButton mt={{ base: 0, md: '4rem' }} />
      ) : null}
      <VStack maxW={config.maxW} w="full" spacing={config.spacing}>
        <Icon
          as={IconBrandTigerhallHorizontal}
          w="8rem"
          h="2.5rem"
          color="tigerOrange.600"
        />
        {shouldShowTitleAndFooter ? (
          <VStack spacing="0.75rem">
            <Text
              color="white"
              textAlign="center"
              fontSize="2xl"
              fontWeight="bold"
            >
              Claim Your 30 Days Free Trial
            </Text>
            <Text
              color="white"
              textAlign="center"
              fontSize="md"
              fontWeight="medium"
            >
              Full access to Tigerhall content and social features
            </Text>
          </VStack>
        ) : null}
        <Outlet />
        {shouldShowTitleAndFooter ? <FreeTrialFooter /> : null}
      </VStack>
      {shouldShowBackButton ? <Box w="4.4rem"></Box> : null}
      {pathname.includes(`${signupBaseRoute}/interest-select`) && (
        <TrackedLink
          name="skipTopicInterest"
          href={`${signupBaseRoute}/creating-account`}
          color="darkGrey.50"
          fontSize="1rem"
        >
          Skip
        </TrackedLink>
      )}
    </Flex>
  );
}
