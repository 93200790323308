import * as React from 'react';
import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react';
import { IconCircleCheck, IconDots, IconTrash } from '@tigerhall/icons';
import { type Notification } from '@tigerhall/core/lib/types';
import * as segment from '@tigerhall/analytics';
import {
  useDeleteNotificationMutation,
  useUpdateNotificationAsReadMutation
} from 'generated';

interface NotificationMenuProps extends Pick<Notification, 'id' | 'state'> {
  hideNotification: React.Dispatch<React.SetStateAction<boolean>>;
}

export function NotificationMenu(props: NotificationMenuProps) {
  const { id, state, hideNotification } = props;

  const isNew = state === 'NEW';
  const isUnread = state === 'UNREAD';

  const [updateNotificationAsRead] = useUpdateNotificationAsReadMutation();

  const [deleteNotification] = useDeleteNotificationMutation({
    onCompleted: (data) => {
      if (data?.deleteNotification?.success) {
        hideNotification(true);
      }
    }
  });

  return (
    <Menu
      onOpen={() => {
        segment.buttonClicked({
          buttonName: 'More Options',
          location: window.location.pathname
        });
      }}
    >
      <MenuButton
        aria-label="More Options"
        as={IconButton}
        variant="ghost"
        _active={{
          background: 'whiteAlpha.200'
        }}
        _hover={{
          background: 'whiteAlpha.200'
        }}
        icon={
          <Icon
            as={IconDots}
            color="lightGrey.400"
            height="1rem"
            width="1rem"
          />
        }
        onClick={(e) => {
          // we need to do this to avoid the notification being marked as read
          e.stopPropagation();
        }}
      />
      <MenuList>
        {isNew || isUnread ? (
          <MenuItem
            icon={
              <Icon
                as={IconCircleCheck}
                display="flex"
                height="1rem"
                width="1rem"
              />
            }
            onClick={(e) => {
              e.stopPropagation();
              segment.buttonClicked({
                buttonName: 'Mark as read',
                location: window.location.pathname
              });
              updateNotificationAsRead({
                variables: {
                  id
                }
              });
            }}
          >
            Mark as read
          </MenuItem>
        ) : null}
        <MenuItem
          icon={
            <Icon as={IconTrash} display="flex" height="1rem" width="1rem" />
          }
          onClick={(e) => {
            e.stopPropagation();
            segment.buttonClicked({
              buttonName: 'Remove Notification',
              location: window.location.pathname
            });
            deleteNotification({
              variables: {
                id
              }
            });
          }}
        >
          Remove notification
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
