import { Box, Flex } from '@chakra-ui/react';
import { IconThumbUp } from '@tigerhall/icons';
import { Typography } from '@tigerhall/ui-components';

type ExpertContentRatingsType = {
  upvotes: number;
};

const ExpertContentRatings = ({ upvotes }: ExpertContentRatingsType) => (
  <Box>
    <Typography variant="heading4" color="lightGrey.200" fontWeight={700}>
      Content Ratings
    </Typography>
    <Box pt={4}>
      <Flex alignItems="center">
        <Flex alignItems="center">
          <IconThumbUp />
          <Typography
            variant="buttonSmall"
            ml="6px"
            mr="12px"
            fontWeight="medium"
            color="darkGrey.50"
          >
            {upvotes}
          </Typography>
        </Flex>
      </Flex>
    </Box>
  </Box>
);
export default ExpertContentRatings;
