import { useState } from 'react';
import {
  Box,
  Circle,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  Text
} from '@chakra-ui/react';

import type { SharedTranscriptReaderProps } from '../types';

export interface FontSizeSliderProps extends SharedTranscriptReaderProps {}

export function FontSizeSlider({
  setConfig,
  config
}: Readonly<FontSizeSliderProps>) {
  const { isDarkMode, fontSize } = config;

  const [fontChange, setFontChange] = useState(16);

  const handleFontSizeChange = (e: number) => {
    setFontChange(e);
    setConfig({
      ...config,
      fontSize: e
    });
  };

  const textColor = isDarkMode ? 'white' : 'black';

  return (
    <Box
      p="1rem 1.5rem 1.5rem"
      bg={isDarkMode ? 'black' : 'lightGrey.200'}
      maxH="16rem"
    >
      <Box textAlign="center">
        <Text pb="1.5rem" color={textColor} fontSize="sm" fontWeight="medium">
          Text Size
        </Text>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text
          color={textColor}
          fontSize={fontSize}
          fontWeight="bold"
          transition="all 0.3s ease-in-out"
        >
          Aa
        </Text>
        <Text color={textColor} fontSize="7xl" fontWeight="bold">
          Aa
        </Text>
      </Box>
      <Box>
        <Slider
          max={50}
          width="100%"
          zIndex={1}
          onChangeEnd={(e) => {
            handleFontSizeChange(e);
          }}
          onChange={(e) => {
            handleFontSizeChange(e);
          }}
          value={fontChange}
          step={0.01}
        >
          <SliderTrack bg="darkGrey.300">
            <SliderFilledTrack bg="tigerOrange.600" />
          </SliderTrack>

          <Circle
            pos="absolute"
            bg="tigerOrange.600"
            transform="auto"
            size="1rem"
            left={`${fontChange * 2}%`}
            translateY="-50%"
          />
        </Slider>
      </Box>
    </Box>
  );
}
