import { Flex, type FlexProps } from '@chakra-ui/react';

export function DarkPanel({ children, ...props }: FlexProps) {
  return (
    <Flex
      direction="column"
      boxShadow="0px 4px 20px rgba(0, 0, 0, 0.9);"
      border="1px solid"
      borderRadius="md"
      borderColor="darkGrey.400"
      backgroundColor="darkGrey.700"
      p="1.5rem"
      flex={1}
      {...props}
    >
      {children}
    </Flex>
  );
}
