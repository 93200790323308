import { type Ref, forwardRef } from 'react';
import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import { type Comment, type Pretty } from '@tigerhall/core/lib/types';
import { type THLinkProps } from '@tigerhall/ui-components';
import { DateTime } from 'luxon';

import { NameWithBadge } from '~/components/ui/User/NameWithBadge';
import { CommentOptions, type CommentOptionsProps } from '../CommentOptions';
import { CommentText } from './components/CommentText';

export interface CommentBoxProps extends Pick<CommentOptionsProps, 'onDelete'> {
  comment: Pretty<
    Pick<Comment, 'id' | 'rawText' | 'comment' | 'updatedAt' | 'createdAt'> &
      Pick<THLinkProps, 'user'> & {
        user: {
          id: string;
        };
      }
  >;
  isBoxHighlighted?: boolean;
  forwardedRef?: Ref<HTMLDivElement>;
}

export const CommentBox = forwardRef<HTMLDivElement, CommentBoxProps>(
  ({ comment, onDelete, isBoxHighlighted }, ref) => {
    const isEdited = false;
    // Note: Remove this commented code when we introduce
    // comment edit feature in our product.
    // const isEdited = !!comment.updatedAt && comment.updatedAt !== comment.createdAt;

    return (
      <Box
        ref={ref}
        backgroundColor={isBoxHighlighted ? 'darkGrey.300' : 'darkGrey.400'}
        mb="0.5rem"
        p="0.75rem"
        borderRadius="md"
        borderColor={isBoxHighlighted ? 'tigerOrange.600' : 'transparent'}
        borderWidth="1px"
        w="100%"
      >
        <HStack>
          <Box flex={1}>
            <NameWithBadge
              userId={comment.user.id}
              fontSize="sm"
              color="lightGrey.400"
              fontWeight="bold"
              noOfLines={1}
              badgeProps={{
                size: 'md'
              }}
            />
          </Box>
          {isEdited ? (
            <Tooltip
              label={`Edited on ${DateTime.fromISO(comment.updatedAt).toFormat(
                'DDD'
              )}`}
            >
              <Text fontSize="xs" color="darkGrey.50">
                Edited
              </Text>
            </Tooltip>
          ) : null}
          <CommentOptions
            commentId={comment.id}
            authorId={comment.user.id}
            onDelete={onDelete}
          />
        </HStack>
        <CommentText text={comment.rawText || comment.comment} />
      </Box>
    );
  }
);
