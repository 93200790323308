import { Icon } from '@chakra-ui/react';
import { IconCalendarEvent } from '@tigerhall/icons';
import { TrackedLink } from '@tigerhall/components';

export function Calendar() {
  return (
    <TrackedLink
      name="SCHEDULE"
      href="/home/calendar"
      as="button"
      aria-label="Calendar"
      borderRadius="lg"
      id="calendar"
      variant="unstyled"
      display="flex"
      alignItems="center"
      p=".5rem"
      _active={{
        background: 'darkGrey.400'
      }}
      _hover={{
        background: 'darkGrey.400'
      }}
    >
      <Icon
        as={IconCalendarEvent}
        color="lightGrey.400"
        height="1.5rem"
        width="1.5rem"
      />
    </TrackedLink>
  );
}
