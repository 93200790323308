import { DateTime, Duration } from 'luxon';
import capitalize from 'lodash/capitalize';
import _uniqBy from 'lodash/uniqBy';

import {
  ConsumableContentFormats,
  Content,
  ContentCard,
  ContentType,
  DisplayableContentType,
  Ebook,
  Event,
  EventType,
  LearningPath,
  Podcast,
  ReportableContentType,
  Stream
} from '~/types';
import { isLearningPath } from '../typeGuards';

const EMPTY_ARRAY = Object.freeze([]);

export function formatContentType(type: ContentType, plural = false): string {
  switch (type) {
    case ContentType.Stream:
      return plural ? 'Videos' : 'Video';

    case ContentType.Podcast:
      return plural ? 'Podcasts' : 'Podcast';

    case ContentType.Ebook:
      return plural ? 'Power reads' : 'Power read';

    case ContentType.Expert:
      return plural ? 'Thinkfluencers' : 'Thinkfluencer';

    case ContentType.Event:
      return plural ? 'Experiences' : 'Experience';

    default:
      throw new Error(`Unknown content type ${type}`);
  }
}

export function formatEventType(content: Event): string {
  switch (content.eventType) {
    case EventType.AskMeAnything:
      return 'Ask Me Anything';

    case EventType.Conference:
      return 'Conference';

    case EventType.SmallGroupSession:
      return 'Small Group Session';

    case EventType.VirtualEvent:
      return 'Virtual Event';

    case EventType.Webinar:
      return 'Webinar';

    case EventType.Livestream:
      return 'Livestream';

    case EventType.MeetInPerson:
      return 'Meet In Person';

    default:
      throw new Error('unsupported event type');
  }
}

export type ExtractContentFromLearningPath = {
  __typename: LearningPath['__typename'];
  ebooks?: (Partial<Omit<Ebook, '__typename' | 'userContentTracking'>> & {
    __typename: Ebook['__typename'];
    userContentTracking?: ContentConsumedTime['userContentTracking'];
  })[];
  podcasts?: (Partial<Omit<Podcast, '__typename' | 'userContentTracking'>> & {
    __typename: Podcast['__typename'];
    userContentTracking?: ContentConsumedTime['userContentTracking'];
  })[];
  events?: (Partial<Omit<Event, '__typename' | 'userContentTracking'>> & {
    __typename: Event['__typename'];
    userContentTracking?: ContentConsumedTime['userContentTracking'];
  })[];
  streams?: (Partial<Omit<Stream, '__typename' | 'userContentTracking'>> & {
    __typename: Stream['__typename'];
    userContentTracking?: ContentConsumedTime['userContentTracking'];
  })[];
  sortOrder: LearningPath['sortOrder'];
};

/**
 * @returns an array of content cards sorted by the `sortOrder` field of the learning path
 * or an empty array if there is no content in the learning path such as `ebooks`, `podcasts`, `events` or `streams`.
 */
export function extractLearningPathContent<
  T extends ExtractContentFromLearningPath
>(
  learningPath: T
): (
  | NonNullable<NonNullable<T['ebooks']>[0]>
  | NonNullable<NonNullable<T['events']>[0]>
  | NonNullable<NonNullable<T['podcasts']>[0]>
  | NonNullable<NonNullable<T['streams']>[0]>
)[] {
  const {
    ebooks = EMPTY_ARRAY,
    podcasts = EMPTY_ARRAY,
    events = EMPTY_ARRAY,
    streams = EMPTY_ARRAY,
    sortOrder
  } = learningPath || {};

  const contents = [...ebooks, ...podcasts, ...events, ...streams];

  function comparator(a, b) {
    return sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id);
  }

  return contents.sort(comparator);
}

export function extractContentTypeFromContent(
  content: Pick<ContentCard, '__typename'>
): ContentType {
  return content?.__typename?.toUpperCase() as ContentType;
}

export function formatContentTypeAsSlug(type: ContentType) {
  return formatContentType(type).toLowerCase().replace(' ', '-');
}

export type ContentTotalTime =
  | { __typename: Stream['__typename']; length: number }
  | { __typename: Podcast['__typename']; length: number }
  | { __typename: Ebook['__typename']; readingTime?: number | null }
  | { __typename: Event['__typename']; startsAt?: string; endsAt?: string }
  | {
      __typename: LearningPath['__typename'];
      contentCards: ContentTotalTime[];
    };

export function contentTotalTime(content: ContentTotalTime): number {
  switch (content?.__typename) {
    case 'Stream':
    case 'Podcast':
      return content.length;
    case 'Ebook':
      return (content?.readingTime ?? 0) * 60;

    case 'Event': {
      const { startsAt, endsAt } = content;

      if (!startsAt || !endsAt) {
        return 0;
      }

      // Reason to use milliseconds is because in some cases seconds were 0.
      const differenceInMilliSeconds = DateTime.fromISO(startsAt).diff(
        DateTime.fromISO(endsAt)
      ).milliseconds;

      return Math.abs(differenceInMilliSeconds) / 1000;
    }

    case 'LearningPath':
      return content.contentCards.reduce(
        (time, card) => time + contentTotalTime(card),
        0
      );

    default:
      return 0;
  }
}

type TotalListenTime = {
  userContentTracking?: {
    isFinished: boolean;
    current: number;
    currentPlacement: number;
    total: number;
  };
  episodes: { audio: { length: number } }[];
  length: number;
};

export function totalListenTime(podcast: TotalListenTime) {
  if (!podcast?.userContentTracking) {
    return 0;
  }

  const {
    current,
    currentPlacement,
    isFinished: isPodcastFinished
  } = podcast.userContentTracking;

  if (isPodcastFinished) {
    return podcast.length;
  }

  const combinedListenTime = podcast.episodes.reduce(
    (previousValue, element, i: number) => {
      // If it's the previous episode then we assume they have listened to the entire episode
      if (i < current) {
        return previousValue + element?.audio?.length || 0;
      }

      if (i === current) {
        return previousValue + currentPlacement;
      }
      return previousValue;
    },
    0
  );

  return combinedListenTime || 0;
}

type ContentConsumedTime = (
  | {
      __typename: Stream['__typename'];
      length: number;
    }
  | {
      __typename: Podcast['__typename'];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      episodes: any[];
      length: number;
    }
  | {
      __typename: Ebook['__typename'];
      readingTime?: number | null;
    }
  | {
      __typename: Event['__typename'];
    }
) & {
  userContentTracking?: {
    isFinished: boolean;
    current: number;
    currentPlacement: number;
    total: number;
  };
};

export function contentConsumedTime(content: ContentConsumedTime): number {
  const isContentFinished = content?.userContentTracking?.isFinished ?? false;

  switch (content.__typename) {
    case 'Podcast':
      return totalListenTime(content);
    case 'Ebook': {
      const current = content.userContentTracking?.current ?? 0;
      const readingTime = content.readingTime ?? 0;
      const total = content?.userContentTracking?.total || 1;

      return isContentFinished
        ? readingTime * 60
        : current * (readingTime / total) * 60;
    }
    case 'Stream': {
      const currentPlacement =
        content.userContentTracking?.currentPlacement ?? 0;

      return isContentFinished ? content.length : currentPlacement;
    }
    default:
      return 0;
  }
}

export function learningPathProgress(cards: ContentConsumedTime[]): {
  consumed: number;
  total: number;
} {
  return cards.reduce(
    (aggregated, content) => ({
      consumed: aggregated?.consumed + contentConsumedTime(content),
      total: aggregated?.total + contentTotalTime(content)
    }),
    { consumed: 0, total: 0 }
  );
}

type LearningPathWithTracking = {
  __typename: LearningPath['__typename'];
  ebooks?: {
    __typename: Ebook['__typename'];
    readingTime?: number | null;
    userContentTracking?: ContentConsumedTime['userContentTracking'];
  }[];
  podcasts?: {
    __typename: Podcast['__typename'];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    episodes: any[];
    length: number;
    userContentTracking?: ContentConsumedTime['userContentTracking'];
  }[];
  streams?: {
    __typename: Stream['__typename'];
    length: number;
    userContentTracking?: ContentConsumedTime['userContentTracking'];
  }[];
  events?: {
    __typename: Event['__typename'];
  }[];
  sortOrder: LearningPath['sortOrder'];
};

type GetContentCardProgress =
  | LearningPathWithTracking
  | (ContentConsumedTime & ContentTotalTime);

export function getContentCardProgress(card: GetContentCardProgress): {
  consumed: number;
  total: number;
} {
  if (isLearningPath(card)) {
    return learningPathProgress(
      extractLearningPathContent<LearningPathWithTracking>(card)
    );
  }

  return {
    consumed: contentConsumedTime(card),
    total: contentTotalTime(card)
  };
}

export function formatShortContentDuration(seconds: number) {
  if (!seconds) {
    return '';
  }

  const duration = Duration.fromObject({ seconds }).shiftTo('hours', 'minutes');

  const hours = duration.hours ? `${duration.hours}h ` : '';
  const minutes = duration.minutes ? `${Math.round(duration.minutes)}m` : '';

  return minutes === '0m' ? '<1m' : `${hours}${minutes}`.trim();
}

type IsFinished = {
  __typename: Content['__typename'] | LearningPath['__typename'];
  userContentTracking?: {
    isFinished: boolean;
  };
  contentCards?: IsFinished[];
};

export function isFinished(card: IsFinished): boolean {
  if (isLearningPath(card)) {
    const hasContentCards = Boolean(card.contentCards?.length);

    const didFinishAllContentCards = hasContentCards
      ? card.contentCards?.every((c) => c?.userContentTracking?.isFinished)
      : false;

    return Boolean(didFinishAllContentCards);
  }

  return Boolean(card.userContentTracking?.isFinished);
}

export function extractDurationFromLearningContent(
  contents: ContentTotalTime[]
) {
  const combinedTime = contents.reduce(
    (aggregated, content) => aggregated + contentTotalTime(content),
    0
  );
  return formatShortContentDuration(combinedTime);
}

export function extractExpertsFromLearningContents(
  contents: ConsumableContentFormats[]
) {
  return _uniqBy(
    contents
      ?.map((contentItem) => contentItem.experts)
      ?.flat()
      ?.filter((item) => item?.firstName || item?.lastName),
    (item) => `${item?.firstName}-${item?.lastName}`
  );
}

export function extractCompanyFromLearningContents(
  contents: ConsumableContentFormats[]
) {
  return _uniqBy(
    contents
      ?.map((contentItem) => contentItem.experts)
      ?.flat()
      ?.filter((item) => item?.company)
      ?.map((item) => item?.company),
    (item) => item
  );
}

export function extractContentName(content: ConsumableContentFormats) {
  return content?.name;
}

/**
 * @deprecated: Use the extractContentDuration instead
 */
export function extractContentReadingTime(content: ContentTotalTime) {
  return formatShortContentDuration(contentTotalTime(content));
}

export function extractExpertInfo(
  content: Pick<ConsumableContentFormats, 'experts'>
) {
  const expert = content?.experts?.[0];
  return `${expert?.firstName} ${expert?.lastName} | ${expert?.title} | ${expert?.company}`;
}

export function extractContentType(content: Pick<ContentCard, '__typename'>) {
  return capitalize(content?.__typename);
}

export function extractContentDuration(content: ContentTotalTime) {
  return formatShortContentDuration(contentTotalTime(content));
}

export function extractContentTypeText(content?: string) {
  switch (content) {
    case 'Ebook':
      return 'Ebook';
    case 'Podcast':
      return 'Podcast';
    case 'Event':
      return 'Event';
    case 'Stream':
      return 'Stream';
    case 'LearningPath':
      return 'Trail';
    case 'Expert':
      return 'Expert';

    default:
      return content;
  }
}

export function extractSlugToContentType(contentType: string): ContentType {
  switch (contentType.toLowerCase()) {
    case 'podcast':
      return ContentType.Podcast;

    case 'power-read':
      return ContentType.Ebook;

    case 'stream':
      return ContentType.Stream;

    case 'experience':
      return ContentType.Event;

    case 'thinkfluencer':
      return ContentType.Expert;

    default:
      throw new Error(`Unknown content type ${contentType}`);
  }
}

export function getTimeIntervalForEventToStart(
  content: Pick<Event, 'startsAt'>
): Duration | null {
  if (content?.startsAt) {
    return DateTime.fromISO(content?.startsAt).diff(DateTime.now(), [
      'days',
      'hours',
      'minutes',
      'second'
    ]);
  }
  return null;
}

export function getTimeLeftForEventToStart(
  content: Pick<Event, 'startsAt'>
): string | null {
  const difference = getTimeIntervalForEventToStart(content);
  if (difference) {
    if (difference?.seconds < 0) {
      return null;
    }

    if (difference?.days > 0) {
      return `${difference?.days} ${difference?.days > 1 ? 'days' : 'day'}`;
    }

    return `${difference.toFormat('hh:mm:ss')}`;
  }

  return null;
}

export function sortLearningPath({
  ebooks = [],
  podcasts = [],
  events = [],
  streams = [],
  sortOrder
}: Pick<
  LearningPath,
  'ebooks' | 'podcasts' | 'events' | 'streams' | 'sortOrder'
>) {
  const content = [...ebooks, ...podcasts, ...events, ...streams];

  function compareById(a, b) {
    return sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id);
  }

  return content.sort(compareById);
}

export function getContentPiecesCount(content: LearningPath) {
  if (content.__typename === 'LearningPath') {
    if (content.contentCards) {
      return content.contentCards?.length;
    }
    return extractLearningPathContent(content).length;
  }
}

export function convertArrayToChunks<T>(data: T[], size: number): T[][] {
  const items = [...data];
  const chunks: T[][] = [];

  if (size === 0) {
    return [items];
  }

  while (items.length > 0) {
    chunks.push(items.splice(0, size));
  }

  return chunks;
}

export type ContentTypename =
  | Podcast['__typename']
  | Ebook['__typename']
  | Event['__typename']
  | Stream['__typename']
  | LearningPath['__typename'];

export function getContentTypeSlug(type: ContentType | ContentTypename) {
  switch (type?.toUpperCase()) {
    case ContentType.Stream:
      return 'streams';

    case ContentType.Podcast:
      return 'podcasts';

    case ContentType.Ebook:
      return 'power-reads';

    case ContentType.Event:
      return 'experiences';

    case 'LEARNINGPATH':
    case ContentType.LearningPath:
      return 'trails';

    case ContentType.Expert:
      return 'thinkfluencers';

    default:
      throw new Error(`Unknown content type ${type}`);
  }
}

export function getReportableContentType(
  type: keyof typeof ReportableContentType | undefined
) {
  switch (type) {
    case 'Ebook':
      return ReportableContentType.Ebook;
    case 'Event':
      return ReportableContentType.Event;
    case 'Podcast':
      return ReportableContentType.Podcast;
    case 'Stream':
      return ReportableContentType.Stream;
    case 'LearningPath':
      return ReportableContentType.LearningPath;
    case 'Comment':
      return ReportableContentType.Comment;
    case 'Post':
      return ReportableContentType.Post;
    case 'User':
      return ReportableContentType.User;
    default:
      throw new Error(`Unknown reportable content type: ${type}`);
  }
}

/**
 * @example```
 * const eventName = getEventName(eventType);
 * ```
 */
export function getEventName(eventType: EventType) {
  switch (eventType) {
    case 'SMALL_GROUP_SESSION':
      return 'SMALL GROUP SESSION';
    case 'ASK_ME_ANYTHING':
      return 'VIRTUAL SESSION';
    case 'LIVESTREAM':
      return 'LIVESTREAM';
    case 'CONFERENCE':
    case 'VIRTUAL_EVENT':
    case 'MEET_IN_PERSON':
    case 'WEBINAR':
    default:
      return 'EXPERIENCE';
  }
}

/**
 * Get the public facing name for a given content type.
 *
 * @param contentType - It's simply the content type
 * @param eventType - In case `contentType` is `Event`, we need this 
 * arg to determine the name for the given `eventType`.
 * You might need to do a validation here, see the example below.
 * 
 * @example```
 * const name = getContentName(
    contentType,
    content.__typename === 'Event' ? content.eventType : undefined
  );
 * ```
 */
export function getContentName<T extends DisplayableContentType>(
  contentType: T,
  eventType: T extends ContentType.Event ? EventType : never
) {
  switch (contentType) {
    case ContentType.Ebook:
      return 'POWER READ';
    case ContentType.Stream:
      return 'VIDEO';
    case ContentType.Event:
      return getEventName(eventType);
    case ContentType.LearningPath:
      return 'TRAIL';
    case ContentType.Podcast:
    default:
      return 'PODCAST';
  }
}
