import { Flex, useBreakpointValue } from '@chakra-ui/react';

import { DrawerTabs, type DrawerTabsProps } from './components/DrawerTabs';
import { LiveHeader, type LiveHeaderProps } from './components/LiveHeader';

export interface DrawerContentProps extends Omit<DrawerTabsProps, 'isLive'> {
  liveHeaderProps?: LiveHeaderProps & { isLive: boolean };
}

export function DrawerContent({
  content,
  isPlaylist,
  type,
  liveHeaderProps,
  defaultSelectedTab
}: Readonly<DrawerContentProps>) {
  const isMobile = useBreakpointValue(
    { base: true, lg: false },
    {
      fallback: 'lg',
      ssr: false
    }
  );

  /* Setting `overflow: hidden` because we do not want to scroll the tabs out of view*/

  return (
    <Flex
      height={'100%'}
      maxH={'calc(100vh - 1rem)'}
      direction={'column'}
      overflow={'hidden'}
      w={'inherit'}
    >
      {liveHeaderProps?.isLive && !isMobile && (
        <LiveHeader {...liveHeaderProps} />
      )}
      <DrawerTabs
        content={content}
        isPlaylist={isPlaylist}
        isLive={liveHeaderProps?.isLive ?? false}
        type={type}
        defaultSelectedTab={defaultSelectedTab}
      />
    </Flex>
  );
}
