import { type ComponentStyleConfig } from '@chakra-ui/react';

export const Radio: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: (props) => ({
    control: {
      border: '2px solid',
      borderColor: 'lightGrey.900',

      _checked: {
        bg: 'tigerOrange.600',
        border: '2px solid',
        borderColor: 'tigerOrange.600'
      }
    }
  }),

  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    sm: { label: { fontSize: '0.875rem' } },
    md: { label: { fontSize: '0.875rem' } },
    lg: { label: { fontSize: '0.875rem' } }
  },

  // styles for different visual variants ("outline", "solid")
  variants: {},

  defaultProps: {
    colorScheme: 'tigerOrange'
  }
};
