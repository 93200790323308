import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CreatePostInput, PrivacySettings } from '@tigerhall/core/lib/types';
import type { RootState } from 'app/reducer';
import isUndefined from 'lodash/isUndefined';

// ------------------------------------
// Reducer
// ------------------------------------

interface State extends CreatePostInput {
  parentPostPrivacy?: PrivacySettings;
}

const initialState: State = {
  description: '',
  mentions: undefined,
  parentPost: undefined,
  privacy: PrivacySettings.Public,
  attachments: []
};

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setPrivacySetting: (
      state: State,
      action: PayloadAction<State['privacy']>
    ): State => ({
      ...state,
      privacy: action.payload
    }),
    setDescription: (
      state: State,
      action: PayloadAction<State['description']>
    ): State => ({
      ...state,
      description: action.payload
    }),
    setMentions: (
      state: State,
      action: PayloadAction<State['mentions']>
    ): State => ({
      ...state,
      mentions: action.payload
    }),
    setParentPost: (
      state: State,
      action: PayloadAction<State['parentPost']>
    ): State => ({
      ...state,
      parentPost: action.payload
    }),
    setAttachments: (
      state: State,
      action: PayloadAction<State['attachments']>
    ): State => ({
      ...state,
      attachments: action.payload
    }),
    /**
     *
     * @description only replace previewLink attachments in the
     * attachments array and keep other as it is.
     */

    setLinkAttachments: (
      state: State,
      action: PayloadAction<State['attachments']>
    ) => {
      const attachmentsExceptPreviewLink = state.attachments.filter((each) => {
        return isUndefined(each?.previewLink);
      });

      return {
        ...state,
        attachments: [...attachmentsExceptPreviewLink, ...action.payload]
      };
    },
    setParentPostPrivacySetting: (
      state: State,
      action: PayloadAction<State['parentPostPrivacy']>
    ): State => ({
      ...state,
      parentPostPrivacy: action.payload
    }),
    resetPost: () => initialState
  }
});

export const {
  setPrivacySetting,
  setDescription,
  setMentions,
  setParentPost,
  setAttachments,
  setLinkAttachments,
  setParentPostPrivacySetting,
  resetPost
} = postSlice.actions;

// ------------------------------------
// Selectors
// ------------------------------------
export function getPostDescription(state: RootState) {
  return state.post.description ?? '';
}

export function getPostPrivacySetting(state: RootState) {
  return state.post.privacy ?? PrivacySettings.Public;
}

export function getPostMentions(state: RootState) {
  return state.post.mentions ?? [];
}

export function getParentPost(state: RootState) {
  return state.post.parentPost ?? '';
}

export function getPostAttachments(state: RootState) {
  return state.post.attachments ?? [];
}

export function getParentPostPrivacySetting(state: RootState) {
  return state.post.parentPostPrivacy;
}

export function getPost(state: RootState) {
  const { parentPostPrivacy, ...post } = state.post;
  return post;
}
