import { type ExoticComponent } from 'react';
import { PermissionIdentifier } from '@tigerhall/core';
import {
  IconBook,
  IconCalendarEvent,
  IconClipboardText,
  IconFileAnalytics,
  IconGraph,
  IconHeadphones,
  IconHelpCircle,
  IconHome,
  IconLive,
  IconLogout,
  IconMovie,
  IconNews,
  IconRoute,
  IconSearch,
  IconSettings,
  IconTopologyRing,
  IconUser,
  IconUsers
} from '@tigerhall/icons';

export const SIDEBAR_WIDTH_COLLAPSED = '3.25rem';
export const SIDEBAR_WIDTH_EXPANDED = '14.25rem';

export type MenuItemType = {
  /**
   * Label to be displayed in the sidebar
   */
  label: string;

  /**
   * Route to be navigated to when the menu item is clicked
   */
  route: string;

  /**
   * Weather to match exactly on the route or only if the route starts with the provided route
   *
   * This is used in the admin section where we want the menu item to be highlighted even when access a sub-route.
   */
  isActiveRouteMatchExact?: boolean;

  /**
   * Icon to be displayed in the sidebar
   */
  icon: ExoticComponent;

  /**
   * The accessLevel the menu belongs to
   */
  accessLevel?: PermissionIdentifier;
};

export type MenuSectionType = {
  /**
   * An optional label to be displayed in the sidebar
   */
  label?: string;

  /**
   * Whether the menu section is visible on mobile
   */
  isMobileVisible?: boolean;

  /**
   * Whether the menu section is visible on desktop
   */
  isDesktopVisible?: boolean;

  /**
   * Whether the menu section is visible to microsoft teams
   */
  isTeamsVisible?: boolean;

  /**
   * Children menu items to be displayed in the sidebar
   */
  children: MenuItemType[];
};

export const MENU_ITEMS: MenuSectionType[] = [
  {
    isMobileVisible: true,
    isTeamsVisible: true,
    children: [
      {
        label: 'Home',
        route: '/home',
        isActiveRouteMatchExact: true,
        icon: IconHome
      },
      {
        label: 'Search',
        route: '/search',
        icon: IconSearch
      },
      {
        label: 'Feed',
        route: '/feed',
        icon: IconNews
      },
      {
        label: 'Calendar',
        route: '/home/calendar',
        isActiveRouteMatchExact: true,
        icon: IconCalendarEvent
      },
      {
        label: 'Profile',
        route: '/profile',
        icon: IconUser
      }
    ]
  },
  {
    label: 'ADMIN PORTAL',
    isDesktopVisible: true,
    children: [
      {
        label: 'Statistics',
        route: '/admin/statistics',
        icon: IconGraph,
        accessLevel: PermissionIdentifier.ViewAndExportStatistics
      },
      {
        label: 'Report Builder',
        route: '/admin/report-builder',
        icon: IconFileAnalytics,
        accessLevel: PermissionIdentifier.ViewAndExportStatistics
      },
      {
        label: 'Manage Users',
        route: '/admin/users',
        icon: IconUsers,
        accessLevel: PermissionIdentifier.EditUsersAndGroups
      },
      {
        label: 'Manage Groups',
        route: '/admin/groups',
        icon: IconTopologyRing,
        accessLevel: PermissionIdentifier.EditUsersAndGroups
      },
      {
        label: 'Settings',
        route: '/admin/settings',
        icon: IconSettings,
        accessLevel: PermissionIdentifier.SetAccessLevels
      }
    ]
  },
  {
    label: 'ASSIGN CONTENT',
    isDesktopVisible: true,
    children: [
      {
        label: 'Manage Assignments',
        route: '/admin/assignments',
        icon: IconClipboardText,
        accessLevel: PermissionIdentifier.CreateEditAssignments
      }
    ]
  },
  {
    label: 'MY CONTENT',
    isDesktopVisible: true,
    children: [
      {
        label: 'Trails',
        route: '/admin/trails',
        icon: IconRoute,
        accessLevel: PermissionIdentifier.CreateCustomContent
      },
      {
        label: 'Videos',
        route: '/admin/videos',
        icon: IconMovie,
        accessLevel: PermissionIdentifier.CreateCustomContent
      },
      {
        label: 'Broadcast',
        route: '/admin/broadcasts',
        icon: IconMovie,

        // Ugly hack to only show this menu item to super admins
        accessLevel: 'BROADCAST' as PermissionIdentifier
      },
      {
        label: 'Experiences',
        route: '/admin/events',
        icon: IconLive,
        accessLevel: PermissionIdentifier.CreateCustomContent
      },
      {
        label: 'Podcasts',
        route: '/admin/podcasts',
        icon: IconHeadphones,
        accessLevel: PermissionIdentifier.CreateCustomContent
      },
      {
        label: 'Power Reads',
        route: '/admin/ebooks',
        icon: IconBook,
        accessLevel: PermissionIdentifier.CreateCustomContent
      }
    ]
  }
];

export function getMenuItems(
  isMobile: boolean,
  isInTeams: boolean,
  isSuperAdmin: boolean,
  hiddenRoutes: string[],
  accessLevels: PermissionIdentifier[]
): MenuSectionType[] {
  return MENU_ITEMS.filter((menuItem) => {
    const hasChildWithAccess = menuItem.children.some(
      (child) => !child.accessLevel || accessLevels.includes(child.accessLevel)
    );

    if (!isSuperAdmin && !hasChildWithAccess) {
      return false;
    }

    if (menuItem.isMobileVisible && isMobile) {
      return true;
    }

    if (menuItem.isDesktopVisible && !isMobile) {
      return true;
    }

    return !!(menuItem.isTeamsVisible && isInTeams);
  }).map((menuItem) => {
    const children = menuItem.children.filter((child) => {
      if (hiddenRoutes.indexOf(child.route) !== -1) {
        return false;
      }

      if (isSuperAdmin) {
        return true;
      }

      if (!child.accessLevel) {
        return true;
      }

      return accessLevels.includes(child.accessLevel);
    });

    return { ...menuItem, children };
  });
}

export const supportMenuItem: MenuItemType = {
  label: 'Support',
  route: '/support',
  icon: IconHelpCircle
};

export const logoutMenuItem: MenuItemType = {
  label: 'Logout',
  route: '/logout',
  icon: IconLogout
};
