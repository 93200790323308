import {
  UserCommentsReportColumns as Columns,
  ContentCardsSorting,
  ContentType,
  type UserCommentsReportFilter as Filter,
  type Maybe,
  UserCommentsLocation,
  formatContentType
} from '@tigerhall/core';
import {
  type GenericRow,
  type TableSelectDataResponse
} from '@tigerhall/components';

import {
  type AllStaticFilters,
  type DynamicFilter,
  type ReportColumn,
  type ReportTemplate,
  StaticFilterType
} from '~/modules/admin/modules/reportBuilder/types';
import { type ReportBuilderWizardStoreState } from '~/modules/admin/modules/reportBuilder/stores';
import { apolloClient } from '~/api/apollo';
import {
  ExportUserCommentsReportDocument,
  type ExportUserCommentsReportMutation,
  type ExportUserCommentsReportMutationVariables,
  GetContentTitlesDocument,
  type GetContentTitlesQuery,
  type GetContentTitlesQueryVariables
} from '~/modules/admin/modules/reportBuilder/queries';
import {
  extractStaticDropDownValue,
  transformStateToDefaultFilters,
  transformStaticDateToTimeRange
} from '~/modules/admin/modules/reportBuilder/utils';

export class CommentsReport implements ReportTemplate<Filter> {
  getName(): string {
    return 'User Comments';
  }

  getDescription(): string {
    return 'List of comments by any user on any feed, post or content piece.';
  }

  isEnabled(): boolean {
    return true;
  }

  getColumns(): ReportColumn[] {
    return [
      {
        id: Columns.UserName,
        name: 'Username'
      },
      {
        id: Columns.Email,
        name: 'Email'
      },
      {
        id: Columns.TigerhallUserId,
        name: 'Tigerhall User ID'
      },
      {
        id: Columns.Date,
        name: 'Date'
      },
      {
        id: Columns.CommentDetail,
        name: 'Comment Detail'
      },
      {
        id: Columns.Location,
        name: 'Location'
      },
      {
        id: Columns.CommentType,
        name: 'Comment Type'
      },
      {
        id: Columns.ContentTitle,
        name: 'Content Title, Parent Feed Post Or Parent Comment'
      },
      {
        id: Columns.Link,
        name: 'Link'
      }
    ];
  }

  getSampleData(): Record<string, string | number | boolean> {
    return {
      [Columns.UserName]: 'John Doe',
      [Columns.Email]: 'john.doe@tigerhall.com',
      [Columns.TigerhallUserId]: '8e24070e-3388-42ae-9e49-997b0ce4e7bb',
      [Columns.Date]: '2021-07-01',
      [Columns.CommentDetail]: 'Great post!',
      [Columns.Location]: 'Feed Post',
      [Columns.CommentType]: 'Comment',
      [Columns.ContentTitle]: 'How to be a great leader',
      [Columns.Link]: 'https://tigerhall.com'
    };
  }

  getStaticFilters(): AllStaticFilters<Filter>[] {
    return [
      {
        name: 'timeFilter',
        label: 'Time Period',
        placeholder: 'Select a time period',
        tooltip: '',
        type: StaticFilterType.DateTime
      },
      {
        name: 'location',
        label: 'Location',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        options: [
          { label: 'On Content', value: UserCommentsLocation.Content },
          { label: 'In the Feed', value: UserCommentsLocation.Feed }
        ]
      },
      {
        name: 'includeArchivedUsers',
        label: 'Include Archived Users',
        placeholder: 'Select Filter',
        type: StaticFilterType.DropDown,
        isClearDisabled: true,
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' }
        ]
      }
    ];
  }

  getDynamicFilter(): DynamicFilter<Filter> | null {
    return {
      id: 'contents',
      label: 'Content Pieces',
      description: 'Filter the report on a number of content pieces',

      columns: [
        {
          id: 'name',
          name: 'Name'
        },
        {
          id: 'format',
          name: 'Format',
          accessor: (row: GenericRow) => {
            return formatContentType(row['__typename'].toUpperCase(), false);
          }
        }
      ],

      rules: {
        required: false
      },

      dataSource: {
        query: async (
          context,
          keywords,
          limit,
          offset
        ): Promise<TableSelectDataResponse> => {
          const response = await apolloClient.query<
            GetContentTitlesQuery,
            GetContentTitlesQueryVariables
          >({
            query: GetContentTitlesDocument,
            variables: {
              filter: {
                types: [
                  ContentType.Podcast,
                  ContentType.Event,
                  ContentType.Stream,
                  ContentType.Ebook
                ],
                keywords,
                offset,
                limit
              },
              sorting: ContentCardsSorting.PublishedAt
            },

            /**
             * Todo figure out why cache is breaking my pagination
             */
            fetchPolicy: 'no-cache'
          });

          return {
            edges: response.data.contentCards.edges,
            meta: response.data.contentCards.meta
          };
        }
      }
    };
  }

  /**
   * This number is totally fake / made up but it doesn't matter as no org will have a million comments
   *
   * @param filter
   * @param audienceSize
   */
  calculatePotentialRowCount(
    filter: Filter,
    audienceSize: number
  ): Promise<number> {
    return Promise.resolve(audienceSize);
  }

  async export(state: ReportBuilderWizardStoreState): Promise<void> {
    const { reportFilters: filter } = state;

    const standardFilter = await transformStateToDefaultFilters<Columns>(state);

    await apolloClient.mutate<
      ExportUserCommentsReportMutation,
      ExportUserCommentsReportMutationVariables
    >({
      mutation: ExportUserCommentsReportDocument,
      variables: {
        filter: {
          ...standardFilter,

          contents: filter.contents.map((r: GenericRow) => r.id),

          timeFilter: transformStaticDateToTimeRange(filter.timeFilter),

          location: extractStaticDropDownValue(
            filter.location
          ) as Maybe<UserCommentsLocation>
        }
      }
    });
  }
}
