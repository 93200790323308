import { IconShare3 } from '@tigerhall/icons';
import { ShareModal, useShareModal } from '@tigerhall/components';
import { User } from '@tigerhall/core';
import { useDisclosure } from '@chakra-ui/react';
import { captureException } from '@sentry/react';

import { OptionsMenuItem } from '~/components/ui/OptionsMenu';

interface ShareProfileOptionProps {
  user: Pick<User, 'id' | 'firstName' | 'lastName' | 'biography'>;
}

export function ShareProfileOption({
  user
}: Readonly<ShareProfileOptionProps>) {
  const { id, firstName, lastName, biography } = user;

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: false
  });

  const urlToShare = `${import.meta.env.VITE_WEBAPP_URL}/profile/${id}`;

  const titleToShare = `${firstName} ${lastName}`;
  const descriptionToShare = biography ?? '';

  const { isAvailable, openShareModal } = useShareModal({
    urlToShare,
    title: titleToShare,
    description: descriptionToShare,
    onOpen
  });

  if (!isAvailable) {
    return null;
  }

  return (
    <>
      <OptionsMenuItem
        icon={IconShare3}
        title="Share Profile"
        description="Creates a shareable link to the user's profile"
        onClick={(event) => {
          openShareModal(event).catch((error) => {
            captureException(error);
          });
        }}
      />
      <ShareModal
        isOpen={isOpen}
        onClose={onClose}
        heading="Share Profile"
        titleToShare={titleToShare}
        urlToShare={urlToShare}
      />
    </>
  );
}
