import * as segment from '@tigerhall/analytics';
import { useCallback, useEffect, useMemo } from 'react';
import { useTabFocusHandler } from '@tigerhall/core';
import type { ContentAutoplayedState } from '@tigerhall/analytics';

import {
  getIsAutoNextPlayEnabled,
  getIsMinimized,
  getPlayingContent,
  setIsLastContentInPlaylist
} from '~/app/state';
import { useVideoAction } from '~/modules/application/hooks/useVideoAction';
import { usePodcastAction } from '~/modules/application/hooks/usePodcastAction';
import { useEbookAction } from '~/modules/application/hooks/useEbookAction';
import type { PlaylistContent, PlaylistTrailContent } from './types';
import { useAppDispatch, useAppSelector } from '../redux';
import { useGetUpNextQueueItems } from '../useGetUpNextQueueItems';

export function useAutoplayContent() {
  const dispatch = useAppDispatch();

  const { isLoading, queueItems } = useGetUpNextQueueItems();

  const playingContent = useAppSelector(getPlayingContent);
  const isAutoNextPlayEnabled = useAppSelector(getIsAutoNextPlayEnabled);
  const isPlayerIsMinimized = useAppSelector(getIsMinimized);

  const { isFocused } = useTabFocusHandler();

  const { playVideo } = useVideoAction();
  const { playPodcast } = usePodcastAction();
  const { startEbook } = useEbookAction();

  const playerViewState = useMemo((): ContentAutoplayedState => {
    if (isFocused) {
      return isPlayerIsMinimized ? 'Minimized' : 'Maximized';
    }
    return 'Backgrounded';
  }, [isFocused, isPlayerIsMinimized]);

  const handleContent = useCallback(
    (nextContentToPlay: PlaylistTrailContent) => {
      switch (nextContentToPlay?.__typename) {
        case 'Podcast': {
          playPodcast({ id: nextContentToPlay.id });
          break;
        }
        case 'Stream': {
          playVideo({ id: nextContentToPlay.id });
          break;
        }
        case 'Ebook': {
          startEbook({ id: nextContentToPlay.id });
          break;
        }
      }

      segment.contentAutoplayed({
        state: playerViewState
      });
    },
    [playPodcast, playVideo, startEbook, playerViewState]
  );

  useEffect(() => {
    /**
     * creating this flat array of all the playable content
     * so that it's easier to find the next content
     */
    const allContentFlatArray = queueItems.flatMap((queueItem) =>
      queueItem.__typename === 'LearningPath'
        ? queueItem.contentCards
        : queueItem
    );
    const currentPlayingIndex = allContentFlatArray.findIndex(
      (queueItem) => queueItem.id === playingContent?.id
    );

    dispatch(
      setIsLastContentInPlaylist(
        currentPlayingIndex === allContentFlatArray.length - 1
      )
    );

    if (!isAutoNextPlayEnabled) {
      return;
    }

    if (currentPlayingIndex < allContentFlatArray.length - 1) {
      let nextContentToPlay: PlaylistContent | undefined =
        allContentFlatArray[currentPlayingIndex + 1];

      if (nextContentToPlay?.__typename === 'Event') {
        /** Skipping to next playable content is the `nextContentToPlay` is an Event */
        nextContentToPlay = allContentFlatArray
          .slice(currentPlayingIndex + 1)
          .find((content) => content.__typename !== 'Event');
      }

      if (nextContentToPlay) {
        handleContent(nextContentToPlay);
      } else {
        /** exits the useEffect because there is no content to play if last content is an Event */
        return;
      }
    }

    /**
     * this dispatch is to prevent overlay from showing
     * `next piece starts in 5, 4, 3, 2, 1` count down
     * on completing the last content in the playlist
     */

    dispatch(
      setIsLastContentInPlaylist(
        currentPlayingIndex >= allContentFlatArray.length - 2
      )
    );
  }, [
    dispatch,
    handleContent,
    isAutoNextPlayEnabled,
    playingContent?.id,
    queueItems
  ]);

  return {
    isLoading,
    queueItems
  };
}
