import { ComponentStyleConfig } from '@chakra-ui/react';

import { Input } from './input';

export const Textarea: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: (props) => ({
    ...(Input.baseStyle as (props: any) => any)(props).field,
    minH: '10rem'
  }),

  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    variant: '',
    focusBorderColor: 'tigerOrange.800'
  }
};
