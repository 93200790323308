import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import {
  FormDateTimePicker,
  FormImageUpload,
  FormInput,
  FormVideoUpload,
  TrackedForm
} from '@tigerhall/components';
import {
  CategoriesSelect,
  ExpertsSelect,
  FormAdditionalInfo,
  OrganisationGroupSelect
} from 'components/form';
import { FullWidthBox } from 'components/ui';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FetchStreamQuery } from 'generated';
import { EditorForm } from 'components/ui/lexical/form/EditorForm';
import { LoadingScreen } from 'modules/admin/components';
import { UseFormReturn } from 'react-hook-form';

import { UploadCompleted } from './components';
import { useGalleryImageUpload } from '../../../../../../../../hooks';

export interface FormValues
  extends Pick<
    NonNullable<FetchStreamQuery['stream']>,
    | 'name'
    | 'preamble'
    | 'image'
    | 'experts'
    | 'categories'
    | 'organisationGroups'
    | 'contentSegments'
  > {
  publishedAt: Date;
  video: File;
}

interface Props {
  isDraft: boolean;

  onSubmit: (values: FormValues, form: UseFormReturn<FormValues>) => void;

  defaultValues: Partial<FormValues>;
  uploadProgress: number;
  isUploadingError?: boolean;
  includePublicExperts?: boolean;
}

export function Form({
  isDraft,
  onSubmit,
  defaultValues,
  uploadProgress,
  isUploadingError,
  includePublicExperts
}: Props) {
  const navigate = useNavigate();
  const upload = useGalleryImageUpload();

  const buttons = () => [
    {
      variant: 'outline',
      onClick: () => {
        navigate(-1);
      },
      text: 'Cancel',
      id: 'cancel'
    },
    {
      type: 'submit',

      text: isDraft ? 'Create' : 'Update',
      id: 'create'
    }
  ];

  const minDate = DateTime.now().minus({ days: 1 }).toJSDate();

  return (
    <TrackedForm
      name="CREATE_VIDEO"
      onSubmit={onSubmit}
      formHookProps={{ defaultValues }}
    >
      {({ formState: { isSubmitting, isSubmitSuccessful, isValid } }) => {
        if (isSubmitting && isDraft && isValid) {
          return (
            <LoadingScreen progress={uploadProgress} title="video uploading" />
          );
        }

        if (isSubmitSuccessful && !isUploadingError && !isSubmitting) {
          return <UploadCompleted />;
        }

        return (
          <FullWidthBox
            title={isDraft ? 'New Video' : 'Update Video Details'}
            subTitle="Video Details"
            buttons={buttons()}
          >
            <Grid templateColumns="repeat(3, 1fr)">
              <GridItem colSpan={2} pr="8" borderRight="1px solid #474747">
                <Grid gap="4" templateColumns="repeat(2, 1fr)">
                  <FormInput
                    name="name"
                    label="Video Title"
                    placeholder="Enter video title"
                    rules={{
                      required: 'Please select the video title',
                      minLength: {
                        value: 3,
                        message: 'Video title must be at least 3 characters'
                      },
                      maxLength: {
                        value: 85,
                        message: 'Video title must be at most 85 characters'
                      }
                    }}
                  />

                  <ExpertsSelect
                    name="experts"
                    isMulti
                    required
                    includePublicExperts={includePublicExperts}
                  />
                  <CategoriesSelect name="categories" isMulti required />
                  <OrganisationGroupSelect name="organisationGroups" isMulti />
                  <FormDateTimePicker
                    name="publishedAt"
                    label="Release Date"
                    placeholder="Select schedule release date"
                    minDate={minDate}
                    rules={{
                      required: 'Please select schedule release date'
                    }}
                  />
                </Grid>

                <Box mt="4">
                  <EditorForm
                    name="preamble"
                    label="Preamble"
                    placeholder="Add a 2-3 sentence description that tells people what this video is about, topics that are covered, why they should watch it, and who it is by. "
                    rules={{
                      required: 'Please add a preamble'
                    }}
                    showHeadings={false}
                  />

                  <FormAdditionalInfo title="Additional Information" />
                </Box>
              </GridItem>

              <GridItem pl="8">
                <Flex direction="column" gap="1rem">
                  {isDraft && (
                    <FormVideoUpload
                      name="video"
                      label="Select Video File for Upload"
                      rules={{
                        required: 'Please select a video'
                      }}
                    />
                  )}

                  <FormImageUpload
                    name="image"
                    label="Cover Image"
                    hint="(Minimum 800x600px resolution)"
                    upload={upload}
                    showResetButton={false}
                    rules={{
                      required: 'Please upload a cover image'
                    }}
                  />
                </Flex>
              </GridItem>
            </Grid>
          </FullWidthBox>
        );
      }}
    </TrackedForm>
  );
}
