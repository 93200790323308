import * as React from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { ContentPanel } from 'components/ui';

import { GroupsSelectionTable } from './GroupsSelectionTable';
import { UsersSelectionTable } from './UsersSelectionTable';

interface SelectedUsersAndGroupsProps {
  selectedUserRowIds: string[];
  onUserRowSelection: (rows: string[]) => void;
  selectedGroupRowIds: string[];
  onGroupRowSelection: (row: string[]) => void;
}

export function SelectedUsersAndGroups({
  selectedUserRowIds,
  onUserRowSelection,
  selectedGroupRowIds,
  onGroupRowSelection
}: Readonly<SelectedUsersAndGroupsProps>) {
  return (
    <ContentPanel heading="Users & Groups">
      <Tabs isLazy>
        <TabList>
          <Tab>Users</Tab>
          <Tab>Groups</Tab>
        </TabList>
        <TabPanels>
          <TabPanel padding="0">
            <UsersSelectionTable
              selectedRowIds={selectedUserRowIds}
              onRowSelection={onUserRowSelection}
            />
          </TabPanel>
          <TabPanel padding="0">
            <GroupsSelectionTable
              selectedRowIds={selectedGroupRowIds}
              onRowSelection={onGroupRowSelection}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ContentPanel>
  );
}
