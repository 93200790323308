import { Typography } from '@tigerhall/ui-components';

type FollowCountPorps = {
  followCount: string;
};

const FollowCount = ({ followCount }: FollowCountPorps) => (
  <>
    <Typography variant="heading4" color="lightGrey.200" my="1.5rem">
      {followCount || ''}
    </Typography>
  </>
);

export default FollowCount;
