import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgBrandGoogleColor = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    focusable={false}
    className="th-icon"
    ref={ref}
    {...props}
  >
    <path
      fill="#4285F4"
      d="M22.914 12.272c0-.85-.073-1.669-.207-2.454H12v4.642h6.118c-.263 1.5-1.064 2.77-2.268 3.621v3.011h3.674c2.15-2.089 3.39-5.165 3.39-8.82Z"
    />
    <path
      fill="#34A853"
      d="M12 24c3.07 0 5.642-1.075 7.523-2.907l-3.673-3.011c-1.018.72-2.32 1.145-3.85 1.145-2.961 0-5.467-2.11-6.361-4.947H1.84v3.11C3.711 21.31 7.556 24 12 24Z"
    />
    <path
      fill="#FBBC05"
      d="M5.639 14.28A7.577 7.577 0 0 1 5.282 12c0-.791.13-1.56.357-2.28V6.61H1.84A12.542 12.542 0 0 0 .63 12c0 1.936.44 3.769 1.21 5.389l3.798-3.11Z"
    />
    <path
      fill="#EA4335"
      d="M12 4.773c1.669 0 3.167.605 4.346 1.794l3.26-3.442C17.637 1.19 15.064 0 12 0 7.556 0 3.71 2.69 1.84 6.61L5.64 9.72c.894-2.836 3.4-4.947 6.36-4.947Z"
    />
  </svg>
);
SvgBrandGoogleColor.displayName = "IconBrandGoogleColor";
const ForwardRef = forwardRef(SvgBrandGoogleColor);
export default ForwardRef;
