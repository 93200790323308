import { useEffect } from 'react';
import { useMicrosoftTeams } from '@tigerhall/core';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { hasMicrosoftTeamsTenantId, isLoggedIn, logout } from '../../app/state';

/**
 * If a user is authenticated on the WebApp and loads the app in Microsoft Teams
 * We need to make sure they are logged out if they don't have a tenantId in their token
 * as this will cause problems related tot he microsoft teams specific requests.
 *
 * @constructor
 */
export function MicrosoftTeamsAuthGuard() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(isLoggedIn);
  const hasTokenWithTenantId = useAppSelector(hasMicrosoftTeamsTenantId);
  const { isInTeams } = useMicrosoftTeams();

  useEffect(() => {
    // There will be lots of issues if we use a JWT token that doesn't contain the tenantId
    if (isInTeams && isAuthenticated && !hasTokenWithTenantId) {
      console.warn(
        'User is authenticated but does not have a tenantId in their token. Logging out.'
      );
      dispatch(logout());
    }
  }, [dispatch, isInTeams, isAuthenticated, hasTokenWithTenantId]);

  return null;
}
