import {
  LightMode,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';
import { MeetingsEmbed } from '@tigerhall/components';
import { useNavigate } from 'react-router-dom';

const BookMeetingModal = () => {
  const navigate = useNavigate();

  const handleModalClose = () => {
    navigate(-1);
  };

  return (
    <LightMode>
      <Modal isOpen onClose={handleModalClose} size="lg">
        <ModalOverlay />
        <ModalContent rounded="lg" overflow="hidden">
          <ModalCloseButton />
          <ModalBody>
            <MeetingsEmbed paddingTop="0rem" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </LightMode>
  );
};
export default BookMeetingModal;
