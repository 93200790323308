import * as React from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { Select as ReactSelect } from 'chakra-react-select';
import property from 'lodash/property';
import * as segment from '@tigerhall/analytics';

import { SelectChakraStyles } from './styles';
import { PagesCounter } from './components/PagesCounter';

const DEFAULT_SIZES = [10, 20, 50, 100, 250, 500, 1000, 2500, 5000];
const SELECT_NAME = 'tablePaginator';

export type PaginationProps = {
  setPageSize: (size: number) => void;
  gotoPage: (index: number) => void;

  canNextPage: boolean;
  canPreviousPage: boolean;

  pageIndex: number;
  pageSize: number;
  pageCount: number;

  sizes?: number[];
  rowsAlias?: string;
};

type Option = {
  label: string;
  value: number;
};

export function TablePaginator({
  gotoPage,
  setPageSize,
  pageIndex,
  pageCount,
  canNextPage,
  canPreviousPage,
  sizes = DEFAULT_SIZES,
  rowsAlias = 'rows',
  pageSize
}: PaginationProps) {
  const options: Option[] = React.useMemo(
    () => sizes.map((s) => ({ value: s, label: `${s} ${rowsAlias}` })),
    [sizes, rowsAlias]
  );

  const selected = React.useMemo(
    () => options.find((option) => option.value === pageSize),
    [options, pageSize]
  );

  return (
    <Flex mt={5} alignItems="center" justifyContent="center">
      <HStack spacing="1rem">
        <Flex alignItems="center" gap=".5rem" fontSize="xs">
          <Text color="white" fontSize="inherit">
            Page
          </Text>
          <PagesCounter
            label={pageIndex + 1}
            onClickUp={canNextPage ? () => gotoPage(pageIndex + 1) : undefined}
            onClickDown={
              canPreviousPage ? () => gotoPage(pageIndex - 1) : undefined
            }
          />
          {pageCount ? (
            <Text color="white" fontSize="inherit">
              of {pageCount}
            </Text>
          ) : null}
        </Flex>
        <Box w={32}>
          {/* TODO: In the future we want to create an UncontrolledSelect to replace this */}
          <ReactSelect
            name={SELECT_NAME}
            onChange={(option) => {
              segment.dropdownSelectionClicked({
                dropdownName: SELECT_NAME,
                selection: (option as Option)?.label,
                location: window.location.pathname
              });

              setPageSize((option as Option)?.value || 10);
            }}
            data-cy={`pagination-${SELECT_NAME}`}
            options={options}
            value={selected}
            getOptionLabel={property('label')}
            getOptionValue={property('value')}
            chakraStyles={SelectChakraStyles}
            defaultValue={options[0]}
          />
        </Box>
      </HStack>
    </Flex>
  );
}
