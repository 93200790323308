import {
  Box,
  Flex,
  Heading,
  Icon,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import { IconRoute } from '@tigerhall/icons';
import {
  ContentBarWithLinks,
  ContentCardWithLinks
} from 'components/ui/ContentBox';

import { useGetTrailOfTheMonthQuery } from './queries/generated/trailOfTheMonth.generated';
import { TrailOfTheMonthSkeleton } from './TrailOfTheMonthSkeleton';

export function TrailOfTheMonth() {
  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { fallback: 'md', ssr: false }
  );

  const { data, loading } = useGetTrailOfTheMonthQuery();

  const trailOfTheMonth = data?.promotedLearningPathOfTheMonth;

  if (!loading && !trailOfTheMonth) {
    return <TrailOfTheMonthSkeleton />;
  }

  if (!trailOfTheMonth) {
    return null;
  }

  return (
    <Box
      as="article"
      p={{
        base: '0.75rem 1rem',
        md: '0.75rem 2rem'
      }}
      width="100%"
    >
      <Flex
        direction={{
          base: 'column',
          md: 'row'
        }}
        gap={{
          base: '1.5rem',
          md: '3.5rem'
        }}
        p={{
          base: '1rem',
          sm: '2rem',
          md: '3rem'
        }}
        justifyContent="center"
        alignItems="center"
        backgroundColor="teal.800"
        rounded="lg"
        width="100%"
        height="auto"
        position="relative"
        overflow="hidden"
      >
        <Icon
          display={{
            base: 'none',
            md: 'block'
          }}
          aria-hidden
          as={IconRoute}
          color="blackAlpha.400"
          boxSize="30rem"
          position="absolute"
          left={{
            base: '-10rem',
            xl: '0'
          }}
        />
        <Flex
          direction="column"
          gap={{
            base: '0.75rem',
            md: '1.5rem'
          }}
          width="100%"
          maxWidth="40rem"
          zIndex={1}
        >
          <Heading
            color="white"
            fontSize={{
              base: 'xl',
              md: '2xl'
            }}
            lineHeight="normal"
          >
            Recommended
            <br />
            Trail of the month
          </Heading>
          <Text color="lightGrey.600" fontSize="md">
            {trailOfTheMonth.description}
          </Text>
        </Flex>
        {isMobile ? (
          <ContentBarWithLinks
            sectionName="Trail of the Month"
            {...trailOfTheMonth.learninPath}
          />
        ) : (
          <ContentCardWithLinks
            sectionName="Trail of the Month"
            {...trailOfTheMonth.learninPath}
          />
        )}
      </Flex>
    </Box>
  );
}
