import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  type ModalProps
} from '@chakra-ui/react';
import {
  type Ebook,
  type Event,
  type Podcast,
  type Stream
} from '@tigerhall/core';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { LeftPanel, RightPanel } from './children';

export interface ContentSummaryModalProps {
  content: Ebook | Podcast | Stream | Event;
  actions: React.ReactNode;
  isOpen: ModalProps['isOpen'];
  onClose?: ModalProps['onClose'];
}

export function ContentSummaryModal({
  actions,
  content,
  isOpen,
  onClose
}: Readonly<ContentSummaryModalProps>) {
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={isOpen}
      size="5xl"
      onClose={onClose ? onClose : () => navigate(-1)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent border="1px solid" borderColor="lightGrey.200" p={0}>
        <ModalCloseButton />
        <ModalBody p={0} borderRadius="md">
          <Flex direction="row" flex={1} minHeight="30rem" maxHeight="30rem">
            <Flex
              flex={1}
              p="1.5rem"
              direction="column"
              justifyContent="space-between"
              gap="3rem"
            >
              <LeftPanel content={content} actions={actions} />
            </Flex>
            <Flex
              flex={2}
              direction="column"
              borderRightRadius="md"
              bg="darkGrey.400"
              p="1.5rem"
              gap="1.5rem"
              overflow="auto"
            >
              <RightPanel content={content} />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
