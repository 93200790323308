import * as segment from '@tigerhall/analytics';
import { useInterval, useTabFocusHandler } from '@tigerhall/core';
import { useCallback, useEffect, useRef } from 'react';

import { useMediaState, type MediaStateStore } from '../context';
import { THRESHOLD_FOR_MAKING_MEDIA_AS_COMPLETED } from '../utils/const';
import {
  reportProgress,
  useAnalytics,
  type UseAnalyticsArgs
} from './useAnalytics';

/**
 * This is a subset of the MediaState that allows us to significantly reduce the number of re-renders by not listening
 * to the progress value as that triggers about four time per second, and it's not needed until we want to report the time spent
 * or send a progress update in which case we can use the zustand getter to fetch it.
 */
interface InternalState {
  progress: number;
  isPlaying: boolean;
  duration: number;
  didJustSeek: boolean;
  playbackRate: number;
}

function convertMediaStateToInternalState(
  state: Pick<
    MediaStateStore,
    | 'isPlaying'
    | 'customDuration'
    | 'duration'
    | 'didJustSeek'
    | 'playbackRate'
    | 'getProgress'
  >
): InternalState {
  return {
    progress: state.getProgress(),
    isPlaying: state.isPlaying,
    duration: state.customDuration ?? state.duration,
    didJustSeek: state.didJustSeek,
    playbackRate: state.playbackRate
  };
}

export function useMediaAnalytics({
  contentId,
  contentType,
  playlistId,
  playlistType,
  playerState,
  onTrackProgress,
  onTrackProgressIntervalMS = 10_000,
  onTrackFinished,
  disableTimeSpentReporting,
  isOffline = false,
  isLive = false,
  mediaType,
  language
}: Readonly<UseAnalyticsArgs>) {
  const mediaState = useMediaState((state) => ({
    getProgress: state.getProgress,
    isPlaying: state.isPlaying,
    customDuration: state.customDuration,
    duration: state.duration,
    didJustSeek: state.didJustSeek,
    playbackRate: state.playbackRate
  }));

  const { isFocused } = useTabFocusHandler();

  const { startedTrackingAt, prevReportingTime, markedAsFinished } =
    useAnalytics();

  // This is used to track changes between states
  const prevState = useRef<InternalState>(
    convertMediaStateToInternalState(mediaState)
  );

  // This is used to compare the current state to the previous state when reporting consumption analytics
  const prevReportingState = useRef<InternalState>(
    convertMediaStateToInternalState(mediaState)
  );

  const reportSeek = useCallback(
    (state: InternalState) => {
      segment.contentPositionSeek({
        contentId,
        contentType,

        positionFrom: prevReportingState.current.progress,
        positionTo: state.progress,

        // this is not correct, but we only have 40 somewhat podcasts that have multiple episodes,
        // and we have removed the ability to create new ones.  So this is a temporary fix
        episodeIndex: 0
      });
    },
    [contentId, contentType]
  );

  const reportTimeSpent = useCallback(
    (state: InternalState) => {
      if (disableTimeSpentReporting) {
        return;
      }

      const newReportingTime = Date.now();

      // How long time has passed for the person viewing since we last reported
      const duration = (newReportingTime - prevReportingTime.current) / 1000;

      // How long time has passed in the actual media since we last reported
      const durationByPositionDelta =
        state.progress - prevReportingState.current.progress;

      reportProgress({
        contentId,
        contentType,
        playlistId,
        playlistType,
        duration,
        durationByPositionDelta,
        playbackRate: state.playbackRate,
        position: {
          total: state.duration,
          previous: prevReportingState.current.progress,
          current: state.progress
        },
        startedTrackingAt: new Date(startedTrackingAt.current),
        playerState: !isFocused ? 'Backgrounded' : playerState,
        isOffline,
        isLive,
        language,
        mediaType
      });

      onTrackProgress(state.progress);

      prevReportingTime.current = newReportingTime;
      prevReportingState.current = state;
    },
    [
      contentId,
      contentType,
      disableTimeSpentReporting,
      isFocused,
      isOffline,
      isLive,
      onTrackProgress,
      playerState,
      playlistId,
      playlistType,
      prevReportingTime,
      startedTrackingAt,
      language,
      mediaType
    ]
  );

  /*
   * When the state transitions to paused from playing we want to reportTimeSpent the progress and pause tracking
   */
  useEffect(() => {
    const newPrevState = convertMediaStateToInternalState(mediaState);

    switch (true) {
      // Reporting tracking because the user paused the media
      case !mediaState.isPlaying && prevState.current.isPlaying:
        reportTimeSpent(newPrevState);
        break;

      // Reset the prev reporting time when the user starts playing again
      case mediaState.isPlaying && !prevState.current.isPlaying:
        prevReportingTime.current = Date.now();
        break;

      // Reporting tracking because the user just seeked
      case mediaState.didJustSeek:
        reportSeek(newPrevState);

        if (disableTimeSpentReporting) {
          break;
        }

        if (mediaState.isPlaying) {
          // calls both `reportProgress` and `onTrackProgress`
          reportTimeSpent(newPrevState);
        } else {
          onTrackProgress(mediaState.getProgress());
        }
        break;
    }

    prevState.current = newPrevState;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTrackProgress, reportSeek, reportTimeSpent, mediaState]);

  /**
   * Mark the content as finished if the user has watched more than the configured threshold
   */
  useInterval(() => {
    const currentDuration = mediaState.customDuration ?? mediaState.duration;

    if (currentDuration <= 0) {
      return;
    }

    const completionRatio = mediaState.getProgress() / currentDuration;

    if (completionRatio < THRESHOLD_FOR_MAKING_MEDIA_AS_COMPLETED) {
      return;
    }

    if (markedAsFinished.current) {
      return;
    }

    markedAsFinished.current = true;

    onTrackFinished();
    reportTimeSpent(convertMediaStateToInternalState(mediaState));
  }, 1000);

  useInterval(() => {
    // We only want to progressively reportTimeSpent if there has been no state changes
    if (!mediaState.isPlaying || !prevState.current.isPlaying) {
      return;
    }

    reportTimeSpent(convertMediaStateToInternalState(mediaState));
  }, onTrackProgressIntervalMS);
}
