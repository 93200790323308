import { useMemo } from 'react';
import * as segment from '@tigerhall/analytics';
import {
  type Organisation,
  useGetAllOrganisationsQuery,
  useReauthorizeWithOrgMutation
} from 'generated';
import { useLocation } from 'react-router-dom';
import { Select as ReactSelect } from 'chakra-react-select';
import { captureException } from '@sentry/react';
import {
  getSelectedOrg,
  setAccessToken,
  setSelectedOrganisation
} from 'app/state';
import { getSelectChakraStyles } from '@tigerhall/components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { AppError } from 'utils';

export default function OrganisationSelection(): JSX.Element | null {
  const org = useAppSelector(getSelectedOrg);
  const dispatch = useAppDispatch();

  const chakraStyles = useMemo(
    () => ({
      ...getSelectChakraStyles<
        Pick<
          Organisation,
          'name' | 'id' | 'type' | 'activeUsers' | 'subscription'
        >
      >('dark'),
      control: (provided) => ({
        ...provided,
        backgroundColor: 'darkGrey.700',
        padding: 2,
        paddingY: 1
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        height: 'auto'
      })
    }),
    []
  );

  const { data, loading, error } = useGetAllOrganisationsQuery({
    variables: {
      filter: {
        limit: 1000
      },
      sorting: {
        sorting: [
          {
            field: 'name',
            direction: 'asc'
          }
        ]
      }
    }
  });

  const { pathname } = useLocation();

  const [exchangeJWT] = useReauthorizeWithOrgMutation();

  if (loading || error) {
    return null;
  }

  if (!data?.organisations || data?.organisations.edges.length <= 1) {
    return null;
  }

  return (
    <ReactSelect
      isClearable={false}
      isMulti={false}
      options={data?.organisations.edges as Organisation[]}
      defaultValue={org}
      getOptionLabel={(o) => `${o.name} - ${o.type}`}
      getOptionValue={(o) => o.id}
      onChange={async (value) => {
        if (!value) {
          return;
        }

        segment.dropdownSelectionClicked({
          dropdownName: 'OrganisationSelection',
          selection: String(value?.name),
          location: pathname
        });

        try {
          // Get a new JWT associated to the new organisation
          const { data: exchangeJWTResponse, errors } = await exchangeJWT({
            variables: {
              orgId: value.id
            }
          });

          const freshToken = exchangeJWTResponse?.reauthorizeWithOrg.jwt;

          if (freshToken) {
            // This works in conjunction with the logic in `Layout.tsx`
            // if not selected org has been set then we show the splash screen
            dispatch(setSelectedOrganisation(null));
            dispatch(
              setAccessToken({
                token: freshToken
              })
            );

            // Reload the page when the main thread is idle
            window.setTimeout(() => {
              window.location.reload();
            }, 500);
          }

          throw new AppError('Error Switching Organization', {
            extra: {
              exchangeJWTResponse,
              errors
            }
          });
        } catch (err) {
          captureException(
            err,
            err instanceof AppError ? { ...err.context } : {}
          );
        }
      }}
      chakraStyles={chakraStyles}
    />
  );
}
