import { Text } from '@chakra-ui/react';
import { useFlag } from '@unleash/proxy-client-react';
import { BetaFlags } from '@tigerhall/core';

interface BetaBadgeProps {
  betaFlag: BetaFlags;
}

export function BetaBadge({ betaFlag }: BetaBadgeProps) {
  const isBetaFlagEnabled = useFlag(betaFlag);

  return isBetaFlagEnabled ? (
    <Text
      as="span"
      display="inline-block"
      fontSize="xs"
      fontWeight="medium"
      whiteSpace="nowrap"
      textTransform="uppercase"
      lineHeight="normal"
      borderRadius="base"
      color="white"
      backgroundColor="blue.600"
      padding={1}
    >
      Beta
    </Text>
  ) : null;
}
