import { IconRepeat } from '@tigerhall/icons';
import { Icon, MenuButton } from '@chakra-ui/react';
import { OptionsMenuList } from 'components/ui/OptionsMenu';

import { ActionButton } from '../ActionButton';
import {
  InstantRepost,
  type InstantRepostProps
} from './components/InstantRepost';
import {
  RepostWithThoughts,
  type RepostWithThoughtsProps
} from './components/RepostWithThoughts';

export interface RepostProps
  extends InstantRepostProps,
    RepostWithThoughtsProps {}

export function Repost({ postId, privacySetting }: RepostProps) {
  return (
    <OptionsMenuList
      maxWidth="23rem"
      menuButton={
        <MenuButton
          as={ActionButton}
          leftIcon={<Icon as={IconRepeat} />}
          __css={{
            // Reason: `MenuButton` adds an extra `span` to `children` with `flex: 1 1 auto;`
            '& span': {
              flex: 'unset'
            }
          }}
          alignItems="center"
          justifyContent="center" // Reason: `MenuButton` modifies the styles of `ActionButton`
        >
          Repost
        </MenuButton>
      }
    >
      <RepostWithThoughts postId={postId} privacySetting={privacySetting} />
      <InstantRepost postId={postId} />
    </OptionsMenuList>
  );
}
