import { Box } from '@chakra-ui/react';
import { type Chapter } from 'generated';
import { useFreeAccount } from 'hooks';
import { useCallback, useMemo, useState } from 'react';

import { PreviewWall } from '../PreviewWall';
import { PowerReadPlayer, type PowerReadPlayerProps } from './components';
import {
  useGetEbookAudioByLanguageQuery,
  useGetEbookContentByLanguageQuery,
  useGetEbookForConnectedPowerReadPlayerQuery
} from './queries';
import { PlayerLoader } from '../PlayerLoader';

interface PowerReadPlayerConnectedProps
  extends Pick<
    PowerReadPlayerProps,
    'onFinished' | 'onClose' | 'isMinimized' | 'onMaximize' | 'setIsMinimized'
  > {
  id: string;
  userPreferredLanguage: string;
}

export function calculatePagesInChapters(
  chapters: Pick<Chapter, 'pages'>[]
): number {
  return chapters.reduce((acc, chapter) => acc + chapter.pages.length, 0);
}

export function PowerReadPlayerConnected({
  id,
  onFinished,
  onClose,
  onMaximize,
  isMinimized,
  setIsMinimized,
  userPreferredLanguage
}: Readonly<PowerReadPlayerConnectedProps>) {
  const [contentLanguage, setContentLanguage] = useState<string>(
    userPreferredLanguage
  );
  const [audioLanguage, setAudioLanguage] = useState<string | null>(
    userPreferredLanguage
  );

  const [shouldShowPreviewWall, setShouldShowPreviewWall] =
    useState<boolean>(false);
  const { isInPreviewMode } = useFreeAccount();
  const { data: { ebook: powerRead } = {} } =
    useGetEbookForConnectedPowerReadPlayerQuery({
      variables: {
        id
      },
      fetchPolicy: 'cache-and-network'
    });

  const { data: { ebook: powerReadContent } = {} } =
    useGetEbookContentByLanguageQuery({
      variables: {
        id,
        language: contentLanguage
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        /**
         * We are setting English as the content language,
         * if Ebook is not present for the preferred language
         * set by user from languages in settings.
         */
        if (data.ebook?.chapters.length === 0) {
          setContentLanguage('EN');
        }
      }
    });

  const { data: { ebook: powerReadAudio } = {} } =
    useGetEbookAudioByLanguageQuery({
      variables: {
        id,
        language: audioLanguage
      },
      fetchPolicy: 'cache-first',
      skip: !audioLanguage,
      onCompleted: (data) => {
        /**
         * We are setting English as the Ebook's audio language,
         * if Ebook's audio content is not present for the preferred language
         * set by user from languages in settings.
         */
        if (!data.ebook?.episodes.language) {
          setAudioLanguage('EN');
        }
      }
    });

  const totalPages = useMemo(
    () =>
      powerReadContent?.chapters
        ? calculatePagesInChapters(powerReadContent?.chapters)
        : 0,
    [powerReadContent?.chapters]
  );

  const onPageChange = useCallback(
    (pageNumber: number) => {
      const isOnLastPage = pageNumber === totalPages - 1;

      if (totalPages && isOnLastPage && isInPreviewMode) {
        setShouldShowPreviewWall(true);
      }
    },
    [totalPages, isInPreviewMode]
  );

  // if power read chapters array is empty we keep showing the loader
  // until we query for default language content
  if (!powerRead || totalPages === 0) {
    return <PlayerLoader />;
  }

  // we always get 1 episode which contains the audio for the whole power read
  const episodes = powerReadAudio?.episodes?.episodes ?? [];
  const audio = episodes[0]?.audio;

  return (
    <Box
      w="full"
      h="100%"
      sx={{
        '& #power-read-player': {
          h: 'full'
        }
      }}
      pos="relative"
    >
      <PowerReadPlayer
        powerRead={powerRead}
        chapters={powerReadContent?.chapters}
        audio={audio}
        onFinished={onFinished}
        setAudioLanguage={setAudioLanguage}
        audioLanguage={audioLanguage}
        setContentLanguage={setContentLanguage}
        contentLanguage={contentLanguage}
        onClose={onClose}
        onMaximize={onMaximize}
        isMinimized={isMinimized}
        setIsMinimized={setIsMinimized}
        onPageChange={onPageChange}
      />
      {isInPreviewMode && shouldShowPreviewWall ? (
        <PreviewWall onClose={onClose} />
      ) : null}
    </Box>
  );
}
