import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import {
  FormDateTimePicker,
  FormImageUpload,
  FormInput,
  FormRadio,
  FormSelectGoogleMapsAddress,
  useTrackedForm
} from '@tigerhall/components';
import { Variant } from '@tigerhall/ui-components';
import { FullWidthBox } from 'components/ui';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useGalleryImageUpload } from 'hooks';
import { EditorForm } from 'components/ui/lexical/form/EditorForm';

import {
  CategoriesSelect,
  ExpertsSelect,
  FormAdditionalInfo,
  OrganisationGroupSelect
} from '../../../../../../../components/form';

interface FormValues {
  isOnline?: boolean;
}

interface Props {
  isDraft: boolean;

  onSubmit: (values: FormValues) => void;

  defaultValues: Partial<FormValues>;
}

export function Form({ isDraft, onSubmit, defaultValues }: Props) {
  const navigate = useNavigate();
  const upload = useGalleryImageUpload();

  const form = useTrackedForm('CREATE_EXPERIENCE', {
    defaultValues
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting }
  } = form;

  const isOnline = watch('isOnline') === true;

  const buttons = [
    {
      id: 'back',
      text: 'Cancel',
      variant: 'outline' as Variant,
      onClick: () => {
        navigate(-1);
      }
    },
    {
      id: 'continue',
      text: isDraft ? 'Create' : 'Update',
      dataCy: 'continue-button',
      onClick: handleSubmit(onSubmit),
      loading: isSubmitting
    }
  ];

  const minDate = DateTime.now().minus({ days: 1 }).toJSDate();

  return (
    <FullWidthBox
      title={isDraft ? 'Create New Experience' : 'Update Experience Details'}
      subTitle="Experience Details"
      buttons={buttons}
    >
      <FormProvider {...form}>
        <Grid templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={2} pr="8" borderRight="1px solid #474747">
            <Grid rowGap="4" columnGap="8" templateColumns="repeat(2, 1fr)">
              <FormInput
                name="name"
                label="Experience Title"
                placeholder="Enter experience title"
                rules={{
                  required: true,
                  minLength: {
                    value: 3,
                    message: 'Experience title must be at least 3 characters'
                  },
                  maxLength: {
                    value: 85,
                    message: 'Experience title must be at most 85 characters'
                  }
                }}
              />

              <ExpertsSelect name="experts" isMulti required />
              <CategoriesSelect name="categories" isMulti required />
              <OrganisationGroupSelect name="organisationGroups" isMulti />

              <FormDateTimePicker
                name="publishedAt"
                label="Release Date"
                placeholder="Select schedule release date"
                minDate={minDate}
                rules={{
                  required: true
                }}
              />
              <FormInput
                type="number"
                name="maxAttendees"
                label="Max Number of Attendees"
                placeholder="Enter max number"
                rules={{
                  required: true
                }}
              />

              <FormDateTimePicker
                name="startsAt"
                label="Starts At"
                placeholder="Select start time"
                minDate={minDate}
                rules={{
                  required: true
                }}
              />
              <FormDateTimePicker
                name="endsAt"
                label="Ends At"
                placeholder="Select end time"
                minDate={minDate}
                rules={{
                  required: true
                }}
              />
            </Grid>

            <Box mt="4">
              <EditorForm
                name="preamble"
                label="Preamble"
                placeholder="Add a 2-3 sentence description that tells people what this experience is about, topics that are covered, why they should watch it, and who it is by. "
                rules={{
                  required: true
                }}
                showHeadings={false}
              />

              <FormAdditionalInfo title="Additional Information" />
            </Box>
          </GridItem>

          <GridItem pl="8">
            <Flex direction="column" gap="1rem">
              <FormRadio
                label="Is an online event?"
                name="isOnline"
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' }
                ]}
                transform={{
                  onChange: (value) => value === 'true',
                  value: (value) => (value ? 'true' : 'false')
                }}
              />

              {isOnline ? (
                <>
                  <FormInput
                    name="conferenceUrl"
                    label="Conference Url"
                    placeholder="Enter conference url"
                  />
                  <FormInput
                    name="locationDisplayName"
                    label="Displayed location (Optional)"
                    placeholder="Enter another name for this location"
                  />
                </>
              ) : (
                <>
                  <FormSelectGoogleMapsAddress
                    name="location"
                    rules={{ required: true }}
                    label="Location"
                    placeholder="Enter the location of the event"
                  />
                  <FormInput
                    name="locationDisplayName"
                    label="Displayed location (Optional)"
                    placeholder="Enter another name for this location"
                  />
                </>
              )}

              <FormImageUpload
                name="image"
                label="Cover Image"
                hint="(Minimum 800x600px resolution)"
                upload={upload}
                showResetButton={false}
                rules={{
                  required: true
                }}
              />
            </Flex>
          </GridItem>
        </Grid>
      </FormProvider>
    </FullWidthBox>
  );
}
