import type { GraphQLErrors } from '@apollo/client/errors';
import { logout } from 'app/state';
import { store } from 'app/store';

export default function handleAPIErrors(
  graphQLErrors: GraphQLErrors | undefined
) {
  if (!graphQLErrors || graphQLErrors.length <= 0) {
    return;
  }

  let httpStatus: number | undefined;

  graphQLErrors.forEach((error) => {
    if (error?.message.includes('401')) {
      httpStatus = 401;
    }
  });

  switch (httpStatus) {
    case 401: {
      store.dispatch(logout());
      window.location.href = '/login?error=401';
      break;
    }
    case 400:
    case 404:
    case 405:
    case 407:
    case 408:
    case 422:
    case 429:
    default:
      return null;
  }
}
