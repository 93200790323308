import { isObject as H } from "./index38.mjs";
import rr from "./index39.mjs";
var er = (r) => /!(important)?$/.test(r), j = (r) => typeof r == "string" ? r.replace(/!(important)?$/, "").trim() : r, tr = (r, t) => (a) => {
  const o = String(t), d = er(o), l = j(o), p = r ? `${r}.${l}` : l;
  let u = H(a.__cssMap) && p in a.__cssMap ? a.__cssMap[p].varRef : t;
  return u = j(u), d ? `${u} !important` : u;
};
function M(r) {
  const { scale: t, transform: a, compose: o } = r;
  return (l, p) => {
    var u;
    const m = tr(t, l)(p);
    let k = (u = a == null ? void 0 : a(m, p)) != null ? u : m;
    return o && (k = o(k, p)), k;
  };
}
var x = (...r) => (t) => r.reduce((a, o) => o(a), t);
function g(r, t) {
  return (a) => {
    const o = { property: a, scale: r };
    return o.transform = M({
      scale: r,
      transform: t
    }), o;
  };
}
var ar = ({ rtl: r, ltr: t }) => (a) => a.direction === "rtl" ? r : t;
function or(r) {
  const { property: t, scale: a, transform: o } = r;
  return {
    scale: a,
    property: ar(t),
    transform: a ? M({
      scale: a,
      compose: o
    }) : o
  };
}
var V = [
  "rotate(var(--chakra-rotate, 0))",
  "scaleX(var(--chakra-scale-x, 1))",
  "scaleY(var(--chakra-scale-y, 1))",
  "skewX(var(--chakra-skew-x, 0))",
  "skewY(var(--chakra-skew-y, 0))"
];
function ir() {
  return [
    "translateX(var(--chakra-translate-x, 0))",
    "translateY(var(--chakra-translate-y, 0))",
    ...V
  ].join(" ");
}
function nr() {
  return [
    "translate3d(var(--chakra-translate-x, 0), var(--chakra-translate-y, 0), 0)",
    ...V
  ].join(" ");
}
var dr = {
  "--chakra-blur": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-brightness": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-contrast": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-grayscale": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-hue-rotate": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-invert": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-saturate": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-sepia": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-drop-shadow": "var(--chakra-empty,/*!*/ /*!*/)",
  filter: [
    "var(--chakra-blur)",
    "var(--chakra-brightness)",
    "var(--chakra-contrast)",
    "var(--chakra-grayscale)",
    "var(--chakra-hue-rotate)",
    "var(--chakra-invert)",
    "var(--chakra-saturate)",
    "var(--chakra-sepia)",
    "var(--chakra-drop-shadow)"
  ].join(" ")
}, sr = {
  backdropFilter: [
    "var(--chakra-backdrop-blur)",
    "var(--chakra-backdrop-brightness)",
    "var(--chakra-backdrop-contrast)",
    "var(--chakra-backdrop-grayscale)",
    "var(--chakra-backdrop-hue-rotate)",
    "var(--chakra-backdrop-invert)",
    "var(--chakra-backdrop-opacity)",
    "var(--chakra-backdrop-saturate)",
    "var(--chakra-backdrop-sepia)"
  ].join(" "),
  "--chakra-backdrop-blur": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-backdrop-brightness": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-backdrop-contrast": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-backdrop-grayscale": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-backdrop-hue-rotate": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-backdrop-invert": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-backdrop-opacity": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-backdrop-saturate": "var(--chakra-empty,/*!*/ /*!*/)",
  "--chakra-backdrop-sepia": "var(--chakra-empty,/*!*/ /*!*/)"
};
function lr(r) {
  return {
    "--chakra-ring-offset-shadow": "var(--chakra-ring-inset) 0 0 0 var(--chakra-ring-offset-width) var(--chakra-ring-offset-color)",
    "--chakra-ring-shadow": "var(--chakra-ring-inset) 0 0 0 calc(var(--chakra-ring-width) + var(--chakra-ring-offset-width)) var(--chakra-ring-color)",
    "--chakra-ring-width": r,
    boxShadow: [
      "var(--chakra-ring-offset-shadow)",
      "var(--chakra-ring-shadow)",
      "var(--chakra-shadow, 0 0 #0000)"
    ].join(", ")
  };
}
var cr = {
  "row-reverse": {
    space: "--chakra-space-x-reverse",
    divide: "--chakra-divide-x-reverse"
  },
  "column-reverse": {
    space: "--chakra-space-y-reverse",
    divide: "--chakra-divide-y-reverse"
  }
}, C = {
  "to-t": "to top",
  "to-tr": "to top right",
  "to-r": "to right",
  "to-br": "to bottom right",
  "to-b": "to bottom",
  "to-bl": "to bottom left",
  "to-l": "to left",
  "to-tl": "to top left"
}, pr = new Set(Object.values(C)), W = /* @__PURE__ */ new Set([
  "none",
  "-moz-initial",
  "inherit",
  "initial",
  "revert",
  "unset"
]), ur = (r) => r.trim();
function gr(r, t) {
  if (r == null || W.has(r))
    return r;
  if (!(z(r) || W.has(r)))
    return `url('${r}')`;
  const d = /(^[a-z-A-Z]+)\((.*)\)/g.exec(r), l = d == null ? void 0 : d[1], p = d == null ? void 0 : d[2];
  if (!l || !p)
    return r;
  const u = l.includes("-gradient") ? l : `${l}-gradient`, [m, ...k] = p.split(",").map(ur).filter(Boolean);
  if ((k == null ? void 0 : k.length) === 0)
    return r;
  const K = m in C ? C[m] : m;
  k.unshift(K);
  const Q = k.map((S) => {
    if (pr.has(S))
      return S;
    const $ = S.indexOf(" "), [O, T] = $ !== -1 ? [S.substr(0, $), S.substr($ + 1)] : [S], B = z(T) ? T : T && T.split(" "), F = `colors.${O}`, D = F in t.__cssMap ? t.__cssMap[F].varRef : O;
    return B ? [
      D,
      ...Array.isArray(B) ? B : [B]
    ].join(" ") : D;
  });
  return `${u}(${Q.join(", ")})`;
}
var z = (r) => typeof r == "string" && r.includes("(") && r.includes(")"), br = (r, t) => gr(r, t ?? {});
function hr(r) {
  return /^var\(--.+\)$/.test(r);
}
var mr = (r) => {
  const t = parseFloat(r.toString()), a = r.toString().replace(String(t), "");
  return { unitless: !a, value: t, unit: a };
}, h = (r) => (t) => `${r}(${t})`, i = {
  filter(r) {
    return r !== "auto" ? r : dr;
  },
  backdropFilter(r) {
    return r !== "auto" ? r : sr;
  },
  ring(r) {
    return lr(i.px(r));
  },
  bgClip(r) {
    return r === "text" ? { color: "transparent", backgroundClip: "text" } : { backgroundClip: r };
  },
  transform(r) {
    return r === "auto" ? ir() : r === "auto-gpu" ? nr() : r;
  },
  vh(r) {
    return r === "$100vh" ? "var(--chakra-vh)" : r;
  },
  px(r) {
    if (r == null)
      return r;
    const { unitless: t } = mr(r);
    return t || typeof r == "number" ? `${r}px` : r;
  },
  fraction(r) {
    return typeof r != "number" || r > 1 ? r : `${r * 100}%`;
  },
  float(r, t) {
    const a = { left: "right", right: "left" };
    return t.direction === "rtl" ? a[r] : r;
  },
  degree(r) {
    if (hr(r) || r == null)
      return r;
    const t = typeof r == "string" && !r.endsWith("deg");
    return typeof r == "number" || t ? `${r}deg` : r;
  },
  gradient: br,
  blur: h("blur"),
  opacity: h("opacity"),
  brightness: h("brightness"),
  contrast: h("contrast"),
  dropShadow: h("drop-shadow"),
  grayscale: h("grayscale"),
  hueRotate: h("hue-rotate"),
  invert: h("invert"),
  saturate: h("saturate"),
  sepia: h("sepia"),
  bgImage(r) {
    return r == null || z(r) || W.has(r) ? r : `url(${r})`;
  },
  outline(r) {
    const t = String(r) === "0" || String(r) === "none";
    return r !== null && t ? { outline: "2px solid transparent", outlineOffset: "2px" } : { outline: r };
  },
  flexDirection(r) {
    var t;
    const { space: a, divide: o } = (t = cr[r]) != null ? t : {}, d = { flexDirection: r };
    return a && (d[a] = 1), o && (d[o] = 1), d;
  }
}, e = {
  borderWidths: g("borderWidths"),
  borderStyles: g("borderStyles"),
  colors: g("colors"),
  borders: g("borders"),
  gradients: g("gradients", i.gradient),
  radii: g("radii", i.px),
  space: g("space", x(i.vh, i.px)),
  spaceT: g("space", x(i.vh, i.px)),
  degreeT(r) {
    return { property: r, transform: i.degree };
  },
  prop(r, t, a) {
    return {
      property: r,
      scale: t,
      ...t && {
        transform: M({ scale: t, transform: a })
      }
    };
  },
  propT(r, t) {
    return { property: r, transform: t };
  },
  sizes: g("sizes", x(i.vh, i.px)),
  sizesT: g("sizes", x(i.vh, i.fraction)),
  shadows: g("shadows"),
  logical: or,
  blur: g("blur", i.blur)
}, w = {
  background: e.colors("background"),
  backgroundColor: e.colors("backgroundColor"),
  backgroundImage: e.gradients("backgroundImage"),
  backgroundSize: !0,
  backgroundPosition: !0,
  backgroundRepeat: !0,
  backgroundAttachment: !0,
  backgroundClip: { transform: i.bgClip },
  bgSize: e.prop("backgroundSize"),
  bgPosition: e.prop("backgroundPosition"),
  bg: e.colors("background"),
  bgColor: e.colors("backgroundColor"),
  bgPos: e.prop("backgroundPosition"),
  bgRepeat: e.prop("backgroundRepeat"),
  bgAttachment: e.prop("backgroundAttachment"),
  bgGradient: e.gradients("backgroundImage"),
  bgClip: { transform: i.bgClip }
};
Object.assign(w, {
  bgImage: w.backgroundImage,
  bgImg: w.backgroundImage
});
var n = {
  border: e.borders("border"),
  borderWidth: e.borderWidths("borderWidth"),
  borderStyle: e.borderStyles("borderStyle"),
  borderColor: e.colors("borderColor"),
  borderRadius: e.radii("borderRadius"),
  borderTop: e.borders("borderTop"),
  borderBlockStart: e.borders("borderBlockStart"),
  borderTopLeftRadius: e.radii("borderTopLeftRadius"),
  borderStartStartRadius: e.logical({
    scale: "radii",
    property: {
      ltr: "borderTopLeftRadius",
      rtl: "borderTopRightRadius"
    }
  }),
  borderEndStartRadius: e.logical({
    scale: "radii",
    property: {
      ltr: "borderBottomLeftRadius",
      rtl: "borderBottomRightRadius"
    }
  }),
  borderTopRightRadius: e.radii("borderTopRightRadius"),
  borderStartEndRadius: e.logical({
    scale: "radii",
    property: {
      ltr: "borderTopRightRadius",
      rtl: "borderTopLeftRadius"
    }
  }),
  borderEndEndRadius: e.logical({
    scale: "radii",
    property: {
      ltr: "borderBottomRightRadius",
      rtl: "borderBottomLeftRadius"
    }
  }),
  borderRight: e.borders("borderRight"),
  borderInlineEnd: e.borders("borderInlineEnd"),
  borderBottom: e.borders("borderBottom"),
  borderBlockEnd: e.borders("borderBlockEnd"),
  borderBottomLeftRadius: e.radii("borderBottomLeftRadius"),
  borderBottomRightRadius: e.radii("borderBottomRightRadius"),
  borderLeft: e.borders("borderLeft"),
  borderInlineStart: {
    property: "borderInlineStart",
    scale: "borders"
  },
  borderInlineStartRadius: e.logical({
    scale: "radii",
    property: {
      ltr: ["borderTopLeftRadius", "borderBottomLeftRadius"],
      rtl: ["borderTopRightRadius", "borderBottomRightRadius"]
    }
  }),
  borderInlineEndRadius: e.logical({
    scale: "radii",
    property: {
      ltr: ["borderTopRightRadius", "borderBottomRightRadius"],
      rtl: ["borderTopLeftRadius", "borderBottomLeftRadius"]
    }
  }),
  borderX: e.borders(["borderLeft", "borderRight"]),
  borderInline: e.borders("borderInline"),
  borderY: e.borders(["borderTop", "borderBottom"]),
  borderBlock: e.borders("borderBlock"),
  borderTopWidth: e.borderWidths("borderTopWidth"),
  borderBlockStartWidth: e.borderWidths("borderBlockStartWidth"),
  borderTopColor: e.colors("borderTopColor"),
  borderBlockStartColor: e.colors("borderBlockStartColor"),
  borderTopStyle: e.borderStyles("borderTopStyle"),
  borderBlockStartStyle: e.borderStyles("borderBlockStartStyle"),
  borderBottomWidth: e.borderWidths("borderBottomWidth"),
  borderBlockEndWidth: e.borderWidths("borderBlockEndWidth"),
  borderBottomColor: e.colors("borderBottomColor"),
  borderBlockEndColor: e.colors("borderBlockEndColor"),
  borderBottomStyle: e.borderStyles("borderBottomStyle"),
  borderBlockEndStyle: e.borderStyles("borderBlockEndStyle"),
  borderLeftWidth: e.borderWidths("borderLeftWidth"),
  borderInlineStartWidth: e.borderWidths("borderInlineStartWidth"),
  borderLeftColor: e.colors("borderLeftColor"),
  borderInlineStartColor: e.colors("borderInlineStartColor"),
  borderLeftStyle: e.borderStyles("borderLeftStyle"),
  borderInlineStartStyle: e.borderStyles("borderInlineStartStyle"),
  borderRightWidth: e.borderWidths("borderRightWidth"),
  borderInlineEndWidth: e.borderWidths("borderInlineEndWidth"),
  borderRightColor: e.colors("borderRightColor"),
  borderInlineEndColor: e.colors("borderInlineEndColor"),
  borderRightStyle: e.borderStyles("borderRightStyle"),
  borderInlineEndStyle: e.borderStyles("borderInlineEndStyle"),
  borderTopRadius: e.radii(["borderTopLeftRadius", "borderTopRightRadius"]),
  borderBottomRadius: e.radii([
    "borderBottomLeftRadius",
    "borderBottomRightRadius"
  ]),
  borderLeftRadius: e.radii(["borderTopLeftRadius", "borderBottomLeftRadius"]),
  borderRightRadius: e.radii([
    "borderTopRightRadius",
    "borderBottomRightRadius"
  ])
};
Object.assign(n, {
  rounded: n.borderRadius,
  roundedTop: n.borderTopRadius,
  roundedTopLeft: n.borderTopLeftRadius,
  roundedTopRight: n.borderTopRightRadius,
  roundedTopStart: n.borderStartStartRadius,
  roundedTopEnd: n.borderStartEndRadius,
  roundedBottom: n.borderBottomRadius,
  roundedBottomLeft: n.borderBottomLeftRadius,
  roundedBottomRight: n.borderBottomRightRadius,
  roundedBottomStart: n.borderEndStartRadius,
  roundedBottomEnd: n.borderEndEndRadius,
  roundedLeft: n.borderLeftRadius,
  roundedRight: n.borderRightRadius,
  roundedStart: n.borderInlineStartRadius,
  roundedEnd: n.borderInlineEndRadius,
  borderStart: n.borderInlineStart,
  borderEnd: n.borderInlineEnd,
  borderTopStartRadius: n.borderStartStartRadius,
  borderTopEndRadius: n.borderStartEndRadius,
  borderBottomStartRadius: n.borderEndStartRadius,
  borderBottomEndRadius: n.borderEndEndRadius,
  borderStartRadius: n.borderInlineStartRadius,
  borderEndRadius: n.borderInlineEndRadius,
  borderStartWidth: n.borderInlineStartWidth,
  borderEndWidth: n.borderInlineEndWidth,
  borderStartColor: n.borderInlineStartColor,
  borderEndColor: n.borderInlineEndColor,
  borderStartStyle: n.borderInlineStartStyle,
  borderEndStyle: n.borderInlineEndStyle
});
var fr = {
  color: e.colors("color"),
  textColor: e.colors("color"),
  fill: e.colors("fill"),
  stroke: e.colors("stroke")
}, L = {
  boxShadow: e.shadows("boxShadow"),
  mixBlendMode: !0,
  blendMode: e.prop("mixBlendMode"),
  backgroundBlendMode: !0,
  bgBlendMode: e.prop("backgroundBlendMode"),
  opacity: !0
};
Object.assign(L, {
  shadow: L.boxShadow
});
var kr = {
  filter: { transform: i.filter },
  blur: e.blur("--chakra-blur"),
  brightness: e.propT("--chakra-brightness", i.brightness),
  contrast: e.propT("--chakra-contrast", i.contrast),
  hueRotate: e.degreeT("--chakra-hue-rotate"),
  invert: e.propT("--chakra-invert", i.invert),
  saturate: e.propT("--chakra-saturate", i.saturate),
  dropShadow: e.propT("--chakra-drop-shadow", i.dropShadow),
  backdropFilter: { transform: i.backdropFilter },
  backdropBlur: e.blur("--chakra-backdrop-blur"),
  backdropBrightness: e.propT(
    "--chakra-backdrop-brightness",
    i.brightness
  ),
  backdropContrast: e.propT("--chakra-backdrop-contrast", i.contrast),
  backdropHueRotate: e.degreeT("--chakra-backdrop-hue-rotate"),
  backdropInvert: e.propT("--chakra-backdrop-invert", i.invert),
  backdropSaturate: e.propT("--chakra-backdrop-saturate", i.saturate)
}, I = {
  alignItems: !0,
  alignContent: !0,
  justifyItems: !0,
  justifyContent: !0,
  flexWrap: !0,
  flexDirection: { transform: i.flexDirection },
  flex: !0,
  flexFlow: !0,
  flexGrow: !0,
  flexShrink: !0,
  flexBasis: e.sizes("flexBasis"),
  justifySelf: !0,
  alignSelf: !0,
  order: !0,
  placeItems: !0,
  placeContent: !0,
  placeSelf: !0,
  gap: e.space("gap"),
  rowGap: e.space("rowGap"),
  columnGap: e.space("columnGap")
};
Object.assign(I, {
  flexDir: I.flexDirection
});
var N = {
  gridGap: e.space("gridGap"),
  gridColumnGap: e.space("gridColumnGap"),
  gridRowGap: e.space("gridRowGap"),
  gridColumn: !0,
  gridRow: !0,
  gridAutoFlow: !0,
  gridAutoColumns: !0,
  gridColumnStart: !0,
  gridColumnEnd: !0,
  gridRowStart: !0,
  gridRowEnd: !0,
  gridAutoRows: !0,
  gridTemplate: !0,
  gridTemplateColumns: !0,
  gridTemplateRows: !0,
  gridTemplateAreas: !0,
  gridArea: !0
}, vr = {
  appearance: !0,
  cursor: !0,
  resize: !0,
  userSelect: !0,
  pointerEvents: !0,
  outline: { transform: i.outline },
  outlineOffset: !0,
  outlineColor: e.colors("outlineColor")
}, b = {
  width: e.sizesT("width"),
  inlineSize: e.sizesT("inlineSize"),
  height: e.sizes("height"),
  blockSize: e.sizes("blockSize"),
  boxSize: e.sizes(["width", "height"]),
  minWidth: e.sizes("minWidth"),
  minInlineSize: e.sizes("minInlineSize"),
  minHeight: e.sizes("minHeight"),
  minBlockSize: e.sizes("minBlockSize"),
  maxWidth: e.sizes("maxWidth"),
  maxInlineSize: e.sizes("maxInlineSize"),
  maxHeight: e.sizes("maxHeight"),
  maxBlockSize: e.sizes("maxBlockSize"),
  overflow: !0,
  overflowX: !0,
  overflowY: !0,
  overscrollBehavior: !0,
  overscrollBehaviorX: !0,
  overscrollBehaviorY: !0,
  display: !0,
  aspectRatio: !0,
  hideFrom: {
    scale: "breakpoints",
    transform: (r, t) => {
      var a, o, d;
      return { [`@media screen and (min-width: ${(d = (o = (a = t.__breakpoints) == null ? void 0 : a.get(r)) == null ? void 0 : o.minW) != null ? d : r})`]: { display: "none" } };
    }
  },
  hideBelow: {
    scale: "breakpoints",
    transform: (r, t) => {
      var a, o, d;
      return { [`@media screen and (max-width: ${(d = (o = (a = t.__breakpoints) == null ? void 0 : a.get(r)) == null ? void 0 : o._minW) != null ? d : r})`]: { display: "none" } };
    }
  },
  verticalAlign: !0,
  boxSizing: !0,
  boxDecorationBreak: !0,
  float: e.propT("float", i.float),
  objectFit: !0,
  objectPosition: !0,
  visibility: !0,
  isolation: !0
};
Object.assign(b, {
  w: b.width,
  h: b.height,
  minW: b.minWidth,
  maxW: b.maxWidth,
  minH: b.minHeight,
  maxH: b.maxHeight,
  overscroll: b.overscrollBehavior,
  overscrollX: b.overscrollBehaviorX,
  overscrollY: b.overscrollBehaviorY
});
var Sr = {
  listStyleType: !0,
  listStylePosition: !0,
  listStylePos: e.prop("listStylePosition"),
  listStyleImage: !0,
  listStyleImg: e.prop("listStyleImage")
};
function yr(r, t, a, o) {
  const d = typeof t == "string" ? t.split(".") : [t];
  for (o = 0; o < d.length && r; o += 1)
    r = r[d[o]];
  return r === void 0 ? a : r;
}
var Rr = (r) => {
  const t = /* @__PURE__ */ new WeakMap();
  return (o, d, l, p) => {
    if (typeof o > "u")
      return r(o, d, l);
    t.has(o) || t.set(o, /* @__PURE__ */ new Map());
    const u = t.get(o);
    if (u.has(d))
      return u.get(d);
    const m = r(o, d, l, p);
    return u.set(d, m), m;
  };
}, Tr = Rr(yr), Br = {
  border: "0px",
  clip: "rect(0, 0, 0, 0)",
  width: "1px",
  height: "1px",
  margin: "-1px",
  padding: "0px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  position: "absolute"
}, xr = {
  position: "static",
  width: "auto",
  height: "auto",
  clip: "auto",
  padding: "0",
  margin: "0",
  overflow: "visible",
  whiteSpace: "normal"
}, E = (r, t, a) => {
  const o = {}, d = Tr(r, t, {});
  for (const l in d)
    l in a && a[l] != null || (o[l] = d[l]);
  return o;
}, wr = {
  srOnly: {
    transform(r) {
      return r === !0 ? Br : r === "focusable" ? xr : {};
    }
  },
  layerStyle: {
    processResult: !0,
    transform: (r, t, a) => E(t, `layerStyles.${r}`, a)
  },
  textStyle: {
    processResult: !0,
    transform: (r, t, a) => E(t, `textStyles.${r}`, a)
  },
  apply: {
    processResult: !0,
    transform: (r, t, a) => E(t, r, a)
  }
}, R = {
  position: !0,
  pos: e.prop("position"),
  zIndex: e.prop("zIndex", "zIndices"),
  inset: e.spaceT("inset"),
  insetX: e.spaceT(["left", "right"]),
  insetInline: e.spaceT("insetInline"),
  insetY: e.spaceT(["top", "bottom"]),
  insetBlock: e.spaceT("insetBlock"),
  top: e.spaceT("top"),
  insetBlockStart: e.spaceT("insetBlockStart"),
  bottom: e.spaceT("bottom"),
  insetBlockEnd: e.spaceT("insetBlockEnd"),
  left: e.spaceT("left"),
  insetInlineStart: e.logical({
    scale: "space",
    property: { ltr: "left", rtl: "right" }
  }),
  right: e.spaceT("right"),
  insetInlineEnd: e.logical({
    scale: "space",
    property: { ltr: "right", rtl: "left" }
  })
};
Object.assign(R, {
  insetStart: R.insetInlineStart,
  insetEnd: R.insetInlineEnd
});
var Ir = {
  ring: { transform: i.ring },
  ringColor: e.colors("--chakra-ring-color"),
  ringOffset: e.prop("--chakra-ring-offset-width"),
  ringOffsetColor: e.colors("--chakra-ring-offset-color"),
  ringInset: e.prop("--chakra-ring-inset")
}, s = {
  margin: e.spaceT("margin"),
  marginTop: e.spaceT("marginTop"),
  marginBlockStart: e.spaceT("marginBlockStart"),
  marginRight: e.spaceT("marginRight"),
  marginInlineEnd: e.spaceT("marginInlineEnd"),
  marginBottom: e.spaceT("marginBottom"),
  marginBlockEnd: e.spaceT("marginBlockEnd"),
  marginLeft: e.spaceT("marginLeft"),
  marginInlineStart: e.spaceT("marginInlineStart"),
  marginX: e.spaceT(["marginInlineStart", "marginInlineEnd"]),
  marginInline: e.spaceT("marginInline"),
  marginY: e.spaceT(["marginTop", "marginBottom"]),
  marginBlock: e.spaceT("marginBlock"),
  padding: e.space("padding"),
  paddingTop: e.space("paddingTop"),
  paddingBlockStart: e.space("paddingBlockStart"),
  paddingRight: e.space("paddingRight"),
  paddingBottom: e.space("paddingBottom"),
  paddingBlockEnd: e.space("paddingBlockEnd"),
  paddingLeft: e.space("paddingLeft"),
  paddingInlineStart: e.space("paddingInlineStart"),
  paddingInlineEnd: e.space("paddingInlineEnd"),
  paddingX: e.space(["paddingInlineStart", "paddingInlineEnd"]),
  paddingInline: e.space("paddingInline"),
  paddingY: e.space(["paddingTop", "paddingBottom"]),
  paddingBlock: e.space("paddingBlock")
};
Object.assign(s, {
  m: s.margin,
  mt: s.marginTop,
  mr: s.marginRight,
  me: s.marginInlineEnd,
  marginEnd: s.marginInlineEnd,
  mb: s.marginBottom,
  ml: s.marginLeft,
  ms: s.marginInlineStart,
  marginStart: s.marginInlineStart,
  mx: s.marginX,
  my: s.marginY,
  p: s.padding,
  pt: s.paddingTop,
  py: s.paddingY,
  px: s.paddingX,
  pb: s.paddingBottom,
  pl: s.paddingLeft,
  ps: s.paddingInlineStart,
  paddingStart: s.paddingInlineStart,
  pr: s.paddingRight,
  pe: s.paddingInlineEnd,
  paddingEnd: s.paddingInlineEnd
});
var _r = {
  textDecorationColor: e.colors("textDecorationColor"),
  textDecoration: !0,
  textDecor: { property: "textDecoration" },
  textDecorationLine: !0,
  textDecorationStyle: !0,
  textDecorationThickness: !0,
  textUnderlineOffset: !0,
  textShadow: e.shadows("textShadow")
}, $r = {
  clipPath: !0,
  transform: e.propT("transform", i.transform),
  transformOrigin: !0,
  translateX: e.spaceT("--chakra-translate-x"),
  translateY: e.spaceT("--chakra-translate-y"),
  skewX: e.degreeT("--chakra-skew-x"),
  skewY: e.degreeT("--chakra-skew-y"),
  scaleX: e.prop("--chakra-scale-x"),
  scaleY: e.prop("--chakra-scale-y"),
  scale: e.prop(["--chakra-scale-x", "--chakra-scale-y"]),
  rotate: e.degreeT("--chakra-rotate")
}, Er = {
  transition: !0,
  transitionDelay: !0,
  animation: !0,
  willChange: !0,
  transitionDuration: e.prop("transitionDuration", "transition.duration"),
  transitionProperty: e.prop("transitionProperty", "transition.property"),
  transitionTimingFunction: e.prop(
    "transitionTimingFunction",
    "transition.easing"
  )
}, Cr = {
  fontFamily: e.prop("fontFamily", "fonts"),
  fontSize: e.prop("fontSize", "fontSizes", i.px),
  fontWeight: e.prop("fontWeight", "fontWeights"),
  lineHeight: e.prop("lineHeight", "lineHeights"),
  letterSpacing: e.prop("letterSpacing", "letterSpacings"),
  textAlign: !0,
  fontStyle: !0,
  textIndent: !0,
  wordBreak: !0,
  overflowWrap: !0,
  textOverflow: !0,
  textTransform: !0,
  whiteSpace: !0,
  isTruncated: {
    transform(r) {
      if (r === !0)
        return {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        };
    }
  },
  noOfLines: {
    static: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "var(--chakra-line-clamp)"
    },
    property: "--chakra-line-clamp"
  }
}, Wr = {
  scrollBehavior: !0,
  scrollSnapAlign: !0,
  scrollSnapStop: !0,
  scrollSnapType: !0,
  scrollMargin: e.spaceT("scrollMargin"),
  scrollMarginTop: e.spaceT("scrollMarginTop"),
  scrollMarginBottom: e.spaceT("scrollMarginBottom"),
  scrollMarginLeft: e.spaceT("scrollMarginLeft"),
  scrollMarginRight: e.spaceT("scrollMarginRight"),
  scrollMarginX: e.spaceT(["scrollMarginLeft", "scrollMarginRight"]),
  scrollMarginY: e.spaceT(["scrollMarginTop", "scrollMarginBottom"]),
  scrollPadding: e.spaceT("scrollPadding"),
  scrollPaddingTop: e.spaceT("scrollPaddingTop"),
  scrollPaddingBottom: e.spaceT("scrollPaddingBottom"),
  scrollPaddingLeft: e.spaceT("scrollPaddingLeft"),
  scrollPaddingRight: e.spaceT("scrollPaddingRight"),
  scrollPaddingX: e.spaceT(["scrollPaddingLeft", "scrollPaddingRight"]),
  scrollPaddingY: e.spaceT(["scrollPaddingTop", "scrollPaddingBottom"])
};
function q(r) {
  return H(r) && r.reference ? r.reference : String(r);
}
var _ = (r, ...t) => t.map(q).join(` ${r} `).replace(/calc/g, ""), X = (...r) => `calc(${_("+", ...r)})`, Y = (...r) => `calc(${_("-", ...r)})`, P = (...r) => `calc(${_("*", ...r)})`, A = (...r) => `calc(${_("/", ...r)})`, G = (r) => {
  const t = q(r);
  return t != null && !Number.isNaN(parseFloat(t)) ? String(t).startsWith("-") ? String(t).slice(1) : `-${t}` : P(t, -1);
}, y = Object.assign(
  (r) => ({
    add: (...t) => y(X(r, ...t)),
    subtract: (...t) => y(Y(r, ...t)),
    multiply: (...t) => y(P(r, ...t)),
    divide: (...t) => y(A(r, ...t)),
    negate: () => y(G(r)),
    toString: () => r.toString()
  }),
  {
    add: X,
    subtract: Y,
    multiply: P,
    divide: A,
    negate: G
  }
);
function zr(r, t = "-") {
  return r.replace(/\s+/g, t);
}
function Lr(r) {
  const t = zr(r.toString());
  return Mr(Pr(t));
}
function Pr(r) {
  return r.includes("\\.") ? r : !Number.isInteger(parseFloat(r.toString())) ? r.replace(".", "\\.") : r;
}
function Mr(r) {
  return r.replace(/[!-,/:-@[-^`{-~]/g, "\\$&");
}
function Or(r, t = "") {
  return [t, r].filter(Boolean).join("-");
}
function Fr(r, t) {
  return `var(${r})`;
}
function Dr(r, t = "") {
  return Lr(`--${Or(r, t)}`);
}
function Ar(r, t, a) {
  const o = Dr(r, a);
  return {
    variable: o,
    reference: Fr(o)
  };
}
var c = {
  hover: (r, t) => `${r}:hover ${t}, ${r}[data-hover] ${t}`,
  focus: (r, t) => `${r}:focus ${t}, ${r}[data-focus] ${t}`,
  focusVisible: (r, t) => `${r}:focus-visible ${t}`,
  focusWithin: (r, t) => `${r}:focus-within ${t}`,
  active: (r, t) => `${r}:active ${t}, ${r}[data-active] ${t}`,
  disabled: (r, t) => `${r}:disabled ${t}, ${r}[data-disabled] ${t}`,
  invalid: (r, t) => `${r}:invalid ${t}, ${r}[data-invalid] ${t}`,
  checked: (r, t) => `${r}:checked ${t}, ${r}[data-checked] ${t}`,
  indeterminate: (r, t) => `${r}:indeterminate ${t}, ${r}[aria-checked=mixed] ${t}, ${r}[data-indeterminate] ${t}`,
  readOnly: (r, t) => `${r}:read-only ${t}, ${r}[readonly] ${t}, ${r}[data-read-only] ${t}`,
  expanded: (r, t) => `${r}:read-only ${t}, ${r}[aria-expanded=true] ${t}, ${r}[data-expanded] ${t}`,
  placeholderShown: (r, t) => `${r}:placeholder-shown ${t}`
}, v = (r) => Z((t) => r(t, "&"), "[role=group]", "[data-group]", ".group"), f = (r) => Z((t) => r(t, "~ &"), "[data-peer]", ".peer"), Z = (r, ...t) => t.map(r).join(", "), U = {
  _hover: "&:hover, &[data-hover]",
  _active: "&:active, &[data-active]",
  _focus: "&:focus, &[data-focus]",
  _highlighted: "&[data-highlighted]",
  _focusWithin: "&:focus-within",
  _focusVisible: "&:focus-visible, &[data-focus-visible]",
  _disabled: "&:disabled, &[disabled], &[aria-disabled=true], &[data-disabled]",
  _readOnly: "&[aria-readonly=true], &[readonly], &[data-readonly]",
  _before: "&::before",
  _after: "&::after",
  _empty: "&:empty",
  _expanded: "&[aria-expanded=true], &[data-expanded]",
  _checked: "&[aria-checked=true], &[data-checked]",
  _grabbed: "&[aria-grabbed=true], &[data-grabbed]",
  _pressed: "&[aria-pressed=true], &[data-pressed]",
  _invalid: "&[aria-invalid=true], &[data-invalid]",
  _valid: "&[data-valid], &[data-state=valid]",
  _loading: "&[data-loading], &[aria-busy=true]",
  _selected: "&[aria-selected=true], &[data-selected]",
  _hidden: "&[hidden], &[data-hidden]",
  _autofill: "&:-webkit-autofill",
  _even: "&:nth-of-type(even)",
  _odd: "&:nth-of-type(odd)",
  _first: "&:first-of-type",
  _firstLetter: "&::first-letter",
  _last: "&:last-of-type",
  _notFirst: "&:not(:first-of-type)",
  _notLast: "&:not(:last-of-type)",
  _visited: "&:visited",
  _activeLink: "&[aria-current=page]",
  _activeStep: "&[aria-current=step]",
  _indeterminate: "&:indeterminate, &[aria-checked=mixed], &[data-indeterminate]",
  _groupHover: v(c.hover),
  _peerHover: f(c.hover),
  _groupFocus: v(c.focus),
  _peerFocus: f(c.focus),
  _groupFocusVisible: v(c.focusVisible),
  _peerFocusVisible: f(c.focusVisible),
  _groupActive: v(c.active),
  _peerActive: f(c.active),
  _groupDisabled: v(c.disabled),
  _peerDisabled: f(c.disabled),
  _groupInvalid: v(c.invalid),
  _peerInvalid: f(c.invalid),
  _groupChecked: v(c.checked),
  _peerChecked: f(c.checked),
  _groupFocusWithin: v(c.focusWithin),
  _peerFocusWithin: f(c.focusWithin),
  _peerPlaceholderShown: f(c.placeholderShown),
  _placeholder: "&::placeholder",
  _placeholderShown: "&:placeholder-shown",
  _fullScreen: "&:fullscreen",
  _selection: "&::selection",
  _rtl: "[dir=rtl] &, &[dir=rtl]",
  _ltr: "[dir=ltr] &, &[dir=ltr]",
  _mediaDark: "@media (prefers-color-scheme: dark)",
  _mediaReduceMotion: "@media (prefers-reduced-motion: reduce)",
  _dark: ".chakra-ui-dark &:not([data-theme]),[data-theme=dark] &:not([data-theme]),&[data-theme=dark]",
  _light: ".chakra-ui-light &:not([data-theme]),[data-theme=light] &:not([data-theme]),&[data-theme=light]",
  _horizontal: "&[data-orientation=horizontal]",
  _vertical: "&[data-orientation=vertical]"
}, jr = Object.keys(
  U
), J = rr(
  {},
  w,
  n,
  fr,
  I,
  b,
  kr,
  Ir,
  vr,
  N,
  wr,
  R,
  L,
  s,
  Wr,
  Cr,
  _r,
  $r,
  Sr,
  Er
);
Object.assign({}, s, b, I, N, R);
[...Object.keys(J), ...jr];
({ ...J, ...U });
function Gr(r) {
  return r;
}
function Hr(r) {
  return r;
}
function Vr(r) {
  return {
    definePartsStyle(t) {
      return t;
    },
    defineMultiStyleConfig(t) {
      return { parts: r, ...t };
    }
  };
}
export {
  Or as addPrefix,
  w as background,
  n as border,
  y as calc,
  fr as color,
  Vr as createMultiStyleConfigHelpers,
  Ar as cssVar,
  Gr as defineStyle,
  Hr as defineStyleConfig,
  L as effect,
  kr as filter,
  I as flexbox,
  N as grid,
  vr as interactivity,
  b as layout,
  Sr as list,
  wr as others,
  R as position,
  jr as pseudoPropNames,
  U as pseudoSelectors,
  Ir as ring,
  Wr as scroll,
  s as space,
  J as systemProps,
  _r as textDecoration,
  Dr as toVarDefinition,
  Fr as toVarReference,
  tr as tokenToCSSVar,
  $r as transform,
  Er as transition,
  Cr as typography
};
