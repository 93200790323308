import { Text } from '@chakra-ui/react';

import { PromptModal } from '~/components/ui/modals';

interface UnblockUserModal {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  isLoading: boolean;
}

export function UnblockUserModal({
  isOpen,
  onClose,
  onClick,
  isLoading
}: Readonly<UnblockUserModal>) {
  return (
    <PromptModal
      isOpen={isOpen}
      onClose={onClose}
      type="confirmation"
      heading="Unblock User"
      actionButton={{
        text: 'Unblock',
        isLoading: isLoading,
        onClick: onClick,
        'aria-label': 'Unblock User'
      }}
      cancelButton={{
        text: 'Cancel',
        onClick: onClose
      }}
    >
      <Text
        color="darkGrey.400"
        fontWeight="medium"
        fontSize="sm"
        lineHeight="lg"
        pt={6}
      >
        They will now be able to follow you, and view your activity and profile.
        They won&apos;t be notified that you have unblocked them.
      </Text>
    </PromptModal>
  );
}
