import { type ComponentStyleConfig } from '@chakra-ui/react';

export const Select: ComponentStyleConfig = {
  baseStyle: (props) => ({
    field: {
      height: '3rem',
      width: '10rem'
    },

    icon: {
      color: 'darkGrey.50'
    }
  })
};
