import * as React from 'react';
import { FormProvider, FieldValues } from 'react-hook-form';
import { useDeepCompareDebounce } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';
import { useTrackVisibility } from 'react-intersection-observer-hook';
import { runIfFn } from '@chakra-ui/utils';

import { TrackedFormProps } from './TrackedForm';
import { useTrackedForm } from '../../hooks/useTrackedForm';

interface TrackedRTFormProps<TFieldValues extends FieldValues, TContext = any>
  extends TrackedFormProps<TFieldValues, TContext> {
  /**
   * How long to wait before calling onUpdate
   *
   * @default 300
   */
  debounceMS?: number;
}

/**
 * A "real time" form that triggers onChange with debounce instead of waiting
 * for the form to be submitted and is used in conjunction with search or filtering
 *
 * React hook form is not really suitable for search type forms where you want to debounce the changes and pass to
 * remote query, this component is here to try and alleviate those issues
 */
export function TrackedRTForm<T extends FieldValues>({
  children,
  name,
  formHookProps,
  onSubmit,
  focus,
  debounceMS = 300
}: TrackedRTFormProps<T>) {
  const form = useTrackedForm(name, formHookProps);

  const [ref, { isVisible }] = useTrackVisibility();

  const wrappedOnSubmit = React.useCallback(
    async (values: T) => {
      await onSubmit(values, form);
    },
    [onSubmit, form]
  );

  React.useEffect(() => {
    if (focus) {
      form.setFocus(focus);
    }
  }, [focus, form, form.setFocus]);

  React.useEffect(() => {
    if (isVisible) {
      segment.formViewed({
        formName: name,
        formLocation: window.location.pathname
      });
    }
  }, [name, isVisible]);

  const values = form.watch();

  useDeepCompareDebounce(
    () => {
      onSubmit(values, form);
    },
    debounceMS,
    [values]
  );

  // Use a larger debounce value for the "formSubmitted" event
  useDeepCompareDebounce(
    () => {
      segment.formSubmitted({
        formName: name,
        formLocation: window.location.pathname
      });
    },
    debounceMS + 700,
    [values]
  );

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(wrappedOnSubmit)}
        name={name}
        style={{ width: '100%' }}
        ref={ref}
      >
        {runIfFn(children, form)}
      </form>
    </FormProvider>
  );
}
