import { type ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export const Input: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: (props) => ({
    field: {
      _placeholder: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.5rem',
        margin: '0.75rem',

        color: mode('darkGrey.50', 'darkGrey.200')(props)
      },

      color: mode('darkGrey.300', 'lightGrey.400')(props),

      // Stop the input from being resized when the hover is triggered
      border: '1px',
      borderColor: mode('lightGrey.600', 'darkGrey.200')(props),

      fontSize: '1rem',
      fontWeight: 500,
      fontFamily: 'body',

      background: mode('white', 'darkGrey.400')(props),

      _hover: {
        borderColor: mode('darkGrey.200', 'lightGrey.900')(props)
      },

      _focus: {
        borderColor: mode('darkGrey.300', 'lightGrey.600')(props)
      },

      _disabled: {
        backgroundColor: mode('lightGrey.400', 'black')(props),
        borderColor: mode('lightGrey.600', 'darkGrey.700')(props)
      },

      _invalid: {
        borderColor: mode('red.500', 'red.400')(props)
      }
    }
  }),

  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    md: {
      field: {
        height: '3rem'
      }
    }
  },

  // styles for different visual variants ("outline", "solid")
  variants: {},

  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    size: 'md',
    variant: '',
    focusBorderColor: 'tigerOrange.800'
  }
};
