import { Box, Text } from '@chakra-ui/react';

import type { User } from '../../types';

interface UserInfoProps {
  user: User;
}

export function UserInfo({
  user: { firstName, lastName, jobTitle, company }
}: UserInfoProps) {
  return (
    <Box textAlign="center">
      <Text
        color="lightGrey.400"
        fontSize={{ base: '0.625rem', md: '0.875rem' }}
        fontWeight="700"
        noOfLines={1}
        as="h4"
      >
        {`${firstName} ${lastName}`}
      </Text>
      <Text
        noOfLines={1}
        fontSize={{ base: '0.5rem', md: '0.75rem' }}
        color="lightGrey.400"
        as="h5"
      >
        {jobTitle}
      </Text>
      <Text
        noOfLines={1}
        fontSize={{ base: '0.5rem', md: '0.75rem' }}
        color="tigerOrange.600"
        as="span"
      >
        {company}
      </Text>
    </Box>
  );
}
