import * as React from 'react';
import { Button, Icon } from '@chakra-ui/react';
import { Stream } from '@tigerhall/core/lib/types';
import { IconTrash } from '@tigerhall/icons';
import { useDeleteStreamMutation, useFetchStreamQuery } from 'generated';
import { ContentSummaryModal } from 'modules/admin/components';
import { useNavigate, useParams } from 'react-router-dom';

import { broadcastFormRoute } from '../../utils';

export function BroadcastSummary() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [deleteStream] = useDeleteStreamMutation({
    variables: {
      id: id as string
    }
  });

  const { data, loading, error } = useFetchStreamQuery({
    variables: {
      id: id as string
    }
  });

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <ContentSummaryModal
      isOpen
      content={data?.stream as Stream}
      actions={
        <>
          <Button
            flex={1}
            variant="outline"
            leftIcon={<Icon as={IconTrash} />}
            onClick={async () => {
              await deleteStream();
            }}
          >
            Delete
          </Button>
          <Button
            flex={1}
            variant="solid"
            onClick={() => {
              navigate(broadcastFormRoute(id as string));
            }}
          >
            Edit
          </Button>
        </>
      }
    />
  );
}
