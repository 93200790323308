import { useNavigate } from 'react-router-dom';
import { IconMessageCircle } from '@tigerhall/icons';
import * as segment from '@tigerhall/analytics';
import { Icon } from '@chakra-ui/react';

import { ActionButton } from './ActionButton';

interface CommentProps {
  postId: string;
}

export function Comment({ postId }: CommentProps) {
  const navigate = useNavigate();
  const contentLink = `/feed/${postId}`;

  return (
    <ActionButton
      leftIcon={<Icon as={IconMessageCircle} />}
      onClick={() => {
        segment.commentButtonClicked({
          referenceId: postId
        });

        if (window.location.pathname === contentLink) {
          return;
        }

        navigate(contentLink);
      }}
    >
      Comment
    </ActionButton>
  );
}
