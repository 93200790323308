import { Share as DefaultShare, ShareProps } from '@tigerhall/components';
import * as React from 'react';
import { useMicrosoftTeams } from '@tigerhall/core';

export function Share({ ...props }: ShareProps) {
  const { isInTeams } = useMicrosoftTeams();

  if (!isInTeams) {
    return <DefaultShare {...props} />;
  }

  // todo: in the future we should replace this with a Teams specific share component
  return null;
}
