import { Fragment } from 'react';
import { DateTime } from 'luxon';
import { Avatar, Divider, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { resizeImage } from '@tigerhall/core';
import { RichTextMarkdownWrapper } from 'components/ui/MarkdownWrapper/RichTextMarkdownWrapper';

import { type LiveBroadcastAgendaFragment } from './queries/generated';

interface BroadcastAgendaProps {
  agenda: LiveBroadcastAgendaFragment[];
}

export function BroadcastAgenda({ agenda }: Readonly<BroadcastAgendaProps>) {
  return (
    <VStack spacing={4} w={'full'} alignItems={'flex-start'}>
      <Text
        as={'h3'}
        color={'lightGrey.200'}
        fontSize={'xl'}
        fontWeight={'bold'}
        px={4}
        pb={4}
      >
        Agenda
      </Text>
      {agenda.map((item) => (
        <Fragment key={`agenda-${item.topic}`}>
          <Divider />
          <Flex
            w={'full'}
            paddingX={4}
            gap={4}
            borderLeft={'3px solid'}
            borderColor={item.isActive ? 'tigerOrange.600' : 'transparent'}
            opacity={item.isActive ? 1 : 0.7}
          >
            <Text
              as={'span'}
              color={'darkGrey.50'}
              fontSize={'xs'}
              // to make sure the topics list is always aligned
              width={'4.25rem'}
            >
              {DateTime.fromISO(item.estimatedStartTime).toFormat('t ZZZZ')}
            </Text>
            <Flex w={'full'} direction={'column'}>
              <Text
                as={'h4'}
                color={'lightGrey.200'}
                fontWeight={'bold'}
                fontSize={'sm'}
              >
                {item.topic}
              </Text>
              {item.description ? (
                <RichTextMarkdownWrapper
                  fontSize={'xs'}
                  color={'darkGrey.50'}
                  fontWeight={'medium'}
                >
                  {item.description}
                </RichTextMarkdownWrapper>
              ) : null}
              <Flex w={'full'} direction={'column'} pt={2} gap={1.5}>
                {item.speakers?.map((speaker) => (
                  <HStack key={`agenda-speaker-${speaker.id}`}>
                    <Avatar
                      src={resizeImage({
                        url: speaker.avatarImage?.uri || '',
                        width: 48,
                        height: 48
                      })}
                      size={'xs'}
                    />
                    <Text
                      as={'p'}
                      color={'lightGrey.200'}
                      fontSize={'xs'}
                      noOfLines={1}
                    >
                      {`${speaker.firstName} ${speaker.lastName}, ${speaker.jobTitle}, ${speaker.company}`}
                    </Text>
                  </HStack>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Fragment>
      ))}
      <Divider />
    </VStack>
  );
}
