import { useState, useCallback, useEffect, useMemo } from 'react';
import map from 'lodash/map';
import {
  useGetCurrentUserQuery,
  useGetLifeGoalsQuery,
  useUpdateUserLifegoalsMutation
} from 'generated';

type SelectedInterestsType = string[];

export function useInterests({ onComplete }) {
  const { data: userData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first'
  });

  const alreadySelectedLifegoalIds =
    useMemo(
      () => map(userData?.me?.lifegoals, (lifegoal) => lifegoal?.id),
      [userData?.me?.lifegoals]
    ) || [];

  const [selectedIDs, setSelectedIDs] = useState<SelectedInterestsType>([]);
  const { data, loading } = useGetLifeGoalsQuery({
    variables: {
      filter: {
        offset: 0
      }
    }
  });
  const [updateUserLifegoalsMutation, updateResponse] =
    useUpdateUserLifegoalsMutation();

  useEffect(() => {
    if (updateResponse.data?.updateUserLifegoals?.success && onComplete) {
      onComplete();
    }
  }, [onComplete, updateResponse.data?.updateUserLifegoals?.success]);

  const lifeGoalsData = useMemo(
    () =>
      data?.lifegoals?.edges?.map((goal) => ({
        id: goal.id,
        name: goal.name,
        emoji: goal.emoji as string
      })) || [],
    [data]
  );

  const handleClick = useCallback(
    async (selectedInterests: string[]) => {
      await updateUserLifegoalsMutation({
        variables: {
          lifeGoals: selectedInterests || selectedIDs
        }
      });
    },
    [updateUserLifegoalsMutation, selectedIDs]
  );

  return {
    selectedIDs:
      selectedIDs?.length >= 1 ? selectedIDs : alreadySelectedLifegoalIds,
    setSelectedIDs,
    handleClick,
    lifeGoalsData,
    loading,
    isUpdating: updateResponse?.loading
  };
}
