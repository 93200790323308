export type HomeComponentsKeys =
  | 'streams'
  | 'organisationContentBanner'
  | 'topicCloud'
  | 'pickUpWhereYouLeftOff'
  | 'bookmarked'
  | 'new'
  | 'topPicks'
  | 'upcomingExperiences'
  | 'recommendedForYou'
  | 'recentCategory1'
  | 'trending'
  | 'recentCategory2'
  | 'mostPopularThisWeek'
  | 'trailOfTheMonth'
  | 'recentCategory3'
  | 'featuredThinkfluencers';

export const B2B_COMPONENTS_LIST: HomeComponentsKeys[] = [
  'streams',
  'organisationContentBanner',
  'topicCloud',
  'pickUpWhereYouLeftOff',
  'bookmarked',
  'new',
  'topPicks',
  'upcomingExperiences',
  'recommendedForYou',
  'recentCategory1',
  'trending',
  'recentCategory2',
  'mostPopularThisWeek',
  'trailOfTheMonth',
  'recentCategory3',
  'featuredThinkfluencers'
];

export const B2C_COMPONENTS_LIST: HomeComponentsKeys[] = [
  'streams',
  'topPicks',
  'pickUpWhereYouLeftOff',
  'bookmarked',
  'new',
  'upcomingExperiences',
  'recommendedForYou',
  'topicCloud',
  'recentCategory1',
  'trending',
  'recentCategory2',
  'mostPopularThisWeek',
  'trailOfTheMonth',
  'recentCategory3',
  'featuredThinkfluencers'
];
