import {
  Box,
  Fade,
  type FadeProps,
  Flex,
  Heading,
  Text,
  VStack
} from '@chakra-ui/react';
import { type Chapter } from '@tigerhall/core';
import { type PlaylistType } from '@tigerhall/analytics';

import {
  type PowerReadState,
  usePowerReadState
} from '../context/usePowerReadState';
import { Navigation } from './Navigation';
import { AudioBookPlayer } from '~/player/PowerRead/components/AudioBookPlayer';

const FADE_PROPS: FadeProps = {
  transition: {
    enter: {
      duration: 0.5
    },
    exit: {
      duration: 0.5
    }
  }
};

interface ReaderChildProps extends Pick<PowerReadState, 'fontSize' | 'theme'> {
  content: string;
}

export interface ReaderProps {
  onDone: () => void;
  /**
   * The name of the power read.
   */
  name: string;
  /**
   * The current chapter.
   */
  chapter: Pick<Chapter, 'name' | 'index'> | undefined;
  /**
   * The total number of chapters in the power read.
   */
  chaptersTotal: number | undefined;
  /**
   *
   * finished flag for power read
   */
  isFinished?: boolean;
  /**
   * All the pages of the power read.
   */
  pages: string[] | undefined;
  /**
   * We are using render props here to allow the user to render the content
   * in their own way. This is relevant because the `content` is in markdown
   * format, so the user has the flexibility to customize it.
   *
   * @example```tsx
   * <Reader
   *  name="Ebook Name"
   *  chapter={{ name: 'Chapter 1', index: 0 }}
   *  chaptersTotal={3}
   *  pages={['Page 1', 'Page 2', 'Page 3']}
   *  children={({ content, fontSize, theme }) => (
   *    <MarkdownWrapper fontSize={fontSize} theme={theme}>
   *      {content}
   *    </MarkdownWrapper>
   *  )}
   * />
   *  ```
   */
  children: (props: ReaderChildProps) => React.ReactNode;
  playlistType: PlaylistType | undefined;
  isLastContentInPlaylist: boolean;
  onNextContentPiece: () => void;
}

export function Reader({
  onDone,
  onNextContentPiece,
  name,
  chapter,
  chaptersTotal,
  pages,
  playlistType,
  isLastContentInPlaylist,
  isFinished,
  children
}: Readonly<ReaderProps>) {
  const { theme, fontSize, pageIndex } = usePowerReadState((state) => {
    return {
      theme: state.theme,
      fontSize: state.fontSize,
      pageIndex: state.pageIndex
    };
  });

  const isDarkMode = theme === 'dark';

  const { name: chapterName = '', index = 0 } = chapter ?? {};
  const chapterNumber = index + 1 < 10 ? `0${index + 1}` : index + 1;

  const headingColor = isDarkMode ? 'lightGrey.200' : 'darkGrey.400';
  const backgroundColor = isDarkMode ? 'darkGrey.400' : 'lightGrey.200';

  return (
    <Flex
      justifyContent="center"
      h="auto"
      w="100%"
      backgroundColor={backgroundColor}
    >
      <Flex w="100%" maxWidth="50rem" p="2rem" flexDirection="column">
        <VStack spacing={0}>
          <Text fontSize="sm" pb="2.25rem" color={headingColor}>
            {name}
          </Text>
          {chaptersTotal && chaptersTotal > 1 ? (
            <Text as="span" fontSize="lg" pb="0.5rem" color={headingColor}>
              {chapterNumber}
            </Text>
          ) : null}
          <Fade in={!!chapterName} {...FADE_PROPS}>
            <Heading as="h2" fontSize="4xl" pb="2.25rem" color={headingColor}>
              {chapterName}
            </Heading>
          </Fade>
        </VStack>
        <Box flex={1}>
          <Fade in={!!pages?.[pageIndex]} {...FADE_PROPS}>
            {children({ content: pages?.[pageIndex] ?? '', fontSize, theme })}
          </Fade>
        </Box>
        <Navigation
          onDone={onDone}
          onNextContentPiece={onNextContentPiece}
          playlistType={playlistType}
          isFinished={isFinished}
          isLastContentInPlaylist={isLastContentInPlaylist}
        />
        <AudioBookPlayer />
      </Flex>
    </Flex>
  );
}
