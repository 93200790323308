import { AnimatePresence, motion } from 'framer-motion';

import { ReplyInputBox, type ReplyInputBoxProps } from './ReplyInputBox';

interface AnimatedReplyBoxProps extends ReplyInputBoxProps {}

export function AnimatedReplyBox({
  comment,
  isOrganizationContent,
  referenceId,
  referenceType,
  onReplyCancel,
  refetch
}: AnimatedReplyBoxProps) {
  return (
    <AnimatePresence>
      <motion.div
        key={`animatedReplyBox--${comment.id}`}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0 }}
      >
        <ReplyInputBox
          comment={comment}
          isOrganizationContent={isOrganizationContent}
          referenceId={referenceId}
          referenceType={referenceType}
          onReplyCancel={onReplyCancel}
          refetch={refetch}
        />
      </motion.div>
    </AnimatePresence>
  );
}
