import * as React from 'react';
import { Button } from '@chakra-ui/react';
import * as segment from '@tigerhall/analytics';

export type Topic = {
  id: string;
  name: string;
  emoji?: string;
};

type Props = {
  item: Topic;
  isSelected?: boolean;
  onClick: (id: string) => void;
};

export default function TopicCloudItem({ item, isSelected, onClick }: Props) {
  const bgColor = isSelected ? 'darkGrey.400' : 'tigerOrange.50';
  const textColor = isSelected ? 'white' : 'darkGrey.400';
  const borderColor = isSelected ? 'tigerOrange.600' : 'transparent';
  return (
    <Button
      variant="unstyled"
      size="lg"
      bgColor={bgColor}
      color={textColor}
      fontSize="sm"
      border="2px solid"
      borderColor={borderColor}
      borderRadius="lg"
      alignItems="center"
      p={2}
      mb={2}
      mr={2}
      data-cy="topic-cloud-item"
      onClick={() => {
        segment.topicButtonClicked({
          buttonName: item?.name,
          location: window.location.pathname
        });
        onClick(item?.id);
      }}
      _hover={{
        bgColor: 'darkGrey.400',
        color: 'white'
      }}
    >
      {`${item?.emoji} ${item?.name}`}
    </Button>
  );
}
