import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TogglePostReactionMutationVariables = SchemaTypes.Exact<{
  reactionType: SchemaTypes.ReactionType;
  referenceId: SchemaTypes.Scalars['ID'];
}>;


export type TogglePostReactionMutation = { __typename?: 'Mutation', toggleReact: { __typename?: 'ReactionsSummary', totalCount: number, userReacted?: { __typename?: 'UserReaction', id: string, reactionType: SchemaTypes.ReactionType } | null } };


export const TogglePostReactionDocument = gql`
    mutation TogglePostReaction($reactionType: ReactionType!, $referenceId: ID!) {
  toggleReact(
    reactionType: $reactionType
    reactionRefType: POST
    referenceId: $referenceId
  ) {
    userReacted {
      id
      reactionType
    }
    totalCount
  }
}
    `;
export type TogglePostReactionMutationFn = Apollo.MutationFunction<TogglePostReactionMutation, TogglePostReactionMutationVariables>;

/**
 * __useTogglePostReactionMutation__
 *
 * To run a mutation, you first call `useTogglePostReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTogglePostReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [togglePostReactionMutation, { data, loading, error }] = useTogglePostReactionMutation({
 *   variables: {
 *      reactionType: // value for 'reactionType'
 *      referenceId: // value for 'referenceId'
 *   },
 * });
 */
export function useTogglePostReactionMutation(baseOptions?: Apollo.MutationHookOptions<TogglePostReactionMutation, TogglePostReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TogglePostReactionMutation, TogglePostReactionMutationVariables>(TogglePostReactionDocument, options);
      }
export type TogglePostReactionMutationHookResult = ReturnType<typeof useTogglePostReactionMutation>;
export type TogglePostReactionMutationResult = Apollo.MutationResult<TogglePostReactionMutation>;
export type TogglePostReactionMutationOptions = Apollo.BaseMutationOptions<TogglePostReactionMutation, TogglePostReactionMutationVariables>;