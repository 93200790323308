import { useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  HStack,
  Icon,
  MenuButton,
  Text,
  VStack
} from '@chakra-ui/react';
import { useFlag } from '@unleash/proxy-client-react';
import * as segment from '@tigerhall/analytics';
import { Flags, PrivacySettings, type User } from '@tigerhall/core';
import { IconCaretDownFilled } from '@tigerhall/icons';
import { OptionsMenuList } from 'components/ui/OptionsMenu';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getParentPostPrivacySetting,
  getPostPrivacySetting,
  hasOrgMemberAccess,
  setPrivacySetting
} from 'app/state';

import {
  PRIVACY_SETTINGS,
  getAudienceIcon,
  getPrivacySettingsList
} from '../../../../../../utils/privacySettings';
import { PrivacyOptionItem } from './components/PrivacyOptionItem';

export interface PrivacyOptionsProps extends Pick<User, 'roles'> {}

export function PrivacyOptions({ roles }: PrivacyOptionsProps) {
  const dispatch = useAppDispatch();
  const selectedPrivacySetting = useAppSelector(getPostPrivacySetting);
  const parentPostPrivacySetting = useAppSelector(getParentPostPrivacySetting);
  const isOrgMember = useAppSelector(hasOrgMemberAccess);

  const isPlatformAnnouncementEnabled = useFlag(
    Flags.FeedCreatePlatformAnnouncement
  );

  const privacySettingsList = useMemo(
    () =>
      getPrivacySettingsList({
        roles,
        isOrgMember,
        parentPrivacySetting: parentPostPrivacySetting
      }),
    [roles, isOrgMember, parentPostPrivacySetting]
  );

  const savePrivacySetting = useCallback(
    (setting: PrivacySettings) => {
      dispatch(setPrivacySetting(setting));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!privacySettingsList.includes(selectedPrivacySetting)) {
      savePrivacySetting(privacySettingsList[0]);
    }
  }, [selectedPrivacySetting, privacySettingsList, savePrivacySetting]);

  const hasSinglePrivacyOption = privacySettingsList.length === 1;

  if (hasSinglePrivacyOption) {
    return (
      <HStack as="span" fontSize="xs" color="darkGrey.50" py="0.25rem">
        <Icon as={getAudienceIcon(selectedPrivacySetting)} />
        <Text fontSize="inherit" fontWeight="bold" lineHeight={1}>
          {PRIVACY_SETTINGS[selectedPrivacySetting].heading}
        </Text>
      </HStack>
    );
  }

  return (
    <>
      <OptionsMenuList
        maxWidth="23rem"
        onOpen={() => {
          segment.postAudienceClicked({});
        }}
        menuButton={
          <MenuButton
            as={Button}
            variant="ghost"
            size="sm"
            color="lightGrey.600"
            p="0.25rem 0.5rem"
            borderRadius="base"
            height="1.5rem"
            lineHeight={1}
            leftIcon={<Icon as={getAudienceIcon(selectedPrivacySetting)} />}
            rightIcon={<Icon as={IconCaretDownFilled} boxSize="0.5rem" />}
            background="darkGrey.400"
            _active={{
              background: 'darkGrey.300'
            }}
            _hover={{
              background: 'darkGrey.300'
            }}
          >
            {PRIVACY_SETTINGS[selectedPrivacySetting].heading}
          </MenuButton>
        }
      >
        <VStack
          alignItems="flex-start"
          spacing="0.25rem"
          p="0.5rem"
          color="darkGrey.400"
          _dark={{
            color: 'lightGrey.900'
          }}
        >
          <Text fontSize="sm" fontWeight="bold" color="inherit">
            Who can see your post?
          </Text>
          <Text fontSize="sm" color="inherit">
            Your post will appear in the feed and on your profile
          </Text>
        </VStack>
        {privacySettingsList.map((privacySetting) => (
          <PrivacyOptionItem
            key={`privacy-options-${privacySetting}`}
            selectedOption={privacySetting}
            isDisabled={
              privacySetting === PrivacySettings.PlatformAnnouncement &&
              !isPlatformAnnouncementEnabled
            }
          />
        ))}
      </OptionsMenuList>
    </>
  );
}
