import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import { EventFragmentFragmentDoc } from '../../../../../../queries/fragments/generated/event.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEventMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.EventInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', result?: { __typename?: 'Event', id: string, name: string, preamble: string, entryLevel: SchemaTypes.EntryLevelValue, eventType: SchemaTypes.EventType, maxAttendees: number, isFeatured: boolean, isFree: boolean, isAssigned: boolean, ticketPrice: number, ticketCurrency: string, isOnline: boolean, conferenceUrl?: string | null, attendeeCount: number, startsAt?: any | null, endsAt?: any | null, updatedAt: any, publishedAt?: any | null, locationDisplayName?: string | null, image: { __typename?: 'Image', id: string, uri: string }, organisationGroups: Array<{ __typename?: 'OrganisationGroup', id: string, name: string }>, attendees: Array<{ __typename?: 'User', id: string, firstName: string, lastName: string, email: string, organisationGroups?: Array<{ __typename?: 'OrganisationGroup', name: string }> | null } | null>, contentSegments: Array<{ __typename?: 'ContentSegment', header: string, paragraph: string }>, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, title: string, company?: string | null, image: { __typename?: 'Image', id: string, uri: string } }>, categories: Array<{ __typename?: 'Category', id: string, name: string }>, location?: { __typename?: 'Location', name: string, city: string } | null, userContentTracking: { __typename: 'UserContentTracking', id: string, current: number, currentPlacement: number, total: number, isAttending: boolean, isFinished: boolean, isBookmarked: boolean, bookmarkedAt?: any | null, lastOpenedAt?: any | null, finishedAt?: any | null } } | null };

export type UpdateEventMutationVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
  input: SchemaTypes.EventInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', result?: { __typename?: 'Event', id: string, name: string, preamble: string, entryLevel: SchemaTypes.EntryLevelValue, eventType: SchemaTypes.EventType, maxAttendees: number, isFeatured: boolean, isFree: boolean, isAssigned: boolean, ticketPrice: number, ticketCurrency: string, isOnline: boolean, conferenceUrl?: string | null, attendeeCount: number, startsAt?: any | null, endsAt?: any | null, updatedAt: any, publishedAt?: any | null, locationDisplayName?: string | null, image: { __typename?: 'Image', id: string, uri: string }, organisationGroups: Array<{ __typename?: 'OrganisationGroup', id: string, name: string }>, attendees: Array<{ __typename?: 'User', id: string, firstName: string, lastName: string, email: string, organisationGroups?: Array<{ __typename?: 'OrganisationGroup', name: string }> | null } | null>, contentSegments: Array<{ __typename?: 'ContentSegment', header: string, paragraph: string }>, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, title: string, company?: string | null, image: { __typename?: 'Image', id: string, uri: string } }>, categories: Array<{ __typename?: 'Category', id: string, name: string }>, location?: { __typename?: 'Location', name: string, city: string } | null, userContentTracking: { __typename: 'UserContentTracking', id: string, current: number, currentPlacement: number, total: number, isAttending: boolean, isFinished: boolean, isBookmarked: boolean, bookmarkedAt?: any | null, lastOpenedAt?: any | null, finishedAt?: any | null } } | null };

export type DeleteEventMutationVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['String'];
}>;


export type DeleteEventMutation = { __typename?: 'Mutation', deleteEvent: string };


export const CreateEventDocument = gql`
    mutation createEvent($input: EventInput!) {
  result: createEvent(input: $input) {
    ...eventFragment
  }
}
    ${EventFragmentFragmentDoc}`;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const UpdateEventDocument = gql`
    mutation updateEvent($id: String!, $input: EventInput!) {
  result: updateEvent(input: $input, id: $id) {
    ...eventFragment
  }
}
    ${EventFragmentFragmentDoc}`;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const DeleteEventDocument = gql`
    mutation deleteEvent($id: String!) {
  deleteEvent(id: $id)
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;