import { Avatar, Box, Flex, LinkBox, Text } from '@chakra-ui/react';
import { type Expert, type User, resizeImage } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';

import { LinkOverlay } from '~/link';
import { LiveBadge } from '~/badges';
import { ThinkfluencerBadgeWrapper } from '~/user/components/ThinkfluencerBadgeWrapper';
import type {
  ThinkfluencerBadgeSize,
  ThinkfluencerScope
} from '~/user/components/ThinkfluencerBadge';

function extractProperties(profile: Readonly<ProfileBarProps['profile']>) {
  if (profile.__typename === 'User') {
    return {
      name: `${profile.firstName} ${profile.lastName}`,
      image: profile.avatarImage,
      company: profile.company,
      title: profile.jobTitle,
      id: profile.id
    };
  }
  if (profile.__typename === 'Expert') {
    return {
      name: `${profile.firstName} ${profile.lastName}`,
      image: profile.image,
      company: profile.company,
      title: profile.title,
      id: profile.id
    };
  }
  throw new Error('unsupported profile provided');
}

function handleProfileClick(profile: {
  id: string;
  name: string;
  isThinkfluencer: boolean;
}) {
  segment.profileClicked({
    href: `/profile/${profile.id}`,
    userId: profile.id,
    profileName: profile.name,
    location: window.location.pathname,
    isExpert: profile.isThinkfluencer
  });
}

export interface UserBasicInfo
  extends Pick<
    User,
    '__typename' | 'id' | 'firstName' | 'lastName' | 'company' | 'jobTitle'
  > {
  avatarImage: { uri: string; alt?: string };
}

export interface ExpertBasicInfo
  extends Pick<
    Expert,
    '__typename' | 'id' | 'firstName' | 'lastName' | 'company' | 'title'
  > {
  image?: { uri: string; alt?: string } | null;
}

export type ProfileBasicInfo = UserBasicInfo | ExpertBasicInfo;
type ProfileBarSize = 'xs' | 'sm' | 'md';

export interface ProfileBarProps {
  profile: ProfileBasicInfo;
  size?: ProfileBarSize;
  thinkfluencerScope?: ThinkfluencerScope;
  disableLinkOverlay?: boolean;
  isLive?: boolean;
}

export const sizeMap: Record<ProfileBarSize, Record<string, string>> = {
  xs: {
    imageSize: '2rem',
    nameFontSize: 'xs',
    titleFontSize: 'xs',
    companyFontSize: 'xs'
  },
  sm: {
    imageSize: '3rem',
    nameFontSize: 'sm',
    titleFontSize: 'xs',
    companyFontSize: 'xs'
  },
  md: {
    imageSize: '4rem',
    nameFontSize: 'md',
    titleFontSize: 'sm',
    companyFontSize: 'sm'
  }
};

const sizeToBadgeSizeMap: Record<ProfileBarSize, ThinkfluencerBadgeSize> = {
  xs: 'sm',
  sm: 'md',
  md: 'lg'
};

const sizeToBadgeSpacingMap: Record<ProfileBarSize, number> = {
  xs: 1,
  sm: 1,
  md: 2
};

/**
 * This is intended to be a partial replacement of `UserItem` component that exists in `ui-components` package.
 * However, it is not a drop-in replacement as it has a slight different API.
 *
 * ℹ️ This component is not supposed to support neither the "Verified" badge for now nor the "Follow" button
 * since that is "app specific" functionality. If you need that, you should create a "wrapper" component
 * in the app that will handle that (e.g. web app, marketing website). ℹ️
 *
 * @see [Figma Design](https://www.figma.com/file/WLhIGuIkWTy7rf4QbRfxvA/New-Design-System?type=design&node-id=793-5103&mode=dev)
 */
export function ProfileBar({
  profile,
  thinkfluencerScope,
  size = 'md',
  disableLinkOverlay = false,
  isLive = false
}: Readonly<ProfileBarProps>) {
  const { image, name, title, company, id } = extractProperties(profile);

  const imageBorderColor = isLive ? 'tigerOrange.600' : 'darkGrey.300';

  const thinkfluencerBadgeProps = thinkfluencerScope
    ? ({
        scope: thinkfluencerScope,
        size: sizeToBadgeSizeMap[size]
      } as const)
    : undefined;

  const transition = disableLinkOverlay
    ? undefined
    : {
        transition: 'transform 0.2s',
        _groupHover: {
          transform: 'scale(0.9)'
        }
      };

  return (
    <LinkBox gap="1rem" overflow={'hidden'} width={'full'}>
      <Flex flex={1} gap="0.75rem" role="group">
        <Box
          position={'relative'}
          borderColor={imageBorderColor}
          borderWidth={2}
          borderRadius={'full'}
          {...transition}
        >
          <Avatar
            src={resizeImage({
              url: image?.uri || '',
              width: 64,
              height: 64
            })}
            srcSet={`${resizeImage({
              url: image?.uri || '',
              width: 64,
              height: 64
            })} 1x, ${resizeImage({
              url: image?.uri || '',
              width: 128,
              height: 128
            })} 2x`}
            boxSize={sizeMap[size].imageSize}
            name={name}
            showBorder={isLive}
            border={'2px solid black'}
            objectFit="cover"
          />
          {isLive && size !== 'xs' ? (
            <LiveBadge
              size={size === 'sm' ? 'xs' : 'sm'}
              badgeProps={{
                position: 'absolute',
                bottom: '-0.125rem',
                left: '50%',
                transform: 'translateX(-50%)'
              }}
            />
          ) : null}
        </Box>
        <Flex flexDirection="column" justifyContent="center" flex={1}>
          <ThinkfluencerBadgeWrapper
            spacing={sizeToBadgeSpacingMap[size]}
            badgeProps={thinkfluencerBadgeProps}
          >
            <Text
              fontSize={sizeMap[size].nameFontSize}
              fontWeight={'bold'}
              color={'lightGrey.200'}
              noOfLines={1}
            >
              {disableLinkOverlay ? (
                name
              ) : (
                <LinkOverlay
                  href={`/profile/${id}`}
                  onClick={() =>
                    handleProfileClick({
                      id,
                      name,
                      isThinkfluencer: !!thinkfluencerScope
                    })
                  }
                >
                  {name}
                </LinkOverlay>
              )}
            </Text>
          </ThinkfluencerBadgeWrapper>
          {size !== 'xs' && (
            <Text
              fontSize={sizeMap[size].titleFontSize}
              fontWeight={'medium'}
              noOfLines={1}
              color={'lightGrey.600'}
            >
              {title}
            </Text>
          )}
          <Text
            noOfLines={1}
            fontSize={sizeMap[size].companyFontSize}
            fontWeight={'medium'}
            color={'tigerOrange.600'}
          >
            {company}
          </Text>
        </Flex>
      </Flex>
    </LinkBox>
  );
}
