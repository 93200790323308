import * as React from 'react';
import { Text } from '@chakra-ui/react';
import map from 'lodash/map';

const TABLE_ITEM_PLACE_HOLDER = '--';

function createColumn(id: string, header: string, options = {}) {
  return { id, Header: header, ...options };
}

export function getContentUsersColumns() {
  return [
    createColumn('name', 'Full Name', {
      /** TODO: add default accessor handling in Table Component **/
      accessor: (member) => (
        <Text
          fontSize="inherit"
          fontWeight="inherit"
          color="inherit"
          textTransform="capitalize"
        >
          {member.firstName} {member.lastName ?? ''}
        </Text>
      )
    }),
    createColumn('group', 'Group', {
      accessor: (member) =>
        map(member?.organisationGroups, (org) => org?.name).join(', ') ??
        TABLE_ITEM_PLACE_HOLDER,
      disableSortBy: true
    }),
    createColumn('email', 'Email', {
      accessor: (member) => (
        <Text fontSize="inherit" fontWeight="inherit" color="inherit">
          {member.email ?? TABLE_ITEM_PLACE_HOLDER}
        </Text>
      )
    })
  ];
}

export function getContentGroupsColumns() {
  return [
    createColumn('name', 'Group Name', {
      width: 230,
      accessor: (group) => (
        <Text
          fontSize="inherit"
          fontWeight="inherit"
          color="inherit"
          textTransform="capitalize"
        >
          {group.name}
        </Text>
      )
    }),
    createColumn('numberOfMembers', 'No of Users', {
      accessor: (group) => group?.numberOfMembers,
      disableSortBy: true
    }),
    createColumn('createdBy', 'Created By', {
      width: 230,
      accessor: (group) =>
        group?.createdBy?.firstName && group?.createdBy?.lastName
          ? `${group?.createdBy?.firstName} ${group?.createdBy?.lastName}`
          : '-',
      disableResizing: true,
      disableSortBy: true
    })
  ];
}
