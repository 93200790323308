import memoize from 'lodash/memoize';
import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import theme from 'theme';

const VARIANTS = {
  label: 'label',
  body: 'body',
  headingXL: 'headingXL',
  heading1: 'heading1',
  heading2: 'heading2',
  heading3: 'heading3',
  heading4: 'heading4',
  heading5: 'heading5',

  bodyLarge: 'bodyLarge',
  bodyMedium: 'bodyMedium',
  bodySmall: 'bodySmall',
  bodyXS: 'bodyXS',

  detail1: 'detail1',
  detail2: 'detail2',
  detail3: 'detail3',
  detail4: 'detail4',
  detail5: 'detail5',

  button: 'button',
  buttonSmall: 'buttonSmall',

  xsTitle: 'xsTitle',
  xxsTitle: 'xxsTitle',
  /** TODO: verify variant from designs **/
  tabs: 'tabs',
  tableHeader: 'tableHeader'
};

type Variant = keyof typeof VARIANTS;

/**
 * @deprecated No need to use FontWeight, specify as string/int instead
 */

// eslint-disable-next-line no-shadow
export enum FontWeight {
  Light = 300,
  Regular = 400,
  SemiBold = 600,
  Bold = 700,
  ExtraBold = 800
}

interface TypographyProps extends TextProps {
  // Which variant of text we want to use
  variant?: Variant;

  // If we want to transform the text to uppercase
  uppercase?: boolean;

  /**
   * @deprecated: Use the color property instead
   */
  primary?: boolean;

  centered?: boolean;

  // Font weight
  weight?: TextProps['fontWeight'];

  // If we should convert the new lines to line breaks
  nl2br?: boolean;
}

const createStyleProps = memoize((variant: Variant): TextProps => {
  switch (variant) {
    case 'headingXL':
      return {
        fontSize: 'calc(32px + 16 * ((100vw - 320px) / 680))',
        fontWeight: 'bold',
        as: 'h1'
      };

    case 'heading1':
      return {
        fontSize: '2.5rem',

        letterSpacing: '0.25rem',
        fontWeight: FontWeight.Bold,
        as: 'h1'
      };

    case 'heading2':
      return {
        fontSize: '1.75rem',
        as: 'h2'
      };

    case 'heading3':
      return {
        fontSize: '1.5rem',
        // fontWeight: FontWeight.Bold,
        as: 'h3'
      };

    case 'heading4':
      return {
        fontSize: '1.125rem',
        fontWeight: FontWeight.Bold,
        as: 'h4'
      };

    case 'heading5':
      return {
        fontSize: '1rem',
        // fontWeight: FontWeight.Bold,
        as: 'h5'
      };

    case 'bodyLarge':
      return {
        fontSize: '1.5rem'
      };

    case 'bodyMedium':
      return {
        fontSize: '1.125rem'
      };

    case 'bodySmall':
      return {
        fontSize: '1.125rem'
      };

    case 'bodyXS':
      return {
        fontSize: '0.875rem'
      };

    case 'detail1':
      return {
        fontSize: '1.125rem'
      };

    case 'detail2':
      return {
        fontSize: '1rem'
      };

    case 'detail3':
      return {
        fontSize: '0.875rem'
      };

    case 'detail4':
      return {
        fontSize: '0.75rem'
      };

    case 'detail5':
      return {
        fontSize: '0.625rem'
      };

    case 'button':
      return {
        fontSize: '1rem',
        fontWeight: FontWeight.Bold
      };

    case 'buttonSmall':
      return {
        fontSize: '0.875rem',
        fontWeight: FontWeight.Bold
      };

    default:
      return {
        fontWeight: 400,
        fontSize: 14
      };
  }
});

/** @deprecated use `Heading` or `Text` from '@chakra-ui/react' instead */
function Typography({
  variant = 'body',
  uppercase = false,
  primary = false,
  weight,
  nl2br = false,
  centered = false,
  children,
  ...props
}: TypographyProps) {
  let styleProps: React.ComponentProps<typeof Text> = createStyleProps(variant);

  if (uppercase) {
    styleProps = { ...styleProps, textTransform: 'uppercase' };
  }

  if (primary) {
    styleProps = { ...styleProps, color: theme.colors.orange['600'] };
  }

  if (nl2br) {
    styleProps = { ...styleProps, whiteSpace: 'pre-wrap' };
  }

  if (centered) {
    styleProps = { ...styleProps, textAlign: 'center' };
  }

  if (weight) {
    styleProps = { ...styleProps, fontWeight: weight };
  }

  styleProps = { ...styleProps };

  return (
    <Text {...styleProps} {...props}>
      {children}
    </Text>
  );
}

export default Typography;
