const s = {
  // style object for base or default style
  baseStyle: {
    borderRadius: "base"
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {},
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {}
};
export {
  s as Link
};
