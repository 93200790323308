import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import { ImageIdUriFragmentDoc } from './imageIdUri.generated';
import { UserContentTrackingFragmentFragmentDoc } from './userContentTracking.generated';
export type EventFragmentFragment = { __typename?: 'Event', id: string, name: string, preamble: string, entryLevel: SchemaTypes.EntryLevelValue, eventType: SchemaTypes.EventType, maxAttendees: number, isFeatured: boolean, isFree: boolean, isAssigned: boolean, ticketPrice: number, ticketCurrency: string, isOnline: boolean, conferenceUrl?: string | null, attendeeCount: number, startsAt?: any | null, endsAt?: any | null, updatedAt: any, publishedAt?: any | null, locationDisplayName?: string | null, image: { __typename?: 'Image', id: string, uri: string }, organisationGroups: Array<{ __typename?: 'OrganisationGroup', id: string, name: string }>, attendees: Array<{ __typename?: 'User', id: string, firstName: string, lastName: string, email: string, organisationGroups?: Array<{ __typename?: 'OrganisationGroup', name: string }> | null } | null>, contentSegments: Array<{ __typename?: 'ContentSegment', header: string, paragraph: string }>, experts: Array<{ __typename?: 'Expert', id: string, firstName: string, lastName: string, title: string, company?: string | null, image: { __typename?: 'Image', id: string, uri: string } }>, categories: Array<{ __typename?: 'Category', id: string, name: string }>, location?: { __typename?: 'Location', name: string, city: string } | null, userContentTracking: { __typename: 'UserContentTracking', id: string, current: number, currentPlacement: number, total: number, isAttending: boolean, isFinished: boolean, isBookmarked: boolean, bookmarkedAt?: any | null, lastOpenedAt?: any | null, finishedAt?: any | null } };

export const EventFragmentFragmentDoc = gql`
    fragment eventFragment on Event {
  id
  name
  preamble
  image {
    ...imageIdUri
  }
  organisationGroups {
    id
    name
  }
  attendees {
    id
    firstName
    lastName
    email
    organisationGroups {
      name
    }
  }
  entryLevel
  eventType
  maxAttendees
  isFeatured
  isFree
  isAssigned
  contentSegments {
    header
    paragraph
  }
  ticketPrice
  ticketCurrency
  experts {
    id
    firstName
    lastName
    title
    company
    image {
      id
      uri
    }
  }
  categories {
    id
    name
  }
  location {
    name
    city
  }
  isOnline
  conferenceUrl
  attendeeCount
  startsAt
  endsAt
  updatedAt
  publishedAt
  locationDisplayName
  userContentTracking {
    ...userContentTrackingFragment
  }
}
    ${ImageIdUriFragmentDoc}
${UserContentTrackingFragmentFragmentDoc}`;