import { Box, Flex, HStack } from '@chakra-ui/react';
import { FormInput, TrackedRTForm } from '@tigerhall/components';
import { AssignmentStatus } from '@tigerhall/core';
import {
  AssignmentStatusSelect,
  AssignmentStatusSelectOption,
  getAllStatusOptions
} from 'components/form';
import * as React from 'react';

type FilterProps = {
  onUpdate: (filters: any) => void;
};

interface FormValues extends Record<string, unknown> {
  title: string;
  status: AssignmentStatusSelectOption;
}

const ASSIGNMENT_STATUSES = [AssignmentStatus.Active, AssignmentStatus.Expired];
const defaultAssignmentStatus = getAllStatusOptions(ASSIGNMENT_STATUSES)[0];

export const TableFilters = React.memo<FilterProps>(
  ({ onUpdate }: Readonly<FilterProps>) => {
    const onFilterChange = React.useCallback(
      (values: FormValues) => {
        onUpdate({
          title: values.title,
          status: values.status.value
        });
      },
      [onUpdate]
    );

    return (
      <Flex direction="column" py="1rem" gap="1rem">
        <TrackedRTForm<FormValues>
          name="assignmentSearch"
          onSubmit={onFilterChange}
          formHookProps={{
            defaultValues: {
              status: defaultAssignmentStatus
            }
          }}
        >
          <HStack direction="row" spacing={4}>
            <Box width="25rem">
              <FormInput
                name="title"
                type="text"
                label="Search"
                placeholder="Enter assignment title"
              />
            </Box>
            <Box width="15rem">
              <AssignmentStatusSelect
                name="status"
                statusToShow={ASSIGNMENT_STATUSES}
              />
            </Box>
          </HStack>
        </TrackedRTForm>
      </Flex>
    );
  }
);
