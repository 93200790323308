import React from 'react';
import ReactSlidingPane from 'react-sliding-pane';
import { useWindowSize } from '@tigerhall/core';
import { css, Global } from '@emotion/react';

// THIS MUST BE IMPORTED MANUALLY IN EACH PROJECT THAT USES IT
// BECAUSE IT BREAKS NEXTJS CSS!!
// import 'react-sliding-pane/dist/react-sliding-pane.css';

type Props = {
  children: React.ReactNode | null;

  isOpen?: boolean;
};

const styles = css`
  .slide-pane__overlay.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .slide-pane__content {
    background-color: rgb(0, 31, 36);
  }

  .slide-pane__overlay {
    z-index: 1000;
  }
`;

export default function SidePane({
  children,
  isOpen = false,
  ...props
}: Props) {
  // If we don't provide a width then ReactSlidingPane sets it using
  // a default prop would have been better to use css and media queries
  const { width } = useWindowSize();

  return (
    <>
      <Global styles={styles} />
      <ReactSlidingPane
        isOpen={isOpen}
        hideHeader
        shouldCloseOnEsc={false}
        width={(width || 0) >= 1000 ? '35%' : '50%'}
        onRequestClose={() => {}}
        {...props}
      >
        {children}
      </ReactSlidingPane>
    </>
  );
}
