import decodeJwt from 'jwt-decode';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/reducer';

// ------------------------------------
// Reducer
// ------------------------------------

type CustomerType = 'INDIVIDUAL' | 'TEAM' | 'ENTERPRISE';

interface Claims {
  iat: number | null;
  exp: number | null;
  sub: string | null;
  tenantId: null | string;
  organisationId: null | string;
  customerType: CustomerType | null;
  roles: string[];
}

interface State {
  token: string | null;
  claims: Claims;
  shouldOnboardUser: boolean;
  user: object;
}

const initialState: State = {
  token: null,
  claims: {
    iat: null,
    exp: null,
    sub: null,
    tenantId: null,
    organisationId: null,
    customerType: null,
    roles: []
  },
  shouldOnboardUser: false,
  user: {}
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAccessToken: (
      state,
      action: PayloadAction<{
        token: string | null;
        shouldOnboardUser?: boolean;
      }>
    ) => {
      if (!action.payload.token) {
        return initialState;
      }

      return {
        ...state,
        ...action.payload,
        claims: decodeJwt(action.payload.token)
      };
    },
    setIsOnboardingStatus: (
      state,
      action: PayloadAction<{ shouldOnboardUser: boolean }>
    ) => ({
      ...state,
      ...action.payload
    }),

    logout: () => initialState
  }
});

export const { logout, setAccessToken, setIsOnboardingStatus } =
  userSlice.actions;

export default userSlice.reducer;

// ------------------------------------
// Custom selectors
// ------------------------------------
export function getAccessToken(state: RootState) {
  return state.user.token;
}

export function isLoggedIn(state: RootState) {
  return !!state.user.token;
}

export function getUserId(state: RootState) {
  return state.user.claims.sub;
}
export function getOnboardingStatus(state: RootState) {
  return state.user.shouldOnboardUser;
}
export function getCustomerType(state: RootState) {
  return state.user.claims.customerType;
}

export function hasMicrosoftTeamsTenantId(state: RootState) {
  return !!state.user.claims.tenantId;
}
export function getOrgIdFromToken(state: RootState) {
  return state?.user?.claims?.organisationId || null;
}

export function getTokenIssuedAt(state: RootState) {
  return state.user.claims.iat;
}

export function getSelectedOrgId(state: RootState): string | null {
  return state.user.claims.organisationId;
}

export function hasSuperAdminAccess(state: RootState) {
  if (!state.user.claims) {
    return false;
  }

  return state.user.claims.roles.indexOf('admin') !== -1;
}

export function hasOrgAdminAccess(state: RootState) {
  if (!state.user.claims) {
    return false;
  }

  if (state.user.claims.roles.indexOf('admin') !== -1) {
    return true;
  }

  return state.user.claims.roles.indexOf('org_admin') !== -1;
}

export function hasOrgMemberAccess(state: RootState) {
  const orgId = getSelectedOrgId(state);

  return !!orgId && !orgId.includes('00000000-0000-0000-0000-000000000000');
}
