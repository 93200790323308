import { TrackedLink } from '@tigerhall/components';
import { compactNumber } from '@tigerhall/core';
import * as segment from '@tigerhall/analytics';

interface RepostsCountProps {
  postId: string;
  count: number;
}

/**
 * @todo This will be a link that opens the reposts screen.
 * Will be done in Post Improvements.
 * Remember to add the segment event onClick.
 */
export function RepostsCount({ postId, count }: RepostsCountProps) {
  const repostsListLink = `/feed/${postId}/reposts`;

  if (count === 0) {
    return null;
  }

  const compactCount = compactNumber(count);

  return (
    <TrackedLink
      name="REPOSTS_LIST"
      href={repostsListLink}
      onClick={() => {
        segment.viewRepostsClicked({
          referenceId: postId
        });
      }}
    >
      {compactCount} {count === 1 ? 'repost' : 'reposts'}
    </TrackedLink>
  );
}
