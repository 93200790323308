import { useEffect, useRef } from 'react';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { Button, Center, Icon, Text } from '@chakra-ui/react';
import { useLoginLinkedInMutation } from 'generated';
import { useAppDispatch } from 'hooks';
import { setAccessToken } from 'app/state';
import { IconBrandLinkedin } from '@tigerhall/icons';
import * as segment from '@tigerhall/analytics';

export function SignInWithLinkedIn() {
  const dispatch = useAppDispatch();
  const [mutation] = useLoginLinkedInMutation();

  const isAuthRequestInflight = useRef(false);

  const clientId = import.meta.env.VITE_LINKEDIN_CLIENT_ID as string;
  const redirectUri = import.meta.env.VITE_LINKEDIN_REDIRECT_URI as string;

  useEffect(() => {
    async function listener(event: MessageEvent): Promise<void> {
      if (
        event.data?.from !== 'Linked In' &&
        event.origin !== import.meta.env.VITE_WEBAPP_URL
      ) {
        return;
      }

      // We typically receive about 4-5 messages from the PopUp and having
      // multiple calls to the login mutation will cause the backend to respond with invalid code
      if (isAuthRequestInflight.current) {
        return;
      }

      isAuthRequestInflight.current = true;

      try {
        const response = await mutation({
          variables: {
            token: event.data.code,
            redirectUri
          }
        });

        dispatch(
          setAccessToken({
            token: response.data?.loginLinkedIn?.jwt as string
          })
        );
      } catch (e) {
        console.error(e);
      } finally {
        isAuthRequestInflight.current = false;
      }
    }

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [dispatch, mutation, redirectUri]);

  const { linkedInLogin } = useLinkedIn({
    clientId,
    redirectUri,
    scope: 'r_liteprofile r_emailaddress',
    onError: (error) => {
      console.log('onError', error);
    },
    onSuccess: () => {
      console.log(
        'this is broken and we added a event listener above to solve it'
      );
    }
  });

  return (
    <Button
      leftIcon={
        <Icon as={IconBrandLinkedin} color="white" w="1.5em" h="1.5em" />
      }
      w="100%"
      p="0.75rem"
      onClick={() => {
        segment.buttonClicked({
          location: window.location.pathname,
          buttonName: 'LINKEDIN_SSO_LOGIN'
        });

        linkedInLogin();
      }}
      backgroundColor="#2867B2"
      minH="2.75rem"
    >
      <Center>
        <Text color="white" fontSize="1rem" my="0.75rem" as="span">
          Sign in with LinkedIn
        </Text>
      </Center>
    </Button>
  );
}
