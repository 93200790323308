import { useLocation, useNavigate } from 'react-router-dom';
import { useLastLocation } from '@tigerhall/core';
import { setAccessToken } from 'app/state';
import { useAppDispatch } from 'hooks';
import { useEffect } from 'react';

export function useSingleSignOnCallback() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { lastLocation } = useLastLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token && token !== null) {
      dispatch(
        setAccessToken({
          token: params.get('token')
        })
      );

      /**
       * first we navigate to `/home` so if the user is redirected to a "modal" and then closes it
       * they are redirected to `/home` instead of the last location which will be the
       * "login success page" because right now when we close the modal we navigate to `-1`.
       */
      navigate('/home', { replace: true });

      if (lastLocation) {
        navigate(lastLocation);
      }
    }
  }, [dispatch, lastLocation, location.search, navigate]);
}
