import {
  Modal as CHModal,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  type ModalProps
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'hooks';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { getNavigationPageCount, resetTemporaryState } from 'app/state';
import { removeArgFromQueryString } from 'utils';
import { IconBrandTigerhallHorizontal } from '@tigerhall/icons';

interface Props {
  children: React.ReactNode;

  /**
   * Page title
   */
  title: string;

  /**
   * If we want the modal to be displayed as a full page (Not full screen view)
   */
  fullPage?: boolean;

  size?: ModalProps['size'];

  /**
   * this is an action to close the modal in custom manner
   * speficially for performing action when we get to the modal through `url`
   */
  handleModalClose?: () => void;
  /**
   * this is a replacement for a spefific case for performing an action on modalClose
   */
  performActionOnModalClose?: () => void;
}

const FullPageContent = ({ children }) => (
  <ModalContent
    bg="black"
    borderColor="white"
    p={{ base: 0, md: 4 }}
    containerProps={{
      zIndex: 'contentPage'
    }}
  >
    <ModalHeader position="absolute" display={{ base: 'none', md: 'block' }}>
      <Icon
        as={IconBrandTigerhallHorizontal}
        width="6.5rem"
        height={7}
        color="tigerOrange.600"
      />
    </ModalHeader>
    <ModalCloseButton
      color="white"
      size="lg"
      top={{ base: 4, md: 6 }}
      right={{ base: 4, md: 6 }}
      zIndex="999"
    />
    <ModalBody
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={{ base: 0, md: 4 }}
    >
      {children}
    </ModalBody>
  </ModalContent>
);

const StandardContent = ({ children }) => (
  <ModalContent
    maxW="48rem"
    h="fit-content"
    mb={28}
    borderWidth={{ base: 'none', md: '1px' }}
    borderColor="darkGrey.300"
    borderRadius={{ base: 'none', md: '2xl' }}
    overflow="hidden"
    containerProps={{
      zIndex: 'contentPage'
    }}
  >
    {children}
  </ModalContent>
);

const Modal = ({
  title,
  children,
  fullPage = false,
  size,
  handleModalClose,
  performActionOnModalClose
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const pageCount = useAppSelector(getNavigationPageCount);

  const handleOnClose = () => {
    dispatch(resetTemporaryState());
    performActionOnModalClose?.();
    // Objective: Enhance the modal's flow when accessed through a URL while
    // preserving its natural behavior in other cases.
    // Approach: We aim to provide a seamless experience for users accessing the
    // modal via `url`. To achieve this, we check the content's history and
    // remove unnecessary parameters, while still retaining the option to
    // override the default behavior using the `handleModalClose` function.
    // By implementing these changes, users accessing the modal through `url`
    // will have a smoother flow back to the content.
    if (pageCount > 1) {
      navigate(-1);
    } else {
      if (handleModalClose) {
        handleModalClose();
      } else {
        navigate(removeArgFromQueryString(location, 'content', 'trail'));
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CHModal
        onClose={() => handleOnClose()}
        // necessary to avoid automatic scroll when another modal
        // that was open on top of this modal is closed
        autoFocus={false}
        size={size}
        motionPreset={fullPage === true ? 'scale' : 'slideInBottom'}
        preserveScrollBarGap
        // necessary to avoid blocking the scroll of other modals
        // that were open on top of this modal
        blockScrollOnMount={false}
        isOpen
      >
        <ModalOverlay bg={fullPage ? 'black' : undefined} zIndex={'overlay'} />
        {fullPage ? (
          <FullPageContent>{children}</FullPageContent>
        ) : (
          <StandardContent>{children}</StandardContent>
        )}
      </CHModal>
    </>
  );
};

export default Modal;
