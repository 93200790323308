import { IconMessageReport } from '@tigerhall/icons';
import * as segment from '@tigerhall/analytics';
import { useState } from 'react';

import { ReportModal } from '~/components/ui/modals';
import { OptionsMenuItem } from '~/components/ui/OptionsMenu';

interface ReportProfileOptionProps {
  userId: string;
}

export function ReportProfileOption({
  userId
}: Readonly<ReportProfileOptionProps>) {
  const [shouldOpenReportModal, setShouldOpenReportModal] = useState(false);

  return (
    <>
      <OptionsMenuItem
        icon={IconMessageReport}
        title="Report User"
        description="Report this user for review."
        onClick={() => {
          segment.commentOptionsClicked({
            referenceId: userId,
            buttonName: 'report_user'
          });
          setShouldOpenReportModal(true);
        }}
      />
      <ReportModal
        isOpen={shouldOpenReportModal}
        onClose={() => {
          setShouldOpenReportModal(false);
        }}
        contentId={userId}
        contentTypeName="User"
        heading="Report User"
        subheading=""
      />
    </>
  );
}
