import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel
} from '@chakra-ui/react';

import type { LearningPathContent } from '~/hooks/useAutoplayContent/types';
import { QueueItem } from './QueueItem';

interface TrailContentsProps {
  content: LearningPathContent;
  contentIndex: string;
}

export function TrailContents({
  content,
  contentIndex
}: Readonly<TrailContentsProps>) {
  return (
    <Accordion
      defaultIndex={[0]}
      allowMultiple
      border={'transparent'}
      p={0}
      w={'full'}
      mt={0}
    >
      <AccordionItem>
        <AccordionButton p={0} w={'full'} _hover={{ bg: 'darkGrey.400' }}>
          <QueueItem
            content={content}
            contentIndex={contentIndex}
            key={`upNext-${content?.__typename}-${content?.id}`}
            isTrailHeader
          />
          <AccordionIcon me={6} />
        </AccordionButton>

        <AccordionPanel p={0} gap={2} bg={'black'}>
          {content?.contentCards.map((item, index) => (
            <QueueItem
              content={item}
              contentIndex={`${contentIndex}.${index + 1}`}
              isContentPartOfTrail
              key={`upNext-${item?.__typename}-${item.id}`}
            />
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
