import { DateTime } from 'luxon';

export const MONTH_NAMES_FULL = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const MONTH_NAMES_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const WEEKDAY_NAMES_SHORT = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export const RANGE_DATES_SHORTCUTS = [
  {
    id: 'today',
    label: 'Today',
    getStartDate: () => DateTime.local().startOf('day').toJSDate(),
    getEndDate: () => DateTime.local().endOf('day').toJSDate()
  },
  {
    id: 'yesterday',
    label: 'Yesterday',
    getStartDate: () =>
      DateTime.local().startOf('day').minus({ days: 1 }).toJSDate(),
    getEndDate: () =>
      DateTime.local().endOf('day').minus({ days: 1 }).toJSDate()
  },
  {
    id: 'last7Days',
    label: 'Last 7 Days',
    getStartDate: () =>
      DateTime.local().startOf('day').minus({ days: 6 }).toJSDate(),
    getEndDate: () => DateTime.local().endOf('day').toJSDate()
  },
  {
    id: 'last14Days',
    label: 'Last 14 Days',
    getStartDate: () =>
      DateTime.local().startOf('day').minus({ days: 13 }).toJSDate(),
    getEndDate: () => DateTime.local().endOf('day').toJSDate()
  },
  {
    id: 'thisWeek',
    label: 'This Week',
    getStartDate: () => DateTime.local().startOf('week').toJSDate(),
    getEndDate: () => DateTime.local().endOf('day').toJSDate()
  },
  {
    id: 'lastWeek',
    label: 'Last Week',
    getStartDate: () =>
      DateTime.local().startOf('week').minus({ week: 1 }).toJSDate(),
    getEndDate: () =>
      DateTime.local().endOf('week').minus({ week: 1 }).toJSDate()
  },
  {
    id: 'thisMonth',
    label: 'This Month',
    getStartDate: () => DateTime.local().startOf('month').toJSDate(),
    getEndDate: () => DateTime.local().endOf('day').toJSDate()
  },
  {
    id: 'lastMonth',
    label: 'Last Month',
    getStartDate: () =>
      DateTime.local().startOf('month').minus({ month: 1 }).toJSDate(),
    getEndDate: () =>
      DateTime.local().endOf('month').minus({ month: 1 }).toJSDate()
  },
  {
    id: 'thisYear',
    label: 'This Year',
    getStartDate: () => DateTime.local().startOf('year').toJSDate(),
    getEndDate: () => DateTime.local().endOf('day').toJSDate()
  },
  {
    id: 'lastYear',
    label: 'Last Year',
    getStartDate: () =>
      DateTime.local().startOf('year').minus({ year: 1 }).toJSDate(),
    getEndDate: () =>
      DateTime.local().endOf('year').minus({ year: 1 }).toJSDate()
  }
];
