import { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { ContentType, StreamSource } from '@tigerhall/core';
import {
  getContentUrl,
  getIsContentPreviewOver,
  getPlayingContent,
  setIsContentPreviewOver,
  setPlayingContent
} from 'app/state';
import { useAppDispatch, useAppSelector, useFreeAccount } from 'hooks';
import { PreviewLimitReachedWall } from 'components/ui/PreviewLimitReachedWall';
import useCommonModal from 'modules/application/hooks/useCommonModal';
import { MODALTYPES } from 'components/ui/modals';

import { LiveContentConnected } from '../LiveContentConnected';
import { LiveStreamConnected } from '../LiveStreamConnected';
import { PodcastPlayerConnected } from '../PodcastPlayerConnected';
import { PowerReadPlayerConnected } from '../PowerReadPlayerConnected';
import { VideoPlayerConnected } from '../VideoPlayerConnected';
import { useGetUserPreferredLanguageQuery } from '~/generated';
import { PlayerLoader } from '../PlayerLoader';

export interface PlayerConnectedProps {
  isMinimized: boolean;
  setIsMinimized: (value: boolean) => void;
}

export function PlayerConnected({
  isMinimized,
  setIsMinimized
}: Readonly<PlayerConnectedProps>) {
  const playingContent = useAppSelector(getPlayingContent);
  const contentUrl = useAppSelector(getContentUrl);
  const isPreviewLimitConsumed = useAppSelector(getIsContentPreviewOver);
  const dispatch = useAppDispatch();
  const { isInPreviewMode } = useFreeAccount();
  const { openModal } = useCommonModal();

  const { data: userLanguageData, loading: isLoadingUserPreferredLanguage } =
    useGetUserPreferredLanguageQuery();

  const userPreferredLanguage =
    userLanguageData?.user?.preferredLanguage ?? 'EN';

  const shouldShowPreviewLimitWall =
    isPreviewLimitConsumed && isInPreviewMode && !isMinimized;

  const onFinished = useCallback(() => {
    playingContent?.onCompletionCallback?.();

    if (isInPreviewMode) {
      setIsMinimized(false);
      dispatch(setIsContentPreviewOver(true));
    }
  }, [dispatch, isInPreviewMode, playingContent, setIsMinimized]);

  const onPlayerClose = useCallback(() => {
    dispatch(setPlayingContent(undefined));
  }, [dispatch]);

  const onMaximize = useCallback(() => {
    if (contentUrl) {
      window.history.pushState(null, '', contentUrl);
    }
    setIsMinimized(false);
  }, [contentUrl, setIsMinimized]);

  const onUpgradePlan = useCallback(() => {
    onPlayerClose();
    setIsMinimized(false);
    dispatch(setIsContentPreviewOver(false));
    openModal(MODALTYPES.Payment);
  }, [dispatch, onPlayerClose, openModal, setIsMinimized]);

  const onWatchAgain = useCallback(() => {
    dispatch(setIsContentPreviewOver(false));
  }, [dispatch]);

  if (!playingContent?.id) {
    return <Box w="full" h="100%" bg="black" />;
  }

  if (isLoadingUserPreferredLanguage) {
    return <PlayerLoader />;
  }

  if (shouldShowPreviewLimitWall) {
    return (
      <PreviewLimitReachedWall
        onUpgradePlan={onUpgradePlan}
        onWatchAgain={onWatchAgain}
        content={{
          id: playingContent.id,
          type: playingContent.type
        }}
        isVisible={shouldShowPreviewLimitWall}
      />
    );
  }

  const commonProps = {
    id: playingContent.id,
    onMaximize,
    isMinimized,
    setIsMinimized,
    onClose: onPlayerClose,
    onFinished,
    userPreferredLanguage
  };

  switch (playingContent.type) {
    case ContentType.Podcast:
      return <PodcastPlayerConnected {...commonProps} />;
    case ContentType.Stream:
      return <VideoPlayerConnected {...commonProps} />;
    case ContentType.Ebook:
      return <PowerReadPlayerConnected {...commonProps} />;
    case StreamSource.BroadcastCloudflareLive:
    case StreamSource.BroadcastAwsIvs:
      return (
        <LiveContentConnected
          {...commonProps}
          onFinished={playingContent.onCompletionCallback}
        />
      );
    case StreamSource.LivestreamAgora:
      return (
        <LiveStreamConnected
          {...commonProps}
          onFinished={playingContent.onCompletionCallback}
        />
      );
    default:
      return <Box w="full" h="100%" bg="black" />;
  }
}
