import {
  Center,
  HStack,
  Icon,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tooltip
} from '@chakra-ui/react';
import { useState, useTransition } from 'react';
import { IconTextSize } from '@tigerhall/icons';

import { usePowerReadState } from '~/player/PowerRead/context/usePowerReadState';

const MIN_FONT_SIZE = 14;
const MAX_FONT_SIZE = 48;

export function TextSizeSlider() {
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
  const [, startTransition] = useTransition();

  const { theme, setFontSize, fontSize } = usePowerReadState((state) => {
    return {
      theme: state.theme,
      setFontSize: state.setFontSize,
      fontSize: state.fontSize
    };
  });

  const [sliderValue, setSliderValue] = useState(fontSize);

  const handleChange = (e: number) => {
    setSliderValue(e);
    startTransition(() => {
      setFontSize(e);
    });
  };

  const isDarkMode = theme === 'dark';
  const textColor = isDarkMode ? 'white' : 'darkGrey.300';
  const backgroundColor = isDarkMode ? 'black' : 'lightGrey.600';

  return (
    <Center width="100%" backgroundColor={backgroundColor}>
      <HStack
        justifyContent="space-between"
        alignItems="center"
        p="1.5rem"
        spacing="1rem"
        width="100%"
        maxWidth="50rem"
      >
        <Icon
          as={IconTextSize}
          color={textColor}
          w={`${MIN_FONT_SIZE / 16}rem`}
          h={`${MIN_FONT_SIZE / 16}rem`}
        />
        <Slider
          max={48}
          min={14}
          step={0.5}
          width="100%"
          onChangeEnd={(e) => {
            handleChange(e);
          }}
          onChange={(e) => {
            handleChange(e);
          }}
          onMouseEnter={() => {
            startTransition(() => {
              setShouldShowTooltip(true);
            });
          }}
          onMouseLeave={() => {
            startTransition(() => {
              setShouldShowTooltip(false);
            });
          }}
          value={sliderValue}
        >
          <SliderTrack bg="darkGrey.300">
            <SliderFilledTrack bg="tigerOrange.600" />
          </SliderTrack>

          <Tooltip
            hasArrow
            placement="top"
            isOpen={shouldShowTooltip}
            label={`${sliderValue}px`}
          >
            <SliderThumb bg="tigerOrange.600" />
          </Tooltip>
        </Slider>
        <Icon
          as={IconTextSize}
          color={textColor}
          w={`${MAX_FONT_SIZE / 16}rem`}
          h={`${MAX_FONT_SIZE / 16}rem`}
        />
      </HStack>
    </Center>
  );
}
