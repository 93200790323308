import * as React from 'react';
import {
  Center,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack
} from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { IconCaretDownFilled } from '@tigerhall/icons';

interface TimePickerProps {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
}

function IncrementDecrementButton({
  children,
  onClick,
  label
}: {
  children: React.ReactElement;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  label: string;
}) {
  return (
    <IconButton
      variant="unstyled"
      minWidth="1.5rem"
      height="1rem"
      borderRadius="sm"
      aria-label={label}
      icon={children}
      onClick={onClick}
    />
  );
}

export function TimePicker({ selectedDate, setSelectedDate }: TimePickerProps) {
  return (
    <Flex direction="column" gap="1rem">
      <Text fontSize="sm" fontWeight="bold" color="white" as="span">
        Time
      </Text>
      <HStack spacing=".75rem">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          background="transparent"
          gap=".125rem"
          border="1px solid"
          borderColor="darkGrey.200"
          borderRadius="base"
          padding="0.125rem"
        >
          <Center
            flex={1}
            width="2.5rem"
            height="2rem"
            background="darkGrey.700"
            borderRadius="base"
          >
            <Text fontSize="md" color="white">
              {DateTime.fromJSDate(selectedDate).toFormat('H').toString()}
            </Text>
          </Center>
          <VStack spacing="0.125rem">
            <IncrementDecrementButton
              label="Increment hour"
              onClick={() => {
                setSelectedDate(
                  DateTime.fromJSDate(selectedDate)
                    .plus({ hours: 1 })
                    .toJSDate()
                );
              }}
            >
              <Icon
                as={IconCaretDownFilled}
                width=".75rem"
                height=".75rem"
                transform="auto"
                rotate="180deg"
              />
            </IncrementDecrementButton>
            <IncrementDecrementButton
              label="Decrement hour"
              onClick={() => {
                setSelectedDate(
                  DateTime.fromJSDate(selectedDate)
                    .minus({ hours: 1 })
                    .toJSDate()
                );
              }}
            >
              <Icon as={IconCaretDownFilled} width=".75rem" height=".75rem" />
            </IncrementDecrementButton>
          </VStack>
        </Flex>
        <Text fontSize="3xl" color="white" as="span">
          :
        </Text>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          background="transparent"
          gap=".125rem"
          border="1px solid"
          borderColor="darkGrey.200"
          borderRadius="base"
          padding="0.125rem"
        >
          <Center
            flex={1}
            width="2.5rem"
            height="2rem"
            background="darkGrey.700"
            borderRadius="base"
          >
            <Text fontSize="md" color="white">
              {DateTime.fromJSDate(selectedDate).toFormat('mm').toString()}
            </Text>
          </Center>
          <VStack spacing="0.125rem">
            <IncrementDecrementButton
              label="Increment 15 minutes"
              onClick={() => {
                setSelectedDate(
                  DateTime.fromJSDate(selectedDate)
                    .plus({ minutes: 5 })
                    .toJSDate()
                );
              }}
            >
              <Icon
                as={IconCaretDownFilled}
                width=".75rem"
                height=".75rem"
                transform="auto"
                rotate="180deg"
              />
            </IncrementDecrementButton>
            <IncrementDecrementButton
              label="Decrement 15 minutes"
              onClick={() => {
                setSelectedDate(
                  DateTime.fromJSDate(selectedDate)
                    .minus({ minutes: 5 })
                    .toJSDate()
                );
              }}
            >
              <Icon as={IconCaretDownFilled} width=".75rem" height=".75rem" />
            </IncrementDecrementButton>
          </VStack>
        </Flex>
      </HStack>
    </Flex>
  );
}
