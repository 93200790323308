import { mode } from '@chakra-ui/theme-tools';
import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle
} from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleDialogContainer = defineStyle(
  ({ isCentered, scrollBehavior }) => {
    return {
      display: 'flex',
      zIndex: 'modal',
      justifyContent: 'center',
      alignItems: isCentered ? 'center' : 'flex-start',
      overflow: scrollBehavior === 'inside' ? 'hidden' : 'auto'
    };
  }
);

const baseStyleDialog = defineStyle((props) => {
  return {
    bg: mode('white', 'darkGrey.700')(props)
  };
});

const baseStyleHeader = defineStyle((props) => {
  return {
    fontSize: '1.25rem',
    fontWeight: '700',
    lineHeight: '1.2',
    color: mode('black', 'white')(props),
    px: '1.5rem',
    py: '1rem',
    borderBottomWidth: '1px'
  };
});

const baseStyleCloseButton = defineStyle((props) => {
  return {
    color: mode('darkGrey.50', 'white')(props),
    position: 'absolute',
    top: 2,
    insetEnd: 3,
    borderRadius: 'full'
  };
});

const baseStyleBody = defineStyle(({ scrollBehavior }) => {
  return {
    px: '1.5rem',
    py: '1rem',
    flex: 1,
    overflow: scrollBehavior === 'inside' ? 'auto' : undefined
  };
});

const baseStyleFooter = defineStyle((props) => ({
  borderTopWidth: '1px'
}));

const baseStyle = definePartsStyle((props) => ({
  dialogContainer: baseStyleDialogContainer(props),
  dialog: baseStyleDialog(props),
  header: baseStyleHeader(props),
  closeButton: baseStyleCloseButton(props),
  body: baseStyleBody(props),
  footer: baseStyleFooter(props)
}));

const prompt = definePartsStyle((props) => ({
  header: {
    textAlign: 'center'
  }
}));

export const Modal = defineMultiStyleConfig({
  baseStyle,
  variants: { prompt }
});
