import { Box, Center, Tooltip } from '@chakra-ui/react';

interface ExpandCollapseIconProps {
  isOpen: boolean;
  onToggle: () => void;
}

export function ExpandCollapseIcon({
  isOpen,
  onToggle
}: Readonly<ExpandCollapseIconProps>) {
  return (
    <Tooltip
      label={isOpen ? 'Close sidebar' : 'Open sidebar'}
      bg="darkGrey.700"
      placement="left"
      color={'white'}
      fontWeight={'bold'}
      hasArrow
    >
      <Center
        as={'button'}
        h={'full'}
        w={6}
        p={'3.5rem 0.5rem'}
        bg={'darkGrey.700'}
        color={'white'}
        onClick={onToggle}
        zIndex={'docked'}
        data-group
      >
        <Box height={4} marginBottom={4}>
          <Box
            w={1}
            h={'100%'}
            bg={'darkGrey.50'}
            borderTopRadius={4}
            transition={'ease'}
            transitionDuration={'0.3s'}
            _groupHover={{
              borderRadius: 4,
              transform: isOpen
                ? 'rotate(-45deg) translate(-2.5px, 2.5px)'
                : 'rotate(45deg) translate(2.5px, 2.5px)'
            }}
          />
          <Box
            w={1}
            h={'100%'}
            bg={'darkGrey.50'}
            borderBottomRadius={4}
            transition={'ease'}
            transitionDuration={'0.3s'}
            _groupHover={{
              borderRadius: 4,
              transform: isOpen
                ? 'rotate(45deg) translate(-2.5px, -2.5px)'
                : 'rotate(-45deg) translate(2.5px, -2.5px)'
            }}
          />
        </Box>
      </Center>
    </Tooltip>
  );
}
