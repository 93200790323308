import { MODALTYPES } from 'components/ui/modals';
import { useNavigate } from 'react-router-dom';

import { commonModal } from '../utils/routes';

const useCommonModal = () => {
  const navigate = useNavigate();
  // dispatch action to start actual ebook here.
  const openModal = (modalType: MODALTYPES) => navigate(commonModal(modalType));

  return {
    openModal
  };
};

export default useCommonModal;
