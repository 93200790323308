import * as React from 'react';
import { Button, Flex, Heading, Icon, VStack } from '@chakra-ui/react';
import { Paywall } from 'components/ui/Paywall';
import useCommonModal from 'modules/application/hooks/useCommonModal';
import { MODALTYPES } from 'components/ui/modals';
import { useSearchParams } from 'react-router-dom';
import { IconBrandTigerhallHorizontal } from '@tigerhall/icons';

import BookMeetingModal from './BookMeetingModal';
export function SubscriptionPlan() {
  const { openModal } = useCommonModal();
  const [params] = useSearchParams();

  return (
    <VStack
      className="scroll-style"
      backgroundColor="darkGrey.700"
      flex={1}
      minH="100vh"
      overflowY="auto"
      pb="3rem"
    >
      <Flex
        w="100%"
        mb={10}
        pt={6}
        px={10}
        justifyContent="space-between"
        alignItems="center"
      >
        <a href="https://tigerhall.com">
          <Icon
            as={IconBrandTigerhallHorizontal}
            width="6.25rem"
            height={7}
            color="tigerOrange.600"
          />
        </a>
        <Button
          size={{
            base: 'sm',
            md: 'md'
          }}
          onClick={() => {
            openModal(MODALTYPES.MeetingsEmbed);
          }}
        >
          Book a Meeting
        </Button>
      </Flex>
      <Heading fontSize="3xl" textAlign="center" color="white" px="2rem">
        Choose a subscription
      </Heading>
      <Paywall />
      {params.has('modal') ? <BookMeetingModal /> : null}
    </VStack>
  );
}
