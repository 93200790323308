import * as React from 'react';
import { RouteObject } from 'react-router-dom';
import { ContentModalContainer } from 'components/ui/ContentModalContainer';
import { ContentPlayer } from 'components/ui/ContentPlayer';

const Tab = React.lazy(() =>
  import('./screens/ChannelTab').then((module) => ({
    default: module.ChannelTab
  }))
);

const Configure = React.lazy(() =>
  import('./screens/Configure').then((module) => ({
    default: module.Configure
  }))
);

const Remove = React.lazy(() =>
  import('./screens/Remove').then((module) => ({
    default: module.Remove
  }))
);

const routes: RouteObject[] = [
  {
    path: 'follow-topics',
    children: [
      {
        index: true,
        element: (
          <React.Suspense>
            <ContentModalContainer>
              <Tab />
            </ContentModalContainer>
            <ContentPlayer />
          </React.Suspense>
        )
      },
      {
        path: 'configure',
        element: (
          <React.Suspense>
            <Configure />
          </React.Suspense>
        )
      },
      {
        path: 'remove',
        element: (
          <React.Suspense>
            <Remove />
          </React.Suspense>
        )
      }
    ]
  }
];

export default routes;
