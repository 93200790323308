import * as React from 'react';
import { Button } from '@chakra-ui/react';
import { type DateObj, type RenderProps } from 'dayzed';

import { type DatepickerProps, type DayOfMonthBtnStyleProps } from '../types';

interface DayOfMonthProps extends DatepickerProps {
  renderProps: RenderProps;
  isInRange?: boolean | null;
  dateObj: DateObj;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
}

const halfGap = 0.125; // default Chakra-gap-space-1 is 0.25rem

export const DayOfMonth: React.FC<DayOfMonthProps> = ({
  dateObj,
  propsConfigs,
  isInRange,
  renderProps,
  onMouseEnter
}) => {
  const {
    date,
    selected: isSelected,
    selectable: isSelectable,
    today: isToday
  } = dateObj;
  const { getDateProps } = renderProps;
  const {
    defaultBtnProps,
    isInRangeBtnProps,
    selectedBtnProps,
    todayBtnProps
  } = propsConfigs?.dayOfMonthBtnProps || {};

  const styleBtnProps: DayOfMonthBtnStyleProps = React.useMemo(
    () => ({
      defaultBtnProps: {
        fontSize: 'sm',
        px: '1rem',
        height: '2rem',
        width: '3rem',
        variant: 'unstyled',
        background: 'transparent',
        borderColor: 'transparent',
        borderRadius: 'base',
        color: 'white',
        // this intends to fill the visual gap from Grid to improve the UX
        // so the button active area is actually larger than what it's seen
        _after: {
          content: "''",
          position: 'absolute',
          top: `-${halfGap}rem`,
          left: `-${halfGap}rem`,
          bottom: `-${halfGap}rem`,
          right: `-${halfGap}rem`,
          borderWidth: `${halfGap}rem`,
          borderColor: 'transparent'
        },
        ...defaultBtnProps,
        _hover: isSelectable
          ? {
              bg: 'darkGrey.200',
              color: 'white',
              ...defaultBtnProps?._hover
            }
          : undefined
      },
      isInRangeBtnProps: {
        background: 'darkGrey.200',
        color: 'white',
        borderRadius: 0,
        ...isInRangeBtnProps
      },
      selectedBtnProps: {
        background: 'white',
        color: 'black',
        borderRadius: 'base',
        ...selectedBtnProps
      },
      todayBtnProps: {
        borderColor: 'blue.400',
        ...todayBtnProps
      }
    }),
    [
      defaultBtnProps,
      isInRangeBtnProps,
      selectedBtnProps,
      todayBtnProps,
      isSelectable
    ]
  );

  return (
    <Button
      {...getDateProps({
        dateObj,
        disabled: !isSelectable,
        onMouseEnter: onMouseEnter
      })}
      isDisabled={!isSelectable}
      {...styleBtnProps.defaultBtnProps}
      {...(isInRange && isSelectable && styleBtnProps.isInRangeBtnProps)}
      {...(isSelected && isSelectable && styleBtnProps.selectedBtnProps)}
      {...(isToday && styleBtnProps.todayBtnProps)}
    >
      {date.getDate()}
    </Button>
  );
};
