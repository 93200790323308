import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/reducer';

interface State {
  expanded: boolean;
  isMouseOver: boolean;
}

const initialState: State = {
  expanded: false,
  isMouseOver: false
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setIsExpanded: (state, action: PayloadAction<boolean>) => {
      state.expanded = action.payload;
    },

    setIsMouseOver: (state, action: PayloadAction<boolean>) => {
      state.isMouseOver = action.payload;
    }
  }
});

export const isSidebarExpanded = (state: RootState) =>
  state.sidebar.expanded || state.sidebar.isMouseOver;

export const { setIsExpanded, setIsMouseOver } = sidebarSlice.actions;
