import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrgGroupsByOrgIdQueryVariables = SchemaTypes.Exact<{
  organisationId: SchemaTypes.Scalars['String'];
  filter?: SchemaTypes.InputMaybe<SchemaTypes.OrganisationGroupFilter>;
}>;


export type GetOrgGroupsByOrgIdQuery = { __typename?: 'Query', organisation: { __typename: 'Organisation', id: string, orgGroups: { __typename?: 'OrganisationGroupConnection', edges: Array<{ __typename: 'OrganisationGroup', name: string, id: string }>, meta: { __typename?: 'PageInfo', limit: number, offset: number, total: number } } } };


export const GetOrgGroupsByOrgIdDocument = gql`
    query GetOrgGroupsByOrgId($organisationId: String!, $filter: OrganisationGroupFilter) {
  organisation(id: $organisationId) {
    id
    __typename
    orgGroups(filter: $filter) {
      edges {
        name
        id
        __typename
      }
      meta {
        limit
        offset
        total
      }
    }
  }
}
    `;

/**
 * __useGetOrgGroupsByOrgIdQuery__
 *
 * To run a query within a React component, call `useGetOrgGroupsByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgGroupsByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgGroupsByOrgIdQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOrgGroupsByOrgIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrgGroupsByOrgIdQuery, GetOrgGroupsByOrgIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgGroupsByOrgIdQuery, GetOrgGroupsByOrgIdQueryVariables>(GetOrgGroupsByOrgIdDocument, options);
      }
export function useGetOrgGroupsByOrgIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgGroupsByOrgIdQuery, GetOrgGroupsByOrgIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgGroupsByOrgIdQuery, GetOrgGroupsByOrgIdQueryVariables>(GetOrgGroupsByOrgIdDocument, options);
        }
export type GetOrgGroupsByOrgIdQueryHookResult = ReturnType<typeof useGetOrgGroupsByOrgIdQuery>;
export type GetOrgGroupsByOrgIdLazyQueryHookResult = ReturnType<typeof useGetOrgGroupsByOrgIdLazyQuery>;
export type GetOrgGroupsByOrgIdQueryResult = Apollo.QueryResult<GetOrgGroupsByOrgIdQuery, GetOrgGroupsByOrgIdQueryVariables>;