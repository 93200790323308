import * as SchemaTypes from '@tigerhall/core/lib/types';

import { gql } from '@apollo/client';
export type ImageIdUriFragment = { __typename?: 'Image', id: string, uri: string };

export const ImageIdUriFragmentDoc = gql`
    fragment imageIdUri on Image {
  id
  uri
}
    `;