import * as React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'components/tools/ErrorBoundary';
import { Splash } from 'components/ui/Layout/components';

const Topic = React.lazy(() =>
  import('./screens/Topic').then((module) => ({
    default: module.Topic
  }))
);

const TopicTrails = React.lazy(() =>
  import('./screens/TopicTrails').then((module) => ({
    default: module.TopicTrails
  }))
);

const Category = React.lazy(() =>
  import('./screens/Category').then((module) => ({
    default: module.Category
  }))
);

export const routes: RouteObject[] = [
  {
    path: 'content/',
    children: [
      {
        index: true,
        element: <Navigate to="/home" />
      },
      {
        path: 'topic/:id',
        element: (
          <ErrorBoundary>
            <React.Suspense fallback={<Splash />}>
              <Topic />
            </React.Suspense>
          </ErrorBoundary>
        )
      },
      {
        path: 'topic/:id/trails',
        element: (
          <ErrorBoundary>
            <React.Suspense fallback={<Splash />}>
              <TopicTrails />
            </React.Suspense>
          </ErrorBoundary>
        )
      },
      {
        path: 'category/:id',
        element: (
          <ErrorBoundary>
            <React.Suspense fallback={<Splash />}>
              <Category />
            </React.Suspense>
          </ErrorBoundary>
        )
      }
    ]
  }
];
