import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
const SvgBrandTigerhall = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    focusable={false}
    className="th-icon"
    color="white"
    width="1.25rem"
    height="1.25rem"
    ref={ref}
    {...props}
  >
    <path
      fill="currentcolor"
      fillRule="evenodd"
      d="M12 2a1 1 0 0 1 1 1v9.125h1.375V7.5a1 1 0 1 1 2 0v11.125h-2v-4.5H13V20h5.625v2H6.375a1 1 0 1 1 0-2H11v-5.875H9.625v4.5h-2V7.5a1 1 0 0 1 2 0v4.625H11V3a1 1 0 0 1 1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
SvgBrandTigerhall.displayName = "IconBrandTigerhall";
const ForwardRef = forwardRef(SvgBrandTigerhall);
export default ForwardRef;
