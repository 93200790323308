import {
  SubscriptionStatus,
  useGetCurrentUserSubscriptionStatusQuery
} from 'generated';
import { getAccessToken } from 'app/state';

import { useAppSelector } from './redux';

export function useSubscriptionStatus() {
  const accessToken = useAppSelector(getAccessToken);

  const isLoggedIn = Boolean(accessToken);

  const { data } = useGetCurrentUserSubscriptionStatusQuery({
    fetchPolicy: 'network-only',
    skip: !isLoggedIn
  });

  const currentUser = data?.me;

  const isExpired =
    currentUser?.subscriptionStatus === SubscriptionStatus.Expired;

  const isActive =
    currentUser?.subscriptionStatus === SubscriptionStatus.Active;

  const isTrial = currentUser?.subscriptionStatus === SubscriptionStatus.Trial;

  return {
    isExpired,
    isActive,
    isTrial,
    subscriptionStatus: currentUser?.subscriptionStatus
  };
}
