import { Divider, Flex, Text, VStack } from '@chakra-ui/react';
import {
  SignInWithApple,
  SignInWithGoogle,
  SignInWithLinkedIn,
  SignInWithMicrosoft
} from 'modules/authentication/components';
import { useSingleSignOnCallback } from 'modules/authentication/hook';
import {
  BaseLoginLayout,
  FormDetectSSOEnabled
} from 'modules/authentication/modules/Login/components';
import * as React from 'react';
import { Helmet } from 'react-helmet';

export function Login() {
  useSingleSignOnCallback();

  return (
    <>
      <Helmet>
        <title>Tigerhall Login</title>
      </Helmet>
      <BaseLoginLayout>
        <VStack spacing="0.75rem">
          <SignInWithGoogle />
          <SignInWithMicrosoft />
          <SignInWithApple />
          <SignInWithLinkedIn />
          <Flex align="center" w="100%" py="1rem">
            <Divider borderColor="lightGrey.600" orientation="horizontal" />
            <Text padding="0.5rem" fontSize="0.875rem" color="lightGrey.600">
              OR
            </Text>
            <Divider borderColor="lightGrey.600" orientation="horizontal" />
          </Flex>
          <FormDetectSSOEnabled />
        </VStack>
      </BaseLoginLayout>
    </>
  );
}
