import { useNavigate } from 'react-router-dom';
import { ButtonProps, Icon } from '@chakra-ui/react';
import { IconChevronLeft } from '@tigerhall/icons';
import { TrackedButton } from '@tigerhall/components';
import { getPreviousRoute } from 'utils';

interface BackButtonProps extends Omit<ButtonProps, 'as'> {
  to?: string;
  name?: string;
  children?: string;
}

export function BackButton({
  to,
  name = 'BACK',
  children = 'Back',
  ...rest
}: Readonly<BackButtonProps>) {
  const navigate = useNavigate();

  const { prevRoute, replace } = getPreviousRoute();

  return (
    <TrackedButton
      as="button"
      name={name}
      variant="outline"
      size="lg"
      width="fit-content"
      leftIcon={<Icon as={IconChevronLeft} />}
      onClick={() => {
        if (to) {
          navigate(to);
        } else if (typeof prevRoute === 'string') {
          navigate(prevRoute, { replace });
        } else {
          navigate(prevRoute);
        }
      }}
      {...rest}
    >
      {children}
    </TrackedButton>
  );
}
