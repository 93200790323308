import * as Sentry from '@sentry/react';
import merge from 'lodash/merge';

import { AppError } from '../../../utils';

/**
 * This function is supposed to be used with the `ErrorBoundary` component,
 * in the `beforeCapture` callback.
 *
 * It will merge the `error.context` with the current sentry scope.
 *
 * For this to work perfectly, the `error` must be an instance of `AppError`.
 *
 * @example
 * ```tsx
 * <ErrorBoundary
 *   fallback={ScreenErrorFallback}
 *   beforeCapture={mergeErrorContext}
 * >
 *   <App /> // ---> This is the component that throws the error
 * </ErrorBoundary>
 * ```
 */
export function mergeErrorContext(
  scope: Sentry.Scope,
  error: Error | AppError
) {
  if (error instanceof AppError) {
    scope.update((currentScope) => {
      return merge(currentScope, error.context);
    });
  }
}

/**
 * This function throws an `AppError` with the given `message` and `context`.
 *
 * It is supposed to be used with the `ErrorBoundary` component wrapping the
 * whole app or part of it.
 *
 * @example
 * ```tsx
 * const id = data?.id ?? raiseError('No content id found', { extra: data });
 * ```
 */
export function raiseError(
  message: AppError['message'],
  context?: AppError['context']
): never {
  throw new AppError(message, context);
}
